import React from "reactn";
import MsgIcon from "../../../assets/img/msgicon.png";

class ProfilePhotos extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      child: null
    };
    this.fileChange = this.fileChange.bind(this);
    this.uploadNew = this.uploadNew.bind(this);
  }

  uploadNew(c) {
    if (c) {
      this.setState({
        child: c
      });
    } else {
      this.setState({ child: null });
    }
    const uploadEle = document.getElementById("uploadPhoto");
    uploadEle.click();
  }

  fileChange(e) {
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      const fd = new FormData();
      fd.set("image", e.target.files[0]);
      if (this.state.child) {
        fd.set("child", JSON.stringify(this.state.child));
      }
      this.dispatch.uploadProfilePhoto(fd);
    }
    e.target.value = "";
  }

  render() {
    return (
      <div className="box">
        <div className="bgofbox">
          <div className="header">
            <div className="row">
              <div className="col-md-12">
                <h2>PROFILE PHOTOS</h2>
              </div>
            </div>
          </div>
          <div className="formbody">
            <div className="boxofprfilephotos">
              <div className="row">
                <div className="col-md-4 col-6 text-center">
                  <p>Me</p>
                  <img
                    src={
                      this.global.dsUser.photo
                        ? `${process.env.REACT_APP_DS_FILES_S3}/${this.global.dsUser.photo}`
                        : MsgIcon
                    }
                    alt=""
                  />
                  <button onClick={() => this.uploadNew(null)}>Upload</button>
                </div>
                {this.global.dsUser.accountType === "Parent" &&
                  this.global.children.map((c, i) =>
                    typeof c.id === "number" ? (
                      <div className="col-md-4 col-6 text-center" key={i}>
                        <p>{c.fullName}</p>
                        <img
                          src={
                            c.photo
                              ? `${process.env.REACT_APP_DS_FILES_S3}/${c.photo}`
                              : MsgIcon
                          }
                          alt=""
                          key={`${c.id}-child`}
                        />
                        <button onClick={() => this.uploadNew(c)}>
                          Upload
                        </button>
                      </div>
                    ) : (
                      ""
                    )
                  )}
              </div>
            </div>
          </div>
          <input
            type="file"
            style={{ display: "none" }}
            id="uploadPhoto"
            onChange={this.fileChange}
            accept="image/x-png,image/gif,image/jpeg"
          />
        </div>
      </div>
    );
  }
}
export default ProfilePhotos;
