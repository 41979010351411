import React from "reactn";
import BoxOrange from "../../components/common/BoxOrange";
import BoxBlue from "../../components/common/BoxBlue";
import GrossTuition from "../../components/admin/GrossTuition";
import DashboardBoxes from "../../components/admin/DashboardBoxes";
import RecentPayment from "../../components/admin/Payouts";
import axios from "axios";

class Dashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      prescreenCounts: {},
      applicationCounts: {},
      infoCounts: {},
      tourCounts: {},
      enrollmentCounts: {},
      programSubmittedCounts: {},
      programPublishedCounts: {},
      enrollPendingCounts: {},
      enrollActiveCounts: {},
      runningTotal: null,
      total: 0
    };
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/dashboard`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(`${ep}`);
      if (res.data.success) {
        this.setState({ ...res.data.data });
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      console.error(err);
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    const {
      prescreenCounts,
      applicationCounts,
      infoCounts,
      tourCounts,
      enrollmentCounts,
      programSubmittedCounts,
      programPublishedCounts,
      enrollPendingCounts,
      enrollActiveCounts,
      runningTotal,
      total,
      balances
    } = this.state;

    return (
      <div className="container-fluid hero">
        <div className="cont">
          <div className="d-sm-flex align-items-center justify-content-between">
            <h1 className="">Welcome, {this.global.dsUser.displayName}</h1>
          </div>

          <div className="info-boxes">
            <div className="orange section">
              <BoxOrange
                boxName="Pre-Screening Applications"
                boxCount={prescreenCounts.total || 0}
                boxNew={prescreenCounts.newThisWeek || 0}
                title="EDUCATORS"
              />

              <BoxOrange
                boxName="Submitted Applications"
                boxCount={applicationCounts.total || 0}
                boxNew={applicationCounts.newThisWeek || 0}
              />
              <BoxBlue
                boxName="Family Registrations"
                boxCount={enrollmentCounts.total || 0}
                boxNew={enrollmentCounts.newThisWeek || 0}
                title="PARENTS"
              />

              <BoxBlue
                boxName="Tour Requests"
                boxCount={tourCounts.total || 0}
                boxNew={tourCounts.newThisWeek || 0}
              />
              <BoxBlue
                boxName="Plan Requests"
                boxCount={infoCounts.total || 0}
                boxNew={infoCounts.newThisWeek || 0}
              />
            </div>
          </div>
        </div>
        <div className="cont d-flex mt-3">
          <RecentPayment earnings={balances || []} />
          <DashboardBoxes
            enrollments={{ enrollActiveCounts, enrollPendingCounts }}
            programs={{ programPublishedCounts, programSubmittedCounts }}
          />
        </div>
      </div>
    );
  }
}

export default Dashboard;
