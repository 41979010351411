import React from "reactn";
import { Modal } from "../../UI";
import axios from "axios";
import { toast } from "react-toastify";

class PrescreeningAcceptModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      prescreen: { ...props.data },
      profile: {
        dsFee: "",
        termLength: "",
        spots: "",
        allSlots: true
      },
      open: false
    };
    this.acceptApp = this.acceptApp.bind(this);
    this.setSlots = this.setSlots.bind(this);
  }

  acceptApp = async () => {
    const ep = `${process.env.REACT_APP_API}/adm/prescreen/accept`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.post(ep, this.state);
      this.setGlobal({ loading: false, lastAPICall: res });
      if (res.data.success) {
        this.props.onClose(true);
      }
    } catch (err) {
      toast.error(err.message);
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  };

  componentWillReceiveProps(newProps) {
    if (newProps.open) {
      this.setState({
        prescreen: { ...newProps.data },
        profile: {
          dsFee: "",
          termLength: "",
          spots: "",
          allSlots: true
        },
        open: true
      });
    } else {
      this.setState({ open: false });
    }
  }

  setSlots = e => {
    this.setState({
      profile: {
        ...this.state.profile,
        [e.target.name]: e.target.value === "Yes" ? true : false
      }
    });
  };

  render() {
    const { onClose } = this.props;
    return (
      <div>
        <Modal
          open={this.state.open}
          heading="Educator Acceptance Terms"
          onClose={onClose}
        >
          <div className="row">
            <div className="col-6">
              <p>Dreamschools Fee %</p>
            </div>
            <div className="col-6">
              <input
                type="number"
                placeholder="0.0%"
                name="dsFee"
                onChange={e => {
                  this.setState({
                    profile: {
                      ...this.state.profile,
                      [e.target.name]: e.target.value
                    }
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <p>Term Length</p>
            </div>
            <div className="col-6">
              <input
                type="number"
                placeholder="2 years"
                name="termLength"
                id="termLength"
                onChange={e => {
                  this.setState({
                    profile: {
                      ...this.state.profile,
                      [e.target.name]: e.target.value
                    }
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <p>All Slots?</p>
            </div>
            <div className="col-6">
              <select
                value={this.state.allSlots}
                name="allSlots"
                onChange={e => {
                  this.setSlots(e);
                }}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          {/* {this.state.profile.allSlots ? null : ( */}
          <div className="row">
            <div className="col-6">
              <p>Spots Shared</p>
            </div>
            <div className="col-6">
              <input
                type="number"
                placeholder="0 spots"
                name="spots"
                onChange={e => {
                  this.setState({
                    profile: {
                      ...this.state.profile,
                      [e.target.name]: e.target.value
                    }
                  });
                }}
              />
            </div>
          </div>
          {/* )} */}
          <div className="row">
            <div className="col-12">
              <div className="buttons">
                <button
                  onClick={this.acceptApp}
                  className="nextbtn"
                  disabled={
                    !this.state.profile.termLength ||
                    !this.state.profile.dsFee ||
                    (!this.state.profile.allSlots &&
                      !this.state.profile.spots) ||
                    this.global.loading
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default PrescreeningAcceptModal;
