import React, { useEffect, useGlobal, useState } from "reactn";
import axios from "axios";

import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { format } from "date-fns";
import { toast } from "react-toastify";

import _, { update } from "lodash";

import { Letter, ParentMessage, Reporting10 as SMS } from "../../../assets";

import "../../../assets/css/componentSpecificCss/communications.css";
import MessageThreadView from "./commComponents/MessageThreadView";
import MessageTable from "./commComponents/MessageTable";
//import SearchBar from "./commComponents/SearchBar";
import MessageViewHeader from "./commComponents/MessageViewHeader";
import Chat from "./commComponents/Chat";
import { formatPhoneNumber, getOtherPhone } from "./commComponents/commUtil";

/*
Rushed code. Communications handles both twilio messages and regular messages.
Regular (stored in database) messages can be either email or SMS.

Potential improvements:
- Avoid complete data refetch when a message is sent
- Standardize twilio messages, database SMS's, and database emails. 
*/

const Communications = props => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");
  const setLoading = useGlobal("loading")[1];

  // Message Threads
  const [messageThreads, setMessageThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState(/* {} */);
  // Table Data
  const [userInfoLT, setUserInfoLT] = useState({});

  // Used for refetching data.
  const [refetch, setRefetch] = useState(0);



  const fetchSingleThreadData = async (thread) => {
    console.info(`- fetchSingleThreadData[${thread?.uid}]`);
    if (!thread.listingTable || !thread.listingId) {
      throw new Error(`Unexpected null in either listingTable=[${thread.listingTable}] or listingId=[${thread.listingId}]`);
    }
    const ep = `${process.env.REACT_APP_API}/partners/connections/message-threads/${thread.listingTable}/${thread.listingId}`;
    const results = await axios.get(ep);
    if (!results.data.success) {
      toast.error(results.data.message);
      return;
    }
    return results.data.data;
  };

  const fetchUserProfiles = async (userIdsSet) => {
    if (!userIdsSet || userIdsSet?.size === 0 || userIdsSet?.length === 0)
      return {};
    const ep = `${process.env.REACT_APP_API}/partners/connections/user-info`;
    const results = await axios.post(ep, { ids: Array.from(userIdsSet) });
    if (!results.data.success) {
      toast.error(results.data.message);
      return;
    }

    const convertToLookupTable = (userInfoArr) => (
      Object.fromEntries(
        userInfoArr.map(e => ([e.id, e]))
      )
    );
    return convertToLookupTable(results.data.data);
  };



  // Initial useEffect
  useEffect(() => {

    const fetchMessageThreads = async () => {
      const ep = `${process.env.REACT_APP_API}/partners/connections/message-threads`;
      const results = await axios.get(ep);
      if (!results.data.success) {
        toast.error(results.data.message);
        return;
      }
      return results.data.data;
    };

    const fetchTwilioMessages = async () => {
      const ep = `${process.env.REACT_APP_API}/partners/connections/twilio-messages`;
      const results = await axios.post(ep);
      if (!results.data.success) {
        toast.error(results.data.message);
        return;
      }
      return results.data.data;
    };

    const processMessageThreads = (msgThreads) => {
      if (!msgThreads) return;

      // This is get a list of custom SMS messages from a group of threads
      const messages = {}
      msgThreads.forEach(thread => {
        if (thread.listingId === null && thread.listingTable === null) {
          if (!messages[thread.to]) {
            messages[thread.to] = [
              {
                message: thread.latestMessageSubject,
                date: new Date(thread.latestMessageDate)
              }
            ];
          } else {
            messages[thread.to].push({
              message: thread.latestMessageSubject,
              date: new Date(thread.latestMessageDate),
            });
          }
        }
      });

      // Remove duplicate entries from message threads
      const filter1 = msgThreads.filter((v, i, a) =>
        !(v.listingTable === null && v.listingId === null) ? (
          a.findIndex(v2 =>
            ["listingTable", "listingId"].every(k =>
              v[k] === v2[k]
            )
          ) === i
        ) : (
          a.findIndex(v2 =>
            v['to'][0] === v2['to'][0]
          ) === i
        )
      );

      msgThreads = filter1.map((t, index) => {
        return {
          ...t,
          uid: index,
          threadTitle: t.listingProductName,
          latestMessageDate: new Date(t.latestMessageDate),
          photo: (t.latestMessageIsEmail ? Letter : SMS),
          smsMessages: messages[t.to],  // Put in the custom SMS messages
        }
      });

      return msgThreads;
    };

    const processTwilioMessages = (twilioMessages, userIdsSet) => {
      if (!twilioMessages || !twilioMessages.length)
        return [];

      //loop through all messages
      twilioMessages.forEach(msg => {
        if (msg.dsUserId) userIdsSet.add(msg.dsUserId);
      });

      //first, group by the other party's number. Other party is the one that is not our partner.
      //NOTE: old way is to group by phone#. New way should be to group by userId, shouldn't it?
      const groupedByUserIdOrPhone = _.groupBy(twilioMessages, e => (e.dsUserId || getOtherPhone(e)));

      //Now some additional processing and some filtering to build an array of userId/phone to twilio-messages.
      const groupedMessages = Object.entries(groupedByUserIdOrPhone)
        .flatMap(([userIdOrPhone, messages]) => {
          // Remove any groups without any inbound messages (Moh's request).
          // It usually means a broadcast was sent out to many people, and no one-to-one dialog took place after.
          if (!messages.find(message => message.direction === "inbound"))
            return [];

          //threads are sorted oldest to newest...
          messages = messages.sort((a, b) => (new Date(a.dateCreated) - new Date(b.dateCreated)));
          const mostRecentMsg = messages[messages.length - 1];
          return [{
            uid: userIdOrPhone + "#twilio",
            latestMessageSubject: mostRecentMsg.body,
            latestMessageDate: new Date(mostRecentMsg.dateCreated),
            messages: messages,
            dsUserId: mostRecentMsg.dsUserId,
            phone: getOtherPhone(mostRecentMsg),  //this just picks the from-number if inbound, and to-number if outbound.
            isTwilioThread: true,
          }];
        });

      return groupedMessages;
    };

    const mergeTwilioThreadsWithUserProfiles = (twilioThreads, userInfoLT) => {
      twilioThreads.forEach(t => {
        //        const userProfile = findUserData( {to:t.phone, from:t.phone}, userProfiles );
        const userProfile = (t.dsUserId) ? userInfoLT[t.dsUserId] : null;
        t.user = userProfile;                        //won't bother cloning!
        t.userName = (userProfile && userProfile.displayName) || "unknown";
        t.threadTitle = ((userProfile && userProfile.displayName) || "unknown")
          + " / " + formatPhoneNumber(t.phone);
        t.photo = ((userProfile && userProfile.photo)
          ? `${process.env.REACT_APP_DS_FILES_S3}/${userProfile.photo}`
          : ParentMessage);
      });
    }

    const fetchData = async () => {
      setLoading(true);

      const userIdsSet = new Set();
      // Get all message threads - ie. everything from the "partner_messages" table.
      const msgThreadsRaw = await fetchMessageThreads();
      console.log("FETCHED MESSAGE THREADS:", msgThreadsRaw);
      const msgThreads = processMessageThreads(msgThreadsRaw);
      console.log("PROCESSED MESSAGE THREADS:", msgThreads);

      // Get all Twilio messages for this partner - no threads.
      const twilioMessages = await fetchTwilioMessages() || [];
      console.log("FETCHED TWILIO MESSAGES:", twilioMessages);

      // Now process those messages and make threads out of them.
      const twilioThreads = processTwilioMessages(twilioMessages, userIdsSet);
      console.log("PROCESSED TWILIO MESSAGES:", twilioThreads);

      // Gather user information using phones (mostly for profile picture).
      const userInfoLT = await fetchUserProfiles(userIdsSet);
      console.log("FETCHED USER INFO:", userInfoLT);

      // Full Join  Twilio threads  and User profiles  using phone#. 
      mergeTwilioThreadsWithUserProfiles(twilioThreads, userInfoLT);
      console.log("MERGED TWILIO THREADS WITH USER INFO:", twilioThreads);

      /*========================== START OF CODE THAT NEEDS REVISING! ==========================*/
      const messageThreads = [];
      // Fixed to only show custom number SMS under "Topic Threads" once.
      msgThreads.forEach(e => {
        if (e.threadTitle === null && twilioMessages.length !== 0) {
          twilioMessages.forEach(c => {
            const messageDate = (new Date(e.latestMessageDate)).setMilliseconds(0);
            const twilioDate = (new Date(c.dateCreated)).setMilliseconds(0);
            // Margin of error between times 
            if (messageDate - twilioDate <= 1000 && messageDate - twilioDate >= -1000) {
              const from = c.from;
              const to = c.to;
              const body = c.body;
              // Reverse from and to as we are checking if partner received a reply.
              const receivedReply = twilioMessages.filter(d => d.to === from && d.from === to && d.direction === "inbound");
              if (receivedReply.length === 0) {
                e.latestMessageSubject = body;
                e.photo = ParentMessage;
                messageThreads.push(e);
              }
              // If partner receives a reply, the twilio thread will show instead.
            }
          });
        } else {
          // Regular emails or SMS sent to specific programs are pushed normally.
          messageThreads.push(e);
        }
      });
      /*========================== END OF CODE THAT NEEDS REVISING! ==========================*/
      //threads are sorted newest to oldest...
      const all = [...messageThreads, ...twilioThreads].sort((a, b) => {
        return b.latestMessageDate - a.latestMessageDate
      });
      setMessageThreads(all);

      if (userInfoLT) {
        setUserInfoLT(userInfoLT);
      }

      console.log("ALL MESSAGE THREADS:", all);

      setLoading(false);
    };

    fetchData();
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Communications", to: "/communications" }
    ]);

    /*
    Generally a fairly bad idea to do and should instead use some sort of listener to improve
    */
    // update messages every 5 seconds
    const intervalID = setInterval(async () => {
      console.log("Offline:", window.navigator.onLine);
      if (!window.navigator.onLine)
        return;

      const userIdsSet = new Set();
      // Get all message threads - ie. everything from the "partner_messages" table.
      const msgThreadsRaw = await fetchMessageThreads();
      const msgThreads = processMessageThreads(msgThreadsRaw);

      // Get all Twilio messages for this partner - no threads.
      const twilioMessages = await fetchTwilioMessages() || [];

      // Now process those messages and make threads out of them.
      const twilioThreads = processTwilioMessages(twilioMessages, userIdsSet);

      // Gather user information using phones (mostly for profile picture).
      const userInfoLT = await fetchUserProfiles(userIdsSet);

      // Full Join  Twilio threads  and User profiles  using phone#. 
      mergeTwilioThreadsWithUserProfiles(twilioThreads, userInfoLT);

      /*========================== START OF CODE THAT NEEDS REVISING! ==========================*/
      const messageThreads = [];
      // Fixed to only show custom number SMS under "Topic Threads" once.
      msgThreads.forEach(e => {
        if (e.threadTitle === null && twilioMessages.length !== 0) {
          twilioMessages.forEach(c => {
            const messageDate = (new Date(e.latestMessageDate)).setMilliseconds(0);
            const twilioDate = (new Date(c.dateCreated)).setMilliseconds(0);
            // Margin of error between times 
            if (messageDate - twilioDate <= 1000 && messageDate - twilioDate >= -1000) {
              const from = c.from;
              const to = c.to;
              const body = c.body;
              // Reverse from and to as we are checking if partner received a reply.
              const receivedReply = twilioMessages.filter(d => d.to === from && d.from === to && d.direction === "inbound");
              if (receivedReply.length === 0) {
                e.latestMessageSubject = body;
                e.photo = ParentMessage;
                messageThreads.push(e);
              }
              // If partner receives a reply, the twilio thread will show instead.
            }
          });
        } else {
          // Regular emails or SMS sent to specific programs are pushed normally.
          messageThreads.push(e);
        }
      });
      /*========================== END OF CODE THAT NEEDS REVISING! ==========================*/
      //threads are sorted newest to oldest...
      const all = [...messageThreads, ...twilioThreads].sort((a, b) => {
        return b.latestMessageDate - a.latestMessageDate
      });
      setMessageThreads(all);

      if (userInfoLT) {
        setUserInfoLT(userInfoLT);
      }
    }, 5000)
    return () => {
      // undo the interval
      clearInterval(intervalID);
    }
  }, [refetch]);

  useEffect(() => {
    if (selectedThread) {
      const updatedThread = messageThreads.find(thread => thread?.threadTitle == selectedThread?.threadTitle)
      setSelectedThreadIntercept(updatedThread);
    }
  }, [messageThreads])


  /**
   * Used by the SMS Chat view.
   * Just inserts the newly sent SMS message into our own in-memory message cache, and trigger appropriate React updates.
   */
  const insertMessageHandler = text => {
    const messageDate = new Date();
    setSelectedThread(prevSelectedThread => {
      prevSelectedThread.messages.push({
        body: text,
        dateCreated: messageDate,
        direction: "outbound-api",
        dsUserId: prevSelectedThread.dsUserId,
        //from: "+1416....",
        //status: "delivered",
        //to: "+1416....",
      });
      return {
        ...prevSelectedThread,
        latestMessageDate: messageDate,
        latestMessageSubject: text,
      };
    });
  };


  /**
   * This intercept was to allow messages to be downloaded in the background,
   * and then to call the React hook "setSelectedThread()".
   */
  const setSelectedThreadIntercept = (thread) => {
    if (thread && thread.listingId  //<-- if a thread is selected, and it's one of our internal ones (not Twilio)
      && !thread.messages   //<-- and the messages have not been previously downloaded!
      /*|| thread.lastDownloaded > 5mins ago*/
    ) {
      fetchSingleThreadData(thread)
        .then(threadData => {
          let dsUserIds = new Set();
          //#1 - assume messages are already sorted when sent from server!
          //#2 - do some early processing: isCollapsed=true for all except last message in thread!
          threadData.forEach((msg, idx, arr) => {
            msg.isCollapsed = (idx < arr.length - 1);
            msg.to.forEach(to => {
              if (to.dsUserId) dsUserIds.add(to.dsUserId);  //this builds a list of userIds whose profiles we have to fetch.
            });
          });
          thread.messages = threadData;
          setSelectedThread(thread);

          //Now filter the userIds we have to fetch from the profiles we already have in "userInfoLT".
          const userIdsToFetch = Array.from(dsUserIds)
            .filter(dsUserId => (!userInfoLT[dsUserId]));
          console.info("userIdsToFetch:", userIdsToFetch);
          return fetchUserProfiles(userIdsToFetch);
        })
        .then(userInfoLT => {
          //console.log("userInfoLT:",userInfoLT);
          setUserInfoLT(prevUserInfoLT => {
            return { ...prevUserInfoLT, ...userInfoLT };
          });
        })
        .catch(err => {
          console.error(err);
        })
    }
    else {   //else, the thread's "messages" property is already pre-populated.
      setSelectedThread(thread);
    }

  };

  const setLeftPaneOptions = (something) => {
  }

  /* =================================================================================================== */

  const threadViewHeaderTitle = (thread) => {
    if (!thread || !(thread.listingProductName || thread.isTwilioThread)) {
      return `Messages`;
    }
    else if (thread.isTwilioThread) {
      return thread.threadTitle;
    }
    else /* if regular thread for a program/event/virtual/etc */ {
      const startingDateStr = !thread.listingProductDate
        ? "---null---"   //<-- if anyone reports this, it might mean server is sending bad data!
        : format(new Date(thread.listingProductDate), "PP")
      return [
        thread.listingProductName,
        `(starting ${startingDateStr})`
      ];
    }
  }
  const threadViewHeader = (
    <MessageViewHeader
      isSmallScreen={isSmallScreen}
      onBack={() => { console.log("clicking the back button!?"); setSelectedThread(undefined) }}
      onRefresh={() => { console.log("clicking the refresh button!?"); setRefetch(Math.random()); }}
      text={threadViewHeaderTitle(selectedThread)}
    />
  );


  const useChatView = selectedThread && selectedThread.isTwilioThread;
  return (
    <div className="container-fluid adminprofiles">
      <div className="row cont" style={{ alignItems: "center" }}>
        <div style={{ marginLeft: "15px" }}>
          <h1>Communications</h1>
        </div>
        <div
          className="forbtnwrap justify-end"
          style={{ flexGrow: "1", width: "auto", margin: "0 20px" }}
        >
          <div className="forbtnapp">
            <button
              className="newapplications"
              onClick={() => {
                props.history.push("/partners/send-message");
              }}
            >
              New Message
            </button>
          </div>
        </div>
      </div>
      <div className="cont">
        {isSmallScreen ? (
          // Mobile Layout
          <div className="col">
            {(selectedThread !== null && selectedThread !== undefined) ? (
              /* small screen - when message is selected, just show the messages or chat view, but not the thread list! */
              <div className="box comm-right" style={{ marginRight: "0" }}>
                <div className="bgofbox messagecenter">
                  {threadViewHeader}
                  {!useChatView && (
                    <MessageThreadView
                      messageThread={selectedThread}
                      userInfoLT={userInfoLT}
                    />
                  )}
                  {useChatView && (
                    <Chat
                      messageThread={selectedThread}
                      userInfoLT={userInfoLT}
                      setRefetch={setRefetch}
                      insertMessage={insertMessageHandler}
                    />
                  )}
                </div>
              </div>
            ) : (
              /* small screen - when message not selected, just show the thread list! */
              <MessageTable
                messageThreads={messageThreads}
                setSelectedThread={setSelectedThreadIntercept}
                selectedThread={selectedThread}
                setLeftPaneOptions={setLeftPaneOptions}
              />
            )}
          </div>
        ) : (
          // Main Layout
          <div className="row flex-nowrap">
            <div
              className="col"
              style={{ paddingRight: "0.5rem", maxWidth: "400px" }}
            >
              <MessageTable
                messageThreads={messageThreads}
                setSelectedThread={setSelectedThreadIntercept}
                selectedThread={selectedThread}
                setLeftPaneOptions={setLeftPaneOptions}
              />
            </div>
            <div
              className="col"
              style={{ marginLeft: "0", paddingLeft: "0.5rem" }}
            >
              <div className="box comm-right">
                <div className="bgofbox messagecenter">
                  {threadViewHeader}
                  {!useChatView && (
                    <MessageThreadView
                      messageThread={selectedThread}
                      userInfoLT={userInfoLT}
                    />
                  )}
                  {useChatView && (
                    <Chat
                      messageThread={selectedThread}
                      userInfoLT={userInfoLT}
                      setRefetch={setRefetch}
                      insertMessage={insertMessageHandler}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Communications;
