import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";
import TaxRateModal from "../../../../components/modals/TaxRateModal";
import ReactTooltip from "react-tooltip";
import axios from "axios";

class SectionNine extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { openTaxRateModal: false, taxCode: "", isEditTaxCode: false };
  }

  componentDidMount() {
    this.setState({ taxCode: this.global.organizationInfo.tax_code });
    console.log("this.global.organizationInfo", this.global.organizationInfo);
  }

  handleChange = (e, v) => {
    this.setGlobal({
      organizationInfo: {
        ...this.global.organizationInfo,
        [e]: v
      }
    });
  };

  customForm = () => {
    return (
      <div className="formbody">
        <div style={{ marginLeft: "35px" }}>
          <h3 className="label-names">Tax Code</h3>
          {!this.state.isEditTaxCode ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "60px",
                justifyContent: "space-between"
              }}
            >
              {this.global.organizationInfo.tax_code &&
              this.global.organizationInfo.tax_code !== "" ? (
                <p style={{ margin: "4px 0 6px 0", fontSize: "14px" }}>
                  {() => {
                    this.setState({
                      taxCode: this.global.organizationInfo.tax_code
                    });
                    return null;
                  }}
                  {this.global.organizationInfo.tax_code}
                </p>
              ) : (
                <p style={{ margin: "4px 0 6px 0", fontSize: "14px" }}>
                  {() => {
                    this.setState({
                      taxCode: this.global.organizationInfo.tax_code
                    });
                    return null;
                  }}
                  No Tax Code {this.global.organizationInfo.tax_code}
                </p>
              )}
              <button
                onClick={() => {
                  this.setState({ isEditTaxCode: true });
                }}
                style={{
                  height: "26px",
                  width: "60px",
                  backgroundColor: "#00A7E1",
                  color: "white",
                  border: "none",
                  fontSize: "13px",
                  borderRadius: "14px",
                  paddingTop: "2px",
                  boxShadow: "2px 2px 9px 0px rgba(0,0,0,0.2)"
                }}
              >
                Edit
              </button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "60px",
                justifyContent: "space-between"
              }}
            >
              <input
                onChange={e => this.setState({ taxCode: e.target.value })}
                value={this.state.taxCode}
                style={{ width: "200px" }}
              />
              <div>
                <button
                  style={{
                    height: "26px",
                    width: "60px",
                    backgroundColor: "rgb(27, 200, 138)",
                    color: "white",
                    border: "none",
                    fontSize: "13px",
                    borderRadius: "14px",
                    paddingTop: "2px",
                    boxShadow: "2px 2px 9px 0px rgba(0,0,0,0.2)"
                  }}
                  onClick={async () => {
                    this.setState({ isEditTaxCode: false });
                    // save the tax code to global
                    this.setGlobal({
                      organizationInfo: {
                        ...this.global.organizationInfo,
                        tax_code: this.state.taxCode
                      }
                    });

                    // save the tax code to database
                    try {
                      const ep = `${process.env.REACT_APP_API}/partners/stripe/taxCode`;
                      const response = await axios.post(ep, {
                        taxCode: this.state.taxCode
                      });
                      console.log(response);
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                >
                  Save
                </button>
                <button
                  style={{
                    height: "26px",
                    width: "60px",
                    backgroundColor: "#ff3b8e",
                    color: "white",
                    border: "none",
                    fontSize: "13px",
                    borderRadius: "14px",
                    marginLeft: "14px",
                    paddingTop: "2px",
                    boxShadow: "2px 2px 9px 0px rgba(0,0,0,0.2)"
                  }}
                  onClick={() => {
                    this.setState({ isEditTaxCode: false });
                    this.setState({
                      taxCode: this.global.organizationInfo.tax_code
                    });
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
        <form>
          <div className="row" style={{ marginLeft: "5px" }}>
            <div className="col-3">
              <h3 className="label-names">
                Add Tax Rate{" "}
                <i
                  data-tip
                  data-for="tax_rate"
                  className="fas fa-info-circle"
                  style={{ color: "#DCDCDC" }}
                ></i>
                <ReactTooltip
                  id="tax_rate"
                  place="right"
                  effect="solid"
                  type="info"
                >
                  A bank account must be linked in order to add tax rates
                </ReactTooltip>
              </h3>
            </div>
            <div className="col-9 d-flex">
              <button
                className="newapplications button-tags"
                type="button"
                onClick={_ => this.setState({ openTaxRateModal: true })}
                disabled={this.global.organizationInfo.stripeId === null}
              >
                Add
              </button>
            </div>
          </div>

          {this.global.organizationInfo.stripeTaxId.length > 0 ? (
            <table className="tableticket" style={{ marginLeft: "25px" }}>
              <thead>
                <tr>
                  <th
                    style={{ padding: "10px" }}
                    className="input-fields-no-border"
                  >
                    Tax Name
                  </th>
                  <th
                    style={{ padding: "10px" }}
                    className="input-fields-no-border"
                  >
                    Percentage
                  </th>
                  <th
                    style={{ padding: "10px" }}
                    className="input-fields-no-border"
                  >
                    Jurisdiction
                  </th>
                  <th
                    style={{ padding: "10px" }}
                    className="input-fields-no-border"
                  >
                    Description
                  </th>
                </tr>
              </thead>
              {this.global.organizationInfo.stripeTaxId.map((ele, key) => {
                const e = typeof ele === "string" ? JSON.parse(ele) : ele;

                return (
                  <tbody key={key}>
                    <tr>
                      <td style={{ padding: "10px" }}>
                        <span name="display_name">{e.display_name}</span>
                      </td>
                      <td style={{ padding: "10px" }}>
                        <span name="percentage">{e.percentage}%</span>
                      </td>
                      <td style={{ padding: "10px" }}>
                        <span name="jurisdiction">{e.jurisdiction}</span>
                      </td>
                      <td style={{ padding: "10px" }}>
                        <span name="description">{e.description}</span>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          ) : (
            <p style={{ marginTop: "10px", textAlign: "center" }}>
              No Tax Rates Added!
            </p>
          )}
        </form>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.openTaxRateModal && (
          <TaxRateModal
            onClose={_ => this.setState({ openTaxRateModal: false })}
          />
        )}
        <BoxForm
          handleChange={this.handleChange}
          name={"Tax Rate Allotment"}
          customForm={this.customForm()}
        />
      </>
    );
  }
}

export default SectionNine;
