import React from "reactn";
import "../../../../assets/css/componentSpecificCss/invoiceBuilder.css";
import DatePicker from "react-datepicker";

class InvoiceDatePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // console.log("this.props", this.props);
    // console.log("this.state", this.state);

    return (
      <div className="date-section">
        <div className="date-item-list">
          <div className="invoice-date">Issue Date</div>
          <DatePicker
            selected={this.props.invoiceDate}
            onChange={date => {
              this.props.invoiceDateChange(date);
            }}
            minDate={new Date()}
            showYearDropdown
            showMonthDropdown
            dateFormat="MMMM d, yyyy"
            className="invoice-control"
            placeholderText="Invoice Date"
            name="date"
            autoComplete="off"
            disabled={(this.props.invoiceStatus === "Cancelled" ||
              this.props.invoiceStatus === "Issued" ||
              this.props.invoiceStatus === "Paid Offline")}
          />
        </div>

        <div className="date-item-list">
          <div className="invoice-date-range">Service Period</div>
          <DatePicker
            selectsRange
            selected={this.props.serviceStartDate}
            startDate={this.props.serviceStartDate}
            endDate={this.props.serviceEndDate}
            onChange={dates => {
              const [start, end] = dates;
              this.props.serviceStartDateChange(start);
              this.props.serviceEndDateChange(end);
            }}
            minDate={new Date()}
            showYearDropdown
            showMonthDropdown
            // dateFormat="MMMM d, yyyy"
            className="invoice-control"
            placeholderText="Service Period"
            disabled={(this.props.invoiceStatus === "Cancelled" ||
              this.props.invoiceStatus === "Issued" ||
              this.props.invoiceStatus === "Paid Offline")}
          />
        </div>

        <div className="date-item-list">
          <div className="invoice-due-date">Due Date</div>
          <DatePicker
            selected={this.props.invoiceDueDate}
            onChange={date => {
              this.props.invoiceDueDateChange(date);
            }}
            minDate={new Date()}
            showYearDropdown
            showMonthDropdown
            dateFormat="MMMM d, yyyy"
            className="invoice-control"
            placeholderText="Due Date"
            name="date"
            autoComplete="off"
            disabled={(this.props.invoiceStatus === "Cancelled" ||
              this.props.invoiceStatus === "Issued" ||
              this.props.invoiceStatus === "Paid Offline")}
          />
        </div>
      </div>
    );
  }
}

export default InvoiceDatePicker;
