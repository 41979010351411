import React from "reactn";

import MessageView from "./MessageView";

import "react-lazy-load-image-component/src/effects/opacity.css";
import CustomSMSChat from "./CustomSMSChat";

/**
 * Displays SMS or Emails sent to a given group.
 *
 * @param {Object} messageThread Message group object, containing an array multiple messages.
 * @param {Object} userInfoLT a dictionary of id to user-profile mappings, eg. key=1234 => value=user data for user#1234.
 */
const MessageThreadView = ({ messageThread, userInfoLT }) => {
  const ascendingOrder = (a,b) => (new Date(a.createdAt) - new Date(b.createdAt));

  let elementForPendingScrollIntoViewOp = null;    //don't worry about name length. Minifier should take care of that!
  const callbackForScrollIntoViewOp = (el) => { if (el) { elementForPendingScrollIntoViewOp=el; } }

  const refCallback = (el) => {
    //console.log("========== refCallback():", el, el?.scrollTop, el?.childNodes?.[0].clientHeight );
    //console.log("       \\==",mt?.threadTitle,"=",mt?.scrollTop);
    if (el) {
      if (elementForPendingScrollIntoViewOp) {
        elementForPendingScrollIntoViewOp.scrollIntoView();
        elementForPendingScrollIntoViewOp=null;
      } else {
        el.scrollTop = messageThread?.scrollTop || 0;
        //console.log("       \\==  ...and now scrolled to:",mt?.scrollTop);
      }
    }
  }

  const onScrollHandler = (evt) => {
    //const t=evt.target;
    const mt = messageThread;
    //const prev=mt?.scrollTop;
    if (mt) mt.scrollTop = evt.target.scrollTop;
    //console.log("onscroll() event:",mt?.threadTitle,`prev=${prev}, now=${t.scrollTop}`);
    //messageThread.scrollTop=t.scrollTop;
  }

  return (
    <div className="message-thread-view"
      style={{height:"calc(100vh - 215px)"}}
      onScroll={onScrollHandler}
      ref={refCallback}
    >
      {!messageThread ? (
        <p style={{ marginTop: "5px", textAlign: "center" }}>
          No message thread selected!
        </p>
      ) : messageThread?.messages === undefined ? (  // When the SMS doesn't come from Twilio, but rather internal database
        <CustomSMSChat
          messages={messageThread.smsMessages}
        />
      )
      : (
        <div>
          {messageThread.messages.sort(ascendingOrder)
            .map((message, index) => {

              //See notes above for "callbackForScrollIntoViewOp".
              //condition is true when messageThread is fresh (ie. never scrolled, so scrollTop=undefined),
              //...and when the message (<MessageView>) is supposed to be expanded - typically for last message!
              const onRefCallback =
                ((messageThread.scrollTop===undefined || messageThread.scrollTop===null) && !message.isCollapsed)
                  ? callbackForScrollIntoViewOp
                  : null;

              return (
                <MessageView
                  key={`${index}/${messageThread.uid}`}
                  message={message}
                  userInfoLT={userInfoLT}
                  onRefCallback={onRefCallback}
                />
              );
            }
          )}
        </div>
      )}
    </div>
  );
};

export default MessageThreadView;
