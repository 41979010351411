import React from "reactn";
import BaseModal from "./baseModal/BaseModal";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { CONTENT_BLOCK } from "./baseModal/FieldTypes";
import { addDays, subMonths , format, subDays } from "date-fns";

const AutoPayViewModal = ({ onClose, editDate, selectedItem, history }) => {
  console.log("props", { selectedItem, editDate });
  const getBaseModalFields = () => {
    const program = {
      type: CONTENT_BLOCK,
      data: {
        name: `Program`,
        content: selectedItem.item
      }
    };
    const customer = {
      type: CONTENT_BLOCK,
      data: {
        name: `Customer`,
        content: selectedItem.purchaser
      }
    };
    let issueDate = new Date(selectedItem.issue_date);
    console.log("SELECTED ITEM", selectedItem);

    const issueDateField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Issue Date",
        content: `Invoice will be issued on ${format(issueDate, "LLL dd, yyyy")}`
      }
    };
    console.log(selectedItem);

    const nextInvoiceDateField = () => {
      return (
        {
          type: CONTENT_BLOCK,
          data: {
            name: "Next Invoice",
            content: `Next invoice will be ${selectedItem.membership_term ? "created" : "sent"} on ${format(new Date(selectedItem.next_invoice_date), "LLL dd, yyyy")}`
          }
        }
      );

    }


    let fields = [program, customer];
    if (selectedItem.invoice_status === "Ready to Bill" && !selectedItem.stripe_transaction_id?.includes("sub")) {
      fields.push(issueDateField);
    }

    if (selectedItem.next_invoice_date) {
      fields.push(nextInvoiceDateField());
    }

    if (new Date(editDate) > new Date()) {
      const viewField = {
        type: CONTENT_BLOCK,
        data: {
          name: "",
          content: `You cannot view or edit this invoice yet. Please wait until ${format(
            editDate,
            "LLL dd, yyyy"
          )}.`
        }
      };
      const linkField = {
        type: CONTENT_BLOCK,
        data: {
          name: "",
          content: `If you need to edit this invoice, please click here.`,
          handleClick: () => {
            window.open(
              `${process.env.REACT_APP_REDIRECT_URI}/partners/recurring/upcoming/${selectedItem.stripe_transaction_id.replace(/sub_/, "")}`,
              "_blank"
            );
          },
          textStyles: {
            cursor: "pointer",
          }
        }
      }

      fields.push(viewField);
      fields.push(linkField);
    } else {
      const otherDetails = {
        type: CONTENT_BLOCK,
        data: {
          name: "Updated Amount",
          content: `$${(selectedItem.total / 100).toFixed(2)}`
        }
      };
      fields.push(otherDetails);
    }
    return fields;
  };

  const getBaseModalButtons = () => {
    const closeButton = {
      name: "Close",
      handleClick: onClose,
      buttonColour: "#3B4EFF",
      textColour: "#fff"
    };
    return [closeButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Invoice Overview",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: onClose
    };
  };

  return <BaseModal {...getBaseModalProps()} />;
};

export default AutoPayViewModal;
