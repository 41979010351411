import React from "reactn";
import axios from "axios";
import { Table } from "../../components/UI";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import ComModal from "../../components/table/admins/EduCommentModal";
class ProgramOperations extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: ""
    };
    this.headings = [
      { id: "fullName", label: "Educator" },
      { id: "programName", label: "Program Name" },
      {
        id: "checkList",
        label: "Checklist Completion",
        customCell: this.checkListCell
      },
      {
        id: "tourcounts",
        label: "# of Tour Requests"
      },
      {
        id: "plancounts",
        label: "# of Plan Requests"
      },
      {
        id: "appcounts",
        label: "# of Applications"
      },

      {
        id: "availability",
        label: "Tour Availability Set",
        customCell: this.tourAvailCell
      },
      { id: "action", label: "", customStyle: { width: 80 } }
    ];

    this.actions = [
      { name: "View", action: this.viewProgram },
      { name: "Notes", action: r => this.viewComments(r) }
    ];

    this.viewProgram = this.viewProgram.bind(this);
    this.filterApplications = this.filterApplications.bind(this);
  }

  checkListCell = (r, i) => {
    return (
      <td key={`${i}-clc`}>
        {r.checklistCount || 0} / {r.checklistTotal || 0}
      </td>
    );
  };
  viewComments = async row => {
    this.setState({ commentsModalShown: true, modalData: row });
  };

  tourAvailCell = (r, i) => {
    return (
      <td key={`${i}-tour`}>
        <div
          className={
            r.availability && r.availability.length
              ? "green-circle"
              : "red-circle"
          }
        ></div>
      </td>
    );
  };

  filterApplications = () => {
    return this.state.data.filter(
      d =>
        d.fullName.toLocaleLowerCase().includes(this.state.filter) &&
        d.programPublished
    );
  };

  viewProgram = async r => {
    // this.props.history.push(`/admin/program-settings/setup/${r.dsUserId}`);
    window.open(`/admin/program-operations/${r.dsUserId}`);
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/educator/program`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setState({
        data: res.data.data.filter(e => e.programPublished)
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    return (
      <div className="admin">
        <div className="container-fluid programsettings">
          <div className="cont">
            <h1>Program Operations</h1>
          </div>
          <ComModal
            open={this.state.commentsModalShown}
            data={this.state.modalData}
            onClose={() =>
              this.setState({
                commentsModalShown: false,
                modalData: null
              })
            }
          />

          <div className="cont">
            {/* <div className="row">
            <div className="search">
              <div className="input">
                <ProgramSearchInput placeholder="Search by name" />
              </div>
            </div>
          </div> */}
            <input
              type="text"
              onChange={e =>
                this.setState({ filter: e.target.value.trim().toLowerCase() })
              }
              style={{
                marginBottom: 15,
                width: "100%",
                borderRadius: 5,
                border: "2px solid #E3E6F0",
                fontSize: "13px",
                padding: 3,
                outline: 0
              }}
              placeholder="Filter by Educator Name or Program Name..."
            />
            <div className="tablecont">
              <div className="table-responsive">
                <Table
                  headings={this.headings}
                  data={this.filterApplications()}
                  actions={this.actions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProgramOperations;
