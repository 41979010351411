import React from "reactn";
import axios from "axios";
import { format } from "date-fns";
import CertificationsModal from "../../../components/modals/CertificationsModal";
import EditCertificationsModal from "../../../components/modals/EditCertificationsModal";
import { useMediaQuery, withStyles } from "@material-ui/core";
import "../../../assets/css/componentSpecificCss/userDetails.css";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import Dropdown from "rc-dropdown";

const styles = {
  iconButton: {
    backgroundColor: "transparent !important",
    padding: "1em !important",
    color: "rgba(0, 0, 0, 0.54) !important",
    "&:focus": {
      outline: "none !important"
    }
  },
  avatar: {
    width: "50px",
    height: "50px"
  },
  item: {
    padding: "5px 10px",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "13px"
  },
  list: {
    height: "60px",
    padding: "0px"
  },
  tabRoot: {
    color: "#61616F !important",
    fontFamily: "Open Sans,sans-serif !important",
    fontWeight: "600 !important",
    fontSize: "1rem !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "#61616F !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "#61616F !important"
    }
  },
  tabSelected: {
    color: "black !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "black !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "black !important"
    }
  },
  tabIndicator: {
    backgroundColor: "#61616F",
    height: "3px",
    marginTop: "20px"
  }
};

const Emergency = [
  { label: "Name", type: "input" },
  { label: "Email Address", type: "input" },
  { label: "Phone", type: "input" },
  { label: "Address", type: "input" },
  { label: "City", type: "input" },
  { label: "Province", type: "input" },
  { label: "Postal Code", type: "input" },
  { label: "Country", type: "input" }
];

const Other = [{ label: "Certifications", type: "input" }];

class ProfileDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      subActive: "Individual",
      canEdit: false,
      openCertificateModal: false,
      emergency: [
        {
          name: "",
          email: "",
          phone: "",
          address: "",
          city: "",
          province: "",
          postalcode: "",
          country: ""
        }
      ]
    };
  }

  componentDidMount() {
    this.setState({
      ...this.props.data,
      emergency:
        !this.props.data.emergency || false ||
        !this.props.data.emergency[0]
          ? [
              {
                name: "",
                email: "",
                phone: "",
                address: "",
                city: "",
                province: "",
                postalcode: "",
                country: ""
              }
            ]
          : this.props.data.emergency.map(e => JSON.parse(e))
    });
  }

  updateIndividualData = async () => {
    const data = {
      partner_login_id: this.state.id,
      phone: this.state.phone,
      address: this.state.address,
      city: this.state.city,
      province: this.state.province,
      postalcode: this.state.postalcode,
      country: this.state.country,
      birthDate: this.state.birthDate,
      emergency: this.state.emergency
    };
    const ep = `${process.env.REACT_APP_API}/partners/users/update-individual/${this.props.match.params.id}`;
    const results = await axios.post(ep, data);

    if (results.data.success) {
      this.setState({
        data: results.data.data,
        canEdit: false
      });
    }
  };

  // Certifications button select options
  onSelect = async (element, planKey, event) => {
    if (event.key === "edit") {
      this.setState({
        openEditCertificateModal: true,
        selectedCertification: planKey
      });
    } else if (event.key === "view") {
      element.file &&
        window.open(
          `${process.env.REACT_APP_DS_FILES_S3 + "/" + element.file}`
        );
    } else {
      const newCert = this.state.certifications;
      newCert.splice(planKey, 1);

      const data = {
        certifications: newCert
      };
      const ep = `${process.env.REACT_APP_API}/partners/users/update-user-info/${this.props.match.params.id}`;
      const results = await axios.post(ep, data);

      if (results.data.success) {
        this.setState({
          certifications: results.data.data.certifications
        });
        this.props.update();
      }
    }
  };

  menu = (element, planId) => {
    return (
      <Menu onClick={event => this.onSelect(element, planId, event)}>
        <MenuItem key="edit">Edit</MenuItem>
        <MenuItem key="view">View</MenuItem>
        <MenuItem key="delete">Delete</MenuItem>
      </Menu>
    );
  };

  render() {
    return (
      <div className="admin">
        {this.state.openCertificateModal && (
          <CertificationsModal
            onClose={_ => this.setState({ openCertificateModal: false })}
            profile={this.state}
            update={e => {
              this.setState({ certifications: e });
              this.props.update();
            }}
          />
        )}
        {this.state.openEditCertificateModal && (
          <EditCertificationsModal
            onClose={_ => this.setState({ openEditCertificateModal: false })}
            certifications={this.state.certifications}
            selectedCertification={this.state.selectedCertification}
            update={e => {
              this.setState({ certifications: e });
              this.props.update();
            }}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="user-box">
            <div className="user-nav-line">
              <div className="left-box">
                <i
                  className="fas fa-pencil-alt pencil-style"
                  onClick={_ => this.setState({ canEdit: !this.state.canEdit })}
                ></i>
              </div>
              <div className="right-box">
                <span
                  className={`user-sub-header ${
                    this.state.subActive === "Individual" &&
                    "active-user-sub-header"
                  }`}
                  onClick={_ => this.setState({ subActive: "Individual" })}
                >
                  Individual
                </span>
                <span
                  className={`user-sub-header ${
                    this.state.subActive === "Emergency" &&
                    "active-user-sub-header"
                  }`}
                  onClick={_ => this.setState({ subActive: "Emergency" })}
                >
                  Emergency
                </span>
                <span
                  className={`user-sub-header ${
                    this.state.subActive === "Other" && "active-user-sub-header"
                  }`}
                  onClick={_ => this.setState({ subActive: "Other" })}
                >
                  Other
                </span>
              </div>
            </div>

            {this.state.subActive === "Individual" && (
              <div className="individual-info">
                <div className="user-info-section">
                  <div className="user-row">
                    <label className="user-label">Name</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.username}
                        disabled={true}
                        onChange={e =>
                          this.setState({ username: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Email</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.email}
                        disabled={true}
                        onChange={e => this.setState({ email: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Cell Phone</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.phone}
                        disabled={!this.state.canEdit}
                        onChange={e => this.setState({ phone: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Address</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.address}
                        disabled={!this.state.canEdit}
                        onChange={e =>
                          this.setState({ address: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">City</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.city}
                        disabled={!this.state.canEdit}
                        onChange={e => this.setState({ city: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Region</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.province}
                        disabled={!this.state.canEdit}
                        onChange={e =>
                          this.setState({ province: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Postal / Zip</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.postalcode}
                        disabled={!this.state.canEdit}
                        onChange={e =>
                          this.setState({ postalcode: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Country</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.country}
                        disabled={!this.state.canEdit}
                        onChange={e =>
                          this.setState({ country: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Date of Birth</label>
                    <div className="user-item-content">
                      <input
                        type="date"
                        className="user-input-field"
                        value={
                          this.state.birthDate
                            ? format(
                                new Date(this.state.birthDate),
                                "yyyy-MM-dd"
                              )
                            : new Date()
                        }
                        // value="2020-09-01"
                        disabled={!this.state.canEdit}
                        onChange={e =>
                          this.setState({ birthDate: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.subActive === "Emergency" && (
              <div className="emergency-info">
                <div className="user-info-section">
                  <div className="user-row">
                    <label className="user-label">Name</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.emergency[0].name}
                        disabled={!this.state.canEdit}
                        onChange={e =>
                          this.setState({
                            emergency: [
                              {
                                ...this.state.emergency[0],
                                name: e.target.value
                              }
                            ]
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Email</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.emergency[0].email}
                        disabled={!this.state.canEdit}
                        onChange={e =>
                          this.setState({
                            emergency: [
                              {
                                ...this.state.emergency[0],
                                email: e.target.value
                              }
                            ]
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Cell Phone</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.emergency[0].phone}
                        disabled={!this.state.canEdit}
                        onChange={e =>
                          this.setState({
                            emergency: [
                              {
                                ...this.state.emergency[0],
                                phone: e.target.value
                              }
                            ]
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Address</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.emergency[0].address}
                        disabled={!this.state.canEdit}
                        onChange={e =>
                          this.setState({
                            emergency: [
                              {
                                ...this.state.emergency[0],
                                address: e.target.value
                              }
                            ]
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">City</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.emergency[0].city}
                        disabled={!this.state.canEdit}
                        onChange={e =>
                          this.setState({
                            emergency: [
                              {
                                ...this.state.emergency[0],
                                city: e.target.value
                              }
                            ]
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Region</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.emergency[0].province}
                        disabled={!this.state.canEdit}
                        onChange={e =>
                          this.setState({
                            emergency: [
                              {
                                ...this.state.emergency[0],
                                province: e.target.value
                              }
                            ]
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Postal / Zip</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.emergency[0].postalcode}
                        disabled={!this.state.canEdit}
                        onChange={e =>
                          this.setState({
                            emergency: [
                              {
                                ...this.state.emergency[0],
                                postalcode: e.target.value
                              }
                            ]
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Country</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="user-input-field"
                        value={this.state.emergency[0].country}
                        disabled={!this.state.canEdit}
                        onChange={e =>
                          this.setState({
                            emergency: [
                              {
                                ...this.state.emergency[0],
                                country: e.target.value
                              }
                            ]
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.subActive === "Other" && (
              <div className="other-info">
                <div className="user-info-section">
                  <div className="user-certificate-title-section">
                    <span className="user-certificate-title">
                      CERTIFICATIONS:
                    </span>
                    <div
                      className="user-certificate-add-btn"
                      onClick={_ =>
                        this.setState({ openCertificateModal: true })
                      }
                    >
                      Add
                    </div>
                  </div>

                  <div className="user-certificate-table-headers">
                    <div className="user-header-boundry1">
                      <span className="user-header">Category</span>
                    </div>
                    <div className="user-header-boundry2">
                      <span className="user-header">Title</span>
                    </div>
                    <div className="user-header-boundry3">
                      <span className="user-header">Details</span>
                    </div>
                  </div>

                  {this.state.certifications &&
                    this.state.certifications.map((e, i) => {
                      const element = typeof e === "string" ? JSON.parse(e) : e;
                      return (
                        <div
                          className="user-certificate-table-data"
                          key={`${i}-data`}
                        >
                          <div className="user-data-boundry1">
                            <span className="user-data">
                              {element.other ? element.other : element.category}
                            </span>
                          </div>
                          <div className="user-data-boundry2">
                            <span className="user-data">{element.title}</span>
                          </div>
                          <div className="user-data-boundry3">
                            <span className="user-data">{element.details}</span>
                          </div>
                          <div className="user-data-boundry4">
                            <Dropdown
                              trigger={["click"]}
                              overlay={this.menu(element, element.id)}
                              animation="slide-up"
                            >
                              <button
                                style={{
                                  borderRadius: "50%",
                                  width: 25,
                                  height: 25,
                                  outline: "none",
                                  border: 0,
                                  background: "#f4f6fd",
                                  fontSize: "14px"
                                }}
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </button>
                            </Dropdown>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {this.state.canEdit && (
              <div className="user-save-row">
                <button
                  className="user-save-btn"
                  onClick={_ => this.updateIndividualData()}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ProfileDetails);
