import React from "reactn";
// import { background1, background2 } from "../../assets";
import axios from "axios";
import { format } from "date-fns";
import "../../assets/css/componentSpecificCss/eventsManage.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { utcToZonedTime } from "date-fns-tz";
import { DateTime } from "luxon";
import { InviteOverview } from "../../components/partner/InviteOverview";

class ManageEvents extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: "",
      detailsModalShown: false,
      modalData: [],
      original: {},
      totalInvites: 0,
      invitesAccepted: 0,
    };
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Manage Events", to: "/events/manage" }
      ]
    });
  }

  viewDetail = async row => {
    this.setState({ detailsModalShown: true, modalData: row });
  };
  viewComments = async row => {
    this.setState({ commentsModalShown: true, modalData: row });
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt((Date.now() - new Date(r.insys).getTime()) / (3600000 * 24))}
      </td>
    );
  };

  async componentDidMount() {
    const startTime = new Date().getTime();
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/events/list_manage`;
    const results = await axios.get(ep);

    const role = await this.filterEventsBasedUser();
    let data = results.data.data;

    if (results.data.success) {
      if (role?.eventlist?.length > 0 && !role?.eventlist?.includes(0)) {
        data = data.filter(
          e => role?.eventlist?.filter(f => f === e.id).length > 0
        );
      } else if (role?.eventlist === null || role?.eventlist?.length === 0) {
        data = [];
      }

      const published = data
        .filter(item => item.published)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .sort((a, b) => a.display_order - b.display_order);
      const unpublished = data
        .filter(item => !item.published)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

      const allEvents = published.concat(unpublished);

      this.setState({ data: allEvents });
    }

    const ep2 = `${process.env.REACT_APP_API}/partners/invitations/overview/event`;
    const results2 = await axios.get(ep2);
    if (results2.data.success) {
      this.setState({
        totalInvites: results2.data.data.totalInvites,
        invitesAccepted: results2.data.data.invitesAccepted
      });
    }

    this.setGlobal({ loading: false });
    console.log("Time Taken", new Date().getTime() - startTime);
  }

  filterEventsBasedUser = async () => {
    if (this.global?.dsUser?.sub_user && this.global?.dsUser?.role) {
      const ep = `${process.env.REACT_APP_API}/partners/users/all-details/${this.global?.dsUser?.user_id}`;
      const results = await axios.get(ep);
      console.log("results", results);
      if (results.data.success) {
        this.setState({
          role: results.data.data
        });
        return results.data.data;
      }
    }
    return {};
  };

  getTimeZone = () => {
    const tz = this.global.organizationInfo.timezone;

    return DateTime.now().setZone(tz).toFormat("ZZZZ");
  }

  render() {
    return (
      <div className="admin">
        <div
          className="container-fluid adminprofiles"
          style={{ height: "70%" }}
        >
          <div className="cont invites-title-wrapper">
            <h1>Events</h1>
            <InviteOverview
              totalInvites={this.state.totalInvites}
              invitesAccepted={this.state.invitesAccepted}
              listingType="Events"
            />
          </div>
          <div className="cont">
            {this.state.data.length > 0 ? (
              <div className="tablecont">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {this.state.data.map((e, key) => {
                    const tz = this.global.organizationInfo.timezone;
                    const date = format(
                      utcToZonedTime(e.event_start, tz),
                      "LLL dd, yyyy"
                    );
                    const time = format(
                      utcToZonedTime(e.event_start, tz),
                      "hh:mm aa"
                    );

                    return (
                      <div
                        className="event-cards"
                        style={{
                          backgroundColor: "white",
                          width: "250px",
                          borderRadius: "10px",
                          marginRight: "20px",
                          marginBottom: "20px",
                          cursor: "pointer"
                        }}
                        onClick={_ =>
                          this.props.history.push(
                            `/partners/events/manage/single/${e.id}`
                          )
                        }
                        key={key}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "200px"
                          }}
                        >
                          <LazyLoadImage
                            src={`${process.env.REACT_APP_DS_FILES_S3}/${e.event_image}`}
                            alt="Event"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              objectFit: "cover"
                            }}
                            wrapperProps={{
                              style: {
                                width: "100%",
                                height: "100%",
                                borderTopLeftRadius: "10px",
                                borderTopRightRadius: "10px",
                                objectFit: "cover"
                              }
                            }}
                            effect="opacity"
                          />
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "12px",
                            marginTop: "5px",
                            marginBottom: "5px"
                          }}
                        >
                          {e.event_title}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                            color: "grey",
                            fontSize: "10px",
                            marginTop: "5px",
                            marginBottom: "5px"
                          }}
                        >
                          {date} at{" "}
                          {time} {" "} ({this.getTimeZone()})
                        </p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "5px"
                          }}
                        >
                          <span
                            style={{
                              flexGrow: 1,
                              color: "lightgrey",
                              fontSize: "12px",
                              marginLeft: "5px"
                            }}
                          >
                            {/* {key + 1} / {this.state.data.length} */}
                            {e.enrolledNum / e.total_spots >= 1 ? (
                              <strong
                                style={{
                                  flexGrow: 1,
                                  color: "#1BC889",
                                  fontSize: "12px",
                                  marginLeft: "0px"
                                }}
                              >
                                Full
                              </strong>
                            ) : (
                              `${e.enrolledNum} enrolled / ${
                                e.total_spots ? e.total_spots : "0"
                              } spots`
                            )}
                          </span>
                          <img
                            src="/static/media/monthlysurvey.122ae06e.svg"
                            alt=""
                            style={{ marginRight: "10px" }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "60vh",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <p
                  style={{
                    fontStyle: "italic",
                    textAlign: "center",
                    fontSize: "18px"
                  }}
                >
                  Click{" "}
                  <b>
                    <Link to="/partners/events/list">here</Link>
                  </b>{" "}
                  to start creating your own events!
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ManageEvents;
