import React from "reactn";
import MainBanner from "../../components/front/educator/MainBanner";
import TeacherProfile from "../../components/front/educator/TeacherProfile";
import CenterColumn from "../../components/front/educator/CenterColumn";
import LeftColumn from "../../components/front/educator/LeftColumn";
import RightColumn from "../../components/front/educator/RightColumn";
import { Mail, Schedule } from "../../assets";
import { Helmet } from "react-helmet";
import axios from "axios";
import ApplyEnrollModal from "../../components/modals/ApplyEnrollModal";
import RequestTourModal from "../../components/modals/RequestTourModal";
import "../../assets/css/educator.css";
class Educator extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      applyModal: false,
      tourModal: false,
      gallery: false,
      referral: null
    };
    this.showModal = this.showModal.bind(this);
    this.showApplyModal = this.showApplyModal.bind(this);
  }

  showModal = () => {
    this.setState({ modalShow: true });
  };
  showApplyModal = () => {
    const slug = this.props.match.params.slug;
    if (this.global.dsUser && this.global.dsUser.accountType === "Parent") {
      this.props.history.push(
        `/parents/interested-programs/${slug}/choose-child`
      );
    } else {
      this.setState({ applyModal: true });
    }
  };

  componentWillUnmount() {
    this.setGlobal({ school: null });
  }

  async componentDidMount() {
    if (this.props.location.search) {
      const params = this.props.location.search;
      if (params.indexOf("referral") !== -1) {
        const referral = params.substr(
          params.indexOf("referral") + 9,
          params.indexOf("&", params.indexOf("referral")) !== -1
            ? params.indexOf("&", params.indexOf("referral")) -
                params.indexOf("referral") -
                9
            : undefined
        );
        this.setState({
          referral: referral
        });
        const ep = `${process.env.REACT_APP_API}/search/referral`;
        axios.post(ep, {
          referral: referral
        });
      }
    }
    const params = this.props.match.params;
    if (!params.slug && !params.preview) {
      this.props.history.push("/");
    } else if (params.preview) {
      try {
        const ep = `${process.env.REACT_APP_API}/frnt/program/preview/${params.preview}`;
        this.setGlobal({ loading: true });
        const res = await axios.get(ep);

        if (res.data.success) {
          this.setGlobal({ school: res.data.data });

          this.forceUpdate();
        }
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (er) {
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    } else {
      try {
        const ep = `${process.env.REACT_APP_API}/frnt/program/${params.slug}`;
        this.setGlobal({ loading: true });
        const res = await axios.get(ep);
        if (res.data.success) {
          this.setGlobal({ school: res.data.data });
          this.forceUpdate();
        }
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (er) {
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }
  }

  render() {
    return !this.global.school ? null : (
      <>
        {!this.state.tourModal ? null : (
          <RequestTourModal
            onClose={() =>
              this.setState({
                tourModal: false
              })
            }
          />
        )}

        {!this.state.applyModal ? null : (
          <ApplyEnrollModal
            onClose={() => this.setState({ applyModal: false })}
          />
        )}

        <Helmet>
          <title>
            Dreamschools | {this.global.school.programName} -{" "}
            {this.global.school.educatorName || this.global.school.displayName}{" "}
            ({this.global.school.city}, {this.global.school.province})
          </title>
        </Helmet>
        <MainBanner />
        <TeacherProfile gallery={_ => this.setState({ gallery: true })} />
        <div className="container-fluid tcontent">
          <div className="profile-container">
            <CenterColumn />
            <LeftColumn />
            <RightColumn
              galleryOpen={this.state.gallery}
              galleryClose={() => this.setState({ gallery: false })}
            />
          </div>
          <div className="btnsdown">
            <div className="col-md-12 mb-5">
              {this.props.location.pathname.includes("/preview/") &&
              this.global.school.publishStage !== "0" ? (
                <>
                  <button className="btn1" data-remote="true">
                    <img src={Schedule} alt="" />
                    Schedule a Tour
                  </button>
                  <button className="btn2">
                    <img src={Mail} alt="" />
                    Request Information
                  </button>
                </>
              ) : null}
            </div>
          </div>
          <div className="mobile-sticky-footer">
            <div className="contact-title">
              <p className="mypara">
                Daily tuition
                <br />
                <span className="myspan">
                  {this.global.school.weeklyRate
                    ? `
                  From $${parseInt(this.global.school.weeklyRate)} / Day`
                    : `To be determined!`}
                </span>
              </p>
            </div>
            <div className="contact-tour">
              <button
                className="mybtn"
                onClick={() => {
                  window.location.hash = "#pricing";
                }}
              >
                See prices
              </button>
            </div>
            <div className="contact-tour">
              {this.global.school.publishStage === "2" && (
                <button
                  className="mybtn"
                  onClick={() => this.setState({ tourModal: true })}
                >
                  Schedule tour
                </button>
              )}
              {this.global.school.publishStage !== "2" && (
                <button className="mybtn" disabled>
                  Coming soon
                </button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Educator;
