import React from "reactn";
import { Link } from "react-router-dom";
import axios from "axios";
// import { withStyles } from "@material-ui/core";
import { ChevronRight, ExpandMore } from "@material-ui/icons";
import {
  Attendance,
  Connections,
  Document,
  ExpenseTracker,
  FamilyPayments,
  FamilyProfile,
  Milestones,
  MyEarnings,
  PricingPlans,
  ProgramSetup,
  Tasks,
  TourRequests,
  WeeklySlots
} from "../../assets";

import "../../assets/css/componentSpecificCss/sideMenu.css";

let ProfileLinks = [
  {
    name: "Upcoming",
    icon: FamilyProfile,
    to: "#/partners",
    nested: [
      { name: "Calendar", to: "/partners/calendar", icon: Attendance },
      { name: "Tasks & Reminders", to: "/partners/tasks", icon: Tasks }
    ]
  },
  {
    name: "Leads",
    icon: ProgramSetup,
    to: "#/",
    nested: [
      { name: "Form Leads", to: "/partners/form-leads", icon: Document },
      {
        name: "Appointments",
        to: "/partners/appointments",
        icon: TourRequests
      },
      { name: "Applications", to: "/partners/applications", icon: Document },
      { name: "Active", to: "/partners/active-leads", icon: Document }
    ]
  },
  {
    name: "Manage",
    icon: Milestones,
    to: "#/",
    nested: [
      { name: "Programs", to: "/partners/programs/manage", icon: ProgramSetup },
      { name: "Events", to: "/partners/events/manage", icon: WeeklySlots },
      {
        name: "Online Virtual",
        to: "/partners/online/manage",
        icon: ExpenseTracker
      },
      {
        name: "Memberships",
        to: "/partners/memberships/manage",
        icon: Connections
      },
      { name: "Parties", to: "/partners/parties/manage", icon: Document },
      { name: "Resources", to: "/partners/resources", icon: Document },
      {
        name: "Gift Card",
        to: "/partners/gift_cards/manage",
        icon: Document
      }
    ]
  },
  {
    name: "Financials",
    icon: PricingPlans,
    to: "#/",
    nested: [
      { name: "Total Earnings", to: "/partners/my-earnings", icon: MyEarnings },
      { name: "Paid Orders", to: "/partners/orders", icon: ExpenseTracker },
      {
        name: "Recurring Payments",
        to: "/partners/recurring",
        icon: FamilyPayments
      },
      { name: "Coupons", to: "/partners/coupon", icon: Document },
      { name: "Expense Tracker", to: "/partners/expense", icon: ExpenseTracker }
    ]
  },
  {
    name: "Contacts",
    icon: Connections,
    to: "#/",
    nested: [
      { name: "Connections", to: "/partners/connections", icon: Connections },
      {
        name: "Communications",
        to: "/partners/communications",
        icon: ProgramSetup
      },
      {
        name: "Send Message",
        to: "/partners/send-message",
        icon: ProgramSetup
      }
    ]
  },

  {
    name: "Listings",
    icon: Tasks,
    to: "#/",
    nested: [
      { name: "Programs", to: "/partners/programs/list", icon: ProgramSetup },
      { name: "Events", to: "/partners/events/list", icon: WeeklySlots },
      { name: "Online Virtual", to: "/partners/online", icon: ExpenseTracker },
      {
        name: "Memberships",
        to: "/partners/memberships/list",
        icon: Connections
      },
      {
        name: "Parties",
        to: "/partners/parties/list",
        icon: Document
      },
      {
        name: "Gift Card",
        to: "/partners/gift_cards",
        icon: Document
      },
      { name: "Promotions", to: "/partners/promotion/list", icon: MyEarnings },
      {
        name: "News & Updates",
        to: "/partners/news-updates",
        icon: Document
      },
      { name: "Forms", to: "/partners/forms", icon: Document }
    ]
  }
];

class SideBar extends React.PureComponent {
  constructor() {
    super();
    this.isEnabled = this.isEnabled.bind(this);
    this.state = { show: [] };
  }

  async componentDidMount() {
    // get permissions for coupons and expense tracker
    const ep_exp = `${process.env.REACT_APP_API}/partner/permissions/expense-tracker`;
    const expense_resp = await axios.get(ep_exp);
    const ep_coups = `${process.env.REACT_APP_API}/partner/permissions/coupons`;
    const coupons_resp = await axios.get(ep_coups);
    const ep_listings = `${process.env.REACT_APP_API}/partner/permissions/fetch/new/listings-permissions`;
    const listings_resp = await axios.get(ep_listings);

    // get role info
    const ep_role = `${process.env.REACT_APP_API}/partners/roles/${this.global.dsUser.role}`;
    const role_resp = await axios.get(ep_role);

    const financials = ProfileLinks.filter(pl => pl.name === "Financials")[0];
    const listings = ProfileLinks.filter(pl => pl.name === "Listings")[0];
    const contacts = ProfileLinks.filter(pl => pl.name === "Contacts")[0];

    // filter out the profile links that user does not have permission for - Expense Tracker
    if (
      expense_resp.data &&
      expense_resp.data.success &&
      expense_resp.data.permission?.has_access === false
    ) {
      const new_nested = financials.nested.filter(
        n => n.name !== "Expense Tracker"
      );
      financials.nested = new_nested;
    }

    // filter out the profile links that user does not have permission for - Coupons
    if (
      coupons_resp.data &&
      coupons_resp.data.success &&
      coupons_resp.data.permission?.has_access === false
    ) {
      const new_nested = financials.nested.filter(n => n.name !== "Coupons");
      financials.nested = new_nested;
    }

    // filter out the profile links that user does not have permission for - Contacts
    // Only for subusers
    if (
      this.global.dsUser.sub_user &&
      role_resp.data &&
      role_resp.data.success &&
      role_resp.data.data[0].role !== "General (all)"
    ) {
      const new_nested = contacts.nested.filter(n => n.name !== "Send Message");
      contacts.nested = new_nested;
    }

    if (
      this.global.dsUser.plan_type === "Daycare / Preschool - DS" ||
      this.global.dsUser.plan_type === "Daycare / Preschool - Custom"
    ) {
      let newLinks = ProfileLinks;
      console.debug("SideMenu.jsx[201]: ProfileLinks =",ProfileLinks); //Next line was crashing uncaught errors: undefined is not an object (evaluating '(y=s_)[2].nested[5].name')
      if (newLinks[2].nested[5].name !== "Observation") {
        newLinks[2].nested.splice(
          5,
          0,
          { name: "Observation", to: "/partners/observation", icon: Document },
          { name: "Newsfeeds", to: "/partners/newsfeed", icon: Document }
        );
        ProfileLinks = newLinks;
      }
    }

    const manage = ProfileLinks.filter(pl => pl.name === "Manage")[0];

    /**
     * Enabled the has access hiding capability on side menu of the listings
     */
    if (listings_resp.data && listings_resp.data.success) {
      const listNames = [];
      const listPermissions = listings_resp.data.data.filter(
        e => !e.has_access
      );
      const featureListings = listPermissions.map(e =>
        listNames.push(e.feature)
      );
      const new_nested = listings.nested.filter(n => {
        const isTrue = listNames.filter(f => n.name.includes(f))[0];
        if (n.name.includes(isTrue)) {
          return;
        }
        return n;
      });
      listings.nested = new_nested;

      // if (this.global.dsUser.sub_user && role_resp.data && role_resp.data.success && role_resp.data.data[0].role === "Listings (Specific)") {
      //   const ep = `${process.env.REACT_APP_API}/partners/users/all-details/${this.global?.dsUser?.user_id}`;
      //   const results = await axios.get(ep);
      //   console.log("results", results);
      //   if (results.data.success) {
      //     manage.nested = manage.nested.filter(n => {
      //       switch (n.name) {
      //         case "Programs":
      //           return results.data.data.programlist?.length > 0;
      //         case "Events":
      //           return results.data.data.eventlist?.length > 0;
      //         case "Online Virtual":
      //           return results.data.data.onlinelist?.length > 0;
      //         case "Memberships":
      //           return results.data.data.membershiplist?.length > 0;
      //         case "Resources":
      //           return results.data.data.membershiplist?.length > 0;
      //         case "Gift Card":
      //           return results.data.data.membershiplist?.length > 0;
      //         default:
      //           return false;
      //       }
      //     });
      //   }
      // }

      manage.nested = manage.nested.filter(n => {
        const isTrue = listNames.filter(f => n.name.includes(f))[0];
        if (n.name.includes(isTrue)) {
          return;
        }
        return n;
      });
    }
  }

  isEnabled = link => {
    if (link.disabled) return true;

    return false;
  };

  render() {
    return (
      <>
        <hr className="sidebar-divider my-0" />

        <li
          className="nav-item active"
          style={{ marginTop: "30px", fontFamily: "Montserrat" }}
        >
          {ProfileLinks.map((link, key) => {
            // Hide the Contacts link if they only have permission to use Send Message, but send message is not enabled
            if (this.global.dsUser.sub_user && link.name === "Contacts") {
              if (!this.global.dsUser.accesspoints.Contacts?.Communications?.read && !this.global.dsUser.accesspoints.Contacts?.Connections?.read) {
                if (!link.nested.includes("Send Message")) {
                  return;
                }
              }
            }

            if (
              !this.global.dsUser.sub_user ||
              this.global.dsUser.accesspoints[
                link.name.slice(0, 1) + link.name.slice(1).toLocaleLowerCase()
              ]
            )
              return (
                <div
                  style={{
                    margin: "5px 0"
                  }}
                  key={key}
                >
                  <span
                    className={
                      this.isEnabled(link)
                        ? "nav-link disabled-link"
                        : "nav-linkpartner"
                    }
                    to={link.to}
                    key={key}
                    disabled={this.isEnabled(link)}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      this.state.show.includes(link.name)
                        ? this.setState({
                            show: [
                              ...this.state.show.filter(e => e !== link.name)
                            ]
                          })
                        : this.setState({
                            show: [...this.state.show, link.name]
                          });
                    }}
                  >
                    {
                      <img
                        src={link.icon}
                        alt=""
                        style={{ width: "10px", height: "10px" }}
                      />
                    }
                    <span style={{ flexGrow: 1, fontWeight: "300" }}>
                      {link.name}
                    </span>

                    <div style={{ marginBottom: "3px", paddingRight: "10px" }}>
                      {this.state.show.includes(link.name) && link.nested && (
                        <ExpandMore style={{ height: "17px", width: "17px" }} />
                      )}
                      {!this.state.show.includes(link.name) && link.nested && (
                        <ChevronRight
                          style={{ height: "17px", width: "17px" }}
                        />
                      )}
                    </div>
                  </span>
                  {this.state.show.includes(link.name) && link.nested && (
                    <ul
                      style={{
                        listStyleType: "none",
                        padding: 0,
                        width: "85%",
                        margin: "0px auto",
                        borderRadius: "10px",
                        paddingLeft: "20px",
                        fontSize: "14px"
                      }}
                    >
                      {link.nested
                        .filter(
                          e =>
                            !this.global.dsUser.sub_user ||
                            (this.global.dsUser.accesspoints[
                              link.name.slice(0, 1) +
                                link.name.slice(1).toLocaleLowerCase()
                            ][e.name] &&
                              this.global.dsUser.accesspoints[
                                link.name.slice(0, 1) +
                                  link.name.slice(1).toLocaleLowerCase()
                              ][e.name].read)
                        )
                        .map((e, key) => {
                          return (
                            <li
                              style={{ paddingBottom: "2px" }}
                              className="side-menu-sub-list"
                              key={key}
                            >
                            <span key={key}>
                              {
                                <img
                                  src={e.icon}
                                  alt=""
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    marginRight: "18px"
                                  }}
                                />
                              }
                              <Link
                                to={e.to}
                                style={{
                                  marginLeft: "8px",
                                  fontSize: "12px",
                                  color: "white",
                                  fontWeight: "300"
                                }}
                                className="submenu"
                              >
                                {e.name}
                              </Link>
                            </span>
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </div>
              );
          })}
        </li>
      </>
    );
  }
}

export default SideBar;
