import React, { useGlobal, useState } from "reactn";
import { BoxForm } from "../../../../components/UI/form";
import Switch from "@material-ui/core/Switch";
import ReactTooltip from "react-tooltip";

function EnableManualZoom() {
  const [global] = useGlobal();
  return (
    <div
      style={{
        opacity: 0.5
      }}
    >
      <div className="row">
        <div className="col-6">
          <h3>
            <label className="label-names">Cameras on start</label>
          </h3>
        </div>
        <div className="col-6">
          <Switch checked={global.online.camera_on_start} onClick={() => {}} />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h3>
            <label className="label-names">Microphones on start</label>
          </h3>
        </div>
        <div className="col-6">
          <Switch
            checked={global.online.microphones_on_start}
            onClick={() => {}}
          />
        </div>
      </div>
      <div style={{ opacity: 0.5 }}>
        <div className="row">
          <div className="col-6">
            <h3>
              <label className="label-names">Enable Recording</label>
            </h3>
          </div>
          <div className="col-6">
            <Switch
              checked={!!global.online.enable_recording}
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h3>
            <label className="label-names">Only Broadcast</label>
          </h3>
        </div>
        <div className="col-6">
          <Switch checked={global.online.only_broadcast} onClick={() => {}} />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h3>
            <label className="label-names">Maximum participants</label>
          </h3>
        </div>
        <div className="col-6">
          <input
            className="input-fields"
            style={{ width: "100%" }}
            type="number"
            name="participants"
            value={global.online.maximum_participants}
            onChange={e => {}}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h3>
            <label className="label-names">Enable Chat</label>
          </h3>
        </div>
        <div className="col-6">
          <Switch checked={global.online.enable_chat} onClick={() => {}} />
        </div>
      </div>
    </div>
  );
}
const SectionEight = () => {
  const [global, setGlobal] = useGlobal();
  const [invalidMaxParticipants, setInvalidMaxParticipants] = useState(false);

  const handleChange = (e, v) => {
    setGlobal({
      online: {
        ...global.online,
        [e]: v
      }
    });
  };

  const customForm = () => {
    if (global.online.manual_zoom) {
      return (
        <div className="formbody">
          <form>
            <EnableManualZoom />
            <div className="row">
              <div className="col-6">
                <h3>
                  <label className="label-names">Enable Zoom (Manual)</label>
                </h3>
              </div>
              <div className="col-6">
                <Switch
                  checked={global.online.manual_zoom}
                  onClick={() => {
                    setGlobal({
                      online: {
                        ...global.online,
                        manual_zoom: !global.online.manual_zoom
                      }
                    });
                  }}
                />
              </div>
            </div>
            {/* <div style={{ opacity: 0.5 }}>
              <div className="row">
                <div className="col-6"><h3><label className="label-names">Enable Google Meets</label></h3></div>
                <div className="col-6">
                  <Switch
                    checked={global.online.enable_google_meets}
                  />
                </div>
              </div>
            </div> */}
          </form>
        </div>
      );
    }
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-6">
              <h3>
                <label className="label-names">Cameras on start</label>
              </h3>
            </div>
            <div className="col-6">
              <Switch
                checked={global.online.camera_on_start}
                onClick={() => {
                  setGlobal({
                    online: {
                      ...global.online,
                      camera_on_start: !global.online.camera_on_start
                    }
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h3>
                <label className="label-names">Microphones on start</label>
              </h3>
            </div>
            <div className="col-6">
              <Switch
                checked={global.online.microphones_on_start}
                onClick={() => {
                  setGlobal({
                    online: {
                      ...global.online,
                      microphones_on_start: !global.online.microphones_on_start
                    }
                  });
                }}
              />
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-6">
                <h3>
                  <label className="label-names">Enable Recording</label>
                </h3>
              </div>
              <div className="col-6">
                <Switch
                  checked={!!global.online.enable_recording}
                  onClick={() => {
                    setGlobal({
                      online: {
                        ...global.online,
                        enable_recording: !global.online.enable_recording
                      }
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h3>
                <label className="label-names">Only Broadcast</label>
              </h3>
            </div>
            <div className="col-6">
              <Switch
                checked={global.online.only_broadcast}
                onClick={() => {
                  setGlobal({
                    online: {
                      ...global.online,
                      only_broadcast: !global.online.only_broadcast
                    }
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h3>
                <label className="label-names">
                  Maximum participants
                  <RequiredStar />
                  <i
                    data-tip
                    data-for="max_participants"
                    className="fas fa-info-circle"
                    style={{ color: "#DCDCDC" }}
                  ></i>
                  <ReactTooltip
                    id="max_participants"
                    place="right"
                    effect="solid"
                    type="info"
                  >
                    Maximum 200 participants
                  </ReactTooltip>
                </label>
              </h3>
            </div>
            <div className="col-6">
              <input
                className="input-fields"
                style={ invalidMaxParticipants ? {
                  borderColor: "red"
                } : {}}
                type="number"
                name="participants"
                value={global.online.maximum_participants}
                onChange={e => {
                  setInvalidMaxParticipants(e.target.value > 200)
                  const val = (e.target.value > 0 && e.target.value !== "") ? e.target.value : 1;
                  setGlobal({
                    online: {
                      ...global.online,
                      maximum_participants: val
                    }
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h3>
                <label className="label-names">Enable Chat</label>
              </h3>
            </div>
            <div className="col-6">
              <Switch
                checked={global.online.enable_chat}
                onClick={() => {
                  setGlobal({
                    online: {
                      ...global.online,
                      enable_chat: !global.online.enable_chat
                    }
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h3>
                <label className="label-names">Enable People UI{" "}
                  <i
                    data-tip
                    data-for="enable_people_ui"
                    className="fas fa-info-circle"
                    style={{ color: "#DCDCDC" }}
                  ></i>
                  <ReactTooltip
                    id="enable_people_ui"
                    place="right"
                    effect="solid"
                    type="info"
                  >
                    Allows users to see a list of participants
                  </ReactTooltip>
                </label>
              </h3>
            </div>
            <div className="col-6">
              <Switch
                checked={global.online.enable_people_ui}
                onClick={() => {
                  setGlobal({
                    online: {
                      ...global.online,
                      enable_people_ui: !global.online.enable_people_ui
                    }
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h3>
                <label className="label-names">Enable Knocking{" "}
                  <i
                    data-tip
                    data-for="enable_knocking"
                    className="fas fa-info-circle"
                    style={{ color: "#DCDCDC" }}
                  ></i>
                  <ReactTooltip
                    id="enable_knocking"
                    place="right"
                    effect="solid"
                    type="info"
                  >
                    Enable a lobby for unauthorized participants until accepted into the online room
                  </ReactTooltip>
                </label>
              </h3>
            </div>
            <div className="col-6">
              <Switch
                checked={global.online.enable_knocking}
                onClick={() => {
                  setGlobal({
                    online: {
                      ...global.online,
                      enable_knocking: !global.online.enable_knocking
                    }
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h3>
                <label className="label-names">Enable Zoom (Manual)</label>
              </h3>
            </div>
            <div className="col-6">
              <Switch
                checked={global.online.manual_zoom}
                onClick={() => {
                  setGlobal({
                    online: {
                      ...global.online,
                      manual_zoom: !global.online.manual_zoom
                    }
                  });
                }}
              />
            </div>
          </div>
          {/* <div style={{ opacity: 0.5 }}>
            <div className="row">
              <div className="col-6"><h3><label className="label-names">Enable Google Meets</label></h3></div>
              <div className="col-6">
                <Switch
                  checked={global.online.enable_google_meets}
                />
              </div>
            </div>
          </div> */}
        </form>
      </div>
    );
  };

  function RequiredStar() {
    return <span className="requiredField">*</span>;
  }

  return (
    <BoxForm
      handleChange={handleChange}
      name={"Tech Link Details"}
      customForm={customForm()}
    />
  );
};

SectionEight.propTypes = {};

export default SectionEight;
