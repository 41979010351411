import React from "reactn";
import axios from "axios";
import AddFieldModal from "../../components/modals/AddFieldModal";
import EditFormFieldModal from "../../components/modals/EditFormFieldModal";
import AddSectionModal from "../../components/modals/AddSectionModal";
import EditSectionModal from "../../components/modals/EditSectionName";
import "react-form-builder2/dist/app.css";
import "../../assets/css/componentSpecificCss/formbuilder.css";
import Date from "react-datepicker";
import { toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FormPreviewModal from "../../components/modals/FormPreviewModal";

const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));
class FormBuilder extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      programs: [],
      tourModal: false,
      infoModal: false,
      selectedProgram: null,
      days: {},
      form: {},
      data: [],
      form_elements: { task_data: [] },
      items: getItems(10),
      openPreviewModal: false,
      sectionToAdd: 0
    };
    // this.grid = 8;
    this.elements = (
      element,
      label,
      type = undefined,
      options,
      policyOptions
    ) => {
      if (element === "input")
        return (
          <input
            type={type}
            placeholder={label}
            className="formbuilder-input"
            disabled
          />
        );
      if (element === "textarea")
        return (
          <textarea
            rows={4}
            placeholder={label}
            className="formbuilder-textarea"
            disabled
          ></textarea>
        );
      if (element === "days")
        return (
          <div
            id="days"
            className="row days"
            style={{
              width: "100%",
              justifySelf: "center"
            }}
          >
            <div
              data-day="Monday"
              className={`day-monday day ${
                this.state.days["Monday"] ? "selecta" : ""
              }`}
            >
              M
            </div>
            <div
              data-day="Tuesday"
              className={`day-tuesday day ${
                this.state.days["Tuesday"] ? "selecta" : ""
              }`}
            >
              Tu
            </div>
            <div
              data-day="Wednesday"
              className={`day-wednesday day ${
                this.state.days["Wednesday"] ? "selecta" : ""
              }`}
            >
              W
            </div>
            <div
              data-day="Thursday"
              className={`day-thursday day ${
                this.state.days["Thursday"] ? "selecta" : ""
              }`}
            >
              Th
            </div>
            <div
              data-day="Friday"
              className={`day-friday day ${
                this.state.days["Friday"] ? "selecta" : ""
              }`}
            >
              F
            </div>
            <div
              data-day="Saturday"
              className={`day-saturday day ${
                this.state.days["Saturday"] ? "selecta" : ""
              }`}
            >
              Sa
            </div>
            <div
              data-day="Sunday"
              className={`day-sunday day ${
                this.state.days["Sunday"] ? "selecta" : ""
              }`}
            >
              Su
            </div>
          </div>
        );
      if (element === "radios")
        return (
          <div>
            <div style={{ marginRight: "10px" }}>
              <input type="radio" name="childcareneed" value="Immediately" />{" "}
              Immediately
            </div>
            <div style={{ marginRight: "10px" }}>
              <input type="radio" name="childcareneed" value="1-3 Months" /> 1-3
              Months
            </div>
            <div style={{ marginRight: "10px" }}>
              <input type="radio" name="childcareneed" value="3+ Months" /> 3+
              Months
            </div>
          </div>
        );
      if (element === "Dropdown")
        return (
          <select disabled>
            <option value="" disabled selected>
              Please Select
            </option>
            {options.map((e, i) => (
              <option value={e.value} key={i}>
                {e.value}
              </option>
            ))}
          </select>
        );
      if (element === "Multiple Choices")
        return (
          <div className="formbuilder-radios">
            {options.map((e, i) => (
              <div className="formbuilder-radio-container">
                <input
                  type="radio"
                  key={i}
                  name={label}
                  value={e.value}
                  disabled
                />{" "}
                {e.value}
              </div>
            ))}
          </div>
        );
      if (element === "signature") return <a href="#/">Draw Signature</a>;
      if (element === "Checkboxes")
        return (
          <div className="formbuilder-radios">
            {options.map((e, i) => (
              <div className="formbuilder-radio-container">
                <input
                  type="checkbox"
                  name={label}
                  value={e.value}
                  key={i}
                  disabled
                />{" "}
                {e.value}
              </div>
            ))}
          </div>
        );
      if (element === "DOB")
        return (
          <Date
            disabled={true}
            dateFormat="LLLL dd,yyyy"
            showMonthYearDropdown
          />
        );
      if (element === "Policy") {
        const { isFileUpload, file, policyText, checkboxText, link } =
          policyOptions;
        const element = (
          <>
            {policyText}
            <br />
            {(isFileUpload || link) && (
              <a
                href={
                  isFileUpload
                    ? `${process.env.REACT_APP_DS_FILES_S3}/${file}`
                    : link
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                View Policy
              </a>
            )}
          </>
        );
        return (
          <div style={{ margin: "0 8px" }}>
            <p>{element}</p>
            <input
              type="checkbox"
              id="confirmText"
              name="confirmText"
              style={{ marginRight: "5px" }}
              disabled
            />
            <label htmlFor="confirmText">{checkboxText}</label>
          </div>
        );
      }
    };
  }

  componentDidUpdate() {
    if (document.getElementsByClassName("react-datepicker-wrapper")) {
      for (let dates of document.getElementsByClassName(
        "react-datepicker-wrapper"
      )) {
        dates.style.width = "auto";
      }
    }
  }

  editField(updatedData, formIndex, fieldIndex) {
    const existingData = this.state.data;
    existingData[formIndex].fields.splice(
      fieldIndex,
      1,
      JSON.stringify(updatedData)
    );

    this.setState({
      data: existingData
    });
  }

  /**
   * Adds a field to the form. Does not save data to server just yet.
   * Passed to AddFieldModal as props.
   * No return value.
   *
   * @param {Number} form From 0-19 representing the type of field added.
   * @param {String} label Text label of the field.
   * @param {Array} options For dropdowns and selects, represents the options users can choose from.
   * @param {Object} policyOptions An object for extra data when user chooses a policy field.
   */
  addField(form, label = "", options = [], policyOptions = {}) {
    const forms = [
      {
        field: "input",
        first_label: "Full Name",
        label: "Full Name",
        required: true,
        type: "text",
        visible: true
      },
      {
        field: "input",
        first_label: "Email",
        label: "Email",
        required: true,
        type: "email",
        visible: true
      },
      {
        field: "input",
        first_label: "Address",
        label: "Address",
        required: false,
        type: "text",
        visible: true
      },
      {
        field: "input",
        first_label: "Home Phone",
        label: "Home Phone",
        required: false,
        type: "text",
        visible: true
      },
      {
        field: "input",
        first_label: "Cell Phone",
        label: "Cell Phone",
        required: false,
        type: "text",
        visible: true
      },
      {
        field: "input",
        first_label: "City",
        label: "City",
        required: false,
        type: "text",
        visible: true
      },
      {
        field: "input",
        first_label: "Region",
        label: "Region",
        required: false,
        type: "text",
        visible: true
      },
      {
        field: "input",
        first_label: "Postal / Zip",
        label: "Postal / Zip",
        required: false,
        type: "text",
        visible: true
      },
      {
        field: "input",
        first_label: "Country",
        label: "Country",
        required: false,
        type: "text",
        visible: true
      },
      {
        field: "DOB",
        first_label: "Date of Birth",
        label: "Date of Birth",
        required: false,
        visible: true
      },
      {
        field: "days",
        first_label: "Ideal Days",
        required: false,
        visible: true
      },
      {
        field: "radios",
        first_label: "Ideal Start Date",
        required: false,
        visible: true
      },
      {
        field: "DOB",
        first_label: label,
        label: label,
        required: false,
        visible: true
      },
      {
        field: "input",
        first_label: label,
        label: label,
        required: false,
        type: "file",
        visible: true
      },
      {
        field: "input",
        first_label: label,
        label: label,
        required: false,
        visible: true
      },
      {
        field: "textarea",
        rows: 4,
        label: label,
        first_label: label,
        required: false,
        visible: true
      },
      {
        field: "signature",
        first_label: label,
        label: label,
        required: false,
        visible: true
      },
      {
        field: "Dropdown",
        first_label: label,
        label: label,
        required: false,
        visible: true,
        options: options
      },
      {
        field: "Multiple Choices",
        first_label: label,
        label: label,
        required: false,
        visible: true,
        options: options
      },
      {
        field: "Checkboxes",
        first_label: label,
        label: label,
        required: false,
        visible: true,
        options: options
      },
      {
        field: "Policy",
        first_label: label,
        label: label,
        required: false,
        visible: true,
        policyOptions: policyOptions
      }
    ];

    if (
      this.state.data[this.state.sectionToAdd].fields
        .map(e => JSON.parse(e))
        .filter(e => e.first_label === forms[form].first_label).length > 0
    ) {
      toast.error(`This field already exists`);
      return;
    }

    const allFields = [...this.state.data];
    allFields[this.state.sectionToAdd].fields.push(JSON.stringify(forms[form]));
    this.setState({ data: [...allFields] });
  }
  onBeforeDragStart = () => {};

  onDragEnd = (result, index) => {
    if (!result.destination) {
      return;
    }

    const resultat = Array.from(this.state.data[index].fields);
    const parsed = JSON.parse(resultat[result.destination.index]);
    if (
      parsed.first_label === "Full Name" ||
      // parsed.first_label === "First Name" ||
      // parsed.first_label === "Last Name" ||
      parsed.first_label === "Email" ||
      parsed.first_label === "Phone" ||
      parsed.first_label === "Cell Phone"
    ) {
      return;
    }
    const [removed] = resultat.splice(result.source.index, 1);
    resultat.splice(result.destination.index, 0, removed);

    const dataClone = [...this.state.data];
    dataClone[index].fields = resultat;
    this.setState({ data: dataClone });
  };
  addSection(section) {
    const sectiontoAdd = [
      ...this.state.data,
      {
        form_section_name: section,
        fields: [],
        formId: this.props.match.params.id
      }
    ];
    this.setState({ data: sectiontoAdd });
  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      const ep = `${process.env.REACT_APP_API}/partners/forms/${this.props.match.params.id}`;
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setGlobal({ loading: false, lastAPICall: res });
      if (!res.data.success) {
        this.props.history.push("/partner");
        return;
      }
      this.setState({
        data: res.data.data.sections.sort((a, b) =>
          a.id > b.id ? 1 : a.id < b.id ? -1 : 0
        ),
        form: res.data.data.form
      });

      /*try {
      } catch (err) {
        this.setGlobal({ loading: false, lastAPICall: null });
      }*/
      this.setGlobal({
        partnerPathway: [
          ...this.global.partnerPathway.slice(0, 1),
          { label: "Forms", to: "/forms" },
          {
            label: `${this.state.form.form_name} - (${this.state.form.form_sub_name})`,
            to: this.props.location.pathname.replace(/^\/partners/, "")
          }
        ]
      });
    }
  }
  updateObj(obj, sectionIndex, index) {
    const allFields = [...this.state.data];
    allFields[sectionIndex].fields[index] = JSON.stringify(obj);
    this.setState({ data: [...allFields] });
  }

  getListStyle = isDraggingOver => ({
    background: "#f7f7f8",
    width: "100%",
    maxWidth: "650px",
    margin: "0 auto"
  });
  getItemStyle = (isDragging, draggableStyle, color) => ({
    // change background colour if dragging
    background: color,

    // styles we need to apply on draggables
    ...draggableStyle
  });
  async saveForm() {
    const res = await axios.put(`${process.env.REACT_APP_API}/partners/forms`, {
      data: [...this.state.data],
      delete: this.state.toDelete
    });
    if (res.data.success) {
      toast.success("Save Successful!");
      this.props.history.push("/partners/forms");
    }
  }

  render() {
    console.log("THIS STATE", this.state);
    return (
      <>
        <div className="container-fluid interested_programs">
          {this.state.addSection && (
            <AddSectionModal
              onClose={_ => this.setState({ addSection: false })}
              addSection={ques => this.addSection(ques)}
            />
          )}
          {this.state.editSectionModal && (
            <EditSectionModal
              onClose={_ => this.setState({ editSectionModal: false })}
              name={this.state.edit_sec_name}
              editSection={name =>
                this.setState({
                  data: this.state.data.map((e, i) =>
                    i === this.state.edit_sec_index
                      ? { ...e, form_section_name: name }
                      : e
                  )
                })
              }
            />
          )}
          {this.state.addField && (
            <AddFieldModal
              onClose={_ => this.setState({ addField: false })}
              addField={(id, ques, options, policyOptions) =>
                this.addField(id, ques, options, policyOptions)
              }
            />
          )}
          {this.state.openEditFieldModal && (
            <EditFormFieldModal
              onClose={_ => this.setState({ openEditFieldModal: false })}
              editData={this.state.data}
              editIndex={this.state.editIndex}
              editFormIndex={this.state.editFormIndex}
              editField={(updatedData, formInex, fieldIndex) =>
                this.editField(updatedData, formInex, fieldIndex)
              }
            />
          )}
          {this.state.openPreviewModal && (
            <FormPreviewModal
              selectedFormId={this.props.match.params.id}
              onClose={_ => this.setState({ openPreviewModal: false })}
              fields={this.state.data}
            />
          )}
          <div className="cont">
            <h1>
              Add Form: {this.state.form.form_name} - (
              {this.state.form.form_sub_name})
            </h1>
          </div>
          <div className="cont">
            <div
              className="boxes-row ip-boxes"
              style={{ flexDirection: "column" }}
            >
              {this.state.data
                .filter(e => !e.archived)
                .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
                .map((val, i) => (
                  <React.Fragment key={i}>
                    <div className="formbuilder-header formbuilder-row">
                      <label className="formbuilder-show-require">SHOW</label>
                      <label className="formbuilder-show-require">
                        REQUIRE
                      </label>
                      <label className="formbuilder-section">Section</label>
                      <label className="formbuilder-section-name">
                        {val.form_section_name}
                      </label>
                      {
                        // To remove sections (excluding the very first one)
                        i !== 0 && (
                          <i
                            className="fas fa-times-circle formbuilder-icon-button formbuilder-delete-section"
                            onClick={_ => {
                              const index = this.state.data.indexOf(val);
                              const data = this.state.data.filter(
                                f => f.id === val.id
                              );
                              this.setState({
                                data: this.state.data.filter(
                                  (e, indexo) => indexo !== index
                                ),
                                toDelete: this.state.toDelete
                                  ? [
                                      ...this.state.toDelete,
                                      this.state.data[index].id
                                    ]
                                  : [this.state.data[index].id]
                              });
                            }}
                          ></i>
                        )
                      }
                    </div>
                    <DragDropContext
                      onDragEnd={result => this.onDragEnd(result, i)}
                    >
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={this.getListStyle(snapshot.isDraggingOver)}
                          >
                            {val.fields.map((v, index) => {
                              const values =
                                typeof v === "string" ? JSON.parse(v) : v;

                              return (
                                <Draggable
                                  key={"power" + index}
                                  draggableId={
                                    (i !== 0 ||
                                      values.first_label !== "Full Name") &&
                                    (i !== 0 ||
                                      values.first_label !== "Email") &&
                                    (i !== 0 ||
                                      values.first_label !== "Phone") &&
                                    (i !== 0 ||
                                      values.first_label !== "Cell Phone")
                                      ? ("power" + index).toString()
                                      : index.toString()
                                  }
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={this.getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style,
                                        (i !== 0 ||
                                          values.first_label !== "Full Name") &&
                                          (i !== 0 ||
                                            values.first_label !== "Email") &&
                                          (i !== 0 ||
                                            values.first_label !== "Phone") &&
                                          (i !== 0 ||
                                            values.first_label !== "Cell Phone")
                                          ? "#FFF"
                                          : "#e8e8e8"
                                      )}
                                      className="formbuilder-row formbuilder-item"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={values.visible}
                                        onChange={e =>
                                          this.updateObj(
                                            {
                                              ...values,
                                              visible: e.target.checked
                                            },
                                            i,
                                            index
                                          )
                                        }
                                      />
                                      <input
                                        type="checkbox"
                                        checked={values.required}
                                        onChange={e =>
                                          this.updateObj(
                                            {
                                              ...values,
                                              required: e.target.checked
                                            },
                                            i,
                                            index
                                          )
                                        }
                                      />
                                      <label>
                                        {values.first_label}{" "}
                                        {values.required && (
                                          <span className="requiredField">
                                            *
                                          </span>
                                        )}
                                      </label>
                                      {values.field === "textarea"
                                        ? this.elements(
                                            values.field,
                                            values.first_label,
                                            values.type,
                                            values.options,
                                            values.policyOptions
                                          )
                                        : this.elements(
                                            values.field,
                                            values.first_label,
                                            values.type,
                                            values.options,
                                            values.policyOptions
                                          )}
                                      {(i !== 0 ||
                                        values.first_label !== "Full Name") &&
                                        (i !== 0 ||
                                          values.first_label !== "Email") && (
                                          <>
                                            <i
                                              className="fas fa-pencil-alt formbuilder-icon-button"
                                              onClick={_ => {
                                                this.setState({
                                                  editIndex: index,
                                                  editFormIndex: i,
                                                  openEditFieldModal: true
                                                });
                                              }}
                                            ></i>
                                            <i
                                              className="fas fa-times-circle formbuilder-icon-button"
                                              onClick={_ => {
                                                const datacopy = [
                                                  ...this.state.data
                                                ];
                                                datacopy[i].fields = datacopy[
                                                  i
                                                ].fields.filter(
                                                  (_, indexo) =>
                                                    indexo !== index
                                                );
                                                this.setState({
                                                  data: datacopy
                                                });
                                              }}
                                            ></i>
                                          </>
                                        )}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <div className="formbuilder-buttons-row">
                      <div className="buttons">
                        <button
                          type="button"
                          name="add"
                          className="formbuilder-button"
                          onClick={e =>
                            this.setState({ sectionToAdd: i, addField: true })
                          }
                        >
                          + FIELD
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
        <div className="cont mb-5">
          <div className="buttons w-100">
            <button
              type="button"
              name="addsection"
              className="savebtn"
              onClick={e => this.setState({ addSection: true })}
            >
              ADD SECTION
            </button>
            <button
              type="button"
              name="add"
              className="savebtn"
              onClick={e =>
                this.setState({
                  sectionToAdd: this.state.data.length - 1,
                  addField: true
                })
              }
            >
              ADD FIELD
            </button>
            <button
              name="save"
              className="savebtn"
              onClick={_ => this.setState({ openPreviewModal: true })}
            >
              PREVIEW
            </button>
            <button
              name="next"
              className="savebtn"
              onClick={_ => this.saveForm()}
            >
              SAVE
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default FormBuilder;
