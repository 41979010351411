import PreScreeningTable from "./PreScreeningTable";
import EducatorApplicationsTable from "./EducatorApplicationsTable";
import DSFeesTable from "./DSFeesTable";
import EducatorPayoutsTable from "./EducatorPayoutsTable";
import ActiveEnrollmentsTable from "./ActiveEnrollmentsTable";
import FamilyApplicationsTable from "./FamilyApplicationsTable";
import FinancesOverviewTable from "./FinancesOverviewTable";
import InvoicesTable from "./InvoicesTable";
import CreditsTable from "./CreditsTable";
import PromotionsTable from "./PromotionsTable";

const Admins = {
  PreScreeningTable: PreScreeningTable,
  EducatorApplicationsTable: EducatorApplicationsTable,
  DSFeesTable: DSFeesTable,
  EducatorPayoutsTable: EducatorPayoutsTable,
  ActiveEnrollmentsTable: ActiveEnrollmentsTable,
  FamilyApplicationsTable: FamilyApplicationsTable,
  FinancesOverviewTable: FinancesOverviewTable,
  InvoicesTable: InvoicesTable,
  CreditsTable: CreditsTable,
  PromotionsTable: PromotionsTable
};

export default Admins;
