import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { DATE_PICKER, GET_HTML } from "./baseModal/FieldTypes";
import BaseModal from "./baseModal/BaseModal";

const DOLLAR_TO_CENT = 100;

class EditStartDateModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async editStartDate(e) {
    e.preventDefault();
    let ep;
    if (this.props.program.classificationType === "Online") {
      ep = `${process.env.REACT_APP_API}/partners/virtual/customers/update`;
    } else if (this.props.program.classificationType === "Program") {
      ep = `${process.env.REACT_APP_API}/partners/programs/customers/update`;
    }
    
    const res = await axios.post(ep, {
      id: this.props.program.id,
      startDate: this.state.startDate
    });

    console.log("res", res);
    if (res.data.success) {
      toast.success("Start Date Updated");
      this.props.onClose();
    }
  }

  async componentDidMount() {
    const {plans, plan_id} = this.props.program;
    const parsedPlan =
      plans && plan_id !== null ? JSON.parse(plans[plan_id]) : null;
    const startDate = parsedPlan ? parsedPlan.startDate : this.props.program.startDate;
    this.setState({ startDate: new Date(startDate) });
  }

  getBaseModalFields = () => {
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    const startDateField = {
      type: DATE_PICKER,
      data: {
        name: "Start Date",
        value: this.state.startDate,
        handleChange: e => this.setState({ startDate: e }),
        minDate: nextDay,
        dateFormat: "MMMM d, yyyy",
        placeholder: "Start Date"
      }
    }

    const fields = [];

    fields.push(startDateField);

    return fields;
  }

  getBaseModalButtons = () => {
    const submitButton = {
      name: "Update",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      handleClick: e => this.editStartDate(e)
    };

    return [submitButton];
  }

  getBaseModalProps = () => {
    return {
      title: "Edit Start Date",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      height: "65vh",
      midSectionHeight: "49vh"
    };
  }

  render() {
    console.log("this.state", this.state);
    console.log("this.global", this.global);
    console.log("this.props", this.props);

    return <BaseModal {...this.getBaseModalProps()}/>
  }
}

export default withRouter(EditStartDateModal);
