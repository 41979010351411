import React, { useRef, useState, useEffect } from "react";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Close } from "@material-ui/icons";
import { ModalBanner, MiniPlus } from "../../assets";

const PartnerJournalModal = ({ onClose, journalText, author }) => {
  return (
    <div className="request-modal-cont">
      <div className="request-modal lesspadding">
        <div style={{ display: "block" }}>
          <img
            src={ModalBanner}
            alt="Modal Banner"
            style={{
              height: "40px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "48px",
              marginBottom: ".5rem"
            }}
          >
            <h3 data-testid="??">{author}'s Journal</h3>
            <IconButton
              style={{
                borderRadius: "50%",
                color: "#2880FF",
                marginBottom: "1rem"
              }}
              onClick={onClose}
            >
              <Close />
            </IconButton>
          </div>
          <div
            className="forbtnwrap justify-end"
            style={{ marginBottom: "15px" }}
          ></div>
          <div>
            <Typography
              align="left"
              color="primary"
              display="block"
              paragraph={true} // for bottom margin
              variant="body1"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {journalText}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerJournalModal;
