import React, { useState, useGlobal, useEffect } from "reactn";
import { format, parse, addDays } from "date-fns";
import _ from "lodash";
import axios from "axios";

import { useDateFilter } from "../../../providers/reports";
import {
  DateFilter,
  ReportContainer,
  ReportTable,
  FilterButton
} from "../../../components/UI/reportsAnalytics";
import { formatMoney } from "../../../utilities/reportsAnalytics";

const TaxSummary = () => {
  const [dateFrom, dateTo, setDateFrom, setDateTo] = useDateFilter();
  const orgData = useGlobal("organizationInfo")[0];
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");

  const [listingType, setListingType] = useState("program");
  const [allListings, setAllListings] = useState([]);
  const [selectedListings, setSelectedListings] = useState([]);

  const [allOrders, setAllOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  // change the name from "Program: XXXX" to "XXXX"
  const cleanName = orders => {
    return orders.map(o => ({
      ...o,
      item: o.item.includes("Invoice")
        ? o.item
        : o.item.slice(o.item.indexOf(":") + 1).trim()
    }));
  };

  // program => Program
  // event => Event
  // membership => Membership
  // online => Online Virtual
  const convertListing = name => {
    if (name === "program") {
      return "Program";
    }
    if (name === "event") {
      return "Event";
    }
    if (name === "membership") {
      return "Membership";
    }
    if (name === "online") {
      return "Online Virtual";
    }
    if (name === "invoice") {
      return "Invoice";
    }
  };

  useEffect(() => {
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" },
      { label: "Tax Summary", to: "/reports/tax-summary" }
    ]);

    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/partners/order-tracking`
    );

    console.log("response", response);

    const allOrders = cleanName([
      ...response.data.data.Once.payments,
      ...response.data.data.Recurring.payments
    ])
      .filter(order => (parseFloat(order.modifiers.tax) !== 0 && order.modifiers.tax !== null && order.modifiers.tax !== undefined))
      .sort((a, b) => a.item.localeCompare(b.item));
    setAllOrders(allOrders);

    console.log("All Orders", allOrders)
    const allListings = allOrders
      .reduce((prev, curr) => {
        if (
          prev.find(
            l =>
              l.listingId === curr.listingId &&
              l.listingType === curr.listingType
          )
        ) {
          return prev;
        }
        return [
          ...prev,
          {
            listingId: curr.listingId,
            item: curr.item,
            listingType: curr.listingType
          }
        ];
      }, [])
      .filter(e => e.listingId);
    setAllListings(allListings);
    setSelectedListings(allListings.filter(l => l.listingType === listingType));
  };

  useEffect(() => {
    console.log("selected liseintgs", selectedListings);
    const filteredOrders = allOrders.filter(order => {
      if (order.listingType !== listingType) {
        return false;
      }

      if (
        new Date(order.date) > addDays(new Date(dateTo), 1) ||
        new Date(order.date) < new Date(dateFrom)
      ) {
        return false;
      }

      if (!selectedListings.find(e => e.listingId === order.listingId)) {
        return false;
      }

      return true;
    });
    setFilteredOrders(filteredOrders);
  }, [allOrders, dateFrom, dateTo, listingType, selectedListings]);

  /**
   * Displays the table rows for the tax summary table, for a specific listing type.
   *
   * Filters the order array to match the selected filter inputs.
   *
   * @returns {Array} Array representing the rows of the table.
   */
  const getTaxData = () => {
    return filteredOrders.map(row => [
      row.purchaser,
      convertListing(row.listingType),
      row.item,
      formatMoney(row.modifiers.tax / 100)
    ]);
  };

  /**
   * Gets total tax by aggregating all order data.
   *
   * @returns {Number} Total revenue.
   */
  const displayTotalTax = () => {
    return formatMoney(
      filteredOrders.reduce((a, c) => {
        let taxAmount = (c.modifiers.tax === null || c.modifiers.tax === undefined) ? 0 : parseFloat(c.modifiers.tax);
        return a + taxAmount;
      }, 0) / 100
    );
  };

  const csvHeaders = [
    { label: "Customer Name", key: "name" },
    { label: "Listing Type", key: "type" },
    { label: "Transaction Date", key: "date" },
    { label: "Product", key: "item" },
    { label: "Tax Paid", key: "tax" }
  ];

  /**
   * Gets the CSV for the CSVLink component to use.
   *
   * @returns {Array<Object>} Each property of each object corresponds to a column.
   */
  const getCsvData = () => {
    let csvRows = [];
    filteredOrders.map(row => {
      csvRows.push({
        name: row.purchaser,
        type: row.listingType,
        date: format(new Date(row.date), "MMM d, yyyy"),
        item: row.item,
        tax: formatMoney(row.modifiers.tax / 100)
      });
    });
    csvRows.push({
      name: "TOTAL",
      tax: displayTotalTax()
    });
    return csvRows;
  };

  let reportSubtitles = [
    orgData.organization_title,
    `For ${format(parse(dateFrom, "yyyy-MM-dd", new Date()), "MMM d, yyyy")}
    - 
    ${format(parse(dateTo, "yyyy-MM-dd", new Date()), "MMM d, yyyy")} 
    `
  ];

  return (
    <div className="admin">
      <div className="container-fluid adminprofiles">
        <div className="row cont">
          <div className="col-md-6">
            <h1>Tax Summary</h1>
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-section">
            <DateFilter
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
            />
            <div className="filter-item grid-new-col">
              <label htmlFor="client_name" className="filter-label">
                Listing Type
              </label>
              <select
                name="client_name"
                id="client_name"
                type="text"
                className="form-control filter-input"
                onChange={e => {
                  setListingType(e.target.value);
                  // Reset the listing select field on change.
                  setSelectedListings([]);
                }}
              >
                <option value="program">Program</option>
                <option value="event">Events</option>
                <option value="membership">Memberships</option>
                <option value="online">Online Virtual</option>
                <option value="invoice">Invoices</option>
              </select>
              <FilterButton
                label="Select Listings"
                checkedOptions={selectedListings}
                setCheckedOptions={setSelectedListings}
                allOptions={allListings.filter(
                  e => e.listingType === listingType
                )}
                stringProperty="item"
              />
            </div>
          </div>
        </div>
        <ReportContainer
          title="Tax Summary"
          subtitles={reportSubtitles}
          csvHeaders={csvHeaders}
          csvData={getCsvData()}
          fileName={`Tax_${dateFrom}-${dateTo}.csv`}
        >
          <div className="report-table-large">
            <ReportTable
              headings={[
                { label: "Summary", className: "table-item-bold" },
                {
                  label: "Total Taxed Amount",
                  className: "table-item-money",
                  style: { textAlign: "right" }
                }
              ]}
              body={[["Total Tax", displayTotalTax()]]}
              noDataText="No tax data found."
            />
            <ReportTable
              headings={[
                { label: "Customer" },
                { label: "Type" },
                { label: "Item" },
                {
                  label: "Tax Paid",
                  className: "table-item-money",
                  style: { textAlign: "right" }
                }
              ]}
              body={getTaxData()}
              noDataText="No tax data found."
            />
          </div>
        </ReportContainer>
      </div>
    </div>
  );
};

export default TaxSummary;
