import React from "reactn";
import PropTypes from "prop-types";
import { Route, Redirect, Switch } from "react-router-dom";
// import { setAdmin } from "../store";

class AdminRoute extends React.PureComponent {
  constructor(props) {
    super(props);
    document.getElementsByTagName("html")[0].className = "console-pages";
    document.getElementsByTagName("html")[0].style.fontSize = "initial";
    document.getElementsByTagName("html")[0].style.lineHeight = "initial";
    // setAdmin();
  }

  render() {
    const {
      component: Component,
      isAuthenticated,
      admin,
      to,
      ...rest
    } = this.props;
    return (
      <Switch>
        <Route
          {...rest}
          render={props =>
            admin ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: to,
                  state: { redirect: props.location.pathname, admin }
                }}
              />
            )
          }
        />
      </Switch>
    );
  }
}

AdminRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  // isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object,
  to: PropTypes.string
};

AdminRoute.defaultProps = {
  to: "/"
};

export default AdminRoute;
