import React from "reactn";
import FranchiserRoute from "../../routes/franchiserRoute";
import Sidebar from "../common/SideBarPartner";
import TopBar from "./TopBarFranchise";
import SideMenu from "./SideMenuFranchise";

class FranchiserLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.basePath = "/franchiser";

    this.state = {
      Routes: []
    };
  }

  async componentDidMount() {
    if (
      localStorage.getItem("franchiser_token") &&
      !this.global.franchiseUser
    ) {
      await this.dispatch.validateTokenFranchiser();

      if (
        !this.global.franchiseUser ||
        this.global.franchiseUser.accountType !== "Franchiser"
      ) {
        this.props.history.push("/franchise/log-in");
      }
    } else if (
      !localStorage.getItem("franchiser_token") &&
      !this.global.franchiseUser
    ) {
      this.props.history.push(
        `/sign-in?follow=${this.props.location.pathname}`
      );
    }
  }

  render() {
    const { path } = this.props.match;

    return (
      <>
        {this.global.franchiseUser &&
        this.global.franchiseUser.accountType === "Franchiser" ? (
          <>
            <Sidebar path={path} Menu={SideMenu} />
            <div
              id="content-wrapper"
              className="d-flex flex-column"
              onClick={() => this.setGlobal({ sidenavShown: false })}
            >
              <div id="content">
                <TopBar ConsoleRoute={this.basePath} />

                {this.global.routes.map((route, i) => (
                  <FranchiserRoute
                    educator={false}
                    key={i}
                    franchiser={true}
                    exact
                    path={`${this.basePath}${route.path}`}
                    component={route.component}
                  />
                ))}
              </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
              <i className="fas fa-angle-up" />
            </a>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default FranchiserLayout;
