import React from "reactn";
import axios from "axios";
import { withRouter } from "react-router-dom";
import EditClassificationsModal from "./EditClassificationsModal";
import AddDomainModal from "./AddDomainModal";
import AddSkillsModal from "./AddSkillsModal";

class ObservationCustomizations extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editClassificationsModal: true,
      addDomainModal: false,
      addSkillsModal: false,
      data: null
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {
    await this.fetchInitialData()
  }

  componentDidUpdate() {
    // reset editClassificationModal to be open if all other modals are closed
    if (!this.state.editClassificationsModal &&
      !this.state.addDomainModal &&
      !this.state.addSkillsModal) {
      this.setState({
        editClassificationsModal: true
      })
    }
  }

  async fetchInitialData() {
    try {
      const ep = `${process.env.REACT_APP_API}/partner/observations/customization`;
      const results = await axios.get(ep);

      if (results.data.success) {
        this.setState({
          ...this.state,
          data: results.data.data
        })
      }
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    return (
      <>
        {this.state.editClassificationsModal && (
          <EditClassificationsModal
            data={this.state.data}
            onClose={() => {
              this.setState({
                editClassificationsModal: false,
              })
              this.props.onClose();
            }}
            nextPage={async () => {
              await this.fetchInitialData()
              this.setState({
                editClassificationsModal: false,
                addDomainModal: true
              })
            }}
          />
        )}
        {this.state.addDomainModal && this.state.data && (
          <AddDomainModal
            data={this.state.data}
            onClose={() => {
              this.setState({
                addDomainModal: false,
              })
              this.props.onClose();
            }}
            nextPage={async () => {
              await this.fetchInitialData()
              this.setState({
                addDomainModal: false,
                addSkillsModal: true
              })
            }}
            backPage={async () => {
              await this.fetchInitialData()
              this.setState({
                addDomainModal: false,
                editClassificationsModal: true,
              })
            }}
          />
        )}
        {/* {this.state.addSkillsModal && this.state.data && (
          <AddSkillsModal
            data={this.state.data}
            onClose={() => {
              this.setState({
                addSkillsModal: false
              })
              this.props.onClose();
            }}
            backPage={async () => {
              await this.fetchInitialData()
              this.setState({
                addSkillsModal: false,
                addDomainModal: true
              })
            }}
          />
        )} */}
      </>
    )
  }
}

export default withRouter(ObservationCustomizations);