import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import BaseModal from "./baseModal/BaseModal";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { DATE_PICKER, DROPDOWN, STRING_INPUT } from "./baseModal/FieldTypes";
import { notEmptyString } from "./baseModal/validators";

class AddTaskModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      users: [],
      assigned: null,
      priority: "",
      due: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/roles`;
    const users = await axios.get(ep);
    if (users.data.success) {
      this.setState({
        users: users.data.data.users
      });
    }
  }

  addTask = async e => {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/partners/tasks`;
    const close = { ...this.state };
    delete close.users;
    if (close.assigned === "") {
      close.assigned = this.global.dsUser.user_id;
    }
    // Store id of user who created task
    close.createdBy = this.global.dsUser.user_id;
    const res = await axios.post(ep, {
      ...close
    });
    if (res?.data?.success) {
      toast.success("Task Added");
      this.props.tasks(res.data.data);
      this.props.onClose();
    } else {
      toast.error("Something Went Wrong");
    }
  };

  getAssignOptions = () => {
    return [
      { value: "", text: this.global.dsUser.name },
      ...this.state.users
        .filter(f => !f.isArchived && f.sub_user)
        .map(({ id, username }) => ({ value: id, text: username }))
    ];
  };

  getBaseModalFields = () => {
    const descriptionField = {
      type: STRING_INPUT,
      data: {
        name: "Description",
        required: true,
        placeholder: "Description . . .",
        handleChange: e => this.setState({ description: e.target.value }),
        value: this.state.description
      },
      validators: { validateSubmit: notEmptyString(this.state.description) }
    };

    const dueField = {
      type: DATE_PICKER,
      data: {
        name: "Due Date",
        required: true,
        minDate: new Date(),
        placeholder: "Pick a date",
        dateFormat: "MMMM d, yyyy",
        value: this.state.due,
        handleChange: date => this.setState({ due: date })
      },
      validators: { validateSubmit: notEmptyString(this.state.due) }
    };

    const priorityField = {
      type: DROPDOWN,
      data: {
        name: "Priority",
        placeholder: "Select priority",
        required: true,
        handleChange: e => this.setState({ priority: e.target.value }),
        value: this.state.priority,
        choices: [
          { value: 1, text: "Low" },
          { value: 2, text: "Medium" },
          { value: 3, text: "High" }
        ]
      },
      validators: { validateSubmit: notEmptyString(this.state.priority) }
    };

    const assignField = {
      type: DROPDOWN,
      data: {
        name: "Assigned To",
        placeholder: "Select assigned",
        required: true,
        handleChange: e => this.setState({ assigned: e.target.value }),
        choices: this.getAssignOptions()
      },
      validators: { validateSubmit: () => this.state.assigned !== null }
    };

    return [descriptionField, dueField, priorityField, assignField];
  };

  getBaseModalButtons = () => {
    const createButton = {
      name: "Create",
      handleClick: this.addTask,
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit"
    };

    return [createButton];
  };

  getBaseModalProps = () => {
    return {
      title: "New Task",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(AddTaskModal);
