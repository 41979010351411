import React, { useState } from "reactn";
import "./AttendanceCalendarModal.css";
import StatusSelectionModal from "./StatusSelectionModal/StatusSelectionModal";
import ReducedDayModal from "./ReducedDayModal/ReducedDayModal";
import AbsentModal from "./AbsentModal/AbsentModal";
import ExtendedDayModal from "./ExtendedDayModal/ExtendedDayModal";

const AttendanceCalendarModal = ({ onClose, child, setChildStatus }) => {
  const [useStatus, setStatus] = useState(0);

  const goBack = () => {
    setStatus(0);
  };

  const newStatus = status => {
    return {
      status: status,
      percent: 0,
      notified: true,
      extendedHours: 0,
      extendedMinutes: 0,
      fee: 0
    };
  };

  if (!child.status) {
    child.status = { ...newStatus("Select") };
  }

  const getModalScreen = () => {
    switch (useStatus) {
      case 0:
        return (
          <StatusSelectionModal
            {...{
              child,
              onClose,
              setModalScreen: setStatus,
              setChildStatus
            }}
          />
        );
      case 1:
        setChildStatus({
          ...child,
          status: { ...child.status, ...newStatus("Present") }
        });
        onClose();
        break;
      case 2:
        if (child.status !== "Reduced")
          child.status = { ...child.status, ...newStatus("Reduced") };
        return (
          <ReducedDayModal
            {...{
              child: child,
              onClose,
              setModalScreen: setStatus,
              setChildStatus,
              goBack
            }}
          />
        );
      case 3:
        if (child.status !== "Extended")
          child.status = { ...child.status, ...newStatus("Extended") };
        return (
          <ExtendedDayModal
            {...{
              child: child,
              onClose,
              setModalScreen: setStatus,
              setChildStatus,
              goBack
            }}
          />
        );
      case 4:
        setChildStatus({
          ...child,
          status: { ...child.status, ...newStatus("Absent") }
        });
        onClose();
        break;
      case 5:
        setChildStatus({
          ...child,
          status: { ...child.status, ...newStatus("Late") }
        });
        onClose();
        break;
      default:
        return (
          <StatusSelectionModal
            {...{
              child,
              onClose,
              setModalScreen: setStatus,
              setChildStatus
            }}
          />
        );
    }
  };

  return (
    <div id="modal-bg">
      <div className="boxforc" style={{}} onClick={e => e.stopPropagation()}>
        {getModalScreen()}
      </div>
    </div>
  );
};

export default AttendanceCalendarModal;
