import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import axios from "axios";
import { format } from "date-fns";
import InviteUserModal from "../../components/modals/InviteUserModal";
import EditUserModal from "../../components/modals/EditUserModal";
import CreateRoleModal from "../../components/modals/CreateRoleModal";
import EditRoleModal from "../../components/modals/EditRoleModal";
import SendInviteModal from "../../components/modals/SendInviteModal";
import EmailInviteModal from "../../components/modals/EmailInviteModal";
import ResendInviteModal from "../../components/modals/ReSendInviteModal";
import ResendPasswordResetModal from "../../components/modals/ReSendPasswordResetModal";
import EditScheduleModal from "../../components/modals/EditScheduleModal";
import Avatar from "@material-ui/core/Avatar";
import {
  Avatar1b,
  Avatar2b,
  Avatar3b,
  Avatar4b,
  Avatar5b,
  Avatar6b,
  Avatar7b,
  Avatar8b,
  Avatar9b,
  Megaphone
} from "../../assets";
import CreateIcon from "@material-ui/icons/Create";
import { IconButton, withStyles } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Delete } from "@material-ui/icons";
import ConfirmArchiveModal from "../../components/modals/ConfirmArchiveModal";
import { SettingsBackupRestore as Restore } from "@material-ui/icons";
import ReactTooltip from "react-tooltip";
import "../../assets/css/componentSpecificCss/userManagement.css";

const styles = {
  iconButton: {
    backgroundColor: "transparent !important",
    padding: "1em !important",
    color: "rgba(0, 0, 0, 0.54) !important",
    "&:focus": {
      outline: "none !important"
    }
  },
  avatar: {
    width: "50px",
    height: "50px"
  },
  item: {
    padding: "5px 10px",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "13px"
  },
  list: {
    height: "60px",
    padding: "0px"
  },
  scheduleButton: {
    width: "30px",
    height: "30px"
  }
};

class UserManagement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: { "Role Permissions": [], "User Names": [], Archived: [] },
      displayData: { "Role Permissions": [], "User Names": [] },
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      selectedUser: null,
      avatarMap: {
        "1b": Avatar1b,
        "2b": Avatar2b,
        "3b": Avatar3b,
        "4b": Avatar4b,
        "5b": Avatar5b,
        "6b": Avatar6b,
        "7b": Avatar7b,
        "8b": Avatar8b,
        "9b": Avatar9b
      },
      popoverAnchorE1: null,
      openSendInviteModal: false,
      openEmailInviteModal: false,
      showConfirmationModal: false,
      openResendPasswordResetModal: false,
      roleToDelete: 0,
      showConfirmRestoreModal: false,
      idToEdit: null,
      openEditScheduleModal: false,
      dayToEdit: "Monday",
      schedule: []
    };

    this.tabs = ["User Names", "Role Permissions", "Archived"];
    this.filterListLabels = ["View / Edit", "Delete"];
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Team Management", to: "/team-management" }
      ]
    });
  }

  isAdmin() {
    if (this.global.dsUser.sub_user === false) {
      return true
    }
    if (this.global.dsUser.role) {
      const role = this.state.data["Role Permissions"].filter(
        f => f.id == this.global.dsUser.role)[0];

      if (role) {
        if (role.role === "Administrator") {
          return true
        }
      }
    }

    return false
  }

  getTabs() {
    if (this.isAdmin()) {
      return this.tabs
    }

    return this.tabs.filter(tab =>
      tab !== "Role Permissions" && tab !== "Archived"
    )
  }

  headings() {
    const headings = {
      "User Names": [
        {
          id: "avatar",
          label: "",
          customCell: (r, i) => (
            <td
              key={i}
              style={{ paddingRight: "0px", height: "50px", width: "50px" }}
            >
              <Avatar
                src={this.state.avatarMap[r.avatar] || Megaphone}
                classes={{ root: this.props.classes.avatar }}
              />
            </td>
          )
        },
        {
          id: "username",
          label: "",
          customCell: this.displayProfileInfo
        },
        {
          id: "email",
          label: "Email",
          customStyle: { fontSize: "13px" },
          customCell: (r, i) => (
            <td key={i} style={{ color: "#808080", fontWeight: 600 }}>
              {r.email}
            </td>
          )
        },
        {
          id: "schedule",
          label: "Schedule",
          customCell: (r, i) => {
            return r.sub_user ? (
              <td key={i} style={{ color: "#808080", fontWeight: 600 }}>
                <span>
                  <button
                    className={`${this.props.classes.scheduleButton} scheduleBtn`}
                    style={{
                      backgroundColor: this.isWorkingDay(r.id, 0)
                        ? "lightgreen"
                        : "lightgrey"
                    }}
                  // onClick={() => {
                  //   this.setState({
                  //     openEditScheduleModal: true,
                  //     idToEdit: r.id,
                  //     dayToEdit: "Sunday"
                  //   });
                  // }}
                  >
                    Su
                  </button>
                </span>
                <span>
                  <button
                    className={`${this.props.classes.scheduleButton} scheduleBtn`}
                    style={{
                      backgroundColor: this.isWorkingDay(r.id, 1)
                        ? "lightgreen"
                        : "lightgrey"
                    }}
                  // onClick={() => {
                  //   this.setState({
                  //     openEditScheduleModal: true,
                  //     idToEdit: r.id,
                  //     dayToEdit: "Monday"
                  //   });
                  // }}
                  >
                    M
                  </button>
                </span>
                <span>
                  <button
                    className={`${this.props.classes.scheduleButton} scheduleBtn`}
                    style={{
                      backgroundColor: this.isWorkingDay(r.id, 2)
                        ? "lightgreen"
                        : "lightgrey"
                    }}
                  // onClick={() => {
                  //   this.setState({
                  //     openEditScheduleModal: true,
                  //     idToEdit: r.id,
                  //     dayToEdit: "Tuesday"
                  //   });
                  // }}
                  >
                    Tu
                  </button>
                </span>
                <span>
                  <button
                    className={`${this.props.classes.scheduleButton} scheduleBtn`}
                    style={{
                      backgroundColor: this.isWorkingDay(r.id, 3)
                        ? "lightgreen"
                        : "lightgrey"
                    }}
                  // onClick={() => {
                  //   this.setState({
                  //     openEditScheduleModal: true,
                  //     idToEdit: r.id,
                  //     dayToEdit: "Wednesday"
                  //   });
                  // }}
                  >
                    W
                  </button>
                </span>
                <span>
                  <button
                    className={`${this.props.classes.scheduleButton} scheduleBtn`}
                    style={{
                      backgroundColor: this.isWorkingDay(r.id, 4)
                        ? "lightgreen"
                        : "lightgrey"
                    }}
                  // onClick={() => {
                  //   this.setState({
                  //     openEditScheduleModal: true,
                  //     idToEdit: r.id,
                  //     dayToEdit: "Thursday"
                  //   });
                  // }}
                  >
                    Th
                  </button>
                </span>
                <span>
                  <button
                    className={`${this.props.classes.scheduleButton} scheduleBtn`}
                    style={{
                      backgroundColor: this.isWorkingDay(r.id, 5)
                        ? "lightgreen"
                        : "lightgrey"
                    }}
                  // onClick={() => {
                  //   this.setState({
                  //     openEditScheduleModal: true,
                  //     idToEdit: r.id,
                  //     dayToEdit: "Friday"
                  //   });
                  // }}
                  >
                    F
                  </button>
                </span>
                <span>
                  <button
                    className={`${this.props.classes.scheduleButton} scheduleBtn`}
                    style={{
                      backgroundColor: this.isWorkingDay(r.id, 6)
                        ? "lightgreen"
                        : "lightgrey"
                    }}
                  // onClick={() => {
                  //   this.setState({
                  //     openEditScheduleModal: true,
                  //     idToEdit: r.id,
                  //     dayToEdit: "Saturday"
                  //   });
                  // }}
                  >
                    Sa
                  </button>
                </span>
              </td>
            ) : (
              <td key={i}></td>
            );
          }
        },
        {
          id: "role",
          label: "Admin",
          customCell: (r, i) => (
            <td key={i} style={{ color: "#808080", fontWeight: 600 }}>
              {!r.role ||
                this.state.data["Role Permissions"].filter(
                  role => role.id === r.role
                )[0].role === "Administrator"
                ? "Yes"
                : "No"}
            </td>
          )
        },
        {
          id: "recemail",
          label: "Status",
          customCell: this.displayStatus
        },
        {
          id: "edit",
          label: "",
          customCell: (r, i) =>
            r.sub_user ? this.editButton(r) : <td key={i}></td>
        }
      ],
      "Role Permissions": [
        { id: "role", label: "Roles" },
        {
          id: "access",
          label: "Access Points",
          customCell: (r, i) => {
            const rolesToShow = {};
            Object.keys(r.accesspoints).forEach(e =>
              Object.keys(r.accesspoints[e]).map(
                k =>
                  (r.accesspoints[e][k].read || r.accesspoints[e][k].edit) &&
                  (rolesToShow[e] = r.accesspoints[e][k])
              )
            );
            const arrResult = Object.keys(rolesToShow);
            return (
              <td key={i}>
                {arrResult.map((val, key) =>
                  key + 1 < arrResult.length
                    ? rolesToShow[val].edit
                      ? val + ", "
                      : `${val} - Read Only, `
                    : rolesToShow[val].edit
                      ? val + ""
                      : `${val} - Read Only`
                )}
              </td>
            );
          }
        },
        {
          id: "edit",
          label: "",
          customCell: (r, i) => {
            if (
              this.global.dsUser.sub_user === false ||
              (this.global.dsUser.role &&
                this.state.data["Role Permissions"].filter(
                  f => f.id == this.global.dsUser.role
                )[0].role === "Administrator")
            ) {
              return r.role !== "Owner" ? (
                <td key={i}>
                  <IconButton
                    className={this.props.classes.iconButton}
                    onClick={_ =>
                      this.setState({ editRoleModal: true, selectedRole: r })
                    }
                  >
                    <CreateIcon />
                  </IconButton>
                </td>
              ) : (
                <td key={i}></td>
              );
            } else {
              return <td key={i}></td>;
            }
          }
        },
        {
          id: "delete",
          label: "",
          customCell: (r, i) => {
            if (
              this.global.dsUser.sub_user === false ||
              (this.global.dsUser.role &&
                this.state.data["Role Permissions"].filter(
                  f => f.id == this.global.dsUser.role
                )[0].role === "Administrator")
            ) {
              return r.role !== "Administrator" && r.role !== "Owner" ? (
                <td key={i}>
                  <IconButton
                    className={this.props.classes.iconButton}
                    onClick={_ =>
                      this.setState({
                        showConfirmationModal: true,
                        roleToDelete: r
                      })
                    }
                  >
                    <Delete />
                  </IconButton>
                </td>
              ) : (
                <td key={i}></td>
              );
            } else {
              return <td key={i}></td>;
            }
          }
        }
      ],
      Schedules: [
        {
          id: "avatar",
          label: "",
          customCell: (r, i) => (
            <td
              key={i}
              style={{ paddingRight: "0px", height: "50px", width: "50px" }}
            >
              <Avatar
                src={this.state.avatarMap[r.avatar] || Megaphone}
                classes={{ root: this.props.classes.avatar }}
              />
            </td>
          )
        }
      ],
      Archived: [
        {
          id: "avatar",
          label: "",
          customCell: (r, i) => (
            <td
              key={i}
              style={{ paddingRight: "0px", height: "50px", width: "50px" }}
            >
              <Avatar
                src={this.state.avatarMap[r.avatar] || Megaphone}
                classes={{ root: this.props.classes.avatar }}
              />
            </td>
          )
        },
        {
          id: "username",
          label: "",
          customCell: this.displayProfileInfo
        },
        {
          id: "email",
          label: "Email",
          customStyle: { fontSize: "13px" },
          customCell: (r, i) => (
            <td key={i} style={{ color: "#808080", fontWeight: 600 }}>
              {r.email}
            </td>
          )
        },
        {
          id: "role",
          label: "Admin",

          customCell: (r, i) => (
            <td key={i} style={{ color: "#808080", fontWeight: 600 }}>
              {!r.role ||
                this.state.data["Role Permissions"].filter(
                  role => role.id === r.role
                )[0].role === "Administrator"
                ? "Yes"
                : "No"}
            </td>
          )
        },
        {
          id: "recemail",
          label: "Status",
          customCell: this.displayStatus
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <div className="action-group">
                  <Restore
                    style={{ color: "#BBB" }}
                    onClick={_ => {
                      this.setState({
                        showConfirmRestoreModal: true,
                        idToEdit: r.id
                      });
                    }}
                    data-for="restore"
                    data-tip
                  />
                  <ReactTooltip
                    id="restore"
                    place="top"
                    effect="solid"
                    type="info"
                  >
                    Restore User
                  </ReactTooltip>
                </div>
              </td>
            );
          }
        }
      ]
    };

    if (!this.isAdmin()) {
      delete headings["Role Permissions"]
      delete headings["Archived"]
    }

    return headings
  }

  async deleteRole() {
    const ep = `${process.env.REACT_APP_API}/partners/roles/${this.state.roleToDelete.id}`;
    const results = await axios.delete(ep);

    if (results.data.success) {
      this.fetchInitialData();
    }
  }

  async handleDelete() {
    const ep = `${process.env.REACT_APP_API}/partners/users/archive/${this.state.selectedItem.id}`;
    const results = await axios.put(ep);

    if (results.data.success) {
      this.fetchInitialData();
    }
  }

  handleOptionsToggle(value) {
    if (value === "View / Edit") {
      this.props.history.push({
        pathname: `/partners/team-management/${this.state.selectedItem.id}`,
        state: {
          username: this.state.selectedUser.username,
          userId: this.state.selectedItem.id
        }
      });
    } else {
      this.state.selectedItem.sub_user && this.handleDelete();
      this.handleClose();
    }
  }

  handleClose() {
    this.setState({
      popoverAnchorE1: null
    });
  }

  handleOptionsClick(event, r) {
    this.setState({
      popoverAnchorE1: event.currentTarget,
      selectedItem: r
    });
  }

  editButton = (r, i) => {
    return (
      <td key={i}>
        {(this.isAdmin() || r.id === this.global.dsUser.user_id) && <div
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "10px",
            color: "white",
            padding: "5px",
            background: "#BEBEBE",
            transition: "all 0.15s ease 0s",
            borderRadius: "5px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-evenly",
            lineHeight: "24px"
          }}
          aria-describedby={
            Boolean(this.state.popoverAnchorE1) ? "simple-popover" : undefined
          }
          onClick={event => {
            this.setState({ selectedUser: r });
            this.handleOptionsClick(event, r);
          }}
        >
          Options{" "}
          <i
            className="fas fa-sort-down"
            style={{ color: "#828282", fontSize: "18px" }}
          ></i>
        </div>}
        <Popover
          id={
            Boolean(this.state.popoverAnchorE1) ? "simple-popover" : undefined
          }
          // classes={{ root: this.props.classes.popover }}
          open={Boolean(this.state.popoverAnchorE1)}
          anchorEl={this.state.popoverAnchorE1}
          onClose={this.handleClose.bind(this)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <List classes={{ root: this.props.classes.list }}>
            <ListItem
              key={"View / Edit"}
              role={undefined}
              dense
              button
              onClick={() => this.handleOptionsToggle("View / Edit")}
              classes={{ root: this.props.classes.item }}
            >
              <ListItemText id={`view${i}`} primary={"View / Edit"} />
            </ListItem>
            {r.sub_user && (
              <ListItem
                key={"Delete"}
                role={undefined}
                dense
                button
                onClick={_ => this.handleOptionsToggle("Delete")}
                classes={{ root: this.props.classes.item }}
              >
                <ListItemText id={`delete-${i}`} primary={"Delete"} />
              </ListItem>
            )}
          </List>
        </Popover>
      </td>
    );
  };

  displayProfileInfo = (r, i) => {
    if (!r.role || r.role === undefined) {
      return (
        <td key={`${i}-profile`}>
          <span
            style={{
              display: "block",
              color: "#61616F",
              fontWeight: 700,
              fontSize: "16px",
              marginBottom: "4px"
            }}
          >
            {r.username}
          </span>
          <span
            style={{
              display: "block",
              color: "#817f7f",
              fontSize: "12px",
              fontWeight: 600
            }}
          >
            Admin (owner)
          </span>
          <span
            style={{
              display: "block",
              color: "#817f7f",
              fontSize: "12px",
              fontWeight: 600
            }}
          >
            {r.last_logged_in &&
              format(new Date(r.last_logged_in), "LLLL dd,yyyy")}
          </span>
        </td>
      );
    } else {
      return (
        <td key={`${i}-profile`}>
          <span
            style={{
              display: "block",
              color: "#61616F",
              fontWeight: 700,
              fontSize: "16px",
              marginBottom: "4px"
            }}
          >
            {r.username}
          </span>
          <span
            style={{
              display: "block",
              color: "#817f7f",
              fontSize: "12px",
              fontWeight: 600
            }}
          >
            {
              this.state.data["Role Permissions"].filter(
                role => role.id === r.role
              )[0].role
            }
          </span>
          <span
            style={{
              display: "block",
              color: "#817f7f",
              fontSize: "12px",
              fontWeight: 600
            }}
          >
            {r.last_logged_in &&
              format(new Date(r.last_logged_in), "LLLL dd,yyyy")}
          </span>
        </td>
      );
    }
  };

  displayStatus = (r, i) => (
    <td key={`${i}-status`}>
      {r.email && r.password && (
        <span style={{ display: "block", color: "#2BC3B4", fontWeight: 700 }}>
          Signed-Up
        </span>
      )}
      {r.email && r.token && !r.password && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ display: "block", color: "#2BC3B4", fontWeight: 700 }}>
            Invited
          </span>
          {!r.isArchived && (
            <div
              style={{
                fontFamily: "Open Sans, sans-serif",
                fontWeight: 700,
                fontSize: "10px",
                lineHeight: "12px",
                color: "#fff",
                padding: "5px",
                background: "#00AFEF",
                transition: "all 0.15s ease",
                borderRadius: "5px",
                cursor: "pointer",
                marginLeft: "34px"
              }}
              onClick={_ =>
                this.setState({ resendInviteModal: true, selectedUser: r })
              }
            >
              Re-send
            </div>
          )}
        </div>
      )}
      {r.email && r.token && r.password && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ display: "block", color: "#2BC3B4", fontWeight: 700 }}>
            Reset Password
          </span>
          {!r.isArchived && (
            <div
              style={{
                fontFamily: "Open Sans, sans-serif",
                fontWeight: 700,
                fontSize: "10px",
                lineHeight: "12px",
                color: "#fff",
                padding: "5px",
                background: "#00AFEF",
                transition: "all 0.15s ease",
                borderRadius: "5px",
                cursor: "pointer",
                marginLeft: "34px"
              }}
              onClick={_ =>
                this.setState({
                  openResendPasswordResetModal: true,
                  selectedUser: r
                })
              }
            >
              Re-send
            </div>
          )}
        </div>
      )}
      {!r.email && !r.token && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ color: "#817f7f" }}>Needs Email</span>
          {!r.isArchived && (
            <div
              style={{
                fontFamily: "Open Sans, sans-serif",
                fontWeight: 700,
                fontSize: "10px",
                lineHeight: "12px",
                color: "#fff",
                padding: "5px",
                background: "#00AFEF",
                transition: "all 0.15s ease",
                borderRadius: "5px",
                cursor: "pointer",
                marginLeft: "5px"
              }}
              onClick={_ =>
                this.setState({ openSendInviteModal: true, selectedUser: r })
              }
            >
              Invite
            </div>
          )}
        </div>
      )}
    </td>
  );

  async componentDidMount() {
    await this.fetchInitialData();
  }

  fetchInitialData = async () => {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/roles`;
    const results = await axios.get(ep);
    console.log("results", results);

    if (results.data.success) {
      let roles = [];
      results.data.data.roles.map(e => {
        if (
          e.role.startsWith("Program Specific") ||
          e.role.startsWith("Event Specific") ||
          e.role.startsWith("Online Specific") ||
          e.isArchived
        ) {
          return;
        } else {
          roles.push(e);
        }
      });

      //sort by updated at
      roles.sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );

      console.log("roles", roles);
      this.setState({
        data: {
          "Role Permissions": results.data.data.roles,
          "User Names": results.data.data.users.filter(e => !e.isArchived),
          Archived: results.data.data.users.filter(e => e.isArchived)
        },
        displayData: {
          "Role Permissions": roles,
          "User Names": results.data.data.users.filter(e => !e.isArchived),
          Archived: results.data.data.users.filter(e => e.isArchived)
        }
      });
      //this.setState({ data: data });
    }

    const scheduleEp = `${process.env.REACT_APP_API}/partners/users/schedule/all`;
    const schedule = await axios.get(scheduleEp);
    if (schedule.data.success) {
      const staffSchedulesArr = schedule.data.data;
      const filteredStaffSchedules = [[], [], [], [], [], [], []];
      staffSchedulesArr.forEach(scheduleItem => {
        const dayOfWeek = new Date(scheduleItem.start_date).getDay();
        filteredStaffSchedules[dayOfWeek].push(scheduleItem);
      });

      filteredStaffSchedules.forEach(subarray => {
        subarray.sort((a, b) => {
          return new Date(a.start_time) - new Date(b.start_time);
        });
      });

      this.setState({
        schedules: filteredStaffSchedules
      });

      this.setGlobal({ loading: false });
    }
  };

  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  isWorkingDay(staff, day) {
    const today = new Date();
    const currentDayOfWeek = today.getDay();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - currentDayOfWeek);
    startOfWeek.setDate(startOfWeek.getDate()); // Add weeks to startOfWeek
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week

    const weekDatesFull = [];
    const startDay = new Date(startOfWeek);

    while (startDay <= endOfWeek) {
      const year = startDay.getFullYear();
      const month = String(startDay.getMonth() + 1).padStart(2, "0");
      const day = String(startDay.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      weekDatesFull.push(formattedDate);
      startDay.setDate(startDay.getDate() + 1);
    }

    let isWorkingToday = false;

    if (this.state.schedules) {
      this.state.schedules[day].map(schedule => {
        if (
          schedule.dsUserId === staff &&
          schedule.start_date.substring(0, 10) === weekDatesFull[day] &&
          !schedule.isArchived
        ) {
          isWorkingToday = true;
        }
      });
    }

    return isWorkingToday;
  }

  /**
   * Makes request to backend to archive/unarchive user.
   * Uses state to determine options (what to delete, archive/unarchive).
   */
  archiveUser = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/users/restore/${this.state.idToEdit}`;
    const results = await axios.put(ep);

    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  render() {
    // console.log('this.global', this.global);
    console.log("this.state", this.state);

    return (
      <div className="admin">
        {this.state.inviteModal && (
          <InviteUserModal
            onClose={_ => this.setState({ inviteModal: false })}
            roles={this.state.data["Role Permissions"]}
            user={r => this.fetchInitialData()}
          />
        )}
        {this.state.editUserModal && (
          <EditUserModal
            onClose={_ => this.setState({ editUserModal: false })}
            roles={this.state.data["Role Permissions"]}
            selectedUser={this.state.selectedUser}
            user={_ => this.fetchInitialData()}
          />
        )}
        {this.state.roleModal && (
          <CreateRoleModal
            onClose={_ => this.setState({ roleModal: false })}
            role={r => {
              this.setState({
                data: {
                  ...this.state.data,
                  "Role Permissions": [
                    ...this.state.data["Role Permissions"],
                    { ...r }
                  ]
                }
              });
              this.fetchInitialData();
            }}
          />
        )}
        {this.state.editRoleModal && (
          <EditRoleModal
            onClose={_ => this.setState({ editRoleModal: false })}
            selectedRole={this.state.selectedRole}
            role={r => this.fetchInitialData()}
          />
        )}
        {this.state.openSendInviteModal && (
          <SendInviteModal
            onClose={_ => this.setState({ openSendInviteModal: false })}
            selectedUser={this.state.selectedUser}
            openEmailInvite={_ => this.setState({ openEmailInviteModal: true })}
          />
        )}
        {this.state.openEmailInviteModal && (
          <EmailInviteModal
            onClose={_ => this.setState({ openEmailInviteModal: false })}
            selectedUser={this.state.selectedUser}
            user={_ => this.fetchInitialData()}
          />
        )}
        {this.state.resendInviteModal && (
          <ResendInviteModal
            onClose={_ => this.setState({ resendInviteModal: false })}
            selectedUser={this.state.selectedUser}
            user={_ => this.fetchInitialData()}
          />
        )}
        {this.state.openResendPasswordResetModal && (
          <ResendPasswordResetModal
            onClose={() =>
              this.setState({ openResendPasswordResetModal: false })
            }
            selectedUser={this.state.selectedUser}
            user={() => this.fetchInitialData()}
          />
        )}
        {this.state.showConfirmationModal && (
          <ConfirmArchiveModal
            onClose={_ => this.setState({ showConfirmationModal: false })}
            onSubmit={_ => this.deleteRole()}
            toArchive={true}
            modalBody="Are you sure you would like to remove this role? This action cannot be undone."
          />
        )}

        {this.state.showConfirmRestoreModal && (
          <ConfirmArchiveModal
            onSubmit={() => {
              this.archiveUser();
            }}
            onClose={_ => this.setState({ showConfirmRestoreModal: false })}
            toArchive={false}
            modalBody={"Are you sure you want to restore this user?"}
            notAllowedToDelete={false}
          />
        )}

        {this.state.openEditScheduleModal && (
          <EditScheduleModal
            onClose={() => this.setState({ openEditScheduleModal: false })}
            dayToEdit={this.state.dayToEdit}
            idToEdit={this.state.idToEdit}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>Team Management</h1>
            </div>
            {this.isAdmin() && <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp">
                  <button
                    className="newapplications"
                    onClick={_ => this.setState({ inviteModal: true })}
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Add User
                  </button>
                </div>
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  <button
                    className="newapplications"
                    onClick={_ => this.setState({ roleModal: true })}
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Create Roles
                  </button>
                </div>
              </div>
            </div>}
          </div>
          <div className="cont">
            <div className="tablecont">
              <div className="table-responsive">
                <NewTabbedTable
                  tabs={this.getTabs()}
                  headings={this.headings()}
                  data={this.state.displayData}
                  actions={this.actions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(UserManagement);
