import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, SWITCH, DROPDOWN, TITLE } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import axios from "axios";

class EditMembershipPriceModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      price_per_term: this.props.selectedItem.price_per_term,
      membership_term: this.props.selectedItem.membership_term,
      auto_renew: this.props.selectedItem.auto_renew,
      isTaxEnabled: this.props.selectedItem.isTaxEnabled,
      taxInfo: this.props.selectedItem.taxInfo,
      auto_withdraw: this.props.selectedItem.auto_withdraw,
      id: this.props.selectedItem.id,
      price_stripe: this.props.selectedItem.price_stripe
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async update_plan() {
    const obj = { ...this.state, id: this.props.selectedItem.id };
    if (this.state.isTaxEnabled && this.state.taxInfo.length === 0) {
      toast.error("Please select a tax plan when tax is enabled");
      return;
    }

    this.setGlobal({
      membership: {
        ...this.global.membership,
        ["prices"]: this.global.membership.prices.map(item =>
          JSON.parse(item).id === obj.id ? JSON.stringify(obj) : item
        )
      }
    });

    await this.dispatch.saveMembership();
    this.props.onClose();
  }

  /// ================== NEW MODAL ========================== ///

  taxesOptions = () => {
    const global_organization = this.global.organizationInfo.stripeTaxId;
    console.log("global_organization", global_organization);
    const DATA = [];
    for (let i = 0; i < global_organization.length; i++) {
      DATA.push(JSON.parse(global_organization[i]));
    }

    return [
      ...DATA.map((e, i) => ({
        value: i,
        text: e.display_name + " " + e.jurisdiction + " " + e.percentage + "%"
      }))
    ];
  };

  termOptions = () => {
    return Array(
      { value: 1, key: 1, text: '1 Month' },
      { value: 3, key: 3, text: '3 Months' },
      { value: 6, key: 6, text: '6 Months' },
      { value: 12, key: 12, text: '12 Months' }
    )
  };

  getCurrTaxPlan() {
    for (let taxPlan of this.taxesOptions()) {
      if (
        this.state.taxInfo[0] &&
        taxPlan.text ===
          this.state.taxInfo[0].display_name +
            " " +
            this.state.taxInfo[0].jurisdiction +
            " " +
            this.state.taxInfo[0].percentage +
            "%"
      ) {
        return taxPlan.value;
      }
    }
  }

  getBaseModalFields() {
    const daysInput = {
      type: DROPDOWN,
      data: {
        name: "Membership Term",
        required: true,
        placeholder: "Please Select",
        value: this.state.membership_term,
        handleChange: e => {
          this.setState({
            membership_term: e.target.value
          });
        },
        choices: this.termOptions()
      },
      validators: { validateSubmit: () => this.state.membership_term !== "" }
    };

    const pricingTerm = {
      type: STRING_INPUT,
      data: {
        name: "Pricing for term",
        required: true,
        type: "number",
        isDisabled: false,
        handleChange: e => {
          let price = parseFloat(e.target.value || 0);
          let decimalValue = e.target.value.split(".")[1];

          if (decimalValue && decimalValue.length > 2) {
            return;
          } else if (!e.target.value.endsWith("0")) {
            price = Math.round(price * 100) / 100;
          } else {
            price = e.target.value;
          }

          this.setState({
            price_per_term: price.toString()
          });
        },
        value: this.state.price_per_term
      },
      validators: {
        validateSubmit: () =>
          this.state.price_per_term > 0 && this.state.price_per_term !== ""
      }
    };

    const autoRenew = {
      type: SWITCH,
      data: {
        name: "Subscription",
        isDisabled: true,
        checked: !!this.state.auto_renew,
        handleClick: e => this.setState({ auto_renew: !this.state.auto_renew })
      }
    };

    const autoPay = {
      type: SWITCH,
      data: {
        isDisabled: true,
        name: "Make Autopay Mandatory",
        isDisabled: true,
        checked: !!this.state.auto_withdraw,
        info: "Auto withdraw payments from customers for subscription plans. Organizer will be incurring a Credit Card processing fee.",
        handleClick: _ =>
          this.setState({ auto_withdraw: !this.state.auto_withdraw })
      }
    };

    const enableTaxes = {
      type: SWITCH,
      data: {
        name: "Enable Taxes",
        isDisabled: false,
        checked: this.state.isTaxEnabled,
        handleClick: e =>
          this.setState({
            isTaxEnabled: e.target.checked,
            taxInfo: []
          })
      }
    };

    const selectTaxes = {
      type: DROPDOWN,
      data: {
        name: "Tax Plan",
        required: false,
        placeholder: "Please Select",
        handleChange: e => {
          const item = JSON.parse(
            this.global.organizationInfo.stripeTaxId[e.target.value]
          );
          this.setState({
            taxInfo: [
              {
                id: item.id,
                percentage: item.percentage,
                jurisdiction: item.jurisdiction,
                display_name: item.display_name,
                description: item.description
              }
            ]
          });
        },
        value: this.getCurrTaxPlan(),
        choices: this.taxesOptions()
      }
    };

    const fields = [];

    fields.push(daysInput);
    fields.push(pricingTerm);
    fields.push(autoRenew);
    fields.push(autoPay);
    fields.push(enableTaxes);

    if (this.state.isTaxEnabled) {
      fields.push(selectTaxes);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.update_plan()
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Edit Pricing Plan",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ========================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(EditMembershipPriceModal);
