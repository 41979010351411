import React, { useState, useEffect, useGlobal } from "reactn";
import axios from "axios";
import SearchResult from "./SearchResult";
import SearchCategory from "./SearchCategory";
import "../../assets/css/componentSpecificCss/searchResultsList.css";

export const SearchResultsList = ({
  results,
  searchValue,
  fetchOrganizerData
}) => {
  const [partner, setPartner] = useGlobal("dsUser");

  const [manage, setManage] = useState([]);
  const [listings, setListings] = useState([]);
  const [enrollments, setEnrollments] = useState([]);
  const [leads, setLeads] = useState([]);
  const [fileUploads, setFileUploads] = useState([]);
  const [isEmpty, setIsEmpty] = useState(null);

  useEffect(() => {
    setManage([]);
    setListings([]);
    setFileUploads([]);
    setEnrollments([]);
    setLeads([]);
    setIsEmpty(results?.length <= 0);

    if (fetchOrganizerData) {
      fetchListingsData(searchValue);
    }

    if (
      !partner?.accesspoints ||
      (partner.accesspoints &&
        partner.accesspoints.Contacts &&
        partner.accesspoints.Contacts["Connections"]?.read)
    ) {
      results
        .sort((a, b) => {
          const nameA = a.displayName.toUpperCase(); // Convert to uppercase for case-insensitive sorting
          const nameB = b.displayName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
        .map(async user => {
          let dsUserId;
          if (!user.dsUserId) {
            dsUserId = await fetchUserId(user.email);
            if (dsUserId > -1) {
              await fetchEnrollmentsData(dsUserId);
              await fetchLeadsData(dsUserId, user.email);
              await fetchUploadsData(dsUserId);
            }
          } else {
            await fetchEnrollmentsData(user.dsUserId);
            await fetchLeadsData(user.dsUserId, user.email);
            await fetchUploadsData(user.dsUserId);
          }
        });
    }
  }, [searchValue, fetchOrganizerData, results]);

  const fetchUserId = async email => {
    const ep = `${process.env.REACT_APP_API}/partners/searchbar/user/${email}`;
    const result_ep = await axios.get(ep);

    return result_ep.data.data.length > 0 ? result_ep.data.data[0].id : -1;
  };

  const fetchListingsData = async searchValue => {
    const access = partner.accesspoints;

    if (searchValue === "" || searchValue.length < 3) {
      return false;
    }

    const sharedEp = `${process.env.REACT_APP_API}/partners/searchbar/shared/${partner.id}`;
    const sharedData = await axios.get(sharedEp);

    const filteredSharedListings = [];
    const filteredSharedManage = [];

    sharedData.data.data.filter(shared => {
      if (
        !shared.displayName
          .toLowerCase()
          .replace(/\s/g, "")
          .includes(searchValue)
      ) {
        return false;
      }

      if (!access) {
        filteredSharedListings.push(shared);
        filteredSharedManage.push(shared);
      } else if (shared.classificationType === "gift_cards") {
        if (access.Listings && access.Listings["Gift Card"]?.read) {
          filteredSharedListings.push(shared);
        }

        if (access.Manage && access.Manage["Gift Card"]?.read) {
          filteredSharedManage.push(shared);
        }
      } else if (shared.classificationType === "programs") {
        if (access.Listings && access.Listings["Programs"]?.read) {
          filteredSharedListings.push(shared);
        }

        if (access.Manage && access.Manage["Programs"]?.read) {
          filteredSharedManage.push(shared);
        }
      } else if (shared.classificationType === "online") {
        if (access.Listings && access.Listings["Online Virtual"]?.read) {
          filteredSharedListings.push(shared);
        }
      } else if (shared.classificationType === "events") {
        if (access.Listings && access.Listings["Events"]?.read) {
          filteredSharedListings.push(shared);
        }

        if (access.Manage && access.Manage["Events"]?.read) {
          filteredSharedManage.push(shared);
        }
      } else if (shared.classificationType === "memberships") {
        if (access.Listings && access.Listings["Memberships"]?.read) {
          filteredSharedListings.push(shared);
        }

        if (access.Manage && access.Manage["Memberships"]?.read) {
          filteredSharedManage.push(shared);
        }
      }

      return false;
    });

    if (searchValue === "" || searchValue.length < 3) {
      return false;
    }

    const manageEp = `${process.env.REACT_APP_API}/partners/searchbar/manage/${partner.id}`;
    const manageData = await axios.get(manageEp);

    const filteredManage = manageData.data.data.filter(manage => {
      if (
        !manage.displayName
          .toLowerCase()
          .replace(/\s/g, "")
          .includes(searchValue)
      ) {
        return false;
      }

      if (!access) {
        return true;
      } else if (manage.classificationType === "resources") {
        if (access.Manage && access.Manage["Resources"]?.read) {
          return access.Manage["Resources"].read
            ? manage.displayName
                .toLowerCase()
                .replace(/\s/g, "")
                .includes(searchValue)
            : false;
        }
      }
      return false;
    });

    if (searchValue === "" || searchValue.length < 3) {
      return false;
    }

    const listingsEp = `${process.env.REACT_APP_API}/partners/searchbar/listings/${partner.id}`;
    const listingsData = await axios.get(listingsEp);

    const filteredListings = listingsData.data.data.filter(listing => {
      if (
        !listing.displayName
          .toLowerCase()
          .replace(/\s/g, "")
          .includes(searchValue)
      ) {
        return false;
      }

      if (!access) {
        return true;
      } else if (listing.classificationType === "news-updates") {
        if (access.Listings && access.Listings["News & Updates"]) {
          return access.Listings["News & Updates"].read
            ? listing.displayName
                .toLowerCase()
                .replace(/\s/g, "")
                .includes(searchValue)
            : false;
        }
      } else if (listing.classificationType === "forms") {
        if (access.Listings && access.Listings["Forms"]) {
          return access.Listings["Forms"].read
            ? listing.displayName
                .toLowerCase()
                .replace(/\s/g, "")
                .includes(searchValue)
            : false;
        }
      } else if (listing.classificationType === "promotion") {
        if (access.Listings && access.Listings["Promotions"]) {
          return access.Listings["Promotions"].read
            ? listing.displayName
                .toLowerCase()
                .replace(/\s/g, "")
                .includes(searchValue)
            : false;
        }
      }

      return false;
    });

    setManage([...filteredSharedManage, ...filteredManage]);
    setListings([...filteredSharedListings, ...filteredListings]);

    if (
      filteredSharedManage.length > 0 ||
      filteredManage.length > 0 ||
      filteredSharedListings.length > 0 ||
      filteredListings.length > 0
    ) {
      setIsEmpty(false);
    }
  };

  const fetchEnrollmentsData = async dsUserId => {
    if (dsUserId) {
      const enrollmentsEp = `${process.env.REACT_APP_API}/partners/searchbar/enrollments/${dsUserId}`;
      const enrollmentsData = await axios.get(enrollmentsEp, {
        params: {
          partnerId: partner.id
        }
      });

      setEnrollments(prevEnrollments => [
        ...prevEnrollments,
        enrollmentsData.data.data.slice(0, 5) // Assuming the actual data is inside enrollmentsData.data
      ]);

      if (enrollmentsData.data.data.length > 0) {
        setIsEmpty(false);
      }
    } else {
      setEnrollments(prevEnrollments => [...prevEnrollments, []]);
    }
  };

  const fetchLeadsData = async (dsUserId, email) => {
    if (dsUserId) {
      const leadsEp = `${process.env.REACT_APP_API}/partners/searchbar/leads/${dsUserId}`;
      const leadsData = await axios.get(leadsEp, {
        params: {
          partnerId: partner.id,
          email: email
        }
      });

      setLeads(prevLeads => [...prevLeads, leadsData.data.data.slice(0, 5)]);
      if (leadsData.data.data.length > 0) {
        setIsEmpty(false);
      }
      return leadsData.data.data.length === 0;
    } else {
      setLeads(prevLeads => [...prevLeads, []]);
    }
  };

  const fetchUploadsData = async dsUserId => {
    if (dsUserId) {
      const uploadsEp = `${process.env.REACT_APP_API}/partners/milestones_submissions/get/all/${dsUserId}`;
      const uploadsData = await axios.get(uploadsEp);

      let fileArr = [];
      uploadsData.data.Participants.map(item => {
        if (item && item.file_uploads) {
          let count = 1;
          while (item.file_uploads["file_" + count]) {
            if (!item.file_uploads["file_" + count]?.isHidden) {
              fileArr.push({
                ...item,
                file_uploads: item.file_uploads["file_" + count]
              });
            }
            count++;
          }
        }
      });

      fileArr
        .sort((a, b) => {
          const dateA = new Date(a.updatedAt);
          const dateB = new Date(b.updatedAt);
          return dateB - dateA; // Sort in descending order (latest updatedAt first)
        })
        .slice(0, 5);

      setFileUploads(prevUploads => [...prevUploads, fileArr]);
      if (fileArr.length > 0) {
        setIsEmpty(false);
      }
    } else {
      setFileUploads(prevUploads => [...prevUploads, []]);
    }
  };

  return (
    <div className="results-list">
      {isEmpty && searchValue !== "" && searchValue.length >= 2 && (
        <SearchResult type={"Empty"} result={[]} />
      )}
      {manage.length > 0 && <SearchCategory category={"Manage"} />}
      {manage.length > 0 &&
        manage
          .sort((a, b) => {
            const nameA = a && a.displayName ? a.displayName.toUpperCase() : "";
            const nameB = b && b.displayName ? b.displayName.toUpperCase() : "";
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
          .map(item => {
            return <SearchResult type={"Manage"} result={item} />;
          })}
      {listings.length > 0 && <SearchCategory category={"Listings"} />}
      {listings.length > 0 &&
        listings
          .sort((a, b) => {
            const nameA = a && a.displayName ? a.displayName.toUpperCase() : "";
            const nameB = b && b.displayName ? b.displayName.toUpperCase() : "";
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
          .map(item => {
            return <SearchResult type={"Listings"} result={item} />;
          })}
      {results
        .sort((a, b) => {
          const nameA = a.displayName.toUpperCase(); // Convert to uppercase for case-insensitive sorting
          const nameB = b.displayName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
        .map((result, index) => {
          const userEnrollmentsInfo = [];
          enrollments.map(item => {
            item.forEach(enrollmentInfo => {
              if (enrollmentInfo.user_email === result.email) {
                userEnrollmentsInfo.push(enrollmentInfo);
              }
            });
          });

          const userLeadsInfo = [];
          leads.map(item => {
            item.forEach(leadInfo => {
              if (leadInfo.user_email === result.email) {
                userLeadsInfo.push(leadInfo);
              }
            });
          });

          const userAttachmentsInfo = [];
          fileUploads.map(item => {
            item.forEach(attachmentInfo => {
              if (attachmentInfo.user_email === result.email) {
                userAttachmentsInfo.push(attachmentInfo);
              }
            });
          });

          return (
            <>
              <SearchCategory
                category={"User"}
                user={result.displayName}
                isSearchByChild={result.isSearchByChild}
              />
              <SearchCategory category={"Profile"} />
              <SearchResult type={"Profile"} result={result} />
              {(!partner?.accesspoints ||
                (partner?.accesspoints?.Leads &&
                  partner?.accesspoints?.Leads["Appointments"] &&
                  partner?.accesspoints?.Leads["Applications"] &&
                  partner?.accesspoints?.Leads["Form Leads"])) &&
                userLeadsInfo.length > 0 &&
                userLeadsInfo.some(
                  lead => lead.status_stage === "New_Pending"
                ) && <SearchCategory category={"Leads"} />}
              {(!partner?.accesspoints ||
                (partner?.accesspoints?.Leads &&
                  partner?.accesspoints?.Leads["Appointments"] &&
                  partner?.accesspoints?.Leads["Applications"] &&
                  partner?.accesspoints?.Leads["Form Leads"])) &&
                userLeadsInfo.length > 0 &&
                userLeadsInfo.some(
                  lead => lead.status_stage === "New_Pending"
                ) && <SearchResult type={"Leads"} result={userLeadsInfo} />}
              <SearchCategory category={"History"} />
              <SearchResult type={"History"} result={result} />
              <SearchCategory category={"Financials"} />
              <SearchResult type={"Financials"} result={result} />
              {(!partner?.accesspoints ||
                (partner?.accesspoints?.Manage &&
                  partner?.accesspoints?.Manage["Programs"]?.read)) &&
                userEnrollmentsInfo.length > 0 && (
                  <SearchCategory category={"Enrollments"} />
                )}
              {(!partner?.accesspoints ||
                (partner?.accesspoints?.Manage &&
                  partner?.accesspoints?.Manage["Programs"]?.read)) &&
                userEnrollmentsInfo.length > 0 && (
                  <SearchResult
                    key={index}
                    type={"Enrollments"}
                    result={userEnrollmentsInfo}
                    childrens={result.childrens}
                  />
                )}
              {userAttachmentsInfo.length > 0 && (
                <SearchCategory category={"Attachments"} />
              )}
              {userAttachmentsInfo.length > 0 && (
                <SearchResult
                  key={index}
                  type={"Attachments"}
                  result={userAttachmentsInfo}
                />
              )}
              {(!partner?.accesspoints ||
                (partner?.accesspoints?.Leads &&
                  partner?.accesspoints?.Leads["Appointments"]?.read &&
                  partner?.accesspoints?.Leads["Applications"]?.read &&
                  partner?.accesspoints?.Leads["Form Leads"]?.read)) &&
                userLeadsInfo.length > 0 &&
                !userLeadsInfo.some(
                  lead => lead.status_stage === "New_Pending"
                ) && <SearchCategory category={"Leads"} />}
              {(!partner?.accesspoints ||
                (partner?.accesspoints?.Leads &&
                  partner?.accesspoints?.Leads["Appointments"]?.read &&
                  partner?.accesspoints?.Leads["Applications"]?.read &&
                  partner?.accesspoints?.Leads["Form Leads"]?.read)) &&
                userLeadsInfo.length > 0 &&
                !userLeadsInfo.some(
                  lead => lead.status_stage === "New_Pending"
                ) && <SearchResult type={"Leads"} result={userLeadsInfo} />}
            </>
          );
        })}
    </div>
  );
};
