import React, { useGlobal } from "reactn";
import { Link } from "react-router-dom";

import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const Breadcrumbs = () => {
  const theme = useTheme();

  // Use breakpoint to determine whether to show condensed breakcrumbs.
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  // Change breadcrumbs margin based on presence of the collapsed sidebar.
  const isMediumScreen = useMediaQuery("(max-width:1100px)");

  /**
   * partnerPathway is an array of objects with attributes "to" and "label".
   * "to" is the end of a route, e.g "/connections"
   * "label" is the visual text shown to the user in the breadcrumbs.
   */

  const [global, _] = useGlobal();
  const partnerPathway = global.partnerPathway;

  const pathwayLength = partnerPathway.length;

  // If screen width is smaller than 600px, use custom shortened breadcrumb.
  const smallScreenLink =
    pathwayLength > 1 ? (
      // Get the link of the second-to-last element in pathway.
      <Link to={`/partners${partnerPathway[pathwayLength - 2].to}`}>
        {pathwayLength === 2 ? "Home" : "Back"}
      </Link>
    ) : (
      ""
    );

  return (
    <p style={{ margin: `3px 0px 0px ${isMediumScreen ? "50px" : "35px"}` }}>
      {isSmallScreen
        ? smallScreenLink
        : partnerPathway.map((e, i) => {
            const link = e.to ? (
              <Link to={`/partners${e.to}`} key={i}>
                {e.label}
              </Link>
            ) : (
              `${e.label}`
            );

            // Do not render a link for the last element (current page).
            return pathwayLength - 1 > i ? (
              <span key={i}>{link} / </span>
            ) : (
              e.label
            );
          })}
    </p>
  );
};

export default Breadcrumbs;
