import React from "reactn";
import { Table } from "../../UI";
import { format } from "date-fns";
import Numral from "numeral";
class CreditsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
    this.headings = [
      { id: "displayName", label: "Educator" },
      { id: "parentname", label: "Parent" },
      {
        id: "amount",
        label: "Credit Amount",
        customCell: row => (
          <td key={`${row.id}-acc`}>{Numral(row.amount).format("$0,0.00")}</td>
        )
      },
      {
        id: "createdAt",
        label: "Date",
        customCell: row => (
          <td key={`${row.id}-acc`}>
            {format(new Date(row.createdAt), "LLL dd, yyyy - h:mm a")}
          </td>
        )
      },
      { id: "reason", label: "Description" }
    ];
  }

  render() {
    return (
      <div style={{ width: "100%" }}>
        <Table headings={this.headings} data={this.props.data} />
      </div>
    );
  }
}

export default CreditsTable;
