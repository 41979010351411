import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ModalBanner } from "../../../assets";

const ReportingCard = ({ card }) => {
  if (!card.has_access) {
    return null;
  }
  return (
    <Link to={card.link}>
      <div
        style={{
          position: "relative"
        }}
        className="reporting-card"
        key={card.id}
      >
        <img
          src={ModalBanner}
          alt="Modal Banner"
          style={{
            height: "10px",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px"
          }}
        />
        <div>
          <img
            src={card.imageSource}
            alt="Reporting"
            style={{
              height: "50px",
              width: "50px",
              borderRadius: "50%"
            }}
          />
        </div>
        <div style={{ marginLeft: "20px" }}>
          <div
            style={{
              textAlign: "justify",
              color: "#072664",
              fontSize: "16px",
              fontFamily: "Montserrat",
              fontWeight: "600"
            }}
          >
            <span style={{ fontWeight: "bold" }}>{card.title}</span>
          </div>
          <p
            style={{
              color: "#B2B2B2",
              marginBottom: 0,
              fontSize: "13px"
            }}
          >
            {card.description}
          </p>
        </div>
      </div>
    </Link>
  );
};

ReportingCard.propTypes = {
  card: PropTypes.shape({
    imageSource: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    has_access: PropTypes.bool.isRequired
  })
};

export default ReportingCard;
