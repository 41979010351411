import React from "reactn";
import PropTypes from "prop-types";
import {
  StreamApp,
  FlatFeed,
  NewActivitiesNotification
} from "react-activity-feed";
import "react-activity-feed/dist/index.css";
import { Scrollbars } from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import {
  NotIcon,
  ArrowTop,
  NotImg1,
  MsgIcon,
  CalendarIcon
} from "../../assets";
import { Link } from "react-router-dom";
import Avatar from "../../assets/img/msgicon.png";
import EduAvatar from "../../assets/img/educator.png";
import ProgramSearchInput from "../common/ProgramSearchInput";
import "../../assets/css/componentSpecificCss/topBar.css";

const moment = require("moment");

class TopBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showMenu: false };
  }
  humanizeTimestamp = timestamp => {
    var time = moment.utc(timestamp); // parse time as UTC

    var now = moment(); // Not in future humanized time

    return moment.min(time, now).from(now);
  };

  componentDidMount() {
    document.body.onclick = e => {
      if (document.getElementById("showNotif")) {
        if (
          e.target !== document.getElementById("showNotif") &&
          !document.getElementById("showNotif").contains(e.target)
        )
          document.getElementById("showNotif").classList.remove("show");
      }
      if (document.getElementById("popupcontainer")) {
        if (
          e.target !== document.getElementById("popupcontainer") &&
          !document.getElementById("popupcontainer").contains(e.target)
        )
          this.setState({ showMenu: false });
      }
    };
  }
  notification = e => {
    return (
      <div
        style={{
          display: "flex",
          borderTop: "1px solid #e3e3e3",
          alignItems: "center"
        }}
      >
        <div
          style={{
            width: "80px",
            display: "flex",
            justifyItems: "middle",
            height: "60px"
          }}
        >
          <img
            src={NotImg1}
            style={{ width: "22px", height: "22px", margin: "auto" }}
            alt=""
          />
        </div>
        <div style={{ height: "fit-content", flexGrow: 1 }}>
          <strong>{e.activity.message}</strong>
          <br />
          <span
            style={{
              fontSize: "11px",
              display: "block",
              maxWidth: "200px",
              textOverflow: "ellipsis"
            }}
          >
            {e.activity.notifier}
          </span>
        </div>
        <div style={{ width: "100px", height: "fit-content" }}>
          <sub>{this.humanizeTimestamp(e.activity.time)}</sub>
        </div>
      </div>
    );
  };

  render() {
    const { ConsoleRoute } = this.props;

    return (
      <nav className="navbar navbar-expand navbar-light bg-white topbar static-top shadow">
        {/* <!-- Topbar Navbar --> */}
        <ul className="navbar-nav top-navbar ml-auto">
          {this.global.franchiseUser.accountType !== "Franchiser" && (
            <>
              <li className="w-100">
                <div className="v-center">
                  <Link to="/franchiser">DS Home</Link>
                </div>
              </li>
              <div className="topbar-divider d-none d-sm-block" />
            </>
          )}

          {/* <!-- Nav Item - Alerts --> */}
          <li className="nav-item dropdown no-arrow mx-1">
            <button
              className="nav-link dropdown-toggle"
              href="#/"
              id="alertsDropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={_ =>
                document.getElementById("showNotif").classList.add("show")
              }
            >
              <img src={NotIcon} alt="" />
            </button>
            {/* <!-- Dropdown - Alerts --> */}
            <div
              className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="alertsDropdown"
              id="showNotif"
            >
              <img className="arrowtop" src={ArrowTop} alt="" />
              <div className="boxofit">
                <h6 className="dropdown-header">
                  <div className="row">
                    <div className="col-12">Notifications</div>
                  </div>
                </h6>
              </div>
            </div>
          </li>

          {/* <div className="topbar-divider d-none d-sm-block" />

          <li className="nav-item dropdown no-arrow">
            <Link
              className="nav-link nameofc"
              to={`${ConsoleRoute}/messages`}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img src={MsgIcon} alt="" />
              {this.global.unread && <div className="bluecircletop"></div>}
            </Link>
          </li>

          <div className="topbar-divider d-none d-sm-block" />

          <li
            className="nav-item dropdown no-arrow"
            style={{ display: ConsoleRoute === "/admin" ? "none" : "flex" }}
          >
            <Link
              className="nav-link nameofc"
              to={`${ConsoleRoute}/calendar`}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img src={CalendarIcon} alt="" />
              <div className="bluecircletop" />
            </Link>
          </li> */}

          {ConsoleRoute === "/admin" || (
            <div className="topbar-divider d-none d-sm-block" />
          )}
          <li
            className="nav-item dropdown no-arrow"
            style={{ position: "relative" }}
            onClick={e => {
              if (this.global.franchiseUser.accountType === "Franchiser") {
                this.setState({ showMenu: true });
              }
            }}
          >
            <a
              className="nav-link dropdown-toggle nameofc"
              href="#/"
              id="userDropdown"
              role="button"
            >
              <div
                className="divforimg user-img-top-bar"
                style={{
                  backgroundImage: `url(${Avatar})`,
                  backgroundPosition: "center",
                  width: "32px",
                  height: "32px",
                  borderRadius: "50%",
                  backgroundSize: "cover"
                }}
              ></div>
              <span
                className="mr-2 d-none d-lg-inline text-gray-600 small"
                style={{ maxWidth: "148px" }}
              >
                {this.global.franchiseUser.name}
              </span>
            </a>
            {this.state.showMenu && (
              <div id="popupcontainer" className="franchise-popup-container">
                <ul className="inner-popup-container">
                  {/* {(!this.global.dsUser.sub_user ||
                    (access["Account"] &&
                      access["Account"]["Profile Setup"] &&
                      access["Account"]["Profile Setup"].read)) && (
                  )} */}
                  {/* <li className="popup-item">
                      <a href="/partners/profile" className="popup-link">Organization Profile</a>
                    </li>
                    <li  className="popup-item">
                      <a href="/partners/settings" className="popup-link">Account Settings</a>
                    </li>
                    <li className="popup-item">
                      <a href="/partners/customization" className="popup-link">Customization</a>
                    </li>
                    <li className="popup-item">
                      <a href="/partners/team-management" className="popup-link">Team Management</a>
                    </li>
                    <li className="popup-item">
                      <a href="/partners/tasks" className="popup-link">Tasks & Reminders</a>
                    </li>
                    <li className="popup-item">
                      <a href="/partners/reporting" className="popup-link">Reports & Analytics</a> 
                    </li> */}
                  {/* {!this.global.dsUser.sub_user && (
                  )} */}
                  {/* {!this.global.dsUser.sub_user && (
                  )} */}
                  {/* {!this.global.dsUser.sub_user && (
                  )} */}
                  {/* {(!this.global.dsUser.sub_user ||
                    (access["Upcoming"] &&
                      access["Upcoming"]["Tasks & Reminders"] &&
                      access["Upcoming"]["Tasks & Reminders"].read)) && (
                  )} */}
                  {/* {(!this.global.dsUser.sub_user ||
                    (access["Account"] &&
                      access["Account"]["Reporting"] &&
                      access["Account"]["Reporting"].read)) && (
                  )} */}
                  <li className="popup-item">
                    <a href="/franchiser/settings" className="popup-link">
                      Account Settings
                    </a>
                  </li>
                  <li className="popup-item">
                    <a
                      href="#/"
                      onClick={async () => {
                        this.props.history.push("/franchise/log-in");
                        await this.dispatch.logoutFranchiser();
                      }}
                      className="popup-link"
                    >
                      Log Out
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </li>
        </ul>
      </nav>
    );
  }
}
TopBar.propTypes = {
  ConsoleRoute: PropTypes.string
};

TopBar.defaultProps = {
  ConsoleRoute: "/"
};

export default withRouter(TopBar);
