import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { addWeeks, format } from "date-fns";
import { IconButton, Switch } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { addDays, addMonths } from "date-fns/esm";
import { zonedTimeToUtc  } from "date-fns-tz";
import { ModalBanner } from "../../assets";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, SWITCH, DROPDOWN, TITLE, GET_HTML } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import Title from "./baseModal/fieldComponents/Title.jsx";
import DatePicker from "react-datepicker";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class AddLeadModal extends React.PureComponent {
  constructor(props) {
    super(props);
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.state = {
      startDate: zonedTimeToUtc(this.global.online.program_start, tz),
      endDate: null,
      price_per_day: null,
      total_price: null,
      addOn: false,
      addOnItems: [{ text: "Add On Fee", value: "0" }],
      isInstallmentsAllowed: false,
      installments: false,
      installments_plan: null,
      installments_breakdown: null,
      installments_interest: "0",
      custom_date1: zonedTimeToUtc(this.global.online.program_start, tz),
      custom_date2: zonedTimeToUtc(this.global.online.program_start, tz),
      custom_date3: zonedTimeToUtc(this.global.online.program_start, tz),
      custom_date4: zonedTimeToUtc(this.global.online.program_start, tz),
      custom_date5: zonedTimeToUtc(this.global.online.program_start, tz),
      custom_date6: zonedTimeToUtc(this.global.online.program_start, tz),
      includeDeposit: false,
      deposit: "0",
      isDescriptionEnabled: false,
      description: "",
      isTaxEnabled: false,
      isCapEnabled: false,
      planCap: "",
      taxInfo: [],
      dropIns: false,
      dropin_interest: 0
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  componentDidMount() {
    this.checkInstallmentFeature();
    this.getTotalDays();
  }

  checkInstallmentFeature = async () => {
    const ep = `${process.env.REACT_APP_API}/partner/permissions/feature/enable-installments`;
    const results = await axios.get(ep);
    if (results.data?.success && results.data.data?.has_access) {
      this.setState({ isInstallmentsAllowed: true });
    }
  };

  updateAddOn(e) {
    const obj = this.state.addOnItems[0];
    obj[e.target.name] = e.target.value;
    obj["id"] = `${this.state.addOnItems.length}`;
    this.setState({ addOnItems: [obj] });
  }

  getTotalDays() {
    const days = {
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
      0: "Sunday"
    };
    const timeFrame = [
      new Date(this.global.online.program_start),
      addWeeks(
        new Date(this.global.online.program_start),
        this.global.online.number_of_weeks
      )
    ];
    let closures = [];
    if (
      this.global.online.closure_timeframe[0] &&
      this.global.online.closure_timeframe[1]
    ) {
      let closuredate = new Date(this.global.online.closure_timeframe[0]);
      while (
        closuredate.toDateString() !==
        addDays(
          new Date(this.global.online.closure_timeframe[1]),
          1
        ).toDateString()
      ) {
        closures.push(format(closuredate, "LLLL dd, yyyy"));
        closuredate = addDays(closuredate, 1);
      }
    }
    let date = timeFrame[0];
    let daycount = 0;
    const okdays = [];
    while (date.toDateString() !== timeFrame[1].toDateString()) {
      if (
        this.global.online.days_of_week[days[date.getDay()]] &&
        !this.global.online.closure_dates.includes(
          format(date, "LLLL dd, yyyy")
        ) &&
        !closures.includes(format(date, "LLLL dd, yyyy"))
      ) {
        okdays.push(date);
        daycount++;
      }
      date = addDays(date, 1);
    }
    this.setState({ dayCount: daycount, available_days: okdays });
  }
  async savePlan() {
    if (
      (!this.state.total_price || this.state.total_price != 0) &&
      !this.global.organizationInfo.stripeId
    ) {
      toast.error("Denied. Please connect your Bank Account first");
      return;
    }
    if (this.state.isTaxEnabled && this.state.taxInfo.length == 0) {
      toast.error("Please select a tax plan, otherwise disable taxes");
      return;
    }
    const obj = { ...this.state, type: "Semester" };
    if (
      this.global.online.total_spots &&
      this.global.online.total_spots < parseInt(obj.planCap)
    ) {
      obj.planCap = this.global.online.total_spots;
    } else {
      if (parseInt(obj.planCap) > 200) {
        obj.planCap = 200;
      }
    }

    if (!this.state.addOn) {
      delete obj.addOn;
      delete obj.addOnItems;
    }

    obj.total_price = parseFloat(obj.total_price).toFixed(2);
    const ep = `${process.env.REACT_APP_API}/partners/stripe/add_products`;
    const res = await axios.post(ep, {
      ...obj,
      parent: "Online",
      name: this.global.online.program_name,
      type: "Semester",
      product: this.global.online.product_stripe
    });

    this.setGlobal(
      {
        online: {
          ...this.global.online,
          product_stripe: res.data.data.product,
          plans: [
            ...this.global.online.plans,
            {
              ...obj,
              prodId: res.data.data.id,
              type: "Semester",
              method: "Online",
              id: this.global.online.plans.length
            }
          ]
        }
      },
      () => {
        this.dispatch.saveVirtual();
      }
    );
    this.props.onClose();
  }

  /// ================== NEW MODAL ========================== ///

  taxesOptions = () => {
    //const global_organization = [JSON.parse(this.global.organizationInfo.stripeTaxId)]
    const global_organization = this.global.organizationInfo.stripeTaxId;
    console.log("global_organization", global_organization);
    const DATA = [];
    for (let i = 0; i < global_organization.length; i++) {
      DATA.push(JSON.parse(global_organization[i]));
    }
    console.log("DATA", DATA);

    return [
      ...DATA.map((e, i) => ({
        value: i,
        text: e.display_name + " " + e.jurisdiction + e.percentage + "%"
      }))
    ];
  };

  optionsFunc = () => {
    return this.state.addOnItems.map((e, i) => (
      <div
        className="form-group"
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 0
        }}
        key={i}
      >
        <input
          name="add-on-name"
          type="text"
          className="string-input-field field-component-input-box"
          placeholder={`Fee Name`}
          style={{
            width: "150px",
            marginRight: "10px"
          }}
          onChange={e => {
            console.log("E VALUE", e.target.value, e.target.value === "");
            {
              const options = [...this.state.addOnItems];
              options[i] = {
                text: e.target.value,
                value: options[i].value
              };
              this.setState({ addOnItems: options });
            }
          }}
          value={this.state.addOnItems[i].text === undefined || this.state.addOnItems[i].text === null ? "Add-On Fee" : this.state.addOnItems[i].text}
        />
        <input
          name="add-on-amount"
          type="number"
          className="string-input-field field-component-input-box"
          placeholder={`Amount`}
          style={{
            width: "150px",
            marginRight: "10px"
          }}
          onChange={e => {
            {
              const options = [...this.state.addOnItems];
              options[i] = {
                text: options[i].text,
                value: e.target.value
              };
              this.setState({ addOnItems: options });
            }
          }}
          value={this.state.addOnItems[i].value}
        />
        <button
          type="button"
          onClick={_ => {
            if (this.state.addOnItems?.length < 2) {
              this.setState({
                addOnItems: [...this.state.addOnItems, {}]
              });
            }
          }}
          style={{
            backgroundColor: "yellowgreen",
            border: "none",
            color: "white",
            borderRadius: "3px",
            marginRight: "10px",
            marginTop: "8px"
          }}
        >
          +
        </button>

        {i !== 0 && (
          <button
            style={{
              width: "8%",
              backgroundColor: "red",
              border: "none",
              color: "white",
              borderRadius: "3px",
              marginTop: "8px"
            }}
            type="button"
            onClick={_ =>
              this.setState({
                addOnItems: this.state.addOnItems.filter(
                  (_, index) => index !== i
                )
              })
            }
          >
            -
          </button>
        )}
      </div>
    ));
  };

  termOptions = () => {
    return Array(12)
      .fill(0)
      .map((e, i) => ({
        value: i + 1,
        key: i + 1,
        text: `${i + 1} Month`
      }));
  };

  getBaseModalFields() {
    let totalAddOnFee = 0;
    this.state.addOnItems.map(fee => {
      if (fee.value) {
        totalAddOnFee += parseFloat(fee.value);
      }
    });

    const priceInput = {
      type: STRING_INPUT,
      data: {
        name: `Price (${
          isNaN(this.state.dayCount) ? "#" : this.state.dayCount
        }) Days`,
        type: "number",
        required: false,
        handleChange: e => {
          let price = parseFloat(e.target.value || 0);
          let decimalValue = e.target.value.split(".")[1];

          if (decimalValue && decimalValue.length > 2) {
            return;
          } else if (!e.target.value.endsWith("0")) {
            price = parseInt((price * 100).toString()) / 100;
          } else {
            price = e.target.value;
          }

          const price_per_day = (price / this.state.dayCount).toFixed(2);

          this.setState({
            total_price: price.toString(),
            price_per_day: price_per_day
          });
        },
        value:
          this.state.total_price ||
          this.state.price_per_day * this.state.dayCount ||
          ""
      }
    };

    const priceperdayInput = {
      type: STRING_INPUT,
      data: {
        name: "Price / Day",
        required: false,
        // isDisabled: this.state.total_price && !this.state.price_per_day,
        type: "number",
        handleChange: e => {
          let price = parseFloat(e.target.value || 0);
          let decimalValue = e.target.value.split(".")[1];

          if (decimalValue && decimalValue.length > 2) {
            return;
          } else if (!e.target.value.endsWith("0")) {
            price = Math.round(price * 100) / 100;
          } else {
            price = e.target.value;
          }

          let totalPrice = (price * this.state.dayCount).toFixed(2);

          this.setState({
            price_per_day: price.toString(),
            total_price: totalPrice
          });
        },
        value:
          this.state.price_per_day ||
          (this.state.total_price / this.state.dayCount).toFixed(2) ||
          ""
      }
    };

    const addDescription = {
      type: SWITCH,
      data: {
        name: "Add Description",
        checked: this.state.isDescriptionEnabled,
        isDisabled: false,
        handleClick: () => {
          this.setState({
            isDescriptionEnabled: !this.state.isDescriptionEnabled
          });
        }
      }
    };

    const description = {
      type: STRING_INPUT,
      data: {
        name: "Description",
        required: false,
        maxLength: 32,
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description
      }
    };

    const addsonFee = {
      type: SWITCH,
      data: {
        name: "Adds-On Fee",
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        checked: this.state.addOn,
        handleClick: e => this.setState({ addOn: e.target.checked })
      }
    };

    const addOnOptionField = {
      type: GET_HTML,
      data: {
        gethtml: this.optionsFunc
      },
      validatorKey: "validateSubmit",
      validators: { validateSubmit: () => this.state.addOnItems.reduce((acc, curr) => acc && curr.text !== "" && parseFloat(curr.value) >= 0, true)}
    };

    const installmentsField = {
      type: SWITCH,
      data: {
        name: "Enable Installments",
        isDisabled: false,
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        checked: this.state.installments,
        handleClick: e => {
          this.setState({ installments: e.target.checked });
          if (e.target.checked === false) {
            this.setState({
              installments_plan: null,
              installments_breakdown: null,
              installments_interest: 0
            });
          }
        }
      }
    };

    const installmentPlan = {
      type: DROPDOWN,
      data: {
        name: "Installment Cycle",
        isDisabled: false,
        required: true,
        placeholder: "Select Cycle...",
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        value: this.state.installments_plan,
        handleChange: e => this.setState({ installments_plan: e.target.value }),
        choices: [
          { text: "Weekly", value: "Weekly" },
          { text: "Bi-Weekly", value: "Bi-Weekly" },
          { text: "Monthly", value: "Monthly" },
          { text: "Custom Dates", value: "Custom" }
        ]
      }
    };

    const installmentBreakdown = {
      type: DROPDOWN,
      data: {
        name: "Number of Installments",
        isDisabled: false,
        required: true,
        placeholder: "Select Number...",
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        value: this.state.installments_breakdown,
        handleChange: e =>
          this.setState({ installments_breakdown: e.target.value }),
        choices: [
          { text: "1 Payments", value: 1 },
          { text: "2 Payments", value: 2 },
          { text: "3 Payments", value: 3 },
          { text: "4 Payments", value: 4 },
          { text: "5 Payments", value: 5 },
          { text: "6 Payments", value: 6 }
        ]
      }
    };

    const depositToggle = {
      type: SWITCH,
      data: {
        name: "Include Deposit",
        checked: this.state.includeDeposit,
        handleClick: e =>
          this.setState({ includeDeposit: e.target.checked, deposit: "0" }),
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const depositField = {
      type: STRING_INPUT,
      data: {
        required: false,
        type: "number",
        handleChange: e => {
          let sanitizedAndBoundedDeposit =
            //first make zero if null, undefined, "", 0, NaN, -ve numbers, and non-numeric strings.
            !e.target.value || !(parseFloat(e.target.value) > 0)
              ? 0
              : //else, cap deposit value's upper limit to the total_price.
                Math.min(
                  parseFloat(e.target.value),
                  parseFloat(this.state.total_price)
                );
          this.setState({
            deposit: sanitizedAndBoundedDeposit
          });
        },
        value: this.state.deposit,
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const labelFunc = () => {
      return (
        <>
          <div className="field-component-title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <div>Installment</div>
              <div>Charge Date</div>
              <div>Amount</div>
            </div>
          </div>
          <hr />
        </>
      );
    };

    const depositFunc = () => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Title name={"Deposit"} required={false} />
          <Title name={"At Checkout"} required={false} />
          <Title
            name={`$${
              this.state.deposit
                ? parseFloat(this.state.deposit).toFixed(2)
                : "0.00"
            }`}
            required={false}
          />
        </div>
      );
    };

    const labelField = {
      type: GET_HTML,
      data: {
        gethtml: labelFunc
      }
    };

    const customDateDeposit = {
      type: GET_HTML,
      data: {
        gethtml: depositFunc
      }
    };

    const customDate1Func = () => {
      // If deposit is enabled, move the dates of all installments back a cycle.
      let increment = 0;
      if (this.state.deposit && this.state.deposit !== "0") {
        increment++;
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Title name={"Installment 1:"} required={false} />
          {this.state.deposit && this.state.deposit !== "0" ? (
            <div>
              <DatePicker
                selected={
                  this.state.installments_plan === "Custom"
                    ? this.state.custom_date1
                    : this.state.installments_plan === "Monthly"
                    ? addMonths(this.state.startDate, increment)
                    : this.state.installments_plan === "Bi-Weekly"
                    ? addWeeks(this.state.startDate, increment * 2)
                    : addWeeks(this.state.startDate, increment)
                }
                onChange={date => this.setState({ custom_date1: date })}
                minDate={this.state.startDate}
                showYearDropdown
                showMonthDropdown
                dateFormat={"MMMM d, yyyy"}
                disabled={
                  this.state.installments_plan !== "Custom" ? true : false
                }
                className={`field-component-input-box`}
                autoComplete="off"
              />
            </div>
          ) : (
            <Title name={"At Checkout"} required={false} />
          )}
          <Title
            name={`$${
              this.state.total_price
                ? parseFloat(
                    ((parseFloat(this.state.total_price) + totalAddOnFee) *
                      (this.state.installments_interest &&
                      this.state.installments_interest !== "0"
                        ? 1 + parseFloat(this.state.installments_interest) / 100
                        : 1) *
                      parseFloat(
                        this.state.taxInfo && this.state.taxInfo[0]
                          ? 1 +
                              parseFloat(this.state.taxInfo[0].percentage / 100)
                          : 1
                      ) -
                      parseFloat(this.state.deposit ? this.state.deposit : 0)) /
                      parseInt(this.state.installments_breakdown)
                  ).toFixed(2)
                : "0.00"
            }`}
            required={false}
          />
        </div>
      );
    };

    const customDate1 = {
      type: GET_HTML,
      data: {
        gethtml: customDate1Func
      }
    };

    // const customDate1 = {
    //   type: DATE_PICKER,
    //   data: {
    //     name: "Installment 1:",
    //     isDisabled: this.state.installments_plan === "Custom" ? false : true,
    //     wrapperStyles: {
    //       display: "flex",
    //       alignItems: "center",
    //       justifyContent: "space-between"
    //     },
    //     minDate: new Date(),
    //     dateFormat: "MMMM d, yyyy",
    //     value: this.state.installments_plan === "Custom" ? this.state.custom_date1
    //       : this.state.installments_plan === "Monthly" ? addMonths(new Date(), 0)
    //       : this.state.installments_plan === "Bi-Weekly" ? addWeeks(new Date(), 0)
    //       : addWeeks(new Date(), 0),
    //     handleChange: date => {
    //       this.setState({ custom_date1: date });
    //     },
    //   }
    // }

    const customDate2Func = () => {
      // If deposit is enabled, move the dates of all installments back a cycle.
      let increment = 1;
      if (this.state.deposit && this.state.deposit !== "0") {
        increment++;
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Title name={"Installment 2:"} required={false} />
          <div>
            <DatePicker
              selected={
                this.state.installments_plan === "Custom"
                  ? this.state.custom_date2
                  : this.state.installments_plan === "Monthly"
                  ? addMonths(this.state.startDate, increment)
                  : this.state.installments_plan === "Bi-Weekly"
                  ? addWeeks(this.state.startDate, increment * 2)
                  : addWeeks(this.state.startDate, increment)
              }
              onChange={date => this.setState({ custom_date2: date })}
              minDate={this.state.startDate}
              showYearDropdown
              showMonthDropdown
              dateFormat={"MMMM d, yyyy"}
              disabled={
                this.state.installments_plan !== "Custom" ? true : false
              }
              className={`field-component-input-box`}
              autoComplete="off"
            />
          </div>
          <Title
            name={`$${
              this.state.total_price
                ? parseFloat(
                    ((parseFloat(this.state.total_price) + totalAddOnFee) *
                      (this.state.installments_interest &&
                      this.state.installments_interest !== "0"
                        ? 1 + parseFloat(this.state.installments_interest) / 100
                        : 1) *
                      parseFloat(
                        this.state.taxInfo && this.state.taxInfo[0]
                          ? 1 +
                              parseFloat(this.state.taxInfo[0].percentage / 100)
                          : 1
                      ) -
                      parseFloat(this.state.deposit ? this.state.deposit : 0)) /
                      parseInt(this.state.installments_breakdown)
                  ).toFixed(2)
                : "0.00"
            }`}
            required={false}
          />
        </div>
      );
    };

    const customDate2 = {
      type: GET_HTML,
      data: {
        gethtml: customDate2Func
      }
    };

    const customDate3Func = () => {
      // If deposit is enabled, move the dates of all installments back a cycle.
      let increment = 2;
      if (this.state.deposit && this.state.deposit !== "0") {
        increment++;
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Title name={"Installment 3:"} required={false} />
          <div>
            <DatePicker
              selected={
                this.state.installments_plan === "Custom"
                  ? this.state.custom_date3
                  : this.state.installments_plan === "Monthly"
                  ? addMonths(this.state.startDate, increment)
                  : this.state.installments_plan === "Bi-Weekly"
                  ? addWeeks(this.state.startDate, increment * 2)
                  : addWeeks(this.state.startDate, increment)
              }
              onChange={date => this.setState({ custom_date3: date })}
              minDate={this.state.startDate}
              showYearDropdown
              showMonthDropdown
              dateFormat={"MMMM d, yyyy"}
              disabled={
                this.state.installments_plan !== "Custom" ? true : false
              }
              className={`field-component-input-box`}
              autoComplete="off"
            />
          </div>
          <Title
            name={`$${
              this.state.total_price
                ? parseFloat(
                    ((parseFloat(this.state.total_price) + totalAddOnFee) *
                      (this.state.installments_interest &&
                      this.state.installments_interest !== "0"
                        ? 1 + parseFloat(this.state.installments_interest) / 100
                        : 1) *
                      parseFloat(
                        this.state.taxInfo && this.state.taxInfo[0]
                          ? 1 +
                              parseFloat(this.state.taxInfo[0].percentage / 100)
                          : 1
                      ) -
                      parseFloat(this.state.deposit ? this.state.deposit : 0)) /
                      parseInt(this.state.installments_breakdown)
                  ).toFixed(2)
                : "0.00"
            }`}
            required={false}
          />
        </div>
      );
    };

    const customDate3 = {
      type: GET_HTML,
      data: {
        gethtml: customDate3Func
      }
    };

    const customDate4Func = () => {
      // If deposit is enabled, move the dates of all installments back a cycle.
      let increment = 3;
      if (this.state.deposit && this.state.deposit !== "0") {
        increment++;
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Title name={"Installment 4:"} required={false} />
          <div>
            <DatePicker
              selected={
                this.state.installments_plan === "Custom"
                  ? this.state.custom_date4
                  : this.state.installments_plan === "Monthly"
                  ? addMonths(this.state.startDate, increment)
                  : this.state.installments_plan === "Bi-Weekly"
                  ? addWeeks(this.state.startDate, increment * 2)
                  : addWeeks(this.state.startDate, increment)
              }
              onChange={date => this.setState({ custom_date4: date })}
              minDate={this.state.startDate}
              showYearDropdown
              showMonthDropdown
              dateFormat={"MMMM d, yyyy"}
              disabled={
                this.state.installments_plan !== "Custom" ? true : false
              }
              className={`field-component-input-box`}
              autoComplete="off"
            />
          </div>
          <Title
            name={`$${
              this.state.total_price
                ? parseFloat(
                    ((parseFloat(this.state.total_price) + totalAddOnFee) *
                      (this.state.installments_interest &&
                      this.state.installments_interest !== "0"
                        ? 1 + parseFloat(this.state.installments_interest) / 100
                        : 1) *
                      parseFloat(
                        this.state.taxInfo && this.state.taxInfo[0]
                          ? 1 +
                              parseFloat(this.state.taxInfo[0].percentage / 100)
                          : 1
                      ) -
                      parseFloat(this.state.deposit ? this.state.deposit : 0)) /
                      parseInt(this.state.installments_breakdown)
                  ).toFixed(2)
                : "0.00"
            }`}
            required={false}
          />
        </div>
      );
    };

    const customDate4 = {
      type: GET_HTML,
      data: {
        gethtml: customDate4Func
      }
    };

    const customDate5Func = () => {
      // If deposit is enabled, move the dates of all installments back a cycle.
      let increment = 4;
      if (this.state.deposit && this.state.deposit !== "0") {
        increment++;
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Title name={"Installment 5:"} required={false} />
          <div>
            <DatePicker
              selected={
                this.state.installments_plan === "Custom"
                  ? this.state.custom_date5
                  : this.state.installments_plan === "Monthly"
                  ? addMonths(this.state.startDate, increment)
                  : this.state.installments_plan === "Bi-Weekly"
                  ? addWeeks(this.state.startDate, increment * 2)
                  : addWeeks(this.state.startDate, increment)
              }
              onChange={date => this.setState({ custom_date5: date })}
              minDate={this.state.startDate}
              showYearDropdown
              showMonthDropdown
              dateFormat={"MMMM d, yyyy"}
              disabled={
                this.state.installments_plan !== "Custom" ? true : false
              }
              className={`field-component-input-box`}
              autoComplete="off"
            />
          </div>
          <Title
            name={`$${
              this.state.total_price
                ? parseFloat(
                    ((parseFloat(this.state.total_price) + totalAddOnFee) *
                      (this.state.installments_interest &&
                      this.state.installments_interest !== "0"
                        ? 1 + parseFloat(this.state.installments_interest) / 100
                        : 1) *
                      parseFloat(
                        this.state.taxInfo && this.state.taxInfo[0]
                          ? 1 +
                              parseFloat(this.state.taxInfo[0].percentage / 100)
                          : 1
                      ) -
                      parseFloat(this.state.deposit ? this.state.deposit : 0)) /
                      parseInt(this.state.installments_breakdown)
                  ).toFixed(2)
                : "0.00"
            }`}
            required={false}
          />
        </div>
      );
    };

    const customDate5 = {
      type: GET_HTML,
      data: {
        gethtml: customDate5Func
      }
    };

    const customDate6Func = () => {
      // If deposit is enabled, move the dates of all installments back a cycle.
      let increment = 5;
      if (this.state.deposit && this.state.deposit !== "0") {
        increment++;
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Title name={"Installment 6:"} required={false} />
          <div>
            <DatePicker
              selected={
                this.state.installments_plan === "Custom"
                  ? this.state.custom_date6
                  : this.state.installments_plan === "Monthly"
                  ? addMonths(this.state.startDate, increment)
                  : this.state.installments_plan === "Bi-Weekly"
                  ? addWeeks(this.state.startDate, increment * 2)
                  : addWeeks(this.state.startDate, increment)
              }
              onChange={date => this.setState({ custom_date6: date })}
              minDate={this.state.startDate}
              showYearDropdown
              showMonthDropdown
              dateFormat={"MMMM d, yyyy"}
              disabled={
                this.state.installments_plan !== "Custom" ? true : false
              }
              className={`field-component-input-box`}
              autoComplete="off"
            />
          </div>
          <Title
            name={`$${
              this.state.total_price
                ? parseFloat(
                    ((parseFloat(this.state.total_price) + totalAddOnFee) *
                      (this.state.installments_interest &&
                      this.state.installments_interest !== "0"
                        ? 1 + parseFloat(this.state.installments_interest) / 100
                        : 1) *
                      parseFloat(
                        this.state.taxInfo && this.state.taxInfo[0]
                          ? 1 +
                              parseFloat(this.state.taxInfo[0].percentage / 100)
                          : 1
                      ) -
                      parseFloat(this.state.deposit ? this.state.deposit : 0)) /
                      parseInt(this.state.installments_breakdown)
                  ).toFixed(2)
                : "0.00"
            }`}
            required={false}
          />
        </div>
      );
    };

    const customDate6 = {
      type: GET_HTML,
      data: {
        gethtml: customDate6Func
      }
    };

    const customDates = [
      customDate1,
      customDate2,
      customDate3,
      customDate4,
      customDate5,
      customDate6
    ];

    const installmentInterest = {
      type: STRING_INPUT,
      data: {
        name: "Premium Charge",
        isDisabled: false,
        placeholder: "Enter Interest",
        info: "(Max 20%)",
        type: "number",
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        value: this.state.installments_interest,
        handleChange: e =>
          this.setState({ installments_interest: e.target.value })
      }
    };

    const dropIns = {
      type: SWITCH,
      data: {
        name: "Enable Drop-ins",
        checked: this.state.dropIns,
        isDisabled: true,
        handleClick: e =>
          this.setState({
            dropIns: e.target.checked
          })
      }
    };

    const surchargeInput = {
      type: STRING_INPUT,
      data: {
        name: "Drop-in surcharge %",
        info: "Max: 50%",
        required: false,
        isDisabled: !this.state.dropIns,
        type: "number",
        handleChange: e => {
          if (e.target.value <= 50)
            this.setState({
              dropin_interest: e.target.value
            });
        },
        value: this.state.dropin_interest
      }
    };

    const enableTaxes = {
      type: SWITCH,
      data: {
        name: "Enable Taxes",
        isDisabled: false,
        checked: this.state.isTaxEnabled,
        handleClick: e =>
          this.setState({
            isTaxEnabled: e.target.checked,
            taxInfo: []
          })
      }
    };

    const selectTaxes = {
      type: DROPDOWN,
      data: {
        name: "Tax Plan",
        required: false,
        placeholder: "Please Select" /* 
            value: this.state.taxInfo, */,
        handleChange: e => {
          const item = JSON.parse(
            this.global.organizationInfo.stripeTaxId[e.target.value]
          );
          this.setState({
            taxInfo: [
              {
                id: item.id,
                percentage: item.percentage,
                jurisdiction: item.jurisdiction,
                display_name: item.display_name,
                description: item.description
              }
            ]
          });
        },
        choices: this.taxesOptions()
      }
    };

    const addCap = {
      type: SWITCH,
      data: {
        name: "Enable Program Plan Cap",
        isDisabled: false,
        checked: this.state.isCapEnabled,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: e =>
          this.setState({
            isCapEnabled: e.target.checked,
            planCap: ""
          })
      }
    };

    const planCap = {
      type: STRING_INPUT,
      data: {
        type: "number",
        name: this.global.online.total_spots
          ? `Plan Cap (Max: ${this.global.online.total_spots})`
          : "Plan Cap",
        required: false,
        placeholder: "Enter Cap",
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleChange: e => {
          this.setState({
            planCap: e.target.value
          });
        }
      }
    };

    const fields = [];
    fields.push(priceInput);
    fields.push(priceperdayInput);
    /* fields.push(dropIns); */ // Hidden Switch
    fields.push(addDescription);
    if (this.state.isDescriptionEnabled) {
      fields.push(description);
    }
    fields.push(addsonFee);
    if (this.state.addOn) {
      fields.push(addOnOptionField);
    }
    if (this.state.dropIns) {
      fields.push(surchargeInput);
    }
    fields.push(enableTaxes);
    if (this.state.isTaxEnabled) {
      fields.push(selectTaxes);
    }
    fields.push(addCap);
    if (this.state.isCapEnabled) {
      fields.push(planCap);
    }
    if (this.state.isInstallmentsAllowed) {
      fields.push(installmentsField);
    }
    if (this.state.installments) {
      fields.push(installmentPlan);
      fields.push(installmentBreakdown);
      fields.push(installmentInterest);
      fields.push(depositToggle);
      if (this.state.includeDeposit) {
        fields.push(depositField);
      }
      if (this.state.installments_plan && this.state.installments_breakdown) {
        fields.push(labelField);
        if (this.state.deposit && this.state.deposit !== "0") {
          fields.push(customDateDeposit);
        }
        for (let i = 0; i < parseInt(this.state.installments_breakdown); i++) {
          fields.push(customDates[i]);
        }
      }
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.savePlan()
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Pricing Plan",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ========================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(AddLeadModal);
