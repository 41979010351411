import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "../../assets/css/componentSpecificCss/applicationViewModal.css";
import moment from "moment";
import { format, addWeeks } from "date-fns";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
// import { ReactPDF, PDFViewer , BlobProvider, PDFDownloadLink, pdf, Document, Page } from '@react-pdf/renderer';
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import BaseModal from "./baseModal/BaseModal";
import { GET_HTML } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class ApplicationViewOnlyModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      formSections: [],
      displayPDF: false,
      isMembershipAutoRenew: false
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  showProgramPlanCoupon = () => {
    const plan = JSON.parse(
      this.props.program.product.plans[this.props.program.plan_id]
    );
    const objConv = {
      week: "Weekly",
      "bi-week": "Bi-Weekly",
      month: "Monthly"
    };

    if (!plan.type) {
      let couponDiscount = 0;
      // Coupon Applied
      if (this.props.program.coupon) {
        couponDiscount =
          this.props.program.coupon.type === "Percentage"
            ? parseFloat(
                parseFloat(plan.tuition_rate) *
                  (parseFloat(this.props.program.coupon.amount_off) / 100)
              )
            : parseFloat(this.props.program.coupon.amount_off);
      }

      return (
        <span>
          ({this.props.program.coupon.coupon_id}) / ${couponDiscount}
        </span>
      );
    }

    if (plan.type) {
      let couponDiscount = 0;
      // Coupon Applied
      if (this.props.program.coupon) {
        couponDiscount =
          this.props.program.coupon.type === "Percentage"
            ? parseFloat(
                parseFloat(plan.total_price) *
                  (parseFloat(this.props.program.coupon.amount_off) / 100)
              ) *
              parseInt(
                this.props.program.userSelectedQuantity
                  ? this.props.program.userSelectedQuantity
                  : 1
              )
            : parseFloat(this.props.program.coupon.amount_off);
      }

      if (this.props.program.drop_in_dates && this.props.program.coupon) {
        couponDiscount =
          this.props.program.coupon.type === "Percentage"
            ? parseFloat(
                parseFloat(parseFloat(plan.total_price) / plan.dayCount) *
                  (1 + parseInt(plan.dropin_interest) / 100) *
                  (parseFloat(this.props.program.coupon.amount_off) / 100)
              ).toFixed(2)
            : parseFloat(this.props.program.coupon.amount_off);
      }
      // ? `Semester Drop-in - $${parseFloat(
      //     (plan.total_price / plan.dayCount) *
      //       (1 + parseInt(plan.dropin_interest) / 100)
      //   ).toFixed(0)} / ${format(
      //     new Date(this.props.program.program_start),
      //     "LL/dd/yy"
      //   )} -
      //     ${format(
      //       addWeeks(
      //         new Date(this.props.program.program_start),
      //         this.props.program.number_of_weeks
      //       ),
      //       "LL/dd/yy"
      //     )}`

      return (
        <span>
          ({this.props.program.coupon.coupon_id}) / ${couponDiscount}
        </span>
      );
    }
  };

  showEventPlanCoupon = () => {
    const plan = JSON.parse(
      this.props.program.product.tickets[this.props.program.plan_id]
    );
    const ticketPrice = parseFloat(plan.ticket_price);

    let couponDiscount = 0;
    // Coupon Applied
    if (this.props.program.coupon) {
      couponDiscount =
        this.props.program.coupon.type === "Percentage"
          ? parseFloat(
              parseFloat(ticketPrice) *
                (parseFloat(this.props.program.coupon.amount_off) / 100)
            )
          : parseFloat(this.props.program.coupon.amount_off);
    }
    return (
      <span>
        ({this.props.program.coupon.coupon_id}) / ${couponDiscount}
      </span>
    );
  };

  showOnlinePlanCoupon = () => {
    const plan = JSON.parse(
      this.props.program.product.plans[this.props.program.plan_id]
    );
    const objConv = {
      week: "Weekly",
      "bi-week": "Bi-Weekly",
      month: "Monthly"
    };

    if (!plan.type) {
      let couponDiscount = 0;
      // Coupon Applied
      if (this.props.program.coupon) {
        couponDiscount =
          this.props.program.coupon.type === "Percentage"
            ? parseFloat(
                parseFloat(plan.tuition_rate) *
                  (parseFloat(this.props.program.coupon.amount_off) / 100)
              )
            : parseFloat(this.props.program.coupon.amount_off);
      }

      return (
        <span>
          ({this.props.program.coupon.coupon_id}) / ${couponDiscount}
        </span>
      );
    }
    if (plan.type === "Free") {
      return <span>Free</span>;
    } else {
      let couponDiscount = 0;
      // Coupon Applied
      if (this.props.program.coupon) {
        couponDiscount =
          this.props.program.coupon.type === "Percentage"
            ? parseFloat(
                parseFloat(plan.total_price) *
                  (parseFloat(this.props.program.coupon.amount_off) / 100)
              )
            : parseFloat(this.props.program.coupon.amount_off);
      }
      return (
        <span>
          ({this.props.program.coupon.coupon_id}) / ${couponDiscount}
        </span>
      );
    }
  };

  showMembershipPlanCoupon = () => {
    const plan = JSON.parse(
      this.props.program.product.prices[this.props.program.plan_id]
    );
    const pricePerTerm = parseFloat(plan.price_per_term);

    let couponDiscount = 0;
    // Coupon Applied
    if (this.props.program.coupon) {
      couponDiscount =
        this.props.program.coupon.type === "Percentage"
          ? parseFloat(
              parseFloat(pricePerTerm) *
                (parseFloat(this.props.program.coupon.amount_off) / 100)
            )
          : parseFloat(this.props.program.coupon.amount_off);
    }
    return (
      <span>
        ({this.props.program.coupon.coupon_id}) / ${couponDiscount}
      </span>
    );
  };

  showProgramPlan = () => {
    // const plan = JSON.parse(
    //   this.props.program.product.plans[this.props.program.plan_id]
    // );
    const plan = JSON.parse(
      this.props.program.product.plans.filter(
        e => JSON.parse(e).id == this.props.program.plan_id
      )[0]
    );

    const objConv = {
      week: "Weekly",
      "bi-week": "Bi-Weekly",
      month: "Monthly"
    };
    if (this.props.program.isSemesterInstallment) {
      return (
        <span>
          {`Semester - Inst - $${parseFloat(
            (plan.total_price / plan.installments_breakdown) *
              (1 + parseInt(plan.installments_interest) / 100)
          ).toFixed(0)} - ${plan.installments_plan} / ${format(
            new Date(this.props.program.product.program_start),
            "LL/dd/yy"
          )} -
              ${format(
                addWeeks(
                  new Date(this.props.program.product.program_start),
                  this.props.program.product.number_of_weeks
                ),
                "LL/dd/yy"
              )}`}
        </span>
      );
    }
    return (
      <span>
        {plan.type === "Semester"
          ? this.props.program.drop_in_dates
            ? `Semester Drop-in - $${parseFloat(
                (plan.total_price / plan.dayCount) *
                  (1 + parseInt(plan.dropin_interest) / 100)
              ).toFixed(0)} / ${format(
                new Date(this.props.program.product.program_start),
                "LL/dd/yy"
              )} -
                ${format(
                  addWeeks(
                    new Date(this.props.program.product.program_start),
                    this.props.program.product.number_of_weeks
                  ),
                  "LL/dd/yy"
                )}`
            : `Semester - $${plan.total_price} - ${format(
                new Date(this.props.program.product.program_start),
                "LL/dd/yy"
              )} -
                  ${format(
                    addWeeks(
                      new Date(this.props.program.product.program_start),
                      this.props.program.product.number_of_weeks
                    ),
                    "LL/dd/yy"
                  )}`
          : plan.type === "Free"
          ? "Free"
          : plan.type === "Single Sessions"
          ? `Single Sessions - $${plan.total_price} x(${this.props.program.userSelectedQuantity})`
          : `Recurring - $${plan.tuition_rate} / ${
              objConv[plan.billing_cycle]
            }, \n ${
              plan.isReplaceTimeEnabled
                ? `${plan.description}`
                : `${plan.timing[0]} to ${plan.timing[1]}`
            }`}
      </span>
    );
  };

  showOnlinePlan = () => {
    const plan = JSON.parse(
      this.props.program.product.plans[this.props.program.plan_id]
    );
    const objConv = {
      week: "Weekly",
      "bi-week": "Bi-Weekly",
      month: "Monthly"
    };
    if (!plan.type) {
      return (
        <span>
          Recurring - ${plan.tuition_rate} / {objConv[plan.billing_cycle]}
        </span>
      );
    }
    if (plan.type === "Free") {
      return <span>Free</span>;
    } else if (plan.type === "Single Sessions") {
      return `Single Sessions - $${plan.total_price} x(${this.props.program.userSelectedQuantity})`;
    } else {
      return <span>${plan.total_price}</span>;
    }
  };

  showEventPlan = () => {
    const plan = JSON.parse(
      this.props.program.product.tickets[this.props.program.plan_id]
    );
    const ticketName =
      plan.ticket_status === "Paid" ? "Standard" : plan.ticket_status;
    const ticketPrice = plan.ticket_price ? `$${plan.ticket_price}` : "Free";
    return (
      <span>
        {ticketName} Ticket / {ticketPrice}
      </span>
    );
  };

  showMembershipPlan = () => {
    const plan = JSON.parse(
      this.props.program.product.prices[this.props.program.plan_id]
    );
    const membershipTerm = plan.membership_term;
    const renewalStatus = plan.auto_renew
      ? "Subscription Membership"
      : "Standard Membership";
    const pricePerTerm = plan.price_per_term;
    return (
      <span>
        {membershipTerm} Month Term - {renewalStatus} - {pricePerTerm}
      </span>
    );
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/forms/${this.props.program.formId}`;
    const results = await axios.get(ep);

    if (results.data.success) {
      this.setState({ formSections: results.data.data.sections });
    }

    const customFormData =
      this.props.program.formData && Object.keys(this.props.program.formData);

    const formArr = [];

    const formObj = {};

    customFormData.map(lead => {
      const fullLabel = lead;
      const leadSectionId = lead.match(/\d+/)[0];

      const leadSectionLabel = lead.slice(lead.match(/\d+/)[0].length);
      const leadSectionData = results.data.data.sections.filter(
        e => e.id === parseInt(leadSectionId)
      );

      const leadSectionName = leadSectionData[0].form_section_name;
      const leadValue = this.props.program.formData[lead];

      const obj = {
        sectionId: leadSectionId,
        originalLabel: fullLabel,
        name: leadSectionLabel,
        sectionName: leadSectionName,
        value: leadValue
      };
      formArr.push({
        sectionId: leadSectionId,
        originalLabel: fullLabel,
        name: leadSectionLabel,
        sectionName: leadSectionName,
        value: leadValue
      });

      if (!formObj[leadSectionName]) {
        formObj[leadSectionName] = [];
      }
      formObj[leadSectionName] = [...formObj[leadSectionName], obj];
      return lead;
    });

    formArr.sort((a, b) => a.sectionId - b.sectionId);

    const nameOfSection = Object.keys(formObj);

    const filteredSections = results.data.data.sections.filter(
      e =>
        e.form_section_name ===
        nameOfSection.filter(f => f === e.form_section_name)[0]
    );

    if (this.props.displayCategory === "Membership") {
      const plan = JSON.parse(
        this.props.program.product.prices[this.props.program.plan_id]
      );
      this.setState({ isMembershipAutoRenew: plan.auto_renew ? true : false });
    }

    this.setState({
      customFormData: formArr,
      customFormDataObj: formObj,
      filteredSections: filteredSections
    });
  }

  downloadPDF = () => {
    const domElement = document.getElementById("application-pdf");
    this.setGlobal({ loading: true });
    html2canvas(domElement, {
      onclone: document => {
        document.getElementById("modal-footer").style.visibility = "hidden";
        document.getElementById("pdf-download").style.visibility = "hidden";
        // document.getElementById('message-area').style.visibility = 'hidden'
      },
      scale: 3,
      scrollX: 0,
      scrollY: 0
    })
      .then(canvas => {
        const PDF_MARGIN = 20;
        const PDF_WIDTH = 140;
        const MAX_HEIGHT = 250;

        const img = canvas.toDataURL("image/png");
        const pdf = new jsPdf("p", "mm", "letter", true);

        const imgProps = pdf.getImageProperties(img);

        // Try setting height based on a standard width.
        let width = pdf.internal.pageSize.getWidth() - 40;
        let height = (imgProps.height * width) / imgProps.width;

        // If the height overflows the page, set width based on MAX_HEIGHT.
        if (height > MAX_HEIGHT) {
          height = MAX_HEIGHT;
          width = (imgProps.width * height) / imgProps.height;
        }

        pdf.addImage(img, "JPEG", PDF_MARGIN, PDF_MARGIN, width, height);
        pdf.save("Application.pdf");
        this.setGlobal({ loading: false });
      })
      .catch(error => {
        toast.error("Could not generate PDF.");
        this.setGlobal({ loading: false });
      });
  };

  displayModalData = () => {
    const local = DateTime.fromISO(this.props.program.createdAt);
    const rezoned = local.setZone(this.global.organizationInfo.timezone);

    console.log("this.props", this.props);

    return (
      <div style={{ padding: "10px" }} id="application-pdf">
        <div>
          {/*         <div>
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Contacts
            </label>
          </div> */}
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Name
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.displayName}
            </span>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Applied To
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.title}
            </span>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Payment Status
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.status_stage === "Accepted_Paid"
                ? "Paid"
                : "Pending"}
            </span>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Plan
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.displayCategory === "Program" &&
                this.showProgramPlan()}
              {this.props.displayCategory === "Event" && this.showEventPlan()}
              {this.props.displayCategory === "Membership" &&
                this.showMembershipPlan()}
              {this.props.displayCategory === "Online" && this.showOnlinePlan()}
            </span>
          </div>

          {this.props.program.coupon && (
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                htmlFor="displayName"
                style={{
                  width: "25%",
                  textAlign: "left",
                  fontSize: "1.15rem",
                  fontFamily: "futura-pt, sans-serif",
                  fontWeight: "500",
                  color: "#626262"
                }}
              >
                Coupon Applied
              </label>
              <span
                style={{
                  width: "70%",
                  fontSize: "16px",
                  fontFamily: "futura-pt, sans-serif",
                  fontWeight: "400",
                  color: "#626262"
                }}
              >
                {this.props.displayCategory === "Program" &&
                  this.showProgramPlanCoupon()}
                {this.props.displayCategory === "Event" &&
                  this.showEventPlanCoupon()}
                {this.props.displayCategory === "Membership" &&
                  this.showMembershipPlanCoupon()}
                {this.props.displayCategory === "Online" &&
                  this.showOnlinePlanCoupon()}
              </span>
            </div>
          )}

          {this.props.program.promotion && (
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                htmlFor="displayName"
                style={{
                  width: "25%",
                  textAlign: "left",
                  fontSize: "1.15rem",
                  fontFamily: "futura-pt, sans-serif",
                  fontWeight: "500",
                  color: "#626262"
                }}
              >
                Promo:
              </label>
              <span
                style={{
                  width: "70%",
                  fontSize: "16px",
                  fontFamily: "futura-pt, sans-serif",
                  fontWeight: "400",
                  color: "#626262"
                }}
              >
                {this.props.program.promotion.discount_type === "Percentage"
                  ? `${this.props.program.promotion.discount_percent}% ${this.props.program.promotion.duration_applied}`
                  : `$${this.props.program.promotion.discount_fixed} ${this.props.program.promotion.duration_applied}`}
              </span>
            </div>
          )}

          {this.props.displayCategory === "Program" &&
            JSON.parse(
              this.props.program.product.plans[this.props.program.plan_id]
            ).addOn && (
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{
                    width: "25%",
                    textAlign: "left",
                    fontSize: "1.15rem",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "500",
                    color: "#626262"
                  }}
                >
                  Add On Fee:
                </label>
                <span
                  style={{
                    width: "70%",
                    fontSize: "16px",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "400",
                    color: "#626262"
                  }}
                >
                  $
                  {
                    JSON.parse(
                      this.props.program.product.plans[
                        this.props.program.plan_id
                      ]
                    ).addOnItems[0].addOnFee
                  }
                </span>
              </div>
            )}

          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Email
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.email}
            </span>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Cell Phone
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.homePhone}
            </span>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Address
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.address}
            </span>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              City
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.city}
            </span>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Postal / Zip
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.postalCode}
            </span>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Region
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.province}
            </span>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Country
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.country}
            </span>
          </div>

          {this.props.program.childId && (
            <>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{
                    width: "25%",
                    textAlign: "left",
                    fontSize: "1.15rem",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "500",
                    color: "#626262"
                  }}
                >
                  Child's Name
                </label>
                <span
                  style={{
                    width: "70%",
                    fontSize: "16px",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "400",
                    color: "#626262"
                  }}
                >
                  {this.props.program.childName} (
                  <Link
                    to={`/partners/child-profile/${this.props.program.dsUserId}/${this.props.program.childId}`}
                  >
                    View more details
                  </Link>
                  )
                </span>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{
                    width: "25%",
                    textAlign: "left",
                    fontSize: "1.15rem",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "500",
                    color: "#626262"
                  }}
                >
                  Date of Birth
                </label>
                <span
                  style={{
                    width: "70%",
                    fontSize: "16px",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "400",
                    color: "#626262"
                  }}
                >
                  {moment(
                    new Date(
                      this.props.program.birthDate
                        .replace(/-/g, "/")
                        .replace(/T.+/, "")
                    )
                  ).format("MMMM Do, YYYY")}
                </span>
              </div>
            </>
          )}

          {/* {this.props.program.startDate && ()} */}
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Start Date
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.startDate
                ? moment(
                    new Date(
                      this.props.program.startDate
                        .replace(/-/g, "/")
                        .replace(/T.+/, "")
                    )
                  ).format("MMMM Do, YYYY")
                : moment(
                    new Date(
                      this.props.program.createdAt
                        .replace(/-/g, "/")
                        .replace(/T.+/, "")
                    )
                  ).format("MMMM Do, YYYY")}
            </span>
          </div>
          {this.props.displayCategory === "Program" &&
            this.props.program.recurringCustomerSelectedDays.length > 0 && (
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{
                    width: "25%",
                    textAlign: "left",
                    fontSize: "1.15rem",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "500",
                    color: "#626262"
                  }}
                >
                  Selected Days
                </label>
                <span
                  style={{
                    width: "70%",
                    fontSize: "16px",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "400",
                    color: "#626262"
                  }}
                >
                  {this.props.program.recurringCustomerSelectedDays.map(
                    (e, i) => {
                      const element = JSON.parse(e);
                      if (
                        this.props.program.recurringCustomerSelectedDays[i + 1]
                      ) {
                        return `${element.name}, `;
                      } else {
                        return element.name;
                      }
                    }
                  )}
                </span>
              </div>
            )}
          {this.props.program.drop_in_dates && (
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                htmlFor="displayName"
                style={{
                  width: "25%",
                  textAlign: "left",
                  fontSize: "1.15rem",
                  fontFamily: "futura-pt, sans-serif",
                  fontWeight: "500",
                  color: "#626262"
                }}
              >
                Drop In Dates
              </label>
              <span
                style={{
                  width: "70%",
                  fontSize: "16px",
                  fontFamily: "futura-pt, sans-serif",
                  fontWeight: "400",
                  color: "#626262"
                }}
              >
                {this.props.program.drop_in_dates.map(e => {
                  return (
                    <span>
                      {moment(
                        new Date(e.replace(/-/g, "/").replace(/T.+/, ""))
                      ).format("MMMM Do, YYYY")}{" "}
                    </span>
                  );
                })}
              </span>
            </div>
          )}

          {this.state.filteredSections &&
            this.state.filteredSections.map(section => {
              const formFilterData =
                this.state.customFormDataObj[section.form_section_name];

              // const filteredValues = formFilterData.map(i => {
              //   section.fields
              // })

              const fieldsFilter = section.fields.map(g => {
                if (
                  formFilterData.filter(
                    h => h.name === JSON.parse(g).first_label
                  ).length > 0
                ) {
                  return g;
                }
                return;
              });

              return (
                <>
                  <h3>{section.form_section_name}</h3>

                  {section.fields
                    .filter(g => {
                      if (
                        formFilterData.filter(
                          h => h.name === JSON.parse(g).first_label
                        ).length > 0
                      ) {
                        return g;
                      }
                      return;
                    })
                    .map(e => {
                      const field = JSON.parse(e);

                      const formData = this.state.customFormDataObj[
                        `${section.form_section_name}`
                      ].filter(f => f.name === field.first_label);

                      if (field.first_label === formData[0].name) {
                        return (
                          <div
                            className="form-group"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: 0
                            }}
                          >
                            <label
                              htmlFor="displayName"
                              style={{
                                width: "25%",
                                textAlign: "left",
                                fontSize: "1.15rem",
                                fontFamily: "futura-pt, sans-serif",
                                fontWeight: "500",
                                color: "#626262"
                              }}
                            >
                              {field.first_label}
                            </label>
                            <span
                              style={{
                                width: "70%",
                                fontSize: "16px",
                                fontFamily: "futura-pt, sans-serif",
                                fontWeight: "400",
                                color: "#626262"
                              }}
                            >
                              {typeof formData[0].value === "object" &&
                              formData[0].value !== null ? (
                                Object.keys(formData[0].value).map(e => (
                                  <span>{e} </span>
                                ))
                              ) : typeof formData[0].value === "boolean" ? (
                                formData[0].value ? (
                                  "Yes"
                                ) : (
                                  "No"
                                )
                              ) : formData[0].value.includes("http") ? (
                                <a href={formData[0].value}>View File</a>
                              ) : formData[0].value.includes(
                                  "data:image/png"
                                ) ? (
                                <img src={formData[0].value} width="150px" />
                              ) : formData[0].value.includes("-") ? (
                                moment(
                                  new Date(
                                    formData[0].value
                                      .replace(/-/g, "/")
                                      .replace(/T.+/, "")
                                  )
                                ).format("MMMM Do, YYYY")
                              ) : (
                                formData[0].value
                              )}
                            </span>
                          </div>
                        );
                      }
                    })}
                </>
              );
            })}

          {/* {this.props.program.custom && Object.keys(this.props.program.custom).map(lead => (
                <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                  <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                    {lead.match(/\d+/) ? lead.substr(lead.match(/\d+/)[0].length) : lead}{" "}  
                  </label>
                  <span
                      style={{ width: "70%", color: "black", fontSize: "16px" }}
                  >
                  {(typeof this.props.program.custom[lead] === 'object' && this.props.program.custom[lead] !== null) ? Object.keys(this.props.program.custom[lead]).map(e => <span>{e}{" "}</span>) : this.props.program.custom[lead].includes("http") ? (
                    <a href={this.props.program.custom[lead]}>View File</a>
                  ) : this.props.program.custom[lead].includes("data:image/png") ? (
                      <img src={this.props.program.custom[lead]} width="150px" />
                  ) : this.props.program.custom[lead].includes("-") ? (
                      moment(new Date(this.props.program.custom[lead].replace(/-/g, '\/').replace(/T.+/, ''))).format('MMMM Do, YYYY')
                  ) : (
                      this.props.program.custom[lead]
                  )
                  }
                  </span>
                  </div>
            ))} */}
        </div>

        {/* this.props.noF ? 0 :  */}
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            htmlFor="displayName"
            style={{
              width: "25%",
              textAlign: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            Submission
          </label>
          <span
            style={{
              width: "70%",
              fontSize: "16px",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "400",
              color: "#626262"
            }}
          >
            {rezoned.toFormat("fff")}
          </span>
        </div>

        {this.props.program.userSelectedQuantity && (
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Number of sessions requested
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.userSelectedQuantity}
            </span>
          </div>
        )}

        {this.props.program.sessionTiming?.length > 0 && (
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Requested Times
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.sessionTiming.map((e, i) => {
                const element = typeof e === "string" ? JSON.parse(e) : e;
                let date = new Date(element.date);
                date.setHours(element.hours);
                date.setMinutes(element.minutes === 5 ? 30 : 0);

                return (
                  <span>
                    {format(new Date(date), "LLLL dd, yyyy h:mm a")}
                    {this.props.program.sessionTiming.length - 1 !== i
                      ? ", "
                      : ""}
                  </span>
                );
              })}
            </span>
          </div>
        )}

        {this.props.program.sessionDates?.length > 0 && (
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Requested Dates
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.sessionDates.map((e, i) => {
                return (
                  <span>
                    {format(new Date(e), "LLLL dd, yyyy")}
                    {this.props.program.sessionDates.length - 1 !== i
                      ? ", "
                      : ""}
                  </span>
                );
              })}
            </span>
          </div>
        )}

        {this.props.displayCategory === "Program" &&
          this.props.program.sessionsCustomerSelectedDays.length > 0 && (
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                htmlFor="displayName"
                style={{
                  width: "25%",
                  textAlign: "left",
                  fontSize: "1.15rem",
                  fontFamily: "futura-pt, sans-serif",
                  fontWeight: "500",
                  color: "#626262"
                }}
              >
                Selected Days
              </label>
              <span
                style={{
                  width: "70%",
                  fontSize: "16px",
                  fontFamily: "futura-pt, sans-serif",
                  fontWeight: "400",
                  color: "#626262"
                }}
              >
                {this.props.program.sessionsCustomerSelectedDays.map((e, i) => {
                  const element = JSON.parse(e);
                  if (this.props.program.sessionsCustomerSelectedDays[i + 1]) {
                    return `${element.name}, `;
                  } else {
                    return element.name;
                  }
                })}
              </span>
            </div>
          )}

        <div className="modal-footer" id="modal-footer"></div>

        {/*         <IconButton
          style={{ position: "absolute", right: "0", top: "0" }}
          onClick={_ => this.props.onClose()}
        >
          <Close />
        </IconButton> */}
      </div>
    );
  };

  getBaseModalFields() {
    const allFields = {
      type: GET_HTML,
      data: {
        gethtml: this.displayModalData
      }
    };

    const fields = [];
    fields.push(allFields);

    return fields;
  }

  getBaseModalButtons = () => {};

  getBaseModalProps = () => {
    return {
      title: `Application Details`,
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    if (!this.state.customFormDataObj) {
      return null;
    }
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          {this.displayModalData()}
        </div>
      </div>
    );
  }
}

export default withRouter(ApplicationViewOnlyModal);
