import React from "reactn";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import Axios from "axios";
import Task from "../../components/admin/Task";
import { Button } from "@material-ui/core";
import { format } from "date-fns";
class autoFollowups extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectVals: [],
      tasks: [],
      pre: [],
      post: [],
      completed: [],
      postTourShow: false,
      preTourShow: true,
      completedShown: false
    };
  }
  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/followups/tasks`;
    const results = await Axios.get(ep);

    if (results.data.success) {
      this.setState({
        pre: results.data.data.pre,
        post: results.data.data.post,
        completed: results.data.data.completed
      });
    }
  }

  render() {
    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          <div className="cont">
            <h1>Auto Follow-ups</h1>
            <div style={{ marginBottom: "15px" }}>
              {[
                {
                  link: " Tour reminders",
                  active: this.state.preTourShow,
                  func: () => {
                    this.setState({
                      preTourShow: true,
                      completedShown: false,
                      postTourShow: false
                    });
                  }
                },
                {
                  link: "Post-tour check",
                  active: this.state.postTourShow,
                  func: () => {
                    this.setState({
                      preTourShow: false,
                      completedShown: false,
                      postTourShow: true
                    });
                  }
                },
                {
                  link: "Completed tasks",
                  active: this.state.completedShown,
                  func: () => {
                    this.setState({
                      preTourShow: false,
                      completedShown: true,
                      postTourShow: false
                    });
                  }
                }
              ].map((element, i) => (
                <Button
                  variant="contained"
                  color={element.active ? "primary" : "default"}
                  onClick={element.func}
                  key={i}
                >
                  {element.link}
                </Button>
              ))}
            </div>
            <div>
              {this.state.postTourShow &&
                this.state.post.map(task => (
                  <Task
                    key={task.id}
                    task={task}
                    completed={task => {
                      const copyArr = [...this.state.post];
                      const element = copyArr.splice(
                        copyArr.indexOf(copyArr.find(t => t.id === task.id)),
                        1
                      )[0];
                      const completed = [...this.state.completed];
                      completed.push(element);
                      this.setState({
                        completed,
                        post: copyArr
                      });
                    }}
                  />
                ))}
              {this.state.preTourShow &&
                this.state.pre.map(task => (
                  <Task
                    key={task.id}
                    task={{
                      ...task,
                      taskName: `${task.displayName} (${format(
                        new Date(task.requestedTourDate),
                        "LLL dd, yyyy - hh:mm a"
                      )}) - ${
                        task.canceled
                          ? "Canceled Tour"
                          : task.accepted
                          ? "Accepted Tour"
                          : "Pending (No tour)"
                      }`
                    }}
                    completed={task => {
                      const copyArr = [...this.state.pre];
                      const element = copyArr.splice(
                        copyArr.indexOf(copyArr.find(t => t.id === task.id)),
                        1
                      )[0];
                      const completed = [...this.state.completed];
                      completed.push(element);
                      this.setState({
                        completed,
                        pre: copyArr
                      });
                    }}
                  />
                ))}
              {this.state.completedShown &&
                this.state.completed.map(task => (
                  <Task key={task.id} task={task} />
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default autoFollowups;
