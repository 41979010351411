import React, { useState, useEffect } from "reactn";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  makeStyles,
  IconButton
} from "@material-ui/core";
import { EditorState, convertToRaw, Modifier, convertFromRaw } from "draft-js";
import axios from "axios";
import TextEditor from "../partner/Editor";
import TemplateEditorHeader from "./TemplateEditorHeader";
import TemplateEditorFooter from "./TemplateEditorFooter";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "'Open Sans', sans-serif",
    justifyContent: "center",
    zIndex: "99999999 !important"
  },
  // remove scrollbars
  dialogContent: {
    maxWidth: "900px",
    overflow: "hidden",
    height: "100%",
    padding: "1rem 0 0 0",
    zIndex: "99999999 !important"
  },
  dialog: {
    zIndex: "999999999 !important"
  },
  emailContainer: {
    margin: "1rem 2rem"
  }
}));

const emptyFieldMessage = "Title, Subject, and Body cannot be empty.";
const genericErrorMessage = "Save failed. Please try again.";
const duplicateTitle = "A template with this title already exists.";

const successMessage = "Template saved!";

const TemplateEditor = props => {
  const [subject, setSubject] = useState("");
  const [title, setTitle] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [templateToEdit, setTemplateToEdit] = useState(null);
  const classes = useStyles();

  const { onSave, onClose, allTemplates } = props;

  useEffect(() => {
    if (templateToEdit) {
      const loadTemplateForView = () => {
        try {
          const {
            content,
            subject: templateSubject,
            title: templateTitle
          } = templateToEdit;
          const rawContentState = convertFromRaw(content);
          const editorState = EditorState.createWithContent(rawContentState);
          setEditorState(EditorState.moveSelectionToEnd(editorState));
          setSubject(templateSubject);
          setTitle(templateTitle);
        } catch (error) {
          console.error(error);
        }
      };
      loadTemplateForView();
    } else {
      clearState();
    }
  }, [templateToEdit]);

  const handleClose = () => {
    clearState();
    onClose();
  };

  const clearState = () => {
    setTitle("");
    setSubject("");
    setEditorState(EditorState.createEmpty());
    //setRecentlyFetched(false);
    setTemplateToEdit(null);
  };

  const handleVariableValueClick = value => {
    let textToInsert;
    switch (value) {
      case "Contact's Name":
        textToInsert = "{{Name}}";
        break;
      default:
        break;
    }
    const newEditorState = insertText(textToInsert);
    setEditorState(newEditorState);
  };

  // Inserts variables text into editor
  const insertText = textToInsert => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    const newContentState = Modifier.insertText(
      contentState,
      selectionState,
      textToInsert
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );
    return newEditorState;
  };

  // Sends editor content to back-end
  const handleSave = async () => {
    try {
      if (title && subject && editorState.getCurrentContent().hasText()) {
        if (templateToEdit) {
          const ep = `${process.env.REACT_APP_API}/partners/connections/update_template`;
          await axios.post(ep, {
            id: templateToEdit.id,
            title,
            subject,
            content: convertToRaw(editorState.getCurrentContent())
          });
        } else {
          if (allTemplates.find(tmp => tmp.title === title)) {
            toast.error(duplicateTitle);
            return;
          }
          const ep = `${process.env.REACT_APP_API}/partners/connections/create_template`;
          await axios.post(ep, {
            title,
            subject,
            content: convertToRaw(editorState.getCurrentContent())
          });
        }
        handleClose();
        onSave();
      } else {
        toast.error(emptyFieldMessage);
      }
    } catch (error) {
      toast.error(genericErrorMessage);
    }
  };

  /**
   * No need to toast error/success messages since the confirm archive modal already handles it.
   */
  const handleDelete = async () => {
    try {
      if (!templateToEdit) {
        return;
      }
      const ep = `${process.env.REACT_APP_API}/partners/connections/delete_template`;
      const result = await axios.post(ep, { id: templateToEdit.id });
      if (result.data.success) {
        onSave();
        setTemplateToEdit(null);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={false}
      maxWidth="md"
      className={classes.root}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid container className={classes.emailContainer}>
            <TemplateEditorHeader
              handleClose={handleClose}
              handleDelete={handleDelete}
              title={title}
              setTemplateToEdit={setTemplateToEdit}
              templateToEdit={templateToEdit}
              allTemplates={allTemplates}
              setTitle={setTitle}
              subject={subject}
              setSubject={setSubject}
            />

            <Grid item xs={12}>
              <TextEditor
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </Grid>
          </Grid>

          <TemplateEditorFooter
            handleVariableValueClick={handleVariableValueClick}
            handleClose={handleClose}
            handleSave={handleSave}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default TemplateEditor;
