import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import AddTaskModal from "../../components/modals/AddTaskModal";
import AddReminderModal from "../../components/modals/AddReminderModal";
import ParentMilestoneViewModal from "../../components/modals/ParentMilestoneViewModal";
import { Delete } from "@material-ui/icons";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import AppointmentDetailsModal from "../../components/modals/AppointmentDetailsModal";
import Details from "../../components/modals/DetailsModal";
import ConnectionsViewModal from "../../components/modals/ConnectionsViewModal";
import NotesModal from "../../components/modals/NotesModal";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Avatar from "@material-ui/core/Avatar";
import "../../assets/css/componentSpecificCss/singleConnectionsOverview.css";
import Profile from "./connections/Profile";
import InviteConnectionModal from "../../components/modals/InviteConnectionModal";
import ConfirmArchiveModal from "../../components/modals/ConfirmArchiveModal";
import ApplicationViewOnlyModal from "../../components/modals/ApplicationViewOnlyModal";
import ConnectionsApplicationViewModal from "../../components/modals/ConnectionsApplicationViewModal";
import UploadFileModal from "../../components/modals/UploadFileModal";
import ReactTooltip from "react-tooltip";
import RemoveFileConfirmationModal from "../../components/modals/RemoveFileConfirmationModal";
import SurveyViewModal from "../../components/modals/FormViewModal";
import NudgeInvitationModal from "../../components/modals/NudgeInvitationModal";
import MilestoneEventLogDetailsModal from "../../components/modals/MilestoneEventLogDetailsModal";
import EndEnrollmentModal from "../../components/modals/EndEnrollmentModal";

const styles = {
  tabRoot: {
    color: "#61616F !important",
    fontFamily: "Open Sans,sans-serif !important",
    fontWeight: "600 !important",
    fontSize: "1.1rem !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "#61616F !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "#61616F !important"
    }
  },
  tabSelected: {
    color: "black !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "black !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "black !important"
    }
  },
  tabIndicator: {
    backgroundColor: "#ff6663",
    height: "3px",
    marginTop: "20px"
  },
  iconButton: {
    backgroundColor: "transparent !important",
    padding: "1em !important",
    color: "rgba(0, 0, 0, 0.54) !important",
    "&:focus": {
      outline: "none !important"
    }
  },
  avatar: {
    width: "65px",
    height: "65px",
    fontWeight: 700,
    backgroundColor: "#c0d1e7"
  },
  item: {
    padding: "5px 10px",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "13px"
  },
  list: {
    height: "60px",
    padding: "0px"
  }
};
const defaultColor = "#1bc88a";

class SingleConnections extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: { Active: [], Uploads: [] },
      dataFinance: { ActiveFinance: [] },
      dataMilestone: { MilestoneEventLogs: [] },
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      popoverAnchorE1: null,
      filterListChecked: [],
      openApptModal: false,
      openContactUsModal: false,
      openEnrollmentModal: false,
      selectedItem: null,
      active: "Profile",
      openInviteModal: false,
      openConfirmArchiveModal: false,
      openEndEnrollmentModal: false,
      openViewApplicationModal: false,
      showParentMilestoneViewModal: false,
      currentParent: {},
      openUploadFileModal: false,
      location: {},
      has_submitted: false,
      organizerUploads: [],
      fileName: "",
      openRemoveFileConfirmationModal: false,
      selectedFile: null,
      openViewSurveymodal: false,
      name: "",
      milestones: [],
      openNudgeInvitationModal: false,
      showMilestoneTable: false,
      openMilestoneEventLogDetailsModal: false,
      selectedEvent: null
    };

    this.handleFilterListToggle = this.handleFilterListToggle.bind(this);
    this.filterListLabels = ["Name", "Date"];

    this.tabs = ["Active"];
    this.headings = {
      Active: [
        /*         {
          id: "displayName",
          label: "User Name",
          customCell: (r, i) => {
            const isCustom = r.custom ? true : false;
            const custom = r.custom ? r.custom : null;
            let nameKey = "";
            if (isCustom) {
              const keys = Object.keys(r.custom);
              nameKey = keys.filter(f => f.includes("Full Name"))[0];
            }

            return (
              <td key={`${i}-name`}>
                {isCustom ? `${custom[nameKey]}` : r.displayName}
              </td>
            );
          }
        },
        {
          id: "amount",
          label: "Amount",
          customCell: (r, i) => {
            return (
              <td key={`${i}-amount`}>
                <a href={`${process.env.REACT_APP_REDIRECT_URI}/partners/single/connections/${r.dsUserId}`}>{r.email}</a>
              </td>
            );
          }
        }, */
        {
          id: "phone",
          label: "Phone",
          customCell: (r, i) => {
            const custom = r.custom ? r.custom : null;
            let nameKey = "";
            if (custom) {
              const keys = Object.keys(r.custom);
              nameKey = keys.filter(f => f.includes("Cell Phone"))[0];
            }

            return (
              <td key={`${i}-name`}>
                {custom && custom[nameKey]
                  ? `${custom[nameKey]}`
                  : r.phone
                  ? r.phone
                  : "--"}
              </td>
            );
          }
        },
        {
          id: "came_from",
          label: "Interested In",
          customCell: (r, i) => {
            if (r.came_from.includes("Initiated")) {
              return <td key={`${i}-came_from`}>Initiated Booking</td>;
            }

            let statusStage = r.status_stage ? r.status_stage : "";
            if (r.status_stage && !r.product.application_required) {
              if (r.status_stage === "Accepted_Unpaid") {
                statusStage = "Direct_Unpaid";
              } else if (r.status_stage === "Accepted_Paid") {
                statusStage = "Direct_Paid";
              }
            }

            let description = "";
            let isFormCompleted = null;
            let productName = r.title;
            if (r.status_stage === "Accepted_Paid") {
              description = "Enrolled: " + productName;
            } else if (
              r.status_stage === "Waitlisted_Unpaid" ||
              r.status_stage === "New_Pending" ||
              r.status_stage === "Accepted_Unpaid"
            ) {
              description = "Application: " + productName;
            } else if (
              r.status_stage === "Inactive" ||
              r.status_stage === "Cancelled" ||
              r.status_stage === "Cancelled_Unpaid" ||
              r.status_stage === "Rejected" ||
              r.status_stage === "Application_Cancelled"
            ) {
              description = "Inactive: " + productName;
            } else {
              isFormCompleted = r.custom ? true : false;
              if (r.formId) {
                description = `${r.came_from}: ${
                  r.form ? r.form.form_sub_name : ""
                }`;
              } else {
                description = r.came_from;
                isFormCompleted = r.classification === "pending" ? false : true;
              }
            }

            return (
              <td key={`${i}-came_from`}>
                {description}{" "}
                <span style={{ fontStyle: "italic" }}>
                  {isFormCompleted === false ? `(Pending)` : ""}
                </span>
                <span style={{ fontStyle: "italic" }}>
                  {r.status_stage ? `(${statusStage})` : ""}
                </span>
              </td>
            );
          }
        },
        {
          id: "childName",
          label: "Additional"
        },
        {
          id: "createdAt",
          label: "Date",
          customCell: (r, i) => {
            return (
              <td key={`${i}-name`}>
                {format(new Date(r.createdAt), "LLLL dd, yyyy")}
              </td>
            );
          }
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            const type = r.classificationType;
            let milestone = [];
            let milestoneOnline = [];
            console.log(
              "MILESTONE CLASSIFICATION",
              type,
              r.programId,
              r,
              this.state.milestones
            );
            if (type && type === "Program") {
              milestone = this.state.milestones.filter(milestone => {
                return r.programId && milestone.program === r.programId;
              });
              console.log("MILESTONE ARR 1", milestone);
            } else if (type && type === "Online") {
              milestoneOnline = this.state.milestones.filter(
                milestone => r.programId && milestone.onlineId === r.programId
              );
              console.log("MILESTONE ARR 2", milestoneOnline);
            } else {
              console.log(
                "LOOK HERE123456",
                type,
                r.programId,
                r,
                this.state.milestones
              );
            }

            console.log(
              "MILESTONE ARR 3",
              milestone,
              milestone.length,
              milestoneOnline.length
            );

            if (
              r.status_stage === "Accepted_Paid" &&
              milestone.length > 0 &&
              (r.classificationType === "Program" ||
                r.classificationType === "Online")
            ) {
              this.setState({ showMilestoneTable: true });
            }

            let isPendingForm = false;
            if (
              (r.came_from === "Survey" ||
                r.came_from === "Contact-Us" ||
                r.came_from === "Waiver" ||
                r.came_from === "Appointments") &&
              r.custom === null
            ) {
              isPendingForm = true;
            }

            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                {/* <button
                  style={{ marginRight: "30px" }}
                  onClick={_ => this.openModal(r, i)}
                >
                  View Details
                </button> */}
                {r.status_stage === "Accepted_Paid" &&
                  milestone.length > 0 &&
                  (r.classificationType === "Program" ||
                    r.classificationType === "Online") && (
                    <>
                      {/* <p
                        style={{
                          color: "lightgrey",
                          fontSize: "16px",
                          cursor: "pointer",
                          color: "#ccc"
                        }}
                        id="milestone-icon"
                      >
                        View Milestone
                      </p> */}
                      <i
                        onClick={() => {
                          this.setState({
                            showParentMilestoneViewModal: true,
                            selectedItem: r
                          });
                        }}
                        id="milestone-icon"
                        className="fas fa-book-reader option-img"
                        style={{
                          color: "lightgrey",
                          fontSize: "16px",
                          cursor: "pointer",
                          color: "#ccc"
                        }}
                        data-tip
                        data-for="milestone-icon"
                      ></i>
                      <ReactTooltip
                        id="milestone-icon"
                        place="right"
                        effect="solid"
                        type="info"
                      >
                        View Milestone
                      </ReactTooltip>
                    </>
                  )}
                {r.status === "Enrollment" && (
                  <>
                    <i
                      className="fas fa-paper-plane"
                      style={{
                        marginRight: "30px",
                        cursor: "pointer",
                        color: "#ccc",
                        fontSize: "16px"
                      }}
                      onClick={_ => {
                        this.setState({
                          openViewApplicationModal: true,
                          selectedItem: r
                        })}
                      }
                      data-tip
                      data-for="application-icon"
                    ></i>
                    <ReactTooltip
                      id="application-icon"
                      place="right"
                      effect="solid"
                      type="info"
                    >
                      View Application Details
                    </ReactTooltip>
                  </>
                )}
                {r.came_from &&
                  r.came_from.startsWith("Invited") &&
                  r.classification === "pending" && (
                    <>
                      <i
                        className="fas fa-bell"
                        style={{
                          marginRight: "30px",
                          cursor: "pointer",
                          color: "#ccc",
                          fontSize: "16px"
                        }}
                        onClick={_ => {
                          this.setState({
                            openNudgeInvitationModal: true,
                            selectedItem: r
                          })}
                        }
                        data-tip
                        data-for="nudge-icon"
                      ></i>
                      <ReactTooltip
                        id="nudge-icon"
                        place="right"
                        effect="solid"
                        type="info"
                      >
                        Nudge
                      </ReactTooltip>
                    </>
                  )}
                {(r.came_from === "Survey" ||
                  r.came_from === "Contact-Us" ||
                  r.came_from === "Waiver" ||
                  r.came_from === "Appointments") &&
                  isPendingForm && (
                    <>
                      <i
                        className="fas fa-bell"
                        style={{
                          marginRight: "10px",
                          cursor: "pointer",
                          color: "#ccc",
                          fontSize: "16px"
                        }}
                        onClick={_ => {
                          this.setState({
                            openNudgeInvitationModal: true,
                            selectedItem: r
                          })}
                        }
                        data-tip
                        data-for="nudge-icon"
                      ></i>
                      <ReactTooltip
                        id="nudge-icon"
                        place="right"
                        effect="solid"
                        type="info"
                      >
                        Nudge
                      </ReactTooltip>
                    </>
                  )}
                {(r.came_from === "Survey" ||
                  r.came_from === "Contact-Us" ||
                  r.came_from === "Waiver" ||
                  r.came_from === "Appointments") &&
                  !isPendingForm && (
                    <>
                      <i
                        className="fas fa-paper-plane"
                        style={{
                          marginRight: "30px",
                          cursor: "pointer",
                          color: "#ccc",
                          fontSize: "16px"
                        }}
                        onClick={_ => {
                          this.setState({
                            openViewSurveyModal: true,
                            selectedItem: r
                          })}
                        }
                        data-tip
                        data-for="application-icon"
                      ></i>
                      <ReactTooltip
                        id="application-icon"
                        place="right"
                        effect="solid"
                        type="info"
                      >
                        View Application Details
                      </ReactTooltip>
                    </>
                  )}
                {!isPendingForm && (
                  <>
                    <i
                      className="fas fa-comment-dots"
                      style={{
                        marginRight: "30px",
                        cursor: "pointer",
                        color: "#ccc",
                        fontSize: "16px"
                      }}
                      onClick={_ => this.openModal(r, i)}
                      data-tip
                      data-for="connection-details-icon"
                    ></i>
                    <ReactTooltip
                      id="connection-details-icon"
                      place="right"
                      effect="solid"
                      type="info"
                    >
                      View Connection Details
                    </ReactTooltip>
                  </>
                )}
                {!isPendingForm && (
                  <>
                    <i
                      className="fas fa-clipboard icon"
                      style={{
                        marginRight: "10px",
                        cursor: "pointer",
                        color: "#ccc",
                        fontSize: "16px"
                      }}
                      onClick={_ =>
                        this.setState({
                          notesModalShown: true,
                          modalData: r,
                          table: r.table
                        })
                      }
                      data-tip
                      data-for="note-icon"
                    />
                    <ReactTooltip
                      id="note-icon"
                      place="right"
                      effect="solid"
                      type="info"
                    >
                      View Notes
                    </ReactTooltip>
                  </>
                )}
                {/* <button style={{ marginRight: "30px" }} onClick={_ => this.setState({ notesModalShown: true, modalData: r, table: r.came_from === "Contact-Us" ? "partner_leads" : "partner_connections" })}>Notes</button> */}
              </td>
            );
          }
        }
      ]
    };
    this.actions = [{ name: "Notes", action: null }];

    /* ==============  Milestone Event Log Section =============== */

    this.tabsMilestones = ["MilestoneEventLogs"];
    this.headingsMilestones = {
      MilestoneEventLogs: [
        {
          id: "MilestoneTitle",
          label: "Milestone",
          customCell: (r, i) => {
            return (
              <td key={`${i}-milestone-title`} style={{ width: "200px" }}>
                {r.event_name}
              </td>
            );
          }
        },
        {
          id: "MilestoneDescription",
          label: "Event Description",
          customCell: (r, i) => {
            return <td key={`${i}-milestone-title`}>{r.event_description}</td>;
          }
        },
        {
          id: "MilestoneEventTime",
          label: "Time",
          customCell: (r, i) => {
            const displayTime = new Date(r.timestamp);
            const formattedTime = displayTime.toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              timeZone: this.global.organizationInfo.timezone
            });

            return (
              <td style={{ width: "200px" }} key={`${i}-milestone-title`}>
                {formattedTime}
              </td>
            );
          }
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                style={{ textAlign: "right", width: "200px" }}
                key={`${i}-milestone-title`}
              >
                <button
                  onClick={e =>
                    this.setState({
                      openMilestoneEventLogDetailsModal: true,
                      selectedEvent: r
                    })
                  }
                >
                  View Details
                </button>
              </td>
            );
          }
        }
      ]
    };

    /* ==============  Financial Section =============== */
    this.tabsFinancials = ["ActiveFinance"];
    this.headingsFinancials = {
      ActiveFinance: [
        {
          id: "createdAt",
          label: "Date",
          customCell: (r, i) => {
            return (
              <td key={`${i}-name`}>
                {format(new Date(r.date), "LLLL dd, yyyy")}
              </td>
            );
          }
        },
        {
          id: "amount",
          label: "Total",
          customCell: (r, i) => {
            return (
              <td key={`${i}-name`}>${parseFloat(r.total / 100).toFixed(2)}</td>
            );
          }
        },
        {
          id: "item",
          label: "Item",
          customCell: (r, i) => {
            if (r.type !== "Invoice") {
              return (
                <td key={`${i}-name`} className="table-custom-text">
                  {r.item}
                </td>
              );
            }
            if (r.type === "Invoice") {
              if (r.cartOrder) {
                let productNameArr = r.invoice_details.map(
                  item => JSON.parse(item).itemName
                );

                let productName =
                  "Invoice: " +
                  productNameArr.map(item => item.split(": ")[1]).join(", ");

                if (productName.length > 90) {
                  productName = productName.substring(0, 150) + "...";
                }
                return (
                  <td key={`${i}-name`} className="table-custom-text">
                    {productName}
                  </td>
                );
              }

              return (
                <td key={`${i}-name`} className="table-custom-text">
                  {r.item}
                </td>
              );
            }
          }
        },
        {
          id: "purchaser",
          label: "Purchaser",
          customCell: (r, i) => {
            return <td key={`${i}-displayName`}>{r.purchaser}</td>;
          }
        },
        {
          id: "status",
          label: "Status",
          customCell: (r, i) => {
            let color = defaultColor;
            switch (r.status) {
              case "Draft":
                r.status = "Draft";
                color = "rgb(33 91 255)";
                break;

              case "Paid":
                r.status = "Paid";
                color = "#1bc88a";
                break;

              case "Ready to Bill":
                r.status = "Ready to Bill";
                color = "#eba93e";
                break;

              case "Cancelled":
                r.status = "Cancelled";
                color = "#ff3b8e";
                break;

              case "Issued":
                r.status = "Issued";
                color = "rgba(92 200 220)";
                break;

              case "Late":
                r.status = "Late";
                color = "#ff3b8e";
                break;

              case "Failed":
                r.status = "Failed";
                color = "#ff3b8e";
                break;

              case "Processing":
                r.status = "Processing";
                color = "rgb(33 91 255)";
                break;

              default:
                break;
            }

            return (
              <td key={`${i}-name`}>
                <div
                  style={{
                    textAlign: "right",
                    display: "flex"
                  }}
                >
                  <span
                    className="red"
                    style={{
                      minWidth: "100px",
                      background: color,
                      padding: "3px",
                      borderRadius: "13px",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Open Sans",
                      fontSize: "12px",
                      fontWeight: 600
                    }}
                  >
                    {r.status}
                  </span>
                </div>
              </td>
            );
          }
        }
      ]
    };
    this.actions = [{ name: "Notes", action: null }];

    /* ==============  Attachments Section =============== */
    this.tabsUploads = ["Uploads"];
    this.headingsUploads = {
      Uploads: [
        {
          id: "fileName",
          label: "File Name",
          customCell: (r, i) => {
            return (
              <td key={`${i}-fileName`}>
                {r.file_uploads.file_display_name
                  ? r.file_uploads.file_display_name
                  : r.file_uploads.file_name}
              </td>
            );
          }
        },
        {
          id: "type",
          label: "Type",
          customCell: (r, i) => {
            if (r.contactUploads || (!r.programId && !r.onlineId)) {
              return <td key={`${i}-name`}>Organizer</td>;
            }
            return (
              <td key={`${i}-name`}>
                {r.milestoneName} ({r.milestoneType})
              </td>
            );
          }
        },
        {
          id: "date",
          label: "Date",
          customCell: (r, i) => {
            return (
              <td key={`${i}-date`}>
                {format(new Date(r.file_uploads.upload_date), "LLLL dd, yyyy")}
              </td>
            );
          }
        },
        {
          id: "file",
          label: "File",
          customCell: (r, i) => {
            return (
              <td key={`${i}-file`}>
                <a href={r.file_uploads.file_url} target="_blank">
                  View File
                </a>
                {r.file_uploads.file_display_name && (
                  <>
                    <Delete
                      onClick={() => {
                        this.setState({
                          openRemoveFileConfirmationModal: true,
                          selectedFile: r
                        });
                      }}
                      style={{
                        fill: "grey",
                        marginLeft: "3px",
                        cursor: "pointer",
                        marginBottom: "2px"
                      }}
                      data-for="remove-file"
                      data-tip
                    />
                    <ReactTooltip
                      id="remove-file"
                      place="top"
                      effect="solid"
                      type="info"
                    >
                      Remove File
                    </ReactTooltip>
                  </>
                )}
              </td>
            );
          }
        }
      ]
    };
    this.actions = [{ name: "Notes", action: null }];
  }

  openModal = (r, i) => {
    if (r.came_from === "Appointments") {
      this.setState({ openApptModal: true, selectedItem: r });
    } else if (r.came_from === "Contact-Us") {
      this.setState({ openContactUsModal: true, selectedItem: r });
    } else {
      this.setState({ openEnrollmentModal: true, selectedItem: r });
    }
  };

  async fetchEventLogData(parentId) {
    console.log("MILESTONE EVENT LOG PARENT ID", parentId);
    const ep4 = `${process.env.REACT_APP_API}/partners/milestones_submission/event-log/${parentId}`;

    return axios
      .get(ep4)
      .then(response => {
        if (response.status === 200) {
          console.log("MILESTONE EVENT LOG RESULT", response);
          return response.data.submission.sort((a, b) => {
            const timestampA = new Date(a.timestamp).getTime();
            const timestampB = new Date(b.timestamp).getTime();
            return timestampB - timestampA;
          });
        } else {
          console.error(
            "Event Log Endpoint responded with status:",
            response.status
          );
          return []; // Return an empty array or default data when the endpoint doesn't exist
        }
      })
      .catch(error => {
        console.log("MILESTONE EVENT LOG FAIL");
        console.error(
          "An error occurred while fetching milestone event logs:",
          error
        );
        return []; // Return an empty array or default data in case of other errors
      });
  }

  pauseEnrollment = async (isStripe, id, resume) => {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/orders/toggle-pause`;
    const res = await axios.post(ep, { id: id, is_stripe: isStripe });
    if (res.data.success) {
      toast.success(`Successfully ${resume ? "resumed" : "paused"} enrollment`);
    } else {
      toast.error("Please wait until the next invoice has been generated!");
    }
    this.setState({ openViewApplicationModal: false });
    await this.fetchInitialData();
  };

  async componentDidMount() {
    this.setGlobal({ loading: true });
    await this.fetchInitialData();
    await this.fetchFinancialDataCurrentUser();
    await this.fetchAttachmentData();
    this.setGlobal({ loading: false });
  }

  async componentDidUpdate(prevProps) {
    const { location } = this.props;

    // Check if the URL has changed
    if (location !== prevProps.location) {
      this.setGlobal({ loading: true });
      await this.fetchInitialData();
      await this.fetchFinancialDataCurrentUser();
      await this.fetchAttachmentData();
      this.setGlobal({ loading: false });
    }
  }

  async fetchInitialData() {
    const searchParams = new URLSearchParams(window.location.search);
    let parentId = searchParams.get("parentId");
    const tab = searchParams.get("tab");

    if (parentId === "undefined") {
      parentId = false;
    }
    if (tab === "History") {
      this.setState({ active: "History" });
    } else if (tab === "Financials") {
      this.setState({ active: "Financials" });
    } else if (tab === "Attachments") {
      this.setState({ active: "Attachments" });
    } else {
      this.setState({ active: "Profile" });
    }

    const ep = `${process.env.REACT_APP_API}/partners/connections/${true}`;
    const results = await axios.get(ep, {
      params: { parentId: parentId }
    });

    const ep3 = `${process.env.REACT_APP_API}/partners/milestones/get`;
    const res3 = await axios.get(ep3);

    if (parentId) {
      const ep2 = `${process.env.REACT_APP_API}/partners/connections/profile-info/${parentId}`;
      const results2 = await axios.get(ep2);
      const parentInfo = results2.data.data[0];
      console.log("PARENT INFO QUERY RESULT", parentId, results2.data.data);
      console.log("MILESTONE EVENT LOG PARENT ID", parentId);

      const ep4 = `${process.env.REACT_APP_API}/partners/connections/event-logs/${parentId}`;
      const res4 = await axios.get(ep4);

      // const ep4 = `${process.env.REACT_APP_API}/partners/milestones_submission/event-log/${parentId}`;
      // const res4 = await axios.get(ep4);
      // console.log("MILESTONE EVENT LOG RESULT", res4);

      // const milestoneEventLogData = await this.fetchEventLogData(parentId);
      // console.log("MILESTONE EVENT LOG DATA", milestoneEventLogData);
      // const milestoneEventLogData = results2.data.data.eventLogInfo[0];
      let eventLogs = [];

      if (res4.data.success) {
        eventLogs = res4.data.data;
        eventLogs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      }

      console.log("RES4", res4);
      
      this.setState({
        name: parentInfo?.fullName,
        dataMilestone: {
          MilestoneEventLogs: eventLogs
        }
      });
    }

    if (results.data.success) {
      this.setState({
        data: {
          Active: results.data.data.connections
            .filter(
              f => f.email == this.props.match.params.email && !f.isArchived
            )
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        }
      });

      this.setGlobal({
        partnerPathway: [
          ...this.global.partnerPathway.slice(0, 1),
          { label: "Connections", to: "/connections" },
          {
            label: `${
              results.data.data.connections
                .filter(f => f.email == this.props.match.params.email)
                .sort(
                  (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                )[0].displayName
            }`,
            to: `/single/connections/${this.props.match.params.email}/true`
          }
        ]
      });
    }

    if (res3.data.success) {
      this.setState({
        milestones: res3.data["Active"]
      });
    }
    // Profile component will set loading to false when it completes render.
    // this.setGlobal({ loading: false });
  }

  async fetchFinancialDataCurrentUser() {
    const searchParams = new URLSearchParams(window.location.search);
    let parentId = searchParams.get("parentId");
    if (parentId === undefined) {
      parentId = false;
    }
    console.log("parentId: ", parentId);
    console.time("Start");
    // this.setGlobal({ loading: true });
    const Partner_Orders_ep = `${process.env.REACT_APP_API}/partners/order-tracking`;
    const Partner_Orders_results = await axios.get(Partner_Orders_ep,
      { params: { parentId: parentId } }
    );
    
    if (Partner_Orders_results.data.success) {
      const payments = Partner_Orders_results.data.data.Recurring.payments
        .concat(Partner_Orders_results.data.data.Once.payments)
        .concat(Partner_Orders_results.data.data.Once.failed)
        .concat(Partner_Orders_results.data.data.Recurring.failed); // Payments for Once and Recurring are added here
      const pending = Partner_Orders_results.data.data.Recurring.pending.concat(
        Partner_Orders_results.data.data.Once.pending
      ); // Pending for Once and Recurring are added here
      const inactive = Partner_Orders_results.data.data.Recurring.inactive.concat(
        Partner_Orders_results.data.data.Once.inactive
      ); // Inactive for Once and Recurring are added here

      const pendpay = pending.concat(payments); // All the payments and pending results are added here
      console.log("Pending and Payments...", pendpay);
      const allData = inactive.concat(pendpay); // Payments, pending and inactive results are added here
      console.log("all data...", allData);

      if (Partner_Orders_results.data.success) {
        console.log(
          "Partner_Orders Success ?",
          Partner_Orders_results.data.success
        );
        this.setState({
          dataFinance: {
            ActiveFinance: pendpay
              .filter(
                // For the finance tab  we are currently only using the payments and pendings
                f =>
                  f.email == this.props.match.params.email &&
                  !f.isArchived &&
                  !f.hidden
              )
              .sort((a, b) => new Date(b.date) - new Date(a.date))
          }
        });
      }
    }

    console.timeEnd("Start");
    // this.setGlobal({ loading: false });
  }

  async fetchAttachmentData() {
    // this.setGlobal({ loading: true });

    const searchParams = new URLSearchParams(window.location.search);
    const parentId = searchParams.get("parentId");

    this.setState({ selectedParent: parentId });

    let dsUserId = parseInt(this.state.selectedParent);

    if (Number.isInteger(parseInt(dsUserId))) {
      const ep3 = `${process.env.REACT_APP_API}/partners/milestones_submissions/get/all/${dsUserId}`;
      const milestone_submissions = await axios.get(ep3);
      const ep4 = `${process.env.REACT_APP_API}/partners/file_uploads/get/all/${dsUserId}`;
      const organizer_file_uploads = await axios.get(ep4);

      let fileArr = [];
      milestone_submissions.data.Participants.map(item => {
        if (item && item.file_uploads) {
          let count = 1;
          while (item.file_uploads["file_" + count]) {
            if (!item.file_uploads["file_" + count]?.isHidden) {
              fileArr.push({
                ...item,
                file_uploads: item.file_uploads["file_" + count]
              });
            }
            count++;
          }
        }
      });
      organizer_file_uploads.data.data.map(item => {
        if (item && item.contactUploads) {
          let count = 1;
          while (item.contactUploads["file_" + count]) {
            if (!item.contactUploads["file_" + count]?.isHidden) {
              fileArr.push({
                ...item,
                file_uploads: item.contactUploads["file_" + count]
              });
            }
            count++;
          }
        }
      });

      this.setState({
        data: {
          ...this.state.data,
          Uploads: fileArr
        }
      });
    }
    // this.setGlobal({ loading: false });
  }

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
    if (results.data.success) {
      toast.success(`Partner Accepted`);
      this.componentDidMount();
    } else {
      toast.error(`Error, this person already signed-up!`);
    }
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  handleClose() {
    this.setState({
      popoverAnchorE1: null
    });
  }

  handleFilterClick(event) {
    this.setState({
      popoverAnchorE1: event.currentTarget
    });
  }

  handleFilterListToggle(value) {
    const currentIndex = this.state.filterListChecked.indexOf(value);
    const newChecked = [...this.state.filterListChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      filterListChecked: newChecked
    });
  }

  pipeData(allData) {
    return {
      Active: allData
    };
  }

  pipeDataOrders(allData) {
    return {
      ActiveFinance: allData
    };
  }

  filterData = () => {
    if (this.state.search) {
      return {
        ...this.pipeData(
          this.state.data.Active.filter(
            e =>
              e.displayName
                .trim()
                .toLocaleLowerCase()
                .includes(this.state.search.trim().toLocaleLowerCase()) ||
              e.email
                .trim()
                .toLocaleLowerCase()
                .includes(this.state.search.trim().toLocaleLowerCase())
          )
        )
      };
    } else return this.state.data;
  };

  filterDataOrders = () => {
    if (this.state.search) {
      return {
        ...this.pipeDataOrders()
      };
    } else return this.state.dataFinance;
  };

  nudgeUser = async () => {
    const nudgeValue = this.state.data.Active[0];

    if (nudgeValue.nudgeCount >= 2) {
      toast.error(`Cannot nudge user more than twice`);
      return;
    }

    const endp = nudgeValue.promotionId
      ? `${process.env.REACT_APP_API}/partners/users/promo/invite_parent`
      : `${process.env.REACT_APP_API}/partners/users/invite_parent`;
    const result = await axios.post(endp, {
      name: nudgeValue.displayName,
      email: nudgeValue.email,
      program: nudgeValue.productId,
      program_name: nudgeValue.program_name,
      plan: nudgeValue.planId,
      modifier: nudgeValue.modifier,
      selectedUser: nudgeValue.dsUserId,
      type: nudgeValue.type,
      isParent: nudgeValue.isParent,
      amount: nudgeValue.amount,
      ticket: nudgeValue.ticket,
      product_table: nudgeValue.table,
      promotion: nudgeValue.promotionId ? nudgeValue.promotion : null,
      isNudgeOnly: true,
      invitationId: nudgeValue.id,
      nudgeCount: nudgeValue.nudgeCount
    });

    if (result.data.success) {
      toast.success("User Nudge Successful!");
      this.setGlobal({ loading: true });
      this.fetchInitialData();
      this.setGlobal({ loading: false });
    } else {
      toast.error(`User already nudged!`);
    }
  };

  archiveAddedConnection = async () => {
    const addedContact = this.state.data.Active[0];

    const endp = `${process.env.REACT_APP_API}/partners/leads/delete/added-contact`;
    const result = await axios.post(endp, {
      id: addedContact.id,
      isArchived: true,
      archivedDate: new Date()
    });

    if (result.data.success) {
      this.props.history.push("/partners/connections");
    } else {
      toast.error(`Unable to archive!`);
    }
  };

  async fileChange(e) {
    let count = 1;

    let fileNum = "";
    while (e.length >= 1) {
      fileNum = "file_" + count;

      let fileName = e[0].name;
      if (e.length && e[0]) {
        const fd = new FormData();
        fd.set("file", e[0]);

        const ep = `${process.env.REACT_APP_API}/partners/file_uploads/file/upload`;
        const postreq = await axios.post(ep, fd, {
          onUploadProgress: progressEvent => {
            let progress =
              Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
              "%";
            this.setState({ progress: progress });
          }
        });

        if (postreq.data && postreq.data.success) {
          const location = {
            name: postreq.data.name,
            path: postreq.data.path,
            fileName: fileName
          };
          this.setState({
            location: {
              ...this.state.location,
              [`${fileNum}`]: location
            }
          });
          count++;
          e.shift();
        }
      }
    }
    return;
  }

  submitFileUpload = async e => {
    this.setGlobal({ loading: true });

    const ep_uploads = `${process.env.REACT_APP_API}/partners/file_uploads/get/all/${this.state.selectedParent}`;
    const result_uploads = await axios.get(ep_uploads);

    let dupeFile = false;
    result_uploads.data.data.map(file => {
      if (file.contactUploads) {
        if (
          !file.contactUploads["file_1"]?.isHidden &&
          file.contactUploads["file_1"]?.file_name ===
            this.state.location["file_1"]?.fileName
        ) {
          toast.error("You have already uploaded this file!");
          dupeFile = true;
          return;
        }
      }
    });

    let fileUploads = {};

    let count = 1;

    if (!dupeFile) {
      fileUploads["file_" + count] = {
        file_url: this.state.location["file_" + count].path,
        file_name: this.state.location["file_" + count].fileName,
        file_display_name: this.state.fileName,
        upload_date: new Date(),
        isHidden: false
      };

      const dataSubmission = {
        file_uploads: fileUploads,
        parentId: this.state.selectedParent
      };

      const ep = `${process.env.REACT_APP_API}/partners/file_uploads/add`;
      const submitFileUploadData = await axios.post(ep, dataSubmission);
      if (submitFileUploadData.data && submitFileUploadData.data.success) {
        toast.success("Successfully Submitted");
        this.setState({
          location: null,
          openUploadFileModal: false,
          fileSubmitted: true
        });
        await this.fetchAttachmentData();
        this.setGlobal({ loading: false });
      } else {
        // todo account for if no success
        console.log("CANT SUBMIT");
      }
    }
    this.setGlobal({ loading: false });
  };

  editFileName = name => {
    this.state.fileName = name;
  };

  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return !this.global.dsUser.accesspoints.Leads.Applications.edit;
    }
  };

  isActiveTab() {
    const currentURL = window.location.href;
    const startIndex = currentURL.lastIndexOf("/") + 1; // Find the index of the last "/"
    const endIndex = currentURL.indexOf("?parentId="); // Find the index of "?parentId="
    const value = currentURL.substring(startIndex, endIndex);
    console.log("CURRENT TAB", value, typeof value);
    if (value === "true") {
      return true;
    }
    return false;
  }

  render() {
    console.log("this.state", this.state);
    console.log("this.global", this.global);
    if (!this.state.data.Active[0]) {
      return null;
    }

    return (
      <div className="admin">
        {this.state.notesModalShown && (
          <NotesModal
            data={this.state.modalData}
            table={this.state.table}
            onClose={() =>
              this.setState({
                notesModalShown: false,
                modalData: null
              })
            }
            showTime={this.state.table === "partner_leads" ? true : false}
          />
        )}
        {this.state.showParentMilestoneViewModal && (
          <ParentMilestoneViewModal
            program={this.state.selectedItem}
            onClose={() =>
              this.setState({ showParentMilestoneViewModal: false })
            }
            milestone={this.state.milestones
              .filter(milestone => {
                const type = this.state.selectedItem.classificationType;
                if (type === "Program") {
                  return (
                    milestone.program === this.state.selectedItem.programId
                  );
                } else if (type === "Online") {
                  return (
                    milestone.onlineId === this.state.selectedItem.onlineId
                  );
                }
                return;
              })
              .sort((a, b) => a.display_order - b.display_order)}
            type={this.state.selectedItem.classificationType}
          />
        )}
        {this.state.openViewApplicationModal && (
          <ConnectionsApplicationViewModal
            refresh={() => {
              this.setGlobal({ loading: true });
              this.fetchInitialData();
              this.setGlobal({ loading: false });
            }}
            endEnrollment={_ => {
              this.setState({
                openEndEnrollmentModal: true,
                openViewApplicationModal: false
              });
            }}
            pauseEnrollment={(isStripe, subscription, resume) => {
              this.pauseEnrollment(isStripe, subscription, resume);
            }}
            program={this.state.selectedItem}
            onClose={_ => {
              this.setState({ openViewApplicationModal: false });
            }}
            isApplication={this.state.selectedItem.product.application_required}
            type={
              this.state.selectedItem.status_stage === "Accepted_Paid"
                ? "Enrolled"
                : this.state.selectedItem.status_stage === "New_Pending"
                ? "New"
                : this.state.selectedItem.status_stage === "Accepted_Unpaid"
                ? "Accepted"
                : this.state.selectedItem.status_stage ===
                    "Waitlisted_Unpaid" ||
                  this.state.selectedItem.status_stage === "Waitlisted_Paid"
                ? "Waitlisted"
                : this.state.selectedItem.status_stage ===
                    "Application_Cancelled" ||
                  this.state.selectedItem.status_stage === "Cancelled_Unpaid"
                ? "Archived"
                : null
            }
            displayCategory={
              this.state.selectedItem.classificationType === "Program"
                ? "Programs"
                : this.state.selectedItem.classificationType === "Event"
                ? "Events"
                : this.state.selectedItem.classificationType === "Party"
                ? "Parties"
                : this.state.selectedItem.classificationType === "Membership"
                ? "Memberships"
                : this.state.selectedItem.classificationType === "Online"
                ? "Online Virtual"
                : this.state.displayCategory
            }
            canSubUserEdit={this.canSubUserEdit()}
          />
        )}
        {this.state.openNudgeInvitationModal && (
          <NudgeInvitationModal
            onClose={() => this.setState({ openNudgeInvitationModal: false })}
            selectedItem={this.state.selectedItem}
            fetchData={() => this.fetchInitialData()}
          />
        )}
        {this.state.openEndEnrollmentModal && (
          <EndEnrollmentModal
            program={this.state.selectedItem}
            onClose={_ => {
              this.fetchInitialData();
              this.setState({ openEndEnrollmentModal: false });
            }}
            listingType={this.state.selectedItem.classificationType}
          />
        )}
        {this.state.openViewSurveyModal && (
          <SurveyViewModal
            onClose={() => this.setState({ openViewSurveyModal: false })}
            selectedItem={this.state.selectedItem}
            program={this.state.selectedItem}
          />
        )}
        {this.state.openConfirmArchiveModal && (
          <ConfirmArchiveModal
            onClose={_ => this.setState({ openConfirmArchiveModal: false })}
            toArchive={true}
            onSubmit={_ => this.archiveAddedConnection()}
            modalBody="Are you sure you would like to remove this contact?"
          />
        )}

        {this.state.openInviteModal && (
          <InviteConnectionModal
            onClose={_ => {
              this.setState({ openInviteModal: false });
              this.fetchInitialData();
            }}
            email={this.state.data.Active[0].email}
            name={this.state.data.Active[0].displayName}
            addOne={_ => console.log("Invite Sent")}
            isActive={this.isActiveTab()}
          />
        )}
        {this.state.openApptModal && (
          <AppointmentDetailsModal
            message={this.state.selectedItem.Message}
            onClose={() =>
              this.setState({
                openApptModal: false
              })
            }
          />
        )}
        {this.state.openContactUsModal && (
          <Details
            lead={this.state.selectedItem.custom}
            formId={this.state.selectedItem.formId}
            onClose={_ => this.setState({ openContactUsModal: false })}
          />
        )}
        {this.state.openEnrollmentModal && (
          <ConnectionsViewModal
            program={this.state.selectedItem}
            onClose={_ => this.setState({ openEnrollmentModal: false })}
          />
        )}
        {this.state.taskModal && (
          <AddTaskModal onClose={_ => this.setState({ taskModal: false })} />
        )}
        {this.state.reminderModal && (
          <AddReminderModal
            onClose={_ => this.setState({ reminderModal: false })}
            page={this.state.stage}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-12" style={{ display: "block" }}>
              <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 700,
                  fontSize: "25px",
                  lineHeight: "34px",
                  color: "#042470",
                  textAlign: "left",
                  marginTop: "22px",
                  marginBottom: "22px"
                }}
              >
                Connection Profile
              </div>
            </div>
          </div>
          {/* Details Section */}
          <div className="select-tab">
            <div className="user-wrapper">
              <div className="user-block">
                <div className="user-details">
                  <div className="user-img-container">
                    <Avatar classes={{ root: this.props.classes.avatar }}>
                      {this.state.name
                        ? this.state.name.charAt(0)
                        : this.state.data.Active[0].displayName.charAt(0)}
                    </Avatar>
                  </div>
                  <div className="user-info">
                    <div className="user-name">
                      {this.state.name
                        ? this.state.name
                        : this.state.data.Active[0].displayName}
                    </div>
                    <div className="user-particular">
                      {this.state.data.Active[0].email}
                    </div>
                    {/* {this.state.data.last_logged_in && (
                      <div className="user-particular">
                        {this.state.data.last_logged_in &&
                          format(
                            new Date(this.state.data.last_logged_in),
                            "LLLL dd,yyyy"
                          )}
                      </div>
                    )}
                    {this.state.data.receives_emails && (
                      <div className="user-particular">
                        {this.state.data.receives_emails &&
                          "Subscribed to emails"}
                      </div>
                    )} */}
                  </div>
                </div>
                <div className="conn-upper-tabs">
                  <div className="tab-items">
                    <Tabs
                      value={this.state.active}
                      onChange={(event, newValue) =>
                        this.setState({ active: newValue })
                      }
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      TabIndicatorProps={{
                        className: this.props.classes.tabIndicator
                      }}
                      classes={{ root: this.props.classes.tabsHeadings }}
                    >
                      <Tab
                        label="Profile"
                        value="Profile"
                        disableRipple
                        disableFocusRipple
                        classes={{
                          root: this.props.classes.tabRoot,
                          selected: this.props.classes.tabSelected
                        }}
                      />

                      <Tab
                        label="History"
                        value="History"
                        disableRipple
                        disableFocusRipple
                        classes={{
                          root: this.props.classes.tabRoot,
                          selected: this.props.classes.tabSelected
                        }}
                      />
                      <Tab
                        label="Financials"
                        value="Financials"
                        disableRipple
                        disableFocusRipple
                        classes={{
                          root: this.props.classes.tabRoot,
                          selected: this.props.classes.tabSelected
                        }}
                      />
                      <Tab
                        label="Attachments"
                        value="Attachments"
                        disableRipple
                        disableFocusRipple
                        classes={{
                          root: this.props.classes.tabRoot,
                          selected: this.props.classes.tabSelected
                        }}
                      />
                    </Tabs>
                  </div>
                </div>
                <div className="conn-btn-section">
                  <button
                    className="newapplications conn-btn"
                    onClick={_ => this.setState({ openInviteModal: true })}
                  >
                    Invite
                  </button>
                </div>
                {this.state.data.Active.length === 1 &&
                  this.state.data.Active[0].table === "partner_invitations" && (
                    <div className="conn-btn-section">
                      <button
                        className="newapplications conn-btn"
                        onClick={_ => this.nudgeUser()}
                      >
                        Nudge
                      </button>
                    </div>
                  )}
                {this.state.data.Active[0]?.added_contact && (
                  <div className="conn-btn-section">
                    <i
                      className="fas fa-trash"
                      style={{
                        color: "#bfbfbf",
                        cursor: "pointer",
                        fontSize: "15px"
                      }}
                      onClick={() =>
                        this.setState({ openConfirmArchiveModal: true })
                      }
                    ></i>
                  </div>
                )}
                {this.state.openMilestoneEventLogDetailsModal && (
                  <MilestoneEventLogDetailsModal
                    onClose={() =>
                      this.setState({
                        openMilestoneEventLogDetailsModal: false
                      })
                    }
                    eventInfo={this.state.selectedEvent}
                  />
                )}
              </div>
            </div>
          </div>

          {this.state.active === "Profile" && (
            <Profile
              connectedEmail={this.state.data.Active[0].email}
              isActive={
                this.props.match.params.isActive === "true" ? true : false
              }
              updated={_ => {
                this.setGlobal({ loading: true });
                this.fetchInitialData();
                this.setGlobal({ loading: false });
              }}
            />
          )}
          {console.log("LOOK HERE 123123", this.filterData())}
          {this.state.active === "History" && (
            <div className="cont">
              <div className="tablecont">
                <div className="table-responsive">
                  <NewTabbedTable
                    tabs={this.tabs}
                    headings={this.headings}
                    data={this.filterData()}
                    actions={this.actions}
                    hideHeader={true}
                  />
                  {this.state.showMilestoneTable && (
                    <>
                      <h3
                        style={{
                          marginLeft: "10px",
                          fontWeight: "600"
                        }}
                      >
                        Milestone Event Log
                      </h3>
                      <NewTabbedTable
                        tabs={this.tabsMilestones}
                        headings={this.headingsMilestones}
                        data={this.state.dataMilestone}
                        actions={this.actions}
                        hideHeader={true}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {this.state.active === "Financials" && (
            <div className="cont">
              <div className="tablecont">
                <div className="table-responsive">
                  <NewTabbedTable
                    tabs={this.tabsFinancials}
                    headings={this.headingsFinancials}
                    data={this.filterDataOrders()}
                    actions={this.actionsFinancials}
                    hideHeader={true}
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.active === "Attachments" && (
            <div className="cont">
              <div className="tablecont">
                <div className="table-responsive">
                  <NewTabbedTable
                    tabs={this.tabsUploads}
                    headings={this.headingsUploads}
                    data={this.state.data}
                    actions={this.actionsAttachments}
                    hideHeader={true}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="col-md-6">
            <div
              className="forbtnwrap justify-end"
              style={{ marginLeft: "70px" }}
            >
              <div className="forbtnapp" style={{ paddingBottom: "30px" }}>
                {this.state.active === "Attachments" ? (
                  <div style={{ display: "flex" }}>
                    <button
                      className="newapplications"
                      id="add-file-upload-button"
                      style={{
                        cursor: "pointer",
                        width: "140px"
                      }}
                      onClick={() => {
                        this.setState({
                          openUploadFileModal: true,
                          fileSubmitted: false
                        });
                      }}
                    >
                      <img
                        src={MiniPlus}
                        alt=""
                        style={{ alignSelf: "center" }}
                      />
                      Upload Files
                    </button>
                    <span
                      id="file-upload-info-icon"
                      style={{ marginLeft: "10px" }}
                    >
                      <i
                        data-tip
                        data-for="file-upload"
                        className="fas fa-info-circle"
                        style={{ color: "#DCDCDC", fontSize: "24px" }}
                      ></i>
                      <ReactTooltip
                        id="file-upload"
                        place="right"
                        effect="solid"
                        type="info"
                      >
                        Only files with the extensions .png, .jpeg, .jpg, .pdf,
                        .mov, .mp4, .mpeg, and .avi are supported.
                      </ReactTooltip>
                    </span>
                  </div>
                ) : null}
                {this.state.openUploadFileModal && (
                  <UploadFileModal
                    onClose={() => {
                      this.setState({ openUploadFileModal: false });
                    }}
                    onSubmit={async () => {
                      // if (this.state.has_submitted) {
                      //   this.editFileUpload();
                      // } else {
                      await this.submitFileUpload();
                      //}
                    }}
                    onFileChange={e => this.fileChange(e)}
                    onFileNameChange={name => this.editFileName(name)}
                  />
                )}
                {this.state.openRemoveFileConfirmationModal && (
                  <RemoveFileConfirmationModal
                    onClose={() => {
                      this.setState({ openRemoveFileConfirmationModal: false });
                    }}
                    onSubmit={async () => {
                      this.setGlobal({ loading: true });
                      await this.fetchAttachmentData();
                      this.setGlobal({ loading: false });
                    }}
                    selectedFile={this.state.selectedFile}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(SingleConnections));
