import axios from "axios";

console.debug(`Starting console capture for client-side telemetry. (build=${process.env.REACT_APP_BUILD_DATE}, hash=${process.env.REACT_APP_BUILD_HASH})`);

const originalConsoleLogFn = console.__log = console.log;
const originalConsoleDebugFn = console.__debug = console.debug;
const originalConsoleInfoFn = console.__info = console.info;
const originalConsoleWarnFn = console.__warn = console.warn;
const originalConsoleErrorFn = console.__error = console.error;



// Redefine console.xxxx methods with custom functions
console.log = function () {
  capture( "log", [...arguments] );
  //capturedLogs.push({ ts:Date.now(), fn:"log", args: stringifyArgs([...arguments]) });
  originalConsoleLogFn.apply(console, arguments);
};
console.debug = function () {
  capture( "debug", [...arguments] );
  //capturedLogs.push({ ts:Date.now(), fn:"debug", args: stringifyArgs([...arguments]) });
  originalConsoleDebugFn.apply(console, arguments);
};
console.info = function () {
  capture( "info", [...arguments] );
  //capturedLogs.push({ ts:Date.now(), fn:"info", args: stringifyArgs([...arguments]) });
  originalConsoleInfoFn.apply(console, arguments);
};
console.warn = function () {
  capture( "warn", [...arguments] );
  //capturedLogs.push({ ts:Date.now(), fn:"warn", args: stringifyArgs([...arguments]) });
  originalConsoleWarnFn.apply(console, arguments);
};
console.error = function () {
  capture( "error", [...arguments] );
  //capturedLogs.push({ ts:Date.now(), fn:"error", args: stringifyArgs([...arguments]) });
  originalConsoleErrorFn.apply(console, arguments);
};



console.logUncaught = function (errorObj) {
  capture( "uncaught", errorObj );
  //capturedLogs.push({ ts:Date.now(), fn:"uncaught", args: JSON.stringify(errorObj) });
}


console.logAxiosResponse = function (response) {
  //first get a string-baseed "url" that you can later use.
  let url = response.config.url;  //response.config.url can be either string type, or the built-in URL class.
  if (url instanceof URL)
    url = url.href;
  if (!url  ||  !(typeof url==="string" || url instanceof String))
    throw new TypeError(`Unexpected data type for "url"! [${JSON.stringify(url)}]`);

  //don't create additional log messages for the log-transfers, otherwise it's an endless cycle!
  if ( url.endsWith("/api/telemetry/log-transfer") )
    return;

  const rcvdLength = parseInt(response.headers["content-length"]) || 0;
  const rcvdSnippet = rcvdLength===0
    ? ``
    : (response.data?.success===true || response.data?.success===false
       ? ` - {success:${response.data.success}, message:"${response.data?.message}", data: ...`
       : ` - ...` );
  const logmsg = `${(response.config.method || "").toUpperCase()} ${url} \n`
    + `  request: ${response.config?.data?.length||0} bytes\n`
    + `  response: ${rcvdLength} bytes\n`
    + `  ${response.status} ${response.statusText}${rcvdSnippet}`;

  capture( "axios", logmsg );
  //capturedLogs.push({ ts:Date.now(), fn:"axios", args: logmsg });
}






const LOG_ENTRY_OVERHEAD = 44; //`{"ts":1653985483336,"fn":"error","args":""},`.length;
const MAX_LOG_BUFFER_SIZE = 80_000;    //I think 150k is current POST-body limit, so 80k is a safe max.
let capturedLogs = console.__capturedLogs = [];
let capturedLogSize = 0;

function capture( fn, args ) {
  const stringifiedArgs = (typeof args==="string" || args instanceof String)  ?  args  :  stringifyArgs(args);
  capturedLogs.push( {ts:Date.now(), fn, args:stringifiedArgs });
  capturedLogSize += stringifiedArgs.length + LOG_ENTRY_OVERHEAD;

//  //Now keep the log buffer within a reasonable size by removing elements from the head of the array.
//  while (capturedLogSize>MAX_LOG_BUFFER_SIZE) {
//    const removedEntry = capturedLogs.shift();  //don't care about shift() performance. Small array, fast CPUs.
//    capturedLogSize -= removedEntry.args.length + LOG_ENTRY_OVERHEAD;
//  }

}






function stringifyArgs(args) {

  const value_replacer = ( k, v ) => {
    if (v instanceof Error) {
      let retval = {_type:v.constructor.name, name:v.name, message:v.message, stack:v.stack };
      if (v.incidentRefUid)  retval.incidentRefUid = v.incidentRefUid;  //only include if there's a value.
      return retval;
    }
    return v;
  }

  try {
    let output = JSON.stringify(args, value_replacer);
    return output.length > 20000 ? ".....too big....." : output;
  } catch (exc) {
    return `---stringify error--- ${exc.name}: ${exc.message}`;
  }
}






const MAX_BATCH_SIZE = 100000;  //Current server limit is 250k, but ideally, no POST body should exceed 100k.
                                //100,000 = ~97.7k. Assume escape characters for JSON, etc, will increase
                                //the payload size by at least 25%.
const ASSUMED_OVERHEAD = 50;

const LOG_TRANSFER_TIMEOUT = 10_000;
const LOG_TRANSFER_INTERVAL = 15_000;

function logTransferFn() {
  const ep = `${process.env.REACT_APP_API}/telemetry/log-transfer`;
  let currentBatch = [];
  let currentBatchSize = 0;
  while (capturedLogs.length>0) {
    //Prepare batches of log data in chunks upto MAX_BATCH_SIZE for transmit!
    if (capturedLogs[0]?.args.length + currentBatchSize > MAX_BATCH_SIZE)
      break;

    let logEntry = capturedLogs.shift();
    if (!logEntry) break;  //weird! shouldn't happen!
    currentBatch.push(logEntry);
    currentBatchSize += logEntry.args.length + ASSUMED_OVERHEAD;
  }

  if (currentBatch.length===0)
    return;   //exit function if there's nothing to send.

  if (!localStorage.getItem("ds_token")) { //check if we're already authenticated (ie JWT stored in ds_token)
    return;   //exit function if we're not logged in - also means we ignore and clear out the capturedLogs.
              //@@NOTE@@: Potential problem is that we miss important log messages from before log-in
              //          and also some important log messages from before (not only after) log-out.
  }

  //No await! Don't wait for results to come back. Let callback handlers inform you.
  //@@FUTURE@@: put in code to handle retry when it fails the first time - maybe another then() and catch().
  axios.post(ep, currentBatch, {timeout:LOG_TRANSFER_TIMEOUT, ignore_global_lastPost:true})
    .then( (response) => {
      console.__debug("logTransferFn.response =",response);
    })
    .catch( (error) => {
      console.__debug("logTransferFn.error =",error);
    });
}



// eslint-disable-next-line no-unused-vars
const intervalId = setInterval( logTransferFn, LOG_TRANSFER_INTERVAL);

console.forceLogTransferNow = () => {
  console.__log("forcing Log Transfer now!");
  logTransferFn();
}

console.IS_LOGGED_IN = true;     //@@TEMPORARY@@: Just needed a flag to determine logged-in status.


// //@@FUTURE@@: we will turn on/off buffered logging using this function.
// //  - active logging = sends log data periodically to the server, crash or no crash.
// //  - buffered logging = keeps a limited buffer in client memory and sends only when client crashes.
// console.enableBufferedLogging = ( state ) => {};
