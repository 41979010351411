import React from "reactn";
import { formTypes, BoxForm } from "../../../../components/UI/form";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

class ProgramInfoForm extends React.PureComponent {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.customForm = this.customForm.bind(this);
  }
  handleChange = (e, v) => {
    this.setGlobal({ myProgramInfo: { ...this.global.myProgramInfo, [e]: v } });
  };

  customForm = () => (
    <div className="formbody">
      <form>
        <div className="row">
          <div className="col-5">
            <h3>
              Program Name<span className="requiredField">*</span>
            </h3>
          </div>
          <div className="col-7 d-flex">
            <div
              className={`w-100 ${
                !this.global.myProgramInfo.programName ||
                !this.global.myProgramInfo.programName.length
                  ? "formbody-invalid"
                  : ""
              }`}
            >
              <formTypes.Input
                name="programName"
                value={this.global.myProgramInfo.programName}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                readOnly={
                  !this.global.profile.programSubmitted ||
                  this.global.dsUser.accountType === "Admin"
                    ? false
                    : true
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <h3>
              Program Type<span className="requiredField">*</span>
            </h3>
          </div>
          <div className="col-7 d-flex">
            <div
              className={`w-100 ${
                !this.global.myProgramInfo.programType ||
                !this.global.myProgramInfo.programType.length
                  ? "formbody-invalid"
                  : ""
              }`}
            >
              <formTypes.Select
                name="programType"
                value={this.global.myProgramInfo.programType}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                options={formTypes.SelectOptions.programTypeOptions}
                isDisabled={
                  this.global.profile.programSubmitted &&
                  this.global.dsUser.accountType !== "Admin"
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <h3>
              Phone Number<span className="requiredField">*</span>
            </h3>
          </div>
          <div className="col-7 d-flex">
            <div
              className={`w-100 ${
                !this.global.myProgramInfo.phoneNumber ||
                !this.global.myProgramInfo.phoneNumber.length
                  ? "formbody-invalid"
                  : ""
              }`}
            >
              <PhoneInput
                name="phoneNumber"
                value={this.global.myProgramInfo.phoneNumber}
                onChange={e => this.handleChange("phoneNumber", e)}
                readOnly={
                  !this.global.profile.programSubmitted ||
                  this.global.dsUser.accountType === "Admin"
                    ? false
                    : true
                }
                countries={["CA", "US"]}
                country="CA"
                className="w-100"
                limitMaxLength={true}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <h3>
              Email<span className="requiredField">*</span>
            </h3>
          </div>
          <div className="col-7 d-flex">
            <div
              className={`w-100 ${
                !this.global.myProgramInfo.email ||
                !this.global.myProgramInfo.email.length
                  ? "formbody-invalid"
                  : ""
              }`}
            >
              <formTypes.Input
                name="email"
                value={this.global.myProgramInfo.email}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                readOnly={
                  !this.global.profile.programSubmitted ||
                  this.global.dsUser.accountType === "Admin"
                    ? false
                    : true
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <h3>
              Educator Name<span className="requiredField">*</span>
            </h3>
          </div>
          <div className="col-7 d-flex">
            <div
              className={`w-100 ${
                !this.global.myProgramInfo.educatorName ||
                !this.global.myProgramInfo.educatorName.length
                  ? "formbody-invalid"
                  : ""
              }`}
            >
              <formTypes.Input
                name="educatorName"
                value={this.global.myProgramInfo.educatorName}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                readOnly={
                  !this.global.profile.programSubmitted ||
                  this.global.dsUser.accountType === "Admin"
                    ? false
                    : true
                }
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-5">
            <h3>
              Number of Staff<span className="requiredField">*</span>
            </h3>
          </div>
          <div className="col-7 d-flex">
            <div
              className={`w-100 ${
                !this.global.myProgramInfo.numStaff ? "formbody-invalid" : ""
              }`}
            >
              <input
                type="number"
                min="1"
                name="numStaff"
                value={1}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                readOnly={
                  !this.global.profile.programSubmitted ||
                  this.global.dsUser.accountType !== "Admin"
                }
              />
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-5">
            <h3>
              Educator Title<span className="requiredField">*</span>
            </h3>
          </div>
          <div className="col-7 d-flex">
            <formTypes.Input
              name="educatorTitle"
              value={this.global.myProgramInfo.educatorTitle}
              onChange={e => this.handleChange(e.target.name, e.target.value)}
              readOnly={
                !this.global.profile.programSubmitted ||
                this.global.dsUser.accountType === "Admin"
                  ? false
                  : true
              }
            />
          </div>
        </div> */}
        <div className="row">
          <div className="col-5">
            <h3>
              Curriculum Inspiration<span className="requiredField">*</span>
            </h3>
          </div>
          <div className="col-7 d-flex">
            {/* <formTypes.Input
              name="curriculum"
              value={this.global.myProgramInfo.curriculum}
              onChange={e => this.handleChange(e.target.name, e.target.value)}
              readOnly={
                !this.global.profile.programSubmitted ||
                this.global.dsUser.accountType === "Admin"
                  ? false
                  : true
              }
            /> */}
            <div
              className={`w-100 ${
                !this.global.myProgramInfo.curriculum ||
                !this.global.myProgramInfo.curriculum.length
                  ? "formbody-invalid"
                  : ""
              }`}
            >
              <select
                value={this.global.myProgramInfo.curriculum}
                readOnly={
                  !this.global.profile.programSubmitted ||
                  this.global.dsUser.accountType !== "Admin"
                }
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                name="curriculum"
                className="w-100"
              >
                <option value="">Please Select</option>
                <option value="Play-Based">Play-Based</option>
                <option value="Montessori">Montessori</option>
                <option value="Reggio-Emilia">Reggio-Emilia</option>
                <option value="Waldorf">Waldorf</option>
                <option value="Nature-Based Program">
                  Nature-Based Program
                </option>
                <option value="Bilingual">Bilingual</option>
                <option value="Language Immersion">Language Immersion</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <h3>
              Age Groups<span className="requiredField">*</span>
            </h3>
          </div>
          <div className="col-7 d-flex">
            <div
              className={`w-100 ${
                !this.global.myProgramInfo.ageGroups ||
                !this.global.myProgramInfo.ageGroups.length
                  ? "formbody-invalid"
                  : ""
              }`}
            >
              {this.global.myProgramInfo.programType ===
              "Independent Program Unlicensed" ? (
                <formTypes.ChildAgeGroupSelect
                  name="ageGroups"
                  value={this.global.myProgramInfo.ageGroups}
                  onChange={this.handleChange}
                  isDisabled={
                    this.global.profile.programSubmitted &&
                    this.global.dsUser.accountType !== "Admin"
                  }
                />
              ) : (
                <formTypes.ChildCenterAgeGroupSelect
                  name="ageGroups"
                  value={this.global.myProgramInfo.ageGroups}
                  onChange={this.handleChange}
                  isDisabled={
                    this.global.profile.programSubmitted &&
                    this.global.dsUser.accountType !== "Admin"
                  }
                />
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-5">
            <h3>
              Target Enrollments<span className="requiredField">*</span>
            </h3>
          </div>
          <div className="col-7 d-flex">
            <div
              className={`w-100 ${
                !this.global.myProgramInfo.capacity ? "formbody-invalid" : ""
              }`}
            >
              <input
                name="capacity"
                type="number"
                value={this.global.myProgramInfo.capacity}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                readOnly={
                  !this.global.profile.programSubmitted ||
                  this.global.dsUser.accountType === "Admin"
                    ? false
                    : true
                }
              />
            </div>
          </div>
        </div>
        {this.global.myProgramInfo.programType ===
          "Licensed Daycare Center" && (
          <div className="row">
            <div className="col-5">
              <h3>
                Late fees Rate<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`w-100 ${
                  !this.global.myProgramInfo.curriculum ||
                  !this.global.myProgramInfo.curriculum.length
                    ? "formbody-invalid"
                    : ""
                }`}
              >
                <select
                  value={this.global.myProgramInfo.latefee}
                  readOnly={
                    !this.global.profile.programSubmitted ||
                    this.global.dsUser.accountType !== "Admin"
                  }
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  name="latefee"
                  className="w-100"
                >
                  <option value="">Please Select</option>
                  <option value="25">$0.25 / Minute</option>
                  <option value="50">$0.50 / Minute</option>
                  <option value="75">$0.75 / Minute</option>
                  <option value="100">$1.00 / Minute</option>
                </select>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"MY PROGRAM"}
        customForm={this.customForm()}
      />
    );
  }
}

export default ProgramInfoForm;
