import React, { useState } from "reactn";
import { formTypes } from "../../../../../components/UI/form";

const AbsentModal = ({ child, onClose, setChildStatus, goBack }) => {
  const [useChildState, setChildState] = useState(child);
  const handleChange = (e, v) => {
    setChildState({
      ...useChildState,
      status: { ...useChildState.status, [e]: v }
    });
  };

  return (
    <>
      <h3>
        <span className="attendance-red">
          <i className="fa fa-times" aria-hidden="true"></i> Absent:
        </span>
        <br />
        {`${useChildState.child.student.fullName} (${useChildState.child.day})`}
        :
      </h3>
      <div className="modal-row">
        Choose to give the student a discount on their daily enrollment fee:
      </div>
      <div className="modal-row">
        <sub>Discount Amount:</sub>
        <formTypes.PercentageHalvesDropdownSelect
          className="mt-2"
          value={useChildState.status.percent}
          onChange={handleChange}
          name="percent"
        />
      </div>
      <button
        onClick={() => {
          setChildStatus({
            ...useChildState,
            status: { ...useChildState.status, status: "Absent" }
          });
          onClose();
        }}
      >
        SAVE
      </button>
      <button onClick={() => onClose()}>CANCEL</button>
      <button onClick={() => goBack()}>BACK</button>
    </>
  );
};

export default AbsentModal;
