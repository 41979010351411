import React from "reactn";
import { Table } from "../../UI";

class ActiveEnrollmentsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.headings = [
      { id: "program", label: "PROGRAM" },
      { id: "childName", label: "CHILD NAME" },
      { id: "enrollmentPeriod", label: "ENROLLMENT PERIOD" },
      { id: "programPlan", label: "PROGRAM PLAN" },
      { id: "action", label: " " }
    ];
    this.data = [
      {
        program: "Daisy Dreamschool",
        childName: "Suzanna Collins",
        enrollmentPeriod: "Jan 3 - Jan 9 2019",
        programPlan: "Plan A"
      },
      {
        program: "Little Teletubby Dreamschool",
        childName: "Suzanna Collins",
        enrollmentPeriod: "Jan 3 - Jan 9 2019",
        programPlan: "Plan A"
      }
    ];
    this.actions = [{ name: "VIEW", action: this.viewEnrollment }];
  }

  viewEnrollment = async row => {};

  render() {
    return (
      <div>
        <Table
          headings={this.headings}
          data={this.data}
          actions={this.actions}
        />
      </div>
    );
  }
}

export default ActiveEnrollmentsTable;
