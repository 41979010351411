import React from "reactn";
import { Modal } from "../../UI";
import { Fragment } from "react";

class InterestedProgramsModal extends React.PureComponent {
  render() {
    const { open, onClose } = this.props;
    let years = 0;
    let months = 0;
    if (this.props.data && this.props.data.childDob) {
      years = parseInt(
        (Date.now() - new Date(this.props.data.childDob)) / (3600000 * 24 * 365)
      );
      months = parseInt(
        (Date.now() -
          new Date(this.props.data.childDob) -
          years * 3600000 * 24 * 365) /
          (3600000 * 24 * 30)
      );
    }
    return (
      <div>
        <Modal open={open} heading="Survey Data" onClose={onClose}>
          {this.props.data && (
            <Fragment>
              <div className="row">
                <p style={{ textAlign: "center" }}></p>
              </div>
              {this.props.data.childDob ? (
                <>
                  <div className="row">
                    <p>
                      <b>Child's Date of birth :</b>{" "}
                      {new Date(this.props.data.childDob).toDateString()}
                    </p>
                  </div>
                  <div className="row">
                    <p>
                      <b>Years:</b> {years}
                    </p>
                  </div>
                  <div className="row">
                    <p>
                      <b>Months:</b> {months}
                    </p>
                  </div>
                  <div className="row">
                    <p>
                      <b>Preferred days of care : </b>
                      {this.props.data.daysOfCare}.
                    </p>
                  </div>
                  <div className="row">
                    <p>
                      <b>Preferred Drop-Off time : </b>
                      {this.props.data.dropOff}.
                    </p>
                  </div>
                  <div className="row">
                    <p>
                      <b>Preferred Pick-Up time : </b>
                      {this.props.data.pickUp}.
                    </p>
                  </div>
                  <div className="row">
                    <p>
                      <b> How soon do you need child care :</b>{" "}
                      {this.props.data.childneed}
                    </p>
                  </div>
                </>
              ) : this.props.data.childYears || this.props.data.childMonths ? (
                <>
                  <div className="row">
                    <p>
                      <b>Child's Years :</b> {this.props.data.childYears}
                    </p>
                  </div>
                  <div className="row">
                    <p>
                      <b>Child's Months :</b> {this.props.data.childMonths}
                    </p>
                  </div>
                  <div className="row">
                    <p>
                      <b>Preferred days of care : </b>
                      {this.props.data.daysOfCare}.
                    </p>
                  </div>
                  <div className="row">
                    <p>
                      <b>Preferred Drop-Off time : </b>
                      {this.props.data.dropOff}.
                    </p>
                  </div>
                  <div className="row">
                    <p>
                      <b>Preferred Pick-Up time : </b>
                      {this.props.data.pickUp}.
                    </p>
                  </div>
                  <div className="row">
                    <p>
                      <b> How soon do you need child care :</b>{" "}
                      {this.props.data.childneed}
                    </p>
                  </div>
                </>
              ) : (
                <div className="row">Survey Info Unavailable</div>
              )}

              <div className="row">
                <div className="col-md-12">
                  <div className="buttons">
                    <button onClick={() => onClose()}>Close</button>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </Modal>
      </div>
    );
  }
}

export default InterestedProgramsModal;
