import React from "react";
import "../../assets/css/componentSpecificCss/searchCategory.css";

const SearchCategory = ({ category, user, isSearchByChild }) => {
  return category === "User" ? (
    <div className="search-category-user">
      {isSearchByChild ? `${user} (Parent of ${isSearchByChild})` : `${user}`}
    </div>
  ) : category === "Manage" ? (
    <div className="search-category-manage">Manage</div>
  ) : category === "Listings" ? (
    <div className="search-category-listings">Listings</div>
  ) : (
    <div className="search-category">{category}</div>
  );
};

export default SearchCategory;
