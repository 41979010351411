import React from "reactn";
import { DdIcon } from "../../../../assets";
import { ArrowRight, ArrowLeft } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { format } from "date-fns";

const getStatusColor = status => {
  if (status === "Draft") {
    return "rgb(33 91 255)";
  } else if (status === "Ready to Bill") {
    return "#eba93e";
  } else if (status === "Cancelled") {
    return "#ff3b8e";
  } else if (status === "Issued") {
    return "rgba(92 200 220)";
  } else if (status === "Late" || status === "Failed") {
    return "#ff3b8e";
  } else if (status === "Processing") {
    return "rgb(33 91 255)";
  } else {
    return "#1bc88a";
  }
};

class RecentPayments extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 0
    }
  }

  render () {
    const earnings = this.props.earnings.sort((a, b) => new Date(b.date) - new Date(a.date));
    return (
      <div className="col-xl-6 col-xl-6-new">
        <div
          className="tablebox"
        >
          <div className="tableheader">
            <h2 style={{ flexGrow: 1 }}>
              ALL RECENT PAYMENTS <img src={DdIcon} alt="" />
            </h2>
            <IconButton
              disabled={this.state.page === 0}
              onClick={e =>
                this.setState({
                  page: this.state.page - 1
                })
              }
            >
              <ArrowLeft />
            </IconButton>
            <IconButton
              disabled={
                earnings.slice(
                  6 * this.state.page,
                  6 * (this.state.page + 1)
                ).length < 6
              }
              onClick={e =>
                this.setState({
                  page: this.state.page + 1
                })
              }
            >
              <ArrowRight />
            </IconButton>
          </div>
          <div className="tablebody" style={{ padding: "5px 18px" }}>
            <div className="table-responsive">
              <table className="w-100">
                <thead>
                  <tr
                    style={{ color: "#333", borderBottom: "1px solid #ececec" }}
                  >
                    <th className="p-2">Date</th>
                    <th className="p-2">Amount</th>
                    <th className="p-2">Purchaser</th>
                    <th className="p-2">Status</th>
                    <th className="p-2"></th>
                  </tr>
                </thead>
                <tbody>
                  {earnings.length ? null : (
                    <tr>
                      <td colSpan="5" className="p-5 text-center">
                        Nothing to show...{" "}
                        <span style={{ fontStyle: "italic" }}>Yet!</span>
                      </td>
                    </tr>
                  )}
                  {earnings
                    .slice(6 * this.state.page, 6 * (this.state.page + 1))
                    .map((e, i) => {
                      return (
                        <tr key={i}>
                          <td className="p-2">
                            {console.log("date in recent payments", e.date)}
                            {e.date ? format(new Date(e.date), "MMM dd, yyyy") : "N/A"}
                          </td>
                          <td className="p-2">
                            ${(parseInt(e.total) / 100).toFixed(2)}
                          </td>
                          <td className="p-2">{e.purchaser}</td>
                          <td className="p-2">
                            {
                              <span
                                style={{
                                  backgroundColor: getStatusColor(e.status),
                                  borderRadius: "13px",
                                  padding: "3px 10px 3px 10px",

                                  color: "#FFFFFF",
                                  display: "inline-block",
                                  textAlign: "center"
                                }}
                              >
                                {e.status}
                              </span>
                            }
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default withRouter(RecentPayments);