import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
class CancelModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  requestTour = async e => {
    e.preventDefault();
  };

  cancelThis = async () => {
    if (!this.props.actor) {
      const ep = `${process.env.REACT_APP_API}/par/tour/cancel`;
      if (this.props.tour) {
        const result = await axios.post(ep, {
          tour: this.props.tour
        });
        if (result.data.success) {
          this.props.tour.canceled = result.data.data.canceled;
          this.props.tour.canceledBy = result.data.data.canceledBy;
          this.props.onClose();
        }
      }
    } else {
      const ep = `${process.env.REACT_APP_API}/adm/booked/cancel`;
      if (this.props.tour) {
        const result = await axios.post(ep, {
          tour: this.props.tour
        });
        if (result.data.success) {
          this.props.tour.canceled = result.data.data.canceled;
          this.props.tour.canceledBy = result.data.data.canceledBy;
          this.props.onClose();
        }
      }
    }
  };

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal">
          <div>
            <h3>Cancel Tour?</h3>
            <p>Are you sure you want to cancel your tour request ?</p>
            <div className="modal-footer">
              <button
                type="submit"
                onClick={this.cancelThis}
                className="btn profile-btn"
              >
                Cancel my tour request!
              </button>
              {!this.props.actor && (
                <button
                  type="submit"
                  onClick={() => {
                    this.props.openSchedule();
                    this.props.onClose();
                  }}
                  className="btn profile-btn"
                >
                  Reschedule Instead!
                </button>
              )}
              <button
                onClick={this.props.onClose}
                className="mx-2 backbtn btn profile-btn"
              >
                Close
              </button>
            </div>
            <IconButton
              style={{ position: "absolute", right: "0", top: "0" }}
              onClick={_ => this.props.onClose()}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CancelModal);
