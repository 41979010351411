import React from "reactn";
import { TabbedTable } from "../../components/UI";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import axios from "axios";
import { format } from "date-fns";
import FReg from "../../components/table/admins/FRegistrationModal";
import ComModal from "../../components/table/admins/CommentsModal";
class FamilyRegistration extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      filter: "",
      detailsModalShown: false,
      modalData: [],
      original: {}
    };
    this.tabs = ["New", "Warm Leads", "Warm Leads Actioned", "Unfit Leads"];
    this.headings = {
      New: [
        { id: "displayName", label: "Parent" },
        {
          id: "email",
          label: "Email"
        },
        // { id: "programName", label: "Program Name" },
        {
          id: "phone",
          label: "Phone Number"
        },

        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        {
          id: "location",
          label: "Location / IP",
          customCell: this.location
        },
        {
          id: "signInCount",
          label: "Sign-in Count",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`}>
                {r.signInCount ? r.signInCount : "Unavailable"}
              </td>
            );
          }
        },
        { id: "action", label: "Actions" }
      ],
      "Warm Leads": [
        { id: "displayName", label: "Parent" },
        {
          id: "email",
          label: "Email"
        },
        // { id: "programName", label: "Program Name" },
        {
          id: "phone",
          label: "Phone Number"
        },
        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        {
          id: "location",
          label: "Location / IP",
          customCell: this.location
        },
        {
          id: "signInCount",
          label: "Sign-in Count",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`}>
                {r.signInCount ? r.signInCount : "Unavailable"}
              </td>
            );
          }
        },
        {
          id: "actionable",
          label: "ACTIONS",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewDetail(r)}
                >
                  View Details
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.actionParent(r, 3)}
                >
                  Action Lead
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.actionParent(r, 1)}
                >
                  Unfit Lead
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewComments(r)}
                >
                  Notes
                </button>
              </td>
            );
          }
        }
      ],
      "Warm Leads Actioned": [
        { id: "displayName", label: "Parent" },
        {
          id: "email",
          label: "Email"
        },
        // { id: "programName", label: "Program Name" },
        {
          id: "phone",
          label: "Phone Number"
        },
        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        {
          id: "location",
          label: "Location / IP",
          customCell: this.location
        },
        {
          id: "signInCount",
          label: "Sign-in Count",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`}>
                {r.signInCount ? r.signInCount : "Unavailable"}
              </td>
            );
          }
        },
        {
          id: "actionable",
          label: "ACTIONS",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewDetail(r)}
                >
                  View Details
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewComments(r)}
                >
                  Notes
                </button>
              </td>
            );
          }
        }
      ],
      "Unfit Leads": [
        { id: "displayName", label: "Parent" },

        {
          id: "email",
          label: "Email"
        },
        // { id: "programName", label: "Program Name" },
        {
          id: "phone",
          label: "Phone Number"
        },
        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        {
          id: "location",
          label: "Location / IP",
          customCell: this.location
        },
        {
          id: "signInCount",
          label: "Sign-in Count",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`}>
                {r.signInCount ? r.signInCount : "Unavailable"}
              </td>
            );
          }
        },
        {
          id: "actionable",
          label: "ACTIONS",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewDetail(r)}
                >
                  View Details
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewComments(r)}
                >
                  Notes
                </button>
              </td>
            );
          }
        }
      ]
    };
    this.actions = [
      { name: "View Details", action: this.viewDetail },
      { name: "Notes", action: this.viewComments },
      {
        name: "Warm Lead",
        action: r => this.actionParent(r, 2)
      },
      {
        name: "Unfit Lead",
        action: r => this.actionParent(r, 1)
      }
    ];
    this.filterData = this.filterData.bind(this);
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/family/fregistration`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setState({
        data: {
          New: res.data.data.parents.filter(parent =>
            !parent.actioned ? true : false
          ),
          "Warm Leads": res.data.data.parents.filter(
            parent => parent.actioned === 2
          ),
          "Warm Leads Actioned": res.data.data.parents.filter(
            parent => parent.actioned === 3
          ),
          "Unfit Leads": res.data.data.parents.filter(
            parent => parent.actioned === 1
          )
        },
        original: res.data.data.parents
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  location = (r, i) => {
    return (
      <td key={i}>
        {r.preferredLocation
          ? r.preferredLocation
          : r.currentIP
          ? r.currentIP
          : "Not Available"}
      </td>
    );
  };
  enrollCountCell = (r, i) => {
    return (
      <td key={i}>
        {r.enrollCount || 0} / {r.childCount || 0}
      </td>
    );
  };

  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  lastActionCell = (r, i) => {
    return (
      <td key={i}>{format(new Date(r.createdAt), "LLL dd, yyyy hh:mm a")}</td>
    );
  };

  viewDetail = async row => {
    this.setState({ detailsModalShown: true, modalData: row });
  };
  viewComments = async row => {
    this.setState({ commentsModalShown: true, modalData: row });
  };
  actionParent = async (row, action) => {
    const ep = `${process.env.REACT_APP_API}/adm/parent/notes/action`;
    const result = await axios.post(ep, {
      actioned: action,
      id: row.surveyid,
      dsUserId: row.id
    });
    if (result.data.success) {
      const arr = [...this.state.original];
      const indextoChange = arr.indexOf(arr.find(e => e.id === row.id));
      const obj = arr[indextoChange];
      arr[indextoChange] = {
        ...obj,
        actioned: action,
        surveyid: result.data.data.id
      };
      this.setState({
        data: {
          New: arr.filter(parent => (parent.actioned ? false : true)),
          "Warm Leads": arr.filter(parent => parent.actioned === 2),
          "Warm Leads Actioned": arr.filter(parent => parent.actioned === 3),
          "Unfit Leads": arr.filter(parent => parent.actioned === 1)
        },
        original: [...arr]
      });
    }
  };

  filterData(val) {
    this.setState({
      data: {
        New: this.state.original.filter(
          parent =>
            !parent.actioned &&
            parent.displayName
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase())
        ),
        "Warm Leads": this.state.original.filter(
          parent =>
            parent.actioned === 2 &&
            parent.displayName
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase())
        ),
        "Warm Leads Actioned": this.state.original.filter(
          parent =>
            parent.actioned === 3 &&
            parent.displayName
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase())
        ),
        "Unfit Leads": this.state.original.filter(
          parent =>
            parent.actioned === 1 &&
            parent.displayName
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase())
        )
      }
    });
  }

  render() {
    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          <div className="cont">
            <h1>Family Registration</h1>
          </div>
          <FReg
            open={this.state.detailsModalShown}
            data={this.state.modalData}
            onClose={() =>
              this.setState({
                detailsModalShown: false,
                modalData: null
              })
            }
          />
          <ComModal
            open={this.state.commentsModalShown}
            data={this.state.modalData}
            onClose={() =>
              this.setState({
                commentsModalShown: false,
                modalData: null
              })
            }
          />
          <div className="cont">
            <div className="tablecont">
              <div className="table-responsive">
                <input
                  type="text"
                  onChange={e => this.filterData(e.target.value)}
                  placeholder="Search for family..."
                  style={{
                    marginBottom: 15,
                    width: "100%",
                    borderRadius: 5,
                    border: "2px solid #E3E6F0",
                    fontSize: "13px",
                    padding: 3,
                    outline: 0
                  }}
                />
                <TabbedTable
                  headings={this.headings}
                  data={this.state.data}
                  tabs={this.tabs}
                  actions={this.actions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FamilyRegistration;
