import React, { useGlobal } from "reactn";
import { formTypes, BoxForm } from "../../../../components/UI/form";

const PhysicianContact = ({
  contactNo,
  physicianContact,
  onChange,
  onRemove
}) => {
  const app = useGlobal("madeApp");
  const handleChange = (e, v) => {
    const c = { ...physicianContact, [e]: v };
    onChange(c);
  };

  const fields = {
    children: {
      type: formTypes.ChildMultiSelect,
      val: physicianContact.children,
      name: "Child(ren)",
      required: true
    },
    fullName: {
      type: formTypes.Input,
      val: physicianContact.fullName,
      name: "Full Name",
      required: true
    },
    address: {
      type: formTypes.Input,
      val: physicianContact.address,
      name: "Address",
      required: true
    },
    phone: {
      type: formTypes.Input,
      val: physicianContact.phone,
      name: "Phone",
      required: true
    },
    company: {
      type: formTypes.Input,
      val: physicianContact.company,
      name: "Institution Name",
      required: true
    }
  };

  return (
    <BoxForm
      handleChange={handleChange}
      name={"PHYSICIAN CONTACT " + (contactNo + 1)}
      fields={fields}
      object={physicianContact}
      removable={true}
      handleRemove={onRemove}
      removeAfterSaved={false}
    />
  );
};

export default PhysicianContact;
