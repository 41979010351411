import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
class CancelModalSub extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  requestTour = async e => {
    e.preventDefault();
  };

  cancelThis = async e => {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/par/enrollment/cancel`;
    const result = await axios.post(ep, {
      appId: this.props.application.id,
      cancel_at: this.state.requestedDate
    });
    if (result.data.success) toast.success(`Your enrollment was cancelled!`);
    this.props.update(this.state.requestedDate);
    this.props.onClose();
  };

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal">
          <div>
            {this.props.application && (
              <>
                <h3>Cancel your subscription</h3>
                <p>Choose when you want your enrollment to end.</p>
                <form onSubmit={this.cancelThis}>
                  <label htmlFor="requestedDate">Date Requested *</label>
                  <DatePicker
                    selected={this.state.requestedDate}
                    minDate={
                      new Date(this.props.application.startDate).getTime() +
                        3600000 * 24 * 20 >
                      Date.now() + 3600000 * 24 * 5
                        ? new Date(this.props.application.startDate).getTime() +
                          3600000 * 24 * 20
                        : Date.now() + 3600000 * 24 * 14
                    }
                    onChange={date => this.setState({ requestedDate: date })}
                    filterDate={date => new Date(date).getDay() === 0}
                    dateFormat="MMMM d, yyyy"
                    className="w-100 form-control"
                    placeholderText="Date Requested"
                    name="requestedDate"
                    id="requestedDate"
                    validation="isNotEmpty"
                    autoComplete="off"
                  />

                  <div className="modal-footer">
                    <button type="submit" className="btn profile-btn">
                      Cancel my Enrollment
                    </button>
                    <button
                      onClick={this.props.onClose}
                      className="mx-2 backbtn btn profile-btn"
                    >
                      Close
                    </button>
                  </div>
                </form>
              </>
            )}
            <IconButton
              style={{ position: "absolute", right: "0", top: "0" }}
              onClick={_ => this.props.onClose()}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CancelModalSub);
