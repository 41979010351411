import React, { Component } from "react";
import axios from "axios";
import { format } from "date-fns";

class NewsUpdates extends Component {
  state = { article: null };
  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/search/news/${this.props.match.params.id}`;
    const results = await axios.get(ep);

    if (!results.data.success) {
      this.props.history.push("/");
      return;
    }
    this.setState({ article: results.data.data });
  }

  render() {
    return (
      this.state.article && (
        <div>
          <div
            style={{
              height: "100px",
              backgroundImage: `url("${process.env.REACT_APP_DS_FILES_S3}/${this.state.article.headerimage}")`,
              backgroundPosition: "50%",
              backgroundBlendMode: "multiply",
              backgroundSize: "cover",
              backgroundColor: "rgba(0,0,255,.2)"
            }}
          ></div>

          <h3
            style={{ textAlign: "center", marginBottom: 0, fontSize: "4rem" }}
          >
            {this.state.article.title}
          </h3>

          <p
            className="small"
            style={{ textAlign: "center", color: "lightgrey" }}
          >
            {format(new Date(this.state.article.date), "LLLL dd, yyyy")}
          </p>

          {this.state.article.mainbody.split("\n").map(e => (
            <p style={{ width: "80%", margin: "0 auto" }}>
              {e} <br />
            </p>
          ))}

          <div
            style={{
              width: "80%",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
              flexWrap: "wrap"
            }}
          >
            {this.state.article.middlegallery.map(e => (
              <img
                src={`${process.env.REACT_APP_DS_FILES_S3}/${e}`}
                alt="Middle Gallery"
                width="200px"
                style={{ paddingRight: "25px", marginBottom: "10px" }}
              />
            ))}
          </div>

          {this.state.article.bottomsection.split("\n").map(e => (
            <p style={{ width: "80%", margin: "0 auto", paddingTop: "10px" }}>
              {e} <br />
            </p>
          ))}

          <div
            style={{
              width: "80%",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
              flexWrap: "wrap"
            }}
          >
            {this.state.article.bottomgallery.map(e => (
              <img
                src={`${process.env.REACT_APP_DS_FILES_S3}/${e}`}
                alt="Bottom Gallery"
                width="200px"
                style={{ paddingRight: "25px", marginBottom: "10px" }}
              />
            ))}
          </div>
          {this.state.article.additional_text.split("\n").map(e => (
            <p style={{ width: "80%", margin: "0 auto", paddingTop: "10px" }}>
              {e} <br />
            </p>
          ))}
          <div
            style={{
              width: "80%",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap"
            }}
          >
            {this.state.article.additional_gallery.map(e => (
              <img
                src={`${process.env.REACT_APP_DS_FILES_S3}/${e}`}
                alt="Bottom Gallery"
                width="200px"
                style={{ paddingRight: "25px", marginBottom: "10px" }}
              />
            ))}
          </div>
        </div>
      )
    );
  }
}

export default NewsUpdates;
