import React from "react";
import { PureComponent } from "reactn";

class Form extends PureComponent {
  render() {
    const { fields, handleChange } = this.props;
    return (
      <div className="formbody">
        <form>
          {Object.keys(fields).map(field => {
            return fields[field].line ? (
              <hr key={field} />
            ) : (
              <div className="row" key={field}>
                {fields[field].fullWidth ? (
                  fields[field].textBefore ? (
                    <div className="col-12">
                      <h3>
                        {fields[field].textBefore}{" "}
                        {fields[field].required ? (
                          <span className="requiredField">*</span>
                        ) : (
                          ""
                        )}
                      </h3>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  <div className="col-5">
                    <h3>
                      {fields[field].name}
                      {fields[field].required ? (
                        <span className="requiredField">*</span>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>
                )}
                <div
                  className={
                    fields[field].fullWidth ? "col-12 d-flex" : "col-7 d-flex"
                  }
                  style={{ alignItems: "flex-end" }}
                >
                  <div
                    style={{
                      borderBottom:
                        fields[field].required &&
                        (!fields[field].val || !fields[field].val.length)
                          ? "1px solid red"
                          : "1px solid transparent"
                    }}
                    className="w-100"
                  >
                    {fields[field].customField
                      ? fields[field].customField()
                      : fields[field].type &&
                        React.createElement(fields[field].type, {
                          ...fields[field],
                          isDisabled: fields[field]["isDisabled"],
                          onChange: handleChange,
                          onBlur: fields[field]["onBlur"],
                          valueOnly: true,
                          name: field,
                          value: fields[field].val,
                          rows: fields[field].rows,
                          options: fields[field].options,
                          autocomplete: false
                        })}
                  </div>
                </div>
              </div>
            );
          })}
        </form>
      </div>
    );
  }
}

export default Form;
