import React from "reactn";
import Tables from "../../components/table";
import axios from "axios";
import Numeral from "numeral";
class TuitionPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tuitions: [],
      deposits: [],
      credits: 0
    };
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "Tuition Payments"]
    });
  }

  async componentDidMount() {
    this.setGlobal({ loading: true });

    const ep = this.props.match.params.id
      ? `${process.env.REACT_APP_API}/pmnt/invoices/${this.props.match.params.id}`
      : `${process.env.REACT_APP_API}/pmnt/invoices`;
    try {
      const res = await axios.get(ep);
      this.setGlobal({ loading: false, lastAPICall: res });
      if (res.data.success) {
        this.setState({
          credits: res.data.data.credits,
          tuitions: res.data.data.tuition.filter(
            e => e.amount_due > 0 && e.status_transitions.voided_at === null
          ),
          deposits: res.data.data.deposits.filter(e =>
            e.description.includes("Deposit for")
          )
        });
      }
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="cont">
          <h1>Tuition Payments</h1>
          <p>
            Dreamschools Credit :{" "}
            {Numeral(this.state.credits).format("$0,0.00")}
          </p>
        </div>

        <div className="cont">
          <div className="row">
            {/* <div className="col-lg-3">
              <Tables.Parents.TuitionPaymentOverview values={this.state} />
            </div> */}
            <div className="col-lg-12">
              <Tables.Parents.TuitionPaymentTable
                enrollments={this.state.tuitions}
                deposits={this.state.deposits}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TuitionPayments;
