import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { Button } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import BaseModal from "./baseModal/BaseModal";

import {
  CONTENT_BLOCK,
  BUTTON,
  TEXT_AREA,
  IMAGE_CONTENT,
  DROPDOWN,
  STRING_INPUT
} from "./baseModal/FieldTypes";
import {
  BLUE_BUTTON,
  GREEN_BUTTON,
  RED_BUTTON,
  WHITE,
  YELLOW_BUTTON
} from "./baseModal/colours";

const DocumentIcon = () => {
  return (
    <svg
      style={{ width: "15px", height: "15px" }}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
      ></path>
    </svg>
  );
};
class ViewParentMilestoneUploadsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isRemoved: [],
      currentFile: 1
    };
  }

  getBaseModalFields() {
    const baseModalFieldsArr = [];
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let count = 1;
    while (
      this.props.milestoneDetails.file_uploads &&
      this.props.milestoneDetails.file_uploads["file_" + count]
    ) {
      if (
        this.props.milestoneDetails.file_uploads["file_" + count] &&
        !this.props.milestoneDetails.file_uploads["file_" + count].isHidden
      ) {
        let currentCount = count;
        const fileUpload = {
          type: BUTTON,
          data: {
            titleStyle: { fontSize: "1rem" },
            name: this.props.milestoneDetails.file_uploads[
              "file_" + currentCount
            ]
              ? `File (${
                  this.props.milestoneDetails.file_uploads[
                    "file_" + currentCount
                  ].file_name
                }) Uploaded on ${new Date(
                  this.props.milestoneDetails.file_uploads[
                    "file_" + currentCount
                  ].upload_date
                ).toLocaleString("en-US", { timeZone: timeZone })}`
              : "File",
            buttonText: this.props.milestoneDetails.file_uploads[
              "file_" + currentCount
            ]
              ? `View File`
              : "No File Uploaded",
            buttonColour: BLUE_BUTTON,
            textColour: WHITE,
            handleClick: () => {
              window.open(
                this.props.milestoneDetails.file_uploads["file_" + currentCount]
                  .file_url,
                "_blank"
              );
            }
          }
        };

        baseModalFieldsArr.push(fileUpload);
      }
      count++;
    }

    if (!this.props.milestoneDetails.file_uploads) {
      const noSubmissionField = {
        type: CONTENT_BLOCK,
        data: {
          name: "",
          content: "No Uploads"
        }
      };
      baseModalFieldsArr.push(noSubmissionField);
    }
    return baseModalFieldsArr;
  }

  getBaseModalButtons() {
    let baseModalBtnArr = [];

    return baseModalBtnArr;
  }

  getBaseModalProps() {
    const baseModalProps = {
      title: "Milestone Submission Upload Details",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };

    return baseModalProps;
  }

  getFileExtention = file_url => {
    if (file_url) {
      const split = file_url.split(".");
      return split[split.length - 1];
    }
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default ViewParentMilestoneUploadsModal;
