import React from "reactn";
import Axios from "axios";
import { toast } from "react-toastify";
class Credits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      educators: [],
      parents: [],
      educator: "",
      parent: "",
      amount: null,
      reason: ""
    };
  }
  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/finances/educators`;
    const results = await Axios.get(ep);
    this.setState({
      educators: results.data.data
    });
  }
  async submitForm(e) {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/adm/finances/credits`;
    const result = await Axios.post(ep, {
      stripeAcc: this.state.educator,
      customerId: this.state.parent,
      amount: parseInt(this.state.amount),
      reason: this.state.reason
    });
    if (result.data.success) {
      toast.success(`Successfully applied the credit!`);
      this.props.addCredit({
        amount: this.state.amount,
        reason: this.state.reason,
        createdAt: new Date(),
        displayName: this.state.educators.find(
          e => e.stripe_user_id === this.state.educator
        ).displayName,
        parentname: this.state.parents.find(
          e => e.stripeCustomer === this.state.parent
        ).displayName
      });
      this.setState({
        educators: [],
        parents: [],
        educator: "",
        parent: "",
        amount: null,
        reason: ""
      });
      this.props.setOpen();
    }
  }

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal">
          <form onSubmit={e => this.submitForm(e)}>
            <h3>Credits</h3>
            <label htmlFor="displayName">Educator</label>
            <select
              className="form-control"
              onChange={async e => {
                if (!e.target.value) {
                  this.setState({
                    educator: "",
                    parent: "",
                    parents: []
                  });
                } else {
                  const ep = `${process.env.REACT_APP_API}/adm/finances/parents`;
                  const val = e.target.value;
                  const result = await Axios.post(ep, {
                    stripe_user_id: val
                  });
                  this.setState({
                    parents: result.data.data,
                    educator: val
                  });
                }
              }}
            >
              <option value="" selected hidden disabled>
                Choose an Educator :
              </option>
              {this.state.educators.map(option => (
                <option value={option.stripe_user_id}>
                  {option.displayName}
                </option>
              ))}
            </select>
            <label htmlFor="email">Parent</label>
            <select
              className="form-control"
              disabled={!this.state.educator}
              onChange={e => this.setState({ parent: e.target.value })}
            >
              <option value="" selected hidden disabled>
                Choose a Parent :
              </option>
              {this.state.parents.map(option => (
                <option value={option.stripeCustomer}>
                  {option.displayName}
                </option>
              ))}
            </select>
            <label htmlFor="requestPhone">Credit Amount</label> <br />
            <input
              name="requestPhone"
              id="requestPhone"
              type="number"
              placeholder="Enter the amount..."
              className="form-control"
              disabled={!this.state.parent}
              onChange={e => {
                this.setState({
                  amount: e.target.value
                });
              }}
            />
            <label htmlFor="requestPhone">Reason</label> <br />
            <input
              name="requestPhone"
              id="requestPhone"
              type="text"
              placeholder="Reason for providing the credit..."
              className="form-control"
              disabled={!this.state.parent}
              onChange={e => {
                this.setState({
                  reason: e.target.value
                });
              }}
            />
            <div className="modal-footer">
              <button
                onClick={this.props.setOpen}
                type="button"
                className="mx-2 backbtn btn profile-btn"
              >
                Cancel
              </button>
              <button
                className="btn profile-btn"
                type="submit"
                onClick={this.loginRegister}
              >
                Apply Credit !
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default Credits;
