import React from "reactn";
import ProgramInfoForm from "./forms/programInfo";
import ProgramLocationHours from "./forms/programLocationHours";
class ProgramSetupBasicInfoPage extends React.Component {
  render() {
    return (
      <div className="cont boxes-row mt-50px">
        <ProgramInfoForm />
        <ProgramLocationHours />
      </div>
    );
  }
}
export default ProgramSetupBasicInfoPage;
