import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import axios from "axios";
import CancelModal from "../../components/modals/CancelModal";
import Reschedule from "../../components/modals/RescheduleModal";
import AppointmentDetailsModal from "../../components/modals/AppointmentDetailsModal";
class TourRequests extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        Appointments: []
      },
      selectedMessage: null
    };

    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Appointments", to: "/appointments" }
      ]
    });
    this.tabs = ["Appointments"];
    this.headings = {
      Appointments: [
        {
          id: "displayName",
          label: "Parent",
          customCell: (r, i) => (
            <td
              key={`${i}-name`}
              style={{
                fontFamily: "Open Sans, sans-serif",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "19px",
                color: "#000000",
                padding: "15px"
              }}
            >
              {r.displayName}
            </td>
          )
        },
        {
          id: "requestedTourDate",
          label: "Requested Date",
          customCell: this.dateCell
        },
        {
          id: "phone",
          label: "Cell Phone",
          customCell: (r, i) => (
            <td
              key={`${i}-phone`}
              style={{
                fontFamily: "Open Sans, sans-serif",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "19px",
                color: "#000000",
                padding: "15px"
              }}
            >
              {r.phone}
            </td>
          )
        },
        {
          id: "email",
          label: "Email",
          customCell: (r, i) => (
            <td
              key={`${i}-email`}
              style={{
                fontFamily: "Open Sans, sans-serif",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "19px",
                color: "#000000",
                padding: "15px"
              }}
            >
              {r.email}
            </td>
          )
        },
        {
          id: "accepted",
          label: "Accepted?",
          customCell: this.acceptedCell
        },
        {
          id: "details",
          label: "",
          customCell: this.details
        },
        {
          id: "accept",
          label: "",
          customCell: this.acceptNow,
          customStyle: { width: 180 }
        }

        /*{
          id: "cancel",
          label: "",
          customCell: this.cancelTourCell,
          customStyle: { width: 180 }
        }*/
      ]
    };
  }
  acceptTour = async r => {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/appointments/accept`;
    const result = await axios.post(ep, {
      ...r
    });
    this.setGlobal({ loading: false });
    if (result.data.success) {
      this.setState({
        data: {
          Appointments: this.state.data["Appointments"].map(e =>
            e.id === result.data.data.id
              ? { ...e, accepted: result.data.data.accepted }
              : e
          )
        }
      });
    }
  };

  cancelTourCell = (r, i) => {
    if (new Date(r["Appointment Time"]).getTime() > Date.now() && !r.canceled) {
      return (
        <td key={`${i}-btn`}>
          <button
            style={{
              fontFamily: "Open Sans, sans-serif",
              fontWeight: 700,
              fontSize: "10px",
              lineHeight: "14px",
              color: "#fff",
              padding: "10px 25px",
              background: "#9bb0eb",
              transition: "all 0.15s ease",
              border: 0,
              margin: "0 1px"
            }}
            onClick={() =>
              this.setState({ cancelModalOpen: true, tourCancel: r })
            }
          >
            CANCEL TOUR
          </button>
        </td>
      );
    }
    return <td key={`${i}-btn`} colSpan={1}></td>;
  };
  rescheduleTourCell = (r, i) => {
    if (new Date(r["Appointment Time"]).getTime() > Date.now() && !r.canceled) {
      return (
        <td key={`${i}-btn`}>
          <button
            style={{
              fontFamily: "Open Sans, sans-serif",
              fontWeight: 700,
              fontSize: "10px",
              lineHeight: "14px",
              color: "#fff",
              padding: "10px 25px",
              background: "#9bb0eb",
              transition: "all 0.15s ease",
              border: 0,
              margin: "0 1px"
            }}
            onClick={() =>
              this.setState({ rescheduleModalOpen: true, tourReschedule: r })
            }
          >
            RESCHEDULE
          </button>
        </td>
      );
    }
    return <td key={`${i}-btn`} colSpan={1}></td>;
  };
  acceptNow = (r, i) => {
    if (
      new Date(r["Appointment Time"]).getTime() > Date.now() &&
      !r.accepted &&
      !r.canceled &&
      parseInt(r.rescheduledBy) !== this.global.dsUser.id
    ) {
      return (
        <td key={`${i}-btn`}>
          <button
            onClick={() => this.acceptTour(r)}
            style={{
              fontFamily: "Open Sans, sans-serif",
              fontWeight: 700,
              fontSize: "10px",
              lineHeight: "14px",
              color: "#fff",
              padding: "10px 25px",
              background: "#9bb0eb",
              transition: "all 0.15s ease",
              border: 0,
              margin: "0 1px"
            }}
          >
            ACCEPT
          </button>
        </td>
      );
    }
    return <td key={`${i}-btn`} colSpan={1}></td>;
  };

  acceptedCell = (r, i) => {
    return (
      <td
        key={`${i}-${r.id}-acc`}
        style={{
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 400,
          fontSize: "13px",
          lineHeight: "19px",
          color: "#000000",
          padding: "15px"
        }}
      >
        {r.canceled
          ? `Canceled by : ${r.canceledBy}`
          : r.accepted
          ? "Tour Accepted"
          : Date.now() > new Date(r["Appointment Time"]).getTime()
          ? "Tour Expired"
          : "Pending Acceptation"}
      </td>
    );
  };
  details = (r, i) => {
    return (
      <td key={`${i}-${r.id}-det`}>
        <button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "10px",
            lineHeight: "14px",
            color: "#fff",
            padding: "10px 25px",
            background: "#9bb0eb",
            transition: "all 0.15s ease",
            border: 0,
            margin: "0 1px"
          }}
          onClick={() =>
            this.setState({
              detailsModalShown: true,
              modalData: r,
              selectedMessage: r.Message
            })
          }
        >
          View Details
        </button>
      </td>
    );
  };

  dateCell = (r, i) => {
    return (
      <td
        key={`${i}-${r.id}`}
        style={{
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 400,
          fontSize: "13px",
          lineHeight: "19px",
          color: "#000000",
          padding: "15px"
        }}
      >
        {format(new Date(r["Appointment Time"]), "LLL dd, yyyy - h:mm a")}
      </td>
    );
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/appointments`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setState({
        data: {
          Appointments: res.data.data
        }
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  /**
   * This function handles the edit button functionality of a sub user
   * returns a boolean value
   */
  canSubUserEdit = () => {
    return !(!this.global.dsUser.sub_user ||
      this.global?.dsUser?.accesspoints?.Leads?.Appointments?.edit);
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="cont">
          <div className="row">
            <div className="col-md-6">
              <h1>Appointment Requests</h1>
            </div>
            {this.state.detailsModalShown && (
              <AppointmentDetailsModal
                message={this.state.selectedMessage}
                onClose={() =>
                  this.setState({
                    detailsModalShown: false
                  })
                }
              />
            )}
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div
                  className="forbtnapp"
                  style={{
                    cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                  }}
                >
                  <Link
                    to="/partners/appointments/availability"
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                  >
                    <img src="img/MyTourAvailability.svg" alt="" />
                    Appointments Availability
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cont">
          <NewTabbedTable
            tabs={this.tabs}
            headings={this.headings}
            data={this.state.data}
            // actions={this.actions}
          />
        </div>
        {this.state.cancelModalOpen && (
          <CancelModal
            tour={this.state.tourCancel}
            openSchedule={() => this.setState({ rescheduleModalOpen: true })}
            onClose={() => this.setState({ cancelModalOpen: false })}
          />
        )}
        {this.state.rescheduleModalOpen && (
          <Reschedule
            tour={this.state.tourReschedule}
            onClose={() => this.setState({ rescheduleModalOpen: false })}
          />
        )}
      </div>
    );
  }
}

export default TourRequests;
