import React from "react";
import LineChart from "../../../../components/charts/Line";
import { NotImg4 } from "../../../../assets";

class FranchiserEarningsOverview extends React.PureComponent {
  render() {
    const { earnings, total } = this.props;

    return (
      <div className="col-xl-6 col-xl-6-new">
        <div className="tablebox">
          <div className="tableheader">
            <h2>
              EARNINGS OVERVIEW
              <img src={NotImg4} alt="My Earnings" className="imgfix" />
            </h2>
          </div>
          <div className="tablebody padded">
            <h2 className="graph-header">
              <span>${parseInt(total).toLocaleString()}</span> Cumulative
            </h2>
            <div className="chart-area">
              {earnings ? (
                <LineChart
                  mapData={earnings.map(e =>
                    e.amount ? parseFloat(e.amount / 100).toFixed(2) : 0
                  )}
                  labels={earnings.map(e => e.date)}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default FranchiserEarningsOverview;