import React from "reactn";
import { Modal } from "../../UI";
import { Fragment } from "react";
import { TextField } from "@material-ui/core";
import Axios from "axios";
class ViewNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editNotes: false,
      notes: ""
    };
  }

  componentWillReceiveProps() {
    /*
    this.setState({
      notes: this.props.data
    });*/
  }
  saveNotes() {
    const ep = `${process.env.REACT_APP_API}/adm/followups/subtask/notes`;
    Axios.post(ep, {
      id: this.props.id,
      notes: this.state.notes
    });
    this.props.save(this.state.notes);
    this.setState({
      editNotes: false
    });
  }
  render() {
    const { open, onClose } = this.props;
    return (
      <div>
        <Modal open={open} heading="Task Notes" onClose={onClose}>
          <Fragment>
            <div className="row">
              <p style={{ textAlign: "center" }}></p>
            </div>
            <div className="col-md-12">
              {this.state.editNotes ? (
                <TextField
                  multiline
                  variant="outlined"
                  rows="4"
                  fullWidth
                  value={this.state.notes}
                  onChange={e => this.setState({ notes: e.target.value })}
                />
              ) : !this.props.data ? (
                <p className="row"> No notes found!</p>
              ) : (
                this.props.data.split("\n").map((element, i) => {
                  if (element) return <p key={i}>{element}</p>;
                })
              )}

              <div className="buttons">
                {this.state.editNotes ? (
                  <button onClick={() => this.saveNotes()}>Save</button>
                ) : (
                  <button
                    onClick={() =>
                      this.setState({ editNotes: true, notes: this.props.data })
                    }
                  >
                    Edit Notes
                  </button>
                )}
                <button onClick={() => onClose()} style={{ marginLeft: "5px" }}>
                  Close
                </button>
              </div>
            </div>
          </Fragment>
        </Modal>
      </div>
    );
  }
}

export default ViewNotes;
