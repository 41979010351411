import React, { useState, useEffect, useGlobal } from "reactn";
import axios from "axios";
import PropTypes from "prop-types";

import { format, getDay, addWeeks } from "date-fns";
import { DateTime } from "luxon";
import { constructDateOnlyWithTimeZone, constructTimeRangeFromDecimalHours, getFormattedTimeRange, formattedTimeRange } from "../../pages/front/SpecificPageUtils/utils";

import DailyIframe from "@daily-co/daily-js";

import {
  getTime,
  getNextScheduledLesson,
  generate_dates,
  isDaysOfWeek,
  merge_analytics_with_schedule,
  days_rev
} from "./VirtualRoom/utils";
import { ModalTemplate } from "../UI/modalComponents";

const dreamschools_daily_dot_co = "https://dreamschools.daily.co";

const VirtualRoomModal = ({ onClose, program, history, timezone }) => {
  const {
    isMonday,
    isThursday,
    isTuesday,
    isWednesday,
    isFriday,
    isSaturday,
    isSunday
  } = isDaysOfWeek(program.days_of_week);

  useEffect(() => {
    if (program.manual_zoom) {
      display_zoom_link();
    }
  }, []);

  const start_time = parseFloat(program.timing[0]);
  const end_time = parseFloat(program.timing[1]);
  const sessionTimes = program.timing.map(e => parseFloat(e));

  const [showJoinButton, setShowJoinButton] = useState(false);
  const [TableData, setTableData] = useState([]);
  const [futureCalls, setFutureCalls] = useState([]);
  const [schedule, _] = useState(generate_dates(program));
  const [virtualCall, setVirtualCall] = useGlobal("virtualCall");
  const [leftIndex, setLeftIndex] = useState([0, 2, 4, 6, 8, 10, 12, 14, 16]);
  const [rightIndex, setRightIndex] = useState([1, 3, 5, 7, 9, 11, 13, 15, 17]);

  console.log("tableData", TableData);
  console.log("futureCalls", futureCalls);
  console.log("schedule", schedule);
  console.log("program", program);

  /**
   * Zoom Display Join button
   */
  const display_zoom_link = async () => {
    //
    const ep = `${process.env.REACT_APP_API}/partner/virtual/can_user_join/${program.id}`;
    const resp = await axios.get(ep);

    if (resp.data && resp.data.success) {
      setShowJoinButton(resp.data.can_user_join);
    }
  };

  const getFutureCallsSchedule = () => {
    const schedule = [];
    if(parseInt(program.program_type) === 0){
      // console.log("program.program_start", program.program_start);
      // console.log("new Date(program.program_start)", new Date(program.program_start + "T00:00"));
      //Creates correct date when adding "T00:00"
      schedule.push(new Date(program.program_start + "T00:00"));
      setFutureCalls(schedule);
    } else {
      if (isMonday) {
        const nextMonday = getNextScheduledLesson(days_rev["Monday"], end_time);
        schedule.push(nextMonday);
        schedule.push(addWeeks(nextMonday, 1));
      }
  
      if (isThursday) {
        const nextThursday = getNextScheduledLesson(
          days_rev["Thursday"],
          end_time
        );
        schedule.push(nextThursday);
        schedule.push(addWeeks(nextThursday, 1));
      }
  
      if (isFriday) {
        const nextFriday = getNextScheduledLesson(days_rev["Friday"], end_time);
        schedule.push(nextFriday);
        schedule.push(addWeeks(nextFriday, 1));
      }
  
      if (isSaturday) {
        const nextSaturday = getNextScheduledLesson(
          days_rev["Saturday"],
          end_time
        );
        schedule.push(nextSaturday);
        schedule.push(addWeeks(nextSaturday, 1));
      }
  
      if (isSunday) {
        const nextSunday = getNextScheduledLesson(days_rev["Sunday"], end_time);
        schedule.push(nextSunday);
        schedule.push(addWeeks(nextSunday, 1));
      }
  
      if (isTuesday) {
        const nextTuesday = getNextScheduledLesson(days_rev["Tuesday"], end_time);
        schedule.push(nextTuesday);
        schedule.push(addWeeks(nextTuesday, 1));
      }
  
      if (isWednesday) {
        const nextWednesday = getNextScheduledLesson(
          days_rev["Wednesday"],
          end_time
        );
        schedule.push(nextWednesday);
        schedule.push(addWeeks(nextWednesday, 1));
      }
  
      if (program.number_of_weeks !== 0 && program.number_of_weeks !== null) {
        // filter the days that go past the start date + number of weeks
        const end_date = addWeeks(
          new Date(program.program_start),
          parseInt(program.number_of_weeks)
        );
        const getDaysArray = function (start, end) {
          for (
            var arr = [], dt = new Date(start);
            dt <= end;
            dt.setDate(dt.getDate() + 1)
          ) {
            arr.push(new Date(dt));
          }
          return arr;
        };
        console.log("days_rev: ", days_rev)
        const semestered_schedule = getDaysArray(new Date(), end_date).filter(
          date => {
            if (isMonday && getDay(date) === days_rev["Monday"]) {
              return true;
            }
            if (isTuesday && getDay(date) === days_rev["Tuesday"]) {
              return true;
            }
            if (isWednesday && getDay(date) === days_rev["Wednesday"]) {
              return true;
            }
            if (isThursday && getDay(date) === days_rev["Thursday"]) {
              return true;
            }
            if (isFriday && getDay(date) === days_rev["Friday"]) {
              return true;
            }
            if (isSaturday && getDay(date) === days_rev["Saturday"]) {
              return true;
            }
            if (isSunday && getDay(date) === days_rev["Sunday"]) {
              return true;
            }
            return false;
          }
        );
  
        setFutureCalls(semestered_schedule.sort((a, b) => a - b));
      } else {
        setFutureCalls(schedule.sort((a, b) => a - b));
      }
    }
    
  };

  useEffect(() => {
    getFutureCallsSchedule();
  }, []);

  /**
   * Daily.co
   */
  const getMeetingToken = async () => {
    if (program && program.daily_co_room_name) {
      const ep = `${process.env.REACT_APP_API}/partner/virtual/get_meeting_token/${program.daily_co_room_name}`;
      const resp = await axios.get(ep);

      if (resp.data.success) {
        setVirtualCall({
          ...virtualCall,
          room_url: `${dreamschools_daily_dot_co}/${program.daily_co_room_name}`,
          meeting_token: resp.data.token
        });
        setShowJoinButton(resp.data.can_join);
      }
    }
  };

  /**
   * Daily.co
   */
  useEffect(() => {
    if (program.daily_co_room_name) {
      getMeetingToken();
    }
  }, [showJoinButton]);

  /**
   * Daily.co
   */
  const getRoomMeetingAnalytics = async () => {
    const ep = `${process.env.REACT_APP_API}/partner/virtual/get_room_meetings/${program.daily_co_room_name}`;
    const resp = await axios.get(ep);

    const data = resp.data;
    const lst = data.data.data;

    const sorted_data = lst.sort((a, b) => a.start_time - b.start_time);
    console.log("sorted_data", sorted_data);
    const new_data = merge_analytics_with_schedule(schedule, sorted_data);
    setTableData(new_data);
  };

  const getTimeRange = (start_time, end_time, date) => {
    let { dateOnlyWithTZ } = constructDateOnlyWithTimeZone( date.toISOString(), timezone );

    /* Below, "dateOnlyWithTZ" is used to frame the time ranges within the correct timezone.  */
    let {startTime,endTime} = constructTimeRangeFromDecimalHours(
      parseFloat(start_time),
      parseFloat(end_time),
      dateOnlyWithTZ
    );
    const formattedTimeRange = getFormattedTimeRange( startTime, endTime );

    return formattedTimeRange;
  }

  useEffect(() => {
    getRoomMeetingAnalytics();
  }, []);

  const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

  // Main table content
  const mainContent = (
    <table className="table">
      <thead
        style={{
          color: "#A9A9A9",
          fontSize: "11.2px",
          borderBottom: "1px solid gainsboro"
        }}
      >
        <tr>
          <th data-testid="table-header-name">Date</th>
          <th data-testid="table-header-date">Time</th>
        </tr>
      </thead>

      <tbody>
        {/* past calls */}
        {TableData &&
          TableData.map(data => {
            console.log("past: ", data);
            if (data.day <= new Date(program.program_start)) {
              return;
            }
            return (
              <tr
                style={{
                  fontSize: "12px",
                  color: "#959595",
                  borderBottom: "2px solid gainsboro"
                }}
              >
                <td>{format(data.day, "eee MM/dd/yyyy")}</td>
                <td>
                  {data.start_time
                    ? format(new Date(data.start_time * 1000), "p")
                    : "-"}
                </td>
                {!program.manual_zoom && (
                  <>
                    <td>{data.participants ? data.participants : "-"}</td>
                    <td>
                      {data.duration
                        ? (data.duration / 60).toFixed(0) + "min"
                        : "-"}{" "}
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        {/* future calls */}
        {futureCalls.map((data, id) => {
          const compareDate = data;
          let start_date = new Date(program.program_start);
          start_date = start_date.setHours(0);
          const customDayTimes = program.enableCustomTimes ? program.customTimes[weekday[data.getDay()]] : []
          if (compareDate < start_date) {
            return;
          }
          return (
            <tr
              key={id}
              style={{
                fontSize: "12px",
                color: "#959595",
                borderBottom: "2px solid gainsboro"
              }}
            >
              <td>{format(data, "eee MM/dd/yyyy")}</td>
              <td>
                {program.program_category === "Single Sessions"
                  ? program.enableCustomTimes
                    ? customDayTimes.length >= 2 &&
                    new Array(customDayTimes.length / 2)
                      .fill(1)
                      .map((_, index) => {
                        return (
                          <>
                            {getTime(customDayTimes[leftIndex[index]])} to{" "}
                            {getTime(customDayTimes[rightIndex[index]])}
                            {index !== customDayTimes.length / 2 - 1 ? ", " : ""}
                          </>
                        );
                      })
                    : (
                      sessionTimes.length >= 2 &&
                        new Array(sessionTimes.length / 2)
                          .fill(1)
                          .map((_, index) => {
                            return (
                              <>
                                {getTime(sessionTimes[leftIndex[index]])} to{" "}
                                {getTime(sessionTimes[rightIndex[index]])}
                                {index !== sessionTimes.length / 2 - 1 ? ", " : ""}
                              </>
                            );
                          })
                      )
                  : (start_time === -1 || end_time === -1
                    ? "Not Provided"
                    : <>{getTimeRange(start_time, end_time, data)}</>)}
              </td>
              {!program.manual_zoom && (
                <>
                  <td></td>
                  <td></td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  var footerContent;
  // Set join button status.
  if (program.manual_zoom) {
    footerContent = showJoinButton ? (
      <>
        {program.manual_metting_link ? (
          <a href={`${program.manual_metting_link}`} target="_blank">
            <button className="btn">Join Now</button>
          </a>
        ) : (
          <>
            <p style={{ fontSize: "0.9rem", color: "grey" }}>
              {program.manual_meeting_id && (
                <>
                  meeting id: {program.manual_meeting_id} <br />
                </>
              )}
              {program.manual_meeting_password && (
                <>
                  meeting password: {program.manual_meeting_password}
                  <br />
                </>
              )}
            </p>
          </>
        )}
      </>
    ) : (
      <button className="btn" disabled>
        Join Now
      </button>

    );
  } else {
    footerContent =
      virtualCall.room_url && virtualCall.meeting_token && showJoinButton ? (
        <div>
          {DailyIframe.supportedBrowser().supported ? (
            <>
              {!virtualCall.isCallFrameCreated && (
                <button
                  onClick={() => {
                    setVirtualCall({
                      ...virtualCall,
                      isCallFrameCreated: true
                    });
                    onClose();
                    // setIsCallFrameCreated(true)
                  }}
                  className="btn"
                >
                  Join Now
                </button>
              )}
            </>
          ) : (
            "Sorry, browser not supported! Please update your browser."
          )}
        </div>
      ) : (
        <button className="btn" disabled>
          Join Now
        </button>
      );
  }

  return (
    <ModalTemplate
      heading="Virtual Room"
      onClose={onClose}
      footerContent={footerContent}
    >
      {mainContent}
    </ModalTemplate>
  );
};

VirtualRoomModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  program: PropTypes.object.isRequired,
  history: PropTypes.object
};
export default VirtualRoomModal;
