import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton, Switch } from "@material-ui/core";
import Button from "react-bootstrap/Button";
import { formTypes } from "../UI/form";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, SWITCH, DROPDOWN, TITLE, GET_HTML } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class EditBirthdayPartyModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ticket_name: this.props.selectedItem.ticket_name,
      ticket_price: parseFloat(this.props.selectedItem.ticket_price),
      ticket_quantity: this.props.selectedItem.ticket_quantity,
      ticket_status: this.props.selectedItem.ticket_status,
      isTaxEnabled: this.props.selectedItem.isTaxEnabled,
      taxInfo: this.props.selectedItem.taxInfo,
      selectedMembership: this.props.selectedItem.selectedMembership,
      published_memberships: [],
      addOn: this.props.selectedItem.addOn,
      addOnItems: this.props.selectedItem.addOnItems || [],
      addOns: []
    };
  }

  async componentDidMount() {
    this.setGlobal({
      loading: true
    })

    const ep = `${process.env.REACT_APP_API}/partners/parties/addons`
    const result = await axios.get(ep);

    if (result.data.success) {
      this.setState({
        addOns: result.data.data
      });

      // const addon = result.data.data.find(addon => parseInt(addon.id) === parseInt(selectedAddon))
      // if (addon) {
      //   setAddonName(addon.name)
      //   setAddonPrice(addon.price)
      //   setAddonDescription(addon.description)
      // }

    } else {
      toast.error("Failed to load add-ons")
    }

    this.setGlobal({
      loading: false
    })
  }

  addOnsField = () => {
    return (
      <div
        className="col-12 d-flex"
        style={{
          padding: "0px",
        }}
      >
        <div
          style={{
            border: "1px solid #cbcbcb",
            borderRadius: "5px",
            padding: "5px",
            width: "100%",
            marginTop: "10px",
            height: "180px",
            overflow: "auto"
          }}
        >
          {this.state.addOnItems.map(e => {
            return (
              <Button
                disabled={this.props.viewOnlyMode}
                variant="primary"
                size="sm"
                style={{ margin: "3px 5px" }}
              >
                {`${e.text}: $${parseFloat(e.value).toFixed(2)}`}
                <Close
                  disabled={this.props.viewOnlyMode}
                  style={{
                    color: "white",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    this.setState({
                      addOnItems: this.state.addOnItems.filter(addon => addon.text !== e.text)
                    })
                  }}
                />
              </Button>
            );
          })}
        </div>
      </div>
    )
  }

  async getPublishedMemberships() {

  }

  requestTour = async e => {
    e.preventDefault();
  };
  async savePlan() {
    if (
      this.state.ticket_status !== "Free" &&
      this.state.ticket_price !== 0 &&
      !this.global.organizationInfo.stripeId
    ) {
      toast.error("Denied. Please connect your Bank Account first");
      return;
    }

    const obj = { ...this.state, id: this.props.selectedItem.id };

    if (!this.state.addOn) {
      delete obj.addOn;
      delete obj.addOnItems;
    }

    this.setGlobal({
      party: {
        ...this.global.party,
        ["tickets"]: this.global.party.tickets.map(item =>
          JSON.parse(item).id === obj.id ? JSON.stringify(obj) : item
        )
      }
    });
    await this.dispatch.saveParty();
    this.props.onClose();
  }

  /// ================== NEW MODAL ========================== ///

  taxesOptions = () => {
    const global_organization = this.global.organizationInfo.stripeTaxId;
    console.log("global_organization", global_organization);
    const DATA = [];
    for (let i = 0; i < global_organization.length; i++) {
      DATA.push(JSON.parse(global_organization[i]));
    }
    console.log("DATA", DATA);

    return [
      ...DATA.map((e, i) => ({
        value: i,
        text: e.display_name + " " + e.jurisdiction + " " + e.percentage + "%"
      }))
    ];
  };

  addOnOptions = () => {
    //const global_organization = [JSON.parse(this.global.organizationInfo.stripeTaxId)]
    return [
      ...this.state.addOns.map((e, i) => ({
        text: e.name,
        value: e.price,
      }))
    ];
  };

  getCurrTaxPlan() {
    for (let taxPlan of this.taxesOptions()) {
      if (
        this.state.taxInfo[0] &&
        taxPlan.text ===
        this.state.taxInfo[0].display_name +
        " " +
        this.state.taxInfo[0].jurisdiction +
        " " +
        this.state.taxInfo[0].percentage +
        "%"
      ) {
        return taxPlan.value;
      }
    }
  }

  optionsFunc = () => {
    return this.state.addOnItems.map((e, i) => (
      <div
        className="form-group"
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 0
        }}
        key={i}
      >
        <input
          name="add-on-name"
          type="text"
          className="string-input-field field-component-input-box"
          placeholder={`Fee Name`}
          style={{
            width: "150px",
            marginRight: "10px"
          }}
          onChange={e => {
            console.log("E VALUE", e.target.value, e.target.value === "");
            {
              const options = [...this.state.addOnItems];
              options[i] = {
                text: e.target.value,
                value: options[i].value
              };
              this.setState({ addOnItems: options });
            }
          }}
          value={this.state.addOnItems[i].text}
        />
        <input
          name="add-on-amount"
          type="number"
          className="string-input-field field-component-input-box"
          placeholder={`Amount`}
          style={{
            width: "150px",
            marginRight: "10px"
          }}
          onChange={e => {
            {
              const options = [...this.state.addOnItems];
              options[i] = {
                text: options[i].text,
                value: e.target.value
              };
              this.setState({ addOnItems: options });
            }
          }}
          value={this.state.addOnItems[i].value || "0"}
        />
        <button
          type="button"
          onClick={_ => {
            if (this.state.addOnItems?.length < 10) {
              this.setState({
                addOnItems: [...this.state.addOnItems, {}]
              });
            }
          }}
          style={{
            backgroundColor: "yellowgreen",
            border: "none",
            color: "white",
            borderRadius: "3px",
            marginRight: "10px",
            marginTop: "8px"
          }}
        >
          +
        </button>

        {i !== 0 && (
          <button
            style={{
              width: "8%",
              backgroundColor: "red",
              border: "none",
              color: "white",
              borderRadius: "3px",
              marginTop: "8px"
            }}
            type="button"
            onClick={_ =>
              this.setState({
                addOnItems: this.state.addOnItems.filter(
                  (_, index) => index !== i
                )
              })
            }
          >
            -
          </button>
        )}
      </div>
    ));
  };

  getBaseModalFields() {
    const ticketName = {
      type: STRING_INPUT,
      data: {
        isDisabled: this.props.viewOnlyMode,
        name: "Package Name",
        required: true,
        handleChange: e => {
          this.setState({
            ticket_name: e.target.value
          });
        },
        value: this.state.ticket_name
      },
      validators: { validateSubmit: () => this.state.ticket_name !== "" }
    };

    const pricingTicket = {
      type: STRING_INPUT,
      data: {
        name: "Pricing for Package",
        required: true,
        isDisabled: this.props.viewOnlyMode || this.state.ticket_status === "Free",
        type: "number",
        handleChange: e => {
          let price = parseFloat(e.target.value || 0);
          let decimalValue = e.target.value.split(".")[1];
          console.log("Price 1: ", price);
          if (decimalValue && decimalValue.length > 2) {
            return;
          } else if (!e.target.value.endsWith("0")) {
            price = Math.round(price * 100) / 100;
          } else {
            price = e.target.value;
          }
          console.log("Price 2: ", price);
          this.setState({
            ticket_price: price.toString()
          });
        },
        value: this.state.ticket_price
      },
      validators: {
        validateSubmit: () =>
          this.state.ticket_price >= 0 && this.state.ticket_price !== ""
      }
    };

    const ticketQuantity = {
      type: STRING_INPUT,
      data: {
        isDisabled: this.props.viewOnlyMode,
        name: "Ticket Quantity",
        required: false,
        type: "number",
        handleChange: e => {
          this.setState({
            ticket_quantity: e.target.value
          });
        },
        value: this.state.ticket_quantity
      }
    };

    const status = {
      type: TITLE,
      data: { name: `Type: ${this.state.ticket_status}` }
    };

    const enableTaxes = {
      type: SWITCH,
      data: {
        isDisabled: this.props.viewOnlyMode,
        name: "Enable Taxes",
        checked: this.state.isTaxEnabled,
        handleClick: e =>
          this.setState({
            isTaxEnabled: e.target.checked,
            taxInfo: []
          })
      }
    };

    const selectTaxes = {
      type: DROPDOWN,
      data: {
        isDisabled: this.props.viewOnlyMode,
        name: "Tax Plan",
        required: true,
        placeholder: "Please Select",
        handleChange: e => {
          if (!e.target.value) {
            this.setState({
              taxInfo: []
            });
            return;
          }

          const item = JSON.parse(
            this.global.organizationInfo.stripeTaxId[e.target.value]
          );
          this.setState({
            taxInfo: [
              {
                id: item.id,
                percentage: item.percentage,
                jurisdiction: item.jurisdiction,
                display_name: item.display_name,
                description: item.description
              }
            ]
          });
        },
        value: this.getCurrTaxPlan(),
        choices: this.taxesOptions()
      },
      validators: {
        validateSubmit: () => !this.state.isTaxEnabled || this.state.taxInfo.length
      }
    };

    const selectMembership = {
      type: DROPDOWN,
      data: {
        isDisabled: this.props.viewOnlyMode,
        name: "Select Membership",
        required: true,
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            selectedMembership: e.target.value
          });
        },
        choices: this.state.published_memberships
      },
      validators: { validateSubmit: () => this.state.selectedMembership !== "" }
    };

    const addsonFee = {
      type: SWITCH,
      data: {
        isDisabled: this.props.viewOnlyMode,
        name: "Adds-On Fee",
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        checked: this.state.addOn,
        handleClick: e => this.setState({ addOn: e.target.checked })
      }
    };

    const fields = [];
    fields.push(ticketName);
    fields.push(pricingTicket);
    fields.push(ticketQuantity);
    // fields.push(status);

    fields.push(addsonFee);

    const addOnSelectionField = {
      type: DROPDOWN,
      data: {
        isDisabled: this.props.viewOnlyMode,
        name: "Add-ons",
        required: true,
        placeholder: "Select Add-on",
        value: "Select Add-on",
        handleChange: e => {
          const name = e.target.options[e.target.selectedIndex].text

          if (e.target.selectedIndex === 0) {

          } else if (this.state.addOnItems.find(addon => addon.text === name)) {
            toast.error("You have already selected this add-on")
          } else {
            const addon = this.state.addOns.find(addon => addon.name === name)
            this.setState({
              addOnItems: [...this.state.addOnItems, {
                id: addon.id,
                text: addon.name,
                value: addon.price,
                description: addon.description
              }]
            })
          }
        },
        choices: this.addOnOptions()
      },
      validators: { validateSubmit: () => !this.state.isTaxEnabled || this.state.taxInfo.length }
    }

    const addOnOptionField = {
      type: GET_HTML,
      data: {
        gethtml: this.addOnsField
      },
      validators: {
        validateSubmit: () => {
          if (this.state.addOn) {
            return this.state.addOnItems.length > 0
          }
          return true;
        }
      }
    };

    if (this.state.addOn) {
      fields.push(addOnSelectionField);
      fields.push(addOnOptionField);
    }

    if (this.state.ticket_status === "Membership") {
      fields.push(selectMembership);
    }

    if (this.state.addsonFee) {

    }

    if (this.state.ticket_status !== "Free") {
      fields.push(enableTaxes);
    }
    if (this.state.isTaxEnabled) {
      fields.push(selectTaxes);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    console.log("STATE", this.state);
    console.log("PROPS", this.props);
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.savePlan()
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: `${this.props.viewOnlyMode? "View" : "Edit"} Package`,
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ========================== ///

  render() {
    console.log("this.state.ticket_status", this.state.ticket_status);
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(EditBirthdayPartyModal);
