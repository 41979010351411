import React from "reactn";
import { Modal } from "../../UI";
import { Fragment } from "react";

class InterestedProgramsModal extends React.PureComponent {
  render() {
    const { open, onClose } = this.props;

    return (
      <div>
        <Modal open={open} heading="Details" onClose={onClose}>
          {this.props.data && (
            <Fragment>
              <div className="row">
                <p style={{ textAlign: "center" }}>
                  The parent <b>{this.props.data.displayName}</b> has shown
                  Interest in <b>{this.props.data.programName}</b> :<br />
                  {this.props.data.firstMessage ? (
                    <span>
                      The parent asked for more information from the educator
                      and their message was : <br />{" "}
                      <b>{this.props.data.firstMessage}</b>
                    </span>
                  ) : (
                    <span>
                      The parent has't yet asked the educator for more
                      information
                    </span>
                  )}
                  <br />
                  {this.props.data.requestedTour ? (
                    <span>The parent asked this educator for tour!</span>
                  ) : (
                    "The parent didn't ask the educator for a tour yet!"
                  )}
                  <br />
                  The parent's phone number is :{" "}
                  <b>{this.props.data.phone || "Unavailable"}</b> <br />
                  The parent's email is :{" "}
                  <b>{this.props.data.email || "Unavailable"}</b>
                </p>
              </div>
              <div className="row">
                <p></p>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="buttons">
                    <button onClick={() => onClose()}>Close</button>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </Modal>
      </div>
    );
  }
}

export default InterestedProgramsModal;
