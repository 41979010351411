import React from "reactn";
import axios from "axios";

class BankForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accountId: "",
      loginId: "",
      institution: ""
    };
    this.updateFlinks = this.updateFlinks.bind(this);
  }
  async componentDidMount() {
    window.addEventListener("message", this.updateFlinks);
  }

  async updateFlinks(e) {
    if (!e.data.step && e.data.loginId && e.data.institution) {
      this.setState({
        loginId: e.data.loginId,
        institution: e.data.institution
      });
    } else if (e.data.step === "ACCOUNT_SELECTED") {
      this.setState({
        accountId: e.data.accountId,
        institution: e.data.institution
      });
    } else if (e.data.step === "REDIRECT") {
      window.removeEventListener("message", this.updateFlinks);
      this.setGlobal({ loading: true });
      const res = await axios.post(
        `${process.env.REACT_APP_API}/pmnt/flinks`,
        this.state
      );
      this.setGlobal({
        loading: false,
        lastAPICall: res,
        payment: { ...this.global.payment, loading: true }
      });
    }
  }

  render() {
    return (
      <iframe
        title="Flinks Connect"
        height="480"
        scrolling="no"
        src="https://dreamcaretechnologies-iframe.private.fin.ag/?desktopLayout=true&institutionFilterEnable=true&backgroundColor=343F64&foregroundColor2=F0EEEE&demo=true&consentEnable=true&accountSelectorEnable=true"
        style={{ width: "100%" }}
      ></iframe>
    );
  }
}

export default BankForm;
