import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import axios from "axios";
// import { toast } from "react-toastify";
import { format } from "date-fns";
// import AddTaskModal from "../../components/modals/AddTaskModal";
// import AddReminderModal from "../../components/modals/AddReminderModal";
// import { Delete } from "@material-ui/icons";
import CreateCouponModal from "../../components/modals/CreateCouponModal";
import EditCouponModal from "../../components/modals/EditCouponModal";
import ConfirmArchiveModal from "../../components/modals/ConfirmArchiveModal";
import ViewCouponModal from "../../components/modals/ViewCouponModal";

import {
  Delete,
  SettingsBackupRestore as Restore,
  Create as Edit
} from "@material-ui/icons";
import ReactTooltip from "react-tooltip";

class Coupon extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      openCreateCouponModal: false,
      hasPermissions: true,
      hasPermissionMessage: "",
      selectedItem: null
    };

    this.tabs = ["Coupons", "Archive"];

    this.actions = [{ name: "Notes", action: null }];
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Coupons", to: "/coupon" }
      ]
    });
  }

  headings = () => {
    return {
      Coupons: [
        { id: "coupon_id", label: "Coupon Code" },
        {
          id: "name",
          label: "Internal Name",
          customCell: (r, i) => <td key={i}>{r.coupon_name}</td>
        },
        {
          id: "discounts",
          label: "Discount",
          customCell: (r, i) => (
            <td key={i}>
              {r.type !== "Percentage" && "$"} {r.amount_off}{" "}
              {r.type === "Percentage" && "%"}
            </td>
          )
        },
        {
          id: "red",
          label: "Redemption Count",

          customCell: (r, i) => <td key={i}>{r.redemption_count}</td>
        },
        {
          id: "red",
          label: "Terms",

          customCell: (r, i) => {
            const symbol = r.type === "Percentage" ? "%" : "$";
            const isDurationMonths = r.duration === "repeating" ? true : false;
            const durationDisplay =
              r.duration === "repeating"
                ? `${r.type !== "Percentage" ? "$" : ""}${r.amount_off}${
                    r.type === "Percentage" ? "%" : ""
                  } off for ${r.duration_in_months} months`
                : `${r.type !== "Percentage" ? "$" : ""}${r.amount_off}${
                    r.type === "Percentage" ? "%" : ""
                  } off ${r.duration ? r.duration : ""}`;
            return (
              <td key={i}>
                {r.duration && isDurationMonths
                  ? durationDisplay
                  : durationDisplay}
              </td>
            );
          }
        },
        {
          id: "red",
          label: "Expiry",

          customCell: (r, i) => (
            <td key={i}>
              {r.redeem_by
                ? format(new Date(r.redeem_by), "LLLL dd, yyyy")
                : ""}
            </td>
          )
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button
                  style={{ marginRight: "30px" }}
                  //   onClick={_ =>
                  //     this.props.history.push(`/partners/news-create/${r.id}`)
                  //   }
                  onClick={() => {
                    this.openModal(r, i);
                  }}
                >
                  View
                </button>
                <Edit
                  onClick={() => {
                    this.setState({
                      showEditModal: true,
                      moduleToEdit: r
                    });
                  }}
                  data-for="edit"
                  data-tip
                  style={{ color: "grey", cursor: "pointer" }}
                />
                <ReactTooltip id="edit" place="top" effect="solid" type="info">
                  Edit Coupon
                </ReactTooltip>
                <Delete
                  onClick={() => {
                    this.setState({
                      showConfirmModal: true,
                      moduleToDelete: r,
                      toArchive: true
                    });
                  }}
                  style={{ color: "grey", cursor: "pointer" }}
                  data-for="archive"
                  data-tip
                />
                <ReactTooltip
                  id="archive"
                  place="top"
                  effect="solid"
                  type="info"
                >
                  Delete Coupon
                </ReactTooltip>
                {/* <Delete style={{ color: "lightgrey" }} /> */}
              </td>
            );
          }
        }
      ],
      Archive: [
        { id: "coupon_id", label: "Coupon Code" },
        {
          id: "name",
          label: "Internal Name",
          customCell: (r, i) => <td key={i}>{r.coupon_name}</td>
        },
        {
          id: "discounts",
          label: "Discount",
          customCell: (r, i) => (
            <td key={i}>
              {r.type !== "Percentage" && "$"} {r.amount_off}{" "}
              {r.type === "Percentage" && "%"}
            </td>
          )
        },
        {
          id: "red",
          label: "Redemption Count",

          customCell: (r, i) => <td key={i}>{r.redemption_count}</td>
        },
        {
          id: "red",
          label: "Terms",

          customCell: (r, i) => {
            const symbol = r.type === "Percentage" ? "%" : "$";
            const isDurationMonths = r.duration === "repeating" ? true : false;
            const durationDisplay =
              r.duration === "repeating"
                ? `${r.type !== "Percentage" ? "$" : ""}${r.amount_off}${
                    r.type === "Percentage" ? "%" : ""
                  } off for ${r.duration_in_months} months`
                : `${r.type !== "Percentage" ? "$" : ""}${r.amount_off}${
                    r.type === "Percentage" ? "%" : ""
                  } off ${r.duration ? r.duration : ""}`;
            return (
              <td key={i}>
                {r.duration && isDurationMonths
                  ? durationDisplay
                  : durationDisplay}
              </td>
            );
          }
        },
        {
          id: "red",
          label: "Expiry",

          customCell: (r, i) => (
            <td key={i}>
              {r.redeem_by
                ? format(new Date(r.redeem_by), "LLLL dd, yyyy")
                : ""}
            </td>
          )
        }
      ]
    };
  };

  openModal = (r, i) => {
    this.setState({ showViewModal: true, selectedItem: r });
  };

  /**
   * This function handles the edit button functionality of a sub user
   * returns a boolean value
   */
  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return this.global.dsUser.accesspoints.Financials["Coupons"].edit
        ? false
        : true;
    }
  };

  async componentDidMount() {
    this.fetchInitial();
  }

  async fetchInitial() {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/stripe/coupon`;
    const results = await axios.get(ep);
    console.log(results);

    if (results.data.success) {
      // Sort by most recent to oldest
      results.data.data.sort((coupon1, coupon2) => {
        return new Date(coupon2.updatedAt) - new Date(coupon1.updatedAt)
      })

      this.setState({
        data: {
          Coupons: results.data.data
            .filter(f => !f.isArchived)
            .map(e => ({
              ...e,
              status: "Published"
            })),
          Archive: results.data.data
            .filter(f => f.isArchived)
            .map(e => ({
              ...e,
              status: "Published"
            }))
        }
      });
    } else {
      this.setState({ hasPermissions: false });
      this.setState({ hasPermissionMessage: results.data.message });
    }
    this.setGlobal({ loading: false });
  }

  //   acceptPartnership = async row => {
  //     const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
  //     const results = await axios.post(ep, { partner: row });
  //     if (results.data.success) {
  //       toast.success(`Partner Accepted`);
  //       this.componentDidMount();
  //     } else {
  //       toast.error(`Error, this person already signed-up!`);
  //     }
  //   };

  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  async onSubmit() {
    const ep = `${process.env.REACT_APP_API}/partners/stripe/delete/coupon`;
    const results = await axios.post(ep, {
      id: this.state.moduleToDelete.id,
      coupon_code: this.state.moduleToDelete.coupon_id
    });

    if (results.data.success) {
      this.fetchInitial();
    }
  }

  render() {
    if (!this.state.hasPermissions) {
      return (
        <div className="admin">
          <div className="container-fluid adminprofiles">
            <div className="cont">
              <div className="row ">
                <h1 data-testid="heading-no-permission">Coupons</h1>
              </div>
              <div className="row">
                <p>{this.state.hasPermissionMessage}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="admin">
        {this.state.openCreateCouponModal && (
          <CreateCouponModal
            onClose={() => this.setState({ openCreateCouponModal: false })}
            addOne={
              dt => this.fetchInitial()
              // this.setState({
              //   data: { Coupons: [...this.state.data.Coupons, dt] }
              // })
            }
          />
        )}
        {this.state.showEditModal && (
          <EditCouponModal
            item={this.state.moduleToEdit}
            onEdit={_ => this.fetchInitial()}
            onClose={_ => this.setState({ showEditModal: false })}
          />
        )}
        {this.state.showConfirmModal && (
          <ConfirmArchiveModal
            onClose={_ => this.setState({ showConfirmModal: false })}
            onSubmit={_ => this.onSubmit()}
            toArchive={true}
            isPermanent={true}
            modalBody={
              "Are you sure you would like to remove this listing? This coupon will no longer be redeemable once archived."
            }
          />
        )}

        {this.state.showViewModal && (
          <ViewCouponModal
            onClose={_ => this.setState({ showViewModal: false })}
            couponInfo={this.state.selectedItem}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1 data-testid="heading-permission">Coupons</h1>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp">
                  <button
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ =>
                      this.setState({ openCreateCouponModal: true })
                    }
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Create New
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="tablecont">
              <div className="table-responsive">
                <NewTabbedTable
                  tabs={this.tabs}
                  headings={this.headings()}
                  data={this.state.data}
                  actions={this.actions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Coupon;
