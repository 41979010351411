import React from "react";
import { Bar, defaults } from 'react-chartjs-2';
console.log("defaults", defaults);

// For some reason the options object is being ignored so
// the default values are modified directly so the most important
// option values are followed
defaults.bar.scales.yAxes[0].ticks = { maxTicksLimit: 7 };
defaults.global.legend.display = false;
defaults.global.maintainAspectRatio = false;

let options = {
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 10,
      right: 25,
      top: 25,
      bottom: 0
    }
  },
  scales: {
    xAxes: [
      {
        type: 'cateogory',
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          maxTicksLimit: 7
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          maxTicksLimit: 5,
          padding: 10,
          callback: (value, index, values) => {
            return "$" + parseInt(value).toLocaleString().replace(/\d(?=(\d{3})+\.)/g, "$&,");
          }
        },
        gridLines: {
          color: "rgb(234, 236, 244)",
          zeroLineColor: "rgb(234, 236, 244)",
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2]
        }
      }
    ]
  },
  legend: {
    display: false
  },
  tooltips: {
    backgroundColor: "rgb(255,255,255)",
    bodyFontColor: "#858796",
    titleMarginBottom: 10,
    titleFontColor: "#6e707e",
    titleFontSize: 14,
    borderColor: "#dddfeb",
    borderWidth: 1,
    xPadding: 15,
    yPadding: 15,
    displayColors: false,
    intersect: false,
    mode: "index",
    caretPadding: 10,
    callbacks: {
      label: function (tooltipItem, chart) {
        var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || "";
        return `${datasetLabel} $${parseFloat(
          tooltipItem.yLabel
        ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
      }
    }
  }
};

export default class BarChart extends React.Component {
  componentWillReceiveProps(props) {
    this.forceUpdate();
  }

  render () {
    console.log("Bar Chart props: ", this.props);
    let data = {
      labels: this.props.labels ? this.props.labels: [],
      datasets: [
        {
          data: this.props.data ? this.props.data : [],
          backgroundColor: "rgba(78, 115, 223, 0.6)",
          borderColor: "rgba(78, 115, 223, 1)",
          borderWidth: 1,
        }
      ]
    };

    return <Bar data={data} option={options} />;
  }
};