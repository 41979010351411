import React from "reactn";
import {
  AIcon,
  BIcon,
  CIcon,
  Lock,
  MilestoneCheck,
  MilestoneCheck2,
  IconG
} from "../../assets";
import { Scrollbars } from "react-custom-scrollbars";

const milestones = {
  A: {
    image: AIcon
  },
  B: {
    image: BIcon
  },
  C: {
    image: CIcon
  }
};

class LeftSideBox extends React.PureComponent {
  getCompletion(group) {
    return Math.round(
      ((group.earned.length + group.progress.length * 0.5) /
        group.milestones.length) *
        100
    );
  }

  render() {
    return (
      <Scrollbars
        style={{
          height: "calc(100vh - 55px)",
          maxHeight: "calc(100vh - 55px)"
        }}
        className="leftsidebox"
      >
        <div className="p-4">
          <h1 className="mt-1">Milestones</h1>
          <p>
            This is where you can track all your milestones! Complete the first
            badge to pre-launch your program! (receive 10000 points)
          </p>
          <div className="box1">
            {this.props.milestones.map((ms, i) => {
              return (
                <div
                  className="firstline"
                  key={ms.id}
                  onClick={() =>
                    i === 0
                      ? this.props.selectGroup(0)
                      : this.getCompletion(this.props.milestones[i - 1]) >= 70
                      ? this.props.selectGroup(i)
                      : null
                  }
                  style={{
                    cursor:
                      i !== 0 && this.getCompletion(ms) >= 70
                        ? "pointer"
                        : ms.name === "A"
                        ? "pointer"
                        : ""
                  }}
                >
                  <div className="row">
                    <div className="col-md-4 col-sm-12 d-flex">
                      <img
                        src={milestones[ms.name].image}
                        alt=""
                        style={{
                          display: "block",
                          margin: "auto",
                          maxWidth: "100%"
                        }}
                      />
                    </div>
                    <div
                      className="col-md-8 col-sm-12 d-flex"
                      style={{ flexWrap: "wrap" }}
                    >
                      {i !== 0 &&
                      this.getCompletion(this.props.milestones[i - 1]) < 70 ? (
                        <img src={Lock} alt="" style={{ margin: "auto" }} />
                      ) : (
                        <>
                          <div className="ssline">
                            <div
                              className="completed"
                              style={{
                                width: this.getCompletion(ms) + "%"
                              }}
                            />
                          </div>
                          <p>{this.getCompletion(ms)} %</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="box2 mb-0">
            <h3>LEGEND</h3>
            <p>
              <img src={MilestoneCheck} className="check" alt="" />
              <span>In Progress</span>{" "}
              <img src={IconG} className="aboutit" alt="" />
            </p>
            <p>
              <img src={MilestoneCheck2} className="check" alt="" />
              <span>Complete</span>{" "}
              <img src={IconG} className="aboutit" alt="" />
            </p>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

export default LeftSideBox;
