import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";

import { ModalTemplate } from "../UI/modalComponents";

import {
  getProductName,
  getSubtotal,
  getPlusMinus
} from "../../utilities/reportsAnalytics";

// const timeRanges = [
//   { name: "Select", value: -1 },
//   { name: "5:00 AM", value: 5 },
//   { name: "5:30 AM", value: 5.5 },
//   { name: "6:00 AM", value: 6 },
//   { name: "6:30 AM", value: 6.5 },
//   { name: "7:00 AM", value: 7 },
//   { name: "7:30 AM", value: 7.5 },
//   { name: "8:00 AM", value: 8 },
//   { name: "8:30 AM", value: 8.5 },
//   { name: "9:00 AM", value: 9 },
//   { name: "9:30 AM", value: 9.5 },
//   { name: "10:00 AM", value: 10 },
//   { name: "10:30 AM", value: 10.5 },
//   { name: "11:00 AM", value: 11 },
//   { name: "11:30 AM", value: 11.5 },
//   { name: "12:00 PM", value: 12 },
//   { name: "12:30 PM", value: 12.5 },
//   { name: "1:00 PM", value: 13 },
//   { name: "1:30 PM", value: 13.5 },
//   { name: "2:00 PM", value: 14 },
//   { name: "2:30 PM", value: 14.5 },
//   { name: "3:00 PM", value: 15 },
//   { name: "3:30 PM", value: 15.5 },
//   { name: "4:00 PM", value: 16 },
//   { name: "4:30 PM", value: 16.5 },
//   { name: "5:00 PM", value: 17 },
//   { name: "5:30 PM", value: 17.5 },
//   { name: "6:00 PM", value: 18 },
//   { name: "6:30 PM", value: 18.5 },
//   { name: "7:00 PM", value: 19 },
//   { name: "7:30 PM", value: 19.5 },
//   { name: "8:00 PM", value: 20 },
//   { name: "8:30 PM", value: 20.5 },
//   { name: "9:00 PM", value: 21 },
//   { name: "9:30 PM", value: 21.5 },
//   { name: "10:00 PM", value: 22 },
//   { name: "10:30 PM", value: 22.5 },
//   { name: "11:00 PM", value: 23 }
// ];
class TotalEarningsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const item = this.props.selectedItem;
    const itemInfo = getProductName(item);
    console.log("item", item);

    let stripeTotal;
    let otherTotal;
    if (item.stripe && item.stripe.total) {
      stripeTotal =
        item.stripe.total === 0
          ? "0.00"
          : parseFloat(item.stripe.total / 100).toFixed(2);
    }
    if (item.stripe && item.stripe.amount) {
      stripeTotal = parseFloat(
        item.stripe.amount / 100 - item.stripe.amount_refunded / 100
      ).toFixed(2);
    }
    if (
      item.stripe?.charge?.amount_refunded &&
      item.stripe?.charge?.amount_refunded !== 0
    ) {
      stripeTotal =
        stripeTotal - parseFloat(item.stripe?.charge?.amount_refunded / 100);
    }
    if (!item.stripe && item.type !== "Installment") {
      otherTotal = parseFloat(itemInfo.price).toFixed(2);
    }
    if (item.type === "Installment") {
      otherTotal = parseFloat(itemInfo.price + itemInfo.taxAmount).toFixed(2);
    }
    if (item.product.transaction_cost) {
      const tranCost = parseFloat(itemInfo.price * 0.015);
      otherTotal = parseFloat(parseInt(itemInfo.price) + tranCost).toFixed(2);
    }

    // Coupon Applied
    if (item.manual && item.coupon) {
      const couponDiscount =
        item.coupon.type === "Percentage"
          ? parseFloat(
              itemInfo.price * (parseFloat(item.coupon.amount_off) / 100)
            )
          : parseFloat(parseFloat(item.coupon.amount_off));

      otherTotal = parseFloat(parseFloat(otherTotal) - couponDiscount).toFixed(
        2
      );
    }

    // Tax Added for Manual
    if (item.manual && itemInfo.isTaxEnabled) {
      const taxAdded = parseFloat(itemInfo.taxAmount);

      otherTotal = parseFloat(parseFloat(otherTotal) + taxAdded).toFixed(2);
    }

    // if(!item.manual && item.coupon){
    //   const couponDiscount = item.coupon.type === "Percentage"
    //   ? parseFloat(
    //       itemInfo.price *
    //         (parseFloat(
    //           item.coupon.amount_off
    //         ) /
    //           100)
    //     )
    //   : parseFloat(
    //       parseFloat(
    //         item.coupon.amount_off
    //       ) / 100
    //     )

    //   stripeTotal = parseFloat(parseFloat(stripeTotal) + parseFloat(item.amount_off)).toFixed(2)
    // }

    const subTotal = getSubtotal(item);
    let promoCreditFee;
    if (item.promotionId && item.promotion.discount_type === "Percentage") {
      const discount = parseInt(
        itemInfo.price * (item.promotion.discount_percent / 100)
      );
      promoCreditFee = parseFloat((itemInfo.price - discount) * 0.015).toFixed(
        2
      );
    }

    // Each element in priceModifiers Array has "label" and "text" property.
    // Consists of promo, coupon, tax, and CC fee information.
    let priceModifiers = getPlusMinus(item);

    let promo;
    if (priceModifiers[0] && priceModifiers[0].label === "Promo") {
      promo = priceModifiers[0];
      priceModifiers = priceModifiers.slice(1);
    }
    return (
      <ModalTemplate heading="View Details" onClose={_ => this.props.onClose()}>
        <form>
          <div className="modal-main">
            {item.customer_name && (
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{
                    width: "65%",
                    textAlign: "left",
                    textDecoration: "underline"
                  }}
                >
                  Purchaser: {item.customer_name}
                </label>
              </div>
            )}
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                htmlFor="displayName"
                style={{ width: "65%", textAlign: "left" }}
              >
                Item ({itemInfo.name})
              </label>
              <span
                style={{
                  width: "35%",
                  color: "black",
                  fontSize: "16px",
                  textAlign: "right"
                }}
              >
                ${parseFloat(itemInfo.price).toFixed(2)}
              </span>
            </div>

            {item.isInstallment && (
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "65%", textAlign: "left" }}
                >
                  Installment
                </label>
                <span
                  style={{
                    width: "35%",
                    color: "black",
                    fontSize: "16px",
                    textAlign: "right"
                  }}
                >
                  Payment {item.installmentIndex} of {item.no_payments}
                </span>
              </div>
            )}

            {/* Promotions  */}
            {promo && (
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "65%", textAlign: "left" }}
                >
                  {promo.label}
                </label>
                <span
                  style={{
                    width: "35%",
                    color: "black",
                    fontSize: "16px",
                    textAlign: "right"
                  }}
                >
                  {promo.text}
                </span>
              </div>
            )}

            {/* Subtotal */}
            {!item.isDeposit && (
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "65%", textAlign: "left" }}
                >
                  Sub Total
                </label>
                <span
                  style={{
                    width: "35%",
                    color: "black",
                    fontSize: "16px",
                    textAlign: "right"
                  }}
                >
                  {subTotal}
                </span>
              </div>
            )}

            {/* Encompasses coupons, taxes, and credit card fees */}
            {priceModifiers.map(modifier => {
              return (
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                  key={modifier.label}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "65%", textAlign: "left" }}
                  >
                    {modifier.label}
                  </label>
                  <span
                    style={{
                      width: "35%",
                      color: "black",
                      fontSize: "16px",
                      textAlign: "right"
                    }}
                  >
                    {modifier.text}
                  </span>
                </div>
              );
            })}

            {/* Refunds */}
            {item.charge &&
              item.charge.amount_refunded !== 0 &&
              !item.isDeposit && (
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "65%", textAlign: "left" }}
                  >
                    Refunded
                  </label>
                  <span
                    style={{
                      width: "35%",
                      color: "black",
                      fontSize: "16px",
                      textAlign: "right"
                    }}
                  >
                    {item.charge.amount_refunded !== 0
                      ? `$${parseFloat(
                          item.charge.amount_refunded / 100
                        ).toFixed(2)}`
                      : ""}
                  </span>
                </div>
              )}

            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                htmlFor="displayName"
                style={{ width: "65%", textAlign: "left" }}
              >
                Total {!item.isDeposit ? "" : "(Deposit)"}
              </label>
              <span
                style={{
                  width: "35%",
                  color: "black",
                  fontSize: "16px",
                  textAlign: "right"
                }}
              >
                ${item.stripe ? stripeTotal : otherTotal}
              </span>
            </div>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(TotalEarningsModal);
