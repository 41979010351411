import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalBanner, Envelope } from "../../assets";

class SendInviteModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async inviteUser(e) {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/partners/users/resend-invite`;
    const res = await axios.post(ep, {
      id: this.props.selectedUser.id
    });

    if (res.data.success) {
      this.props.user();
      toast.success("Invite sent");
      this.props.onClose();
    } else {
      toast.error(res.data.message);
    }
    this.props.onClose();
  }
  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding" style={{ display: "block" }}>
          <img
            src={ModalBanner}
            alt="Modal Banner"
            style={{
              height: "40px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "78px"
              }}
            >
              <img
                src={Envelope}
                alt="Modal Banner"
                style={{
                  height: "50px",
                  width: "50px",
                  borderRadius: "50%"
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "78px"
              }}
            >
              <div
                style={{
                  color: "#817f7f",
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: 700,
                  textAlign: "center",
                  fontSize: "22px"
                }}
              >
                Are you sure you want to re-send invite?
              </div>
            </div>
            <form onSubmit={e => this.inviteUser(e)}>
              <div className="modal-footer">
                <div style={{ display: "block" }}>
                  <button type="submit" className="btn profile-btn">
                    Yes, Invite
                  </button>
                  <div
                    onClick={_ => this.props.onClose()}
                    style={{
                      textAlign: "center",
                      fontSize: "15px",
                      marginTop: "10px",
                      cursor: "pointer"
                    }}
                  >
                    Skip for now
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SendInviteModal);
