import React from "reactn";
import Sidebar from "../common/Sidebar";
import TopBar from "../common/TopBar";
import SideMenu from "./SideMenu";
import AdminRoute from "../../routes/adminRoute";
import Promotions from "../../pages/admin/promotions";
import FamilyProfiles from "../../pages/admin/familyProfiles";
import ProgramSettings from "../../pages/admin/programSettings";
import Calendar from "../../pages/shared/calendar";
import Messaging from "../../pages/shared/messaging/messaging";
import Dashboard from "../../pages/admin/dashboard";
import PreScreening from "../../pages/admin/prescreening";
import InterestedPrograms from "../../pages/admin/interestedPrograms";
import EducatorApplications from "../../pages/admin/educatorApplications";
import DSFees from "../../pages/admin/dsFees";
import EducatorPayouts from "../../pages/admin/educatorPayouts";
import ActiveEnrollments from "../../pages/admin/activeEnrollments";
import FamilyApplications from "../../pages/admin/familyApplications";
import FinancesOverview from "../../pages/admin/financesOverview";
import Invoices from "../../pages/admin/invoices";
import Credits from "../../pages/admin/credits";
import MyApplicationPage from "../../pages/educators/myApplication/myApplication";
import FamilyReg from "../../pages/admin/familyRegistration";
import bookedTours from "../../pages/admin/bookedTours";
import PlanRequests from "../../pages/admin/PlanRequests";
import ProgramSetupPage from "../../pages/educators/programSetup/programSetup";
import Milestones from "../../pages/educators/milestones";
import PricingPlans from "../../pages/educators/pricingPlans";
import ProgramOperations from "../../pages/admin/programOperations";
import ViewProgram from "../../pages/admin/programOperations/viewProgram";
import Documents from "../../pages/admin/documents";
import autoFollow from "../../pages/admin/autofollow";
import SearchPhone from "../../pages/admin/searchPhone";
import ApplicationView from "../../pages/parents/applicationView";
import ParentTuition from "../../pages/parents/tuitionPayments";
import PartnerLeads from "../../pages/admin/partnerLeads";
const AdminRoutes = [
  {
    path: "/admin",
    component: Dashboard
  },
  {
    path: "/admin/partner-leads",
    component: PartnerLeads
  },
  {
    path: "/admin/calendar",
    component: Calendar
  },
  {
    path: "/admin/messages",
    component: Messaging
  },
  {
    path: "/admin/promotions",
    component: Promotions
  },
  {
    path: "/admin/family-profiles",
    component: FamilyProfiles
  },
  {
    path: "/admin/program-settings",
    component: ProgramSettings
  },
  {
    path: "/admin/parent-tuition/:id",
    component: ParentTuition
  },
  {
    path: "/admin/pre-screening",
    component: PreScreening
  },
  {
    path: "/admin/interested-programs",
    component: InterestedPrograms
  },
  {
    path: "/admin/educator-applications",
    component: EducatorApplications
  },
  {
    path: "/admin/dsfees",
    component: DSFees
  },
  {
    path: "/admin/educator-payouts",
    component: EducatorPayouts
  },
  {
    path: "/admin/active-enrollments",
    component: ActiveEnrollments
  },
  {
    path: "/admin/applications/:slug/:time",
    component: ApplicationView
  },
  {
    path: "/admin/family-applications",
    component: FamilyApplications
  },
  {
    path: "/admin/auto-followups",
    component: autoFollow
  },
  {
    path: "/admin/finances-overview",
    component: FinancesOverview
  },
  {
    path: "/admin/invoices",
    component: Invoices
  },
  {
    path: "/admin/search-phone",
    component: SearchPhone
  },
  {
    path: "/admin/credits",
    component: Credits
  },
  {
    path: "/admin/educator-applications/view/:id",
    component: MyApplicationPage
  },
  {
    path: "/admin/program-settings/setup/:id",
    component: ProgramSetupPage
  },
  {
    path: "/admin/program-settings/plans/:id",
    component: PricingPlans
  },
  {
    path: "/admin/program-settings/milestones/:id",
    component: Milestones
  },
  {
    path: "/admin/program-operations",
    component: ProgramOperations
  },
  {
    path: "/admin/program-operations/:id",
    component: ViewProgram
  },
  {
    path: "/admin/resources",
    component: Documents
  },
  {
    path: "/admin/family-registration",
    component: FamilyReg
  },
  {
    path: "/admin/booked-tours",
    component: bookedTours
  },
  {
    path: "/admin/plan-requests",
    component: PlanRequests
  }
];
const teamRoutes =
  "/admin/messages,/admin/family-profiles,/admin/active-enrollments,/admin/family-applications,/admin/auto-followups,/admin/family-registration,/admin/booked-tours,/admin/plan-requests";
class AdminLayout extends React.PureComponent {
  async componentDidMount() {
    if (localStorage.getItem("ds_token") && !this.global.dsUser) {
      await this.dispatch.validateToken();
      if (!this.global.dsUser || this.global.dsUser.accountType !== "Admin") {
        this.props.history.push("/");
      }
      if (this.global.dsUser.displayName === "Dreamschools Community") {
        if (!teamRoutes.split(",").includes(this.props.location.pathname))
          this.props.history.push("/admin");
      }
    } else if (!localStorage.getItem("ds_token") && !this.global.dsUser) {
      this.props.history.push(
        `/sign-in?follow=${this.props.location.pathname}`
      );
    }
  }

  render() {
    const { path } = this.props.match;
    return (
      <>
        {this.global.dsUser ? (
          <>
            <Sidebar path={path} Menu={SideMenu} />
            <div
              id="content-wrapper"
              className="d-flex flex-column"
              onClick={() => this.setGlobal({ sidenavShown: false })}
            >
              <div id="content">
                <TopBar ConsoleRoute="/admin" />
                {AdminRoutes.map((route, key) => (
                  <AdminRoute
                    key={key}
                    admin={true}
                    exact
                    path={route.path}
                    component={route.component}
                  />
                ))}
              </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
              <i className="fas fa-angle-up"></i>
            </a>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default AdminLayout;
