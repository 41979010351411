import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { toast } from "react-toastify";
// import * as dateFns from "date-fns";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { validateForm } from "../../lib/util";
class RescheduleModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      requestedDate: "",
      requestedTime: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  rescheduleThis = async ev => {
    ev.preventDefault();
    if (validateForm(this.state, ev.target)) {
      if (!this.state.requestedDate) {
        ev.target["requestedDate"].style.border = "1px solid red";
        return;
      }
      const date = new Date(this.state.requestedDate).setHours(
        this.state.requestedTime
      );
      const ep = this.props.admin
        ? `${process.env.REACT_APP_API}/adm/booked/reschedule`
        : `${process.env.REACT_APP_API}/par/tour/reschedule`;
      if (this.props.tour) {
        const result = await axios.post(ep, {
          tour: this.props.tour,
          requestedTourDate: date
        });
        if (result.data.success) {
          this.props.tour.accepted = result.data.data.accepted;
          this.props.tour.requestedTourDate =
            result.data.data.requestedTourDate;
          this.props.tour.rescheduledBy = result.data.data.rescheduledBy;
          this.props.onClose();
        }
      }
    }
  };

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal">
          <div>
            <h3>Reschedule</h3>
            <form onSubmit={this.rescheduleThis}>
              <label htmlFor="requestedDate">Date Requested *</label>
              <DatePicker
                selected={this.state.requestedDate}
                minDate={new Date()}
                maxDate={new Date(Date.now() + 14 * 24 * 60 * 60 * 1000)}
                onChange={date => this.setState({ requestedDate: date })}
                dateFormat="MMMM d, yyyy"
                className="w-100 form-control"
                placeholderText="Date Requested"
                name="requestedDate"
                id="requestedDate"
                validation="isNotEmpty"
              />

              <label htmlFor="requestedTime">Requested Time *</label>
              <select
                name="requestedTime"
                id="requestedTime"
                className="w-100 form-control"
                onChange={e =>
                  this.setState({ [e.target.name]: e.target.value })
                }
                validation="isNotEmpty"
              >
                <option value="">Please select a time...</option>
                <option value="6">6:00 am</option>
                <option value="7">7:00 am</option>
                <option value="8">8:00 am</option>
                <option value="9">9:00 am</option>
                <option value="10">10:00 am</option>
                <option value="11">11:00 am</option>
                <option value="12">12:00 pm</option>
                <option value="13">1:00 pm</option>
                <option value="14">2:00 pm</option>
                <option value="15">3:00 pm</option>
                <option value="16">4:00 pm</option>
                <option value="17">5:00 pm</option>
                <option value="18">6:00 pm</option>
                <option value="19">7:00 pm</option>
              </select>
              <div className="modal-footer">
                <button type="submit" className="btn profile-btn">
                  Reschedule !
                </button>
                <button
                  onClick={this.props.onClose}
                  className="mx-2 backbtn btn profile-btn"
                >
                  Close
                </button>
              </div>
            </form>

            <IconButton
              style={{ position: "absolute", right: "0", top: "0" }}
              onClick={_ => this.props.onClose()}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RescheduleModal);
