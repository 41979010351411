import { setGlobal } from "reactn";

const virtualCall = {
  room_url: null,
  meeting_token: null,
  isCallFrameCreated: false
};
setGlobal({
  virtualCall: virtualCall
});
