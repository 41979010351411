import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";

class SectionEight extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { days: {} };
  }
  handleChange = (e, v) => {
    this.setGlobal({
      organizationInfo: {
        ...this.global.organizationInfo,
        [e]: v
      }
    });
  };

  customForm = () => {
    return (
      <div className="formbody">
        <form>
          <h4 style={{ padding: "12px 20px" }}>
            Order numbers start at 101 by default. While you can't change the
            number itself, you can add a prefix or suffix to create custom IDs
            like "LC101" or "101-AD"
          </h4>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-5">
              <h3 style={{ margin: 0, padding: 0 }}>Prefix</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  name="order_prefix"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.order_prefix}
                />
              </div>
            </div>
          </div>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-5">
              <h3 style={{ margin: 0, padding: 0 }}>Suffix</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  name="order_suffix"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.order_suffix}
                />
              </div>
            </div>
          </div>
          <p style={{ margin: "15px 20px" }}>
            Your order ID will appear as{" "}
            {`${this.global.organizationInfo.order_prefix}101${this.global.organizationInfo.order_suffix}`}
            ,{" "}
            {` ${this.global.organizationInfo.order_prefix}102${this.global.organizationInfo.order_suffix}`}
            ,
            {` ${this.global.organizationInfo.order_prefix}103${this.global.organizationInfo.order_suffix}`}
            ...
          </p>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Customize your order id"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionEight;
