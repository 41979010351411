import ApplicationsTable from "./ApplicationsTable";
import TuitionPaymentTable from "./TuitionPaymentTable";
import TuitionPaymentOverview from "./TuitionPaymentOverview";

const Parents = {
  ApplicationsTable: ApplicationsTable,
  TuitionPaymentTable: TuitionPaymentTable,
  TuitionPaymentOverview: TuitionPaymentOverview
};

export default Parents;
