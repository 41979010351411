import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { addWeeks, format, subMonths, subWeeks } from "date-fns";
import { addDays } from "date-fns/esm";
import { toast } from "react-toastify";
import _ from "lodash";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import "../../assets/css/componentSpecificCss/addSingleSessionPlanModal.css";
import ReactTooltip from "react-tooltip";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  IMAGE_DROPDOWN,
  SWITCH,
  DROPDOWN
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class AddSingleSessionPlanModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      total_price: null,
      isTaxEnabled: false,
      taxInfo: [],
      isTimeEnabled: this.global.program.program_details !== "Classes",
      isDateSelectionEnabled: false,
      description: "",
      duration_type: null,
      frequency: null,
      duration: null,
      quantity: 1,
      days_per_week: null
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async savePlan() {
    if (!this.global.organizationInfo.stripeId) {
      toast.error("Denied. Please connect your Bank Account first");
      return;
    }
    if (!this.state.total_price || this.state.total_price <= 0) {
      toast.error("Please enter a valid price.");
      return;
    }
    if (this.state.isTaxEnabled && this.state.taxInfo.length == 0) {
      toast.error("Please select a tax plan, otherwise disable taxes");
      return;
    }
    if (
      parseFloat(this.state.quantity) >= 1 &&
      parseFloat(this.state.quantity) >= 50
    ) {
      toast.error(
        "Please enter a value between the range of 1-50 in quantity."
      );
      return;
    }
    if (this.state.duration_type === null || this.state.frequency === null) {
      toast.error("Please select a type and frequency");
      return;
    }
    if (this.state.frequency === "min" && this.state.duration === null) {
      toast.error("Please select a duration value");
      return;
    }

    const obj = { ...this.state, type: "Single Sessions" };
    if (!this.state.duration) {
      delete obj.duration;
    }
    obj.total_price = parseFloat(obj.total_price).toFixed(2);

    const ep = `${process.env.REACT_APP_API}/partners/stripe/add_products`;
    try {
      const res = await axios.post(ep, {
        ...obj,
        parent: "Program",
        name: this.global.program.program_name,
        type: "Single Sessions",
        product: this.global.program.product_stripe
      });
      this.setGlobal(
        {
          program: {
            ...this.global.program,
            product_stripe: res.data.data.product,
            plans: [
              ...this.global.program.plans,
              {
                ...obj,
                id: this.global.program.plans.length,
                prodId: res.data.data.id
              }
            ]
          }
        },
        () => {
          this.dispatch.saveProgram();
        }
      );
      this.props.onClose();
    } catch {
      toast.error("Could not add pricing plan.");
    }
  }

  /// ================== NEW MODAL ========================== ///

  taxesOptions = () => {
    //const global_organization = [JSON.parse(this.global.organizationInfo.stripeTaxId)]
    const global_organization = this.global.organizationInfo.stripeTaxId;
    console.log("global_organization", global_organization);
    const DATA = [];
    for (let i = 0; i < global_organization.length; i++) {
      DATA.push(JSON.parse(global_organization[i]));
    }
    console.log("DATA", DATA);

    return [
      ...DATA.map((e, i) => ({
        value: i,
        text: e.display_name + " " + e.jurisdiction + e.percentage + "%"
      }))
    ];
  };

  typeOptions = () => {
    if (this.global.program.program_details === "Classes") {
      return [{ value: "Class", text: "Class" }];
    } else {
      return [
        { value: "Lesson", text: "Lesson" },
        { value: "Session", text: "Session" }
      ];
    }
  };

  frequencyOptions = () => {
    if (this.state.duration_type === null) {
      return [];
    } else if (this.state.duration_type === "Class") {
      return [
        { value: "day", text: "Daily" },
        { value: "week", text: "Weekly" }
      ];
    } else {
      return [
        { value: "minutes", text: "Minutes" },
        { value: "hour", text: "Hourly" }
      ];
    }
  };

  minutesOptions = () => {
    return [
      { value: "30", text: "30 Minutes" },
      { value: "45", text: "45 Minutes" },
      { value: "60", text: "60 Minutes" },
      { value: "90", text: "90 Minutes" },
      { value: "120", text: "120 Minutes" },
      { value: "150", text: "150 Minutes" }
    ];
  };

  daysOptions = () => {
    return Object.keys(this.global.program.days_of_week).map((e, i) => ({
      value: i + 1,
      text: i + 1
    }));
  };

  getBaseModalFields() {
    const typeInput = {
      type: DROPDOWN,
      data: {
        name: "Type",
        required: false,
        placeholder: "Please Select",
        value: this.state.duration_type,
        handleChange: e => {
          this.setState({
            duration_type: e.target.value
          });
        },
        choices: this.typeOptions()
      }
    };
    console.log("this.typeOptions()", this.typeOptions());
    console.log("duration_type", this.state.duration_type);
    const unitamountInput = {
      type: STRING_INPUT,
      data: {
        name: "Unit Amount",
        required: false,
        type: "number",
        info: "The base price of the plan",
        handleChange: e => {
          let price = parseFloat(e.target.value || 0);
          let decimalValue = e.target.value.split(".")[1];

          if (decimalValue && decimalValue.length > 2) {
            return;
          } else if (!e.target.value.endsWith("0")) {
            price = Math.round(price * 100) / 100;
          } else {
            price = e.target.value;
          }

          this.setState({
            total_price: price.toString()
          });
        },
        value: this.state.total_price
      }
    };

    const frequencyInput = {
      type: DROPDOWN,
      data: {
        name: "",
        required: false,
        placeholder: "Please Select",
        value: this.state.frequency,
        handleChange: e => {
          this.setState({
            frequency: e.target.value
          });
        },
        choices: this.frequencyOptions()
      }
    };

    const minutesInput = {
      type: DROPDOWN,
      data: {
        name: "Select Minutes",
        required: false,
        placeholder: "Please Select",
        value: this.state.duration,
        handleChange: e => {
          this.setState({
            duration: e.target.value
          });
        },
        choices: this.minutesOptions()
      }
    };

    const daysInput = {
      type: DROPDOWN,
      data: {
        name: "Select Days",
        required: false,
        placeholder: "Please Select",
        value: this.state.days_per_week,
        handleChange: e => {
          this.setState({
            days_per_week: e.target.value
          });
        },
        choices: this.daysOptions()
      }
    };

    const descriptionInput = {
      type: STRING_INPUT,
      data: {
        name: "Description",
        required: false,
        maxLength: 32,
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description
      }
    };

    const quanitylimitInput = {
      type: STRING_INPUT,
      data: {
        name: "Quantity Limit",
        required: false,
        type: "number",
        info: "Allows users to select the number of sessions/classes they would be allowed to purchase.",
        handleChange: e => {
          this.setState({
            quantity: e.target.value
          });
        },
        value: this.state.quantity
      }
    };

    const enabledateSelection = {
      type: SWITCH,
      data: {
        name: "Enable Date Selection",
        isDisabled: false,
        checked: this.state.isDateSelectionEnabled,
        handleClick: e =>
          this.setState({ isDateSelectionEnabled: e.target.checked })
      }
    };

    const enabletimeSelection = {
      type: SWITCH,
      data: {
        name: "Enable Time Selection",
        isDisabled: false,
        checked: this.state.isTimeEnabled,
        handleClick: e => this.setState({ isTimeEnabled: e.target.checked })
      }
    };

    const enableTaxes = {
      type: SWITCH,
      data: {
        name: "Enable Taxes",
        isDisabled: false,
        checked: this.state.isTaxEnabled,
        handleClick: e =>
          this.setState({
            isTaxEnabled: e.target.checked,
            taxInfo: []
          })
      }
    };

    const selectTaxes = {
      type: DROPDOWN,
      data: {
        name: "Tax Plan",
        required: false,
        placeholder: "Please Select" /* 
          value: this.state.taxInfo, */,
        handleChange: e => {
          const item = JSON.parse(
            this.global.organizationInfo.stripeTaxId[e.target.value]
          );
          this.setState({
            taxInfo: [
              {
                id: item.id,
                percentage: item.percentage,
                jurisdiction: item.jurisdiction,
                display_name: item.display_name,
                description: item.description
              }
            ]
          });
        },
        choices: this.taxesOptions()
      }
    };

    const fields = [];
    fields.push(typeInput);
    fields.push(unitamountInput);
    fields.push(frequencyInput);
    if (this.state.frequency === "minutes") {
      fields.push(minutesInput);
    } else if (this.state.frequency === "week") {
      fields.push(daysInput);
    }
    fields.push(descriptionInput);
    fields.push(quanitylimitInput);
    if (this.state.duration_type === "Class") {
      fields.push(enabledateSelection);
    } else if (
      this.state.duration_type === "Session" ||
      this.state.duration_type === "Lesson"
    ) {
      fields.push(enabletimeSelection);
    }
    fields.push(enableTaxes);
    if (this.state.isTaxEnabled) {
      fields.push(selectTaxes);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const debounceSavePlan = _.debounce(_ => this.savePlan(), 2000, {
      leading: true,
      trailing: false
    });
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => {
        e.preventDefault();
        debounceSavePlan();
      }
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Pricing Plan",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ========================== ///

  render() {
    const debounceSavePlan = _.debounce(_ => this.savePlan(), 2000, {
      leading: true,
      trailing: false
    });

    console.log("this.global.program", this.global.program);
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <ModalTemplate heading="Pricing Plan" onClose={_ => this.props.onClose()}>
        <form
          onSubmit={e => {
            e.preventDefault();
            debounceSavePlan();
          }}
        >
          <div className="modal-main">
            <ModalFields.select
              label="Type"
              name="duration_type"
              onChange={e => {
                this.setState({
                  duration_type: e.target.value
                });
              }}
              value={this.state.duration_type}
            >
              {this.global.program.program_details === "Classes" ? (
                <option value="Class">Class</option>
              ) : (
                <>
                  <option value="Lesson">Lesson</option>
                  <option value="Session">Session</option>
                </>
              )}
            </ModalFields.select>

            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                htmlFor="total_price"
                style={{
                  width: "25%",
                  textAlign: "left",
                  marginRight: "22px"
                }}
              >
                Unit Amount{" "}
                <i
                  data-tip
                  data-for="total_price"
                  className="fas fa-info-circle"
                  style={{ color: "#DCDCDC" }}
                ></i>
                <ReactTooltip
                  id="total_price"
                  place="right"
                  effect="solid"
                  type="info"
                >
                  The base price of the plan
                </ReactTooltip>
              </label>
              <div className="input-group" style={{ width: "30%" }}>
                <span className="input-group-addon">$</span>
                <input
                  name="total_price"
                  id="total_price"
                  type="number"
                  step="any"
                  className="form-control currency"
                  style={{ width: "20%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  disabled={this.state.price_per_day}
                  min={0}
                  value={this.state.total_price}
                />
              </div>

              <select
                name="frequency"
                id="frequency"
                type="text"
                className="form-control"
                style={{ width: "36%", marginLeft: "16px" }}
                onChange={e => {
                  this.setState({
                    [e.target.name]: e.target.value
                  });
                }}
              >
                <option value="">Please Select</option>
                {this.state.duration_type === null ? null : this.state
                    .duration_type === "Class" ? (
                  <>
                    <option value="day">Daily</option>
                    <option value="week">Weekly</option>
                    {/* <option value="month">Monthly</option> */}
                  </>
                ) : (
                  <>
                    <option value="minutes">Minutes</option>
                    <option value="hour">Hourly</option>
                  </>
                )}
              </select>
            </div>

            {/* <ModalFields.select
              label="Frequency"
              name="frequency"
              tooltip="Determines the frequency of the plan"
              onChange={e => {
                this.setState({
                  frequency: e.target.value
                });
              }}
              value={this.state.frequency}
            >
              {this.state.duration_type === null ? null 
              : this.state.duration_type === "Class" ? (
                <>
                <option value="day">Daily</option>
                <option value="week">Weekly</option>
                </>
              ) : (
                <>
                <option value="minutes">Minutes</option>
                <option value="hour">Hourly</option>
                </>
              )}
            </ModalFields.select> */}

            {this.state.frequency === "minutes" && (
              <ModalFields.select
                label="Duration"
                name="duration"
                onChange={e => {
                  this.setState({
                    duration: e.target.value
                  });
                }}
                value={this.state.duration}
              >
                <option value="30">30 Minutes</option>
                <option value="45">45 Minutes</option>
                <option value="60">60 Minutes</option>
                <option value="90">90 Minutes</option>
                <option value="120">120 Minutes</option>
                <option value="150">150 Minutes</option>
              </ModalFields.select>
            )}

            {this.state.frequency === "week" && (
              <ModalFields.select
                label="Days Per Week"
                name="days_per_week"
                onChange={e => {
                  this.setState({
                    days_per_week: e.target.value
                  });
                }}
              >
                {Object.keys(this.global.program.days_of_week).map((e, i) => {
                  return <option value={i + 1}>{i + 1}</option>;
                })}
              </ModalFields.select>
            )}

            <ModalFields.input
              label="Description"
              name="description"
              type="text"
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
              value={this.state.description}
            />

            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                htmlFor="price_day"
                style={{
                  width: "25%",
                  textAlign: "left",
                  marginRight: "22px"
                }}
              >
                Quantity Limit{" "}
                <i
                  data-tip
                  data-for="price_day"
                  className="fas fa-info-circle"
                  style={{ color: "#DCDCDC" }}
                ></i>
                <ReactTooltip
                  id="price_day"
                  place="right"
                  effect="solid"
                  type="info"
                >
                  Allows users to select the number of sessions/classes they
                  would be allowed to purchase.
                </ReactTooltip>
              </label>
              <input
                name="quantity"
                id="quantity"
                type="number"
                className="form-control"
                style={{ width: "20%" }}
                min={1}
                max={50}
                onChange={e => {
                  this.setState({
                    [e.target.name]: e.target.value
                  });
                }}
                value={this.state.quantity}
              />
            </div>

            {this.state.duration_type === "Class" && (
              <ModalFields.switch
                label="Enable Date Selection"
                name="date_selection"
                checked={this.state.isDateSelectionEnabled}
                onChange={e =>
                  this.setState({ isDateSelectionEnabled: e.target.checked })
                }
              />
            )}

            {(this.state.duration_type === "Session" ||
              this.state.duration_type === "Lesson") && (
              <>
                <ModalFields.switch
                  label="Enable Time Selection"
                  name="time_selection"
                  checked={this.state.isTimeEnabled}
                  onChange={e =>
                    this.setState({ isTimeEnabled: e.target.checked })
                  }
                />
              </>
            )}

            <ModalFields.switch
              label="Enable Taxes"
              name="taxes"
              checked={this.state.isTaxEnabled}
              onChange={e =>
                this.setState({
                  isTaxEnabled: e.target.checked,
                  taxInfo: []
                })
              }
            />

            {this.state.isTaxEnabled && (
              <ModalFields.select
                label="Tax Plan"
                name="taxInfo"
                onChange={e => {
                  const item = JSON.parse(
                    this.global.organizationInfo.stripeTaxId[e.target.value]
                  );
                  this.setState({
                    [e.target.name]: [
                      {
                        id: item.id,
                        percentage: item.percentage,
                        jurisdiction: item.jurisdiction,
                        display_name: item.display_name,
                        description: item.description
                      }
                    ]
                  });
                }}
              >
                {this.global.organizationInfo.stripeTaxId.map((ele, i) => {
                  const e = typeof ele === "string" ? JSON.parse(ele) : ele;
                  return (
                    <>
                      <option value={i}>
                        {e.display_name} {e.jurisdiction} {e.percentage}%
                      </option>
                    </>
                  );
                })}
              </ModalFields.select>
            )}
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn">
              Submit
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(AddSingleSessionPlanModal);
