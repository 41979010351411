import React from "react";

const FamilyOverviewTable = ({ counts }) => {
  return (
    <div className="money-box mt-0">
      <div className="row">
        <div className="col-lg-12 col-sm-6">
          <div className="money-box-inner">
            <h2>TOTAL INVOICES</h2>
            <h3>{counts.total || 0}</h3>
          </div>
        </div>
        <div className="col-lg-12 col-sm-6">
          <div className="money-box-inner">
            <h2>PAID INVOICES</h2>
            <h3>{counts.paid || 0}</h3>
          </div>
        </div>
        <div className="col-lg-12 col-sm-6">
          <div className="money-box-inner">
            <h2>PENDING INVOICES</h2>
            <h3>{counts.upcoming || 0}</h3>
          </div>
        </div>
        <div className="col-lg-12 col-sm-6">
          <div className="money-box-inner">
            <h2>OVERDUE INVOICES</h2>
            <h3>{counts.overdue || 0}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FamilyOverviewTable;
