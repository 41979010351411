import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK, TITLE } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { isAfter, addHours, format } from "date-fns";

class MilestoneEventLogDetailsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      milestoneSubmissionInfo: null
    };
  }

  async componentDidMount() {
    this.setGlobal({ loading: true });

    const ep = `${process.env.REACT_APP_API}/partners/milestones_submissions/get/one/${this.props.eventInfo.milestoneSubmissionId}`;
    const res = await axios.get(ep);

    this.setState({ milestoneSubmissionInfo: res.data.data });
    this.setGlobal({ loading: false });
  }

  getBaseModalFields = () => {
    const fields = [];
    const eventInfo = this.props.eventInfo;

    if (
      eventInfo.event_type === "Milestone" &&
      eventInfo.event_description?.startsWith("Journal has been submitted")
    ) {
      const journalSubmissionField = {
        type: CONTENT_BLOCK,
        data: {
          name: "Submitted Journal Entry",
          content: eventInfo ? eventInfo.journal : ""
        }
      };

      fields.push(journalSubmissionField);
    }

    if (
      eventInfo.event_type === "Milestone" &&
      eventInfo.event_description?.startsWith("Journal has been edited")
    ) {
      const journalEditOldField = {
        type: CONTENT_BLOCK,
        data: {
          name: "Previous Journal Entry",
          content: eventInfo.old_journal ? eventInfo.old_journal : ""
        }
      };

      const journalEditNewField = {
        type: CONTENT_BLOCK,
        data: {
          name: "New Journal Entry",
          content: eventInfo ? eventInfo.journal : ""
        }
      };

      fields.push(journalEditOldField);
      fields.push(journalEditNewField);
    }

    if (
      eventInfo.event_type === "Milestone" &&
      eventInfo.event_description?.startsWith("A file has been removed")
    ) {
      const fileRemovedField = {
        type: CONTENT_BLOCK,
        data: {
          name: "File Removed",
          content: eventInfo ? eventInfo.file_update[0]?.file_name : ""
        }
      };

      fields.push(fileRemovedField);
    }

    if (
      eventInfo.event_type === "Milestone" &&
      eventInfo.event_description?.endsWith("files has been uploaded")
    ) {
      const concatenatedFileNames = eventInfo?.file_update
        .map(item => item.file_name)
        .join(", ");

      const fileUploadField = {
        type: CONTENT_BLOCK,
        data: {
          name: "Files Uploaded",
          content: concatenatedFileNames
        }
      };

      fields.push(fileUploadField);
    }

    const displayTime = new Date(eventInfo.timestamp);
    const formattedTime = displayTime.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: this.global.organizationInfo.timezone
    });

    const timeOfEventField = {
      type: CONTENT_BLOCK,
      data: {
        name: "",
        content: formattedTime,
        textStyles: {
          fontStyle: "italic",
          textAlign: "center",
          fontSize: "14px"
        }
      }
    };

    fields.push(timeOfEventField);

    return fields;
  };

  getBaseModalButtons = () => {
    const buttons = [];

    const closeButton = {
      name: "Close",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      handleClick: this.props.onClose
    };

    buttons.push(closeButton);
    return buttons;
  };

  getBaseModalProps = () => {
    return {
      title: "Event Log Details",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: () => this.props.onClose()
    };
  };

  render() {
    console.log("this.state123", this.state);
    console.log("this.props123", this.props);
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(MilestoneEventLogDetailsModal);
