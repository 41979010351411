import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { BLUE_BUTTON, RED_BUTTON, WHITE } from "./baseModal/colours";
import {
  CONTENT_BLOCK,
  DATE_PICKER,
  DROPDOWN,
  GET_HTML
} from "./baseModal/FieldTypes";
import BaseModal from "./baseModal/BaseModal";

const EditBillingCycleModal = ({ program, onClose }) => {
  const [billingCycle, setBillingCycle] = React.useState("");

  const handleSubmit = async () => {
    if (billingCycle === "") {
      toast.error("Please select a billing cycle");
      return;
    }

    const stripeSubscription = program.stripeSubscription;
    const data = {
      programId: program.id,
      partnerId: program.partnerId,
      billingCycle,
      subscriptionId: stripeSubscription?.id,
      orderId: program.manualOrder?.id
    }

    let ep;
    if (stripeSubscription) {
      // Change billing cycle for autopay program
      ep = `${process.env.REACT_APP_API}/partners/programs/edit-billing-cycle-autopay`;
    } else {
      // Change billing cycle for non-autopay program
      ep = `${process.env.REACT_APP_API}/partners/programs/edit-billing-cycle`;
    }

    const res = await axios.post(ep, data);
    if (res.data.success) {
      toast.success("Billing cycle updated");
      onClose();
    } else {
      toast.error("Error updating billing cycle");
    }
  };

  const modalFields = [
    {
      type: DROPDOWN,
      data: {
        name: "Billing Cycle Anchor",
        value: billingCycle,
        handleChange: (e) => setBillingCycle(e.target.value),
        placeholder: "Please select a billing cycle anchor",
        choices: [
          {
            value: "month-start",
            text: "Beginning of Month"
          },
          // {
          //   value: "month-mid",
          //   text: "Middle of Month"
          // },
          {
            value: "month-end",
            text: "End of Month"
          }
        ]
      }
    },
    {
      type: CONTENT_BLOCK,
      data: {
        content: "Warning: Any changes to an upcoming invoice will move the due date into the future. We recommend adding a prorated charge to the upcoming invoice to collect any remaining balance."
      }
    }
  ];

  const modalButtons = [
    {
      name: "Update",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      handleClick: handleSubmit
    },
    {
      name: "Cancel",
      buttonColour: RED_BUTTON,
      textColour: WHITE,
      handleClick: onClose
    }
  ];

  return (
    <BaseModal
      title="Edit Billing Cycle"
      fields={modalFields}
      buttons={modalButtons}
      handleClose={onClose}
      height="65vh"
      midSectionHeight="49vh"
    />
  );
};

export default withRouter(EditBillingCycleModal);