import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";

import {
  CONTENT_BLOCK,
  BUTTON,
  TEXT_AREA,
  IMAGE_CONTENT,
  DROPDOWN,
  DATE_PICKER,
  GET_HTML
} from "./baseModal/FieldTypes";
import { Close as ClearIcon } from "@material-ui/icons";
import BaseModal from "./baseModal/BaseModal";
import {
  BLUE_BUTTON,
  GREEN_BUTTON,
  RED_BUTTON,
  WHITE,
  YELLOW_BUTTON
} from "./baseModal/colours";

class FranchiseViewModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listingsCount: 0,
      connectionsCount: 0,
      enrollmentsCount: 0,
      totalEarnings: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/franchise/view/${this.props.selectedPartnerId}`;
    const results = await axios.get(ep);

    if (results.data.success) {
      this.setState({
        listingsCount: results.data.data.listingsCount,
        connectionsCount: results.data.data.connectionsCount,
        enrollmentsCount: results.data.data.enrollmentsCount,
        totalEarnings: parseFloat(results.data.data.totalEarnings / 100).toFixed(2)
      });
    }
  }

  getBaseModalFields() {
    const listingNumber = {
      type: CONTENT_BLOCK,
      data: { name: "Number of Listings:", content: `${this.state.listingsCount}` }
    }

    const connectionNumber = {
      type: CONTENT_BLOCK,
      data: { name: "Number of Connections:", content: `${this.state.connectionsCount}` }
    }

    const enrollmentNumber = {
      type: CONTENT_BLOCK,
      data: { name: "Number of Enrollments:", content: `${this.state.enrollmentsCount}` }
    }

    const totalEarnings = {
      type: CONTENT_BLOCK,
      data: { name: "Total Earnings:", content: `$${this.state.totalEarnings}` }
    }

    return [listingNumber, connectionNumber, enrollmentNumber, totalEarnings]
  }

  getBaseModalButtons() {
    const closeButton = {
      name: "Close",
      buttonClass: "client-settings-button",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      handleClick: e => this.props.onClose()
    };

    return [closeButton];
  }

  getBaseModalProps() {
    const baseModalProps = {
      title: "Franchise View Details",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };

    return baseModalProps;
  }

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px",
                marginBottom: ".5rem"
              }}
            >
              <h3>Franchise View Details</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="days_per_week"
                  style={{
                    width: "45%",
                    textAlign: "left",
                    fontFamily: "Open Sans"
                  }}
                >
                  Number of Listings:
                </label>
                <div style={{ fontSize: "15px" }}>
                  {this.state.listingsCount}
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="billing_cycle"
                  style={{
                    width: "45%",
                    textAlign: "left",
                    fontFamily: "Open Sans"
                  }}
                >
                  Number of Connections:
                </label>
                <div style={{ fontSize: "15px" }}>
                  {this.state.connectionsCount}
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="email"
                  style={{
                    width: "45%",
                    textAlign: "left",
                    fontFamily: "Open Sans"
                  }}
                >
                  Number of Enrollments:
                </label>
                <div style={{ fontSize: "15px" }}>
                  {this.state.enrollmentsCount}
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{
                    width: "45%",
                    textAlign: "left",
                    fontFamily: "Open Sans"
                  }}
                >
                  Total Earnings:
                </label>
                <div style={{ fontSize: "15px" }}>${this.state.manualSum}</div>
              </div>
            </form>
            <div className="modal-footer">
              <button
                type="submit"
                onClick={_ => this.props.onClose()}
                className="btn profile-btn"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FranchiseViewModal);
