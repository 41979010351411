import React from "reactn";
import { format } from "date-fns";
import { withStyles } from "@material-ui/core";
import "../../../assets/css/componentSpecificCss/userTasks.css";

const styles = {
  iconButton: {
    backgroundColor: "transparent !important",
    padding: "1em !important",
    color: "rgba(0, 0, 0, 0.54) !important",
    "&:focus": {
      outline: "none !important"
    }
  },
  avatar: {
    width: "50px",
    height: "50px"
  },
  item: {
    padding: "5px 10px",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "13px"
  },
  list: {
    height: "60px",
    padding: "0px"
  },
  tabRoot: {
    color: "#61616F !important",
    fontFamily: "Open Sans,sans-serif !important",
    fontWeight: "600 !important",
    fontSize: "1rem !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "#61616F !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "#61616F !important"
    }
  },
  tabSelected: {
    color: "black !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "black !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "black !important"
    }
  },
  tabIndicator: {
    backgroundColor: "#61616F",
    height: "3px",
    marginTop: "20px"
  }
};

class TasksReminders extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      subActive: "Tasks / Reminders",
      canEdit: false,
      tasks: []
    };
  }

  componentDidMount() {
    const filterTasks = this.props.tasks.filter(
      e => e.assigned == this.props.match.params.id
    );
    this.setState({ tasks: filterTasks });
  }

  render() {
    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          <div className="user-task-box">
            <div className="user-nav-line">
              <div className="user-task-reminder-header-box">
                <div className="user-task-header1">Item</div>
                <div className="user-task-header2">Description</div>
                <div className="user-task-header3">Due / Priority</div>
              </div>
            </div>

            <div className="user-task-body-box">
              {this.state.tasks.map((task, i) => {
                return (
                  <div className="user-task-item-row" key={i}>
                    <div className="user-task-item1">Task</div>
                    <div className="user-task-item2">{task.description}</div>
                    <div className="user-task-item3">
                      {format(new Date(task.due), "LLLL dd, yyyy")} /{" "}
                      {task.priority === 1
                        ? "Low"
                        : task.priority === 2
                        ? "Medium"
                        : "High"}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* <div className="user-save-row">
          <button className="user-save-btn" onClick={_ => this.updateIndividualData()}>Save</button>
        </div> */}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TasksReminders);
