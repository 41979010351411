import React from "reactn";
import axios from "axios";
import CalendarPartner from "../../components/calendar/CalendarPartner";
import { addDays, addWeeks, isSameDay } from "date-fns";
import LinkIcon from "@material-ui/icons/Link";
import AddIframeModal from "../../components/modals/AddIframeModal";
import {
  buildScheduleForProduct,
  getGroupedWeekdayRange,
  makeUTCDateTimeAsLocalTZ,
  prepareClosureDates
} from "../front/SpecificPageUtils/utils";
class Calendar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: "",
      tours: [],
      original: [],
      monthlySchedules: [],
      weeklySchedules: [],
      leadModal: false,
      view: "month",
      color: {
        0: "rgba(126,160,255,1) rgba(145,90,255,1)",
        1: "rgba(245,198,140,1) rgba(253,132,192,1)",
        2: "rgba(172,147,255,1) rgba(152,223,232,1)",
        3: "rgba(44,214,214,1) rgba(174,232,94,1)",
        4: "rgba(255,212,70,1) rgba(252,140,126,1)"
      }
    };
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Calendar", to: "/calendar" }
      ]
    });
    //NOTE: Not sure why the themes below use CSS linear-gradient. They apply the same color throughout. CSS "background" was sufficient.
    this.themes = [
      /* program_color = 0 */
      `background: #FF6663;
      background: linear-gradient(to right, #FF6663 0%, #FF6663 100%) !important;`,
      /* program_color = 1 */
      `background: #039BE5;
      background: linear-gradient(to right, #039BE5 0%, #039BE5 100%) !important;`,
      /* program_color = 2 */
      `background: #E57403;
      background: linear-gradient(to right, #E57403 0%, #E57403 100%) !important;`,
      /* program_color = 3 */
      `background: #1C2680;
      background: linear-gradient(to right, #1C2680 0%, #1C2680 100%) !important;`,
      /* program_color = 4 */
      `background: #079475;
      background: linear-gradient(to right, #079475 0%, #079475 100%) !important;`
    ];
    this.THEME_FOR_UNPUBLISHED = `background: #aaaaaa; !important;`;
    this.tabs = ["Active", "Inactive"];
    this.headings = {
      Active: [
        { id: "parent", label: "Parent Name" },
        { id: "child", label: "Child Name" },
        {
          id: "email",
          label: "Email"
        },
        {
          id: "phone",
          label: "Phone"
        },
        {
          id: "form",
          label: "Form"
        },
        {
          id: "event",
          label: "Program / Event"
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button style={{ marginRight: "30px" }}>View Details</button>
                <button style={{ marginRight: "30px" }}>Notes</button>
              </td>
            );
          }
        }
      ],
      Inactive: [
        { id: "parent", label: "Parent Name" },
        { id: "child", label: "Child Name" },
        {
          id: "email",
          label: "Email"
        },
        {
          id: "phone",
          label: "Phone"
        },
        {
          id: "form",
          label: "Form"
        },
        {
          id: "event",
          label: "Program / Event"
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button style={{ marginRight: "15px" }}>View Details</button>
                <button style={{ marginRight: "30px" }}>Notes</button>
              </td>
            );
          }
        }
      ]
    };
    this.actions = [{ name: "Notes", action: null }];
  }

  newCreateCalendarSchedules(_programs, _events, _online, role) {
    const customStyleForEvents = `
      background: rgba(245,198,140,1);
      background: linear-gradient(to right, rgba(245,198,140,1) 0%, rgba(253,132,192,1) 100%);`;

    let programs = [];
    let events = [];
    let online = [];

    // Process roles
    if (
      role.data.data === null ||
      (role.data?.data?.accesspoints?.Manage?.Programs?.read === true &&
        role.data?.data?.programlist === null)
    ) {
      programs = _programs.filter(e => e.published === true);
    } else if (
      role.data?.data?.accesspoints?.Manage?.Programs?.read === true &&
      role.data?.data?.programlist !== null
    ) {
      programs = _programs.filter(
        e =>
          e.published === true || role.data.data.programlist.indexOf(e.id) >= 0
      );
    }
    if (
      role.data.data === null ||
      (role.data?.data?.accesspoints?.Manage?.Events?.read === true &&
        role.data?.data?.eventlist === null)
    ) {
      events = _events;
    } else if (
      role.data?.data?.accesspoints?.Manage?.Events?.read === true &&
      role.data?.data?.eventlist !== null
    ) {
      events = _events.filter(e => role.data.data.eventlist.indexOf(e.id) >= 0);
    }
    if (
      role.data.data === null ||
      (role.data?.data?.accesspoints?.Manage?.["Online Virtual"]?.read ===
        true &&
        role.data?.data?.onlinelist === null)
    ) {
      online = _online.filter(e => e.published === true);
    } else if (
      role.data?.data?.accesspoints?.Manage?.["Online Virtual"]?.read ===
        true &&
      role.data?.data?.onlinelist !== null
    ) {
      online = _online.filter(
        e =>
          e.published === true || role.data.data.onlinelist.indexOf(e.id) >= 0
      );
    }

    const schedulesArr = [];
    let idCounter = 0;
    for (const program of programs) {
      let schedules, seriesFinalDate;
      if (program.enableCustomTimes) {
        console.log("customTimes: ", program.customTimes);
        const days_of_weeks_arr = Object.keys(program.customTimes);
        console.log(days_of_weeks_arr);
        const days_of_weeks = {};
        for (const day of days_of_weeks_arr) {
          days_of_weeks[day] = true;
        }
        //start time 8, end time 16 does not matter in calendar display
        ({ schedules, seriesFinalDate } = buildScheduleForProduct(
          program.program_start,
          program.number_of_weeks * 7,
          days_of_weeks,
          8,
          16,
          prepareClosureDates(
            program.closure_dates,
            program.closure_timeframe,
            program
          )
        ));
        console.log("customTimes schedules: ", schedules, seriesFinalDate);
      } else {
        console.log("customTimes: ", program.customTimes);
        if (program.timing[0] === "-1" || program.timing[1] === "-1") continue;

        ({ schedules, seriesFinalDate } = buildScheduleForProduct(
          program.program_start,
          program.number_of_weeks * 7,
          program.days_of_week,
          parseFloat(program.timing[0]),
          parseFloat(program.timing[1]),
          prepareClosureDates(
            program.closure_dates,
            program.closure_timeframe,
            program
          )
        ));
        if (program.id === 41) {
          console.log("PROGRAM", program);
          console.log("SCHEDULES", schedules);
        }
        console.log("schedules: ", schedules, seriesFinalDate);
      }

      //inject the calculated final date into the program object, because nobody thought to create this field in the first place!
      program.program_final_date = seriesFinalDate;
      schedules = schedules.map(
        // eslint-disable-next-line no-loop-func
        ({ hasNoDuration, startDateTime, endDateTime }) => ({
          isReadOnly: true,
          start: startDateTime,
          end: endDateTime,
          calendarId: 1, //<---can be used in the future to segment programs/events/online-virtuals, and by age group, etc.
          category: "time",
          customStyle: !program.published
            ? this.THEME_FOR_UNPUBLISHED
            : this.themes[program.program_color || 0].trim(),
          title: (hasNoDuration ? "Start: " : "") + program.program_name,
          programId: program.id,
          id: ++idCounter, //schedulesArr.length + 1,
          description: program.description,
          bgColor: "transparent",
          borderColor: "transparent",
          color: "#000"
        })
      );
      schedulesArr.push(...schedules);
    }
    for (const event of events) {
      //below, we use "makeUTCDateTimeAsLocalTZ" to keep the calendar consistent with the timezone of the partner.
      //if the partner created the event in Eastern Time, it will be consistently Eastern Time wherever in the world
      //you view the calendar from.
      schedulesArr.push({
        isReadOnly: true,
        start: makeUTCDateTimeAsLocalTZ(event.event_start, event.timezone),
        end: makeUTCDateTimeAsLocalTZ(event.event_end, event.timezone),
        calendarId: 1, //<---can be used in the future to segment programs/events/online-virtuals, and by age group, etc.
        category: "time",
        customStyle: !event.published
          ? this.THEME_FOR_UNPUBLISHED
          : customStyleForEvents,
        title: event.event_title,
        eventId: event.id,
        id: ++idCounter, //schedulesArr.length + 1,
        description: event.description,
        bgColor: "transparent",
        borderColor: "transparent",
        color: "#000"
      });
    }

    for (const program of online) {
      if (program.timing[0] === "-1" || program.timing[1] === "-1") continue;

      let { schedules, seriesFinalDate } = buildScheduleForProduct(
        program.program_start,
        program.number_of_weeks * 7,
        program.days_of_week,
        parseFloat(program.timing[0]),
        parseFloat(program.timing[1]),
        prepareClosureDates(
          program.closure_dates,
          program.closure_timeframe,
          program
        )
      );

      //inject the calculated final date into the program object, because nobody thought to create this field in the first place!
      program.program_final_date = seriesFinalDate;

      schedules = schedules.map(
        // eslint-disable-next-line no-loop-func
        ({ hasNoDuration, startDateTime, endDateTime }) => ({
          isReadOnly: true,
          start: startDateTime,
          end: endDateTime,
          calendarId: 1, //<---can be used in the future to segment programs/events/online-virtuals, and by age group, etc.
          category: "time",
          customStyle: !program.published
            ? this.THEME_FOR_UNPUBLISHED
            : this.themes[program.program_color || 0].trim(),
          title: (hasNoDuration ? "Start: " : "") + program.program_name,
          programId: program.id,
          id: ++idCounter, //schedulesArr.length + 1,
          description: program.description,
          bgColor: "transparent",
          borderColor: "transparent",
          color: "#000",
          isOnline: true
        })
      );
      schedulesArr.push(...schedules);
    }
    return schedulesArr;
  }

  async componentDidMount() {
    this.newReminderAdded();
  }

  async newReminderAdded() {
    this.setGlobal({ loading: true });
    const req1 = this.getRolePermissions();
    const req2 = axios.get(`${process.env.REACT_APP_API}/partners/reminders`);
    const req3 = axios.get(
      `${process.env.REACT_APP_API}/partners/organization_profile/prg_evts`
    );

    const [role, results, prg_evt] = await Promise.all([req1, req2, req3]);

    if (results.data.success) {
      //this.setState({ data: results.data.data.filter(e => !e.accepted) });
      this.setState({
        monthlySchedules: [
          ...results.data.data
            .filter(r => r.date)
            .map((reminder, i) => ({
              isReadOnly: true,
              start: new Date(reminder.date),
              end: new Date(reminder.date),
              calendarId: 1,
              category: "time",
              customStyle: `background: ${
                this.state.color[reminder.color].split(" ")[0]
              };
                background: linear-gradient(to right, ${
                  this.state.color[reminder.color].split(" ")[0]
                } 0%, ${this.state.color[reminder.color].split(" ")[1]} 100%);
                border-radius: 4px`,
              title: reminder.description,
              id: 2000 + i,
              bgColor: "transparent",
              borderColor: "transparent",
              color: "#000",
              type: "Reminder"
            })),
          ...this.newCreateCalendarSchedules(
            prg_evt.data.data.programs,
            prg_evt.data.data.events,
            prg_evt.data.data.virtual,
            role
          )
        ]
      });
    }
    this.setGlobal({ loading: false });
  }

  getRolePermissions = async () => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/role/${this.global.dsUser.user_id}`;
    const role = await axios.get(ep);
    return role;
  };

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  render() {
    return (
      <div className="admin">
        {this.state.iframeSetUp && (
          <AddIframeModal
            iframe={this.state.selectedIframe}
            open={this.state.iframeSetUp}
            type={this.state.type}
            active={this.state.active}
            onClose={() =>
              this.setState({
                iframeSetUp: false,
                selectedIframe: null,
                type: null,
                active: null
              })
            }
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div style={{ paddingLeft: "0.75rem", display: "block" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                  // marginBottom: "-30px"
                }}
              >
                <h1 style={{ marginRight: "30px" }}>Calendar</h1>
                <LinkIcon
                  style={{
                    transform: "scale(1.5) rotate(130deg)",
                    cursor: "pointer",
                    marginRight: "10px",
                    marginTop: "0.2px",
                    color: "black"
                  }}
                  onClick={_ =>
                    this.setState({
                      iframeSetUp: true,
                      active: "Calendar"
                    })
                  }
                  className="hovermyX"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap">
                <div className="forbtnapp">
                  <button
                    className="newapplications"
                    onClick={_ => this.setState({ view: "month" })}
                    style={{
                      color: this.state.view === "month" ? "#FFF" : "#7ea1ff",
                      backgroundColor:
                        this.state.view === "month" ? "#7ea1ff" : "#FFF"
                    }}
                  >
                    Monthly
                  </button>
                </div>
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  <button
                    className="newapplications"
                    onClick={_ => this.setState({ view: "week" })}
                    style={{
                      color: this.state.view === "week" ? "#FFF" : "#7ea1ff",
                      backgroundColor:
                        this.state.view === "week" ? "#7ea1ff" : "#FFF"
                    }}
                  >
                    Weekly
                  </button>
                </div>
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  <button
                    className="newapplications"
                    onClick={_ => this.setState({ view: "day" })}
                    style={{
                      color: this.state.view === "day" ? "#FFF" : "#7ea1ff",
                      backgroundColor:
                        this.state.view === "day" ? "#7ea1ff" : "#FFF"
                    }}
                  >
                    Daily
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="tablecont">
              <div>
                <CalendarPartner
                  calendarView={this.state.view}
                  schedules={this.state.monthlySchedules}
                  newReminderAdded={r => {
                    this.newReminderAdded();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Calendar;
