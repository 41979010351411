import React from "reactn";
import { Modal } from "../../UI";
import { Fragment } from "react";
import { TextField } from "@material-ui/core";
import axios from "axios";
class EduCommentsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentsEdit: false,
      comment: ""
    };
  }
  componentWillReceiveProps(props) {
    if (props.data) {
      this.setState({ comment: props.data.comments });
    }
  }
  saveComment = async () => {
    if (this.state.comment) {
      const endPnt = `${process.env.REACT_APP_API}/edu/application/comments`;
      const res = await axios.post(endPnt, {
        id: this.props.data.id,
        comments: this.state.comment,
        prescreen: this.props.prescreen
      });
      if (res.data.success) {
        this.setState({ commentsEdit: false });
        this.props.data.comments = this.state.comment;
      }
    }
  };
  render() {
    const { open, onClose } = this.props;
    return (
      <div>
        <Modal open={open} heading="Notes" onClose={onClose}>
          {this.props.data && (
            <Fragment>
              <div className="row">
                <p style={{ textAlign: "center" }}></p>
              </div>

              {!this.state.commentsEdit ? (
                this.state.comment ? (
                  this.state.comment.split("\n").map((element, i) => {
                    if (element) return <p key={i}>{element}</p>;
                  })
                ) : (
                  <div className="row">
                    <p>No notes found!</p>
                  </div>
                )
              ) : (
                <div className="row">
                  <TextField
                    multiline
                    variant="outlined"
                    rows="4"
                    fullWidth
                    value={this.state.comment}
                    onChange={e => this.setState({ comment: e.target.value })}
                  />
                </div>
              )}

              <div className="row">
                <div className="col-md-12">
                  <div className="buttons">
                    {this.state.commentsEdit ? (
                      <button onClick={() => this.saveComment()}>Save</button>
                    ) : (
                      <button
                        onClick={() => this.setState({ commentsEdit: true })}
                      >
                        Edit
                      </button>
                    )}
                    <button onClick={() => onClose()}>Close</button>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </Modal>
      </div>
    );
  }
}

export default EduCommentsModal;
