import React from "reactn";
import EducatorRoute from "../../routes/educatorRoute";
import Sidebar from "../common/Sidebar";
import TopBar from "../common/TopBar";
import Home from "../../pages/educators/home/home";
import Enrolled from "../../pages/educators/enrolled";
import Milestones from "../../pages/educators/milestones";
import PricingPlans from "../../pages/educators/pricingPlans";
import TourRequests from "../../pages/educators/tourRequests";
import Attendance from "../../pages/educators/attendance/attendance";
import MyEarnings from "../../pages/educators/myEarnings/myEarnings";
import WeeklySlots from "../../pages/educators/weeklySlots/weeklySlots";
import Messaging from "../../pages/shared/messaging/messaging";
import Calendar from "../../pages/shared/calendar";
import SideMenu from "./SideMenu";
import ProgramSetup from "../../pages/educators/programSetup/programSetup";
import PaymentMethods from "../../pages/shared/paymentMethods/paymentMethods";
import MyApplication from "../../pages/educators/myApplication/myApplication";
import Applications from "../../pages/educators/applications";
import FamilyPayments from "../../pages/educators/familyPayments";
import ExpenseTracker from "../../pages/educators/expenseTracker";
import Connections from "../../pages/educators/connections";
import TourAvailability from "../../pages/educators/tourAvailability";
import ApplicationView from "../../pages/parents/applicationView";
import Checklist from "../../pages/educators/checklist";
import Documents from "../../pages/educators/documents";
import StripeExpress from "../../pages/educators/stripeExpress";
import Observations from "../../pages/educators/Observations";
import NewsFeed from "../../pages/parents/newsFeed";
class EducatorLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.basePath = "/educators";
    this.routes = [
      { path: "/messages", component: Messaging },
      { path: "/calendar", component: Calendar },
      { path: "", component: Home },
      { path: "/enrolled", component: Enrolled },
      { path: "/milestones", component: Milestones },
      { path: "/my-application", component: MyApplication },
      { path: "/pricing-plans", component: PricingPlans },
      { path: "/tour-requests", component: TourRequests },
      { path: "/tour-requests/availability", component: TourAvailability },
      { path: "/attendance", component: Attendance },
      { path: "/my-earnings", component: MyEarnings },
      { path: "/weekly-slots", component: WeeklySlots },
      { path: "/createobservation", component: Observations },
      { path: "/program-setup", component: ProgramSetup },
      { path: "/connected", component: StripeExpress },
      { path: "/connected/:code", component: StripeExpress },
      { path: "/newsfeed", component: NewsFeed },
      { path: "/applications", component: Applications },
      { path: "/applications/:slug/:time", component: ApplicationView },
      { path: "/family-payments", component: FamilyPayments },
      { path: "/expense-tracker", component: ExpenseTracker },
      { path: "/connections", component: Connections },
      { path: "/checklist", component: Checklist },
      { path: "/resources", component: Documents }
    ];
  }

  async componentDidMount() {
    if (localStorage.getItem("ds_token") && !this.global.dsUser) {
      await this.dispatch.validateToken();
      if (
        !this.global.dsUser ||
        this.global.dsUser.accountType !== "Educator"
      ) {
        this.props.history.push("/");
      }
    } else if (!localStorage.getItem("ds_token") && !this.global.dsUser) {
      this.props.history.push(
        `/sign-in?follow=${this.props.location.pathname}`
      );
    }
  }

  render() {
    const { path } = this.props.match;
    return (
      <>
        {this.global.dsUser && this.global.dsUser.accountType === "Educator" ? (
          <>
            <Sidebar path={path} Menu={SideMenu} />
            <div
              id="content-wrapper"
              className="d-flex flex-column"
              onClick={() => this.setGlobal({ sidenavShown: false })}
            >
              <div id="content">
                <TopBar ConsoleRoute={this.basePath} />
                {!this.global.noShow && (
                  <p style={{ margin: "10px 0px 0px 1rem" }}>
                    {this.global.pathway.map((e, i) =>
                      this.global.pathway.length - 1 > i ? `${e} -> ` : `${e}`
                    )}
                  </p>
                )}
                {this.routes.map((route, i) => (
                  <EducatorRoute
                    key={i}
                    educator={true}
                    exact
                    path={`${this.basePath}${route.path}`}
                    component={route.component}
                  />
                ))}
              </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
              <i className="fas fa-angle-up" />
            </a>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default EducatorLayout;
