import React from "reactn";
import { format } from "date-fns";

const FranchiserEarningsModal = ({ partnerOrg }) => {
  const dsUser = partnerOrg.user;
  const earnings = partnerOrg.earnings;
  console.log("partnerOrg: ", partnerOrg);
  const getRefundComponent = amount => {
    return (
      <p
      style={{
        color: "#a1a1a1",
        paddingBottom: "40px",
        fontStyle: "italic",
        fontSize: "0.9rem"
      }}
      >
        Refunds (to-date):{" "}
        {amount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD"
        })}
      </p>
    );
  };

  const getAmountComponent = amount => {
    return <h3>${amount}</h3>;
  };

  return (
    <div className="boxes">
      <div style={{minWidth: "max-content"}} className="box">
        <h2>PAID ORDERS</h2>
        <div
          className="info"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          {console.log("earnings.onetime", earnings.oneTime ? "hi" : "no")}
          {getAmountComponent(
            earnings.oneTime
              ? parseFloat(earnings.oneTime)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : "0.00"
          )}
          {getRefundComponent(earnings.refundedOneTime ? earnings.refundedOneTime : "0.00")}
          { console.log("HERE", dsUser) }
          <p>
            Since{" "}
            {dsUser && dsUser.createdAt ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
          </p>
        </div>
      </div>
      <div style={{minWidth: "max-content"}} className="box">
        <h2>RECURRING PAYMENTS</h2>
        <div
          className="info"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          {getAmountComponent(
            earnings.recurring
              ? parseFloat(earnings.recurring)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : "0.00"
          )}
          {getRefundComponent(earnings.refundedRecurring ? earnings.refundedRecurring : "0.00")}
          <p>
            Since{" "}
            {dsUser && dsUser.createdAt ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
          </p>
        </div>
      </div>
      <div style={{minWidth: "max-content"}} className="box">
        <h2>CUMULATIVE AMOUNT</h2>
        <div
          className="info"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          {getAmountComponent(
            earnings.recurring || earnings.oneTime
              ? parseFloat((earnings.recurring || 0) + (earnings.oneTime || 0))
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          )}
          {getRefundComponent(
            earnings.recurring || earnings.oneTime 
              ? earnings.refundedOneTime + earnings.refundedRecurring
              : "0.00"
          )}
          <p>
            Since{" "}
            {dsUser && dsUser.createdAt ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FranchiserEarningsModal;