import React from "react";
import axios from "axios";
import { Link, withRouter, useHistory } from "react-router-dom";
import "../../assets/css/componentSpecificCss/searchResult.css";

const SearchResult = ({ result, type, childrens }) => {
  console.log("SearchResult", result, type);
  const history = useHistory();

  const fetchUserId = async email => {
    const ep = `${process.env.REACT_APP_API}/partners/searchbar/user/${email}`;
    const result_ep = await axios.get(ep);

    return result_ep.data.data.length > 0 ? result_ep.data.data[0].id : -1;
  };

  if (type === "Manage") {
    return (
      <div
        className="search-result"
        onClick={e => {
          if (result.classificationType === "resources") {
            history.push(`/partners/resources-manage/${result.id}`);
          } else {
            history.push(
              `/partners/${
                result.classificationType === "online"
                  ? "virtual"
                  : result.classificationType
              }/manage/single/${result.id}`
            );
          }
        }}
      >
        Manage /{" "}
        {result.classificationType === "programs"
          ? "Programs"
          : result.classificationType === "online"
          ? "Online Virtual"
          : result.classificationType === "events"
          ? "Events"
          : result.classificationType === "memberships"
          ? "Memberships"
          : result.classificationType === "gift_cards"
          ? "Gift Cards"
          : result.classificationType === "resources"
          ? "Resources"
          : "Other"}{" "}
        / <span style={{ fontWeight: "bold" }}>{result.displayName}</span>
      </div>
    );
  } else if (type === "Listings") {
    return (
      <div
        className="search-result"
        onClick={e => {
          if (
            result.classificationType === "online" ||
            result.classificationType === "gift_cards" ||
            result.classificationType === "forms" ||
            result.classificationType === "news-updates"
          ) {
            history.push(`/partners/${result.classificationType}`);
          } else {
            history.push(`/partners/${result.classificationType}/list`);
          }
        }}
      >
        Listings /{" "}
        {result.classificationType === "programs"
          ? "Programs"
          : result.classificationType === "online"
          ? "Online Virtual"
          : result.classificationType === "events"
          ? "Events"
          : result.classificationType === "memberships"
          ? "Memberships"
          : result.classificationType === "gift_cards"
          ? "Gift Cards"
          : result.classificationType === "forms"
          ? "Forms"
          : result.classificationType === "news-updates"
          ? "News and Updates"
          : result.classificationType === "promotion"
          ? "Promotions"
          : "Other"}{" "}
        / <span style={{ fontWeight: "bold" }}>{result.displayName}</span>
      </div>
    );
  } else if (type === "Profile") {
    return (
      <>
        <div
          className="search-result"
          onClick={async e => {
            if (result.dsUserId) {
              history.push(`/partners/connections`);
              setTimeout(() => {
                history.push(
                  `/partners/single/connections/${result.email}/true?parentId=${result.dsUserId}`
                );
              }, 500);
            } else {
              const dsUserId = await fetchUserId(result.email);
              if (dsUserId > -1) {
                history.push(`/partners/connections`);
                setTimeout(() => {
                  history.push(
                    `/partners/single/connections/${result.email}/true?parentId=${result.dsUserId}`
                  );
                }, 500);
              } else {
                history.push(`/partners/connections`);
                setTimeout(() => {
                  history.push(
                    `/partners/single/connections/${result.email}/true?parentId=${result.dsUserId}`
                  );
                }, 500);
              }
            }
          }}
        >
          Contacts / Connections / {result.displayName} /{" "}
          <span style={{ fontWeight: "bold" }}>Profile</span>
        </div>
        {result.isSearchByChild && (
          <div
            className="search-result"
            onClick={e => {
              if (result.dsUserId) {
                const fullNameToFind = result.isSearchByChild;
                const foundChild = result.childrens.find(
                  child => child.fullName === fullNameToFind
                );
                history.push(
                  `/partners/child-profile/${result.dsUserId}/${foundChild.id}`
                );
              }
            }}
          >
            Contacts / Connections / {result.displayName} /{" "}
            <span style={{ fontWeight: "bold" }}>
              Child Profile: {result.isSearchByChild}
            </span>
          </div>
        )}
      </>
    );
  } else if (type === "Leads") {
    return (
      <>
        {result.map((item, index) => {
          const status =
            item.status_stage === "New_Pending"
              ? "New"
              : item.status_stage === "Accepted_Unpaid"
              ? "Accepted"
              : item.status_stage === "Waitlisted_Unpaid" ||
                item.status_stage === "Waitlisted_Paid"
              ? "Waitlisted"
              : item.status_stage === "Rejected"
              ? "Rejected"
              : item.status_stage === "Application_Cancelled" ||
                item.status_stage === "Cancelled_Unpaid" ||
                item.status_stage === "Inactive" ||
                item.status_stage === "Cancelled"
              ? "Archived"
              : null;

          if (item.form_name) {
            return (
              <div
                key={index}
                className="search-result"
                onClick={e => {
                  history.push(`/partners/form-leads`);
                }}
              >
                Leads / Form Leads / {item.form_name}: {item.form_sub_name} /{" "}
                <span style={{ fontWeight: "bold" }}>{item.user_name}</span>
              </div>
            );
          } else {
            return (
              <div
                key={index}
                className="search-result"
                onClick={e => {
                  history.push(`/partners/applications?tab=${status}`);
                }}
              >
                Leads / Applications / {status} /{" "}
                <span style={{ fontWeight: "bold" }}>{item.program_name}</span>
              </div>
            );
          }
        })}
      </>
    );
  } else if (type === "History") {
    return (
      <div
        className="search-result"
        onClick={async e => {
          if (result.dsUserId) {
            history.push(
              `/partners/single/connections/${result.email}/true?parentId=${result.dsUserId}&tab=History`
            );
          } else {
            const dsUserId = await fetchUserId(result.email);
            if (dsUserId > -1) {
              history.push(
                `/partners/single/connections/${result.email}/true?parentId=${dsUserId}&tab=History`
              );
            } else {
              history.push(
                `/partners/single/connections/${result.email}/false?parentId=undefined&tab=History`
              );
            }
          }
        }}
      >
        Contacts / Connections / {result.displayName} /{" "}
        <span style={{ fontWeight: "bold" }}>History</span>
      </div>
    );
  } else if (type === "Financials") {
    return (
      <div
        className="search-result"
        onClick={async e => {
          if (result.dsUserId) {
            history.push(
              `/partners/single/connections/${result.email}/true?parentId=${result.dsUserId}&tab=Financials`
            );
          } else {
            const dsUserId = await fetchUserId(result.email);
            if (dsUserId > -1) {
              history.push(
                `/partners/single/connections/${result.email}/true?parentId=${dsUserId}&tab=Financials`
              );
            } else {
              history.push(
                `/partners/single/connections/${result.email}/false?parentId=undefined&tab=Financials`
              );
            }
          }
        }}
      >
        Contacts / Connections / {result.displayName} /{" "}
        <span style={{ fontWeight: "bold" }}>Financials</span>
      </div>
    );
  } else if (type === "Enrollments") {
    let childNames = childrens.map(child => child.fullName).join(", ");
    if (childNames.length > 30) {
      childNames = childNames.substring(0, 30) + "...";
    }
    return (
      <>
        {result.map((item, index) => {
          const childEnrolled = item.childId;
          let childEnrolledName = "";
          if (childEnrolled) {
            const childArr = childrens.filter(
              child => child.id === childEnrolled
            );
            childEnrolledName = childArr[0]?.fullName;
          }
          return (
            <div
              key={index}
              className="search-result"
              onClick={e => {
                const programType =
                  item.classificationType === "Program"
                    ? "programs"
                    : item.classificationType === "Online"
                    ? "virtual"
                    : item.classificationType === "Event"
                    ? "events"
                    : item.classificationType === "Membership"
                    ? "memberships"
                    : "";
                const id = item.programId ? item.programId : null;
                history.push(`/partners/${programType}/manage/single/${id}`);
              }}
            >
              Manage /{" "}
              {item.classificationType === "Program"
                ? "Programs"
                : item.classificationType === "Online"
                ? "Online Virtual"
                : item.classificationType === "Event"
                ? "Events"
                : item.classificationType === "Membership"
                ? "Memberships"
                : ""}{" "}
              / {item.program_name} /{" "}
              {item.childId && childEnrolledName ? (
                <span style={{ fontWeight: "bold" }}>
                  {item.user_name} (<i>{childEnrolledName}</i>)
                </span>
              ) : (
                <span style={{ fontWeight: "bold" }}>{item.user_name}</span>
              )}
            </div>
          );
        })}
      </>
    );
  } else if (type === "Attachments") {
    return (
      <>
        {result.map((item, index) => (
          <div
            key={index}
            className="search-result"
            onClick={async e => {
              if (result.dsUserId) {
                history.push(
                  `/partners/single/connections/${item.user_email}/true?parentId=${result.dsUserId}&tab=Attachments`
                );
              } else {
                const dsUserId = await fetchUserId(item.user_email);
                if (dsUserId > -1) {
                  history.push(
                    `/partners/single/connections/${item.user_email}/true?parentId=${dsUserId}&tab=Attachments`
                  );
                } else {
                  history.push(
                    `/partners/single/connections/${item.user_email}/false?parentId=undefined&tab=Attachments`
                  );
                }
              }
            }}
          >
            Contacts / Connections / {item.fullName} / Attachments /{" "}
            <span style={{ fontWeight: "bold" }}>
              {item.file_uploads.file_name}
            </span>
          </div>
        ))}
      </>
    );
  } else if (type === "Empty") {
    return <div className="search-result">No results found.</div>;
  } else {
    return null;
  }
};

export default SearchResult;
