import React from "react";
import { Doughnut } from "react-chartjs-2";

export default class DoughnutChart extends React.Component {
  constructor (props) {
    super(props);
    this.options = {
      maintainAspectRatio: false,
      tooltips: {
        backgroundColor: "rgb(255,255,255)",
        bodyFontColor: "#858796",
        borderColor: "#dddfeb",
        borderWidth: 1,
        xPadding: 15,
        yPadding: 15,
        displayColors: false,
        caretPadding: 10
      },
      legend: {
        display: true,
        position: this.props.legendPositions || "left"
      },
      cutoutPercentage: this.props.cutoutPercentage || 50,
    };
  }

  componentWillReceiveProps(props) {
    this.forceUpdate();
  }

  render () {
    let data = {
      labels: this.props.labels ? this.props.labels: [],
      datasets: [
        {
          data: this.props.data ? this.props.data : [],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
          weight: 20
        }
      ]
    };
    return <Doughnut data={data} options={this.options} />;
  }
}
