import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";

class SectionSeven extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.locations = [
      {
        address:
          this.global.organizationInfo.address1 +
          ", " +
          this.global.organizationInfo.city +
          ", " +
          this.global.organizationInfo.province +
          " " +
          this.global.organizationInfo.postalcode +
          ", " +
          this.global.organizationInfo.country
      },
      ...this.global.organizationInfo.other_locations.map(e => ({
        address: e.address
      }))
    ];
    this.theme = [`FF6663`, `039BE5`, `E57403`, `1C2680`, `079475`];
  }
  handleChange = (e, v) => {
    this.setGlobal({
      online: {
        ...this.global.online,
        [e]: v
      }
    });
  };

  customForm = () => {
    return (
      <div className="formbody">
        <form>
          <>
            <div className="row">
              <div className="col-5">
                <h3 className="label-names">
                  Meeting Id <span className="requiredField">*</span>
                </h3>
              </div>
              <div className="col-7 d-flex">
                <div className={`w-100`}>
                  <input
                    className="input-fields"
                    name="manual_meeting_id"
                    type="number"
                    min="0"
                    value={this.global.online.manual_meeting_id}
                    onChange={e => {
                      this.handleChange(e.target.name, e.target.value); // .replace(/\s/g,'')
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-5">
                <h3 className="label-names">
                  Meeting Password <span className="requiredField">*</span>
                </h3>
              </div>
              <div className="col-7 d-flex">
                <div className={`w-100`}>
                  <input
                    className="input-fields"
                    type="password"
                    name="manual_meeting_password"
                    value={this.global.online.manual_meeting_password}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <h3 className="label-names">
                  Meeting Link <span className="requiredField">*</span>
                </h3>
              </div>
              <div className="col-7 d-flex">
                <div className={`w-100`}>
                  <input
                    className="input-fields"
                    type="text"
                    name="manual_metting_link"
                    value={this.global.online.manual_metting_link}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Zoom Details"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionSeven;
