import React, { Component } from "react";
import {
  ExpansionPanelDetails,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  Typography
} from "@material-ui/core";
import Axios from "axios";
import { Edit } from "@material-ui/icons";
import ViewNotes from "../table/admins/viewNotes";
import { format } from "date-fns";
class SubTask extends Component {
  state = {
    selectVals: [],
    showNotes: false
  };
  render() {
    const lastUpdate = format(
      new Date(this.props.task.updatedAt),
      "LL/dd/yy hh:mm aaaa"
    );
    return (
      <>
        <ViewNotes
          open={this.state.showNotes}
          data={this.props.task.notes}
          id={this.props.task.id}
          save={note => this.props.saveNote(note)}
          onClose={() =>
            this.setState({
              showNotes: false
            })
          }
        />
        <ExpansionPanelDetails
          style={{
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "30%",
              paddingLeft: "20px"
            }}
          >
            <b>{this.props.task.subTaskName}</b>
          </div>
          <FormControl variant="outlined" style={{ width: "30%" }}>
            <InputLabel id="actionlabel">Action(s)</InputLabel>
            <Select
              labelId="actionlabel"
              multiple
              value={this.props.task.stepscompleted || []}
              color="secondary"
              style={{
                minWidth: "200px"
              }}
              onChange={e => {
                const ep = `${process.env.REACT_APP_API}/adm/followups/subtask/steps`;
                Axios.post(ep, {
                  stepscompleted: e.target.value,
                  id: this.props.task.id
                });
                this.props.updateSteps(e.target.value);
              }}
            >
              {[
                "Called, No LVM",
                "Called LVM",
                "DS Messages",
                "Emailed/Texted",
                "Received confirmation via Email/Text",
                "Called/Spoke"
              ]
                .reverse()
                .map(r => (
                  <MenuItem key={r} value={r}>
                    {r}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Typography style={{ marginBottom: 0, padding: "5px" }}>
            Last Updated at: {lastUpdate}
          </Typography>
          <FormControl>
            <Button
              variant="outlined"
              startIcon={<Edit />}
              color="primary"
              onClick={() =>
                this.setState({
                  showNotes: true
                })
              }
            >
              View notes
            </Button>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                value={this.props.preTour ? "Confirmed" : "Completed"}
                checked={this.props.task.completed}
                onChange={e => {
                  const ep = `${process.env.REACT_APP_API}/adm/followups/subtask/complete`;
                  Axios.post(ep, {
                    complete: e.target.checked,
                    id: this.props.task.id
                  });
                  this.props.update(!this.props.task.completed);
                }}
              />
            }
            label={this.props.preTour ? "Confirmed" : "Completed"}
          />
        </ExpansionPanelDetails>
      </>
    );
  }
}

export default SubTask;
