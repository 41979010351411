import { setGlobal } from "reactn";
import "./shared";
import "./auth/auth";

setGlobal({
  ccUser: {},
  ccContacts: []
});

const setParents = () => {
  require("./parents");
};

const setEducators = () => {
  require("./educators");
};

const setAdmin = () => {
  require("./admin");
};
const setPartner = () => {
  require("./partners");
};

const setFranchiser = () => {
  require("./franchiser");
};

setGlobal({
  loading: false,
  sidenavShown: false,
  lastAPICall: {},
  dsUser: null,
  franchiseUser: null
});

export { setParents, setEducators, setAdmin, setPartner, setFranchiser };
