import React from "reactn";
import MyEarningsModal from "../../../components/modals/MyEarningsModal";
import EarningOverview from "./earnings-overview";
import RecentDeposits from "./recent-deposits";
import DepositModal from "../../../components/modals/DepositsModal";
import axios from "axios";
import { format, addDays, getDay } from "date-fns";
class MyEarnings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      payouts: [],
      counts: {
        lastPayout: null,
        nextPayout: null,
        totalPayout: null
      },
      runningTotal: null,
      transactions: [],
      payout: null
    };
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "My Earnings"]
    });
  }

  groupByWeek(transactions) {
    const initalDate = new Date(transactions[0].created * 1000);
    let start = addDays(initalDate, (getDay(initalDate) + 1) * -1);
    const finalArray = [];
    finalArray.push({ amount: 0, date: format(start, "LLL dd") });
    start = addDays(start, 7);
    let amount = 0;
    for (let transaction of transactions) {
      if (transaction.created < start.getTime() / 1000) {
        amount +=
          transaction.amount -
          (transaction.amount * 0.029 +
            transaction.application_fee_amount +
            30);
      } else {
        finalArray.push({ amount, date: format(start, "LLL dd") });
        start = addDays(start, 7);
        amount = 0;
        amount +=
          transaction.amount -
          (transaction.amount * 0.029 +
            transaction.application_fee_amount +
            30);
      }
    }
    finalArray.push({ amount, date: format(start, "LLL dd") });
    return finalArray;
  }
  async componentDidMount() {
    try {
      const ep = `${process.env.REACT_APP_API}/edu/payout`;
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      if (res.data.success) {
        this.setState({
          payouts: res.data.data.payouts.data,
          runningTotal: res.data.data.counts.totalPayout.reduce(
            (f, s) =>
              f +
              (s.amount - (s.amount * 0.029 + s.application_fee_amount + 30)),
            0
          ),
          transactions: this.groupByWeek(
            res.data.data.counts.totalPayout.reverse()
          ),
          counts: {
            lastPayout:
              res.data.data.payouts.data.length > 0
                ? res.data.data.payouts.data[0]
                : null,
            nextPayout: res.data.data.counts.nextPayout.instant_available[0],
            totalPayout: res.data.data.counts.totalPayout.reduce(
              (f, s) =>
                f +
                (s.amount - (s.amount * 0.029 + s.application_fee_amount + 30)),
              0
            )
          }
        });
        this.forceUpdate();
      }

      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (er) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    return (
      <div className="container-fluid my_earning">
        {this.state.payout ? (
          <DepositModal
            item={this.state.payout}
            setItem={_ => this.setState({ payout: null })}
          />
        ) : (
          <>
            <div className="cont">
              <h1>My Earnings</h1>
            </div>
            <div className="cont">
              <MyEarningsModal earnings={this.state.counts} next={null} />
              <div className="row">
                <RecentDeposits
                  earnings={this.state.payouts}
                  setDeposit={dep => this.setState({ payout: dep })}
                />
                <EarningOverview
                  total={this.state.runningTotal / 100}
                  earnings={this.state.transactions}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default MyEarnings;
