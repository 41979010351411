import React, { Fragment } from "reactn";
import { formTypes } from "../../components/UI/form";
import { SelectArrow } from "../../assets";
import axios from "axios";
import { numToHours } from "../../lib/util";
import CancelModal from "../../components/modals/CancelSubscription";
class MyEnrollmentsChange extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      application: null,
      pricingPlans: [],
      program: null,
      selectedPlan: {},
      daysOfWeek: [],
      isEditing: false
    };
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "My Enrollments", "Details"]
    });
    this.handleChange = this.handleChange.bind(this);

    // this.editEnrollment = this.nextStep.bind(this);
    // this.endEnrollment = this.prevStep.bind(this);

    //     if (!this.global.enrApp || !this.global.enrApp.selectedChild.id) {
    //       this.props.history.push(
    //         `/parents/my-enrollments`
    //       );
    //     }
  }

  //   endEnrollment() {
  //     const slug = this.props.match.params.slug;
  //     this.setGlobal({
  //       enrApp: {
  //         ...this.state
  //       }
  //     });
  //     this.props.history.push(`/parents/interested-programs/${slug}/finalize`);
  //   }

  //   endEnrollment() {
  //     const slug = this.props.match.params.slug;
  //     this.props.history.push(
  //       `/parents/interested-programs/${slug}/choose-child`
  //     );
  //   }

  handleChange = (e, v) => {
    this.setState({
      [e]: v
    });
  };

  handleSaveButton = () => {
    if (!this.state.isEditing) {
      this.setState({ isEditing: true });
    } else {
    }
  };

  async componentDidMount() {
    try {
      const ep = `${process.env.REACT_APP_API}/par/application/${this.props.match.params.slug}/${this.props.match.params.time}`;
      this.setGlobal({ loading: true });

      const application = await axios.get(ep);

      this.setState({ ...application.data.data });
      this.setGlobal({ loading: false, lastAPICall: application });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    const vals = this.state;

    return (
      <div className="container-fluid new_application new_application_3">
        {!this.state.application ? null : (
          <>
            {this.state.cancel && (
              <CancelModal
                application={this.state.application}
                update={e =>
                  this.setState({
                    application: { ...this.state.application, endDate: e }
                  })
                }
                onClose={() => this.setState({ cancel: false })}
              />
            )}

            <div className="cont">
              <div className="col-md-12">
                <h1>View Enrollment Details</h1>
              </div>
            </div>
            <div className="cont">
              <div className="contbox">
                <div className="header">
                  <h2>{vals.program.programName}</h2>
                </div>
                <div className="body">
                  <div className="row">
                    <div className="col-md-7 col-lg-6">
                      <h2>Program Plan</h2>
                      <div className="boxwithcroll">
                        <Fragment>
                          <h3>Actual Plan</h3>
                          <div className="controw">
                            <div className="row">
                              <div className="col-7">
                                <h4>{vals.selectedPlan.numDays} days / week</h4>
                                <p>
                                  <span>
                                    ${vals.selectedPlan.amount} -{" "}
                                    {vals.selectedPlan.interval}
                                  </span>
                                </p>
                                <p>
                                  {numToHours(vals.selectedPlan.hoursFrom)} -{" "}
                                  {numToHours(vals.selectedPlan.hoursTo)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      </div>
                    </div>
                    <div className="col-md-5 col-lg-6">
                      <div className="rightside">
                        <div className="wraper">
                          <h2>Days of the Week</h2>
                          <div className="select">
                            <formTypes.DaysDropdownSelect
                              name="daysOfWeek"
                              value={vals.application.daysOfWeek}
                              onChange={this.handleChange}
                              options={formTypes.SelectOptions.daysOfWeekRanges}
                              className="w-100"
                              isDisabled={!vals.isEditing}
                              range={vals.program.daysOfWeek}
                              toremove={vals.program.excludedDays}
                            />
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <p>
                                {vals.daysOfWeek.length >
                                vals.selectedPlan.numDays ? (
                                  <span style={{ color: "red" }}>
                                    Selected days cannot exceed number of plan
                                    days
                                  </span>
                                ) : (
                                  <>&nbsp; </>
                                )}
                                {vals.daysOfWeek.length <
                                vals.selectedPlan.numDays ? (
                                  <span style={{ fontSize: "13px" }}>
                                    {vals.selectedPlan.numDays -
                                      vals.daysOfWeek.length}{" "}
                                    remaining
                                  </span>
                                ) : (
                                  <>&nbsp;</>
                                )}
                              </p>
                            </div>
                          </div>

                          <h2>Start Date</h2>

                          <div className="select">
                            <img src={SelectArrow} alt="" />

                            <formTypes.Date
                              name="startDate"
                              value={vals.application.startDate}
                              onChange={this.handleChange}
                              isDisabled={!vals.isEditing}
                              minDate={new Date()}
                            />
                          </div>
                          <h2>End Date</h2>
                          <div className="select">
                            <img src={SelectArrow} alt="" />
                            <formTypes.Date
                              name="endDate"
                              value={vals.application.endDate}
                              onChange={this.handleChange}
                              isDisabled={!vals.isEditing}
                              minDate={new Date()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="buttons mb-0">
                      <button
                        className="savebtn"
                        onClick={this.handleSaveButton}
                        disabled={
                          vals.daysOfWeek.length > vals.selectedPlan.numDays ||
                          vals.daysOfWeek.length < vals.selectedPlan.numDays
                        }
                      >
                        {this.state.isEditing ? "SAVE" : "EDIT"}
                      </button>

                      <button
                        onClick={() => {
                          this.setState({
                            cancel: true
                          });
                        }}
                        className="nextbtn"
                      >
                        END ENROLLMENT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default MyEnrollmentsChange;

/*<a href="#/" className="selected">
                                   <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13.754"
                                    height="13"
                                    viewBox="0 0 13.754 13"
                                  >
                                    <g
                                      id="Group_800"
                                      data-name="Group 800"
                                      transform="translate(-939 -754)"
                                    >
                                      <g
                                        id="Ellipse_1040"
                                        data-name="Ellipse 1040"
                                        transform="translate(939 754)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                        stroke-width="1.5"
                                      >
                                        <circle
                                          cx="6.5"
                                          cy="6.5"
                                          r="6.5"
                                          stroke="none"
                                        />
                                        <circle
                                          cx="6.5"
                                          cy="6.5"
                                          r="6"
                                          fill="none"
                                        />
                                      </g>
                                      <path
                                        id="Path_1429"
                                        data-name="Path 1429"
                                        d="M3555.464,4000.681l3.458,3.642,6.47-6.782"
                                        transform="translate(-2613 -3242)"
                                        fill="none"
                                        stroke=""
                                      />
                                    </g>
                                  </svg>
                                  Selected
                                </a> */
