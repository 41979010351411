import React from "reactn";
import CreatePromotionListModal from "../../../components/modals/CreatePromotionListModal";
import { NewTabbedTable } from "../../../components/UI";
import { MiniPlus } from "../../../assets";
import { format } from "date-fns";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import { isPast } from "date-fns";
import { postPromotionToggle, postPublishPromotion } from "./utils";
import LinkIcon from "@material-ui/icons/Link";
import AddIframeModal from "../../../components/modals/AddIframeModal";
import ReactTooltip from "react-tooltip";
import { Delete, SettingsBackupRestore as Restore } from "@material-ui/icons";
import ConfirmArchiveModal from "../../../components/modals/ConfirmArchiveModal";
import { DateTime } from "luxon";
import { utcToZonedTime } from "date-fns-tz";

class PromotionList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      filter: "",
      tours: [],
      original: [],
      showModal: false,
      toArchive: true,
      selectedItem: null
    };

    this.tabs = ["Active", "Archive"];
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Promotions", to: "/promotion/list" }
      ]
    });
  }

  getTimeZone = () => {
    const tz = this.global.organizationInfo.timezone;

    return DateTime.now().setZone(tz).toFormat("ZZZZ");
  }

  headings = () => {
    return {
      Active: [
        {
          id: "program_name",
          label: "Promotion Name",
          customCell: (r, i) => {
            return (
              <td key={i} style={{ whiteSpace: "nowrap" }}>
                {r.title}
              </td>
            );
          }
        },
        {
          id: "program_category",
          label: "Promotion Type",
          customCell: (r, i) => {
            return (
              <td key={i} style={{ textTransform: "capitalize" }}>
                {r.promotion_type} (
                {r.product.program_name ||
                  r.product.membership_name ||
                  r.product.event_title ||
                  r.product.party_title}
                )
              </td>
            );
          }
        },
        {
          id: "program_category",
          label: "Publish",
          customCell: (r, i) => {
            return (
              <td key={i}>
                <Switch
                  checked={r.isPublished}
                  onChange={async () => await this.publishPromotion(r)}
                  name="checkedA"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </td>
            );
          }
        },
        {
          id: "updated",
          label: `Updated (${this.getTimeZone()})`,
          customCell: row => {
            const tz = this.global.organizationInfo.timezone;
            const startZonedTimeDisplay = format(
              utcToZonedTime(row.updatedAt, tz),
              "LLL dd, yyyy - h:mm a"
            );

            return (
              <td key={`${row.id}-start`}>
                { startZonedTimeDisplay }
              </td>
            );
          }
        },
        {
          id: "",
          label: "",
          customCell: (r, i) => (
            <td key={i}>
              <button
                disabled={this.canSubUserEdit()}
                style={{
                  cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                }}
                onClick={() => {
                  this.props.history.push(`/partners/promotion/setup/${r.id}`);
                }}
              >
                Edit
              </button>
            </td>
          )
        },
        {
          id: "active",
          label: "",
          customCell: (r, i) => (
            <td key={i}>
              {r.expiry_date &&
              isPast(
                new Date(r.expiry_date) ||
                  (r.promo_limit && r.promo_limit_times <= 0)
              ) ? (
                <>
                  {/* <Switch disabled /> */}
                  <Delete
                    // onClick={async () => await this.toggleActivePromotion(r)}
                    // data-for="archive"
                    // data-tip
                    disabled
                    style={{ color: "lightgrey" }}
                  />
                </>
              ) : (
                <>
                  {/* <Switch
                    checked={r.is_active}
                    onChange={async () => await this.toggleActivePromotion(r)}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  /> */}
                  <Delete
                    onClick={async () => {
                      this.setState({
                        toArchive: true,
                        showConfirmModal: true,
                        selectedItem: r
                      });
                    }}
                    data-for="archive"
                    data-tip
                    style={{ color: "lightgrey", cursor: "pointer" }}
                  />
                </>
              )}
            </td>
          )
        }
      ],
      Archive: [
        {
          id: "program_name",
          label: "Promotion Name",
          customCell: (r, i) => {
            return (
              <td key={i} style={{ whiteSpace: "nowrap" }}>
                {r.title}
              </td>
            );
          }
        },
        {
          id: "program_category",
          label: "Promotion Type",
          customCell: (r, i) => {
            return (
              <td key={i} style={{ textTransform: "capitalize" }}>
                {r.promotion_type}
              </td>
            );
          }
        },
        {
          id: "active",
          label: "",
          customCell: (r, i) => (
            <td key={i}>
              {r.expiry_date &&
              isPast(
                new Date(r.expiry_date) ||
                  (r.promo_limit && r.promo_limit_times <= 0)
              ) ? (
                <>
                  {/* <Switch disabled /> */}
                  <Restore
                    // onClick={async () => await this.toggleActivePromotion(r)}
                    // data-for="archive"
                    // data-tip
                    disabled
                    style={{ color: "lightgrey" }}
                  />
                </>
              ) : (
                <>
                  {/* <Switch
                    checked={r.is_active}
                    onChange={async () => await this.toggleActivePromotion(r)}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  /> */}
                  <Restore
                    onClick={async () => {
                      this.setState({
                        toArchive: false,
                        showConfirmModal: true,
                        selectedItem: r
                      });
                    }}
                    data-for="archive"
                    data-tip
                    style={{ color: "lightgrey", cursor: "pointer" }}
                  />
                </>
              )}
            </td>
          )
        }
      ]
    };
  };

  publishPromotion = async promotion => {
    const resp = await postPublishPromotion(promotion);

    if (resp.success) {
      this.fetchInitialData();
      // if (promotion.isPublished) {
      //   const newPromotion = { ...promotion, isPublished: false };
      //   const newActive = this.state.data.Active.filter(
      //     pr => pr.id !== promotion.id
      //   );
      //   // update state
      //   this.setState({
      //     data: {
      //       ...this.state.data,
      //       Active: [...newActive, newPromotion]
      //     }
      //   });
      // } else {
      //   const newPromotion = { ...promotion, isPublished: true };
      //   const newActive = this.state.data.Active.filter(
      //     pr => pr.id !== promotion.id
      //   );
      //   // update state
      //   this.setState({
      //     data: {
      //       ...this.state.data,
      //       Active: [...newActive, newPromotion].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
      //     }
      //   });
      // }
    }
  };

  /**
   * This function handles the edit button functionality of a sub user
   */
  canSubUserEdit = () => {
    return !(!this.global.dsUser.sub_user ||
      this.global?.dsUser?.accesspoints?.Listings?.Promotions?.edit);
  };

  async toggleActivePromotion(promotion) {
    const resp = await postPromotionToggle(promotion);
    if (resp.success) {
      if (promotion.is_active) {
        const newPromotion = { ...promotion, is_active: false };
        const newArchive = this.state.data.Archive.concat([newPromotion]);
        const newActive = this.state.data.Active.filter(
          pr => pr.id !== promotion.id
        );
        // update state
        this.setState({
          data: {
            ...this.state.data,
            Active: newActive,
            Archive: newArchive
          }
        });
      } else {
        const newPromotion = { ...promotion, is_active: true };

        const newArchive = this.state.data.Archive.filter(
          pr => pr.id !== promotion.id
        );
        const newActive = this.state.data.Active.concat([newPromotion]);
        // update state
        this.setState({
          data: {
            ...this.state.data,
            Active: newActive,
            Archive: newArchive
          }
        });
      }
    }
  }

  async componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    this.setGlobal({ loading: true });
    const resp = await axios.get(
      `${process.env.REACT_APP_API}/partners/promotions/manage/all`
    );
    console.log("resp", resp);
    if (resp.data.success) {
      const pros = resp.data.data;

      this.setState({ data: pros });
    }
    this.setGlobal({ loading: false });
  };

  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  render() {
    return (
      <div className="admin" data-testid="promotion-list">
        {this.state.iframeSetUp && (
          <AddIframeModal
            iframe={this.state.selectedIframe}
            open={this.state.iframeSetUp}
            type={this.state.type}
            active={this.state.active}
            onClose={() =>
              this.setState({
                iframeSetUp: false,
                selectedIframe: null,
                type: null,
                active: null
              })
            }
          />
        )}
        <div className="container-fluid adminprofiles">
          {this.state.showModal && (
            <CreatePromotionListModal
              // open={this.state.showModal}
              onClose={() => this.setState({ showModal: false })}
              history={this.props.history}
              // data={this.state.selectedPromo}
            />
          )}
          {this.state.showConfirmModal && (
            <ConfirmArchiveModal
              onSubmit={async () => {
                await this.toggleActivePromotion(this.state.selectedItem);
              }}
              onClose={_ => this.setState({ showConfirmModal: false })}
              toArchive={this.state.toArchive}
              modalBody={
                this.state.notAllowedToDelete
                  ? "Sorry, programs with customers cannot be deleted."
                  : ""
              }
              // notAllowed={this.state.notAllowedToDelete}
            />
          )}
          <div className="row cont">
            <div className="col-md-6">
              <div style={{ display: "block" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "-30px"
                  }}
                >
                  <h1 style={{ marginRight: "30px" }}>Promotions</h1>
                  <LinkIcon
                    style={{
                      transform: "scale(1.5) rotate(130deg)",
                      cursor: "pointer",
                      marginRight: "10px",
                      marginTop: "0.2px",
                      color: "black"
                    }}
                    onClick={_ =>
                      this.setState({
                        iframeSetUp: true,
                        active: "Promotions"
                      })
                    }
                    className="hovermyX"
                  />
                </div>
                <div
                  style={{ marginLeft: "-6px", marginTop: "4px" }}
                  className="switch-tooltip-group"
                >
                  <Switch
                    checked={
                      !!this.global.organizationInfo.display_promotions_listings
                    }
                    size="small"
                    onChange={_ => {
                      this.setGlobal({
                        organizationInfo: {
                          ...this.global.organizationInfo,
                          display_promotions_listings:
                            !this.global.organizationInfo
                              .display_promotions_listings
                        }
                      });
                      this.dispatch.saveOrganizationalProfile();
                    }}
                    color="primary"
                    disabled={this.canSubUserEdit()}
                  />
                  <i
                    data-tip
                    data-for="view-program-toggle"
                    className="fas fa-info-circle"
                    style={{ color: "#DCDCDC" }}
                  ></i>
                  <ReactTooltip
                    id="view-program-toggle"
                    place="right"
                    effect="solid"
                    type="info"
                  >
                    Toggles promotion tab visibility for users
                  </ReactTooltip>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  <button
                    data-testid="new-page"
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={() => this.setState({ showModal: true })}
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Create New
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="tablecont">
              <div className="table-responsive">
                <NewTabbedTable
                  tabs={this.tabs}
                  headings={this.headings()}
                  data={this.state.data}
                  actions={this.actions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PromotionList;
