import { createContext } from "react";

export function reducer(state, action) {
  switch (action.type) {
    // CARD 1
    case actions.UPDATE_TITLE:
      return { ...state, title: action.payload };
    case actions.UPDATE_DESCRIPTION:
      return { ...state, description: action.payload };
    case actions.UPDATE_DISCOUNT_TYPE:
      return { ...state, discountType: action.payload };
    case actions.UPDATE_DISCOUNT_AMOUNT:
      return { ...state, discountAmount: action.payload };
    case actions.UPDATE_DURATION_APPLIED:
      return { ...state, durationApplied: action.payload };
    case actions.UPDATE_DURATION_MONTHS:
      return { ...state, months: action.payload };
    // CARD 2
    case actions.UPDATE_SET_EXPIRY_DATE:
      return { ...state, isExpirySet: action.payload };
    case actions.UPDATE_EXPIRY_DATE:
      return { ...state, expiryDate: action.payload };
    case actions.UPDATE_SET_PROMO_USAGE:
      return { ...state, isLimitPromoCode: action.payload };
    case actions.UPDATE_SET_PROMO_ONLY:
      return { ...state, show_promo_only: action.payload };
    case actions.UPDATE_PROMO_USAGE:
      return { ...state, limitPromoCode: action.payload };
    case actions.UPDATE_CUSTOM_BILL_NAME:
      return { ...state, customBillName: action.payload };
    case actions.UPDATE_PROMOTION_TYPE:
      return { ...state, promotion_type: action.payload };
    case actions.SET_PROMOTION_TYPE_LIST:
      return { ...state, promotion_type_list: action.payload };
    case actions.SET_SELECTED_PROMOTION_TYPE:
      return { ...state, selectedPromotion: +action.payload };
    case actions.UPDATE_IS_ACTIVE:
      return { ...state, isActive: action.payload };
    case actions.SET_PLANS_LIST:
      return { ...state, plans: action.payload };
    case actions.ADD_SELECTED_PLAN:
      return {
        ...state,
        selectedPlans: [...state.selectedPlans, action.payload]
      };
    case actions.SET_SELECTED_PLAN:
      return { ...state, selectedPlans: action.payload };
    case actions.SET_PRICES_LIST:
      return { ...state, prices: action.payload };
    case actions.ADD_SELECTED_PRICES:
      return {
        ...state,
        selectedPrices: [...state.selectedPrices, action.payload]
      };
    case actions.SET_SELECTED_PRICES:
      return { ...state, selectedPrices: action.payload };
    case actions.SET_TICKETS_LIST:
      return { ...state, tickets: action.payload };
    case actions.ADD_SELECTED_TICKETS:
      return {
        ...state,
        selectedTickets: [...state.selectedTickets, action.payload]
      };
    case actions.SET_SELECTED_TICKETS:
      return { ...state, selectedTickets: action.payload };
    case actions.SET_ID:
      return { ...state, id: action.payload };
    default:
      return state;
  }
}

export const actions = {
  UPDATE_TITLE: "UPDATE_TITLE",
  UPDATE_DESCRIPTION: "UPDATE_DESCRIPTION",
  UPDATE_DISCOUNT_TYPE: "UPDATE_DISCOUNT_TYPE",
  UPDATE_DISCOUNT_AMOUNT: "UPDATE_DISCOUNT_AMOUNT",
  UPDATE_DURATION_APPLIED: "UPDATE_DURATION_APPLIED",
  UPDATE_DURATION_MONTHS: "UPDATE_DURATION_MONTHS",
  UPDATE_SET_EXPIRY_DATE: "UPDATE_SET_EXPIRY_DATE",
  UPDATE_EXPIRY_DATE: "UPDATE_EXPIRY_DATE",
  UPDATE_SET_PROMO_USAGE: "UPDATE_SET_PROMO_USAGE",
  UPDATE_PROMO_USAGE: "UPDATE_PROMO_USAGE",
  UPDATE_CUSTOM_BILL_NAME: "UPDATE_CUSTOM_BILL_NAME",
  UPDATE_PROMOTION_TYPE: "UPDATE_PROMOTION_TYPE",
  SET_PROMOTION_TYPE_LIST: "SET_PROMOTION_TYPE_LIST",
  SET_SELECTED_PROMOTION_TYPE: "SET_SELECTED_PROMOTION_TYPE",
  UPDATE_IS_ACTIVE: "UPDATE_IS_ACTIVE",
  ADD_SELECTED_PLAN: "ADD_SELECTED_PLAN",
  ADD_SELECTED_TICKETS: "ADD_SELECTED_TICKETS",
  SET_SELECTED_TICKETS: "SET_SELECTED_TICKETS",
  SET_SELECTED_PLAN: "SET_SELECTED_PLAN",
  SET_PLANS_LIST: "SET_PLANS_LIST",
  SET_PRICES_LIST: "SET_PRICES_LIST",
  SET_TICKETS_LIST: "SET_TICKETS_LIST",
  SET_SELECTED_PRICES: "SET_SELECTED_PRICES",
  SET_ID: "SET_ID",
  UPDATE_SET_PROMO_ONLY: "UPDATE_SET_PROMO_ONLY"
};

// dispacth actions
export const set_id_action = (payload, dispatch) => {
  dispatch({
    type: actions.SET_ID,
    payload
  });
};

export const set_title_action = (payload, dispatch) => {
  dispatch({
    type: actions.UPDATE_TITLE,
    payload
  });
};

export const set_description_action = (payload, dispatch) => {
  dispatch({
    type: actions.UPDATE_DESCRIPTION,
    payload
  });
};

export const set_promotion_type = (payload, dispatch) => {
  dispatch({
    type: actions.UPDATE_PROMOTION_TYPE,
    payload
  });
};

export const set_promotion_type_list = (payload, dispatch) => {
  dispatch({
    type: actions.SET_PROMOTION_TYPE_LIST,
    payload
  });
};

export const set_discount_type = (payload, dispatch) => {
  dispatch({
    type: actions.UPDATE_DISCOUNT_TYPE,
    payload
  });
};

export const set_discount_amount = (payload, dispatch) => {
  dispatch({
    type: actions.UPDATE_DISCOUNT_AMOUNT,
    payload
  });
};

export const set_duration_applied = (payload, dispatch) => {
  dispatch({
    type: actions.UPDATE_DURATION_APPLIED,
    payload
  });
};

export const set_expiry_date_toggle = (payload, dispatch) => {
  dispatch({
    type: actions.UPDATE_SET_EXPIRY_DATE,
    payload
  });
};
export const set_expiry_date = (payload, dispatch) => {
  dispatch({
    type: actions.UPDATE_EXPIRY_DATE,
    payload
  });
};

export const set_promo_limit_toggle = (payload, dispatch) => {
  dispatch({
    type: actions.UPDATE_SET_PROMO_USAGE,
    payload
  });
};

export const set_show_promo_only_toggle = (payload, dispatch) => {
  console.log("payload", payload);
  dispatch({
    type: actions.UPDATE_SET_PROMO_ONLY,
    payload
  });
};

export const set_promo_limit_number = (payload, dispatch) => {
  dispatch({
    type: actions.UPDATE_PROMO_USAGE,
    payload
  });
};
export const set_tickets_action = (payload, dispatch) => {
  dispatch({
    type: actions.SET_TICKETS_LIST,
    payload
  });
};
export const set_selected_tickets_action = (selected_tickets, dispatch) => {
  dispatch({
    type: actions.SET_SELECTED_TICKETS,
    payload: selected_tickets
  });
};

export const set_selected_prices_action = (seleted_prices, dispatch) => {
  dispatch({
    type: actions.SET_SELECTED_PRICES,
    payload: seleted_prices
  });
};

export const set_prices_action = (prices, dispatch) => {
  dispatch({
    type: actions.SET_PRICES_LIST,
    payload: prices
  });
};

export const set_seleted_plans_action = (seleted_plans, dispatch) => {
  dispatch({
    type: actions.SET_SELECTED_PLAN,
    payload: seleted_plans
  });
};

export const set_plans_action = (plans, dispatch) => {
  dispatch({
    type: actions.SET_PLANS_LIST,
    payload: plans
  });
};

export const discountTypes = {
  FIXED_AMOUNT: "Fixed Amount",
  PERCENTAGE: "Percentage"
};
export const discountDurationTypes = {
  ONCE: "Once",
  FOREVER: "Forever",
  MULTIPLE_MONTHS: "Multiple Months"
};

export const initial_state = {
  id: null,
  // CARD 1
  title: "",
  description: "",
  discountType: discountTypes.PERCENTAGE,
  discountAmount: 0,
  durationApplied: discountDurationTypes.ONCE,
  months: 0,
  // CARD 2
  isExpirySet: false,
  expiryDate: new Date(),
  isLimitPromoCode: false,
  limitPromoCode: 10,
  customBillName: "",
  promotion_type: "",
  promotion_type_list: [],
  selectedPromotion: -1, // represents the id from the promotion_type_list
  isActive: true,
  selectedPlans: [],
  selectedPrices: [],
  selectedTickets: [],
  plans: [],
  prices: [],
  tickets: [],
  show_promo_only: false
};

export const typeOptions = [
  {
    value: "program",
    display: "Program"
  },
  {
    value: "event",
    display: "Event"
  },
  {
    value: "membership",
    display: "Membership"
  },
  {
    value: "online",
    display: "Online"
  },
  {
    value: "party",
    display: "Party"
  }
];

export const PromotionContext = createContext(initial_state);
