import React, { useState, useGlobal, useEffect } from "reactn";
import { GREEN_BUTTON, RED_BUTTON, WHITE } from "./baseModal/colours";
import axios from "axios";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK } from "./baseModal/FieldTypes";

const ApplicationDeleteModal = ({ onClose, program, displayCategory, canSubUserEdit }) => {
  const deleteApplication = async () => {
    let ep;
    if (displayCategory === "Programs") {
      ep = `${process.env.REACT_APP_API}/partners/programs/customers/silent_archive/${program.id}`;
    }
    if (displayCategory === "Events") {
      ep = `${process.env.REACT_APP_API}/partners/events/customers/silent_archive/${program.id}`;
    }
    if (displayCategory === "Memberships") {
      ep = `${process.env.REACT_APP_API}/partners/memberships/customers/silent_archive/${program.id}`;
    }
    if (displayCategory === "Online Virtual") {
      ep = `${process.env.REACT_APP_API}/partners/virtual/customers/silent_archive/${program.id}`;
    }
    const res = await axios.post(ep);
    if (res.data.success) {
      toast.success("Application archived successfully");
      onClose();
    } else {
      toast.error("Error archived application");
    }
  }

  const fields = [
    {
      type: CONTENT_BLOCK,
      data: {
        name: "Warning",
        content: "This action will archive this application without notifying the client. This action cannot be undone. Are you sure you want to archive this application?",
      }
    }
  ]

  const buttons = [
    {
      name: "Archive",
      handleClick: deleteApplication,
      buttonColour: RED_BUTTON,
      textColour: WHITE,
      isDisabled: canSubUserEdit
    }
  ];

  return <BaseModal title="Archive Application" buttons={buttons} handleClose={onClose} fields={fields} />;


};

export default ApplicationDeleteModal;