import React from "reactn";
import { format } from "date-fns";
import Doughnut from "../charts/Doughnut";

const FranchiserRecurringPaymentsModal = ({ partnerOrg }) => {
  const dsUser = partnerOrg.user;
  const paymentInfo = partnerOrg.paymentInfo;
  console.log("partnerOrg: ", partnerOrg);
  const getInfoComponent =(amount, infoType) => {
    return (
      <p
      style={{
        color: "#a1a1a1",
        paddingBottom: "5px",
        fontStyle: "italic",
        fontSize: "0.9rem"
      }}
      >
        {infoType} (to-date):{" "}
        {infoType === "Refunds" 
          ? amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD"
          })
          : amount.toLocaleString("en-US")
        }
      </p>
    );
  };


  const getAmountComponent = amount => {
    return <h3>${amount}</h3>;
  };

  return (
    <div className="boxes" style={{display: "flex", alignItems:"flex-start"}}>
      <div className="box" style={{maxWidth: "calc(30%)"}}>
        <h2>PAYMENTS BY FREQUENCY</h2>
        <div
          className="info"
          style={{ padding:"20px" }}
        >
          <div style={{marginRight: "5px"}}>
            <Doughnut labels={Object.keys(paymentInfo.frequencyData)} data={Object.values(paymentInfo.frequencyData)}/>
          </div>
          <p>
            Since{" "}
            {dsUser && dsUser.createdAt ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
          </p>
        </div>
      </div>
      <div className="box">
        <h2>LATE RATE</h2>
        <div
          className="info"
          style={{ paddingTop: "20px", paddingBottom: "20px", flexGrow: "0"}}
        >
          <h3>
            {paymentInfo && paymentInfo.lateCount && paymentInfo.totalCount
              ? (paymentInfo.lateCount / paymentInfo.totalCount * 100).toFixed(2) 
              : "0"}%
          </h3>
          {getInfoComponent(paymentInfo.lateCount ? paymentInfo.lateCount : "0", "Late Payments")}
          <p>
            Since{" "}
            {dsUser && dsUser.createdAt ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
          </p>
        </div>
      </div>
      <div className="box" style={{maxWidth: "calc(27%)", flexGrow: "0"}}>
        <h2>TOTAL RECURRING PAYMENT EARNINGS</h2>
        <div
          className="info"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          {getAmountComponent(
            paymentInfo.totalPayments
              ? parseFloat(paymentInfo.totalPayments || 0)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          )}
          {getInfoComponent(
            paymentInfo.refunds
              ? paymentInfo.refunds
              : "0.00", "Refunds" 
          )}
          <p>
            Since{" "}
            {dsUser && dsUser.createdAt ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FranchiserRecurringPaymentsModal;
