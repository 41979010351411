import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { Button } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";

class AddMilestoneParticipantModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // selectedFile: null
      progress: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  uploadNew = () => {
    const uploadEle = document.getElementById("uploadFile");
    uploadEle.click();
  };

  fileChange = async e => {
    if (e.target.files.length && e.target.files[0]) {
      const fd = new FormData();
      fd.set("file", e.target.files[0]);
      const ep = `${process.env.REACT_APP_API}/partners/milestones_submission/file/upload`;
      const postreq = await axios.post(ep, fd, {
        onUploadProgress: progressEvent => {
          let progress =
            Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
            "%";
          this.setState({ progress: progress });
        }
      });

      if (postreq.data.success) {
        const location = { name: postreq.data.name, path: postreq.data.path };
        this.setState({ location: location });
        return;
      }
    }
  };

  addParticipant = async () => {
    // send data to add route

    const dataSubmission = {
      milestoneId: this.props.milestoneId,
      programId: this.props.programId,
      onlineId: this.props.onlineId,
      journal: this.state.journal,
      file_url: this.state.location.path,
      childId: parseInt(this.state.childId),
      parentId: parseInt(this.state.parentId)
    };

    const ep = `${process.env.REACT_APP_API}/partners/milestones_submission/add`;
    const submitMilestoneData = await axios.post(ep, dataSubmission);

    if (submitMilestoneData.data.success) {
      this.props.onClose();
    }
  };

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <img
            src={ModalBanner}
            alt="Modal Banner"
            style={{
              height: "40px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>Add Participant</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="parentId"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Participant
                </label>
                <select
                  name="parentId"
                  id="parentId"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                >
                  <option value="">Please Select :</option>
                  {this.props.enrollments.map(e => {
                    return (
                      <option key={e.dsUserId} value={e.dsUserId}>
                        {e.displayName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="childId"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Child
                </label>
                <select
                  name="childId"
                  id="childId"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                >
                  <option value="">Please Select :</option>
                  {this.props.enrollments.map(e => {
                    return (
                      <option key={e.childId} value={e.childId}>
                        {e.childName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="journal"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Journal
                </label>
                <textarea
                  name="journal"
                  id="journal"
                  type="text"
                  value={this.state.journal}
                  onChange={e => this.setState({ journal: e.target.value })}
                  className="form-control"
                  style={{ width: "70%" }}
                  rows="4"
                  columns="12"
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="uploaded_expense"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  File Upload
                </label>
                <div
                  style={{
                    marginLeft: "25px",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Button
                    component="span"
                    color="primary"
                    size="small"
                    startIcon={<AttachFileIcon />}
                    onClick={_ => this.uploadNew()}
                  >
                    Attach File
                  </Button>
                  {this.state.location !== undefined && (
                    <div
                      style={{
                        marginLeft: "15px",
                        fontSize: "12px",
                        color: "#7b7b90"
                      }}
                    >
                      Upload Success
                    </div>
                  )}
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="uploadFile"
                    onChange={e => this.fileChange(e)}
                    // accept="*"
                  />
                </div>
              </div>
            </form>

            <div className="modal-footer">
              <button
                type="submit"
                onClick={_ => this.addParticipant()}
                className="btn profile-btn"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddMilestoneParticipantModal);
