import React from "reactn";
import { Redesign_Logo } from "../../../../assets";
import "./tosForm.css";

class ConfirmProgramForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dsContractAccepted: false
    };
    this.saveApplication = this.saveApplication.bind(this);
  }

  saveApplication = async () => {
    try {
      if (this.state.dsContractAccepted) {
        await this.dispatch.saveProgram(true);
        this.props.onClose();
      } else {
      }
    } catch (ex) {}
  };

  render() {
    return (
      <div className="tos-modal-cont">
        <div className="tos-modal">
          <img
            className="school-logo"
            src={Redesign_Logo}
            alt="Dreamschools"
            style={{ width: "100%" }}
          />
          <p>
            By submitting your application you acknowledge that any further
            modifications that are required will need to be done by contacting a
            member of the Dreamschools team as future modifications will not be
            possible. The Dreamschools team will be hard at work verifying
            everything and getting your program ready for the front page.
          </p>
          <input
            type="checkbox"
            name="dsContractAccepted"
            id="dsContractAccepted"
            checked={this.state.dsContractAccepted ? "checked" : ""}
            onChange={() => {
              this.setState({
                dsContractAccepted: !this.state.dsContractAccepted
              });
            }}
          />{" "}
          <label htmlFor="dsContractAccepted">
            I have read and acknowledged the above and confirm that the
            information in my Program Setup is accurate
          </label>
          <br />
          <button onClick={this.props.onClose} className="mx-2 backbtn">
            Save for Later
          </button>
          <button className="mx-2" onClick={() => this.saveApplication()}>
            Accept &amp; Continue
          </button>
        </div>
      </div>
    );
  }
}

export default ConfirmProgramForm;
