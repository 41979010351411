import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";

class RejectModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      message: ""
    };
    this.handleReject = this.handleReject.bind(this);
  }

  handleReject = async () => {
    this.setGlobal({ loading: true });
    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/edu/enrollment/application/${params.slug}/${params.time}`;
    try {
      const res = await axios.post(ep, {
        action: "Reject",
        message: this.state.message
      });
      this.setGlobal({ loading: false, lastAPICall: res });
      if (res.data.success) {
        this.props.history.push("/educators/applications");
      }
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  };

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal" style={{ border: "2px solid #df4e4e" }}>
          <h3>Reject</h3>
          <label htmlFor="message">Reject Reason</label>
          <textarea
            name="message"
            id="message"
            type="text"
            className="form-control"
            placeholder="Leave an optional message for the parent..."
            onChange={e => {
              this.setState({
                [e.target.name]: e.target.value
              });
            }}
          />
          <div className="modal-footer">
            <button
              onClick={this.props.onClose}
              className="mx-2 backbtn btn profile-btn"
            >
              Cancel
            </button>
            <button
              className="btn profile-btn"
              onClick={() => this.handleReject()}
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RejectModal);
