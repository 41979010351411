import React from "reactn";
import PartnerRoute from "../../routes/partnerRoute";
import Sidebar from "../common/SideBarPartner";
import TopBar from "../common/TopBar";

import SideMenu from "./SideMenu";

class PartnerLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.basePath = "/partners";

    this.state = {
      Routes: []
    };
  }

  async componentDidMount() {
    if (localStorage.getItem("ds_token") && !this.global.dsUser) {
      await this.dispatch.validateTokenPartner();

      if (!this.global.dsUser || this.global.dsUser.accountType !== "Partner") {
        this.props.history.push("/");
      }
    } else if (!localStorage.getItem("ds_token") && !this.global.dsUser) {
      this.props.history.push(
        `/sign-in?follow=${this.props.location.pathname}`
      );
    }
  }

  render() {
    const { path } = this.props.match;
    return (
      <>
        {this.global.dsUser && this.global.dsUser.accountType === "Partner" ? (
          <>
            <Sidebar path={path} Menu={SideMenu} />
            <div
              id="content-wrapper"
              className="d-flex flex-column"
              onClick={() => this.setGlobal({ sidenavShown: false })}
            >
              <div id="content">
                <TopBar ConsoleRoute={this.basePath} />

                {this.global.routes.map((route, i) => (
                  <PartnerRoute
                    educator={false}
                    key={i}
                    partner={true}
                    exact
                    path={`${this.basePath}${route.path}`}
                    component={route.component}
                  />
                ))}
              </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
              <i className="fas fa-angle-up" />
            </a>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default PartnerLayout;
