import React, { useState, useEffect, useGlobal } from "reactn";
import axios from "axios";

import { canUserCreate } from "../../utilities/listingCreation";

import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import BaseModal from "./baseModal/BaseModal";
import { SWITCH, TITLE } from "./baseModal/FieldTypes";

const CreateMembershipModal = ({
  onClose,
  history,
  dispatchEvent,
  setGlobal,
  global,
  dispatch
}) => {
  const [manualInvoices, setManualInvoices] = useState(false);
  const [applicationRequired, setApplicationRequired] = useState(false);
  const [displayManualInvoices, setDisplayManualInvoices] = useState(false);
  const [allowSameEnrollment, setAllowSameEnrollment] = useState(true);

  const [canUserMake, setCanUserMake] = useState(false);
  const [loading, setLoading] = useGlobal("loading");

  async function checkManual() {
    const feature_category = "Customization";
    const ep = `${process.env.REACT_APP_API}/partner/permissions/${feature_category}`;
    const resp = await axios.get(ep);

    if (resp.data) {
      const customizationPermissions = resp.data.data.filter(
        e => e.feature === "Accept Manual Payments"
      );
      const checkManual =
        customizationPermissions.length > 0
          ? customizationPermissions[0].has_access
          : false;
      setDisplayManualInvoices(checkManual);
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch.resetEvent();
      await checkManual();

      // Check if user can create more listings.
      const resp = await canUserCreate("membership");
      setCanUserMake(resp.success);
      setLoading(false);
    })();
  }, []);

  const getBaseModalFields = () => {
    const offlinePaymentsField = {
      type: SWITCH,
      data: {
        name: "Offline Payments",
        checked: manualInvoices,
        handleClick: () => setManualInvoices(!manualInvoices),
        info: "Allows your client to pass through checkout without paying online"
      }
    };

    const applicationRequiredField = {
      type: SWITCH,
      data: {
        name: "Application Required",
        checked: applicationRequired,
        handleClick: () => setApplicationRequired(!applicationRequired)
      }
    };

    const allFields = [];
    if (displayManualInvoices) {
      allFields.push(offlinePaymentsField);
    }
    allFields.push(applicationRequiredField);

    return allFields;
  };

  const getBaseModalButtons = () => {
    const createButton = {
      name: "Create",
      handleClick: () => {
        history.push({
          pathname: "/partners/memberships/setup",
          state: {
            manualInvoices: manualInvoices,
            applicationRequired: applicationRequired,
            allowSameEnrollment: allowSameEnrollment
          }
        });
      },
      buttonColour: BLUE_BUTTON,
      textColour: WHITE
    };
    return [createButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Create Membership",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: () => {
        console.log({
          manualInvoices,
          applicationRequired,
          displayManualInvoices,
          canUserMake
        });
        onClose();
      }
    };
  };

  const getLimitReachedModal = () => {
    const props = {
      title: "Create Membership",
      fields: [
        {
          type: TITLE,
          data: { name: "You have reached your membership limit." }
        }
      ],
      handleClose: onClose
    };
    return <BaseModal {...props} />;
  };

  if (canUserMake) {
    return <BaseModal {...getBaseModalProps()} />;
  } else {
    return getLimitReachedModal();
  }
};

export default CreateMembershipModal;
