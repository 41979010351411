import React from "reactn";
import BarChart from "../../../../components/charts/Bar";

class EarningsByListingType extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render () {
    const { earnings } = this.props;
    let chart = {
      Programs: 0,
      Events: 0,
      Online: 0,
      Memberships: 0,
      Parties: 0
    };
    if (earnings) {
      let extra = 0;
      console.log("earnings", earnings);
      earnings.map((order) => {
        let amount = (parseFloat(order.amount) / 100);
        switch(order.product_table) {
          case ('partner_programs'):
            chart.Programs += amount;
            break;
          case ('partner_event'):
            chart.Events += amount;
            break;
          case ('partner_online'):
            chart.Online += amount;
            break;
          case ('partner_memberships'):
            chart.Memberships += amount;
            break;
          case ('partner_party'):
            chart.Parties += amount;
            break;
          default:
            extra += amount;
            break;
        }
      });
      console.log("Extra earnings: ", extra);
    }

    return (
      <div className="col-xl-6 col-xl-6-new">
        <div className="tablebox">
          <div className="tableheader">
            <h2>
              Earnings By Listing Type
            </h2>
          </div>
          <div className="tablebody padded">
            <div className="chart-area">
              {this.props.earnings ? (
                <BarChart
                  data={Object.values(chart).map((value) => value.toFixed(2))}
                  labels={Object.keys(chart)}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EarningsByListingType;