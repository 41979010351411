import React from "react";
import moment from "moment";

/**
 * Two date inputs to represent a date range.
 *
 * @param {String} dateFrom Date string in the form "YYYY-MM-DD"
 * @param {function: (date) => void} setDateFrom Function to set dateFrom.
 * @param {String} dateTo Date string in the form "YYYY-MM-DD"
 * @param {function: (date) => void} setDateTo Function to set dateTo.
 */
const DateFilter = ({ dateFrom, setDateFrom, dateTo, setDateTo }) => {
  return (
    <div className="filter-item">
      <label htmlFor="date_range" className="filter-label">
        Date Range
      </label>
      <input
        name="date_range"
        id="date_range"
        type="date"
        className="form-control date-range-1"
        onChange={e => {
          if (e.target.value === "") {
            const lastMonth = moment().subtract(1, "month");
            setDateFrom(lastMonth.format("YYYY-MM-DD"));
          } else {
            setDateFrom(moment(e.target.value).format("YYYY-MM-DD"));
          }
        }}
        value={dateFrom}
      />
      <div className="date-range-to">
        <input
          name="date_range"
          id="date_range"
          type="date"
          className="form-control date-range-2"
          onChange={e => {
            if (e.target.value === "") {
              const currentMonth = moment();
              setDateTo(currentMonth.format("YYYY-MM-DD"));
            } else {
              setDateTo(moment(e.target.value).format("YYYY-MM-DD"));
            }
          }}
          value={dateTo}
        />
      </div>
    </div>
  );
};

export default DateFilter;
