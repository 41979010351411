import ModalTemplate from "./ModalTemplate";

import ModalInputGroup from "./ModalInputGroup";
import ModalCustomGroup from "./ModalCustomGroup";
import ModalAreaGroup from "./ModalAreaGroup";
import ModalSwitchGroup from "./ModalSwitchGroup";
import ModalDisplayGroup from "./ModalDisplayGroup";
import ModalSelectGroup from "./ModalSelectGroup";

const ModalFields = {
  input: ModalInputGroup,
  textarea: ModalAreaGroup,
  switch: ModalSwitchGroup,
  custom: ModalCustomGroup,
  display: ModalDisplayGroup,
  select: ModalSelectGroup
};

export { ModalTemplate, ModalFields };
