import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { addWeeks, format, subMonths, subWeeks, addMonths } from "date-fns";
import { addDays } from "date-fns/esm";
import { toast } from "react-toastify";
import _, { toInteger } from "lodash";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  DATE_PICKER,
  IMAGE_DROPDOWN,
  GET_HTML,
  SWITCH,
  DROPDOWN
} from "./baseModal/FieldTypes";
import Title from "./baseModal/fieldComponents/Title.jsx";
import DatePicker from "react-datepicker";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class EditSemesterPlanModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      startDate: this.props.selectedItem.startDate ? new Date(this.props.selectedItem.startDate) : new Date(),
      endDate: null,
      price_per_day: null,
      total_price: null,
      addOn: false,
      addOnItems: [{}],
      isInstallmentsAllowed: false,
      installments: false,
      installments_plan: null,
      installments_breakdown: null,
      installments_interest: "0",
      custom_date1: new Date(this.props.customDate1),
      custom_date2: new Date(this.props.customDate2),
      custom_date3: new Date(this.props.customDate3),
      custom_date4: new Date(this.props.customDate4),
      custom_date5: new Date(this.props.customDate5),
      custom_date6: new Date(this.props.customDate6),
      includeDeposit: false,
      deposit: "0",
      dropIns: false,
      dropin_interest: 0,
      isDescriptionEnabled: false,
      description: "",
      isTaxEnabled: false,
      isCapEnabled: false,
      taxInfo: [],
      planCap: "",
      addsOnFee: 0
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  componentDidMount() {
    this.setState({
      ...this.props.selectedItem
    });
    this.checkInstallmentFeature();
    this.getTotalDays();
  }

  checkInstallmentFeature = async () => {
    const ep = `${process.env.REACT_APP_API}/partner/permissions/feature/enable-installments`;
    const results = await axios.get(ep);
    if (results.data?.success && results.data.data?.has_access) {
      this.setState({ isInstallmentsAllowed: true });
    }
  };

  updateAddOn(e) {
    const obj = this.state.addOnItems[0];
    obj[e.target.name] = e.target.value;
    obj["id"] = `${this.state.addOnItems.length}`;
    this.setState({ addOnItems: [obj] });
  }

  getTotalDays() {
    const days = {
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
      0: "Sunday"
    };
    const timeFrame = [
      new Date(this.global.program.program_start),
      addWeeks(
        new Date(this.global.program.program_start),
        this.global.program.number_of_weeks
      )
    ];
    let closures = [];
    if (
      this.global.program.closure_timeframe[0] &&
      this.global.program.closure_timeframe[1]
    ) {
      let closuredate = new Date(this.global.program.closure_timeframe[0]);
      while (
        closuredate.toDateString() !==
        addDays(
          new Date(this.global.program.closure_timeframe[1]),
          1
        ).toDateString()
      ) {
        closures.push(format(closuredate, "LLLL dd, yyyy"));
        closuredate = addDays(closuredate, 1);
      }
    }
    let date = timeFrame[0];
    let daycount = 0;
    const okdays = [];
    while (date.toDateString() !== timeFrame[1].toDateString()) {
      if (
        this.global.program.days_of_week[days[date.getDay()]] &&
        !this.global.program.closure_dates.includes(
          format(date, "LLLL dd, yyyy")
        ) &&
        !closures.includes(format(date, "LLLL dd, yyyy"))
      ) {
        okdays.push(date);
        daycount++;
      }
      date = addDays(date, 1);
    }
    this.setState({ dayCount: daycount, available_days: okdays });
  }

  async savePlan() {
    if (!this.global.organizationInfo.stripeId) {
      toast.error("Denied. Please connect your Bank Account first");
      return;
    }
    if (!this.state.total_price || this.state.total_price <= 0) {
      toast.error("Please enter a valid price.");
      return;
    }
    if (this.state.isTaxEnabled && this.state.taxInfo.length == 0) {
      toast.error("Please select a tax plan, otherwise disable taxes");
      return;
    }
    if (this.state.installments && !this.state.installments_plan) {
      toast.error("Please select an installment cycle");
      return;
    }
    if (this.state.installments && !this.state.installments_breakdown) {
      toast.error("Please select an installment breakdown");
      return;
    }
    if (
      (this.state.installments && this.state.installments_interest < 0) ||
      this.state.installments_interest > 20
    ) {
      toast.error("Invalid interest rate for premium charge.");
      return;
    }
    const obj = { ...this.state, type: "Semester" };
    if (this.state.installments && this.state.installments_interest === "") {
      obj.installments_interest = 0;
    }
    if (!this.state.installments) {
      delete obj.installments;
      delete obj.installments_plan;
      delete obj.installments_breakdown;
      delete obj.installments_interest;
      delete obj.custom_date1;
      delete obj.custom_date2;
      delete obj.custom_date3;
      delete obj.custom_date4;
      delete obj.custom_date5;
      delete obj.custom_date6;
      delete obj.deposit;
      delete obj.includeDeposit;
    }
    if (!this.state.dropIns) {
      delete obj.available_days;
    }
    if (!this.state.addOn) {
      delete obj.addOn;
      delete obj.addOnItems;
    }

    if (
      this.global.program.total_spots &&
      this.global.program.total_spots < parseInt(obj.planCap)
    ) {
      obj.planCap = this.global.program.total_spots;
    } else {
      if (parseInt(obj.planCap) > 200) {
        obj.planCap = 200;
      }
    }
    // obj.total_price = parseFloat(obj.total_price).toFixed(2);

    const parsedPlans = this.global.program.plans.map(e =>
      typeof e === "string" ? JSON.parse(e) : e
    );
    const updatedPlans = parsedPlans.map(e =>
      e.id === obj.id ? { ...obj } : e
    );

    this.setGlobal(
      {
        program: {
          ...this.global.program,
          plans: updatedPlans
        }
      },
      () => {
        this.dispatch.saveProgram();
      }
    );

    this.props.onClose();
  }

  taxesOptions = () => {
    //const global_organization = [JSON.parse(this.global.organizationInfo.stripeTaxId)]
    const global_organization = this.global.organizationInfo.stripeTaxId;
    console.log("global_organization", global_organization);
    const DATA = [];
    for (let i = 0; i < global_organization.length; i++) {
      DATA.push(JSON.parse(global_organization[i]));
    }
    console.log("DATA", DATA);

    return [
      ...DATA.map((e, i) => ({
        value: i,
        text: e.display_name + " " + e.jurisdiction + e.percentage + "%"
      }))
    ];
  };

  optionsFunc = () => {
    return this.state.addOnItems.map((e, i) => (
      <div
        className="form-group"
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 0
        }}
        key={i}
      >
        <input
          name="add-on-name"
          type="text"
          className="string-input-field field-component-input-box"
          placeholder={`Fee Name`}
          style={{
            width: "150px",
            marginRight: "10px"
          }}
          onChange={e => {
            {
              const options = [...this.state.addOnItems];
              options[i] = {
                text: e.target.value,
                value: options[i].value
              };
              this.setState({ addOnItems: options });
            }
          }}
          disabled
          value={this.state.addOnItems[i].text || ""}
        />
        <input
          name="add-on-amount"
          type="number"
          className="string-input-field field-component-input-box"
          placeholder={`Amount`}
          style={{
            width: "150px",
            marginRight: "10px"
          }}
          disabled
          onChange={e => {
            {
              const options = [...this.state.addOnItems];
              options[i] = {
                text: options[i].text,
                value: e.target.value
              };
              this.setState({ addOnItems: options });
            }
          }}
          value={this.state.addOnItems[i].value || ""}
        />
        <button
          type="button"
          onClick={_ =>
            this.setState({
              addOnItems: [...this.state.addOnItems, {}]
            })
          }
          style={{
            backgroundColor: "yellowgreen",
            border: "none",
            color: "white",
            borderRadius: "3px",
            marginRight: "10px",
            marginTop: "8px"
          }}
          disabled
        >
          +
        </button>

        {i !== 0 && (
          <button
            style={{
              width: "8%",
              backgroundColor: "red",
              border: "none",
              color: "white",
              borderRadius: "3px",
              marginTop: "8px"
            }}
            type="button"
            onClick={_ =>
              this.setState({
                addOnItems: this.state.addOnItems.filter(
                  (_, index) => index !== i
                )
              })
            }
            disabled
          >
            -
          </button>
        )}
      </div>
    ));
  };

  getBaseModalFields = () => {
    let totalAddOnFee = 0;
    this.state.addOnItems.map(fee => {
      if (fee.value) {
        totalAddOnFee += parseFloat(fee.value);
      }
    });

    const descriptionInput = {
      type: STRING_INPUT,
      data: {
        name: `Price (${
          isNaN(this.state.dayCount) ? "#" : this.state.dayCount
        }) Days`,
        type: "number",
        required: true,
        isDisabled: false,
        handleChange: e => {
          this.setState({
            total_price: e.target.value
          });
        },
        value:
          this.state.total_price ||
          this.state.price_per_day * this.state.dayCount ||
          "",
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      },
      validators: { validateSubmit: () => this.state.total_price !== null }
    };

    const emailInput = {
      type: STRING_INPUT,
      data: {
        name: "Price / Day",
        required: true,
        isDisabled: false,
        type: "number",
        handleChange: e => {
          this.setState({
            price_per_day: e.target.value,
            total_price: e.target.value * this.state.dayCount
          });
        },
        value:
          this.state.price_per_day ||
          (this.state.total_price / this.state.dayCount).toFixed(2) ||
          "",
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      },
      validators: { validateSubmit: () => this.state.total_price !== null }
    };

    const addDescription = {
      type: SWITCH,
      data: {
        name: "Add Description",
        checked: this.state.isDescriptionEnabled,
        isDisabled: this.props.mode === "View" ? true : false,
        handleClick: () => {
          this.setState({
            isDescriptionEnabled: !this.state.isDescriptionEnabled
          });
        }
      }
    };

    const description = {
      type: STRING_INPUT,
      data: {
        name: "Description",
        required: false,
        isDisabled: this.props.mode === "View" ? true : false,
        maxLength: 32,
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description
      }
    };

    const addsonFee = {
      type: SWITCH,
      data: {
        name: "Adds-On Fee",
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        checked: this.state.addOn,
        isDisabled: true,
        handleClick: e => this.setState({ addOn: e.target.checked })
      }
    };

    const addOnOptionField = {
      type: GET_HTML,
      data: {
        gethtml: this.optionsFunc,
        isDisabled: true
      }
    };

    const installmentsField = {
      type: SWITCH,
      data: {
        name: "Enable Installments",
        isDisabled: this.props.mode === "View" ? true : false,
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        checked: this.state.installments,
        handleClick: e => {
          this.setState({ installments: e.target.checked });
          if (e.target.checked === false) {
            this.setState({
              installments_plan: null,
              installments_breakdown: null,
              installments_interest: 0
            });
          }
        }
      }
    };

    const installmentPlan = {
      type: DROPDOWN,
      data: {
        name: "Installment Cycle",
        isDisabled: this.props.mode === "View" ? true : false,
        placeholder: "Select Cycle...",
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        value: this.state.installments_plan,
        handleChange: e => this.setState({ installments_plan: e.target.value }),
        choices: [
          { text: "Weekly", value: "Weekly" },
          { text: "Bi-Weekly", value: "Bi-Weekly" },
          { text: "Monthly", value: "Monthly" },
          { text: "Custom Dates", value: "Custom" }
        ]
      }
    };

    const installmentBreakdown = {
      type: DROPDOWN,
      data: {
        name: "Number of Installments",
        isDisabled: this.props.mode === "View" ? true : false,
        placeholder: "Select Number...",
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        value: this.state.installments_breakdown,
        handleChange: e =>
          this.setState({ installments_breakdown: e.target.value }),
        choices: [
          { text: "1 Payments", value: 1 },
          { text: "2 Payments", value: 2 },
          { text: "3 Payments", value: 3 },
          { text: "4 Payments", value: 4 },
          { text: "5 Payments", value: 5 },
          { text: "6 Payments", value: 6 }
        ]
      }
    };

    const installmentInterest = {
      type: STRING_INPUT,
      data: {
        name: "Premium Charge",
        isDisabled: this.props.mode === "View" ? true : false,
        placeholder: "Enter Interest",
        info: "Max (20%)",
        type: "number",
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        value: this.state.installments_interest,
        handleChange: e =>
          this.setState({ installments_interest: e.target.value })
      }
    };

    const depositToggle = {
      type: SWITCH,
      data: {
        name: "Include Deposit",
        checked: this.state.includeDeposit,
        isDisabled: this.props.mode === "View" ? true : false,
        handleClick: e =>
          this.setState({ includeDeposit: e.target.checked, deposit: "0" }),
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const depositField = {
      type: STRING_INPUT,
      data: {
        required: false,
        type: "number",
        isDisabled: this.props.mode === "View" ? true : false,
        handleChange: e => {
          let sanitizedAndBoundedDeposit =
            //first make zero if null, undefined, "", 0, NaN, -ve numbers, and non-numeric strings.
            !e.target.value || !(parseFloat(e.target.value) > 0)
              ? 0
              : //else, cap deposit value's upper limit to the total_price.
                Math.min(
                  parseFloat(e.target.value),
                  parseFloat(this.state.total_price)
                );
          this.setState({
            deposit: sanitizedAndBoundedDeposit
          });
        },
        value: this.state.deposit,
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const labelFunc = () => {
      return (
        <>
          <div className="field-component-title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <div>Installment</div>
              <div>Example Start Date</div>
              <div>Amount</div>
            </div>
          </div>
          <hr />
        </>
      );
    };

    const depositFunc = () => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Title name={"Deposit"} required={false} />
          <Title name={"At Checkout"} required={false} />
          <Title
            name={`$${
              this.state.deposit
                ? parseFloat(this.state.deposit).toFixed(2)
                : "0.00"
            }`}
            required={false}
          />
        </div>
      );
    };

    const labelField = {
      type: GET_HTML,
      data: {
        gethtml: labelFunc
      }
    };

    const customDateDeposit = {
      type: GET_HTML,
      data: {
        gethtml: depositFunc
      }
    };

    const customDate1Func = () => {
      // If deposit is enabled, move the dates of all installments back a cycle.
      let increment = 0;
      if (this.state.deposit && this.state.deposit !== "0") {
        increment++;
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Title name={"Installment 1:"} required={false} />
          { console.log("start date here: ", this.state.startDate)}
          {this.state.deposit && this.state.deposit !== "0" ? (
            <div>
              <DatePicker
                selected={
                  this.state.installments_plan === "Custom"
                    ? new Date(this.state.custom_date1)
                    : this.state.installments_plan === "Monthly"
                    ? addMonths(new Date(this.state.startDate), increment)
                    : this.state.installments_plan === "Bi-Weekly"
                    ? addWeeks(new Date(this.state.startDate), increment * 2)
                    : addWeeks(new Date(this.state.startDate), increment)
                }
                onChange={date => this.setState({ custom_date1: date })}
                minDate={new Date(this.state.startDate)}
                showYearDropdown
                showMonthDropdown
                dateFormat={"MMMM d, yyyy"}
                disabled={
                  this.props.mode === "View"
                    ? true
                    : this.state.installments_plan !== "Custom"
                    ? true
                    : false
                }
                className={`field-component-input-box`}
                autoComplete="off"
              />
            </div>
          ) : (
            <Title name={"At Checkout"} required={false} />
          )}
          <Title
            name={`$${
              this.state.total_price
                ? parseFloat(
                    ((parseFloat(this.state.total_price) + totalAddOnFee) *
                      (this.state.installments_interest &&
                      this.state.installments_interest !== "0"
                        ? 1 + parseFloat(this.state.installments_interest) / 100
                        : 1) *
                      parseFloat(
                        this.state.taxInfo && this.state.taxInfo[0]
                          ? 1 +
                              parseFloat(this.state.taxInfo[0].percentage / 100)
                          : 1
                      ) -
                      parseFloat(this.state.deposit ? this.state.deposit : 0)) /
                      parseInt(this.state.installments_breakdown)
                  ).toFixed(2)
                : "0.00"
            }`}
            required={false}
          />
        </div>
      );
    };

    const customDate1 = {
      type: GET_HTML,
      data: {
        gethtml: customDate1Func
      }
    };

    // const customDate1 = {
    //   type: DATE_PICKER,
    //   data: {
    //     name: "Installment 1:",
    //     isDisabled: this.state.installments_plan === "Custom" ? false : true,
    //     wrapperStyles: {
    //       display: "flex",
    //       alignItems: "center",
    //       justifyContent: "space-between"
    //     },
    //     minDate: new Date(),
    //     dateFormat: "MMMM d, yyyy",
    //     value: this.state.installments_plan === "Custom" ? this.state.custom_date1
    //       : this.state.installments_plan === "Monthly" ? addMonths(new Date(), 0)
    //       : this.state.installments_plan === "Bi-Weekly" ? addWeeks(new Date(), 0)
    //       : addWeeks(new Date(), 0),
    //     handleChange: date => {
    //       this.setState({ custom_date1: date });
    //     },
    //   }
    // }

    const customDate2Func = () => {
      // If deposit is enabled, move the dates of all installments back a cycle.
      let increment = 1;
      if (this.state.deposit && this.state.deposit !== "0") {
        increment++;
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Title name={"Installment 2:"} required={false} />
          <div>
            <DatePicker
              selected={
                this.state.installments_plan === "Custom"
                  ? new Date(this.state.custom_date2)
                  : this.state.installments_plan === "Monthly"
                  ? addMonths(new Date(this.state.startDate), increment)
                  : this.state.installments_plan === "Bi-Weekly"
                  ? addWeeks(new Date(this.state.startDate), increment * 2)
                  : addWeeks(new Date(this.state.startDate), increment)
              }
              onChange={date => this.setState({ custom_date2: date })}
              minDate={new Date(this.state.startDate)}
              showYearDropdown
              showMonthDropdown
              dateFormat={"MMMM d, yyyy"}
              disabled={
                this.props.mode === "View"
                  ? true
                  : this.state.installments_plan !== "Custom"
                  ? true
                  : false
              }
              className={`field-component-input-box`}
              autoComplete="off"
            />
          </div>
          <Title
            name={`$${
              this.state.total_price
                ? parseFloat(
                    ((parseFloat(this.state.total_price) + totalAddOnFee) *
                      (this.state.installments_interest &&
                      this.state.installments_interest !== "0"
                        ? 1 + parseFloat(this.state.installments_interest) / 100
                        : 1) *
                      parseFloat(
                        this.state.taxInfo && this.state.taxInfo[0]
                          ? 1 +
                              parseFloat(this.state.taxInfo[0].percentage / 100)
                          : 1
                      ) -
                      parseFloat(this.state.deposit ? this.state.deposit : 0)) /
                      parseInt(this.state.installments_breakdown)
                  ).toFixed(2)
                : "0.00"
            }`}
            required={false}
          />
        </div>
      );
    };

    const customDate2 = {
      type: GET_HTML,
      data: {
        gethtml: customDate2Func
      }
    };

    const customDate3Func = () => {
      // If deposit is enabled, move the dates of all installments back a cycle.
      let increment = 2;
      if (this.state.deposit && this.state.deposit !== "0") {
        increment++;
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Title name={"Installment 3:"} required={false} />
          <div>
            <DatePicker
              selected={
                this.state.installments_plan === "Custom"
                  ? new Date(this.state.custom_date3)
                  : this.state.installments_plan === "Monthly"
                  ? addMonths(new Date(this.state.startDate), increment)
                  : this.state.installments_plan === "Bi-Weekly"
                  ? addWeeks(new Date(this.state.startDate), increment * 2)
                  : addWeeks(new Date(this.state.startDate), increment)
              }
              onChange={date => this.setState({ custom_date3: date })}
              minDate={new Date(this.state.startDate)}
              showYearDropdown
              showMonthDropdown
              dateFormat={"MMMM d, yyyy"}
              disabled={
                this.props.mode === "View"
                  ? true
                  : this.state.installments_plan !== "Custom"
                  ? true
                  : false
              }
              className={`field-component-input-box`}
              autoComplete="off"
            />
          </div>
          <Title
            name={`$${
              this.state.total_price
                ? parseFloat(
                    ((parseFloat(this.state.total_price) + totalAddOnFee) *
                      (this.state.installments_interest &&
                      this.state.installments_interest !== "0"
                        ? 1 + parseFloat(this.state.installments_interest) / 100
                        : 1) *
                      parseFloat(
                        this.state.taxInfo && this.state.taxInfo[0]
                          ? 1 +
                              parseFloat(this.state.taxInfo[0].percentage / 100)
                          : 1
                      ) -
                      parseFloat(this.state.deposit ? this.state.deposit : 0)) /
                      parseInt(this.state.installments_breakdown)
                  ).toFixed(2)
                : "0.00"
            }`}
            required={false}
          />
        </div>
      );
    };

    const customDate3 = {
      type: GET_HTML,
      data: {
        gethtml: customDate3Func
      }
    };

    const customDate4Func = () => {
      // If deposit is enabled, move the dates of all installments back a cycle.
      let increment = 3;
      if (this.state.deposit && this.state.deposit !== "0") {
        increment++;
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Title name={"Installment 4:"} required={false} />
          <div>
            <DatePicker
              selected={
                this.state.installments_plan === "Custom"
                  ? new Date(this.state.custom_date4)
                  : this.state.installments_plan === "Monthly"
                  ? addMonths(new Date(this.state.startDate), increment)
                  : this.state.installments_plan === "Bi-Weekly"
                  ? addWeeks(new Date(this.state.startDate), increment * 2)
                  : addWeeks(new Date(this.state.startDate), increment)
              }
              onChange={date => this.setState({ custom_date4: date })}
              minDate={new Date(this.state.startDate)}
              showYearDropdown
              showMonthDropdown
              dateFormat={"MMMM d, yyyy"}
              disabled={
                this.props.mode === "View"
                  ? true
                  : this.state.installments_plan !== "Custom"
                  ? true
                  : false
              }
              className={`field-component-input-box`}
              autoComplete="off"
            />
          </div>
          <Title
            name={`$${
              this.state.total_price
                ? parseFloat(
                    ((parseFloat(this.state.total_price) + totalAddOnFee) *
                      (this.state.installments_interest &&
                      this.state.installments_interest !== "0"
                        ? 1 + parseFloat(this.state.installments_interest) / 100
                        : 1) *
                      parseFloat(
                        this.state.taxInfo && this.state.taxInfo[0]
                          ? 1 +
                              parseFloat(this.state.taxInfo[0].percentage / 100)
                          : 1
                      ) -
                      parseFloat(this.state.deposit ? this.state.deposit : 0)) /
                      parseInt(this.state.installments_breakdown)
                  ).toFixed(2)
                : "0.00"
            }`}
            required={false}
          />
        </div>
      );
    };

    const customDate4 = {
      type: GET_HTML,
      data: {
        gethtml: customDate4Func
      }
    };

    const customDate5Func = () => {
      // If deposit is enabled, move the dates of all installments back a cycle.
      let increment = 4;
      if (this.state.deposit && this.state.deposit !== "0") {
        increment++;
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Title name={"Installment 5:"} required={false} />
          <div>
            <DatePicker
              selected={
                this.state.installments_plan === "Custom"
                  ? new Date(this.state.custom_date5)
                  : this.state.installments_plan === "Monthly"
                  ? addMonths(new Date(this.state.startDate), increment)
                  : this.state.installments_plan === "Bi-Weekly"
                  ? addWeeks(new Date(this.state.startDate), increment * 2)
                  : addWeeks(new Date(this.state.startDate), increment)
              }
              onChange={date => this.setState({ custom_date5: date })}
              minDate={new Date(this.state.startDate)}
              showYearDropdown
              showMonthDropdown
              dateFormat={"MMMM d, yyyy"}
              disabled={
                this.props.mode === "View"
                  ? true
                  : this.state.installments_plan !== "Custom"
                  ? true
                  : false
              }
              className={`field-component-input-box`}
              autoComplete="off"
            />
          </div>
          <Title
            name={`$${
              this.state.total_price
                ? parseFloat(
                    ((parseFloat(this.state.total_price) + totalAddOnFee) *
                      (this.state.installments_interest &&
                      this.state.installments_interest !== "0"
                        ? 1 + parseFloat(this.state.installments_interest) / 100
                        : 1) *
                      parseFloat(
                        this.state.taxInfo && this.state.taxInfo[0]
                          ? 1 +
                              parseFloat(this.state.taxInfo[0].percentage / 100)
                          : 1
                      ) -
                      parseFloat(this.state.deposit ? this.state.deposit : 0)) /
                      parseInt(this.state.installments_breakdown)
                  ).toFixed(2)
                : "0.00"
            }`}
            required={false}
          />
        </div>
      );
    };

    const customDate5 = {
      type: GET_HTML,
      data: {
        gethtml: customDate5Func
      }
    };

    const customDate6Func = () => {
      // If deposit is enabled, move the dates of all installments back a cycle.
      let increment = 5;
      if (this.state.deposit && this.state.deposit !== "0") {
        increment++;
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Title name={"Installment 6:"} required={false} />
          <div>
            <DatePicker
              selected={
                this.state.installments_plan === "Custom"
                  ? new Date(this.state.custom_date6)
                  : this.state.installments_plan === "Monthly"
                  ? addMonths(new Date(this.state.startDate), increment)
                  : this.state.installments_plan === "Bi-Weekly"
                  ? addWeeks(new Date(this.state.startDate), increment * 2)
                  : addWeeks(new Date(this.state.startDate), increment)
              }
              onChange={date => this.setState({ custom_date6: date })}
              minDate={new Date(this.state.startDate)}
              showYearDropdown
              showMonthDropdown
              dateFormat={"MMMM d, yyyy"}
              disabled={
                this.props.mode === "View"
                  ? true
                  : this.state.installments_plan !== "Custom"
                  ? true
                  : false
              }
              className={`field-component-input-box`}
              autoComplete="off"
            />
          </div>
          <Title
            name={`$${
              this.state.total_price
                ? parseFloat(
                    ((parseFloat(this.state.total_price) + totalAddOnFee) *
                      (this.state.installments_interest &&
                      this.state.installments_interest !== "0"
                        ? 1 + parseFloat(this.state.installments_interest) / 100
                        : 1) *
                      parseFloat(
                        this.state.taxInfo && this.state.taxInfo[0]
                          ? 1 +
                              parseFloat(this.state.taxInfo[0].percentage / 100)
                          : 1
                      ) -
                      parseFloat(this.state.deposit ? this.state.deposit : 0)) /
                      parseInt(this.state.installments_breakdown)
                  ).toFixed(2)
                : "0.00"
            }`}
            required={false}
          />
        </div>
      );
    };

    const customDate6 = {
      type: GET_HTML,
      data: {
        gethtml: customDate6Func
      }
    };

    const customDates = [
      customDate1,
      customDate2,
      customDate3,
      customDate4,
      customDate5,
      customDate6
    ];

    const enableDropIns = {
      type: SWITCH,
      data: {
        name: "Enable Drop-ins",
        isDisabled: true,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
        /*         checked: this.state.receives_emails,
            handleClick: e => this.setState({ receives_emails: e.target.checked }) */
      }
    };

    const EnableTaxes = {
      type: SWITCH,
      data: {
        name: "Enable Taxes",
        isDisabled: this.props.mode === "View" ? true : false,
        checked: this.state.isTaxEnabled,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: e =>
          this.setState({
            isTaxEnabled: e.target.checked,
            taxInfo: []
          })
      }
    };

    //if (this.state.isTaxEnabled) {
    const selectTaxes = {
      type: DROPDOWN,
      data: {
        name: "Tax Plan",
        required: false,
        placeholder: "Please Select" /* 
            value: this.state.taxInfo, */,
        isDisabled: this.props.mode === "View" ? true : false,
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleChange: e => {
          const item = JSON.parse(
            this.global.organizationInfo.stripeTaxId[e.target.value]
          );
          this.setState({
            taxInfo: [
              {
                id: item.id,
                percentage: item.percentage,
                jurisdiction: item.jurisdiction,
                display_name: item.display_name,
                description: item.description
              }
            ]
          });
        },
        choices: this.taxesOptions()
      } /* ,
          validators: { validateSubmit: () => this.state.programs_picked.length !== 0 } */
    };
    //   return [
    //     descriptionInput,
    //     emailInput,
    //     addsonFee,
    //     /* enrollmentEnable, */ //Hidden switch
    //     /* enableDropIns, */ //Hidden switch
    //     EnableTaxes,
    //     selectTaxes
    //   ];
    // }

    const AddCap = {
      type: SWITCH,
      data: {
        name: "Enable Program Plan Cap",
        isDisabled: this.props.mode === "View" ? true : false,
        checked: this.state.isCapEnabled,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: e =>
          this.setState({
            isCapEnabled: e.target.checked,
            planCap: ""
          })
      }
    };

    const PlanCap = {
      type: STRING_INPUT,
      data: {
        type: "number",
        name: this.global.program.total_spots
          ? `Plan Cap (Max: ${this.global.program.total_spots})`
          : "Plan Cap",
        required: false,
        isDisabled: this.props.mode === "View" ? true : false,
        placeholder: "Enter Cap",
        styles: {
          width: "63%"
        },
        value: this.state.planCap,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleChange: e => {
          this.setState({
            planCap: e.target.value
          });
        }
      }
    };

    const fields = [];

    fields.push(descriptionInput);
    fields.push(emailInput);
    fields.push(addDescription);
    if (this.state.isDescriptionEnabled) {
      fields.push(description);
    }
    fields.push(addsonFee);
    if (this.state.addOn) {
      fields.push(addOnOptionField);
    }
    fields.push(EnableTaxes);
    if (this.state.isTaxEnabled) {
      fields.push(selectTaxes);
    }
    fields.push(AddCap);
    if (this.state.isCapEnabled) {
      fields.push(PlanCap);
    }
    if (this.state.isInstallmentsAllowed) {
      fields.push(installmentsField);
      if (this.state.installments) {
        fields.push(installmentPlan);
        fields.push(installmentBreakdown);
        fields.push(installmentInterest);
        fields.push(depositToggle);
        if (this.state.includeDeposit) {
          fields.push(depositField);
        }
        if (this.state.installments_plan && this.state.installments_breakdown) {
          fields.push(labelField);
          if (this.state.deposit && this.state.deposit !== "0") {
            fields.push(customDateDeposit);
          }
          for (
            let i = 0;
            i < parseInt(this.state.installments_breakdown);
            i++
          ) {
            fields.push(customDates[i]);
          }
        }
      }
    }

    return fields;
  };

  getBaseModalButtons = () => {
    const debounceSavePlan = _.debounce(_ => this.savePlan(), 2000, {
      leading: true,
      trailing: false
    });
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => {
        e.preventDefault();
        debounceSavePlan();
      }
    };

    const buttons = [];

    if (this.props.mode === "Edit") {
      buttons.push(addButton);
    }

    return buttons;
  };

  getBaseModalProps = () => {
    return {
      title: `${this.props.mode} Pricing Plan`,
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      height: "65vh",
      midSectionHeight: "49vh"
    };
  };

  render() {
    console.log("this.state", this.state);
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(EditSemesterPlanModal);
