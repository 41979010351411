import React, { useState, useGlobal } from "reactn";
import { Grid, makeStyles, Popover, List } from "@material-ui/core";

import StyledButtonOutline from "../charts/StyledButtonOutline";
import StyledButtonTransparent from "../charts/StyledButtonTransparent";
import StyledButtonText from "../charts/StyledButtonText";
import ListItemBtn from "../charts/ListItemBtn";
// const colors = {
//   green: "#2AA897",
//   lightGreen: "#4FBE75",
//   white: "#FFFFFF",
//   black: "#000000",
//   gray: "#F4F6FC",
//   midGray: "#EEEEEE",
//   borderGray: "#BBBBBB",
//   darkGray: "#808080"
// };
const themes = {
  0: "#73909B #35406d",
  1: "#818AB4 #124C4C",
  2: "#f6bd8f #a24141",
  3: "#89A7FC #241D45",
  4: "#91A5D2 #222B65",
  5: "#01A3A7 #124C4C",
  6: "#FF7B65 #B64747"
};

const useStyles = makeStyles(theme => ({
  buttonBar: {
    margin: "0.2rem",
    padding: "0rem",
    borderRadius: 5
  },
  popover: {
    zIndex: "999999999 !important"
  }
}));

const TemplateEditorFooter = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [color] = useGlobal("organizationInfo");
  const classes = useStyles();
  const { handleClose, handleSave, handleVariableValueClick } = props;

  const handleVariablesBtnClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleVariableClick = event => {
    handleVariableValueClick(event.target.innerText);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const theme = color.theme ? color.theme : 0;

  return (
    <Grid
      container
      item
      className={classes.buttonBar}
      style={{
        background: `linear-gradient(to right, ${
          themes[theme].split(" ")[0]
        }, ${themes[theme].split(" ")[1]})`
      }}
      xs={12}
    >
      <Grid item xs={10}>
        <StyledButtonText onClick={handleClose}>Cancel</StyledButtonText>
        <StyledButtonTransparent
          id="variablesBtn"
          onClick={handleVariablesBtnClick}
          aria-describedby={id}
        >
          Variables
        </StyledButtonTransparent>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={e => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          className={classes.popover}
          // disableAutoFocus={true}
          // disableEnforceFocus={true}
        >
          <List component="nav">
            <ListItemBtn text="Contact's Name" onClick={handleVariableClick} />
          </List>
        </Popover>
      </Grid>
      <Grid item xs={2}>
        <StyledButtonOutline align="right" onClick={handleSave}>
          Save
        </StyledButtonOutline>
      </Grid>
    </Grid>
  );
};

export default TemplateEditorFooter;
