import React from "reactn";
import LeftMessagingPanel from "./LeftMessagingPane/LeftMessagingPane";
import CenterMessagingPanel from "./CenterMessagingPane/CenterMessagingPane";
// import RightMessagingPanel from "./RightMessagingPane/RightMessagingPane";

import "./messaging.css";

class Messaging extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = { smallScreen: window.innerWidth < 800 };
    window.addEventListener("resize", () => {
      this.setState({ smallScreen: window.innerWidth < 800 });
    });
  }

  async handleClick(contact) {
    await this.dispatch.ccChangeConversationAdmin(contact);
  }
  render() {
    return (
      <div className="messages overflow-hidden">
        <div className="messagecenter d-flex">
          {this.state.smallScreen ? (
            !this.global.ccAdminSelectedContact.conversationId && (
              <LeftMessagingPanel
                handleClick={this.handleClick}
                contacts={this.global.userPromise}
                selectedContact={this.global.ccSelectedContact}
              />
            )
          ) : (
            <LeftMessagingPanel
              handleClick={this.handleClick}
              contacts={this.global.userPromise}
              selectedContact={this.global.ccSelectedContact}
            />
          )}
          {this.global.ccAdminSelectedContact.conversationId ? (
            <CenterMessagingPanel smallScreen={this.state.smallScreen} />
          ) : (
            ""
          )}
          {/* <RightMessagingPanel /> */}
        </div>
      </div>
    );
  }
}

export default Messaging;
