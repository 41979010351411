import React from "reactn";
import ParentGuardian from "./forms/ParentGuardian";

class ParentGuardiansPage extends React.Component {
  handChange = e => {
    const vals = this.global.parentGuardians.map(c => (c.id === e.id ? e : c));
    this.setGlobal({ parentGuardians: vals });
  };

  handRemove = e => {
    this.dispatch.removeGuardianFromFamilyProfile(e);
  };

  render() {
    return (
      <div className="cont boxes-row mt-50px">
        {this.global.parentGuardians.map((guardian, i) => (
          <ParentGuardian
            key={i}
            guardian={guardian}
            guardianNo={i}
            onChange={this.handChange}
            onRemove={this.handRemove}
          />
        ))}
        <button
          className="addchild"
          onClick={() => this.dispatch.addGuardianToFamilyProfile()}
        >
          <h3>Add Parent / Guardian</h3>
        </button>
      </div>
    );
  }
}

export default ParentGuardiansPage;
