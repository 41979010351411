import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import {
  DROPDOWN,
  STRING_INPUT,
  SELECT_MULTIPLE_DROPDOWN,
  DATE_PICKER,
  ITEM_CONTENT
} from "./baseModal/FieldTypes";
import { notEmptyString } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { color0, color1, color2, color3, color4 } from "../../assets";
class AddTaskModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      color: 0,
      date: null,
      image: ""
    };

    this.color_choices = [
      { name: "color0", image: color0 },
      { name: "color1", image: color1 },
      { name: "color2", image: color2 },
      { name: "color3", image: color3 },
      { name: "color4", image: color4 }
    ];
  }

  requestTour = async e => {
    e.preventDefault();
  };

  getColor(name) {
    return this.color_choices.filter(img => img.name === name)[0].image;
  }

  async addReminder(e) {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/partners/reminders`;
    const res = await axios.post(ep, {
      description: this.state.description,
      date: this.state.date,
      color: this.state.color,
      createdBy: this.global.dsUser.user_id
    });
    if (res.data.success) {
      toast.success("Reminder Added");
      this.props.reminder(res.data.data);
      this.props.onClose();
    }
  }

  /// ================== NEW MODAL ========================== ///

  getBaseModalFields() {
    const descriptionInput = {
      type: STRING_INPUT,
      data: {
        name: "Reminder",
        required: true,
        placeholder: "Reminder Description...",
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description
      },
      validators: { validateSubmit: () => this.state.description !== "" }
    };

    const dueField = {
      type: DATE_PICKER,
      data: {
        name: "Due Date",
        required: false,
        selected: new Date(this.state.date),
        handleChange: date => this.setState({ date: date }),
        minDate: new Date(),
        dateFormat: "MMMM d, yyyy",
        placeholder: "Pick a date",
        value: this.state.date
      }
    };

    const colorSelections = {
      type: ITEM_CONTENT,
      data: {
        name: "Color",
        handleChange: value => {
          this.setState({ color: value }, () => console.log("value", value));
        },
        content: [
          { src: this.getColor("color0"), content: "", value: 0 },
          { src: this.getColor("color1"), content: "", value: 1 },
          { src: this.getColor("color2"), content: "", value: 2 },
          { src: this.getColor("color3"), content: "", value: 3 },
          { src: this.getColor("color4"), content: "", value: 4 }
        ]
      }
    };

    return [descriptionInput, dueField, colorSelections];
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Create",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.addReminder(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "New Reminder",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ===================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(AddTaskModal);
