import React, { useState, useEffect, useGlobal } from "reactn";
import { CircleCheck } from "../../assets";
import axios from "axios";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { canUserCreate } from "../../utilities/listingCreation";
import { toast } from "react-toastify";

import { STRING_INPUT, TEXT_AREA, BUTTON, GET_HTML, SWITCH, TITLE } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import BaseModal from "./baseModal/BaseModal";

const AddNewAddonsModal = ({ onClose, history, dispatch, refetch, selectedAddon }) => {
  const [loading, setLoading] = useGlobal("loading");
  const [addonName, setAddonName] = useState("");
  const [addonPrice, setAddonPrice] = useState("0")
  const [addonDescription, setAddonDescription] = useState("")
  const [addons, setAddons] = useState([]);

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const ep = `${process.env.REACT_APP_API}/partners/parties/addons`
      const result = await axios.get(ep);

      if (result.data.success) {
        setAddons(result.data.data);

        const addon = result.data.data.find(addon => parseInt(addon.id) === parseInt(selectedAddon))
        if (addon) {
          setAddonName(addon.name)
          setAddonPrice(addon.price)
          setAddonDescription(addon.description)
        }

      } else {
        toast.error("Failed to load add-ons")
      }
      setLoading(false)
    }
    fetchData()
  }, []);

  const submit = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/parties/create-addons`
    const result = await axios.post(ep, {
      id: selectedAddon,
      name: addonName,
      price: addonPrice,
      description: addonDescription
    });

    console.log("RESULT", result)
    if (result.data.success) {
      await refetch()
      onClose()
    } else {
      toast.error("Unable to save Addon")
    }
  }

  const getBaseModalFields = () => {
    const addonNameInput = {
      type: STRING_INPUT,
      data: {
        name: "Name",
        required: true,
        placeholder: "",
        handleChange: e => {
          setAddonName(e.target.value)
        },
        value: addonName
      },
      validators: { validateSubmit: () => addonName !== "" }
    };

    const addonPriceInput = {
      type: STRING_INPUT,
      data: {
        type: "number",
        name: "Item Price",
        required: true,
        placeholder: "",
        handleChange: e => {
          setAddonPrice(Math.floor(e.target.value * 100) / 100)
        },
        value: addonPrice
      },
      validators: { validateSubmit: () => addonPrice !== "0" }
    };

    const addonDescriptionInput = {
      type: TEXT_AREA,
      data: {
        name: "Description",
        required: false,
        placeholder: "",
        handleChange: e => setAddonDescription(e.target.value),
        value: addonDescription
      }
    }

    return [addonNameInput, addonPriceInput, addonDescriptionInput];
  };

  const getBaseModalButtons = () => {
    const saveButton = {
      name: "Save",
      handleClick: async () => {
        await submit()
      },
      buttonColour: BLUE_BUTTON,
      textColour: WHITE
    };

    return [saveButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Manage Addons",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: onClose
    };
  };


  return (
    <BaseModal {...getBaseModalProps()} />
  )
};

export default AddNewAddonsModal;
