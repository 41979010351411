import React, { Component } from "reactn";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { formTypes } from "../../components/UI/form";
import ReactTooltip from "react-tooltip";
import BaseModal from "./baseModal/BaseModal";
import { GET_HTML, DROPDOWN, STRING_INPUT } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class CreateDiscountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "Percentage",
      duration: "forever",
      amount_off: 0,
      discount_name: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async create_discount(e) {
    e.preventDefault();

    if (this.state.amount_off === 0) return null;

    const ep = `${process.env.REACT_APP_API}/partners/pricing_items_discounts/create`;
    const cb = await axios.post(ep, { ...this.state });

    if (cb.data.success) {
      toast.success(`Discount Created!`);
      this.props.addedDiscount(cb.data.data);
      // this.props.onClose();
    }
  }

  typeOptions = () => {
    return [
      { value: "Percentage", text: "Percentage" },
      { value: "Amount", text: "Fixed Amount" }
    ];
  };

  durationOptions = () => {
    return [
      { value: "once", text: "Once" },
      { value: "forever", text: "Forever" }
    ];
  };

  getBaseModalFields() {
    const discountappliedInput = {
      type: STRING_INPUT,
      data: {
        name: "Discount Name",
        required: true,
        handleChange: e => {
          this.setState({
            discount_name: e.target.value
          });
        },
        value: this.state.discount_name
      },
      validators: { validateSubmit: () => this.state.discount_name !== "" }
    };

    const selectdiscountInput = {
      type: DROPDOWN,
      data: {
        name: "Select Discount",
        required: true,
        placeholder: "Please Select",
        value: this.state.type,
        handleChange: e => {
          this.setState({
            type: e.target.value
          });
        },
        choices: this.typeOptions()
      },
      validators: { validateSubmit: () => this.state.type !== "" }
    };

    const discountpercentageInput = {
      type: STRING_INPUT,
      data: {
        name: `Discount ${
          this.state.type === "Percentage" || this.state.type === ""
            ? "Percentage (%)"
            : "Amount ($)"
        }`,
        type: "number",
        required: true,
        handleChange: e => {
          this.setState({
            amount_off: e.target.value
          });
        },
        value: this.state.amount_off
      },
      validators: { validateSubmit: () => this.state.amount_off !== 0 }
    };

    const durationInput = {
      type: DROPDOWN,
      data: {
        name: "Select Discount",
        required: false,
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            duration: e.target.value
          });
        },
        value: this.state.duration,
        choices: this.durationOptions()
      }
    };

    const fields = [];
    fields.push(discountappliedInput);
    fields.push(selectdiscountInput);
    fields.push(discountpercentageInput);
    fields.push(durationInput);

    return fields;
  }

  getBaseModalButtons = () => {
    const creatediscountButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.create_discount(e)
    };

    return [creatediscountButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Create Discount",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    console.log("Type", this.state.type);

    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>Create Discount</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  color: "#999999",
                  fontSize: ".8rem",
                  marginBottom: "20px"
                }}
              >
                Items with <span style={{ color: "orange" }}>*</span> are
                required
              </div>
            </div>
            <form onSubmit={e => this.create_discount(e)}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="discount_name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Discount Name <span style={{ color: "orange" }}>*</span>
                </label>
                <input
                  name="discount_name"
                  id="discount_name"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    console.log("e", e);
                    this.setState({
                      discount_name: e.target.value
                    });
                  }}
                  // value={this.state.discount_name}
                />
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="type"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Discount Type <span style={{ color: "orange" }}>*</span>
                </label>
                <select
                  name="type"
                  id="type"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={this.state.type}
                >
                  <option value="Percentage">Percentage</option>
                  <option value="Amount">Fixed Amount</option>
                </select>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="amount_off"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Discount{" "}
                  {this.state.type === "Percentage"
                    ? "Percentage (%)"
                    : "Amount ($)"}{" "}
                  <span style={{ color: "orange" }}>*</span>
                </label>
                <input
                  name="amount_off"
                  id="amount_off"
                  type="number"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      amount_off: e.target.value
                    });
                  }}
                  value={this.state.amount_off}
                />
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="duration"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Duration
                </label>
                <select
                  name="duration"
                  id="duration"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={this.state.duration}
                >
                  <option value="once">Once</option>
                  <option value="forever">Forever</option>
                </select>
              </div>

              <div className="modal-footer" style={{ marginTop: "25px" }}>
                <button
                  type="submit"
                  // onClick={_ => this.savePlan()}
                  className="btn profile-btn"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateDiscountModal;
