import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import {
  DROPDOWN,
  STRING_INPUT,
  SELECT_MULTIPLE_DROPDOWN,
  DATE_PICKER,
  SWITCH
} from "./baseModal/FieldTypes";
import { notEmptyString } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { color0, color1, color2, color3, color4 } from "../../assets";
class CreateStaffScheduleModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      staff: this.props.selectedStaff ? this.props.selectedStaff.id : "",
      task: "Schedule",
      taskDescription: "",
      isRepeatingWeekly: false,
      startDate: this.props.selectedDate
        ? new Date(this.props.selectedDate)
        : "",
      endDate: "",
      start_hour: "",
      start_minute: "",
      start_time_period: "",
      end_hour: "",
      end_minute: "",
      end_time_period: "",
      daysOfTheWeek: []
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/roles`;
    const users = await axios.get(ep);
    if (users.data.success) {
      this.setState({
        users: users.data.data.users
      });
    }
  }

  getAllStaff = () => {
    return [
      { value: this.global.dsUser.id, text: this.global.dsUser.name },
      ...this.state.users
        .filter(f => !f.isArchived && f.sub_user)
        .map(({ id, username }) => ({ value: id, text: username }))
    ];
  };

  hourOptions = () => {
    const options = [];
    for (let hour = 1; hour <= 12; hour++) {
      const time = hour.toString().padStart(2, "0") + ":00";
      options.push({
        value: hour * 60,
        key: hour * 60,
        text: time
      });
    }
    return options;
  };

  minuteOptions = () => {
    const options = [];
    for (let minute = 0; minute < 60; minute++) {
      const time = minute.toString().padStart(2, "0");
      options.push({
        value: minute,
        key: minute,
        text: time
      });
    }
    return options;
  };

  timePeriodOptions = () => {
    const options = [
      { value: "AM", text: "AM" },
      { value: "PM", text: "PM" }
    ];
    return options;
  };

  getDaysOfTheWeek = () => {
    const options = [
      { value: "Sunday", text: "Sunday" },
      { value: "Monday", text: "Monday" },
      { value: "Tuesday", text: "Tuesday" },
      { value: "Wednesday", text: "Wednesday" },
      { value: "Thursday", text: "Thursday" },
      { value: "Friday", text: "Friday" },
      { value: "Saturday", text: "Saturday" }
    ];
    return options;
  };

  getBaseModalFields() {
    const fields = [];

    const staffField = {
      type: DROPDOWN,
      data: {
        name: "Staff",
        required: true,
        placeholder: "Please Select",
        value: this.state.staff,
        handleChange: e => {
          this.setState({ staff: e.target.value });
        },
        choices: this.getAllStaff()
      },
      validators: { validateSubmit: () => this.state.staff !== "" }
    };

    const taskName = {
      type: STRING_INPUT,
      data: {
        name: "Task Name",
        required: true,
        value: this.state.task,
        handleChange: e => {
          this.setState({
            task: e.target.value,
            endDate: "",
            endHour: "",
            endMinute: "",
            end_time_period: "",
            daysOfTheWeek: []
          });
        }
      },
      validators: { validateSubmit: () => this.state.task !== "" }
    };

    const taskDescription = {
      type: STRING_INPUT,
      data: {
        name: "Task Description",
        required: false,
        placeholder: "Task Description",
        value: this.state.taskDescription,
        handleChange: e => {
          this.setState({ taskDescription: e.target.value });
        }
      }
    };

    const isRepeatingField = {
      type: SWITCH,
      data: {
        name: "Repeats every week",
        required: false,
        checked: this.state.isRepeatingWeekly,
        handleClick: e => this.setState({ isRepeatingWeekly: e.target.checked })
      }
    };

    const startDate = {
      type: DATE_PICKER,
      data: {
        name: "Start Date",
        required: true,
        placeholder: "Pick a date",
        dateFormat: "MMMM d, yyyy",
        value: this.state.startDate,
        handleChange: date => this.setState({ startDate: date })
      },
      validators: { validateSubmit: notEmptyString(this.state.startDate) }
    };

    const endDate = {
      type: DATE_PICKER,
      data: {
        name: "End Date",
        required: true,
        placeholder: "Pick a date",
        dateFormat: "MMMM d, yyyy",
        value: this.state.endDate,
        handleChange: date => this.setState({ endDate: date })
      },
      validators: { validateSubmit: notEmptyString(this.state.endDate) }
    };

    const startHour = {
      type: DROPDOWN,
      data: {
        name: "Start Hour",
        required: true,
        placeholder: "Please Select",
        value: this.state.start_hour,
        handleChange: e => {
          this.setState({
            start_hour: e.target.value
          });
        },
        choices: this.hourOptions()
      },
      validators: { validateSubmit: () => this.state.start_hour !== "" }
    };

    const startMinute = {
      type: DROPDOWN,
      data: {
        name: "Start Minute",
        required: true,
        placeholder: "Please Select",
        value: this.state.start_minute,
        handleChange: e => {
          this.setState({
            start_minute: e.target.value
          });
        },
        choices: this.minuteOptions()
      },
      validators: { validateSubmit: () => this.state.start_minute !== "" }
    };

    const startTimePeriod = {
      type: DROPDOWN,
      data: {
        name: "Start Time Period",
        required: true,
        placeholder: "Please Select",
        value: this.state.start_time_period,
        handleChange: e => {
          this.setState({
            start_time_period: e.target.value
          });
        },
        choices: this.timePeriodOptions()
      },
      validators: { validateSubmit: () => this.state.start_time_period !== "" }
    };

    const endHour = {
      type: DROPDOWN,
      data: {
        name: "End Hour",
        required: true,
        placeholder: "Please Select",
        value: this.state.end_hour,
        handleChange: e => {
          this.setState({
            end_hour: e.target.value
          });
        },
        choices: this.hourOptions()
      },
      validators: { validateSubmit: () => this.state.end_hour !== "" }
    };

    const endMinute = {
      type: DROPDOWN,
      data: {
        name: "End Minute",
        required: true,
        placeholder: "Please Select",
        value: this.state.end_minute,
        handleChange: e => {
          this.setState({
            end_minute: e.target.value
          });
        },
        choices: this.minuteOptions()
      },
      validators: { validateSubmit: () => this.state.end_minute !== "" }
    };

    const endTimePeriod = {
      type: DROPDOWN,
      data: {
        name: "End Time Period",
        required: true,
        placeholder: "Please Select",
        value: this.state.end_time_period,
        handleChange: e => {
          this.setState({
            end_time_period: e.target.value
          });
        },
        choices: this.timePeriodOptions()
      },
      validators: { validateSubmit: () => this.state.end_time_period !== "" }
    };

    const daysOfTheWeek = {
      type: SELECT_MULTIPLE_DROPDOWN,
      data: {
        name: "Days of the week",
        required: true,
        placeholder: "Please Select",
        value: this.state.daysOfTheWeek,
        handleChange: e => {
          this.setState({ daysOfTheWeek: e });
        },
        choices: this.getDaysOfTheWeek()
      },
      validators: {
        validateSubmit: () => this.state.daysOfTheWeek.length > 0
      }
    };

    fields.push(staffField);
    fields.push(taskName);
    // fields.push(taskDescription);
    fields.push(isRepeatingField);
    fields.push(startDate);
    if (this.state.isRepeatingWeekly) {
      fields.push(endDate);
    }
    fields.push(startHour);
    fields.push(startMinute);
    fields.push(startTimePeriod);
    fields.push(endHour);
    fields.push(endMinute);
    fields.push(endTimePeriod);
    if (this.state.isRepeatingWeekly) {
      fields.push(daysOfTheWeek);
    }

    return fields;
  }

  async submitSchedule() {
    const startHour = parseInt(this.state.start_hour);
    const startMinute = parseInt(this.state.start_minute);
    const endHour = parseInt(this.state.end_hour);
    const endMinute = parseInt(this.state.end_minute);
    const CONVERT_TIME_PM = 720;

    const startTime =
      this.state.start_time_period === "AM"
        ? startHour + startMinute
        : startHour + startMinute + CONVERT_TIME_PM;
    const endTime =
      this.state.end_time_period === "AM"
        ? endHour + endMinute
        : endHour + endMinute + CONVERT_TIME_PM;

    if (
      (this.state.endDate === "" ||
        new Date(this.state.startDate).getTime() ===
          new Date(this.state.endDate).getTime()) &&
      startTime >= endTime
    ) {
      toast.error("Start Time must be before end time!");
      return;
    }

    const startDate = new Date(this.state.startDate);
    startDate.setMinutes(startDate.getMinutes() + startTime);
    const endDate = new Date(
      this.state.endDate === "" ? this.state.startDate : this.state.endDate
    );
    endDate.setMinutes(endDate.getMinutes() + endTime);

    const ep = `${process.env.REACT_APP_API}/partners/users/schedule/${parseInt(
      this.state.staff
    )}`;
    // const results = await axios.post(ep, {
    //   task_name: this.state.task,
    //   task_description: this.state.taskDescription,
    //   isRepeatingWeekly: this.state.isRepeatingWeekly,
    //   start_date: this.state.startDate !== "" ? this.state.startDate : null,
    //   end_date: this.state.endDate !== "" ? this.state.endDate : null,
    //   start_time: startDate,
    //   end_time: endDate,
    //   selected_days_of_week: this.state.selected_days_of_week
    // });

    // if (results.data.success) {
    //   toast.success("Schedule successfully created!");
    //   this.props.onRefresh();
    //   this.props.onClose();
    // } else {
    //   toast.error("Error while creating schedule.");
    // }
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Create",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.submitSchedule()
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Create New Schedule",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(CreateStaffScheduleModal);
