import React from "reactn";
import EarningOverview from "./earningOverview";
import Leads from "./leadstuition";
import Calendar from "./calendar";
import axios from "axios";
import ConnectionsOverview from "./connectionsOverview";
import { format, addDays, getDay } from "date-fns";

const COMPLETION_VALIDATION_STEPS_ORGANIZATION_PROFILE = 4;

class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setGlobal({
      partnerPathway: [...this.global.partnerPathway.slice(0, 1)]
    });
    this.state = {
      oneTime: 0,
      recurring: 0,
      leads: {
        appNew: 0,
        appTotal: 0,
        infoNew: 0,
        infoTotal: 0,
        tourNew: 0,
        tourTotal: 0
      },
      finance: {
        pending: 0,
        pendingWeek: 0,
        overdue: 0,
        overdueWeek: 0
      },
      enrollments: {
        activeTotal: 0,
        pendingTotal: 0,
        waitlistTotal: 0,
        pastTotal: 0
      },
      programName: "",
      totalPayout: [],
      runningTotal: [],
      gallery: {},
      tosFormShown: true,
      connections: [],
      listingsCount: 0,
      enrollmentsCount: 0,
      formLeadsCount: 0,
      appointmentsCount: 0,
      applicationsCount: 0
    };
  }
  async componentDidMount() {
    const startTime = new Date().getTime();
    const ep = `${process.env.REACT_APP_API}/partners/orders/total`;
    const ep2 = `${process.env.REACT_APP_API}/partners/connections/dashboard`;
    const ep3 = `${process.env.REACT_APP_API}/partners/dashboard`;

    // for first time users who have not registered their stripe accounts in organization profile
    if (
      this.global.organizationInfo.profile_completion_validation !==
      COMPLETION_VALIDATION_STEPS_ORGANIZATION_PROFILE
    ) {
      this.props.history.push("/partners/profile");
    }

    try {
      this.setGlobal({ loading: true });
      // Commenting out the earnings overview section
      // const res = await axios.get(ep);
      // if (res.data.success) {
      //   this.setState({
      //     oneTime: res.data.data.totalOneTime.totalCharges,
      //     recurring: res.data.data.recurring.totalRecurring,
      //     chart: this.groupByWeek(
      //       [
      //         ...res.data.data.totalOneTime.charges,
      //         ...res.data.data.recurring.invoices
      //       ]
      //         .map(e => (e.amount ? e : { ...e, amount: e.amount_paid }))
      //         .reverse()
      //     )
      //   });
      // }

      const res3 = await axios.get(ep3);

      if (res3.data.success) {
        this.setState({
          listingsCount: res3.data.data.listingsCount,
          enrollmentsCount: res3.data.data.enrollmentsCount,
          formLeadsCount: res3.data.data.formLeadsCount,
          appointmentsCount: res3.data.data.appointmentsCount,
          applicationsCount: res3.data.data.applicationsCount
        });
      }

      const res2 = await axios.get(ep2);
      console.log("res2", res2);

      if (res2.data.success && res2.data.data.connections.length > 0) {
        const data = [];

        res2.data.data.connections
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          .map(e => !data.filter(f => f.email === e.email)[0] && data.push(e));

        this.setState({
          connections: this.groupByWeekConnections([
            ...data.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          ])
        });
      }

      this.setGlobal({ loading: false, lastAPICall: res2 });
    } catch (er) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
    console.log("Time Taken", new Date().getTime() - startTime);
  }

  // groupByWeek(transactions) {
  //   const initalDate = new Date(transactions[0].created * 1000);
  //   let start = addDays(initalDate, (getDay(initalDate) + 1) * -1);
  //   const finalArray = [];
  //   finalArray.push({ amount: 0, date: format(start, "LLL dd") });
  //   start = addDays(start, 7);
  //   let amount = 0;
  //   for (let transaction of transactions) {
  //     if (transaction.created < start.getTime() / 1000) {
  //       amount += parseInt(transaction.amount);
  //     } else {
  //       finalArray.push({ amount, date: format(start, "LLL dd") });
  //       start = addDays(start, 7);
  //       amount = 0;
  //       amount += parseInt(transaction.amount);
  //     }
  //   }
  //   finalArray.push({ amount, date: format(start, "LLL dd") });
  //   return finalArray;
  // }

  groupByWeekConnections(transactions) {
    const initialDate = new Date(transactions[0].createdAt);

    let start = addDays(initialDate, (getDay(initialDate) + 1) * -1);

    const finalArray = [];
    finalArray.push({ amount: 0, date: format(start, "LLL dd") });
    start = addDays(start, 7);

    let amount = 0;

    for (let transaction of transactions) {
      if (
        new Date(transaction.createdAt).getTime() / 1000 <
        start.getTime() / 1000
      ) {
        amount += 1;
      } else {
        finalArray.push({ amount, date: format(start, "LLL dd") });
        start = addDays(start, 7);
        amount += 1;
      }
    }
    finalArray.push({ amount, date: format(start, "LLL dd") });

    return finalArray;
  }

  render() {
    return (
      <div className="container-fluid hero">
        <div className="cont">
          <div className="d-sm-flex align-items-center justify-content-between">
            <h1 className="">Welcome, {this.global.dsUser.displayName}!</h1>
          </div>
          <Leads
            connectionsCount={
              this.state.connections.length > 0
                ? this.state.connections[this.state.connections.length - 1]
                    .amount
                : 0
            }
            listingsCount={this.state.listingsCount}
            enrollmentsCount={this.state.enrollmentsCount}
            formLeadsCount={this.state.formLeadsCount}
            appointmentsCount={this.state.appointmentsCount}
            applicationsCount={this.state.applicationsCount}
          />

          <div className="row">
            <ConnectionsOverview
              total={
                this.state.connections.length > 0
                  ? this.state.connections[this.state.connections.length - 1]
                      .amount
                  : 0
              }
              connections={this.state.connections}
            />
            {/* <EarningOverview
              earnings={this.state.chart || 0}
              total={this.state.oneTime + this.state.recurring}
            /> */}
            <Calendar />
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
