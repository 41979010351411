import React from "reactn";
import SectionThree from "./forms/SectionThree";
import SectionFour from "./forms/SectionFour";
class AddD extends React.Component {
  render() {
    return (
      <div className="cont boxes-row mt-50px">
        {/* <SectionThree vals={this.global.myApplicationProfileInfo} /> */}
        <SectionFour vals={this.global.myApplicationProfileInfo} />
      </div>
    );
  }
}

export default AddD;
