import React, { useGlobal, useEffect } from "reactn";
import { formTypes, BoxForm } from "../../../../components/UI/form";

const ParentGuardian = ({ guardianNo, guardian, onChange, onRemove, main }) => {
  const app = useGlobal("madeApp");
  const [mainUser, changeIt] = useGlobal("pendingParent");
  const handleChange = (e, v) => {
    const c = { ...guardian, [e]: v };
    onChange(c);
  };
  useEffect(() => {
    if (main) {
      onChange({
        ...guardian,
        fullName: mainUser.displayName,
        email: mainUser.email
      });
    }
  }, []);

  const fields = {
    fullName: {
      type: formTypes.Input,
      val: guardian.fullName,
      name: "Full Name",
      required: true,
      isDisabled: main
    },
    address: {
      type: formTypes.Input,
      val: guardian.address,
      name: "Address",
      required: true
    },
    city: {
      type: formTypes.Input,
      val: guardian.city,
      name: "City",
      required: true
    },
    province: {
      type: formTypes.Input,
      val: guardian.province,
      name: "Province",
      required: true
    },
    country: {
      type: formTypes.Input,
      val: guardian.country,
      name: "Country",
      required: true
    },
    postalCode: {
      type: formTypes.Input,
      val: guardian.postalCode,
      name: "Postal Code",
      required: true
    },
    email: {
      type: formTypes.Input,
      val: guardian.email,
      name: "Email",
      required: true,
      isDisabled: main
    },
    homePhone: {
      type: formTypes.Input,
      val: guardian.homePhone,
      name: "Home Phone",
      required: true
    },
    workPhone: {
      type: formTypes.Input,
      val: guardian.workPhone,
      name: "Work Phone"
    }
  };

  return (
    <BoxForm
      handleChange={handleChange}
      name={"PARENT / GUARDIAN " + (guardianNo + 1)}
      fields={fields}
      object={guardian}
      removable={true}
      handleRemove={onRemove}
      removeAfterSaved={false}
    />
  );
};

export default ParentGuardian;
