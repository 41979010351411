import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { NewTabbedTable } from "../../components/UI";
import { MilestonesCanvas } from "../../assets";
import moment from "moment";

class MilestonePreviewModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };

    this.tabs = ["Active"];
    this.headings = {
      Active: [
        {
          id: "name",
          label: "Order",
          customStyle: { width: "300px" },
          customCell: milestone => {
            return (
              <td className="order-width">
                <h3 className="order-title" style={{ fontSize: "16px" }}>
                  {milestone.name}
                </h3>
                <div className="order-description">{milestone.description}</div>
              </td>
            );
          }
        },
        {
          id: "due_date",
          label: "Date",
          customStyle: { width: "200px" },
          customCell: milestone => {
            return (
              <td className="milestone-date">
                {milestone.due_date &&
                  moment(milestone.due_date).format("MMM Do YYYY")}
              </td>
            );
          }
        },
        {
          id: "journal",
          label: "Journal",
          customStyle: { width: "300px" },
          customCell: milestone => {
            if (!milestone.user_journal) {
              return <td className="journal-width"></td>;
            }

            return (
              <td className="journal-width">
                <textarea
                  className="form-control"
                  onChange={e => {
                    const text = e.target.value;
                    this.setState({ journalText: text });
                  }}
                />
              </td>
            );
          }
        },
        {
          id: "actionf",
          label: "",
          customStyle: { width: "400px" },
          customCell: (r, i) => {
            const showViewButton =
              r.has_submitted && r.can_communicate ? true : false;
            const showSubmitButton =
              !r.has_submitted && !r.can_communicate ? true : false;
            return (
              <td
                key={`${i}-btn`}
                className="buttons-container"
                style={{ width: "210px" }}
              >
                {r.can_upload && (
                  <button className="action-buttons">
                    <i className="fas fa-arrow-up upload-icon"></i> Upload
                  </button>
                )}
                {showViewButton && (
                  <button className="action-buttons">View</button>
                )}
                {showSubmitButton && (
                  <button className="action-buttons">Submit</button>
                )}
              </td>
            );
          }
        }
      ]
    };
    this.actions = [{ name: "Edit", action: null }];
  }

  requestTour = async e => {
    e.preventDefault();
  };

  componentDidMount() {
    this.setState({
      data: { Active: this.props.milestone }
    });
  }

  render() {
    return (
      <div className="request-modal-cont">
        <div
          className="request-modal lesspadding"
          style={{ maxWidth: "800px", maxHeight: "645px" }}
        >
          <img
            src={ModalBanner}
            alt="Modal Banner"
            style={{
              height: "40px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>{this.props.milestone.name} Preview</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <div>
              <div
                className="w-100"
                style={{
                  minHeight: 240,
                  backgroundImage: `url(${MilestonesCanvas})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundColor: "rgb(116 116 116 / 35%)",
                  backgroundBlendMode: "multiply",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div style={{ display: "block" }}>
                  <h3
                    style={{
                      color: "white",
                      fontSize: "30px",
                      fontWeight: "bold",
                      margin: "auto",
                      textAlign: "center"
                    }}
                  >
                    Milestones
                  </h3>
                </div>
              </div>
              <div
                className="container-fluid adminprofiles"
                style={{ background: "#f7f7f8", paddingBottom: "50px" }}
              >
                <div
                  className="row"
                  style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                >
                  {/* Milestones */}
                  {this.props.milestone ? (
                    <>
                      <div
                        style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                      >
                        <div className="tablecont">
                          <div
                            className="table-responsive"
                            style={{ fontSize: "16px" }}
                          >
                            <NewTabbedTable
                              tabs={this.tabs}
                              headings={this.headings}
                              data={this.state.data}
                              actions={this.actions}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>There are no milestones to display right now.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MilestonePreviewModal);
