import React from "reactn";
import { Table } from "../../UI";

class FamilyApplicationsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.headings = [
      { id: "child", label: "Child" },
      { id: "age", label: "Age" },
      { id: "plan", label: "Plan" },
      { id: "duration", label: "Duration" },
      { id: "applicationDate", label: "Application Date" },
      { id: "applicationStatus", label: "Application Status" },
      { id: "action", label: " " }
    ];
    this.data = [
      {
        child: "Logan Michaels",
        age: "2 years",
        plan: "Plan A",
        duration: "Jan 1 - Oct 1 2019",
        applicationDate: "Jan 1 2019",
        applicationStatus: "Enrolled"
      },
      {
        child: "Jesse Wheeler",
        age: "Smol",
        plan: "Plan A",
        duration: "Jan 1 - Oct 1 2019",
        applicationDate: "Jan 1 2019",
        applicationStatus: "Rejected"
      }
    ];
    this.actions = [{ name: "VIEW", action: this.viewApplication }];
  }

  viewApplication = async row => {};

  render() {
    return (
      <div>
        <Table
          headings={this.headings}
          data={this.data}
          actions={this.actions}
        />
      </div>
    );
  }
}

export default FamilyApplicationsTable;
