import React from "reactn";
import { Link, withRouter } from "react-router-dom";
import { Logo, LogoutBg } from "../../assets";
import { Scrollbars } from "react-custom-scrollbars";
import { Hidden } from "@material-ui/core";

class Sidebar extends React.PureComponent {
  logout = async () => {
    this.props.history.push("/");
    await this.dispatch.logout();
  };
  render() {
    const { Menu, path } = this.props;
    return (
      <ul
        className={
          (this.global.sidenavShown ? "sidebar-shown " : "") +
          "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        }
        id="accordionSidebar"
      >
        <Link
          className={
            "sidebar-brand d-flex align-items-center justify-content-center"
          }
          to={path}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <img src={Logo} alt="logo" />
        </Link>
        <Scrollbars
          style={{ height: "100vh", overflowX: "Hidden" }}
          autoHide={true}
        >
          <hr className="sidebar-divider my-0" />
          <Menu />
        </Scrollbars>
        <button
          id="sidebarToggleTop"
          type="button"
          className={
            (this.global.sidenavShown ? "sidebarToggleActive " : "") +
            "btn btn-link rounded-circle mr-3"
          }
          onClick={() =>
            this.setGlobal({ sidenavShown: !this.global.sidenavShown })
          }
        >
          <i className="fa fa-bars" />
        </button>
        <div className="logoutbtn">
          <button onClick={() => this.logout()}>Log Out</button>
          <img src={LogoutBg} alt="" />
        </div>
      </ul>
    );
    //  End of Sidebar
  }
}

export default withRouter(Sidebar);
