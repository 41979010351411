import React from "reactn";
import { Modal } from "../../../components/UI";

class PricingPlanDeleteModal extends React.PureComponent {
  render() {
    const { open, onClose } = this.props;
    return (
      <div>
        <Modal
          open={open}
          heading="Delete Pricing Plan"
          onClose={() => {
            onClose(null);
          }}
        >
          <div className="row">
            <div className="col-12">
              <p>
                <strong>Read Before Deleting</strong>
              </p>
            </div>
            <div className="col-12">
              <p style={{ margin: "14px 0", lineHeight: "25px" }}>
                You currently have enrolled students in this Pricing Plan.
                Deleting the plan will disenroll said students at the end of
                their next billing cycle.
              </p>
              <p style={{ margin: "14px 0", lineHeight: "25px" }}>
                If you do not wish to remove the students but want to prevent
                new students from enrolling in this plan we recommend setting
                the plan to inactive instead.
              </p>
              <p style={{ margin: "14px 0", lineHeight: "25px" }}>
                Be sure to inform the parents of their upcoming disenrollment so
                they may prepare if you intend to continue with the removal.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="buttons">
                <button
                  onClick={() => onClose(true)}
                  className="nextbtn"
                  style={{
                    background: "#da4747",
                    borderColor: "#d43636",
                    color: "white"
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default PricingPlanDeleteModal;
