import React from "reactn";
import { Modal } from "../UI";
import { Fragment } from "react";
import { TextField } from "@material-ui/core";
import axios from "axios";

import { CONTENT_BLOCK, TEXT_AREA } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import BaseModal from "./baseModal/BaseModal";
import { format } from "date-fns";

const ShowNotesModal = ({ notes, onClose, onClickEdit, time }) => {
  const getBaseModalProps = () => {
    const notesField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Your Notes",
        content: notes,
        borderStyle: notes
          ? {
              borderStyle: "solid",
              borderWidth: "2px",
              borderColor: "#E9E9E9",
              padding: "10px",
              marginTop: "5px"
            }
          : {},
        textStyles: {
          whiteSpace: "pre-wrap"
        }
      }
    };

    const editButton = {
      name: "Edit",
      handleClick: onClickEdit,
      buttonColour: BLUE_BUTTON,
      textColour: WHITE
    };

    const fields = [];

    fields.push(notesField);

    if (time) {
      const timeField = {
        type: CONTENT_BLOCK,
        data: {
          content: `Last updated: ${format(new Date(time), "PPP pp")}`,
          textStyles: {
            fontSize: "12px",
            fontStyle: "italic"
          }
        }
      };
      fields.push(timeField);
    }

    return {
      title: "Notes",
      fields: fields,
      buttons: [editButton],
      handleClose: onClose,
      height: "450px",
      midSectionHeight: "35vh"
    };
  };

  return <BaseModal {...getBaseModalProps()} />;
};

const EditNotesModal = ({ notes, onClose, onChange, onSubmit, time }) => {
  const getBaseModalProps = () => {
    const editField = {
      type: TEXT_AREA,
      data: {
        name: "Edit Note",
        handleChange: onChange,
        value: notes
      }
    };

    const submitButton = {
      name: "Update Note",
      handleClick: onSubmit,
      buttonColour: BLUE_BUTTON,
      textColour: WHITE
    };

    return {
      title: "Notes",
      fields: [editField],
      buttons: [submitButton],
      handleClose: onClose,
      height: "450px",
      midSectionHeight: "35vh"
    };
  };

  return <BaseModal {...getBaseModalProps()} />;
};

class NotesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: "",
      notesCopy: "",
      isViewModal: true,
      time: null
    };
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        notes: this.props.data.notes,
        notesCopy: this.props.data.notes
      });
      if (this.props.data.lastNoteUpdate) {
        this.setState({
          time: this.props.data.lastNoteUpdate
        });
      }
    }
  }

  // componentWillRecieveProps is now depricated
  // componentWillReceiveProps(props) {
  //   if (props.data) {
  //     this.setState({ notes: props.data.notes });
  //   }
  // }

  saveNotes = async () => {
    const endPnt = `${process.env.REACT_APP_API}/partners/notes`;

    let res;
    const newTime = new Date();

    if (this.props.showTime) {
      res = await axios.post(endPnt, {
        id: this.props.data.id,
        notes: this.state.notes,
        table: this.props.table,
        time: newTime
      });
    } else {
      res = await axios.post(endPnt, {
        id: this.props.data.id,
        notes: this.state.notes,
        table: this.props.table
      });
    }

    if (res.data.success) {
      this.setState({ isViewModal: true });
      this.props.data.notes = this.state.notes;
      if (typeof this.props.onRefresh === "function") {
        this.props.onRefresh();
      }
    }
  };

  getShowNotesModalProps() {
    if (this.props.showTime) {
      return {
        notes: this.state.notes,
        onClose: () => {
          this.props.onClose();
        },
        onClickEdit: () => {
          this.setState({ isViewModal: false });
        },
        time: this.state.time
      };
    } else {
      return {
        notes: this.state.notes,
        onClose: () => {
          this.props.onClose();
        },
        onClickEdit: () => {
          this.setState({ isViewModal: false });
        }
      };
    }
  }

  getEditNotesModalProps() {
    if (this.props.showTime) {
      return {
        notes: this.state.notesCopy,
        onClose: () => this.props.onClose,
        onChange: e => this.setState({ notesCopy: e.target.value }),
        onSubmit: () => {
          this.setState(
            {
              notes: this.state.notesCopy,
              isViewModal: true,
              time: new Date()
            },
            this.saveNotes
          );
        },
        time: this.props.data.lastNoteUpdate,
        height: "50vh",
        midSectionHeight: "52vh"
      };
    } else {
      return {
        notes: this.state.notesCopy,
        onClose: this.props.onClose,
        onChange: e => this.setState({ notesCopy: e.target.value }),
        onSubmit: () => {
          this.setState(
            { notes: this.state.notesCopy, isViewModal: true },
            this.saveNotes
          );
        },
        height: "50vh",
        midSectionHeight: "52vh"
      };
    }
  }

  render() {
    // console.log("this.state", this.state);
    // console.log("this.props", this.props);
    return (
      <div>
        {this.state.isViewModal ? (
          <ShowNotesModal {...this.getShowNotesModalProps()} />
        ) : (
          <EditNotesModal {...this.getEditNotesModalProps()} />
        )}
      </div>
    );
  }
}

export default NotesModal;
