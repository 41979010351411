import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import BaseModal from "./baseModal/BaseModal";
import { SWITCH, STRING_INPUT, GET_HTML, TEXT_AREA } from "./baseModal/FieldTypes";
import { notEmptyString } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { DatePickerCalendar } from "react-nice-dates";

class EditFormFieldModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      options: [{}, {}, {}],
      data: null,
      policyText: "",
      isFileUpload: false,
      checkboxText: "I agree to the listed policies.",
      file: null
    };
  }

  async fileChangeDoc(e) {
    if (e.target.files.length && e.target.files[0]) {
      const fd = new FormData();
      fd.set("image", e.target.files[0]);
      const ep = `${process.env.REACT_APP_API}/partners/forms/document`;
      this.setGlobal({ loading: true });
      const result = await axios.post(ep, fd);
      this.setGlobal({ loading: false });
      if (result.data.success) {
        this.setState({ file: result.data.data });
      }
    }
  }

  async submitForm(e) {
    e.preventDefault();

    // Validate Label.
    if (!this.state.data.first_label) {
      toast.error("Please enter a label!");
      return;
    }

    // Validate file upload.
    if (
      this.state.data.field === "Policy" &&
      this.state.isFileUpload &&
      !this.state.file
    ) {
      toast.error("Please upload a file!");
      return;
    }

    // Validate policy text.
    if (
      this.state.data.field === "Policy" &&
      !this.state.policyText &&
      !this.state.file
    ) {
      toast.error("Please enter either policy text or upload a file!");
      return;
    }

    const field = {
      ...this.state.data,
      policyOptions: {
        isFileUpload: this.state.isFileUpload,
        policyText: this.state.policyText,
        checkboxText: this.state.checkboxText,
        file: this.state.file,
        link: this.state.link
      }
    };
    this.props.editField(field, this.props.editFormIndex, this.props.editIndex);
    this.props.onClose();
  }

  componentDidMount() {
    const data =
      this.props.editData[this.props.editFormIndex].fields[
        this.props.editIndex
      ];

    const parsed = JSON.parse(data);
    this.setState({
      data: parsed,
      ...parsed.policyOptions
    });
  }

  calendarFunc = () => {
    return (
      <div style={{ height: "50%" }}>
        <DatePickerCalendar
          format="MMMM d, yyyy"
          onDateChange={e => {
          }}
        />
      </div>
    )
  }

  optionsFunc = () => {
    return (
      this.state.data.options.map((e, i) => (
        <div
          className="form-group"
          style={{
            display: "flex",

            alignItems: "center",
            marginBottom: 0
          }}
          key={i}
        >
          <input
            name="question"
            id="organization"
            type="text"
            className="string-input-field field-component-input-box"
            placeholder={`Option text`}
            style={{
              width: i === 0 ? "79%" : "80%",
              marginRight: "10px"
            }}
            onChange={e => {
              {
                const options = [...this.state.data.options];
                options[i] = {
                  text: e.target.value,
                  value: e.target.value
                };
                this.setState({
                  data: {
                    ...this.state.data,
                    options: options
                  }
                });
              }
            }}
            value={this.state.data.options[i].text || ""}
          />

          <button
            type="button"
            onClick={_ => {
              const options = [...this.state.data.options, {}]
              this.setState({
                data: {...this.state.data, options: options}
              })
            }}
            style={{
              width: "8%",
              backgroundColor: "yellowgreen",
              border: "none",
              color: "white",
              borderRadius: "3px",
              marginRight: "10px",
              marginTop: "8px"
            }}
          >
            +
          </button>

          {i !== 0 && (
            <button
              style={{
                width: "8%",
                backgroundColor: "red",
                border: "none",
                color: "white",
                borderRadius: "3px",
                marginTop: "8px"
              }}
              type="button"
              onClick={_ =>
                {
                const options = this.state.data.options.filter(
                  (_, index) => index !== i
                )
                this.setState({
                  data: {...this.state.data, options: options}
                })
              }}
            >
              -
            </button>
          )}
        </div>
      )
    ))
  }

  uploadFileFunc = () => {
    return (
      <div
        className="form-group"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: 0
        }}
      >
        <label
          htmlFor="uploaded_expense"
          style={{
            width: "35%",
            textAlign: "left",
            fontSize: "1.15rem",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262"
          }}
        >
          Policy Document Upload{" "}
        </label>
        {this.state.isFileUpload && (
          <div
            style={{
              marginLeft: "0px",
              display: "flex",
              alignItems: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262",
              marginBottom: "0.5rem"
            }}
          >
            <Button
              component="span"
              //color="primary"
              size="small"
              startIcon={<AttachFileIcon />}
              onClick={_ => {
                document.getElementById("uploadDocument").click();
              }}
            >
              Attach File
            </Button>
            
          </div>
        )}
        {this.state.file && this.state.isFileUpload && (
          <p className="confirmation" style={{ marginBottom: "10px" }}>
            File Uploaded!
          </p>
        )}
      </div>
    )
  }

  hiddenFieldFunc = () => {
    return (
      <input
        type="file"
        style={{ display: "none" }}
        id="uploadDocument"
        onChange={e => this.fileChangeDoc(e)}
        accept="*"
      />
    )
  }

  getBaseModalFields = () => {
    const labelField = {
      type: STRING_INPUT,
      data: {
        name: "Label",
        required: true,
        value: this.state.data.first_label,
        handleChange: e => {
          this.setState({
            data: {
              ...this.state.data,
              first_label: e.target.value
            }
          });
        }
      }
    }

    const optionField = {
      type: GET_HTML,
      data: {
        name: "Options",
        required: true,
        gethtml: this.optionsFunc
      }
    };

    const policyTextField = {
      type: TEXT_AREA,
      data: {
        name: "Policy Text",
        required: !this.state.isFileUpload,
        placeholder: "Enter your policy here...",
        value: this.state.policyText,
        handleChange: e => this.setState({ policyText: e.target.value }),
      }
    }

    const uploadFileToggleField = {
      type: SWITCH,
      data: {
        name: "Upload File?",
        info: "Check to upload a PDF of your policy",
        checked: this.state.isFileUpload,
        handleClick: e => this.setState({ isFileUpload: e.target.checked }),
      }
    }

    const uploadFileField = {
      type: GET_HTML,
      data: {
        gethtml: this.uploadFileFunc
      }
    }

    const embedLinkField = {
      type: STRING_INPUT,
      data: {
        name: "Embed Link",
        placeholder: "Enter link to waiver...",
        info: "Must start with http:// or https://",
        handleChange: e => this.setState({ link: e.target.value }),
        value: this.state.link
      },
      validators: { validateSubmit: notEmptyString(this.state.link) }
    }

    const checkboxTextField = {
      type: STRING_INPUT,
      data: {
        name: "Checkbox Text",
        required: true,
        info: "This text will appear next to the checkbox",
        handleChange: e => this.setState({ checkboxText: e.target.value }),
        value: this.state.checkboxText
      },
      validators: { validateSubmit: notEmptyString(this.state.checkboxText) }
    }

    const dateField = {
      type: GET_HTML,
      data: {
        gethtml: this.calendarFunc
      }
    }

    const hiddenField = {
      type: GET_HTML,
      data: {
        gethtml: this.hiddenFieldFunc
      }
    }

    const fields = []

    fields.push(labelField);

    if (this.state.data.field === "DOB") {
      fields.push(dateField);
    }

    if (this.state.data.field === "Dropdown" || this.state.data.field === "Multiple Choices" || this.state.data.field === "Checkboxes") {
      fields.push(optionField);
    }
    
    if (this.state.data.field === "Policy") {
      fields.push(policyTextField);
      fields.push(uploadFileToggleField);
      if (this.state.isFileUpload) {
        fields.push(uploadFileField);
      } else {
        fields.push(embedLinkField);
      }
      fields.push(hiddenField);
      fields.push(checkboxTextField);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const editFieldButton = {
      name: "Update",
      handleClick: e => this.submitForm(e),
      buttonColour: BLUE_BUTTON,
      textColour: WHITE
    };

    return [editFieldButton];
  }

  getBaseModalProps = () => {
    return {
      title: "Edit Form Field",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  }

  render() {
    if (!this.state.data) {
      return null;
    }

    return (<BaseModal {...this.getBaseModalProps()}/>)

    return (
      <ModalTemplate
        heading="Edit Form Field"
        onClose={_ => this.props.onClose()}
      >
        <form onSubmit={e => this.submitForm(e)}>
          <div className="modal-main">
            <ModalFields.input
              label="Label"
              name="question"
              type="text"
              placeholder="Custom Label..."
              required
              value={this.state.data.first_label}
              onChange={e => {
                this.setState({
                  data: {
                    ...this.state.data,
                    first_label: e.target.value
                  }
                });
              }}
            />
            {(this.state.data.field === "Dropdown" ||
              this.state.data.field === "Multiple Choices" ||
              this.state.data.field === "Checkboxes") &&
              this.state.data.options.map((e, i) => (
                <div
                  className="form-group"
                  style={{
                    display: "flex",

                    alignItems: "center",
                    marginBottom: 0
                  }}
                  key={i}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "30%", textAlign: "left" }}
                  >
                    {i === 0 && "Options *"}
                  </label>
                  <input
                    name="question"
                    id="organization"
                    type="text"
                    className="form-control"
                    placeholder={`Option text`}
                    style={{
                      width: "50%",
                      marginRight: i === 0 ? "7px" : "10px"
                    }}
                    onChange={e => {
                      {
                        const options = [...this.state.data.options];
                        options[i] = {
                          text: e.target.value,
                          value: e.target.value
                        };
                        this.setState({
                          data: {
                            ...this.state.data,
                            options: options
                          }
                        });
                      }
                    }}
                    value={this.state.data.options[i].text || ""}
                  />

                  <button
                    type="button"
                    onClick={_ =>
                      this.setState({
                        options: [...this.state.data.options, {}]
                      })
                    }
                    style={{
                      width: "8%",
                      backgroundColor: "yellowgreen",
                      border: "none",
                      color: "white",
                      borderRadius: "3px",
                      marginRight: "10px"
                    }}
                  >
                    +
                  </button>

                  {i !== 0 && (
                    <button
                      style={{
                        width: "8%",
                        backgroundColor: "red",
                        border: "none",
                        color: "white",
                        borderRadius: "3px"
                      }}
                      type="button"
                      onClick={_ =>
                        this.setState({
                          options: this.state.data.options.filter(
                            (_, index) => index !== i
                          )
                        })
                      }
                    >
                      -
                    </button>
                  )}
                </div>
              ))}
            {this.state.data.field === "Policy" && (
              <>
                <ModalFields.textarea
                  label="Policy Text"
                  name="policyText"
                  value={this.state.policyText}
                  placeholder="Enter your policy here..."
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  required={!this.state.isFileUpload}
                />
                <ModalFields.switch
                  label="Upload File?"
                  name="isFileUpload"
                  checked={this.state.isFileUpload}
                  onClick={e => {
                    this.setState({ [e.target.name]: e.target.checked });
                  }}
                  tooltip="Check to upload a PDF of your policy"
                />
                {this.state.isFileUpload && (
                  <ModalFields.custom
                    label="Policy Document Upload"
                    name="policyDocument"
                    required
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      startIcon={<CloudUpload />}
                      style={{ width: "65%", marginRight: "0px !important" }}
                      onClick={_ => {
                        document.getElementById("uploadDocument").click();
                      }}
                    >
                      Upload
                    </Button>
                  </ModalFields.custom>
                )}
                {this.state.file && this.state.isFileUpload && (
                  <p className="confirmation" style={{ marginBottom: "10px" }}>
                    File Uploaded!
                  </p>
                )}
                {!this.state.isFileUpload && (
                  <ModalFields.input
                    label="Embed link"
                    name="link"
                    type="text"
                    placeholder="Enter link to waiver..."
                    onChange={e => {
                      this.setState({
                        [e.target.name]: e.target.value
                      });
                    }}
                    value={this.state.link}
                    tooltip="Must start with http:// or https://"
                  />
                )}
                <ModalFields.input
                  label="Checkbox Text"
                  name="checkboxText"
                  value={this.state.checkboxText}
                  type="text"
                  required
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  tooltip="This text will appear next to the checkbox"
                />
              </>
            )}
            <input
              type="file"
              style={{ display: "none" }}
              id="uploadDocument"
              onChange={e => this.fileChangeDoc(e)}
              accept="*"
            />
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn">
              Update
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(EditFormFieldModal);
