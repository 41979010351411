import React, { Component } from "reactn";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { formTypes } from "../../components/UI/form";
import ReactTooltip from "react-tooltip";
import { format } from "date-fns";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  DROPDOWN,
  DATE_PICKER,
  SELECT_MULTIPLE_DROPDOWN,
  TEXT_AREA
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class SendInvoiceEmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: ""
    };
    console.log("props.data", props.data);
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async send_email(e) {
    e.preventDefault();

    this.props.sendMessage(this.state.message);
    this.props.onClose();
  }

  getBaseModalFields() {
    const sendtoInput = {
      type: STRING_INPUT,
      data: {
        name: "Send To",
        required: false,
        isDisabled: true,
        handleChange: e => {
          console.log("e", e);
          this.setState({
            discount_name: e.target.value
          });
        },
        value: this.props.customer[0].email,
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const messageInput = {
      type: TEXT_AREA,
      data: {
        name: "Message",
        placeholder: "Enter a message...",
        required: false,
        handleChange: e => {
          this.setState({
            message: e.target.value
          });
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const scheduledInput = {
      type: STRING_INPUT,
      data: {
        name: "Scheduled On",
        isDisabled: true,
        value: this.props.data?.orderStartDate
          ? format(new Date(this.props.data.orderStartDate), "LLL d, yyyy")
          : format(new Date(), "LLL d, yyyy"),
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const fields = [];
    fields.push(sendtoInput);
    fields.push(messageInput);
    fields.push(scheduledInput);

    return fields;
  }

  getBaseModalButtons = () => {
    const previewButton = {
      name: "Preview",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => e
    };

    const sendButton = {
      name: "Send",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.send_email(e)
    };

    return [previewButton, sendButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Send Invoice Email",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    console.log("this.state", this.state);
    console.log("this.global", this.global);
    console.log("this.props", this.props);
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>Send Invoice Email</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form onSubmit={e => this.send_email(e)}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="email"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Send To
                </label>
                <input
                  name="email"
                  id="email"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  disabled={true}
                  onChange={e => {
                    console.log("e", e);
                    this.setState({
                      discount_name: e.target.value
                    });
                  }}
                  value={this.props.customer[0].email}
                />
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="message"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  type="text"
                  className="form-control"
                  rows="4"
                  style={{ width: "70%" }}
                  placeholder="Enter a message..."
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="message"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Scheduled On
                </label>
                <span style={{ width: "70%", fontSize: "16px" }}>
                  {this.props.data.orderStartDate
                    ? format(
                        new Date(this.props.data.orderStartDate),
                        "LLL d, yyyy"
                      )
                    : format(new Date(), "LLL d, yyyy")}
                </span>
              </div>

              <div className="modal-footer" style={{ marginTop: "25px" }}>
                <div
                  className="btn"
                  style={{
                    backgroundColor: "#3b4eff",
                    border: "1px solid #3b4eff",
                    width: "200px",
                    borderRadius: "7px !important",
                    color: "white",
                    padding: "8px !important",
                    fontWeight: 600,
                    boxShadow: "4px 4px 6px 0px rgb(191 190 190 / 75%)",
                    height: "36px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "3px"
                  }}
                >
                  Preview
                </div>
                <button
                  type="submit"
                  // onClick={_ => this.savePlan()}
                  className="btn profile-btn"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default SendInvoiceEmailModal;
