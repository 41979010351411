import React, { useGlobal, useEffect } from "reactn";
import _ from "lodash";
import { format, parse, fromUnixTime } from "date-fns";

import {
  useAggregatedOrders,
  useDateFilter,
  MILLISECONDS_IN_SECONDS,
  CENTS_IN_DOLLAR
} from "../../../providers/reports";
import { showPlan, formatMoney } from "../../../utilities/reportsAnalytics";
import {
  DateFilter,
  ReportContainer,
  ReportTable
} from "../../../components/UI/reportsAnalytics";

const OverdueInvoies = () => {
  const orgData = useGlobal("organizationInfo")[0];
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");
  const [dateFrom, dateTo, setDateFrom, setDateTo] = useDateFilter();

  const allOrders = useAggregatedOrders();
  useEffect(() => {
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" },
      { label: "Overdue Invoices", to: "/reports/overdue-invoices" }
    ]);
  }, []);
  /**
   * Function used to filter orders. Should only return true for overdue invoices.
   *
   * @returns {Boolean} Whether or not the order should be displayed/considered.
   *
   * @param {Object} order The order to filter.
   */
  const filterOrder = order => {
    if (
      order.status === "succeeded" ||
      order.status === "paid" ||
      order.status === "Paid"
    ) {
      return false;
    }
    // Converts the YYYY-MM-DD date filter dates to unix timestamps.
    const dateFromUnix = new Date(dateFrom).getTime() / MILLISECONDS_IN_SECONDS;
    const tempDateTo = new Date(dateTo);
    // Offset by a day to provide the correct filter.
    const dateToUnix =
      tempDateTo.setDate(tempDateTo.getDate() + 1) / MILLISECONDS_IN_SECONDS;
    return order.created > dateFromUnix && order.created < dateToUnix;
  };

  /**
   * Displays the table rows based on date filter.
   *
   * @returns {Array} Array representing table rows.
   *
   * @param {Boolean} isManual Filter by manual payments or not.
   */
  const getDetailedPayments = ({ isManual }) => {
    const filteredOrders = allOrders.filter(order => {
      return filterOrder(order) && Boolean(order.manual) === isManual;
    });

    return filteredOrders.map(e => {
      const total = e.revenue === NaN ? 0 : e.revenue;
      if (!e.status) {
        e.status = "Pending";
      }
      return [
        e.customer_name,
        showPlan(e),
        e.status,
        formatMoney(parseFloat(total / CENTS_IN_DOLLAR))
      ];
    });
  };

  const csvHeaders = [
    { label: "Customer Name", key: "name" },
    { label: "Transaction Creation Date", key: "dateTime" },
    { label: "Listing", key: "listingName" },
    { label: "Product Description", key: "description" },
    { label: "Manual", key: "manual" },
    { label: "Amount", key: "amount" }
  ];

  /**
   * Gets the CSV for the CSVLink component to use.
   *
   * @returns {Array<Object>} Each property of each object corresponds to a column.
   */
  const getCsvData = () => {
    const filteredOrders = allOrders.filter(order => {
      return filterOrder(order);
    });

    let csvRows = [];
    filteredOrders.forEach(row => {
      const productName =
        row.product.program_name ||
        row.product.event_title ||
        row.product.membership_name;

      csvRows.push({
        name: row.customer_name,
        dateTime: format(fromUnixTime(row.created), "yyyy-MM-dd'T'HH:mm:ss"),
        listingName: productName,
        description: showPlan(row),
        manual: Boolean(row.manual),
        amount: formatMoney(row.revenue / CENTS_IN_DOLLAR)
      });
    });

    return csvRows;
  };

  let reportSubtitles = [
    orgData.organization_title,
    `For ${format(parse(dateFrom, "yyyy-MM-dd", new Date()), "MMM d, yyyy")}
    - 
    ${format(parse(dateTo, "yyyy-MM-dd", new Date()), "MMM d, yyyy")} 
    `
  ];

  return (
    <div className="admin">
      <div className="container-fluid adminprofiles">
        <div className="row cont">
          <div className="col-md-6">
            <h1>Overdue Invoices</h1>
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-section">
            <DateFilter
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
            />
          </div>
        </div>
        <ReportContainer
          title="Overdue Invoices"
          subtitles={reportSubtitles}
          csvHeaders={csvHeaders}
          csvData={getCsvData()}
          fileName={`Overdue_Payments_${dateFrom}-${dateTo}.csv`}
        >
          <div className="report-table-large">
            <ReportTable
              headings={[
                { label: "Credit Card", className: "table-item-bold" },
                { label: "Product Description" },
                { label: "Payment Status" },
                {
                  label: "Total",
                  className: "table-item-money",
                  style: { textAlign: "right" }
                }
              ]}
              body={getDetailedPayments({ isManual: false })}
              noDataText="No overdue credit card payments!"
            />
            <ReportTable
              headings={[
                { label: "Offline Payments", className: "table-item-bold" },
                { label: "Product Description" },
                { label: "Payment Status" },
                {
                  label: "Total",
                  className: "table-item-money",
                  style: { textAlign: "right" }
                }
              ]}
              body={getDetailedPayments({ isManual: true })}
              noDataText="No overdue offline payments!"
            />
          </div>
        </ReportContainer>
      </div>
    </div>
  );
};

export default OverdueInvoies;
