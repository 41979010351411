import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import { GET_HTML, DROPDOWN, STRING_INPUT } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

const DOLLAR_TO_CENT = 100;

class EditDiscountModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      existingDiscounts: [],
      selectedDiscount: null,
      discountObj: {}
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async editDiscount(e) {
    e.preventDefault();
    console.log("EditDiscountModal");
    if (!this.props.isStripe) {
      if (Object.keys(this.state.discountObj).length > 0) {
        const ep = `${process.env.REACT_APP_API}/partners/orders/update`;

        let inv_details = [...this.props.invoiceDetails];
        inv_details[this.props.selectedItemIndex].discountId =
          this.state.selectedDiscount;
        console.log("inv_details", inv_details);

        const res = await axios.post(ep, {
          id: this.props.match.params.id,
          invoice_details: inv_details
        });

        console.log("res", res);
        if (res.data.success) {
          toast.success("Discount Updated");
          this.props.editDiscount(true);
          this.props.onClose();
        }
      }
    } else {
      this.setGlobal({ loading: true });
      const ep = `${process.env.REACT_APP_API}/partners/orders/delete-invoice-discount`;
      await axios.post(ep, { id: this.props.selectedItem });
      const ep2 = `${process.env.REACT_APP_API}/partners/sub-invoice/line/${this.props.selectedItem}/discount`;
      const res = await axios.post(ep2, {
        discount: this.state.discountObj
      });
      this.props.onClose();
    }
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/pricing_items_discounts`;
    const res = await axios.get(ep);
    console.log("res", res);

    let invoiceDiscountId;
    let selectedInvoiceObj;

    if (!this.props.isStripe) {
      const invoiceDetails = this.props.invoiceDetails;
      invoiceDiscountId =
        invoiceDetails[this.props.selectedItemIndex].discountId;
      selectedInvoiceObj =
        invoiceDetails[this.props.selectedItemIndex].discount;
    } else {
      invoiceDiscountId = parseInt(this.props.discountId);
      selectedInvoiceObj = res.data.data.find(r => r.id === invoiceDiscountId);
    }

    if (res.data.success) {
      this.setState({
        existingDiscounts: res.data.data,
        selectedDiscount: invoiceDiscountId,
        discountObj: selectedInvoiceObj
      });
    }
  }

  removeDiscountModal = async () => {
    if (!this.props.isStripe) {
      const ep = `${process.env.REACT_APP_API}/partners/orders/update`;

      let inv_details = [...this.props.invoiceDetails];
      inv_details[this.props.selectedItemIndex].discountId = null;
      inv_details[this.props.selectedItemIndex].discount = null;

      console.log("inv_details", inv_details);

      const res = await axios.post(ep, {
        id: this.props.match.params.id,
        invoice_details: inv_details
      });

      console.log("res", res);
      if (res.data.success) {
        toast.success("Discount Removed");

        this.props.removeDiscount();
        this.props.onClose();
      }
    } else {
      this.setGlobal({ loading: true });
      const ep = `${process.env.REACT_APP_API}/partners/sub-invoice/line/:id/discount`;
      await axios.delete(ep);
      this.props.onClose();
    }
  };

  selectdiscountOptions = () => {
    return this.state.existingDiscounts.map((e, i) => ({
      value: e.id,
      key: e.id,
      text:
        e.discount_name +
        " " +
        `${e.type === "Percentage" ? "" : "$"}${e.amount_off}${
          e.type === "Percentage" ? "%" : ""
        }`
    }));
  };

  getBaseModalFields() {
    const selectdiscountInput = {
      type: DROPDOWN,
      data: {
        name: "Select Discount",
        required: false,
        placeholder: "Please Select",
        value: this.state.selectedDiscount,
        handleChange: e => {
          const discountObj = this.state.existingDiscounts.filter(
            f => f.id === parseInt(e.target.value)
          )[0];

          this.setState({
            selectedDiscount: e.target.value,
            discountObj: discountObj
          });
        },
        choices: this.selectdiscountOptions()
      }
    };

    const discountappliedInput = {
      type: STRING_INPUT,
      data: {
        name: "Discount Applied",
        required: false,
        value:
          this.state.selectedDiscount === null
            ? "$0.00"
            : `${
                this.state.discountObj?.type === "Percentage"
                  ? `${this.state.discountObj?.amount_off}%`
                  : `$${this.state.discountObj?.amount_off}`
              }`
      }
    };

    const fields = [];
    fields.push(selectdiscountInput);
    fields.push(discountappliedInput);

    return fields;
  }

  getBaseModalButtons = () => {
    const creatediscountButton = {
      name: "Remove",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.removeDiscountModal()
    };

    const applydiscountButton = {
      name: "Update",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.editDiscount(e)
    };

    return [creatediscountButton, applydiscountButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Edit Price Item",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose
    };
  };

  render() {
    console.log("this.state", this.state);
    console.log("this.global", this.global);
    console.log("this.props", this.props);
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <ModalTemplate
        heading="Edit Price Item"
        onClose={this.props.onClose}
        style={{ overflow: "visible" }}
      >
        <form onSubmit={e => this.editDiscount(e)}>
          <div id="heightset" className="modal-main">
            <ModalFields.select
              label="Select Discount"
              name="selectedDiscount"
              onChange={e => {
                const discountObj = this.state.existingDiscounts.filter(
                  f => f.id === parseInt(e.target.value)
                )[0];

                this.setState({
                  [e.target.name]: e.target.value,
                  discountObj: discountObj
                });
              }}
              required={false}
              value={this.state.selectedDiscount}
            >
              <option value={""}>Please Select</option>
              {this.state.existingDiscounts.map((e, i) => {
                return (
                  <option value={e.id} key={e.id}>{`${e.discount_name} ${
                    e.type === "Percentage" ? "" : "$"
                  }${e.amount_off}${
                    e.type === "Percentage" ? "%" : ""
                  }`}</option>
                );
              })}
            </ModalFields.select>

            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label htmlFor="name" style={{ width: "25%", textAlign: "left" }}>
                Discount Applied
              </label>
              <span
                style={{ width: "70%", color: "#7b7b90", fontSize: "16px" }}
              >
                {this.state.selectedDiscount === null
                  ? "$0.00"
                  : `${
                      this.state.discountObj?.type === "Percentage"
                        ? `${this.state.discountObj?.amount_off}%`
                        : `$${this.state.discountObj?.amount_off}`
                    }`}
              </span>
            </div>
          </div>

          <div className="modal-footer">
            <div
              className="btn"
              style={{
                backgroundColor: "#3b4eff",
                border: "1px solid #3b4eff",
                width: "200px",
                borderRadius: "7px !important",
                color: "white",
                padding: "8px !important",
                fontWeight: 600,
                boxShadow: "4px 4px 6px 0px rgb(191 190 190 / 75%)",
                height: "36px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "3px"
              }}
              onClick={_ => {
                console.log("Create Discount");
                this.removeDiscountModal();
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <span>Remove</span>
              </div>
            </div>

            <button type="submit" className="btn">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <img
                  src={CircleCheck}
                  alt=""
                  style={{
                    color: "white",
                    height: "15px",
                    width: "15px",
                    marginRight: "8px"
                  }}
                />
                <span>Update</span>
              </div>
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(EditDiscountModal);
