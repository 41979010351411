import React from "reactn";
import axios from "axios";
import CalendarPartner from "../../components/calendar/CalendarPartner";
import { addDays, addWeeks, isSameDay } from "date-fns";
import LinkIcon from "@material-ui/icons/Link";
import AddIframeModal from "../../components/modals/AddIframeModal";
import {
  buildScheduleForProduct,
  getGroupedWeekdayRange,
  makeUTCDateTimeAsLocalTZ,
  prepareClosureDates
} from "../front/SpecificPageUtils/utils";
import { MiniPlus } from "../../assets/index";
import StaffScheduleInfoModal from "../../components/modals/StaffScheduleInfoModal";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import TeamCalendar from "../../components/calendar/TeamCalendar";

class StaffSchedules extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: "",
      tours: [],
      original: [],
      monthlySchedules: [],
      weeklySchedules: [],
      leadModal: false,
      view: "Week",
      openCreateStaffScheduleModal: false,
      users: [],
      task: "Schedule",
      staff: "",
      startTime: "",
      endTime: "",
      startDate: "",
      endDate: "",
      isRepeatingWeekly: false,
      refresh: false
    };
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Team Schedules", to: "/schedules" }
      ]
    });
  }

  async componentDidMount() {
    // this.newReminderAdded();
    const ep = `${process.env.REACT_APP_API}/partners/roles`;
    const users = await axios.get(ep);
    if (users.data.success) {
      this.setState({
        users: users.data.data.users.filter(user => !user.isArchived)
      });
    }
  }

  getRolePermissions = async () => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/role/${this.global.dsUser.user_id}`;
    const role = await axios.get(ep);
    return role;
  };

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  getAllStaff = () => {
    return [
      { value: this.global.dsUser.id, text: this.global.dsUser.name },
      ...this.state.users
        .filter(f => !f.isArchived && f.sub_user)
        .map(({ id, username }) => ({ value: id, text: username }))
    ];
  };

  async submitSchedule() {
    if (this.state.staff === "" && !this.state.selectedStaff) {
      toast.error("Please select a staff member");
      return;
    }

    if (this.state.task === "") {
      toast.error("Please enter the task");
      return;
    }

    if (this.state.startDate === "" && !this.state.selectedDate) {
      toast.error("Please enter the start date");
      return;
    }

    if (this.state.startTime === "") {
      toast.error("Please enter the start time");
      return;
    }

    if (this.state.endTime === "") {
      toast.error("Please enter the end time");
      return;
    }

    const timeStringStart = this.state.startTime; // The time string in "HH:MM" format
    const timeStringEnd = this.state.endTime;

    const date = new Date(
      this.state.startDate !== ""
        ? this.state.startDate
        : this.state.selectedDate
    );
    const dateEnd = new Date(
      this.state.endDate !== "" ? this.state.endDate : this.state.startDate
    );

    const [hours, minutes] = timeStringStart.split(":"); // Split the time string into hours and minutes
    const [hoursEnd, minutesEnd] = timeStringEnd.split(":");

    date.setHours(hours);
    date.setMinutes(minutes);
    dateEnd.setHours(hoursEnd);
    dateEnd.setMinutes(minutesEnd);

    if (date.getTime() >= dateEnd.getTime()) {
      toast.error("Start Time must be before end time!");
      return;
    }

    const staffId =
      this.state.staff === ""
        ? this.state.selectedStaff.id
        : parseInt(this.state.staff);
    const ep = `${process.env.REACT_APP_API}/partners/users/schedule/${staffId}`;

    this.setGlobal({ loading: true });
    console.log(
      "LOOK HERE 123123",
      this.state.task,
      this.state.taskDescription,
      this.state.startDate,
      this.state.selectedDate,
      this.state.endDate,
      date,
      dateEnd,
      this.state.isRepeatingWeekly,
      this.state.selectedDate,
      this.state.endDate === ""
    );
    const results = await axios.post(ep, {
      task_name: this.state.task,
      task_description: this.state.taskDescription,
      isRepeatingWeekly: this.state.isRepeatingWeekly,
      start_date:
        this.state.startDate !== ""
          ? this.state.startDate
          : new Date(this.state.selectedDate).toISOString().split("T")[0],
      end_date: this.state.endDate !== "" ? this.state.endDate : null,
      start_time: date,
      end_time: dateEnd,
      selected_days_of_week: this.state.daysOfTheWeek
    });

    if (results.data.success) {
      toast.success("Schedule successfully created!");
      this.setState({
        task: "Schedule",
        staff: "",
        startTime: "",
        endTime: "",
        startDate: "",
        endDate: "",
        selectedDate: "",
        openCreateStaffScheduleModal: false,
        refresh: !this.state.refresh
      });
      this.setGlobal({ loading: false });
    } else {
      toast.error("Error while creating schedule.");
    }
  }

  render() {
    return (
      <div className="admin">
        {this.state.openCreateStaffScheduleModal &&
          this.props.view !== "Profile View" && (
            <div>
              <Modal
                size="lg"
                isOpen={this.state.openCreateStaffScheduleModal}
                toggle={() =>
                  this.setState({
                    openCreateStaffScheduleModal:
                      !this.state.openCreateStaffScheduleModal
                  })
                }
                contentClassName="custom-modal-content"
              >
                <div
                  className="modal-content"
                  style={{ borderRadius: "30px 30px 0px 0px" }}
                >
                  <div className="modal-header">
                    <h5 className="modal-title staff-modal-title">
                      Create Staff Schedule
                    </h5>
                    <button
                      className="close"
                      style={{ paddingTop: "16px" }}
                      onClick={() =>
                        this.setState({
                          openCreateStaffScheduleModal: false
                        })
                      }
                    >
                      <span>&times;</span>
                    </button>
                  </div>
                </div>
                <ModalBody>
                  <form>
                    <Row>
                      <Col lg={12}>
                        <label htmlFor="staff">Staff</label>
                        <select
                          id="myDropdown"
                          defaultValue={
                            this.state.selectedStaff
                              ? this.state.selectedStaff.id
                              : ""
                          }
                          onChange={e =>
                            this.setState({ staff: e.target.value })
                          }
                        >
                          <option value="" key="empty">
                            Please select
                          </option>
                          {this.getAllStaff().map(option => (
                            <option value={option.value} key={option.value}>
                              {option.text}
                            </option>
                          ))}
                        </select>
                        <div>
                          <label htmlFor="task">Task</label>
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.task}
                            style={{ padding: "5px" }}
                            onChange={e =>
                              this.setState({ task: e.target.value })
                            }
                          />
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <label htmlFor="start-date">Start Date</label>
                            <input
                              type="date"
                              id="start-date"
                              style={{ padding: "5px", background: "none" }}
                              defaultValue={null}
                              onChange={e =>
                                this.setState({ startDate: e.target.value })
                              }
                            />
                          </div>
                          {this.state.isRepeatingWeekly && (
                            <div style={{ marginLeft: "auto" }}>
                              <label htmlFor="end-date">End Date</label>
                              <input
                                type="date"
                                id="end-date"
                                style={{
                                  padding: "5px",
                                  background: "none"
                                }}
                                onChange={e =>
                                  this.setState({ endDate: e.target.value })
                                }
                              />
                            </div>
                          )}
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <label htmlFor="start-time">Start Time</label>
                            <input
                              type="time"
                              id="start-time"
                              onChange={e =>
                                this.setState({ startTime: e.target.value })
                              }
                            />
                          </div>
                          <div style={{ marginLeft: "auto" }}>
                            <label htmlFor="end-time">End Time</label>
                            <input
                              type="time"
                              id="end-time"
                              onChange={e =>
                                this.setState({ endTime: e.target.value })
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </form>
                  <div style={{ textAlign: "center" }}>
                    <button
                      className="staff-schedule-save-btn"
                      onClick={() => this.submitSchedule()}
                    >
                      Save
                    </button>
                  </div>
                </ModalBody>
              </Modal>
            </div>
          )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div
              className="col-md-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div style={{ display: "block" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "-30px"
                  }}
                >
                  <h1 style={{ marginRight: "30px" }}>Schedule</h1>
                  <div className="forbtnwrap">
                    <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                      <button
                        className="newapplications"
                        onClick={_ => this.setState({ view: "Week" })}
                        style={{
                          color:
                            this.state.view === "Week" ? "#FFF" : "#7ea1ff",
                          backgroundColor:
                            this.state.view === "Week" ? "#7ea1ff" : "#FFF"
                        }}
                      >
                        Week
                      </button>
                    </div>
                    <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                      <button
                        className="newapplications"
                        onClick={_ => this.setState({ view: "Day" })}
                        style={{
                          color: this.state.view === "Day" ? "#FFF" : "#7ea1ff",
                          backgroundColor:
                            this.state.view === "Day" ? "#7ea1ff" : "#FFF"
                        }}
                      >
                        Day
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  <button
                    className="newapplications add-schedule-btn"
                    style={{
                      cursor: "pointer",
                      marginTop: "26px"
                    }}
                    onClick={_ =>
                      this.setState({
                        openCreateStaffScheduleModal: true
                      })
                    }
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                      className="mobile-calendar-img"
                    />
                    Add Staff Schedule
                  </button>
                </div>
              </div>
            </div>
            <div className="cont" style={{ overflowX: "auto" }}>
              <div className="tablecont" style={{ marginBottom: "75px" }}>
                <div>
                  <TeamCalendar
                    viewMode={this.state.view}
                    onRefresh={this.state.refresh}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StaffSchedules;
