/**
 * This modal sets the date and time for single session/lessons/classes invites.
 * Specifically, classes should have a day selector only, while lessons and sessions should have a date and time selector.
 */

import React, { useEffect, useState } from "reactn";
import BaseModal from "./baseModal/BaseModal";
import { BLUE_BUTTON, RED_BUTTON, WHITE } from "./baseModal/colours";
import { DATE_PICKER } from "./baseModal/FieldTypes";
import { setHours, setMinutes } from "date-fns";
import { toast } from "react-toastify";

const DateTimePickerModal = ({ onClose, selectedItem, quantity, dates, setDates, plan }) => {
  const isClass = selectedItem.program_details === "Classes";

  const [currentDates, setCurrentDates] = useState([]);
  const [excludedDates, setExcludedDates] = useState([]);
  const [customTimes, setCustomTimes] = useState([]);
  const [timing, setTiming] = useState([]);


  const minuteDisplay = value => {
    const time = value.toString();
    if (time.split(".")[1] == 5) {
      return 30;
    }
    if (time.split(".")[1] == 25) {
      return 15;
    }
    if (time.split(".")[1] == 75) {
      return 45;
    }
    return 0;
  };
  const hourDisplay = value => {
    const time = value.toString().split(".");
    return parseInt(time[0]);
  };
  const getDayOfWeek = date => {
    switch(new Date(date).getDay()) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
    }
  };
  const getExcludedDays = days_of_week => {
    let excludedDays = [];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];

    days.forEach((e, i) => {
      if (e === days_of_week.filter(f => f === e)[0]) {
        return e;
      } else {
        return excludedDays.push({ name: e, value: e, dayCount: i });
      }
    });

    return excludedDays;
  };

  useEffect(() => {
    const getTiming = (range) => {
      let timeToSubtract = 1;
      if (plan.frequency === "minutes") {
        timeToSubtract = parseInt(plan.duration) / 60;
      }
      let timeValues = []
      range.forEach(e => {
        let timeStart = e[0];
        let timeEnd = e[1];
        for (let i = timeStart; i <= timeEnd - timeToSubtract; i = i + 0.25) {
          timeValues.push(i);
        }
      });
      return timeValues.map(f => setHours(setMinutes(new Date(), minuteDisplay(f)), hourDisplay(f)));
    }

    console.log("DATES", dates);
    let currentDates = [...dates];
    if (currentDates.length !== quantity) {
      for (let i = currentDates.length; i < quantity; i++) {
        currentDates.push(null);
      }
    }
    console.log("currentDates", currentDates);
    setCurrentDates(currentDates);

    const excludedDays = getExcludedDays(Object.keys(selectedItem.days_of_week));
    console.log("excluded", excludedDays);
    setExcludedDates(excludedDays);

    let range = [];
    if (selectedItem.enableCustomTimes) {
      let customTimes = {};
      for (const day in selectedItem.customTimes) {
        selectedItem.customTimes[day].forEach((e, i) => {
            if (i % 2 === 0) {
              range.push([
                parseFloat(e),
                parseFloat(selectedItem.customTimes[day][i + 1])]);
            }
          }
        );

        const timePickerIncludedTimes = getTiming(range);
        customTimes = Object.assign({ [day]: timePickerIncludedTimes }, customTimes);
      }
      setCustomTimes(customTimes);
    } else {
      selectedItem.timing.forEach((e, i) => {
        if (i % 2 === 0) {
          range.push([
            parseFloat(e),
            parseFloat(selectedItem.timing[i + 1])]);
        }
      });
      const timePickerIncludedTimes = getTiming(range);
      console.log("timePickerIncludedTimes", timePickerIncludedTimes);
      setTiming(timePickerIncludedTimes);
    }
  }, []);

  const getBaseModalProps = () => {
    return {
      title: "Choose Dates and Times",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      handleClose: onClose,
    }
  };

  const getBaseModalButtons = () => {
    return [
      {
        name: "Cancel",
        handleClick: onClose,
        buttonColour: RED_BUTTON,
        textColour: WHITE,
      },
      {
        name: "Save",
        handleClick: () => {
          if (currentDates.filter(e => !e).length > 0) {
            toast.error("Please select a date for each session");
            return;
          }
          setDates(currentDates);
          onClose();
        },
        buttonColour: BLUE_BUTTON,
        textColour: WHITE,
      }
    ]
  }

  const getBaseModalFields = () => {
    const datePickerField = (index) => {
      return {
        type: DATE_PICKER,
        data: {
          name: `Date ${index + 1}`,
          value: currentDates[index] || "",
          handleChange: (date) => {
            setCurrentDates(prev => {
              const copy = [...prev];
              copy[index] = date;
              return copy;
            });
          },
          filterDate: (date) => {
            return !excludedDates.filter(f => f.dayCount === date.getDay())[0];
          },
          dateFormat: "MMMM d, yyyy",
          minDate: new Date(),
        },
      };
    };

    const DateTimePickerField = (index) => {
      return {
        type: DATE_PICKER,
        data: {
          name: `Date and Time ${index + 1}`,
          value: currentDates[index] || "",
          handleChange: (date) => {
            setCurrentDates(prev => {
              const copy = [...prev];
              copy[index] = date;
              return copy;
            });
          },
          filterDate: (date) => {
            return !excludedDates.filter(f => f.dayCount === date.getDay())[0];
          },
          showTimeSelect: true,
          timeIntervals: 15,
          includeTimes: selectedItem.enableCustomTimes ? (currentDates[index] ? customTimes[getDayOfWeek(currentDates[index])] : []) : timing,
          dateFormat: "MMMM d, yyyy h:mm aa",
          minDate: new Date(),
        },
      };
    };

    return isClass ? currentDates.map((e, i) => {
      return datePickerField(i);
    }) : currentDates.map((e, i) => {
      return DateTimePickerField(i);
    });
  }



  return (
    <BaseModal {...getBaseModalProps()} zIndex={10000} />
  );
};

export default DateTimePickerModal;