import React from "reactn";
import { DdIcon } from "../../../assets";
import { ArrowRight, ArrowLeft } from "@material-ui/icons";
import { Modal } from "../../../components/UI";
import Axios from "axios";
import { IconButton } from "@material-ui/core";
import { DateTime } from "luxon";
import { format } from "date-fns";
import { withRouter } from "react-router-dom";

import TotalEarningsModal from "../../../components/modals/TotalEarningsModal";
import ViewDonationModal from "../../../components/modals/ViewDonationModal";

const getStatusColor = status => {
  if (status === "Draft") {
    return "rgb(33 91 255)";
  } else if (status === "Ready to Bill") {
    return "#eba93e";
  } else if (status === "Cancelled") {
    return "#ff3b8e";
  } else if (status === "Issued") {
    return "rgba(92 200 220)";
  } else if (status === "Late" || status === "Failed") {
    return "#ff3b8e";
  } else if (status === "Processing") {
    return "rgb(33 91 255)";
  } else {
    return "#1bc88a";
  }
};

const TRANSACTION_PERCENTAGE = 0.015;
class RecentDeposits extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalData: null,
      page: 0,
      selectedItem: null,
      openDonationModal: false
    };
  }
  closeModal() {
    this.setState({
      modalData: null,
      modalOpen: false
    });
  }
  async setData(payout) {
    console.log("inside setData");
    this.setGlobal({
      loading: true
    });
    const ep = this.props.id
      ? `${process.env.REACT_APP_API}/edu/payout/breakdown/${payout.id}/${this.props.id}`
      : `${process.env.REACT_APP_API}/edu/payout/breakdown/${payout.id}`;
    const results = await Axios.get(ep);
    if (results.data.success) {
      this.setGlobal({
        loading: false
      });
      this.props.setDeposit({ ...payout, transactions: results.data.data });
    }
  }
  render() {
    console.log("this.state recent-deposits.jsx", this.state);
    console.log("this.props recent-deposits.jsx", this.props);

    return (
      <div className="col-xl-6 col-xl-6-new">
        {this.state.modalData && (
          <Modal
            open={this.state.modalOpen}
            heading={"Payout Details"}
            onClose={_ => this.closeModal()}
          ></Modal>
        )}
        {this.state.openTotalModal && (
          <TotalEarningsModal
            onClose={_ => this.setState({ openTotalModal: false })}
            selectedItem={this.state.selectedItem}
          />
        )}
        {this.state.openDonationModal && (
          <ViewDonationModal
            onClose={_ => this.setState({ openDonationModal: false })}
            selectedItem={this.state.selectedItem}
          />
        )}
        <div
          className="tablebox"
          style={{ justifyContent: "flex-start", height: "375px" }}
        >
          <div className="tableheader">
            <h2 style={{ flexGrow: 1 }}>
              ALL RECENT PAYMENTS <img src={DdIcon} alt="" />
            </h2>
            <IconButton
              disabled={this.state.page === 0}
              onClick={e =>
                this.setState({
                  page: this.state.page - 1
                })
              }
            >
              <ArrowLeft />
            </IconButton>
            <IconButton
              disabled={
                this.props.earnings.slice(
                  6 * this.state.page,
                  6 * (this.state.page + 1)
                ).length < 6
              }
              onClick={e =>
                this.setState({
                  page: this.state.page + 1
                })
              }
            >
              <ArrowRight />
            </IconButton>
          </div>
          <div className="tablebody" style={{ padding: "5px 18px" }}>
            <div className="table-responsive">
              <table className="w-100">
                <thead>
                  <tr
                    style={{ color: "#333", borderBottom: "1px solid #ececec" }}
                  >
                    <th className="p-2">Date</th>
                    <th className="p-2">Amount</th>
                    <th className="p-2">Purchaser</th>
                    <th className="p-2">Status</th>
                    <th className="p-2"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.earnings.length ? null : (
                    <tr>
                      <td colSpan="5" className="p-5 text-center">
                        Nothing to show...{" "}
                        <span style={{ fontStyle: "italic" }}>Yet!</span>
                      </td>
                    </tr>
                  )}
                  {this.props.earnings
                    .slice(6 * this.state.page, 6 * (this.state.page + 1))
                    .map((e, i) => {
                      // if (e.type === "Donation") {
                      //   return (
                      //     <tr key={i}>
                      //       <td className="p-2">
                      //         {format(new Date(e.date), "LLLL d, yyyy")}
                      //       </td>
                      //       <td className="p-2">
                      //         $
                      //         {parseFloat(parseFloat(e.total) / 100).toFixed(
                      //           2
                      //         )}
                      //       </td>
                      //       <td className="p-2">
                      //         {`${format(
                      //           new Date(e.date),
                      //           "LLLL d, yyyy"
                      //         )}`}
                      //       </td>
                      //       <td className="p-2">
                      //         <span
                      //           style={{
                      //             backgroundColor: "#1bc88a",
                      //             borderRadius: "13px",
                      //             padding: "3px 10px 3px 10px",

                      //             color: "#FFFFFF",
                      //             display: "inline-block",
                      //             textAlign: "center"
                      //           }}
                      //         >
                      //           Donation
                      //         </span>
                      //       </td>
                      //       <td
                      //         className="p-2"
                      //         style={{ color: "mediumblue", cursor: "pointer" }}
                      //         onClick={_ => {
                      //           this.setState({
                      //             // openDonationModal: true,
                      //             selectedItem: e
                      //           });
                      //           console.log("clicked is", e.orderId, e.id);
                      //           this.props.history.push(
                      //             // `orders/orders-receipt/${e.orderId}`
                      //             `orders/orders-receipt/${e.orderId || e.id}`
                      //           );
                      //         }}
                      //       >
                      //         View Details
                      //       </td>
                      //     </tr>
                      //   );
                      // }

                      // Hide the transaction cost of 1.5%
                      // let amount =
                      //   e.product.transaction_cost &&
                      //   !e.isDeposit &&
                      //   e.type !== "Single Sessions"
                      //     ? (parseFloat(e.amount || e.total) *
                      //         (1 / (1 + TRANSACTION_PERCENTAGE))) /
                      //       100
                      //     : parseFloat(e.amount / 100 || e.total / 100);
                      return (
                        <tr key={i}>
                          <td className="p-2">
                            {format(new Date(e.date), "MMM dd, yyyy")}
                          </td>
                          <td className="p-2">
                            ${(parseInt(e.total) / 100).toFixed(2)}
                          </td>
                          <td className="p-2">{e.purchaser}</td>
                          <td className="p-2">
                            {
                              <span
                                style={{
                                  backgroundColor: getStatusColor(e.status),
                                  borderRadius: "13px",
                                  padding: "3px 10px 3px 10px",

                                  color: "#FFFFFF",
                                  display: "inline-block",
                                  textAlign: "center"
                                }}
                              >
                                {e.status}
                              </span>
                            }
                          </td>
                          <td
                            className="p-2"
                            style={{ color: "mediumblue", cursor: "pointer" }}
                            onClick={_ => {
                              this.setState({
                                // openTotalModal: true,
                                selectedItem: e
                              });
                              console.log("e is", e);
                              this.props.history.push(
                                // `orders/orders-receipt/${e.orderId}`
                                `orders/orders-receipt/${e.id}`
                              );
                            }}
                          >
                            View
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RecentDeposits);

// import React from "reactn";
// import { DdIcon } from "../../../assets";
// import { ArrowRight, ArrowLeft } from "@material-ui/icons";
// import { Modal } from "../../../components/UI";
// import Axios from "axios";
// import { IconButton } from "@material-ui/core";
// import { DateTime } from "luxon";
// import { format } from "date-fns";
// import { withRouter } from "react-router-dom";

// import TotalEarningsModal from "../../../components/modals/TotalEarningsModal";
// import ViewDonationModal from "../../../components/modals/ViewDonationModal";

// const TRANSACTION_PERCENTAGE = 0.015;
// class RecentDeposits extends React.PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       modalOpen: false,
//       modalData: null,
//       page: 0,
//       selectedItem: null,
//       openDonationModal: false
//     };
//   }
//   closeModal() {
//     this.setState({
//       modalData: null,
//       modalOpen: false
//     });
//   }
//   async setData(payout) {
//     console.log("inside setData");
//     this.setGlobal({
//       loading: true
//     });
//     const ep = this.props.id
//       ? `${process.env.REACT_APP_API}/edu/payout/breakdown/${payout.id}/${this.props.id}`
//       : `${process.env.REACT_APP_API}/edu/payout/breakdown/${payout.id}`;
//     const results = await Axios.get(ep);
//     if (results.data.success) {
//       this.setGlobal({
//         loading: false
//       });
//       this.props.setDeposit({ ...payout, transactions: results.data.data });
//     }
//   }
//   render() {
//     console.log("this.state recent-deposits.jsx", this.state);
//     console.log("this.props recent-deposits.jsx", this.props);

//     return (
//       <div className="col-xl-6 col-xl-6-new">
//         {this.state.modalData && (
//           <Modal
//             open={this.state.modalOpen}
//             heading={"Payout Details"}
//             onClose={_ => this.closeModal()}
//           ></Modal>
//         )}
//         {this.state.openTotalModal && (
//           <TotalEarningsModal
//             onClose={_ => this.setState({ openTotalModal: false })}
//             selectedItem={this.state.selectedItem}
//           />
//         )}
//         {this.state.openDonationModal && (
//           <ViewDonationModal
//             onClose={_ => this.setState({ openDonationModal: false })}
//             selectedItem={this.state.selectedItem}
//           />
//         )}
//         <div
//           className="tablebox"
//           style={{ justifyContent: "flex-start", height: "375px" }}
//         >
//           <div className="tableheader">
//             <h2 style={{ flexGrow: 1 }}>
//               ALL RECENT PAYMENTS <img src={DdIcon} alt="" />
//             </h2>
//             <IconButton
//               disabled={this.state.page === 0}
//               onClick={e =>
//                 this.setState({
//                   page: this.state.page - 1
//                 })
//               }
//             >
//               <ArrowLeft />
//             </IconButton>
//             <IconButton
//               disabled={
//                 this.props.earnings.slice(
//                   6 * this.state.page,
//                   6 * (this.state.page + 1)
//                 ).length < 6
//               }
//               onClick={e =>
//                 this.setState({
//                   page: this.state.page + 1
//                 })
//               }
//             >
//               <ArrowRight />
//             </IconButton>
//           </div>
//           <div className="tablebody" style={{ padding: "5px 18px" }}>
//             <div className="table-responsive">
//               <table className="w-100">
//                 <thead>
//                   <tr
//                     style={{ color: "#333", borderBottom: "1px solid #ececec" }}
//                   >
//                     <th className="p-2">Payment Date</th>
//                     <th className="p-2">Amount</th>
//                     <th className="p-2">Expected Arrival</th>
//                     <th className="p-2">Status</th>
//                     <th className="p-2">Details</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {this.props.earnings.length ? null : (
//                     <tr>
//                       <td colSpan="5" className="p-5 text-center">
//                         Nothing to show...{" "}
//                         <span style={{ fontStyle: "italic" }}>Yet!</span>
//                       </td>
//                     </tr>
//                   )}
//                   {this.props.earnings
//                     .slice(6 * this.state.page, 6 * (this.state.page + 1))
//                     .map((e, i) => {
//                       if (e.type === "Donation") {
//                         return (
//                           <tr key={i}>
//                             <td className="p-2">
//                               {format(new Date(e.createdAt), "LLLL d, yyyy")}
//                             </td>
//                             <td className="p-2">
//                               $
//                               {parseFloat(parseFloat(e.amount) / 100).toFixed(
//                                 2
//                               )}
//                             </td>
//                             <td className="p-2">
//                               {`${format(
//                                 new Date(e.createdAt),
//                                 "LLLL d, yyyy"
//                               )}`}
//                             </td>
//                             <td className="p-2">
//                               <span
//                                 style={{
//                                   backgroundColor: "#1bc88a",
//                                   borderRadius: "13px",
//                                   padding: "3px 10px 3px 10px",

//                                   color: "#FFFFFF",
//                                   display: "inline-block",
//                                   textAlign: "center"
//                                 }}
//                               >
//                                 Donation
//                               </span>
//                             </td>
//                             <td
//                               className="p-2"
//                               style={{ color: "mediumblue", cursor: "pointer" }}
//                               onClick={_ => {
//                                 this.setState({
//                                   // openDonationModal: true,
//                                   selectedItem: e
//                                 });
//                                 console.log("clicked is", e.orderId, e.id);
//                                 this.props.history.push(
//                                   // `orders/orders-receipt/${e.orderId}`
//                                   `orders/orders-receipt/${e.orderId || e.id}`
//                                 );
//                               }}
//                             >
//                               View Details
//                             </td>
//                           </tr>
//                         );
//                       }

//                       const local = DateTime.fromMillis(e.created * 1000);
//                       const rezoned = local.setZone(
//                         this.global.organizationInfo.timezone
//                       );
//                       const localArrival = DateTime.fromMillis(
//                         e.created * 1000 + 24 * 7 * 3600000
//                       );
//                       const rezonedArrival = localArrival.setZone(
//                         this.global.organizationInfo.timezone
//                       );

//                       // Hide the transaction cost of 1.5%
//                       // let amount =
//                       //   e.product.transaction_cost &&
//                       //   !e.isDeposit &&
//                       //   e.type !== "Single Sessions"
//                       //     ? (parseFloat(e.amount || e.total) *
//                       //         (1 / (1 + TRANSACTION_PERCENTAGE))) /
//                       //       100
//                       //     : parseFloat(e.amount / 100 || e.total / 100);
//                       let amount =
//                         parseFloat(
//                           e?.stripe?.amount_received || e.amount || e.total
//                         ) / 100;

//                       if (isNaN(amount)) {
//                         amount = 0;
//                       }

//                       if (
//                         e.charge?.amount_refunded &&
//                         e.charge?.amount_refunded !== 0
//                       ) {
//                         amount =
//                           amount - parseFloat(e.charge.amount_refunded / 100);
//                       }

//                       return (
//                         <tr key={i}>
//                           <td className="p-2">
//                             {(() => {
//                               if (e.created) {
//                                 return rezoned.toFormat("DD");
//                               }
//                               if (e.createdAt) {
//                                 return format(
//                                   new Date(e.createdAt),
//                                   "MMM dd, yyyy"
//                                 );
//                               }
//                               return "-";
//                             })()}
//                             {/* {e.created
//                               ? // ? format(new Date(e.created * 1000), "LLLL d, yyyy")
//                                 rezoned.toFormat("DD")
//                               : " - "} */}
//                           </td>
//                           <td className="p-2">${amount.toFixed(2)}</td>
//                           <td className="p-2">
//                             {e.created &&
//                               // `${format(
//                               //   new Date(e.created * 1000 + 24 * 7 * 3600000),
//                               //   "LLLL d, yyyy"
//                               // )}`
//                               rezonedArrival.toFormat("DD")}
//                           </td>
//                           <td className="p-2">
//                             {/* {e.paid ? ( */}
//                             {e.paid || e.status === "Paid" ? (
//                               <span
//                                 style={{
//                                   backgroundColor: "#1bc88a",
//                                   borderRadius: "13px",
//                                   padding: "3px 10px 3px 10px",

//                                   color: "#FFFFFF",
//                                   display: "inline-block",
//                                   textAlign: "center"
//                                 }}
//                               >
//                                 {e.invoice_status === "Paid Offline"
//                                   ? "Paid (Offline)"
//                                   : "Paid"}
//                               </span>
//                             ) : (
//                               <span
//                                 style={{
//                                   backgroundColor: "#ff3b8e",
//                                   borderRadius: "13px",
//                                   padding: "3px 10px 3px 10px",

//                                   color: "#FFFFFF",
//                                   display: "inline-block",
//                                   textAlign: "center"
//                                 }}
//                               >
//                                 {(() => {
//                                   if (e.status) {
//                                     if (e?.invoice_status === "Paid Offline") {
//                                       return `${e.status} (Offline)`;
//                                     } else {
//                                       return e.status;
//                                     }
//                                   } else {
//                                     return "Pending Collection";
//                                   }
//                                 })()}
//                               </span>
//                             )}
//                           </td>
//                           <td
//                             className="p-2"
//                             style={{ color: "mediumblue", cursor: "pointer" }}
//                             onClick={_ => {
//                               this.setState({
//                                 // openTotalModal: true,
//                                 selectedItem: e
//                               });
//                               console.log("e is", e);
//                               this.props.history.push(
//                                 // `orders/orders-receipt/${e.orderId}`
//                                 `orders/orders-receipt/${e.orderId || e.id}`
//                               );
//                             }}
//                           >
//                             View Details
//                           </td>
//                         </tr>
//                       );
//                     })}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default withRouter(RecentDeposits);
