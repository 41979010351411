import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import axios from "axios";
import { toast } from "react-toastify";
import ApplicationViewModal from "../../components/modals/ApplicationViewModal";
import moment from "moment";
import setSeconds from "date-fns/esm/setSeconds/index";
import ReactTooltip from "react-tooltip";
import ApplicationDeleteModal from "../../components/modals/ApplicationDeleteModal";

class Applications extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      programData: {},
      eventData: {},
      membershipData: {},
      onlineData: {},
      showApplicationViewModal: false,
      showDeleteModal: false,
      selectedProgram: null,
      type: "",
      displayCategory: "All",
      dataCount: [],
      currTab: 0
    };
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Applications", to: "/applications" }
      ]
    });

    this.tabs = ["New", "Accepted", "Waitlisted", "Rejected", "Archived"];

    this.actions = [{ name: "Notes", action: null }];
  }

  headings = () => {
    return {
      New: [
        { id: "displayName", label: "Name", customCell: this.displayName },
        { id: "title", label: "Title" },
        {
          id: "endDate",
          label: "Duration",
          customCell: (r, i) => {
            return (
              <td key={`${i}-duration`}>
                {r.startDate &&
                  moment(
                    new Date(r.startDate.replace(/-/g, "/").replace(/T.+/, ""))
                  ).format("MMMM Do, YYYY")}
                {r.endDate &&
                  ` - ${moment(
                    new Date(r.endDate.replace(/-/g, "/").replace(/T.+/, ""))
                  ).format("MMMM Do, YYYY")}`}
              </td>
            );
          }
        },
        {
          id: "date",
          label: "Request Date",
          customCell: (r, i) => {
            return (
              <td key={`${i}-date`}>
                {moment(
                  new Date(r.createdAt.replace(/-/g, "/").replace(/T.+/, ""))
                ).format("MMMM Do, YYYY")}
              </td>
            );
          }
        },
        {
          id: "updated",
          label: "",
          customCell: (r, i) => {
            return (
              <td key={`${i}-updated`}>
                <ReactTooltip />
                <i
                  data-tip={`Last Updated: ${moment(r.updatedAt).format(
                    "LLL"
                  )}`}
                  className="bi bi-clock-history"
                  style={{ fontSize: "15px" }}
                ></i>
              </td>
            );
          }
        },
        {
          id: "delete",
          label: "",
          customCell: (r, i) => {
            return (
              <td key={`${i}-delete`}>
                <ReactTooltip />
                <i
                  className="bi bi-trash3"
                  data-tip="Delete Application"
                  style={{ fontSize: "15px" }}
                  onClick={() =>
                    this.setState({ showDeleteModal: true, selectedProgram: r })
                  }
                />
              </td>
            );
          }
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button
                  style={{ marginRight: "30px" }}
                  onClick={_ =>
                    this.setState({
                      selectedProgram: r,
                      showApplicationViewModal: true,
                      type: "New"
                    })
                  }
                >
                  View
                </button>
                <button style={{ marginRight: "30px" }}>Notes</button>
              </td>
            );
          }
        }
      ],
      Accepted: [
        { id: "displayName", label: "Name", customCell: this.displayName },
        { id: "title", label: "Title" },
        {
          id: "type",
          label: "Type",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-type`}
                style={
                  r.application_required
                    ? { color: "green", fontWeight: "bold" }
                    : {}
                }
              >
                {r.application_required
                  ? "Application"
                  : "Registered, but unpaid"}
              </td>
            );
          }
        },
        {
          id: "endDate",
          label: "Duration",
          customCell: (r, i) => {
            return (
              <td key={`${i}-duration`}>
                {r.startDate &&
                  moment(
                    new Date(r.startDate.replace(/-/g, "/").replace(/T.+/, ""))
                  ).format("MMMM Do, YYYY")}
                {r.endDate &&
                  ` - ${moment(
                    new Date(r.endDate.replace(/-/g, "/").replace(/T.+/, ""))
                  ).format("MMMM Do, YYYY")}`}
              </td>
            );
          }
        },
        {
          id: "date",
          label: "Request Date",
          customCell: (r, i) => {
            return (
              <td key={`${i}-date`}>
                {moment(
                  new Date(r.createdAt.replace(/-/g, "/").replace(/T.+/, ""))
                ).format("MMMM Do, YYYY")}
              </td>
            );
          }
        },
        {
          id: "updated",
          label: "",
          customCell: (r, i) => {
            return (
              <td key={`${i}-updated`}>
                <ReactTooltip />
                <i
                  data-tip={`Last Updated: ${moment(r.updatedAt).format(
                    "LLL"
                  )}`}
                  className="bi bi-clock-history"
                  style={{ fontSize: "15px" }}
                ></i>
              </td>
            );
          }
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button
                  style={{ marginRight: "30px" }}
                  onClick={_ =>
                    this.setState({
                      selectedProgram: r,
                      showApplicationViewModal: true,
                      type: "Accepted"
                    })
                  }
                >
                  View
                </button>
                <button style={{ marginRight: "30px" }}>Notes</button>
              </td>
            );
          }
        }
      ],
      Waitlisted: [
        { id: "displayName", label: "Name", customCell: this.displayName },
        { id: "title", label: "Title" },
        {
          id: "endDate",
          label: "Duration",
          customCell: (r, i) => {
            return (
              <td key={`${i}-duration`}>
                {r.startDate &&
                  moment(
                    new Date(r.startDate.replace(/-/g, "/").replace(/T.+/, ""))
                  ).format("MMMM Do, YYYY")}
                {r.endDate &&
                  ` - ${moment(
                    new Date(r.endDate.replace(/-/g, "/").replace(/T.+/, ""))
                  ).format("MMMM Do, YYYY")}`}
              </td>
            );
          }
        },
        {
          id: "date",
          label: "Request Date",
          customCell: (r, i) => {
            return (
              <td key={`${i}-date`}>
                {moment(
                  new Date(r.createdAt.replace(/-/g, "/").replace(/T.+/, ""))
                ).format("MMMM Do, YYYY")}
              </td>
            );
          }
        },
        {
          id: "updated",
          label: "",
          customCell: (r, i) => {
            return (
              <td key={`${i}-updated`}>
                <ReactTooltip />
                <i
                  data-tip={`Last Updated: ${moment(r.updatedAt).format(
                    "LLL"
                  )}`}
                  className="bi bi-clock-history"
                  style={{ fontSize: "15px" }}
                ></i>
              </td>
            );
          }
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button
                  style={{ marginRight: "30px" }}
                  onClick={_ =>
                    this.setState({
                      selectedProgram: r,
                      showApplicationViewModal: true,
                      type: "Waitlisted"
                    })
                  }
                >
                  View
                </button>
                <button style={{ marginRight: "30px" }}>Notes</button>
              </td>
            );
          }
        }
      ],
      Rejected: [
        { id: "displayName", label: "Name", customCell: this.displayName },
        { id: "programName", label: "Title" },
        {
          id: "endDate",
          label: "Duration",
          customCell: (r, i) => {
            return (
              <td key={`${i}-duration`}>
                {r.startDate &&
                  moment(
                    new Date(r.startDate.replace(/-/g, "/").replace(/T.+/, ""))
                  ).format("MMMM Do, YYYY")}
                {r.endDate &&
                  ` - ${moment(
                    new Date(r.endDate.replace(/-/g, "/").replace(/T.+/, ""))
                  ).format("MMMM Do, YYYY")}`}
              </td>
            );
          }
        },
        {
          id: "date",
          label: "Request Date",
          customCell: (r, i) => {
            return (
              <td key={`${i}-date`}>
                {moment(
                  new Date(r.createdAt.replace(/-/g, "/").replace(/T.+/, ""))
                ).format("MMMM Do, YYYY")}
              </td>
            );
          }
        },
        {
          id: "updated",
          label: "",
          customCell: (r, i) => {
            return (
              <td key={`${i}-updated`}>
                <ReactTooltip />
                <i
                  data-tip={`Last Updated: ${moment(r.updatedAt).format(
                    "LLL"
                  )}`}
                  className="bi bi-clock-history"
                  style={{ fontSize: "15px" }}
                ></i>
              </td>
            );
          }
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button
                  style={{ marginRight: "30px" }}
                  onClick={_ =>
                    this.setState({
                      selectedProgram: r,
                      showApplicationViewModal: true,
                      type: "Rejected"
                    })
                  }
                >
                  View
                </button>
                <button style={{ marginRight: "30px" }}>Notes</button>
              </td>
            );
          }
        }
      ],
      Archived: [
        { id: "displayName", label: "Name", customCell: this.displayName },
        { id: "programName", label: "Title" },
        {
          id: "endDate",
          label: "Duration",
          customCell: (r, i) => {
            return (
              <td key={`${i}-duration`}>
                {r.startDate &&
                  moment(
                    new Date(r.startDate.replace(/-/g, "/").replace(/T.+/, ""))
                  ).format("MMMM Do, YYYY")}
                {r.endDate &&
                  ` - ${moment(
                    new Date(r.endDate.replace(/-/g, "/").replace(/T.+/, ""))
                  ).format("MMMM Do, YYYY")}`}
              </td>
            );
          }
        },
        {
          id: "date",
          label: "Request Date",
          customCell: (r, i) => {
            return (
              <td key={`${i}-date`}>
                {moment(
                  new Date(r.createdAt.replace(/-/g, "/").replace(/T.+/, ""))
                ).format("MMMM Do, YYYY")}
              </td>
            );
          }
        },
        {
          id: "updated",
          label: "",
          customCell: (r, i) => {
            return (
              <td key={`${i}-updated`}>
                <ReactTooltip />
                <i
                  data-tip={`Last Updated: ${moment(r.updatedAt).format(
                    "LLL"
                  )}`}
                  className="bi bi-clock-history"
                  style={{ fontSize: "15px" }}
                ></i>
              </td>
            );
          }
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button
                  style={{ marginRight: "30px" }}
                  onClick={_ =>
                    this.setState({
                      selectedProgram: r,
                      showApplicationViewModal: true,
                      type: "Archived"
                    })
                  }
                >
                  View
                </button>
                <button style={{ marginRight: "30px" }}>Notes</button>
              </td>
            );
          }
        }
      ]
    };
  };

  /**
   * This function handles the edit button functionality of a sub user
   * returns a boolean value
   */
  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return !this.global.dsUser.accesspoints.Leads.Applications.edit;
    }
  };

  displayName = (r, i) => {
    return (
      <td key={`${i}-name`}>
        {r.displayName} {r.childName ? `(${r.childName})` : ""}
      </td>
    );
  };

  async componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    this.setGlobal({ loading: true });
    // const ep = `${process.env.REACT_APP_API}/adm/partner/leads`;
    // const results = await axios.get(ep);
    const partnerId = this.global.organizationInfo.partnerId;

    let status = "new";
    if (this.state.currTab === 0) {
      status = "new";
    } else if (this.state.currTab === 1) {
      status = "accepted";
    } else if (this.state.currTab === 2) {
      status = "waitlisted";
    } else if (this.state.currTab === 3) {
      status = "rejected";
    } else if (this.state.currTab === 4) {
      status = "archived";
    } else {
      console.log("NO TAB FOUND");
    }

    const program_customers_ep = `${process.env.REACT_APP_API}/partners/programs/customers/${status}/${partnerId}`;
    console.time("initial_program_customers_fetch"); // start the timer
    const program_customers = await axios.get(program_customers_ep);
    console.timeEnd("initial_program_customers_fetch"); // stop the timer and log the elapsed time

    const program_customers_count_ep = `${process.env.REACT_APP_API}/partners/programs/customers/count/${partnerId}`;
    console.time("program_customers_count_fetch"); // start the timer
    const program_customers_count = await axios.get(program_customers_count_ep);
    console.timeEnd("program_customers_count_fetch"); // stop the timer and log the elapsed time

    const programCount = {
      New: program_customers_count.data.data.filter(
        e => e.status_stage === "New_Pending"
      ),
      Accepted: program_customers_count.data.data.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: program_customers_count.data.data.filter(
        e => e.status_stage === "Rejected"
      ),
      Waitlisted: program_customers_count.data.data.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: program_customers_count.data.data.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    program_customers.data.data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const programData = {
      New: program_customers.data.data.filter(
        e => e.status_stage === "New_Pending"
      ),
      Accepted: program_customers.data.data.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: program_customers.data.data.filter(
        e => e.status_stage === "Rejected"
      ),
      Waitlisted: program_customers.data.data.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: program_customers.data.data.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    const event_customers_ep = `${process.env.REACT_APP_API}/partners/events/customers/${status}/${partnerId}`;
    console.time("initial_event_customers_fetch"); // start the timer
    const event_customers = await axios.get(event_customers_ep);
    console.timeEnd("initial_event_customers_fetch"); // stop the timer and log the elapsed time

    const event_customers_count_ep = `${process.env.REACT_APP_API}/partners/events/customers/count/${partnerId}`;
    console.time("event_customers_count_fetch"); // start the timer
    const event_customers_count = await axios.get(event_customers_count_ep);
    console.timeEnd("event_customers_count_fetch"); // stop the timer and log the elapsed time

    const eventCount = {
      New: event_customers_count.data.data.filter(
        e => e.status_stage === "New_Pending"
      ),
      Accepted: event_customers_count.data.data.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: event_customers_count.data.data.filter(
        e => e.status_stage === "Rejected"
      ),
      Waitlisted: event_customers_count.data.data.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: event_customers_count.data.data.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    event_customers.data.data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const eventData = {
      New: event_customers.data.data.filter(
        e => e.status_stage === "New_Pending"
      ),
      Accepted: event_customers.data.data.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: event_customers.data.data.filter(
        e => e.status_stage === "Rejected"
      ),
      Waitlisted: event_customers.data.data.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: event_customers.data.data.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    const memberships_customers_ep = `${process.env.REACT_APP_API}/partners/memberships/customers/${status}/${partnerId}`;
    console.time("initial_membership_customers_fetch"); // start the timer
    const memberships_customers = await axios.get(memberships_customers_ep);
    console.timeEnd("initial_membership_customers_fetch"); // stop the timer and log the elapsed time

    const memberships_customers_count_ep = `${process.env.REACT_APP_API}/partners/memberships/customers/count/${partnerId}`;
    console.time("membership_customers_count_fetch"); // start the timer
    const memberships_customers_count = await axios.get(
      memberships_customers_count_ep
    );
    console.timeEnd("membership_customers_count_fetch"); // stop the timer and log the elapsed time
    const membershipCount = {
      New: memberships_customers_count.data.data.filter(
        e => e.status_stage === "New_Pending"
      ),
      Accepted: memberships_customers_count.data.data.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: memberships_customers_count.data.data.filter(
        e => e.status_stage === "Rejected"
      ),
      Waitlisted: memberships_customers_count.data.data.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: memberships_customers_count.data.data.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    memberships_customers.data.data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const membershipData = {
      New: memberships_customers.data.data.filter(
        e => e.status_stage === "New_Pending"
      ),
      Accepted: memberships_customers.data.data.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: memberships_customers.data.data.filter(
        e => e.status_stage === "Rejected"
      ),
      Waitlisted: memberships_customers.data.data.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: memberships_customers.data.data.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    const online_customers_ep = `${process.env.REACT_APP_API}/partners/virtual/customers/${status}/${partnerId}`;
    console.time("initial_online_customers_fetch"); // start the timer
    const online_customers = await axios.get(online_customers_ep);
    console.timeEnd("initial_online_customers_fetch"); // stop the timer and log the elapsed time

    const online_customers_count_ep = `${process.env.REACT_APP_API}/partners/virtual/customers/count/${partnerId}`;
    console.time("online_customers_count_fetch"); // start the timer
    const online_customers_count = await axios.get(online_customers_count_ep);
    console.timeEnd("online_customers_count_fetch"); // stop the timer and log the elapsed time
    const onlineCount = {
      New: online_customers_count.data.data.filter(
        e => e.status_stage === "New_Pending"
      ),
      Accepted: online_customers_count.data.data.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: online_customers_count.data.data.filter(
        e => e.status_stage === "Rejected"
      ),
      Waitlisted: online_customers_count.data.data.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: online_customers_count.data.data.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    online_customers.data.data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const onlineData = {
      New: online_customers.data.data.filter(
        e => e.status_stage === "New_Pending"
      ),
      Accepted: online_customers.data.data.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: online_customers.data.data.filter(
        e => e.status_stage === "Rejected"
      ),
      Waitlisted: online_customers.data.data.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: online_customers.data.data.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    const party_customers_ep = `${process.env.REACT_APP_API}/partners/parties/customers/${status}/${partnerId}`;
    console.time("initial_party_customers_fetch"); // start the timer
    const party_customers = await axios.get(party_customers_ep);
    console.timeEnd("initial_party_customers_fetch"); // stop the timer and log the elapsed time

    const party_customers_count_ep = `${process.env.REACT_APP_API}/partners/parties/customers/count/${partnerId}`;
    console.time("party_customers_count_fetch"); // start the timer
    const party_customers_count = await axios.get(party_customers_count_ep);
    console.timeEnd("party_customers_count_fetch"); // stop the timer and log the elapsed time

    const partyCount = {
      New: party_customers_count.data.data.filter(
        e => e.status_stage === "New_Pending"
      ),
      Accepted: party_customers_count.data.data.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: party_customers_count.data.data.filter(
        e => e.status_stage === "Rejected"
      ),
      Waitlisted: party_customers_count.data.data.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: party_customers_count.data.data.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    party_customers.data.data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const partyData = {
      New: party_customers.data.data.filter(
        e => e.status_stage === "New_Pending"
      ),
      Accepted: party_customers.data.data.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: party_customers.data.data.filter(
        e => e.status_stage === "Rejected"
      ),
      Waitlisted: party_customers.data.data.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: party_customers.data.data.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    const allCustomers = [];
    allCustomers.push(...program_customers.data.data);
    allCustomers.push(...event_customers.data.data);
    allCustomers.push(...memberships_customers.data.data);
    allCustomers.push(...online_customers.data.data);
    allCustomers.push(...party_customers.data.data);

    const allData = {
      New: allCustomers.filter(e => e.status_stage === "New_Pending"),
      Accepted: allCustomers.filter(e => e.status_stage === "Accepted_Unpaid"),
      Rejected: allCustomers.filter(e => e.status_stage === "Rejected"),
      Waitlisted: allCustomers.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: allCustomers.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    const allCustomersCount = [];
    allCustomersCount.push(...program_customers_count.data.data);
    allCustomersCount.push(...event_customers_count.data.data);
    allCustomersCount.push(...memberships_customers_count.data.data);
    allCustomersCount.push(...online_customers_count.data.data);
    allCustomersCount.push(...party_customers_count.data.data);

    const allCount = {
      New: allCustomersCount.filter(e => e.status_stage === "New_Pending"),
      Accepted: allCustomersCount.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: allCustomersCount.filter(e => e.status_stage === "Rejected"),
      Waitlisted: allCustomersCount.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: allCustomersCount.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    if (program_customers.data.success) {
      //this.setState({ data: results.data.data.filter(e => !e.accepted) });
      this.setState(
        {
          allData: allData,
          programData: programData,
          eventData: eventData,
          partyData: partyData,
          membershipData: membershipData,
          onlineData: onlineData,
          allCount: allCount,
          programCount: programCount,
          eventCount: eventCount,
          partyCount: partyCount,
          membershipCount: membershipCount,
          onlineCount: onlineCount
        },
        this.setCurrentData
      );
    }

    this.setGlobal({ loading: false });
  };

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
    if (results.data.success) {
      toast.success(`Partner Accepted`);
      this.componentDidMount();
    } else {
      toast.error(`Error, this person already signed-up!`);
    }
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  getCurrentData() {
    const categoryDataTable = {
      All: this.state.allData,
      Programs: this.state.programData,
      Events: this.state.eventData,
      Parties: this.state.partyData,
      Memberships: this.state.membershipData,
      "Online Virtual": this.state.onlineData
    };

    const cleanString = s => {
      return s.trim().toLocaleLowerCase();
    };

    const filterCondition = e => {
      return cleanString(e.displayName).includes(
        cleanString(this.state.filter)
      ) || (e.childName && cleanString(e.childName).includes(cleanString(this.state.filter)));
    };

    const data = categoryDataTable[this.state.displayCategory];

    const filteredData = Object.entries(data).reduce((prev, [key, value]) => {
      prev[key] = value.filter(e => filterCondition(e));
      return prev;
    }, {});

    if (this.state.displayCategory === "All") {
      this.setState({ dataCount: this.state.allCount });
    } else if (this.state.displayCategory === "Programs") {
      this.setState({ dataCount: this.state.programCount });
    } else if (this.state.displayCategory === "Online Virtual") {
      this.setState({ dataCount: this.state.onlineCount });
    } else if (this.state.displayCategory === "Events") {
      this.setState({ dataCount: this.state.eventCount });
    } else if (this.state.displayCategory === "Parties") {
      this.setState({ dataCount: this.state.partyCount });
    } else if (this.state.displayCategory === "Memberships") {
      this.setState({ dataCount: this.state.membershipCount });
    }

    return filteredData;
  }

  setCurrentData() {
    this.setState({ data: this.getCurrentData() });
  }

  getCurrTabData = async () => {
    this.setGlobal({ loading: true });
    const partnerId = this.global.organizationInfo.partnerId;

    const params = new URLSearchParams({ currentTab: 1 });

    let status = "new";
    if (this.state.currTab === 0) {
      status = "new";
    } else if (this.state.currTab === 1) {
      status = "accepted";
    } else if (this.state.currTab === 2) {
      status = "waitlisted";
    } else if (this.state.currTab === 3) {
      status = "rejected";
    } else if (this.state.currTab === 4) {
      status = "archived";
    } else {
      console.log("NO TAB FOUND");
    }

    const program_customers_ep = `${process.env.REACT_APP_API}/partners/programs/customers/${status}/${partnerId}`;
    console.time("program_customers_fetch"); // start the timer
    const program_customers = await axios.get(program_customers_ep);
    console.timeEnd("program_customers_fetch"); // stop the timer and log the elapsed time

    program_customers.data.data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const programData = {
      New: program_customers.data.data.filter(
        e => e.status_stage === "New_Pending"
      ),
      Accepted: program_customers.data.data.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: program_customers.data.data.filter(
        e => e.status_stage === "Rejected"
      ),
      Waitlisted: program_customers.data.data.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: program_customers.data.data.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    const event_customers_ep = `${process.env.REACT_APP_API}/partners/events/customers/${status}/${partnerId}`;
    console.time("event_customers_fetch"); // start the timer
    const event_customers = await axios.get(event_customers_ep);
    console.timeEnd("event_customers_fetch"); // stop the timer and log the elapsed time

    event_customers.data.data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const eventData = {
      New: event_customers.data.data.filter(
        e => e.status_stage === "New_Pending"
      ),
      Accepted: event_customers.data.data.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: event_customers.data.data.filter(
        e => e.status_stage === "Rejected"
      ),
      Waitlisted: event_customers.data.data.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: event_customers.data.data.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    const party_customers_ep = `${process.env.REACT_APP_API}/partners/parties/customers/${status}/${partnerId}`;
    console.time("party_customers_fetch"); // start the timer
    const party_customers = await axios.get(party_customers_ep);
    console.timeEnd("party_customers_fetch"); // stop the timer and log the elapsed time

    party_customers.data.data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const partyData = {
      New: party_customers.data.data.filter(
        e => e.status_stage === "New_Pending"
      ),
      Accepted: party_customers.data.data.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: party_customers.data.data.filter(
        e => e.status_stage === "Rejected"
      ),
      Waitlisted: party_customers.data.data.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: party_customers.data.data.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    const memberships_customers_ep = `${process.env.REACT_APP_API}/partners/memberships/customers/${status}/${partnerId}`;
    console.time("membership_customers_fetch"); // start the timer
    const memberships_customers = await axios.get(memberships_customers_ep);
    console.timeEnd("membership_customers_fetch"); // stop the timer and log the elapsed time

    memberships_customers.data.data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const membershipData = {
      New: memberships_customers.data.data.filter(
        e => e.status_stage === "New_Pending"
      ),
      Accepted: memberships_customers.data.data.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: memberships_customers.data.data.filter(
        e => e.status_stage === "Rejected"
      ),
      Waitlisted: memberships_customers.data.data.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: memberships_customers.data.data.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    const online_customers_ep = `${process.env.REACT_APP_API}/partners/virtual/customers/${status}/${partnerId}`;
    console.time("online_customers_fetch"); // start the timer
    const online_customers = await axios.get(online_customers_ep);
    console.timeEnd("online_customers_fetch"); // stop the timer and log the elapsed time

    online_customers.data.data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const onlineData = {
      New: online_customers.data.data.filter(
        e => e.status_stage === "New_Pending"
      ),
      Accepted: online_customers.data.data.filter(
        e => e.status_stage === "Accepted_Unpaid"
      ),
      Rejected: online_customers.data.data.filter(
        e => e.status_stage === "Rejected"
      ),
      Waitlisted: online_customers.data.data.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: online_customers.data.data.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    const allCustomers = [];
    allCustomers.push(...program_customers.data.data);
    allCustomers.push(...event_customers.data.data);
    allCustomers.push(...party_customers.data.data);
    allCustomers.push(...memberships_customers.data.data);
    allCustomers.push(...online_customers.data.data);

    const allData = {
      New: allCustomers.filter(e => e.status_stage === "New_Pending"),
      Accepted: allCustomers.filter(e => e.status_stage === "Accepted_Unpaid"),
      Rejected: allCustomers.filter(e => e.status_stage === "Rejected"),
      Waitlisted: allCustomers.filter(
        e =>
          e.status_stage === "Waitlisted_Unpaid" ||
          e.status_stage === "Waitlisted_Paid"
      ),
      Archived: allCustomers.filter(
        e =>
          e.status_stage === "Application_Cancelled" ||
          e.status_stage === "Cancelled_Unpaid"
      )
    };

    if (program_customers.data.success) {
      this.setState(
        {
          allData: allData,
          programData: programData,
          eventData: eventData,
          partyData: partyData,
          membershipData: membershipData,
          onlineData: onlineData
        },
        this.setCurrentData
      );
    }

    this.setGlobal({ loading: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currTab !== this.state.currTab) {
      this.getCurrTabData();
    }
  }

  render() {
    return (
      <div className="admin">
        {this.state.showApplicationViewModal && (
          <ApplicationViewModal
            refresh={this.fetchInitialData}
            program={this.state.selectedProgram}
            onClose={_ => {
              this.setState({ showApplicationViewModal: false });
            }}
            isApplication={this.state.selectedProgram.application_required}
            type={this.state.type}
            displayCategory={
              this.state.displayCategory === "All"
                ? this.state.selectedProgram.classificationType === "Program"
                  ? "Programs"
                  : this.state.selectedProgram.classificationType === "Event"
                  ? "Events"
                  : this.state.selectedProgram.classificationType === "Party"
                  ? "Parties"
                  : this.state.selectedProgram.classificationType ===
                    "Membership"
                  ? "Memberships"
                  : this.state.selectedProgram.classificationType === "Online"
                  ? "Online Virtual"
                  : this.state.displayCategory
                : this.state.displayCategory
            }
            canSubUserEdit={this.canSubUserEdit()}
            openEditStart={() => console.log("openEditStart clicked")}
          />
        )}
        {this.state.showDeleteModal && (
          <ApplicationDeleteModal
            program={this.state.selectedProgram}
            onClose={_ => {
              this.fetchInitialData();
              this.setState({ showDeleteModal: false });
            }}
            type={this.state.type}
            displayCategory={
              this.state.displayCategory === "All"
                ? this.state.selectedProgram.classificationType === "Program"
                  ? "Programs"
                  : this.state.selectedProgram.classificationType === "Event"
                  ? "Events"
                  : this.state.selectedProgram.classificationType ===
                    "Membership"
                  ? "Memberships"
                  : this.state.selectedProgram.classificationType === "Online"
                  ? "Online Virtual"
                  : this.state.displayCategory
                : this.state.displayCategory
            }
            canSubUserEdit={this.canSubUserEdit()}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>Applications</h1>
            </div>
          </div>
          <div className="cont">
            <div className="tablecont">
              <div
                style={{
                  display: "inline-flex",
                  flex: "1 1",
                  border: "none",
                  borderRadius: "10px",
                  overflow: "hidden",
                  width: "100%",
                  marginBottom: 15
                }}
              >
                <i
                  className="fas fa-search"
                  style={{ padding: "8px", background: "white" }}
                />
                <input
                  type="text"
                  placeholder="Filter by parent name or child name"
                  style={{
                    border: "none",
                    fontSize: "13px",
                    padding: "5px",
                    outline: 0,
                    background: "white",
                    flex: 1
                  }}
                  onChange={e =>
                    this.setState(
                      {
                        filter: e.target.value
                      },
                      this.setCurrentData
                    )
                  }
                />
              </div>
              <div className="table-responsive">
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <select
                    name="selectForm"
                    id="selectForm"
                    type="text"
                    className="form-control"
                    style={{ width: "22%" }}
                    onChange={e => {
                      if (e.target.value === "All") {
                        this.setState(
                          {
                            displayCategory: "All"
                          },
                          this.setCurrentData
                        );
                      }
                      if (e.target.value === "Programs") {
                        this.setState(
                          {
                            displayCategory: "Programs"
                          },
                          this.setCurrentData
                        );
                      }
                      if (e.target.value === "Events") {
                        this.setState(
                          {
                            displayCategory: "Events"
                          },
                          this.setCurrentData
                        );
                      }
                      if (e.target.value === "Memberships") {
                        this.setState(
                          {
                            displayCategory: "Memberships"
                          },
                          this.setCurrentData
                        );
                      }
                      if (e.target.value === "Online Virtual") {
                        this.setState(
                          {
                            displayCategory: "Online Virtual"
                          },
                          this.setCurrentData
                        );
                      }
                    }}
                  >
                    <option value="All">All</option>
                    <option value="Programs">Programs</option>
                    <option value="Events">Events</option>
                    <option value="Memberships">Memberships</option>
                    <option value="Online Virtual">Online Virtual</option>
                  </select>
                </div>
                <NewTabbedTable
                  tabs={this.tabs}
                  headings={this.headings()}
                  data={this.state.data}
                  dataCount={this.state.dataCount}
                  actions={this.actions}
                  page={"Leads>Applications"}
                  setCurrTab={tab => {
                    this.setState({ currTab: tab });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Applications;
