import React from "reactn";
import axios from "axios";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/core";
import "../../../assets/css/componentSpecificCss/userAccess.css";
import "../../../assets/css/componentSpecificCss/userSchedule.css";
import "../../../assets/css/componentSpecificCss/userDetails.css";
import ScheduleSelector from "react-schedule-selector";
import { startOfWeek, differenceInCalendarWeeks, addWeeks } from "date-fns";

const styles = {
  iconButton: {
    backgroundColor: "transparent !important",
    padding: "1em !important",
    color: "rgba(0, 0, 0, 0.54) !important",
    "&:focus": {
      outline: "none !important"
    }
  },
  avatar: {
    width: "50px",
    height: "50px"
  },
  item: {
    padding: "5px 10px",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "13px"
  },
  list: {
    height: "60px",
    padding: "0px"
  },
  tabRoot: {
    color: "#61616F !important",
    fontFamily: "Open Sans,sans-serif !important",
    fontWeight: "600 !important",
    fontSize: "1rem !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "#61616F !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "#61616F !important"
    }
  },
  tabSelected: {
    color: "black !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "black !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "black !important"
    }
  },
  tabIndicator: {
    backgroundColor: "#61616F",
    height: "3px",
    marginTop: "20px"
  }
};

class Schedule extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      subActive: "Current",
      schedule: [],
      notes: ""
    };

    this.locations = [
      {
        address:
          this.global.organizationInfo.address1 +
          ", " +
          this.global.organizationInfo.city +
          ", " +
          this.global.organizationInfo.province +
          " " +
          this.global.organizationInfo.postalcode +
          ", " +
          this.global.organizationInfo.country
      },
      ...this.global.organizationInfo.other_locations.map(e => ({
        address: e.address
      }))
    ];
  }

  async componentDidMount() {
    if (this.props.selectedUser.schedule) {
      const mappedTimes = this.props.selectedUser.schedule.map(time => {
        const wkDiff = Math.abs(
          differenceInCalendarWeeks(new Date(), new Date(time))
        );
        return new Date(addWeeks(new Date(time), wkDiff));
      });
      this.setState({
        schedule: mappedTimes,
        notes: this.props.selectedUser.notes
      });
    }
  }

  async handleRangeSelection() {
    try {
      this.setGlobal({ loading: true });
      const res = await axios.post(
        `${process.env.REACT_APP_API}/partners/users/update-user-info/${this.props.match.params.id}`,
        { schedule: this.state.schedule, notes: this.state.notes }
      );

      if (res.data.success) {
        toast.success(`Saved Info!`);
        this.setState({
          schedule: res.data.data.schedule,
          notes: res.data.data.notes
        });
        this.props.update();
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          <div className="user-nav-line">
            <div className="user-schedule-header-box">
                <span
                  className={`user-sub-header schedule-nav-header1 ${
                    this.state.subActive === "Current" &&
                    "active-user-sub-header"
                  }`}
                  onClick={_ => this.setState({ subActive: "Current" })}
                >
                  Current
                </span>
              <span
                className={`user-sub-header schedule-nav-header2 ${
                  this.state.subActive === "Notes" && "active-user-sub-header"
                }`}
                onClick={_ => this.setState({ subActive: "Notes" })}
              >
                  Notes
                </span>
            </div>
          </div>
          <div className="user-schedule-box">
            {this.state.subActive === "Current" ? (
              <>
                <div className="user-task-body-box">
                  <div
                    className="cont"
                    style={{ width: "90%", marginTop: "15px", marginLeft: "auto", marginRight: "auto" }}
                  >
                    <ScheduleSelector
                      rowGap={"4px"}
                      selection={this.state.schedule}
                      numDays={7}
                      minTime={7}
                      maxTime={22}
                      startDate={startOfWeek(new Date())}
                      onChange={e => this.setState({ schedule: e })}
                      dateFormat="ddd"
                      unselectedColor={"#f7f7f8"}
                      hoveredColor={"#85899f"}
                      selectedColor={"#85899f"}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="schedule-notes">
                <textarea
                  name="notes"
                  id="notes"
                  type="text"
                  value={this.state.notes}
                  onChange={e =>
                    this.setState({
                      notes: e.target.value
                    })
                  }
                  style={{
                    width: "90%",
                    border: "1px solid lightgrey",
                    padding: "8px 12px"
                  }}
                  rows="12"
                  columns="12"
                />
              </div>
            )}

            <div className="user-save-row" style={{ marginTop: "10px" }}>
              <button
                className="user-save-btn"
                onClick={_ => this.handleRangeSelection()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Schedule);
