import React from "reactn";
import { MiniCheck, MiniPlus, MiniIks, MiniMinus } from "../../../../../assets";

const StatusSelectionModal = ({
  child,
  onClose,
  setModalScreen,
  setChildStatus
}) => {
  return (
    <>
      <h3>Mark {`${child.child.student.fullName} (${child.child.day})`}:</h3>
      <div
        className="modal-row"
        style={{ cursor: "pointer" }}
        onClick={() => setModalScreen(1)}
      >
        <img src={MiniCheck} alt="Present" />
        Present
      </div>
      <div
        className="modal-row"
        style={{ cursor: "pointer" }}
        onClick={() => setModalScreen(5)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9.601"
          height="7.311"
          viewBox="0 0 9.601 7.311"
          style={{ marginRight: "6px" }}
        >
          <path
            id="path"
            d="M523.855,1054.383l5.783-5.783.764.764-6.546,6.546-3.055-3.055.764-.764Z"
            transform="translate(-520.8 -1048.6)"
            fill="Orange"
          />
        </svg>
        Late
      </div>
      <div
        className="modal-row"
        style={{ cursor: "pointer" }}
        onClick={() => setModalScreen(4)}
      >
        <img src={MiniIks} alt="Absent" />
        Absent
      </div>
      <button onClick={() => onClose()}>CANCEL</button>
    </>
  );
};

export default StatusSelectionModal;
