import React, { useRef, useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import "../../assets/css/componentSpecificCss/imageCropModal.css";
import BaseModal from "./baseModal/BaseModal";
import { IMAGE_CONTENT } from "./baseModal/FieldTypes";

const ImageCropModal = ({ onClose, sourceImage, uploadImage, aspectRatio }) => {
  const [imageDestination, setImageDestination] = useState("");
  const ImageElement = useRef();

  useEffect(() => {
    const cropper = new Cropper(ImageElement.current, {
      zoomable: false,
      scalable: true,
      aspectRatio: aspectRatio,
      crop: () => {
        const canvas = cropper.getCroppedCanvas();
        canvas.toBlob(blob => {
          setImageDestination(blob);
        });
      }
    });
  }, []);

  const getBaseModalFields = () => {
    const imageField = {
      type: IMAGE_CONTENT,
      data: {
        src: sourceImage,
        refer: ImageElement
      }
    };

    const fields = [];

    fields.push(imageField);

    return fields;
  };

  const getBaseModalButtons = () => {
    const createButton = {
      name: "Upload Image",
      buttonColour: "#3B4EFF",
      textColour: "#fff",
      handleClick: () => uploadImage(imageDestination)
    };
    return [createButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Center and Crop Your Image",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      handleClose: onClose,
      height: "75vh",
      midSectionHeight: "52vh"
    };
  };

  return <BaseModal {...getBaseModalProps()} />;
};

export default ImageCropModal;
