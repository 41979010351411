import React from "reactn";
import { CardElement, injectStripe } from "react-stripe-elements";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import axios from "axios";

class PaymentSettingsCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // cardElement: null,
      cust: {
        name: "",
        address_line1: "",
        address_line2: "",
        address_city: "",
        address_state: "",
        address_zip: "",
        address_country: "CA"
      },
      phone: ""
    };
    this.handleReady = this.handleReady.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async handleSubmit(e) {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/stripe/client`;
    try {
      const vals = this.state.cust;
      if (!vals.name) throw new Error("Name must be provided!");
      if (!this.state.phone) throw new Error("Phone Number must be provided!");
      if (!vals.address_line1) throw new Error("Address must be provided!");
      if (!vals.address_city) throw new Error("City must be provided!");
      if (!vals.address_state) throw new Error("Province must be provided!");
      if (!vals.address_zip) throw new Error("Postal Code must be provided!");
      Object.keys(vals).forEach(k => {
        vals[k] = vals[k].toUpperCase();
      });
      this.setGlobal({ loading: true });
      const token = await this.props.stripe.createToken(vals);
      try {
        const res = await axios.post(ep, {
          ...vals,
          phone: this.state.phone,
          source: token
        });
        if (res.data.success) {
          this.setGlobal({
            stripe: res.data.data
          });
          toast("Successfully updated payment info!");
        } else {
          toast(res.data.message);
        }
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (ex) {
        toast.error(ex.message);
        console.error(ex);
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    } catch (err) {
      this.setGlobal({ loading: false });
      toast.error(err.message);
      console.error(err);
    }
  }

  handleChange = (e, v) => {
    this.setState({ cust: { ...this.state.cust, [e]: v } });
  };

  handleReady = element => {
    // this.setState({ cardElement: element });
  };
  componentDidMount() {
    if (this.global.parentGuardians[0]) {
      const parent = this.global.parentGuardians[0];
      this.setState({
        cust: {
          ...this.state.cust,
          name: parent.fullName,
          address_line1: parent.address,
          address_city: parent.city,
          address_state: parent.province,
          address_zip: parent.postalCode,
          address_country: "CA"
        },
        phone: "+1" + parent.homePhone
      });
    }
  }

  render() {
    const parent = this.global.parentGuardians[0];
    return (
      <form
        onSubmit={this.handleSubmit}
        className="w-100"
        style={{
          maxWidth: 480,
          margin: "auto auto",
          display: "block"
        }}
      >
        <h2 className="pl-0">Update Payment Settings</h2>
        <label className="mb-2 mt-3">Full Name *</label>
        <input
          type="text"
          name="name"
          value={this.state.cust.name}
          onChange={e => this.handleChange(e.target.name, e.target.value)}
        />
        <label className="mb-2 mt-3">Phone Number *</label>
        <PhoneInput
          name="phone"
          value={this.state.phone}
          onChange={e => this.setState({ phone: e })}
          countries={["CA", "US"]}
          country="CA"
          className="w-100"
          limitMaxLength={true}
        />
        <label className="mb-2 mt-3">Address Line 1 *</label>
        <input
          type="text"
          name="address_line1"
          value={this.state.cust.address_line1}
          onChange={e => this.handleChange(e.target.name, e.target.value)}
        />
        <label className="mb-2 mt-3">Address Line 2</label>
        <input
          type="text"
          name="address_line2"
          value={this.state.cust.address_line2}
          onChange={e => this.handleChange(e.target.name, e.target.value)}
        />
        <label className="mb-2 mt-3">City *</label>
        <input
          type="text"
          name="address_city"
          value={this.state.cust.address_city}
          onChange={e => this.handleChange(e.target.name, e.target.value)}
        />
        <label className="mb-2 mt-3">Province *</label>
        <select
          name="address_state"
          value={this.state.cust.address_state}
          onChange={e => this.handleChange(e.target.name, e.target.value)}
          className="w-100 mb-2"
          style={{ border: "1px solid #cbcbcb", padding: 6, borderRadius: 6 }}
        >
          <option value="">Please Select...</option>
          <option value="ON">Ontario</option>
          <option value="QC">Quebec</option>
          <option value="NS">Nova Scotia</option>
          <option value="NB">New Brunswick</option>
          <option value="MB">Manitoba</option>
          <option value="BC">British Columbia</option>
          <option value="PE">Prince Edward Island</option>
          <option value="SK">Saskatchewan</option>
          <option value="AB">Alberta</option>
          <option value="NL">Newfoundland & Labrador</option>
        </select>
        <label className="mb-2 mt-3">Postal Code *</label>
        <input
          type="text"
          name="address_zip"
          value={this.state.cust.address_zip}
          onChange={e => this.handleChange(e.target.name, e.target.value)}
        />
        <label className="mb-2 mt-3">Card details *</label>
        <CardElement
          style={{ base: { fontSize: "18px" } }}
          onReady={this.handleReady}
        />
        <button
          type="submit"
          className="parent__handbook_link mt-3"
          style={{
            width: "fit-content",
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 14,
            paddingRight: 14,
            margin: "auto auto"
          }}
        >
          Save
        </button>
      </form>
    );
  }
}

export default injectStripe(PaymentSettingsCard);
