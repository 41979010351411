import React from "reactn";
import axios from "axios";
import ChildProfile from "../shared/applicationView/ChildProfile";
import EmergencyContact from "../shared/applicationView/EmergencyContact";
import PhysicianContact from "../shared/applicationView/PhysicianContact";
import GuardianContact from "../shared/applicationView/ParentGuardian";
import EnrollmentDetails from "../shared/applicationView/EnrollmentDetails";
import { format } from "date-fns";

class ApplicationView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        createdAt: null,
        plans: null,
        birthDate: null,
        allergies: [],
        languages: [],
        medication: [],
        likes: [],
        dislikes: []
      },
      tab: 0
    };
  }

  async componentDidMount() {
    const id = this.props.match.params.id;
    const ep = `${process.env.REACT_APP_API}/partners/programs/app/${id}`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setGlobal({
        loading: false,
        lastAPICall: res
      });
      this.setState({ data: res.data.data });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
    this.handleAccept = this.handleAccept.bind(this);
  }

  handleAccept = async () => {
    this.setGlobal({ loading: true });
    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/edu/enrollment/application/${params.slug}/${params.time}`;
    try {
      const res = await axios.post(ep, { action: "Accept" });
      this.setGlobal({ loading: false, lastAPICall: res });
      if (res.data.success) {
        this.props.history.push("/educators/applications");
      }
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  };

  render() {
    const { tab } = this.state;
    const contacts = [];
    const plan = this.state.data.plans
      ? JSON.parse(this.state.data.plans[this.state.data.plan_id])
      : undefined;
    const objConv = {
      week: "Weekly",
      "bi-week": "Bi-Weekly",
      month: "Monthly"
    };
    return (
      <div className="container-fluid">
        <div className="cont">
          <div className="row">
            <div className="col-md-12">
              <h1>{this.state.data.fullName}</h1>
            </div>
          </div>
        </div>
        <div className="cont">
          <div className="boxes d-flex" style={{ flexWrap: "wrap" }}>
            {!this.state.data ? null : (
              <div
                className="box"
                style={{
                  width: "100%",
                  maxWidth: 380,
                  minWidth: 280,
                  height: "fit-content"
                }}
              >
                <div className="bgofbox">
                  <div className="header">
                    <div className="row">
                      <div className="col-12">
                        <h2>ENROLLMENT DETAILS</h2>
                      </div>
                    </div>
                  </div>
                  <div className="formbody">
                    <div className="row">
                      <div className="col-4">
                        <h4>Start Date</h4>
                      </div>
                      <div className="col-8">
                        {format(
                          new Date(this.state.data.createdAt),
                          "LLLL dd, yyyy"
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h4>End Date</h4>
                      </div>
                      <div className="col-8">--</div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h4>Plan</h4>
                      </div>
                      <div className="col-8">
                        {plan
                          ? plan.type
                            ? `Semester - $${plan.tuition_rate}`
                            : `Recurring - $${plan.tuition_rate} / ${
                                objConv[plan.billing_cycle]
                              }, \n ${plan.timing[0]} to ${plan.timing[1]}`
                          : ""}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h4>Days of Week</h4>
                      </div>
                      <div className="col-8"></div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h4>Spot Available</h4>
                      </div>
                      <div className="col-8">Yes</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!this.state.data ? null : (
              <div
                className="box"
                style={{
                  width: "100%",
                  maxWidth: 380,
                  minWidth: 300,
                  height: "fit-content"
                }}
              >
                <div className="bgofbox">
                  <div className="header">
                    <div className="row">
                      <div className="col-4">
                        <h2>STUDENT</h2>
                      </div>
                      <div className="col-8 d-flex">
                        <button
                          className={0 === tab ? "active" : ""}
                          onClick={() => this.setState({ tab: 0 })}
                        >
                          Profile
                        </button>
                        <button
                          className={1 === tab ? "active" : ""}
                          onClick={() => this.setState({ tab: 1 })}
                        >
                          Additional Info
                        </button>
                      </div>
                    </div>
                  </div>
                  {tab === 0 ? (
                    <div className="formbody">
                      <div className="row">
                        <div className="col-4">
                          <h4>Full Name</h4>
                        </div>
                        <div className="col-8">{this.state.data.fullName}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h4>Birth Date</h4>
                        </div>
                        <div className="col-8">
                          {format(
                            new Date(this.state.data.birthDate),
                            "LLLL dd, yyyy"
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h4>Gender</h4>
                        </div>
                        <div className="col-8">{this.state.data.gender}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h4>Languages</h4>
                        </div>
                        <div className="col-8">
                          {this.state.data.languages
                            ? this.state.data.languages
                                .map(lang => lang.name)
                                .join(", ")
                            : ""}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h4>Allergies</h4>
                        </div>
                        <div className="col-8">
                          {this.state.data.allergies
                            ? this.state.data.allergies
                                .map(allergy => allergy.name)
                                .join(", ")
                            : ""}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h4>Immunized</h4>
                        </div>
                        <div className="col-8">{this.state.data.immunized}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h4>Medication</h4>
                        </div>
                        <div className="col-8">
                          {this.state.data.medication
                            ? this.state.data.medication
                                .map(med => med.name)
                                .join(", ")
                            : ""}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="formbody">
                      <div className="row">
                        <div className="col-4">
                          <h4>Special Food</h4>
                        </div>
                        <div className="col-8">
                          {this.state.data.specialFood
                            ? this.state.data.specialFood
                                .map(food => food.name)
                                .join(", ")
                            : ""}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h4>Potty Trained</h4>
                        </div>
                        <div className="col-8">
                          {this.state.data.pottyTrained}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h4>Health Concerns</h4>
                        </div>
                        <div className="col-8">
                          {this.state.data.healthConcerns
                            ? this.state.data.healthConcerns
                                .map(item => item.name)
                                .join(", ")
                            : ""}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h4>Likes</h4>
                        </div>
                        <div className="col-8">
                          {this.state.data.likes
                            ? this.state.data.likes
                                .map(item => item.name)
                                .join(", ")
                            : ""}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h4>Dislikes</h4>
                        </div>
                        <div className="col-8">
                          {this.state.data.dislikes
                            ? this.state.data.dislikes
                                .map(item => item.name)
                                .join(", ")
                            : ""}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h4>Comments</h4>
                        </div>
                        <div className="col-8">{this.state.data.comments}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {!this.state.guardians ||
            !this.state.physicians ||
            !this.state.contacts ? null : (
              <div
                className="box"
                style={{
                  maxWidth: 540,
                  minWidth: 380,
                  height: "fit-content",
                  flexGrow: 1
                }}
              >
                <div className="bgofbox">
                  <div className="header">
                    <div className="row">
                      <div className="col-4">
                        <h2>CONTACTS</h2>
                      </div>
                      <div className="col-8 d-flex">
                        <button
                          className={
                            0 === this.state.contactTab ? "active" : ""
                          }
                          onClick={() => this.setState({ contactTab: 0 })}
                        >
                          Parent / Guardians
                        </button>
                        <button
                          className={
                            1 === this.state.contactTab ? "active" : ""
                          }
                          onClick={() => this.setState({ contactTab: 1 })}
                        >
                          Emergency
                        </button>
                        <button
                          className={
                            2 === this.state.contactTab ? "active" : ""
                          }
                          onClick={() => this.setState({ contactTab: 2 })}
                        >
                          Physicians
                        </button>
                      </div>
                    </div>
                  </div>
                  {this.state.contactTab !== 0 ? null : (
                    <div className="formbody">
                      {contacts.map((contact, i) => (
                        <React.Fragment key={`${i}-contact`}>
                          <div className="row">
                            <div className="col-4">
                              <h4>Full Name</h4>
                            </div>
                            <div className="col-8">
                              {this.state.data.fullName}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h4>Address</h4>
                            </div>
                            <div className="col-8">
                              {this.state.data.address}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h4>City</h4>
                            </div>
                            <div className="col-8">{this.state.data.city}</div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h4>Province</h4>
                            </div>
                            <div className="col-8">
                              {this.state.data.province}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h4>Country</h4>
                            </div>
                            <div className="col-8">
                              {this.state.data.country}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h4>Postal Code</h4>
                            </div>
                            <div className="col-8">
                              {this.state.data.postalCode}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h4>Email</h4>
                            </div>
                            <div className="col-8">{this.state.data.email}</div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h4>Home Phone</h4>
                            </div>
                            <div className="col-8">
                              {this.state.data.homePhone}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h4>Work Phone</h4>
                            </div>
                            <div className="col-8">
                              {this.state.data.workPhone}
                            </div>
                          </div>
                          {i !== [].length - 1 ? <hr /> : null}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                  {this.state.contactTab !== 1 ? null : (
                    <div className="formbody">
                      {contacts.map((contact, i) => (
                        <React.Fragment key={`${i}-contact`}>
                          <div className="row">
                            <div className="col-4">
                              <h4>Full Name</h4>
                            </div>
                            <div className="col-8">{contact.fullName}</div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h4>Relationship</h4>
                            </div>
                            <div className="col-8">{contact.relationship}</div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h4>Address</h4>
                            </div>
                            <div className="col-8">{contact.address}</div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h4>Phone Number</h4>
                            </div>
                            <div className="col-8">{contact.phone}</div>
                          </div>
                          {i !== contacts.length - 1 ? <hr /> : null}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                  {this.state.contactTab !== 2 ? null : (
                    <div className="formbody">
                      {contacts.map((contact, i) => (
                        <React.Fragment key={`${i}-contact`}>
                          <div className="row">
                            <div className="col-4">
                              <h4>Full Name</h4>
                            </div>
                            <div className="col-8">{contact.fullName}</div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h4>Address</h4>
                            </div>
                            <div className="col-8">{contact.address}</div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h4>Phone Number</h4>
                            </div>
                            <div className="col-8">{contact.phone}</div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h4>Institution Name</h4>
                            </div>
                            <div className="col-8">{contact.company}</div>
                          </div>
                          {i !== contacts.length - 1 ? <hr /> : null}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ApplicationView;
