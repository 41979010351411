import React from "reactn";
import { MiniPlus } from "../../../../assets";
import { withRouter } from "react-router-dom";
import axios from "axios";

class ProgramDisplayPhotos extends React.PureComponent {
  constructor() {
    super();
    this.fileChange = this.fileChange.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
  }

  uploadNew() {
    if (
      !this.global.profile.programSubmitted ||
      this.global.dsUser.accountType === "Admin"
    ) {
      const uploadEle = document.getElementById("uploadPhoto");
      uploadEle.click();
    }
  }

  async removePhoto(e) {
    const params = this.props.match.params;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.post(
        `${process.env.REACT_APP_API}/edu/program/photo/remove-gallery`,
        { photo: e, dsUserId: params.id || this.global.dsUserId }
      );
      if (res.data.success) {
        this.setGlobal({
          myProgramGallery: res.data.data,
          loading: false,
          lastAPICall: res
        });
      } else {
        this.setGlobal({ loading: false, lastAPICall: res });
      }
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  fileChange(e) {
    const params = this.props.match.params;
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      const fd = new FormData();
      if (params.id) {
        fd.set("dsUserId", params.id);
      }
      fd.set("type", "display");
      fd.set("image", e.target.files[0]);
      this.dispatch.uploadProgramPhoto(fd);
    }
  }

  render() {
    return (
      <div className="box" style={{ maxWidth: 440 }}>
        <div className="bgofbox">
          <div className="header">
            <div className="row">
              <div className="col-12">
                <h2>DISPLAY PHOTOS</h2>
              </div>
            </div>
          </div>
          <div
            className="photos"
            style={{
              display: "flex",
              flexWrap: "wrap",
              padding: "0.5rem 0.5rem 0"
            }}
          >
            {this.global.myProgramGallery.photos.map((item, index) => {
              return (
                <figure
                  className="photoContainer"
                  style={{
                    width: "25%",
                    textAlign: "center"
                  }}
                  key={index}
                >
                  <div
                    style={{
                      padding: "2px",
                      width: "80px",
                      height: "80px",
                      border: "1px solid #e3e3e3",
                      display: "inline-block",
                      borderRadius: "8px",
                      cursor: "pointer",
                      backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${item})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat"
                    }}
                    id={index}
                    draggable="true"
                    onDragOver={e => {
                      e.preventDefault();
                    }}
                    onDragStart={e => {
                      const crt = e.target.cloneNode(true);
                      e.target.style.opacity = 0;
                      crt.style.position = "absolute";
                      crt.style.top = "-1000px";
                      crt.style.zIndex = 1000;
                      crt.id = crt.id * 100;
                      document.getElementById(index).appendChild(crt);
                      e.dataTransfer.setDragImage(crt, 0, 0);
                      e.dataTransfer.setData("text/plain", e.target.id);
                    }}
                    onDragEnd={e => {
                      e.target.style.opacity = 1;
                      document
                        .getElementById(index)
                        .removeChild(document.getElementById(index * 100));
                    }}
                    onDrop={e => {
                      e.preventDefault();
                      const imageArray = [
                        ...this.global.myProgramGallery.photos
                      ];
                      const droppedOn = imageArray[e.currentTarget.id];
                      const elementTransferred =
                        imageArray[
                          parseInt(e.dataTransfer.getData("text/plain"))
                        ];
                      imageArray[
                        parseInt(e.dataTransfer.getData("text/plain"))
                      ] = droppedOn;
                      imageArray[e.currentTarget.id] = elementTransferred;
                      axios.post(
                        `${process.env.REACT_APP_API}/edu/program/switch`,
                        {
                          photo: imageArray,
                          dsUserId:
                            this.global.myProgramGallery.dsUserId || null
                        }
                      );
                      this.setGlobal({
                        myProgramGallery: {
                          ...this.global.myProgramGallery,
                          photos: imageArray
                        }
                      });
                    }}
                  >
                    <div
                      className="photoContainerX"
                      onClick={() => this.removePhoto(item)}
                    >
                      <div className="innerX">
                        <i className="far fa-times-circle"></i>
                      </div>
                    </div>
                  </div>
                </figure>
              );
            })}
            {Array.from(
              Array(16 - this.global.myProgramGallery.photos.length).keys()
            ).map((item, i) => {
              return (
                <figure
                  key={i}
                  className="photoContainer"
                  style={{
                    width: "25%",
                    textAlign: "center"
                  }}
                  onClick={() => this.uploadNew()}
                >
                  <div
                    style={{
                      padding: "2px",
                      width: "80px",
                      height: "80px",
                      border: "1px solid #e3e3e3",
                      display: "inline-block",
                      borderRadius: "8px",
                      cursor: "pointer"
                    }}
                  >
                    <img
                      src={MiniPlus}
                      alt="Add"
                      style={{ marginTop: "calc(50% - 4.5px)" }}
                    />
                  </div>
                </figure>
              );
            })}
            <input
              type="file"
              style={{ display: "none" }}
              id="uploadPhoto"
              onChange={this.fileChange}
              accept="image/x-png,image/gif,image/jpeg"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ProgramDisplayPhotos);
