import React from "reactn";
import { Link } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import {
  MonthlySurvey,
  WriteAReview,
  CMale,
  CFemale,
  CUnisex
} from "../../assets";
import { numToHours } from "../../lib/util";

class MyEnrollments extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      programs: []
    };
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "My Enrollments"]
    });
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/par/enrollments`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      // const enroll = res.data.data.filter(d => {
      //   return d.educatorResponse === "Accepted";
      // });

      this.setState({ programs: res.data.data });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    return (
      <div className="container-fluid my_enrollments">
        <div className="cont">
          <h1>My Enrollments</h1>
        </div>
        <div className="cont">
          <div className="boxes-row">
            {this.state.programs.map((program, key) => {
              return (
                <div className="enrollment-box" key={key}>
                  <div className="imghere">
                    <div
                      className="divforimg"
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${program.programCoverPhoto})`,
                        width: "100%",
                        height: "210px",
                        backgroundPosition: "center",
                        backgroundSize: "cover"
                      }}
                    >
                      <div className="paragraph">
                        <div className="verticalcenter">
                          <p>{program.programName}</p>
                          <p className="btnvd">
                            <Link
                              to={`my-enrollments/${program.slug}/${program.submittedDate}`}
                              onClick={() => {
                                window.scrollTo(0, 0);
                              }}
                            >
                              View Details
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="continbox">
                    <div className="row margintopdiv">
                      <div className="col-md-12">
                        <div
                          className="imgchild"
                          alt=""
                          style={{
                            height: 59,
                            width: 59,
                            background: `url(${
                              program.photo
                                ? `${process.env.REACT_APP_DS_FILES_S3}/${program.photo}`
                                : program.gender === "Male"
                                ? CMale
                                : program.gender === "Female"
                                ? CFemale
                                : CUnisex
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                          }}
                        ></div>
                        <h3>{program.fullName}</h3>
                        <div className="skills">
                          <p>
                            <img src={MonthlySurvey} alt="" />
                            Monthly Survey
                          </p>
                          <p>
                            <img src={WriteAReview} alt="" />
                            Write a Review
                          </p>
                          {!program.parentHandbook ? null : (
                            <a
                              target="_blank"
                              href={`${process.env.REACT_APP_DS_FILES_S3}/${program.parentHandbook}`}
                              style={{ fontSize: "12px" }}
                            >
                              Parent Handbook
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="continbox">
                    <div className="row">
                      <div className="col-6">
                        <h2>Plan: </h2>
                      </div>
                      <div className="col-6">
                        <p>{program.name}</p>
                        <p>{program.numDays} days / week</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <h2>Start Date: </h2>
                      </div>
                      <div className="col-6">
                        <p>
                          {format(new Date(program.startDate), "LLL dd, yyyy")}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <h2>End Date: </h2>
                      </div>
                      <div className="col-6">
                        <p>
                          {program.endDate
                            ? format(new Date(program.endDate), "LLL dd, yyyy")
                            : "-"}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <h2>Days: </h2>
                      </div>
                      <div className="col-6">
                        <p>{program.daysOfWeek.map(d => d.name).join(", ")}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <h2>Time: </h2>
                      </div>
                      <div className="col-6">
                        <p>
                          {numToHours(program.hoursFrom)} -{" "}
                          {numToHours(program.hoursTo)}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <h2>Checkout: </h2>
                      </div>
                      <div className="col-6">
                        <Link
                          to={`/parents/acceptance/${program.slug}/${program.submittedDate}`}
                          style={{ fontSize: "12px", color: "#4e73df" }}
                        >
                          View
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default MyEnrollments;
