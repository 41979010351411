import React from "reactn";
import SectionFive from "./forms/SectionFive";
class Setup extends React.Component {
  render() {
    return (
      <div className="cont boxes-row mt-50px center-box">
        <SectionFive vals={this.global.myApplicationProfileInfo} />
      </div>
    );
  }
}

export default Setup;
