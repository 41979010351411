import React from "reactn";
import { StepIndicator } from "../../../components/UI";
import MyProfilePage from "./myProfile";
import MyHomePage from "./myHome";
import StepBar from "../../../components/UI/StepBar";
import Validators from "../../../validators";
import MyCredentialsPage from "./myCredentials";
import MyChildrenPage from "./myChildren";
import axios from "axios";
import TOSForm from "./forms/tosForm";
import CertifyForm from "./forms/certifyForm";
import Axios from "axios";

class MyApplicationPage extends React.PureComponent {
  constructor() {
    super();
    if (this.global.dsUser.accountType === "Admin") {
      require("../../../store/educators/myApplication");
    }
    this.state = {
      step: 0,
      tosModalShown: false,
      certifyModalShown: false
    };
    this.titles = ["Profile", "Home", "Credentials", "My Children"];
    this.validate = [
      () =>
        Validators.Educators.MyApplication.ValidateProfile({
          info: this.global.myApplicationProfileInfo,
          address: this.global.myApplicationProfileAddress
        }),
      () =>
        Validators.Educators.MyApplication.ValidateHome({
          home: this.global.myApplicationHomeInfo,
          regulars: this.global.myApplicationHomeRegulars
        }),
      () =>
        Validators.Educators.MyApplication.ValidateCredentials({
          documentation: this.global.myApplicationDocumentation,
          references: this.global.myApplicationReferences
        }),
      () =>
        Validators.Educators.MyApplication.ValidateChildren(
          this.global.myApplicationChildren,
          this.global.myApplicationProfileInfo
        )
    ];
    this.steps = [
      { name: "Profile", validateFn: this.validate[0] },
      { name: "Home", validateFn: this.validate[1] },
      { name: "Credentials", validateFn: this.validate[2] },
      { name: "Children", validateFn: this.validate[3] }
    ];
    this.setGlobal({
      pathway: [
        ...this.global.pathway.slice(0, 1),
        "My Application",
        this.titles[this.state.step]
      ]
    });
    this.saveProfile = this.saveProfile.bind(this);
  }

  async componentDidMount() {
    const params = this.props.match.params;
    if (!params.id) {
      this.dispatch.getApplication();
    } else {
      const ep = `${process.env.REACT_APP_API}/adm/educator/application/${params.id}`;
      try {
        this.setGlobal({ loading: true });
        const res = await axios.get(ep);

        if (res.data.success) {
          this.setGlobal(res.data.data);
          this.forceUpdate();
        }
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (er) {
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }
  }

  async saveProfile(e) {
    const { step } = this.state;
    const params = this.props.match.params;
    switch (e.target.name) {
      case "next":
        this.setState({ step: step + 1 });
        if (!this.global.profile.applicationSubmitted && !params.id) {
          await this.dispatch.saveApplication();
        }
        break;
      case "back":
        this.setState({ step: step - 1 });
        if (!this.global.profile.applicationSubmitted && !params.id) {
          await this.dispatch.saveApplication();
        }
        break;
      case "save":
        if (
          (!this.global.profile.applicationSubmitted && !params.id) ||
          this.global.dsUser.accountType === "Admin"
        ) {
          if (this.global.dsUser.accountType === "Admin") {
            await this.dispatch.saveApplicationAdmin(params.id);
          } else {
            await this.dispatch.saveApplication();
          }
        }
        break;
      case "submit":
        if (
          !this.global.profile.applicationSubmitted &&
          !params.id &&
          this.getCompletion() === 100
        ) {
          this.setState({ tosModalShown: true });
        }
        break;
      default:
        return;
    }
  }

  changePage = async page => {
    this.setState({ step: page });

    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 2), this.titles[page]]
    });
    const params = this.props.match.params;
    if (!params.id) {
      if (!this.global.profile.applicationSubmitted) {
        await this.dispatch.saveApplication();
      }
    }
  };

  getPage = () => {
    switch (this.state.step) {
      case 0:
        return <MyProfilePage />;
      case 1:
        return <MyHomePage />;
      case 2:
        return <MyCredentialsPage />;
      case 3:
        return <MyChildrenPage />;
      default:
        return <MyProfilePage />;
    }
  };

  checkValid() {
    const { step } = this.state;
    return this.validate[step] && !this.validate[step]();
  }

  getCompletion() {
    let completed = 0;
    const ep = `${process.env.REACT_APP_API}/adm/educator/application/progress`;
    for (let v of this.validate) {
      completed += v();
    }
    Axios.post(ep, {
      id: this.global.profile.id,
      progress: (completed / this.validate.length) * 100
    });
    return (completed / this.validate.length) * 100;
  }

  render() {
    return !this.global.myApplicationProfileInfo ? null : (
      <div className="container-fluid">
        {!this.state.certifyModalShown ? null : (
          <CertifyForm
            onClose={certified => {
              if (certified) {
                this.setState({
                  tosModalShown: true,
                  certifyModalShown: false
                });
              } else {
                this.setState({ certifyModalShown: false });
              }
            }}
          />
        )}
        {!this.state.tosModalShown ? null : (
          <TOSForm
            history={this.props.history}
            onClose={() => {
              this.setState({ tosModalShown: false });
            }}
          />
        )}
        <div className="cont">
          <h1>
            My Application <span>{this.titles[this.state.step]}</span>
          </h1>
        </div>
        <StepBar
          {...{
            steps: this.steps,
            changeFn: this.changePage,
            current: this.state.step
          }}
        />
        <StepIndicator value={this.getCompletion()} />
        {this.getPage()}
        <div className="cont mb-5 d-flex">
          <div className="buttons w-100 text-left">
            <button
              onClick={this.saveProfile}
              disabled={
                this.global.profile.applicationSubmitted &&
                this.global.dsUser.accountType !== "Admin"
              }
              name="save"
              className="savebtn"
            >
              SAVE
            </button>
            <button
              onClick={this.saveProfile}
              disabled={
                this.global.profile.applicationSubmitted ||
                this.getCompletion() !== 100
              }
              name="submit"
              className="nextbtn"
            >
              <>
                SUBMIT <i className="fas fa-arrow-right"></i>
              </>
            </button>
          </div>
          <div className="buttons w-100">
            <button
              onClick={this.saveProfile}
              disabled={this.state.step === 0}
              name="back"
              className="nextbtn"
            >
              BACK
            </button>
            <button
              onClick={this.saveProfile}
              disabled={this.state.step === this.titles.length - 1}
              name="next"
              className="nextbtn"
            >
              NEXT
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default MyApplicationPage;
