import React from "reactn";
import ReactDOMServer from "react-dom/server"
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import {
  DROPDOWN,
  STRING_INPUT,
  SELECT_MULTIPLE_DROPDOWN,
  DATE_PICKER,
  SWITCH,
  CONTENT_BLOCK,
  TEXT_AREA,
  GET_HTML
} from "./baseModal/FieldTypes";
import { notEmptyString } from "./baseModal/validators";
import { BLUE_BUTTON, RED_BUTTON, WHITE } from "./baseModal/colours";

class PartyAttendeeListPDF extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroup: "",
      selectedPerson: "",
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {

  }

  getAttendeeHTML = () => {
    if (this.state.selectedGroup && this.state.selectedPerson) {
      const customer = this.props.customers[this.state.selectedGroup].find(customer => parseInt(this.state.selectedPerson) === parseInt(customer.id))
      const element = (
        <div
          id="attendee-list"
          style={{
            padding: "10px 0px",
            fontFamily: "Montserrat",
            width: "100%"
          }}
        >
          <div
            style={{
              fontSize: "20px",
              marginBottom: "10px",
              width: "100%"
            }}
          >
            {customer.displayName} Attendees:
          </div>
          <table
            style={{
              paddingLeft: "8px",
              fontSize: "14px",
              width: "100%",
              borderCollapse: "collapse",
              borderSpacing: "5px"
            }}
          >
            <tr style={{
              width: "100%"
            }}>
              <th style={{
                width: "50%",
                fontFamily: "Montserrat"
              }}>Name</th>
              <th style={{
                width: "50%",
                fontFamily: "Montserrat"
              }}>Email</th>
            </tr>
            <tr style={{
              width: "100%"
            }}>
              <td style={{
                width: "50%",
                fontFamily: "Montserrat"
              }}>{customer.displayName}</td>
              <td style={{
                width: "50%",
                fontFamily: "Montserrat"
              }}>{customer.email}</td>
            </tr>

            {customer.partyDetails.attendees.map(attendee => {
              return <tr style={{ width: "100%" }}>
                <td style={{
                  width: "50%",
                  fontFamily: "Montserrat"
                }}>{attendee.name}</td>
                <td style={{
                  width: "50%",
                  fontFamily: "Montserrat"
                }}>{attendee.email}</td>
              </tr>
            })}
          </table>
        </div >
      )
      return element
    }
    return null
  }

  downloadPDF = () => {
    const allFields = this.getBaseModalFields();
    const numFields = allFields.length;
    const domElementIds = [];

    // Determine the number of "base-modal-content-#" to add
    const numBaseModalContents = Math.ceil(numFields / 10);

    // Loop to create the "base-modal-content-#" IDs
    for (let i = 1; i <= numBaseModalContents; i++) {
      domElementIds.push(`base-modal-content-${i}`);
    }

    this.setGlobal({ loading: true });

    const element = document.getElementById("attendee-list")

    const renderPage = (pdf) => {
      html2canvas(element, {
        scale: 3,
        scrollX: 0,
        scrollY: 0
      }).then(canvas => {
        const PDF_MARGIN = 20;
        const PDF_WIDTH = 140;
        const MAX_HEIGHT = 250;

        const img = canvas.toDataURL("image/png");
        const imgProps = pdf.getImageProperties(img);

        let width = pdf.internal.pageSize.getWidth() - 40;
        let height = (imgProps.height * width) / imgProps.width;

        if (height > MAX_HEIGHT) {
          height = MAX_HEIGHT;
          width = (imgProps.width * height) / imgProps.height;
        }

        pdf.addImage(img, "JPEG", PDF_MARGIN, PDF_MARGIN, width, height);
        pdf.save("Attendees.pdf")
      })
    }

    const pdf = new jsPdf("p", "mm", "letter", true);

    try {
      renderPage(pdf);
    } catch (err) {
      toast.error("Could not generate PDF.");
    }
    this.setGlobal({ loading: false })
  };

  getChoices = () => {
    if (this.state.selectedGroup) {
      return this.props.customers[this.state.selectedGroup].map(customer => { return { text: customer.displayName, value: customer.id } })
    }
    return []
  }

  getBaseModalFields() {
    const fields = [];

    const selectGroup = {
      type: DROPDOWN,
      data: {
        name: "Select Application Status",
        required: true,
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            selectedGroup: e.target.value,
            selectedPerson: ""
          })
        },
        choices: [{ text: "Enrolled", value: "Attendees" }, { text: "Application", value: "Applications" }]
      }
    };

    const selectPerson = {
      type: DROPDOWN,
      data: {
        name: "Select",
        required: true,
        placeholder: "Please Select",
        value: this.state.selectedPerson,
        handleChange: e => {
          this.setState({
            selectedPerson: e.target.value
          })
        },
        choices: this.getChoices()
      }
    };

    const attendeeList = {
      type: GET_HTML,
      data: {
        gethtml: this.getAttendeeHTML
      }
    };

    fields.push(selectGroup)
    if (this.state.selectedGroup) {
      fields.push(selectPerson)
    }

    if (this.state.selectedGroup && this.state.selectedPerson) {
      fields.push(attendeeList)
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const downloadButton = {
      name: "Download",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => {
        const selectedCustomer = this.props.customers[this.state.selectedGroup].find(customer => parseInt(this.state.selectedPerson) === parseInt(customer.id))
        this.downloadPDF(selectedCustomer)
      }
    };

    return [downloadButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Attendee List PDF",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(PartyAttendeeListPDF);
