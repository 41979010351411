import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
class EditCouponModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async create_coupon(e) {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/partners/stripe/edit/coupon`;
    const cb = await axios.put(ep, {
      id: this.props.item.coupon_id,
      dbId: this.props.item.id,
      ...this.state
    });
    if (cb.data.success) {
      toast.success(`Coupon Updated!`);
      this.props.onEdit();
      this.props.onClose();
    }
  }

  /// ================== NEW MODAL ========================== ///
  getBaseModalFields() {
    const descriptionInput = {
      type: STRING_INPUT,
      data: {
        name: "Internal Name",
        required: false,
        handleChange: e => {
          this.setState({
            name: e.target.value
          });
        },
        value: this.state.name
      }
    };

    return [descriptionInput];
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.create_coupon(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Edit Coupon",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    /*     return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>Edit Coupon</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form onSubmit={e => this.create_coupon(e)}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="coupon_name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Internal Name
                </label>
                <input
                  name="name"
                  id="name"
                  type=""
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={this.state.name}
                />
              </div>

              <div className="modal-footer" style={{ marginTop: "25px" }}>
                <button
                  type="submit"
                  // onClick={_ => this.savePlan()}
                  className="btn profile-btn"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    ); */
  }
}

export default EditCouponModal;
