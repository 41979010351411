import React from "reactn";
import { Link } from "react-router-dom";

class LoginFranchise extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      rememberMe: false,
      showPassword: false
    };
    this.onChange = this.onChange.bind(this);
    this.attemptLogin = this.attemptLogin.bind(this);

    // Ref for manipulating password field directly.
    this.passwordRef = React.createRef();
  }

  onChange = (e, v) => {
    this.setState({ [e]: v });
  };

  attemptLogin = async e => {
    e.preventDefault();
    const test = await this.dispatch.performLoginFranchiser(this.state);
    const location = this.props.location.search;

    if (this.global.franchiseUser) {
      this.props.history.push(
        !location ? "/franchiser" : location.slice(location.indexOf("=") + 1)
      );
    } else {
      // Login failed.
      this.setState({ password: "" });
      this.passwordRef.current.focus();
    }

    return;
  };

  componentDidMount() {}

  render() {
    const { email, password, rememberMe } = this.state;
    return (
      <div className="login-signup-form mb-5">
        <div className="form-content custom-form-content">
          <h2>Franchise Owner - Log-in</h2>

          <form
            id="new_session"
            className="new_user"
            noValidate="novalidate"
            onSubmit={this.attemptLogin}
          >
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <br />
              <input
                className="form-control"
                type="email"
                value={email}
                name="email"
                onChange={e => this.onChange("email", e.target.value)}
                id="email"
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <br />
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  className="form-control"
                  type={this.state.showPassword ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={e => this.onChange("password", e.target.value)}
                  id="password"
                  ref={this.passwordRef}
                />
                <button
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ showPassword: !this.state.showPassword });
                  }}
                  tabIndex={-1}
                  type="button"
                  className="btn"
                  style={{
                    position: "absolute",
                    right: "0",
                    backgroundColor: "transparent",
                  }}
                >
                  <i className={`bi ${this.state.showPassword ? "bi-eye-slash" : "bi-eye"}`} />
                </button>

              </div>
            </div>

            <label htmlFor="rememberMe">
              <input
                type="checkbox"
                checked={rememberMe}
                name="rememberMe"
                onChange={e => this.onChange("rememberMe", e.target.checked)}
                id="rememberMe"
              />{" "}
              Remember me
            </label>
            <div className="form-actions">
              <input
                type="submit"
                name="commit"
                value="Login"
                className="btn btn-custom"
                data-disable-with="Login"
              />
            </div>
          </form>
          <div
            style={{
              width: "100%",
              zIndex: 1000,
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Link to="/forgot-password">Forgot your password?</Link>
            <Link to="/">User Login?</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginFranchise;
