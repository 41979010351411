import React from "reactn";
import { Link } from "react-router-dom";
import {
  FamilyProfile,
  PaymentMethod,
  AccountSettings,
  IntProg,
  ApplicationsIcon,
  MyEnroll,
  TourRequests,
  PaymentSettings
} from "../../assets";

const AccountLinks = [
  {
    name: "Family Profile",
    icon: FamilyProfile,
    to: "/parents/family-profile"
  },
  {
    name: "Payment Settings",
    icon: PaymentMethod,
    to: "/parents/payment-settings",
    disabled: "payment"
  },
  {
    name: "Account Settings",
    icon: AccountSettings,
    to: "/parents/account-settings"
  }
];

const ProgramLinks = [
  {
    name: "Interested Programs",
    icon: IntProg,
    to: "/parents/interested-programs"
  },
  {
    name: "Applications",
    icon: ApplicationsIcon,
    to: "/parents/applications"
  },
  {
    name: "Tuition Payments",
    icon: PaymentMethod,
    to: "/parents/tuition-payments"
  },
  {
    name: "My Enrollments",
    icon: MyEnroll,
    to: "/parents/my-enrollments"
  },
  {
    name: "My Tour Requests",
    icon: TourRequests,
    to: "/parents/tour-requests"
  },
  {
    name: "News Feed",
    icon: PaymentSettings,
    to: "/parents/newsfeed"
  }
];

class SideMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.checkEnabled = this.checkEnabled.bind(this);
  }

  checkEnabled = link => {
    if (!link.disabled) return false;
    if (link.disabled === "payment") return !this.global.payment;
    return true;
  };

  render() {
    return (
      <>
        {/* <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />

        {/* <!-- Heading --> */}
        <div className="sidebar-heading mt-0">ACCOUNT</div>
        {/* <!-- Nav Item - Dashboard --> */}
        <li className="nav-item active">
          {AccountLinks.map((link, key) => {
            const disabled = this.checkEnabled(link);
            return (
              <Link
                className="nav-link"
                to={link.to}
                key={key}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                disabled={disabled}
              >
                {<img src={link.icon} alt="" />}
                <span>{link.name}</span>
              </Link>
            );
          })}
        </li>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />

        {/* <!-- Heading --> */}
        <div className="sidebar-heading">PROGRAM</div>

        {/* <!-- Nav Item - Dashboard --> */}
        <li className="nav-item active">
          {ProgramLinks.map((link, key) => {
            return (
              <Link
                className="nav-link"
                to={link.to}
                key={key}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                {<img src={link.icon} alt="" />}
                <span>{link.name}</span>
              </Link>
            );
          })}
        </li>
      </>
    );
  }
}

export default SideMenu;
