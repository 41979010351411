import React from "reactn";
import { format } from "date-fns";
import { NewTabbedTable } from "../../../../components/UI";
import "../../../../assets/css/componentSpecificCss/newTabbedTable.css";

class FranchiserTrackRecurring extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      page: 0
    }
    this.tabs = ["Pending", "Payments"];
    this.inputRef = React.createRef();
  }
  getStatusColor = (status, auth) => {
    if (status === "Draft") {
      return "rgb(33 91 255)";
    } else if (status === "Ready to Bill" || status === "Paused") {
      return "#eba93e";
    } else if (status === "Cancelled") {
      return "#ff3b8e";
    } else if (status === "Issued" && auth) {
      return "#ff3b8e";
    } else if (status === "Issued") {
      return "rgba(92 200 220)";
    } else if (status === "Late" || status === "Failed" || status === "Late (Manual)") {
      return "#ff3b8e";
    } else if (status === "Processing") {
      return "rgb(33 91 255)";
    } else if (status === "Pending") {
      return "";
    } else {
      return "#1bc88a";
    }
  };

  headings = () => {
    const pending = [
      {
        id: "amount",
        label: "Due Date",
        customCell: (r, i) => {
          return (
            <td key={i}>
              {r.date ? format(new Date(r.date), "LLL dd, yyyy") : ""}
            </td>
          );
        }
      },
      {
        id: "amountOverdue",
        label: "Item",
        customCell: (r, i) => {
          return <td key={i}>{r.item}</td>;
        }
      },
      {
        id: "amountOverdue",
        label: "Total",
        customCell: (r, i) => {
          return <td key={i}>${parseFloat(r.total / 100).toFixed(2)}</td>;
        }
      },
      {
        id: "membership_term",
        label: "Purchaser",
        customCell: (r, i) => {
          if (r.childName?.length > 0) {
            const childNamesConcatenated = r.childName.join(", ");

            const truncatedChildNames =
              childNamesConcatenated.length > 25
                ? childNamesConcatenated.substring(0, 25) + "..."
                : childNamesConcatenated;
            return (
              <td className="table-custom-text">
                {r.purchaser} (
                <span style={{ fontStyle: "italic" }}>
                  {truncatedChildNames}
                </span>
                )
              </td>
            );
          }
          return (
            <td key={i} className="table-custom-text">
              {r.purchaser}
            </td>
          );
        }
      },
      {
        id: "max_amount",
        label: "Status",
        customCell: (r, i) => {
          return (
            <td key={i}>
              <span
                className="red"
                style={{
                  maxWidth: "100px",
                  backgroundColor: this.getStatusColor(r.status, r.requires_auth)
                }}
              >
                {r.requires_auth && r.status === "Issued" ? "Failed" : r.status}
              </span>
            </td>
          );
        }
      }
    ];
    const payments = [
      {
        id: "payment_date",
        label: "Payment Date",
        customCell: (r, i) => {
          return (
            <td key={i}>
              {r.userPaidDate
                ? format(new Date(r.userPaidDate), "LLL dd, yyyy")
                : ""}
            </td>
          );
        }
      },
      {
        id: "amount",
        label: "Due Date",
        customCell: (r, i) => {
          return (
            <td key={i}>
              {r.date ? format(new Date(r.date), "LLL dd, yyyy") : ""}
            </td>
          );
        }
      },
      {
        id: "amountOverdue",
        label: "Item",
        customCell: (r, i) => {
          return <td key={i}>{r.item}</td>;
        }
      },
      {
        id: "amountOverdue",
        label: "Total",
        customCell: (r, i) => {
          return <td key={i}>${parseFloat(r.total / 100).toFixed(2)}</td>;
        }
      },
      {
        id: "membership_term",
        label: "Purchaser",
        customCell: (r, i) => {
          if (r.childName?.length > 0) {
            const childNamesConcatenated = r.childName.join(", ");

            const truncatedChildNames =
              childNamesConcatenated.length > 25
                ? childNamesConcatenated.substring(0, 25) + "..."
                : childNamesConcatenated;
            return (
              <td className="table-custom-text">
                {r.purchaser} (
                <span style={{ fontStyle: "italic" }}>
                  {truncatedChildNames}
                </span>
                )
              </td>
            );
          }
          return (
            <td key={i} className="table-custom-text">
              {r.purchaser}
            </td>
          );
        }
      },
      {
        id: "membership_term",
        label: "Paid Using",
        customCell: (r, i) => <td key={i}>{r.paymentMethod}</td>
      },
      {
        id: "max_amount",
        label: "Status",
        customCell: (r, i) => {
          return (
            <td key={i}>
              <span
                className="red"
                style={{
                  maxWidth: "100px",
                  backgroundColor: this.getStatusColor(r.status, r.requires_auth)
                }}
              >
                {r.requires_auth ? "Requires Action" : r.status}
              </span>
            </td>
          );
        }
      }
    ];
    return {
      Pending: parseInt(this.global.franchiseUser.selectedId) === -1 
        ? [{
            id: "org",
            label: "Organization",
            customCell:(r, i) => (
              <td className="table-custom-text">
                {r.company}
              </td>
            )
          }].concat(pending)
        : pending,
      Payments: parseInt(this.global.franchiseUser.selectedId) === -1
        ? [{
            id: "org",
            label: "Organization",
            customCell:(r, i) => (
              <td className="table-custom-text">
                {r.company}
              </td>
            )
          }].concat(payments)
        : payments
    };
  };

  getFilteredOrders() {
    const ordersArr = JSON.parse(JSON.stringify(this.props.data));
    if (ordersArr["Pending"]) {
      ordersArr["Pending"] = ordersArr["Pending"].filter(order => {
        const value = order?.purchaser?.toLowerCase().replace(/\s/g, "");
        const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
        return value.includes(filter);
      });
    }

    if (ordersArr["Payments"]) {
      ordersArr["Payments"] = ordersArr["Payments"].filter(order => {
        const value = order?.purchaser?.toLowerCase().replace(/\s/g, "");
        const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
        return value.includes(filter);
      });
    }

    return ordersArr;
  }

  render() {
    return (
      <div>
        <div
          style={{
            display: "inline-flex",
            flex: "1 1",
            border: "none",
            borderRadius: "10px",
            overflow: "hidden",
            width: "100%"
          }}
        >
          <i
            className="fas fa-search"
            style={{ padding: "8px", background: "white" }}
          ></i>
          <input
            type="text"
            ref={this.inputRef}
            placeholder="Search by purchaser name"
            style={{
              border: "none",
              fontSize: "13px",
              padding: "5px",
              outline: 0,
              background: "white",
              flex: 1
            }}
            value={this.state.filter}
            onChange={e => {
              this.setState({ 
                filter: this.inputRef.current.value,
                page: 0  
              });
            }}
          />
        </div>
        <NewTabbedTable
          data={this.getFilteredOrders()}
          tabs={this.tabs}
          page={"Financials>Recurring Payments"}
          headings={this.headings()}
          currentPage={this.state.page}
        />
      </div>
    );
  }
};

export default FranchiserTrackRecurring;
