import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { Switch } from "@material-ui/core";
import { format, addDays, addMonths } from "date-fns";

class EndEnrollmentModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cancel_at_period_end: false,
      requestedDate: null,
      buttonDisabled: false,
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  cancelThis = async e => {
    this.setState({ buttonDisabled: true });
    e.preventDefault();
    if (!this.state.cancel_at_period_end && !this.state.requestedDate && this.props.program.transaction_id && this.props.program.transaction_id.includes("sub")) {
      toast.error("Please select an option before ending enrollment");
      this.setState({ buttonDisabled: false });
      return;
    }
    let endDate;
    if (this.props.program.manual_invoices) {
      const frequency = this.props.program.recurring_frequency;
      if (frequency === "week") {
        endDate = addDays(
          new Date(this.props.program.createdAt),
          7
        );
      }
      if (frequency === "bi-week") {
        endDate = addDays(
          new Date(this.props.program.createdAt),
          14
        );
      }
      if (frequency === "month") {
        endDate = addMonths(
          new Date(this.props.program.createdAt),
          1
        );
      }
    }

    if (this.props.listingType === "Program") {
      const ep = `${process.env.REACT_APP_API}/partners/programs/end-enrollment`;
      const result = await axios.post(ep, {
        transactionId: this.props.program.transaction_id,
        cancel_at_period_end: this.state.cancel_at_period_end,
        cancel_at: this.state.requestedDate,
        partnerId: this.props.program.partnerId,
        customerId: this.props.program.id,
        isManual: this.props.program.manual_invoices,
        endDate: endDate
      });
      if (result.data.success) toast.success(`Your enrollment was cancelled!`);
      // this.props.update(this.state.requestedDate);
      this.props.onClose();
      return;
    }

    if (this.props.listingType === "Online") {
      const ep = `${process.env.REACT_APP_API}/partners/virtual/end-enrollment`;
      const result = await axios.post(ep, {
        transactionId: this.props.program.transaction_id,
        cancel_at_period_end: this.state.cancel_at_period_end,
        cancel_at: this.state.requestedDate,
        partnerId: this.props.program.partnerId,
        customerId: this.props.program.id,
        isManual: this.props.program.manual_invoices,
        endDate: endDate
      });
      if (result.data.success) toast.success(`Your enrollment was cancelled!`);
      // this.props.update(this.state.requestedDate);
      this.props.onClose();
      return;
    }

    if (this.props.listingType === "Membership") {
      const ep = `${process.env.REACT_APP_API}/partners/memberships/end-enrollment`;
      const result = await axios.post(ep, {
        transactionId: this.props.program.transaction_id,
        cancel_at_period_end: this.state.cancel_at_period_end,
        cancel_at: this.state.requestedDate,
        partnerId: this.props.program.partnerId,
        customerId: this.props.program.id,
        isManual: this.props.program.manual_invoices,
        endDate: endDate
      });
      if (result.data.success) toast.success(`Your enrollment was cancelled!`);
      // this.props.update(this.state.requestedDate);
      this.props.onClose();
      return;
    }
    this.setState({ buttonDisabled: false });
    toast.error("Something went wrong");
  };

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding" style={{ height: "450px" }}>
          <div>
            <>
              <h3 style={{ marginBottom: "20px" }}>End subscription</h3>
              <form onSubmit={this.cancelThis}>
                {
                  this.props.program.transaction_id && this.props.program.transaction_id.includes("sub") ? (
                    this.props.program.manual_invoices ? (
                      <>
                        <div
                          className="form-group"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 0
                          }}
                        >
                          <label
                            htmlFor="displayName"
                            style={{ width: "60%", textAlign: "left" }}
                          >
                            End recurring subscription
                          </label>
                          <div style={{ width: "65%", textAlign: "left" }}>
                            <Switch
                              checked={this.state.cancel_at_period_end}
                              onChange={e =>
                                this.setState({
                                  cancel_at_period_end: e.target.checked
                                })
                              }
                              color="primary"
                            />
                          </div>
                        </div>
                        {this.state.cancel_at_period_end && (
                          <div
                            className="form-group"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: 0
                            }}
                          >
                            <label
                              htmlFor="displayName"
                              style={{ width: "100%", textAlign: "left" }}
                            >
                              This participant is on a monthly recurring plan (with
                              auto-pay). Their latest billing cycle was created on{" "}
                              {format(
                                new Date(
                                  this.props.program?.manualOrder?.createdAt
                                ),
                                "LLLL dd, yyyy"
                              )}
                              . Ending this enrollment will result in this being
                              their last billing cycle.
                            </label>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div
                          className="form-group"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 0
                          }}
                        >
                          <label
                            htmlFor="displayName"
                            style={{ width: "60%", textAlign: "left" }}
                          >
                            End of the current period{" "}
                            <span style={{ fontStyle: "italic" }}>
                        {this.props.program?.stripeSubscription
                          ?.current_period_end
                          ? `(${format(
                            new Date(
                              this.props.program?.stripeSubscription
                                ?.current_period_end * 1000
                            ),
                            "LLLL dd, yyyy"
                          )})`
                          : null}
                      </span>
                          </label>
                          <div style={{ width: "65%", textAlign: "left" }}>
                            <Switch
                              checked={this.state.cancel_at_period_end}
                              onChange={e =>
                                this.setState({
                                  cancel_at_period_end: e.target.checked,
                                  requestedDate: null
                                })
                              }
                              color="primary"
                            />
                          </div>
                        </div>
                        {!this.state.cancel_at_period_end && (
                          <>
                            <label htmlFor="requestedDate">Date Requested *</label>
                            <div className="date-pick-not-allowed">
                              <DatePicker
                                selected={this.state.requestedDate}
                                minDate={addDays(new Date(), 1)}
                                onChange={date =>
                                  this.setState({
                                    cancel_at_period_end: false,
                                    requestedDate: date
                                  })
                                }
                                disabled={true}
                                // filterDate={date => new Date(date).getDay() === 0}
                                dateFormat="MMMM d, yyyy"
                                className="w-100 form-control date-pick-not-allowed"
                                placeholderText="Date Requested"
                                name="requestedDate"
                                id="requestedDate"
                                validation="isNotEmpty"
                                autoComplete="off"
                              />
                            </div>
                          </>
                        )}
                      </>
                    )
                  ) : (
                    // No transaction ID means not autopay (i.e. no Stripe)
                    <label>This user's enrollment will end at the the end of the day today. Proceed?</label>
                  )
                }


                <div className="modal-footer" style={{ marginTop: "100px" }}>
                  <button type="submit" className="btn profile-btn" disabled={this.state.buttonDisabled}>
                    End Enrollment
                  </button>
                </div>
              </form>
            </>
            <IconButton
              style={{ position: "absolute", right: "0", top: "0" }}
              onClick={_ => this.props.onClose()}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EndEnrollmentModal);
