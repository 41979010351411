import React, { useGlobal, useState } from "reactn";
import BillingDetails from "../../../components/modals/AddBillingDetails";
const ProfileForm = props => {
  const [getValues, setValues] = useGlobal("franchiseUser");
  const [billing, setBilling] = useState(false);
  const handChange = e => {
    if (e.target.value.length < 35)
      setValues({
        ...getValues,
        [e.target.name]: e.target.value
      });
  };

  return (
    <div className="box">
      {billing && (
        <BillingDetails
          onClose={_ => setBilling(false)}
          updateCC={_ => props.refreshCC()}
          user={getValues}
        />
      )}
      <div className="bgofbox">
        <div className="header">
          <div className="row">
            <div className="col-md-12">
              <h2>Billing Details</h2>
            </div>
          </div>
        </div>
        <div className="formbody">
          <form>
            {props.card && (
              <>
                <div className="row" style={{ alignItems: "center" }}>
                  <div className="col-md-6 col-5">
                    <h3>Card No: </h3>
                  </div>
                  <div className="col-md-6 col-7">
                    <p style={{ marginBottom: 0 }}>
                      **** **** **** {props.card.last4}
                    </p>
                  </div>
                </div>
                <div className="row" style={{ alignItems: "center" }}>
                  <div className="col-md-6 col-5">
                    <h3>Exp:</h3>
                  </div>
                  <div className="col-md-6 col-7">
                    {props.card.exp_month}/{props.card.exp_year}
                  </div>
                </div>

                <div className="buttons" style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="savebtn"
                    onClick={_ => setBilling(true)}
                  >
                    Edit
                  </button>
                </div>
              </>
            )}
            {!props.card && (
              <>
                <p style={{ margin: "1rem" }}>No card found on file!</p>
                <div className="buttons" style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="savebtn"
                    onClick={_ => setBilling(true)}
                    disabled={getValues.franchiserId ? true : false}
                  >
                    Add a Credit Card
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default ProfileForm;
