import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import "react-nice-dates/build/style.css";
import CC from "../../components/Payments/stripe_component";
import { StripeProvider, Elements } from "react-stripe-elements";
import DatePick from "react-datepicker";
import { withStyles } from "@material-ui/core/styles";
import "./FormPreviewModal.css";

import { ModalTemplate } from "../UI/modalComponents";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { GET_HTML } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

const styles = {
  plan: {
    transition: "all 300ms",
    "&:hover": {
      // transform: "scale(1.001) translateY(-3px)",
      boxShadow: "0 2px 15px 0 rgba(204,204,204,0.5)"
    }
  },
  planSelected: {
    transform: "scale(1.02) translateY(-3px)",
    boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
  },
  tabRoot: {
    color: "gray !important",
    fontFamily: "Montserrat,sans-serif !important",
    fontWeight: "400 !important",
    fontSize: "1.1rem !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "gray !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "gray !important"
    }
  },
  tabSelected: {
    color: "black !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "black !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "black !important"
    }
  },
  tabIndicator: {
    backgroundColor: "#ff6663",
    height: "3px",
    marginTop: "20px"
  },
  input: {
    height: "3em",
    border: "1px solid #E8E8E8",
    borderRadius: "3px",
    fontFamily: "Montserrat, sans-serif !important",
    paddingLeft: "0.5em"
  },
  textArea: {
    height: "3em",
    border: "1px solid #E8E8E8",
    borderRadius: "3px",
    fontFamily: "Montserrat, sans-serif !important",
    paddingLeft: "0.5em"
  },
  datePicker: {
    height: "3em",
    border: "1px solid #E8E8E8",
    width: "100%",
    borderRadius: "3px",
    fontFamily: "Montserrat, sans-serif !important",
    paddingLeft: "0.5em"
  },
  tabsHeadings: {
    height: "50px",
    minHeight: "40px",
    paddingLeft: "15px",
    borderTop: "1px solid gainsboro",
    borderBottom: "1px solid gainsboro",
    "& .MuiTabs-flexContainer": {
      marginLeft: "57px"
    }
  },
  subHeadings: {
    height: "40px",
    minHeight: "30px",
    paddingLeft: "15px",
    display: "flex",
    alignItems: "center",
    "& .MuiTabs-flexContainer": {
      height: "38px",
      marginLeft: "57px"
    },
    "& .PreviewPartner-tabIndicator-5": {
      backgroundColor: "#08009C !important",
      height: "3px !important"
    },
    "& .MuiTab-wrapper": {
      paddingBottom: "10px !important"
    }
  }
};

class FormPreviewModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedAppointmentTimeIndex: null,
      formData: {},
      selectedForm: 0,
      displayAppointmentRequestFormStep: 0,
      active: "Forms",
      forms: []
    };

    this.elements = (
      element,
      label,
      type = undefined,
      width,
      secId,
      required,
      options = [],
      policyOptions
    ) => {
      if (element === "input") {
        if (type === "file") {
          return (
            <div className="file-upload-element">
              <input
                type={type}
                style={{
                  width: width,
                  fontFamily: "Open sans, sans-serif",
                  fontSize: "13px",
                  color: "transparent"
                }}
                className={type !== "file" && "form-input"}
                name={secId + label}
                required={required}
                onChange={e =>
                  type === "file"
                    ? e.target.files.length &&
                      this.uploadFile(e.target.files, e.target.name)
                    : this.setState({
                        formData: {
                          ...this.state.formData,
                          [e.target.name]: e.target.value
                        }
                      })
                }
                required={required}
                value={
                  type === "file"
                    ? ""
                    : this.state.formData[secId + label] || ""
                }
              />
            </div>
          );
        } else {
          return (
            <input
              type={type}
              style={{ width: width }}
              className={type !== "file" && "form-input"}
              style={{
                height: "1.5em",
                width: "250px",
                fontSize: type === "file" ? "initial" : "inherit"
              }}
              name={secId + label}
              required={required}
              value={
                type === "file" ? "" : this.state.formData[secId + label] || ""
              }
            />
          );
        }
      }
      if (element === "donation")
        return (
          <input
            type={type}
            style={{ width: width }}
            className={type !== "file" && "form-input"}
            name={secId + label}
            required={required}
            value={this.state.formData[secId + label] || ""}
          />
        );
      if (element === "Time")
        return (
          <span style={{ width: width, color: "#7272ca", fontSize: "12px" }}>
            Time does not display in preview
          </span>
        );
      if (element === "Appt")
        return (
          <span style={{ width: width, color: "#7272ca", fontSize: "12px" }}>
            Calendar does not display in preview
          </span>
        );
      if (element === "textarea")
        return (
          <textarea
            type={type}
            rows={4}
            name={secId + label}
            style={{ width: width }}
            className={this.props.classes.textArea}
            required={required}
            value={this.state.formData[secId + label] || ""}
          ></textarea>
        );
      if (element === "days") {
        return (
          <div
            name={secId + label}
            className="row days"
            validation={"validDays"}
            style={{ width: width, margin: 0 }}
          >
            <div
              data-day="Monday"
              className={`day-monday day ${
                this.state.formData[secId + label] &&
                this.state[secId + label]["Monday"]
                  ? "selecta"
                  : ""
              }`}
            >
              M
            </div>
            <div
              data-day="Tuesday"
              className={`day-tuesday day ${
                this.state.formData[secId + label] &&
                this.state.formData[secId + label]["Tuesday"]
                  ? "selecta"
                  : ""
              }`}
            >
              Tu
            </div>
            <div
              data-day="Wednesday"
              className={`day-wednesday day ${
                this.state.formData[secId + label] &&
                this.state.formData[secId + label]["Wednesday"]
                  ? "selecta"
                  : ""
              }`}
            >
              W
            </div>
            <div
              data-day="Thursday"
              className={`day-thursday day ${
                this.state.formData[secId + label] &&
                this.state.formData[secId + label]["Thursday"]
                  ? "selecta"
                  : ""
              }`}
            >
              Th
            </div>
            <div
              data-day="Friday"
              className={`day-friday day ${
                this.state.formData[secId + label] &&
                this.state[secId + label]["Friday"]
                  ? "selecta"
                  : ""
              }`}
            >
              F
            </div>
            <div
              data-day="Saturday"
              className={`day-saturday day ${
                this.state.formData[secId + label] &&
                this.state.formData[secId + label]["Saturday"]
                  ? "selecta"
                  : ""
              }`}
            >
              Sa
            </div>
            <div
              data-day="Sunday"
              className={`day-sunday day ${
                this.state.formData[secId + label] &&
                this.state[secId + label]["Sunday"]
                  ? "selecta"
                  : ""
              }`}
            >
              Su
            </div>
          </div>
        );
      }
      if (element === "radios")
        return (
          <div className="form-multiselect" style={{ marginLeft: "5px" }}>
            <span
              style={{
                marginRight: "10px",
                fontSize: "16px",
                fontFamily: "Open sans, sans-serif"
              }}
            >
              <input type="radio" name={secId + label} value="Immediately" />{" "}
              Immediately
            </span>
            <span
              style={{
                marginRight: "10px",
                fontSize: "16px",
                fontFamily: "Open sans, sans-serif"
              }}
            >
              <input type="radio" name={secId + label} value="1-3 Months" /> 1-3
              Months
            </span>
            <span
              style={{
                marginRight: "10px",
                fontSize: "16px",
                fontFamily: "Open sans, sans-serif"
              }}
            >
              <input type="radio" name={secId + label} value="3+ Months" /> 3+
              Months
            </span>
          </div>
        );
      if (element === "Dropdown")
        return (
          <select
            style={{ width: width, fontSize: "14px" }}
            className={"form-input"}
            name={secId + label}
          >
            <option value="" disabled selected>
              Please Select
            </option>
            {options.map(e => (
              <option value={e.value}>{e.value}</option>
            ))}
          </select>
        );
      if (element === "signature")
        return this.state.formData[secId + label] ? (
          <img src={this.state.formData[secId + label]} />
        ) : (
          <a
            href="#/"
            style={{
              border: "1px solid gainsboro",
              padding: "10px",
              fontSize: "16px !important",
              width: "150px",
              fontFamily: "Open sans, sans-serif"
            }}
            className="button-39"
            id="preview-modal-signature"
          >
            Draw Signature
          </a>
        );
      if (element === "Multiple Choices")
        return (
          <div className="form-multiselect" style={{ marginLeft: "5px" }}>
            {options.map(e => (
              <div style={{ marginRight: "10px", fontSize: "14px" }}>
                <input
                  type="radio"
                  value={e.value}
                  name={secId + label}
                  style={{ margin: "0px 5px" }}
                />
                {e.value}
              </div>
            ))}
          </div>
        );
      if (element === "Checkboxes")
        return (
          <div className="form-multiselect checkbox-wrapper-1">
            {options.map(e => (
              <div style={{ marginRight: "10px", fontSize: "14px" }}>
                <input
                  type="checkbox"
                  value={e.value}
                  name={secId + label}
                  id={secId + label}
                  style={{ margin: "0px 5px" }}
                  className="substituted"
                />
                <label
                  htmlFor={secId + label}
                  style={{
                    marginTop: "8px",
                    fontFamily: "'Open-sans', sans-serif"
                  }}
                >
                  {e.value}
                </label>
              </div>
            ))}
          </div>
        );
      if (element === "DOB")
        return (
          <DatePick
            className="w-100 date-input-border"
            showYearDropdown
            showMonthDropdown
            dateFormat="MMMM d, yyyy"
            name={label}
            selected={this.state.formData[label]}
          />
        );
      if (element === "card")
        return (
          <div style={{ width: "350px", marginTop: "15px" }}>
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE}>
              <Elements>
                <CC />
              </Elements>
            </StripeProvider>
          </div>
        );

      if (element === "Policy") {
        const { isFileUpload, file, policyText, checkboxText, link } =
          policyOptions;
        const element = (
          <>
            {policyText}
            <br />
            {(isFileUpload || link) && (
              <a
                href={
                  isFileUpload
                    ? `${process.env.REACT_APP_DS_FILES_S3}/${file}`
                    : link
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                View Policy
              </a>
            )}
          </>
        );
        return (
          <div className="policy-container">
            <p className="policy" style={{ fontSize: "16px" }}>
              {element}
            </p>
            <div style={{ marginTop: "4px", display: "flex" }}>
              <input
                type="checkbox"
                id="confirmText"
                name="confirmText"
                style={{ marginRight: "10px" }}
              />
              <label
                htmlFor="confirmText"
                style={{ fontSize: "16px", position: "relative" }}
              >
                {checkboxText}
              </label>
            </div>
          </div>
        );
      }
    };
  }

  // unused function? 6/2/2021
  // handleTimeClick(element, i, secId, label) {
  //   this.setState({
  //     formData: {
  //       ...this.state.formData,
  //       [secId + label]: new Date(
  //         new Date(this.state.formData[secId + "Appointment Date"]).setHours(
  //           new Date(element).getHours()
  //         )
  //       )
  //     },
  //     selectedAppointmentTimeIndex: i
  //   });
  // }

  getFields(fieldarr, form_section_name) {
    let fields = fieldarr.filter(e => JSON.parse(e).visible);
    if (form_section_name === "Appointment Request") {
      let parsedFields = fields.map(e => JSON.parse(e));
      let indexOfFieldAppt;
      let indexOfFieldTime;
      const findIndex = parsedFields.map((e, i) => {
        if (e.field === "Appt") {
          indexOfFieldAppt = i;
        }
        if (e.field === "Time") {
          indexOfFieldTime = i;
        }
      });
      const ApptDate = parsedFields.splice(indexOfFieldAppt, 1);
      const ApptTime = parsedFields.splice(indexOfFieldTime - 1, 1);

      parsedFields.splice(0, 0, ApptTime[0]);
      parsedFields.splice(0, 0, ApptDate[0]);

      const stringifyFields = parsedFields.map(e => JSON.stringify(e));

      fields = stringifyFields;
    }

    const arr = [];
    for (let i = 0; i < fields.length; i += 2) {
      if (i + 1 < fields.length) {
        arr.push([fields[i], fields[i + 1]]);
        continue;
      }
      if (i < fields.length) {
        arr.push([fields[i]]);
      }
      if (i >= fields.length) {
        break;
      }
    }
    return arr;
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/organization_profile/previewForms`;
    this.setGlobal({ loading: true });
    const results = await axios.get(ep);

    if (results.data.success) {
      this.setState({
        form: results.data.data.forms.find(
          f => f.id === parseInt(this.props.selectedFormId)
        )
      });
    } else {
      toast.error("Something went wrong when getting form data.");
    }
    this.setGlobal({ loading: false });
  }

  formContent = () => {
    return (
      <>
        {this.state.form && (
          <form style={{ marginTop: "15px", width: "100%" }}>
            {this.state.form.sections
              .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
              .map((sec, secIndex) => {
                const formFields = this.props.fields
                  ? this.props.fields.filter(
                      formName =>
                        formName.form_section_name === sec.form_section_name
                    )
                  : null;

                return (
                  <div
                    style={{ width: "100%", paddingRight: "1em" }}
                    key={secIndex}
                  >
                    <h3
                      style={{
                        margin: 0,
                        fontFamily: "futura-pt, sans-serif",
                        fontSize: "1.6rem",
                        marginBottom: "10px",
                        marginTop: "20px",
                        fontWeight: "500",
                        color: "#626262"
                      }}
                    >
                      {sec.form_section_name}
                    </h3>

                    {sec.form_section_name === "Appointment Request"
                      ? this.getFields(sec.fields, sec.form_section_name).map(
                          (row, i) => {
                            // First Renders the appointment date and time
                            if (
                              this.state.displayAppointmentRequestFormStep ===
                                0 &&
                              i === 0
                            ) {
                              return (
                                <div
                                  className="appointment-line-container"
                                  key={i}
                                >
                                  <div className="appointment-calendar-box">
                                    {row.map((f, fIndex) => {
                                      const field = JSON.parse(f);
                                      return (
                                        <div
                                          className="appointment-form-container"
                                          key={fIndex}
                                        >
                                          {field.type === "file"
                                            ? this.state.formData[
                                                sec.id + field.first_label
                                              ]
                                              ? "File Uploaded"
                                              : this.elements(
                                                  field.field,
                                                  field.first_label,
                                                  field.type,
                                                  "100%",
                                                  sec.id,
                                                  field.required,
                                                  field.policyOptions
                                                )
                                            : this.elements(
                                                field.field,
                                                field.first_label,
                                                field.type,
                                                "100%",
                                                sec.id,
                                                field.required,
                                                field.options,
                                                field.policyOptions
                                              )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div className="appointment-nxt-btn">
                                    <div
                                      style={{
                                        border: "none",
                                        fontFamily: "Open Sans, sans-serif",
                                        padding: "0.5em 2.5em",
                                        fontWeight: "700",
                                        backgroundColor: "rgb(32 63 167)",
                                        fontSize: "0.9em",
                                        color: "white",
                                        marginBottom: "10px",
                                        cursor: "pointer",
                                        marginTop: "35px"
                                      }}
                                      onClick={_ => {
                                        this.setState({
                                          displayAppointmentRequestFormStep: 1
                                        });
                                      }}
                                    >
                                      Next
                                    </div>
                                  </div>
                                </div>
                              );
                            } else if (
                              // Then Renders the other fields in the next step
                              this.state.displayAppointmentRequestFormStep ===
                                1 &&
                              i !== 0
                            ) {
                              if (row.length === 2 && i === 2) {
                                // renders the item when the getfields array has a length of 1
                                return (
                                  <div>
                                    <div
                                      // style={{
                                      //   display: "flex",
                                      //   alignItems: "center"
                                      // }}
                                      className="line-container"
                                    >
                                      {row.map(f => {
                                        const field = JSON.parse(f);

                                        return (
                                          <div
                                            // style={{
                                            //   width: "100%",
                                            //   alignItems: "center",
                                            //   marginBottom: "20px",
                                            //   padding: "0 30px 0 30px"
                                            // }}
                                            className="form-container"
                                          >
                                            <label
                                              htmlFor={field.first_label}
                                              style={{
                                                color: "#696868",
                                                margin: "0 0px 0px 0px",
                                                fontWeight: "700",
                                                width: "175px",
                                                fontSize: "0.6em",
                                                fontFamily:
                                                  "Open Sans, sans-serif"
                                              }}
                                            >
                                              {field.first_label}
                                              {field.required && (
                                                <span
                                                  style={{
                                                    color: "orange"
                                                  }}
                                                >
                                                  *
                                                </span>
                                              )}
                                            </label>
                                            {this.elements(
                                              field.field,
                                              field.first_label,
                                              field.type,
                                              "100%",
                                              sec.id,
                                              field.required,
                                              field.options,
                                              field.policyOptions
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                        padding: "0 30px 0 30px"
                                      }}
                                    >
                                      <div
                                        style={{
                                          border: "none",
                                          fontFamily: "Open Sans, sans-serif",
                                          padding: "0.7rem 2.5rem",
                                          fontWeight: "700",
                                          backgroundColor: "rgb(32 63 167)",
                                          fontSize: "0.9rem",
                                          color: "white",
                                          marginRight: "10px",
                                          cursor: "pointer"
                                        }}
                                        onClick={_ => {
                                          this.setState({
                                            displayAppointmentRequestFormStep: 0
                                          });
                                        }}
                                      >
                                        Back
                                      </div>
                                      <input
                                        type="submit"
                                        style={{
                                          border: "none",
                                          fontFamily: "Montserrat, sans-serif",
                                          padding: "0.5rem 2.5rem",
                                          fontWeight: "700",
                                          backgroundColor: "rgb(32 63 167)",
                                          fontSize: "0.9rem",
                                          color: "white"
                                        }}
                                      />
                                    </div>
                                  </div>
                                );
                              } else {
                                // Renders when the array has length greater than 1
                                return (
                                  <div className="line-container">
                                    {row.map((f, fIndex) => {
                                      const field = JSON.parse(f);
                                      return (
                                        <div
                                          className="form-container"
                                          key={fIndex}
                                        >
                                          <label
                                            style={{
                                              color: "#696868",
                                              margin: "0 0px 0px 0px",
                                              fontWeight: "700",
                                              width: "100%",
                                              fontSize: "0.6em",
                                              fontFamily:
                                                "Open Sans, sans-serif"
                                            }}
                                            htmlFor={field.first_label}
                                          >
                                            {field.first_label}{" "}
                                            {field.required && (
                                              <span
                                                style={{
                                                  color: "red"
                                                }}
                                              >
                                                *
                                              </span>
                                            )}
                                          </label>
                                          {field.type === "file"
                                            ? this.state.formData[
                                                sec.id + field.first_label
                                              ]
                                              ? "File Uploaded"
                                              : this.elements(
                                                  field.field,
                                                  field.first_label,
                                                  field.type,
                                                  "100%",
                                                  sec.id,
                                                  field.required,
                                                  field.policyOptions
                                                )
                                            : this.elements(
                                                field.field,
                                                field.first_label,
                                                field.type,
                                                "100%",
                                                sec.id,
                                                field.required,
                                                field.options,
                                                field.policyOptions
                                              )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              }
                            }
                          }
                        )
                      : this.props.fields
                      ? this.getFields(
                          formFields[0].fields,
                          formFields[0].form_section_name
                        ).map((row, rowIndex) =>
                          row.length > 1 ? (
                            <div className="line-container" key={rowIndex}>
                              {row.map((f, fIndex) => {
                                const field = JSON.parse(f); // This section renders the forms which are not appointments
                                return (
                                  <div
                                    className="form-container"
                                    style={{ width: "100%" }}
                                    key={fIndex}
                                  >
                                    <label
                                      style={{
                                        color: "#696868",
                                        margin: "0 0px 0px 0px",
                                        fontWeight: "700",
                                        width: "100%",
                                        fontSize: "0.6em",
                                        fontFamily: "Open Sans, sans-serif"
                                      }}
                                      htmlFor={field.first_label}
                                    >
                                      {field.first_label}{" "}
                                      {field.required && (
                                        <span style={{ color: "red" }}>*</span>
                                      )}
                                    </label>
                                    {field.type === "file"
                                      ? this.state.formData[
                                          sec.id + field.first_label
                                        ]
                                        ? "File Uploaded"
                                        : this.elements(
                                            field.field,
                                            field.first_label,
                                            field.type,
                                            "100%",
                                            sec.id,
                                            field.required,
                                            field.policyOptions
                                          )
                                      : this.elements(
                                          field.field,
                                          field.first_label,
                                          field.type,
                                          "100%",
                                          sec.id,
                                          field.required,
                                          field.options,
                                          field.policyOptions
                                        )}
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            // This section renders the forms which are not appointments and have an array length of 1
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              {row.map((f, fIndex) => {
                                const field = JSON.parse(f);
                                return (
                                  <div
                                    style={{
                                      width: "100%",
                                      alignItems: "center",
                                      marginBottom: "20px",
                                      padding: "0 30px 0 30px"
                                    }}
                                    key={fIndex}
                                  >
                                    <label
                                      htmlFor={field.first_label}
                                      style={{
                                        color: "#696868",
                                        margin: "0 0px 0px 0px",
                                        fontWeight: "700",
                                        width: "175px",
                                        fontSize: "0.6em",
                                        fontFamily: "Open Sans, sans-serif"
                                      }}
                                    >
                                      {field.first_label}
                                      {field.required && (
                                        <span style={{ color: "red" }}>*</span>
                                      )}
                                    </label>
                                    {this.elements(
                                      field.field,
                                      field.first_label,
                                      field.type,
                                      "100%",
                                      sec.id,
                                      field.required,
                                      field.options,
                                      field.policyOptions
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          )
                        )
                      : this.getFields(sec.fields, sec.form_section_name).map(
                          (row, rowIndex) =>
                            row.length > 1 ? (
                              <div className="line-container" key={rowIndex}>
                                {row.map((f, fIndex) => {
                                  const field = JSON.parse(f); // This section renders the forms which are not appointments
                                  return (
                                    <div
                                      className="form-container"
                                      style={{ width: "100%" }}
                                      key={fIndex}
                                    >
                                      <label
                                        style={{
                                          color: "#696868",
                                          margin: "0 0px 0px 0px",
                                          fontWeight: "700",
                                          width: "100%",
                                          fontSize: "0.6em",
                                          fontFamily: "Open Sans, sans-serif"
                                        }}
                                        htmlFor={field.first_label}
                                      >
                                        {field.first_label}{" "}
                                        {field.required && (
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        )}
                                      </label>
                                      {field.type === "file"
                                        ? this.state.formData[
                                            sec.id + field.first_label
                                          ]
                                          ? "File Uploaded"
                                          : this.elements(
                                              field.field,
                                              field.first_label,
                                              field.type,
                                              "100%",
                                              sec.id,
                                              field.required,
                                              field.policyOptions
                                            )
                                        : this.elements(
                                            field.field,
                                            field.first_label,
                                            field.type,
                                            "100%",
                                            sec.id,
                                            field.required,
                                            field.options,
                                            field.policyOptions
                                          )}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              // This section renders the forms which are not appointments and have an array length of 1
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                {row.map((f, fIndex) => {
                                  const field = JSON.parse(f);
                                  return (
                                    <div
                                      style={{
                                        width: "100%",
                                        alignItems: "center",
                                        marginBottom: "20px",
                                        padding: "0 30px 0 30px"
                                      }}
                                      key={fIndex}
                                    >
                                      <label
                                        htmlFor={field.first_label}
                                        style={{
                                          color: "#696868",
                                          margin: "0 0px 0px 0px",
                                          fontWeight: "700",
                                          width: "175px",
                                          fontSize: "0.6em",
                                          fontFamily: "Open Sans, sans-serif"
                                        }}
                                      >
                                        {field.first_label}
                                        {field.required && (
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        )}
                                      </label>
                                      {this.elements(
                                        field.field,
                                        field.first_label,
                                        field.type,
                                        "100%",
                                        sec.id,
                                        field.required,
                                        field.options,
                                        field.policyOptions
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            )
                        )}
                  </div>
                );
              })}
          </form>
        )}
      </>
    );
  };

  getBaseModalFields = () => {
    const formField = {
      type: GET_HTML,
      data: {
        gethtml: this.formContent
      }
    };

    const fields = [];

    fields.push(formField);

    return fields;
  };

  getBaseModalButtons = () => {
    const submitButton = {
      name: this.state.form.form_name === "Donation" ? "Donate" : "Submit",
      handleClick: () => {},
      buttonColour: BLUE_BUTTON,
      textColour: WHITE
    };

    return [submitButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Form Preview",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      width: "680px",
      maxWidth: "90vw",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    // If form doesn't exist for some reason, do not render anything.
    if (!this.state.form) {
      return null;
    }
    return <BaseModal {...this.getBaseModalProps()} />;

    const footerContent = this.state.form.form_name !== "Appointments" && (
      <button className="btn">
        {this.state.form.form_name === "Donation" ? "Donate" : "Submit"}
      </button>
    );

    return (
      <ModalTemplate
        heading="Form Preview"
        onClose={this.props.onClose}
        style={{ maxWidth: "650px" }}
        footerContent={footerContent}
      >
        {this.state.form && (
          <form style={{ marginTop: "15px", width: "100%" }}>
            {this.state.form.sections
              .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
              .map((sec, secIndex) => (
                <div
                  style={{ width: "100%", paddingRight: "1em" }}
                  key={secIndex}
                >
                  <h3
                    style={{
                      margin: 0,
                      color: "rgb(80, 80, 80)",
                      fontFamily: "Open Sans, sans-serif",
                      paddingLeft: "30px",
                      marginBottom: "10px"
                    }}
                  >
                    {sec.form_section_name}
                  </h3>

                  {sec.form_section_name === "Appointment Request"
                    ? this.getFields(sec.fields, sec.form_section_name).map(
                        (row, i) => {
                          // First Renders the appointment date and time
                          if (
                            this.state.displayAppointmentRequestFormStep ===
                              0 &&
                            i === 0
                          ) {
                            return (
                              <div
                                className="appointment-line-container"
                                key={i}
                              >
                                <div className="appointment-calendar-box">
                                  {row.map((f, fIndex) => {
                                    const field = JSON.parse(f);
                                    return (
                                      <div
                                        className="appointment-form-container"
                                        key={fIndex}
                                      >
                                        {field.type === "file"
                                          ? this.state.formData[
                                              sec.id + field.first_label
                                            ]
                                            ? "File Uploaded"
                                            : this.elements(
                                                field.field,
                                                field.first_label,
                                                field.type,
                                                "100%",
                                                sec.id,
                                                field.required,
                                                field.policyOptions
                                              )
                                          : this.elements(
                                              field.field,
                                              field.first_label,
                                              field.type,
                                              "100%",
                                              sec.id,
                                              field.required,
                                              field.options,
                                              field.policyOptions
                                            )}
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="appointment-nxt-btn">
                                  <div
                                    style={{
                                      border: "none",
                                      fontFamily: "Open Sans, sans-serif",
                                      padding: "0.5em 2.5em",
                                      fontWeight: "700",
                                      backgroundColor: "rgb(32 63 167)",
                                      fontSize: "0.9em",
                                      color: "white",
                                      marginBottom: "10px",
                                      cursor: "pointer",
                                      marginTop: "35px"
                                    }}
                                    onClick={_ => {
                                      this.setState({
                                        displayAppointmentRequestFormStep: 1
                                      });
                                    }}
                                  >
                                    Next
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (
                            // Then Renders the other fields in the next step
                            this.state.displayAppointmentRequestFormStep ===
                              1 &&
                            i !== 0
                          ) {
                            if (row.length === 2 && i === 2) {
                              // renders the item when the getfields array has a length of 1
                              return (
                                <div>
                                  <div
                                    // style={{
                                    //   display: "flex",
                                    //   alignItems: "center"
                                    // }}
                                    className="line-container"
                                  >
                                    {row.map(f => {
                                      const field = JSON.parse(f);

                                      return (
                                        <div
                                          // style={{
                                          //   width: "100%",
                                          //   alignItems: "center",
                                          //   marginBottom: "20px",
                                          //   padding: "0 30px 0 30px"
                                          // }}
                                          className="form-container"
                                        >
                                          <label
                                            htmlFor={field.first_label}
                                            style={{
                                              color: "#696868",
                                              margin: "0 0px 0px 0px",
                                              fontWeight: "700",
                                              width: "175px",
                                              fontSize: "0.6em",
                                              fontFamily:
                                                "Open Sans, sans-serif"
                                            }}
                                          >
                                            {field.first_label}
                                            {field.required && (
                                              <span
                                                style={{
                                                  color: "orange"
                                                }}
                                              >
                                                *
                                              </span>
                                            )}
                                          </label>
                                          {this.elements(
                                            field.field,
                                            field.first_label,
                                            field.type,
                                            "100%",
                                            sec.id,
                                            field.required,
                                            field.options,
                                            field.policyOptions
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      width: "100%",
                                      padding: "0 30px 0 30px"
                                    }}
                                  >
                                    <div
                                      style={{
                                        border: "none",
                                        fontFamily: "Open Sans, sans-serif",
                                        padding: "0.7rem 2.5rem",
                                        fontWeight: "700",
                                        backgroundColor: "rgb(32 63 167)",
                                        fontSize: "0.9rem",
                                        color: "white",
                                        marginRight: "10px",
                                        cursor: "pointer"
                                      }}
                                      onClick={_ => {
                                        this.setState({
                                          displayAppointmentRequestFormStep: 0
                                        });
                                      }}
                                    >
                                      Back
                                    </div>
                                    <input
                                      type="submit"
                                      style={{
                                        border: "none",
                                        fontFamily: "Montserrat, sans-serif",
                                        padding: "0.5rem 2.5rem",
                                        fontWeight: "700",
                                        backgroundColor: "rgb(32 63 167)",
                                        fontSize: "0.9rem",
                                        color: "white"
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            } else {
                              // Renders when the array has length greater than 1
                              return (
                                <div className="line-container">
                                  {row.map((f, fIndex) => {
                                    const field = JSON.parse(f);
                                    return (
                                      <div
                                        className="form-container"
                                        key={fIndex}
                                      >
                                        <label
                                          style={{
                                            color: "#696868",
                                            margin: "0 0px 0px 0px",
                                            fontWeight: "700",
                                            width: "200px",
                                            fontSize: "0.6em",
                                            fontFamily: "Open Sans, sans-serif"
                                          }}
                                          htmlFor={field.first_label}
                                        >
                                          {field.first_label}{" "}
                                          {field.required && (
                                            <span
                                              style={{
                                                color: "orange"
                                              }}
                                            >
                                              *
                                            </span>
                                          )}
                                        </label>
                                        {field.type === "file"
                                          ? this.state.formData[
                                              sec.id + field.first_label
                                            ]
                                            ? "File Uploaded"
                                            : this.elements(
                                                field.field,
                                                field.first_label,
                                                field.type,
                                                "100%",
                                                sec.id,
                                                field.required,
                                                field.policyOptions
                                              )
                                          : this.elements(
                                              field.field,
                                              field.first_label,
                                              field.type,
                                              "100%",
                                              sec.id,
                                              field.required,
                                              field.options,
                                              field.policyOptions
                                            )}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            }
                          }
                        }
                      )
                    : this.getFields(sec.fields, sec.form_section_name).map(
                        (row, rowIndex) =>
                          row.length > 1 ? (
                            <div className="line-container" key={rowIndex}>
                              {row.map((f, fIndex) => {
                                const field = JSON.parse(f); // This section renders the forms which are not appointments
                                return (
                                  <div className="form-container" key={fIndex}>
                                    <label
                                      style={{
                                        color: "#696868",
                                        margin: "0 0px 0px 0px",
                                        fontWeight: "700",
                                        width: "200px",
                                        fontSize: "0.6em",
                                        fontFamily: "Open Sans, sans-serif"
                                      }}
                                      htmlFor={field.first_label}
                                    >
                                      {field.first_label}{" "}
                                      {field.required && (
                                        <span style={{ color: "orange" }}>
                                          *
                                        </span>
                                      )}
                                    </label>
                                    {field.type === "file"
                                      ? this.state.formData[
                                          sec.id + field.first_label
                                        ]
                                        ? "File Uploaded"
                                        : this.elements(
                                            field.field,
                                            field.first_label,
                                            field.type,
                                            "100%",
                                            sec.id,
                                            field.required,
                                            field.policyOptions
                                          )
                                      : this.elements(
                                          field.field,
                                          field.first_label,
                                          field.type,
                                          "100%",
                                          sec.id,
                                          field.required,
                                          field.options,
                                          field.policyOptions
                                        )}
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            // This section renders the forms which are not appointments and have an array length of 1
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              {row.map((f, fIndex) => {
                                const field = JSON.parse(f);

                                return (
                                  <div
                                    style={{
                                      width: "100%",
                                      alignItems: "center",
                                      marginBottom: "20px",
                                      padding: "0 30px 0 30px"
                                    }}
                                    key={fIndex}
                                  >
                                    <label
                                      htmlFor={field.first_label}
                                      style={{
                                        color: "#696868",
                                        margin: "0 0px 0px 0px",
                                        fontWeight: "700",
                                        width: "175px",
                                        fontSize: "0.6em",
                                        fontFamily: "Open Sans, sans-serif"
                                      }}
                                    >
                                      {field.first_label}
                                      {field.required && (
                                        <span style={{ color: "orange" }}>
                                          *
                                        </span>
                                      )}
                                    </label>
                                    {this.elements(
                                      field.field,
                                      field.first_label,
                                      field.type,
                                      "100%",
                                      sec.id,
                                      field.required,
                                      field.options,
                                      field.policyOptions
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          )
                      )}
                </div>
              ))}
          </form>
        )}
      </ModalTemplate>
    );
  }
}

export default withStyles(styles)(FormPreviewModal);
