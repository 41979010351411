import Validators from "../../../validators";

export function validateTechPage(online_global) {
  if (online_global.manual_zoom) {
    return Validators.Partners.Profile.ValidateVirtualManual(online_global);
  }

  return !(online_global.maximum_participants === null ||
    online_global.maximum_participants <= 0 ||
    online_global.maximum_participants > 200);


}
