import axios from "axios";

import { typeOptions } from "./promotion.context";

export async function sendPromotionData(state) {
  if (state === undefined || state === null) {
    throw new Error("The Promotion state is not defined");
  }

  const ep = `${process.env.REACT_APP_API}/partners/promotions/create`;
  const filter_promotion_type_list = {
    ...state,
    promotion_type_list: state.promotion_type_list.filter(p => {
      // need to be ints since selectedPromotion in CreatePromotionListModal.jsx
      // might be a string
      return parseInt(p.id) === parseInt(state.selectedPromotion);
    })
  };
  const resp = await axios.post(ep, filter_promotion_type_list);
  console.log("resp", resp);

  if (resp.data) {
    return resp.data;
  } else {
    return {
      success: false,
      message: "Something went wrong when sending data"
    };
  }
}
export async function updatePromotion(state) {
  const ep = `${process.env.REACT_APP_API}/partners/promotions/update`;
  const data = {
    title: state.title,
    description: state.description,
    id: state.id,
    show_promo_only: state.show_promo_only
  };
  const resp = axios.post(ep, data);
  console.log("resp", resp);
  return resp;
}

function isValidTypeValue(typeValue) {
  for (let i = 0; i < typeOptions.length; i++) {
    if (typeValue === typeOptions[i].value) {
      return true;
    }
  }
  return false;
}
export async function getPromotionTypeValuesBasedOnType(typeValue) {
  if (typeValue === "") return [];
  if (!typeValue) {
    throw new Error("typeValue is a required argument");
  }

  if (!isValidTypeValue(typeValue)) {
    throw new Error("invalid Type Value");
  }
  // const validLists = ['program', 'event', 'membership', 'online']
  if (typeValue === "program") {
    const ep = `${process.env.REACT_APP_API}/partners/programs/list`;
    const resp = await axios.get(ep);
    if (resp.data) {
      return resp.data;
    }
    return { success: false, message: "Could not get list of Programs" };
  } else if (typeValue === "event") {
    const ep = `${process.env.REACT_APP_API}/partners/events/list`;
    const resp = await axios.get(ep);
    if (resp.data) {
      return resp.data;
    }
    return { success: false, message: "Could not get list of Events" };
  } else if (typeValue === "party") {
    const ep = `${process.env.REACT_APP_API}/partners/parties/list`;
    const resp = await axios.get(ep);
    if (resp.data) {
      return resp.data;
    }
    return { success: false, message: "Could not get list of Parties" };
  } else if (typeValue === "membership") {
    const ep = `${process.env.REACT_APP_API}/partners/memberships/list`;
    const resp = await axios.get(ep);
    if (resp.data) {
      return resp.data;
    }
    return { success: false, message: "Could not get list of memberships" };
  } else if (typeValue === "online") {
    const ep = `${process.env.REACT_APP_API}/partners/virtual/list`;
    const resp = await axios.get(ep);
    if (resp.data) {
      return resp.data;
    }
    return { success: false, message: "Could not get list of Virtual" };
  } else {
    return { success: false, message: "a error orrcured" };
  }
}

export function displayPromotionTypeListData(type, item) {
  if (type === "") {
    return "";
  }
  if (!type) {
    throw new Error("Type is a required argument");
  }
  if (!item) {
    throw new Error("Item is a required argument");
  }
  if (type === "program") {
    return item.program_name;
  } else if (type === "event") {
    // if (!item.event_title) {
    //     throw new Error('event_title is not a key of item parameter')
    // }
    return item.event_title;
  } else if (type === "party") {
    // if (!item.event_title) {
    //     throw new Error('event_title is not a key of item parameter')
    // }
    return item.party_title;
  } else if (type === "membership") {
    // if (!item.membership_name) {
    //     throw new Error('membership_name is not a key of item parameter')
    // }
    return item.membership_name;
  } else if (type === "online") {
    return item.program_name;
  }
  return "invalid";
}
