import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import { MemberShip1, MemberShip2, MiniPlus } from "../../../../assets";
import { Switch } from "@material-ui/core";
import ImageCropModal from "../../../../components/modals/ImageCropModal";
import ReactTooltip from "react-tooltip";
class SectionOne extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openImageCropModal: false,
      image: ""
    };
    this.customForm = this.customForm.bind(this);
  }
  handleChange = (e, v) => {
    if (e === "max_amount" && v === "") {
      v = null;
    }

    this.setGlobal({
      membership: {
        ...this.global.membership,
        manual_invoices: this.props.initialManualInvoices,
        application_required: this.props.initialApplicationRequired,
        allow_same_enrollment: this.props.allowSameEnrollment,
        [e]: v
      }
    });
  };

  uploadNew() {
    const uploadEle = document.getElementById("uploadPhoto");
    uploadEle.click();
  }

  fileChange(e) {
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      const file = new FileReader();
      file.readAsDataURL(e.target.files[0]);
      file.onloadend = () => {
        document.getElementById("uploadPhoto").value = "";
        this.setState({ image: file.result, openImageCropModal: true });
      };
    }
  }

  // Loading the initial membership manualInvoices from the create membership modal
  componentDidMount() {
    // this.handleChange("manual_invoices", this.props.initialManualInvoices);
    (this.props.initialManualInvoices ||
      this.props.initialApplicationRequired) &&
      this.setGlobal(
        {
          membership: {
            ...this.global.membership,
            manual_invoices: this.props.initialManualInvoices,
            application_required: this.props.initialApplicationRequired,
            allow_same_enrollment: this.props.allowSameEnrollment
          }
        }
        // async () => await this.dispatch.saveMembership()
      );
    // await this.dispatch.saveMembership();
  }

  customForm = () => {
    return (
      <div className="formbody">
        {this.state.openImageCropModal && (
          <ImageCropModal
            sourceImage={this.state.image}
            onClose={() => {
              this.setState({ openImageCropModal: false });
            }}
            aspectRatio={721 / 434}
            uploadImage={croppedImageURL => {
              const fd = new FormData();
              fd.set("image", croppedImageURL);
              this.dispatch.uploadMembershipPhoto(fd);
              this.setState({ openImageCropModal: false });
            }}
          />
        )}
        <form>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Name<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  className="input-fields"
                  name="membership_name"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.membership.membership_name}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Membership Type<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <formTypes.MemberShipSelect
                  value={
                    this.global.membership.membership_type.map(e =>
                      typeof e === "string" ? JSON.parse(e) : e
                    ) || []
                  }
                  onChange={(_, value) =>
                    this.handleChange(
                      "membership_type",
                      value.map(e => ({
                        name: e.name
                      }))
                    )
                  }
                ></formTypes.MemberShipSelect>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Max # of Memberships</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  className="input-fields"
                  type="number"
                  name="max_amount"
                  onChange={e =>
                    this.handleChange(
                      e.target.name,
                      e.target.value < 1 ? null : e.target.value
                    )
                  }
                  value={
                    this.global.membership.max_amount !== null &&
                    this.global.membership.max_amount < 1
                      ? 1
                      : parseInt(this.global.membership.max_amount)
                  }
                  min="1"
                  step="1"
                />
              </div>
              {console.log("MAX MEMBERSHIP", this.global.membership.max_amount)}
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Show Max # of Memberships</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <Switch
                  name="show_max_amount"
                  checked={!!this.global.membership.show_max_amount}
                  onClick={_ =>
                    this.handleChange(
                      "show_max_amount",
                      !this.global.membership.show_max_amount
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Enable Waitlist{" "}
                <i
                  data-tip
                  data-for="enable_waitlist"
                  className="fas fa-info-circle"
                  style={{ color: "#DCDCDC" }}
                ></i>
                <ReactTooltip
                  id="enable_waitlist"
                  place="right"
                  effect="solid"
                  type="info"
                >
                  Customers will be waitlisted once all total spots get filled
                </ReactTooltip>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className="w-100">
                <Switch
                  disabled={!this.global.membership.max_amount}
                  name="enable_waitlist"
                  checked={!!this.global.membership.enable_waitlist}
                  onClick={_ => {
                    this.handleChange(
                      "enable_waitlist",
                      !this.global.membership.enable_waitlist
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="label-names">Membership Image:</h3>
            </div>
            <div
              className="col-12 d-flex"
              style={{ justifyContent: "space-evenly" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "30%"
                }}
              >
                <input
                  type="checkbox"
                  style={{ marginBottom: "10px" }}
                  name="membership_image"
                  onChange={e => this.handleChange(e.target.name, 0)}
                  checked={this.global.membership.membership_image === 0}
                />
                <img
                  src={MemberShip1}
                  style={{ width: "100%", height: "100%" }}
                  alt="Membership image"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "30%"
                }}
              >
                <input
                  type="checkbox"
                  name="membership_image"
                  style={{ marginBottom: "10px" }}
                  onChange={e => this.handleChange(e.target.name, 1)}
                  checked={this.global.membership.membership_image === 1}
                />
                <img
                  src={MemberShip2}
                  style={{ width: "100%", height: "100%" }}
                  alt="Membership image"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "30%"
                }}
              >
                <input
                  type="checkbox"
                  name="membership_image"
                  style={{ marginBottom: "10px" }}
                  onChange={e => this.handleChange(e.target.name, 2)}
                  checked={this.global.membership.membership_image === 2}
                  disabled={
                    this.global.membership.membership_image_url === null
                  }
                />
                {this.global.membership.membership_image_url ? (
                  <img
                    src={`${process.env.REACT_APP_DS_FILES_S3}/${this.global.membership.membership_image_url}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "5px"
                    }}
                    alt="Membership image"
                    onClick={this.uploadNew}
                  />
                ) : (
                  <div
                    onClick={this.uploadNew}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "auto",
                      aspectRatio: "721 / 434",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      padding: "10px",
                      textAlign: "center",
                      cursor: "pointer",
                      userSelect: "none"
                    }}
                  >
                    Upload new image
                  </div>
                )}
              </div>
            </div>
          </div>
          <input
            type="file"
            style={{ display: "none" }}
            id="uploadPhoto"
            onChange={e => this.fileChange(e)}
            accept="image/x-png,image/gif,image/jpeg"
          />
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Set-Up"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionOne;
