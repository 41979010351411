/**
 * Code in this file is shared between reports/analytics and financials.
 */

const CENTS_IN_DOLLAR = 100;
const CREDIT_CARD_FEE = 0.015;

/**
 * This function probably standardizes some object properties so that all listings are more uniform?
 *
 * Used as a helper by the rest of the functions in this file.
 *
 * @returns {Object} Object containing extra info.
 *
 * @param {Object} r Original order object returned from backend.
 */
const getProductName = r => {
  if (
    r.product_table === "partner_programs" ||
    r.product_table === "partner_online"
  ) {
    let couponDiscount = 0;
    const plan = JSON.parse(
      r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
    );

    // Coupon Applied
    if (r.coupon) {
      couponDiscount =
        r.coupon.type === "Percentage"
          ? parseFloat(
              !plan.type
                ? parseFloat(plan.tuition_rate)
                : parseFloat(plan.total_price) *
                    (r.session_quantity ? r.session_quantity : 1) *
                    (parseFloat(r.coupon.amount_off) / CENTS_IN_DOLLAR)
            )
          : parseFloat(parseFloat(r.coupon.amount_off));
    }
    if (r.drop_in_dates && r.coupon) {
      couponDiscount =
        r.coupon.type === "Percentage"
          ? parseFloat(
              parseFloat(parseFloat(plan.total_price) / plan.dayCount) *
                (1 + parseInt(plan.dropin_interest) / CENTS_IN_DOLLAR) *
                (parseFloat(r.coupon.amount_off) / CENTS_IN_DOLLAR)
            )
          : parseFloat(r.coupon.amount_off);
    }

    return {
      name: r.product.program_name,
      type: "Program",
      price:
        r.type === "Recurring"
          ? plan.tuition_rate
          : r.type === "Drop-in"
          ? (plan.total_price / plan.dayCount) *
            r.selectedDays.length *
            (1 + parseInt(plan.dropin_interest) / CENTS_IN_DOLLAR)
          : r.isInstallment
          ? (r.amount || r.amount_paid) / CENTS_IN_DOLLAR
          : // plan.total_price *
            //   (1 +
            //     parseInt(
            //       plan.installments_interest
            //     ) /
            //       CENTS_IN_DOLLAR)
            plan.total_price * (r.session_quantity ? r.session_quantity : 1),
      deposit: r.type === "Recurring" ? plan.deposit : null,
      isTaxEnabled: plan.isTaxEnabled ? true : false,
      taxPercentage:
        plan.isTaxEnabled && plan.taxInfo[0].percentage / CENTS_IN_DOLLAR,
      taxAmount: plan.isTaxEnabled
        ? r.type === "Recurring"
          ? parseFloat(plan.tuition_rate - couponDiscount) *
            (plan.taxInfo[0].percentage / CENTS_IN_DOLLAR)
          : r.type === "Drop-in"
          ? parseFloat(
              (plan.total_price / plan.dayCount) *
                r.selectedDays.length *
                (1 + parseInt(plan.dropin_interest) / CENTS_IN_DOLLAR) -
                couponDiscount
            ) *
            (plan.taxInfo[0].percentage / CENTS_IN_DOLLAR)
          : r.isInstallment
          ? (r.amount / CENTS_IN_DOLLAR) *
            (plan.taxInfo[0].percentage / CENTS_IN_DOLLAR)
          : // plan.total_price *
            //   (1 +
            //     parseInt(
            //       plan.installments_interest
            //     ) /
            //       CENTS_IN_DOLLAR) *
            // (plan.taxInfo[0].percentage /
            //   CENTS_IN_DOLLAR)
            parseFloat(
              (r.amount || r.amount_paid) -
                (r.amount || r.amount_paid) /
                  (1 + plan.taxInfo[0].percentage / CENTS_IN_DOLLAR)
            ) / CENTS_IN_DOLLAR
        : 0,
      isCreditCareFee: r.product.transaction_cost,
      creditCardFee: r.product.transaction_cost
        ? r.type === "Recurring"
          ? plan.tuition_rate * CREDIT_CARD_FEE // 1.5 percent
          : r.type === "Drop-in"
          ? (plan.total_price / plan.dayCount) *
            r.selectedDays.length *
            (1 + parseInt(plan.dropin_interest) / CENTS_IN_DOLLAR) *
            CREDIT_CARD_FEE
          : r.type === "Installment"
          ? plan.total_price *
            (1 + parseInt(plan.installments_interest) / CENTS_IN_DOLLAR) *
            CREDIT_CARD_FEE
          : (plan.total_price * (r.session_quantity ? r.session_quantity : 1) -
              couponDiscount) *
            CREDIT_CARD_FEE
        : 0
    };
  }
  if (r.product_table === "partner_event") {
    let couponDiscount = 0;
    const ticket = JSON.parse(r.product.tickets[r.planId]);
    // Coupon Applied
    if (r.coupon) {
      couponDiscount =
        r.coupon.type === "Percentage"
          ? parseFloat(
              parseFloat(ticket.ticket_price) *
                (parseFloat(r.coupon.amount_off) / CENTS_IN_DOLLAR)
            )
          : parseFloat(parseFloat(r.coupon.amount_off));
    }

    return {
      name: r.product.event_title,
      type: "Event",
      price: ticket.ticket_status === "Free" ? 0 : ticket.ticket_price,
      isTaxEnabled: ticket.isTaxEnabled ? true : false,
      taxPercentage:
        ticket.isTaxEnabled && ticket.taxInfo[0].percentage / CENTS_IN_DOLLAR,
      taxAmount: ticket.isTaxEnabled
        ? parseFloat(ticket.ticket_price - couponDiscount) *
          (ticket.taxInfo[0].percentage / CENTS_IN_DOLLAR)
        : 0,
      isCreditCareFee: false
    };
  }
  if (r.product_table === "partner_memberships") {
    const price = JSON.parse(r.product.prices[r.planId]);
    let couponDiscount = 0;
    // Coupon Applied
    if (r.coupon) {
      couponDiscount =
        r.coupon.type === "Percentage"
          ? parseFloat(
              parseFloat(price.price_per_term) *
                (parseFloat(r.coupon.amount_off) / CENTS_IN_DOLLAR)
            )
          : parseFloat(parseFloat(r.coupon.amount_off));
    }

    return {
      name: r.product.membership_name,
      type: "Membership",
      price: parseFloat(price.price_per_term),
      isTaxEnabled: price.isTaxEnabled ? true : false,
      taxPercentage:
        price.isTaxEnabled && price.taxInfo[0].percentage / CENTS_IN_DOLLAR,
      taxAmount: price.isTaxEnabled
        ? parseFloat(parseFloat(price.price_per_term) - couponDiscount) *
          (price.taxInfo[0].percentage / CENTS_IN_DOLLAR)
        : 0,
      isCreditCareFee: false
    };
  }
};

/**
 * Code taken from components/modals/TotalEarningsModal.jsx
 *
 * Given an item, get all price modifiers (in the following order):
 * - Promos
 * - Coupons
 * - Tax
 * - Credit Card Fee
 * - Refunds
 *
 * @returns {Array<Object>} Array of the price modifiers.
 * Each price modifier has structure {label, text}.
 *
 * @param {Object} item Order item returned from backend.
 */
const getPlusMinus = item => {
  const itemInfo = getProductName(item);

  // Hiding the credit card info for now. 2021/06/16
  let discount = 0;
  if (item.promotionId && item.promotion.discount_type === "Percentage") {
    if (item.promotion.discount_type === "Percentage") {
      discount = parseInt(
        itemInfo.price * (item.promotion.discount_percent / CENTS_IN_DOLLAR)
      );
    } else {
      discount = item.promotion.discount_fixed / CENTS_IN_DOLLAR;
    }
  }
  const promoCreditFee = parseFloat(
    (itemInfo.price - discount) * CREDIT_CARD_FEE
  ).toFixed(2);

  const modifierArray = [];
  if (item.promotionId && !item.isDeposit) {
    const amount = item.promotion.discount_percent
      ? parseFloat(
          itemInfo.price * (item.promotion.discount_percent / CENTS_IN_DOLLAR)
        ).toFixed(2)
      : parseFloat(item.promotion.discount_fixed / CENTS_IN_DOLLAR).toFixed(2);
    modifierArray.push({
      label: "Promo",
      text: `-$${amount}`,
      amount: -1 * amount
    });
  }

  if (item.coupon) {
    const amount =
      item.coupon.type === "Percentage"
        ? parseFloat(
            itemInfo.price *
              (parseFloat(item.coupon.amount_off) / CENTS_IN_DOLLAR)
          ).toFixed(2)
        : parseFloat(parseFloat(item.coupon.amount_off)).toFixed(2);
    modifierArray.push({
      label: "Coupon",
      text: `-$${amount}`,
      amount: -1 * amount
    });
  }

  if (itemInfo.isTaxEnabled && !item.isDeposit) {
    const amount = parseFloat(
      itemInfo.taxAmount ? itemInfo.taxAmount : item.tax / CENTS_IN_DOLLAR
    ).toFixed(2);
    modifierArray.push({
      label: "Tax",
      text: `$${amount}`,
      amount: amount
    });
  }

  // Hiding the credit card fee for now - 2021/06/16.
  if (itemInfo.isCreditCareFee && !item.isDeposit) {
    const amount = !item.promotionId
      ? parseFloat(itemInfo.creditCardFee).toFixed(2)
      : promoCreditFee;
    modifierArray.push({
      label: "Credit Card Fee",
      text: `$${amount}`,
      amount: amount
    });
  }

  const giftDiscount = parseInt(item.giftDiscount);
  if (giftDiscount > 0) {
    const amount = formatMoney(giftDiscount / CENTS_IN_DOLLAR);
    modifierArray.push({
      label: "Gift Card",
      text: `-${amount}`,
      amount: -1 * amount
    });
  }

  // refunds
  // console.log("item", item);
  const refunds =
    item.refunds?.data ||
    item?.charge?.refunds?.data ||
    item?.stripe?.charges?.data;
  // console.log("refunds", refunds);
  if (refunds && refunds.length > 0) {
    let totalRefundAmount = 0;
    refunds.forEach(r => {
      totalRefundAmount += r.amount_refunded;
    });
    if (totalRefundAmount > 0) {
      modifierArray.push({
        label: "Refund",
        text: `-$${(totalRefundAmount / CENTS_IN_DOLLAR).toFixed(2)}`,
        amount: -1 * totalRefundAmount
      });
    }
  }

  return modifierArray;
};

/**
 * Gets the total revenue change due to
 * refunds, coupouns, etc. (according to the getPlusMinus function)
 *
 * @returns {Number} Total revenue change in cents
 *
 * @param {Array<Object>} filteredOrders Orders (already filtered)
 */
const getRevenueChange = filteredOrders => {
  const totalRevenueChange = filteredOrders.reduce(
    (prev, curr) =>
      prev +
      getPlusMinus(curr).reduce(
        (prev, curr) => prev + parseInt(curr.amount),
        0
      ),
    0
  );
  return totalRevenueChange;
};

/**
 * Gets the subtotal of an item.
 *
 * @returns {String} A 2 decimal represenation of the subtotal. No dollar sign returned.
 *
 * @param {Object} item Order item returned from backend.
 */
const getSubtotal = item => {
  const itemInfo = getProductName(item);

  let subTotal;

  if (item.subtotal) {
    // Some items will already have subtotal calculated, simply use that.
    subTotal = item.subtotal / CENTS_IN_DOLLAR;
  } else if (itemInfo.deposit && !item.manual) {
    // Deposits do not have subtotals, simply return.
    return "N/A";
  } else if (!item.promotionId) {
    subTotal = parseFloat(itemInfo.price);
  } else if (item.manual) {
    subTotal = parseFloat(itemInfo.price);
  } else {
    if (item.promotion.discount_type === "Percentage") {
      const discount = parseInt(
        itemInfo.price * (item.promotion.discount_percent / CENTS_IN_DOLLAR)
      );
      subTotal = parseFloat(itemInfo.price - discount);
    } else {
      subTotal = parseFloat(
        parseInt(
          itemInfo.price - item.promotion.discount_fixed / CENTS_IN_DOLLAR
        )
      );
    }
  }
  return `$${subTotal.toFixed(2)}`;
};

/**
 * Formats a number in dollars as money, with separators.
 *
 * @returns {String}
 * @param {Number} amount Number amount to format as money.
 */
const formatMoney = amount => {
  return `$${parseFloat(amount).toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })}`;
};

export {
  getProductName,
  getPlusMinus,
  getSubtotal,
  formatMoney,
  getRevenueChange
};
