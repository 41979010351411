import React, { useGlobal, useState } from "reactn";
import { Input } from "../../../components/UI";
import ResetPasswordModal from "../../../components/modals/ResetPasswordModal";
const ProfileForm = () => {
  const [getValues, setValues] = useGlobal("franchiseUser");
  const [modal, setOpen] = useState(false);
  const handChange = e => {
    if (e.target.value.length < 35)
      setValues({
        ...getValues,
        [e.target.name]: e.target.value
      });
  };

  const fields = {
    name: "Name",
    email: "Email",
    phone: "Phone Number"
  };

  return (
    <div className="box">
      {modal && (
        <ResetPasswordModal
          open={modal}
          onClose={_ => setOpen(false)}
          user={getValues}
        />
      )}
      <div className="bgofbox">
        <div className="header">
          <div className="row">
            <div className="col-md-12">
              <h2>PROFILE</h2>
            </div>
          </div>
        </div>
        <div className="formbody">
          <form>
            {Object.keys(fields).map(field => {
              return (
                <div className="row" key={field}>
                  <div className="col-md-6 col-5">
                    <h3>{fields[field]}</h3>
                  </div>
                  <div className="col-md-6 col-7">
                    <Input
                      name={field}
                      type={field === "password" ? "password" : "text"}
                      value={getValues[field]}
                      onChange={handChange}
                      style={{
                        background:
                          field === "displayName" || field === "email"
                            ? "#efefef"
                            : ""
                      }}
                      isDisabled={
                        field === "displayName" || field === "email"
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
              );
            })}
            <div className="buttons" style={{ textAlign: "center" }}>
              <button
                type="button"
                className="savebtn"
                onClick={_ => setOpen(true)}
              >
                Reset Your Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ProfileForm;
