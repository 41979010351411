import React from "reactn";
import { Table } from "../../UI";
import Axios from "axios";
import numeral from "numeral";
import { format } from "date-fns";
class EducatorPayoutsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      payouts: []
    };
    this.headings = [
      { id: "name", label: "Educator Name" },
      { id: "programName", label: "Program Name" },
      {
        id: "servicePeriod",
        label: "Service Period",
        customCell: (row, i) => (
          <td key={`id-${i}`}>
            {format(new Date((row.created - 60 * 60 * 24 * 6) * 1000), "LLL d")}{" "}
            - {format(new Date(row.created * 1000), "LLL d yyyy")}
          </td>
        )
      },
      {
        id: "grossDeposit",
        label: "Gross Payment",
        customCell: (row, i) => (
          <td key={`id-${i}`}>${numeral(row.gross / 100).format("0,0.00")}</td>
        )
      },
      {
        id: "dsFees",
        label: "DS Fees",
        customCell: (row, i) => (
          <td key={`id-${i}`}>
            ${numeral((row.gross - row.amount) / 100).format("0,0.00")}
          </td>
        )
      },
      {
        id: "amountOwed",
        label: "Payout Amount",
        customCell: (row, i) => (
          <td key={`id-${i}`}>${numeral(row.amount / 100).format("0,0.00")}</td>
        )
      },
      {
        id: "depositExpected",
        label: "Payout Date",
        customCell: (row, i) => (
          <td key={`id-${i}`}>
            {format(new Date(row.arrival_date * 1000), "LLL d yyyy")}
          </td>
        )
      },
      {
        id: "paymentStatus",
        label: "Status",
        customCell: (row, i) => (
          <td key={`id-${i}`}>
            {row.status === "paid" ? (
              <span className="green">
                {row.status[0].toLocaleUpperCase() + row.status.slice(1)}
              </span>
            ) : (
              row.status[0].toLocaleUpperCase() + row.status.slice(1)
            )}
          </td>
        )
      }
    ];
    this.actions = [
      { name: "FAILED", action: this.failedPayment },
      { name: "PAID", action: this.paidPayment }
    ];
  }

  formula(number, fee) {
    const finalFee = fee < 7 ? fee / 100 + 0.01 : fee / 100;
    return (
      number +
      0.3 +
      ((number + 0.3) / (1 - (finalFee - 0.003)) - (number + 0.3))
    );
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/finances/payouts`;
    const results = await Axios.get(ep);

    if (results.data.success)
      this.setState({
        payouts: results.data.data,
        original: results.data.data
      });
  }
  filterDate(e) {
    this.setState({
      payouts: this.state.original.filter(payout =>
        payout.name.toLocaleLowerCase().includes(e.trim().toLocaleLowerCase())
      )
    });
  }
  render() {
    return (
      <div style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <input
            type="text"
            placeholder="Search by educator's name..."
            onChange={e => this.filterDate(e.target.value)}
            style={{
              marginBottom: 15,
              width: "100%",
              borderRadius: 5,
              border: "2px solid #E3E6F0",
              fontSize: "13px",
              padding: 3,
              outline: 0
            }}
          />
          <Table
            headings={this.headings}
            data={this.state.payouts}
            actions={this.actions}
          />
        </div>
      </div>
    );
  }
}

export default EducatorPayoutsTable;
