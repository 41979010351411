import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import "../../assets/css/componentSpecificCss/dropFileInput.css";
import { ImageConfig } from "../../config/ImageConfig";
import uploadImg from "../../assets/img/cloud-upload-regular-240.png";
import { previousWednesday } from "date-fns";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";

const DropFileInput = props => {
  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [fileDisplayName, setFileDisplayName] = useState(null);

  const onDragEnter = () => {
    wrapperRef.current.classList.add("dragover");
  };

  const onDragLeave = () => {
    wrapperRef.current.classList.remove("dragover");
  };

  const onDrop = () => {
    wrapperRef.current.classList.remove("dragover");
  };

  const onFileDrop = e => {
    const newFile = e.target.files[0];
    console.log("NEW FILE", newFile, fileList);
    if (
      newFile?.name &&
      !newFile.name.endsWith(".png") &&
      !newFile.name.endsWith(".jpg") &&
      !newFile.name.endsWith(".jpeg") &&
      !newFile.name.endsWith(".pdf") &&
      !newFile.name.endsWith(".mov") &&
      !newFile.name.endsWith(".mp4") &&
      !newFile.name.endsWith(".mpeg") &&
      !newFile.name.endsWith(".avi")
    ) {
      toast.error(
        "Only files with the extensions .png, .jpeg, .jpg, .pdf, .mov, .mp4, .mpeg, and .avi are supported."
      );
      return;
    }
    let dupeFile = false;
    let numUploadedFiles = 0;
    // if (props.selectedMilestone.file_uploads) {
    //   Object.keys(props.selectedMilestone.file_uploads).forEach(file => {
    //     if (
    //       !props.selectedMilestone.file_uploads[file].isHidden &&
    //       props.selectedMilestone.file_uploads[file].file_name === newFile.name
    //     ) {
    //       dupeFile = true;
    //     }
    //     if (!props.selectedMilestone.file_uploads[file].isHidden) {
    //       numUploadedFiles++;
    //     }
    //   });
    // }
    fileList.forEach(file => {
      if (file.name === newFile.name) {
        dupeFile = true;
      }
    });

    // Set the file size cap to 30MB
    const fileUploadSizeCap = 30000000;
    if (newFile) {
      if (newFile.size > fileUploadSizeCap) {
        toast.error("File exceeded the 30MB size cap!");
      } else if (fileList.length >= 1) {
        toast.error("Cannot upload more than 1 file at a time!");
      } else if (dupeFile) {
        toast.error("You have already uploaded this file!");
      } else {
        const updatedList = [...fileList, newFile];
        setFileList(updatedList);
        setFileName(newFile.name);
        setFileDisplayName(newFile.name);
        props.onFileChange(updatedList);
        props.onFileNameChange(newFile.name);
      }
    }
  };

  const fileRemove = file => {
    console.log("REMOVING FILE:", file);
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    props.onFileChange(updatedList);
    // props.onFileChange(updatedList);
  };

  // const submitFiles = async () => {
  //   await props.onFileChange(fileList);
  //   await props.onSubmit();
  // };

  return (
    <>
      <div
        ref={wrapperRef}
        className={`drop-file-input ${fileList.length > 0 ? "shrunk" : ""}`}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <img src={uploadImg} alt="" />
          <p>Drag & Drop your files here</p>
        </div>
        <input type="file" value="" onChange={onFileDrop} />
      </div>
      {fileList.length > 0 ? (
        <div className="drop-file-preview">
          {/* <p className="drop-file-preview__title">Ready to upload</p> */}
          {fileList.map((item, index) => {
            return (
              <div key={index} className="drop-file-preview__item">
                <img
                  src={
                    ImageConfig[item.type.split("/")[1]] ||
                    ImageConfig["default"]
                  }
                />
                <div className="drop-file-preview__item__info">
                  {/* <p title={item.name} data-tooltip={item.name}>
                    {item.name}
                  </p> */}
                  <label className="drop-file-preview__item__info">
                    File Name:
                  </label>
                  <input
                    className="drop-file-preview__item__info"
                    type="text"
                    value={
                      fileDisplayName === null ? item.name : fileDisplayName
                    }
                    style={{ border: "2px solid grey !important" }}
                    onChange={e => {
                      setFileDisplayName(e.target.value);
                      props.onFileNameChange(e.target.value);
                    }}
                  />
                  {/* <p>{item.size}</p> */}
                </div>
                <span
                  className="drop-file-preview__item__del"
                  onClick={() => {
                    fileRemove(item);
                  }}
                >
                  x
                </span>
              </div>
            );
          })}
          <div></div>
        </div>
      ) : null}
    </>
  );
};

DropFileInput.propTypes = {
  onFileChange: PropTypes.func
};

export default DropFileInput;
