import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK } from "./baseModal/FieldTypes";

class GuardianDetailModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getBaseModalFields = () => {
    const nameField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Full Name",
        content: this.props.fullName
      }
    };

    const emailField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Email",
        content: this.props.email
      }
    };

    const phoneField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Home Phone",
        content: this.props.homePhone
      }
    };

    const addressField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Address",
        content: this.props.address
      }
    };

    const cityField = {
      type: CONTENT_BLOCK,
      data: {
        name: "City",
        content: this.props.city
      }
    };

    const regionField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Region",
        content: this.props.province
      }
    };

    const postalField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Postal / Zip",
        content: this.props.postalCode
      }
    };

    const countryField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Country",
        content: this.props.country
      }
    };

    const fields = [];

    fields.push(nameField);
    fields.push(emailField);
    fields.push(phoneField);
    fields.push(addressField);
    fields.push(cityField);
    fields.push(regionField);
    fields.push(postalField);
    fields.push(countryField);

    return fields;
  };

  getBaseModalProps = () => {
    return {
      title: "Guardian Details",
      fields: this.getBaseModalFields(),
      height: "60vh",
      midSectionHeight: "49vh",
      handleClose: () => this.props.onClose()
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(GuardianDetailModal);
