import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import {
  DROPDOWN,
  STRING_INPUT,
  SELECT_MULTIPLE_DROPDOWN,
  DATE_PICKER,
  SWITCH,
  CONTENT_BLOCK,
  TEXT_AREA
} from "./baseModal/FieldTypes";
import { notEmptyString } from "./baseModal/validators";
import { BLUE_BUTTON, RED_BUTTON, WHITE } from "./baseModal/colours";
import CreateStaffSCheduleModal from "./CreateStaffScheduleModal";
import { ToastHeader } from "reactstrap";

class DeleteStaffScheduleModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      schedule: this.props.selectedSchedule
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async deleteSchedule() {
    const ep = `${process.env.REACT_APP_API}/partners/users/schedule/delete/${this.state.schedule.id}`;
    const result = await axios.post(ep);

    this.setGlobal({ loading: true });

    if (result.data.success) {
      toast.success("Schedule deleted successfully!");
      this.setGlobal({ loading: false });
      this.props.onRefresh();
      this.props.onClose();
    } else {
      toast.error("Error while deleting schedule");
    }
  }

  getBaseModalFields() {
    const fields = [];

    const confirmationMessage = {
      type: CONTENT_BLOCK,
      data: {
        name: "Warning: Deleting the schedule is irreversible. Are you absolutely sure?"
      }
    };

    fields.push(confirmationMessage);

    return fields;
  }

  getBaseModalButtons = () => {
    const confirmButton = {
      name: "Confirm",
      buttonColour: RED_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.deleteSchedule()
    };

    return [confirmButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Delete Schedule",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(DeleteStaffScheduleModal);
