import React from "reactn";
import { NewTabbedTable } from "../../../../components/UI";
import "../../../../assets/css/componentSpecificCss/newTabbedTable.css";

import { format } from "date-fns";

class FranchiserTrackOrders extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      page: 0
    }
    this.tabs = ["Pending", "Payments"];
    this.inputRef = React.createRef();
  }

  getStatusColor = status => {
    if (status === "Draft") {
      return "rgb(33 91 255)";
    } else if (status === "Ready to Bill") {
      return "#eba93e";
    } else if (status === "Cancelled") {
      return "#ff3b8e";
    } else if (status === "Issued") {
      return "rgba(92 200 220)";
    } else if (status === "Late" || status === "Failed") {
      return "#ff3b8e";
    } else if (status === "Processing") {
      return "rgb(33 91 255)";
    } else {
      return "#1bc88a";
    }
  };

  getStatusElement = status => {
    return (
      <td>
        <div
          style={{
            textAlign: "right",
            display: "flex"
          }}
        >
          <span
            className="red"
            style={{
              minWidth: "100px",
              background: this.getStatusColor(status),
              padding: "3px",
              borderRadius: "13px",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Open Sans",
              fontSize: "12px",
              fontWeight: 600
            }}
          >
            {status}
          </span>
        </div>
      </td>
    );
  };

  headings = () => {
    const pending = [
      {
        id: "amount",
        label: "Created Date",
        customCell: (r, i) => (
          <td className="table-custom-text">
            {format(new Date(r.createdDate), "LLLL dd, yyyy")}
          </td>
        )
      },
      {
        id: "start",
        label: "Issue Date",
        customCell: (r, i) => (
          <td className="table-custom-text">
            {format(new Date(r.startDate), "LLLL dd, yyyy")}
          </td>
        )
      },
      {
        id: "item",
        label: "Item",
        customCell: (r, i) => {
          if (r.type === "Invoice" && r.cartOrder) {
            let productNameArr = r.invoice_details.map(
              item => JSON.parse(item).itemName
            );

            let productName =
              "Invoice: " +
              productNameArr.map(item => item.split(": ")[1]).join(", ");

            if (productName.length > 90) {
              productName = productName.substring(0, 90) + "...";
            }
            return <td className="table-custom-text">{productName}</td>;
          }
          return <td className="table-custom-text">{r.item}</td>;
        }
      },
      {
        id: "amountOverdue",
        label: "Total", // Both Invoice and orders have the same field
        customCell: (r, i) => (
          <td className="table-custom-text">
            {parseFloat(r.total / 100)
              // .toFixed(2)
              .toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
          </td>
        )
      },
      {
        id: "purchaser",
        label: "Purchaser",
        customCell: (r, i) => {
          if (r.childName?.length > 0) {
            const childNamesConcatenated = r.childName.join(", ");

            const truncatedChildNames =
              childNamesConcatenated.length > 25
                ? childNamesConcatenated.substring(0, 25) + "..."
                : childNamesConcatenated;
            return (
              <td className="table-custom-text">
                {r.purchaser} (
                <span style={{ fontStyle: "italic" }}>
                  {truncatedChildNames}
                </span>
                )
              </td>
            );
          }
          return <td className="table-custom-text">{r.purchaser}</td>;
        }
      },
      {
        id: "status",
        label: "Status",
        customCell: (r, i) => this.getStatusElement(r.status)
      }
    ];
    
    const payments = [
      {
        id: "payment_date",
        label: "Payment Date",
        customCell: (r, i) => {
          return (
            <td key={i} className="table-custom-text">
              {r.userPaidDate
                ? format(new Date(r.userPaidDate), "LLL dd, yyyy")
                : ""}
            </td>
          );
        }
      },
      {
        id: "amount",
        label: "Created Date",
        customCell: (r, i) => (
          <td className="table-custom-text" key={i}>
            {format(new Date(r.createdDate), "LLLL dd, yyyy")}
          </td>
        )
      },
      {
        id: "item",
        label: "Item",
        customCell: (r, i) => {
          if (r.type === "Invoice" && r.cartOrder) {
            let productNameArr = r.invoice_details.map(
              item => JSON.parse(item).itemName
            );

            let productName =
              "Invoice: " +
              productNameArr.map(item => item.split(": ")[1]).join(", ");

            if (productName.length > 90) {
              productName = productName.substring(0, 90) + "...";
            }
            return <td className="table-custom-text">{productName}</td>;
          }
          return <td className="table-custom-text">{r.item}</td>;
        }
      },
      {
        id: "amountOverdue",
        label: "Total", // Both Invoice and orders have the same field
        customCell: (r, i) => (
          <td className="table-custom-text">
            {parseFloat(r.total / 100)
              // .toFixed(2)
              .toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
          </td>
        )
      },
      {
        id: "amountOverdue",
        label: "Purchaser",
        customCell: (r, i) => {
          if (r.childName?.length > 0) {
            const childNamesConcatenated = r.childName.join(", ");

            const truncatedChildNames =
              childNamesConcatenated.length > 25
                ? childNamesConcatenated.substring(0, 25) + "..."
                : childNamesConcatenated;
            return (
              <td className="table-custom-text">
                {r.purchaser} (
                <span style={{ fontStyle: "italic" }}>
                  {truncatedChildNames}
                </span>
                )
              </td>
            );
          }
          return <td className="table-custom-text">{r.purchaser}</td>;
        }
      },
      {
        id: "userPaymentMethod",
        label: "Paid Using",
        customCell: (r, i) => (
          <td className="table-custom-text">{r.paymentMethod}</td>
        )
      },
      {
        id: "status",
        label: "Status",
        customCell: (r, i) => this.getStatusElement(r.status)
      }
    ];

    return {
      Pending: parseInt(this.global.franchiseUser.selectedId) === -1
        ? [{
          id: "org",
          label: "Organization",
          customCell:(r, i) => (
            <td className="table-custom-text">
              {r.company}
            </td>
          )
          }].concat(pending)
        : pending,
      Payments: parseInt(this.global.franchiseUser.selectedId) === -1
        ? [{
            id: "org",
            label: "Organization",
            customCell:(r, i) => (
              <td className="table-custom-text">
                {r.company}
              </td>
            )
          }].concat(payments)
        : payments
    };
  };

  getFilteredOrders() {
    const customersArr = JSON.parse(JSON.stringify(this.props.data));

    if (customersArr["Pending"]) {
      customersArr["Pending"] = customersArr["Pending"].filter(customer => {
        const value = customer?.purchaser?.toLowerCase().replace(/\s/g, "");
        const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
        return value.includes(filter);
      });
    }

    if (customersArr["Payments"]) {
      customersArr["Payments"] = customersArr["Payments"].filter(customer => {
        const value = customer?.purchaser?.toLowerCase().replace(/\s/g, "");
        const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
        return value.includes(filter);
      });
    }

    return customersArr;
  }


  render() {
    return (
      <div>
        <div
          style={{
            display: "inline-flex",
            flex: "1 1",
            border: "none",
            borderRadius: "10px",
            overflow: "hidden",
            width: "100%"
          }}
        >
          <i
            className="fas fa-search"
            style={{ padding: "8px", background: "white" }}
          ></i>
          <input
            type="text"
            ref={this.inputRef}
            placeholder="Search by purchaser name"
            style={{
              border: "none",
              fontSize: "13px",
              padding: "5px",
              outline: 0,
              background: "white",
              flex: 1
            }}
            value={this.state.filter}
            onChange={e => {
              this.setState({ 
                filter: this.inputRef.current.value,
                page: 0  
              });
            }}
          />
        </div>
        <NewTabbedTable
          data={this.getFilteredOrders()}
          tabs={this.tabs}
          page={"Financials>Paid Orders"}
          headings={this.headings()}
          currentPage={this.state.page}
        />
      </div>
    );
  }
};

export default FranchiserTrackOrders;