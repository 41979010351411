import React from "reactn";
import { formTypes, BoxForm } from "../../../../components/UI/form";

class ProgramVacationForm extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      timeRanges: formTypes.SelectOptions.timeRanges
    };
    this.handleChange = this.handleChange.bind(this);
    this.dispatchNewVacation = this.dispatchNewVacation.bind(this);
    this.customForm = this.customForm.bind(this);
    this.changeMain = this.changeMain.bind(this);
  }

  handleChange(e, v, sch) {
    const c = { ...sch, [e]: v };
    const n = this.global.myProgramVacations.vacations.map(s =>
      sch.id !== s.id ? s : c
    );
    this.setGlobal({
      myProgramVacations: { ...this.global.myProgramVacations, vacations: n }
    });
  }

  changeMain(e, v) {
    if (e === "charge" && v === "No") {
      this.setGlobal({
        myProgramVacations: {
          ...this.global.myProgramVacations,
          [e]: v,
          amount: "None"
        }
      });
    } else {
      this.setGlobal({
        myProgramVacations: {
          ...this.global.myProgramVacations,
          [e]: v,
          amount: ""
        }
      });
    }
    this.setGlobal({
      myProgramVacations: { ...this.global.myProgramVacations, [e]: v }
    });
  }

  dispatchNewVacation(e) {
    e.preventDefault();
    this.dispatch.addProgramVacation();
  }

  customForm() {
    return (
      <div className="formbody">
        <form>
          {this.global.myProgramVacations.vacations.map((sch, i) => (
            <div className="row" key={i}>
              <div className="col-12 d-flex">
                <formTypes.Date
                  name="vacationStart"
                  value={sch.vacationStart}
                  onChange={(e, v) => this.handleChange(e, v, sch)}
                  placeholder="Vacation"
                  readOnly={
                    !this.global.profile.programSubmitted ||
                    this.global.dsUser.accountType === "Admin"
                      ? false
                      : true
                  }
                />
                <span className="mx-3">to</span>
                <formTypes.Date
                  name="vacationEnd"
                  value={sch.vacationEnd}
                  onChange={(e, v) => this.handleChange(e, v, sch)}
                  placeholder="Vacation"
                  readOnly={
                    !this.global.profile.programSubmitted ||
                    this.global.dsUser.accountType === "Admin"
                      ? false
                      : true
                  }
                />
                <button
                  className="remove-box ml-2"
                  type="button"
                  onClick={() => this.dispatch.removeProgramVacation(sch)}
                  disabled={
                    this.global.profile.programSubmitted &&
                    this.global.dsUser.accountType !== "Admin"
                  }
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          ))}
          <div className="row">
            <div className="col-12" style={{ textAlign: "center" }}>
              <button
                onClick={this.dispatchNewVacation}
                className="add-form-button"
                disabled={
                  this.global.profile.programSubmitted &&
                  this.global.dsUser.accountType !== "Admin"
                }
              >
                Add Vacation
              </button>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-5">
              Charge Families?<span className="requiredField">*</span>
            </div>
            <div className="col-7">
              <div
                className={`w-100 ${
                  !this.global.myProgramVacations.charge
                    ? "formbody-invalid"
                    : ""
                }`}
              >
                <formTypes.Select
                  name="charge"
                  options={formTypes.SelectOptions.yesNoOptions}
                  value={this.global.myProgramVacations.charge}
                  onChange={e => this.changeMain(e.target.name, e.target.value)}
                  isDisabled={
                    this.global.profile.programSubmitted &&
                    this.global.dsUser.accountType !== "Admin"
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              Amount<span className="requiredField">*</span>
            </div>
            <div className="col-7">
              <div
                className={`w-100 ${
                  this.global.myProgramVacations.charge &&
                  this.global.myProgramVacations.charge !== "No" &&
                  !this.global.myProgramVacations.amount
                    ? "formbody-invalid"
                    : ""
                }`}
              >
                <formTypes.Select
                  name="amount"
                  options={[
                    { name: "Please Select", value: "" },
                    { name: "Half Charge", value: "Half" },
                    { name: "Full Charge", value: "Full" }
                  ]}
                  value={this.global.myProgramVacations.amount}
                  onChange={e => this.changeMain(e.target.name, e.target.value)}
                  isDisabled={
                    this.global.myProgramVacations.charge === "No" ||
                    (this.global.profile.programSubmitted &&
                      this.global.dsUser.accountType !== "Admin")
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <>
        <BoxForm
          handleChange={this.handleChange}
          name={"VACATIONS"}
          customForm={this.customForm()}
        />
      </>
    );
  }
}

export default ProgramVacationForm;
