import React, { Component } from "reactn";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import Editor from "../../../components/partner/Editor";
import TemplateModal from "../../../components/modals/TemplateModal";
import axios from "axios";
import { toast } from "react-toastify";
import { formatPhoneNumber } from "./commComponents/commUtil";
import "../../../assets/css/componentSpecificCss/communications.css";
import _ from "lodash";
import Button from "react-bootstrap/Button";
import { Close as ClearIcon } from "@material-ui/icons";
import { differenceInMinutes } from "date-fns";
import { fetchConnectionsData } from "../../../Caching";

const reservedRoles = [
  "Programs (all)",
  "Online Virtual (all)",
  "Events (all)",
  "Listings (Specific)"
];
class SendMessage extends Component {
  state = {
    sms: false,
    email: true,
    contacts: [],
    selectedUsers: [],
    editorState: EditorState.createEmpty(),
    names: [],
    templates: [],
    body: "",
    subject: "",
    selectedTemplate: "",
    partnerPhone: null,
    customPhone: "",
    showCustomPhone: false,
    options: [],
    selectedOption: null,
    programs: [],
    events: [],
    memberships: [],
    onlineVirtuals: [],
    parties: [],
    listingType: "Please Select",
    filteredProgramsIndex: 0,
    filteredEventsIndex: 0,
    filteredMembershipsIndex: 0,
    filteredOnlineVirtualsIndex: 0,
    filteredUsers: [],
    programEnrollments: [],
    eventEnrollments: [],
    membershipEnrollments: [],
    onlineVirtualEnrollments: [],
    partyEnrollments: [],
    type: "",
    stage: "",
    userRole: "",
    activeContacts: []
  };

  isEnrolledCategory = status_stage =>
    status_stage === "Accepted_Paid" || status_stage === "Accepted_Manual";

  isPendingCategory = status_stage =>
    status_stage === "New_Pending" ||
    status_stage === "Accepted_Unpaid" ||
    status_stage === "Waitlisted_Unpaid" ||
    status_stage === "Waitlisted_Paid";

  isInactiveCategory = status_stage =>
    status_stage === "Rejected" ||
    status_stage === "Cancelled" ||
    status_stage === "Inactive";

  async fetchInitialData() {
    this.setGlobal({ loading: true });

    let searchType = null;
    let searchId = null;
    let rootBreadCrumbs = this.props.location.query
      ? [...this.global.partnerPathway]
      : [...this.global.partnerPathway.slice(0, 1)];
    if (this.props.location?.query?.type) {
      searchType = this.props.location.query.type;
      searchId = parseInt(this.props.location.query.id);
      console.log("custom in query");
      this.setState({
        type: searchType,
        stage: "Enrolled",
        selectedOption: searchId
      });
    }
    console.log({ searchType, searchId });
    this.setGlobal({
      partnerPathway: [
        ...rootBreadCrumbs,
        { label: "Send Message", to: "/send-message" }
      ]
    });

    const ep = `${process.env.REACT_APP_API}/partners/organization_profile/preview`;
    const results = await axios.get(ep);
    this.setGlobal({ loading: false });
    if (results.data.success) {
      // Setting the programs data
      const programIds = [];
      const programData = results.data.data.programs
        .filter(f => !f.isArchived)
        .map(e => {
          programIds.push(e.id);
          const parsePlans = e.plans.map(t => JSON.parse(t));
          e.plans = parsePlans;
          return e;
        });

      this.setState({ programs: programData });

      // Fetching the customer enrollments for programs
      const programEnrollmentData = async () => {
        return Promise.all(
          programIds.map(async p => {
            const customers_ep = `${process.env.REACT_APP_API}/partners/programs/customers/communications/${p}`;
            const customers = await axios.get(customers_ep);

            if (customers.data.success) {
              console.log("customer data", customers.data.data);
              customers.data.data.map((e, i) => {
                const find = this.state.programEnrollments.filter(
                  f =>
                    f.programId === e.programId &&
                    f.dsUserId === e.dsUserId &&
                    f.status_stage === e.status_stage
                );

                if (find.length === 0) {
                  this.state.programEnrollments.push(e);

                  if (
                    searchType === "Programs" &&
                    e.programId === searchId &&
                    this.isEnrolledCategory(e.status_stage)
                  ) {
                    this.state.names.push(e);
                    this.state.selectedUsers.push(e.dsUserId);
                  }
                } else {
                  return;
                }
                return;
              });
              // setProgramEnrollments(customerData);
            }
          })
        );
      };
      await programEnrollmentData();
      console.log("programEnrollemnts", this.state.programEnrollments);

      // Setting the events data
      const eventIds = [];
      const eventData = results.data.data.events
        .filter(f => !f.isArchived)
        .map(e => {
          eventIds.push(e.id);
          const parseTicket = e.tickets.map(t => JSON.parse(t));
          e.tickets = parseTicket;
          return e;
        });

      this.setState({ events: eventData });

      // Fetching the customer enrollments for events
      const eventEnrollmentData = async () => {
        return Promise.all(
          eventIds.map(async p => {
            const customers_ep = `${process.env.REACT_APP_API}/partners/events/customers/communications/${p}`;
            const customers = await axios.get(customers_ep);
            if (customers.data.success) {
              customers.data.data.map((e, i) => {
                const find = this.state.eventEnrollments.filter(
                  f =>
                    f.programId === e.programId &&
                    f.dsUserId === e.dsUserId &&
                    f.status_stage === e.status_stage
                );
                if (find.length === 0) {
                  this.state.eventEnrollments.push(e);

                  if (
                    searchType === "Events" &&
                    e.programId === searchId &&
                    this.isEnrolledCategory(e.status_stage)
                  ) {
                    this.state.names.push(e);
                    this.state.selectedUsers.push(e.dsUserId);
                  }
                } else {
                  return;
                }
                return;
              });
              // setProgramEnrollments(customerData);
            }
          })
        );
      };
      await eventEnrollmentData();

      // Setting the parties data
      const partyIds = [];
      const partyData = results.data.data.parties
        .filter(f => !f.isArchived)
        .map(e => {
          partyIds.push(e.id);
          const parseTicket = e.tickets.map(t => JSON.parse(t));
          e.tickets = parseTicket;
          return e;
        });
      console.log(partyData)
      this.setState({ parties: partyData });

      // Fetching the customer enrollments for parties
      const partyEnrollmentData = async () => {
        return Promise.all(
          partyIds.map(async p => {
            const customers_ep = `${process.env.REACT_APP_API}/partners/parties/customers/communications/${p}`;
            const customers = await axios.get(customers_ep);
            if (customers.data.success) {
              customers.data.data.map((e, i) => {
                const find = this.state.partyEnrollments.filter(
                  f =>
                    f.programId === e.programId &&
                    f.dsUserId === e.dsUserId &&
                    f.status_stage === e.status_stage
                );
                if (find.length === 0) {
                  this.state.partyEnrollments.push(e);

                  if (
                    searchType === "Parties" &&
                    e.programId === searchId &&
                    this.isEnrolledCategory(e.status_stage)
                  ) {
                    this.state.names.push(e);
                    this.state.selectedUsers.push(e.dsUserId);
                  }
                } else {
                  return;
                }
                return;
              });
              // setProgramEnrollments(customerData);
            }
          })
        );
      };
      await partyEnrollmentData();

      // Setting the memberships data
      const membershipIds = [];
      const membershipData = results.data.data.memberships
        .filter(f => !f.isArchived)
        .map(e => {
          membershipIds.push(e.id);
          const parseMembershipPrices = e.prices.map(t => JSON.parse(t));
          e.prices = parseMembershipPrices;
          return e;
        });

      this.setState({ memberships: membershipData });

      // Fetching the customer enrollments for programs
      const membershipEnrollmentData = async () => {
        return Promise.all(
          membershipIds.map(async p => {
            const customers_ep = `${process.env.REACT_APP_API}/partners/memberships/customers/communications/${p}`;
            const customers = await axios.get(customers_ep);
            if (customers.data.success) {
              customers.data.data.map((e, i) => {
                const find = this.state.membershipEnrollments.filter(
                  f =>
                    f.programId === e.programId &&
                    f.dsUserId === e.dsUserId &&
                    f.status_stage === e.status_stage
                );
                if (find.length === 0) {
                  this.state.membershipEnrollments.push(e);

                  if (
                    searchType === "Memberships" &&
                    e.programId === searchId &&
                    this.isEnrolledCategory(e.status_stage)
                  ) {
                    this.state.names.push(e);
                    this.state.selectedUsers.push(e.dsUserId);
                  }
                } else {
                  return;
                }
                return;
              });
              // setProgramEnrollments(customerData);
            }
          })
        );
      };
      await membershipEnrollmentData();

      // Setting Online Data
      const onlineIds = [];
      const onlineVirtualData = results.data.data.virtual
        .filter(f => !f.isArchived)
        .map(e => {
          onlineIds.push(e.id);
          const parsePlans = e.plans.map(t => JSON.parse(t));
          e.plans = parsePlans;
          return e;
        });

      this.setState({ onlineVirtuals: onlineVirtualData });

      // Fetching the customer enrollments for online
      const onlineEnrollmentData = async () => {
        return Promise.all(
          onlineIds.map(async p => {
            const customers_ep = `${process.env.REACT_APP_API}/partners/virtual/customers/communications/${p}`;
            const customers = await axios.get(customers_ep);
            if (customers.data.success) {
              customers.data.data.map((e, i) => {
                const find = this.state.onlineVirtualEnrollments.filter(
                  f =>
                    f.programId === e.programId &&
                    f.dsUserId === e.dsUserId &&
                    f.status_stage === e.status_stage
                );
                if (find.length === 0) {
                  this.state.onlineVirtualEnrollments.push(e);

                  if (
                    searchType === "Online" &&
                    e.programId === searchId &&
                    this.isEnrolledCategory(e.status_stage)
                  ) {
                    this.state.names.push(e);
                    this.state.selectedUsers.push(e.dsUserId);
                  }
                } else {
                  return;
                }
                return;
              });
              // setProgramEnrollments(customerData);
            }
          })
        );
      };
      await onlineEnrollmentData();

      const activeContactsData = async () => {
        const connections = await fetchConnectionsData(this.global.dsUser.id);
        const active = [];
        console.log(connections);
        connections
          .filter(f => !f.isArchived)
          .sort((a, b) => {
            if (a.classification < b.classification) {
              return -1;
            }
            if (a.classification > b.classification) {
              return 1;
            }
            return 0;
          })
          .map(e => {
            let childNames = [];
            const allChild = connections
              .filter(f => !f.isArchived)
              .filter(h => h.dsUserId == e.dsUserId)
              .map(
                k =>
                  !childNames.filter(f => f == k.childName)[0] &&
                  k.childName &&
                  childNames.push(k.childName)
              );
            !active.filter(f => f.email === e.email)[0] &&
              e.classification === "active" &&
              active.push({
                ...e,
                childNames,
                childNamesString: childNames.join(", ")
              });
          });

        // results.data.data.connections
        this.setState({
          activeContacts: active
            .filter(f => !f.isArchived)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        });
      
        this.setGlobal({ loading: false });
      };
      await activeContactsData();
    }
  }

  async componentDidMount() {
    const pemissionToViewPage =
      !this.global.dsUser.sub_user ||
      this.global.dsUser.accesspoints?.Contacts?.["Send Message"]?.read ===
      true; //test explicitly for "true". Not truthy value.
    if (!pemissionToViewPage) return;
    //====Do not proceed any further with data loading if the user doesn't have permission to view this page!====

    // If the user is a sub user, we need their Role name
    // General (all) has permissions to message anyone
    if (this.global.dsUser.sub_user) {
      const ep = `${process.env.REACT_APP_API}/partners/roles/${this.global.dsUser.role}`;
      const role = await axios.get(ep);
      if (role.data.success) {
        this.setState({ userRole: role.data.data[0].role });
      }
    }

    await this.fetchInitialData();
    const ep = `${process.env.REACT_APP_API}/partners/connections`;
    const results = await axios.get(ep);

    if (results.data.success) {
      let select = [];

      results.data.data.connections.map(e => {
        if (select.includes(e.came_from)) {
          return;
        } else {
          select.push(e.came_from);
        }
      });

      this.setState({
        options: select,
        contacts: results.data.data.connections,
        templates: results.data.data.templates,
        partnerPhone: results.data.data.partnerPhone
          ? results.data.data.partnerPhone
          : `+1 (647) 696-2097`
      });
    }

    this.getSMSPermissions();
  }

  async fetchTemplates() {
    const ep = `${process.env.REACT_APP_API}/partners/connections`;
    const results = await axios.get(ep);
    if (results.data.success) {
      this.setState({
        templates: results.data.data.templates
      });
    }
  }

  getTableName() {
    let tableName = "";
    switch (this.state.type) {
      case "Programs":
        tableName = "partner_programs";
        break;

      case "Events":
        tableName = "partner_events";
        break;

      case "Online Virtual":
        tableName = "partner_online";
        break;

      case "Memberships":
        tableName = "partner_memberships";
        break;

      case "Parties":
        tableName = "partner_parties";
        break;

      default:
        break;
    }
    return tableName;
  }

  async sendEmail() {
    const ep = `${process.env.REACT_APP_API}/partners/connections/email`;
    const results = await axios.post(ep, {
      //users: this.state.contacts,
      to: this.state.names.map(e => ({
        ...e,
        email: e.email,
        name: e.displayName
      })),
      content: convertToRaw(this.state.editorState.getCurrentContent()),
      subject: this.state.subject,
      listingTable: this.getTableName(),
      listingId: this.state.selectedOption,
      stage: this.state.stage,
      templateId:
        this.state.templates[parseInt(this.state.selectedTemplate)]?.id
    });
    console.log("results....", results);
    if (results.data.success) {
      this.setState({
        selectedUsers: [],
        names: [],
        body: "",
        subject: "",
        selectedTemplate: "",
        editorState: EditorState.createEmpty()
      });
      toast.success(`Email Successfully Sent`);
    } else {
      console.error(results.data);
      console.log("results....2", results);
      toast.error(results.data.message);
    }
  }
  async sendSms() {
    const ep = `${process.env.REACT_APP_API}/partners/connections/sms`;
    const results = await axios.post(ep, {
      isCustomPhone: !!this.state.customPhone,
      customPhone: this.state.customPhone,
      sendFromNumber: this.state.partnerPhone,
      users: this.state.names,
      selectedUsers: this.state.selectedUsers,
      body: this.state.body,
      listingTable: this.getTableName(),
      listingId: this.state.selectedOption,
      stage: this.state.stage
    });

    if (results.data.success) {
      this.setState({
        selectedUsers: [],
        names: [],
        body: "",
        selectedTemplate: "",
        editorState: EditorState.createEmpty()
      });
      toast.success(`SMS Successfully Sent`);
    } else {
      console.error(results.data);
      toast.error(results.data.message);
    }
  }

  /**
   * This function handles the edit button functionality of a sub user
   *  returns boolean
   * NOTE: Function name wrong! Returns true if sub-user CANNOT edit! Returns false if sub-user CAN edit!
   */
  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return !this.global.dsUser.accesspoints?.Contacts?.["Send Message"]?.edit;
    }
  };

  getSMSPermissions = async () => {
    const feature_name = "sms-to-custom-number";
    const ep = `${process.env.REACT_APP_API}/partner/permissions/feature/${feature_name}`;
    const resp = await axios.get(ep);

    if (resp.data.success) {
      this.setState({ showCustomPhone: resp.data.data.has_access });
    }
  };

  setFilteredUsers = () => {
    const listingMap = {
      Programs: { enrollments: this.state.programEnrollments },
      Events: { enrollments: this.state.eventEnrollments },
      Parties: { enrollments: this.state.partyEnrollments },
      Memberships: { enrollments: this.state.membershipEnrollments },
      "Online Virtual": { enrollments: this.state.onlineVirtualEnrollments },
      "Active Contacts": { enrollments: this.state.activeContacts }
    };
    if (this.state.type === "") {
      return;
    }
    if (this.state.type === "Active Contacts") {
      this.setState({
        filteredUsers: listingMap["Active Contacts"].enrollments
      });
      return;
    }
    const enrollments = listingMap[this.state.type].enrollments;

    // Choosing which users to display in the dropdown.
    const programUsers = enrollments.filter(
      f => f.programId === this.state.selectedOption
    );
    console.log(
      "before filter enrollments",
      this.state.selectedOption,
      programUsers
    );
    const filteredUsers = programUsers.filter(f => {
      if (this.state.stage === "Enrolled") {
        return this.isEnrolledCategory(f.status_stage);
      }
      if (this.state.stage === "Pending") {
        return this.isPendingCategory(f.status_stage);
      }
      if (this.state.stage === "Inactive") {
        return this.isInactiveCategory(f.status_stage);
      }
    });
    const filteredUniqueUsers = [];
    filteredUsers.forEach(f => {
      if (
        filteredUniqueUsers.find(e => e.dsUserId === f.dsUserId) === undefined
      ) {
        filteredUniqueUsers.push(f);
      }
    });
    console.log("after filter users", filteredUsers);
    this.setState({ filteredUsers: filteredUniqueUsers });
  };

  handleClick = id => {
    this.setState(
      prevState => {
        return {
          selectedUsers: prevState.selectedUsers.filter(userId => {
            return parseFloat(userId) !== id;
          }),
          names: prevState.names.filter(name => {
            return name.id !== id;
          })
        };
      },
      () => {
        console.log(
          "selectedUsers: ",
          this.state.selectedUsers,
          "names: ",
          this.state.names
        );
      }
    );
  };

  render() {
    // console.__log(`this.global.dsUser.sub_user =`,this.global.dsUser.sub_user);
    // console.__log(`this.global.dsUser.accesspoints?.Contacts?.["Send Message"] =`,
    //   JSON.stringify(this.global.dsUser.accesspoints?.Contacts?.["Send Message"]));
    const pemissionToViewPage =
      !this.global.dsUser.sub_user ||
      this.global.dsUser.accesspoints?.Contacts?.["Send Message"]?.read ===
      true; //test explicitly for "true". Not truthy value.

    if (!pemissionToViewPage) {
      return (
        <div className="container-fluid">
          <div className="cont">
            <div className="row">
              <h1 data-testid="heading-no-permission">Send Message</h1>
            </div>
            <div className="row">
              <p>You do not have permission to view this page.</p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="container-fluid">
        {this.state.templateModal && (
          <TemplateModal
            onClose={_ => this.setState({ templateModal: false })}
            open={this.state.templateModal}
            onSave={() => this.fetchTemplates()}
            page={this.state.stage}
            allTemplates={[...this.state.templates]}
          />
        )}
        <div className="cont">
          <h1>Send Message</h1>
          <div className="forbtnwrap" style={{ marginBottom: "10px" }}>
            <div className="forbtnapp">
              <button
                className="newapplications"
                style={{
                  border: "1px solid #7ea1ff",
                  padding: "6px 20px",
                  backgroundColor: this.state.email ? "rgb(126, 161, 255)" : "",
                  color: this.state.email ? "white" : ""
                }}
                onClick={_ => this.setState({ email: true, sms: false })}
              >
                Email
              </button>
            </div>
            <div
              className="forbtnapp"
              style={{ marginLeft: "20px", flexGrow: 1 }}
            >
              <button
                className="newapplications"
                style={{
                  border: "1px solid #7ea1ff",
                  padding: "6px 20px",
                  backgroundColor: this.state.sms ? "rgb(126, 161, 255)" : "",
                  color: this.state.sms ? "white" : ""
                }}
                onClick={_ => this.setState({ sms: true, email: false })}
              >
                SMS
              </button>
            </div>
            {this.global.organizationInfo.theme >= 0 && (
              <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                <button
                  className="newapplications"
                  style={{
                    cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                  }}
                  disabled={this.canSubUserEdit()}
                  onClick={_ => this.setState({ templateModal: true })}
                >
                  Template
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="cont">
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <div className="box">
                <div className="bgofbox">
                  <div className="header">
                    <div className="row">
                      <div className="col-md-12">
                        <h2>
                          Communications - From{" "}
                          {this.global.organizationInfo.organization_title}{" "}
                          {(() => {
                            if (this.state.sms) {
                              return formatPhoneNumber(this.state.partnerPhone);
                            }
                            if (this.global.organizationInfo.google_token) {
                              return this.global.organizationInfo.google_mail;
                            }
                            return "no-reply@dreamschools.com";
                          })()}
                          {/* {this.state.sms
                            ? this.state.partnerPhone
                            : `${
                                this.global.organizationInfo.organization_title
                              } (${
                                this.global.organizationInfo.google_mail ||
                                "no-reply@dreamschools.com"
                              })`} */}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="formbody">
                    <form onSubmit={e => e.preventDefault()}>
                      {this.state.email && (
                        <>
                          <div className="row">
                            <div
                              className="col-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <h3 style={{ margin: 0, padding: 0 }}>
                                Template
                              </h3>
                            </div>
                            <div className="col-9 d-flex">
                              <select
                                style={{
                                  width: "100%",
                                  border: "1px solid #cbcbcb",
                                  borderRadius: "5px",
                                  padding: "5px"
                                }}
                                onChange={e =>
                                  this.setState({
                                    subject:
                                      this.state.templates[e.target.value]
                                        .subject,
                                    editorState: EditorState.createWithContent(
                                      convertFromRaw(
                                        this.state.templates[e.target.value]
                                          .content
                                      )
                                    ),
                                    selectedTemplate: e.target.value
                                  })
                                }
                                value={this.state.selectedTemplate}
                              >
                                <option selected default hidden value="">
                                  Please Select
                                </option>
                                {this.state.templates.map((e, key) => (
                                  <option key={key} value={key}>
                                    {e.title}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="row">
                            <div
                              className="col-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <h3 style={{ margin: 0, padding: 0 }}>Subject</h3>
                            </div>
                            <div className="col-9 d-flex">
                              <input
                                disabled={this.canSubUserEdit()}
                                style={{
                                  border: "1px solid #cbcbcb",
                                  borderRadius: "5px",
                                  padding: "5px"
                                }}
                                onChange={e =>
                                  this.setState({ subject: e.target.value })
                                }
                                value={this.state.subject}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="row" style={{ position: "relative" }}>
                        <div
                          className="col-3"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h3
                            style={{
                              margin: 0,
                              padding: 0,
                              alignSelf: "start"
                            }}
                          >
                            Body
                          </h3>
                        </div>

                        <div
                          className="col-12 d-flex"
                          style={{
                            marginTop: "20px",
                            border: this.state.email
                              ? "1px solid rgb(203, 203, 203)"
                              : "",
                            borderRadius: "10px",
                            color: "black"
                          }}
                        >
                          {this.state.email ? (
                            <Editor
                              editorState={this.state.editorState}
                              setEditorState={editor =>
                                this.setState({ editorState: editor })
                              }
                              disabled={this.canSubUserEdit()}
                            />
                          ) : (
                            <textarea
                              disabled={this.canSubUserEdit()}
                              rows="8"
                              value={this.state.body}
                              style={{ width: "100%" }}
                              onChange={e =>
                                this.setState({ body: e.target.value })
                              }
                            ></textarea>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="box">
                <div className="bgofbox">
                  <div className="header">
                    <div className="row">
                      <div className="col-md-12">
                        <h2>Send to</h2>
                      </div>
                    </div>
                  </div>
                  <div className="formbody" style={{ paddingBottom: "10px" }}>
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                      }}
                    >
                      <div className="row">
                        <div
                          className="col-3"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h3 style={{ margin: 0, padding: 0 }}>Type</h3>
                        </div>
                        <div className="col-9 d-flex">
                          <select
                            style={{
                              border: "1px solid #cbcbcb",
                              borderRadius: "5px",
                              padding: "5px",
                              width: "100%"
                            }}
                            onChange={e =>
                              this.setState(
                                { type: e.target.value },
                                this.setFilteredUsers
                              )
                            }
                            value={this.state.type}
                            disabled={
                              this.global.dsUser.sub_user &&
                              reservedRoles.includes(this.state.userRole)
                            }
                          >
                            <option selected default value="">
                              Please Select
                            </option>
                            <option value="Programs">Programs</option>
                            <option value="Online Virtual">
                              Online Virtual
                            </option>
                            <option value="Events">Events</option>
                            <option value="Parties">Parties</option>
                            <option value="Memberships">Memberships</option>
                            <option value="Active Contacts">
                              Active Contacts
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-3"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h3 style={{ margin: 0, padding: 0 }}>Select</h3>
                        </div>
                        <div className="col-9 d-flex">
                          <select
                            style={{
                              border: "1px solid #cbcbcb",
                              borderRadius: "5px",
                              padding: "5px",
                              width: "100%"
                            }}
                            onChange={e =>
                              this.setState(
                                {
                                  selectedOption: parseInt(e.target.value),
                                  stage: ""
                                },
                                this.setFilteredUsers
                              )
                            }
                            value={this.state.selectedOption}
                            disabled={
                              (this.global.dsUser.sub_user &&
                                reservedRoles.includes(this.state.userRole)) ||
                              this.state.type === "Active Contacts"
                            }
                          >
                            <option>Please Select</option>
                            {this.state.type === "Programs" &&
                              this.state.programs.map(e => {
                                return (
                                  <option value={e.id} key={e.id}>
                                    {e.program_name}
                                  </option>
                                );
                              })}
                            {this.state.type === "Events" &&
                              this.state.events.map(e => {
                                return (
                                  <option value={e.id} key={e.id}>
                                    {e.event_title}
                                  </option>
                                );
                              })}
                            {this.state.type === "Memberships" &&
                              this.state.memberships.map(e => {
                                return (
                                  <option value={e.id} key={e.id}>
                                    {e.membership_name}
                                  </option>
                                );
                              })}
                            {this.state.type === "Online Virtual" &&
                              this.state.onlineVirtuals.map(e => {
                                return (
                                  <option value={e.id} key={e.id}>
                                    {e.program_name}
                                  </option>
                                );
                              })}
                            {this.state.type === "Parties" &&
                              this.state.parties.map(e => {
                                return (
                                  <option value={e.id} key={e.id}>
                                    {e.party_title}
                                  </option>
                                );
                              })}

                            {/* <option value="All">All</option> */}
                            {/* {this.state.options.map(e => {
                              return(
                                <option value={e}>{e}</option>
                              )
                            })} */}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-3"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h3 style={{ margin: 0, padding: 0 }}>Stage</h3>
                        </div>
                        <div className="col-9 d-flex">
                          <select
                            style={{
                              border: "1px solid #cbcbcb",
                              borderRadius: "5px",
                              padding: "5px",
                              width: "100%"
                            }}
                            onChange={e => {
                              this.setState(
                                { stage: e.target.value },
                                this.setFilteredUsers
                              );
                            }}
                            value={this.state.stage}
                            disabled={
                              (this.global.dsUser.sub_user &&
                                reservedRoles.includes(this.state.userRole)) ||
                              this.state.type === "Active Contacts"
                            }
                          >
                            <option value="">Please Select</option>
                            <option value="Enrolled">Enrolled</option>
                            <option value="Pending">Pending</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-3"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h3 style={{ margin: 0, padding: 0 }}>
                            Search Contacts
                          </h3>
                        </div>
                        <div className="col-9 d-flex">
                          <select
                            disabled={
                              !this.state.stage && !this.state.selectedOption
                            }
                            style={{
                              border: "1px solid #cbcbcb",
                              borderRadius: "5px",
                              padding: "5px",
                              width: "100%"
                            }}
                            onChange={e => {
                              let usersToAdd = [];

                              let idsToAdd = [e.target.value];
                              // If "add all" was selected.
                              if (e.target.value == -2) {
                                usersToAdd = [...this.state.filteredUsers];
                                idsToAdd = this.state.filteredUsers.map(
                                  user => user.dsUserId
                                );
                              } else {
                                const val = this.state.filteredUsers.find(
                                  f => f.dsUserId == e.target.value
                                );
                                usersToAdd.push({ ...val });
                              }
                              const uniqueNames = _.uniqBy(
                                [...this.state.names, ...usersToAdd],
                                "dsUserId"
                              );

                              this.setState(
                                {
                                  selectedUsers: [
                                    ...this.state.selectedUsers,
                                    ...idsToAdd
                                  ],
                                  names: uniqueNames
                                },
                                () => {
                                  console.log(
                                    "selectedUsers: ",
                                    this.state.selectedUsers,
                                    "names: ",
                                    this.state.names
                                  );
                                }
                              );
                            }}
                            value={-1}
                            disabled={
                              this.global.dsUser.sub_user &&
                              reservedRoles.includes(this.state.userRole)
                            }
                          >
                            <option value={-1} disabled hidden>
                              Select User
                            </option>
                            <option value={-2}>Add All Contacts</option>
                            {this.state.filteredUsers.map(e => {
                              return (
                                <option value={e.dsUserId} key={e.dsUserId}>
                                  {e.displayName}
                                </option>
                              );
                            })}

                            {/* {this.state.selectedOption === "All" && this.state.contacts
                              .filter(
                                cont => (this.state.sms ? cont.phone : cont.email) &&
                                  !this.state.selectedUsers.includes(
                                    cont.parentId.toString()
                                  )
                              )
                              .map(cont => (
                                <option
                                  name={cont.displayName}
                                  value={cont.parentId}
                                  selected={false}
                                >
                                  {cont.displayName}
                                </option>
                              ))} */}
                            {/* {this.state.selectedOption !== "All" && this.state.contacts
                              .filter(
                                cont => this.state.selectedOption === cont.came_from && (this.state.sms ? cont.phone : cont.email) &&
                                  !this.state.selectedUsers.includes(
                                    cont.parentId.toString()
                                  )
                              )
                              .map(cont => (
                                <option
                                  name={cont.displayName}
                                  value={cont.parentId}
                                  selected={false}
                                >
                                  {cont.displayName}
                                </option>
                              ))} */}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-12"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h3 style={{ margin: 0, padding: 0 }}>Send to :</h3>
                        </div>
                        <div className="col-12 d-flex">
                          <div
                            style={{
                              border: "1px solid #cbcbcb",
                              borderRadius: "5px",
                              padding: "5px",
                              width: "100%",
                              marginTop: "10px",
                              height: "180px",
                              overflow: "auto"
                            }}
                          >
                            {this.state.names.map(e => {
                              return (
                                <Button
                                  variant="primary"
                                  size="sm"
                                  style={{ margin: "3px 5px" }}
                                >
                                  {this.state.sms
                                    ? `${e.displayName} (${e.phone})`
                                    : e.displayName}
                                  <ClearIcon
                                    style={{
                                      color: "white",
                                      cursor: "pointer"
                                    }}
                                    onClick={() => {
                                      this.handleClick(e.id);
                                    }}
                                  />
                                </Button>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      {this.state.showCustomPhone && this.state.sms && (
                        <div className="row">
                          <div
                            className="col-3"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <h3 style={{ margin: 0, padding: 0 }}>
                              Custom Cell# :
                            </h3>
                          </div>
                          <div className="col-9 d-flex">
                            <input
                              type="number"
                              style={{
                                border: "1px solid #cbcbcb",
                                borderRadius: "5px",
                                padding: "5px",
                                width: "100%"
                              }}
                              value={this.state.customPhone}
                              onChange={e =>
                                this.setState({ customPhone: e.target.value })
                              }
                              disabled={
                                this.global.dsUser.sub_user &&
                                reservedRoles.includes(this.state.userRole)
                              }
                            />
                          </div>
                        </div>
                      )}
                      <div
                        style={{
                          textAlign: "right",
                          width: "100%",
                          paddingRight: "20px"
                        }}
                      >
                        <button
                          className={`${this.canSubUserEdit() ? "button-disabled" : ""
                            }`}
                          type="button"
                          disabled={this.canSubUserEdit()}
                          style={{
                            marginTop: "20px",
                            padding: "7px 15px",
                            color: "white",
                            background: "#4e73df",
                            border: ".5px solid #4e73df",
                            borderColor: "#4e73df",
                            borderRadius: "5px",
                            fontWeight: 600
                          }}
                          onClick={_ =>
                            this.state.sms ? this.sendSms() : this.sendEmail()
                          }
                        >
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SendMessage;
