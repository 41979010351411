import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { format, isToday } from "date-fns";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  DROPDOWN,
  DATE_PICKER,
  SELECT_MULTIPLE_DROPDOWN,
  GET_HTML
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class SetDraftRecurringModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isToday: false
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async SetDraftRecurringModal(e) {
    e.preventDefault();
    console.log("paidOffline");
    const ep = `${process.env.REACT_APP_API}/partners/orders/invoice/remove-paid-offline-recurring`;
    const ep2 = `${process.env.REACT_APP_API}/partners/orders/invoice/remove-stripe-paid-offline-recurring/${this.props.pageId}`;
    const res = await axios.post(ep, {
      id: this.props.orderId,
      invoice_status: null,
      status: null
    });

    const res2 = await axios.post(ep2, { id: this.props.orderId });

    console.log("res", res);
    console.log("res2", res2);
    if (res.data.success) {
      toast.success("Set as Draft");
      this.props.refreshPage();
      this.props.onClose();
    }
  }

  // componentDidMount() {
  //   this.setState({
  //     isToday: isToday(new Date(this.props.data.orderStartDate))
  //   });
  // }

  descriptionInput = () => {
    return (
      <div id="heightset" className="modal-main">
        <p
          style={{
            fontSize: "16px",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262"
          }}
        >
          Are you sure you want to change this invoice back to Draft?
        </p>
      </div>
    );
  };

  getBaseModalFields() {
    const descriptionText = {
      type: GET_HTML,
      data: {
        gethtml: this.descriptionInput
      }
    };

    const fields = [];
    fields.push(descriptionText);

    return fields;
  }

  getBaseModalButtons = () => {
    const confirmSend = {
      name: "Set as Draft",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.SetDraftRecurringModal(e)
    };

    const fieldsbutton = [];
    fieldsbutton.push(confirmSend);

    return fieldsbutton;
  };

  getBaseModalProps = () => {
    return {
      title: "Back to Draft?",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose
    };
  };

  render() {
    console.log("this.state", this.state);
    console.log("this.global", this.global);
    console.log("this.props", this.props);
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <ModalTemplate
        heading="Back to Draft?"
        onClose={this.props.onClose}
        style={{ overflow: "visible" }}
      >
        <form onSubmit={e => this.setDraft(e)}>
          <div id="heightset" className="modal-main">
            <p style={{ fontSize: "16px" }}>
              Are you sure you want to change this invoice back to Draft?
            </p>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <img
                  src={CircleCheck}
                  alt=""
                  style={{
                    color: "white",
                    height: "15px",
                    width: "15px",
                    marginRight: "8px"
                  }}
                />
                <span>Set as Draft</span>
              </div>
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(SetDraftRecurringModal);
