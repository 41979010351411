import React, { useState, useGlobal } from "reactn";
import { getDaysInMonth } from "date-fns";

const ExtendedDayModal = ({ child, onClose, setChildStatus, goBack }) => {
  const [isLargerThanThreshold, setIsLargerThanThreshold] = useState(false);
  const [useChildState, setChildState] = useState(child);
  const [prog, setProg] = useGlobal("myProgramInfo");
  const latefee = 25;
  const handleChange = (e, v) => {
    setChildState({
      ...useChildState,
      status: { ...useChildState.status, [e]: v }
    });
  };

  // const calculateChildExtendedFee = () => {
  //   const m = useChildState.status.notified
  //     ? useChildState.status.extendedMinutes / 60
  //     : useChildState.status.extendedMinutes;
  //   const t =
  //     (useChildState.status.notified
  //       ? useChildState.status.extendedHours
  //       : useChildState.status.extendedHours * 60) +
  //     m -
  //     (useChildState.status.notified ? 0.0833 : 5);
  //   const rate =
  //     useChildState.child.student.interval === "week"
  //       ? useChildState.child.student.amount /
  //         useChildState.child.student.numDays /
  //         (useChildState.child.student.hoursTo -
  //           useChildState.child.student.hoursFrom)
  //       : useChildState.child.student.amount /
  //         ((getDaysInMonth(new Date()) / 7) *
  //           useChildState.child.student.numDays) /
  //         (useChildState.child.student.hoursTo -
  //           useChildState.child.student.hoursFrom);
  //   const v = latefee
  //     ? (useChildState.status.notified ? rate : latefee / 100) * t > 50
  //       ? 50
  //       : (useChildState.status.notified ? rate : latefee / 100) * t
  //     : (useChildState.status.notified ? rate : 0.5) * t > 25
  //     ? 25
  //     : (useChildState.status.notified ? rate : 0.5) * t;
  //   const finalVal = parseFloat(v).toFixed(2);
  //   if (useChildState.status.fee !== finalVal && finalVal >= 0)
  //     handleChange("fee", parseFloat(v).toFixed(2));
  //   return finalVal >= 0 ? finalVal : parseFloat(0).toFixed(2);
  // };

  const updateHours = e => {
    setChildState({
      ...useChildState,
      status: {
        ...useChildState.status,
        extendedHours: isNaN(parseInt(e.target.value))
          ? ""
          : parseInt(e.target.value) > 2
          ? 2
          : parseInt(e.target.value),
        extendedMinutes:
          parseInt(e.target.value) >= 2 &&
          useChildState.status.extendedMinutes > 0
            ? 0
            : useChildState.status.extendedMinutes
      }
    });
  };

  const updateMinutes = e => {
    setChildState({
      ...useChildState,
      status: {
        ...useChildState.status,
        extendedMinutes:
          useChildState.status.extendedHours >= 2
            ? 0
            : parseInt(e.target.value) < 0
            ? 0
            : parseInt(e.target.value) > 59
            ? 59
            : isNaN(parseInt(e.target.value))
            ? ""
            : parseInt(e.target.value)
      }
    });
  };

  return (
    <>
      <h3>
        <span className="attendance-blue">
          <i className="fas fa-plus"></i> Extended Day:
        </span>
        <br />
        {`${useChildState.child.student.fullName} (${useChildState.child.day})`}
        :
      </h3>
      <div className="modal-row">
        Choose to charge the student a fee for an extended period:
      </div>
      <div className="modal-row">
        <strong>Were you notified in advance?</strong>
        <br />
        <input
          type="radio"
          name="notified"
          value={true}
          className="m-2"
          onChange={() =>
            setChildState({
              ...useChildState,
              status: { ...useChildState.status, notified: true, percent: 0 }
            })
          }
          checked={useChildState.status.notified}
        />
        Yes
        <br />
        <input
          type="radio"
          name="notified"
          value={false}
          className="m-2"
          onChange={() =>
            setChildState({
              ...useChildState,
              status: { ...useChildState.status, notified: false, fee: 0 }
            })
          }
          checked={!useChildState.status.notified}
        />
        No
      </div>
      {useChildState.status.notified ? (
        <>
          <div className="modal-row">
            <strong>Extended for:</strong>
            <div className="row mt-2 mb-2">
              <div className="col-6 d-flex">
                <input
                  type="number"
                  min={0}
                  value={useChildState.status.extendedHours}
                  onChange={updateHours}
                  className="w-100 mr-2"
                />
                Hours
              </div>
              <div className="col-6 d-flex">
                <input
                  type="number"
                  min={0}
                  max={59}
                  value={useChildState.status.extendedMinutes}
                  onChange={updateMinutes}
                  className="w-100 mr-2"
                />
                Minutes
              </div>
            </div>
          </div>
          <div className="modal-row">
            <strong>Fee:</strong>
            <div className="my-2">
              <input
                type="number"
                min={0}
                max={50}
                // value={useChildState.status.fee}
                onChange={e => {
                  if (e.target.value <= 50) {
                    handleChange("fee", parseFloat(e.target.value).toFixed(2));
                    setIsLargerThanThreshold(false);
                  } else {
                    setIsLargerThanThreshold(true);
                  }
                }}
                className="w-100 mr-2"
              />
              {isLargerThanThreshold && (
                <span style={{ color: "red" }}>Cannot be more than $50</span>
              )}
              {/* ${calculateChildExtendedFee()} */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="modal-row">
            <strong>Late By:</strong>
            <div className="row mt-2 mb-2">
              <div className="col-6 d-flex">
                <input
                  type="number"
                  min={0}
                  max={2}
                  value={useChildState.status.extendedHours}
                  onChange={updateHours}
                  className="w-100 mr-2"
                />
                Hours
              </div>
              <div className="col-6 d-flex">
                <input
                  type="number"
                  min={0}
                  max={59}
                  value={useChildState.status.extendedMinutes}
                  onChange={updateMinutes}
                  className="w-100 mr-2"
                />
                Minutes
              </div>
            </div>
          </div>
          <div className="modal-row">
            <strong>Late Fee:</strong>
            <div className="my-2">
              <input
                type="number"
                min={0}
                max={50}
                // value={useChildState.status.fee}
                onChange={e =>
                  handleChange("fee", parseFloat(e.target.value).toFixed(2))
                }
                className="w-100 mr-2"
              />
              {/* ${calculateChildExtendedFee()} */}
            </div>
          </div>
        </>
      )}
      <button
        disabled={isLargerThanThreshold}
        onClick={() => {
          setChildStatus({
            ...useChildState,
            status: { ...useChildState.status, status: "Extended" }
          });
          onClose();
        }}
      >
        SAVE
      </button>
      <button onClick={() => onClose()}>CANCEL</button>
      <button onClick={() => goBack()}>BACK</button>
    </>
  );
};

export default ExtendedDayModal;
