import { setGlobal, addReducer } from "reactn";
import axios from "axios";

const notification = {
  tourRequest: false,
  newApplication: false,
  failedPayment: false,
  upcomingEvents: false,
  waitlist: false
};

setGlobal({ notificationSettings: notification });

const profile = {
  displayName: "",
  email: "",
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: ""
};

setGlobal({ profileSettings: profile });

addReducer("getAccountSettings", async (global, dispatch) => {});

addReducer("uploadProfilePhoto", async (global, dispatch, photo) => {
  setGlobal({ loading: true });

  try {
    const ep = `${process.env.REACT_APP_API}/par/account/photo`;
    const res = await axios.post(ep, photo);

    if (!res.data.data.email) {
      let children = global.children;
      children[children.findIndex(c => res.data.data.id === c.id)] =
        res.data.data;
      return {
        ...global,
        ...res.data.data,
        loading: false,
        lastAPICall: res,
        children: children
      };
    } else {
      return {
        ...global,
        loading: false,
        lastAPICall: res,
        dsUser: { ...global.dsUser, ...res.data.data }
      };
    }
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
