import React from "reactn";
import { StepIndicator } from "../../../components/UI";
import Setup from "./Setup";
import AdditionalDetails from "./AdditionalDetails";
import GetPaid from "./GetPaid";
import Customization from "./Customization";
// import Email from "./EmailConnect";
import StepBar from "../../../components/UI/StepBar";
import Validators from "../../../validators";
import Axios from "axios";

class OrganizationalProfile extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      step: 0,
      profile_completion_validation: null,
      timezone: this.global.organizationInfo.timezone
    };
    this.titles = [
      "Organization Profile",
      "Additional Details",
      "Get Paid! Link account",
      // "Connect your Email",
      "Customization"
    ];
    this.validate = [
      () =>
        Validators.Partners.Profile.ValidateOrganization(
          this.global.organizationInfo
        ),
      () =>
        Validators.Partners.Profile.ValidateAddDetails(
          this.global.organizationInfo
        ),
      () =>
        Validators.Partners.Profile.ValidateLikedBankAccount(
          this.global.organizationInfo
        ),
      () =>
        Validators.Partners.Profile.ValidateOrgCustomization(
          this.global.organizationInfo
        )
    ];
    this.steps = [
      { name: "Organization Profile", validateFn: this.validate[0] },
      { name: "Additional Details", validateFn: this.validate[1] },
      { name: "Get Paid! Link account", validateFn: this.validate[2] },
      // { name: "Connect your Email", validateFn: _ => true },
      { name: "Customization", validateFn: this.validate[3] } //this.validate[3]
    ];
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Organization Profile", to: "/profile" }
      ]
    });
  }

  async componentDidMount() {
    if (!this.global.organizationInfo) {
      this.dispatch.profile_reset();
      return;
    }
    const params = this.props.location.query;
    if (params && params.code) {
      const ep =
        params.use === "gmail"
          ? `${process.env.REACT_APP_API}/partners/gmail/processToken`
          : `${process.env.REACT_APP_API}/partners/organization_profile/stripe`;
      const res = await Axios.post(ep, { code: params.code });
      if (res.data.success) {
        params.use === "gmail"
          ? this.setGlobal({
              organizationInfo: {
                ...this.global.organizationInfo,
                google_token: res.data.data.token,
                google_mail: res.data.data.email
              }
            })
          : this.setGlobal({
              organizationInfo: {
                ...this.global.organizationInfo,
                stripeId: res.data.data.stripeId,
                manual_payments: false,
                idempotentKeyStripe: res.data.data.idempotentKeyStripe
              }
            });
        this.setState({ step: params.use === "gmail" ? 3 : 2 });
        this.dispatch.saveOrganizationalProfile();
        // The below line is used to remove the code param from the URL to ensure a duplicate request is not made to stripe that invalidates the account
        this.props.history.replace(
          {},
          "",
          this.props.location.search
            .replace(/[\?&]my_parameter=[^&]+/, "")
            .replace(/^&/, "?")
        );
      } else {
      }
    }
  }

  saveProfile = async e => {
    const { step } = this.state;

    this.setGlobal({
      organizationInfo: {
        ...this.global.organizationInfo,
        profile_completion_validation: this.state.profile_completion_validation,
        timezone: this.state.timezone
      }
    });

    switch (e.target.name) {
      case "next":
        this.setState({ step: step + 1 });
        await this.dispatch.saveOrganizationalProfile();
        break;
      case "back":
        this.setState({ step: step - 1 });
        await this.dispatch.saveOrganizationalProfile();
        break;
      case "save":
        await this.dispatch.saveOrganizationalProfile();
        break;
      default:
        return;
    }
  };

  changePage = async page => {
    await this.dispatch.saveOrganizationalProfile();

    this.setState({ step: page });
  };

  getPage = () => {
    if (this.global.organizationInfo) {
      switch (this.state.step) {
        case 0:
          return <Setup />;
        case 1:
          return <AdditionalDetails />;
        case 2:
          return <GetPaid />;
        // case 3:
        //   return <Email />;
        case 3:
          return (
            <Customization
              timezone={this.state.timezone}
              updateTimeZoneFn={tzName => {
                this.setState({ timezone: tzName });
              }}
            />
          );
        default:
          return <Setup />;
      }
    }
  };

  checkValid() {
    const { step } = this.state;
    return this.validate[step] && !this.validate[step]();
  }

  getCompletion() {
    let completed = 0;

    for (let v of this.validate) {
      completed += v();
    }
    this.setState({ profile_completion_validation: completed });

    return (completed / this.validate.length) * 100;
  }

  render() {
    return (
      <div className="container-fluid">
        {/* Commented out to make space for Top Bar */}
        {/* <div className="cont">
          <h1 className="events-title-header">Organization Profile</h1>
        </div> */}
        <StepBar
          {...{
            steps: this.steps,
            changeFn: this.changePage,
            current: this.state.step
          }}
        />
        <StepIndicator value={this.getCompletion()} />
        {this.getPage()}
        <div className="cont mb-5">
          <div className="buttons w-100">
            <button
              onClick={this.saveProfile}
              disabled={this.state.step === 0}
              name="back"
              className="nextbtn"
            >
              BACK
            </button>
            <button onClick={this.saveProfile} name="save" className="savebtn">
              SAVE
            </button>
            <button
              onClick={this.saveProfile}
              disabled={
                this.state.step === this.titles.length - 1 || this.checkValid()
              }
              name="next"
              className="nextbtn"
            >
              NEXT
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default OrganizationalProfile;
