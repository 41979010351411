import React from "reactn";
import LeftSideBox from "../../components/milestones/LeftSideBox";
import RightSideBox from "../../components/milestones/RightSideBox";
import axios from "axios";

class Milestones extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      milestones: [],
      selectedGroup: 0
    };
    this.updateMilestone = this.updateMilestone.bind(this);
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "Milestones"]
    });
  }

  async updateMilestone(e, milestone) {
    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/edu/milestone`;
    try {
      let ms = {
        dsUserId: params.id,
        milestone: milestone.id,
        status: e.key
      };
      this.setGlobal({ loading: true });

      const res = await axios.post(ep, ms);
      const upd = res.data.data;
      let msGroup = this.state.milestones[this.state.selectedGroup];

      let statusProg = msGroup.progress.findIndex(
        mi => mi.milestone === milestone.id
      );
      let statusComp = msGroup.earned.findIndex(
        mi => mi.milestone === milestone.id
      );

      if (statusProg > -1) {
        msGroup.progress.splice(statusProg, 1);
      } else if (statusComp > -1) {
        msGroup.earned.splice(statusComp, 1);
      }

      if (upd.status === "Complete") msGroup.earned.push(upd);
      else if (upd.status === "In Progress") msGroup.progress.push(upd);
      this.setState({
        milestones: [
          ...this.state.milestones.map(g => (g.id === msGroup.id ? msGroup : g))
        ]
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  async componentDidMount() {
    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/edu/milestone`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(params.id ? `${ep}/${params.id}` : ep);
      this.setState({
        ...res.data.data
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  selectGroup = num => {
    this.setState({ selectedGroup: num });
  };

  render() {
    return (
      <div className="container-fluid milestones">
        {this.state.milestones ? (
          <>
            <LeftSideBox
              milestones={this.state.milestones}
              selectGroup={this.selectGroup}
            />
            <RightSideBox
              milestones={this.state.milestones}
              selectedGroup={this.state.selectedGroup}
              updateMilestone={this.updateMilestone}
            />
          </>
        ) : null}
      </div>
    );
  }
}

export default Milestones;
