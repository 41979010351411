import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";

class WaitlistModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      educatorMessage: null
    };

    this.handleWaitList = this.handleWaitList.bind(this);
  }

  handleWaitList = async () => {
    this.setGlobal({ loading: true });
    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/edu/enrollment/application/${params.slug}/${params.time}`;
    try {
      const res = await axios.post(ep, {
        action: "Waitlist",
        message: this.state.educatorMessage
      });
      this.setGlobal({ loading: false, lastAPICall: res });
      if (res.data.success) {
        this.props.history.push("/educators/applications");
      }
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  };

  render() {
    return (
      <div className="request-modal-cont">
        <div
          className="request-modal"
          style={{ border: "2px solid rgb(223, 132, 78)" }}
        >
          <h3>Waitlist</h3>
          <label htmlFor="message">Waitlist Reason</label>
          <textarea
            name="educatorMessage"
            id="message"
            type="text"
            className="form-control"
            placeholder="Leave an optional message for the parent..."
            onChange={e => {
              this.setState({
                [e.target.name]: e.target.value
              });
            }}
          />
          <div className="modal-footer">
            <button
              onClick={this.props.onClose}
              className="mx-2 backbtn btn profile-btn"
            >
              Cancel
            </button>
            <button onClick={this.handleWaitList} className="btn profile-btn">
              Waitlist
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(WaitlistModal);
