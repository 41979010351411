import React from "reactn";
import SectionThree from "./forms/SectionThree";
import "../../../assets/css/componentSpecificCss/eventListings.css";

class CreatePackage extends React.Component {
  render() {
    return (
      <div className="cont boxes-row mt-50px center-box">
        <SectionThree
          match={this.props.match}
          vals={this.global.myApplicationProfileInfo}
          partyType={this.props.partyType}
        />
      </div>
    );
  }
}

export default CreatePackage;
