import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  SWITCH
} from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class EditFreeOnline extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      total_price: 0,
      description: "",
      isCapEnabled: false,
      planCap: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async savePlan(e) {
    e.preventDefault();
    const obj = { ...this.state, type: "Free" };
    obj.description.substring(0, 50);

    const parsedPlans = this.global.online.plans.map(e =>
      typeof e === "string" ? JSON.parse(e) : e
    );
    const updatedPlans = parsedPlans.map(e =>
      e.id === this.props.selectedItem.id
        ? { ...this.props.selectedItem, description: this.state.description, isCapEnabled: this.state.isCapEnabled, planCap: this.state.planCap}
        : e
    );

    updatedPlans.forEach(e => {
      if (e.id === this.props.selectedItem.id) {
        if (this.global.online.total_spots && this.global.online.total_spots < e.planCap) {
          e.planCap = this.global.online.total_spots;
        } else {
          if (parseInt(e.planCap) > 200) {
            e.planCap = 200;
          }
        }
      }
    });

    this.setGlobal(
      {
        online: {
          ...this.global.online,
          plans: updatedPlans
        }
      },
      () => {
        this.dispatch.saveVirtual();
      }
    );
    this.props.onClose();
  }

  componentDidMount() {
    this.setState({ 
      description: this.props.selectedItem.description,
      isCapEnabled: this.props.selectedItem.isCapEnabled,
      planCap: this.props.selectedItem.planCap
     });
  }

  getBaseModalFields() {
    const descriptionInput = {
      type: STRING_INPUT,
      data: {
        name: "Description",
        required: false,
        info: "max 50 char",
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description.substring(0, 50)
      }
    };

    const AddCap = {
      type: SWITCH,
      data: {
        name: "Enable Program Plan Cap",
        isDisabled: false,
        checked: this.state.isCapEnabled,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: e =>
          this.setState({
            isCapEnabled: e.target.checked,
            planCap: ""
          })
      }
    };

    const PlanCap = {
      type: STRING_INPUT,
      data: {
        type: "number",
        name: this.global.online.total_spots ? `Plan Cap (Max: ${this.global.online.total_spots})` : "Plan Cap",
        required: false,
        value: this.state.planCap,
        placeholder: "Enter Cap",
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleChange: e => {
          this.setState({
            planCap: e.target.value
          });
        }
      }
    }; 

    const fields = [];

    fields.push(descriptionInput);
    if (!this.global.online.isRecurring) {
      fields.push(AddCap);
    }
    if (this.state.isCapEnabled) {
      fields.push(PlanCap);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Save",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      handleClick: e => this.savePlan(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Edit Free Plan",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

    render() {
        return <BaseModal {...this.getBaseModalProps()} />;
    }
}

export default withRouter(EditFreeOnline);