import React from "react";
import { DdIcon, CMale, CFemale, CUnisex } from "../../../assets";
import { format } from "date-fns";
import { numToHours } from "../../../lib/util";

class Finance extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      tab: 0
    };
  }

  mapDays = days => {
    let finalDays = [];
    let ds = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    for (let d of ds) {
      if (days.findIndex(dw => dw.name === d) > -1) finalDays.push(d);
    }
    return finalDays.join(", ");
  };

  render() {
    const { enrollments } = this.props;
    return (
      <div className="col-xl-6">
        <div className="tablebox" style={{ height: "80%" }}>
          <div className="tableheader">
            <h2>
              MY ENROLLMENTS <img src={DdIcon} alt="" />
            </h2>
            <ul className="leftlist">
              {enrollments.map((enr, i) => (
                <li key={i}>
                  <button
                    onClick={() => this.setState({ tab: i })}
                    className={this.state.tab === i ? "active" : ""}
                  >
                    Child {i + 1}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          {!enrollments.length ? (
            <p className="p-5 text-center w-100">
              Nothing to show...{" "}
              <span style={{ fontStyle: "italic" }}>Yet!</span>
            </p>
          ) : (
            <div className="tablebody padded">
              <div className="row">
                <div className="col-md-12">
                  <div className="name">
                    <img
                      src={
                        enrollments[this.state.tab].photo
                          ? `${process.env.REACT_APP_DS_FILES_S3}/${
                              enrollments[this.state.tab].photo
                            }`
                          : enrollments[this.state.tab].gender === "Male"
                          ? CMale
                          : enrollments[this.state.tab].gender === "Female"
                          ? CFemale
                          : CUnisex
                      }
                      alt={enrollments[this.state.tab].fullName}
                      style={{ width: 80, borderRadius: "50%" }}
                    />
                    <h2 className="parent-child">
                      {enrollments[this.state.tab].fullName}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row bl-bbody mb-1">
                <div className="col-3">
                  <p>Program:</p>
                </div>
                <div className="col-9">
                  <p>{enrollments[this.state.tab].programName}</p>
                </div>
              </div>
              <div className="row bl-bbody mb-1">
                <div className="col-3">
                  <p>Duration:</p>
                </div>
                <div className="col-9">
                  <p>
                    {format(
                      new Date(enrollments[this.state.tab].startDate),
                      "LLL d, yyyy"
                    )}
                    {enrollments[this.state.tab].endDate
                      ? ` - ${format(
                          new Date(enrollments[this.state.tab].endDate),
                          "LLL d, yyyy"
                        )}`
                      : " - N/A"}
                  </p>
                </div>
              </div>
              <div className="row bl-bbody mb-1">
                <div className="col-3">
                  <p>Days:</p>
                </div>
                <div className="col-9">
                  <p>{this.mapDays(enrollments[this.state.tab].daysOfWeek)}</p>
                  <p>
                    <span>
                      ({numToHours(enrollments[this.state.tab].hoursFrom)} -{" "}
                      {numToHours(enrollments[this.state.tab].hoursTo)})
                    </span>
                  </p>
                </div>
              </div>
              <div className="row bl-bbody mb-1">
                <div className="col-3">
                  <p>Tuition:</p>
                </div>
                <div className="col-9">
                  <p>
                    ${enrollments[this.state.tab].amount} /{" "}
                    {enrollments[this.state.tab].interval}
                  </p>
                </div>
              </div>
              <div className="row bl-bbody mb-1">
                <div className="col-3">
                  <p>Status:</p>
                </div>
                <div className="col-9">
                  <p>
                    {new Date(enrollments[this.state.tab].startDate) >
                    new Date()
                      ? "Upcoming Enrollment"
                      : "Currently Enrolled"}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default Finance;
