import React from "reactn";
import MyChildForm from "./forms/myChild";

class MyChildrenPage extends React.Component {
  handChange = e => {
    const vals = this.global.myApplicationChildren.map(c =>
      c.id === e.id ? e : c
    );
    this.setGlobal({ myApplicationChildren: vals });
  };

  handRemove = e => {
    this.dispatch.removeApplicationChild(e);
  };

  render() {
    return (
      <div className="cont boxes-row mt-50px">
        <div className="box">
          <div className="bgofbox">
            <div className="header">
              <div className="row">
                <div className="col-12 d-flex">
                  <h2>CHILDREN</h2>
                </div>
              </div>
            </div>
            <div>
              <div className="row p-3">
                <div
                  className="col-12 text-center"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  <p className="mb-3">
                    Any children under your care (such as own children) under
                    the age of 4 must be listed.
                  </p>
                  <p>
                    If you do not have any children under your care under the
                    age of 4 check the box below.
                  </p>
                </div>
              </div>
              <div className="row p-3">
                <div
                  className="col-2"
                  style={{ textAlign: "center", paddingTop: 13 }}
                >
                  <input
                    type="checkbox"
                    checked={
                      !this.global.myApplicationProfileInfo.hasChildren
                        ? "checked"
                        : ""
                    }
                    onChange={() =>
                      this.setGlobal({
                        myApplicationProfileInfo: {
                          ...this.global.myApplicationProfileInfo,
                          hasChildren:
                            !this.global.myApplicationProfileInfo.hasChildren
                        }
                      })
                    }
                    disabled={
                      this.global.profile.applicationSubmitted &&
                      this.global.dsUser.accountType !== "Admin"
                    }
                  />
                </div>
                <div className="col-10">
                  <div className="formbody">
                    <h3>
                      I certify that there are no children under the age of 4,
                      and/or that children under 4 under my care have been
                      listed accurately in this page.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.global.myApplicationChildren.map((reg, i) => (
          <MyChildForm
            key={i}
            vals={reg}
            itemIndex={i}
            onChange={this.handChange}
            onRemove={this.handRemove}
          />
        ))}
        <button
          className="addchild"
          onClick={() =>
            !this.global.profile.applicationSubmitted ||
            this.global.dsUser.accountType === "Admin"
              ? this.dispatch.addApplicationChild()
              : null
          }
        >
          <h3>Add Child</h3>
        </button>
      </div>
    );
  }
}

export default MyChildrenPage;
