import React from "react";
import { Button, withStyles } from "@material-ui/core";
const colors = {
  green: "#2AA897",
  lightGreen: "#4FBE75",
  white: "#FFFFFF",
  black: "#000000",
  gray: "#F4F6FC",
  midGray: "#EEEEEE",
  borderGray: "#BBBBBB",
  darkGray: "#808080"
};

const styles = {
  root: {
    backgroundColor: `${colors.white}`,
    border: `1px solid ${colors.green}`,
    borderRadius: 7,
    color: `${colors.black}`,
    fontWeight: "bold",
    height: 54,
    width: 140
  }
};

const StyledButtonOutline = props => {
  const { classes, ...other } = props;
  return <Button className={classes.root} {...other}></Button>;
};

export default withStyles(styles)(StyledButtonOutline);
