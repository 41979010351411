import React from "reactn";
import { Link } from "react-router-dom";
import { CheckSuc, CMale, CFemale, CUnisex } from "../../../assets";

class FillApplication extends React.PureComponent {
  constructor(props) {
    super(props);

    if (!this.global.enrApp || !this.global.enrApp.selectedChild) {
      this.props.history.push(
        `/parents/interested-programs/${props.match.params.slug}/choose-child`
      );
    }
  }
  render() {
    const glbl = this.global.school;
    return (
      <div className="container-fluid new_application new_application_5">
        {!glbl ? null : (
          <>
            <div className="cont">{/* <h1>New Application</h1> */}</div>

            <div className="cont mt-5 pt-5">
              <div className="contbox_5">
                <div className="imgdiv">
                  <div className="img">
                    <img
                      style={{ maxWidth: 245, maxHeight: 245 }}
                      src={
                        this.global.enrApp.selectedChild.photo
                          ? `${process.env.REACT_APP_DS_FILES_S3}/${this.global.enrApp.selectedChild.photo}`
                          : this.global.enrApp.selectedChild.gender === "Male"
                          ? CMale
                          : this.global.enrApp.selectedChild.gender === "Female"
                          ? CFemale
                          : CUnisex
                      }
                      alt=""
                    />
                    <img src={CheckSuc} className="check" alt="" />
                  </div>
                  <h1>You have successfully applied to:</h1>
                  <h2>{this.global.school.programName}</h2>
                  <Link
                    to="/parents/applications"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    View application
                  </Link>
                  <div className="row">
                    <div className="col-md-12">
                      <p className="btns">
                        <Link
                          to="/parents/interested-programs"
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          NEW APPLICATION
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default FillApplication;
