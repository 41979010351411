import React, { useEffect, useGlobal, useRef } from "reactn";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";

import DailyIframe from "@daily-co/daily-js";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: `calc(100vh - 62px)`,
    border: "0"
  }
}));

const MeetingComponent = () => {
  const [virtualCall, setVirtualCall] = useGlobal("virtualCall");
  const classes = useStyles();
  let iframeRef = useRef(null);

  let frame = null;
  useEffect(() => {
    if (!virtualCall.isCallFrameCreated) {
      return null;
    }

    frame = DailyIframe.wrap(iframeRef.current, {
      showLeaveButton: true,
      showFullscreenButton: true
    });
    frame.join({ url: virtualCall.room_url, token: virtualCall.meeting_token });
    frame.on("left-meeting", () => {
      toast("You have left the Meeting", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      // setVirtualCall({ ...virtualCall, isCallFrameCreated: false })
      window.location.reload();
    });
    frame.on("load-attempt-failed", () => {
      toast.error("Error Loading IFrame", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    });
    frame.on("joined-meeting", () => {
      toast.success("Joined Meeting", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    });
    return () => {
      if (frame) {
        frame.destroy();
        setVirtualCall({ ...virtualCall, isCallFrameCreated: false });
      }
    };
  }, []);
  return (
    <>
      <iframe
        className={classes.root}
        title="Video Call Iframe"
        ref={iframeRef}
        allow="camera; microphone; fullscreen"
      ></iframe>
    </>
  );
};

MeetingComponent.propTypes = {};

export default MeetingComponent;
