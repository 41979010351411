import axios from 'axios';
import { differenceInMinutes } from 'date-fns';

const cacheName = 'connections-cache';

export async function fetchConnectionsData(partnerId) {
    const ep = `${process.env.REACT_APP_API}/partners/connections/${true}`;
    const cacheKey = ep + `${partnerId}`;
    if ('caches' in window) {
        try {
            const cache = await caches.open(cacheName);
            const cached_date = await cache.match(cacheKey+'date');
            const date = cached_date ? await cached_date.json() : null;

            const cached_connections = await cache.match(cacheKey);

            if (date && cached_connections && differenceInMinutes(new Date, new Date(date)) < 10) {
                console.log("fetching data from cache");
                return await cached_connections.json();
            } else {
                console.log("fetching data from endpoint");
                console.time("fetching from endpoint");
                const result = await axios.get(ep);
                console.timeEnd("fetching from endpoint");
                if (result.data.success) {
                    console.time("storing into cache");
                    const dataForCache = new Response(JSON.stringify(result.data.data.connections));
                    await cache.put(cacheKey, dataForCache);
                    const cachedDate = new Response(JSON.stringify(new Date));
                    await cache.put(cacheKey+'date', cachedDate);
                    console.timeEnd("storing into cache");
                    return result.data.data.connections;
                } else {
                    console.error("Failed to fetch connections data");
                }
            }
        } catch (error) {
            console.log("An error occurred while fetching connections data:", error);
            console.log("Attempting to get data from endpoint...");
            try {
                const result = await axios.get(ep);
                if (result.data.success) {
                    return result.data.data.connections;
                }
            } catch (err) {
                console.log("Failed to retrieve connections data from endpoint");
                console.error(err);
                throw err;
            }
        }
    } else {
        console.error("The Cache API is not supported by this browser.");
    }
}

export async function deleteConnectionsData() {
    if ('caches' in window) {
        try {
            await caches.delete(cacheName);
        } catch (error) {
            console.error(`Error in deleted cache`);
        }
    } 
}