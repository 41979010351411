import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
// import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { toast } from "react-toastify";
import {
  ModalBanner,
  Avatar1b,
  Avatar2b,
  Avatar3b,
  Avatar4b,
  Avatar5b,
  Avatar6b,
  Avatar7b,
  Avatar8b,
  Avatar9b
} from "../../assets";
import { Dropdown } from "semantic-ui-react";
import "../../assets/css/componentSpecificCss/inviteUserModal.css";
import _ from "lodash";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  TEXT_AREA,
  DROPDOWN,
  DATE_PICKER
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class AddWorkLog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      task: "",
      start_time: "",
      end_time: "",
      notes: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {}

  convertMinutesToTimestamp = minutes => {
    const milliseconds = minutes * 60 * 1000;
    const timestamp = new Date(milliseconds).toISOString();

    return timestamp;
  };

  async submitWorkLog() {
    const date = new Date(this.state.date);
    const date2 = new Date(this.state.date);

    const startTime = date.setMinutes(parseInt(this.state.start_time));
    const endTime = date2.setMinutes(parseInt(this.state.end_time));

    const ep4 = `${process.env.REACT_APP_API}/partners/users/work-log/${this.props.match.params.id}`;
    const results4 = await axios.post(ep4, {
      task_name: this.state.task,
      work_date: this.state.date,
      start_time: new Date(startTime),
      end_time: new Date(endTime),
      notes: this.state.notes
    });
    toast.success("Work Log Entry Submitted");
    this.props.onRefresh();
    this.props.onClose();
  }

  getBaseModalFields() {
    const taskField = {
      type: STRING_INPUT,
      data: {
        name: "Task / Activity",
        required: true,
        handleChange: e => {
          this.setState({
            task: e.target.value
          });
        },
        value: this.state.task
      },
      validators: {
        validateSubmit: () =>
          this.state.task !== undefined && this.state.task !== ""
      }
    };

    const dateField = {
      type: DATE_PICKER,
      data: {
        name: "Date",
        required: true,
        placeholder: "Pick a date",
        dateFormat: "MMMM d, yyyy",
        value: this.state.date,
        handleChange: date => this.setState({ date: date })
      },
      validators: { validateSubmit: notEmptyString(this.state.date) }
    };

    const startTimeField = {
      type: DROPDOWN,
      data: {
        name: "Start Time",
        required: true,
        placeholder: "Please Select",
        value: this.state.start_time,
        handleChange: e => {
          this.setState({
            start_time: e.target.value
          });
        },
        choices: this.timeOptions()
      },
      validators: { validateSubmit: () => this.state.start_time !== "" }
    };

    const endTimeField = {
      type: DROPDOWN,
      data: {
        name: "End Time",
        required: true,
        placeholder: "Please Select",
        value: this.state.end_time,
        handleChange: e => {
          this.setState({
            end_time: e.target.value
          });
        },
        choices: this.timeOptions()
      },
      validators: { validateSubmit: () => this.state.end_time !== "" }
    };

    const notesField = {
      type: TEXT_AREA,
      data: {
        name: "Notes",
        value: this.state.notes,
        handleChange: e => {
          this.setState({ notes: e.target.value });
        }
      }
    };

    return [taskField, dateField, startTimeField, endTimeField, notesField];
  }

  timeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const period = hour < 12 ? "AM" : "PM";
        const hour12 = hour % 12 || 12;
        const time = `${hour12.toString().padStart(2, "0")}:${minute
          .toString()
          .padStart(2, "0")} ${period}`;
        options.push({
          value: hour * 60 + minute,
          key: hour * 60 + minute,
          text: time
        });
      }
    }
    return options;
  };

  getBaseModalButtons = () => {
    const createLog = {
      name: "Create Log",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.submitWorkLog()
    };

    return [createLog];
  };

  getBaseModalProps = () => {
    return {
      title: "Create Work Log",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ===================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(AddWorkLog);
