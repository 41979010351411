import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";
import { withRouter } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";

class MyCredentialsDocumentationStatusForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.customForm = this.customForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      type: ""
    };
    this.fileChange = this.fileChange.bind(this);
    this.uploadNew = this.uploadNew.bind(this);
  }

  uploadNew(type) {
    if (
      !this.global.profile.programSubmitted ||
      this.global.dsUser.accountType === "Admin"
    ) {
      this.setState({
        type: type
      });
      const uploadEle = document.getElementById("uploadDocumentation");
      uploadEle.click();
    }
  }

  viewDocument(e) {
    window.open(`${process.env.REACT_APP_DS_FILES_S3}/${e.target.value}`);
    e.target.value = "";
  }

  async fileChange(e) {
    const params = this.props.match.params;
    if (e.target.files.length) {
      const fd = new FormData();
      fd.set("type", this.state.type);
      if (params.id) {
        fd.set("dsUserId", params.id);
      }
      fd.set("file", e.target.files[0]);
      this.setGlobal({ loading: true });
      try {
        const ep = `${process.env.REACT_APP_API}/edu/application/documentation`;
        const res = await axios.post(ep, fd);
        if (res.data.success) {
          this.setGlobal({
            loading: false,
            lastAPICall: res,
            myApplicationDocumentationStatus: {
              ...this.global.myApplicationDocumentationStatus,
              [`${this.state.type}Docs`]: [
                ...(this.global.myApplicationDocumentationStatus[
                  `${this.state.type}Docs`
                ]
                  ? this.global.myApplicationDocumentationStatus[
                      `${this.state.type}Docs`
                    ]
                  : []),
                res.data.data.file
              ]
            }
          });
        } else {
          this.setGlobal({
            loading: false,
            lastAPICall: res
          });
        }
        this.setState({ type: "" });
      } catch (err) {
        console.error(err.message);
        this.setGlobal({ loading: false, lastAPICall: null });
      }
      // this.dispatch.uploadProgramPhoto(fd);
    }
  }

  handleChange = (e, v) => {
    if (
      !this.global.profile.applicationSubmitted ||
      this.global.dsUser.accountType === "Admin"
    ) {
      this.setGlobal({
        myApplicationDocumentationStatus: {
          ...this.global.myApplicationDocumentationStatus,
          [e]: v
        }
      });
    }
  };

  customForm = () => {
    const vals = this.global.myApplicationDocumentationStatus;
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-6">
              <h3>Vulnerable Sector Check</h3>
            </div>
            <div className="col-6 d-flex">
              <select
                value={vals.vsc}
                name="vsc"
                onChange={e =>
                  this.handleChange(e.target.name, parseInt(e.target.value))
                }
                className="w-100"
              >
                <option value={0}>Not Applicable</option>
                <option value={1}>Pending</option>
                <option value={2}>Verified</option>
              </select>
            </div>
            <div className="col-9 mt-2 d-flex">
              <select className="w-100" onChange={this.viewDocument}>
                <option value="">View Document</option>
                {vals.vscDocs &&
                  vals.vscDocs.map((v, i) => {
                    return (
                      <option value={v} key={i}>
                        {v}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-3 mt-2 d-flex">
              <button
                type="button"
                style={{ background: "none" }}
                onClick={() => this.uploadNew("vsc")}
              >
                Upload
              </button>
            </div>
            <div className="col-3 mt-2 d-flex">
              <h3>Date Issued:</h3>
            </div>
            <div className="col-9 mt-2 d-flex">
              <DatePicker
                selected={vals.vscIssued ? new Date(vals.vscIssued) : ""}
                onChange={e => this.handleChange("vscIssued", e)}
              />
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-6">
              <h3>Criminal Check</h3>
            </div>
            <div className="col-6 d-flex">
              <select
                value={vals.criminalCheck}
                onChange={e =>
                  this.handleChange(e.target.name, parseInt(e.target.value))
                }
                className="w-100"
                name="criminalCheck"
              >
                <option value={0}>Not Applicable</option>
                <option value={1}>Pending</option>
                <option value={2}>Verified</option>
              </select>
            </div>
            <div className="col-9 mt-2 d-flex">
              <select className="w-100" onChange={this.viewDocument}>
                <option value="">View Document</option>
                {vals.criminalCheckDocs &&
                  vals.criminalCheckDocs.map((v, i) => {
                    return (
                      <option value={v} key={i}>
                        {v}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-3 mt-2 d-flex">
              <button
                type="button"
                style={{ background: "none" }}
                onClick={() => this.uploadNew("criminalCheck")}
              >
                Upload
              </button>
            </div>
            <div className="col-3 mt-2 d-flex">
              <h3>Date Issued:</h3>
            </div>
            <div className="col-9 mt-2 d-flex">
              <DatePicker
                selected={
                  vals.criminalCheckIssued
                    ? new Date(vals.criminalCheckIssued)
                    : ""
                }
                onChange={e => this.handleChange("criminalCheckIssued", e)}
              />
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-6">
              <h3>First Aid</h3>
            </div>
            <div className="col-6 d-flex">
              <select
                value={vals.firstAid}
                onChange={e =>
                  this.handleChange(e.target.name, parseInt(e.target.value))
                }
                className="w-100"
                name="firstAid"
              >
                <option value={0}>Not Applicable</option>
                <option value={1}>Pending</option>
                <option value={2}>Verified</option>
              </select>
            </div>
            <div className="col-9 mt-2 d-flex">
              <select className="w-100" onChange={this.viewDocument}>
                <option value="">View Document</option>
                {vals.firstAidDocs &&
                  vals.firstAidDocs.map((v, i) => {
                    return (
                      <option value={v} key={i}>
                        {v}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-3 mt-2 d-flex">
              <button
                type="button"
                style={{ background: "none" }}
                onClick={() => this.uploadNew("firstAid")}
              >
                Upload
              </button>
            </div>
            <div className="col-3 mt-2 d-flex">
              <h3>Date Issued:</h3>
            </div>
            <div className="col-9 mt-2 d-flex">
              <DatePicker
                selected={
                  vals.firstAidIssued ? new Date(vals.firstAidIssued) : ""
                }
                onChange={e => this.handleChange("firstAidIssued", e)}
              />
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-6">
              <h3>Child CPR</h3>
            </div>
            <div className="col-6 d-flex">
              <select
                value={vals.childCPR}
                onChange={e =>
                  this.handleChange(e.target.name, parseInt(e.target.value))
                }
                className="w-100"
                name="childCPR"
              >
                <option value={0}>Not Applicable</option>
                <option value={1}>Pending</option>
                <option value={2}>Verified</option>
              </select>
            </div>
            <div className="col-9 mt-2 d-flex">
              <select className="w-100" onChange={this.viewDocument}>
                <option value="">View Document</option>
                {vals.childCPRDocs &&
                  vals.childCPRDocs.map((v, i) => {
                    return (
                      <option value={v} key={i}>
                        {v}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-3 mt-2 d-flex">
              <button
                type="button"
                style={{ background: "none" }}
                onClick={() => this.uploadNew("childCPR")}
              >
                Upload
              </button>
            </div>
            <div className="col-3 mt-2 d-flex">
              <h3>Date Issued:</h3>
            </div>
            <div className="col-9 mt-2 d-flex">
              <DatePicker
                selected={
                  vals.childCPRIssued ? new Date(vals.childCPRIssued) : ""
                }
                onChange={e => this.handleChange("childCPRIssued", e)}
              />
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-6">
              <h3>Health Assessment</h3>
            </div>
            <div className="col-6 d-flex">
              <select
                value={vals.healthAssessment}
                onChange={e =>
                  this.handleChange(e.target.name, parseInt(e.target.value))
                }
                className="w-100"
                name="healthAssessment"
              >
                <option value={0}>Not Applicable</option>
                <option value={1}>Pending</option>
                <option value={2}>Verified</option>
              </select>
            </div>
            <div className="col-9 mt-2 d-flex">
              <select className="w-100" onChange={this.viewDocument}>
                <option value="">View Document</option>
                {vals.healthAssessmentDocs &&
                  vals.healthAssessmentDocs.map((v, i) => {
                    return (
                      <option value={v} key={i}>
                        {v}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-3 mt-2 d-flex">
              <button
                type="button"
                style={{ background: "none" }}
                onClick={() => this.uploadNew("healthAssessment")}
              >
                Upload
              </button>
            </div>
            <div className="col-3 mt-2 d-flex">
              <h3>Date Issued:</h3>
            </div>
            <div className="col-9 mt-2 d-flex">
              <DatePicker
                selected={
                  vals.healthAssessmentIssued
                    ? new Date(vals.healthAssessmentIssued)
                    : ""
                }
                onChange={e => this.handleChange("healthAssessmentIssued", e)}
              />
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-6">
              <h3>Immunization</h3>
            </div>
            <div className="col-6 d-flex">
              <select
                value={vals.immunization}
                onChange={e =>
                  this.handleChange(e.target.name, parseInt(e.target.value))
                }
                className="w-100"
                name="immunization"
              >
                <option value={0}>Not Applicable</option>
                <option value={1}>Pending</option>
                <option value={2}>Verified</option>
              </select>
            </div>
            <div className="col-9 mt-2 d-flex">
              <select className="w-100" onChange={this.viewDocument}>
                <option value="">View Document</option>
                {vals.immunizationDocs &&
                  vals.immunizationDocs.map((v, i) => {
                    return (
                      <option value={v} key={i}>
                        {v}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-3 mt-2 d-flex">
              <button
                type="button"
                style={{ background: "none" }}
                onClick={() => this.uploadNew("immunization")}
              >
                Upload
              </button>
            </div>
            <div className="col-3 mt-2 d-flex">
              <h3>Date Issued:</h3>
            </div>
            <div className="col-9 mt-2 d-flex">
              <DatePicker
                selected={
                  vals.immunizationIssued
                    ? new Date(vals.immunizationIssued)
                    : ""
                }
                onChange={e => this.handleChange("immunizationIssued", e)}
              />
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-6">
              <h3>Registered ECE</h3>
            </div>
            <div className="col-6 d-flex">
              <select
                value={vals.registeredECE}
                onChange={e =>
                  this.handleChange(e.target.name, parseInt(e.target.value))
                }
                className="w-100"
                name="registeredECE"
              >
                <option value={0}>Not Applicable</option>
                <option value={1}>Pending</option>
                <option value={2}>Verified</option>
              </select>
            </div>
            <div className="col-9 mt-2 d-flex">
              <select className="w-100" onChange={this.viewDocument}>
                <option value="">View Document</option>
                {vals.registeredECEDocs &&
                  vals.registeredECEDocs.map((v, i) => {
                    return (
                      <option value={v} key={i}>
                        {v}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-3 mt-2 d-flex">
              <button
                type="button"
                style={{ background: "none" }}
                onClick={() => this.uploadNew("registeredECE")}
              >
                Upload
              </button>
            </div>
            <div className="col-4 mt-2">
              <h3>RECE#</h3>
            </div>
            <div className="col-8 mt-3">
              <input
                name="registeredECENumber"
                value={vals.registeredECENumber || ""}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                className="w-100"
              />
            </div>
            <div className="col-3 mt-2 d-flex">
              <h3>Date Issued:</h3>
            </div>
            <div className="col-9 mt-2 d-flex">
              <DatePicker
                selected={vals.receIssued ? new Date(vals.receIssued) : ""}
                onChange={e => this.handleChange("receIssued", e)}
              />
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-6">
              <h3>Registered Educator</h3>
            </div>
            <div className="col-6 d-flex">
              <select
                value={vals.registeredEducator}
                onChange={e =>
                  this.handleChange(e.target.name, parseInt(e.target.value))
                }
                className="w-100"
                name="registeredEducator"
              >
                <option value={0}>Not Applicable</option>
                <option value={1}>Pending</option>
                <option value={2}>Verified</option>
              </select>
            </div>
            <div className="col-9 mt-2 d-flex">
              <select className="w-100" onChange={this.viewDocument}>
                <option value="">View Document</option>
                {vals.registeredEducatorDocs &&
                  vals.registeredEducatorDocs.map((v, i) => {
                    return (
                      <option value={v} key={i}>
                        {v}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-3 mt-2 d-flex">
              <button
                type="button"
                style={{ background: "none" }}
                onClick={() => this.uploadNew("registeredEducator")}
              >
                Upload
              </button>
            </div>
            <div className="col-3 mt-2 d-flex">
              <h3>Date Issued:</h3>
            </div>
            <div className="col-9 mt-2 d-flex">
              <DatePicker
                selected={
                  vals.registeredEducatorIssued
                    ? new Date(vals.registeredEducatorIssued)
                    : ""
                }
                onChange={e => this.handleChange("registeredEducatorIssued", e)}
              />
            </div>
          </div>

          <input
            type="file"
            style={{ display: "none" }}
            id="uploadDocumentation"
            onChange={this.fileChange}
            accept="image/*,.pdf"
          />
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"DOCUMENTATION STATUS"}
        customForm={this.customForm()}
      />
    );
  }
}

export default withRouter(MyCredentialsDocumentationStatusForm);
