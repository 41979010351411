import React from "reactn";
import { Table } from "../../components/UI";
import Axios from "axios";

class Search extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
    this.headings = [
      { id: "displayName", label: "Name" },
      { id: "email", label: "Email" },
      { id: "phone", label: "Phone" },
      {
        id: "accountType",
        label: "Account Type"
      }
    ];
  }
  async fetchPhone(search) {
    const searchphone = search.trim().replace(/[^0-9]/g, "%");
    const ep = `${process.env.REACT_APP_API}/adm/parent/notes/phonelup`;
    const results = await Axios.post(ep, {
      phone: searchphone
    });
    this.setState({
      data: results.data.data
    });
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="cont">
          <div className="row">
            <div className="col-md-6">
              <h1>Search By Phone</h1>
            </div>
          </div>
        </div>
        <div className="cont">
          <div className="tablecont">
            <div className="table-responsive">
              <input
                type="text"
                onChange={e => this.fetchPhone(e.target.value)}
                onKeyPress={e => {
                  if (isNaN(e.key)) {
                    e.preventDefault();
                  }
                }}
                placeholder="Search by phone..."
                style={{
                  marginBottom: 15,
                  width: "100%",
                  borderRadius: 5,
                  border: "2px solid #E3E6F0",
                  fontSize: "13px",
                  padding: 3,
                  outline: 0
                }}
              />
              <Table headings={this.headings} data={this.state.data} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Search;
