import React from "reactn";
import { withRouter } from "react-router-dom";
import { TabbedTable } from "../../UI";
import { format, differenceInMonths } from "date-fns";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import axios from "axios";

//{differenceInCalendarYears(new Date(), new Date(childDate))}
class ApplicationsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        NEW: [],
        ACCEPTED: [],
        WAITLISTED: [],
        DECLINED: []
      }
    };

    this.tabs = ["NEW", "ACCEPTED", "WAITLISTED", "DECLINED"];
    this.headings = {
      NEW: [
        { id: "fullName", label: "Student" },
        { id: "age", label: "Age", customCell: this.ageCell },
        { id: "plan", label: `Plan`, customCell: this.planCell },
        { id: "duration", label: "Duration", customCell: this.durationCell },
        {
          id: "applicationDate",
          label: "Application Submitted",
          customCell: this.applicationDateCell
        },
        {
          id: "availability",
          label: "Availability",
          customCell: this.availabilityCell
        },
        { id: "action", label: "Actions" }
      ],
      ACCEPTED: [
        { id: "fullName", label: "Student" },
        { id: "age", label: "Age", customCell: this.ageCell },
        { id: "plan", label: `Plan`, customCell: this.planCell },
        { id: "duration", label: "Duration", customCell: this.durationCell },
        {
          id: "acceptedDate",
          label: "Accepted Date",
          customCell: this.acceptedDateCell
        },
        {
          id: "depositDue",
          label: "Deposit Due",
          customCell: this.depositDueCell
        },
        {
          id: "ordernothing",
          label: "Order",
          customCell: r => (
            <td key={`Key`} style={{ whiteSpace: "nowrap" }}>
              <IconButton
                style={{
                  padding: "5px 2px",
                  visibility:
                    this.state.data.ACCEPTED.indexOf(r) === 0
                      ? "hidden"
                      : "visible"
                }}
                onClick={_ => {
                  const goDown =
                    this.state.data.ACCEPTED[
                      this.state.data.ACCEPTED.indexOf(r) - 1
                    ];
                  const goUp = r;
                  const order = goUp.order;
                  goUp.order = goDown.order;
                  goDown.order = order;
                  const ep = `${process.env.REACT_APP_API}/edu/enrollment/switch`;
                  axios.post(ep, {
                    first: goUp,
                    second: goDown
                  });
                  this.setState({
                    data: {
                      ...this.state.data,
                      ACCEPTED: [...this.state.data.ACCEPTED].sort(
                        (prev, next) =>
                          parseInt(prev.order) < parseInt(next.order) ? -1 : 1
                      )
                    }
                  });
                }}
              >
                <ArrowUpward />
              </IconButton>
              <IconButton
                style={{
                  padding: "5px 2px",
                  visibility:
                    this.state.data.ACCEPTED.indexOf(r) ===
                    this.state.data.ACCEPTED.length - 1
                      ? "hidden"
                      : "visible"
                }}
                onClick={_ => {
                  const goUp =
                    this.state.data.ACCEPTED[
                      this.state.data.ACCEPTED.indexOf(r) + 1
                    ];
                  const goDown = r;
                  const order = goUp.order;
                  goUp.order = goDown.order;
                  goDown.order = order;
                  const ep = `${process.env.REACT_APP_API}/edu/enrollment/switch`;
                  axios.post(ep, {
                    first: goUp,
                    second: goDown
                  });
                  this.setState({
                    data: {
                      ...this.state.data,
                      ACCEPTED: [...this.state.data.ACCEPTED].sort(
                        (prev, next) =>
                          parseInt(prev.order) < parseInt(next.order) ? -1 : 1
                      )
                    }
                  });
                }}
              >
                <ArrowDownward />
              </IconButton>
            </td>
          )
        },
        { id: "action", label: "Actions" }
      ],
      WAITLISTED: [
        { id: "fullName", label: "Student" },
        { id: "age", label: "Age", customCell: this.ageCell },
        { id: "plan", label: `Plan`, customCell: this.planCell },
        { id: "duration", label: "Duration", customCell: this.durationCell },
        {
          id: "applicationDate",
          label: "Application Submitted",
          customCell: this.applicationDateCell
        },
        { id: "reconsideration", label: "Reconsideration" },
        { id: "educatorMessage", label: "Educator Message" },
        {
          id: "ordernothing",
          label: "Order",
          customCell: (r, i) => (
            <td key={`Key`} style={{ whiteSpace: "nowrap" }}>
              <IconButton
                style={{
                  padding: "5px 2px",
                  visibility:
                    this.state.data.WAITLISTED.indexOf(r) === 0
                      ? "hidden"
                      : "visible"
                }}
                onClick={_ => {
                  const goDown =
                    this.state.data.WAITLISTED[
                      this.state.data.WAITLISTED.indexOf(r) - 1
                    ];
                  const goUp = r;
                  const order = goUp.order;
                  goUp.order = goDown.order;
                  goDown.order = order;
                  const ep = `${process.env.REACT_APP_API}/edu/enrollment/switch`;
                  axios.post(ep, {
                    first: goUp,
                    second: goDown
                  });
                  this.setState({
                    data: {
                      ...this.state.data,
                      WAITLISTED: [...this.state.data.WAITLISTED].sort(
                        (prev, next) =>
                          parseInt(prev.order) < parseInt(next.order) ? -1 : 1
                      )
                    }
                  });
                }}
              >
                <ArrowUpward />
              </IconButton>
              <IconButton
                style={{
                  padding: "5px 2px",
                  visibility:
                    this.state.data.WAITLISTED.indexOf(r) ===
                    this.state.data.WAITLISTED.length - 1
                      ? "hidden"
                      : "visible"
                }}
                onClick={_ => {
                  const goUp =
                    this.state.data.WAITLISTED[
                      this.state.data.WAITLISTED.indexOf(r) + 1
                    ];
                  const goDown = r;
                  const order = goUp.order;
                  goUp.order = goDown.order;
                  goDown.order = order;
                  const ep = `${process.env.REACT_APP_API}/edu/enrollment/switch`;
                  axios.post(ep, {
                    first: goUp,
                    second: goDown
                  });
                  this.setState({
                    data: {
                      ...this.state.data,
                      WAITLISTED: [...this.state.data.WAITLISTED].sort(
                        (prev, next) =>
                          parseInt(prev.order) < parseInt(next.order) ? -1 : 1
                      )
                    }
                  });
                }}
              >
                <ArrowDownward />
              </IconButton>
            </td>
          )
        },
        { id: "action", label: "Actions" }
      ],
      DECLINED: [
        { id: "fullName", label: "Student" },
        { id: "age", label: "Age", customCell: this.ageCell },
        { id: "plan", label: `Plan`, customCell: this.planCell },
        { id: "duration", label: "Duration", customCell: this.durationCell },
        {
          id: "rejectedDate",
          label: "Rejected Date",
          customCell: this.rejectedDateCell
        },
        { id: "educatorMessage", label: "Educator Message" },

        { id: "action", label: "actions" }
      ]
    };

    this.actions = [{ name: "VIEW", action: this.viewApplication }];
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/par/applications/edu`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      const accApp = res.data.data
        .filter(d => {
          return d.educatorResponse === "Accepted";
        })
        .sort((prev, next) =>
          parseInt(prev.order) < parseInt(next.order) ? -1 : 1
        );
      const rejApp = res.data.data.filter(d => {
        return d.educatorResponse === "Rejected";
      });
      const waitApp = res.data.data
        .filter(d => {
          return d.educatorResponse === "Waitlisted";
        })
        .sort((prev, next) =>
          parseInt(prev.order) < parseInt(next.order) ? -1 : 1
        );
      const pendApp = res.data.data.filter(d => {
        return d.educatorResponse === "New";
      });
      this.setState({
        data: {
          ACCEPTED: accApp,
          DECLINED: rejApp,
          WAITLISTED: waitApp,
          NEW: pendApp
        }
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      console.error(err);
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  ageCell = (r, i) => {
    const months = differenceInMonths(new Date(), new Date(r.birthDate));
    const years = Math.floor(months / 12);
    const rem = months % 12;
    return (
      <td key={`${i}-a`}>
        {years >= 1 && rem >= 1
          ? `${years} years, ${rem} months`
          : years >= 1 && rem === 0
          ? `${years} years`
          : `${rem} months`}
      </td>
    );
  };

  rejectedDateCell = (r, i) => {
    return (
      <td key={`${i}-rd`}>{format(new Date(r.updatedAt), "LLL dd, yyyy")}</td>
    );
  };

  acceptedDateCell = (r, i) => {
    return (
      <td key={`${i}-acd`}>
        {format(new Date(r.acceptedDate), "LLL dd, yyyy")}
      </td>
    );
  };

  durationCell = (r, i) => {
    return (
      <td key={`${i}-dur`}>
        {format(new Date(r.startDate), "LLL dd, yyyy")} -{" "}
        {r.endDate ? format(new Date(r.endDate), "LLL dd, yyyy") : "N/A"}
      </td>
    );
  };

  planCell = (r, i) => {
    return (
      <td key={`${i}-pl`}>
        {r.categoryName}
        <br />
        {r.numDays} days/week
      </td>
    );
  };

  applicationDateCell = (r, i) => {
    return (
      <td key={`${i}-ad`}>
        {format(new Date(r.submittedDate), "LLL dd, yyyy")}
      </td>
    );
  };

  availabilityCell = (r, k) => {
    let cName = "";
    if (r.hasAvailability) {
      cName = "green-circle";
    } else {
      cName = "red-circle";
    }
    return (
      <td key={`${k}-avail`}>
        <div
          className={cName}
          style={{ width: "14px", height: "14px", borderRadius: "14px" }}
        ></div>
      </td>
    );
  };

  depositDueCell = (r, i) => {
    return (
      <td key={`${i}-dd`}>
        {format(new Date(r.depositDueDate), "LLL dd, yyyy")}
      </td>
    );
  };

  viewApplication = row => {
    this.props.history.push(
      `/educators/applications/${row.slug}/${row.submittedDate}`
    );
  };
  render() {
    return (
      <TabbedTable
        tabs={this.tabs}
        headings={this.headings}
        data={this.state.data}
        actions={this.actions}
      />
    );
  }
}

export default withRouter(ApplicationsTable);
