import React from "reactn";
import { TabbedTable } from "../../components/UI";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import axios from "axios";
import { format } from "date-fns";
import FReg from "../../components/table/admins/FRegistrationModal";
import ComModal from "../../components/table/admins/CommentsModal";
import Reschedule from "../../components/modals/RescheduleModal";
import Cancel from "../../components/modals/CancelModal";
class BookedTours extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      filter: "",
      detailsModalShown: false,
      modalData: [],
      original: {}
    };
    this.tabs = ["New", "Upcoming", "Expired"];
    this.headings = {
      New: [
        { id: "displayName", label: "Parent" },
        {
          id: "programName",
          label: "Program",
          customCell: this.childCountCell
        },

        {
          id: "email",
          label: "Email"
        },
        {
          id: "phone",
          label: "Phone"
        },
        // { id: "programName", label: "Program Name" },
        {
          id: "requestedTourDate",
          label: "Tour Date",
          customCell: row => (
            <td key={`${row.id}-acc`}>
              {format(new Date(row.requestedTourDate), "LLL dd, yyyy - h:mm a")}
            </td>
          )
        },
        {
          id: "tourStatus",
          label: "Tour Status",
          customCell: this.acceptedCell
        },
        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        {
          id: "currentIP",
          label: "Location / IP",
          customCell: this.location
        },
        {
          id: "signInCount",
          label: "Sign in Count",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`}>
                {r.signInCount ? r.signInCount : "Unavailable"}
              </td>
            );
          }
        },

        { id: "action", label: "Action(s)" },
        {
          id: "actionres",
          label: "",
          customCell: this.rescheduleTourCell
        },
        {
          id: "cancel",
          label: "",
          customCell: this.cancelTourCell,
          customStyle: { width: 180 }
        }
      ],
      Upcoming: [
        { id: "displayName", label: "Parent" },
        {
          id: "programName",
          label: "Program",
          customCell: this.childCountCell
        },
        {
          id: "email",
          label: "Email"
        },
        {
          id: "phone",
          label: "Phone"
        },
        // { id: "programName", label: "Program Name" },
        {
          id: "requestedTourDate",
          label: "Tour Date",
          customCell: row => (
            <td key={`${row.id}-acc`}>
              {format(new Date(row.requestedTourDate), "LLL dd, yyyy - h:mm a")}
            </td>
          )
        },
        {
          id: "tourStatus",
          label: "Tour Status",
          customCell: this.acceptedCell
        },
        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        {
          id: "currentIP",
          label: "Location / IP",
          customCell: this.location
        },
        {
          id: "signInCount",
          label: "Sign in Count",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`}>
                {r.signInCount ? r.signInCount : "Unavailable"}
              </td>
            );
          }
        },
        { id: "action", label: "Action(s)" },
        {
          id: "actionres",
          label: "",
          customCell: this.rescheduleTourCell
        },
        {
          id: "cancel",
          label: "",
          customCell: this.cancelTourCell
        }
      ],
      Expired: [
        { id: "displayName", label: "Parent" },
        {
          id: "programName",
          label: "Program",
          customCell: this.childCountCell
        },
        {
          id: "email",
          label: "Email"
        },
        {
          id: "phone",
          label: "Phone"
        },
        // { id: "programName", label: "Program Name" },
        {
          id: "requestedTourDate",
          label: "Tour Date",
          customCell: row => (
            <td key={`${row.id}-acc`}>
              {format(new Date(row.requestedTourDate), "LLL dd, yyyy - h:mm a")}
            </td>
          )
        },
        {
          id: "tourStatus",
          label: "Tour Status",
          customCell: this.acceptedCell
        },

        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        {
          id: "currentIP",
          label: "Location / IP",
          customCell: this.location
        },
        {
          id: "signInCount",
          label: "Sign in Count",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`}>
                {r.signInCount ? r.signInCount : "Unavailable"}
              </td>
            );
          }
        },
        { id: "action", label: "Action(s)" }
      ]
    };
    this.actions = [
      { name: "View Details", action: this.viewDetail },
      { name: "Notes", action: this.viewComments }
    ];
    this.filterData = this.filterData.bind(this);
  }

  viewDetail = async row => {
    this.setState({ detailsModalShown: true, modalData: row });
  };
  viewComments = async row => {
    this.setState({ commentsModalShown: true, modalData: row });
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt((Date.now() - new Date(r.insys).getTime()) / (3600000 * 24))}
      </td>
    );
  };
  cancelTourCell = (r, i) => {
    if (new Date(r.requestedTourDate).getTime() > Date.now() && !r.canceled) {
      return (
        <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
          <button
            onClick={() =>
              this.setState({ cancelModalOpen: true, tourCancel: r })
            }
          >
            CANCEL TOUR
          </button>
        </td>
      );
    }
    return <td></td>;
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/booked`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setState({
        data: {
          New: res.data.data.filter(
            tour =>
              new Date().getTime() - new Date(tour.createdAt).getTime() <=
                3600000 * 24 &&
              new Date(tour.requestedTourDate).getTime() > Date.now()
          ),
          Upcoming: res.data.data.filter(
            tour => new Date(tour.requestedTourDate).getTime() > Date.now()
          ),
          Expired: res.data.data
            .filter(
              tour => new Date(tour.requestedTourDate).getTime() < Date.now()
            )
            .reverse()
        },
        original: res.data.data
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  acceptedCell = (r, i) => {
    return (
      <td key={`${i}-${r.id}-acc`}>
        {r.canceled
          ? `Canceled by : ${r.canceledBy}`
          : r.accepted
          ? "Tour Accepted"
          : Date.now() > new Date(r.requestedTourDate).getTime()
          ? "Tour Expired"
          : !r.rescheduled
          ? `Pending Acceptation from ${r.educatorname}`
          : parseInt(r.rescheduledBy) === parseInt(r.dsUserId)
          ? `Pending Acceptation from ${r.educatorname}`
          : `Pending Acceptation from ${r.displayName}`}
      </td>
    );
  };

  rescheduleTourCell = (r, i) => {
    if (new Date(r.requestedTourDate).getTime() > Date.now() && !r.canceled) {
      return (
        <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
          <button
            onClick={() =>
              this.setState({ rescheduleModalOpen: true, tourReschedule: r })
            }
          >
            RESCHEDULE TOUR
          </button>
        </td>
      );
    }
    return <td key={`${i}-btn`} colSpan={1}></td>;
  };

  filterData(val) {
    this.setState({
      data: {
        New: this.state.original.filter(
          parent =>
            parent.displayName
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase()) &&
            new Date().getTime() - new Date(parent.createdAt).getTime() <=
              3600000 * 24 &&
            new Date(parent.requestedTourDate).getTime() > Date.now()
        ),
        Upcoming: this.state.original.filter(
          parent =>
            parent.displayName
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase()) &&
            new Date(parent.requestedTourDate).getTime() > Date.now()
        ),
        Expired: this.state.original.filter(
          parent =>
            parent.displayName
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase()) &&
            new Date(parent.requestedTourDate).getTime() < Date.now()
        )
      }
    });
  }

  render() {
    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          <div className="cont">
            <h1>Booked Tours</h1>
          </div>
          <FReg
            open={this.state.detailsModalShown}
            data={this.state.modalData}
            onClose={() =>
              this.setState({
                detailsModalShown: false,
                modalData: null
              })
            }
          />
          <ComModal
            open={this.state.commentsModalShown}
            data={this.state.modalData}
            onClose={() =>
              this.setState({
                commentsModalShown: false,
                modalData: null
              })
            }
          />
          {this.state.rescheduleModalOpen && (
            <Reschedule
              tour={this.state.tourReschedule}
              onClose={() => this.setState({ rescheduleModalOpen: false })}
              admin={true}
            />
          )}
          {this.state.cancelModalOpen && (
            <Cancel
              tour={this.state.tourCancel}
              actor="Admin"
              onClose={() => this.setState({ cancelModalOpen: false })}
            />
          )}
          <div className="cont">
            <div className="tablecont">
              <input
                type="text"
                onChange={e => this.filterData(e.target.value)}
                placeholder="Search by name..."
                style={{
                  marginBottom: 15,
                  width: "100%",
                  borderRadius: 5,
                  border: "2px solid #E3E6F0",
                  fontSize: "13px",
                  padding: 3,
                  outline: 0
                }}
              />
              <div className="table-responsive">
                <TabbedTable
                  headings={this.headings}
                  data={this.state.data}
                  tabs={this.tabs}
                  actions={this.actions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BookedTours;
