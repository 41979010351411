import React, { Component } from "reactn";
import axios from "axios";
import Date from "react-datepicker";
import {
  StripeProvider,
  CardElement,
  Elements,
  injectStripe
} from "react-stripe-elements";
import { toast } from "react-toastify";
class _CardForm extends React.Component {
  state = {
    errorMessage: "",
    cardName: ""
  };
  componentDidMount() {
    if (document.getElementsByClassName("StripeElement"))
      document.getElementsByClassName("StripeElement")[0].style.padding =
        "10px";
  }

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    } else {
      this.setState({ errorMessage: "" });
    }
  };

  handleSubmit = async evt => {
    evt.preventDefault();
    const ep = `${process.env.REACT_APP_API}/stripe/client`;
    if (this.props.stripe) {
      try {
        const token = await this.props.stripe.createToken({
          name: this.state.cardName
        });
        if (token.error)
          throw new Error("There was an error processing your card!");
        /*const res = await axios.post(ep, {
          source: token
        });
        if (res.data.success) {
          this.setGlobal({
            stripe: res.data.data
          });
          this.props.update(res.data.data);
          toast("Successfully saved payment info!");
        } else {
          toast(res.data.message);
        }
        this.setGlobal({ loading: false, lastAPICall: res });*/
      } catch (ex) {
        toast.error(ex.message);
        console.error(ex);
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    } else {
    }
  };

  render() {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px"
          }}
        >
          <label style={{ fontWeight: "normal", width: "30%" }}>
            Cardholder name:
          </label>
          <input
            type="text"
            style={{ flexGrow: "1" }}
            value={this.state.cardName}
            onChange={e => this.setState({ cardName: e.target.value })}
          />
        </div>
        <CardElement onChange={this.handleChange} style={{ padding: "10px" }} />
        <p style={{ color: "red" }}>{this.state.errorMessage}</p>
        <button
          type="button"
          onClick={e => this.handleSubmit(e)}
          style={{
            padding: "10px",
            display: "block",
            marginLeft: "auto",
            marginBottom: 0
          }}
        >
          Complete Order
        </button>
      </>
    );
  }
}
const CardForm = injectStripe(_CardForm);
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = { sections: [], days: {}, lastPage: false };
    this.elements = (element, label, type = undefined, width, secId) => {
      if (element === "input")
        return (
          <input
            type={type}
            style={{ width: width }}
            name={secId + label}
            onChange={e => this.setState({ [e.target.name]: e.target.value })}
            value={this.state[secId + label]}
          />
        );
      if (element === "textarea")
        return (
          <textarea
            type={type}
            rows={4}
            name={secId + label}
            style={{ width: width }}
            onChange={e => this.setState({ [e.target.name]: e.target.value })}
            value={this.state[secId + label]}
          ></textarea>
        );
      if (element === "days") {
        return (
          <div
            name={secId + label}
            className="row days"
            validation={"validDays"}
            style={{ width: width, margin: 0 }}
          >
            <div
              data-day="Monday"
              className={`day-monday day ${
                this.state[secId + label] && this.state[secId + label]["Monday"]
                  ? "selecta"
                  : ""
              }`}
              onClick={e =>
                this.setDay(e.target.getAttribute("data-day"), secId + label)
              }
            >
              M
            </div>
            <div
              data-day="Tuesday"
              className={`day-tuesday day ${
                this.state[secId + label] &&
                this.state[secId + label]["Tuesday"]
                  ? "selecta"
                  : ""
              }`}
              onClick={e =>
                this.setDay(e.target.getAttribute("data-day"), secId + label)
              }
            >
              Tu
            </div>
            <div
              data-day="Wednesday"
              className={`day-wednesday day ${
                this.state[secId + label] &&
                this.state[secId + label]["Wednesday"]
                  ? "selecta"
                  : ""
              }`}
              onClick={e =>
                this.setDay(e.target.getAttribute("data-day"), secId + label)
              }
            >
              W
            </div>
            <div
              data-day="Thursday"
              className={`day-thursday day ${
                this.state[secId + label] &&
                this.state[secId + label]["Thursday"]
                  ? "selecta"
                  : ""
              }`}
              onClick={e =>
                this.setDay(e.target.getAttribute("data-day"), secId + label)
              }
            >
              Th
            </div>
            <div
              data-day="Friday"
              className={`day-friday day ${
                this.state[secId + label] && this.state[secId + label]["Friday"]
                  ? "selecta"
                  : ""
              }`}
              onClick={e =>
                this.setDay(e.target.getAttribute("data-day"), secId + label)
              }
            >
              F
            </div>
            <div
              data-day="Saturday"
              className={`day-saturday day ${
                this.state[secId + label] &&
                this.state[secId + label]["Saturday"]
                  ? "selecta"
                  : ""
              }`}
              onClick={e =>
                this.setDay(e.target.getAttribute("data-day"), secId + label)
              }
            >
              Sa
            </div>
            <div
              data-day="Sunday"
              className={`day-sunday day ${
                this.state[secId + label] && this.state[secId + label]["Sunday"]
                  ? "selecta"
                  : ""
              }`}
              onClick={e =>
                this.setDay(e.target.getAttribute("data-day"), secId + label)
              }
            >
              Su
            </div>
          </div>
        );
      }
      if (element === "radios")
        return (
          <div
            style={{
              display: "flex",
              width: width
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <input
                type="radio"
                name={secId + label}
                value="Immediately"
                onChange={e =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />{" "}
              Immediately
            </div>
            <div style={{ marginRight: "10px" }}>
              <input
                type="radio"
                name={secId + label}
                value="1-3 Months"
                name={secId + label}
                value="Immediately"
                onChange={e =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />{" "}
              1-3 Months
            </div>
            <div style={{ marginRight: "10px" }}>
              <input
                type="radio"
                name={secId + label}
                value="3+ Months"
                name={secId + label}
                value="Immediately"
                onChange={e =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />{" "}
              3+ Months
            </div>
          </div>
        );
      if (element === "DOB")
        return (
          <Date
            className="w-100"
            showYearDropdown
            showMonthDropdown
            dateFormat="MMMM d, yyyy"
            name={label}
            onChange={e => this.setState({ [label]: e })}
            selected={this.state[label]}
          />
        );
    };
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/forms/${this.props.form}`;
    const result = await axios.get(ep);

    this.setState({ sections: result.data.data });
  }
  setDay(day, name) {
    let days;
    if (this.state[name]) days = { ...this.state[name] };
    else days = {};
    if (days[day]) delete days[day];
    else days[day] = true;
    this.setState({
      [name]: days
    });
  }
  getFields(fieldarr) {
    const fields = fieldarr.filter(e => JSON.parse(e).visible);
    const arr = [];
    for (let i = 0; i < fields.length; i += 2) {
      if (i + 1 < fields.length) {
        arr.push([fields[i], fields[i + 1]]);
        continue;
      }
      if (i < fields.length) {
        arr.push([fields[i]]);
      }
      if (i >= fields.length) {
        break;
      }
    }
    return arr;
  }
  componentDidUpdate() {
    if (document.getElementsByClassName("react-datepicker-wrapper")) {
      for (let dates of document.getElementsByClassName(
        "react-datepicker-wrapper"
      )) {
        dates.style.width = "50%";
      }
    }
  }
  render() {
    return (
      <div style={{ width: "80%", padding: "20px", margin: "0 auto" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <h4 className="link-header" onClick={_ => this.props.close()}>
            Selection Process
          </h4>
          <h4 className="link-header selection">Registration Form</h4>
          <h4
            className="link-header"
            onClick={_ => this.setState({ lastPage: true })}
          >
            Checkout Summary
          </h4>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flexGrow: 1 }}>
            <span style={{ color: "grey" }}>
              We need some additional information for your order!
            </span>
          </div>
          <div>
            <button
              style={{
                width: "100px",
                padding: "10px",
                backgroundColor: "#FFF",
                color: "rgb(52, 63, 100)",
                fontWeight: "bold",
                border: "1px solid rgb(52, 63, 100)"
              }}
            >
              Back
            </button>
            <button
              style={{
                width: "100px",
                padding: "10px",
                backgroundColor: "rgb(52, 63, 100)",
                color: "white",
                fontWeight: "bold",
                border: "1px solid rgb(52, 63, 100)",
                marginLeft: "20px"
              }}
            >
              Next
            </button>
          </div>
        </div>
        {!this.state.lastPage &&
          this.state.sections
            .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
            .map(sec => (
              <div style={{ width: "90%", margin: "20px auto" }}>
                <h5 style={{ margin: 0, color: "rgb(52, 63, 100)" }}>
                  {sec.form_section_name}
                </h5>
                <form style={{ marginTop: "30px" }}>
                  {this.getFields(sec.fields).map(row =>
                    row.length > 1 ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {row.map(f => {
                          const field = JSON.parse(f);
                          return (
                            <div
                              style={{
                                width: "50%",
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "20px"
                              }}
                            >
                              <label
                                style={{
                                  color: "grey",
                                  margin: "0 0px 0px 0px",
                                  fontWeight: "500",
                                  width: "30%"
                                }}
                                htmlFor={field.first_label}
                              >
                                {field.first_label}{" "}
                                {field.required && <span>*</span>}
                              </label>
                              {this.elements(
                                field.field,
                                field.first_label,
                                field.type,
                                "50%",
                                sec.id
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {row.map(f => {
                          const field = JSON.parse(f);
                          return (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "20px"
                              }}
                            >
                              <label
                                htmlFor={field.first_label}
                                style={{
                                  color: "grey",
                                  margin: "0 0px 0px 0px",
                                  fontWeight: "500",
                                  width: "15%"
                                }}
                              >
                                {field.first_label}
                                {field.required && <span>*</span>}
                              </label>
                              {this.elements(
                                field.field,
                                field.first_label,
                                field.type,
                                "75.1%",
                                sec.id
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )
                  )}
                </form>
              </div>
            ))}
        {this.state.lastPage && (
          <div
            style={{
              width: "60%",
              margin: "0 auto",
              height: "600px",
              backgroundColor: "rgb(249,249,249)",
              borderRadius: "20px",

              border: "1px solid black"
            }}
          >
            <div>
              <ul
                style={{ padding: "10px", borderBottom: "1px solid #dedede" }}
              >
                {JSON.parse(this.props.program.plans[this.props.selected])
                  .deposit && (
                  <li
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "15px 10px",
                      borderBottom: "1px solid #dedede"
                    }}
                  >
                    <p style={{ marginBottom: 0 }}>Deposit - Payable Now.</p>
                    <span style={{ fontWeight: "bold" }}>
                      $
                      {
                        JSON.parse(
                          this.props.program.plans[this.props.selected]
                        ).deposit
                      }
                    </span>
                  </li>
                )}
                {JSON.parse(this.props.program.plans[this.props.selected])
                  .tuition_rate && (
                  <li
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "15px 10px",
                      borderBottom: "1px solid #dedede"
                    }}
                  >
                    <p style={{ marginBottom: 0 }}>
                      Enrollment Fee - Payable on the 1st day of enrollment.
                    </p>
                    <span style={{ fontWeight: "bold" }}>
                      $
                      {
                        JSON.parse(
                          this.props.program.plans[this.props.selected]
                        ).tuition_rate
                      }
                    </span>
                  </li>
                )}
                <li
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "15px 10px",
                    borderBottom: "1px solid #dedede"
                  }}
                >
                  <p style={{ marginBottom: 0 }}>Subtotal</p>
                  <span style={{ fontWeight: "bold" }}>
                    $
                    {parseFloat(
                      parseFloat(
                        JSON.parse(
                          this.props.program.plans[this.props.selected]
                        ).tuition_rate
                      ) +
                        (JSON.parse(
                          this.props.program.plans[this.props.selected]
                        ).deposit
                          ? parseFloat(
                              JSON.parse(
                                this.props.program.plans[this.props.selected]
                              ).deposit
                            )
                          : 0)
                    ).toFixed(2)}
                  </span>
                </li>
                <li
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "15px 10px"
                  }}
                >
                  <p style={{ marginBottom: 0 }}>Total</p>
                  <span style={{ fontWeight: "bold" }}>
                    $
                    {parseFloat(
                      parseFloat(
                        JSON.parse(
                          this.props.program.plans[this.props.selected]
                        ).tuition_rate
                      ) +
                        (JSON.parse(
                          this.props.program.plans[this.props.selected]
                        ).deposit
                          ? parseFloat(
                              JSON.parse(
                                this.props.program.plans[this.props.selected]
                              ).deposit
                            )
                          : 0)
                    ).toFixed(2)}
                  </span>
                </li>
              </ul>
            </div>
            <div style={{ padding: "10px" }}>
              <h4 style={{ fontFamily: "inherit" }}>Payment Details</h4>
              <StripeProvider apiKey={process.env.REACT_APP_STRIPE}>
                <Elements>
                  <CardForm
                    update={e =>
                      this.setState({
                        paymentInfo: e
                      })
                    }
                  />
                </Elements>
              </StripeProvider>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Checkout;
