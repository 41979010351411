import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import { Switch } from "@material-ui/core";
import axios from "axios";
import FormPreviewModal from "../../../../components/modals/FormPreviewModal";

import Validators from "../../../../validators/validators";

const { isSelectedFormId } = Validators;

class SectionFour extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      days: {},
      forms: [],
      registrationForms: [],
      applicationForms: [],
      openPreviewModal: false,
      customers: []
    };
  }
  handleChange = (e, v) => {
    // this.setGlobal({
    //   membership: {
    //     ...this.global.membership,
    //     [e]: v
    //   }
    // });
    if (e === "participant_type_parent") {
      if (!this.global.membership.participant_type) {
        this.setGlobal({
          membership: {
            ...this.global.membership,
            participant_type: {
              parent: v,
              student: false,
              volunteer: false
            }
          }
        });
      } else {
        this.setGlobal({
          membership: {
            ...this.global.membership,
            participant_type: {
              parent: v,
              student:
                this.global.membership.participant_type?.student === true
                  ? true
                  : false,
              volunteer:
                this.global.membership.participant_type?.volunteer === true
                  ? true
                  : false
            }
          }
        });
      }
    } else if (e === "participant_type_student") {
      if (!this.global.membership.participant_type) {
        this.setGlobal({
          membership: {
            ...this.global.membership,
            participant_type: {
              parent: false,
              student: v,
              volunteer: false
            }
          }
        });
      } else {
        this.setGlobal({
          membership: {
            ...this.global.membership,
            participant_type: {
              parent:
                this.global.membership.participant_type?.parent === true
                  ? true
                  : false,
              student: v,
              volunteer:
                this.global.membership.participant_type?.volunteer === true
                  ? true
                  : false
            }
          }
        });
      }
    } else if (e === "participant_type_volunteer") {
      if (!this.global.membership.participant_type) {
        this.setGlobal({
          membership: {
            ...this.global.membership,
            participant_type: {
              parent: false,
              student: false,
              volunteer: v
            }
          }
        });
      } else {
        this.setGlobal({
          membership: {
            ...this.global.membership,
            participant_type: {
              parent:
                this.global.membership.participant_type?.parent === true
                  ? true
                  : false,
              student:
                this.global.membership.participant_type?.student === true
                  ? true
                  : false,
              volunteer: v
            }
          }
        });
      }
    } else {
      this.setGlobal({
        membership: {
          ...this.global.membership,
          [e]: v
        }
      });
    }
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/forms/list`;
    this.setGlobal({ loading: true });

    const programs = await axios.get(ep);

    // Get customers to check if events have any enrollments.
    const customers_ep = `${process.env.REACT_APP_API}/partners/events/customers/all/${this.global.membership.id}`;
    const customers = await axios.get(customers_ep);

    this.setState({
      forms: programs.data.data,
      registrationForms: programs.data.data.filter(
        e =>
          (e.form_name === "Registration" || e.form_name === "Waiver") &&
          !e.isArchived
      ),
      applicationForms: programs.data.data.filter(
        e => e.is_enabled && e.form_name === "Application" && !e.isArchived
      ),
      customers: customers.data.data
    });
    this.setGlobal({ loading: false, lastAPICall: programs });
  }

  customForm = () => {
    const vals = this.global.myProgramLocationHours;

    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="label-names">
                Application Required?
              </h3>
            </div>
            <div
              className="col-7 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                disabled={this.state.customers.length > 0}
                name="application_required"
                checked={!!this.global.membership.application_required}
                onClick={_ =>
                  this.handleChange(
                    "application_required",
                    !this.global.membership.application_required
                  )
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Select Checkout Form<span className="requiredField">*</span>
              </h3>
            </div>

            <div className="col-7 d-flex">
              {this.global.membership.formId ? (
                <formTypes.Select
                  options={[
                    // conditionally choose registrationForms or applicationForms
                    ...(() =>
                      !this.global.membership.application_required
                        ? this.state.registrationForms
                        : this.state.applicationForms)().map((v, k) => ({
                      label: v.form_name + ` (${v.form_sub_name})`,
                      value: v.id
                    }))
                  ]}
                  value={this.global.membership.formId}
                  onChange={v => this.handleChange("formId", v.target.value)}
                />
              ) : (
                <formTypes.Select
                  options={[
                    { label: "Please Select", value: 0 },
                    // conditionally choose registrationForms or applicationForms
                    ...(() =>
                      !this.global.membership.application_required
                        ? this.state.registrationForms
                        : this.state.applicationForms)().map((v, k) => ({
                      label: v.form_name + ` (${v.form_sub_name})`,
                      value: v.id
                    }))
                  ]}
                  value={this.global.membership.formId}
                  onChange={v => this.handleChange("formId", v.target.value)}
                />
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Checkout Form</h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`newapplications ${
                  !isSelectedFormId(this.global.membership.formId)
                    ? "button-disabled"
                    : ""
                }`}
                style={{
                  // regular colour or greyed out depending on the select field
                  border: `1px solid ${
                    isSelectedFormId(this.global.membership.formId)
                      ? "#7ea1ff"
                      : "#cccccc"
                  }`,
                  padding: "6px 20px",
                  color: isSelectedFormId(this.global.membership.formId)
                    ? "#7ea1ff"
                    : "#cccccc",
                  backgroundColor: "#FFF",
                  borderRadius: "15px",
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "700",
                  fontFamily: "Open Sans",
                  cursor: "pointer"
                }}
                onClick={() => {
                  if (isSelectedFormId(this.global.membership.formId)) {
                    this.setState({ openPreviewModal: true });
                  }
                }}
              >
                Preview
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="label-names">
                Advanced Option
              </h3>
            </div>
            <div
              className="col-7 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch checked={true} />
            </div>
          </div>
          {/* <div className="row">
            <div
              className="col-4"
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "30px"
              }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                Min Cancellation <br />
                (Prior to start date)
              </h3>
            </div>
            <div
              className="col-2 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input type="checkbox" />
            </div>
            <div
              className="col-5 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <formTypes.Select
                options={new Array(14)
                  .fill(1)
                  .map((_, k) => ({ label: `${k + 1} days`, value: k }))}
              ></formTypes.Select>
            </div>
          </div> */}
          <div className="row">
            <div
              className="col-4"
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "30px"
              }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                Offline Payments
              </h3>
            </div>
            <div
              className="col-2 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                checked={this.global.membership.manual_invoices}
                name="manual_invoices"
                onChange={e =>
                  this.handleChange(e.target.name, e.target.checked)
                }
              />
            </div>
            <div
              className="col-5 d-flex sub-labels"
              style={{ display: "flex", alignItems: "center" }}
            >
              Collect Invoices Offline
            </div>
          </div>
          <div className="row">
            <div
              className="col-4"
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "30px"
              }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                Disable EFT Payments
              </h3>
            </div>
            <div
              className="col-2 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                checked={this.global.membership.isEftDisabled}
                name="isEftDisabled"
                onChange={e =>
                  this.handleChange(e.target.name, e.target.checked)
                }
              />
            </div>
            <div
              className="col-5 d-flex sub-labels"
              style={{ display: "flex", alignItems: "center" }}
            >
              Disable EFT Debit payments, which are direct bank transfers that
              can take several days to process.
            </div>
          </div>

          <div className="row">
            <div
              className="col-4"
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "30px"
              }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                Limit Same Participant
              </h3>
            </div>
            <div
              className="col-2 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                checked={this.global.membership.allow_same_enrollment}
                name="allow_same_enrollment"
                onChange={e =>
                  this.handleChange(e.target.name, e.target.checked)
                }
              />
            </div>
            <div
              className="col-5 d-flex sub-labels"
              style={{ display: "flex", alignItems: "center" }}
            >
              Do NOT allow the same Participant to apply / register more than once
            </div>
          </div>

          <div className="row">
            <div
              className="col-4"
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "30px"
              }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                Disable Add-to-Cart
              </h3>
            </div>
            <div
              className="col-2 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                checked={this.global.membership.isCartDisabled}
                name="isCartDisabled"
                onChange={e =>
                  this.handleChange(e.target.name, e.target.checked)
                }
              />
            </div>
            <div
              className="col-5 d-flex sub-labels"
              style={{ display: "flex", alignItems: "center" }}
            >
              Disables the ability for adding this item to the cart
            </div>
          </div>
          <div className="row">
            <div
              className="col-4"
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "30px"
              }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                Participant Type
              </h3>
            </div>
            <div
              className="col-2 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                checked={
                  !this.global.membership.participant_type ||
                  this.global.membership.participant_type
                    ? this.global.membership.participant_type?.parent
                    : true
                  // this.testfunc()
                }
                name="participant_type_parent"
                // style={{ marginTop: "7px" }}
                onChange={e =>
                  this.handleChange(e.target.name, e.target.checked)
                }
              />
            </div>
            <div
              className="col-5 d-flex sub-labels"
              style={{ display: "flex", alignItems: "center" }}
            >
              Parent
            </div>
          </div>
          <div className="row">
            <div
              className="col-4"
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "30px"
              }}
            ></div>
            <div
              className="col-2 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                checked={
                  !this.global.membership.participant_type ||
                  this.global.membership.participant_type
                    ? this.global.membership.participant_type?.student
                    : true
                }
                name="participant_type_student"
                // style={{ marginTop: "-20px" }}
                onChange={e =>
                  this.handleChange(e.target.name, e.target.checked)
                }
              />
            </div>
            <div
              className="col-5 d-flex sub-labels"
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              Student
            </div>
          </div>
          <div className="row">
            <div
              className="col-4"
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "30px"
              }}
            ></div>
            <div
              className="col-2 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                checked={
                  !this.global.membership.participant_type ||
                  this.global.membership.participant_type
                    ? this.global.membership.participant_type?.volunteer
                    : true
                }
                name="participant_type_volunteer"
                onChange={e =>
                  this.handleChange(e.target.name, e.target.checked)
                }
              />
            </div>
            <div
              className="col-5 d-flex sub-labels"
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              Volunteer
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.openPreviewModal && (
          <FormPreviewModal
            selectedFormId={this.global.membership.formId}
            onClose={_ => this.setState({ openPreviewModal: false })}
          />
        )}
        <BoxForm
          handleChange={this.handleChange}
          name={"Form Selection"}
          customForm={this.customForm()}
        />
      </>
    );
  }
}

export default SectionFour;
