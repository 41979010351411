import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
// import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { toast } from "react-toastify";
import {
  ModalBanner,
  Avatar1b,
  Avatar2b,
  Avatar3b,
  Avatar4b,
  Avatar5b,
  Avatar6b,
  Avatar7b,
  Avatar8b,
  Avatar9b
} from "../../assets";
import { Dropdown } from "semantic-ui-react";
import "../../assets/css/componentSpecificCss/inviteUserModal.css";
import _ from "lodash";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  IMAGE_DROPDOWN,
  SWITCH,
  DROPDOWN
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class InviteUserModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      avatar: "",
      list: [],
      selected_program: "",
      lastFetched: ""
    }; //

    this.avatar_images = [
      { name: "1b", image: Avatar1b },
      { name: "2b", image: Avatar2b },
      { name: "3b", image: Avatar3b },
      { name: "4b", image: Avatar4b },
      { name: "5b", image: Avatar5b },
      { name: "6b", image: Avatar6b },
      { name: "7b", image: Avatar7b },
      { name: "8b", image: Avatar8b },
      { name: "9b", image: Avatar9b }
    ];

    this.inviteUser = _.debounce(this.inviteUser.bind(this), 8000, {
      leading: true,
      trailing: false
    });
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async componentDidUpdate() {
    if (
      this.props.roles[this.state.role] &&
      this.props.roles[this.state.role].role === "Program Specific"
    ) {
      if (this.state.lastFetched !== "Programs") {
        const ep = `${process.env.REACT_APP_API}/partners/programs/list`;
        const results = await axios.get(ep);
        if (results.data.success) {
          this.setState({
            list: results.data.data,
            lastFetched: "Programs"
          });
        }
      }
    } else if (
      this.props.roles[this.state.role] &&
      this.props.roles[this.state.role].role === "Event Specific"
    ) {
      if (this.state.lastFetched !== "Events") {
        const ep = `${process.env.REACT_APP_API}/partners/events/list`;
        const results = await axios.get(ep);
        if (results.data.success) {
          this.setState({
            list: results.data.data,
            lastFetched: "Events"
          });
        }
      }
    }
  }

  async inviteUser(e) {
    e.preventDefault();

    const ep = `${process.env.REACT_APP_API}/partners/users`;
    // unused
    // const map = { Events: "event_title", Programs: "program_name" };

    // When the selected role is program specific or event specific
    if (this.state.role == 2 || this.state.role == 3) {
      const res = await axios.post(ep, {
        username: this.state.username,
        email: this.state.email,
        avatar: this.state.avatar,
        receives_emails: this.state.receives_emails,
        role: this.state.role,
        selected: this.state.selected_program,
        last: this.state.lastFetched,
        programlist: [0],
        eventlist: [0],
        onlinelist: [0]
      });

      if (res.data.success) {
        this.props.user({
          ...res.data.data
        });
        toast.success("User Invited");
        this.props.onClose();
      } else {
        toast.error(res.data.message);
      }
    } else if (this.state.role == 1) {
      const res = await axios.post(ep, {
        username: this.state.username,
        email: this.state.email,
        avatar: this.state.avatar,
        receives_emails: this.state.receives_emails,
        role: this.state.role,
        selected: this.state.selected_program,
        last: this.state.lastFetched,
        programlist: [0],
        eventlist: [0],
        onlinelist: [0]
      });

      if (res.data.success) {
        this.props.user({
          ...res.data.data
        });
        toast.success("User Invited");
        this.props.onClose();
      } else {
        toast.error(res.data.message);
      }
    } // When the selected program is non program/event specific
    else {
      const res = await axios.post(ep, {
        username: this.state.username,
        email: this.state.email,
        avatar: this.state.avatar,
        receives_emails: this.state.receives_emails,
        role: this.state.role,
        selected: this.state.selected_program,
        last: this.state.lastFetched,
        programlist: [0],
        eventlist: [0],
        onlinelist: [0]
      });

      if (res.data.success) {
        this.props.user({
          ...res.data.data
        });
        toast.success("User Invited");
        this.props.onClose();
      } else {
        toast.error(res.data.message);
      }
    }
  }

  //backgroundImage: ModalBanner, backgroundPosition: "center", backgroundSize: "cover",
  /// ================== NEW MODAL ========================== ///

  getFormTypeChoices = () => {
    return this.props.roles
      .filter(
        f =>
          f.role !== "Owner" &&
          f.role !== "Program Specific" &&
          f.role !== "Event Specific" &&
          f.role !== "Online Specific" &&
          !f.isArchived
      )
      .map(e => ({ text: e.role, value: e.id }));
  };

  getBaseModalFields() {
    const descriptionInput = {
      type: STRING_INPUT,
      data: {
        name: "Name",
        required: true,
        placeholder: "Full Name",
        handleChange: e => {
          this.setState({
            username: e.target.value
          });
        },
        value: this.state.username
      },
      validators: {
        validateSubmit: () =>
          this.state.username !== undefined && this.state.username !== ""
      }
    };

    const avatarDropdown = {
      type: IMAGE_DROPDOWN,
      data: {
        name: "Avatar",
        required: true,
        placeholder: "Select avatar",
        handleChange: value => this.setState({ avatar: value }),
        choices: this.avatar_images.map((e, i) => ({
          text: `Person ${i + 1}`,
          value: e.name,
          key: e.name,
          image: {
            avatar: true,
            src: `${e.image}`
          }
        })),
        value: this.state.avatar
      },
      validators: { validateSubmit: () => this.state.avatar !== "" }
    };

    const formTypeField = {
      type: DROPDOWN,
      data: {
        name: "Role",
        required: true,
        placeholder: "Please select",
        handleChange: e => {
          this.setState({
            role: e.target.value
          });
        },
        choices: this.getFormTypeChoices()
      },
      validators: {
        validateSubmit: () =>
          this.state.role !== undefined && this.state.role !== ""
      }
    };

    const emailInput = {
      type: STRING_INPUT,
      data: {
        name: "Email",
        info: "optional",
        required: false,
        placeholder: "",
        handleChange: e => {
          if (e.target.value.length === 0) {
            this.setState({
              receives_emails: false
            });
          }
          this.setState({
            email: e.target.value.toLowerCase()
          });
        },
        value: this.state.email,
        type: "email"
      }
    };

    const subscribeEmail = {
      type: SWITCH,
      data: {
        name: "Subscribe to Triggered Emails",
        checked: this.state.receives_emails,
        handleClick: e => this.setState({ receives_emails: e.target.checked })
      }
    };

    return [
      descriptionInput,
      avatarDropdown,
      formTypeField,
      emailInput,
      subscribeEmail
    ];
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Add New",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.inviteUser(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Invite User",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ===================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding" style={{ display: "block" }}>
          <img
            src={ModalBanner}
            alt="Modal Banner"
            style={{
              height: "40px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>Invite User</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form onSubmit={e => this.inviteUser(e)}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="username"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Name <span style={{ color: "orange" }}>*</span>
                </label>
                <input
                  name="username"
                  id="username"
                  type="text"
                  className="form-control"
                  placeholder="Full Name"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  required
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Avatar
                </label>
                <div style={{ width: "70%" }}>
                  <Dropdown
                    style={{
                      border: "1.5px solid #EDF4FF",
                      borderRadius: ".5rem",
                      color: "#8c8ea0"
                    }}
                    className="form-control"
                    placeholder="Select Avatar"
                    fluid
                    selection
                    onChange={(_, { value }) => {
                      this.setState({
                        avatar: value
                      });
                    }}
                    value={this.state.avatar}
                    options={this.avatar_images.map((e, i) => ({
                      text: `Person ${i + 1}`,
                      value: e.name,
                      key: e.name,
                      image: {
                        avatar: true,
                        src: `${e.image}`
                      }
                    }))}
                  />
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Role <span style={{ color: "orange" }}>*</span>
                </label>
                <select
                  name="role"
                  id="postal"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                >
                  <option value="" disabled selected hidden>
                    Please Select
                  </option>
                  {this.props.roles
                    .filter(
                      f =>
                        f.role !== "Owner" &&
                        f.role !== "Program Specific" &&
                        f.role !== "Event Specific" &&
                        f.role !== "Online Specific" &&
                        !f.isArchived
                    )
                    .map((e, i) => (
                      <option value={e.id}>{e.role}</option>
                    ))}
                </select>
              </div>
              {((this.props.roles[this.state.role] &&
                this.props.roles[this.state.role].role ===
                  "Program Specific") ||
                (this.props.roles[this.state.role] &&
                  this.props.roles[this.state.role].role ===
                    "Event Specific")) && (
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "25%", textAlign: "left" }}
                  >
                    {this.state.lastFetched === "Programs"
                      ? "Program"
                      : "Event"}{" "}
                    <span style={{ color: "orange" }}>*</span>
                  </label>
                  <select
                    name="selected_program"
                    id="postal"
                    type="text"
                    className="form-control"
                    style={{ width: "70%" }}
                    onChange={e => {
                      this.setState({
                        [e.target.name]: e.target.value
                      });
                    }}
                  >
                    <option value="">All</option>
                    {this.state.list.map((e, i) => (
                      <option value={e.id}>
                        {e.program_name || e.event_title}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="email"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Email <span style={{ fontWeight: 300 }}>(optional)</span>
                </label>
                <input
                  name="email"
                  id="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  style={{ width: "70%" }}
                  value={this.state.email}
                  onChange={e => {
                    if (e.target.value.length === 0) {
                      this.setState({
                        receives_emails: false
                      });
                    }
                    this.setState({
                      [e.target.name]: e.target.value.toLowerCase()
                    });
                  }}
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0,
                  marginTop: "30px"
                }}
              >
                <input
                  type="checkbox"
                  checked={this.state.receives_emails}
                  name="receives_emails"
                  onChange={e =>
                    this.setState({ [e.target.name]: e.target.checked })
                  }
                  style={{ marginBottom: "5px" }}
                  disabled={!this.state.email}
                />
                <label style={{ marginLeft: "10px" }}>
                  Subscribe to Triggered Emails
                </label>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn profile-btn">
                  Add New
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(InviteUserModal);
