import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus, background1 } from "../../assets";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import axios from "axios";
// import { toast } from "react-toastify";
import { format } from "date-fns";
import NotesModal from "../../components/modals/NotesModal";
import AddInstructorModal from "../../components/modals/AddInstructorModal";
import AddEnrollmentModal from "../../components/modals/AddEnrollmentModal";
// import { Delete } from "@material-ui/icons";
import "../../assets/css/componentSpecificCss/manageSingleProgram.css";
import "../../components/modals/MilestonesModal";
import MilestonesModal from "../../components/modals/MilestonesModal";
// import ApplicationViewModal from "../../components/modals/ApplicationViewModal";

class OnlineVirtualList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      openMilestoneModal: false,
      customers: {}
    };

    this.tabs = ["Enrollments", "Inactive"];
    this.headings = {
      Enrollments: [
        { id: "fullName", label: "Child's name" },
        {
          id: "ptype",
          label: "Program Plan",
          customCell: (r, i) => {
            const plan = JSON.parse(
              this.state.data.plans.filter(
                e => JSON.parse(e).id == r.plan_id
              )[0]
            );
            const objConv = {
              week: "Weekly",
              "bi-week": "Bi-Weekly",
              month: "Monthly"
            };
            return (
              <td key={i}>
                {plan.type
                  ? r.drop_in_dates
                    ? `Drop-in`
                    : `Semester - $${plan.total_price}`
                  : `Recurring - $${plan.tuition_rate} / ${
                      objConv[plan.billing_cycle]
                    }, \n ${plan.timing[0]} to ${plan.timing[1]}`}
              </td>
            );
          }
        },
        {
          id: "start",
          label: "Drop-in Date",
          customCell: (r, i) => {
            const plan = JSON.parse(this.state.data.plans[r.plan_id]);

            return (
              <td key={i}>
                {r.drop_in_dates
                  ? `${r.drop_in_dates
                      .map(e => format(new Date(e), "LLLL dd, yyyy"))
                      .join(",")}`
                  : "N/A"}
              </td>
            );
          }
        },
        {
          id: "end",
          label: "End Date",
          customCell: (r, i) => <td key={i}>---</td>
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button
                  style={{ marginRight: "15px" }}
                  onClick={_ =>
                    this.props.history.push(`/partners/application/${r.id}`)
                  }
                >
                  View Details
                </button>
                <button
                  style={{ marginRight: "15px" }}
                  onClick={_ => this.viewNotes(r, "programs_customers")}
                >
                  Notes
                </button>
              </td>
            );
          }
        }
      ],
      Archive: [
        { id: "pname", label: "Program Name" },
        { id: "ptype", label: "Program Type" },
        {
          id: "status",
          label: "Status"
        },
        {
          id: "updated",
          label: "Updated"
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button style={{ marginRight: "30px" }}>Edit</button>
                <button style={{ marginRight: "30px" }}>Notes</button>
              </td>
            );
          }
        }
      ]
    };
    this.actions = [{ name: "Notes", action: null }];
  }

  async componentDidMount() {
    const param = this.props.match.params.id;
    const ep = `${process.env.REACT_APP_API}/partners/programs/${param}`;
    const res = await axios.get(ep);
    const customers_ep = `${process.env.REACT_APP_API}/partners/programs/customers/${param}`;
    const customers = await axios.get(customers_ep);

    if (res.data.success) {
      this.setState({
        data: res.data.data,
        customers: { Enrollments: customers.data.data }
      });
    }
  }

  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };
  viewNotes = (row, table) => {
    this.setState({
      notesModalShown: true,
      modalData: row,
      table: table
    });
  };

  render() {
    return (
      <div className="admin">
        {this.state.notesModalShown && (
          <NotesModal
            data={this.state.modalData}
            table={this.state.table}
            onClose={() =>
              this.setState({
                notesModalShown: false,
                modalData: null
              })
            }
          />
        )}
        {this.state.instructor && (
          <AddInstructorModal
            open={this.state.instructor}
            program={this.state.modalProgram}
            onClose={() =>
              this.setState({
                instructor: false,
                modalProgram: null
              })
            }
            type="Online"
          />
        )}
        {this.state.addEnrollment && (
          <AddEnrollmentModal
            open={this.state.addEnrollment}
            program={this.state.modalProgram}
            onClose={() => {
              this.setState({
                addEnrollment: false,
                modalProgram: null
              });
              this.fetchInitialData();
            }}
            table={"partner_online"}
            promotion_type="online"
          />
        )}
        {this.state.openMilestoneModal && (
          <MilestonesModal
            onClose={_ => this.setState({ openMilestoneModal: false })}
            history={this.props.history}
            program_id={this.props.match.params.id}
            type="Online"
          />
        )}

        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>{this.state.data.program_name}</h1>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div
                  className="forbtnapp enrollment-btn"
                  style={{ marginLeft: "20px" }}
                >
                  <button
                    className="newapplications"
                    onClick={_ =>
                      this.setState({
                        addEnrollment: true,
                        modalProgram: this.state.data
                      })
                    }
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Enrollment
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="display-container">
              <div className="left-container">
                <div className="image-container">
                  <img
                    src={`${process.env.REACT_APP_DS_FILES_S3}/${this.state.data.program_photo}`}
                    alt="Program"
                    className="image"
                  />
                </div>
                <p className="program-name">{this.state.data.program_name}</p>
                <p className="program-category">
                  {this.state.data.program_category}
                </p>
                <div className="address-container">
                  <h3 className="address-title">
                    {this.state.data.address1} {this.state.data.address2}{" "}
                    {this.state.data.city} {this.state.data.province}
                  </h3>
                </div>
                <div className="options-container">
                  <div
                    className="option-list"
                    onClick={_ =>
                      this.props.history.push(
                        this.state.data.number_of_weeks > 0
                          ? `/partners/manage/simple-attendance/${this.state.data.id}`
                          : `/partners/manage/simple-attendance/${this.state.data.id}`
                      )
                    }
                  >
                    <h3 className="option-name">Manage Attendance</h3>
                    <i className="far fa-calendar-check option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    onClick={_ =>
                      this.viewNotes(this.state.data, "partner_programs")
                    }
                  >
                    <h3 className="option-name">Program Notes</h3>
                    <i className="fas fa-pencil-alt option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    onClick={_ =>
                      this.setState({
                        instructor: true,
                        modalProgram: this.state.data
                      })
                    }
                  >
                    <h3 className="option-name">Instructors</h3>
                    <i className="fas fa-info option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    onClick={_ =>
                      this.props.history.push({
                        pathname: `/partners/send-message`,
                        search: `?type=Online&id=${this.props.match.params.id}`,
                        query: {
                          type: "Online",
                          id: `${this.props.match.params.id}`
                        }
                      })
                    }
                  >
                    <h3 className="option-name">Message Participants</h3>
                    <i className="fas fa-bullhorn option-img"></i>
                  </div>
                  <div className="option-list">
                    <h3 className="option-name">Pause Programs</h3>
                    <i className="far fa-pause-circle option-img"></i>
                  </div>
                  <div className="option-list">
                    <h3 className="option-name">Cancel Program</h3>
                    <i className="fas fa-times-circle option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    onClick={_ => this.setState({ openMilestoneModal: true })}
                  >
                    <h3 className="option-name">Milestones</h3>
                    <i className="fas fa-book-reader option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    onClick={_ =>
                      this.props.history.push(
                        `/partners/programs/setup/${this.state.data.id}`
                      )
                    }
                  >
                    <h3 className="option-name">Edit Program Listing</h3>
                    <i className="far fa-edit option-img"></i>
                  </div>
                </div>
              </div>
              <div className="tablecont">
                <div className="table-responsive">
                  <div
                    style={{
                      display: "inline-flex",
                      flex: "1 1",
                      border: "none",
                      borderRadius: "10px",
                      overflow: "hidden",
                      width: "100%"
                    }}
                  >
                    <i
                      className="fas fa-search"
                      style={{ padding: "8px", background: "white" }}
                    ></i>
                    <input
                      type="text"
                      placeholder="Search by name"
                      style={{
                        border: "none",
                        fontSize: "13px",
                        padding: "5px",
                        outline: 0,
                        background: "white",
                        flex: 1
                      }}
                    />
                  </div>
                  <NewTabbedTable
                    tabs={this.tabs}
                    headings={this.headings}
                    data={this.state.customers}
                    actions={this.actions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OnlineVirtualList;
