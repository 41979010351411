import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { formTypes } from "../../components/UI/form";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { Switch } from "@material-ui/core";

const PERCENT = "Percentage";
const FIXED_AMOUNT = "Fixed Amount";
class SwitchEnrollmentModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      plans: props.plans,
      selectedPlan: props.program.plan_id,
      amount: null,
      isAdvanced: false,
      isWaiveDeposit: true,
      isUserConsent: false,
      isManual: false,
      buttonDisabled: false
    };
  }

  async switchEnrollment(e) {
    this.setState({ buttonDisabled: true });
    this.setGlobal({ loading: true });
    e.preventDefault();
    if (this.props.type === "Programs") {
      const ep = `${process.env.REACT_APP_API}/partners/programs/switch-enrollment`;
      const res = await axios.post(ep, {
        program: this.props.program,
        id: this.props.program.id,
        plans: this.state.plans,
        selectedPlan: this.state.selectedPlan,
        amount: this.state.amount,
        isAdvanced: this.state.isAdvanced,
        isWaiveDeposit: this.state.isWaiveDeposit,
        isUserConsent: this.state.isUserConsent,
        isManual: this.props.program.manual_invoices
      });

      console.log("res", res);
      if (res.data.success) {
        toast.success("Plan Switched Successfully");
        this.props.onClose();
      }
    }

    if (this.props.type === "Online Virtual") {
      const ep = `${process.env.REACT_APP_API}/partners/virtual/switch-enrollment`;
      const res = await axios.post(ep, {
        program: this.props.program,
        id: this.props.program.id,
        plans: this.state.plans,
        selectedPlan: this.state.selectedPlan,
        amount: this.state.amount,
        isAdvanced: this.state.isAdvanced,
        isWaiveDeposit: this.state.isWaiveDeposit,
        isUserConsent: this.state.isUserConsent,
        isManual: this.props.program.manual_invoices
      });

      console.log("res", res);
      if (res.data.success) {
        toast.success("Plan Switched Successfully");
        this.props.onClose();
      }
    }

    this.setGlobal({ loading: false });
    this.setState({ buttonDisabled: false }); // Not strictly necessary since the modal should be closed by now
  }

  requestTour = async e => {
    e.preventDefault();
  };

  render() {
    console.log("this.state switch", this.state);
    console.log("this.props switch", this.props);

    return (
      <ModalTemplate
        heading="Switch Enrollment Plan"
        onClose={this.props.onClose}
      >
        <form
          onSubmit={e => {
            this.switchEnrollment(e);
            this.props.onClose();
          }}
        >
          <div className="modal-main">
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                htmlFor="displayName"
                style={{ width: "25%", textAlign: "left" }}
              >
                Pricing Plan *
              </label>
              <select
                id="weirdSelect"
                style={{ width: "70%" }}
                name="selectedPlan"
                onChange={e => {
                  // const matched = e.target.value.match(/[a-z]/g);
                  // const isPromo = !matched ? false : true;

                  const selectedOption = e.target.value.replace(/[ a-z]/g, "");

                  let ticket = null;
                  let amount = null;
                  let showWaiveDeposit = false;
                  if (this.props.type === "Events") {
                    const parsedPlan = JSON.parse(
                      this.props.program.tickets[selectedOption.trim()]
                    );

                    ticket =
                      parsedPlan.ticket_status === "Free"
                        ? "Free"
                        : parsedPlan.ticket_price;
                  } else {
                    const parsedPlan = JSON.parse(
                      this.state.plans[
                        selectedOption.split("")[0].trim()
                      ]
                    );

                    if (parsedPlan.type) {
                      if (!parsedPlan.installments && !parsedPlan.dropIns) {
                        amount = parseFloat(parsedPlan.total_price);
                      } else {
                        amount = parseFloat(parsedPlan.total_price);
                      }

                      if (e.target.value.includes("Installments")) {
                        amount = parseFloat(
                          parsedPlan.total_price *
                            (1 +
                              parseInt(parsedPlan.installments_interest) / 100)
                        ).toFixed(2);
                      }
                      if (e.target.value.includes("Drop-In")) {
                        amount = parseFloat(
                          (parsedPlan.total_price / parsedPlan.dayCount) *
                            (1 + parseInt(parsedPlan.dropin_interest) / 100)
                        ).toFixed(2);
                      }
                    } else {
                      // Recurring
                      amount = parsedPlan.tuition_rate;
                      showWaiveDeposit = !!parsedPlan.deposit;
                    }
                  }

                  this.setState({
                    [e.target.name]: selectedOption.trim(),
                    amount: amount,
                    ticket: ticket,
                    // isPromoPlanSelected: isPromo,
                    showWaiveDeposit: showWaiveDeposit
                  });
                }}
                className="form-control"
              >
                <option hidden selected disabled value="">
                  Please choose
                </option>
                {console.log("ALL PLANS", this.state.plans)}
                {this.state.plans
                  .filter(f => {
                    console.log("FFF", JSON.parse(f));
                    return !f.archived;
                  })
                  .map((ele, i) => {
                    const e = typeof ele === "string" ? JSON.parse(ele) : ele;
                    if (
                      this.props.type === "Programs" ||
                      this.props.type === "Online Virtual"
                    ) {
                      if (e.type) {
                        const options = [];
                        options.push({
                          value: e.id || i,
                          text:
                            e.active === false
                              ? `Semester - $${parseFloat(
                                  e.total_price
                                ).toFixed(2)} (Inactive)`
                              : `Semester - $${parseFloat(
                                  e.total_price
                                ).toFixed(2)}`,
                          type: ""
                        });
                        if (e.installments)
                          options.push({
                            value: e.id || i,
                            onClick: _ => this.setState({ installments: true }),
                            text: `Semester Installments - $${parseFloat(
                              e.total_price *
                                (1 + parseInt(e.installments_interest) / 100)
                            ).toFixed(2)}`,
                            type: "Installments"
                          });
                        if (e.dropIns)
                          options.push({
                            value: e.id || i,
                            onClick: _ => this.setState({ dropIns: true }),
                            text: `Semester Drop-In - $${parseFloat(
                              (e.total_price / e.dayCount) *
                                (1 + parseInt(e.dropin_interest) / 100)
                            ).toFixed(2)} per day.`,
                            type: "Drop-In"
                          });
                        return options.map(option => {
                          if (option.onClick)
                            return (
                              <option
                                value={option.value + " " + option.type}
                                onClick={option.onClick}
                                key={i + option.type}
                              >
                                {option.text}
                              </option>
                            );
                          else
                            return (
                              <option
                                value={option.value + " " + option.type}
                                key={i + option.type}
                              >
                                {option.text}
                              </option>
                            );
                        });
                      } else {
                        return (
                          <option value={e.id || i} key={i}>
                            Recurring -{" "}
                            {`$${parseFloat(e.tuition_rate).toFixed(2)} - ${
                              e.days_per_week
                            } days per week.`}{" "}
                            {e.active === false ? " (Inactive)" : ""}
                          </option>
                        );
                      }
                    } else if (this.props.type === "Events") {
                      return (
                        <option value={e.id || i} key={i}>
                          {`${e.ticket_status} ticket - $${e.ticket_price}`}
                        </option>
                      );
                    }
                  })}
              </select>
            </div>

            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                htmlFor="displayName"
                style={{ width: "25%", textAlign: "left" }}
              >
                Advanced
              </label>
              <div style={{ marginLeft: "15px" }}>
                <Switch
                  checked={this.state.isAdvanced}
                  onChange={e =>
                    this.setState({
                      isAdvanced: e.target.checked
                    })
                  }
                  color="primary"
                />
              </div>
            </div>

            {this.state.isAdvanced && (
              <>
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "25%", textAlign: "left" }}
                  >
                    Waive Deposit
                  </label>
                  <div style={{ marginLeft: "15px" }}>
                    <Switch
                      disabled={true}
                      checked={this.state.isWaiveDeposit}
                      onChange={e =>
                        this.setState({
                          isWaiveDeposit: e.target.checked
                        })
                      }
                      color="primary"
                    />
                  </div>
                </div>

                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "25%", textAlign: "left" }}
                  >
                    User Consent
                  </label>
                  <div style={{ marginLeft: "15px" }}>
                    <Switch
                      disabled={true}
                      checked={this.state.isUserConsent}
                      onChange={e =>
                        this.setState({
                          isUserConsent: e.target.checked
                        })
                      }
                      color="primary"
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="modal-footer">
            <button
              type="submit"
              className="btn"
              disabled={this.state.buttonDisabled}
            >
              Switch Enrollment
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(SwitchEnrollmentModal);
