import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "../../assets/css/componentSpecificCss/applicationViewModal.css";
import moment from "moment";
import {
  format,
  addWeeks,
  addDays,
  addHours,
  subDays,
  isAfter
} from "date-fns";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
// import { ReactPDF, PDFViewer , BlobProvider, PDFDownloadLink, pdf, Document, Page } from '@react-pdf/renderer';
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import SwitchEnrollmentModal from "./SwitchEnrollmentModal";

import {
  CONTENT_BLOCK,
  BUTTON,
  TEXT_AREA,
  IMAGE_CONTENT,
  DROPDOWN
} from "./baseModal/FieldTypes";
import BaseModal from "./baseModal/BaseModal";
import ApplicationCancelModal from "./ApplicationCancelModal";
import {
  BLUE_BUTTON,
  GREEN_BUTTON,
  RED_BUTTON,
  WHITE,
  YELLOW_BUTTON
} from "./baseModal/colours";
import { result } from "lodash";

const daysOfWeekConversion = day => {
  const table = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  };
  return table[day];
};

const getFirstDay = (startingDate, daysOfWeek) => {
  // Code to compensate for the local timezone of the starting date
  startingDate.setTime(
    startingDate.getTime() + startingDate.getTimezoneOffset() * 60 * 1000
  );

  if (!daysOfWeek) {
    return undefined;
  }
  if (Object.keys(daysOfWeek).length === 0) return;

  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  while (true) {
    if (convertedDaysOfWeek.includes(startingDate.getDay())) {
      return startingDate;
    }
    startingDate = addDays(startingDate, 1);
  }
};

const getLastDay = (lastDay, daysOfWeek) => {
  console.log("lastOfWeek...", daysOfWeek);
  if (!daysOfWeek) {
    return undefined;
  }
  if (Object.keys(daysOfWeek).length === 0) return;
  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  lastDay = addWeeks(lastDay, 1);
  lastDay = subDays(lastDay, 1);
  while (true) {
    if (convertedDaysOfWeek.includes(lastDay.getDay())) {
      return lastDay;
    }
    lastDay = subDays(lastDay, 1);
  }
};

class FormViewModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      formSections: [],
      displayPDF: false,
      isMembershipAutoRenew: false,
      isOpenSwitchModal: false,
      customFormData: [],
      customFormDataObj: {},
      filteredSections: [],
      toStatusStage: "Inactive",
      idToEdit: 0,
      couponList: [],
      hideCouponField: false,
      selectedCoupon: this.props.program.couponId,
      coupon: this.props.program.coupon ? this.props.program.coupon : null,
      allowDropdown: false,
      openCancelApplicationModal: false
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/forms/${this.props.program.formId}`;
    const results = await axios.get(ep);

    if (results.data.success) {
      this.setState({ formSections: results.data.data.sections });
    }

    const customFormData = this.props.program.custom
      ? Object.keys(this.props.program.custom)
      : [];

    const formArr = [];

    const formObj = {};

    customFormData.map(lead => {
      const fullLabel = lead;
      const leadSectionId = lead.match(/\d+/)?.[0];
      if (!leadSectionId) {
        return;
      }

      const leadSectionLabel = lead.slice(lead.match(/\d+/)[0].length);
      const leadSectionData = results.data.data.sections.filter(
        e => e.id === parseInt(leadSectionId)
      );
      console.log({
        lead,
        leadSectionId,
        leadSectionLabel,
        leadSectionData,
        results
      });

      if (leadSectionData.length === 0) return;

      const leadSectionName = leadSectionData[0].form_section_name;
      const leadValue = this.props.program.custom[lead];

      const obj = {
        sectionId: leadSectionId,
        originalLabel: fullLabel,
        name: leadSectionLabel,
        sectionName: leadSectionName,
        value: leadValue
      };
      formArr.push({
        sectionId: leadSectionId,
        originalLabel: fullLabel,
        name: leadSectionLabel,
        sectionName: leadSectionName,
        value: leadValue
      });

      if (!formObj[leadSectionName]) {
        formObj[leadSectionName] = [];
      }
      formObj[leadSectionName] = [...formObj[leadSectionName], obj];
      return lead;
    });

    formArr.sort((a, b) => a.sectionId - b.sectionId);

    const nameOfSection = Object.keys(formObj);

    const filteredSections = results.data.data.sections.filter(
      e =>
        e.form_section_name ===
        nameOfSection.filter(f => f === e.form_section_name)[0]
    );

    if (this.props.displayCategory === "Memberships") {
      const plan = JSON.parse(
        this.props.program.prices[
          this.props.program.plan_id !== undefined
            ? this.props.program.plan_id
            : this.props.program.planId
        ]
      );
      this.setState({ isMembershipAutoRenew: !!plan.auto_renew });
    }

    this.setState({
      customFormData: formArr,
      customFormDataObj: formObj,
      filteredSections: filteredSections
    });

    // Only allow changing the coupon before application is "Accepted_Paid"
    // Or if the program is Recurring Autopay
    if (
      this.props.program.stripeSubscription ||
      this.props.program.status_stage === "Accepted_Unpaid" ||
      this.props.program.status_stage === "New_Pending" ||
      this.props.program.status_stage === "Waitlisted_Unpaid" ||
      this.props.program.status_stage === "Rejected"
    ) {
      const epCoupon = `${process.env.REACT_APP_API}/partners/stripe/application/coupon`;
      const resultCoupon = await axios.get(epCoupon);
      if (resultCoupon.data.success) {
        // Runs when coupon permissions enabled.
        let couponList;
        if (this.props.program.classificationType === "Program") {
          couponList = resultCoupon.data.data.filter(
            e =>
              e.event_picked.length === 0 &&
              e.online_picked.length === 0 &&
              (e.programs_picked.length === 0 ||
                e.programs_picked.find(
                  c => JSON.parse(c).value.id === this.props.program.programId
                ))
          );
        } else if (this.props.program.classificationType === "Online") {
          couponList = resultCoupon.data.data.filter(
            e =>
              e.event_picked.length === 0 &&
              e.programs_picked.length === 0 &&
              (e.online_picked.length === 0 ||
                e.online_picked.find(
                  c => JSON.parse(c).value.id === this.props.program.programId
                ))
          );
        } else if (this.props.program.classificationType === "Event") {
          couponList = resultCoupon.data.data.filter(
            e =>
              e.online_picked.length === 0 &&
              e.programs_picked.length === 0 &&
              (e.event_picked.length === 0 ||
                e.event_picked.find(
                  c => JSON.parse(c).value.id === this.props.program.eventId
                ))
          );
        } else {
          couponList = resultCoupon.data.data.filter(
            e =>
              e.programs_picked.length === 0 &&
              e.online_picked.length === 0 &&
              e.event_picked.length === 0
          );
        }

        this.setState({
          selectedCoupon: this.props.program.couponId,
          couponList: couponList
        });
      } else {
        // Runs when coupon permissions disabled.
        this.setState({ hideCouponField: true });
      }
    }
  }

  downloadPDF = () => {
    const allFields = this.getBaseModalFields();
    const numFields = allFields.length;
    const domElementIds = [];

    // Determine the number of "base-modal-content-#" to add
    const numBaseModalContents = Math.ceil(numFields / 10);

    // Loop to create the "base-modal-content-#" IDs
    for (let i = 1; i <= numBaseModalContents; i++) {
      domElementIds.push(`base-modal-content-${i}`);
    }

    this.setGlobal({ loading: true });

    const renderPage = (pdf, domElementId, pageNumber) => {
      const domElement = document.getElementById(domElementId);
      html2canvas(domElement, {
        scale: 3,
        scrollX: 0,
        scrollY: 0
      })
        .then(canvas => {
          const PDF_MARGIN = 20;
          const PDF_WIDTH = 140;
          const MAX_HEIGHT = 250;

          const img = canvas.toDataURL("image/png");
          const imgProps = pdf.getImageProperties(img);

          let width = pdf.internal.pageSize.getWidth() - 40;
          let height = (imgProps.height * width) / imgProps.width;

          if (height > MAX_HEIGHT) {
            height = MAX_HEIGHT;
            width = (imgProps.width * height) / imgProps.height;
          }

          if (pageNumber > 1) {
            pdf.addPage();
          }

          pdf.addImage(img, "JPEG", PDF_MARGIN, PDF_MARGIN, width, height);

          // If there are more elements to render, proceed to the next one.
          const nextIndex = domElementIds.indexOf(domElementId) + 1;
          if (nextIndex < domElementIds.length) {
            renderPage(pdf, domElementIds[nextIndex], pageNumber + 1);
          } else {
            pdf.save("Application.pdf");
            this.setGlobal({ loading: false });
          }
        })
        .catch(error => {
          toast.error("Could not generate PDF.");
          this.setGlobal({ loading: false });
        });
    };

    const pdf = new jsPdf("p", "mm", "letter", true);
    renderPage(pdf, domElementIds[0], 1);
  };

  getBaseModalFields() {
    const {
      displayName,
      title,
      email,
      homePhone,
      address,
      city,
      postalCode,
      province,
      country,
      childName,
      userSelectedQuantity,
      startDate,
      createdAt,
      plans,
      plan_id,
      sessionDates,
      sessionTiming
    } = this.props.program;

    // const parsedPlan = plans ? JSON.parse(plans[plan_id]) : null;
    // console.log("THE PROGRAM", this.props.program, parsedPlan);
    // const getPaymentStatus = () =>
    //   this.props.program.status_stage === "Accepted_Paid"
    //     ? "Paid"
    //     : this.props.program.status_stage === "Cancelled" ||
    //       this.props.program.status_stage === "Cancelled_Unpaid" ||
    //       this.props.program.status_stage === "Rejected"
    //     ? "Cancelled"
    //     : "Pending";

    // const getFormattedPlan = () => {
    //   switch (this.props.displayCategory) {
    //     case "Programs":
    //       return this.showProgramPlan();
    //     case "Events":
    //       return this.showEventPlan();
    //     case "Memberships":
    //       return this.showMembershipPlan();
    //     case "Online Virtual":
    //       return this.showOnlinePlan();
    //   }
    // };

    // const getCouponApplied = () => {
    //   switch (this.props.displayCategory) {
    //     case "Programs":
    //       return this.showProgramPlanCoupon();
    //     case "Events":
    //       return this.showEventPlanCoupon();
    //     case "Memberships":
    //       return this.showMembershipPlanCoupon();
    //     case "Online Virtual":
    //       return this.showOnlinePlanCoupon();
    //   }
    // };

    // const getPromo = () => {
    //   if (this.props?.program?.promotion?.discount_type === "Percentage") {
    //     return `${this.props?.program?.promotion?.discount_percent}% ${this.props?.program?.promotion?.duration_applied}`;
    //   } else {
    //     return `$${this.props?.program?.promotion?.discount_fixed / 100} ${
    //       this.props?.program?.promotion?.duration_applied
    //     }`;
    //   }
    // };

    // const getStartDate = () => {
    //   if (startDate) {
    //     return moment(
    //       new Date(startDate.replace(/-/g, "/").replace(/T.+/, ""))
    //     ).format("MMMM Do, YYYY");
    //   } else {
    //     return moment(
    //       new Date(createdAt.replace(/-/g, "/").replace(/T.+/, ""))
    //     ).format("MMMM Do, YYYY");
    //   }
    // };

    // const getBillingCycle = () => {
    //   let order = this.props.program.manualOrder;
    //   if (!order) {
    //     if (!parsedPlan) return null;
    //     order = {
    //       recurring_frequency: parsedPlan.billing_cycle,
    //       due_date:
    //         this.props.program.stripeSubscription?.billing_cycle_anchor * 1000
    //     };
    //   }
    //   const billingCycle = order.recurring_frequency;
    //   const dueDate = new Date(order.due_date);
    //   try {
    //     switch (billingCycle) {
    //       case "month":
    //         return `Monthly on the ${format(dueDate, "do")}`;
    //       case "week":
    //         return `Weekly on ${format(dueDate, "EEEE")}`;
    //       case "bi-week":
    //         return `Bi-Weekly on ${format(dueDate, "EEEE")}`;
    //     }
    //   } catch (e) {
    //     console.error("error in getBillingCycle", e);
    //     return null;
    //   }
    // };

    // const getAddOnFee = () => {
    //   if (!this.props.program?.plans?.[this.props?.program?.plan_id]) {
    //     return {};
    //   }
    //   return `$${
    //     JSON.parse(this.props.program?.plans?.[this.props?.program?.plan_id])
    //       ?.addOnItems?.[0]?.addOnFee
    //   }`;
    // };

    // const getChildDateOfBirth = () => {
    //   return moment(
    //     new Date(
    //       this.props.program?.birthDate?.replace(/-/g, "/")?.replace(/T.+/, "")
    //     )
    //   )?.format("MMMM Do, YYYY");
    // };

    // const getRecurringSelectedDays = () => {
    //   return this.props.program?.recurringCustomerSelectedDays?.reduce(
    //     (prev, e, i) => {
    //       const element = JSON.parse(e);
    //       if (this.props.program?.recurringCustomerSelectedDays?.[i + 1]) {
    //         return prev + `${element?.name}, `;
    //       } else {
    //         return prev + element?.name;
    //       }
    //     },
    //     ""
    //   );
    // };

    // const getDropInDates = () => {
    //   return this.props.program?.drop_in_dates?.reduce((prev, e) => {
    //     return (
    //       prev +
    //       moment(new Date(e?.replace(/-/g, "/")?.replace(/T.+/, "")))?.format(
    //         "MMMM Do, YYYY"
    //       )
    //     );
    //   }, "");
    // };

    // const getRequestedTimes = () => {
    //   const lastDate = i => i === sessionTiming?.length - 1;

    //   return this.props.program?.sessionTiming?.map((e, i) => {
    //     const element = typeof e === "string" ? JSON.parse(e) : e;
    //     let date = new Date(element.date);
    //     date.setHours(element.hours);
    //     date.setMinutes(
    //       element.minutes === 5
    //         ? 30
    //         : element.minutes === 25
    //         ? 15
    //         : element.minutes === 75
    //         ? 45
    //         : 0
    //     );
    //     const local = DateTime.fromISO(date.toISOString());
    //     const rezoned = local.setZone(this.global.organizationInfo.timezone);
    //     let space;
    //     if (lastDate(i)) {
    //       space = "";
    //     } else {
    //       space = ",\n";
    //     }
    //     return rezoned.toFormat("fff") + space;
    //   }, "");
    // };

    // const getRequestedDates = () => {
    //   const lastDate = i => i === sessionDates?.length - 1;
    //   return sessionDates?.reduce(
    //     (prev, e, i) =>
    //       prev + format(new Date(e), "LLLL dd, yyyy") + lastDate(i) ? "" : ", ",
    //     ""
    //   );
    // };

    // const getSessionSelectedDays = () => {
    //   return this.props.program?.sessionsCustomerSelectedDays?.reduce(
    //     (prev, e, i) => {
    //       const element = JSON.parse(e);
    //       if (this.props?.program?.sessionsCustomerSelectedDays?.[i + 1]) {
    //         return prev + `${element?.name}, `;
    //       } else {
    //         return prev + element?.name;
    //       }
    //     },
    //     ""
    //   );
    // };

    const getSortedWeekdays = selectedDays => {
      const WEEKDAY_ORDER = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ];
      return WEEKDAY_ORDER.filter(wd => selectedDays[wd]).join(", ");
    };

    const getFilteredSectionContent = (value, field) => {
      if (Array.isArray(value)) {
        return value.join("; ") + (value.length > 0 ? "." : "");
      }
      if (typeof value === "object" && value !== null) {
        if (field === "days") return getSortedWeekdays(value);
        else return Object.keys(value).join(" ");
      }
      if (typeof value === "boolean") {
        return value ? "Yes" : "No";
      }
      // if (value.includes("http")) {
      //   return `<a href="${value}">View File</a>`;
      // }
      if (value.includes("data:image/png")) {
        return `<img src="${value}"" width="150px" />`;
      }
      if (value.includes("-") && field === "DOB") {
        return moment(
          new Date(value.replace(/-/g, "/").replace(/T.+/, ""))
        ).format("MMMM Do, YYYY");
      }

      return value;
    };

    // const redirectToChild = () => {
    //   this.props.history.push(
    //     `/partners/child-profile/${this.props.program.dsUserId}/${this.props.program.childId}`
    //   );
    // };

    const formatContentField = ({ name, content, textStyles = null }) => ({
      type: CONTENT_BLOCK,
      data: { name, content, textStyles }
    });

    // const local = DateTime.fromISO(this.props.program.createdAt);
    // const rezoned = local.setZone(this.global.organizationInfo.timezone);

    // // content fields
    // const nameField = formatContentField({
    //   name: "Name",
    //   content: displayName
    // });
    // const appliedToField = formatContentField({
    //   name: "Applied To",
    //   content: title
    // });
    // const paymentStatusField = formatContentField({
    //   name: "Payment Status",
    //   content: getPaymentStatus()
    // });
    // const planField = formatContentField({
    //   name: "Plan",
    //   content: getFormattedPlan()
    // });
    // const emailField = formatContentField({ name: "Email", content: email });
    // const cellPhoneField = formatContentField({
    //   name: "Cell Phone",
    //   content: homePhone
    // });
    // const addressField = formatContentField({
    //   name: "Address",
    //   content: address
    // });
    // const cityField = formatContentField({ name: "City", content: city });
    // const postalCodeField = formatContentField({
    //   name: "Postal / Zip",
    //   content: postalCode
    // });
    // const regionField = formatContentField({
    //   name: "Region",
    //   content: province
    // });
    // const countryField = formatContentField({
    //   name: "Country",
    //   content: country
    // });
    // const submissionField = formatContentField({
    //   name: "Submission",
    //   content: rezoned.toFormat("fff")
    // });

    // const couponAppliedField = formatContentField({
    //   name: "Coupon Applied",
    //   content: getCouponApplied(),
    //   textStyles: { fontSize: "14px", whiteSpace: "pre-wrap" }
    // });
    // const promoField = formatContentField({
    //   name: "Promo",
    //   content: getPromo()
    // });
    // const addOnFeeField = formatContentField({
    //   name: "Add On Fee",
    //   content: getAddOnFee()
    // });
    // const childDateOfBirthField = formatContentField({
    //   name: "Date of Birth",
    //   content: getChildDateOfBirth()
    // });
    // const recurringSelectedDaysField = formatContentField({
    //   name: "Selected Days",
    //   content: getRecurringSelectedDays()
    // });
    // const dropInDatesField = formatContentField({
    //   name: "Drop In Dates",
    //   content: getDropInDates()
    // });
    // const sessionsRequestedField = formatContentField({
    //   name: "Number of sessions requested",
    //   content: userSelectedQuantity
    // });
    // const requestedTimesField = formatContentField({
    //   name: "Requested Times",
    //   content: getRequestedTimes(),
    //   textStyles: { whiteSpace: "pre-wrap" }
    // });
    // const requestedDatesField = formatContentField({
    //   name: "Requested Dates",
    //   content: getRequestedDates()
    // });
    // const sessionSelectedDaysField = formatContentField({
    //   name: "Selected Days",
    //   content: getSessionSelectedDays()
    // });

    console.log("state filtered sections", this.state.filteredSections);
    const filteredSectionFields = this.state.filteredSections.reduce(
      (prev, section) => {
        const sectionName = section.form_section_name;
        const formFilterData = this.state.customFormDataObj[sectionName];

        const fields = section.fields
          .filter(g => {
            if (
              formFilterData.filter(h => h.name === JSON.parse(g).first_label)
            ) {
              return g;
            }
          })
          .reduce((prev, e) => {
            const field = JSON.parse(e);
            const formData = this.state.customFormDataObj[
              `${section.form_section_name}`
            ].filter(f => f.name === field.first_label);

            if (field.first_label === formData[0]?.name) {
              return [
                ...prev,
                {
                  name: `<i>${sectionName}:</i> ${field.first_label}`,
                  content: getFilteredSectionContent(
                    formData[0].value,
                    field.field
                  ),
                  field: field.field
                }
              ];
            }
            return prev;
          }, []);
        return [...prev, ...fields];
      },
      []
    );
    console.log("filtered seciton fields", filteredSectionFields);
    const signatureField = filteredSectionFields
      .filter(e => e.field === "signature")
      .map(e => ({
        type: IMAGE_CONTENT,
        data: {
          name: e.name,
          src: e.content.slice(10, e.content.length - 37) // remove the surrounding img tag and other data
        }
      }))[0];

    const customFormFields = signatureField
      ? [
          signatureField,
          ...filteredSectionFields
            .filter(e => e.field !== "signature")
            .map(s => formatContentField(s))
        ]
      : filteredSectionFields.map(s => {
          if (s.content.startsWith("http")) {
            // For links
            return {
              type: BUTTON,
              data: {
                name: s.name,
                buttonText: "View File",
                buttonColour: BLUE_BUTTON,
                textColour: "white",
                handleClick: () => {
                  console.log("Navigating to", s.content);
                  window.open(s.content, "_blank");
                }
              }
            };
          } else {
            return formatContentField(s);
          }
        });

    // other fields
    const downloadPDFField = {
      type: BUTTON,
      data: {
        name: "Download PDF",
        buttonText: "Download",
        handleClick: this.downloadPDF,
        buttonColour: BLUE_BUTTON,
        textColour: WHITE
      }
    };

    // const couponButtonField = {
    //   type: BUTTON,
    //   data: {
    //     name: this.state.selectedCoupon ? "" : "Coupon Applied",
    //     buttonText: this.state.selectedCoupon ? "Modify Coupon" : "Add Coupon",
    //     handleClick: e => this.setState({ allowDropdown: true }),
    //     buttonColour: BLUE_BUTTON,
    //     textColour: WHITE
    //   }
    // };

    // const couponDropdownField = {
    //   type: DROPDOWN,
    //   data: {
    //     name: "Coupon Applied",
    //     placeholder: "Select a coupon",
    //     handleChange: e => {
    //       this.setState({ selectedCoupon: e.target.value });
    //     },
    //     choices: this.state.couponList.map(e => ({
    //       text: `${e.coupon_id} (${e.type === "Amount" ? "$" : ""}${
    //         e.amount_off
    //       }${e.type === "Percentage" ? "%" : ""})`,
    //       value: e.id
    //     })),
    //     value: this.state.selectedCoupon
    //   }
    // };

    // const couponSaveField = {
    //   type: BUTTON,
    //   data: {
    //     buttonText: "Save Coupon",
    //     handleClick: e => this.saveCoupon(),
    //     buttonColour: BLUE_BUTTON,
    //     textColour: WHITE
    //   }
    // };

    // const childNameField = {
    //   type: BUTTON,
    //   data: {
    //     name: "Child's Name",
    //     buttonText: `${childName} (view details)`,
    //     handleClick: redirectToChild,
    //     buttonColour: BLUE_BUTTON,
    //     textColour: WHITE
    //   }
    // };

    // // when icon is clicked, call this.props.openEditStart();
    // const startDateField = {
    //   type: CONTENT_BLOCK,
    //   data: {
    //     name: "Start Date",
    //     content: getStartDate(),
    //     icon:
    //       this.props.displayCategory === "Online Virtual" ||
    //       this.props.displayCategory === "Programs"
    //         ? {
    //             className: "far fa-edit",
    //             onClick: this.props.openEditStart
    //           }
    //         : {}
    //   }
    // };

    // const billingCycleField = {
    //   type: CONTENT_BLOCK,
    //   data: {
    //     name: "Billing Cycle",
    //     content: getBillingCycle(),
    //     icon:
    //       (this.props.displayCategory === "Online Virtual" ||
    //         this.props.displayCategory === "Programs") &&
    //       parsedPlan?.billing_cycle === "month"
    //         ? {
    //             className: "far fa-edit",
    //             onClick: this.props.openEditBillingCycle
    //           }
    //         : {}
    //   }
    // };

    // const messageField = {
    //   type: TEXT_AREA,
    //   data: {
    //     name: "Message (optional)",
    //     required: false,
    //     placeholder: "",
    //     handleChange: e => this.setState({ message: e.target.value }),
    //     value: this.state.message,
    //     isDisabled: this.props.type === "Accepted"
    //   }
    // };

    // add fields in order (some conditionally)
    const allFields = [];
    allFields.push(downloadPDFField);
    // allFields.push(nameField);
    // allFields.push(appliedToField);
    // allFields.push(paymentStatusField);
    // allFields.push(planField);
    // if (this.state.coupon && !this.state.allowDropdown) {
    //   allFields.push(couponAppliedField);
    // }
    // if (
    //   !this.state.allowDropdown &&
    //   (this.props.program.stripeSubscription ||
    //     this.props.program.status_stage === "Accepted_Unpaid" ||
    //     this.props.program.status_stage === "New_Pending" ||
    //     this.props.program.status_stage === "Waitlisted_Unpaid" ||
    //     this.props.program.status_stage === "Rejected")
    // ) {
    //   allFields.push(couponButtonField);
    // }
    // if (
    //   !this.state.hideCouponField &&
    //   (this.props.program.stripeSubscription ||
    //     this.props.program.status_stage === "Accepted_Unpaid" ||
    //     this.props.program.status_stage === "New_Pending" ||
    //     this.props.program.status_stage === "Waitlisted_Unpaid" ||
    //     this.props.program.status_stage === "Rejected") &&
    //   this.state.allowDropdown
    // ) {
    //   allFields.push(couponDropdownField);
    //   allFields.push(couponSaveField);
    // }
    // if (this.props.program.promotion) {
    //   allFields.push(promoField);
    // }
    // if (
    //   this.props?.displayCategory === "Programs" &&
    //   JSON.parse(plans[plan_id]).addOn
    // ) {
    //   allFields.push(addOnFeeField);
    // }
    // allFields.push(emailField);
    // allFields.push(cellPhoneField);
    // allFields.push(addressField);
    // allFields.push(cityField);
    // allFields.push(postalCodeField);
    // allFields.push(regionField);
    // allFields.push(countryField);
    // if (this.props.program.childId) {
    //   allFields.push(childNameField);
    //   allFields.push(childDateOfBirthField);
    // }
    // allFields.push(startDateField);
    // if (
    //   (this.props.displayCategory === "Programs" ||
    //     this.props.displayCategory === "Online Virtual") &&
    //   parsedPlan?.billing_cycle &&
    //   billingCycleField.data.content
    // ) {
    //   allFields.push(billingCycleField);
    // }
    // if (
    //   this.props.displayCategory === "Programs" &&
    //   this.props.program.recurringCustomerSelectedDays.length > 0
    // ) {
    //   allFields.push(recurringSelectedDaysField);
    // }
    // if (this.props.program.drop_in_dates) {
    //   allFields.push(dropInDatesField);
    // }
    if (customFormFields) {
      allFields.push(...customFormFields);
    }
    // allFields.push(submissionField);
    // if (this.props.program.userSelectedQuantity) {
    //   allFields.push(sessionsRequestedField);
    // }
    // if (this.props.program.sessionTiming?.length > 0) {
    //   allFields.push(requestedTimesField);
    // }
    // if (this.props.program.sessionDates?.length > 0) {
    //   allFields.push(requestedDatesField);
    // }
    // if (
    //   this.props.displayCategory === "Programs" &&
    //   this.props.program.sessionsCustomerSelectedDays?.length > 0
    // ) {
    //   allFields.push(sessionSelectedDaysField);
    // }
    // if (this.props.type !== "Enrolled") {
    //   allFields.push(messageField);
    // }

    return allFields;
  }

  getBaseModalButtons() {
    return [];
  }

  getBaseModalProps() {
    const baseModalProps = {
      title: "Form Details",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };

    return baseModalProps;
  }

  render() {
    console.log("this.state", this.state);
    console.log("this.props", this.props);
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(FormViewModal);
