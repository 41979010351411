import React from "reactn";
import Tables from "../../components/table";
import axios from "axios";
import { Table } from "../../components/UI";
import { AmountArrow } from "../../assets";
import { format } from "date-fns";
import PaymentDetailsModal from "../../components/modals/PaymentDetailsModal";
import { Link } from "react-router-dom";

class TuitionPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      counts: {
        overdue: 0,
        paid: 0,
        pending: 0,
        total: 0
      },
      selectedStudent: null,
      selectedName: null,
      selectedData: {
        data: [],
        counts: {
          overdue: 0,
          paid: 0,
          pending: 0,
          total: 0
        }
      },
      selectedInvoice: null
    };
    this.actions = [{ name: "VIEW", action: this.viewInvoices }];
    this.headings = [
      { id: "latestChildName", label: "Student" },
      { id: "amount", label: "Last Payment", customCell: this.amountPaid },
      {
        id: "amountOverdue",
        label: "Upcoming Payment",
        customCell: this.amountUpcoming
      },
      { id: "action", label: "", customStyle: { width: 80 } }
    ];
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "Family Payments"]
    });
    this.subHeadings = [
      { id: "invoice", label: "Payment Number" },
      {
        id: "servicePeriod",
        label: "Service Period",
        customCell: this.servicePeriodCell
      },
      { id: "dueDate", label: `Due Date`, customCell: this.customDueDate },
      { id: "status", label: "Status" },
      { id: "amount", label: "Amount", customCell: this.amountCell },
      { id: "action", label: "", customStyle: { width: 80 } }
    ];
    this.subActions = [{ name: "VIEW", action: this.viewInvoice }];
    this.goBack = this.goBack.bind(this);
  }

  amountCell = r => {
    return <td key={`${r.id}-amt`}>${parseFloat(r.amount).toFixed(2)}</td>;
  };

  servicePeriodCell = r => {
    if (!r.servicePeriodStart) return <td key={`${r.id}-sp`}>N/A</td>;
    return (
      <td key={`${r.id}-sp`}>
        {format(new Date(r.servicePeriodStart), "LLL d, yyyy")} -{" "}
        {format(new Date(r.servicePeriodEnd), "LLL d, yyyy")}
      </td>
    );
  };

  customDueDate = r => {
    return (
      <td key={`${r.id}-dd`}>{format(new Date(r.dueDate), "LLL d, yyyy")}</td>
    );
  };

  viewInvoices = async (e, i) => {
    this.setState({
      selectedStudent: e.latestEnrollmentId,
      selectedName: e.latestChildName
    });
    try {
      const ep = `${process.env.REACT_APP_API}/edu/invoice/${e.latestEnrollmentId}`;
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      if (res.data.success) {
        this.setState({ selectedData: res.data.data });
        this.forceUpdate();
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (er) {
      this.setState({ selectedStudent: null });
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  };

  viewInvoice = (e, i) => {
    this.setState({ selectedInvoice: e.invoice });
  };

  amountPaid = (e, i) => {
    return e.lastPaidAmount ? (
      <td key={`${i}-paid`}>
        ${parseFloat(e.lastPaidAmount).toFixed(2)} paid on{" "}
        {format(new Date(e.lastPaidDate), "MMM dd, yyyy")}
      </td>
    ) : (
      <td key={`${i}-paid`}>N/A</td>
    );
  };

  amountUpcoming = (e, i) => {
    return (
      <td key={`${i}-latest`}>
        ${parseFloat(e.latestAmount).toFixed(2)} due on{" "}
        {format(new Date(e.latestDueDate), "MMM dd, yyyy")}
      </td>
    );
  };

  async componentDidMount() {
    try {
      const ep = `${process.env.REACT_APP_API}/edu/invoice`;
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      if (res.data.success) {
        this.setState({ ...res.data.data });
        this.forceUpdate();
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (er) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  goBack = () => {
    this.setState({
      selectedName: null,
      selectedStudent: null
    });
  };

  render() {
    return !this.state.selectedInvoice ? (
      <div className="container-fluid">
        <div className="cont">
          {!this.state.selectedStudent ? (
            <h1>Family Payments</h1>
          ) : (
            <h1 style={{ position: "relative" }}>
              <img
                src={AmountArrow}
                alt="Previous"
                style={{ position: "absolute", left: -23, top: 10 }}
                onClick={this.goBack}
              />
              Family Payments <span>{this.state.selectedName}</span>
            </h1>
          )}
        </div>

        <div className="cont">
          <div className="row">
            <div className="col-lg-3">
              <Tables.Educators.FamilyOverviewTable
                counts={
                  !this.state.selectedStudent
                    ? this.state.counts
                    : this.state.selectedData.counts
                }
              />
            </div>
            <div className="col-lg-9">
              {!this.state.selectedStudent ? (
                <Table
                  data={this.state.data}
                  headings={this.headings}
                  actions={this.actions}
                />
              ) : (
                <Table
                  data={this.state.selectedData.data}
                  headings={this.subHeadings}
                  actions={this.subActions}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="container-fluid tuition_payments">
        <div className="cont">
          <div className="row">
            <div className="col-md-12">
              <h1>
                <Link
                  onClick={() => {
                    this.setState({ selectedInvoice: null });
                    window.scrollTo(0, 0);
                  }}
                >
                  <img src={AmountArrow} alt="Previous" />
                  Invoice #{this.state.selectedInvoice}{" "}
                  <span>{this.state.selectedName}</span>
                </Link>
              </h1>
            </div>
          </div>
        </div>
        <PaymentDetailsModal invoice={this.state.selectedInvoice} />
      </div>
    );
  }
}

export default TuitionPayments;
