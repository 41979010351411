import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK, STRING_INPUT } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { format } from "date-fns";

class ReplicateFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = { formName: "" };
  }

  async replicate_form() {
    if (this.state.formName.trim() === "") {
      toast.error("Form name cannot be empty!");
      return;
    }

    const ep = `${process.env.REACT_APP_API}/partners/forms/replicate`;
    const res = await axios.post(ep, {
      form_name: this.props.selectedForm.form_name,
      form_sub_name: this.state.formName,
      image_url: this.props.selectedForm.image_url,
      leads_only: this.props.selectedForm.leads_only
    });

    const form_sections_ep = `${process.env.REACT_APP_API}/partners/forms/${this.props.selectedForm.id}`;
    const form_sections_results = await axios.get(form_sections_ep);

    const fields = form_sections_results.data.data.sections;

    fields.forEach(section => {
      section.formId = res.data.data.id;
      delete section.id;
    });

    fields.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    const create_fields_ep = `${process.env.REACT_APP_API}/partners/forms`;
    const create_fields_results = axios.put(create_fields_ep, {
      data: fields
    });

    this.props.onRefresh();
    this.props.onClose();
  }

  /// ================== NEW MODAL ========================== ///
  getBaseModalFields() {
    const newFormNameField = {
      type: STRING_INPUT,
      data: {
        name: "Form Name",
        required: true,
        value: this.state.formName,
        handleChange: e => {
          this.setState({ formName: e.target.value });
        }
      }
    };

    return [newFormNameField];
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Replicate Form",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.replicate_form(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Replicate Form",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "40vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default ReplicateFormModal;
