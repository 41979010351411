import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { createElement } from "react";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  SWITCH,
  DROPDOWN,
  TITLE,
  GET_HTML
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import Select from "react-dropdown-select";

const DOLLAR_TO_CENT = 100;

class AddInvoiceLineItemModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      itemName: "",
      description: "",
      rate: 0,
      isSaveItem: false,
      existingLineItems: [],
      selectedExistingLineItem: "",
      quantity: 1,
      loadingComplete: false
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async addLineItem(e) {
    e.preventDefault();
    console.log("addLineItem");

    if (this.state.selectedExistingLineItem !== "") {
      const existingLine = this.state.existingLineItems.filter(
        e => e.id === parseInt(this.state.selectedExistingLineItem)
      )[0];

      this.props.lineItem(existingLine);
      this.props.onClose();
    } else {
      // Validation for adding a new line item
      if (this.state.itemName === "") {
        toast.error("Item Name number be entered");
      }
      if (this.state.rate == 0) {
        toast.error("Please enter a pricing with a minimum value of $1");
      }

      if (this.state.isSaveItem) {
        const ep = `${process.env.REACT_APP_API}/partners/pricing_items/create`;
        const res = await axios.post(ep, {
          itemName: this.state.itemName,
          description: this.state.description,
          rate: Math.round(this.state.rate * DOLLAR_TO_CENT)
        });
        if (res.data.success) {
          toast.success("Customer Added");
          console.log("res.data.data", res.data.data);

          const createdLine = res.data.data;
          createdLine.quantity = this.state.quantity;

          this.props.lineItem(createdLine);
          this.props.onClose();
        }
      } else {
        const createdLine = this.state;
        createdLine.rate = Math.round(createdLine.rate * DOLLAR_TO_CENT);
        delete createdLine.isSaveItem;

        console.log("createdLine", createdLine);

        this.props.lineItem(createdLine);
        this.props.onClose();
      }
    }
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/pricing_items`;
    const res = await axios.get(ep);
    console.log("res", res);
    if (res.data.success) {
      this.setState({
        existingLineItems: res.data.data,
        loadingComplete: true
      });
    }
  }

  /// ================== NEW MODAL ========================== ///

  exitingNameOptions = () => {
    return this.state.existingLineItems
      .map((e, i) => ({
        value: e.id,
        key: e.id,
        text: e.itemName + " - " + "$" + e.rate / 100
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  };

  existingItemModal = () => {
    return (
      <div>
        <div>
          <label
            htmlFor="displayName"
            style={{
              width: "50%",
              textAlign: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            Existing Item
          </label>
        </div>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <div
            style={{
              height: "40px",
              width: "100%",
              fontSize: "0.93rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "400",
              color: "#797979"
            }}
          >
            <Select
              options={this.exitingNameOptions()}
              name={"selectedUser"}
              placeholder={"Please Select"}
              style={{
                height: "40px",
                width: "100%",
                fontSize: "0.93rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#797979",
                borderRadius: "4px"
              }}
              onChange={e => {
                this.setState({
                  selectedExistingLineItem: e.length === 0 ? null : e[0].value
                });
              }}
              labelField={"text"}
              valueField={"value"}
              closeOnSelect={true}
              searchable={true}
              keepSelectedInList={true}
              dropdownHandle={true}
              clearable={true}
              searchBy={"text"}
              loading={!this.state.loadingComplete}
            />
          </div>
        </div>
      </div>
    );
  };

  getBaseModalFields() {
    const existingName = {
      type: DROPDOWN,
      data: {
        name: "Existing Item",
        required: false,
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            selectedExistingLineItem: e.target.value
          });
        },
        choices: this.exitingNameOptions()
      }
    };

    const existingNameNew = {
      type: GET_HTML,
      data: {
        gethtml: this.existingItemModal
      }
    };

    const or = {
      type: TITLE,
      data: { name: "OR" }
    };

    const Name = {
      type: STRING_INPUT,
      data: {
        name: "Name",
        required: false,
        placeholder: "",
        handleChange: e => {
          this.setState({
            itemName: e.target.value
          });
        },
        value: this.state.itemName
      }
    };

    const internalDescription = {
      type: STRING_INPUT,
      data: {
        name: "Internal Description (Optional)",
        required: false,
        placeholder: "",
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description
      }
    };

    const amount = {
      type: STRING_INPUT,
      data: {
        name: "Amount",
        required: false,
        isDisabled: this.state.total_price && !this.state.price_per_day,
        type: "number",
        handleChange: e => {
          this.setState({
            rate: e.target.value
          });
        },
        value: this.state.rate
      }
    };

    const quantity = {
      type: STRING_INPUT,
      data: {
        name: "Quantity",
        required: false,
        type: "number",
        handleChange: e => {
          this.setState({
            quantity: e.target.value
          });
        },
        value: this.state.quantity
      }
    };

    const save = {
      type: SWITCH,
      data: {
        name: "Save for future user?",
        isDisabled: false,
        checked: this.state.isSaveItem,
        handleClick: e => {
          this.setState({
            isSaveItem: e.target.checked
          });
        }
      }
    };

    const fields = [];
    fields.push(existingNameNew);
    if (this.state.selectedExistingLineItem === "") {
      fields.push(or);

      fields.push(Name);
      fields.push(internalDescription);
      fields.push(amount);
      fields.push(quantity);

      fields.push(save);
    }
    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Create Invoice",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.addLineItem(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Add Invoice Item",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ===================== ///

  render() {
    console.log("this.state.existingLineItems", this.state.existingLineItems);
    console.log("this.state", this.state);
    console.log("this.global", this.global);
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <ModalTemplate
        heading="Add Invoice Item"
        onClose={this.props.onClose}
        style={{ overflow: "visible" }}
      >
        <form onSubmit={e => this.addLineItem(e)}>
          <div id="heightset" className="modal-main">
            <ModalFields.select
              label="Existing Item"
              name="selectedExistingLineItem"
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
              required={false}
            >
              <option value={""}>Please Select</option>
              {this.state.existingLineItems.map((e, i) => (
                <option value={e.id} key={e.id}>
                  {e.itemName}
                </option>
              ))}
            </ModalFields.select>

            {this.state.selectedExistingLineItem === "" && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "16px",
                    color: "#7B7B90",
                    fontWeight: 600,
                    marginBottom: "10px"
                  }}
                >
                  OR
                </div>
                <ModalFields.input
                  label="Name"
                  name="itemName"
                  type="text"
                  required={false}
                  placeholder="Item Name"
                  onChange={e => {
                    this.setState({
                      itemName: e.target.value
                    });
                  }}
                />
                <ModalFields.input
                  label="Internal Description (Optional)"
                  name="description"
                  type="text"
                  required={false}
                  placeholder="Enter Internal Description"
                  onChange={e => {
                    this.setState({
                      description: e.target.value
                    });
                  }}
                />
                <ModalFields.input
                  label="Amount"
                  name="rate"
                  type="number"
                  min={1}
                  step="0.01"
                  value={this.state.rate}
                  required={false}
                  placeholder="$"
                  onChange={e => {
                    this.setState({
                      rate: e.target.value
                    });
                  }}
                />
                <ModalFields.input
                  label="Quantity"
                  name="quantity"
                  type="number"
                  min={1}
                  value={this.state.quantity}
                  required={false}
                  onChange={e => {
                    this.setState({
                      quantity: e.target.value
                    });
                  }}
                />
                <ModalFields.switch
                  label="Save for future use?"
                  name="isSaveItem"
                  checked={this.state.isSaveItem}
                  onClick={e => {
                    this.setState({ [e.target.name]: e.target.checked });
                  }}
                  tooltip="Will be available for future use"
                />
              </>
            )}
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <img
                  src={CircleCheck}
                  alt=""
                  style={{
                    color: "white",
                    height: "15px",
                    width: "15px",
                    marginRight: "8px"
                  }}
                />
                <span>Create Invoice</span>
              </div>
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(AddInvoiceLineItemModal);
