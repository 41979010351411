import React from "react";
import "../fieldComponent.css";
import "./ContentBlock.css";
import Title from "../Title";

const ContentBlock = props => {
  const {
    name,
    content,
    icon,
    textStyles,
    borderStyle,
    handleClick,
    historyUrl
  } = props;

  return (
    <div>
      {/* <p className="content-block-title field-component-title">{name}</p> */}
      <Title icon={icon} name={name} />
      <div style={{ ...borderStyle }}>
        <p
          className="field-component-small-text"
          style={{ ...textStyles }}
          onClick={handleClick}
        >
          {content}{" "}
          {historyUrl && (
            <>
              (See{" "}
              <span
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
                onClick={() => {
                  historyUrl();
                }}
              >
                History
              </span>
              )
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default ContentBlock;
