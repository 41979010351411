import React, { useState } from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalTemplate } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import { BLUE_BUTTON, RED_BUTTON } from "./baseModal/colours";
import { STRING_INPUT, TITLE } from "./baseModal/FieldTypes";

/**
 * Modal can be used as an archive/unarchive confirmation modal.
 *
 * @callback onSubmit Function executed upon user confirmation
 * @callback onClose Function executed when modal is closed.
 *
 * @param {Boolean} toArchive true means archival, false means unarchival.
 * @param {Boolean} confirmHeader Optional custom modal header.
 * @param {String | JSX} modalBody Optional custom modal body.
 *
 * @param {Boolean} isPermanent Whether the deletion is permanent. Will show extra text prompt.
 * @param {Boolean} notAllowed If true, there will be no confirmation button.
 * @param {Boolean} cancelApps Determine whether to set all applications in the existing program to 'Inactive'
 */

const ConfirmArchiveModal = ({
  buttonName,
  onSubmit,
  onClose,
  confirmHeader,
  toArchive,
  modalBody,
  isPermanent,
  notAllowed,
  cancelApps
}) => {
  const [confirmed, setConfirmed] = useState(false);
  
  const confirmationHandler = async e => {
    const successMsg = toArchive
      ? "Successfully deleted!"
      : "Successfully restored!";
    const errorMsg = toArchive ? "Could not delete." : "Could not restore.";
    try {
      e.preventDefault();
      await onSubmit();
      onClose();
      toast.success(successMsg);
    } catch (error) {
      toast.error(errorMsg);
    }
  };

  const getConfirmArchiveModalFields = () => {
    const titleField = {
      type: TITLE,
      data: {
        name:
          modalBody ||
          (toArchive
            ? `Are you sure you would like to remove this listing? ${
                isPermanent
                  ? cancelApps
                    ? "This will be a permanent deletion, and cannot be re-archived later. All current applications will be cancelled."
                    : "This will be a permanent deletion, and cannot be re-archived later."
                  : ""
              }`
            : "Are you sure you would like to restore this listing?")
      }
    };

    const confirmField = {
      type: STRING_INPUT,
      data: {
        name: "Type 'confirm' to archive this listing and deactivate any active enrollments.",
        required: true,
        placeholder: "Type 'confirm'",
        handleChange: e => {
          if (e.target.value.toLowerCase() === "confirm") {
            setConfirmed(true);
          } else {
            setConfirmed(false);
          }
        }
      }
    }

    const fields = [];

    fields.push(titleField);
    if (buttonName) { // using buttonName means that we are in correct modal
      fields.push(confirmField);
    }
    return fields;
  };

  const getConfirmArchiveModalButtons = () => {
    const createButton = {
      name: buttonName || "Confirm",
      buttonColour: toArchive ? RED_BUTTON : BLUE_BUTTON,
      textColour: "#fff",
      isDisabled: buttonName ? !confirmed : false,
      handleClick: confirmationHandler
    };
    return [createButton];
  };

  //Modal for when deletion is allowed;
  const getConfirmArchiveModalProps = () => {
    return {
      title: confirmHeader || "Confirmation",
      fields: getConfirmArchiveModalFields(),
      buttons: getConfirmArchiveModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: onClose
    };
  };

  //Modal props for when deletion is not allowed (user is enrolled to targeted program, event, etc...)
  const getArchiveNotAllowedProps = () => {
    return {
      title: confirmHeader || "Confirmation",
      fields: getConfirmArchiveModalFields(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: onClose
    };
  };

  return (
    <>
      {notAllowed ? (
        <BaseModal {...getArchiveNotAllowedProps()} />
      ) : (
        <BaseModal {...getConfirmArchiveModalProps()} />
      )}
    </>
  );
};

export default withRouter(ConfirmArchiveModal);
