import React from "reactn";
// import { Select } from "../../../../components/UI";
import { Scrollbars } from "react-custom-scrollbars";
import Avatar from "../../../../assets/img/msgicon.png";
import { format } from "date-fns";
import { CometChat } from "@cometchat-pro/chat";
class LeftMessagingPane extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      conversations: []
    };

    this.handleClick = this.handleClick.bind(this);
    this.filterContacts = this.filterContacts.bind(this);
  }

  async componentDidMount() {
    await this.global.userPromise;
    // const builder = new CometChat.ConversationsRequestBuilder()
    //   .setLimit(100)
    //   .build();
    // const conversations = await builder.fetchNext();

    this.setState({ conversations: [] });
  }
  handleClick = name => {
    this.props.handleClick(name);
  };

  filterContacts = () => {
    if (this.state.conversations.length > 0) {
      const contacts = this.state.conversations.filter(conversation =>
        conversation.conversationWith.name
          .toLowerCase()
          .includes(this.state.search.trim().toLowerCase())
      );
      return contacts;
    } else return [];
  };

  render() {
    const { selectedContact } = this.props;
    return (
      <div className="leftside">
        <div className="upermenu">
          <div className="row">
            <div className="col-6">
              <div className="leftm">
                <a href="#/" className="active">
                  Conversations
                </a>
              </div>
            </div>
            {/* <div className="col-6">
              <div className="rightm">
                <a href="#/">
                  <span>3</span>New Connections
                </a>
              </div>
            </div> */}
          </div>
        </div>
        <div className="search search2">
          <div className="row">
            <div className="col-12">
              <div className="msg-search">
                <div className="input">
                  <input
                    type="text"
                    placeholder="Search Contacts"
                    style={{ width: "100%" }}
                    onChange={e => this.setState({ search: e.target.value })}
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-12">
              <div className="select">
                <div className="select">
                  <Select
                    name="filter"
                    options={[
                      { value: 0, label: "All" },
                      { value: "1", label: "Enrolled" },
                      { value: "2", label: "Leads" },
                      { value: "3", label: "Invitees" }
                    ]}
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="msgs">
          {this.filterContacts().map(convo => {
            return (
              <div
                onClick={() => this.handleClick(convo)}
                key={convo.conversationId}
                className={
                  (selectedContact &&
                  selectedContact.conversationId === convo.conversationId
                    ? "active "
                    : "") + "contact"
                }
              >
                <div className="row">
                  <div className="col-2">
                    <div
                      className="divforimg"
                      style={{
                        backgroundImage: `url(${
                          convo.conversationWith.avatar || Avatar
                        })`,
                        backgroundPosition: "center",
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        backgroundSize: "cover"
                      }}
                    >
                      <div
                        className="mincircle"
                        style={{
                          backgroundColor:
                            convo.conversationWith.uid === "admin933735"
                              ? "orange"
                              : convo.conversationWith.status === "online"
                              ? "#16d916"
                              : "grey"
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-7">
                    <h2>
                      {convo.conversationWith.name}{" "}
                      {convo.unreadMessageCount > 0 ? "| Unread" : ""}
                    </h2>
                    <p className="">
                      {convo.lastMessage.data.text
                        ? convo.lastMessage.data.text
                        : "..."}
                    </p>
                  </div>
                  <div className="col-3">
                    <p
                      style={{ textAlign: "right", lineHeight: "14px" }}
                      className="mt-2"
                    >
                      {format(
                        new Date(convo.lastMessage.sentAt * 1000),
                        "LLL d"
                      )}
                      <br />
                      {format(
                        new Date(convo.lastMessage.sentAt * 1000),
                        "hh:mm a"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default LeftMessagingPane;
