import React from "reactn";
import { formTypes, BoxForm } from "../../../../components/UI/form";

class ProgramIntroForm extends React.PureComponent {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = (e, v) => {
    this.setGlobal({
      myProgramIntro: { ...this.global.myProgramIntro, [e]: v }
    });
  };

  render() {
    const vals = this.global.myProgramIntro;
    const blurFunc = e => {
      if (e.target.value.length < 40) e.target.style.border = "1px solid red";
      else e.target.style.border = "1px solid green";
    };
    return (
      <>
        <BoxForm
          handleChange={this.handleChange}
          name={"ABOUT OUR SCHOOL"}
          fields={{
            about: {
              type: formTypes.TextArea,
              val: vals.about,
              name: "About",
              onBlur: blurFunc,
              required: true,
              fullWidth: true,
              rows: 10,
              isDisabled:
                this.global.profile.programSubmitted &&
                this.global.dsUser.accountType !== "Admin"
            }
          }}
        />
        <BoxForm
          handleChange={this.handleChange}
          name={"OUR EDUCATORS"}
          fields={{
            ourEducators: {
              type: formTypes.TextArea,
              val: vals.ourEducators,
              name: "Our Educators",
              onBlur: blurFunc,
              required: true,
              fullWidth: true,
              rows: 10,
              isDisabled:
                this.global.profile.programSubmitted &&
                this.global.dsUser.accountType !== "Admin"
            }
          }}
        />
        <BoxForm
          handleChange={this.handleChange}
          name={"LOCATION"}
          fields={{
            location: {
              type: formTypes.TextArea,
              val: vals.location,
              name: "Location",
              onBlur: blurFunc,
              required: true,
              fullWidth: true,
              rows: 10,
              isDisabled:
                this.global.profile.programSubmitted &&
                this.global.dsUser.accountType !== "Admin"
            }
          }}
        />
      </>
    );
  }
}

export default ProgramIntroForm;
