import React from "react";
import PropTypes from "prop-types";
const PERCENT = "Percentage";
const FIXED_AMOUNT = "Fixed Amount";

const BookingDetailPromotionCard = ({ promotion }) => {
  return (
    <div style={{ color: "red" }}>
      {promotion.discount_type === PERCENT && (
        <>{promotion.discount_percent}% Off</>
      )}
      {promotion.discount_type === FIXED_AMOUNT && (
        <>${Math.max(0, promotion.discount_fixed / 100).toFixed(2)} Off</>
      )}
    </div>
  );
};

BookingDetailPromotionCard.propTypes = {
  promotion: PropTypes.shape({
    discount_type: PropTypes.string.isRequired,
    discount_percent: PropTypes.number,
    discount_fixed: PropTypes.number
  })
};

export default BookingDetailPromotionCard;
