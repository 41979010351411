import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, TEXT_AREA, TITLE } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class TaxRateModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      display_name: "Sales Tax",
      percentage: 0,
      jurisdiction: "",
      description: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async submit() {
    const ep = `${process.env.REACT_APP_API}/partners/stripe/addTax`;

    try {
      const res = await axios.post(ep, {
        display_name: this.state.display_name,
        description: this.state.description,
        jurisdiction: this.state.jurisdiction,
        percentage: this.state.percentage
      });

      if (res.data.success) {
        this.setGlobal({
          organizationInfo: {
            ...this.global.organizationInfo,
            stripeTaxId: res.data.data.stripeTaxId
          }
        });
        toast.success("Tax Rate Added!");
        this.props.onClose();
      }
    } catch {
      toast.error("Could not Add Tax, Try again Later");
    }
  }
  /// ================== NEW MODAL ========================== ///
  getBaseModalFields() {
    const type = {
      type: TITLE,
          data: {name: "Type: Sales Tax" }
    };



    const rateInput = {
      type: STRING_INPUT,
      data: {
        name: "Rate (%)",
        required: false,
        styles: {
          width: "80%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleChange: e => {
          this.setState({
            percentage: e.target.value
          });
        },
        value: this.state.percentage
      },
    };




    const regionInput = {
      type: STRING_INPUT,
      data: {
        name: "Region",
        required: false,
        styles: {
          width: "80%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleChange: e => {
          this.setState({
            jurisdiction: e.target.value
          });
        },
        value: this.state.jurisdiction
      },
    };


     const descriptionInput = {
      type: TEXT_AREA,
      data: {
        name: "Description",
        required: false,
        styles: {
          width: "80%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description
      },
    };

    


    return [type, rateInput, regionInput, descriptionInput];
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.submit()
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Create Tax",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      width: "550px"
    };
  };




  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px",
                marginBottom: ".5rem"
              }}
            >
              <h3>Create Tax</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="display_name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Type
                </label>
                <div
                  style={{
                    width: "70%",
                    fontSize: ".9rem",
                    fontWeight: "600",
                    lineHeight: "1.5",
                    color: "#8c8ea0"
                  }}
                >
                  Sales Tax
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="percentage"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Rate (%)
                </label>
                {/* <div style={{ width: "75%", fontSize: ".9rem", fontWeight: "400", lineHeight: "1.5", color: "#8c8ea0" }}> */}
                <input
                  name="percentage"
                  id="percentage"
                  type="number"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  // value={this.state.rate}
                />
                {/* </div> */}
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="jurisdiction"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Region
                </label>
                {/* <div style={{ width: "75%", fontSize: ".9rem", fontWeight: "400", lineHeight: "1.5", color: "#8c8ea0" }}> */}
                <input
                  name="jurisdiction"
                  id="jurisdiction"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
                {/* </div> */}
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="memo"
                  style={{
                    width: "25%",
                    textAlign: "left",
                    marginRight: "22px"
                  }}
                >
                  Description
                </label>
                <textarea
                  name="description"
                  id="description"
                  rows="4"
                  cols="12"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
              </div>
            </form>
            <div className="modal-footer">
              <button
                type="submit"
                onClick={_ => this.submit()}
                className="btn profile-btn"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TaxRateModal);
