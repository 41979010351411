import React, { useState, useEffect, useGlobal } from "reactn";
import axios from "axios";
import _ from "lodash";
import { format, parse } from "date-fns";

import { useDateFilter, useListings } from "../../../providers/reports";

import {
  DateFilter,
  ReportContainer,
  ReportTable
} from "../../../components/UI/reportsAnalytics";

const csvHeaders = [
  { label: "Full Name", key: "name" },
  { label: "Date", key: "date" },
  { label: "Attendance Status", key: "status" },
  { label: "Program", key: "program" }
];

const ReportsAttendance = () => {
  const global = useGlobal()[0];
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");
  const [dateFrom, dateTo, setDateFrom, setDateTo] = useDateFilter();
  const { programs, onlineVirtuals } = useListings();
  const [selectedProgram, setSelectedProgram] = useState("");
  const [listingType, setListingType] = useState("Program");
  const [studentsList, setStudentsList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const ep = `${process.env.REACT_APP_API}/partner/attendance/reports/${global.organizationInfo.partnerId}`;
      const results = await axios.get(ep);

      if (results.data.success) {
        setStudentsList(results.data.data.students);
      }
    };
    fetchData();
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" },
      { label: "Attendance", to: "/reports/attendance" }
    ]);
  }, []);

  const displayAdditional = r => {
    if (r.status === "Reduced") {
      return `(${r.percent}%)`;
    }
    if (r.status === "Extended") {
      return `($${r.fee})`;
    }
    if (r.status === "Absent" && r.percent !== 0) {
      return `(${r.percent}%)`;
    }
    return "";
  };

  /**
   * Filters students by program selected and by date range selected.
   *
   * @returns {Array} Filtered array of students, sorted by date.
   */
  const getFilteredStudents = () => {
    let filteredStudents = studentsList;
    if (selectedProgram !== "") {
      filteredStudents = studentsList
        .filter(e => {
          const dateFromUnix = new Date(dateFrom).getTime();
          const tempDateTo = new Date(dateTo);
          // Offset by a day to provide the correct filter.
          const dateToUnix = tempDateTo.setDate(tempDateTo.getDate() + 1);

          const programDate = new Date(e.attDate).getTime();
          return (
            e.program_name === selectedProgram &&
            programDate >= dateFromUnix &&
            programDate <= dateToUnix
          );
        })
        .sort((a, b) => {
          return new Date(a.attDate) - new Date(b.attDate);
        });
    }
    return filteredStudents;
  };

  const displayProgram = () => {
    const filteredStudents = getFilteredStudents();

    if (filteredStudents.length === 0 || selectedProgram === "") {
      return [];
    }

    return filteredStudents.map(r => {
      return [
        r.fullName || r.displayName,
        format(new Date(r.attDate), "MMM d, yyyy"),
        `${r.status} ${displayAdditional(r)}`
      ];
    });
  };

  /**
   * Gets the CSV data for the CSVLink component to use.
   *
   * Currently, no checking is done to ensure the resulting data is non-empty.
   *
   * @returns {Array} Array of objects {name, date, status}.
   */
  const getCsvData = () => {
    const filteredStudents = getFilteredStudents();
    return filteredStudents.map(student => {
      return {
        name: student.fullName || student.displayName,
        date: format(new Date(student.attDate), "MMM d, yyyy"),
        status: student.status + displayAdditional(student),
        program: selectedProgram
      };
    });
  };

  let listings = [];
  switch (listingType) {
    case "Program":
      listings = programs;
      break;

    case "Online":
      listings = onlineVirtuals;
      break;

    default:
      break;
  }

  let reportSubtitles = [
    "Attendance",
    `For ${format(parse(dateFrom, "yyyy-MM-dd", new Date()), "MMM d, yyyy")}
    - 
    ${format(parse(dateTo, "yyyy-MM-dd", new Date()), "MMM d, yyyy")} 
    `
  ];

  if (selectedProgram) {
    reportSubtitles.push(selectedProgram);
  }

  return (
    <div className="admin">
      <div className="container-fluid adminprofiles">
        <div className="row cont">
          <div className="col-md-6">
            <h1>Attendance</h1>
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-section">
            <DateFilter
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
            />
            <div className="filter-item grid-new-col">
              <label htmlFor="client_name" className="filter-label">
                Listings Type
              </label>
              <select
                name="client_name"
                id="client_name"
                type="text"
                className="form-control filter-input"
                value={listingType}
                onChange={e => {
                  setListingType(e.target.value);
                  setSelectedProgram("");
                }}
              >
                <option value="Program">Program</option>
                <option value="Online">Online Virtual</option>
              </select>
              <select
                name="program"
                id="program"
                type="text"
                className="form-control filter-input"
                value={selectedProgram}
                onChange={e => setSelectedProgram(e.target.value)}
              >
                <option value="" disabled selected hidden>
                  Please Select
                </option>
                {listings.map((e, i) => {
                  return (
                    <option value={e.program_name} key={i}>
                      {e.program_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <ReportContainer
          title="Attendance"
          subtitles={reportSubtitles}
          csvHeaders={csvHeaders}
          csvData={getCsvData()}
          fileName={`Attendance_${dateFrom}_${dateTo}.csv`}
        >
          <div className="report-table-large">
            <ReportTable
              headings={[
                { label: "Student Name", className: "table-item-bold" },
                { label: "Date" },
                {
                  label: "Status",
                  className: "table-item-money",
                  style: { textAlign: "right" }
                }
              ]}
              body={displayProgram()}
              noDataText="No attendance data found for the selected filters!"
            />
          </div>
        </ReportContainer>
      </div>
    </div>
  );
};

export default ReportsAttendance;
