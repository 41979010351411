import React, { useState } from "react";
import PropTypes from "prop-types";
import InfoBalloon from "./InfoBalloon";
// import { ImageActiveA } from "../../assets";
// import Pagination from "../pagination/pagination";
import { withStyles } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "../../assets/css/componentSpecificCss/newTabbedTable.css";
import Pagination from "../pagination/pagination";

const styles = {
  thead: {
    background: "transparent !important",
    "& th": {
      color: "#A9A9A9 !important",
      fontSize: "0.7em"
    }
  },
  tabRootTable: {
    color: "gray !important",
    fontSize: "0.9em !important",
    marginRight: "1.5em !important",
    fontFamily: "Montserrat,sans-serif !important",
    fontWeight: "400 !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "gray !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "gray !important"
    }
  },
  tabSelectedTable: {
    color: "black !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "black !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "black !important",
      outline: "none !important"
    }
  },
  tabIndicatorTable: {
    backgroundColor: "#1C3EC3",
    height: "3px"
  }
};

const NewTabbedTable = props => {
  const {
    inModal,
    data,
    headings,
    tabs,
    actions,
    onTabChange,
    showTab,
    page,
    dataCount,
    isFirstTab,
    resetPreview,
    setCurrTab,
    currentPage
  } = props;
  const [useTab, setTab] = useState(0);
  const [usePage, setPage] = useState(0);
  const [useDataLength, setDataLength] = useState(
    data[tabs[0]] ? data[tabs[0]].length : 0
  );

  if (
    page === "Leads>Applications" ||
    page === "Listings>Programs" ||
    page === "Listings>Events" ||
    page === "Listings>OnlineVirtual" ||
    page === "Listings>Memberships" ||
    page === "Listings>Parties"
  ) {
    setCurrTab(isFirstTab ? 0 : useTab);
  }

  let numItemsShown = 10000;
  if (
    page &&
    (page === "Leads>Applications" ||
      page === "Listings>Programs" ||
      page === "Listings>Events" ||
      page === "Listings>OnlineVirtual" ||
      page === "Listings>Memberships" ||
      page === "Listings>Parties" ||
      page === "Financials>Paid Orders" ||
      page === "Financials>Recurring Payments")
  ) {
    numItemsShown = 25;
  } else if (
    page && 
    (page === "Contacts>Connections" ||
      page === "Leads>Active")
    ) {
    numItemsShown = 50;
  }

  // if showTab is undefined, default it to true
  const shouldShowTab = showTab === undefined ? true : showTab;

  React.useEffect(() => {
    if (currentPage !== undefined && currentPage !== -1) {
      setPage(currentPage);
    }

    if (
      (page === "Leads>Applications" ||
        page === "Listings>Programs" ||
        page === "Listings>Events" ||
        page === "Listings>OnlineVirtual" ||
        page === "Listings>Memberships" ||
        page === "Listings>Parties" ||
        page === "Contacts>Connections" ||
        page === "Leads>Active") &&
      dataCount &&
      dataCount[tabs[useTab]]
    ) {
      //console.log("CASE 1", dataCount[tabs[useTab]].length);
      setDataLength(dataCount[tabs[useTab]].length);
      if (isFirstTab) {
        changeTab(0);
        resetPreview();
      }
    } else {
      //console.log("CASE 2");
      setDataLength(data[tabs[useTab]] ? data[tabs[useTab]].length : 0);
    }
    //TEMPORARY
  }, [data, tabs, useTab]);

  const changeTab = i => {
    setDataLength(data[tabs[i]] ? data[tabs[i]].length : 0);
    setPage(0);
    setTab(i);
    if (onTabChange) onTabChange(i);
  };


  return (
    <>
      {shouldShowTab && (
        <div style={{ margin: "1em 0 1em 0" }}>
          {props.hideHeader ? null : (
            <Tabs
              value={useTab}
              onChange={(event, newValue) => changeTab(newValue)}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              TabIndicatorProps={{ className: props.classes.tabIndicatorTable }}
            >
              {tabs.map((tab, i) => (
                <Tab
                  id={inModal}
                  key={i}
                  label={
                    page === "Leads>Applications" ||
                    page === "Listings>Programs" ||
                    page === "Listings>Events" ||
                    page === "Listings>OnlineVirtual" ||
                    page === "Listings>Memberships" ||
                    page === "Listings>Parties"
                      ? tab +
                        `${
                          dataCount && dataCount[tab]
                            ? ` (${dataCount[tab].length})`
                            : ""
                        }`
                      : tab + `${data[tab] ? ` (${data[tab].length})` : ""}`
                  }
                  value={i}
                  disableRipple
                  disableFocusRipple
                  classes={{
                    root: props.classes.tabRootTable,
                    selected: props.classes.tabSelectedTable
                  }}
                />
              ))}
            </Tabs>
          )}
        </div>
      )}

      <div style={{ overflow: "auto" }}>
        <table className="table">
          <thead
            className={props.classes.thead}
            style={{ borderTop: "none !important" }}
          >
            <tr>
              {headings[tabs[useTab]] &&
                headings[tabs[useTab]].map((heading, index) => (
                  <th
                    scope="col"
                    key={index}
                    style={heading.customStyle ? heading.customStyle : {}}
                  >
                    {heading.label}
                    {heading.infoBalloon && (
                      <InfoBalloon body={heading.infoBalloon} />
                    )}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {data[tabs[useTab]] &&
              data[tabs[useTab]]
                .slice(usePage * numItemsShown, usePage * numItemsShown + numItemsShown)
                .map((row, k) => {
                  return (
                    <tr key={k} style={{ background: "white" }}>
                      {headings[tabs[useTab]] &&
                        headings[tabs[useTab]].map((heading, index) => {
                          if (heading.id === "action") {
                            return (
                              <td key={index} style={{ whiteSpace: "nowrap" }}>
                                {actions.map((action, i) => (
                                  <button
                                    onClick={() => action.action(row)}
                                    key={i}
                                    style={{ marginLeft: "10px" }}
                                    className="table-action-button"
                                  >
                                    {action.name}
                                  </button>
                                ))}
                              </td>
                            );
                          } else {
                            return heading.customCell ? (
                              heading.customCell(row, index)
                            ) : (
                              <td key={index}>{row[heading.id]}</td>
                            );
                          }
                        })}
                    </tr>
                  );
                })}

            {!data || !data[tabs[useTab]] || !data[tabs[useTab]].length ? (
              <tr style={{ background: "white" }}>
                <td
                  colSpan={
                    headings[tabs[useTab]] ? headings[tabs[useTab]].length : 5
                  }
                  className="text-center"
                >
                  Nothing to Show
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      <div style={{ paddingBottom: "25px" }}>
        {console.log("PAGE: ", usePage)}
        {page &&
          (page === "Leads>Applications" ||
            page === "Listings>Programs" ||
            page === "Listings>Events" ||
            page === "Listings>OnlineVirtual" ||
            page === "Listings>Memberships" ||
            page === "Listings>Parties" ||
            page === "Financials>Paid Orders" ||
            page === "Financials>Recurring Payments" ||
            page === "Contacts>Connections"||
            page === "Leads>Active") && (
            <Pagination
              pageCount={Math.ceil(useDataLength / numItemsShown)}
              onPageChange={setPage}
              forcePage={usePage}
            />
          )}
      </div>
    </>
  );
};

NewTabbedTable.propTypes = {
  headings: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  actions: PropTypes.array
};

NewTabbedTable.defaultProps = {
  headings: {},
  data: {},
  tabs: [],
  actions: []
};

export default withStyles(styles)(NewTabbedTable);
