import React, { useState, useGlobal } from "reactn";
import axios from "axios"
import { format } from "date-fns";

import "../../../../assets/css/componentSpecificCss/communications.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import { IconButton } from "@material-ui/core";
import { TuneRounded } from "@material-ui/icons";

/**
 * Table can show either message history or two-way SMS on the communications page.
 *
 * @param {Array} messageThreads Array of data to display in table.
 * @param {Function} setSelectedThread Run when row is clicked.
 * @param {String} selectedThread string uid selected thread.
 */
const MessageTable = ({
  messageThreads,
  setSelectedThread,
  selectedThread,
  // setLeftPaneOptions,
}) => {
  const [global, _] = useGlobal();
  const [something, setSomething] = useState("some something")

  const triggerCrash1 = (evt) => {
    console.log("Triggering a White Screen of Death in React - caused by an error during rendering.");
    setSomething("hiyo!");
  }
  const triggerCrash2 = (evt) => {
    console.log("Triggering an Uncaught Error in the app - caused by an error during event handling.");
    // try {
    throw new Error("Dummy Error!!! Caused by error during event handling!");
    // } catch(e){ console.log("==== caught this error!", e);}
  }
  const triggerCrash3 = (evt) => {
    setTimeout(() => {
      console.log("5sec elapsed. Time now to throw an error!");
      throw new Error("Dummy Error!!! Caused by error during a delayed function call (not async)!");
    }, 5000);
    console.log("Triggering an Uncaught async  in the app - caused by an error in a delayed function.");
  }
  const triggerCrash4 = (evt) => {
    setTimeout(async () => {
      console.log("1sec elapsed. Time now to throw an error!");
      throw new Error("Dummy Error!!! Caused by error during an asynchronous function call!");
    }, 1000);
    console.log("Triggering an Uncaught Error in the app - caused by an error during async function.");

    // let p = new Promise(()=>{
    //   throw new Error("Dummy Error!!! Caused by error thrown inside a Promise!");
    // });
    // p.then().catch();
    // console.log("Triggering an Uncaught Promise Rejection in the app - caused by an error thrown inside a Promise.");
  }

  const searchSettingsMenu = e => {
    return (
      <Menu
        onClick={e => { console.__warn("MessageTable -> settings menu -> onClick handler: I'm here!", e); }}
        selectable={false}
      >
        <MenuItem onClick={triggerCrash1} className="settingsMenuItem">WSoD Crash</MenuItem>
        <MenuItem onClick={triggerCrash2} className="settingsMenuItem">Uncaught Error #1 - event errors</MenuItem>
        <MenuItem onClick={triggerCrash3} className="settingsMenuItem">Uncaught Error #2 - delayed errors</MenuItem>
        <MenuItem onClick={triggerCrash4} className="settingsMenuItem">Uncaught Error #3 - promise rejections</MenuItem>
      </Menu>
    );
  };

  if (something !== "some something") {
    console.log("FS: MessageTable - about to throw an error!");
    throw new Error("dummy error!!!! Should trigger a WSoD in React.");
  }

  const getName = (row) => {
    try {
      const json = JSON.parse(row.to[0]);
      return json.name;
    } catch (err) {
      return "No Name";
    }
  }

  return (
    <div className="tablecont" style={{ minHeight: "500px" }}>
      <div className="table-responsive comm-table">
        <div className="message-top">
          <span className="message-heading">TOPIC THREADS</span>
          {/*========TEMPORARY========*/}
          {global.dsUser?.isImpersonating && (
            <div
              style={{
                padding: "0",
                position: "absolute",
                right: "2px",
                top: "2px",
                zIndex: "5"
              }}
            >
              <div>
                <Dropdown
                  trigger={["click"]}
                  overlay={searchSettingsMenu}
                  animation="slide-up"
                >
                  <IconButton style={{ color: "white" }}>
                    <TuneRounded />
                  </IconButton>
                </Dropdown>
              </div>
            </div>
          )}
          {/*========TEMPORARY========*/}
        </div>
        <div
          className="table-wrapper"
          style={{ overflow: "auto", height: "calc(100vh - 215px)" }}
        >
          <table className="table">
            <tbody>
              {messageThreads.map((row, index) => {
                let unreadMarker = false;
                if (row.isTwilioThread) {
                  const unreadMsgCount = row.messages.filter(msg => msg.unread).length;
                  if (unreadMsgCount > 0) {
                    unreadMarker = unreadMsgCount;
                  }

                  if (unreadMarker && row.threadTitle === selectedThread?.threadTitle) {
                    const ep = `${process.env.REACT_APP_API}/partners/connections/update-last-read`;
                    const result = axios.post(ep, {
                      receivedFrom: row.phone
                    })
                    unreadMarker = false;
                  }
                }
                return (
                  <tr
                    className={`comm-content ${row.uid === selectedThread?.uid ? "selected" : ""}`}
                    key={index}
                    onClick={() => {
                      setSelectedThread(row);
                      if (row.isTwilioThread) {
                        const ep = `${process.env.REACT_APP_API}/partners/connections/update-last-read`;
                        const result = axios.post(ep, {
                          receivedFrom: row.phone
                        })
                      }
                    }}
                  >
                    <td className="pfp-cell">
                      {unreadMarker && (
                        <div style={{
                          position: "relative",
                          width: 0,
                          height: 0
                        }}>
                          <div style={{
                            position: "absolute",
                            left: "30px",
                            top: "-5px",
                            width: "20px",
                            height: "20px",
                            borderRadius: "10px",
                            backgroundColor: "red",
                            position: "absolute",
                            color: "#FFF",
                            fontSize: "10px",
                            textAlign: "center"
                          }}>
                            {unreadMarker}
                          </div>
                        </div>
                      )}
                      <div>
                        <LazyLoadImage
                          src={row.photo}
                          className="pfp"
                          alt="pfp"
                          effect="opacity"
                        />
                      </div>
                    </td>

                    <td className="to-cell">
                      <div>
                        <div className="ellipsis-overflow">
                          {row.threadTitle || getName(row)}
                        </div>
                        <div className="ellipsis-overflow" style={{ color: "slategray" }}>
                          {row.latestMessageIsEmail ? "Email:" : "SMS:"} {row.latestMessageSubject || `<no subject>`}
                        </div>
                      </div>

                    </td>

                    <td className="date-cell">
                      <div>
                        <div>{format(new Date(row.latestMessageDate), "MMM d")}</div>
                        <div style={{ color: "slategray" }}>
                          {format(new Date(row.latestMessageDate), "h:mm a")}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
              {/* =================================================================================================== */}
              {messageThreads.length === 0 && (
                <tr>
                  <td className="text-center">No data to show.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MessageTable;
