import React from "reactn";
import { Table, Modal } from "../../components/UI";
import axios from "axios";
import { addYears, format } from "date-fns";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

class DSFees extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      spotsOpen: false,
      avail: null,
      spots: 0,
      data: [],
      filter: ""
    };

    this.getAvailabilityForProgram = this.getAvailabilityForProgram.bind(this);

    this.headings = [
      { id: "educatorName", label: "Educator" },
      { id: "programName", label: "Program" },
      {
        id: "contractPeriod",
        label: "Contract Period",
        customCell: this.periodCell
      },
      {
        id: "dsFee",
        label: "Fee Percentage",
        customCell: this.feeCell
      },
      {
        id: "spots",
        label: "Spots",
        customCell: this.spotsCell
      },
      { id: "action", label: "", customStyle: { width: 180 } }
    ];
    this.actions = [
      { name: "EDIT", action: () => this.setState({ open: true }) }
    ];
    this.filterApplications = this.filterApplications.bind(this);
  }

  filterApplications = () => {
    return this.state.data.filter(
      d =>
        (d.educatorName &&
          d.educatorName.toLowerCase().includes(this.state.filter)) ||
        (d.programName &&
          d.programName.toLowerCase().includes(this.state.filter))
    );
  };

  nameCell = (row, i) => {
    return <td key={`${i}-name`}>{row.educatorName || row.displayName}</td>;
  };

  spotsCell = (row, i) => {
    return (
      <td key={`${i}-spot`}>
        {row.spots}{" "}
        <i
          className="fas fa-question ml-2"
          style={{
            borderRadius: "50%",
            backgroundColor: "#e3e3e3",
            fontSize: "7pt",
            padding: 5,
            color: "#8877ff"
          }}
          onClick={() => this.getAvailabilityForProgram(row)}
        ></i>
      </td>
    );
  };

  async getAvailabilityForProgram(program) {
    const ep = `${process.env.REACT_APP_API}/adm/educator/fees/slots/${program.id}`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      this.setState({
        spotsOpen: true,
        avail: res.data.data.availability,
        spots: program.spots
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  periodCell = (row, i) => {
    return (
      <td key={`${i}-act`} className="text-center">
        {format(new Date(row.applicationSubmittedDate), "LLL d, yyyy")} -{" "}
        {format(
          addYears(
            new Date(row.applicationSubmittedDate),
            parseInt(row.termLength)
          ),
          "LLL d, yyyy"
        )}
        <br />
        {row.termLength} year(s)
      </td>
    );
  };

  feeCell = (row, i) => {
    return <td key={`${i}-fee`}>{row.dsFee} %</td>;
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/educator/fees`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      this.setState({ data: res.data.data });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    const { open, spotsOpen, avail, spots } = this.state;
    return (
      <div className="container-fluid">
        <Modal
          open={open}
          heading="Edit Contract"
          onClose={() =>
            this.setState({
              open: false
            })
          }
        >
          <div className="row"></div>
        </Modal>
        {!avail ? null : (
          <Modal
            open={spotsOpen}
            heading="Viewing Spot Availability"
            onClose={() =>
              this.setState({
                spotsOpen: false,
                avail: null,
                spots: 0
              })
            }
          >
            {avail.map((slot, i) => (
              <div className="row" key={i}>
                <div className="col-4">{days[i]}</div>
                <div className="col-8 text-center">
                  {slot} / {spots}
                </div>
              </div>
            ))}
          </Modal>
        )}
        <div className="cont">
          <h1>Dreamschools Fees</h1>
        </div>
        <div className="cont">
          <input
            type="text"
            onChange={e =>
              this.setState({ filter: e.target.value.trim().toLowerCase() })
            }
            style={{
              marginBottom: 15,
              width: "100%",
              borderRadius: 5,
              border: "2px solid #E3E6F0",
              fontSize: "13px",
              padding: 3,
              outline: 0
            }}
            placeholder="Filter by Educator Name or Program Name..."
          />
          <Table
            headings={this.headings}
            data={this.filterApplications()}
            actions={this.actions}
          />
        </div>
      </div>
    );
  }
}
export default DSFees;
