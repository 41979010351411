import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { format, isToday, isBefore } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  DROPDOWN,
  DATE_PICKER,
  SELECT_MULTIPLE_DROPDOWN,
  GET_HTML
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class ConfirmSendEmailModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isToday: false
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async sendEmail(e) {
    e.preventDefault();
    console.log("sendEmail");

    if (this.state.isToday) {
      const ep = `${process.env.REACT_APP_API}/partners/orders/invoice/send-email-to-user`;
      const res = await axios.post(ep, {
        id: this.props.match.params.id,
        invoice_message: this.props.message,
        isUpdating: this.props.isUpdating,
      });

      console.log("res", res);
      if (res.data.success) {
        toast.success("Email sent");
        this.props.sendMessage();
        this.props.onClose();
      }
    } else {
      const ep = `${process.env.REACT_APP_API}/partners/orders/update`;
      const res = await axios.post(ep, {
        id: this.props.match.params.id,
        invoice_status: "Ready to Bill",
        invoice_message: this.props.message
      });

      console.log("res", res);
      if (res.data.success) {
        toast.success("Email will be sent");
        this.props.sendMessage();
        this.props.onClose();
      }
    }
  }

  componentDidMount() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const today = (isToday(new Date(this.props.data.orderStartDate)) && isToday(zonedTimeToUtc(this.props.data.orderStartDate, timeZone))) || 
                isBefore(new Date(this.props.data.orderStartDate), new Date());
    this.setState({
      isToday: today,
    });
  }

  descriptionInput = () => {
    return (
      <div id="heightset" className="modal-main">
        <p
          style={{
            fontSize: "16px",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262"
          }}
        >
          Are you sure you want to send this invoice?
        </p>
        <p
          style={{
            fontSize: "16px",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262"
          }}
        >
          Issuing this will send the invoice to the user on the issue date:{" "}
          <span style={{ textDecoration: "underline" }}>
            {this.state.isToday
              ? "today"
              : format(
                  new Date(this.props.data.orderStartDate),
                  "LLL dd, yyyy"
                )}
          </span>
          .
        </p>
      </div>
    );
  };

  getBaseModalFields() {
    const descriptionText = {
      type: GET_HTML,
      data: {
        gethtml: this.descriptionInput
      }
    };

    const fields = [];
    fields.push(descriptionText);

    return fields;
  }

  getBaseModalButtons = () => {
    const confirmSend = {
      name: "Confirm Send",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.sendEmail(e)
    };

    const fieldsbutton = [];
    fieldsbutton.push(confirmSend);

    return fieldsbutton;
  };

  getBaseModalProps = () => {
    return {
      title: "Confirm Send Email",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    console.log("this.state", this.state);
    console.log("this.global", this.global);
    console.log("this.props", this.props);
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <ModalTemplate
        heading="Confirm Send Email"
        onClose={this.props.onClose}
        style={{ overflow: "visible" }}
      >
        <form onSubmit={e => this.sendEmail(e)}>
          <div id="heightset" className="modal-main">
            <p style={{ fontSize: "16px" }}>
              Are you sure you want to send this invoice?
            </p>
            <p style={{ fontSize: "16px" }}>
              Issuing this will send the invoice to the user on the issue date:{" "}
              <span style={{ textDecoration: "underline" }}>
                {this.state.isToday
                  ? "today"
                  : format(
                      new Date(this.props.data.orderStartDate),
                      "LLL dd, yyyy"
                    )}
              </span>
              .
            </p>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <img
                  src={CircleCheck}
                  alt=""
                  style={{
                    color: "white",
                    height: "15px",
                    width: "15px",
                    marginRight: "8px"
                  }}
                />
                <span>Confirm Send</span>
              </div>
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(ConfirmSendEmailModal);
