import React, { useState } from "react";
import PropTypes from "prop-types";
import InfoBalloon from "./InfoBalloon";
import { withStyles } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const styles = {
  thead: {
    background: "transparent !important",
    "& th": {
      color: "#A9A9A9 !important",
      fontSize: "0.7em !important"
    }
  },
  tabRoot: {
    color: "gray !important",
    fontSize: "0.9em !important",
    marginRight: "1.5em !important",
    fontFamily: "Montserrat,sans-serif !important",
    fontWeight: "400 !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "gray !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "gray !important"
    }
  },
  tabSelected: {
    color: "black !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "black !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "black !important",
      outline: "none !important"
    }
  },
  tabIndicator: {
    backgroundColor: "#7ea1ff",
    height: "3px"
  }
};

const NewDraggableTabbedTable = props => {
  const { data, headings, tabs, actions, onTabChange, onDragEndHandler } =
    props;
  const [useTab, setTab] = useState(0);
  const [usePage, setPage] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [useDataLength, setDataLength] = useState(
    data[tabs[0]] ? data[tabs[0]].length : 0
  );

  React.useEffect(() => {
    setDataLength(data[tabs[useTab]] ? data[tabs[useTab]].length : 0);

    //TEMPORARY
  }, [data, tabs, useTab]);

  const changeTab = i => {
    setDataLength(data[tabs[i]] ? data[tabs[i]].length : 0);
    setPage(0);
    setTab(i);
    if (onTabChange) onTabChange(i);
  };

  const onBeforeDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = ({ destination, source, draggableId }) => {
    onDragEndHandler(destination, source, draggableId, useTab);
    setIsDragging(false);
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    if (isDragging || !isDragging) {
      return {
        // some basic styles to make the items look a bit nicer
        userSelect: "none",

        // change background colour if dragging
        background: "white",

        // styles we need to apply on draggables
        ...draggableStyle
      };
    }
  };

  const getListStyle = isDraggingOver => {
    if (!isDraggingOver) {
      return {
        background: "inherit"
      };
    }
  };

  return (
    <>
      <div style={{ margin: "1em 0 1em 0" }}>
        <Tabs
          value={useTab}
          onChange={(event, newValue) => changeTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{ className: props.classes.tabIndicator }}
        >
          {tabs.map((tab, i) => (
            <Tab
              label={tab + `${data[tab] ? ` (${data[tab].length})` : ""}`}
              value={i}
              key={i}
              disableRipple
              disableFocusRipple
              classes={{
                root: props.classes.tabRoot,
                selected: props.classes.tabSelected
              }}
            />
          ))}
        </Tabs>
      </div>

      <div style={{ overflow: "auto" }}>
        <table className="table">
          <thead className={props.classes.thead}>
            <tr>
              {headings[tabs[useTab]] &&
                headings[tabs[useTab]].map((heading, index) => (
                  <th
                    scope="col"
                    key={index}
                    style={heading.customStyle ? heading.customStyle : {}}
                  >
                    {heading.label}
                    {heading.infoBalloon && (
                      <InfoBalloon body={heading.infoBalloon} />
                    )}
                  </th>
                ))}
            </tr>
          </thead>

          <DragDropContext
            onDragEnd={onDragEnd}
            onBeforeDragStart={onBeforeDragStart}
          >
            <Droppable droppableId="tableItems">
              {(provided, snapshot) => (
                <tbody
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {data[tabs[useTab]] &&
                    data[tabs[useTab]]
                      // .slice(usePage * 7, usePage * 7 + 7)
                      .map((row, k) => {
                        return (
                          <Draggable
                            draggableId={`${row.id}`}
                            index={k}
                            key={row.id}
                          >
                            {(provided, snapshot) => {
                              return (
                                <tr
                                  key={k}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  {headings[tabs[useTab]] &&
                                    headings[tabs[useTab]].map(
                                      (heading, index) => {
                                        if (heading.id === "action") {
                                          return (
                                            <td
                                              key={index}
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              {actions.map((action, i) => (
                                                <button
                                                  onClick={() =>
                                                    action.action(row)
                                                  }
                                                  key={i}
                                                  style={{ marginLeft: "10px" }}
                                                >
                                                  {action.name}
                                                </button>
                                              ))}
                                            </td>
                                          );
                                        } else {
                                          return heading.customCell ? (
                                            heading.customCell(row, index)
                                          ) : (
                                            <td key={index}>
                                              {row[heading.id]}
                                            </td>
                                          );
                                        }
                                      }
                                    )}
                                </tr>
                              );
                            }}
                          </Draggable>
                        );
                      })}

                  {!data ||
                  !data[tabs[useTab]] ||
                  !data[tabs[useTab]].length ? (
                    <tr>
                      <td
                        colSpan={
                          headings[tabs[useTab]]
                            ? headings[tabs[useTab]].length
                            : 5
                        }
                        className="text-center"
                      >
                        Nothing to Show
                      </td>
                    </tr>
                  ) : null}

                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </div>
    </>
  );
};

NewDraggableTabbedTable.propTypes = {
  headings: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  actions: PropTypes.array
};

NewDraggableTabbedTable.defaultProps = {
  headings: {},
  data: {},
  tabs: [],
  actions: []
};

export default withStyles(styles)(NewDraggableTabbedTable);
