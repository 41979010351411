import React from "react";
import BoxOrange from "../../../components/common/BoxOrange";
import BoxBlue from "../../../components/common/BoxBlue";

class Program extends React.PureComponent {
  render() {
    const counts = this.props.counts;
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="info-boxes">
            <div className="orange blue section">
              <BoxOrange
                boxName="Interested Programs"
                boxCount={counts.intPCount}
                boxNew={counts.intPWeekCount}
                title="PROGRAM"
                link="/parents/interested-programs"
              />

              <BoxOrange
                boxName="Open Applications"
                boxCount={counts.openAppCount}
                boxNew={counts.openAppWeekCount}
                link="/parents/applications"
              />

              <BoxOrange
                boxName="Program Updates"
                boxCount={0}
                boxNew={0}
                link="/parents/news-feed"
              />
              <BoxBlue
                boxName="Pending Payments"
                boxCount={counts.pendPaymentsCount}
                boxNew={counts.pendPaymentsWeekCount}
                title="FINANCE"
                link="/parents/tuition-payments"
              />

              <BoxBlue
                boxName="Overdue Payments"
                boxCount={counts.overduePaymentCount}
                boxNew={counts.overduePaymentWeekCount}
                link="/parents/tuition-payments"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Program;
