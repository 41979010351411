import React from "reactn";
import { Modal } from "../../../components/UI";
import { formTypes } from "../../../components/UI/form";
import axios from "axios";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

class PricingPlanModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.newPricingPlan = {
      numDays: 0,
      hoursFrom: -1,
      hoursTo: -1,
      depositAmount: 0,
      depositDuration: 1,
      slots: "Full",
      stripe: {
        amount: 0,
        currency: "cad",
        interval: "week",
        product: null,
        active: true
      }
    };
    if (this.props.plan) {
      this.state = {
        plan: { ...this.props.plan },
        timeRanges: formTypes.SelectOptions.timeRanges
      };
    } else {
      this.state = {
        plan: { ...this.newPricingPlan },
        timeRanges: formTypes.SelectOptions.timeRanges
      };
    }

    this.days = [
      { name: "Please Select", value: "0" },
      { name: "1", value: "1" },
      { name: "2", value: "2" },
      { name: "3", value: "3" },
      { name: "4", value: "4" },
      { name: "5", value: "5" },
      { name: "6", value: "6" },
      { name: "7", value: "7" }
    ];
    const excluded = this.global.myProgramLocationHours.excludedDays.length;
    switch (this.global.myProgramLocationHours.daysOfWeek) {
      case "Monday - Friday":
        this.days = this.days.slice(0, 6 - excluded);
        break;
      case "Monday - Saturday":
        this.days = this.days.slice(0, 7 - excluded);
        break;
      case "Monday - Sunday":
        this.days = this.days.slice(0, 8 - excluded);
        break;
      case "Weekend Only":
        this.days = this.days.slice(0, 3 - excluded);
        break;
      default:
        this.days = this.days.slice(0, 6 - excluded);
    }
    this.addPricingPlan = this.addPricingPlan.bind(this);
    this.getAvailableTimes = this.getAvailableTimes.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeDeposit = this.changeDeposit.bind(this);
    this.handleStripeChange = this.handleStripeChange.bind(this);
  }

  componentDidMount() {
    this.getAvailableTimes();
  }

  addPricingPlan = async e => {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/stripe/plan`;
    try {
      this.setGlobal({ loading: true });
      let vals = this.state.plan;
      vals.stripe.product = this.state.plan.planCategory
        ? this.state.plan.planCategory
        : this.props.planCategory;
      if (this.props.params && this.props.params.id) {
        vals["dsUserId"] = this.props.params.id;
      }
      const res = await axios.post(ep, vals);
      this.setState(this.newPricingPlan);
      this.setGlobal({ loading: false, lastAPICall: res });
      this.props.onClose(res.data.data);
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  };

  changeDeposit(e) {
    if (!e) e = this.state.numDays;
    if (this.state.plan.interval === "month") {
    } else {
      const depAmt =
        Math.ceil(
          (parseFloat(this.state.plan.stripe.amount) /
            parseInt(this.state.plan.numDays) /
            5) *
            e
        ) * 5;
      this.setState({
        plan: {
          ...this.state.plan,
          depositDuration: e,
          depositAmount:
            depAmt > this.state.plan.stripe.amount
              ? this.state.plan.stripe.amount
              : depAmt
        }
      });
    }
  }

  getAvailableTimes() {
    // const start = formTypes.SelectOptions.timeRanges.findIndex(
    //   t =>
    //     t.value ===
    //     parseFloat(this.global.myProgramLocationHours.hoursOfOperationStart)
    // );
    // const end = formTypes.SelectOptions.timeRanges.findIndex(
    //   t =>
    //     t.value ===
    //     parseFloat(this.global.myProgramLocationHours.hoursOfOperationEnd)
    // );
    const remaining = formTypes.SelectOptions.timeRanges; //.slice(start, end + 1);
    this.setState({
      timeRanges: remaining,
      plan: {
        ...this.state.plan,
        hoursFrom: remaining[0].value,
        hoursTo: remaining[0].value
      }
    });
  }

  handleChange(e) {
    if (e.target.name === "numDays" && parseInt(e.target.value)) {
      const newD =
        Math.ceil(
          parseInt(this.state.plan.stripe.amount) / parseInt(e.target.value) / 5
        ) *
        5 *
        this.state.plan.depositDuration;
      this.setState({
        plan: {
          ...this.state.plan,
          [e.target.name]: e.target.value,
          depositAmount:
            newD > this.state.plan.stripe.amount
              ? this.state.plan.stripe.amount
              : newD
        }
      });
    } else {
      this.setState({
        plan: {
          ...this.state.plan,
          [e.target.name]: e.target.value
        }
      });
    }
  }

  handleStripeChange(e) {
    this.setState({
      plan: {
        ...this.state.plan,
        stripe: {
          ...this.state.plan.stripe,
          [e.target.name]: e.target.value
        }
      }
    });
  }

  render() {
    const { open, onClose } = this.props;
    return (
      <div>
        <Modal open={open} heading="New Pricing Plan " onClose={onClose}>
          <form>
            <div className="row">
              <div className="col-5">
                <p>
                  <strong>Days Per Week:</strong>
                </p>
              </div>
              <div className="col-7">
                <formTypes.Select
                  name="numDays"
                  value={this.state.plan.numDays.toString()}
                  onChange={this.handleChange}
                  // options={this.getAvailableTimes()}
                  options={this.days}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <p>
                  <strong>Billing Cycle:</strong>
                </p>
              </div>
              <div className="col-7">
                <formTypes.Select
                  name="interval"
                  value={this.state.plan.stripe.interval}
                  onChange={this.handleStripeChange}
                  // options={this.getAvailableTimes()}
                  options={[
                    { name: "Weekly", value: "week" }
                    // { name: "Monthly", value: "Monthly" }
                  ]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <p>
                  <strong>Period:</strong>
                </p>
              </div>
              <div className="col-7 d-flex">
                <formTypes.Select
                  name="hoursFrom"
                  value={this.state.plan.hoursFrom}
                  onChange={this.handleChange}
                  // options={this.getAvailableTimes()}
                  options={this.state.timeRanges}
                />
                <span className="mx-2" style={{ lineHeight: "40px" }}>
                  to
                </span>
                <formTypes.Select
                  name="hoursTo"
                  value={this.state.plan.hoursTo}
                  onChange={this.handleChange}
                  // options={this.getAvailableTimes()}
                  options={this.state.timeRanges}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <p>
                  <strong>Tuition Rate:</strong>
                </p>
              </div>
              <div className="col-7">
                <input
                  name="rate"
                  value={this.state.plan.stripe.amount}
                  type="number"
                  onChange={e => {
                    const newD =
                      Math.ceil(
                        (parseInt(e.target.value) /
                          parseInt(this.state.plan.numDays) /
                          5) *
                          this.state.plan.depositDuration
                      ) * 5;
                    this.setState({
                      plan: {
                        ...this.state.plan,
                        stripe: {
                          ...this.state.plan.stripe,
                          amount: parseInt(e.target.value) || ""
                        },
                        depositAmount:
                          newD > parseInt(e.target.value)
                            ? parseInt(e.target.value)
                            : newD
                      }
                    });
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <p>
                  <strong>Active:</strong>
                </p>
              </div>
              <div className="col-7 py-2">
                <input
                  type="checkbox"
                  checked={this.state.plan.stripe.active ? "checked" : ""}
                  onChange={e =>
                    this.setState({
                      plan: {
                        ...this.state.plan,
                        stripe: {
                          ...this.state.plan.stripe,
                          active: !this.state.plan.stripe.active
                        }
                      }
                    })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <p>
                  <strong>Slot Category:</strong>
                </p>
              </div>
              <div className="col-7">
                <formTypes.Select
                  name="slots"
                  value={this.state.plan.slots}
                  onChange={this.handleChange}
                  // options={this.getAvailableTimes()}
                  options={[
                    { name: "Full Day", value: "Full" },
                    { name: "Partial Day", value: "Partial" },
                    { name: "First Half (Morning)", value: "First" },
                    { name: "Second Half (Afternoon)", value: "Second" }
                    // { name: "Monthly", value: "Monthly" }
                  ]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <p>
                  <strong>Deposit:</strong>
                </p>
              </div>
              <div className="col-7 d-flex" style={{ alignItems: "center" }}>
                {!this.state.plan.numDays || !this.state.plan.stripe.amount ? (
                  <sub style={{ position: "absolute", left: 0, fontSize: 13 }}>
                    Set your days and rate
                  </sub>
                ) : (
                  <sub style={{ position: "absolute", left: 0, fontSize: 13 }}>
                    {this.state.plan.depositDuration}{" "}
                    {this.state.plan.stripe.interval === "week"
                      ? "days"
                      : "weeks"}{" "}
                    (${this.state.plan.depositAmount})
                  </sub>
                )}
                <Slider
                  min={1}
                  max={1 /*parseInt(this.state.plan.numDays) || 7*/}
                  onChange={this.changeDeposit}
                  disabled={
                    !this.state.plan.numDays || !this.state.plan.stripe.amount
                  }
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <div className="buttons">
                  <button
                    disabled={
                      !this.state.plan.stripe.amount || !this.state.plan.numDays
                    }
                    onClick={this.addPricingPlan}
                    className="nextbtn"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

export default PricingPlanModal;
