import React from "reactn";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { SendOutlined, Close } from "@material-ui/icons";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
class SendMessage extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      message: "",
      emoji: false
    };
    this.emojiref = React.createRef();
    this.sendMessage = this.sendMessage.bind(this);
  }
  async sendMessage(e) {
    e.preventDefault();
    if (this.state.message.trim().length > 0) {
      await this.dispatch.ccSendMessage(this.state.message);
      this.setState({ message: "" });
    }
  }
  componentDidMount() {
    const listener = document.body.addEventListener("click", e => {
      let element = e.target;
      while (element.parentNode) {
        if (element.parentNode.classList)
          if (element.parentNode.classList.contains("emoji-mart")) return;
        element = element.parentNode;
      }
      this.setState({
        emoji: false
      });
    });
  }
  render() {
    return (
      <div
        style={{
          padding: "5px",
          position: "absolute",
          bottom: "10px",
          width: "100%"
        }}
      >
        <form>
          <div style={{ position: "relative" }}>
            <TextField
              id="messagetext"
              placeholder="Type a message..."
              variant="outlined"
              multiline
              rowsMax="4"
              style={{ display: "block" }}
              fullWidth
              disabled={!this.global.ccSelectedContact.conversationId}
              value={this.state.message}
              onChange={e => this.setState({ message: e.target.value })}
              onKeyPress={e => {
                if (e.key === "Enter") this.sendMessage(e);
              }}
              style={{
                border: "1px solid grey",
                borderRadius: "10px",
                marginBottom: "10px"
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <p style={{ marginBottom: 0 }}>
                        <button
                          style={{
                            padding: "12px",
                            backgroundColor: "transparent",
                            border: "none"
                          }}
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              emoji: true
                            });
                          }}
                        >
                          😃
                        </button>
                      </p>
                    </InputAdornment>
                    <InputAdornment position="end">
                      <IconButton
                        disabled={!this.global.ccSelectedContact.conversationId}
                        type="submit"
                        onClick={this.sendMessage}
                      >
                        <SendOutlined />
                      </IconButton>
                    </InputAdornment>
                  </>
                )
              }}
            />
            {this.state.emoji && (
              <Picker
                ref={this.emojiref}
                style={{ position: "absolute", right: 0, top: -370 }}
                onSelect={e => {
                  this.setState({
                    message: this.state.message + e.native,
                    emoji: false
                  });
                  document.getElementById("messagetext").focus();
                }}
                onClick={(e, v) => {}}
              />
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default SendMessage;
