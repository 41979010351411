import React from "reactn";
import { Table } from "../../../components/UI";
import Axios from "axios";
import { format } from "date-fns";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import ChildEnrolled from "../../../components/table/admins/AddEnrolledChild";
class Enrolled extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
    this.headings = [
      { id: "fullName", label: "Student" },
      {
        id: "startDate",
        label: "Start Date",
        infoBalloon: (
          <>
            <p>
              <span className="green-circle mr-1"></span>
              Currently attending
            </p>
            <p>
              <span className="orange-circle mr-1"></span>
              Deposit paid, start date pending
            </p>
          </>
        ),
        customCell: row => (
          <td key={`${row.id}-acc`}>
            {format(new Date(row.startDate), "LLLL dd, yyyy")}
          </td>
        )
      },
      {
        id: "endDate",
        label: "End Date",
        customCell: (r, i) => {
          return (
            <td key={i + `Hallo`}>
              {r.endDate ? format(new Date(r.endDate), "LLLL dd, yyyy") : "N/A"}
            </td>
          );
        }
      },
      {
        id: "deposit",
        label: "Deposit Paid",
        customCell: (r, i) => {
          return <td key={i + `depo`}>{r.depositPaid ? "Yes" : "No"}</td>;
        }
      },
      {
        id: "programPlan",
        label: "Program Plan",
        customCell: this.planCell
      }
    ];

    this.actions = [{ name: "VIEW", action: this.viewEnrollment }];
  }

  viewEnrollment = async row => {
    this.props.history.push(
      `/educators/applications/${row.slug}/${row.submittedDate}`
    );
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/edu/enrollment/${this.props.id}`;
    const results = await Axios.get(ep);

    if (results.data.success) {
      this.setState({
        data: results.data.data
      });
    }
  }
  planCell = (r, i) => {
    if (r.categoryName) {
      return (
        <td key={`${i}-pl`}>
          {r.categoryName}
          <br />
          {r.numDays} days/week
        </td>
      );
    } else return <td key={`${i}-pl`}>Not Applicable</td>;
  };

  render() {
    return (
      <div className="container-fluid enrolled">
        {this.state.enrolledModalOpen && (
          <ChildEnrolled
            open={this.state.enrolledModalOpen}
            onClose={() => this.setState({ enrolledModalOpen: false })}
            id={this.props.id}
            update={element =>
              this.setState({
                data: [...this.state.data, element]
              })
            }
          />
        )}
        <div className="cont">
          <h1 style={{ display: "flex" }}>
            <span style={{ flexGrow: 1 }}>Enrolled </span>
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                this.setState({
                  enrolledModalOpen: true
                })
              }
            >
              Add Enrollment!
            </Button>
          </h1>
        </div>
        {/* <Table.Educators.EnrolledTable
          useEnrolledStudents={this.global.enrolledStudents}
        /> */}
        <div className="cont">
          <Table
            headings={this.headings}
            data={this.state.data}
            actions={this.actions}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Enrolled);
