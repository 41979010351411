import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import "../../assets/css/componentSpecificCss/staffScheduleModal.css";

class EditStaffScheduleModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      staff: this.props.selectedStaff,
      task: this.props.selectedSchedule.task_name,
      taskDescription: this.props.selectedSchedule.task_description,
      isRepeatingWeekly: this.props.selectedSchedule.isRepeatingWeekly,
      startDate: this.props.selectedSchedule.start_date,
      endDate: this.props.selectedSchedule.end_date,
      startTime: this.props.selectedSchedule.start_time,
      endTime: this.props.selectedSchedule.end_time,
      daysOfTheWeek: this.props.selectedSchedule.selected_days_of_week,
      startTimeEdited: false,
      endTimeEdited: false
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async submitSchedule() {
    if (this.state.staff === "" && !this.state.selectedStaff) {
      toast.error("Please select a staff member");
      return;
    }

    if (this.state.task === "") {
      toast.error("Please enter the task");
      return;
    }

    if (this.state.startDate === "" && !this.state.selectedDate) {
      toast.error("Please enter the start date");
      return;
    }

    if (this.state.startTime === "") {
      toast.error("Please enter the start time");
      return;
    }

    if (this.state.endTime === "") {
      toast.error("Please enter the end time");
      return;
    }

    const timeStringStart = this.state.startTime; // The time string in "HH:MM" format
    const timeStringEnd = this.state.endTime;

    const date = new Date(this.state.startDate);
    const dateEnd = new Date(
      this.state.endDate === "" || !this.state.endDate
        ? this.state.startDate
        : this.state.endDate
    );

    const [hours, minutes] = timeStringStart.split(":"); // Split the time string into hours and minutes
    const [hoursEnd, minutesEnd] = timeStringEnd.split(":");

    date.setHours(hours);
    date.setMinutes(minutes);
    dateEnd.setHours(hoursEnd);
    dateEnd.setMinutes(minutesEnd);

    if (date.getTime() >= dateEnd.getTime()) {
      toast.error("Start Time must be before end time!");
      return;
    }

    const ep = `${process.env.REACT_APP_API}/partners/users/schedule/update/${this.props.selectedSchedule.id}`;

    this.setGlobal({ loading: true });

    const results = await axios.post(ep, {
      task_name: this.state.task,
      task_description: this.state.taskDescription,
      isRepeatingWeekly: this.state.isRepeatingWeekly,
      start_date: this.state.startDate ? new Date(this.state.startDate) : null,
      end_date: this.state.endDate ? new Date(this.state.endDate) : null,
      start_time: this.state.startTimeEdited
        ? date
        : new Date(this.state.startTime),
      end_time: this.state.endTimeEdited
        ? dateEnd
        : new Date(this.state.endTime),
      selected_days_of_week: this.state.daysOfTheWeek
    });

    if (results.data.success) {
      toast.success("Schedule successfully updated!");
      this.props.onRefresh();
      this.props.onClose();
      this.setState({
        task: "Schedule",
        staff: "",
        startTime: "",
        endTime: "",
        startDate: "",
        endDate: "",
        openCreateStaffScheduleModal: false
      });
      this.setGlobal({ loading: false });
    } else {
      toast.error("Error while updateing schedule.");
    }
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/roles`;
    const users = await axios.get(ep);
    if (users.data.success) {
      this.setState({
        users: users.data.data.users
      });
    }
  }

  render() {
    const selectedDate = this.props.selectedDate ? this.props.selectedDate : "";
    const parts = selectedDate.split("/");
    const formattedStartDate =
      parts.length === 3
        ? `${parts[2]}-${parts[0].padStart(2, "0")}-${parts[1].padStart(
            2,
            "0"
          )}`
        : "";

    const staffName = this.props.selectedStaff?.username
      ? this.props.selectedStaff.username
      : this.global.dsUser
      ? this.global.dsUser.displayName
      : "";

    const startTime = new Date(this.state.startTime);
    const startHour = startTime.getHours().toString().padStart(2, "0");
    const startMinute = startTime.getMinutes().toString().padStart(2, "0");

    const endTime = new Date(this.state.endTime);
    const endHour = endTime.getHours().toString().padStart(2, "0");
    const endMinute = endTime.getMinutes().toString().padStart(2, "0");

    const formattedStartTime = `${startHour}:${startMinute}`;
    const formattedEndTime = `${endHour}:${endMinute}`;

    console.log(
      "EDIT STATE",
      this.state,
      this.props,
      formattedStartTime,
      formattedEndTime
    );
    return (
      <div style={{ borderRadius: "30px" }}>
        <Modal
          size="lg"
          isOpen={this.props.isModalOpen}
          toggle={() => this.props.closeModal()}
          contentClassName="custom-modal-content"
        >
          <div
            className="modal-content"
            style={{ borderRadius: "30px 30px 0px 0px" }}
          >
            <div className="modal-header">
              <h5 className="modal-title staff-modal-title">
                Edit Staff Schedule
              </h5>
              <button
                className="close"
                style={{ paddingTop: "16px" }}
                onClick={() => this.props.closeModal()}
              >
                <span>&times;</span>
              </button>
            </div>
          </div>
          <ModalBody>
            <form>
              <Row>
                <Col lg={12}>
                  <label htmlFor="staff">Staff</label>
                  <p>{staffName}</p>
                  <div>
                    <label htmlFor="task">Task</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.task}
                      style={{ padding: "5px" }}
                      onChange={e => this.setState({ task: e.target.value })}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>
                      <label htmlFor="start-date">Start Date</label>
                      <input
                        type="date"
                        id="start-date"
                        style={{ padding: "5px", background: "none" }}
                        defaultValue={this.state.startDate.substring(0, 10)}
                        onChange={e =>
                          this.setState({ startDate: e.target.value })
                        }
                      />
                    </div>
                    {this.state.isRepeatingWeekly && (
                      <div style={{ marginLeft: "auto" }}>
                        <label htmlFor="end-date">End Date</label>
                        <input
                          type="date"
                          id="end-date"
                          style={{ padding: "5px", background: "none" }}
                          defaultValue={this.state.endDate.substring(0, 10)}
                          onChange={e =>
                            this.setState({ endDate: e.target.value })
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>
                      <label htmlFor="start-time">Start Time</label>
                      <input
                        type="time"
                        id="start-time"
                        defaultValue={formattedStartTime}
                        onChange={e =>
                          this.setState({
                            startTime: e.target.value,
                            startTimeEdited: true
                          })
                        }
                      />
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                      <label htmlFor="end-time">End Time</label>
                      <input
                        type="time"
                        id="end-time"
                        defaultValue={formattedEndTime}
                        onChange={e =>
                          this.setState({
                            endTime: e.target.value,
                            endTimeEdited: true
                          })
                        }
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
            <div style={{ textAlign: "center" }}>
              <button
                className="staff-schedule-save-btn"
                onClick={() => this.submitSchedule()}
              >
                Save
              </button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withRouter(EditStaffScheduleModal);
