import React from "reactn";
import EarningOverview from "../myEarnings/earnings-overview";
import Leads from "./leadsTution";
import MyProgram from "./my-program";
import axios from "axios";
import TOSForm from "../myApplication/forms/tosForm";
import { format, addDays, getDay } from "date-fns";
class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1)]
    });
    this.state = {
      leads: {
        appNew: 0,
        appTotal: 0,
        infoNew: 0,
        infoTotal: 0,
        tourNew: 0,
        tourTotal: 0
      },
      finance: {
        pending: 0,
        pendingWeek: 0,
        overdue: 0,
        overdueWeek: 0
      },
      enrollments: {
        activeTotal: 0,
        pendingTotal: 0,
        waitlistTotal: 0,
        pastTotal: 0
      },
      programName: "",
      totalPayout: [],
      runningTotal: [],
      gallery: {},
      tosFormShown: true
    };
  }
  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/edu/dashboard`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      if (res.data.success) {
        this.setState({
          ...res.data.data,
          runningTotal: res.data.data.totalPayout.reduce(
            (a, c) =>
              a +
              (c.amount - (c.amount * 0.029 + c.application_fee_amount + 30)),
            0
          ),
          totalPayout: this.groupByWeek(res.data.data.totalPayout.reverse())
        });
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (er) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }
  groupByWeek(transactions) {
    const initalDate = new Date(transactions[0].created * 1000);
    let start = addDays(initalDate, (getDay(initalDate) + 1) * -1);
    const finalArray = [];
    finalArray.push({ amount: 0, date: format(start, "LLL dd") });
    start = addDays(start, 7);
    let amount = 0;
    for (let transaction of transactions) {
      if (transaction.created < start.getTime() / 1000) {
        amount +=
          transaction.amount -
          (transaction.amount * 0.029 +
            transaction.application_fee_amount +
            30);
      } else {
        finalArray.push({ amount, date: format(start, "LLL dd") });
        start = addDays(start, 7);
        amount = 0;
        amount +=
          transaction.amount -
          (transaction.amount * 0.029 +
            transaction.application_fee_amount +
            30);
      }
    }
    finalArray.push({ amount, date: format(start, "LLL dd") });
    return finalArray;
  }
  render() {
    return (
      <div className="container-fluid hero">
        {!this.global.profile.applicationSubmitted &&
        this.global.profile.applicationSubmittedDate &&
        this.state.tosFormShown ? (
          <TOSForm
            reaccept={true}
            onClose={() => this.setState({ tosFormShown: false })}
          />
        ) : null}
        <div className="cont">
          <div className="d-sm-flex align-items-center justify-content-between">
            <h1 className="">Welcome, {this.global.dsUser.displayName}!</h1>
          </div>
          <Leads leads={this.state.leads} finance={this.state.finance} />

          <div className="row">
            <EarningOverview
              earnings={this.state.totalPayout}
              total={this.state.runningTotal / 100}
            />
            <MyProgram
              enrollments={this.state.enrollments}
              name={this.state.programName}
              gallery={this.state.gallery}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
