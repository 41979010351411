import React from "reactn";
import { withStyles, Typography, Chip } from "@material-ui/core";
import EduAvatar from "../../assets/img/educator.png";
import { FavoriteBorder, Favorite } from "@material-ui/icons";
import Cognition from "../../assets/img/ObservationCognition.jpg";
import Communication from "../../assets/img/ObservationCommunication.jpg";
import Emotional from "../../assets/img/ObservationEmotional.jpg";
import Physical from "../../assets/img/ObservationPhysical.jpg";
import Social from "../../assets/img/ObservationSocial.jpg";
import Other from "../../assets/img/ObservationOther.jpg";
import Axios from "axios";
import NoDataAvailable from "../../components/front/partner/NoDataAvailable";
import ConfirmArchiveModal from "../../components/modals/ConfirmArchiveModal";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const styles = theme => ({
  stepper: {
    background: "rgb(255 255 255 / 22%)",
    padding: "0px",
    "& .MuiButton-label": {
      width: "100%",
      display: "inherit",
      alignItems: "inherit",
      justifyContent: "inherit",
      fontFamily: "Open Sans",
      fontSize: "13px"
    }
  },
  dotActive: {
    backgroundColor: "#ff6663"
  },
  paper: {
    textAlign: "center",
    marginTop: "30px"
  },
  container: {
    marginTop: "30px",
    width: "65%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexWrap: "wrap",
    marginBottom: "20px",
    "& .post": {
      width: "60%",
      backgroundColor: "white",
      boxSizing: "border-box",
      borderRadius: "20px",
      margin: "0 auto",
      marginBottom: "20px",
      "& .profileimg": {
        width: "50px",
        height: "50px"
      }
    }
  },
  author: {
    display: "flex",
    alignItems: "center",
    width: "90%",
    margin: "20px auto",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
      borderRadius: "50%",
      // border: "2px solid #c7c7c7",
      alignSelf: "stretch"
    }
  },
  authorname: {
    flex: 1,
    marginLeft: "10px"
  },
  postImage: {
    width: "90%",
    margin: "0 auto 15px auto",
    position: "relative",
    "& img": {
      width: "100%",
      height: "300px",
      objectFit: "cover",
      // borderRadius: "20px",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px"
    }
  },
  likes: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "20px",
    width: "90%",
    margin: "5px auto"
  },
  description: {
    width: "90%",
    margin: "5px auto",
    color: "rgb(45, 49, 167)",
    fontFamily: "Open Sans"
  },
  chip: {
    // margin: "3px"
  },
  [theme.breakpoints.down("md")]: {
    container: {
      width: "50%",
      display: "flex",
      margin: "0 auto",
      flexDirection: "column",
      "& .post": {
        width: "100%"
      }
    }
  },
  [theme.breakpoints.down("xs")]: {
    container: {
      width: "90%",
      display: "flex",
      margin: "0 auto",
      flexDirection: "column",
      "& .post": {
        width: "100%"
      }
    }
  }
});
const imgMapping = {
  Social: Social,
  Physical: Physical,
  "Communication Language & Literacy": Communication,
  Emotional: Emotional,
  Cognition: Cognition
};
function calculateTimeElapsed(date) {
  const timeinSeconds =
    (new Date().getTime() - new Date(date).getTime()) / 1000;
  if (timeinSeconds >= 86400)
    return Math.floor(timeinSeconds / 86400) > 1
      ? `${Math.floor(timeinSeconds / 86400)} days`
      : `${Math.floor(timeinSeconds / 86400)} day`;
  if (timeinSeconds >= 3600)
    return Math.floor(timeinSeconds / 3600) > 1
      ? `${Math.floor(timeinSeconds / 3600)} hours`
      : `${Math.floor(timeinSeconds / 3600)} hour`;
  if (timeinSeconds >= 60)
    return Math.floor(timeinSeconds / 60) > 1
      ? `${Math.floor(timeinSeconds / 60)} mins`
      : `${Math.floor(timeinSeconds / 60)} min`;
  return Math.floor(timeinSeconds) + " seconds";
}
class NewsFeed extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      observations: [],
      toArchive: true,
      showConfirmModal: false
    };
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "News Feed", to: "/newsfeed" }
      ]
    });
  }

  async componentDidMount() {
    this.fetchInitialData();
  }

  async fetchInitialData() {
    const ep = `${process.env.REACT_APP_API}/partner/observations/feed/${this.global.dsUser.id}`;
    const results = await Axios.get(ep);

    if (results.data.success) {
      results.data.data.map(e => (e = e.activeStep = 0));
      this.setState({
        observations: results.data.data
          .filter(f => !f.isArchived)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      });
    }
  }

  like(id) {
    const ep = `${process.env.REACT_APP_API}/partner/observations/like`;
    const Obs = [...this.state.observations];
    const index = Obs.indexOf(Obs.find(e => e.id === id));
    Obs[index].likes = {
      ...Obs[index].likes,
      [this.global.dsUser.id]: Obs[index].likes
        ? !Obs[index].likes[this.global.dsUser.id]
        : true
    };
    this.setState({ observations: Obs });
    Axios.post(ep, { id });
  }

  /**
   * Makes request to backend to archive/unarchive observation.
   * Uses state to determine options (what to delete, archive/unarchive).
   */
  archiveNews = async () => {
    const ep = `${process.env.REACT_APP_API}/partner/observations/delete/${this.state.selectedItem.id}`;
    const results = await axios.post(ep, { toArchive: this.state.toArchive });

    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  render() {
    const { classes } = this.props;

    return (
      // <!-- Begin Page Content -->
      <div className="container-fluid hero">
        {this.state.openConfirmModal && (
          <ConfirmArchiveModal
            onSubmit={() => {
              this.archiveNews();
            }}
            onClose={_ => this.setState({ openConfirmModal: false })}
            toArchive={this.state.toArchive}
            modalBody="Are you sure you would like to delete this Newsfeed? This action cannot be undone."
          />
        )}
        <div className="cont">
          <h1>News Feed</h1>
        </div>
        <div className="cont">
          {this.state.observations.length > 0 ? (
            this.state.observations.map((observation, index) => {
              return (
                <div key={`news-${index}`} className={classes.container}>
                  <div
                    className="post"
                    key={observation.id}
                    style={{
                      boxShadow:
                        "0 4px 15px 0 rgba(0,0,0,.1), 0 25px 45px 0 rgba(0,0,0,.08)"
                    }}
                  >
                    <div className={classes.author}>
                      <LazyLoadImage
                        src={
                          observation.photo
                            ? `${process.env.REACT_APP_DS_FILES_S3}/${observation.photo}`
                            : EduAvatar
                        }
                        alt="profilepic"
                        className="profileimg"
                        style={{ border: "none" }}
                        wrapperClassName="profileimg"
                        effect="opacity"
                      />

                      <Typography
                        variant="subtitle1"
                        style={{
                          marginBottom: 0,
                          fontWeight: "bold",
                          color: "#2d31a7"
                        }}
                        className={classes.authorname}
                      >
                        {this.global.dsUser.displayName} <br />
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{
                          marginBottom: 0,
                          fontFamily: "Open Sans",
                          fontStyle: "italic",
                          color: "grey"
                        }}
                      >
                        {calculateTimeElapsed(observation.createdAt)} ago
                      </Typography>
                      {/* <HighlightOffIcon
                      // onClick={() => {
                      //   setShowConfirmModal(true);
                      //   setMilestoneToEdit(r);
                      //   setToArchive(true);
                      // }}
                      fontSize="large"
                      style={{ marginLeft: "10px", color: "red" }}
                      data-for="archive"
                      data-tip
                    /> */}
                      <i
                        onClick={_ =>
                          this.setState({
                            selectedItem: observation,
                            openConfirmModal: true
                          })
                        }
                        className="far fa-times-circle"
                        style={{
                          marginLeft: "10px",
                          color: "#d81a1a",
                          fontSize: "18px",
                          cursor: "pointer"
                        }}
                      ></i>
                    </div>
                    <div
                      className={classes.postImage}
                      style={{ textAlign: "center" }}
                    >
                      <LazyLoadImage
                        src={
                          this.state.observations[index].gallery.length > 0
                            ? `${process.env.REACT_APP_DS_FILES_S3}/${
                                this.state.observations[index].gallery[
                                  this.state.observations[index].activeStep
                                ]
                              }`
                            : observation.image
                            ? `${process.env.REACT_APP_DS_FILES_S3}/${observation.image}`
                            : imgMapping[observation.domain]
                            ? imgMapping[observation.domain]
                            : Other
                        }
                        alt="postimage"
                        effect="opacity"
                        height="380px"
                      />

                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          width: "100%"
                        }}
                      >
                        <MobileStepper
                          variant="dots"
                          steps={observation.gallery.length}
                          position="static"
                          activeStep={this.state.observations[index].activeStep}
                          className={classes.stepper}
                          classes={{
                            root: classes.stepper,
                            dotActive: classes.dotActive
                          }}
                          nextButton={
                            <Button
                              size="small"
                              onClick={_ => {
                                if (
                                  observation.activeStep ===
                                  observation.gallery.length - 1
                                )
                                  return;

                                let observationsCopy = [
                                  ...this.state.observations
                                ];
                                observationsCopy[index].activeStep =
                                  observationsCopy[index].activeStep + 1;

                                this.setState({
                                  observations: observationsCopy
                                });
                              }}
                              disabled={
                                this.state.observations[index].activeStep ===
                                this.state.observations[index].gallery.length -
                                  1
                              }
                            >
                              Next
                              <KeyboardArrowRight />
                            </Button>
                          }
                          backButton={
                            <Button
                              size="small"
                              onClick={_ => {
                                if (observation.activeStep === 0) return;

                                let observationsCopy = [
                                  ...this.state.observations
                                ];
                                observationsCopy[index].activeStep =
                                  observationsCopy[index].activeStep - 1;

                                this.setState({
                                  observations: observationsCopy
                                });
                              }}
                              disabled={
                                this.state.observations[index].activeStep === 0
                              }
                            >
                              <KeyboardArrowLeft />
                              Back
                            </Button>
                          }
                        />
                      </div>
                      <div
                        style={{ position: "absolute", bottom: 36, left: 6 }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{ flexGrow: 1, marginBottom: 0 }}
                        >
                          <b>
                            {observation.skill && (
                              <Chip
                                variant="default"
                                label={
                                  observation.skill.length > 25
                                    ? observation.skill.slice(0, 25) + "..."
                                    : observation.skill
                                }
                                style={{
                                  backgroundColor: "rgb(218 215 215 / 50%)",
                                  color: "white"
                                }}
                                className={classes.chip}
                              />
                            )}
                          </b>
                        </Typography>
                      </div>
                    </div>

                    <div className={classes.description}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          marginBottom: "10px",
                          borderBottom: "1px solid #c7c7c7",
                          paddingBottom: "5px",
                          color: "#rgb(45, 49, 167)",
                          fontFamily: "Open Sans"
                        }}
                      >
                        <b>
                          {observation.domain
                            ? observation.domain.toLocaleUpperCase()
                            : "OTHER"}
                        </b>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontFamily: "Open Sans",
                          color: "grey",
                          fontSize: "14px"
                        }}
                      >
                        {observation.notes}
                      </Typography>
                    </div>

                    <div className={classes.likes}>
                      <Typography
                        variant="subtitle1"
                        style={{ flexGrow: 1, marginBottom: 0 }}
                      >
                        <b>
                          {observation.kidsNamesTags &&
                            observation.kidsNamesTags.split(",").map(f => {
                              return (
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  label={
                                    f.length > 25 ? f.slice(0, 25) + "..." : f
                                  }
                                  style={{
                                    border: "1px solid lightgrey",
                                    backgroundColor: "white",
                                    color: "grey"
                                  }}
                                  className={classes.chip}
                                />
                              );
                            })}
                        </b>
                      </Typography>
                      {observation.likes ? (
                        observation.likes[this.global.dsUser.id] ? (
                          <Favorite
                            style={{
                              color: "red",
                              cursor: "pointer"
                            }}
                            onClick={() => this.like(observation.id)}
                          />
                        ) : (
                          <FavoriteBorder
                            style={{
                              color: "red",
                              cursor: "pointer"
                            }}
                            onClick={() => this.like(observation.id)}
                          />
                        )
                      ) : (
                        <FavoriteBorder
                          style={{
                            color: "red",
                            cursor: "pointer"
                          }}
                          onClick={() => this.like(observation.id)}
                        />
                      )}
                      <Typography
                        variant="subtitle2"
                        style={{
                          marginLeft: "10px",
                          marginBottom: "0",
                          color: "darkgrey",
                          fontFamily: "Open Sans"
                        }}
                      >
                        {observation.likes
                          ? Object.keys(observation.likes).filter(
                              e => observation.likes[e]
                            ).length
                          : 0}
                      </Typography>
                    </div>

                    {/*<div className={classes.description}>
                  {observation.tags && (
                    <>
                      {observation.tags.split(",").map((e, i) => (
                        <Chip
                          variant="default"
                          label={e}
                          color="primary"
                          key={i}
                          className={classes.chip}
                        />
                      ))}
                    </>
                  )}
                      </div>*/}
                  </div>
                </div>
              );
            })
          ) : (
            <NoDataAvailable message="No News Available" />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(NewsFeed);
