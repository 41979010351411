import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import BaseModal from "./baseModal/BaseModal";
import { TITLE } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class ConfirmGiftAmountModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getBaseModalFields = () => {
    const textField = {
      type: TITLE,
      data: {
        name: "Do you want to confirm this gift card amount? Once the gift card is saved, the amount cannot be modified."
      }
    };

    const fields = [];

    fields.push(textField);

    return fields;
  };

  getBaseModalButtons = () => {
    const cancelButton = {
      name: "Confirm",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      handleClick: () => this.props.onClose()
    };
    return [cancelButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Confirm Gift Card Amount",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: () => this.props.onClose(),
      height: "60vh",
      midSectionHeight: "49vh"
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(ConfirmGiftAmountModal);
