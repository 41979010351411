import React from "reactn";
import { StepIndicator } from "../../../components/UI";
import Setup from "./Setup";
import AdditionalDetails from "./AdditionalDetails";
import Customization from "./Customization";
import ZoomDetails from "./ZoomDetails";
import StepBar from "../../../components/UI/StepBar";
import Validators from "../../../validators";
import PricingPlan from "../../../components/modals/Onlinepricing";
import AddOnlineOneTimePlan from "../../../components/modals/AddOnlineOneTimePlan";
import AddOnlineSemesterPlan from "../../../components/modals/AddOnlineSemesterPlan";
import "../../../assets/css/componentSpecificCss/eventListings.css";
import AddOnlinePricingPlan from "../../../components/modals/AddOnlinePricingPlanModal";
import AddFreeOnline from "../../../components/modals/AddFreeOnline";
import OnlineSingleSessionPlan from "../../../components/modals/AddOnlineSingleSessionPlanModal";

import { canUserCreate } from "../../../utilities/listingCreation";
import { validateTechPage } from "./validationFunctions";
import { takeWhile } from "lodash";
class OnlineVirtualSetup extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      step: 0,
      plan: false,
      canUserMakeNewOnlineVirtual: false,
      makeOnlineVirtualMessage: "",
      openOneTimePlanModal: false,
      openSemesterPlanModal: false,
      openSingleSessionPlanModal: false,
      openFreePlanModal: false
    };
    this.titles = [
      "Program Details",
      "Program Schedule",
      "Tech Link",
      "Checkout"
    ];
    this.validate = [
      () => Validators.Partners.Profile.ValidateVirtualOne(this.global.online), // 0
      () =>
        Validators.Partners.Profile.ValidateVirtualThree(this.global.online), // 1

      () => {
        return validateTechPage(this.global.online);
      }, // 2
      () => Validators.Partners.Profile.ValidateVirtualFour(this.global.online) // 3
    ];
    this.steps = [
      { name: "Program Detail", validateFn: this.validate[0] },
      { name: "Schedule", validateFn: this.validate[1] },
      { name: "Tech Link", validateFn: this.validate[2] },
      { name: "Checkout", validateFn: this.validate[3] }
    ];
  }
  async componentDidMount() {
    const canUserMake = await canUserCreate("online");
    this.setState({
      canUserMakeNewOnlineVirtual: canUserMake.success,
      makeOnlineVirtualMessage: canUserMake.message
    });

    if (this.props.match.params.id) {
      await this.dispatch.getVirtual(this.props.match.params.id);
    }

    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Online Virtual", to: "/online" },
        {
          label: this.global.online.program_name || "Online Virtual Setup",
          to: this.props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
        }
      ]
    });
  }

  /**
   * To be run before saving. If any page is not setup correctly, it will unpublish the page.
   */
  ensureValidPublish = () => {
    // If the page is currently published
    if (this.global.online.published) {
      // Check validity of all pages
      for (let i = 0; i < this.steps.length; i++) {
        if (this.checkValid(i)) {
          // If any page is invalid, set publish to false
          this.setGlobal({
            online: {
              ...this.global.online,
              published: false
            }
          });
        }
      }
    }
  }

  saveProfile = async e => {
    const { step } = this.state;

    this.ensureValidPublish();

    switch (e.target.name) {
      case "next":
        this.setState({ step: step + 1 });
        await this.dispatch.saveVirtual();
        break;
      case "back":
        this.setState({ step: step - 1 });
        await this.dispatch.saveVirtual();
        break;
      case "save":
        await this.dispatch.saveVirtual();
        break;
      default:
        return;
    }
  };

  changePage = async page => {
    this.ensureValidPublish();
    if (!this.checkValid()) {
      // Only allow the user to progress to the earliest invalid page
      for (let i = 0; i < page; i++) {
        if (this.checkValid(i)) {
          return; // Prevent the page from changing
        }
      }
      await this.dispatch.saveVirtual();
      this.setState({ step: page });
    } else if (page < this.state.step) { // Allow the user to backtrack to previous pages
      await this.dispatch.saveVirtual();
      this.setState({ step: page });
    }
  };

  getPage = () => {
    switch (this.state.step) {
      case 0:
        return (
          <Setup
            initialProgramCategory={
              this.props.location.state
                ? this.props.location.state.selectedProgramCategory
                : this.global.program.program_category
            }
            initialApplicationRequired={
              this.props.location.state
                ? this.props.location.state.applicationRequired
                : this.global.program.application_required
            }
            initialAddChild={
              this.props.location.state
                ? this.props.location.state.addChild
                : this.global.program.addChild
            }
            initialTransactionCost={
              this.props.location.state
                ? this.props.location.state.transactionCost
                : this.global.program.transaction_cost
            }
            initialAutoWithdraw={
              this.props.location.state
                ? this.props.location.state.autoWithdraw
                : this.global.program.autoWithdraw
            }
            initialIsRecurring={
              this.props.location.state
                ? this.props.location.state.isRecurring
                : this.global.program.isRecurring
            }
            allowSameEnrollment={
              this.props.location.state
                ? this.props.location.state.allowSameEnrollment
                : this.global.program.allowSameEnrollment
            }
            initialManualInvoices={
              this.props.location.state
                ? this.props.location.state.manualInvoices
                : this.global.program.manual_invoices
            }
          />
        );
      case 1:
        return <AdditionalDetails />;
      case 2:
        return <ZoomDetails />;
      default:
        return <Customization openModal={_ => this.setState({ plan: true })} />;
    }
  };

  checkValid(step = this.state.step) {
    return this.validate[step] && !this.validate[step]();
  }

  getCompletion() {
    let completed = 0;
    for (let v of this.validate) {
      if (v()) {
        completed += 1;
      }
    }
    return (completed / this.validate.length) * 100;
  }

  render() {
    if (
      !this.state.canUserMakeNewOnlineVirtual &&
      !this.props.match.params.id
    ) {
      return (
        <div className="container-fluid">
          {/* Commented out to make space for Top Bar */}
          {/* <div className="cont">
            <h1 className="events-title-header">Online Virtual</h1>
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "50vh",
              alignItems: "center"
            }}
          >
            <p>{this.state.makeOnlineVirtualMessage}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="container-fluid">
        {/* Commented out to make space for Top Bar */}
        {/* <div className="cont">
          <h1 className="events-title-header">Online Virtual</h1>
        </div> */}
        {this.state.plan && (
          <PricingPlan
            onClose={_ => this.setState({ plan: false })}
            selectedPlanType={r => {
              r === "Single Sessions" &&
                this.setState({ openSingleSessionPlanModal: true });
              r === "One-Time" && this.setState({ openOneTimePlanModal: true });
              r === "Semester" &&
                this.setState({ openSemesterPlanModal: true });
              r === "Recurring" &&
                this.setState({ openRecurringPlanModal: true });
              r === "Free" &&
                this.setState({ openFreePlanModal: true });
            }}
          />
        )}

        {this.state.openSingleSessionPlanModal && (
          <OnlineSingleSessionPlan
            onClose={_ => this.setState({ openSingleSessionPlanModal: false })}
          />
        )}

        {this.state.openOneTimePlanModal && (
          <AddOnlineOneTimePlan
            onClose={_ => this.setState({ openOneTimePlanModal: false })}
          />
        )}
        {this.state.openSemesterPlanModal && (
          <AddOnlineSemesterPlan
            onClose={_ => this.setState({ openSemesterPlanModal: false })}
          />
        )}
        {this.state.openRecurringPlanModal && (
          <AddOnlinePricingPlan
            onClose={_ => this.setState({ openRecurringPlanModal: false })}
          />
        )}
        {this.state.openFreePlanModal && (
            <AddFreeOnline
              onClose={_ => this.setState({ openFreePlanModal: false })}
            />
        )}
        <StepBar
          {...{
            steps: this.steps,
            changeFn: this.changePage,
            current: this.state.step
          }}
        />
        <StepIndicator value={Math.round(this.getCompletion())} />
        {this.getPage()}
        <div className="cont mb-5">
          <div
            className="buttons w-100"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "80%"
              }}
            >
              <div
                name="back"
                disabled={this.state.step === 0}
                onClick={e => {
                  e.target.name = "back";
                  if (this.state.step === 0) return;
                  this.saveProfile(e);
                }}
              >
                <i
                  name="back"
                  style={{ paddingRight: "24px" }}
                  className={`fas fa-chevron-left ${
                    this.state.step === 0
                      ? "listing-step-btn-inactive"
                      : "listing-step-btn-active"
                  }`}
                ></i>
              </div>
              <div
                name="next"
                onClick={e => {
                  e.target.name = "next";
                  if (
                    this.state.step === this.titles.length - 1 ||
                    this.checkValid()
                  )
                    return;
                  this.saveProfile(e);
                }}
              >
                <i
                  name="next"
                  style={{ paddingLeft: "24px" }}
                  className={`fas fa-chevron-right ${
                    this.state.step === this.titles.length - 1 ||
                    this.checkValid()
                      ? "listing-step-btn-inactive"
                      : "listing-step-btn-active"
                  }`}
                ></i>
              </div>
            </div>
            {/* <button
              onClick={this.saveProfile}
              disabled={this.state.step === 0}
              name="back"
              className="nextbtn"
            >
              BACK
            </button> */}
            <div
              style={{
                width: "15%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              }}
            >
              {this.state.step === this.titles.length - 1 && (
                <button
                  onClick={_ => {
                    this.setGlobal(
                      {
                        online: {
                          ...this.global.online,
                          published: !this.global.online.published
                        }
                      },
                      _ => this.dispatch.saveVirtual()
                    );
                  }}
                  disabled={this.getCompletion() !== 100}
                  name="next"
                  className={
                    this.getCompletion() !== 100 ? "nextbtnDisabled" : "nextbtn"
                  }
                >
                  {this.global.online.published ? "Unpublish" : "Publish"}
                </button>
              )}

              <button
                onClick={this.saveProfile}
                disabled={this.checkValid()}
                name="save"
                className="savebtn"
                style={{ background: "#b2b8c7", border: "1px solid #b2b8c7" }}
              >
                SAVE
              </button>
            </div>

            {/* <button
              onClick={this.saveProfile}
              disabled={
                this.state.step === this.titles.length - 1 || this.checkValid()
              }
              name="next"
              className="nextbtn"
            >
              NEXT
            </button> */}
          </div>
        </div>
      </div>
    );
  }
}

export default OnlineVirtualSetup;
