import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK, STRING_INPUT } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { format } from "date-fns";

class ViewCouponModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    console.log("current state: ", props);
  }

  /// ================== NEW MODAL ========================== ///
  getBaseModalFields() {
    const fieldCouponCode = {
      type: CONTENT_BLOCK,
      data: {
        name: "Coupon Code",
        content: this.props.couponInfo.coupon_id
      }
    };

    const fieldInternalName = {
      type: CONTENT_BLOCK,
      data: {
        name: "Internal Name",
        content: this.props.couponInfo.coupon_name
      }
    };

    const fieldDiscountType = {
      type: CONTENT_BLOCK,
      data: {
        name: "Discount Type",
        content:
          this.props.couponInfo.type === "Amount"
            ? "Fixed Amount ($)"
            : "Percentage (%)"
      }
    };

    const fieldDiscountRate = {
      type: CONTENT_BLOCK,
      data: {
        name: "Discount Rate",
        content: this.props.couponInfo.amount_off
      }
    };

    let programArray = [
      ...this.props.couponInfo.programs_picked,
      ...this.props.couponInfo.online_picked,
      ...this.props.couponInfo.event_picked
    ];
    let objArray = programArray.map(JSON.parse);
    let programNames = objArray.map(obj => obj.text).join(", ");

    const fieldPrograms = {
      type: CONTENT_BLOCK,
      data: {
        name: "Coupon Applied To:",
        content: programNames.length === 0 ? "All" : programNames
      }
    };

    const fieldRedemptionCount = {
      type: CONTENT_BLOCK,
      data: {
        name: "Redemption Count",
        content: this.props.couponInfo.redemption_count
      }
    };

    const fieldDuration = {
      type: CONTENT_BLOCK,
      data: {
        name: "Duration",
        content:
          this.props.couponInfo.duration.substring(0, 1).toUpperCase() +
          this.props.couponInfo.duration.substring(1)
      }
    };

    const fieldExpiryDate = {
      type: CONTENT_BLOCK,
      data: {
        name: "Expiry Date",
        content: this.props.couponInfo.redeem_by
          ? format(new Date(this.props.couponInfo.redeem_by), "LLLL dd, yyyy")
          : "Does not expiry"
      }
    };

    return [
      fieldCouponCode,
      fieldInternalName,
      fieldDiscountType,
      fieldDiscountRate,
      fieldPrograms,
      fieldRedemptionCount,
      fieldDuration,
      fieldExpiryDate
    ];
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Close",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.props.onClose()
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "View Coupon",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default ViewCouponModal;
