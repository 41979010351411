import React from "reactn";
import Tables from "../../components/table";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import CreatePromotionModal from "../../components/modals/CreatePromotionModal";
import axios from "axios";

class Promotions extends React.PureComponent {
  state = {
    open: false,
    selectedPromo: null,
    data: []
  };
  handleModal = promo => {
    this.setState(state => ({
      open: !state.open,
      selectedPromo: null,
      data: promo
        ? this.state.selectedPromo
          ? this.state.data.map(p =>
              p.id === this.state.selectedPromo.id ? promo : p
            )
          : [promo, ...this.state.data]
        : this.state.data
    }));
  };

  viewPromo = row => {
    this.setState({ selectedPromo: row, open: true });
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/stripe/coupon`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      this.setState({ data: res.data.data.data });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    const { open } = this.state;
    return (
      <div className="admin">
        <div className="container-fluid adminpromotion">
          <CreatePromotionModal
            open={open}
            onClose={this.handleModal}
            data={this.state.selectedPromo}
          />
          <div className="cont d-flex">
            <h1>Promotions</h1>
            <div className="forbtnwrap">
              <div className="forbtnapp">
                <button
                  className="newapplications"
                  onClick={() => this.handleModal(null)}
                >
                  New Promotion
                </button>
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="tablecont">
              <div className="table-responsive">
                <Tables.Admins.PromotionsTable
                  data={this.state.data}
                  viewPromo={this.viewPromo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Promotions;
