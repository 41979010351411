import React, { useReducer, useEffect } from "reactn";
import { withStyles } from "@material-ui/core/styles";

import { Promotions } from "../../../assets";
// import PropTypes from 'prop-types'
import {
  PromotionContext,
  initial_state,
  promotion_detail_reducer,
  setPromotion,
  setOrganization,
  setProgram,
  setOnline,
  setEvent,
  setMembership
} from "./PromotionDetail.context";

import ProgramBookingDetail from "./ProgramBookingDetail";
import OnlineBookingDetail from "./OnlineBookingDetail";
import EventBookingDetail from "./EventBookingDetail";
import MembershipBookingDetail from "./MembershipBookingDetail";
import PromotionDetailWidgetData from "./PromotionDetailWidgetData";
import OnlineDescription from "./OnlineDescription";
import ProgramDescription from "./ProgramDescription";

import { getPromotionBasedOnId } from "./utils";

const styles = {
  plan: {
    transition: "all 300ms",
    boxShadow: "0 2px 5px 0 rgba(0,0,0,.2)",
    "&:hover": {
      transform: "scale(1.02) translateY(-3px)",
      boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
    }
  },
  planSelected: {
    transform: "scale(1.02) translateY(-3px)",
    boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
  }
};

const PromotionDetail = props => {
  const { match } = props;
  const [state, dispatch] = useReducer(promotion_detail_reducer, initial_state);

  const getPromotionBasedOnParameters = async () => {
    const resp = await getPromotionBasedOnId(match.params.id);

    if (resp.success) {
      const { promotion } = resp.data;
      const { promotion_type } = promotion;

      setPromotion(dispatch, promotion);
      setOrganization(dispatch, resp.data.organization);
      if (promotion_type === "program") {
        setProgram(dispatch, resp.data.program);
      } else if (promotion_type === "event") {
        setEvent(dispatch, resp.data.event);
      } else if (promotion_type === "membership") {
        setMembership(dispatch, resp.data.membership);
      } else if (promotion_type === "online") {
        setOnline(dispatch, resp.data.online);
      }
    } else {
      props.history.push("/partners");
    }
  };

  useEffect(() => {
    getPromotionBasedOnParameters();
  }, []);
  const {
    program,
    online,
    event,
    membership,
    promotion,
    selected,
    checkout,
    signIn,
    isOpenSignUpModal,
    dropIns,
    installments
  } = state;

  return (
    <PromotionContext.Provider value={{ state, dispatch }}>
      <div className="containerall">
        <div className="leftcontainerprogram">
          <div className="titledivision">
            <h1 className="maintitleprogram">{promotion.title}</h1>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <h4
                style={{
                  margin: `0px 0px 10px 0px`,
                  color: `#999`,
                  fontFamily: `Montserrat`,
                  fontWeight: `600`,
                  textDecoration: `underline`,
                  cursor: "pointer",
                  fontSize: "1.2rem"
                }}
                onClick={_ =>
                  props.history.push(`/programs/${props.match.params.slug}`)
                }
              >
                {state.organization && state.organization.organization_title}
              </h4>
              <div>
                <button
                  className="partnerbutton"
                  style={{ borderRadius: "10px", marginRight: "10px" }}
                >
                  Gallery
                </button>

                <button
                  className="partnerbutton"
                  style={{ borderRadius: "10px" }}
                >
                  Share
                </button>
              </div>
            </div>
          </div>

          <div className="maincontainerdiv">
            <img className="imageprogram" alt="program" src={Promotions} />

            {promotion.promotion_type === "program" ? (
              <PromotionDetailWidgetData
                program={program}
                promotion={promotion}
              />
            ) : promotion.promotion_type === "event" ? (
              <PromotionDetailWidgetData event={event} promotion={promotion} />
            ) : promotion.promotion_type === "online" ? (
              <PromotionDetailWidgetData
                online={online}
                promotion={promotion}
              />
            ) : (
              <PromotionDetailWidgetData promotion={promotion} />
            )}
          </div>

          <div className="box-size">
            <h3 className="sub-title">Promotion Description</h3>

            <p
              style={{
                fontWeight: "400",
                fontFamily: `"Open sans", sans-serif`,
                whiteSpace: "pre-line"
              }}
            >
              {promotion.description}
            </p>
          </div>
          {promotion.promotion_type === "program" && (
            <ProgramDescription program={program} />
          )}
          {promotion.promotion_type === "online" && (
            <OnlineDescription program={online} />
          )}
        </div>

        <div className="rightcontainerprogram">
          {promotion.promotion_type === "program" && (
            <ProgramBookingDetail classes={props.classes} />
          )}
          {promotion.promotion_type === "event" && (
            <EventBookingDetail classes={props.classes} />
          )}
          {promotion.promotion_type === "membership" && (
            <>
              <MembershipBookingDetail classes={props.classes} />
            </>
          )}
          {promotion.promotion_type === "online" && (
            <OnlineBookingDetail classes={props.classes} />
          )}
        </div>
      </div>
    </PromotionContext.Provider>
  );
};

PromotionDetail.propTypes = {};

export default withStyles(styles)(PromotionDetail);
