import React from "reactn";
import { MiniPlus, AddNew, CalIcon, TimeIcon } from "../../assets";
import PricingPlanCategoryModal from "./pricingPlan/PricingPlanCategoryModal";
import PricingPlanDeleteModal from "./pricingPlan/PricingPlanDeleteModal";
import axios from "axios";
import { numToHours } from "../../lib/util";
import PricingPlanModal from "./pricingPlan/PricingPlanModal";
import { IconButton } from "@material-ui/core";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import Dropdown from "rc-dropdown";
import Axios from "axios";

class PricingPlans extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      newCategoryModalShown: false,
      newPlanModalShown: false,
      categories: [],
      category: null,
      selectedPlan: null,
      deleteModalShown: false,
      modalData: null,
      locked: false
    };
    this.gradients = [
      "gra-blue-purple",
      "gra-yellow-pink",
      "gra-purple-blue",
      "gra-teal-green",
      "gra-yellow-orange"
    ];
    this.newCategoryAdded = this.newCategoryAdded.bind(this);
    this.newPlanAdded = this.newPlanAdded.bind(this);
    this.addNewPlan = this.addNewPlan.bind(this);
    this.menu = this.menu.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.d = new Date();
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "Pricing Plans"]
    });
  }

  confirmDelete = async remove => {
    if (remove) {
      const ep = `${process.env.REACT_APP_API}/stripe/plan`;
      this.setGlobal({ loading: true });
      const res = await axios.delete(`${ep}/${this.state.selectedPlan.id}`);
      this.setState({ categories: res.data.data });
      this.setGlobal({ loading: false, lastAPICall: res });
    }
    this.setState({ deleteModalShown: false });
  };

  async componentDidMount() {
    const params = this.props.match.params;
    if (!params.id) {
      try {
        const ep = `${process.env.REACT_APP_API}/stripe/product`;
        this.setGlobal({ loading: true });
        const res = await axios.get(ep);

        if (res.data.success) {
          this.setState({
            ...this.state,
            locked: res.data.data.locked,
            categories: res.data.data.data.reverse()
          });
        } else {
          this.setState({ categories: null });
        }
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (ex) {
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    } else {
      try {
        const ep = `${process.env.REACT_APP_API}/stripe/product/${params.id}`;
        this.setGlobal({ loading: true });
        const res = await axios.get(ep);

        this.setGlobal({ myProgramLocationHours: res.data.data.plh });
        this.setState({
          ...this.state,
          locked: res.data.data.products.locked,
          categories: res.data.data.products.data
        });
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (er) {
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }
  }
  lockPlans = async _ => {
    const educatorId = this.props.match.params.id;
    const ep = `${process.env.REACT_APP_API}/stripe/lock`;
    const res = await axios.post(ep, {
      educatorId
    });
    if (res.data.success) {
      this.setState({
        locked: !this.state.locked
      });
    }
  };

  newCategoryAdded = c => {
    if (c) {
      if (!c.plans) c["plans"] = [];
      if (this.state.modalData !== null) {
        this.setState({
          newCategoryModalShown: false,
          categories: [
            ...this.state.categories.map(v => (v.id !== c.id ? v : c))
          ],
          modalData: null
        });
      } else {
        this.setState({
          newCategoryModalShown: false,
          categories: [...this.state.categories, c]
        });
      }
    } else {
      this.setState({
        newCategoryModalShown: false
      });
    }
  };

  newPlanAdded = c => {
    if (c && c.dsPlan) {
      let categories = this.state.categories;
      for (let cat of categories) {
        if (cat.productId === c.dsPlan.product) {
          const exists = cat.plans.findIndex(cp => cp.id === c.id);
          if (exists > -1) {
            cat.plans[exists] = c.dsPlan;
          } else {
            cat.plans.push(c.dsPlan);
          }
          break;
        }
      }
      this.setState({
        newPlanModalShown: false,
        categories: categories
      });
    } else {
      this.setState({
        newPlanModalShown: false
      });
    }
  };

  addNewPlan = c => {
    this.setState({
      newPlanModalShown: true,
      category: c.productId
    });
  };

  onSelect = async e => {
    if (e.key === "inactive") {
      try {
        const ep = `${process.env.REACT_APP_API}/stripe/plan`;
        this.setGlobal({ loading: true });
        const res = await axios.patch(`${ep}`, {
          plan: this.state.selectedPlan,
          stripe: {
            active: !this.state.selectedPlan.active,
            id: this.state.selectedPlan.planId
          }
        });
        const categories = this.state.categories.map(c => {
          if (c.id === this.state.modalData.id) {
            return {
              ...c,
              plans: c.plans.map(p => {
                return p.id === this.state.selectedPlan.id
                  ? {
                      ...p,
                      active: !this.state.selectedPlan.active
                    }
                  : p;
              })
            };
          }
          return c;
        });
        this.setState({ categories: categories });
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (ex) {
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    } else {
      try {
        if (!parseInt(this.state.selectedPlan.enrollCount)) {
          const ep = `${process.env.REACT_APP_API}/stripe/plan`;
          this.setGlobal({ loading: true });
          const res = await axios.delete(ep, { data: this.state.selectedPlan });
          const categories = this.state.categories.map(c => {
            if (c.id === this.state.modalData.id) {
              return {
                ...c,
                plans: c.plans.filter(p => p.id !== this.state.selectedPlan.id)
              };
            }
            return c;
          });
          this.setState({ categories: categories });
          this.setGlobal({ loading: false, lastAPICall: res });
        } else {
          this.setState({ deleteModalShown: true });
        }
      } catch (ex) {
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }
  };

  menu = e => {
    return (
      <Menu onClick={this.onSelect}>
        <MenuItem key="inactive">
          {this.state.selectedPlan.active ? "Make Inactive" : "Make Active"}
        </MenuItem>
        <MenuItem key="delete">Delete</MenuItem>
      </Menu>
    );
  };

  catmenu = e => {
    return (
      <Menu onClick={this.onCatSelect}>
        <MenuItem key="edit">Edit</MenuItem>
        <MenuItem key="delete">Delete</MenuItem>
      </Menu>
    );
  };

  onCatSelect = async e => {
    if (e.key === "edit") {
      this.setState({ newCategoryModalShown: true });
    } else if (e.key === "delete") {
      try {
        const ep = `${process.env.REACT_APP_API}/stripe/product`;
        this.setGlobal({ loading: true });
        const res = await axios.delete(ep, { data: this.state.modalData });
        if (res.data.success) {
          this.setState({
            categories: this.state.categories.filter(
              c => c.id !== this.state.modalData.id
            )
          });
        }
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (ex) {
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }
  };
  switchOrder(cat1, cat2) {
    const int = cat1.orderBy;
    cat1.orderBy = cat2.orderBy;
    cat2.orderBy = int;
    const ep = `${process.env.REACT_APP_API}/stripe/catswitch`;

    Axios.post(ep, { current: cat1, other: cat2 });
    this.setState({ categories: [...this.state.categories] });
  }

  render() {
    return (
      <>
        <div className="admin">
          <PricingPlanCategoryModal
            open={this.state.newCategoryModalShown}
            data={this.state.modalData}
            onClose={this.newCategoryAdded}
            params={this.props.match.params}
          />
          <PricingPlanDeleteModal
            open={this.state.deleteModalShown}
            // data={this.state.modalData}
            onClose={this.confirmDelete}
          />
          {this.state.newPlanModalShown ? (
            <PricingPlanModal
              open={this.state.newPlanModalShown}
              planCategory={this.state.category}
              onClose={this.newPlanAdded}
              // plan={this.state.selectedPlan}
              params={this.props.match.params}
            />
          ) : null}
          <div className="container-fluid pricingplan">
            <div className="row cont">
              <div className="col-md-6">
                <h1>Pricing Plans</h1>
              </div>
              <div className="col-md-6">
                <div className="forbtnwrap justify-end">
                  {this.global.dsUser.accountType === "Admin" && (
                    <div className="forbtnapp">
                      <button
                        onClick={() => this.lockPlans()}
                        className="newapplications"
                      >
                        {this.state.locked
                          ? `Unlock pricing plans !`
                          : `Lock pricing plans !`}
                      </button>
                    </div>
                  )}
                  <div className="forbtnapp">
                    <button
                      onClick={() => {
                        this.setState({
                          newCategoryModalShown: true,
                          modalData: null
                        });
                      }}
                      disabled={
                        this.state.locked &&
                        this.global.dsUser.accountType !== "Admin"
                      }
                      className="newapplications"
                    >
                      <img src={MiniPlus} alt="" />
                      Add Pricing Plan Category
                    </button>
                  </div>
                </div>
              </div>
              {this.state.locked &&
                this.global.dsUser.accountType !== "Admin" && (
                  <h4 style={{ textAlign: "Center", width: "100%" }}>
                    Your pricing plans are locked. Please message us if you want
                    to make any changes to your plans.
                  </h4>
                )}
            </div>
            {this.state.categories !== null && !this.state.categories.length ? (
              <div className="row cont my-5">
                <div className="col-12 text-center">
                  <h4>
                    Create a new Pricing Plan Category in the top right hand
                    corner to get started!
                  </h4>
                </div>
              </div>
            ) : null}
            {this.state.categories !== null && this.state.categories.length ? (
              <div className="cont">
                <div className="boxes">
                  {this.state.categories
                    .filter(c => c.name !== "Service Fee")
                    .sort((prev, next) =>
                      parseInt(prev.orderBy) < parseInt(next.orderBy) ? -1 : 1
                    )
                    .map((c, i, arr) => {
                      return (
                        <div
                          className="box"
                          style={{ maxWidth: "100%" }}
                          key={i}
                        >
                          <h2 className={this.gradients[i % 5]}>
                            {c.name}
                            <div
                              style={{
                                position: "absolute",
                                right: "50px",
                                top: "3px"
                              }}
                            >
                              <IconButton
                                disabled={i === 0}
                                onClick={_ =>
                                  this.switchOrder(arr[i - 1], arr[i])
                                }
                              >
                                <ArrowUpward />
                              </IconButton>
                              <IconButton
                                disabled={arr.length - 1 === i}
                                onClick={_ =>
                                  this.switchOrder(arr[i], arr[i + 1])
                                }
                              >
                                <ArrowDownward />
                              </IconButton>
                            </div>
                            <Dropdown
                              trigger={["click"]}
                              overlay={this.catmenu}
                              animation="slide-up"
                            >
                              <button
                                disabled={
                                  this.state.locked &&
                                  this.global.dsUser.accountType !== "Admin"
                                }
                                style={{
                                  borderRadius: "50%",
                                  width: 35,
                                  height: 35,
                                  outline: "none",
                                  border: 0,
                                  background: "rgba(127, 127, 127, 0.3)",
                                  fontSize: "14px",
                                  position: "absolute",
                                  right: 15,
                                  top: 10,
                                  color: "#333"
                                }}
                                onClick={() =>
                                  this.setState({
                                    modalData: { name: c.name, id: c.id }
                                  })
                                }
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </button>
                            </Dropdown>
                          </h2>
                          <div
                            id={`${c.id}`}
                            className="head"
                            style={{ paddingTop: 0 }}
                          >
                            {/* PLANS */}
                            {c.plans
                              .sort((prev, next) =>
                                prev.dataOrder < next.dataOrder ? -1 : 1
                              )
                              .map((plan, p) => (
                                <div
                                  className="box innerbox"
                                  key={p}
                                  id={plan.id}
                                  draggable="true"
                                  onDragStart={e => {
                                    const crt = e.target.cloneNode(true);
                                    e.target.style.opacity = 0;
                                    crt.style.width = "200px";
                                    crt.style.margin = "0";
                                    crt.style.border = "1px solid grey";
                                    crt.style.position = "absolute";
                                    crt.style.top = "-1000px";
                                    crt.style.zIndex = 200;
                                    crt.getElementsByTagName(
                                      "button"
                                    )[0].style.opacity = 0;
                                    document
                                      .getElementById(c.id)
                                      .appendChild(crt);
                                    e.dataTransfer.setDragImage(crt, 0, 0);
                                    e.dataTransfer.setData(
                                      "text/plain",
                                      JSON.stringify(plan)
                                    );
                                  }}
                                  style={{
                                    position: "relative",
                                    background: !plan.active ? "#cecece" : ""
                                  }}
                                  onDragOver={e => {
                                    e.preventDefault();
                                  }}
                                  onDragEnd={e => {
                                    e.target.style.opacity = 1;
                                  }}
                                  onDrop={e => {
                                    e.preventDefault();
                                    const plan = c.plans.find(
                                      p =>
                                        p.id ===
                                        JSON.parse(
                                          e.dataTransfer.getData("text/plain")
                                        ).id
                                    );
                                    /*document.getElementById(
                                      plan.id
                                    ).style.opacity = 1;*/
                                    const other = c.plans.find(
                                      p => p.id === parseInt(e.currentTarget.id)
                                    );
                                    const int = plan.dataOrder;
                                    plan.dataOrder = other.dataOrder;
                                    other.dataOrder = int;
                                    const ep = `${process.env.REACT_APP_API}/stripe/switch`;
                                    if (c.productId === plan.product) {
                                      Axios.post(ep, {
                                        current: plan,
                                        other
                                      });
                                      const categoriest = [
                                        ...this.state.categories
                                      ];

                                      const plans = JSON.parse(
                                        JSON.stringify(c)
                                      );
                                      const index = categoriest.indexOf(
                                        categoriest.find(
                                          categ => categ.id === plans.id
                                        )
                                      );
                                      categoriest[index] = plans;
                                      this.setState({
                                        categories: [...categoriest]
                                      });
                                      //categoriest[index].plans[categoriest[index].plans.indexOf(categoriest[index].plans.find(pl => pl.id === ))]
                                    }
                                  }}
                                  // onClick={() => {
                                  //   this.setState({
                                  //     newPlanModalShown: true,
                                  //     selectedPlan: plan
                                  //   });
                                  // }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "15px",
                                      top: "15px"
                                    }}
                                  >
                                    <Dropdown
                                      trigger={["click"]}
                                      overlay={this.menu}
                                      animation="slide-up"
                                    >
                                      <button
                                        disabled={
                                          this.state.locked &&
                                          this.global.dsUser.accountType !==
                                            "Admin"
                                        }
                                        style={{
                                          borderRadius: "50%",
                                          width: 35,
                                          height: 35,
                                          outline: "none",
                                          border: 0,
                                          background: "#f4f6fd",
                                          fontSize: "14px"
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            selectedPlan: plan,
                                            modalData: c
                                          })
                                        }
                                      >
                                        <i className="fas fa-ellipsis-v"></i>
                                      </button>
                                    </Dropdown>
                                  </div>
                                  <div className="head">
                                    <p>
                                      <img
                                        src={CalIcon}
                                        alt=""
                                        className="mr-2"
                                      />
                                      {plan.numDays} days / week
                                    </p>
                                    <div className="info">
                                      <p className="my-2">
                                        <img
                                          src={TimeIcon}
                                          alt=""
                                          className="mr-2"
                                        />
                                        {numToHours(plan.hoursFrom)} -{" "}
                                        {numToHours(plan.hoursTo)}
                                      </p>
                                      <p className="my-2">
                                        Slots : {plan.slots}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="downcols">
                                    <div className="row">
                                      <div className="col-6 paddingdiv">
                                        <p>
                                          <strong>Tuition</strong>
                                          <br />
                                          <span
                                            style={{
                                              textTransform: "lowercase"
                                            }}
                                          >{`$${plan.amount}  / ${plan.interval}`}</span>
                                        </p>
                                      </div>
                                      <div className="col-6 borderleft paddingdiv">
                                        <p>
                                          <strong>Enrollments</strong>
                                          <br />
                                          {parseInt(plan.enrollCount) || 0}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            <button
                              onClick={() => this.addNewPlan(c)}
                              className="addnew"
                              style={{ height: 217 }}
                              disabled={
                                this.state.locked &&
                                this.global.dsUser.accountType !== "Admin"
                              }
                            >
                              <img
                                src={AddNew}
                                alt=""
                                style={{ position: "relative", marginTop: -19 }}
                              />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : null}
            {this.state.categories === null ? (
              <div className="row cont my-5">
                <div className="col-12 text-center">
                  <h4>
                    Couldn't load Pricing Plans, have you connected your{" "}
                    <Link to="/educators/connected">Payment Settings</Link>?
                  </h4>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default PricingPlans;
