import React from "reactn";
import { Link } from "react-router-dom";
import { DdIcon } from "../../assets";
import { ArrowRight, ArrowLeft } from "@material-ui/icons";
import { format, addDays } from "date-fns";
import { Modal } from "../UI";
import Axios from "axios";
import { Icon, IconButton } from "@material-ui/core";
class RecentDeposits extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalData: null,
      page: 0
    };
  }
  render() {
    return (
      <div className="col-xl-6 col-xl-6-new">
        <div
          className="tablebox"
          style={{
            justifyContent: "flex-start",
            height: "400px",
            margin: 0
          }}
        >
          <div className="tableheader">
            <h2 style={{ flexGrow: 1 }}>
              Upcoming Payouts <img src={DdIcon} alt="" />
            </h2>
            {this.props.earnings && (
              <>
                <IconButton
                  disabled={this.state.page === 0}
                  onClick={e =>
                    this.setState({
                      page: this.state.page - 1
                    })
                  }
                >
                  <ArrowLeft />
                </IconButton>
                <IconButton
                  disabled={
                    this.props.earnings
                      .filter(e => e.instant_available[0].amount > 0)
                      .slice(6 * this.state.page, 6 * (this.state.page + 1))
                      .length < 6
                  }
                  onClick={e =>
                    this.setState({
                      page: this.state.page + 1
                    })
                  }
                >
                  <ArrowRight />
                </IconButton>
              </>
            )}
          </div>
          <div className="tablebody" style={{ padding: "5px 18px" }}>
            <div className="table-responsive">
              <table className="w-100">
                <thead>
                  <tr
                    style={{ color: "#333", borderBottom: "1px solid #ececec" }}
                  >
                    <th className="p-2">Educator</th>
                    <th className="p-2">Amount</th>
                    <th className="p-2">
                      <span style={{ marginLeft: "20px" }}>Status</span>
                    </th>
                    <th className="p-2">
                      <span>Arrival On</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.earnings.length ? null : (
                    <tr>
                      <td colSpan="5" className="p-5 text-center">
                        Nothing to show...{" "}
                        <span style={{ fontStyle: "italic" }}>Yet!</span>
                      </td>
                    </tr>
                  )}
                  {this.props.earnings
                    .filter(e => e.instant_available[0].amount > 0)
                    .slice(6 * this.state.page, 6 * (this.state.page + 1))
                    .map((e, i) => (
                      <tr key={i}>
                        <td className="p-2">{e.educator}</td>
                        <td className="p-2">
                          $
                          {e.instant_available[0].amount
                            ? parseFloat(
                                e.instant_available[0].amount / 100
                              ).toFixed(2)
                            : "0.00"}
                        </td>
                        <td className="p-2">
                          <span
                            style={{
                              backgroundColor: "#ff3b8e",
                              borderRadius: "13px",
                              padding: "3px 10px 3px 10px",

                              color: "#FFFFFF",
                              display: "inline-block",
                              textAlign: "center"
                            }}
                          >
                            Upcoming
                          </span>
                        </td>
                        <td className="p-2">
                          <span>
                            {e.account.settings.payouts.schedule.interval ===
                            "manual"
                              ? "Paused"
                              : format(
                                  addDays(
                                    new Date(),
                                    (8 - new Date().getDay()) % 7
                                  ),
                                  "LL/dd/yy"
                                )}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecentDeposits;
