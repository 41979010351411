import React, { useRef, useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner, MiniPlus } from "../../assets";

const TableData = [
  { name: "Assignment #1: XYZ", date: "09/23/20", upload: "Y", action: "VIEW" },
  { name: "Assignment #2: ABC", date: "12/23/20", upload: "N", action: "VIEW" },
  { name: "Assignment #3: HIG", date: "09/23/20", upload: "Y", action: "VIEW" },
  { name: "Assignment #4: XYZ", date: "10/23/20", upload: "N", action: "VIEW" },
  { name: "Assignment #5: YYZ", date: "09/23/20", upload: "Y", action: "VIEW" },
  { name: "Assignment #6: MAA", date: "23/23/20", upload: "N", action: "VIEW" }
];

const RoomsModal = ({ onClose, history }) => {
  return (
    <div className="request-modal-cont">
      <div className="request-modal lesspadding">
        <div style={{ display: "block" }}>
          <img
            src={ModalBanner}
            alt="Modal Banner"
            style={{
              height: "40px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "48px",
              marginBottom: ".5rem"
            }}
          >
            <h3>Rooms</h3>
            <IconButton
              style={{
                borderRadius: "50%",
                color: "#2880FF",
                marginBottom: "1rem"
              }}
              onClick={_ => onClose()}
            >
              <Close />
            </IconButton>
          </div>
          <div
            className="forbtnwrap justify-end"
            style={{ marginBottom: "15px" }}
          >
            <div className="forbtnapp enrollment-btn">
              <button
                className="newapplications"
                onClick={_ => history.push("/partners/rooms")}
              >
                <img src={MiniPlus} alt="" style={{ alignSelf: "center" }} />
                Edit
              </button>
            </div>
          </div>

          <div>
            <table className="table">
              <thead
                style={{
                  color: "#A9A9A9",
                  fontSize: "11.2px",
                  borderBottom: "1px solid gainsboro"
                }}
              >
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Upload</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {TableData &&
                  TableData.map(data => {
                    return (
                      <tr
                        style={{
                          fontSize: "12px",
                          color: "#959595",
                          borderBottom: "2px solid gainsboro"
                        }}
                      >
                        <td>{data.name}</td>
                        <td>{data.date}</td>
                        <td>{data.upload}</td>
                        <td style={{ cursor: "pointer", color: "blue" }}>
                          {data.action}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomsModal;
