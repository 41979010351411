import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  DROPDOWN,
  DATE_PICKER,
  SELECT_MULTIPLE_DROPDOWN,
  TEXT_AREA,
  BUTTON,
  CONTENT_BLOCK
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class RetryPaymentModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: null,
      reason: "",
      memo: "",
      amount: 0,
      options: "Refund",
      stripeTransactionId: "",
      showARN: false,
      ARNData: []
    };
  }

  async submit() {
    console.log(
      "LOOK HERE",
      this.props,
      this.getStripeTransactionId(this.props.selectedItem)
    );
    const ep = `${process.env.REACT_APP_API}/partners/stripe/retry-payment/${this.props.selectedItem.invoice}`;
    const res = await axios.get(ep);

    if (res.data.success) {
      toast.success("Retried payment!");
      this.props.onClose();
      this.props.refresh();
    }

    // if (this.state.options === "Refund") {
    //   const ep2 = `${process.env.REACT_APP_API}/partners/stripe/get-charge/${this.props.selectedItem.invoice}`;
    //   const charge = await axios.get(ep2);
    //   console.log("the charge is", charge);

    //   console.log("selectedItem", this.props.selectedItem);
    //   const ep = `${process.env.REACT_APP_API}/partners/stripe/refund`;
    //   const res = await axios.post(ep, {
    //     charge:
    //       this.props.usage === "recurring_payments"
    //         ? this.props.selectedItem.justDeposit
    //           ? this.props.selectedItem.depositId
    //           : this.props.selectedItem.charge?.id || charge.data.data.charge
    //         : // : this.props.selectedItem.stripe_transaction_id.includes("pi_")
    //         this.state.stripeTransactionId.includes("pi_")
    //         ? null
    //         : this.state.stripeTransactionId.includes("ch_")
    //         ? this.state.stripeTransactionId
    //         : this.props.selectedItem.id,
    //     amount: this.state.amount,
    //     reason: this.state.reason,
    //     // payment_intent: this.props.selectedItem.stripe_transaction_id.includes(
    //     //   "pi_"
    //     // )
    //     payment_intent: this.state.stripeTransactionId?.includes("pi_")
    //       ? // ? this.props.selectedItem.stripe_transaction_id
    //         this.state.stripeTransactionId
    //       : null,
    //     orderId: this.props.selectedItem.id
    //   });
    //   console.log("res", res);
    //   if (res.data.success) {
    //     toast.success("Refund Successful!");
    //     this.props.onClose();
    //     this.props.refresh();
    //   }
    // }
    // if (this.state.options === "Credit") {
    //   const ep = `${process.env.REACT_APP_API}/partners/stripe/refund/credit`;
    //   const res = await axios.post(ep, {
    //     invoice: this.props.selectedItem.id,
    //     amount: this.state.amount,
    //     memo: this.state.memo,
    //     reason: this.state.reason
    //   });

    //   console.log("res", res);
    //   if (res.data.success) {
    //     toast.success("Credit Issue Successful!");
    //     this.props.onClose();
    //   }
    // }
  }

  async retryPayment() {}

  async componentDidMount() {
    this.setState({
      current:
        this.props.usage === "recurring_payments"
          ? this.props.selectedItem.amount_paid || this.props.selectedItem.total
          : this.props.selectedItem.total,
      amount:
        this.props.usage === "recurring_payments"
          ? this.props.selectedItem.amount_paid || this.props.selectedItem.total
          : this.props.selectedItem.total
    });
    this.setState({
      stripeTransactionId: this.getStripeTransactionId(this.props.selectedItem)
    });

    const ep = `${process.env.REACT_APP_API}/partners/stripe/refund/history/${this.props.selectedItem.stripe_transaction_id}`;
    const refundHistory = await axios.get(ep);

    console.log("refund history", refundHistory);

    console.log("selectedItem", this.props.selectedItem);
  }

  getStripeTransactionId(selectedItem) {
    console.log("thei selected item is", selectedItem);
    let stripeTransactionId;
    // if (selectedItem?.id) {
    //   stripeTransactionId = selectedItem.id;
    // }
    if (selectedItem?.stripe_transaction_id) {
      stripeTransactionId = selectedItem.stripe_transaction_id;
    }
    if (selectedItem?.payment_intent) {
      stripeTransactionId = selectedItem.payment_intent;
    }
    if (selectedItem?.stripe?.id) {
      stripeTransactionId = selectedItem.stripe.id;
    }
    // if (selectedItem?.stripe?.id) {
    //   stripeTransactionId = selectedItem.stripe.id;
    // }
    console.log("the stripe transaction id is", stripeTransactionId);
    return stripeTransactionId;
  }

  refundTypeOptions = () => {
    const result = [];
    result.push({ value: "Refund", text: "Refund to their Bank" });
    /* Credit Customer's Balance option commented out as requested by Moh. -Josh
    if (!this.state.stripeTransactionId?.includes("pi_")) {
      result.push({ value: "Credit", text: "Credit Customer's Balance" });
    }
    */
    return result;
  };

  reasonOptions = () => {
    if (this.state.options === "Refund") {
      return [
        { value: "duplicate", text: "Duplicate Charge" },
        { value: "fraudulent", text: "Fradulent Charge" },
        { value: "requested_by_customer", text: "Requested By Customer" }
      ];
    } else {
      return [
        { value: "duplicate", text: "Duplicate Charge" },
        { value: "fraudulent", text: "Fradulent Charge" },
        { value: "product_unsatisfactory", text: "Product Unsatisfactory" },
        { value: "order_change", text: "Order Change" }
      ];
    }
  };

  handleFetchARN = async () => {
    this.setState({
      showARN: true
    });
    const ep = `${process.env.REACT_APP_API}/partners/stripe/refund/history/${this.props.selectedItem.stripe_transaction_id}`;
    const response = await axios.get(ep);
    console.log("response", response);
    if (response?.data?.success) {
      const data = response.data.data.filter(e => e.valid);
      console.log("data", data);

      if (data.length > 0) {
        this.setState({
          ARNData: response.data.data.map(e => ({
            text: e.ARN,
            amount: e.amount
          }))
        });
      } else {
        this.setState({
          ARNData: [
            {
              text: "No ARN found. Either this refund does not have one or it is still being generated (may take up to 3 days)",
              amount: ""
            }
          ]
        });
      }
    } else {
      this.setState({
        ARNData: [
          {
            text: "No ARN found. Either this refund does not have one or it is still being generated (may take up to 3 days)",
            amount: ""
          }
        ]
      });
    }
  };

  getBaseModalFields() {
    let fields = [];

    const confirmationMessage = {
      type: CONTENT_BLOCK,
      data: {
        name: "Are you sure you would like to attempt to retry the payment?"
      }
    };

    const lastRetryPaymentDate = {
      type: CONTENT_BLOCK,
      data: {
        name: "You may only retry the payment once per hour."
      }
    };
    fields.push(confirmationMessage);

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Confirm",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.submit()
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Retry Payment",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(RetryPaymentModal);
