import React from "reactn";
import { StepIndicator } from "../../../components/UI";
import EmergencyContactsPage from "./emergencyContacts";
import ProfileChildrenPage from "./profileChildren";
import ParentGuardiansPage from "./parentGuardians";
import PhysicianContactsPage from "./physicianContacts";
import StepBar from "../../../components/UI/StepBar";
import Validators from "../../../validators";

class ParentFamilyPage extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      step: 0
    };
    this.titles = [
      "My Children",
      "Parent & Guardian Contacts",
      "Emergency Contacts",
      "Physician Contacts"
    ];
    this.validate = [
      () => Validators.Parents.Profile.ValidateChildren(this.global.children),
      () =>
        Validators.Parents.Profile.ValidateGuardians(
          this.global.parentGuardians
        ),
      () =>
        Validators.Parents.Profile.ValidateEmergencyContacts(
          this.global.emergencyContacts
        ),
      () =>
        Validators.Parents.Profile.ValidatePhysicianContacts(
          this.global.physicians
        )
    ];
    this.steps = [
      { name: "Children", validateFn: this.validate[0] },
      { name: "Guardians", validateFn: this.validate[1] },
      { name: "Emergency", validateFn: this.validate[2] },
      { name: "Physicians", validateFn: this.validate[3] }
    ];
    this.setGlobal({
      pathway: [
        ...this.global.pathway.slice(0, 1),
        "Family Profile",
        this.titles[this.state.step]
      ]
    });
  }

  async componentDidMount() {
    await this.dispatch.getProfile();
  }

  saveProfile = async e => {
    const { step } = this.state;
    switch (e.target.name) {
      case "next":
        this.setState({ step: step + 1 });
        await this.dispatch.saveProfile();
        break;
      case "back":
        this.setState({ step: step - 1 });
        await this.dispatch.saveProfile();
        break;
      case "save":
        await this.dispatch.saveProfile();
        break;
      default:
        return;
    }
  };

  changePage = async page => {
    await this.dispatch.saveProfile();
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 2), this.titles[page]]
    });
    this.setState({ step: page });
  };

  getPage = () => {
    switch (this.state.step) {
      case 0:
        return <ProfileChildrenPage />;
      case 1:
        return <ParentGuardiansPage />;
      case 2:
        return <EmergencyContactsPage />;
      case 3:
        return <PhysicianContactsPage />;
      default:
        return <ProfileChildrenPage />;
    }
  };

  checkValid() {
    const { step } = this.state;
    return this.validate[step] && !this.validate[step]();
  }

  getCompletion() {
    let completed = 0;
    for (let v of this.validate) {
      completed += v();
    }
    return (completed / this.validate.length) * 100;
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="cont">
          <h1>
            Family Profile <span>{this.titles[this.state.step]}</span>
          </h1>
        </div>
        <StepBar
          {...{
            steps: this.steps,
            changeFn: this.changePage,
            current: this.state.step
          }}
        />
        <StepIndicator value={this.getCompletion()} />
        {this.getPage()}
        <div className="cont mb-5">
          <div className="buttons w-100">
            <button
              onClick={this.saveProfile}
              disabled={this.state.step === 0}
              name="back"
              className="nextbtn"
            >
              BACK
            </button>
            <button onClick={this.saveProfile} name="save" className="savebtn">
              SAVE
            </button>
            <button
              onClick={this.saveProfile}
              disabled={
                this.state.step === this.titles.length - 1 || this.checkValid()
              }
              name="next"
              className="nextbtn"
            >
              NEXT
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ParentFamilyPage;
