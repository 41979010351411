import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import axios from "axios";
import { toast } from "react-toastify";
import { format, differenceInMinutes } from "date-fns";
import AddTaskModal from "../../components/modals/AddTaskModal";
import AddReminderModal from "../../components/modals/AddReminderModal";
import { Delete } from "@material-ui/icons";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import AppointmentDetailsModal from "../../components/modals/AppointmentDetailsModal";
import Details from "../../components/modals/DetailsModal";
import ConnectionsViewModal from "../../components/modals/ConnectionsViewModal";
import NotesModal from "../../components/modals/NotesModal";
import AddConnectionModal from "../../components/modals/AddConnectionModal";
import { withStyles } from "@material-ui/core/styles";
import { fetchConnectionsData } from "../../Caching";

const styles = {
  filter: {
    height: "165px",
    "& .MuiList-padding": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important"
    }
  },
  checkbox: {
    minWidth: "32px",
    "& .MuiListItemIcon-root": {
      minWidth: "40px"
    }
  }
};

class Connections extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: { Active: [], Pending: [] },
      allData: [],
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      popoverAnchorE1: null,
      filterListChecked: [],
      openApptModal: false,
      openContactUsModal: false,
      openEnrollmentModal: false,
      selectedItem: null,
      isName1: false,
      isName2: false,
      isDate1: false,
      isDate2: false,
      searchField: "",
      search: "",
      page: -1
    };
    this.inputRef = React.createRef();

    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Connections", to: "/connections" }
      ]
    });

    this.handleFilterListToggle = this.handleFilterListToggle.bind(this);
    this.filterListLabels = [
      "Name (A-Z)",
      "Name (Z-A)",
      "Date (new to old)",
      "Date (old to new)"
    ];

    this.tabs = ["Active", "Pending"];
    this.headings = {
      Active: [
        {
          id: "displayName",
          label: "User Name",
          customCell: (r, i) => {
            const isCustom = r.custom ? true : false;
            const custom = r.custom ? r.custom : null;
            let nameKey = "";
            if (isCustom) {
              const keys = Object.keys(r.custom);
              nameKey = keys.filter(f => f.includes("Full Name"))[0];
            }

            return (
              <td key={`${i}-name`}>
                <a
                  href={`${process.env.REACT_APP_REDIRECT_URI}/partners/single/connections/${r.email}/true?parentId=${r.dsUserId}`}
                >
                  {isCustom ? `${custom[nameKey]}` : r.displayName}
                </a>
              </td>
            );
          }
        },
        {
          id: "email",
          label: "Email",
          customCell: (r, i) => {
            return (
              <td key={`${i}-email`}>
                <a
                  href={`${process.env.REACT_APP_REDIRECT_URI}/partners/single/connections/${r.email}/true?parentId=${r.dsUserId}`}
                >
                  {r.email}
                </a>
              </td>
            );
          }
        },
        {
          id: "childName",
          label: "Additional",
          customCell: (r, i) => {
            return (
              <td key={`${i}-additional`}>
                {r.childNames ? r.childNames.join(", ").substring(0, 80) : ""}
              </td>
            );
          }
        },
        {
          id: "phone",
          label: "Phone",
          customCell: (r, i) => {
            const custom = r.custom ? r.custom : null;
            let nameKey = "";
            if (custom) {
              const keys = Object.keys(r.custom);
              nameKey = keys.filter(f => f.includes("Cell Phone"))[0];
            }

            return (
              <td key={`${i}-name`}>
                {custom ? `${custom[nameKey]}` : r.phone}
              </td>
            );
          }
        },
        {
          id: "createdAt",
          label: "Date",
          customCell: (r, i) => {
            return (
              <td key={`${i}-name`}>
                {format(new Date(r.createdAt), "LLLL dd, yyyy")}
              </td>
            );
          }
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button
                  style={{ marginRight: "30px" }}
                  onClick={_ => this.openModal(r, i)}
                >
                  Latest Info
                </button>
                {/* <button
                  style={{ marginRight: "30px" }}
                  onClick={_ =>
                    this.setState({
                      notesModalShown: true,
                      modalData: r,
                      table:
                        r.came_from === "Contact-Us"
                          ? "partner_leads"
                          : "partner_connections"
                    })
                  }
                >
                  Notes
                </button> */}
              </td>
            );
          }
        }
      ],
      Pending: [
        {
          id: "displayName",
          label: "User Name",
          customCell: (r, i) => {
            const isCustom = r.custom ? true : false;
            const custom = r.custom ? r.custom : null;
            let nameKey = "";
            if (isCustom) {
              const keys = Object.keys(r.custom);
              nameKey = keys.filter(f => f.includes("Full Name"))[0];
            }

            return (
              <td key={`${i}-name`}>
                <a
                  href={`${process.env.REACT_APP_REDIRECT_URI}/partners/single/connections/${r.email}/false?parentId=${r.dsUserId}`}
                >
                  {isCustom ? `${custom[nameKey]}` : r.displayName}
                </a>
              </td>
            );
          }
        },
        {
          id: "email",
          label: "Email",
          customCell: (r, i) => {
            return (
              <td key={`${i}-email`}>
                <a
                  href={`${process.env.REACT_APP_REDIRECT_URI}/partners/single/connections/${r.email}/false?parentId=${r.dsUserId}`}
                >
                  {r.email}
                </a>
              </td>
            );
          }
        },
        {
          id: "phone",
          label: "Phone",
          customCell: (r, i) => {
            const custom = r.custom ? r.custom : null;
            let nameKey = "";
            if (custom) {
              const keys = Object.keys(r.custom);
              nameKey = keys.filter(f => f.includes("Cell Phone"))[0];
            }

            return (
              <td key={`${i}-name`}>
                {custom ? `${custom[nameKey]}` : r.phone}
              </td>
            );
          }
        },
        {
          id: "createdAt",
          label: "Date",
          customCell: (r, i) => {
            return (
              <td key={`${i}-name`}>
                {format(new Date(r.createdAt), "LLLL dd, yyyy")}
              </td>
            );
          }
        },
        {
          id: "status",
          label: "Status"
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button
                  style={{ marginRight: "30px" }}
                  onClick={_ => this.openModal(r, i)}
                >
                  Latest Info
                </button>
                {/* <button
                  style={{ marginRight: "30px" }}
                  onClick={_ =>
                    this.setState({
                      notesModalShown: true,
                      modalData: r,
                      table:
                        r.came_from === "Contact-Us"
                          ? "partner_leads"
                          : "partner_connections"
                    })
                  }
                >
                  Notes
                </button> */}
              </td>
            );
          }
        }
      ]
    };
    this.actions = [{ name: "Notes", action: null }];
  }

  openModal = (r, i) => {
    if (r.came_from === "Appointments") {
      this.setState({ openApptModal: true, selectedItem: r });
    } else if (r.came_from === "Added Contact") {
      this.setState({ openContactUsModal: true, selectedItem: r });
    } else {
      this.setState({ openEnrollmentModal: true, selectedItem: r });
    }
  };

  async componentDidMount() {
    this.fetchInitialData();
  }

  async fetchInitialData() {
    this.setGlobal({ loading: true });
    const connections = await fetchConnectionsData(this.global.dsUser.id);
    const data = [];
    const active = [];
    const pending = [];

    // await Promise.all(
    //   results.data.data.connections
    //     .filter(f => !f.isArchived)
    //     .sort((a, b) => {
    //       if (a.classification < b.classification) {
    //         return -1;
    //       }
    //       if (a.classification > b.classification) {
    //         return 1;
    //       }
    //       return 0;
    //     })
    //     .map(async e => {
    //       let childNames = [];
    //       if (e.dsUserId) {
    //         const childrenEp = `${process.env.REACT_APP_API}/partners/connections/child/${e.dsUserId}`;
    //         const allChildrens = await axios.get(childrenEp);
    //         allChildrens.data.data.childrens.map(child => {
    //           childNames.push(child.fullName);
    //         });
    //       }
    //       !active.filter(f => f.email === e.email)[0] &&
    //         e.classification === "active" &&
    //         active.push({
    //           ...e,
    //           childNames: childNames,
    //           childNamesString: childNames.join(", ")
    //         });
    //     })
    // );
    console.time("Loops start");
    connections
      .filter(f => !f.isArchived)
      .sort((a, b) => {
        if (a.classification < b.classification) {
          return -1;
        }
        if (a.classification > b.classification) {
          return 1;
        }
        return 0;
      })
      .map(e => {
        let childNames = [];
        if (e.childNames) {
          e.childNames.map(child => {
            childNames.push(child.fullName);
          });
        }

        // const allChild = results.data.data.connections
        //   .filter(f => !f.isArchived)
        //   .filter(h => h.dsUserId == e.dsUserId)
        //   .map(
        //     k =>
        //       !childNames.filter(f => f == k.childName)[0] &&
        //       k.childName &&
        //       childNames.push(k.childName)
        //   );

        !data.filter(f => f.email === e.email)[0] &&
          data.push({
            ...e,
            childNames,
            childNamesString: childNames.join(", ")
          });
      });

    connections
      .filter(f => !f.isArchived)
      .sort((a, b) => {
        if (a.classification < b.classification) {
          return -1;
        }
        if (a.classification > b.classification) {
          return 1;
        }
        return 0;
      })
      .map(async e => {
        let childNames = [];
        if (e.childNames) {
          e.childNames.map(child => {
            childNames.push(child.fullName);
          });
        }
        // const allChild = results.data.data.connections
        //   .filter(h => h.dsUserId == e.dsUserId)
        //   .map(
        //     k =>
        //       !childNames.filter(f => f == k.childName)[0] &&
        //       k.childName &&
        //       childNames.push(k.childName)
        //   );

        !active.filter(f => f.email === e.email)[0] &&
          e.classification === "active" &&
          active.push({
            ...e,
            childNames: childNames,
            childNamesString: childNames.join(", ")
          });
      });

    connections
      .filter(f => !f.isArchived)
      .sort((a, b) => {
        if (a.classification < b.classification) {
          return -1;
        }
        if (a.classification > b.classification) {
          return 1;
        }
        return 0;
      })
      .map(e => {
        let childNames = [];
        if (e.childNames) {
          e.childNames.map(child => {
            childNames.push(child.fullName);
          });
        }
        // const allChild = results.data.data.connections
        //   .filter(h => h.dsUserId == e.dsUserId)
        //   .map(
        //     k =>
        //       !childNames.filter(f => f == k.childName)[0] &&
        //       k.childName &&
        //       childNames.push(k.childName)
        //   );
        !active.filter(f => f.email === e.email)[0] &&
          !pending.filter(f => f.email === e.email)[0] &&
          pending.push({
            ...e,
            childNames,
            childNamesString: childNames.join(", ")
          });
      });

    console.timeEnd("Loops start");

    // results.data.data.connections
    this.setState({
      allData: data
        .filter(f => !f.isArchived)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
      data: {
        Active: active
          .filter(f => !f.isArchived)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
        Pending: pending
          .filter(f => !f.isArchived)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      }
    });
    this.setGlobal({ loading: false });
  }

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
    if (results.data.success) {
      toast.success(`Partner Accepted`);
      this.componentDidMount();
    } else {
      toast.error(`Error, this person already signed-up!`);
    }
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  handleClose() {
    this.setState({
      popoverAnchorE1: null
    });
  }

  handleFilterClick(event) {
    this.setState({
      popoverAnchorE1: event.currentTarget
    });
  }

  handleFilterListToggle(value) {
    const currentIndex = this.state.filterListChecked.indexOf(value);
    const newChecked = [...this.state.filterListChecked];

    if (currentIndex === -1 && this.state.filterListChecked.length === 0) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      newChecked.push(value);
    }

    const isName1 = value == "Name (A-Z)" ? true : false;
    const isName2 = value == "Name (Z-A)" ? true : false;
    const isDate1 = value == "Date (new to old)" ? true : false;
    const isDate2 = value == "Date (old to new)" ? true : false;

    this.setState({
      filterListChecked: newChecked,
      isName1: isName1,
      isName2: isName2,
      isDate1: isDate1,
      isDate2: isDate2
    });
  }

  pipeData(allData) {
    if (allData.length > 0) {
      return {
        Active: allData.filter(e => {
          if (!e.displayName || !e.email) return false;
          return (
            e.classification === "active" &&
            (e.displayName
              ?.trim()
              .toLocaleLowerCase()
              .includes(
                this.state.search
                  ? this.state.search?.trim().toLocaleLowerCase()
                  : ""
              ) ||
              e.email
                ?.trim()
                .toLocaleLowerCase()
                .includes(
                  this.state.search
                    ? this.state.search?.trim().toLocaleLowerCase()
                    : ""
                ) ||
              e.childNamesString
                ?.trim()
                .toLocaleLowerCase()
                .includes(
                  this.state.search
                    ? this.state.search.trim().toLocaleLowerCase()
                    : ""
                ))
          );
        }),
        Pending: allData.filter(e => {
          if (!e.displayName || !e.email) return false;

          return (
            e.classification === "pending" &&
            (e.displayName
              ?.trim()
              .toLocaleLowerCase()
              .includes(
                this.state.search
                  ? this.state.search?.trim().toLocaleLowerCase()
                  : ""
              ) ||
              e.email
                ?.trim()
                .toLocaleLowerCase()
                .includes(
                  this.state.search
                    ? this.state.search?.trim().toLocaleLowerCase()
                    : ""
                ))
          );
        })
      };
    } else {
      return {
        Active: [],
        Pending: []
      };
    }
  }

  filterData = () => {
    if (this.state.search) {
      return {
        ...this.pipeData(this.state.allData)
      };
    }
    if (this.state.filterListChecked.length > 0) {
      const data = this.state.allData;

      if (this.state.isDate1) {
        data.sort((a, b) => {
          if (new Date(b.createdAt) < new Date(a.createdAt)) {
            return -1;
          }
          if (new Date(b.createdAt) > new Date(a.createdAt)) {
            return 1;
          }
          return 0;
        });
      }
      if (this.state.isDate2) {
        data.sort((a, b) => {
          if (new Date(a.createdAt) < new Date(b.createdAt)) {
            return -1;
          }
          if (new Date(a.createdAt) > new Date(b.createdAt)) {
            return 1;
          }
          return 0;
        });
      }

      if (this.state.isName1) {
        data.sort((a, b) =>
          a.displayName > b.displayName
            ? 1
            : b.displayName > a.displayName
            ? -1
            : 0
        );
      }

      if (this.state.isName2) {
        data.sort((a, b) =>
          b.displayName > a.displayName
            ? 1
            : a.displayName > b.displayName
            ? -1
            : 0
        );
      }

      return {
        ...this.pipeData(data)
      };
    } else return this.state.data;
  };

  render() {
    // if (this.state.allData.length === 0) {
    //   return null;
    // }
    console.log("this.state", this.state);
    console.log("this.global", this.global);
    return (
      <div className="admin">
        {this.state.notesModalShown && (
          <NotesModal
            data={this.state.modalData}
            table={this.state.table}
            onClose={() =>
              this.setState({
                notesModalShown: false,
                modalData: null
              })
            }
          />
        )}
        {this.state.openAddConnectionModal && (
          <AddConnectionModal
            // lead={this.state.selectedItem.custom}
            // formId={this.state.selectedItem.formId}
            addOne={_ => this.fetchInitialData()}
            onClose={_ => this.setState({ openAddConnectionModal: false })}
          />
        )}
        {this.state.openApptModal && (
          <AppointmentDetailsModal
            message={this.state.selectedItem.Message}
            onClose={() =>
              this.setState({
                openApptModal: false
              })
            }
          />
        )}
        {this.state.openContactUsModal && (
          <Details
            lead={this.state.selectedItem.custom}
            formId={this.state.selectedItem.formId}
            onClose={_ => this.setState({ openContactUsModal: false })}
          />
        )}
        {this.state.openEnrollmentModal && (
          <ConnectionsViewModal
            program={this.state.selectedItem}
            onClose={_ => this.setState({ openEnrollmentModal: false })}
          />
        )}
        {this.state.taskModal && (
          <AddTaskModal onClose={_ => this.setState({ taskModal: false })} />
        )}
        {this.state.reminderModal && (
          <AddReminderModal
            onClose={_ => this.setState({ reminderModal: false })}
            page={this.state.stage}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div
              className="col-12"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h1 style={{ flexGrow: 1 }}>Connections</h1>

              <button
                className="newapplications"
                style={{
                  padding: "6px 13px",
                  borderRadius: "10px",
                  marginRight: "20px",
                  color: "white",
                  fontWeight: "700",
                  background: "#5cc8dc",
                  border: "1px solid #5cc8dc",
                  boxShadow: "2px 2px 5px 2px #cdcdcd"
                }}
                onClick={_ => this.setState({ openAddConnectionModal: true })}
              >
                + Add
              </button>

              <button
                className="newapplications"
                style={{
                  border: "1px solid #ccc",
                  padding: "6px 20px",
                  borderRadius: "4px",
                  background: "#ccc",
                  fontWeight: "700",
                  color: "white"
                }}
                aria-describedby={
                  Boolean(this.state.popoverAnchorE1)
                    ? "simple-popover"
                    : undefined
                }
                onClick={this.handleFilterClick.bind(this)}
              >
                Filter
              </button>
              <Popover
                id={
                  Boolean(this.state.popoverAnchorE1)
                    ? "simple-popover"
                    : undefined
                }
                open={Boolean(this.state.popoverAnchorE1)}
                anchorEl={this.state.popoverAnchorE1}
                onClose={this.handleClose.bind(this)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
              >
                <List
                  className={this.props.classes.filter}
                  disablePadding={false}
                >
                  {this.filterListLabels.map((value, i) => {
                    const labelId = `checkbox-list-label-${i}`;
                    return (
                      <ListItem
                        key={value}
                        role={undefined}
                        dense
                        button
                        onClick={_ => {
                          this.handleFilterListToggle(value);
                        }}
                      >
                        <ListItemIcon
                          classes={{ root: this.props.classes.checkbox }}
                        >
                          <Checkbox
                            name={value}
                            edge="start"
                            checked={
                              this.state.filterListChecked.indexOf(value) !== -1
                            }
                            disabled={
                              this.filterListLabels.filter(f => f == value)
                                ? false
                                : true
                            }
                            tabIndex={-1}
                            disableRipple
                            primary
                            color="primary"
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value} />
                      </ListItem>
                    );
                  })}
                </List>
              </Popover>
            </div>
          </div>

          <div className="cont">
            <div className="tablecont">
              <div
                style={{
                  display: "inline-flex",
                  flex: "1 1",
                  border: "none",
                  borderRadius: "10px",
                  overflow: "hidden",
                  width: "100%"
                }}
              >
                <i
                  className="fas fa-search"
                  style={{ padding: "8px", background: "white" }}
                ></i>
                <input
                  type="text"
                  placeholder="Filter by name or email..."
                  ref={this.inputRef}
                  style={{
                    border: "none",
                    fontSize: "13px",
                    padding: "5px",
                    outline: 0,
                    background: "white",
                    flex: 1
                  }}
                  onChange={e => {
                    if (e.target.value === "") {
                      this.setState({ 
                        search: e.target.value
                       });
                    } 
                  }}
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      this.setState({
                        search: this.inputRef.current.value,
                        page: 0
                      })
                    }
                  }}
                />
                <button
                  onClick={e => this.setState({ 
                    search: this.inputRef.current.value,
                    page: 0
                  })}
                  style={{
                    padding: "6px 13px",
                    borderRadius: "10px",
                    marginRight: "20px",
                    color: "white",
                    fontWeight: "700",
                    background: "navy",
                    border: "1px solid navy",
                  }}>
                  Search
                </button>
              </div>
              <div className="table-responsive">
                <NewTabbedTable
                  tabs={this.tabs}
                  headings={this.headings}
                  page={"Contacts>Connections"}
                  data={this.filterData()}
                  actions={this.actions}
                  currentPage={this.state.page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Connections);
