import { StreamApp, NotificationDropdown } from "react-activity-feed";
import "react-activity-feed/dist/index.css";
import "./notifications.css";
import React, { useGlobal, useEffect, useRef } from "reactn";
import SingleNotification from "./SingleNotification";
import { NotIcon } from "../../../assets";
import { useHistory } from "react-router-dom";
import axios from "axios";

const Notifications = () => {
  const { streamtoken } = useGlobal("organizationInfo")[0];
  const history = useHistory();

  useEffect(() => {
    // If streamtoken not assigned, assign streamtoken.
    const generateStreamToken = async () => {
      const ep = `${process.env.REACT_APP_API}/partners/dashboard/stream`;

      const result = await axios.get(ep);
      console.log(result);
    };
    if (!streamtoken) {
      // Rerender page
      generateStreamToken().then(() => history.go(0));
    }
  }, [streamtoken, history]);

  // Ref used to close the Dropdown.
  const streamContainerRef = useRef(null);

  const handleClick = () => {
    streamContainerRef.current.click();
  };

  return (
    <div ref={streamContainerRef} className="notification-wrapper">
      {streamtoken && (
        <StreamApp
          apiKey={process.env.REACT_APP_STREAM_API}
          appId={process.env.REACT_APP_STREAM_APP}
          token={streamtoken}
        >
          <li className="nav-item dropdown no-arrow">
            <div
              className="nav-link dropdown-toggle"
              // href="#/"
              id="alertsDropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <NotificationDropdown
                notify
                right
                Header={() => {
                  return (
                    <h5
                      style={{
                        margin: "10px 20px",
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        color: "black"
                      }}
                    >
                      Notifications
                    </h5>
                  );
                }}
                Placeholder={() => {
                  return (
                    <div style={{ background: "white" }}>
                      <span className="no-notification-text">
                        No notifications... yet!
                      </span>
                    </div>
                  );
                }}
                Group={props => {
                  let activityArr = [];
                  if (
                    props.activityGroup.verb === "milestone_upload" ||
                    props.activityGroup.verb === "milestone_journal"
                  ) {
                    props.activityGroup.activities.forEach(act => {
                      activityArr.push({
                        ...props,
                        activityGroup: {
                          ...props.activityGroup.activities[0],
                          activities: [act],
                          activity_count: 1,
                          actor_count: 1
                        }
                      });
                    });

                    return (
                      <>
                        {activityArr.map((activityProps, index) => {
                          return (
                            <SingleNotification
                              key={index}
                              {...activityProps}
                              closeDropdown={handleClick}
                            />
                          );
                        })}
                      </>
                    );
                  }
                  return (
                    <SingleNotification
                      {...props}
                      closeDropdown={handleClick}
                    />
                  );
                }}
                Icon={() => <img src={NotIcon} alt="" />}
              />
            </div>
          </li>
        </StreamApp>
      )}
    </div>
  );
};

export default Notifications;
