import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton, Switch } from "@material-ui/core";
import { formTypes } from "../UI/form";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, SWITCH, DROPDOWN, TITLE } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import DropFileInput from "./DropFileInput";
import "../../assets/css/componentSpecificCss/uploadFileModal.css";
import ReactTooltip from "react-tooltip";

class UploadFileModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { fileList: [] };
  }

  fileChange = files => {
    this.setState({ fileList: files });
  };

  submitFiles = async () => {
    this.setGlobal({ loading: true });
    if (this.state.fileList.length === 0) {
      toast.error("Please select a file to upload");
      this.setGlobal({ loading: false });
      return;
    }
    await this.props.onFileChange(this.state.fileList);
    await this.props.onSubmit();
    this.setGlobal({ loading: false });
  };

  render() {
    return (
      <div className="request-modal-cont">
        <div
          className="request-modal lesspadding scroll-bar"
          id="show-scroll-bar"
          style={{
            WebkitOverflowScrolling: "touch",
            scrollbarColor: "auto transparent"
          }}
        >
          <div
            className="milestone-file-upload-modal-header"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "sticky",
              top: "0",
              backgroundColor: "#fff",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              paddingBottom: "1rem",
              zIndex: "1"
            }}
          >
            <h2
              className="milestone-file-upload-headers"
              style={{
                flexGrow: 1,
                textAlign: "center",
                margin: "0 auto",
                marginLeft: "2rem",
                fontFamily: "Open sans, sans-serif",
                fontWeight: "600",
                fontSize: "22px"
              }}
            >
              Upload Files
            </h2>
            <button
              className="milestone-file-upload-exit"
              style={{ borderRadius: "50%", flexShrink: 0 }}
              onClick={() => this.props.onClose()}
            >
              x
            </button>
          </div>
          <div className="milestone-file-upload-box">
            <DropFileInput
              onFileChange={e => this.fileChange(e)}
              onSubmit={() => this.props.onSubmit()}
              onFileNameChange={name => this.props.onFileNameChange(name)}
            />
          </div>
          <div className="milestone-file-upload-modal-footer">
            <div
              style={{
                textAlign: "center",
                paddingTop: "12px",
                marginBottom: "10px"
              }}
            >
              <button
                className="drop-file-submit-upload"
                style={{
                  borderRadius: "20px",
                  width: "200px",
                  height: "40px",
                  paddingBottom: "30px",
                  fontFamily: "Open sans, sans-serif"
                }}
                onClick={async () => {
                  await this.submitFiles();
                }}
              >
                Submit
              </button>
            </div>
            <div
              style={{
                fontSize: "11px",
                margin: "0px 20px",
                fontWeight: "500",
                color: "grey",
                textAlign: "center",
                fontFamily: "Open sans, sans-serif"
              }}
            >
              Note: Only files with the extensions .png, .jpeg, .jpg, .pdf,
              .mov, .mp4, .mpeg, and .avi are supported. File size cannot exceed
              30MB. Maximum of 5 files per upload.
            </div>
          </div>
          {/* <div
            style={{
              fontSize: "11px",
              margin: "0px 20px",
              fontWeight: "500",
              color: "grey",
              textAlign: "center",
              fontFamily: "Open sans, sans-serif"
            }}
          >
            NOTE: Only files with the extensions .png, .jpeg, .jpg, .pdf, .mov,
            .mp4, .mpeg, and .avi are supported. File size cannot exceed 20MB.
            Files can only be uploaded one at a time.
          </div> */}
        </div>
      </div>
    );
  }
}

export default withRouter(UploadFileModal);
