import React from "reactn";
import { Link, withRouter } from "react-router-dom";
import { DsHome, LogoutBg } from "../../assets";
import { Scrollbars } from "react-custom-scrollbars";

class SideBarPartner extends React.PureComponent {
  logout = async () => {
    this.props.history.push("/");
    await this.dispatch.logout();
  };
  render() {
    const { Menu, path } = this.props;
    const themes = {
      0: "bg-gradient-purple",
      1: "bg-gradient-secondary",
      2: "bg-gradient-orange",
      3: "bg-gradient-primary",
      4: "bg-gradient-blue",
      5: "bg-gradient-green",
      6: "bg-gradient-pink"
    };
    return (
      <ul
        className={
          (this.global.sidenavShown ? "sidebar-shown " : "") +
          `navbar-nav ${
            this.global.organizationInfo
              ? this.global.organizationInfo.theme
                ? themes[this.global.organizationInfo.theme]
                : "bg-gradient-purple"
              : "bg-gradient-purple"
          } sidebar sidebar-dark accordion`
        }
        id="accordionSidebar"
      >
        {this.global.organizationInfo &&
        this.global.organizationInfo.logo &&
        !this.global.franchiseUser ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingTop: "15px",
              paddingRight: "10px",
              paddingLeft: "10px"
            }}
          >
            <Link
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "8px",
                backgroundColor: "white",
                padding: 0,
                marginLeft: "8px"
              }}
              to={path}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={
                  process.env.REACT_APP_DS_FILES_S3 +
                  "/" +
                  this.global.organizationInfo.logo
                }
                alt="logo"
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "8px",
                  objectFit: "cover",
                  boxShadow: "rgb(133 121 121 / 59%) 0px 1px 12px 0px"
                }}
              />
            </Link>
            <p
              style={{
                color: "white",
                marginBottom: "0px",
                fontWeight: "400",
                fontFamily: "Open Sans, sans-serif",
                paddingLeft: "12px",
                fontSize: ".9rem",
                letterSpacing: "1px"
              }}
            >
              {this.global.organizationInfo.dashboard_title ||
                this.global.organizationInfo.organization_title ||
                ""}
            </p>
          </div>
        ) : (
          <Link
            className={
              "sidebar-brand d-flex align-items-center justify-content-center"
            }
            style={{
              width: "90%",
              margin: "5px auto",
              borderRadius: "10px",
              border: ".25px solid #A7A3A3",
              padding: 0,
              height: "4rem"
            }}
            to={path}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <img
              src={DsHome}
              alt="logo"
              style={{
                maxWidth: "140px",
                maxHeight: "30px",
                objectFit: "cover"
              }}
            />
          </Link>
        )}
        <Scrollbars
          style={{ width: "14rem", height: "100vh", overflowY: "hidden" }}
        >
          <hr className="sidebar-divider my-0" />
          <Menu />
        </Scrollbars>
        <button
          id="sidebarToggleTop"
          type="button"
          className={
            (this.global.sidenavShown ? "sidebarToggleActive " : "") +
            "btn btn-link rounded-circle mr-3"
          }
          onClick={() =>
            this.setGlobal({ sidenavShown: !this.global.sidenavShown })
          }
          style={{ top: "9px" }}
        >
          <i className="fa fa-bars" />
        </button>
        {!this.global.franchiseUser && (
          <div className="logoutbtn">
            <button
              onClick={() => this.props.history.push("/preview")}
              style={{ border: "1px solid grey" }}
            >
              LIVE PREVIEW
            </button>
            <img src={LogoutBg} alt="" />
          </div>
        )}
      </ul>
    );
    //  End of Sidebar
  }
}

export default withRouter(SideBarPartner);
