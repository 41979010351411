import React from "reactn";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import Header from "../components/front/Header";
import Footer from "../components/front/Footer";

class RoutePublic extends React.PureComponent {
  constructor(props) {
    super(props);
    document.getElementsByTagName("html")[0].className = "main-pages";
    document.getElementsByTagName("html")[0].style.fontSize = "10px";
    document.getElementsByTagName("html")[0].style.lineHeight = "11.5px";
  }

  async componentDidMount() {
    if (localStorage.getItem("ds_token") && !this.global.dsUser) {
      if (localStorage.getItem("partner"))
        await this.dispatch.validateTokenPartner();
      else await this.dispatch.validateToken();
    }
  }

  render() {
    const { component: Component, isAuthenticated, to, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect to={to} />
          ) : (
            <>
              <Header />
              <div id="page-content">
                <Component {...props} />
              </div>
              {/*<Footer />*/}
            </>
          )
        }
      />
    );
  }
}

RoutePublic.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  to: PropTypes.string
};

RoutePublic.defaultProps = {
  to: "/private"
};

export default RoutePublic;
