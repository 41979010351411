import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { DateTime } from "luxon";
import LinkIcon from "@material-ui/icons/Link";
import AddIframeModal from "../../components/modals/AddIframeModal";
import { Delete, SettingsBackupRestore as Restore } from "@material-ui/icons";
import CreateProgramModal from "../../components/modals/CreateProgramModal";
import PreviewProgramListingsModal from "../../components/modals/PreviewProgramListingsModal";
import CopyExistingProgramModal from "../../components/modals/CopyExistingProgramModal";
import Switch from "@material-ui/core/Switch";
import ReactTooltip from "react-tooltip";
import ConfirmArchiveModal from "../../components/modals/ConfirmArchiveModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import "react-lazy-load-image-component/src/effects/opacity.css";
import NotesModal from "../../components/modals/NotesModal";
import ViewArchivedProgramDetailsModal from "../../components/modals/ViewArchivedProgramDetailsModal";

const styles = {
  filter: {
    height: "215px",
    "& .MuiList-padding": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important"
    }
  },
  filterArchived: {
    height: "165px",
    "& .MuiList-padding": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important"
    }
  },
  checkbox: {
    minWidth: "32px",
    "& .MuiListItemIcon-root": {
      minWidth: "40px"
    }
  }
};
class ProgramList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      data: { Active: [], Archive: [] },
      data2: { Active2: [] },
      allData: [],
      programCount: { Active: [], Archive: [] },
      //data: {},
      filter: "",
      tours: [],
      programPermissions: {},
      original: [],
      leadModal: false,
      openCreateProgramModal: false,
      openPreviewProgramListingsModal: false,
      openCopyExistingProgramModal: false,
      openViewArchivedModal: false,
      showConfirmModal: false,
      showConfirmHideModal: false,
      toArchive: true,
      toHide: true,
      idToEdit: 0,
      cancelAllApplications: false,
      filterListChecked: [],
      isPreview: true,
      isName1: false,
      isDate1: false,
      isDate2: false,
      isCat1: false,
      currTab: 0,
      previewOrder: [],
      previewUpdated: false,
      page: -1
    };

    this.tabs = ["Active", "Archive"];

    this.actions = [{ name: "Notes", action: null }];
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Programs", to: "/programs/list" }
      ]
    });

    this.handleFilterListToggle = this.handleFilterListToggle.bind(this);
    this.filterListLabels = [
      "Preview Order",
      "Name (A to Z)",
      "Created Date (new to old)",
      "Update Date (new to old)",
      "Category (A to Z)"
    ];
  }
  
  getTimeZone = () => {
    const tz = this.global.organizationInfo.timezone;
    return DateTime.now().setZone(tz).toFormat("ZZZZ");
  }

  headings = () => {
    return {
      Active: [
        {
          id: "pic",
          label: "",
          customCell: (r, i) => (
            <td key={i} style={{ whiteSpace: "nowrap" }}>
              <LazyLoadImage
                src={`${process.env.REACT_APP_DS_FILES_S3 + "/" + r.program_photo
                  }`}
                style={{
                  width: "130px",
                  height: "78px",
                  borderRadius: "7px",
                  objectFit: "cover"
                }}
                effect="opacity"
              />
            </td>
          )
        },
        { id: "program_name", label: "Program Name" },
        { id: "category_description", label: "Category" },
        { id: "program_category", label: "Program Type" },
        {
          id: "status",
          label: "Publish",
          customCell: (r, i) => {
            return (
              <td key={i}>
                <Switch
                  checked={r.published}
                  size="small"
                  onChange={async _ => {
                    await this.updatePublishStatus(r);
                    await this.fetchInitialData();
                  }}
                  disabled={
                    this.canSubUserEdit() ||
                    // Using the same elements that are required when creating a new program
                    !r.program_category ||
                    !r.program_details ||
                    !r.program_name ||
                    !r.description ||
                    !r.address1 ||
                    r.program_color === null ||
                    (!r.number_of_weeks && !r.isRecurring) ||
                    !r.days_of_week ||
                    Object.keys(r.days_of_week).length === 0 ||
                    r.plans.filter(plan => {
                      let ret = false;
                      const { active, archived } = JSON.parse(plan);
                      if ((active === undefined || active) && !archived)
                        ret = true;
                      return ret;
                    }).length === 0 ||
                    r.formId === null
                  }
                  color="primary"
                />
              </td>
            );
          }
        },
        {
          id: "updated",
          label: `Updated (${this.getTimeZone()})`,
          customCell: row => {
            const tz = this.global.organizationInfo.timezone;
            const startZonedTimeDisplay = format(
              utcToZonedTime(row.updatedAt, tz),
              "LLL dd, yyyy - h:mm a"
            );

            return (
              <td key={`${row.id}-start`}>
                { startZonedTimeDisplay }
              </td>
            );
          }
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <div className="action-group">
                  <button
                    style={{
                      marginRight: "15px",
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    disabled={this.canSubUserEdit()}
                    onClick={_ =>
                      this.props.history.push(
                        `/partners/programs/setup/${r.id}`
                      )
                    }
                  >
                    Edit
                  </button>
                  <LinkIcon
                    style={{
                      transform: "rotate(130deg)",
                      marginRight: "10px",
                      color: "darkgrey"
                    }}
                    onClick={_ =>
                      this.setState({
                        iframeSetUp: true,
                        selectedIframe: r.id,
                        type: "program"
                      })
                    }
                    className="hovermyX"
                  />
                  <>
                    <i
                      className="fas fa-clipboard icon"
                      style={{
                        marginRight: "10px",
                        cursor: this.canSubUserEdit()
                          ? "not-allowed"
                          : "pointer"
                      }}
                      onClick={_ => {
                        return this.canSubUserEdit()
                          ? ""
                          : this.viewNotes(r, "partner_programs");
                      }}
                      data-for="notes"
                      data-tip
                    />
                    <ReactTooltip
                      id="notes"
                      place="top"
                      effect="solid"
                      type="info"
                    >
                      Notes
                    </ReactTooltip>
                  </>

                  {!this.canSubUserEdit() && (
                    <>
                      <Delete
                        onClick={() => {
                          this.setState({
                            showConfirmModal: true,
                            idToEdit: r.id,
                            toArchive: true
                          });
                        }}
                        data-for="archive"
                        data-tip
                      />
                      <ReactTooltip
                        id="archive"
                        place="top"
                        effect="solid"
                        type="info"
                      >
                        Archive Program
                      </ReactTooltip>
                    </>
                  )}
                </div>
              </td>
            );
          }
        }
      ],
      Archive: [
        {
          id: "pic",
          label: "",
          customCell: (r, i) => (
            <td key={i} style={{ whiteSpace: "nowrap" }}>
              <LazyLoadImage
                src={`${process.env.REACT_APP_DS_FILES_S3 + "/" + r.program_photo
                  }`}
                style={{
                  width: "130px",
                  height: "78px",
                  borderRadius: "7px",
                  objectFit: "cover"
                }}
                effect="opacity"
              />
            </td>
          )
        },
        { id: "program_name", label: "Program Name" },
        { id: "category_description", label: "Category" },
        { id: "program_category", label: "Program Type" },
        {
          id: "status",
          label: "Publish",
          customCell: (r, i) => {
            return (
              <td key={i}>{r.published ? "Published" : "Not Published"}</td>
            );
          }
        },
        {
          id: "updated",
          label: `Updated (${this.getTimeZone()})`,
          customCell: row => {
            const tz = this.global.organizationInfo.timezone;
            const startZonedTimeDisplay = format(
              utcToZonedTime(row.updatedAt, tz),
              "LLL dd, yyyy - h:mm a"
            );

            return (
              <td key={`${row.id}-start`}>
                { startZonedTimeDisplay }
              </td>
            );
          }
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <div className="action-group">
                  <button
                    style={{
                      marginRight: "15px",
                      cursor: "pointer"
                    }}
                    onClick={_ => this.setState({ 
                      openViewArchivedModal: true,
                      idToEdit: r.id
                    })
                    }
                  >
                    View
                  </button>
                  {!this.canSubUserEdit() && this.showCreateModalButton() && (
                    <>
                      <Restore
                        onClick={() => {
                          this.setState({
                            showConfirmModal: true,
                            idToEdit: r.id,
                            toArchive: false
                          });
                        }}
                        style={{ color: "#BBB" }}
                        data-for="restore"
                        data-tip
                      />
                      <ReactTooltip
                        id="restore"
                        place="top"
                        effect="solid"
                        type="info"
                      >
                        Restore Program
                      </ReactTooltip>
                    </>
                  )}
                  {!this.canSubUserEdit() && this.showCreateModalButton() && (
                    <>
                      <Delete
                        onClick={() => {
                          this.setState({
                            showConfirmHideModal: true,
                            idToEdit: r.id,
                            toArchive: true,
                            toHide: true,
                            cancelAllApplications: !!this.state.customers.find(
                              customer => {
                                return (
                                  customer.programId === r.id &&
                                  (customer.status_stage ===
                                    "Accepted_Unpaid" ||
                                    customer.status_stage === "New_Pending" ||
                                    customer.status_stage ===
                                    "Accepted_Manual" ||
                                    customer.status_stage ===
                                    "Waitlisted_Unpaid")
                                );
                              }
                            )
                          });
                        }}
                        data-for="hidden"
                        data-tip
                      />
                      <ReactTooltip
                        id="hidden"
                        place="top"
                        effect="solid"
                        type="info"
                      >
                        Delete Program
                      </ReactTooltip>
                    </>
                  )}
                </div>
              </td>
            );
          }
        }
      ]
    };
  };

  updatePublishStatus = async r => {
    const ep = `${process.env.REACT_APP_API}/partners/programs/publish`;
    const results = await axios.post(ep, {
      id: r.id,
      published: !r.published
    });

    if (results.data.success) {
      const data = this.state.allData.filter(
        f => f.classification === "active"
      );

      data.filter(f => f.id === r.id)[0].published = !r.published;

      const currentOrder = data
        .filter(f => !f.isArchived && !f.isHidden && f.published)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .sort((a, b) => a.display_order - b.display_order);

      this.setState({
        data: {
          Active: data
        },
        previewOrder: currentOrder
      });
    }
  };

  viewNotes = (row, table) => {
    this.setState({
      notesModalShown: true,
      modalData: row,
      table: table
    });
  };

  /**
   * Makes request to backend to archive/unarchive program.
   * Uses state to determine options (what to delete, archive/unarchive).
   */
  archiveProgram = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/programs/cancel/${this.state.idToEdit}`;
    const results = await axios.post(ep);
    const ep2 = `${process.env.REACT_APP_API}/partners/programs/delete/${this.state.idToEdit}`;
    const results2 = await axios.post(ep2, { toArchive: this.state.toArchive });

    if (results.data.success && results2.data.success) {
      this.fetchInitialData();
    }
  };

  /**
   * Makes request to backend to hide a program from archive.
   * Looks like a permanent deletion, but is only hidden from the partner's view.
   * Program still stored in database
   */
  hideProgram = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/programs/hide/${this.state.idToEdit}`;
    const results = await axios.post(ep, { toHide: this.state.toHide });

    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  cancelProgramApplications = async () => {
    let programCustomerArr = [];

    this.state.customers.forEach(customer => {
      if (customer.programId === this.state.idToEdit) {
        if (
          customer.status_stage === "Accepted_Unpaid" ||
          customer.status_stage === "New_Pending" ||
          customer.status_stage === "Waitlisted_Unpaid" ||
          customer.status_stage === "Accepted_Manual"
        ) {
          programCustomerArr.push({
            id: customer.id,
            programId: customer.programId
          });
        }
      }
    });

    console.log("PROGRAM CUSTOMERS", programCustomerArr);

    const ep = `${process.env.REACT_APP_API}/partners/programs/cancel-application/${this.state.idToEdit}`;
    const results = await axios.post(ep, { programCustomerArr });
  };

  getProgramPermissionsCount = async () => {
    const feature_name = "programs";
    const ep = `${process.env.REACT_APP_API}/partner/permissions/feature/${feature_name}`;
    const resp = await axios.get(ep);

    if (resp.data) {
      return resp.data.data;
    }
  };

  showCreateModalButton = () => {
    const { data, programPermissions } = this.state;
    if (data.Active && programPermissions) {
      return (
        programPermissions.has_access &&
        programPermissions.featureLimit > data.Active.length
      );
    }
    return false;
  };

  /**
   * This function handles the edit button functionality of a sub user
   */
  canSubUserEdit = () => {
    return !(
      !this.global.dsUser.sub_user ||
      this.global?.dsUser?.accesspoints?.Listings?.Programs?.edit
    );
  };

  async componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    this.setGlobal({ loading: true });

    let tab = "active";
    if (this.state.currTab === 0) {
      tab = "active";
    } else if (this.state.currTab === 1) {
      tab = "archived";
    } else {
      console.log("NO TAB FOUND");
    }

    const ep = `${process.env.REACT_APP_API}/partners/programs/list/${tab}`;
    const customers_ep = `${process.env.REACT_APP_API}/partners/programs/customers/active/${this.global.organizationInfo.partnerId}`;
    console.time("initial_results_fetch"); // start the timer
    const results = await axios.get(ep);
    console.timeEnd("initial_results_fetch"); // stop the timer and log the elapsed time

    const program_count_ep = `${process.env.REACT_APP_API}/partners/programs/list/count`;
    console.time("program_count_fetch"); // start the timer
    const program_count_results = await axios.get(program_count_ep);
    console.timeEnd("program_count_fetch"); // stop the timer and log the elapsed time

    console.log("PROGRAM COUNT RESULTS", program_count_results);

    const previewOrderEp = `${process.env.REACT_APP_API}/partners/programs/list/active`;
    const previewOrderResults = await axios.get(previewOrderEp);

    const programCount = {
      Active: program_count_results.data.data.filter(
        e => !e.isArchived && !e.isHidden
      ),
      Archive: program_count_results.data.data.filter(
        e => e.isArchived && !e.isHidden
      )
    };
    console.log("PROGRAM COUNT", programCount);

    // Get all customers to perform check if program has any enrollments.
    console.time("initial_program_customers_fetch"); // start the timer
    const customers = await axios.get(customers_ep);
    console.timeEnd("initial_program_customers_fetch"); // stop the timer and log the elapsed time

    if (results.data.success && customers.data.success) {
      this.setState({
        allData: results.data.data
          .filter(f => !f.isArchived)
          .map(e => ({ ...e, status: "Published", classification: "active" }))
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .concat(
            results.data.data
              .filter(f => f.isArchived)
              .map(e => ({ ...e, classification: "archive" }))
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          ),
        customers: customers.data.data,
        programCount: programCount,
        previewOrder: previewOrderResults.data.data
          .filter(f => !f.isArchived && !f.isHidden && f.published)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .sort((a, b) => a.display_order - b.display_order)
      });
      console.log("customers", customers.data.data);
    }

    //console.log("Data", this.state.data.allData);
    const permissions = await this.getProgramPermissionsCount();
    this.setState({ programPermissions: permissions });
    this.setGlobal({ loading: false });
  };

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
    if (results.data.success) {
      toast.success(`Partner Accepted`);
      this.componentDidMount();
    } else {
      toast.error(`Error, this person already signed-up!`);
    }
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  // To refresh the form page for a new program card
  async resetEvents() {
    await this.dispatch.resetEvent();
  }

  handleClose() {
    this.setState({
      popoverAnchorE1: null
    });
  }

  handleFilterClick(event) {
    this.setState({
      popoverAnchorE1: event.currentTarget
    });
  }

  handleFilterListToggle(value) {
    const currentIndex = this.state.filterListChecked.indexOf(value);
    const newChecked = [...this.state.filterListChecked];

    if (currentIndex === -1 && this.state.filterListChecked.length === 0) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      newChecked.push(value);
    }

    const isPreview = value == "Preview Order";
    const isName1 = value == "Name (A to Z)";
    const isDate1 = value == "Created Date (new to old)";
    const isDate2 = value == "Update Date (new to old)";
    const isCat1 = value == "Category (A to Z)";

    this.setState({
      filterListChecked: newChecked,
      isPreview: localStorage.setItem("isPreview", isPreview) || isPreview,
      isName1: localStorage.setItem("isName1", isName1) || isName1,
      isDate1: localStorage.setItem("isDate1", isDate1) || isDate1,
      isDate2: localStorage.setItem("isDate2", isDate2) || isDate2,
      isCat1: localStorage.setItem("isCat1", isCat1) || isCat1
    });
  }

  pipeData(allData) {
    if (allData.length > 0) {
      return {
        Active: allData.filter(e => e.classification === "active"),
        Archive: allData.filter(
          e => e.classification === "archive" && e.isHidden === false
        )
      };
    } else {
      return {
        Active: [],
        Archive: []
      };
    }
  }

  filterData = () => {
    let data = this.state.allData;

    // Filter programs by filter bar
    if (this.state.filter && this.state.filter !== "") {
      data = data.filter(program => {
        const programName = program.program_name
          .toLowerCase()
          .replace(/\s/g, "");
        const filterText = this.state.filter.toLowerCase().replace(/\s/g, "");
        return programName.includes(filterText);
      });
    }

    // Filter by Preview Order
    if (this.state.isPreview) {
      const currentOrder = data
        .filter(f => !f.isArchived && !f.isHidden && f.published)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .sort((a, b) => a.display_order - b.display_order);
      const filteredData = data
        .filter(f => f.isArchived || f.isHidden || !f.published)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      data = [...currentOrder, ...filteredData];
    }

    // Filter by Created Date (New to Old)
    if (this.state.isDate1) {
      data.sort((a, b) => {
        if (new Date(b.createdAt) < new Date(a.createdAt)) {
          return -1;
        }
        if (new Date(b.createdAt) > new Date(a.createdAt)) {
          return 1;
        }
        return 0;
      });
    }
    // Filter by Updated Date (New to Old)
    if (
      this.state.isDate2 ||
      (this.state.isPreview && this.state.currTab === 1)
    ) {
      data.sort((a, b) => {
        if (new Date(b.updatedAt) < new Date(a.updatedAt)) {
          return -1;
        }
        if (new Date(b.updatedAt) > new Date(a.updatedAt)) {
          return 1;
        }
        return 0;
      });
    }
    // Filter by Program Name (A to Z)
    if (this.state.isName1) {
      data.sort((a, b) =>
        a.program_name > b.program_name
          ? 1
          : b.program_name > a.program_name
            ? -1
            : 0
      );
    }
    // Filter by Category (A to Z)
    if (this.state.isCat1) {
      data.sort((a, b) =>
        a.category_description > b.category_description
          ? 1
          : b.category_description > a.category_description
            ? -1
            : 0
      );
    }

    return {
      ...this.pipeData(data)
    };
  };

  filterDataCount() {
    let dataCount = { ...this.state.programCount };

    if (this.state.filter && this.state.filter !== "") {
      dataCount["Active"] = dataCount["Active"].filter(program => {
        const programName = program.program_name
          .toLowerCase()
          .replace(/\s/g, "");
        const filterText = this.state.filter.toLowerCase().replace(/\s/g, "");
        return programName.includes(filterText);
      });

      dataCount["Archive"] = dataCount["Archive"].filter(program => {
        const programName = program.program_name
          .toLowerCase()
          .replace(/\s/g, "");
        const filterText = this.state.filter.toLowerCase().replace(/\s/g, "");
        return programName.includes(filterText);
      });
    }

    return dataCount;
  }

  async getCurrTabData() {
    this.setGlobal({ loading: true });

    let tab = "active";
    if (this.state.currTab === 0) {
      tab = "active";
    } else if (this.state.currTab === 1) {
      tab = "archived";
    } else {
      console.log("NO TAB FOUND");
    }

    const ep = `${process.env.REACT_APP_API}/partners/programs/list/${tab}`;
    const customers_ep = `${process.env.REACT_APP_API}/partners/programs/customers/active/${this.global.organizationInfo.partnerId}`;
    console.time("results_fetch"); // start the timer
    const results = await axios.get(ep);
    console.timeEnd("results_fetch"); // stop the timer and log the elapsed time
    // Get all customers to perform check if program has any enrollments.
    console.time("program_customers_fetch"); // start the timer
    const customers = await axios.get(customers_ep);
    console.timeEnd("program_customers_fetch"); // stop the timer and log the elapsed time

    if (results.data.success && customers.data.success) {
      this.setState({
        allData: results.data.data
          .filter(f => !f.isArchived)
          .map(e => ({ ...e, status: "Published", classification: "active" }))
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .concat(
            results.data.data
              .filter(f => f.isArchived)
              .map(e => ({ ...e, classification: "archive" }))
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          ),
        customers: customers.data.data
      });
    }

    this.setGlobal({ loading: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.currTab !== this.state.currTab) {
      this.getCurrTabData();
      if (this.state.currTab === 0) {
        this.setState({
          isPreview: true,
          isDate1: false,
          isDate2: false,
          isName1: false,
          isCat1: false
        });
        this.filterListLabels = [
          "Preview Order",
          "Name (A to Z)",
          "Created Date (new to old)",
          "Update Date (new to old)",
          "Category (A to Z)"
        ];
      } else {
        this.setState({
          isPreview: false,
          isDate1: false,
          isDate2: true,
          isName1: false,
          isCat1: false
        });
        this.filterListLabels = [
          "Name (A to Z)",
          "Created Date (new to old)",
          "Update Date (new to old)",
          "Category (A to Z)"
        ];
      }
    }
  }

  onBeforeDragStart = () => {
    this.setState({ isDragging: true });
  };

  onDragEndHandler = async (destination, source, draggableId) => {
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
  };

  onDragEnd = ({ destination, source, draggableId }) => {
    this.onDragEndHandler(destination, source, draggableId);
    this.setState({ isDragging: false });
  };

  togglePreviewUpdated() {
    this.setState({
      previewUpdated: false,
      currTab: 0,
      isPreview: true,
      isDate1: false,
      isDate2: false,
      isName1: false,
      isCat1: false
    });
  }

  render() {
    console.log("Props:", this.props);
    console.log("State:", this.state);
    return (
      <div className="admin">
        {this.state.notesModalShown && (
          <NotesModal
            data={this.state.modalData}
            table={this.state.table}
            onClose={() =>
              this.setState({
                notesModalShown: false,
                modalData: null
              })
            }
          />
        )}
        {this.state.openCreateProgramModal && (
          <CreateProgramModal
            onClose={_ => this.setState({ openCreateProgramModal: false })}
            history={this.props.history}
          //dispatchEvent={_ => this.dispatch.resetEvent()}
          // setGlobal={this.setGlobal}
          // global={this.global}
          />
        )}
        {this.state.openPreviewProgramListingsModal && (
          <PreviewProgramListingsModal
            onClose={() =>
              this.setState({ openPreviewProgramListingsModal: false })
            }
            onOrderSave={newOrder => {
              this.setState({ previewOrder: newOrder, previewUpdated: true });
              this.fetchInitialData();
            }}
            history={this.props.history}
            programs={this.state.previewOrder}
          />
        )}
        {this.state.openCopyExistingProgramModal && (
          <CopyExistingProgramModal
            onClose={() => {
              this.setState({ openCopyExistingProgramModal: false })
              this.fetchInitialData();
            }}
          />
        )}
        {this.state.openViewArchivedModal && (
          <ViewArchivedProgramDetailsModal
            program={this.filterData()["Archive"].filter(data => data.id === this.state.idToEdit)[0]}
            customers={this.state.customers.filter(customer => customer.programId === this.state.idToEdit)}
            onClose={_ => this.setState({ openViewArchivedModal: false })}
          />
        )}
        {this.state.iframeSetUp && (
          <AddIframeModal
            iframe={this.state.selectedIframe}
            open={this.state.iframeSetUp}
            type={this.state.type}
            active={this.state.active}
            onClose={() =>
              this.setState({
                iframeSetUp: false,
                selectedIframe: null,
                type: null,
                active: null
              })
            }
          />
        )}
        {this.state.showConfirmModal && (
          <ConfirmArchiveModal
            onSubmit={() => {
              this.archiveProgram();
            }}
            onClose={_ => this.setState({ showConfirmModal: false })}
            toArchive={this.state.toArchive}
            modalBody={ this.state.toArchive 
              ? "UPDATE: This functionality has been updated. " +
              "It will automatically deactivate all enrollments, and archive the program. " +
              "All the program data will still be saved. " +
              "Please ensure you want to archive this program. "
              : ""
            }
            notAllowed={false}
            buttonName={this.state.toArchive ? "Archive" : undefined}
          />
        )}
        {this.state.showConfirmHideModal && (
          <ConfirmArchiveModal
            onSubmit={() => {
              if (this.state.cancelAllApplications) {
                // Cancel all applications and then hide the program
                this.cancelProgramApplications()
                  .then(() => this.hideProgram())
                  .catch(error => console.log(error));
              } else {
                // Simply hide the program
                this.hideProgram();
              }
            }}
            onClose={_ => this.setState({ showConfirmHideModal: false })}
            toArchive={this.state.toArchive}
            isPermanent={this.state.toHide}
            modalBody={
              this.state.notAllowedToDelete
                ? "Sorry, programs with customers cannot be deleted."
                : ""
            }
            notAllowed={false}
            cancelApps={this.state.cancelAllApplications}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div
              className="col-md-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div style={{ display: "block" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "-30px"
                  }}
                >
                  <h1 style={{ marginRight: "30px" }}>Programs</h1>
                  <LinkIcon
                    style={{
                      transform: "scale(1.5) rotate(130deg)",
                      cursor: "pointer",
                      marginRight: "10px",
                      marginTop: "0.2px",
                      color: "black"
                    }}
                    onClick={_ =>
                      this.setState({
                        iframeSetUp: true,
                        active: "Programs"
                      })
                    }
                    className="hovermyX"
                  />
                </div>
                <div
                  style={{ marginLeft: "-6px", marginTop: "4px" }}
                  className="switch-tooltip-group"
                >
                  <Switch
                    checked={
                      !!this.global.organizationInfo.display_program_listings
                    }
                    size="small"
                    onChange={_ => {
                      this.setGlobal({
                        organizationInfo: {
                          ...this.global.organizationInfo,
                          display_program_listings:
                            !this.global.organizationInfo
                              .display_program_listings
                        }
                      });
                      this.dispatch.saveOrganizationalProfile();
                    }}
                    disabled={this.canSubUserEdit()}
                    color="primary"
                  />
                  <i
                    data-tip
                    data-for="view-program-toggle"
                    className="fas fa-info-circle"
                    style={{ color: "#DCDCDC" }}
                  ></i>
                  <ReactTooltip
                    id="view-program-toggle"
                    place="right"
                    effect="solid"
                    type="info"
                  >
                    Toggles program tab visibility for users
                  </ReactTooltip>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  {this.showCreateModalButton() ? (
                    <button
                      className="newapplications"
                      disabled={this.canSubUserEdit()}
                      style={{
                        cursor: this.canSubUserEdit()
                          ? "not-allowed"
                          : "pointer",
                        paddingLeft: "20px"
                      }}
                      onClick={_ => {
                        this.setState({
                          openCopyExistingProgramModal: true
                        });
                      }}
                    >
                      Copy Existing
                    </button>
                  ) : null}
                </div>
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  {this.showCreateModalButton() ? (
                    <button
                      className="newapplications"
                      disabled={this.canSubUserEdit()}
                      style={{
                        cursor: this.canSubUserEdit()
                          ? "not-allowed"
                          : "pointer",
                        paddingLeft: "20px"
                      }}
                      onClick={_ => {
                        this.setState({
                          openPreviewProgramListingsModal: true
                        });
                      }}
                    >
                      Order
                    </button>
                  ) : null}
                </div>
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  {this.showCreateModalButton() ? (
                    <button
                      className="newapplications"
                      disabled={this.canSubUserEdit()}
                      style={{
                        cursor: this.canSubUserEdit()
                          ? "not-allowed"
                          : "pointer"
                      }}
                      onClick={_ => {
                        this.setState({ openCreateProgramModal: true });
                        this.resetEvents();
                      }}
                    >
                      <img
                        src={MiniPlus}
                        alt=""
                        style={{ alignSelf: "center" }}
                      />
                      Create New
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row cont">
            <div className="col-md-12">
              <div className="forbtnwrap justify-end">
                {/*  ======================== Start of Filter Button ====================== */}
                <button
                  className="newapplications"
                  style={{
                    border: "1px solid #ccc",
                    padding: "4px 20px",
                    borderRadius: "4px",
                    display: "block",
                    background: "#ccc",
                    fontWeight: "700",
                    color: "white"
                  }}
                  aria-describedby={
                    Boolean(this.state.popoverAnchorE1)
                      ? "simple-popover"
                      : undefined
                  }
                  onClick={this.handleFilterClick.bind(this)}
                >
                  Filter
                </button>
                <Popover
                  id={
                    Boolean(this.state.popoverAnchorE1)
                      ? "simple-popover"
                      : undefined
                  }
                  open={Boolean(this.state.popoverAnchorE1)}
                  anchorEl={this.state.popoverAnchorE1}
                  onClose={this.handleClose.bind(this)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                >
                  <List
                    className={
                      this.state.currTab === 0
                        ? this.props.classes.filter
                        : this.props.classes.filterArchived
                    }
                    disablePadding={false}
                  >
                    {this.filterListLabels.map((value, i) => {
                      const labelId = `checkbox-list-label-${i}`;
                      return (
                        <ListItem
                          key={value}
                          role={undefined}
                          dense
                          button
                          onClick={_ => {
                            this.handleFilterListToggle(value);
                          }}
                        >
                          <ListItemIcon
                            classes={{ root: this.props.classes.checkbox }}
                          >
                            <Checkbox
                              name={value}
                              edge="start"
                              checked={
                                value === "Preview Order"
                                  ? this.state.isPreview
                                  : value === "Name (A to Z)"
                                    ? this.state.isName1
                                    : value === "Created Date (new to old)"
                                      ? this.state.isDate1
                                      : value === "Update Date (new to old)"
                                        ? this.state.isDate2
                                        : value === "Category (A to Z)"
                                          ? this.state.isCat1
                                          : false
                              }
                              disabled={
                                !this.filterListLabels.filter(f => f == value)
                              }
                              tabIndex={-1}
                              disableRipple
                              primary
                              color="primary"
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={value} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Popover>
                {/*  ======================== End of Filter Button ====================== */}
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="tablecont">
              <div
                style={{
                  display: "inline-flex",
                  flex: "1 1",
                  border: "none",
                  borderRadius: "10px",
                  overflow: "hidden",
                  width: "100%"
                }}
              >
                <i
                  className="fas fa-search"
                  style={{
                    padding: "8px",
                    background: "white",
                    marginTop: "10px"
                  }}
                />
                <input
                  type="text"
                  placeholder="Filter by program name"
                  style={{
                    border: "none",
                    fontSize: "13px",
                    padding: "5px",
                    outline: 0,
                    background: "white",
                    flex: 1,
                    marginTop: "10px"
                  }}
                  onChange={e => this.setState({ 
                    filter: e.target.value, 
                    page: 0
                  })}
                />
              </div>
              <div className="table-responsive">
                <NewTabbedTable
                  tabs={this.tabs}
                  headings={this.headings()}
                  //data={this.state.data}
                  data={this.filterData()}
                  dataCount={this.filterDataCount()}
                  page={"Listings>Programs"}
                  isFirstTab={this.state.previewUpdated}
                  resetPreview={() => this.togglePreviewUpdated()}
                  setCurrTab={tab => {
                    this.setState({ currTab: tab });
                  }}
                  currentPage={this.state.page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ProgramList);
