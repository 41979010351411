import React from "reactn";
import select from "../../UI/form/SelectOptions";
import { toast } from "react-toastify";
import Axios from "axios";
import DatePicker from "react-datepicker";
class AddEnrolledChild extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      birthDate: null,
      immunized: false,
      startDate: null,
      parentName: "",
      parentEmail: "",
      endDate: null,
      days: {},
      slots: "Full",
      hoursFrom: -1,
      hoursTo: -1
    };
  }
  async submitForm(e) {
    e.preventDefault();
    const {
      fullName,
      birthDate,
      startDate,
      parentName,
      days,
      hoursFrom,
      hoursTo
    } = this.state;
    try {
      if (
        !fullName ||
        !birthDate ||
        !startDate ||
        !parentName ||
        Object.keys(days).length === 0 ||
        hoursFrom === -1 ||
        hoursTo === -1
      ) {
        throw new Error("Please fill all required fields");
      }
      const ep = `${process.env.REACT_APP_API}/edu/enrollment/child/${this.props.id}`;
      const result = await Axios.post(ep, {
        ...this.state
      });

      this.props.update(result.data.data);
      this.props.onClose();
      toast.success("Successfully Added an enrollment!");
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
  }
  setDay(day) {
    const days = { ...this.state.days };
    if (days[day]) delete days[day];
    else days[day] = true;
    this.setState({
      days
    });
  }
  render() {
    const { open, onClose } = this.props;
    return (
      <div className="request-modal-cont" style={{ overflowY: "scroll" }}>
        <div className="request-modal">
          <h3>Add an enrolled child</h3>
          <form onSubmit={e => this.submitForm(e)}>
            <label htmlFor="childName">Enter the child's name *</label>
            <input
              name="childName"
              type="text"
              placeholder="Child's Name"
              className="form-control"
              required
              value={this.state.fullName}
              onChange={e =>
                this.setState({
                  fullName: e.target.value
                })
              }
            />
            <label htmlFor="requestedDate">Date of Birth *</label>
            <DatePicker
              selected={this.state.birthDate}
              maxDate={new Date()}
              showYearDropdown
              showMonthDropdown
              dateFormat="MMMM d, yyyy"
              className="form-control"
              placeholderText="Date of Birth"
              name="requestedDate"
              autoComplete="off"
              onChange={date => this.setState({ birthDate: date })}
            />
            <label htmlFor="requestedDate">Start Date *</label>
            <DatePicker
              selected={this.state.startDate}
              maxDate={new Date()}
              showYearDropdown
              showMonthDropdown
              dateFormat="MMMM d, yyyy"
              className="w-100 form-control"
              placeholderText="Start Date"
              name="requestedDate"
              autoComplete="off"
              onChange={date => this.setState({ startDate: date })}
            />
            <label>Slots</label>
            <select
              className="w-100 form-control"
              onChange={e => this.setState({ slots: e.target.value })}
            >
              <option value="Full">Full Day</option>
              <option value="Partial">Partial</option>
              <option value="First">First Half (Morning)</option>
              <option value="Second">Second Half (Evening)</option>
            </select>
            <label className="input-label">Days of care</label>
            <div name="days" className="row days" validation={"validDays"}>
              <div
                data-day="Monday"
                className={`day-monday day ${
                  this.state.days["Monday"] ? "selecta" : ""
                }`}
                onClick={e => this.setDay(e.target.getAttribute("data-day"))}
              >
                M
              </div>
              <div
                data-day="Tuesday"
                className={`day-tuesday day ${
                  this.state.days["Tuesday"] ? "selecta" : ""
                }`}
                onClick={e => this.setDay(e.target.getAttribute("data-day"))}
              >
                Tu
              </div>
              <div
                data-day="Wednesday"
                className={`day-wednesday day ${
                  this.state.days["Wednesday"] ? "selecta" : ""
                }`}
                onClick={e => this.setDay(e.target.getAttribute("data-day"))}
              >
                W
              </div>
              <div
                data-day="Thursday"
                className={`day-thursday day ${
                  this.state.days["Thursday"] ? "selecta" : ""
                }`}
                onClick={e => this.setDay(e.target.getAttribute("data-day"))}
              >
                Th
              </div>
              <div
                data-day="Friday"
                className={`day-friday day ${
                  this.state.days["Friday"] ? "selecta" : ""
                }`}
                onClick={e => this.setDay(e.target.getAttribute("data-day"))}
              >
                F
              </div>
              <div
                data-day="Saturday"
                className={`day-saturday day ${
                  this.state.days["Saturday"] ? "selecta" : ""
                }`}
                onClick={e => this.setDay(e.target.getAttribute("data-day"))}
              >
                Sa
              </div>
              <div
                data-day="Sunday"
                className={`day-sunday day ${
                  this.state.days["Sunday"] ? "selecta" : ""
                }`}
                onClick={e => this.setDay(e.target.getAttribute("data-day"))}
              >
                Su
              </div>
            </div>
            <label htmlFor="childcareneed">Is the child immunized ? *</label>
            <br />
            <input
              type="checkbox"
              name="childcareneed"
              value="Immediately"
              onChange={e => this.setState({ immunized: e.target.checked })}
              checked={this.state.immunized}
            />{" "}
            <label>Yes, the child is immunized!</label>
            <br />
            <label>From *</label>
            <select
              className="form-control"
              onChange={e => this.setState({ hoursFrom: e.target.value })}
            >
              {select.timeRanges.map((e, i) => (
                <option value={e.value} key={i}>
                  {e.name}
                </option>
              ))}
            </select>
            <label>To *</label>
            <select
              className="form-control"
              onChange={e => this.setState({ hoursTo: e.target.value })}
            >
              {select.timeRanges.map((e, i) => (
                <option value={e.value} key={i}>
                  {e.name}
                </option>
              ))}
            </select>
            <label htmlFor="parentName">Enter the parent's name *</label>
            <input
              name="parentName"
              type="text"
              placeholder="Parent's name"
              className="form-control"
              value={this.state.parentName}
              onChange={e =>
                this.setState({
                  parentName: e.target.value
                })
              }
            />
            <label htmlFor="parentContact">
              Enter the parent's email or phone (optional)
            </label>
            <input
              name="parentContact"
              type="text"
              placeholder="Parent's contact info"
              className="form-control"
              value={this.state.parentEmail}
              onChange={e =>
                this.setState({
                  parentEmail: e.target.value
                })
              }
            />
            <label htmlFor="requestedDate">Enrollment End (optional)</label>
            <DatePicker
              selected={this.state.endDate}
              minDate={new Date()}
              showYearDropdown
              showMonthDropdown
              dateFormat="MMMM d, yyyy"
              className="w-100 form-control"
              placeholderText="End of Enrollment Date"
              name="requestedDate"
              autoComplete="off"
              onChange={date => this.setState({ endDate: date })}
            />
            <div className="modal-footer">
              <button
                onClick={this.props.onClose}
                className="mx-2 backbtn btn profile-btn"
              >
                Close
              </button>
              <button type="submit" className="btn profile-btn">
                Add Enrollment !
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default AddEnrolledChild;
