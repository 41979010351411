import React from "reactn";
import { Grid, makeStyles } from "@material-ui/core";
import notFoundIllustration from "../../../assets/img/RevisedIllustration.png";

const useStyles = makeStyles(theme => ({
  container: {
    margin: "3em 0"
  },
  illustration: {
    width: "200px",
    marginBottom: "1em"
  },
  messageText: {
    fontFamily: "Montserrat, sans-serif !important",
    color: "#505050 !important"
  }
}));

const NoDataAvailable = ({ message }) => {
  const { container, illustration, messageText } = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={container}
    >
      <img src={notFoundIllustration} className={illustration} alt="No Data" />
      <h3 className={messageText}>{message}</h3>
    </Grid>
  );
};

export default NoDataAvailable;
