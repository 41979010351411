import React from "reactn";
import SectionSix from "./forms/SectionSix";
class AddD extends React.Component {
  render() {
    return (
      <div className="cont boxes-row mt-50px">
        <SectionSix vals={this.global.myApplicationProfileInfo} />
      </div>
    );
  }
}

export default AddD;
