import React, { Component } from "react";
import { validateForm } from "../../lib/util";
class SurveyUi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: {},
      months: "",
      years: "",
      dropOff: "",
      pickUp: "",
      childcareneed: ""
    };
  }
  setDay(day) {
    const days = { ...this.state.days };
    if (days[day]) delete days[day];
    else days[day] = true;
    this.setState({
      days
    });
  }
  submitSurvey = e => {
    e.preventDefault();
    if (validateForm(this.state, e.target)) {
      this.props.submit(this.state);
    } else {
      this.setState({
        error: true
      });
    }
  };
  clickRadio = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    return (
      <form onSubmit={this.submitSurvey}>
        <h4
          style={{
            textAlign: "center",
            color: "red",
            display: this.state.error ? "block" : "none"
          }}
        >
          Please fill all the fields to continue!
        </h4>
        <label htmlFor="password">What is your child's age ?</label>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row-reverse"
          }}
        >
          <select
            name="years"
            id="agedesired"
            type="number"
            min="0"
            max="20"
            className="form-control"
            validation={"isNumber"}
            value={this.state.years}
            onChange={e => {
              this.setState({
                [e.target.name]: e.target.value
              });
            }}
          >
            <option value="" disabled>
              Select Years
            </option>
            {new Array(10).fill(1).map((_, i) => (
              <option value={i + 2} key={i}>
                {i + 2}
                {` Years`}
              </option>
            ))}
          </select>
          <br />

          <select
            name="months"
            id="agedesired"
            type="number"
            min="0"
            max="11"
            className="form-control"
            validation={"isNumber"}
            value={this.state.months}
            onChange={e => {
              this.setState({
                [e.target.name]: e.target.value
              });
            }}
          >
            <option value="" disabled>
              Select Months
            </option>
            {new Array(23).fill(1).map((_, i) => (
              <option value={i + 1} key={i}>
                {i + 1}
                {i === 0 ? ` Month` : ` Months`}
              </option>
            ))}
          </select>
        </div>
        <label className="input-label">Days of care needed</label>
        <div name="days" className="row days" validation={"validDays"}>
          <div
            data-day="Monday"
            className={`day-monday day ${
              this.state.days["Monday"] ? "selecta" : ""
            }`}
            onClick={e => this.setDay(e.target.getAttribute("data-day"))}
          >
            M
          </div>
          <div
            data-day="Tuesday"
            className={`day-tuesday day ${
              this.state.days["Tuesday"] ? "selecta" : ""
            }`}
            onClick={e => this.setDay(e.target.getAttribute("data-day"))}
          >
            Tu
          </div>
          <div
            data-day="Wednesday"
            className={`day-wednesday day ${
              this.state.days["Wednesday"] ? "selecta" : ""
            }`}
            onClick={e => this.setDay(e.target.getAttribute("data-day"))}
          >
            W
          </div>
          <div
            data-day="Thursday"
            className={`day-thursday day ${
              this.state.days["Thursday"] ? "selecta" : ""
            }`}
            onClick={e => this.setDay(e.target.getAttribute("data-day"))}
          >
            Th
          </div>
          <div
            data-day="Friday"
            className={`day-friday day ${
              this.state.days["Friday"] ? "selecta" : ""
            }`}
            onClick={e => this.setDay(e.target.getAttribute("data-day"))}
          >
            F
          </div>
          <div
            data-day="Saturday"
            className={`day-saturday day ${
              this.state.days["Saturday"] ? "selecta" : ""
            }`}
            onClick={e => this.setDay(e.target.getAttribute("data-day"))}
          >
            Sa
          </div>
          <div
            data-day="Sunday"
            className={`day-sunday day ${
              this.state.days["Sunday"] ? "selecta" : ""
            }`}
            onClick={e => this.setDay(e.target.getAttribute("data-day"))}
          >
            Su
          </div>
        </div>
        <label className="input-label">Preferred Drop Off</label>
        <select
          className="form-control"
          name="dropOff"
          onChange={e => this.setState({ [e.target.name]: e.target.value })}
          title="Drop Off Time"
          validation={"isNotEmpty"}
        >
          <option value="" defaultValue>
            Select time
          </option>
          <option value="6:00 am">6:00 am</option>
          <option value="7:00 am">7:00 am</option>
          <option value="8:00 am">8:00 am</option>
          <option value="9:00 am">9:00 am</option>
          <option value="10:00 am">10:00 am</option>
          <option value="11:00 am">11:00 am</option>
          <option value="12:00 pm">12:00 pm</option>
          <option value="1:00 pm">1:00 pm</option>
          <option value="2:00 pm">2:00 pm</option>
          <option value="3:00 pm">3:00 pm</option>
          <option value="4:00 pm">4:00 pm</option>
          <option value="5:00 pm">5:00 pm</option>
          <option value="6:00 pm">6:00 pm</option>
          <option value="7:00 pm">7:00 pm</option>
        </select>
        <label className="input-label">Preferred Pick up time</label>
        <select
          className="form-control"
          name="pickUp"
          onChange={e => this.setState({ [e.target.name]: e.target.value })}
          validation={"isNotEmpty"}
          title="Pick Up Time"
        >
          <option value="" defaultValue>
            Select time
          </option>
          <option value="6:00 am">6:00 am</option>
          <option value="7:00 am">7:00 am</option>
          <option value="8:00 am">8:00 am</option>
          <option value="9:00 am">9:00 am</option>
          <option value="10:00 am">10:00 am</option>
          <option value="11:00 am">11:00 am</option>
          <option value="12:00 pm">12:00 pm</option>
          <option value="1:00 pm">1:00 pm</option>
          <option value="2:00 pm">2:00 pm</option>
          <option value="3:00 pm">3:00 pm</option>
          <option value="4:00 pm">4:00 pm</option>
          <option value="5:00 pm">5:00 pm</option>
          <option value="6:00 pm">6:00 pm</option>
          <option value="7:00 pm">7:00 pm</option>
        </select>
        <label className="input-label">When do you need childcare</label>
        <br />
        <input
          type="radio"
          name="childcareneed"
          value="Immediately"
          validation={"isNotEmpty"}
          onClick={e => this.clickRadio(e)}
        />{" "}
        Immediately <br />
        <input
          type="radio"
          name="childcareneed"
          value="1-3 Months"
          validation={"isNotEmpty"}
          onClick={e => this.clickRadio(e)}
        />{" "}
        1-3 Months <br />
        <input
          type="radio"
          name="childcareneed"
          value="3+ Months"
          validation={"isNotEmpty"}
          onClick={e => this.clickRadio(e)}
        />{" "}
        3+ Months
        <br />
        <div className="modal-footer">
          <button
            type="button"
            className="mx-2 backbtn btn profile-btn"
            onClick={() => this.props.back()}
          >
            Back
          </button>
          <button type="submit" className="btn profile-btn">
            Next
          </button>
        </div>
      </form>
    );
  }
}

export default SurveyUi;
