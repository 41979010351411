import React, { useState } from "reactn";
import { format } from "date-fns";

const KidInfo = ({ child }) => {
  const [tab, setTab] = useState(0);

  return (
    <div
      className="box"
      style={{
        width: "100%",
        maxWidth: 380,
        minWidth: 300,
        height: "fit-content"
      }}
    >
      <div className="bgofbox">
        <div className="header">
          <div className="row">
            <div className="col-4">
              <h2>STUDENT</h2>
            </div>
            <div className="col-8 d-flex">
              <button
                className={0 === tab ? "active" : ""}
                onClick={() => setTab(0)}
              >
                Profile
              </button>
              <button
                className={1 === tab ? "active" : ""}
                onClick={() => setTab(1)}
              >
                Additional Info
              </button>
            </div>
          </div>
        </div>
        {tab === 0 ? (
          <div className="formbody">
            <div className="row">
              <div className="col-4">
                <h4>Full Name</h4>
              </div>
              <div className="col-8">{child.fullName}</div>
            </div>
            <div className="row">
              <div className="col-4">
                <h4>Birth Date</h4>
              </div>
              <div className="col-8">
                {format(new Date(child.birthDate), "LLLL dd, yyyy")}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <h4>Gender</h4>
              </div>
              <div className="col-8">{child.gender}</div>
            </div>
            <div className="row">
              <div className="col-4">
                <h4>Languages</h4>
              </div>
              <div className="col-8">
                {child.languages.map(lang => lang.name).join(", ")}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <h4>Allergies</h4>
              </div>
              <div className="col-8">
                {child.allergies.map(allergy => allergy.name).join(", ")}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <h4>Immunized</h4>
              </div>
              <div className="col-8">{child.immunized}</div>
            </div>
            <div className="row">
              <div className="col-4">
                <h4>Medication</h4>
              </div>
              <div className="col-8">
                {child.medication.map(med => med.name).join(", ")}
              </div>
            </div>
          </div>
        ) : (
          <div className="formbody">
            <div className="row">
              <div className="col-4">
                <h4>Special Food</h4>
              </div>
              <div className="col-8">
                {child.specialFood.map(food => food.name).join(", ")}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <h4>Potty Trained</h4>
              </div>
              <div className="col-8">{child.pottyTrained}</div>
            </div>
            <div className="row">
              <div className="col-4">
                <h4>Health Concerns</h4>
              </div>
              <div className="col-8">
                {child.healthConcerns.map(item => item.name).join(", ")}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <h4>Likes</h4>
              </div>
              <div className="col-8">
                {child.likes.map(item => item.name).join(", ")}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <h4>Dislikes</h4>
              </div>
              <div className="col-8">
                {child.dislikes.map(item => item.name).join(", ")}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <h4>Comments</h4>
              </div>
              <div className="col-8">{child.comments}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KidInfo;
