import {
  format,
  addDays,
  getDay,
  differenceInDays,
  subDays,
  addWeeks
} from "date-fns";

/**
 *
 */
export const days_rev = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0
};

/**
 *
 * @param {*} time
 */
export function getTime(time) {
  const d = new Date();
  const offset = d.getTimezoneOffset() / 60; // offset in hours
  // const local_time = time - offset;
  const local_time = time;

  d.setHours(Math.floor(local_time));
  const mins = (local_time - Math.floor(local_time)) * 60;
  d.setMinutes(mins);
  return format(d, "p");
}

/**
 *
 * @param {*} hours
 */

export function getHourMins(hours) {
  return {
    hour: Math.floor(hours),
    mins: (hours - Math.floor(hours)) * 60
  };
}

/**
 *
 * @param {*} day_of_week
 * @param {*} start_hour
 * @param {*} end_hour
 */

export function getNextScheduledLesson(day_of_week, end_hour) {
  const today = new Date();
  const offset = today.getTimezoneOffset() / 60; // offset in hours
  const local_end_hour = end_hour - offset;

  const diff = day_of_week - getDay(today);
  if (diff === 0) {
    const future_time = new Date();
    const { hour, mins } = getHourMins(local_end_hour);
    future_time.setHours(hour);
    future_time.setMinutes(mins);

    if (future_time.getHours() > today.getHours()) {
      return today;
    } else {
      return addWeeks(today, 1);
    }
  } else if (diff < 0) {
    return subDays(addWeeks(today, 1), -1 * diff);
  } else {
    return addDays(today, diff);
  }
}

/**
 *
 * @param {*} days_of_week
 */
export function isDaysOfWeek(days_of_week) {
  const isThursday = days_of_week["Thursday"] ? true : false;
  const isMonday = days_of_week["Monday"] ? true : false;
  const isTuesday = days_of_week["Tuesday"] ? true : false;
  const isWednesday = days_of_week["Wednesday"] ? true : false;
  const isFriday = days_of_week["Friday"] ? true : false;
  const isSaturday = days_of_week["Saturday"] ? true : false;
  const isSunday = days_of_week["Sunday"] ? true : false;
  return {
    isMonday,
    isThursday,
    isTuesday,
    isWednesday,
    isFriday,
    isSaturday,
    isSunday
  };
}

/**
 *
 * @param {*} program
 */
export function generate_dates(program) {
  if (!program.program_start) {
    return null;
  }
  if (!program.days_of_week) {
    return null;
  }

  const start_date = new Date(program.program_start);

  let days = [];
  const {
    isMonday,
    isThursday,
    isTuesday,
    isWednesday,
    isFriday,
    isSaturday,
    isSunday
  } = isDaysOfWeek(program.days_of_week);
  const daysCount = differenceInDays(new Date(), start_date);
  for (let i = 0; i <= daysCount; i++) {
    const day = addDays(start_date, i);
    if (isMonday && getDay(day) === days_rev["Monday"]) {
      days.push(day);
    } else if (isTuesday && getDay(day) === days_rev["Tuesday"]) {
      days.push(day);
    } else if (isWednesday && getDay(day) === days_rev["Wednesday"]) {
      days.push(day);
    } else if (isThursday && getDay(day) === days_rev["Thursday"]) {
      days.push(day);
    } else if (isFriday && getDay(day) === days_rev["Friday"]) {
      days.push(day);
    } else if (isSaturday && getDay(day) === days_rev["Saturday"]) {
      days.push(day);
    } else if (isSunday && getDay(day) === days_rev["Sunday"]) {
      days.push(day);
    }
  }

  return days;
}

/**
 *
 * @param {*} schedule
 * @param {*} sorted_analytics
 */
export function merge_analytics_with_schedule(schedule, sorted_analytics) {
  //TODO: O(n^2) -> O(n)
  const ret = [];
  for (let i = 0; i < schedule.length; i++) {
    const day = schedule[i];

    const pushing_data = {
      day,
      duration: null,
      participants: null,
      start_time: null
    };
    for (let j = 0; j < sorted_analytics.length; j++) {
      const analytic = sorted_analytics[j];
      const a_day = new Date(analytic.start_time * 1000);

      if (
        day.getFullYear() === a_day.getFullYear() &&
        day.getMonth() === a_day.getMonth() &&
        day.getDay() === a_day.getDay()
      ) {
        pushing_data.participants = !pushing_data.participants
          ? analytic.participants.length
          : Math.max(analytic.participants.length, pushing_data.participants);
        pushing_data.duration = !pushing_data.duration
          ? analytic.duration
          : analytic.duration + pushing_data.duration;
        pushing_data.start_time = !pushing_data.start_time
          ? analytic.start_time
          : Math.min(analytic.start_time, pushing_data.start_time);
      }
    }
    ret.push(pushing_data);
  }

  return ret;
}
