import React from "reactn";

const EmergencyContact = ({ contacts }) => {
  return (
    <div className="formbody">
      {contacts.map((contact, i) => (
        <React.Fragment key={`${i}-contact`}>
          <div className="row">
            <div className="col-4">
              <h4>Full Name</h4>
            </div>
            <div className="col-8">{contact.fullName}</div>
          </div>
          <div className="row">
            <div className="col-4">
              <h4>Relationship</h4>
            </div>
            <div className="col-8">{contact.relationship}</div>
          </div>
          <div className="row">
            <div className="col-4">
              <h4>Address</h4>
            </div>
            <div className="col-8">{contact.address}</div>
          </div>
          <div className="row">
            <div className="col-4">
              <h4>Phone Number</h4>
            </div>
            <div className="col-8">{contact.phone}</div>
          </div>
          {i !== contacts.length - 1 ? <hr /> : null}
        </React.Fragment>
      ))}
    </div>
  );
};

export default EmergencyContact;
