import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import { MiniPlus } from "../../../../assets";
// import Geosuggest from "react-geosuggest";
import ImageCropModal from "../../../../components/modals/ImageCropModal";

class SectionTwo extends React.PureComponent {
  constructor(props) {
    super(props);
    /*this.setAddress = this.setAddress.bind(this);
    this.customForm = this.customForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._geoSuggest = null;
    this._geoSuggestPrev = null;*/
    this.state = { location: "", openImageCropModal: false, image: "" };
  }
  handleChange = (e, v) => {
    this.setGlobal({
      news_and_updates: {
        ...this.global.news_and_updates,
        [e]: v
      }
    });
  };
  uploadNew(image) {
    this.setState({ location: image });
    const uploadEle = document.getElementById("uploadPhoto");
    uploadEle.click();
  }
  deleteImage(image, field_name) {
    this.setGlobal({
      news_and_updates: {
        ...this.global.news_and_updates,
        [field_name]: this.global.news_and_updates[field_name].filter(
          e => e !== image
        )
      }
    });
    this.dispatch.deleteNewsPhoto(image);
    this.dispatch.saveNews();
  }
  fileChange(e) {
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      // const fd = new FormData();
      // fd.set("image", e.target.files[0]);
      // fd.set("location", this.state.location);
      // fd.set("oldVal", this.global.news_and_updates[this.state.location]);
      //this.dispatch.uploadNewsPhoto(fd);
      const file = new FileReader();
      file.readAsDataURL(e.target.files[0]);
      file.onloadend = () => {
        document.getElementById("uploadPhoto").value = "";
        this.setState({ image: file.result, openImageCropModal: true });
      };
    }
  }

  customForm = () => {
    const vals = this.global.myProgramLocationHours;

    return (
      <div className="formbody">
        {this.state.openImageCropModal && (
          <ImageCropModal
            sourceImage={this.state.image}
            onClose={() => {
              this.setState({ openImageCropModal: false });
            }}
            aspectRatio={
              this.state.location === "listing_image" ||
              this.state.location === "headerimage"
                ? 16 / 9
                : 1 / 1
            }
            uploadImage={croppedImageURL => {
              const fd = new FormData();
              fd.set("image", croppedImageURL);
              fd.set("location", this.state.location);
              fd.set(
                "oldVal",
                this.global.news_and_updates[this.state.location]
              );
              this.dispatch.uploadNewsPhoto(fd);
              this.setState({ openImageCropModal: false });
            }}
          />
        )}
        {/* {!this.state.timeChangeShown ? null : (
          <ConfirmTimeChangeForm onClose={this.setTime} />
        )} */}
        <form>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Listing Image<span className="requiredField"></span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <figure
                  className="photoContainer"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginLeft: "6px"
                  }}
                  onClick={_ => this.uploadNew("listing_image")}
                >
                  {this.global.news_and_updates.listing_image ? (
                    <div
                      style={{
                        padding: "2px",
                        width: "130px",
                        height: "80px",
                        border: "1px solid #e3e3e3",
                        display: "inline-block",
                        borderRadius: "8px",
                        cursor: "pointer",
                        backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${this.global.news_and_updates.listing_image})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat"
                      }}
                    ></div>
                  ) : (
                    <div
                      style={{
                        padding: "2px",
                        width: "130px",
                        height: "80px",
                        border: "1px solid #e3e3e3",
                        display: "inline-block",
                        borderRadius: "8px",
                        cursor: "pointer"
                      }}
                    >
                      <img
                        src={MiniPlus}
                        alt="Add"
                        style={{ marginTop: "calc(50% - 4.5px)" }}
                      />
                    </div>
                  )}
                </figure>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Date <span style={{ color: "orange" }}>*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <formTypes.Date
                name="date"
                value={this.global.news_and_updates.date}
                onChange={this.handleChange}
                className="w-100"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Header Image</h3>
            </div>
            <div className="col-7 d-flex">
              <figure
                className="photoContainer"
                style={{
                  width: "25%",
                  textAlign: "center",
                  marginLeft: "6px"
                }}
                onClick={_ => this.uploadNew("headerimage")}
              >
                {this.global.news_and_updates.headerimage ? (
                  <div
                    style={{
                      padding: "2px",
                      width: "200px",
                      height: "80px",
                      border: "1px solid #e3e3e3",
                      display: "inline-block",
                      borderRadius: "8px",
                      cursor: "pointer",
                      backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${this.global.news_and_updates.headerimage})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat"
                    }}
                  ></div>
                ) : (
                  <div
                    style={{
                      padding: "2px",
                      width: "200px",
                      height: "80px",
                      border: "1px solid #e3e3e3",
                      display: "inline-block",
                      borderRadius: "8px",
                      cursor: "pointer"
                    }}
                  >
                    <img
                      src={MiniPlus}
                      alt="Add"
                      style={{ marginTop: "calc(50% - 4.5px)" }}
                    />
                  </div>
                )}
              </figure>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Middle Gallery</h3>
            </div>
            <div className="col-12 d-flex">
              {this.global.news_and_updates.middlegallery.map((item, index) => {
                return (
                  <figure
                    className="photoContainer"
                    style={{
                      width: "23%",
                      textAlign: "center",
                      marginLeft: "6px",
                      height: "80px"
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        padding: "2px",
                        width: "80px",
                        height: "80px",
                        border: "1px solid #e3e3e3",
                        display: "inline-block",
                        borderRadius: "8px",
                        cursor: "pointer",
                        backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${item})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat"
                      }}
                      id={index}
                    >
                      <div className="photoContainerX">
                        <div
                          className="innerX"
                          onClick={() =>
                            this.deleteImage(item, "middlegallery")
                          }
                        >
                          {" "}
                          {/*TODO: onClick delete image to happen here*/}
                          <i className="far fa-times-circle"></i>
                        </div>
                      </div>
                    </div>
                  </figure>
                );
              })}
              {new Array(4 - this.global.news_and_updates.middlegallery.length)
                .fill(4)
                .map((e, i) => (
                  <figure
                    className="photoContainer"
                    style={{
                      width: "25%",
                      textAlign: "center",
                      marginLeft: "6px"
                    }}
                    onClick={_ => this.uploadNew("middlegallery")}
                    key={i}
                  >
                    <div
                      style={{
                        padding: "2px",
                        width: "80px",
                        height: "80px",
                        border: "1px solid #e3e3e3",
                        display: "inline-block",
                        borderRadius: "8px",
                        cursor: "pointer"
                      }}
                    >
                      <img
                        src={MiniPlus}
                        alt="Add"
                        style={{ marginTop: "calc(50% - 4.5px)" }}
                      />
                    </div>
                  </figure>
                ))}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="label-names">Bottom Gallery</h3>
            </div>
            <div className="col-12 d-flex">
              {this.global.news_and_updates.bottomgallery.map((item, index) => {
                return (
                  <figure
                    className="photoContainer"
                    style={{
                      width: "23%",
                      textAlign: "center",
                      marginLeft: "6px",
                      height: "80px"
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        padding: "2px",
                        width: "80px",
                        height: "80px",
                        border: "1px solid #e3e3e3",
                        display: "inline-block",
                        borderRadius: "8px",
                        cursor: "pointer",
                        backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${item})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat"
                      }}
                      id={index}
                    >
                      <div className="photoContainerX">
                        <div
                          className="innerX"
                          onClick={() =>
                            this.deleteImage(item, "bottomgallery")
                          }
                        >
                          {" "}
                          {/*TODO: onClick delete image to happen here*/}
                          <i className="far fa-times-circle"></i>
                        </div>
                      </div>
                    </div>
                  </figure>
                );
              })}
              {new Array(4 - this.global.news_and_updates.bottomgallery.length)
                .fill(4)
                .map((e, i) => (
                  <figure
                    className="photoContainer"
                    style={{
                      width: "25%",
                      textAlign: "center",
                      marginLeft: "6px"
                    }}
                    onClick={_ => this.uploadNew("bottomgallery")}
                    key={i}
                  >
                    <div
                      style={{
                        padding: "2px",
                        width: "80px",
                        height: "80px",
                        border: "1px solid #e3e3e3",
                        display: "inline-block",
                        borderRadius: "8px",
                        cursor: "pointer"
                      }}
                    >
                      <img
                        src={MiniPlus}
                        alt="Add"
                        style={{ marginTop: "calc(50% - 4.5px)" }}
                      />
                    </div>
                  </figure>
                ))}
              <input
                type="file"
                style={{ display: "none" }}
                id="uploadPhoto"
                onChange={e => this.fileChange(e)}
                accept="image/x-png,image/gif,image/jpeg"
              />
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Listing Details"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionTwo;
