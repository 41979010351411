import ApplicationsTable from "./ApplicationTable";
import TourRequestTable from "./TourRequestTable";
import FamilyOverviewTable from "./FamilyOverviewTable";
import ExpenseOverviewTable from "./ExpenseOverviewTable";
import ConnectionsTable from "./ConnectionsTable";

const Educators = {
  ApplicationsTable: ApplicationsTable,
  TourRequestTable: TourRequestTable,
  FamilyOverviewTable: FamilyOverviewTable,
  ExpenseOverviewTable: ExpenseOverviewTable,
  ConnectionsTable: ConnectionsTable
};

export default Educators;
