import React, { Component } from "reactn";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { formTypes } from "../../components/UI/form";
import ReactTooltip from "react-tooltip";
import { Switch } from "@material-ui/core";
import { filter } from "lodash";
import BaseModal from "./baseModal/BaseModal";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { DROPDOWN, SWITCH, TEXT_AREA } from "./baseModal/FieldTypes";

const PERCENT = "Percentage";
const FIXED_AMOUNT = "Fixed Amount";
class InviteConnectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name,
      email: this.props.email,
      phone: "",
      programEnrollments: [],
      eventEnrollments: [],
      membershipEnrollments: [],
      onlineVirtualEnrollments: [],
      plans: [],
      planList: [],
      programs: [],
      events: [],
      parties: [],
      onlineVirtuals: [],
      memberships: [],
      forms: [],
      isMessage: false,
      ticket: null,
      amount: null,
      isStartSet: false,
      recurringCustomerSelectedDays: [],
      isWaiveDeposit: false,
      table: null,
      formId: "",
      form: "",
      programId: "",
      program: "",
      isPromoPlanSelected: false,
      promotion: {},
      startDate: null,
      selectedUser: null,
      selectedPlan: "",
      planSelect: "",
      message: "",
      advanced: false,
      isSkipForm: false,
      sendUserFormDetails: true,
      receiveUserFormDetails: true,
      singleSessionQuantity: 1
    };
  }

  async submitForm(e) {
    e.preventDefault();

    if (
      this.state.table !== "partner_forms" &&
      (this.state.program === "" ||
        this.state.type === "" ||
        this.state.selectedPlan === "")
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    if (
      this.state.table === "partner_forms" &&
      (this.state.form === "" || this.state.type === "")
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    const endp = this.state.isPromoPlanSelected
      ? `${process.env.REACT_APP_API}/partners/users/promo/invite_parent`
      : `${process.env.REACT_APP_API}/partners/users/invite_parent`;

    console.log(
      "AddEnrollmentModal.jsx 104: amount passed in request",
      this.state.amount
    );
    let result;
    result = await axios.post(endp, {
      name: this.state.name,
      email: this.state.email,
      form: this.state.form ? this.state.form.id : null,
      form_name: `${this.state.form.form_name}: ${this.state.form.form_sub_name}`,
      program: this.state.program.id,
      program_name:
        this.state.program.program_name ||
        this.state.program.event_title ||
        this.state.program.membership_name,
      plan: this.state.selectedPlan.split(" ")[0],
      modifier: this.state.selectedPlan.split(" ")[1],
      selectedUser: this.state.selectedUser,
      type: this.state.table === "partner_event" ? "event" : null,
      isParent: this.state.program.addChild,
      amount: this.state.amount,
      ticket: this.state.ticket,
      product_table: this.state.table,
      promotion: this.state.isPromoPlanSelected
        ? this.state.promotion[0]
        : null,
      startDate: this.state.startDate,
      recurringCustomerSelectedDays: this.state.recurringCustomerSelectedDays,
      isSkipForm: this.state.isSkipForm,
      isWaiveDeposit: this.state.isWaiveDeposit,
      isStartSet: this.state.isStartSet,
      message: this.state.message !== "" ? this.state.message : null,
      singleSessionQuantity: this.state.singleSessionQuantity,
      sendUserFormDetails: this.state.sendUserFormDetails,
      receiveUserFormDetails: this.state.receiveUserFormDetails
    });
    console.log("result", result);

    if (result.data.success) {
      toast.success("User Invite Successful!");
      this.props.onClose();
    } else {
      toast.error(`User Invite Failed.`);
    }
  }
  /*
  async invite_connection(e) {
    e.preventDefault();

    if (!this.state.type) {
      toast.error(`Select the invitation type.`);
      return;
    }

    if (this.state.isMessage && this.state.message.length == 0) {
      toast.error(`Enter a message when enabled!`);
      return;
    }

    let program = {};
    if (this.state.type === "Programs") {
      program = await this.state.programs.filter(
        f => f.id == this.state.selectedOption
      )[0];
    }
    if (this.state.type === "Online Virtual") {
      program = await this.state.onlineVirtuals.filter(
        f => f.id == this.state.selectedOption
      )[0];
    }
    if (this.state.type === "Events") {
      program = await this.state.events.filter(
        f => f.id == this.state.selectedOption
      )[0];
    }

    const ep = `${process.env.REACT_APP_API}/partners/connections/invite`;
    const res = await axios.post(ep, {
      selectedPlan: this.state.selectedPlan,
      isMessage: this.state.isMessage,
      orgName: this.state.orgData.organization_title,
      message: this.state.message,
      program: program,
      programName: program.program_name || program.event_title,
      plans: this.state.plans,
      plan: this.state.plans[this.state.selectedPlan],
      amount: this.state.amount,
      ticket: this.state.ticket,
      sendEmail: this.props.email,
      userName: this.props.name,
      isGeneral: this.state.type === "General" ? true : false,
      orgSlug: this.state.orgData.slug,
      selectedProgram: this.state.selectedOption,
      type:
        this.state.type === "Programs"
          ? "program"
          : this.state.type === "Online Virtual"
          ? "virtual"
          : "event"
    });

    if (res.data.success) {
      toast.success(`Connection Invited!`);
      this.props.addOne(res.data.data);
      this.props.onClose();
    } else {
      toast.error(`Connection email already invited!`);
    }
  }
  */

  async fetchInitialData() {
    this.setGlobal({ loading: true });

    const ep = `${process.env.REACT_APP_API}/partners/organization_profile/preview`;
    const results = await axios.get(ep);

    if (results.data.success) {
      // Setting the programs data
      const programIds = [];
      console.log(
        "ProgramData",
        results.data.data.programs.filter(f => !f.isArchived)
      );
      const programData = results.data.data.programs
        ? results.data.data.programs
            .filter(f => !f.isArchived)
            .map(e => {
              programIds.push(e.id);
              console.log("Program plans", e.plans);
              const parsePlans = e.plans
                ? e.plans.map(t => JSON.parse(t))
                : null;
              e.plans = parsePlans;
              return e;
            })
        : [];

      this.setState({ programs: programData, orgData: results.data.data.data });

      // Fetching the customer enrollments for programs
      console.log("ProgramIds", programIds);
      const programEnrollmentData = async () => {
        return Promise.all(
          programIds.map(async p => {
            const customers_ep = `${process.env.REACT_APP_API}/partners/programs/customers/communications/${p}`;
            const customers = await axios.get(customers_ep);

            if (customers.data.success && customers.data.data) {
              console.log("Program Customer", customers.data.data);
              customers.data.data.map((e, i) => {
                const find = this.state.programEnrollments.filter(
                  f => f.programId === e.programId && f.dsUserId === e.dsUserId
                );

                if (find.length === 0) {
                  this.state.programEnrollments.push(e);
                } else {
                  return;
                }
                return;
              });
              // setProgramEnrollments(customerData);
            }
          })
        );
      };
      programEnrollmentData();

      // Setting the events data
      const eventIds = [];
      console.log(
        "EventData",
        results.data.data.events.filter(f => !f.isArchived)
      );
      const eventData = results.data.data.events
        ? results.data.data.events
            .filter(f => !f.isArchived)
            .map(e => {
              eventIds.push(e.id);
              console.log("Event Plans", e.tickets);
              const parseTicket = e.tickets
                ? e.tickets.map(t => JSON.parse(t))
                : null;
              e.tickets = parseTicket;
              return e;
            })
        : [];

      this.setState({ events: eventData });

      // Fetching the customer enrollments for programs
      console.log("EventIds", eventIds);
      const eventEnrollmentData = async () => {
        return Promise.all(
          eventIds.map(async p => {
            const customers_ep = `${process.env.REACT_APP_API}/partners/events/customers/communications/${p}`;
            const customers = await axios.get(customers_ep);
            if (customers.data.success && customers.data.data) {
              console.log("Event Customers", customers.data.data);
              customers.data.data.map((e, i) => {
                const find = this.state.eventEnrollments.filter(
                  f => f.programId === e.programId && f.dsUserId === e.dsUserId
                );
                if (find.length === 0) {
                  this.state.eventEnrollments.push(e);
                } else {
                  return;
                }
                return;
              });
              // setProgramEnrollments(customerData);
            }
          })
        );
      };
      eventEnrollmentData();

      // Setting the memberships data
      const membershipIds = [];
      console.log(
        "ProgramData",
        results.data.data.memberships.filter(f => !f.isArchived)
      );
      const membershipData = results.data.data.memberships
        ? results.data.data.memberships
            .filter(f => !f.isArchived)
            .map(e => {
              membershipIds.push(e.id);
              console.log("Membership Plans", e.prices);
              const parseMembershipPrices = e.prices
                ? e.prices.map(t => JSON.parse(t))
                : null;
              e.prices = parseMembershipPrices;
              return e;
            })
        : [];

      this.setState({ memberships: membershipData });

      // Fetching the customer enrollments for programs
      console.log("MembershipIds", membershipIds);
      const membershipEnrollmentData = async () => {
        return Promise.all(
          membershipIds.map(async p => {
            const customers_ep = `${process.env.REACT_APP_API}/partners/memberships/customers/communications/${p}`;
            const customers = await axios.get(customers_ep);
            if (customers.data.success && customers.data.data) {
              console.log("Membership Customers", customers.data.data);
              customers.data.data.map((e, i) => {
                const find = this.state.membershipEnrollments.filter(
                  f => f.programId === e.programId && f.dsUserId === e.dsUserId
                );
                if (find.length === 0) {
                  this.state.membershipEnrollments.push(e);
                } else {
                  return;
                }
                return;
              });
              // setProgramEnrollments(customerData);
            }
          })
        );
      };
      membershipEnrollmentData();

      // Setting Online Data
      const onlineIds = [];
      console.log(
        "OnlineData",
        results.data.data.virtual.filter(f => !f.isArchived)
      );
      const onlineVirtualData = results.data.data.virtual
        ? results.data.data.virtual
            .filter(f => !f.isArchived)
            .map(e => {
              onlineIds.push(e.id);
              console.log("Virutal Plans", e.plans);
              const parsePlans = e.plans
                ? e.plans.map(t => JSON.parse(t))
                : null;
              e.plans = parsePlans;
              return e;
            })
        : [];

      this.setState({ onlineVirtuals: onlineVirtualData });

      // Fetching the customer enrollments for online
      console.log("OnlineIds", onlineIds);
      const onlineEnrollmentData = async () => {
        return Promise.all(
          onlineIds.map(async p => {
            const customers_ep = `${process.env.REACT_APP_API}/partners/virtual/customers/communications/${p}`;
            const customers = await axios.get(customers_ep);
            if (customers.data.success && customers.data.data) {
              console.log("Online Customers", customers.data.data);
              customers.data.data.map((e, i) => {
                const find = this.state.onlineVirtualEnrollments.filter(
                  f => f.programId === e.programId && f.dsUserId === e.dsUserId
                );
                if (find.length === 0) {
                  this.state.onlineVirtualEnrollments.push(e);
                } else {
                  return;
                }
                return;
              });
              // setProgramEnrollments(customerData);
            }
          })
        );
      };
      onlineEnrollmentData();

      // Make program, online virtual, event lists for dropdown.
      const programList = [];
      if (this.state.programs) {
        console.log("this.state.programs", this.state.programs);
        this.state.programs.map(e => {
          programList.push({ value: e.id, text: e.program_name });
        });
      }

      const onlineList = [];
      if (this.state.onlineVirtuals) {
        console.log("this.state.onlineVirtual", this.state.onlineVirtuals);
        this.state.onlineVirtuals.map(e => {
          onlineList.push({ value: e.id, text: e.program_name });
        });
      }

      const eventList = [];
      if (this.state.events) {
        console.log("this.state.events", this.state.events);
        this.state.events.map(e => {
          eventList.push({ value: e.id, text: e.event_title });
        });
      }

      // Make form list for dropdown
      const formList = [];

      const formEp = `${process.env.REACT_APP_API}/partners/forms/list`;
      const form_result = await axios.get(formEp);
      this.setState({ forms: form_result.data.data });

      if (form_result.data.data) {
        form_result.data.data.map(form => {
          if (
            form.form_name !== "Registration" &&
            form.form_name !== "Application" &&
            form.published
          ) {
            formList.push({
              value: form.id,
              text: `${form.form_name}: ${form.form_sub_name}`
            });
          }
        });
      }

      const userEp = `${process.env.REACT_APP_API}/partners/users/user/${this.props.email}`;
      const user_result = await axios.get(userEp);

      this.setState({
        programList,
        onlineList,
        eventList,
        formList,
        selectedUser: user_result.data.data ? user_result.data.data.id : null
      });
    }

    this.setGlobal({ loading: false });
  }

  async componentDidMount() {
    await this.fetchInitialData();
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/forms/contact-us`;
    const results = await axios.get(ep);

    if (results.data.success) {
      this.setState({ contactForm: results.data.data[0] });
    }
    this.setGlobal({ loading: false });
  }

  setForm = async id => {
    const form = this.state.forms.find(e => e.id === parseInt(id));
    this.setState({ form: form });
  };

  setProgram = async id => {
    let program;
    if (this.state.table === "partner_programs") {
      program = this.state.programs.find(e => e.id === parseInt(id));
      this.setState({ program: program });
    } else if (this.state.table === "partner_online") {
      program = this.state.onlineVirtuals.find(e => e.id === parseInt(id));
      this.setState({ program: program });
    } else if (this.state.table === "partner_event") {
      program = this.state.events.find(e => e.id === parseInt(id));
      this.setState({ program: program });
    }

    let plans;

    if (
      this.state.table === "partner_programs" ||
      this.state.table === "partner_online"
    ) {
      plans = program.plans;
    } else if (this.state.table === "partner_event") {
      plans = program.tickets;
    }

    let promotionType;
    if (this.state.table === "partner_programs") {
      promotionType = "program";
    } else if (this.state.table === "partner_online") {
      promotionType = "online";
    } else if (this.state.table === "partner_event") {
      promotionType = "event";
    }

    const ep = `${
      process.env.REACT_APP_API
    }/find/partners/promotions/${promotionType}/${parseInt(id)}`;
    const results = await axios.get(ep);

    const promoPlan = [];
    if (
      results.data.data.length > 0 &&
      results.data.data[0].plan_price_ticketId
    ) {
      results.data.data[0].plan_price_ticketId.map(e =>
        promoPlan.push({ ...plans[e], isPromo: true })
      );
    }
    plans = [...plans, ...promoPlan];
    this.setState({
      plans: plans,
      promotion: results.data.data,
      hasPromotion: results.data.data.length === 0 ? false : true
    });
  };

  getPlans = () => {
    const options = [];
    const plans = this.state.plans;

    if (plans.length === 0) {
      return options;
    }

    console.log(
      "plans",
      plans,
      plans.filter(f =>
        typeof f === "string" ? !JSON.parse(f).archived : !f.archived
      )
    );
    plans
      .filter(f =>
        typeof f === "string" ? !JSON.parse(f).archived : !f.archived
      )
      .map((ele, i) => {
        const e = typeof ele === "string" ? JSON.parse(ele) : ele;
        if (
          this.state.table === "partner_programs" ||
          this.state.table === "partner_online"
        ) {
          if (e.type) {
            if (e.isPromo) {
              options.push({
                value: `${e.id}promo`,
                text: `Semester - $${parseFloat(e.total_price).toFixed(2)} ${
                  this.state.promotion[0].discount_type === PERCENT
                    ? `${this.state.promotion[0].discount_percent} % Off`
                    : ""
                }
                ${
                  this.state.promotion[0].discount_type === FIXED_AMOUNT
                    ? `${Math.max(
                        0,
                        this.state.promotion[0].discount_fixed / 100
                      ).toFixed(2)} Off`
                    : ""
                }`
              });
            } else {
              options.push({
                value: e.id,
                text:
                  e.active === false
                    ? `Semester - $${parseFloat(e.total_price).toFixed(
                        2
                      )} (Inactive)`
                    : `Semester - $${parseFloat(e.total_price).toFixed(2)}`
              });
            }
          } else {
            if (e.isPromo) {
              options.push({
                text: `Recurring -{" "}$${parseFloat(e.tuition_rate).toFixed(
                  2
                )} - ${e.days_per_week} days per week. ${
                  this.state.promotion[0].discount_type === PERCENT
                    ? `${this.state.promotion[0].discount_percent} % Off`
                    : ""
                }
                ${
                  this.state.promotion[0].discount_type === FIXED_AMOUNT
                    ? `${Math.max(
                        0,
                        this.state.promotion[0].discount_fixed / 100
                      ).toFixed(2)} Off`
                    : ""
                }`,
                value: `${e.id}promo`
              });
            } else {
              options.push({
                text: `Recurring -${" "}$${parseFloat(e.tuition_rate).toFixed(
                  2
                )} - ${e.days_per_week} days per week. ${
                  e.active === false ? " (Inactive)" : ""
                }`,
                value: e.id
              });
            }
          }
        } else if (this.state.table === "partner_event") {
          if (e.isPromo) {
            options.push({
              text: `${e.ticket_status} ticket - $${e.ticket_price} ${
                this.state.promotion[0].discount_type === PERCENT
                  ? `${this.state.promotion[0].discount_percent} % Off`
                  : ""
              }
                ${
                  this.state.promotion[0].discount_type === FIXED_AMOUNT
                    ? `${Math.max(
                        0,
                        this.state.promotion[0].discount_fixed / 100
                      ).toFixed(2)} Off`
                    : ""
                }`,
              value: `${e.id}promo`
            });
          } else {
            options.push({
              text:
                e.active === false
                  ? `${e.ticket_status} Ticket - $${e.ticket_price} (Inactive)`
                  : `${e.ticket_status} Ticket - $${e.ticket_price}`,
              value: e.id
            });
          }
        }
      });

    return options;
  };

  setPrice = planId => {
    console.log("planId", planId);
    if (planId === "") {
      this.setState({
        amount: null,
        ticket: null
      });
      return;
    }
    const plans =
      this.state.table === "partner_event"
        ? this.state.program.tickets
        : this.state.program.plans;
    console.log("plans", plans);

    let selectedPlan;
    if (plans) {
      console.log("plans2", plans);
      selectedPlan = plans
        .map((ele, i) => {
          return typeof ele === "string" ? JSON.parse(ele) : ele;
        })
        .filter(f => f.id === parseInt(planId))[0];

      console.log("selectedPlan", selectedPlan);
      this.setState({
        amount: selectedPlan.total_price
          ? selectedPlan.total_price
          : selectedPlan.tuition_rate
          ? selectedPlan.tuition_rate
          : null,
        ticket: selectedPlan.ticket_price
          ? selectedPlan.ticket_price
          : selectedPlan.ticket_status === "Free"
          ? "Free"
          : null
      });
    }
  };

  getBaseModalFields = () => {
    const fields = [];

    const typeField = {
      type: DROPDOWN,
      data: {
        name: "Type",
        required: true,
        placeholder: "Please Select...",
        value: this.state.table,
        handleChange: e => {
          this.setState({
            table: e.target.value,
            programId: "",
            program: "",
            selectedPlan: "",
            planSelect: ""
          });
        },
        choices: this.props.isActive
          ? [
              { value: "partner_programs", text: "Program" },
              { value: "partner_online", text: "Online Virtual" },
              { value: "partner_event", text: "Event" },
              { value: "partner_forms", text: "Form" }
            ]
          : [
              { value: "partner_programs", text: "Program" },
              { value: "partner_online", text: "Online Virtual" },
              { value: "partner_event", text: "Event" }
            ]
      }
    };

    const selectField = {
      type: DROPDOWN,
      data: {
        name: "Select",
        required: true,
        isDisabled: this.state.table === "",
        placeholder: "Please Select...",
        value:
          this.state.table === "partner_forms"
            ? this.state.formId
            : this.state.programId,
        handleChange: e => {
          this.setState({ selectedPlan: "", planSelect: "", formId: "" });
          if (this.state.table !== "partner_forms") {
            this.setState({ programId: e.target.value });
            if (e.target.value !== "") {
              this.setProgram(e.target.value);
            } else {
            }
          } else {
            this.setState({ formId: e.target.value });
            if (e.target.value !== "") {
              this.setForm(e.target.value);
            }
          }
        },
        choices: this.state.table
          ? this.state.table === "partner_programs"
            ? this.state.programList
            : this.state.table === "partner_online"
            ? this.state.onlineList
            : this.state.table === "partner_event"
            ? this.state.eventList
            : this.props.isActive && this.state.table === "partner_forms"
            ? this.state.formList
            : []
          : []
      }
    };

    let planField;
    if (this.state.table !== "partner_forms" || true) {
      planField = {
        type: DROPDOWN,
        data: {
          name: "Pricing Plan",
          required: true,
          isDisabled: this.state.programId === "",
          placeholder: "Please Select...",
          value: this.state.planSelect,
          handleChange: e => {
            const matched = e.target.value.match(/[a-z]/g);
            const isPromo = !matched ? false : true;
            const selectedOption = e.target.value.replace(/[a-z]/g, "");

            this.setState({
              planSelect: e.target.value,
              selectedPlan: selectedOption.trim(),
              isPromoPlanSelected: isPromo
            });
            this.setPrice(selectedOption.trim());
          },
          choices: this.getPlans()
        }
      };
    }

    const singleSessionQuantityField = {
      type: DROPDOWN,
      data: {
        name: "Quantity",
        required: true,
        placeholder: "Please Select",
        value: this.state.singleSessionQuantity,
        handleChange: e => {
          this.setState({ singleSessionQuantity: e.target.value });
        },
        choices: [
          {
            value: 1,
            text: "1"
          },
          {
            value: 2,
            text: "2"
          },
          {
            value: 3,
            text: "3"
          },
          {
            value: 4,
            text: "4"
          },
          {
            value: 5,
            text: "5"
          },
          {
            value: 6,
            text: "6"
          },
          {
            value: 7,
            text: "7"
          },
          {
            value: 8,
            text: "8"
          },
          {
            value: 9,
            text: "9"
          },
          {
            value: 10,
            text: "10"
          }
        ]
      }
    };

    const messageField = {
      type: TEXT_AREA,
      data: {
        name: "Message (optional)",
        placeholder: "Enter a message...",
        value: this.state.message,
        handleChange: e => {
          this.setState({ message: e.target.value });
        }
      }
    };

    const sendUserFormDetails = {
      type: SWITCH,
      data: {
        name: "User to receive their Form Submission details via email",
        checked: this.state.sendUserFormDetails,
        handleClick: e => {
          this.setState({
            sendUserFormDetails: !this.state.sendUserFormDetails
          });
        }
      }
    };

    const receiveUserFormDetails = {
      type: SWITCH,
      data: {
        name: "Organizer (Admin) to receive a user's Form Submission details via email",
        checked: this.state.receiveUserFormDetails,
        handleClick: e => {
          this.setState({
            receiveUserFormDetails: !this.state.receiveUserFormDetails
          });
        }
      }
    };

    const advancedField = {
      type: SWITCH,
      data: {
        name: "Advanced",
        checked: this.state.advanced,
        handleClick: e => {
          this.setState({ advanced: !this.state.advanced, isSkipForm: false });
        }
      }
    };

    const skipFormField = {
      type: SWITCH,
      data: {
        name: "Skip Form / Application",
        checked: this.state.isSkipForm,
        handleClick: e => {
          this.setState({ isSkipForm: !this.state.isSkipForm });
        }
      }
    };

    fields.push(typeField);
    fields.push(selectField);
    if (this.state.table !== "partner_forms") {
      fields.push(planField);
      if (this.state.program.program_category === "Single Sessions") {
        fields.push(singleSessionQuantityField);
      }
    }
    fields.push(messageField);
    if (this.state.table === "partner_forms") {
      fields.push(sendUserFormDetails);
      fields.push(receiveUserFormDetails);
    }
    if (this.state.table !== "partner_forms") {
      fields.push(advancedField);
      if (this.state.advanced) {
        fields.push(skipFormField);
      }
    }

    return fields;
  };

  getBaseModalButtons = () => {
    const inviteButton = {
      name: "Invite",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      handleClick: e => this.submitForm(e)
    };

    return [inviteButton];
  };

  getBaseModalProps = () => {
    return {
      title: `Invite Connection`,
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    // console.log("this.state", this.state);
    // console.log("this.props", this.props);
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>Invite Connection</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  color: "#999999",
                  fontSize: ".8rem",
                  marginBottom: "20px"
                }}
              >
                Enter a message along with the invite.
              </div>
            </div>
            <form onSubmit={e => this.invite_connection(e)}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="type"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Type <span style={{ color: "orange" }}>*</span>
                </label>
                <select
                  name="type"
                  id="type"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                >
                  <option value="">Please Select</option>
                  <option value="General">General</option>
                  <option value="Programs">Programs</option>
                  <option value="Online Virtual">Online Virtual</option>
                  <option value="Events">Events</option>
                </select>
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="select"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Select <span style={{ color: "orange" }}>*</span>
                </label>
                <select
                  disabled={this.state.type === "General" ? true : false}
                  style={{ width: "70%" }}
                  className="form-control"
                  onChange={e => {
                    let plans = [];
                    if (this.state.type === "Programs") {
                      plans = this.state.programs.filter(
                        f => f.id == e.target.value
                      )[0].plans;
                    }
                    if (this.state.type === "Online Virtual") {
                      plans = this.state.onlineVirtuals.filter(
                        f => f.id == e.target.value
                      )[0].plans;
                    }
                    if (this.state.type === "Events") {
                      plans = this.state.events.filter(
                        f => f.id == e.target.value
                      )[0].tickets;
                    }

                    this.setState({
                      selectedOption: e.target.value,
                      plans: plans
                    });
                  }}
                >
                  <option>Please Select</option>
                  {/* {this.state.type === "General" && (
                              <option value="General">General</option>
                            )} */}
                  {this.state.type === "Programs" &&
                    this.state.programs.map(e => {
                      return <option value={e.id}>{e.program_name}</option>;
                    })}
                  {this.state.type === "Events" &&
                    this.state.events.map(e => {
                      return <option value={e.id}>{e.event_title}</option>;
                    })}
                  {/* {this.state.type === "Memberships" &&
                              this.state.memberships.map(e => {
                                return (
                                  <option value={e.id}>
                                    {e.membership_name}
                                  </option>
                                );
                              })} */}
                  {this.state.type === "Online Virtual" &&
                    this.state.onlineVirtuals.map(e => {
                      return <option value={e.id}>{e.program_name}</option>;
                    })}
                </select>
              </div>

              {/* <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="type"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Price Plan{" "}
                  <i
                    data-tip
                    data-for="durationTip"
                    className="fas fa-info-circle"
                    style={{ color: "#DCDCDC" }}
                  ></i>
                  <ReactTooltip
                    id="durationTip"
                    place="top"
                    effect="solid"
                    type="info"
                  >
                    Plan
                  </ReactTooltip>
                </label>


                <select
                    id="weirdSelect"
                    style={{ width: "70%" }}
                    name="selectedPlan"
                    disabled={this.state.type === "General" ? true : false}
                    onChange={e => {

                      const index = parseInt(e.target.value.trim())

                      console.log('prog', this.state.programs.filter(f => f.id == parseInt(this.state.selectedOption))[0].plans[index])


                      let ticket = null;
                      let amount = null;
                      if (this.state.type === "Events") {
                        const parsedPlan = 
                          this.state.events.filter(f => f.id == parseInt(this.state.selectedOption))[0].tickets[index]
                        ;

                        ticket =
                          parsedPlan.ticket_status === "Free"
                            ? "Free"
                            : parsedPlan.ticket_price;
                      }
                      if (this.state.type === "Programs") {
                        const parsedPlan =
                          this.state.programs.filter(f => f.id == parseInt(this.state.selectedOption))[0].plans[index];

                        if (parsedPlan.type) {
                          if (!parsedPlan.installments && !parsedPlan.dropIns) {
                            amount = parseFloat(parsedPlan.total_price);
                          } else {
                            amount = parseFloat(parsedPlan.total_price);
                          }

                          if (e.target.value.includes("Installments")) {
                            amount = parseFloat(
                              parsedPlan.total_price *
                                (1 +
                                  parseInt(parsedPlan.installments_interest) /
                                    100)
                            ).toFixed(2);
                          }
                          if (e.target.value.includes("Drop-In")) {
                            amount = parseFloat(
                              (parsedPlan.total_price / parsedPlan.dayCount) *
                                (1 + parseInt(parsedPlan.dropin_interest) / 100)
                            ).toFixed(2);
                          }
                        } else {
                          // Recurring
                          amount = parsedPlan.tuition_rate;
                        }
                      }
                      if (this.state.type === "Online Virtual") {
                        const parsedPlan =
                          this.state.onlineVirtuals.filter(f => f.id == parseInt(this.state.selectedOption))[0].plans[
                            index
                          ]
                        ;

                        if (parsedPlan.type) {
                          if (!parsedPlan.installments && !parsedPlan.dropIns) {
                            amount = parseFloat(parsedPlan.total_price);
                          } else {
                            amount = parseFloat(parsedPlan.total_price);
                          }

                          if (e.target.value.includes("Installments")) {
                            amount = parseFloat(
                              parsedPlan.total_price *
                                (1 +
                                  parseInt(parsedPlan.installments_interest) /
                                    100)
                            ).toFixed(2);
                          }
                          if (e.target.value.includes("Drop-In")) {
                            amount = parseFloat(
                              (parsedPlan.total_price / parsedPlan.dayCount) *
                                (1 + parseInt(parsedPlan.dropin_interest) / 100)
                            ).toFixed(2);
                          }
                        } else {
                          // Recurring
                          amount = parsedPlan.tuition_rate;
                        }
                      }
                     
                      this.setState({
                        [e.target.name]: e.target.value.trim(),
                        amount: amount,
                        ticket: ticket
                      });
                    }}
                    className="form-control"
                  >
                    <option value="">
                      Please choose
                    </option>
                    {this.state.plans
                      .filter(f => !f.archived)
                      .map((ele, i) => {
                        const e = typeof ele === "string" ? JSON.parse(ele) : ele;
                        if (this.state.type === "Programs" || this.state.type === "Online Virtual") {
                          if (e.type) {
                            const options = [];
                            options.push({
                              value: e.id || i,
                              text: `Semester - $${parseFloat(
                                e.total_price
                              ).toFixed(2)}`,
                              type: ""
                            });
                            if (e.installments)
                              options.push({
                                value: e.id || i,
                                onClick: _ =>
                                  this.setState({ installments: true }),
                                text: `Semester Installments - $${parseFloat(
                                  e.total_price *
                                    (1 +
                                      parseInt(e.installments_interest) / 100)
                                ).toFixed(2)}`,
                                type: "Installments"
                              });
                            if (e.dropIns)
                              options.push({
                                value: e.id || i,
                                onClick: _ => this.setState({ dropIns: true }),
                                text: `Semester Drop-In - $${parseFloat(
                                  (e.total_price / e.dayCount) *
                                    (1 + parseInt(e.dropin_interest) / 100)
                                ).toFixed(2)} per day.`,
                                type: "Drop-In"
                              });
                            return options.map(option => {
                              if (option.onClick)
                                return (
                                  <option
                                    value={option.value + " " + option.type}
                                    onClick={option.onClick}
                                    key={i + option.type}
                                  >
                                    {option.text}
                                  </option>
                                );
                              else
                                return (
                                  <option
                                    value={option.value + " " + option.type}
                                    key={i + option.type}
                                  >
                                    {option.text}
                                  </option>
                                );
                            });
                          } else {
                            return (
                              <option value={e.id || i} key={i}>
                                Recurring -{" "}
                                {`$${parseFloat(e.tuition_rate).toFixed(2)} - ${
                                  e.days_per_week
                                } days per week.`}
                              </option>
                            );
                          }
                        } else if (this.state.type === "Events") {
                          return (
                            <option value={e.id || i} key={i}>
                              {`${e.ticket_status} ticket - $${e.ticket_price}`}
                            </option>
                          );
                        }
                      })}
                  </select>
              </div> */}

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="message"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Message <span style={{ color: "orange" }}>*</span>
                </label>
                <div style={{ display: "block", width: "70%" }}>
                  <Switch
                    checked={this.state.isMessage}
                    onChange={e =>
                      this.setState({
                        isMessage: e.target.checked
                      })
                    }
                    color="primary"
                  />
                </div>
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="message"
                  style={{ width: "25%", textAlign: "left" }}
                ></label>
                {this.state.isMessage && (
                  <textarea
                    row={8}
                    column={8}
                    name="message"
                    id="message"
                    type="text"
                    className="form-control"
                    style={{ width: "70%" }}
                    onChange={e => {
                      this.setState({
                        [e.target.name]: e.target.value
                      });
                    }}
                    value={this.state.message}
                  />
                )}
              </div>

              <div className="modal-footer" style={{ marginTop: "25px" }}>
                <button
                  type="submit"
                  // onClick={_ => this.savePlan()}
                  className="btn profile-btn"
                >
                  Invite
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default InviteConnectionModal;
