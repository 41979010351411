import React from "reactn";
import { LeftMsgArrow, RightMsgArrow } from "../../../../../assets";
import { format } from "date-fns";
import ScrollToBottom from "react-scroll-to-bottom";
import Avatar from "../../../../../assets/img/msgicon.png";
import SendMessage from "./SendMessage";
import { CometChat } from "@cometchat-pro/chat";
import { IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
class CenterMessagingPane extends React.PureComponent {
  componentWillUnmount() {
    this.setGlobal({
      ccAdminSelectedContact: {},
      ccAdminSelectedMessages: []
    });
  }
  render() {
    return (
      <div className="centerm">
        <ScrollToBottom className="paddingmsg">
          {this.global.ccAdminSelectedMessages.map((msg, key) => {
            if (msg.receiver === this.global.program.user.cometchat) {
              return (
                <div key={key} className="leftsidemsg">
                  <div className="row">
                    <div className="col-2">
                      <div
                        className="divforimg activecircle"
                        style={{
                          backgroundImage: `url(${
                            this.global.ccAdminSelectedContact.conversationWith
                              .avatar || Avatar
                          })`,
                          backgroundPosition: "center",
                          width: "45px",
                          height: "45px",
                          borderRadius: "50%",
                          backgroundSize: "cover"
                        }}
                      >
                        {/* <div className="mincircle" /> */}
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="msg">
                        <p>
                          <img
                            src={LeftMsgArrow}
                            className="leftmsgarrow"
                            alt=""
                          />
                          {msg.data.text}{" "}
                        </p>

                        <p className="time">
                          {format(
                            new Date(msg.sentAt * 1000),
                            "LLL dd, yyyy - hh:mm:ss aa"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div key={key} className="floatright">
                  <div className="rightsidemsg">
                    <div className="row">
                      <div className="col-10">
                        <div className="msg">
                          <p>
                            <img
                              src={RightMsgArrow}
                              className="rightmsgarrow"
                              alt=""
                            />
                            {msg.data.text}{" "}
                          </p>

                          <p className="time">
                            {format(
                              new Date(msg.sentAt * 1000),
                              "LLL dd, yyyy - hh:mm:ss aa"
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="col-2">
                        <div
                          className="divforimg"
                          style={{
                            backgroundImage: `url(${
                              this.global.program.user.photo
                                ? process.env.REACT_APP_DS_FILES_S3 +
                                  "/" +
                                  this.global.program.user.photo
                                : Avatar
                            })`,
                            backgroundPosition: "center",
                            width: "45px",
                            height: "45px",
                            borderRadius: "50%",
                            backgroundSize: "cover"
                          }}
                        >
                          {/* <div className="mincircle" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
          {this.props.smallScreen && (
            <IconButton
              style={{ position: "absolute", top: "0px", left: "0px" }}
              onClick={() => {
                this.setGlobal({ ccAdminSelectedContact: {} });
              }}
            >
              <ArrowBack />
            </IconButton>
          )}
        </ScrollToBottom>
      </div>
    );
  }
}

export default CenterMessagingPane;
