import React, { useGlobal } from "reactn";
import { formTypes, BoxForm } from "../../../../components/UI/form";

const EmergencyContact = ({
  contactNo,
  emergencyContact,
  onChange,
  onRemove
}) => {
  const app = useGlobal("madeApp");
  const handleChange = (e, v) => {
    const c = { ...emergencyContact, [e]: v };
    onChange(c);
  };

  const fields = {
    children: {
      type: formTypes.ChildMultiSelect,
      val: emergencyContact.children,
      name: "For Child(ren)",
      required: true
    },
    relationship: {
      type: formTypes.Input,
      val: emergencyContact.relationship,
      name: "Relationship",
      required: true
    },
    fullName: {
      type: formTypes.Input,
      val: emergencyContact.fullName,
      name: "Contact Name",
      required: true
    },
    address: {
      type: formTypes.Input,
      val: emergencyContact.address,
      name: "Address",
      required: true
    },
    phone: {
      type: formTypes.Input,
      val: emergencyContact.phone,
      name: "Home Phone",
      required: true
    }
  };

  return (
    <BoxForm
      handleChange={handleChange}
      name={"EMERGENCY CONTACT " + (contactNo + 1)}
      fields={fields}
      object={emergencyContact}
      removable={true}
      handleRemove={onRemove}
      removeAfterSaved={false}
    />
  );
};

export default EmergencyContact;
