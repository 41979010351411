import { createContext } from "react";

export function promotion_detail_reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.SET_PROMOTIONS:
      return { ...state, promotion: payload };
    case actions.SET_PROGRAM:
      return { ...state, program: payload };
    case actions.SET_ONLINE:
      return { ...state, online: payload };
    case actions.SET_ORGANIZATION:
      return { ...state, organization: payload };
    case actions.SET_EVENT:
      return { ...state, event: payload };
    case actions.SET_SELECTED:
      return { ...state, selected: payload };
    case actions.SET_QUANTITY:
      return { ...state, quantity: payload };
    case actions.SET_CHECKOUT:
      return { ...state, checkout: payload };
    case actions.SET_SIGNUP_MODAL:
      return { ...state, isOpenSignUpModal: payload };
    case actions.SET_DROP_INS:
      return { ...state, dropIns: payload };
    case actions.SET_INSTALLMENTS:
      return { ...state, installments: payload };
    case actions.SET_SIGNIN_MODAL:
      return { ...state, signIn: payload };
    case actions.SET_MEMBERSHIP:
      return { ...state, membership: payload };
    default:
      return state;
  }
}

// actions
export const actions = {
  SET_PROMOTIONS: "SET_PROMOTIONS",
  SET_PROGRAM: "SET_PROGRAM",
  SET_ONLINE: "SET_ONLINE",
  SET_EVENT: "SET_EVENT",
  SET_MEMBERSHIP: "SET_MEMBERSHIP",
  SET_ORGANIZATION: "SET_ORGANIZATION",
  SET_CHECKOUT: "SET_CHECKOUT",
  SET_SIGNUP_MODAL: "SET_SIGNUP_MODAL",
  SET_DROP_INS: "SET_DROP_INS",
  SET_INSTALLMENTS: "SET_INSTALLMENTS",
  SET_SELECTED: "SET_SELECTED",
  SET_SIGNIN_MODAL: "SET_SIGNIN_MODAL",
  SET_QUANTITY: "SET_QUANTITY"
};

// dispatch actions
export const setPromotion = (dispatch, payload) => {
  dispatch({ type: actions.SET_PROMOTIONS, payload });
};

export const setProgram = (dispatch, payload) => {
  dispatch({ type: actions.SET_PROGRAM, payload });
};

export const setOnline = (dispatch, payload) => {
  dispatch({ type: actions.SET_ONLINE, payload });
};

export const setMembership = (dispatch, payload) => {
  dispatch({ type: actions.SET_MEMBERSHIP, payload });
};

export const setSelected = (dispatch, payload) => {
  dispatch({ type: actions.SET_SELECTED, payload });
};

export const setQuantity = (dispatch, payload) => {
  dispatch({ type: actions.SET_QUANTITY, payload });
};

export const setEvent = (dispatch, payload) => {
  dispatch({ type: actions.SET_EVENT, payload });
};

export const setOrganization = (dispatch, payload) => {
  dispatch({ type: actions.SET_ORGANIZATION, payload });
};

export const setDropIns = (dispatch, payload) => {
  dispatch({ type: actions.SET_DROP_INS, payload });
};

export const setInstallments = (dispatch, payload) => {
  dispatch({ type: actions.SET_INSTALLMENTS, payload });
};

export const setCheckout = (dispatch, payload) => {
  dispatch({ type: actions.SET_CHECKOUT, payload });
};

export const setSignIn = (dispatch, payload) => {
  dispatch({ type: actions.SET_SIGNIN_MODAL, payload });
};

export const initial_state = {
  program: {},
  online: {},
  event: {},
  membership: {},
  promotion: {},
  organization: {},
  selected: false,
  checkout: false,
  signIn: false,
  isOpenSignUpModal: false,
  selected: -1,
  dropIns: false,
  installments: false,
  quantity: {}
};

export const PromotionContext = createContext(initial_state);
