import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { typeOptions } from "../../pages/partners/promotions/promotion.context";

import BaseModal from "./baseModal/BaseModal";
import { DROPDOWN, SWITCH } from "./baseModal/FieldTypes";
import { notEmptyString } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

import {
  displayPromotionTypeListData,
  getPromotionTypeValuesBasedOnType
} from "../../pages/partners/promotions/PromotionSetupUtils";

const actions = {
  UPDATE_PROMOTION_TYPE: "UPDATE_PROMOTION_TYPE",
  UPDATE_IS_ACTIVE: "UPDATE_IS_ACTIVE",
  SET_SELECTED_PROMOTION_TYPE: "SET_SELECTED_PROMOTION_TYPE",
  UPDATE_PROMOTION_TYPE_LIST: "UPDATE_PROMOTION_TYPE_LIST"
};

const initial_state = {
  type: "",
  isActive: true,
  selectedPromotion: -1,
  promotion_type_list: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.UPDATE_IS_ACTIVE:
      return { ...state, isActive: !state.isActive };
    case actions.UPDATE_PROMOTION_TYPE:
      return { ...state, type: action.payload };
    case actions.UPDATE_PROMOTION_TYPE_LIST:
      return { ...state, promotion_type_list: action.payload };
    case actions.SET_SELECTED_PROMOTION_TYPE:
      return { ...state, selectedPromotion: parseInt(action.payload) };
    default:
      return state;
  }
};

const CreatePromotionListModal = ({ onClose, history }) => {
  const [state, dispatch] = useReducer(reducer, initial_state);
  const updatePromotionTypeAction = async e => {
    const result = await getPromotionTypeValuesBasedOnType(state.type);
    if (result.success) {
      dispatch({
        type: actions.UPDATE_PROMOTION_TYPE_LIST,
        payload: result.data
          .filter(product => !product.manual_invoices)
          .filter(product => !product.isArchived)
      });
      dispatch({ type: actions.SET_SELECTED_PROMOTION_TYPE, payload: -1 });
    }
  };

  useEffect(() => {
    updatePromotionTypeAction();
  }, [state.type]);

  const handleSubmit = () => {
    history.push({
      pathname: "/partners/promotion/setup",
      state
    });
  };

  const getBaseModalFields = () => {
    const typeField = {
      type: DROPDOWN,
      data: {
        name: "Type",
        required: true,
        placeholder: "Select a type",
        handleChange: e => {
          dispatch({
            type: actions.UPDATE_PROMOTION_TYPE,
            payload: e.target.value
          });
          dispatch({
            type: actions.SET_SELECTED_PROMOTION_TYPE,
            payload: -1
          });
        },
        choices: typeOptions.map(({ value, display }) => ({
          value: value,
          text: display
        }))
      },
      validators: { validateSubmit: notEmptyString(state.type) }
    };

    const selectField = {
      type: DROPDOWN,
      data: {
        name: "Select",
        required: true,
        placeholder: "Select a listing",
        value: state.selectedPromotion,
        handleChange: e =>
          dispatch({
            type: actions.SET_SELECTED_PROMOTION_TYPE,
            payload: e.target.value
          }),
        choices: state.promotion_type_list.map(val => ({
          value: val.id,
          text: displayPromotionTypeListData(state.type, val)
        }))
      },
      validators: {
        validateSubmit: () =>
          state.selectedPromotion !== -1 && state.selectedPromotion !== ""
      }
    };

    const activeField = {
      type: SWITCH,
      data: {
        name: "Active",
        checked: state.isActive,
        handleClick: () => {
          dispatch({ type: actions.UPDATE_IS_ACTIVE });
        }
      }
    };

    return [typeField, selectField, activeField];
  };

  const getBaseModalButtons = () => {
    const createButton = {
      name: "Create Promotion",
      handleClick: handleSubmit,
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit"
    };
    return [createButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Add Promotion",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: () => {
        console.log({ state });
        onClose();
      }
    };
  };

  return <BaseModal {...getBaseModalProps()} />;
};

CreatePromotionListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default CreatePromotionListModal;
