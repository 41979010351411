import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { toast } from "react-toastify";
import { ModalBanner } from "../../assets";
import "../../assets/css/componentSpecificCss/inviteUserModal.css";

class EmailInviteModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async inviteUser(e) {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/partners/users/send-invite`;
    const res = await axios.put(ep, {
      id: this.props.selectedUser.id,
      email: this.state.email,
      receives_emails: this.state.receives_emails
    });

    if (res.data.success) {
      this.props.user();
      toast.success("User Invited");
      this.props.onClose();
    } else {
      toast.error(res.data.message);
    }
  }

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding" style={{ display: "block" }}>
          <img
            src={ModalBanner}
            alt="Modal Banner"
            style={{
              height: "40px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>Invite User</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form onSubmit={e => this.inviteUser(e)}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="email"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Email
                </label>
                <input
                  name="email"
                  id="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  style={{ width: "70%" }}
                  value={this.state.email}
                  onChange={e => {
                    if (e.target.value.length === 0) {
                      this.setState({
                        receives_emails: false
                      });
                    }
                    this.setState({
                      [e.target.name]: e.target.value.toLowerCase()
                    });
                  }}
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 0,
                  marginTop: "30px"
                }}
              >
                <input
                  type="checkbox"
                  checked={this.state.receives_emails}
                  name="receives_emails"
                  onChange={e =>
                    this.setState({ [e.target.name]: e.target.checked })
                  }
                  style={{ marginBottom: "5px" }}
                  disabled={!this.state.email}
                />
                <label style={{ marginLeft: "10px" }}>
                  Subscribe to Triggered Emails
                </label>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn profile-btn">
                  Invite
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EmailInviteModal);
