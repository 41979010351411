import React from "react";
import ProgramDisplayPhotos from "./forms/programDisplayPhotos";
import ProgramPhotos from "./forms/programPhotos";

class Gallery extends React.PureComponent {
  componentDidMount() {}
  render() {
    return (
      <div className="cont boxes-row mt-50px">
        <ProgramDisplayPhotos />
        <ProgramPhotos />
      </div>
    );
  }
}

export default Gallery;
