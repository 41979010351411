import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { toast } from "react-toastify";

class EmailClientModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async submit() {
    const ep = `${process.env.REACT_APP_API}/partners/stripe/sendReceipt`;
    const res = await axios.post(ep, {
      charge: this.props.charge,
      email: this.state.email
    });

    if (res.data.success) {
      toast.success("Email Sent!");
      this.props.onClose();
    }
  }

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px",
                marginBottom: ".5rem"
              }}
            >
              <h3>Email Client</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="email"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Email
                </label>
                <input
                  name="email"
                  id="email"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={this.state.email}
                />
              </div>
            </form>

            <div className="modal-footer">
              <button
                type="submit"
                onClick={_ => this.submit()}
                className="btn profile-btn"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EmailClientModal);
