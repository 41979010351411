import React from "reactn";
import { Table } from "../../UI";
import Axios from "axios";
import numeral from "numeral";
import { format } from "date-fns";
class InvoicesTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      invoices: {
        invoices: [],
        maps: {}
      }
    };
    this.headings = [
      {
        id: "program",
        label: "PROGRAM",
        customCell: (row, i) => (
          <td key="hola">
            {this.state.invoices.maps[row.subscription]
              ? this.state.invoices.maps[row.subscription].program
              : ""}
          </td>
        )
      },
      {
        id: "student",
        label: "STUDENT",
        customCell: (row, i) => (
          <td key="hola2">
            {this.state.invoices.maps[row.subscription]
              ? this.state.invoices.maps[row.subscription].child
              : ""}
          </td>
        )
      },
      {
        id: "parent",
        label: "PARENT",
        customCell: (row, i) => (
          <td key="hola2">
            {this.state.invoices.maps[row.subscription]
              ? this.state.invoices.maps[row.subscription].parentName
              : ""}
          </td>
        )
      },
      {
        id: "servicePeriod",
        label: "SERVICE PERIOD",
        customCell: (row, i) => (
          <td key={`id-${i}`}>
            {row.period_end - row.period_start < 691200
              ? `${format(
                  new Date(row.period_start * 1000 + 7 * 24 * 3600000),
                  "LLL d"
                )} - ${format(
                  new Date(row.period_end * 1000 + 6 * 24 * 3600000),
                  "LLL d yyyy"
                )}`
              : `${format(
                  new Date(row.period_start * 1000),
                  "LLL d"
                )} - ${format(new Date(row.period_end * 1000), "LLL d yyyy")}`}
          </td>
        )
      },
      {
        id: "amount_due",
        label: "AMOUNT",
        customCell: (row, i) => (
          <td key={`id-${i}`}>
            ${numeral(row.amount_due / 100).format("0,0.00")}
          </td>
        )
      },
      {
        id: "paymentStatus",
        label: "PAYMENT STATUS",
        customCell: (row, i) => (
          <td key={`id-${i}`}>
            {row.paid ? (
              <span className="green">Paid</span>
            ) : (
              <span className="red">Pending</span>
            )}
          </td>
        )
      },
      {
        id: "invoiceNumber",
        label: "INVOICE NUMBER",
        customCell: (row, i) => <td key={`id-${i}`}>#{row.number}</td>
      },
      { id: "action", label: " " }
    ];
    this.actions = [{ name: "VIEW INVOICE", action: this.viewInvoice }];
  }

  viewInvoice = async row => {
    this.props.invoice(row, this.state.invoices.maps);
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/finances/invoices`;
    this.setGlobal({ loading: true });
    const results = await Axios.get(ep);

    if (results.data.success) {
      this.setGlobal({ loading: false });
      this.setState({
        invoices: results.data.data
      });
    }
  }
  render() {
    return (
      <div style={{ width: "100%" }}>
        <Table
          headings={this.headings}
          data={this.state.invoices.invoices.filter(e => {
            const search = this.props.search;
            if (!search) return true;
            else {
              if (this.state.invoices.maps[e.subscription]) {
                const parentName =
                  this.state.invoices.maps[
                    e.subscription
                  ].parentName.toLocaleLowerCase();
                const program =
                  this.state.invoices.maps[
                    e.subscription
                  ].program.toLocaleLowerCase();
                const childname =
                  this.state.invoices.maps[
                    e.subscription
                  ].child.toLocaleLowerCase();
                return (
                  parentName.includes(search.toLocaleLowerCase()) ||
                  program.includes(search.toLocaleLowerCase()) ||
                  childname.includes(search.toLocaleLowerCase())
                );
              } else return false;
            }
          })}
          actions={this.actions}
        />
      </div>
    );
  }
}

export default InvoicesTable;
