import React from "reactn";
import { Calendar as DashboardCalendar } from "react-calendar";
import { IconButton } from "@material-ui/core";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import {
  addMonths,
  subMonths,
  isSameDay,
  addWeeks,
  addDays,
  format,
  differenceInDays
} from "date-fns";
import { DdIcon } from "../../../assets";
import "../../../assets/css/componentSpecificCss/mainCalendar.css";

import axios from "axios";
import { dates } from "../../../utilities/calendar/calendarUtils";

import { withRouter } from "react-router";

const DATE_FORMAT = "LLLL d, yyyy";

class Calendar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { date: new Date(), scheduled: [], reminders: [] };
  }

  async componentDidMount() {
    const calendarTitle = document.getElementsByClassName(
      "react-calendar__tile"
    );
    const calendarMonthView = document.getElementsByClassName(
      "react-calendar__month-view__weekdays__weekday"
    );

    while (calendarTitle.length) {
      if (calendarTitle[0]) {
        calendarTitle[0].classList.add("react-calendar__tile_dashboard");
        calendarTitle[0].classList.remove("react-calendar__tile");
      }
    }

    while (calendarMonthView.length) {
      calendarMonthView[0].className =
        "react-calendar__month-view__weekdays__weekday_dashboard";
    }

    // Paint the date bubbles.
    await this.getEvents();
    this.paintDates();
  }

  /**
   * Paints all dates.
   */
  paintDates() {
    /**
     * Paints a given date depending on the type.
     *
     * @returns {Null}
     *
     * @param {String} date Date in the format "LLLL d, yyyy".
     * @param {String} type Currently can be "scheduled" or "reminder".
     */
    const paintDate = (date, type) => {
      const doc = document.querySelector(`[aria-label="${date}"]`);
      if (doc) {
        const isNeighboringMonth = doc.parentNode.className.includes(
          "react-calendar__month-view__days__day--neighboringMonth"
        );
        switch (type) {
          case "scheduled":
            if (!isNeighboringMonth) {
              doc.parentNode.classList.remove("reminder");
              doc.parentNode.classList.add("highlighted", "scheduled");
              doc.style.color = "#FFF";
            }
            break;

          case "reminder":
            doc.style.color = "#FFF";
            doc.parentNode.classList.remove("scheduled");
            doc.parentNode.classList.add("highlighted", "reminder");
            break;

          default:
            break;
        }
      }
    };
    this.state.reminders.forEach(date => paintDate(date, "reminder"));
    this.state.scheduled.forEach(date => paintDate(date, "scheduled"));
  }

  /**
   * Gets all dates which should be coloured in.
   * Currently, should consist of "scheduled" events (e.g program start dates)
   * and "reminder" events (e.g tasks/reminders);.
   *
   * @returns {Object}
   * Each object in the form {scheduled: [...], reminder: [...]}.
   */
  async getEvents() {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/reminders`;
    const results = await axios.get(ep);
    const prg_evt = await axios.get(
      `${process.env.REACT_APP_API}/partners/organization_profile/prg_evts`
    );

    if (!results.data.success || !prg_evt.data.success) return;

    const {
      programs: _programs,
      events: _events,
      virtual: _online
    } = prg_evt.data.data;
    const programs = JSON.parse(
      JSON.stringify(_programs.filter(e => e.published === true))
    );
    const events = JSON.parse(JSON.stringify(_events));
    const online = JSON.parse(
      JSON.stringify(_online.filter(e => e.published === true))
    );

    const scheduledDates = new Set();
    const reminderDates = new Set();

    results.data.data
      .filter(reminder => reminder.date)
      .forEach(reminder => {
        reminderDates.add(format(new Date(reminder.date), DATE_FORMAT));
      });

    // Programs and Online Virtual
    for (const program of [...programs, ...online]) {
      const startDate = new Date(program.program_start);
      // Recurring programs - add only the start dates.
      if (program.number_of_weeks === 0 || program.number_of_weeks === null) {
        scheduledDates.add(format(startDate, DATE_FORMAT));
      } else {
        // All other programs, add all running dates.
        const endDate = addWeeks(startDate, program.number_of_weeks);
        let start = new Date(program.program_start);

        // Iterate through dates, adding dates without any closures.
        while (differenceInDays(endDate, start) > 0) {
          const closureDate = program.closure_dates.map(e => new Date(e));
          const isClosure = closureDate.filter(e => isSameDay(e, start))[0]
            ? true
            : false;
          if (program.days_of_week[dates[start.getDay()]]) {
            const [startHour, endHour] = program.timing;
            if (!(startHour === "-1" || endHour === "-1") && !isClosure) {
              scheduledDates.add(format(start, DATE_FORMAT));
            }
          }
          start = addDays(start, 1);
        }
      }
    }

    // Events
    for (const event of events) {
      const startDate = new Date(event.event_start);
      const endDate = new Date(event.event_end);
      scheduledDates.add(format(startDate, DATE_FORMAT));
      let start = startDate;
      while (differenceInDays(endDate, start) > 0) {
        start = addDays(start, 1);
        scheduledDates.add(format(start, DATE_FORMAT));
      }
    }

    this.setState({
      scheduled: Array.from(scheduledDates),
      reminders: Array.from(reminderDates)
    });
  }

  componentDidUpdate() {
    const calendarTitle = document.getElementsByClassName(
      "react-calendar__tile"
    );
    const calendarMonthView = document.getElementsByClassName(
      "react-calendar__month-view__weekdays__weekday"
    );

    while (calendarTitle.length) {
      if (calendarTitle[0]) {
        calendarTitle[0].classList.add("react-calendar__tile_dashboard");
        calendarTitle[0].classList.remove(
          "react-calendar__tile",
          "highlighted"
        );
      }
    }

    while (calendarMonthView.length) {
      calendarMonthView[0].className =
        "react-calendar__month-view__weekdays__weekday_dashboard";
    }
    this.paintDates();
  }

  render() {
    console.log(
      "difference",
      differenceInDays(new Date(2020, 6, 2, 0, 0), new Date(2021, 6, 2, 0, 0))
    );
    return (
      <div className="col-xl-6 d-flex">
        <div className="tablebox" style={{ height: "450px" }}>
          <div
            className="tableheader"
            onClick={() => this.props.history.push("/partners/calendar")}
            style={{ cursor: "pointer" }}
          >
            <h2>
              CALENDAR <img src={DdIcon} alt="" />
            </h2>
          </div>
          <div className="tablebody padded text-center">
            <div className="row">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  margin: "auto",
                }}
              >
                <div
                  onClick={() => this.props.history.push("/partners/calendar")}
                  style={{ cursor: "pointer" }}
                >
                  <DashboardCalendar
                    value={null}
                    activeStartDate={this.state.date}
                    minDetail="month"
                  />
                </div>

                <div
                  style={{
                    alignItems: "center",
                    margin: "50px 20px 50px 20px",
                    display: "flex",
                    width: "85%",
                    justifyContent: "space-between"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        backgroundColor: "#5cc8dc",
                        marginRight: "10px"
                      }}
                    />
                    <span
                      style={{
                        flexGrow: 1,
                        textAlign: "left"
                      }}
                    >
                      Scheduled
                    </span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        backgroundColor: "#BBB",
                        marginRight: "10px"
                      }}
                    />
                    <span
                      style={{
                        flexGrow: 1,
                        textAlign: "left"
                      }}
                    >
                      Reminders
                    </span>
                  </div>
                  <div>
                    <IconButton
                      onClick={_ =>
                        this.setState({ date: subMonths(this.state.date, 1) })
                      }
                    >
                      <ArrowUpward />
                    </IconButton>
                    <IconButton
                      onClick={_ =>
                        this.setState({ date: addMonths(this.state.date, 1) })
                      }
                    >
                      <ArrowDownward />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Calendar);
