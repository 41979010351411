import React from "react";
import ProgramHolidayForm from "./forms/programHolidays";
import ProgramVacationForm from "./forms/programVacations";
import ProgramAmendments from "./forms/programAmendments";
class ProgramHolidays extends React.PureComponent {
  componentDidMount() {}
  render() {
    return (
      <div className="cont boxes-row mt-50px">
        <ProgramHolidayForm />
        <ProgramVacationForm />
        <ProgramAmendments />
      </div>
    );
  }
}

export default ProgramHolidays;
