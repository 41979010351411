import React from "reactn";
import "./LoaderSquares.css";

class LoaderSquares extends React.PureComponent {
  render() {
    return (
      <div id="loader-squares"
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          width: "100vw",
          height: "100vh",
          display: this.global.loading ? "flex" : "none",
          // display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          backgroundColor: "rgba(0, 0, 0, 0.25)",
          zIndex: 10001
        }}
      >
        <div className="loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default LoaderSquares;
