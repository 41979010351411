import React from "react";
import { Link } from "react-router-dom";

class FindMapMarkerInfoWindow extends React.Component {
  render() {
    const infoWindowStyle = {
      position: "relative",
      bottom: 150,
      left: "-45px",
      width: 200,
      backgroundColor: "white",
      borderRadius: "10px",
      fontSize: 14,
      zIndex: 100
    };

    const { school } = this.props;
    return (
      <div style={infoWindowStyle}>
        <Link
          to={`/schools/${school.slug}`}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <div className="row">
            <div className="col-md-12 no-padding flex">
              <div className="thumbnail no-padding no-margin pop-up-image">
                <img
                  className="style_image pop-up-image"
                  src={`${process.env.REACT_APP_DS_FILES_S3}/${school.programCoverPhoto}`}
                  alt={school.programName}
                />
              </div>
              <div className="caption">
                <h5>{school.programName}</h5>
                <div>{school.majorIntersection}</div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default FindMapMarkerInfoWindow;
