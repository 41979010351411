import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  checkbox: {
    minWidth: "32px",
    "& .MuiListItemIcon-root": {
      minWidth: "40px"
    }
  }
};

/**
 * Filter button with dropdown checkbox filter when clicked.
 *
 * NOTE: This component is not very extensible, is mainly used to display a filter of listings.
 *
 * @param {string} label Label of filter button.
 * @param {Array<Object>} checkedOptions Currently checked options state.
 * @param {Array<Object>} setCheckedOptions Function to set checkedOptions.
 * @param {Array<Object>} allOptions All options to show.
 * @param {string} stringProperty The property of each item to display as a label.
 *
 */
const FilterButton = ({
  label,
  checkedOptions,
  setCheckedOptions,
  allOptions,
  stringProperty,
  classes,
  className
}) => {
  const [showPopper, setShowPopper] = useState(null);

  const handleFilterOptionClick = listing => {
    const found = checkedOptions.find(o => o.listingId === listing.listingId);
    let newChecked = [...checkedOptions];
    
    if (found === undefined) {
      newChecked.push(listing);
    } else {
      newChecked = newChecked.filter(e => e.listingId !== listing.listingId);
    }
    console.log({ newChecked, listing });
    setCheckedOptions(newChecked);
  };

  const toggleAll = () => {
    if (checkedOptions.length !== allOptions.length) {
      setCheckedOptions(allOptions);
    } else {
      setCheckedOptions([]);
    }
  };
  return (
    <div className={className || "filter-item"}>
      <button
        style={{
          margin: "0 5px",
          height: "35px",
          width: "170px",
          alignSelf: "center",
          zIndex: 1,
          backgroundColor: "#FFF",
          color: "#8c8ea0",
          border: "1px solid #7ea1ff",
          padding: "0.375rem 0.75rem",
          fontSize: ".9rem",
          fontWeight: "400"
        }}
        aria-describedby={Boolean(showPopper) ? "events-popover" : undefined}
        onClick={e => setShowPopper(e.currentTarget)}
      >
        {label}
      </button>
      <Popover
        id={Boolean(showPopper) ? "programs-popover" : undefined}
        open={Boolean(showPopper)}
        anchorEl={showPopper}
        onClose={_ => setShowPopper(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        {allOptions && (
          <List>
            <ListItem key="all" dense button onClick={_ => toggleAll()}>
              <ListItemIcon classes={{ root: classes.checkbox }}>
                <Checkbox
                  edge="start"
                  checked={checkedOptions.length === allOptions.length}
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                  inputProps={{
                    "aria-labelledby": "all"
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id="all"
                primary="Toggle all"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
            {allOptions.map((listing, index) => {
              const labelId = `checkbox-list-label-${index}`;

              return (
                <ListItem
                  key={index}
                  role={undefined}
                  dense
                  button
                  onClick={_ => handleFilterOptionClick(listing)}
                >
                  <ListItemIcon classes={{ root: classes.checkbox }}>
                    <Checkbox
                      edge="start"
                      checked={
                        checkedOptions.find(
                          option => option.listingId === listing.listingId
                        ) !== undefined
                      }
                      tabIndex={-1}
                      disableRipple
                      color="primary"
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={listing[stringProperty]}
                    primaryTypographyProps={{
                      style: { maxWidth: "750px" }
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
        )}
      </Popover>
    </div>
  );
};

export default withStyles(styles)(FilterButton);
