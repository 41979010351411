import React from "reactn";
import AddIndvResourceModal from "../../components/modals/AddIndvResourceModal";
import { Edit, Delete } from "@material-ui/icons";
import axios from "axios";
import EditIndvResourceModal from "../../components/modals/EditIndvResourceModal";
import { Resource1 } from "../../assets";
class ManageResources extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      filter: "",
      detailsModalShown: false,
      modalData: [],
      original: {},
      resource: {}
    };
  }

  /**
   * Handles text typed into the filter bar
   */
  filterHandler = e => {
    const filterText = e.target.value;
    this.setState({ filter: filterText });
  };

  viewDetail = async row => {
    this.setState({ detailsModalShown: true, modalData: row });
  };
  viewComments = async row => {
    this.setState({ commentsModalShown: true, modalData: row });
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt((Date.now() - new Date(r.insys).getTime()) / (3600000 * 24))}
      </td>
    );
  };

  async componentDidMount() {
    this.setGlobal({ loading: true });
    await this.fetchInitialData();
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Resources", to: "/resources" },
        {
          label: this.state.resource.module,
          to: this.props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
        }
      ]
    });
    this.setGlobal({ loading: false });
  }

  async fetchInitialData() {
    const id = this.props.match.params.id;
    const ep = `${process.env.REACT_APP_API}/partners/resources/${id}`;
    const results = await axios.get(ep);

    if (results.data.success) {
      this.setState({ resource: results.data.data });
    }
    this.setGlobal({ loading: false });
  }

  /**
   * This function handles the edit button functionality of a sub user
   * returns a boolean value
   */
  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return this.global.dsUser.accesspoints.Manage.Resources.edit
        ? false
        : true;
    }
  };

  /**
   *
   * @param {*} id
   */
  async deleteResource(id) {
    let updatedResources = this.state.resource.resources.filter(
      e => e.id !== id
    );

    const ep = `${process.env.REACT_APP_API}/partners/resources/update_resource`;
    const res = await axios.post(ep, {
      resources: updatedResources,
      id: this.props.match.params.id
    });

    if (res.data.success) {
      this.fetchInitialData();
    }
  }

  render() {
    // Maps resources to Objects, then filters by the search string.
    const filteredResources =
      this.state.resource.resources &&
      this.state.resource.resources
        .map(resource => {
          return typeof resource === "string" ? JSON.parse(resource) : resource;
        })
        .filter(resource => {
          return this.state.filter === ""
            ? true
            : resource.title.toLowerCase().includes(this.state.filter);
        });

    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          {this.state.indvModal && (
            <AddIndvResourceModal
              onClose={_ => this.setState({ indvModal: false })}
              id={this.props.match.params.id}
              add={r =>
                this.setState({
                  resource: r
                })
              }
            />
          )}
          {this.state.openEditModal && (
            <EditIndvResourceModal
              onClose={_ => this.setState({ openEditModal: false })}
              selectedResourceId={this.state.selectedResourceId}
              resource={this.state.resource}
              fetch={_ => this.fetchInitialData()}
            />
          )}
          <div className="cont">
            <h1>Resources</h1>
          </div>
          <div className="cont">
            <div className="tablecont">
              <input
                type="text"
                placeholder="Search by resource name"
                style={{
                  marginBottom: 15,
                  width: "100%",
                  borderRadius: 5,
                  border: "2px solid #E3E6F0",
                  fontSize: "13px",
                  padding: 3,
                  outline: 0
                }}
                onChange={this.filterHandler}
                value={this.state.filter}
              />
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {filteredResources &&
                  filteredResources.map(r => {
                    return (
                      <div
                        style={{
                          backgroundColor: "white",
                          width: "200px",
                          padding: "10px",
                          borderRadius: "10px",
                          marginRight: "10px",
                          marginBottom: "10px",
                          cursor: "pointer"
                        }}
                        key={r.id}
                      >
                        <img
                          src={
                            r.image
                              ? `${process.env.REACT_APP_DS_FILES_S3}/${r.image}`
                              : Resource1
                          }
                          style={{
                            width: "100%",
                            height: "140px",
                            objectFit: "cover",
                            opacity: "0.8"
                          }}
                          onClick={_ =>
                            (r.web_url || r.file) &&
                            window.open(
                              `${
                                r.web_url
                                  ? r.web_url
                                  : process.env.REACT_APP_DS_FILES_S3 +
                                    "/" +
                                    r.file
                              }`
                            )
                          }
                          alt="resource image"
                        />
                        <p
                          style={{
                            textAlign: "center",
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "12px",
                            marginTop: "5px",
                            marginBottom: "5px"
                          }}
                          onClick={_ =>
                            (r.web_url || r.file) &&
                            window.open(
                              `${
                                r.web_url
                                  ? r.web_url
                                  : process.env.REACT_APP_DS_FILES_S3 +
                                    "/" +
                                    r.file
                              }`
                            )
                          }
                        >
                          {r.title}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center"
                          }}
                        >
                          <Edit
                            onClick={_ =>
                              this.setState({
                                openEditModal: true,
                                selectedResourceId: r.id
                              })
                            }
                            style={{
                              marginRight: "5px",
                              color: "darkgrey",
                              cursor: "pointer"
                            }}
                          />
                          <Delete
                            onClick={_ => this.deleteResource(r.id)}
                            style={{ color: "darkgrey", cursor: "pointer" }}
                          />
                          {/* <img
                            src="/static/media/monthlysurvey.122ae06e.svg"
                            alt=""
                          /> */}
                        </div>
                      </div>
                    );
                  })}
                <button
                  className="addchild"
                  style={{
                    minHeight: "unset",
                    minWidth: "unset",
                    maxWidth: "unset",
                    maxHeight: "unset",
                    flexGrow: 0,
                    width: "200px",
                    height: "200px",
                    margin: 0,
                    cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                  }}
                  disabled={this.canSubUserEdit()}
                  onClick={_ => this.setState({ indvModal: true })}
                >
                  <h3>Add Resource</h3>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageResources;
