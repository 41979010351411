import React from "reactn";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { format } from "date-fns";
import "./PaymentDetailsModal.css";
import { toast } from "react-toastify";

class PaymentDetailsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      invoice: null,
      items: []
    };
    this.payDeposit = this.payDeposit.bind(this);
  }

  async componentDidMount() {
    const params = this.props.match.params;
    if (!params.invoice) {
      if (this.global.dsUser.accountType === "Parent") {
        this.props.history.push("/parents/tuition-payments");
      } else {
        const ep = `${process.env.REACT_APP_API}/pmnt/invoices/${this.props.invoice}`;
        try {
          this.setGlobal({ loading: true });
          const res = await axios.get(ep);
          if (res.data.success) {
            this.setState({ ...res.data.data });
            let total = 0;
            res.data.data.items.forEach(i => {
              total += parseFloat(parseFloat(i.amount).toFixed(2));
            });
            this.setState({ subTotal: total, items: res.data.data.items });
          }
          this.setGlobal({ loading: false, lastAPICall: res });
        } catch (er) {
          this.setGlobal({ loading: false, lastAPICall: null });
        }
      }
    } else {
      const ep = `${process.env.REACT_APP_API}/pmnt/invoices/${params.invoice}`;
      try {
        this.setGlobal({ loading: true });
        const res = await axios.get(ep);
        if (res.data.success) {
          this.setState({ ...res.data.data });
          let total = 0;
          res.data.data.items.forEach(i => {
            total += parseFloat(parseFloat(i.amount).toFixed(2));
          });
          this.setState({ subTotal: total, items: res.data.data.items });
        }
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (er) {
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }
  }

  async payDeposit() {
    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/pmnt/billing/pay-deposit/${params.invoice}`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      if (res.data.success) {
        toast("Successfully paid deposit!");
        this.setState({ invoice: res.data.data });
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (er) {
      toast.error("Failed to pay deposit.");
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    return (
      <div className="cont">
        {this.state.invoice === null ? null : (
          <div className="boxpaid">
            <div className="box">
              <div className="header">
                <h2>
                  PAYMENT #
                  {
                    this.state.invoice.invoice.split("-")[
                      this.state.invoice.invoice.split("-").length - 1
                    ]
                  }
                </h2>
                <p>Invoice #{this.state.invoice.invoice}</p>
                <p
                  className={
                    this.state.invoice.paid
                      ? "paid"
                      : this.state.invoice.status === "Pending"
                      ? "pending"
                      : "unpaid"
                  }
                >
                  {this.state.invoice.paid
                    ? "PAID"
                    : this.state.invoice.status === "Pending"
                    ? "PENDING"
                    : "UNPAID"}
                </p>
              </div>
              <div className="body">
                <div className="info">
                  <div className="row">
                    <div className="col-4">
                      <p>
                        <span>By:</span>
                      </p>
                    </div>
                    <div className="col-8">
                      <p>{this.state.invoice.parentName}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <p>
                        <span>For:</span>
                      </p>
                    </div>
                    <div className="col-8">
                      <p>{this.state.invoice.programName}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <p>
                        <span>Service Period:</span>
                      </p>
                    </div>
                    <div className="col-8">
                      <p>
                        {!this.state.invoice.servicePeriodStart ? null : (
                          <>
                            {format(
                              new Date(this.state.invoice.servicePeriodStart),
                              "LLL d"
                            )}
                          </>
                        )}{" "}
                        -{" "}
                        {!this.state.invoice.servicePeriodStart ? null : (
                          <>
                            {format(
                              new Date(this.state.invoice.servicePeriodEnd),
                              "LLL d, yyyy"
                            )}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <p>
                        <span>Due Date: </span>
                      </p>
                    </div>
                    <div className="col-8">
                      <p>
                        {format(
                          new Date(this.state.invoice.dueDate),
                          "LLL d, yyyy"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tablediv">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Amount</th>
                          <th scope="col">Type</th>
                          <th scope="col">Date Added</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.items.map((item, i) => (
                          <tr key={i}>
                            <td>${parseFloat(item.amount).toFixed(2)}</td>
                            <td>{item.type}</td>
                            <td>
                              {format(new Date(item.createdAt), "LLL d, yyyy")}
                            </td>
                          </tr>
                        ))}
                        {this.global.payment.type === "Bambora" ||
                        this.state.invoice.paidByCC ? (
                          <tr>
                            <td>
                              $
                              {parseFloat(
                                parseFloat(this.state.subTotal) * 0.02
                              ).toFixed(2)}
                            </td>
                            <td>Credit Card Processing Fee</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <h3>
                Total Gross Amount: $
                {this.state.subTotal
                  ? parseFloat(
                      parseFloat(this.state.subTotal) *
                        (this.global.payment.type === "Bambora" ||
                        this.state.invoice.paidByCC
                          ? 1.02
                          : 1)
                    ).toFixed(2)
                  : 0.0}
              </h3>
              {(this.state.tuition && this.state.tuition.paid) ||
              this.props.invoice ? null : (
                <div className="body">
                  <div className="info mt-0">
                    <div className="row">
                      <div
                        className="col-12 d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <button
                          className="ds-button"
                          onClick={() =>
                            this.props.history.push("/parents/payment-settings")
                          }
                        >
                          CHANGE PAYMENT INFO
                        </button>
                        <button
                          className="ds-button"
                          onClick={() =>
                            this.props.history.push(
                              `/parents/acceptance/${this.state.invoice.slug}/${this.state.invoice.enrollmentCreation}`
                            )
                          }
                        >
                          VIEW ACCEPTANCE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(PaymentDetailsModal);
