import React, { useGlobal } from "reactn";
import { LeftMsgArrow, RightMsgArrow } from "../../../../assets";
import { format } from "date-fns";
import ScrollToBottom from "react-scroll-to-bottom";
import { PPIcon, ParentMessage, Megaphone } from "../../../../assets";
import SendMessage from "./MessageBar";
import "../messaging.css";
import { findUserData } from "./commUtil";

const Chat = ({ messageThread, userInfoLT, setRefetch, insertMessage }) => {

  const dsUser = useGlobal("dsUser")[0];       //dsUser is the partner, since this page is only on the partner-site!

  if (!messageThread || !messageThread.messages) return;

  // const partnerPhoto = dsUser.photo
  //   ? `${process.env.REACT_APP_DS_FILES_S3}/${dsUser.photo}`
  //   : Megaphone;
  const partnerPhoto = Megaphone;  //dsUser (in this case) doesn't have photo field, nor the organization's logo.

  const userInfo = messageThread.dsUserId && userInfoLT[messageThread.dsUserId]; //get user profile for photo only!
  const userPhoto = userInfo && userInfo.photo
    ? `${process.env.REACT_APP_DS_FILES_S3}/${userInfo.photo}`
    : ParentMessage;

  // Most recent messages appear at the end of array.
  const messages = messageThread.messages.slice().reverse();

  const ascendingOrder = (a,b) => (new Date(a.dateCreated) - new Date(b.dateCreated));

  return (
    <div className="message-center">
      <ScrollToBottom className="partnermsg">
        {messages.sort(ascendingOrder).map((msg, key) => {
          if (msg.direction === "inbound") {
            return (
              <div key={key} className="leftsidemsg">
                <div className="row">
                  <div className="col-2">
                    <div
                      className="divforimg activecircle"
                      style={{
                        backgroundImage: `url(${userPhoto})`,
                        backgroundPosition: "center",
                        width: "45px",
                        height: "45px",
                        borderRadius: "50%",
                        backgroundSize: "cover"
                      }}
                    >
                      {/* <div className="mincircle" /> */}
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="msg">
                      <p>
                        <img
                          src={LeftMsgArrow}
                          className="leftmsgarrow"
                          alt=""
                        />
                        {msg.body}{" "}
                      </p>

                      <p className="time">
                        {format(
                          new Date(msg.dateCreated),
                          "LLL dd, yyyy - hh:mm:ss aa"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div key={key} className="floatright">
                <div className="rightsidemsg">
                  <div className="row">
                    <div className="col-10">
                      <div className="msg">
                        <p>
                          <img
                            src={RightMsgArrow}
                            className="rightmsgarrow"
                            alt=""
                          />
                          {msg.body}{" "}
                        </p>

                        <p className="time">
                          {format(
                            new Date(msg.dateCreated),
                            "LLL dd, yyyy - hh:mm:ss aa"
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="col-2">
                      <div
                        className="divforimg"
                        style={{
                          backgroundImage: `url(${partnerPhoto})`,
                          backgroundPosition: "center",
                          width: "45px",
                          height: "45px",
                          borderRadius: "50%",
                          backgroundSize: "cover"
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </ScrollToBottom>

      <SendMessage
        phone={messageThread.phone}
        setRefetch={setRefetch}
        insertMessage={insertMessage}
      />
    </div>
  );
};

export default Chat;
