import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton, Switch } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, SWITCH, DROPDOWN } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class AddLeadModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      total_price: null,
      isDescriptionEnabled: false,
      description: "",
      isTaxEnabled: false,
      taxInfo: []
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async savePlan() {
    if (
      (!this.state.total_price || this.state.total_price != 0) &&
      !this.global.organizationInfo.stripeId
    ) {
      toast.error("Denied. Please connect your Bank Account first");
      return;
    }
    const obj = { ...this.state, type: "One-Time" };
    obj.total_price = parseFloat(obj.total_price).toFixed(2);

    const ep = `${process.env.REACT_APP_API}/partners/stripe/add_products`;
    const res = await axios.post(ep, {
      ...obj,
      parent: "Online",
      name: this.global.online.program_name,
      type: "One-Time",
      product: this.global.online.product_stripe
    });

    this.setGlobal(
      {
        online: {
          ...this.global.online,
          product_stripe: res.data.data.product,
          plans: [
            ...this.global.online.plans,
            {
              ...obj,
              prodId: res.data.data.id,
              type: "One-Time",
              method: "Online",
              id: this.global.online.plans.length
            }
          ]
        }
      },
      () => {
        this.dispatch.saveVirtual();
      }
    );
    this.props.onClose();
  }

  taxesOptions = () => {
    //const global_organization = [JSON.parse(this.global.organizationInfo.stripeTaxId)]
    const global_organization = this.global.organizationInfo.stripeTaxId;
    console.log("global_organization", global_organization);
    const DATA = [];
    for (let i = 0; i < global_organization.length; i++) {
      DATA.push(JSON.parse(global_organization[i]));
    }
    console.log("DATA", DATA);

    return [
      ...DATA.map((e, i) => ({
        value: i,
        text: e.display_name + " " + e.jurisdiction + e.percentage + "%"
      }))
    ];
  };

  getBaseModalFields() {
    const priceInput = {
      type: STRING_INPUT,
      data: {
        name: "Price",
        required: false,
        type: "number",
        handleChange: e => {
          this.setState({
            total_price: e.target.value
          });
        },
        value: this.state.total_price
      }
    };

    const addDescription = {
      type: SWITCH,
      data: {
        name: "Add Description",
        checked: this.state.isDescriptionEnabled,
        isDisabled: false,
        handleClick: () => {
          this.setState({ isDescriptionEnabled: !this.state.isDescriptionEnabled })
        }
      }
    }

    const description = {
      type: STRING_INPUT,
      data: {
        name: "Description",
        required: false,
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description
      }
    }

    const enableTaxes = {
      type: SWITCH,
      data: {
        name: "Enable Taxes",
        isDisabled: false,
        checked: this.state.isTaxEnabled,
        handleClick: e =>
          this.setState({
            isTaxEnabled: e.target.checked,
            taxInfo: []
          })
      }
    };

    const selectTaxes = {
      type: DROPDOWN,
      data: {
        name: "Tax Plan",
        required: false,
        placeholder: "Please Select" /* 
            value: this.state.taxInfo, */,
        handleChange: e => {
          const item = JSON.parse(
            this.global.organizationInfo.stripeTaxId[e.target.value]
          );
          this.setState({
            taxInfo: [
              {
                id: item.id,
                percentage: item.percentage,
                jurisdiction: item.jurisdiction,
                display_name: item.display_name,
                description: item.description
              }
            ]
          });
        },
        choices: this.taxesOptions()
      }
    };

    const fields = [];

    fields.push(priceInput);
    fields.push(addDescription);
    if (this.state.isDescriptionEnabled) {
      fields.push(description);
    }
    fields.push(enableTaxes);
    if (this.state.isTaxEnabled) {
      fields.push(selectTaxes);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.savePlan()
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Pricing Plan",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <img
            src={ModalBanner}
            alt="Modal Banner"
            style={{
              height: "40px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>Pricing Plan</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="email"
                  style={{
                    width: "25%",
                    textAlign: "left",
                    marginRight: "22px"
                  }}
                >
                  Price
                </label>
                <input
                  name="total_price"
                  id="email"
                  type="number"
                  className="form-control"
                  style={{ width: "20%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={this.state.total_price}
                />
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Enable Taxes
                </label>
                <div style={{ width: "70%", textAlign: "left" }}>
                  <Switch
                    checked={this.state.isTaxEnabled}
                    onChange={e =>
                      this.setState({
                        isTaxEnabled: e.target.checked,
                        taxInfo: []
                      })
                    }
                    color="primary"
                  />
                </div>
              </div>

              {this.state.isTaxEnabled && (
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="taxInfo"
                    style={{
                      width: "25%",
                      textAlign: "left",
                      marginRight: "22px"
                    }}
                  >
                    Tax Plan
                  </label>
                  <select
                    name="taxInfo"
                    id="taxInfo"
                    type="number"
                    className="form-control"
                    style={{ width: "75%" }}
                    min={1}
                    onChange={e => {
                      const item = JSON.parse(
                        this.global.organizationInfo.stripeTaxId[e.target.value]
                      );
                      this.setState({
                        [e.target.name]: [
                          {
                            id: item.id,
                            percentage: item.percentage,
                            jurisdiction: item.jurisdiction,
                            display_name: item.display_name,
                            description: item.description
                          }
                        ]
                      });
                    }}
                  >
                    <option value={null}>Please Select:</option>
                    {this.global.organizationInfo.stripeTaxId.map((ele, i) => {
                      const e = typeof ele === "string" ? JSON.parse(ele) : ele;
                      return (
                        <>
                          <option value={i}>
                            {e.display_name} {e.jurisdiction} {e.percentage}%
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              )}
            </form>
            <div className="modal-footer">
              <button
                type="submit"
                onClick={_ => this.savePlan()}
                className="btn profile-btn"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddLeadModal);
