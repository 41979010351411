import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import {
  DROPDOWN,
  STRING_INPUT,
  SELECT_MULTIPLE_DROPDOWN,
  DATE_PICKER,
  SWITCH,
  CONTENT_BLOCK,
  TEXT_AREA,
  GET_HTML
} from "./baseModal/FieldTypes";
import { notEmptyString } from "./baseModal/validators";
import { BLUE_BUTTON, RED_BUTTON, WHITE } from "./baseModal/colours";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";

class EventAttendeeListPDF extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {

  }

  downloadPDF = () => {
    const allFields = this.getBaseModalFields();
    const numFields = allFields.length;
    const domElementIds = [];

    // Determine the number of "base-modal-content-#" to add
    const numBaseModalContents = Math.ceil(numFields / 10);

    // Loop to create the "base-modal-content-#" IDs
    for (let i = 1; i <= numBaseModalContents; i++) {
      domElementIds.push(`base-modal-content-${i}`);
    }

    this.setGlobal({ loading: true });

    const renderPage = (pdf, domElementId, pageNumber) => {
      const domElement = document.getElementById(domElementId);
      html2canvas(domElement, {
        scale: 3,
        scrollX: 0,
        scrollY: 0
      })
        .then(canvas => {
          const PDF_MARGIN = 20;
          const PDF_WIDTH = 140;
          const MAX_HEIGHT = 250;

          const img = canvas.toDataURL("image/png");
          const imgProps = pdf.getImageProperties(img);

          let width = pdf.internal.pageSize.getWidth() - 40;
          let height = (imgProps.height * width) / imgProps.width;

          if (height > MAX_HEIGHT) {
            height = MAX_HEIGHT;
            width = (imgProps.width * height) / imgProps.height;
          }

          if (pageNumber > 1) {
            pdf.addPage();
          }

          pdf.addImage(img, "JPEG", PDF_MARGIN, PDF_MARGIN, width, height);

          // If there are more elements to render, proceed to the next one.
          const nextIndex = domElementIds.indexOf(domElementId) + 1;
          if (nextIndex < domElementIds.length) {
            renderPage(pdf, domElementIds[nextIndex], pageNumber + 1);
          } else {
            pdf.save("Attendees.pdf");
            this.setGlobal({ loading: false });
          }
        })
        .catch(error => {
          toast.error("Could not generate PDF.");
          this.setGlobal({ loading: false });
        });
    };

    const pdf = new jsPdf("p", "mm", "letter", true);
    renderPage(pdf, domElementIds[0], 1);
  };

  getAttendeeHTML = () => {
    const customer = this.props.customers
    const element = (
      <div
        id="attendee-list"
        style={{
          padding: "10px 0px",
          fontFamily: "Montserrat",
          width: "100%"
        }}
      >
        <div
          style={{
            fontSize: "20px",
            marginBottom: "20px",
            width: "100%"
          }}
        >
          {this.props.data?.event_title} Attendees:
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: "35% 65%",
            gap: "5px",
            fontSize: "14px",
          }}
        >

          <div style={{
            width: "100%",
            fontFamily: "Montserrat",
            padding: "2px 0px",
            textDecoration: "underline"
          }}><b>Enrolled:</b></div>
          <div></div>

          <div style={{
            fontFamily: "Montserrat",
            padding: "2px 0px"
          }}><b>Name</b></div>
          <div style={{
            fontFamily: "Montserrat",
            padding: "2px 0px"
          }}><b>Email</b></div>

          {customer.Attendees.map(attendee => {
            return <>
              <div style={{
                fontFamily: "Montserrat",
                padding: "2px 0px",
                overflowWrap: "break-word"
              }}>{attendee.displayName}</div>
              <div style={{
                fontFamily: "Montserrat",
                padding: "2px 0px",
                overflowWrap: "break-word"
              }}>{attendee.email}</div>
            </>
          })}

          <div style={{
            width: "100%",
            fontFamily: "Montserrat",
            padding: "2px 0px",
            paddingTop: "20px",
            textDecoration: "underline"
          }}><b>Applications:</b></div>
          <div></div>

          <div style={{
            fontFamily: "Montserrat",
            padding: "2px 0px"
          }}><b>Name</b></div>
          <div style={{
            fontFamily: "Montserrat",
            padding: "2px 0px"
          }}><b>Email</b></div>

          {customer.Applications.map(attendee => {
            return <>
              <div style={{
                fontFamily: "Montserrat",
                padding: "2px 0px",
                overflowWrap: "break-word"
              }}>{attendee.displayName}</div>
              <div style={{
                fontFamily: "Montserrat",
                padding: "2px 0px",
                overflowWrap: "break-word"
              }}>{attendee.email}</div>
            </>
          })}
        </div>
      </div >
    )
    return element
  }

  getBaseModalFields() {
    const fields = [];

    const attendeeList = {
      type: GET_HTML,
      data: {
        gethtml: this.getAttendeeHTML
      }
    };

    fields.push(attendeeList)

    return fields;
  }

  getBaseModalButtons = () => {
    const downloadButton = {
      name: "Download",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => {
        this.downloadPDF()
      }
    };

    return [downloadButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Attendee List PDF",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(EventAttendeeListPDF);
