import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  DROPDOWN,
  DATE_PICKER,
  SELECT_MULTIPLE_DROPDOWN,
  TEXT_AREA,
  BUTTON,
  CONTENT_BLOCK
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class RefundModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: null,
      reason: "",
      memo: "",
      amount: 0,
      options: "Refund",
      stripeTransactionId: "",
      showARN: false,
      ARNData: []
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async submit() {
    console.log("submitting refund");
    if (this.state.amount < 10) {
      toast.error("Please enter a larger amount");
      return;
    }

    if (this.state.options === "Refund") {
      const ep2 = `${process.env.REACT_APP_API}/partners/stripe/get-charge/${this.props.selectedItem.invoice}`;
      const charge = await axios.get(ep2);
      console.log("the charge is", charge);

      console.log("selectedItem", this.props.selectedItem);
      const ep = `${process.env.REACT_APP_API}/partners/stripe/refund`;
      const res = await axios.post(ep, {
        charge:
          this.props.usage === "recurring_payments"
            ? this.props.selectedItem.justDeposit
              ? this.props.selectedItem.depositId
              : this.props.selectedItem.charge?.id || charge.data.data.charge
            : // : this.props.selectedItem.stripe_transaction_id.includes("pi_")
            this.state.stripeTransactionId.includes("pi_")
            ? null
            : this.state.stripeTransactionId.includes("ch_")
            ? this.state.stripeTransactionId
            : this.props.selectedItem.id,
        amount: this.state.amount,
        reason: this.state.reason,
        // payment_intent: this.props.selectedItem.stripe_transaction_id.includes(
        //   "pi_"
        // )
        payment_intent: this.state.stripeTransactionId?.includes("pi_")
          ? // ? this.props.selectedItem.stripe_transaction_id
            this.state.stripeTransactionId
          : null,
        orderId: this.props.selectedItem.id
      });
      console.log("res", res);
      if (res.data.success) {
        toast.success("Refund Successful!");
        this.props.onClose();
        this.props.refresh();
      }
    }
    if (this.state.options === "Credit") {
      const ep = `${process.env.REACT_APP_API}/partners/stripe/refund/credit`;
      const res = await axios.post(ep, {
        invoice: this.props.selectedItem.id,
        amount: this.state.amount,
        memo: this.state.memo,
        reason: this.state.reason
      });

      console.log("res", res);
      if (res.data.success) {
        toast.success("Credit Issue Successful!");
        this.props.onClose();
      }
    }
  }

  async componentDidMount() {
    this.setState({
      current:
        this.props.usage === "recurring_payments"
          ? this.props.selectedItem.amount_paid || this.props.selectedItem.total
          : this.props.selectedItem.total,
      amount:
        this.props.usage === "recurring_payments"
          ? this.props.selectedItem.amount_paid || this.props.selectedItem.total
          : this.props.selectedItem.total
    });
    this.setState({
      stripeTransactionId: this.getStripeTransactionId(this.props.selectedItem)
    });

    const ep = `${process.env.REACT_APP_API}/partners/stripe/refund/history/${this.props.selectedItem.stripe_transaction_id}`;
    const refundHistory = await axios.get(ep);

    console.log("refund history", refundHistory);

    console.log("selectedItem", this.props.selectedItem);
  }

  getStripeTransactionId(selectedItem) {
    console.log("thei selected item is", selectedItem);
    let stripeTransactionId;
    // if (selectedItem?.id) {
    //   stripeTransactionId = selectedItem.id;
    // }
    if (selectedItem?.stripe_transaction_id) {
      stripeTransactionId = selectedItem.stripe_transaction_id;
    }
    if (selectedItem?.payment_intent) {
      stripeTransactionId = selectedItem.payment_intent;
    }
    if (selectedItem?.stripe?.id) {
      stripeTransactionId = selectedItem.stripe.id;
    }
    // if (selectedItem?.stripe?.id) {
    //   stripeTransactionId = selectedItem.stripe.id;
    // }
    console.log("the stripe transaction id is", stripeTransactionId);
    return stripeTransactionId;
  }

  /// ================== NEW MODAL ========================== ///

  /*   refundTypeOptions = () => {
    if (this.state.stripeTransactionId.includes("pi_")) {
      return [];
    } else if (!this.state.stripeTransactionId.includes("pi_")) {
      return [
        { value: "Refund", text: "Refund to their Bank" },
        { value: "Credit", text: "Credit Customer's Balance" }
      ];
    } else {
      return [{ value: "Refund", text: "Refund to their Bank" }];
    }
  }; */

  refundTypeOptions = () => {
    const result = [];
    result.push({ value: "Refund", text: "Refund to their Bank" });
    /* Credit Customer's Balance option commented out as requested by Moh. -Josh
    if (!this.state.stripeTransactionId?.includes("pi_")) {
      result.push({ value: "Credit", text: "Credit Customer's Balance" });
    }
    */
    return result;
  };

  reasonOptions = () => {
    if (this.state.options === "Refund") {
      return [
        { value: "duplicate", text: "Duplicate Charge" },
        { value: "fraudulent", text: "Fradulent Charge" },
        { value: "requested_by_customer", text: "Requested By Customer" }
      ];
    } else {
      return [
        { value: "duplicate", text: "Duplicate Charge" },
        { value: "fraudulent", text: "Fradulent Charge" },
        { value: "product_unsatisfactory", text: "Product Unsatisfactory" },
        { value: "order_change", text: "Order Change" }
      ];
    }
  };

  handleFetchARN = async () => {
    this.setState({
      showARN: true
    });
    const ep = `${process.env.REACT_APP_API}/partners/stripe/refund/history/${this.props.selectedItem.stripe_transaction_id}`;
    const response = await axios.get(ep);
    console.log("response", response);
    if (response?.data?.success) {
      const data = response.data.data.filter(e => e.valid);
      console.log("data", data);

      if (data.length > 0) {
        this.setState({
          ARNData: response.data.data.map(e => ({
            text: e.ARN,
            amount: e.amount
          }))
        });
      } else {
        this.setState({
          ARNData: [
            {
              text: "No ARN found. Either this refund does not have one or it is still being generated (may take up to 3 days)",
              amount: ""
            }
          ]
        });
      }
    } else {
      this.setState({
        ARNData: [
          {
            text: "No ARN found. Either this refund does not have one or it is still being generated (may take up to 3 days)",
            amount: ""
          }
        ]
      });
    }
  };

  getBaseModalFields() {
    const currentInput = {
      type: STRING_INPUT,
      data: {
        name: "Current",
        required: false,
        isDisabled: true,
        value: `$ ${
          this.state.current ? (this.state.current / 100).toFixed(2) : 0
        }`
      }
    };

    const refundInput = {
      type: STRING_INPUT,
      data: {
        name: "Refund To-Date",
        required: false,
        isDisabled: true,
        placeholder: "",
        value: `$ ${
          this.props.amountRefunded
            ? (this.props.amountRefunded / 100).toFixed(2)
            : 0
        }`
      }
    };

    const creditInput = {
      type: STRING_INPUT,
      data: {
        name: "Credit To-Date",
        required: false,
        isDisabled: true,
        placeholder: "",
        value: `$ ${
          this.props.post_payment_credit_notes_amount
            ? (this.props.post_payment_credit_notes_amount / 100).toFixed(2)
            : 0
        }`
      }
    };

    const refundType = {
      type: DROPDOWN,
      data: {
        name: "Refund Type",
        required: false,
        placeholder: "Please Select",
        //value: this.state.options,
        handleChange: e => {
          this.setState({
            options: e.target.value,
            reason: ""
          });
        },
        choices: this.refundTypeOptions()
      }
    };

    const reasonInput = {
      type: DROPDOWN,
      data: {
        name: "Reason",
        placeholder: "Please Select",
        required: true,
        handleChange: e => {
          this.setState({
            reason: e.target.value
          });
        },
        choices: this.reasonOptions(),
        value: this.state.reason
      },
      validators: {
        validateSubmit: () => this.state.reason !== ""
      }
    };

    const memoInput = {
      type: TEXT_AREA,
      data: {
        name: "Memo",
        required: false,
        isDisabled: this.state.options === "Refund",
        placeholder: "",
        handleChange: e => {
          this.setState({
            memo: e.target.value
          });
        }
      }
    };

    const amountInput = {
      type: STRING_INPUT,
      data: {
        name: "Amount",
        type: "number",
        required: true,
        placeholder: "",
        handleChange: e => {
          const regex = /^\d*\.?\d{1,2}$/;
          if (e.target.value === "") {
            this.setState({
              amount: ""
            });
          }
          if (regex.test(e.target.value)) {
            this.setState({
              amount: Math.round(e.target.value * 100)
            });
          }
        },
        value: this.state.amount === "" ? "" : this.state.amount / 100
      },
      validators: {
        validateSubmit: () => this.state.amount !== 0 && this.state.amount !== ""
      }
    };

    const ARNButton = {
      type: BUTTON,
      data: {
        name: "",
        buttonText: "Fetch ARN",
        handleClick: this.handleFetchARN,
        buttonColour: BLUE_BUTTON,
        textColour: WHITE
      }
    };

    const ARNFieldTitle = {
      type: CONTENT_BLOCK,
      data: {
        name: "Acquirer Reference Number",
        content: ""
      }
    };

    const ARNFields = this.state.ARNData.map(e => ({
      type: CONTENT_BLOCK,
      data: {
        name: "",
        content: `${e.text}${e.amount !== "" ? ` ($${e.amount / 100})` : ""}`
      }
    }));

    let fields = [];
    fields.push(currentInput);
    fields.push(refundInput);
    fields.push(creditInput);
    fields.push(refundType);
    fields.push(reasonInput);
    fields.push(memoInput);
    fields.push(amountInput);

    // Hidden Button

    /*     if (this.state.showARN) {
      fields.push(ARNFieldTitle);
      fields = fields.concat(ARNFields);
    } else {
      fields.push(ARNButton);
    } */

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.submit()
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Refunds & Credits",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    // console.log("this.props", this.props);
    // console.log("this.state", this.state);
    console.log("Amount", this.state.amount);
    return <BaseModal {...this.getBaseModalProps()} />;

    // return (
    //   <div className="request-modal-cont">
    //     <div className="request-modal lesspadding">
    //       <div style={{ display: "block" }}>
    //         <img
    //           src={ModalBanner}
    //           alt="Modal Banner"
    //           style={{
    //             height: "40px",
    //             width: "100%",
    //             position: "absolute",
    //             top: 0,
    //             left: 0
    //           }}
    //         />
    //         <div
    //           style={{
    //             display: "flex",
    //             justifyContent: "space-between",
    //             alignItems: "center",
    //             height: "48px",
    //             marginBottom: ".5rem"
    //           }}
    //         >
    //           <h3>Refunds & Credits</h3>
    //           <IconButton
    //             style={{
    //               borderRadius: "50%",
    //               color: "#2880FF",
    //               marginBottom: "1rem"
    //             }}
    //             onClick={_ => this.props.onClose()}
    //           >
    //             <Close />
    //           </IconButton>
    //         </div>
    //         <form>
    //           <div
    //             className="form-group"
    //             style={{
    //               display: "flex",
    //               justifyContent: "space-between",
    //               alignItems: "center",
    //               marginBottom: 0
    //             }}
    //           >
    //             <label
    //               htmlFor="current"
    //               style={{ width: "25%", textAlign: "left" }}
    //             >
    //               Current
    //             </label>
    //             <div
    //               style={{
    //                 width: "70%",
    //                 fontSize: ".9rem",
    //                 fontWeight: "400",
    //                 lineHeight: "1.5",
    //                 color: "#8c8ea0"
    //               }}
    //             >
    //               $
    //               {this.state.current
    //                 ? (this.state.current / 100).toFixed(2)
    //                 : 0}
    //             </div>
    //           </div>
    //           <div
    //             className="form-group"
    //             style={{
    //               display: "flex",
    //               justifyContent: "space-between",
    //               alignItems: "center",
    //               marginBottom: 0
    //             }}
    //           >
    //             <label
    //               htmlFor="refunded"
    //               style={{ width: "25%", textAlign: "left" }}
    //             >
    //               Refund To-Date
    //             </label>
    //             <div
    //               style={{
    //                 width: "70%",
    //                 fontSize: ".9rem",
    //                 fontWeight: "400",
    //                 lineHeight: "1.5",
    //                 color: "#8c8ea0"
    //               }}
    //             >
    //               $
    //               {this.props.amountRefunded
    //                 ? (this.props.amountRefunded / 100).toFixed(2)
    //                 : 0}
    //             </div>
    //           </div>
    //           <div
    //             className="form-group"
    //             style={{
    //               display: "flex",
    //               justifyContent: "space-between",
    //               alignItems: "center",
    //               marginBottom: 0
    //             }}
    //           >
    //             <label
    //               htmlFor="refunded"
    //               style={{ width: "25%", textAlign: "left" }}
    //             >
    //               Credit To-Date
    //             </label>
    //             <div
    //               style={{
    //                 width: "70%",
    //                 fontSize: ".9rem",
    //                 fontWeight: "400",
    //                 lineHeight: "1.5",
    //                 color: "#8c8ea0"
    //               }}
    //             >
    //               $
    //               {this.props.post_payment_credit_notes_amount
    //                 ? (
    //                     this.props.post_payment_credit_notes_amount / 100
    //                   ).toFixed(2)
    //                 : 0}
    //             </div>
    //           </div>
    //           <div
    //             className="form-group"
    //             style={{
    //               display: "flex",
    //               justifyContent: "space-between",
    //               alignItems: "center",
    //               marginBottom: 0
    //             }}
    //           >
    //             <label
    //               htmlFor="options"
    //               style={{ width: "25%", textAlign: "left" }}
    //             >
    //               Refund Type
    //             </label>
    //             <select
    //               name="options"
    //               id="options"
    //               type="text"
    //               className="form-control"
    //               style={{ width: "70%" }}
    //               onChange={e => {
    //                 this.setState({
    //                   [e.target.name]: e.target.value,
    //                   reason: ""
    //                 });
    //               }}
    //               value={this.state.options}
    //             >
    //               <option value="Refund">Refund to their Bank</option>
    //               {this.state.stripeTransactionId.includes("pi_") ? null : (
    //                 <option value="Credit">Credit Customer's Balance</option>
    //               )}
    //               {/* {this.getStripeTransactionId(this.props.selectedItem)}if (stripeTransactionId.includes("pi_")) {
    //                   return null;
    //                 } else {
    //                   return (
    //                     <option value="Credit">
    //                       Credit Customer's Balance
    //                     </option>
    //                   );
    //                 }
    //               })()} */}
    //             </select>
    //           </div>
    //           <div
    //             className="form-group"
    //             style={{
    //               display: "flex",
    //               justifyContent: "space-between",
    //               alignItems: "center",
    //               marginBottom: 0
    //             }}
    //           >
    //             <label
    //               htmlFor="reason"
    //               style={{ width: "25%", textAlign: "left" }}
    //             >
    //               Reason <span style={{ color: "orange" }}>*</span>
    //             </label>
    //             <select
    //               name="reason"
    //               id="reason"
    //               type="text"
    //               className="form-control"
    //               style={{ width: "70%" }}
    //               onChange={e => {
    //                 this.setState({
    //                   [e.target.name]: e.target.value
    //                 });
    //               }}
    //               value={this.state.reason}
    //             >
    //               <option value="">Please Select</option>
    //               {this.state.options === "Refund" ? (
    //                 <>
    //                   <option value="duplicate">Duplicate Charge</option>
    //                   <option value="fraudulent">Fradulent Charge</option>
    //                   <option value="requested_by_customer">
    //                     Requested By Customer
    //                   </option>
    //                 </>
    //               ) : (
    //                 <>
    //                   <option value="duplicate">Duplicate Charge</option>
    //                   <option value="fraudulent">Fradulent Charge</option>
    //                   <option value="product_unsatisfactory">
    //                     Product Unsatisfactory
    //                   </option>
    //                   <option value="order_change">Order Change</option>
    //                 </>
    //               )}
    //             </select>
    //           </div>
    //           <div
    //             className="form-group"
    //             style={{
    //               display: "flex",
    //               justifyContent: "flex-start",
    //               alignItems: "center",
    //               marginBottom: 0
    //             }}
    //           >
    //             <label
    //               htmlFor="memo"
    //               style={{
    //                 width: "25%",
    //                 textAlign: "left",
    //                 marginRight: "22px"
    //               }}
    //             >
    //               Memo
    //             </label>
    //             <textarea
    //               disabled={this.state.options === "Refund"}
    //               name="memo"
    //               id="memo"
    //               rows="4"
    //               cols="100"
    //               className="form-control"
    //               style={{ width: "75%" }}
    //               onChange={e => {
    //                 this.setState({
    //                   [e.target.name]: e.target.value
    //                 });
    //               }}
    //             />
    //           </div>
    //           <div
    //             className="form-group"
    //             style={{
    //               display: "flex",
    //               justifyContent: "space-between",
    //               alignItems: "center",
    //               marginBottom: 0
    //             }}
    //           >
    //             <label
    //               htmlFor="amount"
    //               style={{ width: "25%", textAlign: "left" }}
    //             >
    //               Amount <span style={{ color: "orange" }}>*</span>
    //             </label>
    //             <input
    //               name="amount"
    //               id="amount"
    //               type="number"
    //               className="form-control"
    //               style={{ width: "70%" }}
    //               onChange={e => {
    //                 this.setState({
    //                   [e.target.name]: Math.round(e.target.value * 100)
    //                 });
    //               }}
    //               value={this.state.amount / 100}
    //             />
    //           </div>
    //         </form>
    //         <div className="modal-footer">
    //           <button
    //             type="submit"
    //             onClick={_ => this.submit()}
    //             className="btn profile-btn"
    //           >
    //             Submit
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );
  }
}

export default withRouter(RefundModal);
