import React, { useGlobal } from "reactn";
import { formTypes, BoxForm } from "../../../../components/UI/form";
import { MiniPlus } from "../../../../assets";
import ImageCropModal from "../../../../components/modals/ImageCropModal";

class SectionThree extends React.PureComponent {
  constructor(props) {
    super(props);
    /*this.setAddress = this.setAddress.bind(this);
    this.customForm = this.customForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._geoSuggest = null;
    this._geoSuggestPrev = null;*/
    this.state = { location: "", openImageCropModal: false, image: "" };
  }
  handleChange = (e, v) => {
    this.setGlobal({
      news_and_updates: {
        ...this.global.news_and_updates,
        [e]: v
      }
    });
  };
  uploadNew(image) {
    this.setState({ location: image });
    const uploadEle = document.getElementById("uploadPhoto");
    uploadEle.click();
  }
  fileChange(e) {
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      // const fd = new FormData();
      // fd.set("image", e.target.files[0]);
      // fd.set("location", this.state.location);
      // fd.set("oldVal", this.global.news_and_updates[this.state.location]);
      // this.dispatch.uploadNewsPhoto(fd);
      const file = new FileReader();
      file.readAsDataURL(e.target.files[0]);
      file.onloadend = () => {
        document.getElementById("uploadPhoto").value = "";
        this.setState({ image: file.result, openImageCropModal: true });
      };
    }
  }
  deleteImage(image, field_name) {
    this.setGlobal({
      news_and_updates: {
        ...this.global.news_and_updates,
        [field_name]: this.global.news_and_updates[field_name].filter(
          e => e !== image
        )
      }
    });
    this.dispatch.deleteNewsPhoto(image);
    this.dispatch.saveNews();
  }

  customForm = () => {
    return (
      <div className="formbody">
        {this.state.openImageCropModal && (
          <ImageCropModal
            sourceImage={this.state.image}
            onClose={() => {
              this.setState({ openImageCropModal: false });
            }}
            aspectRatio={1 / 1}
            uploadImage={croppedImageURL => {
              const fd = new FormData();
              fd.set("image", croppedImageURL);
              fd.set("location", this.state.location);
              fd.set(
                "oldVal",
                this.global.news_and_updates[this.state.location]
              );
              this.dispatch.uploadNewsPhoto(fd);
              this.setState({ openImageCropModal: false });
            }}
          />
        )}
        {/* {!this.state.timeChangeShown ? null : (
          <ConfirmTimeChangeForm onClose={this.setTime} />
        )} */}
        <form>
          <div className="row">
            <div className="col-12">
              <h3 className="label-names">Additional Bottom Section</h3>
            </div>
            <div className="col-12 d-flex">
              <div className={`w-100`}>
                <textarea
                  className="input-text-fields"
                  name="additional_text"
                  rows={8}
                  style={{ width: "100%" }}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.news_and_updates.additional_text}
                />
              </div>
            </div>
          </div>

          <div className="col-5">
            <h3 className="label-names">Additional Gallery</h3>
          </div>
          <div className="col-12 d-flex">
            {this.global.news_and_updates.additional_gallery.map(
              (item, index) => {
                return (
                  <figure
                    className="photoContainer"
                    style={{
                      width: "23%",
                      textAlign: "center",
                      marginLeft: "6px",
                      height: "80px"
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        padding: "2px",
                        width: "80px",
                        height: "80px",
                        border: "1px solid #e3e3e3",
                        display: "inline-block",
                        borderRadius: "8px",
                        cursor: "pointer",
                        backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${item})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat"
                      }}
                      id={index}
                    >
                      <div className="photoContainerX">
                        <div
                          className="innerX"
                          onClick={() =>
                            this.deleteImage(item, "additional_gallery")
                          }
                        >
                          <i className="far fa-times-circle"></i>
                        </div>
                      </div>
                    </div>
                  </figure>
                );
              }
            )}
            {new Array(
              4 - this.global.news_and_updates.additional_gallery.length
            )
              .fill(4)
              .map((e, i) => (
                <figure
                  className="photoContainer"
                  style={{
                    width: "25%",
                    textAlign: "center",
                    marginLeft: "6px"
                  }}
                  onClick={_ => this.uploadNew("additional_gallery")}
                  key={i}
                >
                  <div
                    style={{
                      padding: "2px",
                      width: "80px",
                      height: "80px",
                      border: "1px solid #e3e3e3",
                      display: "inline-block",
                      borderRadius: "8px",
                      cursor: "pointer"
                    }}
                  >
                    <img
                      src={MiniPlus}
                      alt="Add"
                      style={{ marginTop: "calc(50% - 4.5px)" }}
                    />
                  </div>
                </figure>
              ))}
          </div>
          <input
            type="file"
            style={{ display: "none" }}
            id="uploadPhoto"
            onChange={e => this.fileChange(e)}
            accept="image/x-png,image/gif,image/jpeg"
          />
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Organization Info"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionThree;
