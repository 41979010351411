import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";
import { MiniPlus } from "../../../../assets";

class SectionTwo extends React.PureComponent {
  handleChange = (e, v) => {
    this.setGlobal({
      organizationInfo: {
        ...this.global.organizationInfo,
        [e]: v
      }
    });
  };
  uploadNew() {
    const uploadEle = document.getElementById("uploadPhoto");
    uploadEle.click();
  }

  fileChange(e) {
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      const fd = new FormData();
      fd.set("type", "gallery");
      fd.set("image", e.target.files[0]);
      this.dispatch.uploadProgramPhoto(fd);
    }
  }

  customForm = () => {
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-12">
              <h3>
                Oraganization Description
                <span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-12 d-flex">
              <div className={`w-100`}>
                <textarea
                  maxlength="1250"
                  rows="10"
                  style={{
                    width: "100%",
                    border:
                      this.global.organizationInfo?.description?.length < 100
                        ? "1px solid red"
                        : "1px solid grey"
                  }}
                  name="description"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.description}
                ></textarea>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <div>Min: 100 characters</div>
                  <div>
                    {this.global.organizationInfo?.description
                      ? this.global.organizationInfo?.description?.length
                      : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-5">
              <h3>Gallery</h3>
            </div>
            <div className="col-12 d-flex" style={{ flexWrap: "wrap" }}>
              {this.global.organizationInfo.gallery.map((item, index) => {
                return (
                  <figure
                    className="photoContainer"
                    style={{
                      width: "23%",
                      textAlign: "center",
                      marginLeft: "6px",
                      height: "80px"
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        padding: "2px",
                        width: "80px",
                        height: "80px",
                        border: "1px solid #e3e3e3",
                        display: "inline-block",
                        borderRadius: "8px",
                        cursor: "pointer",
                        backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${item})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat"
                      }}
                      id={index}
                    >
                      <div
                        className="photoContainerX"
                        onClick={_ =>
                          this.setGlobal({
                            organizationInfo: {
                              ...this.global.organizationInfo,
                              gallery:
                                this.global.organizationInfo.gallery.filter(
                                  (_, i) => i !== index
                                )
                            }
                          })
                        }
                      >
                        <div className="innerX">
                          <i className="far fa-times-circle"></i>
                        </div>
                      </div>
                    </div>
                  </figure>
                );
              })}
              {new Array(8 - this.global.organizationInfo.gallery.length)
                .fill(4)
                .map((e, i) => (
                  <figure
                    className="photoContainer"
                    style={{
                      width: "23%",
                      textAlign: "center",
                      marginLeft: "6px"
                    }}
                    onClick={this.uploadNew}
                    key={i}
                  >
                    <div
                      style={{
                        padding: "2px",
                        width: "80px",
                        height: "80px",
                        border: "1px solid #e3e3e3",
                        display: "inline-block",
                        borderRadius: "8px",
                        cursor: "pointer"
                      }}
                    >
                      <img
                        src={MiniPlus}
                        alt="Add"
                        style={{ marginTop: "calc(50% - 4.5px)" }}
                      />
                    </div>
                  </figure>
                ))}
              <input
                type="file"
                style={{ display: "none" }}
                id="uploadPhoto"
                onChange={e => this.fileChange(e)}
                accept="image/x-png,image/gif,image/jpeg"
              />
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Organization Info"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionTwo;
