import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { addWeeks, format, subMonths, subWeeks, addMonths, isBefore, isAfter, isSameDay } from "date-fns";
import { addDays } from "date-fns/esm";
import { toast } from "react-toastify";
import _, { toInteger } from "lodash";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  DATE_PICKER,
  IMAGE_DROPDOWN,
  GET_HTML,
  SWITCH,
  DROPDOWN
} from "./baseModal/FieldTypes";
import Title from "./baseModal/fieldComponents/Title.jsx";
import DatePicker from "react-datepicker";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class EditDropInPlan extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      startDate: this.props.selectedItem.startDate ? new Date(this.props.selectedItem.startDate) : new Date(),
      endDate: null,
      total_price: null,
      available_days: [],
      drop_in_days: [],
      isDescriptionEnabled: false,
      enableSpecificDates: false,
      selectedDates: [],
      description: "",
      isTaxEnabled: false,
      isCapEnabled: false,
      taxInfo: [],
      planCap: "",
      startTime: "",
      endTime: "",
      linkedSemesterPlan: undefined,
      premium: 0
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  componentDidMount() {
    this.setState({
      ...this.props.selectedItem,
      drop_in_days: this.props.selectedItem.drop_in_days.map(day => new Date(day))
    });
    this.getTotalDays();
  }

  getTotalDays() {
    const days = {
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
      0: "Sunday"
    };
    let startDate = new Date(this.global.program.program_start);
    let endDate;

    if (this.global.program.isRecurring) {
      endDate = addMonths(startDate, 24); // This is for recurring programs (by default we display 2 years / 24 months)
    } else {
      endDate = addWeeks(startDate, this.global.program.number_of_weeks);
    }
    let dayCount = 0;
    let okdays = [];

    let date = startDate;
    while (isBefore(date, endDate)) {
      console.log(date);
      if (this.global.program.closure_timeframe[0] &&
        this.global.program.closure_timeframe[1] &&
        isAfter(date, new Date(this.global.program.closure_timeframe[0]) && 
        isBefore(date, new Date(this.global.program.closure_timeframe[1])))) {
        date = addDays(date, 1);
        continue;
      }
      let isClosed = false;
      for (let day of this.global.program.closure_dates) {
        if (isSameDay(day, date)) {
          isClosed = true;
          break;
        }
      }

      if (isClosed) {
        date = addDays(date, 1);
        continue;
      }
      
      if (this.global.program.days_of_week[days[date.getDay()]]) {
        okdays.push(date);
        dayCount++;
      }

      date = addDays(date, 1);
    }
   
    this.setState({ dayCount: dayCount, available_days: okdays, drop_in_days: okdays });
  }

  async savePlan() {
    if (!this.global.organizationInfo.stripeId) {
      toast.error("Denied. Please connect your Bank Account first");
      return;
    }
    if (!this.state.total_price || this.state.total_price <= 0) {
      toast.error("Please enter a valid price.");
      return;
    }
    if (this.state.isTaxEnabled && this.state.taxInfo.length == 0) {
      toast.error("Please select a tax plan, otherwise disable taxes");
      return;
    }
   
    const obj = { ...this.state, type: "Drop-In" };
    delete obj.available_days;
    console.log("Object: ", obj);
    if (
      this.global.program.total_spots &&
      this.global.program.total_spots < parseInt(obj.planCap)
    ) {
      obj.planCap = this.global.program.total_spots;
    } else {
      if (parseInt(obj.planCap) > 200) {
        obj.planCap = 200;
      }
    }
    // obj.total_price = parseFloat(obj.total_price).toFixed(2);
    console.log("Object after if: ", obj);
    const parsedPlans = this.global.program.plans.map(e =>
      typeof e === "string" ? JSON.parse(e) : e
    );
    const updatedPlans = parsedPlans.map(e =>
      e.id === obj.id ? { ...obj } : e
    );

    console.log("Updated Plans:", updatedPlans);

    this.setGlobal(
      {
        program: {
          ...this.global.program,
          plans: updatedPlans
        }
      },
      () => {
        this.dispatch.saveProgram();
      }
    );

    this.props.onClose();
  }

  taxesOptions = () => {
    //const global_organization = [JSON.parse(this.global.organizationInfo.stripeTaxId)]
    const global_organization = this.global.organizationInfo.stripeTaxId;
    console.log("global_organization", global_organization);
    const DATA = [];
    for (let i = 0; i < global_organization.length; i++) {
      DATA.push(JSON.parse(global_organization[i]));
    }
    console.log("DATA", DATA);

    return [
      ...DATA.map((e, i) => ({
        value: i,
        text: e.display_name + " " + e.jurisdiction + e.percentage + "%"
      }))
    ];
  };

  semesterLinkOptions = () => {
    const semesterPlans = this.global.program.plans.filter(plan => {
      console.log("PLAN:", plan);
      try {
        if (typeof plan === "string") {
          const parsedPlan = JSON.parse(plan.trim());
          return (parsedPlan.type === "Semester" || !parsedPlan.type) && !parsedPlan.archived;
        }
      } catch (error) {
        console.error(error);
        console.log("Error Plan:", plan);
        return false;
      }

      return (plan.type === "Semester" || !plan.type) && !plan.archived;
    });
    return semesterPlans.map(plan => {
      let parsedPlan = plan;
      if (typeof plan === "string") {
        try {
          parsedPlan = JSON.parse(plan.trim());
        } catch (error) {
          console.error(error);
          console.log("Error Plan:", plan);
        }
      }
      const price = parseFloat(parsedPlan.total_price || parsedPlan.tuition_rate).toFixed(2);
      const name = (parsedPlan.type || "Recurring") + 
        (parsedPlan.description 
          ? `: ${parsedPlan.description.substring(0, 7)}...`
          : "");
      return {
        value: parsedPlan.id,
        text: `${name} | $${price}`
      }
    });
  }

  getBaseModalFields() {
    const pricing = {
      type: STRING_INPUT,
      data: {
        name: "Drop-in Price",
        required: true,
        type: "number",
        handleChange: e => {
          if (e.target.value === "") {
            this.setState({
              total_price: null
            });
            return;
          }
  
          this.setState({
            total_price: parseFloat(e.target.value)
          });
        },
        value: this.state.total_price,
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      },
      validators: { validateSubmit: () => this.state.total_price !== null }
    };

    const addDescription = {
      type: SWITCH,
      data: {
        name: "Add Description",
        checked: this.state.isDescriptionEnabled,
        isDisabled: false,
        handleClick: () => {
          this.setState({
            isDescriptionEnabled: !this.state.isDescriptionEnabled
          });
        }
      }
    };

    const description = {
      type: STRING_INPUT,
      data: {
        name: "Description",
        required: false,
        maxLength: 32,
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description
      }
    };

    const enableSpecificDropInDates = {
      type: SWITCH,
      data: {
        name: "Enable Selecting Specific Drop-In Dates",
        isDisabled: false,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        checked: this.state.enableSpecificDates,
        handleClick: e => {
          if (e.target.checked) {
            this.setState({
              enableSpecificDates: true,
              drop_in_days: []
            });
          } else {
            this.setState({
              enableSpecificDates: false,
              drop_in_days: this.state.available_days
            });
          }
        } 
      }
    };

    const selectDropInDates = {
      type: DATE_PICKER,
      data: {
        name: "Select Drop-in Dates",
        isDisabled: false,
        placeHolder: "Select Dates",
        minDate: new Date(this.state.startDate),
        dateFormat: "MMMM d, yyyy",
        handleChange: date => {
          let wasSelected = false;
          for (let day of this.state.drop_in_days) {
            if (isSameDay(day, date)) {
              wasSelected = true;
            }
          }

          if (wasSelected) {
            this.setState({drop_in_days: this.state.drop_in_days.filter((day) => !isSameDay(day, date))});
          } else {
            this.setState({drop_in_days: [...this.state.drop_in_days, date]})
          }
        },
        filterDate: date => {
          for (let day of this.state.available_days) {
            if (isSameDay(day, date)) {
              return true;
            }
          }
          return false;
        },
        selectedDates: this.state.drop_in_days,
        inline: true,
        disabledKeyboardNavigation: true
      },
      validators: { validateSubmit: () => this.state.drop_in_days.length !== 0}
    }

    const EnableTaxes = {
      type: SWITCH,
      data: {
        name: "Enable Taxes",
        isDisabled: false,
        checked: this.state.isTaxEnabled,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: e =>
          this.setState({
            isTaxEnabled: e.target.checked,
            taxInfo: []
          })
      }
    };

    //if (this.state.isTaxEnabled) {
    const selectTaxes = {
      type: DROPDOWN,
      data: {
        name: "Tax Plan",
        required: false,
        placeholder: "Please Select" /* 
        value: this.state.taxInfo, */,
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleChange: e => {
          const item = JSON.parse(
            this.global.organizationInfo.stripeTaxId[e.target.value]
          );
          this.setState({
            taxInfo: [
              {
                id: item.id,
                percentage: item.percentage,
                jurisdiction: item.jurisdiction,
                display_name: item.display_name,
                description: item.description
              }
            ]
          });
        },
        choices: this.taxesOptions()
      } /* ,
      validators: { validateSubmit: () => this.state.programs_picked.length !== 0 } */
    };
    //   return [
    //     descriptionInput,
    //     emailInput,
    //     addsonFee,
    //     /* enrollmentEnable, */ //Hidden switch
    //     /* enableDropIns, */ //Hidden switch
    //     EnableTaxes,
    //     selectTaxes
    //   ];
    // }

    const AddCap = {
      type: SWITCH,
      data: {
        name: "Enable Drop-In Cap",
        isDisabled: false,
        checked: this.state.isCapEnabled,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: e =>{
          if (e.target.checked) {
            this.setState({
              isCapEnabled: true
            });
          } else {
            this.setState({
              isCapEnabled: false,
              planCap: ""
            });
          }

        }
      }
    };

    const PlanCap = {
      type: STRING_INPUT,
      data: {
        type: "number",
        name: this.global.program.total_spots
          ? `Plan Cap (Max: ${this.global.program.total_spots})`
          : "Plan Cap",
        required: false,
        placeholder: "Enter Cap",
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleChange: e => {
          this.setState({
            planCap: e.target.value
          });
        }
      }
    };

    const linkSemesterPlan = {
      type: DROPDOWN,
      data: {
        name: "Link Semester Plan",
        placeholder: "Do not link a Semester Plan",
        value: this.state.linkedSemesterPlan ? this.state.linkedSemesterPlan.id : undefined,
        required: false,
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleChange: e => {
          const planId = e.target.value;
          if (planId === "") {
            this.setState({
              linkedSemesterPlan: undefined,
              total_price: 0
            });
            return;
          }
          const plan = JSON.parse(this.global.program.plans.filter(plan => parseInt(JSON.parse(plan).id) === parseInt(planId)));

          if (this.global.program.isRecurring) {
            switch (plan.billing_cycle) {
              case "month":
                this.setState({
                  linkedSemesterPlan: plan,
                  total_price: parseFloat(plan.tuition_rate) / (4 * plan.days_per_week)
                });
                break;
              case "bi-week":
                this.setState({
                  linkedSemesterPlan: plan,
                  total_price: parseFloat(plan.tuition_rate) / (2 * plan.days_per_week)
                });
                break;
              case "week":
                this.setState({
                  linkedSemesterPlan: plan,
                  total_price: parseFloat(plan.tuition_rate) / plan.days_per_week
                });
                break;
            };
          } else {
            this.setState({ 
              linkedSemesterPlan: plan,
              total_price: plan.total_price / this.state.dayCount
            });
          }
        },
        choices: this.semesterLinkOptions()
      }
    };

    const dropInPremium = {
      type: STRING_INPUT,
      data: {
        name: "Premium (%)",
        isDisabled: false,
        placeholder: "Enter a percentage",
        info: "(Max 100%)",
        type: "number",
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        value: this.state.premium,
        handleChange: e => {
          let value = e.target.value;
          while (parseFloat(value) > 100) {
            value = value.substring(0, value.length-1);
          }

          this.setState({ premium: value });
        }
      }
    };

    const fields = [];

    fields.push(linkSemesterPlan);
    fields.push(pricing);

    if (this.state.linkedSemesterPlan) {
      fields.push(dropInPremium);
    }

    fields.push(addDescription);
    if (this.state.isDescriptionEnabled) {
      fields.push(description);
    }

    fields.push(enableSpecificDropInDates)
    if (this.state.enableSpecificDates) {
      fields.push(selectDropInDates);
    }

    fields.push(EnableTaxes);
    if (this.state.isTaxEnabled) {
      fields.push(selectTaxes);
    }
    fields.push(AddCap);
    if (this.state.isCapEnabled) {
      fields.push(PlanCap);
    }

    return fields;
  }
  getBaseModalButtons = () => {
    const debounceSavePlan = _.debounce(_ => this.savePlan(), 2000, {
      leading: true,
      trailing: false
    });
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => {
        e.preventDefault();
        debounceSavePlan();
      }
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: `Edit Pricing Plan`,
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      height: "65vh",
      midSectionHeight: "49vh"
    };
  };

  render() {
    console.log("this.state", this.state);
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(EditDropInPlan);
