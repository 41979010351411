import React, { useState } from "reactn";
import { MiniPlus } from "../../assets";
import { useEffect } from "reactn";
import { toast } from "react-toastify";
import axios from "axios";
import WelcomePreviewModal from "./WelcomePreviewModal";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  DROPDOWN,
  DATE_PICKER,
  SELECT_MULTIPLE_DROPDOWN,
  TEXT_AREA,
  GET_HTML,
  SWITCH
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

const WelcomePageModal = ({
  onClose,
  onRefresh,
  global,
  data,
  table,
  setGlobal
}) => {
  const [id, setId] = useState(null);
  const [isResourcesChecked, setIsResourcesChecked] = useState(false);
  const [isMilestonesChecked, setIsMilestonesChecked] = useState(false);
  const [isNewsfeedChecked, setIsNewsfeedChecked] = useState(false);
  const [isNotesChecked, setIsNotesChecked] = useState(false);
  const [title, setTitle] = useState("");
  const [navigation, setNavigation] = useState("");
  const [welcome, setWelcome] = useState("");
  const [program, setProgram] = useState({});
  const [image, setImage] = useState("");
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  useEffect(() => {
    (async () => {
      const ep = `${process.env.REACT_APP_API}/partners/welcome/${table}/${data.id}`;

      const results = await axios.get(ep);
      const welcomeData = results.data.data.data;
      if (welcomeData) {
        setTitle(welcomeData.title);
        setNavigation(welcomeData.navigation);
        setWelcome(welcomeData.welcome);
        setIsMilestonesChecked(welcomeData.milestones);
        setIsResourcesChecked(welcomeData.resources);
        setIsNewsfeedChecked(welcomeData.newsfeed);
        setIsNotesChecked(welcomeData.notes);
        setId(welcomeData.id);
        setImage(welcomeData.image);
      }
      if (results.data.data.program) {
        setProgram(results.data.data.program);
      }
    })();
  }, []);
  async function saveWelcome() {
    const ep = `${process.env.REACT_APP_API}/partners/welcome`;
    const results = await axios.post(ep, {
      title,
      navigation,
      welcome,
      program_table: table,
      programId: data.id,
      resources: isResourcesChecked,
      milestones: isMilestonesChecked,
      newsfeed: isNewsfeedChecked,
      notes: isNotesChecked,
      id: id,
      image
    });
    if (results.data.success) {
      toast.success(`Welcome Page Saved`);
      onRefresh();
      onClose();
    }
  }

  const uploadNew = () => {
    const uploadEle = document.getElementById("uploadWelcomeImage");
    uploadEle.click();
  };

  const fileChange = async e => {
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      const fd = new FormData();

      fd.set("partnerId", data.partnerId);
      fd.set("image", e.target.files[0]);
      // fd.set("type", this.state.type);
      // this.dispatch.uploadProgramWelcomePagePhoto(fd);

      setGlobal({ loading: true });
      try {
        const ep = `${process.env.REACT_APP_API}/partners/welcome/photo`;
        const res = await axios.post(ep, fd);

        setImage(res.data.data.program_photo);
        setGlobal({ loading: false });
      } catch (err) {
        console.error(err.message);
      }
    }
  };

  const mainContent = (
    <form onSubmit={e => e.preventDefault()}>
      <ModalFields.input
        label="Title"
        name="title"
        tooltip="Enter a title"
        type="text"
        value={title}
        onChange={e => {
          setTitle(e.target.value);
        }}
      />
      <ModalFields.textarea
        label="Welcome Note"
        name="welcome"
        id="welcome"
        rows="4"
        columns="12"
        placeholder={program.description}
        value={welcome}
        onChange={e => {
          setWelcome(e.target.value);
        }}
      />
      <ModalFields.textarea
        label="Navigation Tips"
        name="nav-tips"
        tooltip="Let students know how to arrive at the venue!"
        id="nav-tips"
        rows="4"
        columns="12"
        value={navigation}
        onChange={e => {
          setNavigation(e.target.value);
        }}
      />
      <ModalFields.custom label="Image" name="image">
        <figure
          className="photoContainer"
          style={{
            width: "70%",
            textAlign: "left",
            marginLeft: "6px"
          }}
          onClick={_ => uploadNew()}
        >
          {image ? (
            <div
              style={{
                padding: "2px",
                width: "130px",
                height: "80px",
                border: "1px solid #e3e3e3",
                display: "inline-block",
                borderRadius: "8px",
                cursor: "pointer",
                backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${image})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"
              }}
            ></div>
          ) : (
            <div
              style={{
                padding: "2px",
                width: "130px",
                height: "80px",
                border: "1px solid #e3e3e3",
                borderRadius: "8px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <img
                src={MiniPlus}
                alt="Add"
                // style={{ marginTop: "calc(50% - 4.5px)" }}
              />
            </div>
          )}
          <input
            type="file"
            style={{ display: "none" }}
            id="uploadWelcomeImage"
            onChange={e => fileChange(e)}
            accept="image/x-png,image/gif,image/jpeg"
          />
        </figure>
      </ModalFields.custom>
      <ModalFields.switch
        label="Resources"
        name="resources"
        color="primary"
        checked={isResourcesChecked}
        onClick={_ => setIsResourcesChecked(!isResourcesChecked)}
      />
      <ModalFields.switch
        label="Milestones"
        name="milestones"
        color="primary"
        checked={isMilestonesChecked}
        onClick={_ => setIsMilestonesChecked(!isMilestonesChecked)}
      />
      {(global.dsUser.plan_type === "Daycare / Preschool - DS" ||
        global.dsUser.plan_type === "Daycare / Preschool - Custom") && (
        <ModalFields.switch
          label="Newsfeed"
          name="newsfeed"
          color="primary"
          checked={isNewsfeedChecked}
          onClick={_ => setIsNewsfeedChecked(!isNewsfeedChecked)}
        />
      )}
      <ModalFields.switch
        label="Notes"
        name="notes"
        color="primary"
        checked={isNotesChecked}
        onClick={_ => setIsNotesChecked(!isNotesChecked)}
      />
    </form>
  );

  const footerContent = (
    <>
      <button type="submit" onClick={_ => saveWelcome()} className="btn">
        Save
      </button>
      <button
        type="submit"
        className="btn"
        onClick={_ => setShowPreviewModal(true)}
      >
        Preview
      </button>
    </>
  );

  const imageModal = () => {
    return (
      <div>
        <div
          //className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            style={{
              width: "32%",
              textAlign: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            Image
          </label>
          <figure
            className="photoContainer"
            style={{
              width: "70%",
              textAlign: "left",
              marginLeft: "40px"
            }}
            onClick={_ => uploadNew()}
          >
            {image ? (
              <div
                style={{
                  padding: "2px",
                  width: "130px",
                  height: "80px",
                  border: "1px solid #c5c5c5",
                  display: "inline-block",
                  borderRadius: "4px",
                  cursor: "pointer",
                  backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${image})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat"
                }}
              ></div>
            ) : (
              <div
                style={{
                  padding: "2px",
                  width: "130px",
                  height: "80px",
                  border: "1px solid #c5c5c5",
                  borderRadius: "4px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <img
                  src={MiniPlus}
                  alt="Add"
                  // style={{ marginTop: "calc(50% - 4.5px)" }}
                />
              </div>
            )}
            <input
              type="file"
              style={{ display: "none" }}
              id="uploadWelcomeImage"
              onChange={e => fileChange(e)}
              accept="image/x-png,image/gif,image/jpeg"
            />
          </figure>
        </div>
      </div>
    );
  };

  /// ================== NEW MODAL ========================== ///

  const getBaseModalFields = () => {
    const titleInput = {
      type: STRING_INPUT,
      data: {
        name: "Title",
        required: false,
        info: "Enter a title",
        value: title,
        handleChange: e => {
          setTitle(e.target.value);
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const messageInput = {
      type: TEXT_AREA,
      data: {
        name: "Welcome Note",
        required: false,
        placeholder: program.description,
        value: welcome,
        handleChange: e => {
          setWelcome(e.target.value);
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const navigationInput = {
      type: TEXT_AREA,
      data: {
        name: "Navigation Tips",
        info: "Let students know how to arrive at the venue!",
        required: false,
        value: navigation,
        handleChange: e => {
          setNavigation(e.target.value);
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const imageInput = {
      type: GET_HTML,
      data: {
        gethtml: imageModal
      }
    };

    const resourcesSwitch = {
      type: SWITCH,
      data: {
        name: "Resources",
        isDisabled: false,
        checked: isResourcesChecked,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: _ => setIsResourcesChecked(!isResourcesChecked)
      }
    };

    const milestoneSwitch = {
      type: SWITCH,
      data: {
        name: "Milestones",
        isDisabled: false,
        checked: isMilestonesChecked,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: _ => setIsMilestonesChecked(!isMilestonesChecked)
      }
    };

    const newsfeedSwitch = {
      type: SWITCH,
      data: {
        name: "Newsfeed",
        isDisabled: false,
        checked: isNewsfeedChecked,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: _ => setIsNewsfeedChecked(!isNewsfeedChecked)
      }
    };

    const notesSwitch = {
      type: SWITCH,
      data: {
        name: "Notes",
        isDisabled: false,
        checked: isNotesChecked,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: _ => setIsNotesChecked(!isNotesChecked)
      }
    };

    const fields = [];
    fields.push(titleInput);
    fields.push(messageInput);
    fields.push(navigationInput);
    fields.push(imageInput);
    fields.push(resourcesSwitch);
    fields.push(milestoneSwitch);
    if (
      global.dsUser.plan_type === "Daycare / Preschool - DS" ||
      global.dsUser.plan_type === "Daycare / Preschool - Custom"
    ) {
      fields.push(newsfeedSwitch);
    }
    fields.push(notesSwitch);

    return fields;
  };

  const getBaseModalButtons = () => {
    const submitButton = {
      name: "Save",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => saveWelcome()
    };

    const previewButton = {
      name: "Preview",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => setShowPreviewModal(true)
    };

    return [submitButton, previewButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Welcome Page",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      handleClose: onClose,
      width: "31vw",
      height: "70vh",
      midSectionHeight: "56vh"
    };
  };

  return (
    console.log("showPreviewModal", showPreviewModal),
    (
      /*        <div>  
        <ModalTemplate
      onClose={onClose}
      footerContent={footerContent}
      heading="Welcome Page!"
    >
      {showPreviewModal && (
        <WelcomePreviewModal
          title={title}
          navigation={navigation}
          image={image}
          welcome={welcome}
          table={table}
          onClose={_ => setShowPreviewModal(false)}
        />
      )}
      {mainContent}
    </ModalTemplate>  */

      <div>
        {showPreviewModal ? (
          <ModalTemplate>
            {" "}
            {
              <WelcomePreviewModal
                title={title}
                navigation={navigation}
                image={image}
                welcome={welcome}
                table={table}
                onClose={_ => setShowPreviewModal(false)}
              />
            }
            {mainContent}
          </ModalTemplate>
        ) : (
          <BaseModal {...getBaseModalProps()}> </BaseModal>
        )}

        {/*         <ModalTemplate>
          {" "}
          {showPreviewModal && (
            <WelcomePreviewModal
              title={title}
              navigation={navigation}
              image={image}
              welcome={welcome}
              table={table}
              onClose={_ => setShowPreviewModal(false)}
            />
          )}
          {mainContent}
        </ModalTemplate>
        <BaseModal {...getBaseModalProps()}> </BaseModal> */}
      </div>
    )
  );
};

export default WelcomePageModal;
