import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, SWITCH, DROPDOWN, TITLE } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class AddMembershipPriceModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      price_per_term: 0,
      membership_term: "",
      auto_renew: false,
      isTaxEnabled: false,
      taxInfo: [],
      auto_withdraw: false
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async savePlan() {
    const obj = {
      ...this.state,
      id: this.global.membership.prices.length
    };

    if (this.state.isTaxEnabled && this.state.taxInfo.length === 0) {
      toast.error("Please select a tax plan when tax is enabled");
      return;
    }

    this.setGlobal({
      membership: {
        ...this.global.membership,
        ["prices"]: [...this.global.membership.prices, obj]
      }
    });

    await this.dispatch.saveMembership();
    this.props.onClose();
  }

  /// ================== NEW MODAL ========================== ///

  taxesOptions = () => {
    //const global_organization = [JSON.parse(this.global.organizationInfo.stripeTaxId)]
    const global_organization = this.global.organizationInfo.stripeTaxId;
    console.log("global_organization", global_organization);
    const DATA = [];
    for (let i = 0; i < global_organization.length; i++) {
      DATA.push(JSON.parse(global_organization[i]));
    }
    console.log("DATA", DATA);

    return [
      ...DATA.map((e, i) => ({
        value: i,
        text: e.display_name + " " + e.jurisdiction + e.percentage + "%"
      }))
    ];
  };

  termOptions = () => {
    return Array(
      { value: 1, key: 1, text: '1 Month' },
      { value: 3, key: 3, text: '3 Months' },
      { value: 6, key: 6, text: '6 Months' },
      { value: 12, key: 12, text: '12 Months' }
    )
  };

  getBaseModalFields() {
    const daysInput = {
      type: DROPDOWN,
      data: {
        name: "Membership Term",
        required: true,
        placeholder: "Please Select",
        value: this.state.membership_term,
        handleChange: e => {
          this.setState({
            membership_term: e.target.value
          });
        },
        choices: this.termOptions()
      },
      validators: { validateSubmit: () => this.state.membership_term !== "" }
    };

    const pricingTerm = {
      type: STRING_INPUT,
      data: {
        name: "Pricing for term",
        required: true,
        type: "number",
        handleChange: e => {
          if (e.target.value === "") {
            this.setState({
              price_per_term: ""
            });
            
            return;
          }
          let price = parseFloat(e.target.value || 0);
          let decimalValue = e.target.value.split(".")[1];

          if (decimalValue && decimalValue.length > 2) {
            return;
          } else if (!e.target.value.endsWith("0")) {
            price = Math.round(price * 100) / 100;
          } else {
            price = e.target.value;
          }

          this.setState({
            price_per_term: price.toString()
          });
        },
        value: this.state.price_per_term
      },
      validators: {
        validateSubmit: () =>
          this.state.price_per_term > 0 && this.state.price_per_term !== ""
      }
    };

    const autoRenew = {
      type: SWITCH,
      data: {
        name: "Subscription",
        isDisabled: false,
        checked: !!this.state.auto_renew,
        handleClick: e => {
          if (this.state.auto_renew) {
            this.setState({
              auto_renew: false,
              auto_withdraw: false,
            });
          } else {
            this.setState({
              auto_renew: true,
              auto_withdraw: !this.global.membership.manual_invoices
            });
          }
        }
      }
    };

    const autoPay = {
      type: SWITCH,
      data: {
        name: "Make Autopay Mandatory",
        isDisabled: !this.state.auto_renew || this.global.membership.manual_invoices,
        checked: !!this.state.auto_withdraw && !this.global.membership.manual_invoices,
        info: "Auto withdraw payments from customers for subscription plans. Organizer will be incurring a Credit Card processing fee. Disabled if listing accepts Offline Payments.",
        handleClick: _ =>
          this.setState({ auto_withdraw: !this.state.auto_withdraw })
      }
    };

    const enableTaxes = {
      type: SWITCH,
      data: {
        name: "Enable Taxes",
        isDisabled: false,
        checked: this.state.isTaxEnabled,
        handleClick: e =>
          this.setState({
            isTaxEnabled: e.target.checked,
            taxInfo: []
          })
      }
    };

    const selectTaxes = {
      type: DROPDOWN,
      data: {
        name: "Tax Plan",
        required: false,
        placeholder: "Please Select" /* 
            value: this.state.taxInfo, */,
        handleChange: e => {
          const item = JSON.parse(
            this.global.organizationInfo.stripeTaxId[e.target.value]
          );
          this.setState({
            taxInfo: [
              {
                id: item.id,
                percentage: item.percentage,
                jurisdiction: item.jurisdiction,
                display_name: item.display_name,
                description: item.description
              }
            ]
          });
        },
        choices: this.taxesOptions()
      }
    };

    const fields = [];
    fields.push(daysInput);
    fields.push(pricingTerm);
    fields.push(autoRenew);
    fields.push(autoPay);
    fields.push(enableTaxes);
    if (this.state.isTaxEnabled) {
      fields.push(selectTaxes);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.savePlan()
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Add Pricing Plan",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ========================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    // return (
    //   <ModalTemplate
    //     heading="Add Pricing Plan!"
    //     onClose={_ => this.props.onClose()}
    //   >
    //     <div className="modal-main">
    //       <form>
    //         <ModalFields.select
    //           label="Membership Term"
    //           name="membership_term"
    //           onChange={e => {
    //             this.setState({
    //               [e.target.name]: e.target.value
    //             });
    //           }}
    //           required
    //         >
    //           {Array(12)
    //             .fill(0)
    //             .map((e, i) => (
    //               <option value={i + 1} key={i + 1}>{`${i + 1} Month`}</option>
    //             ))}
    //         </ModalFields.select>
    //         <ModalFields.input
    //           label="Pricing for term"
    //           name="price_per_term"
    //           required
    //           type="number"
    //           value={this.state.price_per_term}
    //           onChange={e => this.setState({ price_per_term: e.target.value })}
    //           min={1}
    //         />
    //         <ModalFields.switch
    //           label="Auto Renew"
    //           name="auto_renew"
    //           checked={!!this.state.auto_renew}
    //           onChange={e =>
    //             this.setState({ auto_renew: !this.state.auto_renew })
    //           }
    //         />
    //         <ModalFields.switch
    //           label="Make Autopay Mandatory"
    //           name="auto_withdraw"
    //           checked={!!this.state.auto_withdraw}
    //           disabled={!this.state.auto_renew ? true : false}
    //           onClick={_ =>
    //             this.setState({ auto_withdraw: !this.state.auto_withdraw })
    //           }
    //           // className="col-8"
    //           tooltip="Auto withdraw payments from customers for auto renew plans. Organizer will be incurring a Credit Card processing fee."
    //         />
    //         <ModalFields.switch
    //           label="Enable Taxes"
    //           name="enable_taxes"
    //           checked={this.state.isTaxEnabled}
    //           onChange={e =>
    //             this.setState({
    //               isTaxEnabled: e.target.checked,
    //               taxInfo: []
    //             })
    //           }
    //         />
    //
    //         {this.state.isTaxEnabled && (
    //           <ModalFields.select
    //             label="Tax Plan"
    //             name="taxInfo"
    //             onChange={e => {
    //               const item = JSON.parse(
    //                 this.global.organizationInfo.stripeTaxId[e.target.value]
    //               );
    //               this.setState({
    //                 [e.target.name]: [
    //                   {
    //                     id: item.id,
    //                     percentage: item.percentage,
    //                     jurisdiction: item.jurisdiction,
    //                     display_name: item.display_name,
    //                     description: item.description
    //                   }
    //                 ]
    //               });
    //             }}
    //             required
    //           >
    //             {this.global.organizationInfo.stripeTaxId.map((ele, i) => {
    //               const e = typeof ele === "string" ? JSON.parse(ele) : ele;
    //               return (
    //                 <>
    //                   <option value={i}>
    //                     {e.display_name} {e.jurisdiction} {e.percentage}%
    //                   </option>
    //                 </>
    //               );
    //             })}
    //           </ModalFields.select>
    //         )}
    //       </form>
    //     </div>
    //     <div className="modal-footer">
    //       <button
    //         type="submit"
    //         onClick={_ => this.savePlan()}
    //         className="btn profile-btn"
    //       >
    //         Submit
    //       </button>
    //     </div>
    //   </ModalTemplate>
    // );
  }
}

export default withRouter(AddMembershipPriceModal);
