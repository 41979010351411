import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";

class SectionTwo extends React.PureComponent {
  handleChange = (e, v) => {
    this.setGlobal({
      gift_card: {
        ...this.global.gift_card,
        [e]: v
      }
    });
  };

  customForm = () => {
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Gift Card Name:<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  name="gift_name"
                  className="input-fields"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.gift_card.gift_name}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="label-names">Description</h3>
            </div>
            <div className="col-12 d-flex">
              <textarea
                className="input-text-fields"
                rows="12"
                style={{ width: "100%" }}
                name="description"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={this.global.gift_card.description}
              ></textarea>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Set-up"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionTwo;
