import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import BaseModal from "./baseModal/BaseModal";
import { DROPDOWN, STRING_INPUT } from "./baseModal/FieldTypes";
import { notEmptyString } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

// currently, although there are 3 fields, only 2 pieces of
// data are being collected (this.data.changed and this.state.form_name)

class AddFormModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { changed: "", form_name: "" };
  }

  addForm = async e => {
    if (!this.state.changed || !this.state.form_name) {
      toast.error("Please fill out all fields!");
      return;
    }
    if (this.state.form_name === "Children" || this.state.form_name === "General") {
      toast.error('"General" and "Children" are reserved names! Please choose a different name.');
      return;
    }

    const ep = `${process.env.REACT_APP_API}/partners/forms/add`;
    const res = await axios.post(ep, {
      form_name: this.state.changed,
      form_sub_name: this.state.form_name
    });
    if (res.data.success) {
      this.props.history.push(`/partners/forms/builder/${res.data.data.id}`);
      this.props.onClose();
    }
  };

  getFormTypeChoices = () => {
    return this.props.forms.reduce((prev, e) => {
      if (e.form_sub_name === "General" || e.form_sub_name === "Children") {
        return [
          ...prev,
          {
            value: `${e.form_name} (${e.form_sub_name})`,
            text: `${e.form_name} (${e.form_sub_name})`
          }
        ];
      }

      return prev;
    }, []);
  };

  getBaseModalFields = () => {
    const formTypeField = {
      type: DROPDOWN,
      data: {
        name: "Form Type",
        required: true,
        placeholder: "Please select",
        handleChange: e => {
          this.setState({
            changed: e.target.value
          });
        },
        choices: this.getFormTypeChoices()
      },
      validators: { validateSubmit: notEmptyString(this.state.changed) }
    };

    const formNameField = {
      type: STRING_INPUT,
      data: {
        name: "Form Name",
        required: true,
        placeholder: "",
        handleChange: e => this.setState({ form_name: e.target.value }),
        value: this.state.form_name
      },
      validators: { validateSubmit: notEmptyString(this.state.form_name) }
    };

    // Moh and Josh could not find anyuse for the "Public Name" i.e. (PublicNameField)
    
    // const publicNameField = {
    //   type: DROPDOWN,
    //   data: {
    //     name: "Public Name",
    //     required: true,
    //     placeholder: "",
    //     handleChange: () => {},
    //     choices: [
    //       {
    //         value: undefined,
    //         text: this.state.form_name && this.state.form_name.substring(0, 20)
    //       }
    //     ]
    //   }
    // };

    // return [formTypeField, formNameField, publicNameField];
    // Moh and Josh could not find anyuse for the "Public Name" i.e. (PublicNameField)
    return [formTypeField, formNameField];
  };

  getBaseModalButtons = () => {
    const addFormButton = {
      name: "Add Form",
      handleClick: this.addForm,
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit"
    };

    return [addFormButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Create a Form",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(AddFormModal);
