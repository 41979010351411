import React from "reactn";
import { Switch, Router, Route } from "react-router-dom";
import history from "./modules/history";
import ReactGA from "react-ga";
import AuthRoute from "./routes/authRoute";
// These unused layouts affect CSS globally, mostly with modals.
import AdminLayout from "./components/admin/AdminLayout";
import ParentLayout from "./components/parents/ParentLayout";
import PartnerLayout from "./components/partner/PartnerLayout";
import FranchiserLayout from "./components/franchiser/FranchiserLayout";
import EducatorLayout from "./components/educator/EducatorLayout";
import Checkout from "./pages/front/checkout";
import RoutePublic from "./routes/routePublic";
import Home from "./pages/front/home";
import About from "./pages/front/about";
import Educator from "./pages/front/educator";
//import Search from "./pages/front/search";          //<--Obsoleting this file on this project! 
import Partner from "./pages/front/partner";
import { CometChat } from "@cometchat-pro/chat";
import Login from "./pages/front/login";
import PartnersLogin from "./pages/front/login_partner";
import PartnersFranchiseLogin from "./pages/front/login_partner_franchise";
import ImpersonatePartner from "./pages/front/impersonate_partner";
import ImpersonatePartnerFranchise from "./pages/front/impersonate_partner_franchise";
import Signup from "./pages/front/signup";
import NotFound from "./pages/notFound";
import ResetPassword from "./pages/partners/resetPassword";
import CreatePassword from "./pages/partners/createPassword";
import Privacy from "./pages/front/privacy";
import ForgotPassword from "./pages/partners/forgotPassword";
import LearnMore from "./pages/front/demo";
import LoaderSquares from "./components/UI/Loader/LoaderSquares";
import { ToastContainer } from "react-toastify";
import PreviewPartner from "./pages/front/previewPartner";
import PromotionDetail from "./pages/front/PromotionDetail/PromotionDetail";
import SpecificNews from "./pages/front/partnerNewsUpdates";
import SpecificOnline from "./pages/front/partnerSpecificOnline";
import SpecificProgram from "./pages/front/partnerSpecificProgram";
import SpecificEvent from "./pages/front/partnerSpecificEvent";
import SpecificMembership from "./pages/front/partnerSpecificMembership";
import SpecificParty from "./pages/front/partnerSpecificParty";
import ScrollToTop from "./ScrollToTop";
import AppErrorBoundary from "./AppErrorBoundary";
import "./assets/css/all.min.css";
import "./assets/css/application.css";
import "./assets/css/parent_css.css";
import "./assets/css/sb-admin-2.css";
import "react-toastify/dist/ReactToastify.css";
import Terms from "./pages/front/terms";


class App extends React.Component {
  componentDidMount() {
    ReactGA.initialize("UA-123001117-1");
    history.listen(location => {
      console.debug("location.change:",location.pathname);
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
    ReactGA.set({ page: history.location.pathname }); // Update the user's current page
    ReactGA.pageview(history.location.pathname); // Record a pageview for the given page

    // const ccSettings = new CometChat.AppSettingsBuilder()
    //   .setRegion("us")
    //   .build();
    // CometChat.init(process.env.REACT_APP_COMETCHAT_APPID, ccSettings);
  }

  render() {
    if (window.location.protocol === "file:") {
      return <div></div>
    }
    return (
      <AppErrorBoundary>
{/*       <React.StrictMode> */}
            <LoaderSquares />
            <Router history={history}>
              <ScrollToTop>
                <Switch>
                  <AuthRoute
                    isAuthenticated={true}
                    admin={true}
                    path="/partners"
                    component={PartnerLayout}
                  />
                  <AuthRoute
                    isAuthenticated={true}
                    admin={true}
                    path="/franchiser"
                    component={FranchiserLayout}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/"
                    exact
                    component={PartnersLogin}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/franchise/log-in"
                    exact
                    component={PartnersFranchiseLogin}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/impersonate/:id/:token"
                    exact
                    component={ImpersonatePartner}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/impersonatefranchise/:id/:token"
                    exact
                    component={ImpersonatePartnerFranchise}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/preview"
                    exact
                    component={PreviewPartner}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/preview/event/:id"
                    exact
                    component={SpecificEvent}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/preview/party/:id"
                    exact
                    component={SpecificParty}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/preview/program/:id"
                    exact
                    component={SpecificProgram}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/preview/promotion/:id"
                    exact
                    component={PromotionDetail}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/preview/online/:id"
                    exact
                    component={SpecificOnline}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/preview/membership/:id"
                    exact
                    component={SpecificMembership}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/preview/news/:id"
                    exact
                    component={SpecificNews}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/checkout"
                    exact
                    component={Checkout}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/create_password/:token"
                    exact
                    component={CreatePassword}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/set_pwd/:token"
                    exact
                    component={ResetPassword}
                  />
                  <RoutePublic
                    isAuthenticated={false}
                    path="/forgot-password"
                    exact
                    component={ForgotPassword}
                  />
                  <Route component={NotFound} />
                </Switch>
              </ScrollToTop>
            </Router>
            <ToastContainer
              position="bottom-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
{/*       </React.StrictMode> */}
      </AppErrorBoundary>
    );
  }
}

export default App;
