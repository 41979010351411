import React from "reactn";
import { Redesign_Logo } from "../../../../assets";
import "./tosForm.css";

class CertifyForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dsContractAccepted: false
    };
    this.saveApplication = this.saveApplication.bind(this);
  }

  saveApplication = async () => {
    try {
      if (this.state.dsContractAccepted) {
        this.props.onClose(true);
      }
    } catch (ex) {}
  };

  render() {
    return (
      <div className="tos-modal-cont">
        <div className="tos-modal">
          <img
            className="school-logo"
            src={Redesign_Logo}
            alt="Dreamschools"
            style={{ width: "100%" }}
          />
          <p>
            I certify that the information I have supplied on this application
            is correct and agree that Dreamschools may further investigate or
            verify this information and contact the references listed above in
            connection with my proposed relationship with Dreamschools.
          </p>
          <input
            type="checkbox"
            name="dsContractAccepted"
            id="dsContractAccepted"
            checked={this.state.dsContractAccepted ? "checked" : ""}
            onChange={() => {
              this.setState({
                dsContractAccepted: !this.state.dsContractAccepted
              });
            }}
          />{" "}
          <label htmlFor="dsContractAccepted">
            I certify that all of the information contained herein is accurate.
          </label>
          <br />
          <button
            onClick={() => this.props.onClose(false)}
            className="mx-2 backbtn"
          >
            Revise Application
          </button>
          <button className="mx-2" onClick={() => this.saveApplication()}>
            Accept &amp; Continue
          </button>
        </div>
      </div>
    );
  }
}

export default CertifyForm;
