import React, { useState, useEffect, useGlobal } from "reactn";
import _ from "lodash";
import { format, parse, addDays } from "date-fns";
import axios from "axios";

import {
  useDateFilter,
  MILLISECONDS_IN_SECONDS,
  CENTS_IN_DOLLAR
} from "../../../providers/reports";
import { formatMoney } from "../../../utilities/reportsAnalytics";
import {
  DateFilter,
  ReportContainer,
  ReportTable
} from "../../../components/UI/reportsAnalytics";

const RevenueClient = () => {
  const orgData = useGlobal("organizationInfo")[0];
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");
  const [dateFrom, dateTo, setDateFrom, setDateTo] = useDateFilter();

  const [selectedClient, setSelectedClient] = useState("");

  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [formattedOrders, setFormattedOrders] = useState([]);

  const [clients, setClients] = useState([]);

  useEffect(() => {
    const filteredOrders = getFilteredOrders();
    setFilteredOrders(filteredOrders);
  }, [selectedClient, dateFrom, dateTo]);

  useEffect(() => {
    const formattedOrders = getFormattedOrders();
    setFormattedOrders(formattedOrders);
  }, [filteredOrders]);

  useEffect(() => {
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" },
      { label: "Revenue by Client", to: "/reports/client-revenue" }
    ]);

    const fetchInitialData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/partners/order-tracking`
      );
      console.log("response", response);
      const allOrders = [
        ...response.data.data.Once.payments,
        ...response.data.data.Recurring.payments
      ].sort((a, b) => new Date(b.date) - new Date(a.date));
      setOrders(allOrders);

      const allClients = allOrders.reduce((prev, curr) => {
        if (!curr.purchaser || prev.find(e => e === curr.purchaser)) {
          return prev;
        }
        return [...prev, curr.purchaser];
      }, []);
      setClients(allClients);

      console.log("all clients", allClients);
      console.log("all orders", allOrders);
    };

    fetchInitialData();
  }, []);

  const getFilteredOrders = () => {
    const filteredOrders = orders.filter(e => {
      return (
        e.purchaser === selectedClient &&
        new Date(e.date) >= new Date(dateFrom) &&
        new Date(e.date) <= addDays(new Date(dateTo), 1) &&
        e.type !== "Invoice"
      );
    });
    return filteredOrders;
  };

  const getModifierString = order => {
    // modifiers are: tax, credit card fee, promotion, coupon, addOnSemesterItemAmount, gift discount, discount amount, refund
    return Object.keys(order.modifiers)
      .map(modifier =>
        parseInt(order.modifiers[modifier])
          ? `${getModifierName(modifier)} (${formatMoney(
              order.modifiers[modifier] / 100
            )})`
          : ""
      )
      .filter(e => e !== "")
      .join(" / ");
  };

  const getModifierName = modifier => {
    switch (modifier) {
      case "tax":
        return "Tax";
      case "creditCardFee":
        return "Credit Card Fee";
      case "promotion":
        return "Promotion";
      case "coupon":
        return "Coupon";
      case "addOnSemesterItem":
        return "Add On Semester Item";
      case "giftDiscount":
        return "Gift Discount";
      case "discount":
        return "Discount";
      case "refunded":
        return "Refunded";
      default:
        return "";
    }
  };

  const getFormattedOrders = () => {
    const formattedOrders = filteredOrders.map(e => [
      format(new Date(e.date), "MMM dd, yyyy"),
      e.item,
      e.purchaser,
      getModifierString(e),
      e.paymentMethod,
      formatMoney(e.total / 100)
    ]);
    return formattedOrders;
  };

  /**uct.pr

  /**
   * Gets total revenue by aggregating all order data.
   *
   * @returns {Number} Total revenue.
   */
  const getTotalRevenue = () => {
    let total = filteredOrders.reduce(
      (acc, order) => acc + parseFloat(order.total),
      0
    );

    return formatMoney(total / CENTS_IN_DOLLAR);
  };

  const csvHeaders = [
    { label: "Customer Name", key: "name" },
    { label: "Transaction Datetime", key: "dateTime" },
    { label: "Listing", key: "listingName" },

    { label: "Tax", key: "tax" },
    { label: "Credit Card Fee", key: "creditCardFee" },
    { label: "Promo", key: "promotion" },
    { label: "Coupon", key: "coupon" },
    { label: "Add on Semester Item", key: "addOnSemesterItem" },
    { label: "Gift Discount", key: "giftDiscount" },
    { label: "Refunded", key: "refunded" },

    { label: "Payment Method", key: "paymentMethod" },
    { label: "Amount", key: "amount" }
  ];

  /**
   * Gets the CSV for the CSVLink component to use.
   *
   * @returns {Array<Object>} Each property of each object corresponds to a column.
   */
  const getCsvData = () => {
    // Returning null here will disable the export button.
    if (!selectedClient) {
      return null;
    }

    let csvRows = [];

    filteredOrders.forEach(row => {
      csvRows.push({
        name: row.purchaser,
        dateTime: format(new Date(row.date), "MMM dd, yyyy"),
        listingName: row.item,
        tax: formatMoney(row.modifiers.tax / 100),
        creditCardFee: formatMoney(row.modifiers.creditCardFee / 100),
        promotion: formatMoney(row.modifiers.promotion / 100),
        coupon: formatMoney(row.modifiers.coupon / 100),
        addOnSemesterItem: formatMoney(row.modifiers.addOnSemesterItem / 100),
        giftDiscount: formatMoney(row.modifiers.giftDiscount / 100),
        refunded: formatMoney(row.modifiers.refunded / 100),
        paymentMethod: row.paymentMethod,
        amount: formatMoney(row.total / 100)
      });
    });

    csvRows.push({
      name: "TOTAL",
      amount: getTotalRevenue()
    });

    return csvRows;
  };

  let reportSubtitles = [
    orgData.organization_title,
    `For ${format(parse(dateFrom, "yyyy-MM-dd", new Date()), "MMM d, yyyy")}
    - 
    ${format(parse(dateTo, "yyyy-MM-dd", new Date()), "MMM d, yyyy")} 
    `
  ];

  if (selectedClient) {
    reportSubtitles.push(selectedClient);
  }

  return (
    <div className="admin">
      <div className="container-fluid adminprofiles">
        <div className="row cont">
          <div className="col-md-6">
            <h1>Revenue by Client</h1>
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-section">
            <DateFilter
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
            />
            <div className="filter-item grid-new-col">
              <label htmlFor="client_name" className="filter-label">
                Client Name *
              </label>
              <select
                name="client_name"
                id="client_name"
                type="text"
                className="form-control filter-input"
                value={selectedClient}
                onChange={e => setSelectedClient(e.target.value)}
              >
                <option value="" selected hidden disabled>
                  Please Select
                </option>
                {clients
                  .sort((a, b) => a.localeCompare(b))
                  .map(e => {
                    return (
                      <option key={e} value={e}>
                        {e}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>

        <ReportContainer
          title="Revenue by Client"
          subtitles={reportSubtitles}
          csvHeaders={csvHeaders}
          csvData={getCsvData()}
          fileName={`${selectedClient}_${dateFrom}-${dateTo}.csv`}
          isLarge
        >
          <div className="report-table-large">
            <ReportTable
              headings={[
                { label: "Summary", className: "table-item-bold" },
                {
                  label: "Total",
                  className: "table-item-money",
                  style: { textAlign: "right" }
                }
              ]}
              body={[["Total Revenue", getTotalRevenue()]]}
            />
            <ReportTable
              headings={[
                { label: "Date" },
                { label: "Item" },
                { label: "Purchaser" },
                { label: "+ / -" },
                { label: "Payment Method" },
                {
                  label: "Total",
                  className: "table-item-money",
                  style: { textAlign: "right" }
                }
              ]}
              body={formattedOrders}
              noDataText="No payments found, try selecting a client or picking a different date range."
            />
          </div>
        </ReportContainer>
      </div>
    </div>
  );
};

export default RevenueClient;
