import React, { useState, useEffect, useGlobal } from "reactn";
import axios from "axios";
import moment from "moment";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";

import { useDateFilter } from "../../../providers/reports";
import {
  DateFilter,
  ReportContainer,
  ReportTable
} from "../../../components/UI/reportsAnalytics";

import { formatMoney } from "../../../utilities/reportsAnalytics";

const yearStartDate = moment(startOfYear(new Date())).format("YYYY-MM-DD");
const yearEndDate = moment(endOfYear(new Date())).format("YYYY-MM-DD");

const ExpenseReport = () => {
  const [category, setCategory] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [expenseOverview, setExpenseOverview] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedCategoryId, setSelectedCategoryId] = useState();

  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");

  const [dateFrom, dateTo, setDateFrom, setDateTo] = useDateFilter(
    yearStartDate,
    yearEndDate
  );

  const [filterByCategory, setFilterByCategory] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [filteredExpenses, setFilteredExpenses] = useState([]);

  /**
   * Initially loads the required data for the component
   */
  useEffect(() => {
    const fetchExpenseData = async () => {
      const ep = `${process.env.REACT_APP_API}/partner/expense`;
      const result = await axios.get(ep);

      if (result.data.success) {
        setCategory(result.data.data.categories);
        setExpenses(result.data.data.expenses);
        setFilteredExpenses(result.data.data.expenses);
        setExpenseOverview(result.data.data.expensesOverview);
      }
    };
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" },
      { label: "Expense Report", to: "/reports/expense-report" }
    ]);
    fetchExpenseData();
  }, []);

  /**
   *
   * @param {event from the onchange input filters} event
   * sets the filtered items in the state
   */
  const filteredExpenseItems = event => {
    let filteredExpenseData = [...expenses];
    let filteredByCategory = filterByCategory;

    if (event.target.name === "category" && event.target.value === "All") {
      setFilterByCategory(filteredExpenseData);
    } else if (event.target.name === "category" && event.target.value !== "") {
      const filterCategoryId = category.filter(
        catFil => catFil.name === event.target.value
      )[0].id;
      filteredByCategory = filteredExpenseData.filter(
        e => e.categoryId === filterCategoryId
      );
      setFilterByCategory(filteredByCategory);
      filteredExpenseData = filteredByCategory;
    }

    if (event.target.name === "filterSearch" && selectedFilter === "Vendor") {
      const eventLowerCaseTrim = event.target.value.trim().toLowerCase();
      let filterByVendor = [];
      if (filterByCategory.length === 0) {
        filterByVendor = [...expenses];
      }
      if (filterByCategory.length > 0) {
        filterByVendor = filterByCategory;
      }
      filteredExpenseData = filterByVendor.filter(e =>
        e.vendor.toLowerCase().includes(eventLowerCaseTrim)
      );
    }

    if (
      event.target.name === "filterSearch" &&
      selectedFilter === "Description"
    ) {
      const eventLowerCaseTrim = event.target.value.trim().toLowerCase();
      let filterByDescription = [];
      if (filterByCategory.length === 0) {
        filterByDescription = [...expenses];
      }
      if (filterByCategory.length > 0) {
        filterByDescription = filterByCategory;
      }
      filteredExpenseData = filterByDescription.filter(e =>
        e.description.toLowerCase().includes(eventLowerCaseTrim)
      );
    }

    if (event.target.name === "filterSearch" && selectedFilter.length === 0) {
      let filterReset = [];
      if (filterByCategory.length === 0) {
        filterReset = [...expenses];
      }
      if (filterByCategory.length > 0) {
        filterReset = filterByCategory;
      }
      filteredExpenseData = filterReset;
    }

    setFilteredExpenses(filteredExpenseData);
  };

  /**
   * This function returns the Expense Items to be displayed in JSX
   */
  const displayExpense = () => {
    return filteredExpenses.map(expense => {
      const expenseDate = new Date(expense.expense_date);
      const selectedDateFrom = new Date(dateFrom).getTime();
      const tempDateTo = new Date(dateTo);
      // Offset by a day to provide the correct filter.
      const selectedDateTo = tempDateTo.setDate(tempDateTo.getDate() + 1);

      if (expenseDate < selectedDateFrom || selectedDateTo < expenseDate) {
        return [];
      }

      return [
        moment(expenseDate).format("YYYY-MM-DD"),
        `${
          expense.vendor ? `${expense.vendor.substring(0, 40)}` : "No vendor"
        }`,
        `${
          expense.description
            ? ` - ${expense.description.substring(0, 40)}`
            : "No Description"
        }`,
        formatMoney(expense.grand_total)
      ];
    });
  };

  const displayTotalExpense = () => {
    const filteredData = [...filteredExpenses];
    const sumFunction = (r, c) => {
      const expenseDate = new Date(c.expense_date);
      const selectedDateFrom = new Date(dateFrom);
      const selectedDateTo = new Date(dateTo);

      if (expenseDate < selectedDateFrom || selectedDateTo < expenseDate) {
        return r + 0;
      }

      return r + parseFloat(c.grand_total);
    };
    return formatMoney(filteredData.reduce(sumFunction, 0));
  };

  const csvHeaders = [
    { label: "Date", key: "date" },
    { label: "Vendor", key: "vendor" },
    { label: "Expense Description", key: "description" },
    { label: "Amount", key: "amount" }
  ];

  /**
   * Gets the CSV for the CSVLink component to use.
   *
   * @returns {Array<Object>} Each property of each object corresponds to a column.
   */
  const getCsvData = () => {
    const data = displayExpense();
    let csvRows = data.map(expense => {
      return {
        date: expense[0],
        vendor: expense[1],
        description: expense[2],
        amount: expense[3]
      };
    });

    csvRows.push({
      date: "TOTAL",
      amount: `$${displayTotalExpense()}`
    });
    return csvRows;
  };

  const reportSubtitles = [
    `${selectedCategory ? selectedCategory : ""}`,
    `For ${moment(dateFrom).format("MMM D, YYYY")} - ${moment(dateTo).format(
      "MMM D, YYYY"
    )}`
  ];
  return (
    <div className="admin">
      <div className="container-fluid adminprofiles">
        <div className="row cont">
          <div className="col-md-6">
            <h1>Expense Report</h1>
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-section">
            <DateFilter
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
            />
            <div className="filter-item">
              <label htmlFor="client_name" className="filter-label">
                Category
              </label>
              <select
                name="category"
                id="category"
                type="text"
                className="form-control filter-input"
                onChange={e => {
                  setSelectedCategory(e.target.value);
                  if (e.target.value.length === 0) {
                    setFilterByCategory([]);
                  }
                  
                  if (e.target.value === "All") {
                    setSelectedCategoryId("");
                  } else if (e.target.value) {
                    const filterCategoryId = category.filter(
                      catFil => catFil.name === e.target.value
                    )[0].id;
                    setSelectedCategoryId(filterCategoryId);
                  } else {
                    setSelectedCategoryId("");
                  }
                  filteredExpenseItems(e);
                }}
                value={selectedCategory}
              >
                <option value="All">All</option>
                {category.map(cat => {
                  return (
                    <option key={cat.id} value={cat.name}>
                      {cat.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="filter-item">
              <label htmlFor="program" className="filter-label">
                Filter
              </label>
              <select
                name="filterBy"
                id="filterBy"
                type="text"
                className="form-control filter-input"
                onChange={e => {
                  setSelectedFilter(e.target.value);
                }}
                value={selectedFilter}
              >
                <option value="">Please Select</option>
                <option value="Vendor">Vendor</option>
                <option value="Description">Description</option>
              </select>
              <div style={{ marginLeft: "10px" }}>
                <input
                  name="filterSearch"
                  id="filterSearch"
                  type="text"
                  className="form-control date-range-2"
                  onChange={e => {
                    // setFilteredExpenses(filteredExpenses);
                    filteredExpenseItems(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <ReportContainer
          title="Expense Report"
          subtitles={reportSubtitles}
          csvHeaders={csvHeaders}
          csvData={getCsvData()}
          fileName={`Expense_Report_${dateFrom}-${dateTo}.csv`}
        >
          <div className="report-table-large">
            <ReportTable
              headings={[
                { label: "Summary", className: "table-item-bold" },
                {
                  label: "Total",
                  className: "table-item-money",
                  style: { textAlign: "right" }
                }
              ]}
              body={[["Total Expense", displayTotalExpense()]]}
            />
            <ReportTable
              headings={[
                { label: "Date", className: "table-item-bold" },
                { label: "Vendor" },
                { label: "Expense Description" },
                {
                  label: "Total",
                  className: "table-item-money",
                  style: { textAlign: "right" }
                }
              ]}
              body={displayExpense()}
              noDataText="No expenses found in this category."
            />
          </div>
        </ReportContainer>
      </div>
    </div>
  );
};

export default ExpenseReport;
