/**
 * Code taken from pages/partners/Orders.jsx.
 * Used to display ticket/plan data for financial summaries.
 */

const CENTS_IN_DOLLAR = 100;

const showProgramPlan = r => {
  if (r.planId === null) {
    return null;
  }
  const plan = JSON.parse(r.product.plans[r.planId]);
  const objConv = {
    week: "Weekly",
    "bi-week": "Bi-Weekly",
    month: "Monthly"
  };
  return plan.type
    ? r.drop_in_dates
      ? `Semester Drop-in - $${parseFloat(
          (plan.total_price / plan.dayCount) *
            (1 + parseInt(plan.dropin_interest) / CENTS_IN_DOLLAR)
        ).toFixed(2)}`
      : r.isInstallment
      ? `Semester - Inst - $${parseFloat(
          (plan.total_price / plan.installments_breakdown) *
            (1 + parseInt(plan.installments_interest) / CENTS_IN_DOLLAR)
        ).toFixed(2)} - ${plan.installments_plan}`
      : `Semester - $${plan.total_price}`
    : `Recurring - $${plan.tuition_rate} / ${objConv[plan.billing_cycle]}, \n ${
        plan.timing[0]
      } to ${plan.timing[1]} ${r.isDeposit ? "(Deposit)" : ""}`;
};

const showEventPlan = r => {
  const plan = JSON.parse(r.product.tickets[r.planId]);
  const ticketName =
    plan.ticket_status === "Paid" ? "Standard" : plan.ticket_status;
  const ticketPrice = plan.ticket_price ? `$${plan.ticket_price}` : "Free";
  return `${ticketName} Ticket / ${ticketPrice}`;
};

const showMembershipPlan = r => {
  const plan = JSON.parse(r.product.prices[r.planId]);
  const membershipTerm = plan.membership_term;
  const renewalStatus = plan.auto_renew ? "Subscription Membership" : "Standard Membership";
  const pricePerTerm = plan.price_per_term;
  return `${membershipTerm} Month Term - ${renewalStatus} - $${pricePerTerm}`;
};

const showOnlinePlan = r => {
  const plan = JSON.parse(r.product.plans[r.planId]);
  const objConv = {
    week: "Weekly",
    "bi-week": "Bi-Weekly",
    month: "Monthly"
  };
  if (!plan.type) {
    return `Recurring - $${plan.tuition_rate} / ${objConv[plan.billing_cycle]}`;
  }
  if (plan.type === "Free") {
    return "Free";
  } else {
    return `Semester - $${plan.total_price}`;
  }
};

const showPlan = r => {
  switch (r.product_table) {
    case "partner_programs":
      return showProgramPlan(r);

    case "partner_event":
      return showEventPlan(r);

    case "partner_memberships":
      return showMembershipPlan(r);

    case "partner_online":
      return showOnlinePlan(r);

    default:
      return "No Information Found";
  }
};

export default showPlan;
