import React from "reactn";
import axios from "axios";

class ForgotPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      reset: false
    };
    this.onChange = this.onChange.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
  }

  onChange = (e, v) => {
    this.setState({ [e]: v });
  };

  forgotPassword = async e => {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/auth/partners/forgot`;
    try {
      if (!this.state.email) throw new Error("Email is required.");
      this.setGlobal({ loading: true });
      const res = await axios.post(ep, { email: this.state.email });
      if (res.data.success) {
        this.setState({
          reset: true
        });
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (er) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
    return;
  };

  render() {
    const { email } = this.state;
    return (
      <div className="login-signup-form mb-5">
        <div className="form-content custom-form-content">
          <h2>Forgot Password</h2>

          <form
            id="new_session"
            className="new_user"
            noValidate="novalidate"
            onSubmit={this.forgotPassword}
          >
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <br />
              <input
                className="form-control"
                type="email"
                value={email}
                name="email"
                onChange={e => this.onChange("email", e.target.value)}
                id="email"
                disabled={this.state.reset}
              />
            </div>

            {this.state.reset ? (
              <p style={{ textAlign: "center" }}>
                If an account exists with the provided information then an email
                has been sent with further instructions on resetting your
                password.
              </p>
            ) : null}

            <div className="form-actions">
              <input
                type="submit"
                name="commit"
                value="Forgot Password"
                className="btn btn-custom"
                disabled={this.state.reset || !this.state.email}
                data-disable-with="Login"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
