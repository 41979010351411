import React from "reactn";
import BaseModal from "./baseModal/BaseModal";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { CONTENT_BLOCK } from "./baseModal/FieldTypes";

const AutoPayEditModal = ({ onClose, selectedItem }) => {
  const getBaseModalFields = () => {
    // const itemName = {
    //   type: CONTENT_BLOCK,
    //   data: {
    //     // name: `Program: ${selectedItem.program_name}\nCustomer: ${selectedItem.customer_name}`,
    //     name: `Program`,
    //     content: "hello"
    //   }
    // };
    const program = {
      type: CONTENT_BLOCK,
      data: {
        name: `Program`,
        content: selectedItem.program_name
      }
    };
    const customer = {
      type: CONTENT_BLOCK,
      data: {
        name: `Customer`,
        content: selectedItem.customer_name
      }
    };
    const viewField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Change Coupon",
        content: "--- TBD ---"
      }
    };
    const other = {
      type: CONTENT_BLOCK,
      data: {
        name: "Other Modifications",
        content: "--- TBD ---"
      }
    };
    return [program, customer, viewField, other];
  };

  const getBaseModalButtons = () => {
    const closeButton = {
      name: "Save Changes",
      handleClick: onClose,
      buttonColour: "#3B4EFF",
      textColour: "#fff"
    };
    return [closeButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Edit Invoice",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      handleClose: onClose
    };
  };

  return <BaseModal {...getBaseModalProps()} />;
};

export default AutoPayEditModal;
