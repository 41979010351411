import React from "reactn";
import SectionOne from "./forms/SectionOne";
import SectionTwo from "./forms/SectionTwo";
class Setup extends React.Component {
  render() {
    return (
      <div className="cont boxes-row mt-50px">
        <SectionOne
          initialIsRecurring={this.props.initialIsRecurring}
          initialProgramCategory={this.props.initialProgramCategory}
          initialApplicationRequired={this.props.initialApplicationRequired}
          initialTransactionCost={this.props.initialTransactionCost}
          initialAddChild={this.props.initialAddChild}
          initialAutoWithdraw={this.props.initialAutoWithdraw}
          initialManualInvoices={this.props.initialManualInvoices}
          allowSameEnrollment={this.props.allowSameEnrollment}
          vals={this.global.myApplicationProfileInfo}
        />
        <SectionTwo vals={this.global.myApplicationProfileInfo} />
      </div>
    );
  }
}

export default Setup;
