import React from "reactn";
import "./RequestInfo.css";
import { toast } from "react-toastify";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
class NextStageModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.required = [
      { name: "name", validation: e => !e },
      { name: "company", validation: e => !e }
    ];
  }

  requestTour = async e => {
    e.preventDefault();
  };
  componentDidMount() {}
  async submitForm(e, save = false) {
    if (!save) e.preventDefault();
    let cancel = false;
    for (let requiredField of this.required) {
      if (
        requiredField.validation(
          this.state[requiredField.name] === undefined
            ? this.props.data[requiredField.name]
            : this.state[requiredField.name]
        )
      ) {
        document.getElementsByName(requiredField.name)[0].style.boxShadow =
          "none";
        document.getElementsByName(requiredField.name)[0].style.border =
          "1px solid red";
        document.getElementsByName(requiredField.name)[0].scrollIntoView();
        cancel = true;
      }
    }
    if (cancel) return;
    const endp = `${process.env.REACT_APP_API}/adm/partner/update`;
    const result = await axios.put(endp, {
      ...this.state,
      categorie: !save ? 1 : this.props.data.categorie,
      id: this.props.data.id
    });
    if (result.data.success) {
      toast.success(save ? "Save Successful!" : "Updated Lead!");
      const objToUpdate = {
        ...this.state,
        categorie: !save ? 1 : this.props.data.categorie,
        id: this.props.data.id
      };
      if (save) delete objToUpdate.categorie;
      this.props.update(objToUpdate);
      this.props.onClose();
    }
  }

  render() {
    const bottomText = `Client to start the Children's Program (DS) package for $75 a month starting May 15,2020. Client has selected 5 Users, and 12 Program / Event Cap. With the promotions, client will pay $50 a month for 6 months
      There is a $150 deposit that is required.`;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ position: "relative" }}>
            {this.props.page === 0 && (
              <>
                <h3>Stages</h3>
                <form onSubmit={e => this.submitForm(e)}>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Name *
                    </label>
                    <input
                      name="name"
                      id="displayName"
                      type="text"
                      className="form-control"
                      placeholder="Lead's full name..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.name === undefined
                          ? this.props.data.name
                          : this.state.name
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Organization *
                    </label>
                    <input
                      name="company"
                      id="organization"
                      type="text"
                      className="form-control"
                      placeholder="Organization's name..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.company === undefined
                          ? this.props.data.company
                          : this.state.company
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Postal / Zip
                    </label>
                    <input
                      name="postalcode"
                      id="postal"
                      type="text"
                      className="form-control"
                      placeholder="Postal Code..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.postalcode === undefined
                          ? this.props.data.postalcode
                          : this.state.postalcode
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="email"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Email
                    </label>
                    <input
                      name="email"
                      id="email"
                      type="email"
                      placeholder="Lead's Email..."
                      className="form-control"
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.email === undefined
                          ? this.props.data.email
                          : this.state.email
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Mobile
                    </label>
                    <input
                      name="phone"
                      id="mobile"
                      type="text"
                      className="form-control"
                      placeholder="Phone Number..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.phone === undefined
                          ? this.props.data.phone
                          : this.state.phone
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Notes
                    </label>
                    <textarea
                      name="notes"
                      id="notes"
                      type="text"
                      className="form-control"
                      style={{ width: "70%" }}
                      rows={4}
                      placeholder="Notes..."
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.notes === undefined
                          ? this.props.data.notes
                          : this.state.notes
                      }
                    ></textarea>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Address 1
                    </label>
                    <input
                      name="address1"
                      id="postal"
                      type="text"
                      className="form-control"
                      placeholder="Address 1..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.address1 === undefined
                          ? this.props.data.address1
                          : this.state.address1
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Address 2
                    </label>
                    <input
                      name="address2"
                      id="postal"
                      type="text"
                      className="form-control"
                      placeholder="Address 2..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.address2 === undefined
                          ? this.props.data.address2
                          : this.state.address2
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      City
                    </label>
                    <input
                      name="city"
                      id="postal"
                      type="text"
                      className="form-control"
                      placeholder="City..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.city === undefined
                          ? this.props.data.city
                          : this.state.city
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Plan Type
                    </label>
                    <select
                      name="plan_type"
                      id="postal"
                      type="text"
                      className="form-control"
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.plan_type === undefined
                          ? this.props.data.plan_type
                          : this.state.plan_type
                      }
                    >
                      <option>Children's Program - DS</option>
                      <option>Children's Program - Custom</option>
                      <option>Daycare / Preschool - DS</option>
                      <option>Daycare / Preschool - Custom</option>
                      <option>Kids Summer Camp - DS</option>
                      <option>Kids Summer Camp - Custom</option>
                      <option>Kids Athletic Program - DS</option>
                      <option>Kids Athletic Program - Custom</option>
                      <option>Service Volunteer- Custom</option>
                      <option>Educational Program - Custom</option>
                      <option>Foundation Program - Custom</option>
                      <option>Community Center - Custom</option>
                    </select>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Stage *
                    </label>
                    <select
                      name="stage"
                      id="postal"
                      type="text"
                      className="form-control"
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.stage === undefined
                          ? this.props.data.stage
                          : this.state.stage
                      }
                    >
                      <option>1st Meeting</option>
                      <option>Follow-up</option>
                      <option>Demo & Education</option>
                    </select>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Interest
                    </label>
                    <select
                      name="interest"
                      id="postal"
                      type="text"
                      className="form-control"
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.interest === undefined
                          ? this.props.data.interest
                          : this.state.interest
                      }
                    >
                      <option>Low</option>
                      <option>Medium</option>
                      <option>High</option>
                    </select>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={_ => this.submitForm(null, true)}
                      className="btn profile-btn"
                    >
                      Save
                    </button>
                    {!this.props.edit && (
                      <button type="submit" className="btn profile-btn">
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </>
            )}
          </div>
          <IconButton
            style={{ position: "absolute", right: "0", top: "0" }}
            onClick={_ => this.props.onClose()}
          >
            <Close />
          </IconButton>
        </div>
      </div>
    );
  }
}

export default withRouter(NextStageModal);
