import React from "reactn";
import { format, addDays, subDays, startOfWeek, endOfWeek } from "date-fns";
import WeeklySlotsTable from "./WeeklySlotsTable/WeeklySlotsTable";
import { Marks, DataArrowLeft, DataArrowRight } from "../../../../assets";
import "./weeklySlots.css";
import axios from "axios";
import { numToHours } from "../../../../lib/util";

class WeeklySlots extends React.PureComponent {
  constructor(props) {
    super(props);
    const today = new Date();
    this.state = {
      timeStart:
        this.props.program.myProgramLocationHours.hoursOfOperationStart,
      timeEnd: this.props.program.myProgramLocationHours.hoursOfOperationEnd,
      start: startOfWeek(today),
      end: endOfWeek(today),
      slots: [],
      availability: []
    };
    this.changeDatePeriod = this.changeDatePeriod.bind(this);
  }

  getNumericDay(day) {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    return days.indexOf(day);
  }

  async componentDidMount() {
    // this.dispatch.getWeeklySlots();
    const ep = `${process.env.REACT_APP_API}/edu/enrollment/slots/${
      this.props.program.profile.id
    }/${format(this.state.start, "yyyy-MM-dd")}/${format(
      this.state.end,
      "yyyy-MM-dd"
    )}`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      for (let i = 0; i < res.data.data.slots.length; i++) {
        if (res.data.data.slots.length === 2) delete res.data.data.slots[1];
      }
      this.setState({
        slots: res.data.data.powers,
        availability: res.data.data.availability
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      console.error(ex);
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  async changeDatePeriod(direction) {
    const start =
      direction === "next"
        ? addDays(new Date(this.state.start), 7)
        : subDays(new Date(this.state.start), 7);
    this.setState({ start: start, end: endOfWeek(start) });
    // this.dispatch.setWeeklySlotsPeriod(start);
    const ep = `${process.env.REACT_APP_API}/edu/enrollment/slots/${
      this.props.program.profile.id
    }/${format(start, "yyyy-MM-dd")}/${format(endOfWeek(start), "yyyy-MM-dd")}`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      this.setState({
        slots: res.data.data.slots,
        availability: res.data.data.availability
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      console.error(ex);
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }
  render() {
    return (
      <div className="container-fluid enrolled_2">
        <div className="cont">
          <h1>
            Weekly Slots{" "}
            <span>{`${numToHours(this.state.timeStart)} - ${numToHours(
              this.state.timeEnd
            )}`}</span>
          </h1>
        </div>
        <div className="cont">
          <div className="row">
            <div className="col-lg-6">
              <img src={Marks} className="marks" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="data">
                <p>
                  <button
                    style={{ background: "none", border: "none" }}
                    className="pl-3 pr-3 mr-2"
                    onClick={() => this.changeDatePeriod("prev")}
                  >
                    <img src={DataArrowLeft} alt="Previous" />
                  </button>
                  <span>
                    {format(this.state.start, "LLL d")} -&nbsp;
                    {format(this.state.end, "LLL d")},&nbsp;
                    {format(this.state.start, "yyyy")}
                  </span>
                  <button
                    style={{ background: "none", border: "none" }}
                    className="pl-3 pr-3 ml-2"
                    onClick={() => this.changeDatePeriod("next")}
                  >
                    <img src={DataArrowRight} alt="Next" />
                  </button>
                </p>
              </div>
            </div>
          </div>
          <WeeklySlotsTable
            slots={this.state.slots}
            availability={this.state.availability}
            profile={this.props.program.profile}
            myProgramLocationHours={this.props.program.myProgramLocationHours}
          />
        </div>
      </div>
    );
  }
}

export default WeeklySlots;
