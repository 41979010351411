import React, { useEffect, useState, useReducer, useGlobal } from "reactn";
import PropTypes from "prop-types";
import axios from "axios";
import { format } from "date-fns";
import { toast } from "react-toastify";

import StepBar from "../../../components/UI/StepBar";
import { StepIndicator } from "../../../components/UI";
import Validators from "../../../validators";

import { sendPromotionData, updatePromotion } from "./PromotionSetupUtils";

import {
  reducer,
  initial_state,
  PromotionContext,
  actions,
  set_id_action,
  set_title_action,
  set_description_action,
  set_promotion_type,
  set_discount_type,
  set_discount_amount,
  set_duration_applied,
  set_promo_limit_toggle,
  set_promo_limit_number,
  set_expiry_date_toggle,
  set_expiry_date,
  set_prices_action,
  set_selected_prices_action,
  set_plans_action,
  set_promotion_type_list,
  set_seleted_plans_action,
  set_selected_tickets_action,
  set_tickets_action,
  set_show_promo_only_toggle
} from "./promotion.context";
import Step0 from "./Step0";

export function getPage(step) {
  if (step === undefined) {
    throw new Error("Step is a required argument");
  }
  switch (step) {
    case 0:
      return <Step0 />;
    default:
      return <div>error, unknown step</div>;
  }
}

const validations = [
  function (state) {
    const {
      title,
      description,
      discountAmount,
      selectedPromotion,
      isLimitPromoCode,
      limitPromoCode
    } = state;
    if (!title) {
      return { succes: false, message: "Title is required" };
    }
    if (!description) {
      return { success: false, message: "Description is Required" };
    }
    if (discountAmount <= 0) {
      return {
        success: false,
        message: "Discount has to be a positive number"
      };
    }
    if (selectedPromotion === -1) {
      return {
        success: false,
        message:
          "You need to select a Program, Event, Party, Membership, or Online value."
      };
    }
    if (isLimitPromoCode && limitPromoCode <= 0) {
      return {
        success: false,
        message: "Limit Promo Usage must be 1 or greater"
      };
    }
    return { success: true, message: "save results" };
  }
];

const steps = data => [
  {
    name: "Set Up",
    validateFn: () => Validators.Partners.Profile.ValidatePromotionsOne(data)
  }
];

function checkValid(validations, step, state) {
  if (validations[step] === undefined) {
    throw new Error(`Validation for step ${step} is not defined`);
  }
  return validations[step](state);
}

const PromotionSetup = props => {
  const { history } = props;
  const [step, _] = useState(0);
  const [setIndicatorValue, setSetIndicatorValue] = useState(0);
  const [state, dispatch] = useReducer(reducer, initial_state);
  const [isCreated, setIsCreated] = useState(false);
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");
  const [loading, setLoading] = useGlobal("loading");

  const checkIncomingParameters = () => {
    if (history.location.state) {
      const incommingValues = history.location.state;
      dispatch({
        type: actions.UPDATE_PROMOTION_TYPE,
        payload: incommingValues.type
      });
      dispatch({
        type: actions.UPDATE_IS_ACTIVE,
        payload: incommingValues.isActive
      });
      if (incommingValues.selectedPromotion) {
        dispatch({
          type: actions.SET_SELECTED_PROMOTION_TYPE,
          payload: incommingValues.selectedPromotion
        });
        const selectedPromotionsList =
          incommingValues.promotion_type_list.filter(
            p => p.id === +incommingValues.selectedPromotion
          );
        console.log("selectedPromotionsList", selectedPromotionsList);
        dispatch({
          type: actions.SET_PROMOTION_TYPE_LIST,
          payload: selectedPromotionsList
        });

        if (
          incommingValues.type === "program" ||
          incommingValues.type === "online"
        ) {
          if (!selectedPromotionsList[0]?.plans) {
            return;
          }
          const parsedPlans = selectedPromotionsList[0].plans.map(plan =>
            JSON.parse(plan)
          );
          // filter installments = true plans
          const installmentsFilteredPlans = parsedPlans; //.filter(plan => !plan.installments)
          dispatch({
            type: actions.SET_PLANS_LIST,
            payload: installmentsFilteredPlans
          });
        } else if (incommingValues.type === "event") {
          const parsedTickets = selectedPromotionsList[0].tickets.map(ticket =>
            JSON.parse(ticket)
          );
          dispatch({ type: actions.SET_TICKETS_LIST, payload: parsedTickets });
        } else if (incommingValues.type === "party") {
          const parsedTickets = selectedPromotionsList[0].tickets.map(ticket =>
            JSON.parse(ticket)
          );
          dispatch({ type: actions.SET_TICKETS_LIST, payload: parsedTickets });
        } else if (incommingValues.type === "membership") {
          if (!selectedPromotionsList[0]) {
            dispatch({ type: actions.SET_PRICES_LIST, payload: [] });
          } else {
            const parsedPrices = selectedPromotionsList[0].prices.map(price =>
              JSON.parse(price)
            );
            dispatch({ type: actions.SET_PRICES_LIST, payload: parsedPrices });
          }
        }
      }
    }
  };
  const checkIncomingUrlParams = async () => {
    const promotionId = props.match.params.id;
    if (promotionId) {
      const ep = `${process.env.REACT_APP_API}/partners/promotions/edit/${promotionId}`;
      const promotionR = await axios.get(ep);
      console.log("promotionR", promotionR);
      if (promotionR.data && promotionR.data.success) {
        const old_p = promotionR.data.data;

        // dispatch to state
        set_id_action(old_p.id, dispatch);
        set_title_action(old_p.title, dispatch);
        set_description_action(old_p.description, dispatch);
        set_promotion_type(old_p.promotion_type, dispatch);
        set_show_promo_only_toggle(old_p.show_promo_only, dispatch);
        dispatch({
          type: actions.SET_SELECTED_PROMOTION_TYPE,
          payload: 0
        });

        set_discount_type(old_p.discount_type, dispatch);
        if (old_p.discount_type === "Percentage") {
          set_discount_amount(old_p.discount_percent, dispatch);
        } else {
          set_discount_amount(old_p.discount_fixed, dispatch);
        }

        set_duration_applied(old_p.duration_applied, dispatch);
        if (old_p.expiry_date) {
          set_expiry_date_toggle(true, dispatch);
          const expiryDate = format(new Date(old_p.expiry_date), "yyyy-MM-dd");
          set_expiry_date(expiryDate, dispatch);
        } else {
          set_expiry_date_toggle(false, dispatch);
        }

        if (old_p.promo_limit !== null) {
          set_promo_limit_toggle(old_p.promo_limit, dispatch);
          set_promo_limit_number(old_p.promo_limit_times, dispatch);
        } else {
          set_promo_limit_toggle(false, dispatch);
        }

        if (old_p.promotion_type === "program") {
          set_promotion_type_list([old_p.program], dispatch);
          const plans = old_p.program.plans.map(p => JSON.parse(p));
          set_plans_action(plans, dispatch);
          const selected_plans = plans.filter(pln =>
            old_p.plan_price_ticketId.includes(pln.id)
          );
          set_seleted_plans_action(selected_plans, dispatch);
        } else if (old_p.promotion_type === "membership") {
          set_promotion_type_list([old_p.membership], dispatch);
          const prices = old_p.membership.prices.map(p => JSON.parse(p));
          const selected_prices = prices.filter(price =>
            old_p.plan_price_ticketId.includes(price.id)
          );

          set_prices_action(prices, dispatch);

          set_selected_prices_action(selected_prices, dispatch);
        } else if (old_p.promotion_type === "event") {
          set_promotion_type_list([old_p.event], dispatch);
          const tickets = old_p.event.tickets.map(tkt => JSON.parse(tkt));
          set_tickets_action(tickets, dispatch);
          const selected_tickets = tickets.filter(ticket =>
            old_p.plan_price_ticketId.includes(ticket.id)
          );
          set_selected_tickets_action(selected_tickets, dispatch);
        } else if (old_p.promotion_type === "party") {
          set_promotion_type_list([old_p.party], dispatch);
          const tickets = old_p.party.tickets.map(tkt => JSON.parse(tkt));
          set_tickets_action(tickets, dispatch);
          const selected_tickets = tickets.filter(ticket =>
            old_p.plan_price_ticketId.includes(ticket.id)
          );
          set_selected_tickets_action(selected_tickets, dispatch);
        }
      } else if (
        !promotionR.data ||
        (promotionR.data && !promotionR.data.success)
      ) {
        toast.error(promotionR.data.message.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    }
  };
  useEffect(() => {
    if (props.match.params.id) {
      checkIncomingUrlParams();
    } else {
      checkIncomingParameters();
    }
  }, []);

  const saveProfile = async () => {
    const isValid = checkValid(validations, step, state);
    setLoading(true);
    if (!isValid.success) {
      toast.error(isValid.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    } else {
      // update indicator value
      setSetIndicatorValue((1 / validations.length) * 100);
      // send data to backend
      if (state.id) {
        const resp = await updatePromotion(state);
        console.log("resp", resp);
        if (resp.data && resp.data.success) {
          toast.success(resp.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
        if (!resp.data || (resp.data && !resp.data.success)) {
          toast.error(resp.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
        setLoading(false);
        return;
      }
      if (!isCreated) {
        const resp = await sendPromotionData(state);
        if (resp.success) {
          toast.success(resp.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          setIsCreated(true);
        } else {
          toast.error(resp.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    (() =>
      Validators.Partners.Profile.ValidatePromotionsOne(state)
        ? setSetIndicatorValue(100)
        : setSetIndicatorValue(0))();
    // setSetIndicatorValue((1 / validations.length) * 100);
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Promotions", to: "/promotion/list" },
      {
        label: state.title || "Promotion Setup",
        to: props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
      }
    ]);
  }, [state]);

  return (
    <div className="container-fluid" data-testid="new-promotion">
      {/* Commented out to make space for Top Bar */}
      {/* <div className="cont">
        <h1 className="events-title-header">Promotions Setup</h1>
      </div> */}
      <StepBar
        {...{
          steps: steps(state),
          changeFn: () => {},
          current: step
        }}
      />
      <StepIndicator value={setIndicatorValue} />
      <PromotionContext.Provider value={{ state, dispatch }}>
        {getPage(step)}
      </PromotionContext.Provider>
      <div className="cont mb-5">
        <div className="buttons w-100">
          <button
            onClick={saveProfile}
            name="save"
            className="savebtn"
            style={{ background: "#b2b8c7", border: "1px solid #b2b8c7" }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

PromotionSetup.propTypes = {
  history: PropTypes.object
};

export default PromotionSetup;
