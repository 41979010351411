import React from "reactn";
import axios from "axios";
import { format } from "date-fns";
import EmergencyContact from "./EmergencyContact";
import PhysicianContact from "./PhysicianContact";
import GuardianContact from "./ParentGuardian";
import KidInfo from "./KidInfo";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import { CUnisex } from "../../../assets";

class ChildProfile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      guardians: [],
      contacts: [],
      physicians: [],
      contactTab: 0,
      child: null
    };
    this.updateScreenSize = this.updateScreenSize.bind(this);
  }

  updateScreenSize() {
    this.setState({
      smallScreen: window.innerWidth < 1025,
      phoneSized: window.innerWidth < 600
    });
  }

  async componentDidMount() {
    this.updateScreenSize();
    window.addEventListener("resize", this.updateScreenSize);
    await this.fetchInitialData();
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Connections", to: "/connections" },
        {
          label: this.state.guardians[0]?.fullName,
          to: `/single/connections/${this.state.guardians[0]?.email}/true?parentId=${this.state.guardians[0]?.dsUserId}`
        },
        {
          label: `Child Profile: ${this.state.child.fullName}`,
          to: this.props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
        }
      ]
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenSize);
  }

  fetchInitialData = async () => {
    const ep = `${process.env.REACT_APP_API}/edu/enrollment/child-profile/${this.props.match.params.dsUserId}/${this.props.match.params.childId}`;
    const results = await axios.get(ep);

    if (results.data.success) {
      console.log("data: ", results.data.data);
      this.setState({
        ...results.data.data
      });
    }
  };

  downloadPDF = () => {
    // let screenHeight = 1200;
    // let screenWidth = 900;
    // let portraitWidth = 612;
    // let portraitHeight = 791;

    this.setGlobal({ loading: true });
    const domElement = document.getElementById("profile-pdf");
    html2canvas(domElement, {
      onclone: document => {
        document.getElementById("pdf-download").style.visibility = "hidden";
      },
      scale: 3,
      scrollX: 0,
      scrollY: 0
    }).then(canvas => {
      // const img = canvas.toDataURL('image/png')
      // const pdf = new jsPdf()
      // pdf.addImage(img, 'JPEG', 0, 0, 200, 220)
      // pdf.save('ChildProfile.pdf')

      // MAKE YOUR PDF
      const PDFMargin = 20;
      const MAX_HEIGHT = 260;
      const img = canvas.toDataURL("image/png");
      const pdf = new jsPdf("p", "mm", "letter", true);

      const imgProps = pdf.getImageProperties(img);
      let width = pdf.internal.pageSize.getWidth() - 2 * PDFMargin;
      let height = (imgProps.height * width) / imgProps.width;

      if (height > MAX_HEIGHT) {
        height = MAX_HEIGHT;
        width = (imgProps.width * height) / imgProps.height;
      }
      pdf.addImage(img, "JPEG", PDFMargin / 2, PDFMargin / 2, width, height);

      // for (let i = 0; i <= domElement.clientHeight / screenHeight; i++) {
      //   //! This is all just html2canvas stuff
      //   const srcImg = canvas;
      //   const sX = 0;
      //   const sY = screenHeight * i; // start 980 pixels down for every new page
      //   const sWidth = screenWidth;
      //   const sHeight = screenHeight;
      //   const dX = 0;
      //   const dY = 0;
      //   const dWidth = screenWidth;
      //   const dHeight = screenHeight;

      //   let onePageCanvas = document.createElement("canvas");
      //   onePageCanvas.setAttribute("width", screenWidth);
      //   onePageCanvas.setAttribute("height", screenHeight);
      //   let ctx = onePageCanvas.getContext("2d");
      //   // details on this usage of this function:
      //   // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
      //   ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

      //   // document.body.appendChild(canvas);
      //   const canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0); //

      //   const width = onePageCanvas.width;
      //   const height = onePageCanvas.clientHeight;

      //   //! If we're on anything other than the first page,
      //   // add another page
      //   if (i > 0) {
      //     pdf.addPage([portraitWidth, portraitHeight], "portrait"); //8.5" x 11" in pts (in*72)
      //   }
      //   //! now we declare that we're working on that page
      //   pdf.setPage(i + 1);
      //   //! now we add content to that page!
      //   pdf.addImage(canvasDataURL, "PNG", 20, 40, width * 0.62, height * 0.62);
      // }
      //! after the for loop is finished running, we save the pdf.
      pdf.save("ChildProfile.pdf");
      this.setGlobal({ loading: false });
    });
  };

  render() {
    return (
      <div className="admin">
        <div
          className="container-fluid adminprofiles"
          id="profile-pdf"
          style={{ width: "600px", marginLeft: "0" }}
        >
          <div className="row cont">
            <div className="col-md-8">
              <div style={{ display: "block" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "-30px"
                  }}
                >
                  <h1 style={{ marginRight: "30px" }}>Child Profile</h1>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "-8px",
                    marginTop: "5px",
                    fontSize: "14px"
                  }}
                >
                  <span style={{ marginLeft: "10px" }}>
                    View Date: {format(new Date(), "LLLL dd, yyyy")}
                  </span>
                  {!this.state.smallScreen && (
                    <span
                      id="pdf-download"
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        marginLeft: "10px",
                        fontStyle: "italic"
                      }}
                      onClick={_ => this.downloadPDF()}
                    >
                      (Print PDF)
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="cont" style={{ marginTop: "30px", display: "flex" }}>
            <div className="boxes d-flex" style={{ flexWrap: "wrap" }}>
              {!this.state.child ? null : <KidInfo child={this.state.child} />}
            </div>

            <div className="img">
              <img
                style={{ maxWidth: 160, maxHeight: 160 }}
                src={CUnisex}
                alt=""
              />
            </div>
          </div>

          <div className="cont" style={{ marginTop: "30px" }}>
            <div className="boxes d-flex" style={{ flexWrap: "wrap" }}>
              {!this.state.guardians ||
              !this.state.physicians ||
              !this.state.contacts ? null : (
                <div
                  className="box"
                  style={{
                    maxWidth: 540,
                    minWidth: 380,
                    height: "fit-content",
                    flexGrow: 1
                  }}
                >
                  <div className="bgofbox">
                    <div className="header">
                      <div className="row">
                        <div className="col-4">
                          <h2>DETAILS</h2>
                        </div>
                        <div className="col-8 d-flex">
                          <button
                            className={
                              0 === this.state.contactTab ? "active" : ""
                            }
                            onClick={() => this.setState({ contactTab: 0 })}
                          >
                            Parent / Guardians
                          </button>
                          <button
                            className={
                              1 === this.state.contactTab ? "active" : ""
                            }
                            onClick={() => this.setState({ contactTab: 1 })}
                          >
                            Emergency
                          </button>
                          <button
                            className={
                              2 === this.state.contactTab ? "active" : ""
                            }
                            onClick={() => this.setState({ contactTab: 2 })}
                          >
                            Physicians
                          </button>
                        </div>
                      </div>
                    </div>
                    {this.state.contactTab !== 0 ? null : (
                      <GuardianContact contacts={this.state.guardians.filter(e => !e.isArchived)} />
                    )}
                    {this.state.contactTab !== 1 ? null : (
                      <EmergencyContact contacts={this.state.contacts} />
                    )}
                    {this.state.contactTab !== 2 ? null : (
                      <PhysicianContact contacts={this.state.physicians} />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChildProfile;
