import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "./DateInput.css";
import { DateTime } from "luxon";
import moment from "moment";
import { getTodayDateInLocalTZ, convertToDateOnlyISOString } from "../../../lib/util";

const DateOnlyInput = props => {

  console.assert(!props.value || typeof props.value==="string" || props.value instanceof String,
                  "Expected props.value to be a string!" );
  console.assert(!props.value || /^\d\d\d\d-\d\d-\d\d$/.test(props.value),
                  "Expected props.value to be in ISO8601 date-only format [yyyy-mm-dd]!" )

  //The React DateTime Picker only recognizes the local time zone, so whatever date we prepare,
  //must have the local hours set to 0. Setting UTC hours to 0 may cause the date to go back by one day.
  const dateOnlyInLocalTZ = props.value
      ? new Date(props.value + "T00:00:00")
      : getTodayDateInLocalTZ();

  //Now flag errors that happened in the step above!
  //e.g. props.value was a non-date string, Date object is still created but it is invalid!
  if ( isNaN(dateOnlyInLocalTZ.valueOf()) )
    throw new Error("Invalid props.value specified for DateOnlyInput (datetime picker)!", {cause: props.value});



  const { isDisabled } = props;

  return (
    <DatePicker
      id={props.id || ""}
      onChange={e => e && props.onChange(props.name, convertToDateOnlyISOString(e))}
      className="select custom-date"
      dateFormat="MMMM d, yyyy"
      showMonthDropdown
      showYearDropdown
      showTimeSelect={false}
      selected={dateOnlyInLocalTZ}
      disabled={!!isDisabled}
      minDate={props.minDate ? props.minDate : null}
      maxDate={props.maxDate ? props.maxDate : null}
      filterDate={props.filterDate ? props.filterDate : null}
    />
  );
};

const DateInput = props => {
  const date = props.value ? new Date(props.value) : null;

  // let rezonedDate;
  let rezonedOffset;
  let modifiedDate = null;
  if (date) {
    const currentTimeZone = DateTime.fromISO(date.toISOString());

    const rezonedTimeZone = currentTimeZone.setZone(props.timezone);
    console.log("Date Input -- Date: ", date);
    console.log("Date Input -- currentTimeZone:", currentTimeZone);
    console.log("Date Input -- rezonedTimeZone: ", rezonedTimeZone);
    //   const mo1 = moment(date.toISOString()); // in browser default time zone
    //   const mo2 = moment(date.toISOString()).tz(props.timezone); // in current state time zone

    //  modifiedDate = moment(date);

    //  modifiedDate = modifiedDate.subtract(mo1.utcOffset() - mo2.utcOffset(), 'minutes');

    modifiedDate = moment(date.toISOString());

    modifiedDate = modifiedDate.subtract(
      currentTimeZone.offset - rezonedTimeZone.offset,
      "minutes"
    );

    modifiedDate = modifiedDate.toDate();

    console.log("Modified Date: ", modifiedDate);

    // const d = date.toLocaleString("en-US", {timezone: props.timezone});
    // const dconverter = DateTime.local(d);
    // const UTC_OFFSET = date.getTimezoneOffset();
    // date.setMinutes(date.getMinutes() * UTC_OFFSET);

    // const dt = new Date(date).toISOString();
    // const luxonDate = DateTime.fromISO(dt, { zone: props.timezone });
    // // rezonedDate = luxonDate.setZone(props.timezone);
    // rezonedOffset = luxonDate.offset;
    // date.setMinutes(date.getMinutes() * rezonedOffset);
  }
  // const date = props.value ? new Date(props.value) : null;
  const { isDisabled } = props;
  return (
    <DatePicker
      id={props.id ? props.id : ""}
      onChange={e => props.onChange(props.name, e)}
      className="select custom-date"
      dateFormat={props.time ? "MMMM d, yyyy hh:mm aa" : "MMMM d, yyyy"}
      showMonthDropdown
      showYearDropdown
      showTimeInput={props.time}
      selected={props.timezone ? modifiedDate : date}
      disabled={isDisabled ? true : false}
      minDate={props.minDate ? props.minDate : null}
      maxDate={props.maxDate ? props.maxDate : null}
      filterDate={props.filterDate ? props.filterDate : null}
      shouldCloseOnSelect={!props.shouldStayOpen}
    />
  );
};
const DateInputMulti = props => {
  const date = props.value ? new Date(props.value) : null;
  const { isDisabled } = props;
  const a = <DatePicker />;

  return (
    <DatePicker
      onChange={e => props.onChange(props.name, e)}
      className="select custom-date"
      dateFormat={props.time ? "MMMM d, yyyy hh:mm aa" : "MMMM d, yyyy"}
      showMonthDropdown
      showYearDropdown
      showTimeInput={props.time}
      selected={date}
      disabled={isDisabled ? true : false}
      minDate={props.minDate ? props.minDate : null}
      maxDate={props.maxDate ? props.maxDate : null}
      filterDate={props.filterDate ? props.filterDate : null}
    />
  );
};

const DateWithCheckMark = props => {
  const date = props.value ? new Date(props.value) : null;
  const [disabled, setDisabled] = useState(false);
  const { isDisabled } = props;
  return (
    <div style={{ position: "relative" }}>
      {date ? (
        date.toString() !== "Invalid Date" ? (
          <DatePicker
            onChange={e => props.onChange(props.name, e)}
            className="select custom-date"
            dateFormat="MMMM d, yyyy"
            showMonthDropdown
            showYearDropdown
            selected={date}
            disabled={disabled || isDisabled ? true : false}
            minDate={props.minDate ? props.minDate : null}
            maxDate={props.maxDate ? props.maxDate : null}
            filterDate={props.filterDate ? props.filterDate : null}
          />
        ) : (
          <input
            type="text"
            value={"TBD"}
            onChange={e => props.onChange(props.name, e)}
          />
        )
      ) : (
        <DatePicker
          onChange={e => props.onChange(props.name, e)}
          className="select custom-date"
          dateFormat="MMMM d, yyyy"
          showMonthDropdown
          showYearDropdown
          selected={date}
          disabled={disabled || isDisabled ? true : false}
          minDate={props.minDate ? props.minDate : null}
          maxDate={props.maxDate ? props.maxDate : null}
          filterDate={props.filterDate ? props.filterDate : null}
        />
      )}

      <div
        style={{
          position: "absolute",
          top: 0,
          right: "10px",
          display: "flex",
          alignItems: "center"
        }}
      >
        <input
          type="checkbox"
          name="macheck"
          style={{ marginRight: "5px" }}
          checked={date && date.toString() === "Invalid Date"}
          onClick={e => {
            setDisabled(e.target.checked);
            props.onChange(props.name, e.target.checked ? "TBD" : null);
          }}
        />
        <label for="macheck" style={{ marginBottom: 0 }}>
          TBD !
        </label>
      </div>
    </div>
  );
};
const MonthYearInput = props => {
  return (
    <DatePicker
      onChange={e => props.onChange(props.name, e)}
      className="select custom-date"
      dateFormat="MM/yy"
      showMonthYearPicker
      selected={props.value}
    />
  );
};

const DateInputs = {
  DateInput,
  DateOnlyInput,
  MonthYearInput,
  DateWithCheckMark
};

export default DateInputs;
