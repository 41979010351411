import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import BaseModal from "./baseModal/BaseModal";
import {
  CONTENT_BLOCK,
  TITLE
} from "./baseModal/FieldTypes";

class EventShareLinkModal extends React.PureComponent {
    constructor(props) {
      super(props);
    }

    getBaseModalFields = () => {
        const eventLink = {
            type: CONTENT_BLOCK,
            data: {
                name: "Copy and paste the link below:",
                content: `${process.env.REACT_APP_MARKETPLACE_URL}/iframe/programs/${this.global.organizationInfo.slug}/${this.props.type.toLowerCase()}/${this.props.programId}`,
                textStyles: {
                    "overflowWrap": "break-word",
                    "wordBreak": "break-all"
                  },
            }
        }

        const fields = [];

        fields.push(eventLink);

        return fields;
    }

    getBaseModalProps = () => {
        return {
          title: `${this.props.type} Share Link`,
          fields: this.getBaseModalFields(),
          height: "300px",
          midSectionHeight: "52vh",
          handleClose: () => this.props.onClose()
        };
    };

    render() {
        return <BaseModal {...this.getBaseModalProps()}/>
    }
}

export default withRouter(EventShareLinkModal);