import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import Details from "../../components/modals/DetailsModal";
import NotesModal from "../../components/modals/NotesModal";
class Connections extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // data: [],
      filteredData: [],
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      headings: {},
      tabs: [],
      forms: [],
      allLeads: [],
      notesModalShown: false
    };
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Form Leads", to: "/appointments" }
      ]
    });
  }

  getHeadings() {
    const obj = {};
    obj["All"] = [
      // { id: "First Name", label: "First Name" },
      // { id: "Last Name", label: "Last Name" },
      { id: "Full Name", label: "Full Name" },
      { id: "Email", label: "Email" },
      {
        id: "created",
        label: "Created On",
        customCell: (r, i) => (
          <td key={i}>{format(new Date(r.createdAt), "LLLL dd,yyyy")}</td>
        )
      },
      {
        id: "actionf",
        label: "",
        customCell: (r, i) => {
          return (
            <td
              key={`${i}-btn`}
              style={{ whiteSpace: "nowrap", textAlign: "right" }}
            >
              <button
                style={{ marginRight: "30px" }}
                onClick={_ =>
                  this.setState({
                    details: true,
                    selected: r.custom,
                    formId: r.formId
                  })
                }
              >
                View Details
              </button>
            </td>
          );
        }
      }
    ];
    for (let tab of this.state.tabs) {
      obj[tab] = [
        // { id: "First Name", label: "First Name" },
        // { id: "Last Name", label: "Last Name" },
        { id: "Full Name", label: "Full Name" },
        { id: "Email", label: "Email" },
        {
          id: "created",
          label: "Created On",
          customCell: (r, i) => (
            <td key={i}>{format(new Date(r.createdAt), "LLLL dd,yyyy")}</td>
          )
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                {r.custom && (
                  <button
                    style={{ marginRight: "15px" }}
                    onClick={_ =>
                      this.setState({
                        details: true,
                        selected: r.custom,
                        formId: r.formId
                      })
                    }
                  >
                    View Details
                  </button>
                )}
                <i
                  className="fas fa-clipboard icon"
                  style={{
                    marginRight: "30px",
                    cursor: "pointer",
                    color: "#ccc"
                  }}
                  onClick={_ =>
                    this.setState({
                      notesModalShown: true,
                      modalData: r,
                      table: "partner_leads"
                    })
                  }
                ></i>
              </td>
            );
          }
        }
      ];
    }
    return obj;
  }
  getLeads() {
    const cleanString = s => {
      if (!s) return "";
      return s.trim().toLocaleLowerCase();
    };

    const filterCondition = e => {
      return cleanString(e["Full Name"]).includes(
        cleanString(this.state.filter)
      );
    };

    const obj = {};
    obj["All"] = this.state.allLeads.filter(e => filterCondition(e));

    for (let form of this.state.forms) {
      const allLeads = form.leads;
      const filteredLeads = allLeads.filter(e => filterCondition(e));
      obj[form.form_name + ` (${form.form_sub_name})`] = filteredLeads;
    }

    return obj;
  }

  setLeads() {
    this.setState({ filteredData: this.getLeads() });
  }

  async componentDidMount() {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/forms/leads`;
    const results = await axios.get(ep);

    if (results.data.success) {
      let allLeads = [];
      results.data.data.map(e => {
        allLeads.push(...e.leads.filter(f => f["Full Name"]));
      });

      this.setState(
        {
          tabs: ["All"],
          headings: {},
          forms: results.data.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          ),
          allLeads: allLeads.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
        },
        this.setLeads
      );
    }
    this.setGlobal({ loading: false });
  }

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
    if (results.data.success) {
      toast.success(`Partner Accepted`);
      this.componentDidMount();
    } else {
      toast.error(`Error, this person already signed-up!`);
    }
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  render() {
    return (
      <div className="admin">
        {this.state.details && (
          <Details
            lead={this.state.selected}
            formId={this.state.formId}
            onClose={_ => this.setState({ details: false })}
          />
        )}
        {this.state.notesModalShown && (
          <NotesModal
            data={this.state.modalData}
            table={this.state.table}
            onClose={() => {
              this.setState({
                notesModalShown: false,
                modalData: null
              });
              this.componentDidMount();
            }}
            showTime={true}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>Form Leads</h1>
            </div>
          </div>
          <div className="cont">
            <div className="tablecont">
              <div
                style={{
                  display: "inline-flex",
                  flex: "1 1",
                  border: "none",
                  borderRadius: "10px",
                  overflow: "hidden",
                  width: "100%",
                  marginBottom: 15
                }}
              >
                <i
                  className="fas fa-search"
                  style={{ padding: "8px", background: "white" }}
                />
                <input
                  type="text"
                  placeholder="Filter by full name ..."
                  style={{
                    border: "none",
                    fontSize: "13px",
                    padding: "5px",
                    outline: 0,
                    background: "white",
                    flex: 1
                  }}
                  onChange={e => {
                    this.setState({ filter: e.target.value }, this.setLeads);
                  }}
                />
              </div>
              <div className="table-responsive">
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <select
                    name="selectForm"
                    id="selectForm"
                    type="text"
                    className="form-control"
                    style={{ width: "22%" }}
                    onChange={e => this.setState({ tabs: [e.target.value] })}
                  >
                    <option value="All">All</option>
                    {this.state.forms.map((e, i) => {
                      return (
                        <option
                          key={i}
                          value={`${e.form_name} (${e.form_sub_name})`}
                        >
                          {e.form_name} ({e.form_sub_name})
                        </option>
                      );
                    })}
                  </select>
                </div>
                <NewTabbedTable
                  tabs={this.state.tabs}
                  headings={this.getHeadings()}
                  data={this.state.filteredData}
                  actions={this.actions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Connections;
