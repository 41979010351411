import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import {
  CONTENT_BLOCK,
  BUTTON,
  TEXT_AREA,
  IMAGE_CONTENT,
  DROPDOWN,
  TITLE
} from "./baseModal/FieldTypes";
import BaseModal from "./baseModal/BaseModal";
import {
  BLUE_BUTTON,
  GREEN_BUTTON,
  RED_BUTTON,
  WHITE,
  YELLOW_BUTTON
} from "./baseModal/colours";

class ApplicationDenyNotificationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: ""
    };
  }

  getBaseModalFields() {
    const titleField = {
      type: TITLE,
      data: {
        name: "Would you like to notify the parent?"
      }
    };

    const allFields = [];
    allFields.push(titleField);
    return allFields;
  }

  getBaseModalButtons() {
    const yesButton = {
      name: "Yes",
      handleClick: () => this.props.rejectApplication(),
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      isDisabled: this.props.canSubUserEdit
    };

    const noButton = {
      name: "No",
      handleClick: () => this.props.rejectApplicationSilent(),
      buttonColour: RED_BUTTON,
      textColour: WHITE,
      isDisabled: this.props.canSubUserEdit
    };

    return [yesButton, noButton];
  }

  getBaseModalProps() {
    const baseModalProps = {
      title: "Deny Application",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };

    return baseModalProps;
  }

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(ApplicationDenyNotificationModal);
