import React from "reactn";
import PropTypes from "prop-types";
import { default as ToastCalendar } from "@toast-ui/react-calendar";
import "tui-calendar/dist/tui-calendar.css";
import { addDays } from "date-fns";
import "./calendar.css";
import axios from "axios";
import { toast } from "react-toastify";
import { MiniPlus } from "../../assets/index";
import { withRouter } from "react-router-dom";
import CreateStaffScheduleModal from "../../components/modals/CreateStaffScheduleModal";
import StaffScheduleInfoModal from "../../components/modals/StaffScheduleInfoModal";
import "./teamCalendar.css";
import {
  Avatar1b,
  Avatar2b,
  Avatar3b,
  Avatar4b,
  Avatar5b,
  Avatar6b,
  Avatar7b,
  Avatar8b,
  Avatar9b,
  Megaphone
} from "../../assets";
import Avatar from "@material-ui/core/Avatar";
import EditStaffScheduleModal from "../../components/modals/EditStaffScheduleModal";
import DeleteStaffScheduleModal from "../modals/DeleteStaffScheduleModal";
import StaffScheduleCheckInModal from "../modals/StaffScheduleCheckInModal";
import StaffScheduleCheckInConfirmationModal from "../modals/StaffScheduleCheckInConfirmationModal";
import StaffScheduleCheckOutConfirmationModal from "../modals/StaffScheduleCheckOutConfirmationModal";

import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";

const today = new Date();

class TeamCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayed: new Date().toLocaleString("default", {
        month: "long",
        year: "numeric"
      }),
      avatarMap: {
        "1b": Avatar1b,
        "2b": Avatar2b,
        "3b": Avatar3b,
        "4b": Avatar4b,
        "5b": Avatar5b,
        "6b": Avatar6b,
        "7b": Avatar7b,
        "8b": Avatar8b,
        "9b": Avatar9b
      },
      selectedWeekDates: [],
      weekAdjustments: 0,
      dayAdjustments: 0,
      openCreateStaffScheduleModal: false,
      openStaffScheduleInfoModal: false,
      openStaffScheduleCheckInModal: false,
      users: [],
      roles: [],
      selectedStaff: null,
      selectedDate: null,
      selectedSchedule: null,
      schedules: [[], [], [], [], [], [], []],
      staff: "",
      task: "Schedule",
      taskDescription: "",
      isRepeatingWeekly: false,
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      daysOfTheWeek: [],
      view: this.props.viewMode ? this.props.viewMode : "Week"
    };
  }

  async componentDidMount() {
    this.fetchInitialData();
  }

  componentWillReceiveProps(props) {
    if (props.viewMode) this.setState({ view: props.viewMode });
  }

  componentDidUpdate(prevProps) {
    if (this.props.onRefresh !== prevProps.onRefresh) {
      this.fetchInitialData();
    }
  }

  async fetchInitialData() {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/roles`;
    const users = await axios.get(ep);
    if (users.data.success) {
      this.setState({
        users: users.data.data.users.filter(user => !user.isArchived),
        roles: users.data.data.roles
      });
    }

    const scheduleEp = `${process.env.REACT_APP_API}/partners/users/schedule/all`;
    const schedule = await axios.get(scheduleEp);
    if (schedule.data.success) {
      const staffSchedulesArr = schedule.data.data;
      const filteredStaffSchedules = [[], [], [], [], [], [], []];
      // Iterate over each schedule and place it in the corresponding subarray
      staffSchedulesArr.forEach(scheduleItem => {
        const dayOfWeek = new Date(scheduleItem.start_date).getDay();
        filteredStaffSchedules[dayOfWeek].push(scheduleItem);
      });

      // Sort each subarray by the desired property (e.g., start time)
      filteredStaffSchedules.forEach(subarray => {
        subarray.sort((a, b) => {
          // Adjust the property to sort by (e.g., a.startTime or b.date)
          return new Date(a.start_time) - new Date(b.start_time);
        });
      });

      this.setState({
        schedules: filteredStaffSchedules
      });

      this.setGlobal({ loading: false });
    }
  }

  getAllStaff = () => {
    return [
      { value: this.global.dsUser.id, text: this.global.dsUser.name },
      ...this.state.users
        .filter(f => !f.isArchived && f.sub_user)
        .map(({ id, username }) => ({ value: id, text: username }))
    ];
  };

  async submitSchedule() {
    if (this.state.staff === "" && !this.state.selectedStaff) {
      toast.error("Please select a staff member");
      return;
    }

    if (this.state.task === "") {
      toast.error("Please enter the task");
      return;
    }

    if (this.state.startDate === "" && !this.state.selectedDate) {
      toast.error("Please enter the start date");
      return;
    }

    if (this.state.startTime === "") {
      toast.error("Please enter the start time");
      return;
    }

    if (this.state.endTime === "") {
      toast.error("Please enter the end time");
      return;
    }

    const timeStringStart = this.state.startTime; // The time string in "HH:MM" format
    const timeStringEnd = this.state.endTime;

    const date = new Date(
      this.state.startDate !== ""
        ? this.state.startDate
        : this.state.selectedDate
    );
    const dateEnd = new Date(
      this.state.endDate !== "" ? this.state.endDate : this.state.selectedDate
    );

    const [hours, minutes] = timeStringStart.split(":"); // Split the time string into hours and minutes
    const [hoursEnd, minutesEnd] = timeStringEnd.split(":");

    date.setHours(hours);
    date.setMinutes(minutes);
    dateEnd.setHours(hoursEnd);
    dateEnd.setMinutes(minutesEnd);

    if (date.getTime() >= dateEnd.getTime()) {
      toast.error("Start Time must be before end time!");
      return;
    }

    const staffId =
      this.state.staff === ""
        ? this.state.selectedStaff.id
        : parseInt(this.state.staff);
    const ep = `${process.env.REACT_APP_API}/partners/users/schedule/${staffId}`;

    this.setGlobal({ loading: true });

    const results = await axios.post(ep, {
      task_name: this.state.task,
      task_description: this.state.taskDescription,
      isRepeatingWeekly: this.state.isRepeatingWeekly,
      start_date:
        this.state.startDate !== ""
          ? this.state.startDate
          : new Date(this.state.selectedDate).toISOString().split("T")[0],
      end_date: this.state.endDate !== "" ? this.state.endDate : null,
      start_time: date,
      end_time: dateEnd,
      selected_days_of_week: this.state.daysOfTheWeek
    });

    if (results.data.success) {
      toast.success("Schedule successfully created!");
      this.fetchInitialData();
      this.setState({
        task: "Schedule",
        staff: "",
        startTime: "",
        endTime: "",
        startDate: "",
        endDate: "",
        openCreateStaffScheduleModal: false
      });
      this.setGlobal({ loading: false });
    } else {
      toast.error("Error while creating schedule.");
    }
  }

  getSelectedWeek() {
    const today = new Date();
    const currentDayOfWeek = today.getDay();
    const startOfWeek = new Date(today);

    startOfWeek.setDate(today.getDate() - currentDayOfWeek);
    startOfWeek.setDate(startOfWeek.getDate() + this.state.weekAdjustments * 7); // Add weeks to startOfWeek
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week

    const startMonth = startOfWeek.toLocaleString("en-US", { month: "long" });
    const startDate = startOfWeek.getDate();
    const endMonth = endOfWeek.toLocaleString("en-US", { month: "long" });
    const endDate = endOfWeek.getDate();

    const formattedDate = `${startMonth} ${startDate} - ${endMonth} ${endDate}`;

    const weekDates = [];
    const weekDatesFull = [];
    const startDay = new Date(startOfWeek);

    while (startDay <= endOfWeek) {
      const dayOfMonth = startDay.getDate();
      const fullDate = startDay.toLocaleDateString("en-US");
      weekDates.push(dayOfMonth);
      weekDatesFull.push(fullDate);
      startDay.setDate(startDay.getDate() + 1);
    }

    return { formattedDate, weekDates, weekDatesFull };
  }

  getSelectedDay() {
    const today = new Date();
    today.setDate(today.getDate() + this.state.dayAdjustments);

    const options = { month: "long", day: "numeric" };
    const formattedDate = today.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  handleRangeChange = action => {
    switch (action) {
      case "next":
        this.setState({ weekAdjustments: this.state.weekAdjustments + 1 });
        break;
      case "nextDay":
        this.setState({ dayAdjustments: this.state.dayAdjustments + 1 });
        break;
      case "prev":
        this.setState({ weekAdjustments: this.state.weekAdjustments - 1 });
        break;
      case "prevDay":
        this.setState({ dayAdjustments: this.state.dayAdjustments - 1 });
        break;
      case "today":
        this.setState({ weekAdjustments: 0, dayAdjustments: 0 });
        break;
      default:
        break;
    }
  };

  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      if (this.global.dsUser.accesspoints.Upcoming) {
        return !this.global.dsUser.accesspoints.Upcoming.Calendar.edit;
      } else {
        return false;
      }
    }
  };

  isSameDay(date1, date2) {
    const scheduleDate = new Date(date1); // Replace with your current date value
    const dateString = date2; // Replace with your date in the format MM/DD/YYYY

    // Parse the date string into separate components (month, day, year)
    const [month, day, year] = dateString.split("/");

    // Create a new Date object with the parsed components
    const targetDate = new Date(year, month - 1, day);

    // Compare the year, month, and day of the two dates
    const isSameDay =
      scheduleDate.getFullYear() === targetDate.getFullYear() &&
      scheduleDate.getMonth() === targetDate.getMonth() &&
      scheduleDate.getDate() === targetDate.getDate();

    return isSameDay;
  }

  isSameHour(day, scheduleDate, scheduleDateEnd, hour) {
    const schedule = new Date(scheduleDate);
    const scheduleEnd = new Date(scheduleDateEnd);
    const selectedDay = new Date(day);
    const nextDay = new Date(selectedDay);
    nextDay.setDate(selectedDay.getDate() + 1);

    if (nextDay.getDate() === 1) {
      nextDay.setMonth(selectedDay.getMonth() + 1);

      if (nextDay.getMonth() === 0) {
        nextDay.setFullYear(selectedDay.getFullYear() + 1);
      }
    }

    if (schedule >= selectedDay && schedule < nextDay) {
      const scheduleHour = schedule.getHours();
      const scheduleHourEnd = scheduleEnd.getHours();

      if (scheduleHourEnd >= hour && scheduleHour < hour + 1) {
        return true;
      }
    }

    return false;
  }

  getStaffWeekHours(staff, startOfWeek, endOfWeek) {
    let totalHours = 0;
    this.state.schedules.forEach(days => {
      days.forEach(schedule => {
        if (!schedule.isArchived && staff.id === schedule.dsUserId) {
          const startDate = new Date(schedule.start_time);
          const endDate = new Date(schedule.end_time);

          const startWeek = new Date(startOfWeek);
          const endWeek = new Date(endOfWeek);

          endWeek.setDate(endWeek.getDate() + 1);

          if (startDate >= startWeek && endDate <= endWeek) {
            const startTimestamp = startDate.getTime();
            const endTimestamp = endDate.getTime();

            // Calculate the difference in milliseconds
            const timeDifference = Math.abs(endTimestamp - startTimestamp);

            // Convert milliseconds to hours
            totalHours += timeDifference / (1000 * 60 * 60);
          }
        }
      });
    });

    totalHours = totalHours.toFixed(2);

    return totalHours;
  }

  getStaffDayHours(staff, day) {
    let totalHours = 0;
    this.state.schedules.forEach(days => {
      days.forEach(schedule => {
        if (!schedule.isArchived && staff.id === schedule.dsUserId) {
          const startTime = new Date(schedule.start_time);
          const endTime = new Date(schedule.end_time);

          const today = new Date(day);
          const currentYear = new Date().getFullYear();
          today.setFullYear(currentYear);

          const nextDay = new Date(today);
          nextDay.setDate(today.getDate() + 1);

          // Check if the next day crosses into the next month
          if (nextDay.getDate() === 1) {
            nextDay.setMonth(today.getMonth() + 1);

            // Check if the next month crosses into the next year
            if (nextDay.getMonth() === 0) {
              nextDay.setFullYear(currentYear + 1);
            }
          }

          if (
            today <= new Date(schedule.start_time) &&
            nextDay >= new Date(schedule.start_time)
          ) {
            const startTimestamp = startTime.getTime();
            const endTimestamp = endTime.getTime();

            // Calculate the difference in milliseconds
            const timeDifference = Math.abs(endTimestamp - startTimestamp);

            // Convert milliseconds to hours
            totalHours += timeDifference / (1000 * 60 * 60);
          }
        }
      });
    });

    totalHours = totalHours.toFixed(2);

    return totalHours;
  }

  render() {
    const { maxWidth } = this.props;
    const dates = this.getSelectedWeek().weekDates;
    const datesFull = this.getSelectedWeek().weekDatesFull;
    const selectedWeek = this.getSelectedWeek().formattedDate;
    const selectedDay = this.getSelectedDay();

    const selectedDate = this.state.selectedDate ? this.state.selectedDate : "";
    const parts = selectedDate.split("/");
    const formattedStartDate =
      parts.length === 3
        ? `${parts[2]}-${parts[0].padStart(2, "0")}-${parts[1].padStart(
            2,
            "0"
          )}`
        : "";

    return (
      <>
        <div
          className={(maxWidth ? "max-width " : "") + "boxofcalendar"}
          style={{ padding: "40px 20px" }}
        >
          {this.state.openCreateStaffScheduleModal &&
            this.props.view !== "Profile View" && (
              <div>
                <Modal
                  size="lg"
                  isOpen={this.state.openCreateStaffScheduleModal}
                  toggle={() =>
                    this.setState({
                      openCreateStaffScheduleModal:
                        !this.state.openCreateStaffScheduleModal
                    })
                  }
                  contentClassName="custom-modal-content"
                >
                  <div
                    className="modal-content"
                    style={{ borderRadius: "30px 30px 0px 0px" }}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title staff-modal-title">
                        Create Staff Schedule
                      </h5>
                      <button
                        className="close"
                        style={{ paddingTop: "16px" }}
                        onClick={() =>
                          this.setState({ openCreateStaffScheduleModal: false })
                        }
                      >
                        <span>&times;</span>
                      </button>
                    </div>
                  </div>
                  <ModalBody>
                    <form>
                      <Row>
                        <Col lg={12}>
                          <label htmlFor="staff">Staff</label>
                          <select
                            id="myDropdown"
                            defaultValue={
                              this.state.selectedStaff
                                ? this.state.selectedStaff.id
                                : ""
                            }
                            onChange={e =>
                              this.setState({ staff: e.target.value })
                            }
                          >
                            <option value="" key="empty">
                              Please select
                            </option>
                            {this.getAllStaff().map(option => (
                              <option value={option.value} key={option.value}>
                                {option.text}
                              </option>
                            ))}
                          </select>
                          <div>
                            <label htmlFor="task">Task</label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.task}
                              style={{ padding: "5px" }}
                              onChange={e =>
                                this.setState({ task: e.target.value })
                              }
                            />
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>
                              <label htmlFor="start-date">Start Date</label>
                              <input
                                type="date"
                                id="start-date"
                                style={{ padding: "5px", background: "none" }}
                                defaultValue={
                                  this.state.selectedDate
                                    ? formattedStartDate
                                    : null
                                }
                                onChange={e =>
                                  this.setState({ startDate: e.target.value })
                                }
                              />
                            </div>
                            {this.state.isRepeatingWeekly && (
                              <div style={{ marginLeft: "auto" }}>
                                <label htmlFor="end-date">End Date</label>
                                <input
                                  type="date"
                                  id="end-date"
                                  style={{ padding: "5px", background: "none" }}
                                  onChange={e =>
                                    this.setState({ endDate: e.target.value })
                                  }
                                />
                              </div>
                            )}
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>
                              <label htmlFor="start-time">Start Time</label>
                              <input
                                type="time"
                                id="start-time"
                                onChange={e =>
                                  this.setState({ startTime: e.target.value })
                                }
                              />
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                              <label htmlFor="end-time">End Time</label>
                              <input
                                type="time"
                                id="end-time"
                                onChange={e =>
                                  this.setState({ endTime: e.target.value })
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </form>
                    <div style={{ textAlign: "center" }}>
                      <button
                        className="staff-schedule-save-btn"
                        onClick={() => this.submitSchedule()}
                      >
                        Save
                      </button>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            )}
          {/* <CreateStaffScheduleModal
              onClose={() =>
                this.setState({ openCreateStaffScheduleModal: false })
              }
              onRefresh={() => this.fetchInitialData()}
              selectedStaff={this.state.selectedStaff}
              selectedDate={this.state.selectedDate}
            /> */}
          {this.state.openStaffScheduleInfoModal &&
            this.props.view !== "Profile View" && (
              <StaffScheduleInfoModal
                onClose={() =>
                  this.setState({ openStaffScheduleInfoModal: false })
                }
                onEdit={() =>
                  this.setState({
                    openEditStaffScheduleModal: true,
                    openStaffScheduleInfoModal: false
                  })
                }
                onDelete={() =>
                  this.setState({
                    openDeleteStaffScheduleModal: true,
                    openStaffScheduleInfoModal: false
                  })
                }
                selectedStaff={this.state.selectedStaff}
                selectedSchedule={this.state.selectedSchedule}
              />
            )}
          {this.state.openStaffScheduleCheckInModal &&
            this.props.view === "Profile View" && (
              <StaffScheduleCheckInModal
                onClose={() =>
                  this.setState({ openStaffScheduleCheckInModal: false })
                }
                onCheckIn={() =>
                  this.setState({
                    openStaffScheduleCheckInConfirmationModal: true,
                    openStaffScheduleCheckInModal: false
                  })
                }
                onCheckOut={() =>
                  this.setState({
                    openStaffScheduleCheckOutConfirmationModal: true,
                    openStaffScheduleCheckInModal: false
                  })
                }
                selectedStaff={this.state.selectedStaff}
                selectedSchedule={this.state.selectedSchedule}
              />
            )}
          {this.state.openStaffScheduleCheckInConfirmationModal && (
            <StaffScheduleCheckInConfirmationModal
              onClose={() =>
                this.setState({
                  openStaffScheduleCheckInConfirmationModal: false
                })
              }
              onRefresh={() => {
                this.fetchInitialData();
                this.props.refresh();
              }}
              selectedStaff={this.state.selectedStaff}
              selectedSchedule={this.state.selectedSchedule}
            />
          )}
          {this.state.openStaffScheduleCheckOutConfirmationModal && (
            <StaffScheduleCheckOutConfirmationModal
              onClose={() =>
                this.setState({
                  openStaffScheduleCheckOutConfirmationModal: false
                })
              }
              onRefresh={() => {
                this.fetchInitialData();
                this.props.refresh();
              }}
              selectedStaff={this.state.selectedStaff}
              selectedSchedule={this.state.selectedSchedule}
            />
          )}
          {this.state.openEditStaffScheduleModal && (
            <EditStaffScheduleModal
              onClose={() =>
                this.setState({ openEditStaffScheduleModal: false })
              }
              onRefresh={() => this.fetchInitialData()}
              isModalOpen={this.state.openEditStaffScheduleModal}
              closeModal={() =>
                this.setState({ openEditStaffScheduleModal: false })
              }
              selectedStaff={this.state.selectedStaff}
              selectedSchedule={this.state.selectedSchedule}
              selectedDate={this.state.selectedDate}
            />
          )}
          {this.state.openDeleteStaffScheduleModal && (
            <DeleteStaffScheduleModal
              onClose={() =>
                this.setState({ openDeleteStaffScheduleModal: false })
              }
              onRefresh={() => this.fetchInitialData()}
              selectedSchedule={this.state.selectedSchedule}
            />
          )}
          <div
            className={`cal-menu d-flex team-cal`}
            style={{
              position: "relative",
              marginBottom: "0px",
              height: "60px",
              backgroundColor: "#FFF",
              width: this.state.view === "Day" ? "2600px" : undefined
            }}
          >
            <button
              type="button"
              className="btn btn-sm move-day"
              data-action="move-prev"
              onClick={() =>
                this.state.view === "Day"
                  ? this.handleRangeChange("prevDay")
                  : this.handleRangeChange("prev")
              }
              style={{ width: "5%", marginRight: "5px", zIndex: 1 }}
            >
              <i className="fas fa-arrow-left"></i>
            </button>
            <div className="team-calendar-date-selector">
              <div style={{ width: "250px", textAlign: "center" }}>
                {this.state.view === "Day" ? selectedDay : selectedWeek}
              </div>
            </div>
            <button
              type="button"
              className="btn btn-sm move-day"
              data-action="move-next"
              onClick={() =>
                this.state.view === "Day"
                  ? this.handleRangeChange("nextDay")
                  : this.handleRangeChange("next")
              }
              style={{
                width: "5%",
                marginRight: "10px",
                marginLeft: "5px",
                zIndex: 1
              }}
            >
              <i className="fas fa-arrow-right"></i>
            </button>
          </div>
          <div>
            <table className="team-calendar">
              <thead>
                <tr className="team-calendar-header">
                  {this.state.view === "Week" ? (
                    <>
                      <th
                        colspan="2"
                        className="team-calendar-header-cell"
                      ></th>
                      <th className="team-calendar-header-cell">
                        <div className="team-calendar-header-date">
                          {dates[0]}
                        </div>
                        <div className="team-calendar-header-day">Sunday</div>
                      </th>
                      <th className="team-calendar-header-cell">
                        <div className="team-calendar-header-date">
                          {dates[1]}
                        </div>
                        <div className="team-calendar-header-day">Monday</div>
                      </th>
                      <th className="team-calendar-header-cell">
                        <div className="team-calendar-header-date">
                          {dates[2]}
                        </div>
                        <div className="team-calendar-header-day">Tuesday</div>
                      </th>
                      <th className="team-calendar-header-cell">
                        <div className="team-calendar-header-date">
                          {dates[3]}
                        </div>
                        <div className="team-calendar-header-day">
                          Wednesday
                        </div>
                      </th>
                      <th className="team-calendar-header-cell">
                        <div className="team-calendar-header-date">
                          {dates[4]}
                        </div>
                        <div className="team-calendar-header-day">Thursday</div>
                      </th>
                      <th className="team-calendar-header-cell">
                        <div className="team-calendar-header-date">
                          {dates[5]}
                        </div>
                        <div className="team-calendar-header-day">Friday</div>
                      </th>
                      <th className="team-calendar-header-cell">
                        <div className="team-calendar-header-date">
                          {dates[6]}
                        </div>
                        <div className="team-calendar-header-day">Saturday</div>
                      </th>
                    </>
                  ) : (
                    <>
                      <th
                        colSpan="2"
                        className="team-calendar-header-cell-day-header"
                      ></th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          12 AM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          1 AM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          2 AM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          3 AM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          4 AM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          5 AM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          6 AM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          7 AM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          8 AM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          9 AM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          10 AM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          11 AM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          12 PM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          1 PM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          2 PM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          3 PM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          4 PM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          5 PM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          6 PM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          7 PM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          8 PM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          9 PM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          10 PM
                        </div>
                      </th>
                      <th className="team-calendar-header-cell-day">
                        <div className="team-calendar-header-date-day">
                          11 PM
                        </div>
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {this.props.view === "Profile View" ? (
                    <th
                      colspan={this.state.view === "Day" ? "26" : "9"}
                      className="team-calendar-header-section"
                    >
                      MY SCHEDULE
                    </th>
                  ) : (
                    <th
                      colspan={this.state.view === "Day" ? "26" : "9"}
                      className="team-calendar-header-section"
                    >
                      TEAM MEMBER
                    </th>
                  )}
                </tr>
                {this.state.users.map(staff => {
                  if (
                    this.props.view === "Profile View" &&
                    staff.id !== parseInt(this.props.match.params.id)
                  ) {
                    return;
                  }

                  const staffName = staff.username
                    ? staff.username
                    : this.global.dsUser.name;
                  const staffRoleArr = this.state.roles.filter(
                    role => role.id === staff.role
                  )[0];
                  const staffRole = staffRoleArr
                    ? staffRoleArr.role
                    : "Organizer";

                  const currentYear = new Date().getFullYear();

                  const today = new Date(selectedDay);
                  today.setFullYear(currentYear);

                  const month = today.getMonth() + 1; // Adding 1 because getMonth() returns zero-based months
                  const day = today.getDate();
                  const year = today.getFullYear();

                  const formattedDay = `${month}/${day}/${year}`;

                  const allSchedules = [...this.state.schedules.flat()];

                  if (this.state.view === "Week") {
                    return (
                      <tr>
                        <td colspan="2" className="team-calendar-staff-cell">
                          <div className="team-calendar-staff-col">
                            <Avatar
                              src={
                                this.state.avatarMap[staff.avatar] || Megaphone
                              }
                              styles={{ width: "50px", height: "50px" }}
                            />
                            <div className="team-calendar-staff-col-info">
                              <div className="team-calendar-staff-col-info-name">
                                {staffName}
                              </div>
                              <div className="team-calendar-staff-col-info-role">
                                {staffRole}
                              </div>
                            </div>
                            <div className="team-calendar-staff-hours">
                              {this.getStaffWeekHours(
                                staff,
                                datesFull[0],
                                datesFull[6]
                              )}{" "}
                              Hours
                            </div>
                          </div>
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: datesFull[0]
                            })
                          }
                        >
                          {this.state.schedules[0].map(schedule => {
                            const startTime = new Date(schedule.start_time);
                            const endTime = new Date(schedule.end_time);
                            const formattedTime = `${startTime.toLocaleTimeString(
                              [],
                              { hour: "numeric", minute: "2-digit" }
                            )} - ${endTime.toLocaleTimeString([], {
                              hour: "numeric",
                              minute: "2-digit"
                            })}`;

                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameDay(schedule.start_date, datesFull[0])
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                    <div style={{ fontSize: "10px" }}>
                                      {formattedTime}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: datesFull[1]
                            })
                          }
                        >
                          {this.state.schedules[1].map(schedule => {
                            console.log("SCHEDULE", schedule);
                            const startTime = new Date(schedule.start_time);
                            const endTime = new Date(schedule.end_time);
                            const formattedTime = `${startTime.toLocaleTimeString(
                              [],
                              { hour: "numeric", minute: "2-digit" }
                            )} - ${endTime.toLocaleTimeString([], {
                              hour: "numeric",
                              minute: "2-digit"
                            })}`;

                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameDay(schedule.start_date, datesFull[1])
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                    <div style={{ fontSize: "10px" }}>
                                      {formattedTime}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: datesFull[2]
                            })
                          }
                        >
                          {this.state.schedules[2].map(schedule => {
                            console.log("SCHEDULE", schedule);
                            const startTime = new Date(schedule.start_time);
                            const endTime = new Date(schedule.end_time);
                            const formattedTime = `${startTime.toLocaleTimeString(
                              [],
                              { hour: "numeric", minute: "2-digit" }
                            )} - ${endTime.toLocaleTimeString([], {
                              hour: "numeric",
                              minute: "2-digit"
                            })}`;

                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameDay(schedule.start_date, datesFull[2])
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                    <div style={{ fontSize: "10px" }}>
                                      {formattedTime}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: datesFull[3]
                            })
                          }
                        >
                          {this.state.schedules[3].map(schedule => {
                            console.log("SCHEDULE", schedule);
                            const startTime = new Date(schedule.start_time);
                            const endTime = new Date(schedule.end_time);
                            const formattedTime = `${startTime.toLocaleTimeString(
                              [],
                              { hour: "numeric", minute: "2-digit" }
                            )} - ${endTime.toLocaleTimeString([], {
                              hour: "numeric",
                              minute: "2-digit"
                            })}`;

                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameDay(schedule.start_date, datesFull[3])
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                    <div style={{ fontSize: "10px" }}>
                                      {formattedTime}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: datesFull[4]
                            })
                          }
                        >
                          {this.state.schedules[4].map(schedule => {
                            console.log("SCHEDULE", schedule);
                            const startTime = new Date(schedule.start_time);
                            const endTime = new Date(schedule.end_time);
                            const formattedTime = `${startTime.toLocaleTimeString(
                              [],
                              { hour: "numeric", minute: "2-digit" }
                            )} - ${endTime.toLocaleTimeString([], {
                              hour: "numeric",
                              minute: "2-digit"
                            })}`;

                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameDay(schedule.start_date, datesFull[4])
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                    <div style={{ fontSize: "10px" }}>
                                      {formattedTime}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: datesFull[5]
                            })
                          }
                        >
                          {this.state.schedules[5].map(schedule => {
                            console.log("SCHEDULE", schedule);
                            const startTime = new Date(schedule.start_time);
                            const endTime = new Date(schedule.end_time);
                            const formattedTime = `${startTime.toLocaleTimeString(
                              [],
                              { hour: "numeric", minute: "2-digit" }
                            )} - ${endTime.toLocaleTimeString([], {
                              hour: "numeric",
                              minute: "2-digit"
                            })}`;

                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameDay(schedule.start_date, datesFull[5])
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                    <div style={{ fontSize: "10px" }}>
                                      {formattedTime}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: datesFull[6]
                            })
                          }
                        >
                          {this.state.schedules[6].map(schedule => {
                            console.log("SCHEDULE", schedule);
                            const startTime = new Date(schedule.start_time);
                            const endTime = new Date(schedule.end_time);
                            const formattedTime = `${startTime.toLocaleTimeString(
                              [],
                              { hour: "numeric", minute: "2-digit" }
                            )} - ${endTime.toLocaleTimeString([], {
                              hour: "numeric",
                              minute: "2-digit"
                            })}`;

                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameDay(schedule.start_date, datesFull[6])
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                    <div style={{ fontSize: "10px" }}>
                                      {formattedTime}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <td colspan="2" className="team-calendar-staff-cell">
                          <div className="team-calendar-staff-col">
                            <Avatar
                              src={
                                this.state.avatarMap[staff.avatar] || Megaphone
                              }
                              styles={{ width: "50px", height: "50px" }}
                            />
                            <div className="team-calendar-staff-col-info">
                              <div className="team-calendar-staff-col-info-name">
                                {staffName}
                              </div>
                              <div className="team-calendar-staff-col-info-role">
                                {staffRole}
                              </div>
                            </div>
                            <div className="team-calendar-staff-hours">
                              {this.getStaffDayHours(staff, selectedDay)} Hours
                            </div>
                          </div>
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() => {
                            return this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            });
                          }}
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                0
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                1
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                2
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                3
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                4
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                5
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                6
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                7
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                8
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                9
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                10
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                11
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                12
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                13
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                14
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                15
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                16
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                17
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                18
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                19
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                20
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                21
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                22
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td
                          className="team-calendar-cell"
                          onClick={() =>
                            this.setState({
                              openCreateStaffScheduleModal: true,
                              selectedStaff: staff,
                              selectedDate: formattedDay
                            })
                          }
                        >
                          {allSchedules.map(schedule => {
                            if (
                              !schedule.isArchived &&
                              staff.id === schedule.dsUserId &&
                              this.isSameHour(
                                formattedDay,
                                schedule.start_time,
                                schedule.end_time,
                                23
                              )
                            ) {
                              return (
                                <div className="centered-content">
                                  <div
                                    style={{
                                      backgroundColor: "lightgrey",
                                      padding: "16px",
                                      zIndex: "1000"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({
                                        openStaffScheduleInfoModal: true,
                                        openStaffScheduleCheckInModal: true,
                                        selectedStaff: staff,
                                        selectedSchedule: schedule
                                      });
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {schedule.task_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(TeamCalendar);
