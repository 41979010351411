import React from "reactn";
import { formTypes, BoxForm } from "../../../../components/UI/form";
import Geosuggest from "react-geosuggest";
// import ConfirmTimeChangeForm from "./confirmTimeChangeForm";

class ProgramLocationHours extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hoursOfOperationStart: null,
      hoursOfOperationEnd: null,
      timeChangeShown: false,
      change: null,
      newTimes: null,
      excludedDays: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.customForm = this.customForm.bind(this);
    this.setTime = this.setTime.bind(this);
    this._geoSuggest = null;
  }

  handleChange = (e, v) => {
    if (e === "hoursOfOperationStart" || e === "hoursOfOperationEnd") {
      // if (
      //   (e === "hoursOfOperationStart" &&
      //     this.state.hoursOfOperationStart !== parseFloat(v) &&
      //     this.state.hoursOfOperationStart !== -1) ||
      //   (e === "hoursOfOperationEnd" &&
      //     this.state.hoursOfOperationEnd !== parseFloat(v) &&
      //     (this.state.hoursOfOperationEnd !== -1 ||
      //       this.state.hoursOfOperationEnd !== "-1"))
      // ) {
      //   if (this.state[e] === null) {
      //     this.setGlobal({
      //       myProgramLocationHours: {
      //         ...this.global.myProgramLocationHours,
      //         [e]: parseFloat(v)
      //       }
      //     });
      //   } else {
      //     this.setState({
      //       newTimes: {
      //         [e]: parseFloat(v)
      //       },
      //       change: e,
      //       timeChangeShown: true
      //     });
      //   }
      // } else {
      this.setGlobal({
        myProgramLocationHours: {
          ...this.global.myProgramLocationHours,
          [e]: parseFloat(v)
        }
      });
      // }
    } else {
      this.setGlobal({
        myProgramLocationHours: {
          ...this.global.myProgramLocationHours,
          [e]: v
        }
      });
    }
    if (e === "daysOfWeek") {
    }
  };

  componentDidMount() {
    this.setState({
      hoursOfOperationEnd:
        this.global.myProgramLocationHours.hoursOfOperationEnd,
      hoursOfOperationStart:
        this.global.myProgramLocationHours.hoursOfOperationStart
    });
  }

  setTime = change => {
    if (change) {
      this.setGlobal({
        myProgramLocationHours: {
          ...this.global.myProgramLocationHours,
          [this.state.change]: this.state.newTimes[this.state.change]
        }
      });
      this.setState({
        timeChangeShown: false,
        change: null
      });
    } else {
      this.setState({
        timeChangeShown: false,
        change: null
      });
    }
  };

  getStartTime = () => {
    const vals = this.global.myProgramLocationHours;
    const index = formTypes.SelectOptions.timeRanges.findIndex(
      o => o.value === parseFloat(vals.hoursOfOperationEnd)
    );
    return index === 0
      ? formTypes.SelectOptions.timeRanges
      : formTypes.SelectOptions.timeRanges.slice(0, index);
  };

  getEndTime = () => {
    const vals = this.global.myProgramLocationHours;
    const index = formTypes.SelectOptions.timeRanges.findIndex(
      o => o.value === parseFloat(vals.hoursOfOperationStart)
    );
    return index === 0
      ? formTypes.SelectOptions.timeRanges
      : [
          formTypes.SelectOptions.timeRanges[0],
          ...formTypes.SelectOptions.timeRanges.slice(index)
        ];
  };

  setAddress = e => {
    if (e && e.gmaps) {
      const addLine1 = e.gmaps.name;
      let city, pc, country, province;
      try {
        city = e.gmaps.address_components.find(
          c => c.types.includes("locality") && c.types.includes("political")
        ).long_name;
      } catch (ex) {}
      try {
        pc = e.gmaps.address_components.find(c =>
          c.types.includes("postal_code")
        ).long_name;
      } catch (ex) {}
      try {
        country = e.gmaps.address_components.find(
          c => c.types.includes("country") && c.types.includes("political")
        ).long_name;
      } catch (ex) {}
      try {
        province = e.gmaps.address_components.find(
          c =>
            c.types.includes("administrative_area_level_1") &&
            c.types.includes("political")
        ).long_name;
      } catch (ex) {}
      this.setGlobal({
        myProgramLocationHours: {
          ...this.global.myProgramLocationHours,
          address: addLine1 ? addLine1 : "",
          city: city ? city : "",
          postalCode: pc ? pc : "",
          country: country ? country : "",
          province: province ? province : "",
          lat: e.location.lat,
          lng: e.location.lng
        }
      });
      this._geoSuggest.update(addLine1);
    }
  };

  customForm = () => {
    const vals = this.global.myProgramLocationHours;

    return (
      <div className="formbody">
        {/* {!this.state.timeChangeShown ? null : (
          <ConfirmTimeChangeForm onClose={this.setTime} />
        )} */}
        <form>
          <div className="row">
            <div className="col-5">
              <h3>
                Address<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`w-100 ${
                  !vals.address || !vals.address.length
                    ? "formbody-invalid"
                    : ""
                }`}
              >
                <Geosuggest
                  onSuggestSelect={this.setAddress}
                  initialValue={vals.address}
                  placeholder="Search for Address"
                  ref={el => (this._geoSuggest = el)}
                  location={
                    new window.google.maps.LatLng(43.6499722, -79.3997312)
                  }
                  radius={30}
                  // location={new google.maps.LatLng(53.558572, 9.9278215)}
                  // radius="20"
                  disabled={
                    this.global.profile.programSubmitted &&
                    this.global.dsUser.accountType !== "Admin"
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>Address Line Two</h3>
            </div>
            <div className="col-7 d-flex">
              <input
                name="address2"
                value={vals.address2 ? vals.address2 : ""}
                placeholder="Unit #, Buzzer #"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>Closest Intersection</h3>
            </div>
            <div className="col-7 d-flex">
              <input
                name="majorIntersection"
                value={vals.majorIntersection}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                readOnly={
                  !this.global.profile.programSubmitted ||
                  this.global.dsUser.accountType === "Admin"
                    ? false
                    : true
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                City<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`w-100 ${
                  !vals.city || !vals.city.length ? "formbody-invalid" : ""
                }`}
              >
                <input
                  name="city"
                  value={vals.city}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  readOnly={
                    !this.global.profile.programSubmitted ||
                    this.global.dsUser.accountType === "Admin"
                      ? false
                      : true
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                Province<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`w-100 ${
                  !vals.province || !vals.province.length
                    ? "formbody-invalid"
                    : ""
                }`}
              >
                <input
                  name="province"
                  value={vals.province}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  readOnly={
                    !this.global.profile.programSubmitted ||
                    this.global.dsUser.accountType === "Admin"
                      ? false
                      : true
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                Country<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`w-100 ${
                  !vals.country || !vals.country.length
                    ? "formbody-invalid"
                    : ""
                }`}
              >
                <input
                  name="country"
                  value={vals.country}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  readOnly={
                    !this.global.profile.programSubmitted ||
                    this.global.dsUser.accountType === "Admin"
                      ? false
                      : true
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                Postal Code<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`w-100 ${
                  !vals.postalCode || !vals.postalCode.length
                    ? "formbody-invalid"
                    : ""
                }`}
              >
                <input
                  name="postalCode"
                  value={vals.postalCode}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  readOnly={
                    !this.global.profile.programSubmitted ||
                    this.global.dsUser.accountType === "Admin"
                      ? false
                      : true
                  }
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-5">
              <h3>
                Hours of Operation<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`w-100 ${
                  !vals.hoursOfOperationStart ||
                  vals.hoursOfOperationStart === -1
                    ? "formbody-invalid"
                    : ""
                }`}
              >
                <formTypes.Select
                  name="hoursOfOperationStart"
                  value={vals.hoursOfOperationStart}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  options={this.getStartTime()}
                  className="w-100"
                  isDisabled={
                    this.global.profile.programSubmitted &&
                    this.global.dsUser.accountType !== "Admin"
                  }
                />
              </div>
              <h3 className="mx-2">To</h3>
              <div
                className={`w-100 ${
                  !vals.hoursOfOperationEnd || vals.hoursOfOperationEnd === -1
                    ? "formbody-invalid"
                    : ""
                }`}
              >
                <formTypes.Select
                  name="hoursOfOperationEnd"
                  value={vals.hoursOfOperationEnd}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  options={this.getEndTime()}
                  className="w-100"
                  isDisabled={
                    this.global.profile.programSubmitted &&
                    this.global.dsUser.accountType !== "Admin"
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                Days of Week<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`w-100 ${
                  !vals.daysOfWeek || !vals.daysOfWeek.length
                    ? "formbody-invalid"
                    : ""
                }`}
              >
                <formTypes.Select
                  name="daysOfWeek"
                  value={vals.daysOfWeek}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  options={formTypes.SelectOptions.daysOfWeekRanges}
                  className="w-100"
                  isDisabled={
                    this.global.profile.programSubmitted &&
                    this.global.dsUser.accountType !== "Admin"
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>Excluded Days</h3>
            </div>
            <div className="col-7 d-flex">
              <formTypes.DaysDropdownSelect
                name="excludedDays"
                value={vals.excludedDays}
                onChange={this.handleChange}
                range={vals.daysOfWeek}
                isDisabled={
                  this.global.profile.programSubmitted &&
                  this.global.dsUser.accountType !== "Admin"
                }
              />
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"LOCATION & HOURS"}
        customForm={this.customForm()}
      />
    );
  }
}

export default ProgramLocationHours;
