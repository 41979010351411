import React, { useState, useEffect, useGlobal, setGlobal } from "reactn";
import PropTypes from "prop-types";
import "../../assets/css/componentSpecificCss/customizationIntegrations.css";
import {
  MailChimpLogo,
  ZoomLogo,
  GmailLogo,
  Theme1,
  Theme2,
  Theme3,
  Theme4,
  Theme5,
  Theme6,
  Theme7
} from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";
import { Button, Switch } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import "../../assets/css/promo.css";
import { withStyles } from "@material-ui/core/styles";
import ImageCropModal from "../../components/modals/ImageCropModal";
import ObservationCustomization from "../../components/modals/ObservationCustomizations"
import Axios from "axios";

const styles = {
  uploadButton: {
    "& .MuiButton-root": {
      fontSize: "11px !important"
    },
    "& .MuiButton-label": {
      fontSize: "11px !important"
    }
  }
};

class Customization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IntegrationsData: [
        {
          id: "1",
          title: "Zoom",
          description: "Sync Dreamschools with your Zoom",
          buttonName: "Connect Zoom",
          imageSource: ZoomLogo,
          redirect_url: `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_ID}&redirect_uri=${process.env.REACT_APP_ZOOM_REDIRECT_URL}`,
          linked: (obj, glbl) =>
            obj.zoom || glbl.organizationInfo.zoom_refresh_token
        },
        {
          title: "Mailchimp",
          permissions: {},
          description: "Sync Dreamschools with your Mailchimp account",
          buttonName: "Connect Mailchimp",
          imageSource: MailChimpLogo,
          linked: obj => obj.mail
        }
      ],
      zoom: false,
      logoAndDashboardTitlePermissions: {},
      themePermissions: {},
      type: "",
      openImageCropModal: false,
      image: "",
      marketplaceImage: "",
      google_mail: ""
    };

    this.banners = [
      "partners/Banners/Banner1.jpg",
      "partners/Banners/Banner2.png",
      "partners/Banners/Banner3.jpg",
      "partners/Banners/Banner4.jpg",
      "partners/Banners/Banner5.jpg",
      "partners/Banners/Banner6.png",
      "partners/Banners/Banner7.png",
      "partners/Banners/Banner8.jpg"
    ];
    this.theme = [Theme5, Theme2, Theme3, Theme1, Theme4, Theme6, Theme7];
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Customization", to: "/customization" }
      ]
    });
  }
  async componentDidMount() {
    this.saveGmailInfo();
    if (this.state.isNotificationEnabled) {
      console.log(
        "this.state.isNotificationEnabled",
        this.state.isNotificationEnabled
      );
    }

    const code = this.props.history.location.query.code;
    if (code) {
      const ep = `${process.env.REACT_APP_API}/partners/zoom/auth`;
      const results = await axios.post(ep, { code });
      if (results.data.success) {
        this.setState({ zoom: true });
        this.props.history.push("/partners/customization");
        window.location.reload();
      } else {
        // toast.error(results.data.message)
        this.props.history.push("/partners/customization");
      }
    } else {
    }

    // get permissions for logo + dashboard
    await this.getLogoAndDashboardPermissions();
    await this.getThemePermissions();
    await this.getMailChimpPermissions();
    await this.getZoomPermissions();
  }

  SendNotification = async () => {
    console.log(
      "this.state.isNotificationEnabled",
      this.state.isNotificationEnabled
    );

    /*     const ep = `${process.env.REACT_APP_API}/partners/resources/delete-module/${this.state.idToEdit}`;
    const results = await axios.post(ep, { toArchive: this.state.toArchive });

    if (results.data.success) {
      this.fetchInitialData();
    } */
  };

  saveGmailInfo = async () => {
    const params = this.props.location.query;
    if (params && params.code && params.use === "gmail") {
      const ep = `${process.env.REACT_APP_API}/partners/gmail/processToken`;
      const res = await Axios.post(ep, { code: params.code });

      if (res.data.success) {
        params.use === "gmail" &&
          this.setGlobal({
            organizationInfo: {
              ...this.global.organizationInfo,
              google_token: res.data.data.token
              // google_mail: res.data.data.email
            }
          });
        this.dispatch.saveOrganizationalProfile();
        // The below line is used to remove the code param from the URL to ensure a duplicate request is not made to stripe that invalidates the account
        // this.props.history.replace({}, "", this.props.location.search.replace(/[\?&]my_parameter=[^&]+/, '').replace(/^&/, '?'));
      } else {
      }
    }
  };

  getMailChimpPermissions = async () => {
    const featureName = "connect-mailchimp";
    const ep = new URL(
      `${process.env.REACT_APP_API}/partner/permissions/feature/${featureName}`
    );
    const resp = await axios.get(ep);
    if (resp.data && resp.data.success) {
      this.setState({
        IntegrationsData: this.state.IntegrationsData.map(feature => {
          if (feature.title === "Mailchimp") {
            const newFeature = {
              ...feature,
              id: resp.data.data.id,
              permissions: resp.data.data
            };
            return newFeature;
          } else {
            return feature;
          }
        })
      });
    }
  };

  getZoomPermissions = async () => {
    const featureName = "zoom";
    const ep = new URL(
      `${process.env.REACT_APP_API}/partner/permissions/feature/${featureName}`
    );
    const resp = await axios.get(ep);
    if (resp.data && resp.data.success) {
      this.setState({
        IntegrationsData: this.state.IntegrationsData.map(feature => {
          if (feature.title === "Zoom") {
            const newFeature = {
              ...feature,
              id: resp.data.data.id,
              permissions: resp.data.data
            };
            return newFeature;
          } else {
            return feature;
          }
        })
      });
    }
  };

  getLogoAndDashboardPermissions = async () => {
    const featureName = "dashboard-logo-title";
    const ep = new URL(
      `${process.env.REACT_APP_API}/partner/permissions/feature/${featureName}`
    );
    const resp = await axios.get(ep);
    if (resp.data && resp.data.success) {
      this.setState({ logoAndDashboardTitlePermissions: resp.data.data });
    }
  };

  getThemePermissions = async () => {
    const featureName = "theme";
    const ep = new URL(
      `${process.env.REACT_APP_API}/partner/permissions/feature/${featureName}`
    );
    const resp = await axios.get(ep);
    if (resp.data && resp.data.success) {
      this.setState({ themePermissions: resp.data.data });
    }
  };

  uploadNew() {
    const uploadEle = document.getElementById("uploadPhoto");
    uploadEle.click();
  }

  fileChange(e) {
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      // const fd = new FormData();
      // fd.set("type", this.state.type);
      // fd.set("image", e.target.files[0]);
      //this.dispatch.uploadProgramPhoto(fd);
      const file = new FileReader();
      file.readAsDataURL(e.target.files[0]);
      file.onloadend = () => {
        document.getElementById("uploadPhoto").value = "";
        this.setState({ image: file.result, openImageCropModal: true });
      };
    }
  }

  uploadNewMarketplaceImage() {
    const uploadEle = document.getElementById("uploadMarketplaceImage");
    uploadEle.click();
  }

  fileMPImageChange(e) {
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      // const fd = new FormData();
      // fd.set("type", this.state.type);
      // fd.set("image", e.target.files[0]);
      //this.dispatch.uploadProgramPhoto(fd);
      const file = new FileReader();
      file.readAsDataURL(e.target.files[0]);
      file.onloadend = () => {
        document.getElementById("uploadMarketplaceImage").value = "";
        this.setState({
          marketplaceImage: file.result,
          openImageCropModal: true
        });
      };
    }
  }

  render() {
    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          {this.state.openImageCropModal && (
            <ImageCropModal
              sourceImage={
                this.state.type == "logo"
                  ? this.state.image
                  : this.state.marketplaceImage
              }
              onClose={() => {
                this.setState({ openImageCropModal: false });
              }}
              aspectRatio={1 / 1}
              uploadImage={croppedImageURL => {
                const fd = new FormData();
                fd.set("id", this.global.organizationInfo.id);
                fd.set("type", this.state.type);
                fd.set("image", croppedImageURL);
                this.dispatch.uploadProgramPhoto(fd);
                this.setState({ openImageCropModal: false });
                setTimeout(
                  _ => toast.success(`Image Uploaded Successfully`),
                  7000
                );
              }}
            />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div className="col-md-6" style={{ marginLeft: "2rem" }}>
              <h1>Customization</h1>
            </div>
            <div style={{ marginRight: "25px" }}>
              {/* <div className="forbtnwrap justify-end"> */}
              {/* <div className="forbtnapp" style={{ marginLeft: "20px" }}> */}
              <button
                // className="newapplications"
                style={{
                  width: "60px",
                  borderRadius: "7px",
                  backgroundColor: "#7EA1FF",
                  color: "white",
                  border: "none",
                  height: "40px"
                }}
                onClick={_ => this.dispatch.saveOrganizationalProfile()}
              >
                Save
              </button>
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>

          <div className="large-cards">
            <ConnectEmailCard
              updateEmail={value => {
                this.setGlobal({
                  organizationInfo: {
                    ...this.global.organizationInfo,
                    google_mail: value
                  }
                });
                this.dispatch.saveOrganizationalProfile();
              }}
            />

            <div className="customization-card">
              <div className="header-section">
                <h2 className="card-titles">Customization</h2>
              </div>
              <form>
                <div className="row-item">
                  <div className="label-section">
                    <h3 className="label-title">
                      Banner Image<span className="requiredField">*</span>
                    </h3>
                  </div>
                  <div className="input-section">
                    <Dropdown
                      placeholder="Select Banner"
                      fluid
                      selection
                      onChange={(_, { value }) =>
                        this.setGlobal({
                          organizationInfo: {
                            ...this.global.organizationInfo,
                            bcg_image: value
                          }
                        })
                      }
                      value={this.global.organizationInfo.bcg_image}
                      options={this.banners.map((e, i) => ({
                        text: `Banner ${i + 1}`,
                        value: e,
                        key: e,
                        image: {
                          bordered: true,
                          src: `${process.env.REACT_APP_DS_FILES_S3}/${e}`
                        }
                      }))}
                    />
                  </div>
                </div>

                <div className="row-item">
                  <div className="label-section">
                    <h3 className="label-title">Marketplace Image</h3>
                  </div>
                  <div className="col-7 d-flex">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      startIcon={<CloudUpload />}
                      classes={{ root: this.props.classes.uploadButton }}
                      onClick={_ => {
                        this.setState({ type: "marketplace" });
                        this.uploadNewMarketplaceImage();
                      }}
                      fullWidth
                    >
                      Upload Image
                    </Button>
                  </div>
                </div>

                {this.state.logoAndDashboardTitlePermissions.has_access ? (
                  <>
                    <div className="row-item">
                      <div className="label-section">
                        <h3 className="label-title">
                          Dashboard Title
                          <br />
                          <span style={{ fontSize: "9px" }}>
                            {" "}
                            (16 char max)
                          </span>
                        </h3>
                      </div>
                      <div className="col-7 d-flex">
                        <input
                          style={{ width: "100%" }}
                          className="order-input"
                          placeholder={
                            this.global.organizationInfo.organization_title +
                            "..."
                          }
                          name="dashboard_title"
                          onChange={e =>
                            this.setGlobal({
                              organizationInfo: {
                                ...this.global.organizationInfo,
                                [e.target.name]: e.target.value.slice(0, 16)
                              }
                            })
                          }
                          value={
                            this.global.organizationInfo.dashboard_title || ""
                          }
                        />
                      </div>
                    </div>

                    <div className="row-item">
                      <div className="label-section">
                        <h3 className="label-title">Logo</h3>
                      </div>
                      <div className="col-7 d-flex">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          startIcon={<CloudUpload />}
                          onClick={_ => {
                            this.setState({ type: "logo" });
                            this.uploadNew();
                          }}
                          fullWidth
                          classes={{ root: this.props.classes.uploadButton }}
                        >
                          Upload Image
                        </Button>
                      </div>
                    </div>
                  </>
                ) : null}

                {this.state.themePermissions.has_access ? (
                  <>
                    <div className="row-item">
                      <div className="label-section">
                        <h3 className="label-title">Pick your theme:</h3>
                      </div>
                      <div className="col-7 d-flex">
                        <Dropdown
                          placeholder="Pick a Theme"
                          fluid
                          selection
                          onChange={(_, { value }) => {
                            this.setGlobal({
                              organizationInfo: {
                                ...this.global.organizationInfo,
                                theme: value
                              }
                            });
                          }}
                          value={this.global.organizationInfo.theme}
                          options={this.theme.map((e, i) => ({
                            text: `Theme ${i + 1}`,
                            value: i,
                            key: e,
                            image: {
                              bordered: true,
                              src: `${e}`
                            }
                          }))}
                        />
                      </div>
                      <div
                        className="col-md-9 col-9"
                        style={{ display: "none", alignItems: "center" }}
                      >
                        <input
                          type="file"
                          style={{ display: "none" }}
                          id="uploadPhoto"
                          onChange={e => this.fileChange(e)}
                          accept="image/x-png,image/gif,image/jpeg"
                        />
                        <input
                          type="file"
                          style={{ display: "none" }}
                          id="uploadMarketplaceImage"
                          onChange={e => this.fileMPImageChange(e)}
                          accept="image/x-png,image/gif,image/jpeg"
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </form>
            </div>

            <OrderIDCard />

            <div className="order-card">
              <div className="header-section">
                <h2 className="card-titles">Notifications</h2>
              </div>
              <h4 style={{ padding: "12px 15px" }} className="order-title">
                Send customers reminders before auto-payment date
              </h4>
              <div style={{ width: "70%", textAlign: "left" }}>
                <Switch
                  checked={this.global.organizationInfo.customerNotifications}
                  onChange={e =>
                    this.setGlobal({
                      organizationInfo: {
                        ...this.global.organizationInfo,
                        customerNotifications: e.target.checked
                      }
                    })
                  }
                  /*                     onChange={() => {
                    this.SendNotification(e =>
                      this.setState({
                        isNotificationEnabled: e.target.checked
                      }));
                  }} */
                  color="primary"
                />
              </div>
              <h4 style={{ padding: "12px 15px" }} className="order-title">
                Send reminders to organizer before auto-payment date
              </h4>
              <div style={{ width: "70%", textAlign: "left" }}>
                <Switch
                  checked={this.global.organizationInfo.partnerNotifications}
                  onChange={e =>
                    this.setGlobal({
                      organizationInfo: {
                        ...this.global.organizationInfo,
                        partnerNotifications: e.target.checked
                      }
                    })
                  }
                  color="primary"
                />
              </div>
            </div>

            <ObservationsCard />
          </div>
        </div>
      </div>
    );
  }
}

function ObservationsCard() {
  const [global, _] = useGlobal();
  const [observationCustomizations, setObservationCustomizations] = useState(false);

  return (
    <div className="order-card">
      <div className="header-section">
        <h2 className="card-titles">Observations</h2>
      </div>
      <h4 style={{ padding: "12px 15px" }} className="order-title">
        Customize your Observation settings and Classifications
      </h4>
      {observationCustomizations && (
        <ObservationCustomization
          onClose={() => {
            setObservationCustomizations(false);
          }}
        />
      )}
      <button
      style={{marginLeft: "10px", marginTop: "10px"}}
        className="set-email"
        onClick={() => {
          setObservationCustomizations(true);
        }}
      >
        Manage
      </button>
    </div>
  );
}

function OrderIDCard() {
  const [global, _] = useGlobal();

  const [permission, setPermission] = useState({});

  const getPermissionsForOrderID = async () => {
    const featureName = "order-id";
    const ep = new URL(
      `${process.env.REACT_APP_API}/partner/permissions/feature/${featureName}`
    );
    const resp = await axios.get(ep);

    if (resp.data.success) {
      setPermission(resp.data.data);
    } else {
      // TODO: account for error
    }
  };

  useEffect(() => {
    getPermissionsForOrderID();
  }, []);

  return (
    <div className="order-card">
      <div className="header-section">
        <h2 className="card-titles">Customize your Order ID</h2>
      </div>
      <h4 style={{ padding: "12px 15px" }} className="order-title">
        Order numbers start at 101 by default. While you can't change the number
        itself, you can add a prefix or suffix to create custom IDs like "LC101"
        or "101-AD".
      </h4>
      {permission && !permission.has_access ? (
        <div />
      ) : (
        <form>
          <div className="order-row">
            <div>
              <h3 className="order-label">Prefix</h3>
            </div>
            <div className="order-input-area">
              <input
                className="order-input"
                name="order_prefix"
                onChange={e =>
                  setGlobal({
                    organizationInfo: {
                      ...global.organizationInfo,
                      [e.target.name]: e.target.value
                    }
                  })
                }
                value={global.organizationInfo.order_prefix || ""}
              />
            </div>
          </div>
          <div className="order-row">
            <div>
              <h3 className="order-label">Suffix</h3>
            </div>
            <div className="order-input-area">
              <input
                className="order-input"
                name="order_suffix"
                onChange={e =>
                  setGlobal({
                    organizationInfo: {
                      ...global.organizationInfo,
                      [e.target.name]: e.target.value
                    }
                  })
                }
                value={global.organizationInfo.order_suffix || ""}
              />
            </div>
          </div>
          <p className="order-text">
            Your order ID will appear as{" "}
            {`${global.organizationInfo.order_prefix}101${global.organizationInfo.order_suffix}`}
            ,{" "}
            {` ${global.organizationInfo.order_prefix}102${global.organizationInfo.order_suffix}`}
            ,
            {` ${global.organizationInfo.order_prefix}103${global.organizationInfo.order_suffix}`}
            ...
          </p>
        </form>
      )}
    </div>
  );
}

function ConnectEmailCard({ updateEmail }) {
  const [global, _] = useGlobal();
  const [permission, setPermission] = useState({});
  const [email, setEmail] = useState(global.organizationInfo.google_mail);
  const [showConnectButton, setShowConnectButton] = useState(false);

  const getPermissionsForEmailConnect = async () => {
    const featureName = "connect-email";
    const ep = new URL(
      `${process.env.REACT_APP_API}/partner/permissions/feature/${featureName}`
    );
    const resp = await axios.get(ep);

    if (resp.data.success) {
      setPermission(resp.data.data);
    } else {
      // TODO: account for error
    }
  };

  const getAuthUrl = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/gmail/getAuthUrl`;
    const result = await axios.get(ep);

    if (result.data.success) {
      window.location.replace(result.data.data);
    }
  };

  useEffect(() => {
    getPermissionsForEmailConnect();
  }, []);

  useEffect(() => { }, [permission]);

  if (permission && !permission.has_access) {
    return (
      <div style={{ opacity: "50%" }} className="email-card">
        <div className="header-section">
          <h2 className="card-titles">Connect your Email</h2>
        </div>
        <div className="email-description-section">
          <p className="email-description">
            Connect your email address to access all of Dreamschool's
            communication features.
          </p>
        </div>
        <div className="email-lower-section">
          <div>
            <img
              src={GmailLogo}
              alt="Mail Logo"
              className="integrations-card-logo"
            />
          </div>
          <div className="email-button">
            {global.organizationInfo.google_token ? (
              <h2>
                Your Email has been linked :{" "}
                {global.organizationInfo.google_mail}{" "}
              </h2>
            ) : (
              <span
                className="disabled-integrations-button"
                style={{ padding: "0.2rem 1rem" }}
              >
                Connect Email
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }

  // connect your email card
  return (
    <div className="email-card">
      <div className="header-section">
        <h2 className="card-titles">Connect Gmail</h2>
      </div>
      <div className="email-description-section">
        <p className="email-description">
          Connect your Gmail address in order to send emails to clients and be
          able to access our communication features.
        </p>
      </div>
      {!global.organizationInfo.google_token && (
        <div className="email-input-section">
          <span className="email-label">Email</span>
          <input
            name="google_mail"
            id="google_mail"
            type="text"
            className="email-input"
            placeholder="xyz@gmail.com"
            value={email}
            onChange={e => setEmail(e.target.value)}
            disabled={showConnectButton}
          />
          {!showConnectButton ? (
            <button
              className="set-email"
              onClick={_ => {
                updateEmail(email);
                setShowConnectButton(true);
              }}
              disabled={!!global.organizationInfo.google_token}
            >
              Set
            </button>
          ) : (
            <i
              className="fas fa-pencil-alt email-pencil"
              onClick={_ => setShowConnectButton(false)}
            ></i>
          )}
        </div>
      )}
      <div className="email-lower-section">
        <div>
          <img
            src={GmailLogo}
            alt="Mail Logo"
            className="integrations-card-logo"
          />
        </div>
        <div className="email-button">
          {global.organizationInfo.google_token ? (
            <h2
              style={{
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "600",
                fontSize: "13px",
                lineHeight: "20px",
                color: "#000000",
                padding: "12px 0 12px 25px",
                marginBottom: "0px !important"
              }}
            >
              Your Email has been linked: {global.organizationInfo.google_mail}{" "}
            </h2>
          ) : (
            <button
              className={
                !showConnectButton
                  ? "disabled-integrations-button"
                  : "integrations-button"
              }
              onClick={_ => { if (showConnectButton) getAuthUrl(); }}
              disabled={!showConnectButton}
            >
              Connect Gmail
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

/* function CustomerNotification() {
  const [global, _] = useGlobal();

  const [permission, setPermission] = useState({});

  const getPermissionsForOrderID = async () => {
    const featureName = "order-id";
    const ep = new URL(
      `${process.env.REACT_APP_API}/partner/permissions/feature/${featureName}`
    );
    const resp = await axios.get(ep);

    if (resp.data.success) {
      setPermission(resp.data.data);
    } else {
      // TODO: account for error
    }
  };

  useEffect(() => {
    getPermissionsForOrderID();
  }, []);

  return (
    <div className="order-card">
      <div className="header-section">
        <h2 className="card-titles">Customer Notifications</h2>
      </div>
      <h4 style={{ padding: "12px 15px" }} className="order-title">
        Send customers reminders before auto-payment date
      </h4>
      <div style={{ width: "70%", textAlign: "left" }}>
        <Switch
          checked={this.state.isNotificationEnabled}
          onChange={e =>
            this.setState({
              isNotificationEnabled: e.target.checked
            })
          }
          color="primary"
        />
      </div>
    </div>
  );
} */

Customization.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object
};

export default withStyles(styles)(Customization);

// import React, { useState, useEffect, useGlobal, setGlobal } from "reactn";
// import PropTypes from "prop-types";
// import "../../assets/css/componentSpecificCss/customizationIntegrations.css";
// import {
//   MailChimpLogo,
//   ZoomLogo,
//   GmailLogo,
//   Theme1,
//   Theme2,
//   Theme3,
//   Theme4,
//   Theme5,
//   Theme6,
//   Theme7
// } from "../../assets";
// import axios from "axios";
// import { toast } from "react-toastify";
// import { Dropdown } from "semantic-ui-react";
// import { Button } from "@material-ui/core";
// import { CloudUpload } from "@material-ui/icons";
// import "../../assets/css/promo.css";
// import { withStyles } from "@material-ui/core/styles";
// import ImageCropModal from "../../components/modals/ImageCropModal";
// import Axios from "axios";

// const styles = {
//   uploadButton: {
//     "& .MuiButton-root": {
//       fontSize: "11px !important"
//     },
//     "& .MuiButton-label": {
//       fontSize: "11px !important"
//     }
//   }
// };

// class Customization extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       IntegrationsData: [
//         {
//           id: "1",
//           title: "Zoom",
//           description: "Sync Dreamschools with your Zoom",
//           buttonName: "Connect Zoom",
//           imageSource: ZoomLogo,
//           redirect_url: `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_ID}&redirect_uri=${process.env.REACT_APP_ZOOM_REDIRECT_URL}`,
//           linked: (obj, glbl) =>
//             obj.zoom || glbl.organizationInfo.zoom_refresh_token
//         },
//         {
//           title: "Mailchimp",
//           permissions: {},
//           description: "Sync Dreamschools with your Mailchimp account",
//           buttonName: "Connect Mailchimp",
//           imageSource: MailChimpLogo,
//           linked: obj => obj.mail
//         }
//       ],
//       zoom: false,
//       logoAndDashboardTitlePermissions: {},
//       themePermissions: {},
//       type: "",
//       openImageCropModal: false,
//       image: "",
//       marketplaceImage: "",
//       google_mail: ""
//     };

//     this.banners = [
//       "partners/Banners/Banner1.jpg",
//       "partners/Banners/Banner2.png",
//       "partners/Banners/Banner3.jpg",
//       "partners/Banners/Banner4.jpg",
//       "partners/Banners/Banner5.jpg",
//       "partners/Banners/Banner6.png",
//       "partners/Banners/Banner7.png",
//       "partners/Banners/Banner8.jpg"
//     ];
//     this.theme = [Theme5, Theme2, Theme3, Theme1, Theme4, Theme6, Theme7];
//     this.setGlobal({
//       partnerPathway: [
//         ...this.global.partnerPathway.slice(0, 1),
//         { label: "Customization", to: "/customization" }
//       ]
//     });
//   }
//   async componentDidMount() {
//     this.saveGmailInfo();

//     const code = this.props.history.location.query.code;
//     if (code) {
//       const ep = `${process.env.REACT_APP_API}/partners/zoom/auth`;
//       const results = await axios.post(ep, { code });
//       if (results.data.success) {
//         this.setState({ zoom: true });
//         this.props.history.push("/partners/customization");
//         window.location.reload();
//       } else {
//         // toast.error(results.data.message)
//         this.props.history.push("/partners/customization");
//       }
//     } else {
//     }

//     // get permissions for logo + dashboard
//     await this.getLogoAndDashboardPermissions();
//     await this.getThemePermissions();
//     await this.getMailChimpPermissions();
//     await this.getZoomPermissions();
//   }

//   saveGmailInfo = async () => {
//     const params = this.props.location.query;
//     if (params && params.code && params.use === "gmail") {
//       const ep = `${process.env.REACT_APP_API}/partners/gmail/processToken`;
//       const res = await Axios.post(ep, { code: params.code });

//       if (res.data.success) {
//         params.use === "gmail" &&
//           this.setGlobal({
//             organizationInfo: {
//               ...this.global.organizationInfo,
//               google_token: res.data.data.token
//               // google_mail: res.data.data.email
//             }
//           });
//         this.dispatch.saveOrganizationalProfile();
//         // The below line is used to remove the code param from the URL to ensure a duplicate request is not made to stripe that invalidates the account
//         // this.props.history.replace({}, "", this.props.location.search.replace(/[\?&]my_parameter=[^&]+/, '').replace(/^&/, '?'));
//       } else {
//       }
//     }
//   };

//   getMailChimpPermissions = async () => {
//     const featureName = "connect-mailchimp";
//     const ep = new URL(
//       `${process.env.REACT_APP_API}/partner/permissions/feature/${featureName}`
//     );
//     const resp = await axios.get(ep);
//     if (resp.data && resp.data.success) {
//       this.setState({
//         IntegrationsData: this.state.IntegrationsData.map(feature => {
//           if (feature.title === "Mailchimp") {
//             const newFeature = {
//               ...feature,
//               id: resp.data.data.id,
//               permissions: resp.data.data
//             };
//             return newFeature;
//           } else {
//             return feature;
//           }
//         })
//       });
//     }
//   };

//   getZoomPermissions = async () => {
//     const featureName = "zoom";
//     const ep = new URL(
//       `${process.env.REACT_APP_API}/partner/permissions/feature/${featureName}`
//     );
//     const resp = await axios.get(ep);
//     if (resp.data && resp.data.success) {
//       this.setState({
//         IntegrationsData: this.state.IntegrationsData.map(feature => {
//           if (feature.title === "Zoom") {
//             const newFeature = {
//               ...feature,
//               id: resp.data.data.id,
//               permissions: resp.data.data
//             };
//             return newFeature;
//           } else {
//             return feature;
//           }
//         })
//       });
//     }
//   };

//   getLogoAndDashboardPermissions = async () => {
//     const featureName = "dashboard-logo-title";
//     const ep = new URL(
//       `${process.env.REACT_APP_API}/partner/permissions/feature/${featureName}`
//     );
//     const resp = await axios.get(ep);
//     if (resp.data && resp.data.success) {
//       this.setState({ logoAndDashboardTitlePermissions: resp.data.data });
//     }
//   };

//   getThemePermissions = async () => {
//     const featureName = "theme";
//     const ep = new URL(
//       `${process.env.REACT_APP_API}/partner/permissions/feature/${featureName}`
//     );
//     const resp = await axios.get(ep);
//     if (resp.data && resp.data.success) {
//       this.setState({ themePermissions: resp.data.data });
//     }
//   };

//   uploadNew() {
//     const uploadEle = document.getElementById("uploadPhoto");
//     uploadEle.click();
//   }

//   fileChange(e) {
//     if (e.target.files.length && e.target.files[0].type.includes("image/")) {
//       // const fd = new FormData();
//       // fd.set("type", this.state.type);
//       // fd.set("image", e.target.files[0]);
//       //this.dispatch.uploadProgramPhoto(fd);
//       const file = new FileReader();
//       file.readAsDataURL(e.target.files[0]);
//       file.onloadend = () => {
//         document.getElementById("uploadPhoto").value = "";
//         this.setState({ image: file.result, openImageCropModal: true });
//       };
//     }
//   }

//   uploadNewMarketplaceImage() {
//     const uploadEle = document.getElementById("uploadMarketplaceImage");
//     uploadEle.click();
//   }

//   fileMPImageChange(e) {
//     if (e.target.files.length && e.target.files[0].type.includes("image/")) {
//       // const fd = new FormData();
//       // fd.set("type", this.state.type);
//       // fd.set("image", e.target.files[0]);
//       //this.dispatch.uploadProgramPhoto(fd);
//       const file = new FileReader();
//       file.readAsDataURL(e.target.files[0]);
//       file.onloadend = () => {
//         document.getElementById("uploadMarketplaceImage").value = "";
//         this.setState({
//           marketplaceImage: file.result,
//           openImageCropModal: true
//         });
//       };
//     }
//   }

//   render() {
//     return (
//       <div className="admin">
//         <div className="container-fluid adminprofiles">
//           {this.state.openImageCropModal && (
//             <ImageCropModal
//               sourceImage={
//                 this.state.type == "logo"
//                   ? this.state.image
//                   : this.state.marketplaceImage
//               }
//               onClose={() => {
//                 this.setState({ openImageCropModal: false });
//               }}
//               aspectRatio={1 / 1}
//               uploadImage={croppedImageURL => {
//                 const fd = new FormData();
//                 fd.set("id", this.global.organizationInfo.id);
//                 fd.set("type", this.state.type);
//                 fd.set("image", croppedImageURL);
//                 this.dispatch.uploadProgramPhoto(fd);
//                 this.setState({ openImageCropModal: false });
//                 setTimeout(
//                   _ => toast.success(`Image Uploaded Successfully`),
//                   7000
//                 );
//               }}
//             />
//           )}
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center"
//             }}
//           >
//             <div className="col-md-6" style={{ marginLeft: "2rem" }}>
//               <h1>Customization</h1>
//             </div>
//             <div style={{ marginRight: "25px" }}>
//               {/* <div className="forbtnwrap justify-end"> */}
//               {/* <div className="forbtnapp" style={{ marginLeft: "20px" }}> */}
//               <button
//                 // className="newapplications"
//                 style={{
//                   width: "60px",
//                   borderRadius: "7px",
//                   backgroundColor: "#7EA1FF",
//                   color: "white",
//                   border: "none",
//                   height: "40px"
//                 }}
//                 onClick={_ => this.dispatch.saveOrganizationalProfile()}
//               >
//                 Save
//               </button>
//               {/* </div> */}
//               {/* </div> */}
//             </div>
//           </div>

//           <div className="large-cards">
//             <ConnectEmailCard
//               updateEmail={value => {
//                 this.setGlobal({
//                   organizationInfo: {
//                     ...this.global.organizationInfo,
//                     google_mail: value
//                   }
//                 });
//                 this.dispatch.saveOrganizationalProfile();
//               }}
//             />

//             <div className="customization-card">
//               <div className="header-section">
//                 <h2 className="card-titles">Customization</h2>
//               </div>
//               <form>
//                 <div className="row-item">
//                   <div className="label-section">
//                     <h3 className="label-title">
//                       Banner Image<span className="requiredField">*</span>
//                     </h3>
//                   </div>
//                   <div className="input-section">
//                     <Dropdown
//                       placeholder="Select Banner"
//                       fluid
//                       selection
//                       onChange={(_, { value }) =>
//                         this.setGlobal({
//                           organizationInfo: {
//                             ...this.global.organizationInfo,
//                             bcg_image: value
//                           }
//                         })
//                       }
//                       value={this.global.organizationInfo.bcg_image}
//                       options={this.banners.map((e, i) => ({
//                         text: `Banner ${i + 1}`,
//                         value: e,
//                         key: e,
//                         image: {
//                           bordered: true,
//                           src: `${process.env.REACT_APP_DS_FILES_S3}/${e}`
//                         }
//                       }))}
//                     />
//                   </div>
//                 </div>

//                 <div className="row-item">
//                   <div className="label-section">
//                     <h3 className="label-title">Marketplace Image</h3>
//                   </div>
//                   <div className="col-7 d-flex">
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       component="span"
//                       startIcon={<CloudUpload />}
//                       classes={{ root: this.props.classes.uploadButton }}
//                       onClick={_ => {
//                         this.setState({ type: "marketplace" });
//                         this.uploadNewMarketplaceImage();
//                       }}
//                       fullWidth
//                     >
//                       Upload Image
//                     </Button>
//                   </div>
//                 </div>

//                 {this.state.logoAndDashboardTitlePermissions.has_access ? (
//                   <>
//                     <div className="row-item">
//                       <div className="label-section">
//                         <h3 className="label-title">
//                           Dashboard Title
//                           <br />
//                           <span style={{ fontSize: "9px" }}>
//                             {" "}
//                             (16 char max)
//                           </span>
//                         </h3>
//                       </div>
//                       <div className="col-7 d-flex">
//                         <input
//                           style={{ width: "100%" }}
//                           className="order-input"
//                           placeholder={
//                             this.global.organizationInfo.organization_title +
//                             "..."
//                           }
//                           name="dashboard_title"
//                           onChange={e =>
//                             this.setGlobal({
//                               organizationInfo: {
//                                 ...this.global.organizationInfo,
//                                 [e.target.name]: e.target.value.substr(0, 16)
//                               }
//                             })
//                           }
//                           value={
//                             this.global.organizationInfo.dashboard_title || ""
//                           }
//                         />
//                       </div>
//                     </div>

//                     <div className="row-item">
//                       <div className="label-section">
//                         <h3 className="label-title">Logo</h3>
//                       </div>
//                       <div className="col-7 d-flex">
//                         <Button
//                           variant="contained"
//                           color="primary"
//                           component="span"
//                           startIcon={<CloudUpload />}
//                           onClick={_ => {
//                             this.setState({ type: "logo" });
//                             this.uploadNew();
//                           }}
//                           fullWidth
//                           classes={{ root: this.props.classes.uploadButton }}
//                         >
//                           Upload Image
//                         </Button>
//                       </div>
//                     </div>
//                   </>
//                 ) : null}

//                 {this.state.themePermissions.has_access ? (
//                   <>
//                     <div className="row-item">
//                       <div className="label-section">
//                         <h3 className="label-title">Pick your theme:</h3>
//                       </div>
//                       <div className="col-7 d-flex">
//                         <Dropdown
//                           placeholder="Pick a Theme"
//                           fluid
//                           selection
//                           onChange={(_, { value }) => {
//                             this.setGlobal({
//                               organizationInfo: {
//                                 ...this.global.organizationInfo,
//                                 theme: value
//                               }
//                             });
//                           }}
//                           value={this.global.organizationInfo.theme}
//                           options={this.theme.map((e, i) => ({
//                             text: `Theme ${i + 1}`,
//                             value: i,
//                             key: e,
//                             image: {
//                               bordered: true,
//                               src: `${e}`
//                             }
//                           }))}
//                         />
//                       </div>
//                       <div
//                         className="col-md-9 col-9"
//                         style={{ display: "none", alignItems: "center" }}
//                       >
//                         <input
//                           type="file"
//                           style={{ display: "none" }}
//                           id="uploadPhoto"
//                           onChange={e => this.fileChange(e)}
//                           accept="image/x-png,image/gif,image/jpeg"
//                         />
//                         <input
//                           type="file"
//                           style={{ display: "none" }}
//                           id="uploadMarketplaceImage"
//                           onChange={e => this.fileMPImageChange(e)}
//                           accept="image/x-png,image/gif,image/jpeg"
//                         />
//                       </div>
//                     </div>
//                   </>
//                 ) : null}
//               </form>
//             </div>

//             <OrderIDCard />
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// function OrderIDCard() {
//   const [global, _] = useGlobal();

//   const [permission, setPermission] = useState({});

//   const getPermissionsForOrderID = async () => {
//     const featureName = "order-id";
//     const ep = new URL(
//       `${process.env.REACT_APP_API}/partner/permissions/feature/${featureName}`
//     );
//     const resp = await axios.get(ep);

//     if (resp.data.success) {
//       setPermission(resp.data.data);
//     } else {
//       // TODO: account for error
//     }
//   };

//   useEffect(() => {
//     getPermissionsForOrderID();
//   }, []);

//   return (
//     <div className="order-card">
//       <div className="header-section">
//         <h2 className="card-titles">Customize your Order ID</h2>
//       </div>
//       <h4 style={{ padding: "12px 15px" }} className="order-title">
//         Order numbers start at 101 by default. While you can't change the number
//         itself, you can add a prefix or suffix to create custom IDs like "LC101"
//         or "101-AD".
//       </h4>
//       {permission && !permission.has_access ? (
//         <div />
//       ) : (
//         <form>
//           <div className="order-row">
//             <div>
//               <h3 className="order-label">Prefix</h3>
//             </div>
//             <div className="order-input-area">
//               <input
//                 className="order-input"
//                 name="order_prefix"
//                 onChange={e =>
//                   setGlobal({
//                     organizationInfo: {
//                       ...global.organizationInfo,
//                       [e.target.name]: e.target.value
//                     }
//                   })
//                 }
//                 value={global.organizationInfo.order_prefix}
//               />
//             </div>
//           </div>
//           <div className="order-row">
//             <div>
//               <h3 className="order-label">Suffix</h3>
//             </div>
//             <div className="order-input-area">
//               <input
//                 className="order-input"
//                 name="order_suffix"
//                 onChange={e =>
//                   setGlobal({
//                     organizationInfo: {
//                       ...global.organizationInfo,
//                       [e.target.name]: e.target.value
//                     }
//                   })
//                 }
//                 value={global.organizationInfo.order_suffix}
//               />
//             </div>
//           </div>
//           <p className="order-text">
//             Your order ID will appear as{" "}
//             {`${global.organizationInfo.order_prefix}101${global.organizationInfo.order_suffix}`}
//             ,{" "}
//             {` ${global.organizationInfo.order_prefix}102${global.organizationInfo.order_suffix}`}
//             ,
//             {` ${global.organizationInfo.order_prefix}103${global.organizationInfo.order_suffix}`}
//             ...
//           </p>
//         </form>
//       )}
//     </div>
//   );
// }

// function ConnectEmailCard({ updateEmail }) {
//   const [global, _] = useGlobal();
//   const [permission, setPermission] = useState({});
//   const [email, setEmail] = useState(global.organizationInfo.google_mail);
//   const [showConnectButton, setShowConnectButton] = useState(false);

//   const getPermissionsForEmailConnect = async () => {
//     const featureName = "connect-email";
//     const ep = new URL(
//       `${process.env.REACT_APP_API}/partner/permissions/feature/${featureName}`
//     );
//     const resp = await axios.get(ep);

//     if (resp.data.success) {
//       setPermission(resp.data.data);
//     } else {
//       // TODO: account for error
//     }
//   };

//   const getAuthUrl = async () => {
//     const ep = `${process.env.REACT_APP_API}/partners/gmail/getAuthUrl`;
//     const result = await axios.get(ep);

//     if (result.data.success) {
//       window.location.replace(result.data.data);
//     }
//   };

//   useEffect(() => {
//     getPermissionsForEmailConnect();
//   }, []);

//   useEffect(() => {}, [permission]);

//   if (permission && !permission.has_access) {
//     return (
//       <div style={{ opacity: "50%" }} className="email-card">
//         <div className="header-section">
//           <h2 className="card-titles">Connect your Email</h2>
//         </div>
//         <div className="email-description-section">
//           <p className="email-description">
//             Connect your email address to access all of Dreamschool's
//             communication features.
//           </p>
//         </div>
//         <div className="email-lower-section">
//           <div>
//             <img
//               src={GmailLogo}
//               alt="Mail Logo"
//               className="integrations-card-logo"
//             />
//           </div>
//           <div className="email-button">
//             {global.organizationInfo.google_token ? (
//               <h2>
//                 Your Email has been linked :{" "}
//                 {global.organizationInfo.google_mail}{" "}
//               </h2>
//             ) : (
//               <span
//                 className="integrations-button"
//                 style={{ padding: "0.2rem 1rem" }}
//                 onClick={_ => getAuthUrl()}
//               >
//                 Connect Email
//               </span>
//             )}
//           </div>
//         </div>
//       </div>
//     );
//   }

//   // connect your email card
//   return (
//     <div className="email-card">
//       <div className="header-section">
//         <h2 className="card-titles">Connect Gmail</h2>
//       </div>
//       <div className="email-description-section">
//         <p className="email-description">
//           Connect your Gmail address in order to send emails to clients and be
//           able to access our communication features.
//         </p>
//       </div>
//       {!global.organizationInfo.google_token && (
//         <div className="email-input-section">
//           <span className="email-label">Email</span>
//           <input
//             name="google_mail"
//             id="google_mail"
//             type="text"
//             className="email-input"
//             placeholder="xyz@gmail.com"
//             value={email}
//             onChange={e => setEmail(e.target.value)}
//             disabled={showConnectButton ? true : false}
//           />
//           {!showConnectButton ? (
//             <button
//               className="set-email"
//               onClick={_ => {
//                 updateEmail(email);
//                 setShowConnectButton(true);
//               }}
//               disabled={global.organizationInfo.google_token ? true : false}
//             >
//               Set
//             </button>
//           ) : (
//             <i
//               className="fas fa-pencil-alt email-pencil"
//               onClick={_ => setShowConnectButton(false)}
//             ></i>
//           )}
//         </div>
//       )}
//       <div className="email-lower-section">
//         <div>
//           <img
//             src={GmailLogo}
//             alt="Mail Logo"
//             className="integrations-card-logo"
//           />
//         </div>
//         <div className="email-button">
//           {global.organizationInfo.google_token ? (
//             <h2
//               style={{
//                 fontFamily: "Open Sans, sans-serif",
//                 fontWeight: "600",
//                 fontSize: "13px",
//                 lineHeight: "20px",
//                 color: "#000000",
//                 padding: "12px 0 12px 25px",
//                 marginBottom: "0px !important"
//               }}
//             >
//               Your Email has been linked: {global.organizationInfo.google_mail}{" "}
//             </h2>
//           ) : (
//             <button
//               className={
//                 !showConnectButton
//                   ? "disabled-integrations-button"
//                   : "integrations-button"
//               }
//               onClick={_ => getAuthUrl()}
//               disabled={!showConnectButton ? true : false}
//             >
//               Connect Gmail
//             </button>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// Customization.propTypes = {
//   classes: PropTypes.object,
//   history: PropTypes.object,
//   location: PropTypes.object,
//   match: PropTypes.object
// };

// export default withStyles(styles)(Customization);
