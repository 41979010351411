import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import BaseModal from "./baseModal/BaseModal";
import { DROPDOWN, STRING_INPUT } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
class AddSectionModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      section: "",
      section_title: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async submitForm(e) {
    e.preventDefault();
    if (this.state.section.trim()) {
      this.props.addSection(this.state.section);
      this.props.onClose();
    }
  }

  getBaseModalFields = () => {
    const selectTitleField = {
      type: DROPDOWN,
      data: {
        name: "Select Title",
        required: true,
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            section_title: e.target.value,
            section: e.target.value
          });
        },
        choices: [
          {
            value: "Parent / Guardian",
            text: "Parent / Guardian"
          },
          {
            value: "Child Profile",
            text: "Child Profile"
          },
          {
            value: "Profile Information",
            text: "Profile Information"
          },
          {
            value: "Other",
            text: "Other"
          },
        ]
      }
    }

    const sectionNameField = {
      type: STRING_INPUT,
      data: {
        name: "Section Name",
        isDisabled: this.state.section_title !== "Other",
        placeholder: "Section Name...",
        value: this.state.section,
        handleChange: e => {
          this.setState({
            section: e.target.value
          });
        }
      }
    }

    const fields = [];

    fields.push(selectTitleField);
    fields.push(sectionNameField);

    return fields;
  }

  getBaseModalButtons = () => {
    const addSectionButton = {
      name: "Add Section",
      handleClick: e =>  this.submitForm(e),
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit"
    }

    return [addSectionButton];
  }

  getBaseModalProps = () => {
    return {
      title: "Add Section",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    }
  }

  render() {
    return (<BaseModal {...this.getBaseModalProps()}/>)
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div>
            <h3>Add Section</h3>
            <form onSubmit={e => this.submitForm(e)}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Select Title
                </label>
                <select
                  name="section_title"
                  id="section_title"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value,
                      section: e.target.value
                    });
                  }}
                >
                  <option value="">Please Select</option>
                  <option value="Parent / Guardian">Parent / Guardian</option>
                  <option value="Child Profile">Child Profile</option>
                  <option value="Profile Information">
                    Profile Information
                  </option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Section Name *
                </label>
                <input
                  name="section"
                  id="organization"
                  type="text"
                  className="form-control"
                  placeholder="Section Name..."
                  style={{ width: "70%" }}
                  disabled={this.state.section_title !== "Other"}
                  value={this.state.section}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
              </div>

              <div className="modal-footer">
                <button type="submit" className="btn profile-btn">
                  Add new
                </button>
              </div>
            </form>

            <IconButton
              style={{ position: "absolute", right: "0", top: "0" }}
              onClick={_ => this.props.onClose()}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddSectionModal);
