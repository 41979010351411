import React from "reactn";
import MyEarningsModal from "../../../components/modals/MyEarningsModal";
import EarningOverview from "./earnings-overview";
import RecentDeposits from "./recent-deposits";
import DepositModal from "../../../components/modals/DepositsModal";
import axios from "axios";
import { format, addDays, getDay, isAfter, isBefore, subDays } from "date-fns";
class MyEarnings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      payouts: [],
      oneTime: 0,
      recurring: 0,
      counts: {
        lastPayout: null,
        nextPayout: null,
        totalPayout: null
      },
      recentPayments: [],
      donations: [],
      runningTotal: null,
      transactions: [],
      payout: null,
      refundedOneTime: 0,
      refundedRecurring: 0,
      refundedTotal: 0
    };
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "My Earnings", to: "/my-earnings" }
      ]
    });
  }

  // including start, excluding end
  getAmountBetweenDates(start, end, arr) {
    let amount = 0;
    arr.forEach(e => {
      if (isBefore(e.date, end) && isAfter(e.date, subDays(start, 1))) {
        amount += parseInt(e.amount);
      }
    });
    return amount;
  }

  makeCumulative = transactions => {
    let cumulative = 0;
    return transactions.map(e => {
      cumulative += e.amount;
      return { ...e, amount: cumulative };
    });
  };

  newGroupByWeek = transactions => {
    let date = transactions[0].date;
    let endDate = transactions[transactions.length - 1].date;
    const result = [];

    while (isBefore(date, addDays(endDate, 7))) {
      const amount = this.getAmountBetweenDates(
        date,
        addDays(date, 7),
        transactions
      );
      const formattedDate = format(date, "LLL dd");
      console.log("formatted date is", formattedDate);
      result.push({ date: formattedDate, amount: parseInt(amount) });
      date = addDays(date, 7);
    }

    console.log("result is", result);
    return this.makeCumulative(result);
  };

  async componentDidMount() {
    try {
      const ep = `${process.env.REACT_APP_API}/partners/order-tracking`;
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      console.log("res", res);

      if (res.data.success) {
        const data = res.data.data;

        const oneTimeTotal = data.Once.payments.reduce(
          (prev, curr) => prev + parseInt(curr.total),
          0
        );

        const recurringTotal = data.Recurring.payments.reduce(
          (prev, curr) => prev + parseInt(curr.total),
          0
        );

        const total = oneTimeTotal + recurringTotal;
        console.log({ oneTimeTotal, recurringTotal, total });

        const refundedOneTime = data.Once.payments.reduce(
          (prev, curr) => prev + parseInt(curr.refundedAmount),
          0
        );
        const refundedRecurring = data.Recurring.payments.reduce(
          (prev, curr) => prev + parseInt(curr.refundedAmount),
          0
        );
        const refundedTotal = refundedOneTime + refundedRecurring;

        this.setState({
          total: total / 100,
          oneTime: oneTimeTotal / 100,
          recurring: recurringTotal / 100,
          recentPayments: [
            ...data.Recurring.payments,
            ...data.Once.payments,
            ...data.Once.failed
          ]
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .filter(e => isAfter(new Date(e.date), subDays(new Date(), 90))),
          refundedOneTime: refundedOneTime / 100,
          refundedRecurring: refundedRecurring / 100,
          refundedTotal: refundedTotal / 100,
          chart: this.newGroupByWeek(
            [...data.Once.payments, ...data.Recurring.payments]
              .sort((a, b) => new Date(a.date) - new Date(b.date))
              .map(e => ({ date: new Date(e.date), amount: e.total }))
          )
          // this.groupByWeek(
          //   [...data.Once.payments, ...data.Recurring.payments]
          //     .sort((a, b) => new Date(a.date) - new Date(b.date))
          //     .map(e => ({ date: new Date(e.date), amount: e.total }))
          // )
        });
        this.forceUpdate();
      }

      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (er) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    console.log("this.state myEarnings.jsx", this.state);

    return (
      <div className="container-fluid my_earning">
        {this.state.payout ? (
          <DepositModal
            item={this.state.payout}
            setItem={_ => this.setState({ payout: null })}
          />
        ) : (
          <>
            <div className="cont">
              <h1>Earnings Summary</h1>
            </div>
            <div className="cont">
              <MyEarningsModal
                earnings={{
                  oneTime: this.state.oneTime,
                  recurring: this.state.recurring,
                  refundedOneTime: this.state.refundedOneTime,
                  refundedRecurring: this.state.refundedRecurring
                }}
                next={null}
              />
              <div className="row">
                <RecentDeposits earnings={this.state.recentPayments || []} />
                <EarningOverview
                  total={this.state.total}
                  earnings={this.state.chart || []}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default MyEarnings;

// import React from "reactn";
// import MyEarningsModal from "../../../components/modals/MyEarningsModal";
// import EarningOverview from "./earnings-overview";
// import RecentDeposits from "./recent-deposits";
// import DepositModal from "../../../components/modals/DepositsModal";
// import axios from "axios";
// import { format, addDays, getDay } from "date-fns";
// class MyEarnings extends React.PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       payouts: [],
//       oneTime: 0,
//       recurring: 0,
//       counts: {
//         lastPayout: null,
//         nextPayout: null,
//         totalPayout: null
//       },
//       recentPayments: [],
//       donations: [],
//       runningTotal: null,
//       transactions: [],
//       payout: null
//     };
//     this.setGlobal({
//       partnerPathway: [
//         ...this.global.partnerPathway.slice(0, 1),
//         { label: "My Earnings", to: "/my-earnings" }
//       ]
//     });
//   }

//   groupByWeek(transactions) {
//     const initalDate = new Date(transactions[0].created * 1000);
//     let start = addDays(initalDate, (getDay(initalDate) + 1) * -1);
//     const finalArray = [];
//     finalArray.push({ amount: 0, date: format(start, "LLL dd") });
//     start = addDays(start, 7);
//     let amount = 0;
//     for (let transaction of transactions) {
//       if (transaction.created < start.getTime() / 1000) {
//         if (transaction.manual) {
//           amount += !transaction.total
//             ? parseInt(transaction.amount)
//             : parseInt(transaction.total);
//         } else {
//           amount +=
//             parseInt(transaction.amount) === 0
//               ? 0
//               : parseInt(transaction.amount) -
//                 (parseInt(transaction.amount) * 0.029 + 30);
//         }
//       } else {
//         finalArray.push({ amount, date: format(start, "LLL dd") });
//         start = addDays(start, 7);
//         amount = 0;
//         if (transaction.manual) {
//           amount += !transaction.total
//             ? parseInt(transaction.amount)
//             : parseInt(transaction.total);
//         } else {
//           amount +=
//             parseInt(transaction.amount) === 0
//               ? 0
//               : parseInt(transaction.amount) -
//                 (parseInt(transaction.amount) * 0.029 + 30);
//         }
//       }
//     }

//     finalArray.push({ amount, date: format(start, "LLL dd") });
//     console.log("final array is", finalArray);
//     return finalArray;
//   }
//   async componentDidMount() {
//     try {
//       const ep = `${process.env.REACT_APP_API}/partners/orders/total`;
//       this.setGlobal({ loading: true });
//       const res = await axios.get(ep);
//       console.log("res", res);

//       if (res.data.success) {
//         const data = res.data.data;

//         // remove those without a status
//         data.invoices.invoices = data.invoices.invoices.filter(e => e.status);
//         data.recurring.invoices = data.recurring.invoices.filter(e => e.status);
//         data.totalOneTime.charges = data.totalOneTime.charges.filter(
//           e => e.status
//         );

//         let total = 0;
//         total += data.totalOneTime.charges.reduce(
//           (a, c) => a + (c.amount - (c.amount * 0.029 + 30)),
//           0
//         );
//         total += data.recurring.invoices
//           .filter(f => f.total !== 0 && f.amount !== 0)
//           .reduce(
//             (a, c) =>
//               a +
//               (c.manual
//                 ? parseFloat(c.total / 100)
//                 : parseFloat(c.amount / 100) -
//                   (parseFloat(c.amount) * 0.029 + 30)),
//             0
//           ); //Taking into account manual payments
//         total += data.recurring.deposits.reduce(
//           (a, c) => a + (c.amount - (c.amount * 0.029 + 30)),
//           0
//         );

//         const deposits =
//           data.recurring.deposits.reduce((a, c) => a + c.amount, 0) / 100;

//         let allInstallmentInvoices = [];
//         data.Installments.charges.forEach(charge => {
//           charge.payments.forEach(payment => {
//             allInstallmentInvoices.push(payment.invoice);
//           });
//         });

//         this.setState({
//           total: parseFloat(total) / 100,
//           oneTime:
//             // data.totalOneTime.totalCharges +
//             (data.totalOneTime.charges.reduce(
//               (prev, curr) => prev + curr?.stripe?.amount,
//               0
//             ) / 100 || data.totalOneTime.totalCharges) +
//             data.Installments.totalCharges +
//             // parseFloat(deposits) + // dont include recurring payment deposits in oneTIme
//             data.invoices.invoices.reduce(
//               (prev, curr) => prev + parseInt(curr.amount) / 100,
//               0
//             ),
//           recurring: data.recurring.totalRecurring + deposits,
//           recentPayments: [
//             ...data.donations,
//             ...data.totalOneTime.charges,
//             ...data.recurring.invoices.filter(
//               f => f.total !== 0 && f.amount !== 0
//             ),
//             ...data.recurring.deposits,
//             ...allInstallmentInvoices,
//             ...data.invoices.invoices
//           ]
//             .sort((a, b) => new Date(b.created) - new Date(a.created))
//             .map(e => (e.amount ? e : { ...e, amount: e.amount_paid }))
//             .filter(e => e.status), // remove those without a status (dont show those that are Pending Collection)
//           donations: data.donations,
//           chart: this.groupByWeek(
//             [
//               ...data.totalOneTime.charges.sort(
//                 (a, b) =>
//                   new Date(b.created * 1000) - new Date(a.created * 1000)
//               ),
//               ...data.recurring.invoices.sort(
//                 (a, b) =>
//                   new Date(b.created * 1000) - new Date(a.created * 1000)
//               ),
//               ...data.recurring.deposits.sort(
//                 (a, b) =>
//                   new Date(b.created * 1000) - new Date(a.created * 1000)
//               )
//             ]
//               .map(e =>
//                 e.amount
//                   ? e
//                   : { ...e, amount: e.manual ? e.total : e.amount_paid }
//               )
//               .reverse()
//           )
//         });
//         this.forceUpdate();
//       }

//       this.setGlobal({ loading: false, lastAPICall: res });
//     } catch (er) {
//       this.setGlobal({ loading: false, lastAPICall: null });
//     }
//   }

//   render() {
//     console.log("this.state myEarnings.jsx", this.state);

//     return (
//       <div className="container-fluid my_earning">
//         {this.state.payout ? (
//           <DepositModal
//             item={this.state.payout}
//             setItem={_ => this.setState({ payout: null })}
//           />
//         ) : (
//           <>
//             <div className="cont">
//               <h1>Earnings Summary</h1>
//             </div>
//             <div className="cont">
//               <MyEarningsModal
//                 earnings={{
//                   oneTime: this.state.oneTime,
//                   recurring: this.state.recurring
//                 }}
//                 next={null}
//               />
//               <div className="row">
//                 <RecentDeposits earnings={this.state.recentPayments || []} />
//                 <EarningOverview
//                   total={this.state.total}
//                   earnings={this.state.chart || []}
//                 />
//               </div>
//             </div>
//           </>
//         )}
//       </div>
//     );
//   }
// }

// export default MyEarnings;
