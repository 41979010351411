import React from "reactn";
import Sidebar from "../common/Sidebar";
import SideMenu from "./SideMenu";
import TopBar from "../common/TopBar";
import ParentRoute from "../../routes/parentRoute";
import Home from "../../pages/parents/home/home";
import Calendar from "../../pages/shared/calendar";
import Messaging from "../../pages/shared/messaging/messaging";
import PaymentMethods from "../../pages/shared/paymentMethods/paymentMethods";
import AccountSettings from "../../pages/parents/accountSettings/accountSettings";
import Application from "../../pages/parents/applications";
import ApplicationView from "../../pages/parents/applicationView";
import InterestedPrograms from "../../pages/parents/interestedPrograms";
import ChooseChild from "../../pages/parents/newApplication/chooseChild";
import FileApplication from "../../pages/parents/newApplication/fileApplication";
import ConfirmApplication from "../../pages/parents/newApplication/confirmApplication";
import TuitionPayments from "../../pages/parents/tuitionPayments";
import TuitionPaymentsDetails from "../../pages/shared/invoice/tuitionPaymentsDetails";
import MyEnrollments from "../../pages/parents/myEnrollments";
import MyEnrollmentDetails from "../../pages/parents/myEnrollmentDetails";
import MyEnrollmentEnd from "../../pages/parents/myEnrollmentEnd";
import ParentFamilyPage from "../../pages/parents/family-profile/familyProfile";
import ApplicationConfirmation from "../../pages/parents/newApplication/applicationConfirmation";
import MyEnrollmentsChange from "../../pages/parents/myEnrollmentsChange";
import Acceptance from "../../pages/parents/acceptance";
import PaymentSettings from "../../pages/parents/paymentSettings";
import TourRequests from "../../pages/parents/myTourRequests";
import NewsFeed from "../../pages/parents/newsFeed";
class ParentLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    this.pathPrefix = "/parents/";
    this.paths = [
      { path: "", component: Home },
      { path: "messages", component: Messaging },
      { path: "calendar", component: Calendar },
      { path: "family-profile", component: ParentFamilyPage },
      {
        path: "payment-settings",
        component: PaymentSettings,
        props: { showCC: true }
      },
      { path: "applications", component: Application },
      { path: "applications/:slug/:time", component: ApplicationView },
      { path: "account-settings", component: AccountSettings },
      { path: "interested-programs", component: InterestedPrograms },
      {
        path: "interested-programs/:slug/choose-child",
        component: ChooseChild
      },
      {
        path: "interested-programs/:slug/application",
        component: FileApplication
      },
      {
        path: "newsfeed",
        component: NewsFeed
      },
      {
        path: "interested-programs/:slug/confirm",
        component: ConfirmApplication
      },
      { path: "tuition-payments", component: TuitionPayments },
      {
        path: "tuition-payments/:invoice",
        component: TuitionPaymentsDetails
      },
      {
        path: "tuition-payments/deposit/:invoice",
        component: TuitionPaymentsDetails
      },
      {
        path: "tour-requests",
        component: TourRequests
      },
      { path: "my-enrollments", component: MyEnrollments },
      { path: "my-enrollments/view", component: MyEnrollmentDetails },
      { path: "my-enrollments/end", component: MyEnrollmentEnd },
      {
        path: "interested-programs/:slug/finalize",
        component: ApplicationConfirmation
      },
      { path: "my-enrollments/:slug/:time", component: MyEnrollmentsChange },
      { path: "acceptance/:slug/:time", component: Acceptance }
    ];
  }

  async componentDidMount() {
    if (localStorage.getItem("ds_token") && !this.global.dsUser) {
      const result = await this.dispatch.validateToken();
      if (!this.global.dsUser || this.global.dsUser.accountType !== "Parent") {
        this.props.history.push("/");
      }
    } else if (!localStorage.getItem("ds_token") && !this.global.dsUser) {
      this.props.history.push(
        `/sign-in?follow=${this.props.location.pathname}`
      );
    }
  }

  render() {
    const { path } = this.props.match;
    return (
      <>
        {this.global.dsUser ? (
          <>
            <Sidebar path={path} Menu={SideMenu} />
            {/* Content Wrapper */}

            <div
              id="content-wrapper"
              className="d-flex flex-column"
              onClick={() => this.setGlobal({ sidenavShown: false })}
            >
              {/* Main Content */}
              <div id="content">
                <TopBar ConsoleRoute="/parents" />
                {!this.global.noShow && (
                  <p style={{ margin: "10px 0px 0px 1rem" }}>
                    {this.global.pathway.map((e, i) =>
                      this.global.pathway.length - 1 > i ? `${e} -> ` : `${e}`
                    )}
                  </p>
                )}
                {this.paths.map((p, i) => (
                  <ParentRoute
                    parent={true}
                    exact
                    path={`${this.pathPrefix}${p.path}`}
                    component={p.component}
                    key={i}
                    {...p.props}
                  />
                ))}
              </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
              <i className="fas fa-angle-up" />
            </a>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default ParentLayout;
