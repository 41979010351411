import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton, Switch, rgbToHex } from "@material-ui/core";
import { formTypes } from "../UI/form";
import { Close, Delete, Create as Edit } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import "./baseModal/fieldComponents/Button/Button"
import { BUTTON, GET_HTML, CONTENT_BLOCK } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, RED_BUTTON, WHITE } from "./baseModal/colours";
import AddSkillsModal from "./AddSkillsModal";

class YesNoModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  requestTour = async e => {
    e.preventDefault();
  };

  render() {
    return (
      <div
        className="request-modal-cont base-modal-outer-wrapper"
      >
        <div
          id="base-modal-wrapper"
          className="base-modal-wrapper"
          style={{
            width: "330px",
            height: "145px"
          }}
          ref={(node) => {
            if (node) {
              node.style.setProperty("min-height", "145px", "important");
            }
          }}
        >
          <div id="base-modal-title">
            <div className="base-modal-title-wrapper">
              <h3 className="base-modal-title">Are you sure you want to delete this?</h3>
              <div onClick={() => {
                this.props.onClose();
              }}>
                <Close className="base-modal-exit-image" />
                {/* <img className="base-modal-exit-image" src={closeButton} alt="" /> */}
              </div>
            </div>
          </div>
          <div id="base-modal-buttons">
            <div id="base-modal-buttons">
              <div className="base-modal-buttons">
                <button
                  style={{
                    backgroundColor: RED_BUTTON,
                    color: WHITE,
                  }}
                  onClick={e => {
                    this.props.noClick();
                    this.props.onClose();
                  }}
                  className="base-modal-button"
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty("margin-top", "16px", "important");
                      node.style.setProperty("margin-bottom", "16px", "important");
                    }
                  }}
                  type={BUTTON}
                >
                  {"No"}
                </button>
                <button
                  style={{
                    backgroundColor: BLUE_BUTTON,
                    color: WHITE,
                  }}
                  onClick={e => {
                    this.props.yesClick();
                    this.props.onClose();
                  }}
                  className="base-modal-button"
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty("margin-top", "16px", "important");
                      node.style.setProperty("margin-bottom", "16px", "important");
                    }
                  }}
                  type={BUTTON}
                >
                  {"Yes"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class AddDomainModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...props.data,
      newDomains: [],
      deleteModal: null,
      manageDomain: null,
      editMode: true,
    };
  }

  componentDidMount() {
    const domains = this.state.domain;
    if (domains.length > 0) {
      this.setState({
        ...this.state,
        domain: domains,
        newDomains: [],
        deleteModal: null,
      })
    } else {
      this.setState({
        ...this.state,
        editMode: true,
        domain: domains,
        newDomains: [{ id: 0, name: "" }],
        deleteModal: null
      })
    }
    // this.fetchInitialData()
  }

  async fetchInitialData() {

    try {
      const ep = `${process.env.REACT_APP_API}/partner/observations/customization`;
      const results = await axios.get(ep);

      if (results.data.success) {
        const domains = results.data.data.domain

        if (domains.length > 0) {
          this.setState({
            ...this.state,
            domain: domains,
            skills: results.data.data.skills,
            newDomains: [],
            deleteModal: null,
            classification1: results.data.data.classification1,
            classification2: results.data.data.classification2
          })
        } else {
          this.setState({
            ...this.state,
            editMode: true,
            domain: domains,
            skills: results.data.data.skills,
            newDomains: [{ id: 0, name: "" }],
            deleteModal: null,
            classification1: results.data.data.classification1,
            classification2: results.data.data.classification2
          })
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async savePlan() {
    if (this.checkFields()) {
      const ep = `${process.env.REACT_APP_API}/partner/observations/customization/domain`;
      // const data = (this.state.newDomains.length <= 1 && this.state.newDomains[0].name === "") ?
      //   this.state.domain : [...this.state.domain, ...this.state.newDomains]
      const result = await axios.post(ep, { domains: [...this.state.domain, ...this.state.newDomains] })

      if (result.data.success) {
        toast.success("Saved Observation Settings");
        // this.setState({
        //   ...this.state,
        // editMode: false
        // })
        await this.fetchInitialData();
      } else {
        toast.error("Observation settings could not be saved")
      }
    } else {
      toast.error("Please fill in all fields")
    }
  }

  async savePlanBackground() {
    if (this.checkFields()) {
      const ep = `${process.env.REACT_APP_API}/partner/observations/customization/domain`;
      // const data = (this.state.newDomains.length <= 1 && this.state.newDomains[0].name === "") ?
      //   this.state.domain : [...this.state.domain, ...this.state.newDomains]
      const result = await axios.post(ep, { domains: [...this.state.domain, ...this.state.newDomains] })

      if (result.data.success) {
        // this.setState({
        //   ...this.state,
        // editMode: false
        // })
        await this.fetchInitialData();
      }
    }
  }

  // async nextButton() {
  //   if (this.checkFields()) {
  //     const ep = `${process.env.REACT_APP_API}/partner/observations/customization/domain`;
  //     const data = (this.state.newDomains.length <= 1 && this.state.newDomains[0].name === "") ?
  //       this.state.domain : [...this.state.domain, ...this.state.newDomains]
  //     const result = await axios.post(ep, { domains: data })

  //     if (result.data.success) {
  //       toast.success("Saved Observation Settings");
  //       this.props.onClose();
  //     } else {
  //       toast.error("Observation settings could not be saved")
  //     }
  //   } else {
  //     toast.error("Please fill in all fields")
  //   }
  // }

  checkFields = () => {
    const allDomains = [...this.state.domain, ...this.state.newDomains]

    for (let domain of allDomains) {
      if (domain.name === "") {
        return false
      }
    }
    // if (this.state.newDomains.length > 1) {
    //   this.state.newDomains.forEach((domain, index) => {
    //     if (domain.name === "") {
    //       return false
    //     }
    //   })
    // }
    return true
  }

  /// ================== NEW MODAL ========================== ///
  getModal = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: 9
          }}
        >
          <label
            htmlFor=""
            style={{
              width: "15%",
              textAlign: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262",
              marginTop: "1vh"
            }}
          >
            {this.props.data.classification1}
          </label>
          <div style={{ display: "block", width: "75%" }}>
            {this.state.domain.map((domain, i) => {
              return (
                <div
                  key={i}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start"
                  }}
                  name=""
                >
                  <div
                    style={{
                      width: "90%",
                      display: "block",
                      alignSelf: "flex-start"
                    }}
                  >
                    <input
                      style={{
                        height: "40px",
                        width: "100%",
                        fontSize: "0.93rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "300",
                        color: "#494949",
                        border: "1px solid #c5c5c5",
                        borderRadius: "4px",
                        paddingLeft: "8px"
                      }}
                      // disabled={!this.state.editMode}
                      disabled={true}
                      name={domain.id}
                      id={domain.id}
                      type="text"
                      onChange={e => {
                        const domains = [...this.state.domain]
                        domains[i].name = e.target.value
                        this.setState({
                          ...this.state,
                          domain: domains
                        })
                      }}
                      value={domain.name}
                    />
                  </div>
                  {/* {this.state.editMode && ( */}
                  <div
                    style={{
                      cursor: "pointer",
                      color: "#9BA7B2",
                      marginLeft: "20px"
                    }}
                    onClick={async () => {
                      await this.savePlanBackground();
                      this.setState({
                        manageDomain: domain
                      })
                      // if (this.state.domain.length <= 1) {
                      //   return;
                      // }
                      // const domains = this.state.domain.filter((domain, index) => {
                      //   return i !== index
                      // })
                      // this.setState({
                      //   ...this.state,
                      //   domain: domains
                      // })
                    }}
                  >
                    <Edit color="action" fontSize="large" />
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      color: "#9BA7B2",
                      marginLeft: "20px"
                    }}
                    onClick={() => {
                      this.setState({
                        deleteModal: {
                          noClick: () => {

                          },
                          yesClick: () => {
                            console.log("TEMP", this.state.skills[domain.id].length)
                            if (this.state.skills[domain.id].length > 0) {
                              toast.error("Please Remove all Sub-Classifications first")
                              return;
                            }
                            const domains = this.state.domain.filter((domain, index) => {
                              return i !== index
                            })
                            this.setState({
                              ...this.state,
                              domain: domains
                            })
                          },
                          onClose: () => {
                            this.setState({
                              deleteModal: null
                            })
                          }
                        }
                      })

                      // console.log("TEMP", this.state.skills[domain.id].length)
                      // if (this.state.skills[domain.id].length > 0) {
                      //   toast.error("Please Remove all Sub-Classifications first")
                      //   return;
                      // }
                      // const domains = this.state.domain.filter((domain, index) => {
                      //   return i !== index
                      // })
                      // this.setState({
                      //   ...this.state,
                      //   domain: domains
                      // })
                    }}
                  >
                    <Close color="action" fontSize="large" />
                  </div>
                  {/* )} */}
                </div>
              );
            })}
            {this.state.editMode && (
              <>
                {this.state.newDomains.map((domain, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start"
                      }}
                      name=""
                    >
                      <div
                        style={{
                          width: "90%",
                          display: "block",
                          alignSelf: "flex-start"
                        }}
                      >
                        <input
                          style={{
                            height: "40px",
                            width: "100%",
                            fontSize: "0.93rem",
                            fontFamily: "futura-pt, sans-serif",
                            fontWeight: "300",
                            color: "#494949",
                            border: "1px solid #c5c5c5",
                            borderRadius: "4px",
                            paddingLeft: "8px"
                          }}
                          name={domain.id}
                          id={domain.id}
                          type="text"
                          onChange={e => {
                            const newDomains = [...this.state.newDomains]
                            newDomains[i].name = e.target.value
                            this.setState({
                              ...this.state,
                              newDomains: newDomains
                            })
                          }}
                          value={domain.name}
                        />
                      </div>
                      <div
                        style={{
                          cursor: "pointer",
                          color: "#9BA7B2",
                          marginLeft: "20px"
                        }}
                        onClick={() => {
                          this.setState({
                            deleteModal: {
                              noClick: () => {

                              },
                              yesClick: () => {
                                if (this.state.newDomains.length <= 1 && this.state.domain.length === 0) {
                                  return;
                                }
                                const newDomains = this.state.newDomains.filter((domain, index) => {
                                  return i !== index
                                })
                                this.setState({
                                  ...this.state,
                                  newDomains: newDomains
                                })
                              },
                              onClose: () => {
                                this.setState({
                                  deleteModal: null
                                })
                              }
                            }
                          })

                        }}
                      >
                        <Close color="action" fontSize="large" />
                      </div>
                      <div
                        style={{
                          visibility: "hidden",
                          color: "#9BA7B2",
                          marginLeft: "20px"
                        }}
                      >
                        <Close color="action" fontSize="large"/>
                      </div>
                    </div>
                  );
                })}
                {/* <div
                style={{
                  width: "60%",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "right",
                  padding: "2px 0",
                  marginTop: "10px",
                  fontSize: "0.7em",
                  fontFamily: "futura-pt, sans-serif",
                  fontWeight: "500",
                  color: "#797979",
                  marginLeft: "50px"
                }}
                onClick={() => {
                  if (this.state.newDomains.length > 0) {
                    const newDomains = [...this.state.newDomains]
                    newDomains.push({ id: newDomains[newDomains.length - 1].id + 1, name: "" })
                    this.setState({
                      ...this.state,
                      newDomains: newDomains
                    })
                  } else {
                    const newDomains = [...this.state.newDomains]
                    newDomains.push({ id: this.state.domain[this.state.domain.length - 1].id + 1, name: "" })
                    this.setState({
                      ...this.state,
                      newDomains: newDomains
                    })
                  }
                }}
              >
                + Add
              </div> */}
              </>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "flex-start",
            marginBottom: 0
          }}
        >
          <button
            style={{
              backgroundColor: "#5cc8dc",
              color: WHITE,
              marginRight: 50
            }}
            className="button-field-button"
            onClick={e => {
              if (this.state.newDomains.length > 0) {
                const newDomains = [...this.state.newDomains]
                newDomains.push({ id: newDomains[newDomains.length - 1].id + 1, name: "" })
                this.setState({
                  ...this.state,
                  newDomains: newDomains
                })
              } else {
                const newDomains = [...this.state.newDomains]
                newDomains.push({ id: this.state.domain[this.state.domain.length - 1].id + 1, name: "" })
                this.setState({
                  ...this.state,
                  newDomains: newDomains
                })
              }
            }}
            type={BUTTON}
          >
            {"+ Add"}
          </button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "flex-start",
            marginBottom: 0
          }}
        >
          <div
            className="base-modal-field"
            style={{
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            <div>
              <p
                className="field-component-small-text"
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  marginLeft: 0,
                  marginRight: 50,
                  fontSize: 13,
                  fontStyle: 'italic'
                }}
              >
                Once you have added the {this.props.data.classification1}, make sure to click on the <b>pencil icon</b> to add the sub-categories of {this.props.data.classification2}
                {/* {content}{" "}
                {historyUrl && (
                  <>
                    (See{" "}
                    <span
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        historyUrl();
                      }}
                    >
                      History
                    </span>
                    )
                  </>
                )} */}
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            marginBottom: 0
          }}
        >
          <button
            onClick={async e => {
              await this.savePlan();
              // handleClickAndValidate(handleClick, validatorKey, e)
            }}
            className="base-modal-button"
            style={{
              backgroundColor: BLUE_BUTTON,
              color: WHITE,
              maxWidth: "175px"
            }}
            type={BUTTON}
          >
            {"Save Edit"}
          </button>
        </div>
      </>
    )
  }

  getBaseModalFields = () => {
    const modal = {
      type: GET_HTML,
      data: {
        gethtml: this.getModal
      }
    };

    const fields = [];
    fields.push(modal);

    return fields;
  }

  getBaseModalButtons = () => {
    const backButton = {
      name: "Back",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: async _ => {
        if (!this.checkFields()) {
          toast.error("Please finish making your changes")
          return;
        }
        await this.props.backPage();
      }
    }
    // const nextButton = {
    //   name: "Next",
    //   buttonColour: BLUE_BUTTON,
    //   textColour: WHITE,
    //   validatorKey: "validateSubmit",
    //   handleClick: _ => {
    //     if (this.state.editMode) {
    //       toast.error("Please finish making your changes")
    //       return;
    //     }
    //     this.props.nextPage();
    //   }
    // }

    const submitButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: async _ => {
        if (!this.checkFields()) {
          toast.error("Please finish making your changes")
          return;
        }
        await this.savePlan();
        this.props.onClose();
      }
    }

    console.log(this.props);
    if (this.props.data.classification2 === "") {
      return [backButton];
    }

    return [backButton, submitButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Add Classifications",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ========================== ///

  render() {
    return (
      <>
        <BaseModal {...this.getBaseModalProps()} />;
        {this.state.deleteModal && (
          <YesNoModal
            yesClick={this.state.deleteModal.yesClick}
            noClick={this.state.deleteModal.noClick}
            onClose={this.state.deleteModal.onClose}
          />
        )}
        {this.state.manageDomain && (
          <>
            <AddSkillsModal
              // data={this.state.data}
              manageDomain={this.state.manageDomain}
              onClose={async () => {
                await this.fetchInitialData()
                this.setState({
                  manageDomain: null
                })
                // this.props.onClose();
              }}
              backPage={async () => {
                await this.fetchInitialData()
                this.setState({
                  manageDomain: null
                })
              }}
            />
          </>
        )}
      </>
    )
  }
}

export default withRouter(AddDomainModal);
