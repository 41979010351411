import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { DateTime } from "luxon";
import { Delete, SettingsBackupRestore as Restore } from "@material-ui/icons";
import LinkIcon from "@material-ui/icons/Link";
import AddIframeModal from "../../components/modals/AddIframeModal";
import CreateOnlineVirtualModal from "../../components/modals/CreateOnlineVirtualModal";
import PreviewOnlineListingsModal from "../../components/modals/PreviewOnlineListingsModal";
import CopyExistingOnlineVirtualModal from "../../components/modals/CopyExistingOnlineVirtualModal";
import Switch from "@material-ui/core/Switch";
import ReactTooltip from "react-tooltip";
import ConfirmArchiveModal from "../../components/modals/ConfirmArchiveModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import ViewArchivedOnlineDetailsModal from "../../components/modals/ViewArchivedOnlineDetailsModal";

const styles = {
  filter: {
    height: "165px",
    "& .MuiList-padding": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important"
    }
  },
  filterArchived: {
    height: "130px",
    "& .MuiList-padding": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important"
    }
  },
  checkbox: {
    minWidth: "32px",
    "& .MuiListItemIcon-root": {
      minWidth: "40px"
    }
  }
};
class OnlineVirtual extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      data: { Active: [], Archive: [] },
      allData: [],
      onlineVirtualCount: { Active: [], Archive: [] },
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      openCreateOnlineVirtualModal: false,
      openPreviewOnlineListingsModal: false,
      openCopyExistingProgramModal: false,
      showConfirmModal: false,
      showConfirmHideModal: false,
      toArchive: true,
      toHide: true,
      idToEdit: 0,
      cancelAllApplications: false,
      filterListChecked: [],
      isPreview: true,
      isName1: false,
      isDate1: false,
      isDate2: false,
      currTab: 0,
      previewOrder: [],
      previewUpdated: false,
      page: -1
    };

    this.tabs = ["Active", "Archive"];

    this.actions = [{ name: "Notes", action: null }];
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Online Virtual", to: "/online" }
      ]
    });

    this.handleFilterListToggle = this.handleFilterListToggle.bind(this);
    this.filterListLabels = [
      "Preview Order",
      "Name (A to Z)",
      "Created Date (new to old)",
      "Update Date (new to old)"
    ];
  }

  getTimeZone = () => {
    const tz = this.global.organizationInfo.timezone;
    return DateTime.now().setZone(tz).toFormat("ZZZZ");
  }

  headings = () => {
    return {
      Active: [
        {
          id: "pic",
          label: "",
          customCell: (r, i) => (
            <td key={i} style={{ whiteSpace: "nowrap" }}>
              <LazyLoadImage
                src={`${process.env.REACT_APP_DS_FILES_S3 + "/" + r.program_photo
                  }`}
                alt="Online"
                style={{
                  width: "130px",
                  height: "78px",
                  borderRadius: "7px",
                  objectFit: "cover"
                }}
                effect="opacity"
              />
            </td>
          )
        },
        { id: "program_name", label: "Program Name" },
        { id: "program_category", label: "Program Type" },
        {
          id: "status",
          label: "Publish",
          customCell: (r, i) => {
            return (
              <td key={i}>
                <Switch
                  checked={r.published}
                  size="small"
                  onChange={_ => {
                    this.updatePublishStatus(r);
                  }}
                  disabled={
                    this.canSubUserEdit() ||
                    !r.program_category ||
                    !r.program_name ||
                    !r.description ||
                    r.program_color === null ||
                    (!r.number_of_weeks &&
                      !r.isRecurring &&
                      r.program_type != 0) ||
                    !r.days_of_week ||
                    Object.keys(r.days_of_week).length === 0 ||
                    r.plans.filter(plan => {
                      let ret = false;
                      const { active, archived } = JSON.parse(plan);
                      if ((active === undefined || active) && !archived)
                        ret = true;
                      return ret;
                    }).length === 0 ||
                    r.formId === null ||
                    r.times == [-1, -1] ||
                    !r.maximum_participants
                  }
                  color="primary"
                />
              </td>
            );
          }
        },
        {
          id: "updated",
          label: `Updated (${this.getTimeZone()})`,
          customCell: r => {
            const tz = this.global.organizationInfo.timezone;
            const startZonedTimeDisplay = format(
              utcToZonedTime(r.updatedAt, tz),
              "LLL dd, yyyy - h:mm a"
            );

            return (
              <td key={`${r.id}-start`}>
                { startZonedTimeDisplay }
              </td>
            );
          }
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <div className="action-group">
                  <button
                    style={{
                      marginRight: "15px",
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    disabled={this.canSubUserEdit()}
                    onClick={_ =>
                      this.props.history.push(
                        `/partners/online-virtual/setup/${r.id}`
                      )
                    }
                  >
                    Edit
                  </button>
                  <LinkIcon
                    style={{
                      transform: "rotate(130deg)",
                      marginRight: "10px",
                      color: "darkgrey"
                    }}
                    onClick={_ =>
                      this.setState({
                        iframeSetUp: true,
                        selectedIframe: r.id,
                        type: "online"
                      })
                    }
                    className="hovermyX"
                  />
                  <i
                    className="fas fa-clipboard icon"
                    style={{ marginRight: "10px" }}
                  />
                  {!this.canSubUserEdit() && (
                    <>
                      <Delete
                        onClick={() => {
                          this.setState({
                            showConfirmModal: true,
                            idToEdit: r.id,
                            toArchive: true
                          });
                        }}
                        data-for="archive"
                        data-tip
                      />
                      <ReactTooltip
                        id="archive"
                        place="top"
                        effect="solid"
                        type="info"
                      >
                        Archive Online Virtual
                      </ReactTooltip>
                    </>
                  )}
                </div>
              </td>
            );
          }
        }
      ],
      Archive: [
        {
          id: "pic",
          label: "",
          customCell: (r, i) => (
            <td key={i} style={{ whiteSpace: "nowrap" }}>
              <LazyLoadImage
                src={`${process.env.REACT_APP_DS_FILES_S3 + "/" + r.program_photo
                  }`}
                alt="Online"
                style={{
                  width: "130px",
                  height: "78px",
                  borderRadius: "7px",
                  objectFit: "cover"
                }}
                effect="opacity"
              />
            </td>
          )
        },
        { id: "program_name", label: "Program Name" },
        { id: "program_category", label: "Program Type" },
        {
          id: "status",
          label: "Publish",
          customCell: (r, i) => {
            return (
              <td key={i}>{r.published ? "Published" : "Not Published"}</td>
            );
          }
        },
        {
          id: "updated",
          label: `Updated (${this.getTimeZone()})`,
          customCell: row => {
            const tz = this.global.organizationInfo.timezone;
            const startZonedTimeDisplay = format(
              utcToZonedTime(row.updatedAt, tz),
              "LLL dd, yyyy - h:mm a"
            );

            return (
              <td key={`${row.id}-start`}>
                { startZonedTimeDisplay }
              </td>
            );
          }
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <div className="action-group">
                  <button
                    style={{
                      marginRight: "15px",
                      cursor: "pointer"
                    }}
                    onClick={_ => this.setState({ 
                      openViewArchivedModal: true,
                      idToEdit: r.id
                    })
                    }
                  >
                    View
                  </button>
                  {!this.canSubUserEdit() && this.showCreateModalButton() && (
                    <>
                      <Restore
                        onClick={() => {
                          this.setState({
                            showConfirmModal: true,
                            idToEdit: r.id,
                            toArchive: false
                          });
                        }}
                        style={{ color: "#BBB" }}
                        data-for="restore"
                        data-tip
                      />
                      <ReactTooltip
                        id="restore"
                        place="top"
                        effect="solid"
                        type="info"
                      >
                        Restore Online Virtual
                      </ReactTooltip>
                    </>
                  )}
                  {!this.canSubUserEdit() && this.showCreateModalButton() && (
                    <>
                      <Delete
                        onClick={() => {
                          this.setState({
                            showConfirmHideModal: true,
                            idToEdit: r.id,
                            toArchive: true,
                            toHide: true,
                            cancelAllApplications: !!this.state.customers.find(
                              customer => {
                                return (
                                  customer.programId === r.id &&
                                  (customer.status_stage ===
                                    "Accepted_Unpaid" ||
                                    customer.status_stage === "New_Pending" ||
                                    customer.status_stage ===
                                    "Accepted_Manual" ||
                                    customer.status_stage ===
                                    "Waitlisted_Unpaid")
                                );
                              }
                            )
                          });
                        }}
                        data-for="hidden"
                        data-tip
                      />
                      <ReactTooltip
                        id="hidden"
                        place="top"
                        effect="solid"
                        type="info"
                      >
                        Delete Program
                      </ReactTooltip>
                    </>
                  )}
                </div>
              </td>
            );
          }
        }
      ]
    };
  };

  updatePublishStatus = async r => {
    const ep = `${process.env.REACT_APP_API}/partners/virtual/publish`;
    const results = await axios.post(ep, {
      id: r.id,
      published: !r.published
    });

    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  /**
   * This function handles the edit button functionality of a sub user
   * Note: Returns TRUE if the user CANNOT edit (it's flipped!)
   */
  canSubUserEdit = () => {
    return !(
      !this.global.dsUser.sub_user ||
      this.global?.dsUser?.accesspoints?.Listings?.["Online Virtual"]?.edit
    );
  };

  /**
   * Makes request to backend to archive/unarchive online virtual.
   * Uses state to determine options (what to delete, archive/unarchive).
   */
  archiveOnline = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/virtual/cancel/${this.state.idToEdit}`;
    const results = await axios.post(ep)
    const ep2 = `${process.env.REACT_APP_API}/partners/virtual/delete/${this.state.idToEdit}`;
    const results2 = await axios.post(ep2, { toArchive: this.state.toArchive });

    if (results.data.success && results2.data.success) {
      this.fetchInitialData();
    }
  };

  /**
   * Makes request to backend to hide online virtual.
   * Looks like a permanent deletion, but is only hidden from the partner's view.
   * Program still stored in database.
   */
  hideOnline = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/virtual/hide/${this.state.idToEdit}`;
    const results = await axios.post(ep, { toHide: this.state.toHide });

    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  cancelOVApplications = async () => {
    let OVCustomerArr = [];

    this.state.customers.forEach(customer => {
      if (customer.programId === this.state.idToEdit) {
        if (
          customer.status_stage === "Accepted_Unpaid" ||
          customer.status_stage === "New_Pending" ||
          customer.status_stage === "Waitlisted_Unpaid" ||
          customer.status_stage === "Accepted_Manual"
        ) {
          OVCustomerArr.push({
            id: customer.id,
            programId: customer.programId
          });
        }
      }
    });

    console.log("OV CUSTOMERS", OVCustomerArr);

    const ep = `${process.env.REACT_APP_API}/partners/virtual/cancel-application/${this.state.idToEdit}`;
    const results = await axios.post(ep, { OVCustomerArr });
  };

  async componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    this.setGlobal({ loading: true });

    let tab = "active";
    if (this.state.currTab === 0) {
      tab = "active";
    } else if (this.state.currTab === 1) {
      tab = "archived";
    } else {
      console.log("NO TAB FOUND");
    }

    const ep = `${process.env.REACT_APP_API}/partners/virtual/list/${tab}`;
    console.time("initial_results_fetch");
    const results = await axios.get(ep);
    console.timeEnd("initial_results_fetch");

    const online_virtual_count_ep = `${process.env.REACT_APP_API}/partners/virtual/list/count`;
    console.time("initial_count_fetch");
    const online_virtual_count_results = await axios.get(
      online_virtual_count_ep
    );
    console.timeEnd("initial_count_fetch");

    const onlineVirtualCount = {
      Active: online_virtual_count_results.data.data.filter(
        e => !e.isArchived && !e.isHidden
      ),
      Archive: online_virtual_count_results.data.data.filter(
        e => e.isArchived && !e.isHidden
      )
    };
    console.log("ONLINE VIRTUAL COUNT", onlineVirtualCount);

    // Get customers to check if events have any enrollments.
    const customers_ep = `${process.env.REACT_APP_API}/partners/virtual/customers/active/${this.global.organizationInfo.partnerId}`;
    console.time("initial_online-virtual_customers_fetch");
    const customers = await axios.get(customers_ep);
    console.timeEnd("initial_online-virtual_customers_fetch");

    const previewOrderEp = `${process.env.REACT_APP_API}/partners/virtual/list/active`;
    const previewOrderResults = await axios.get(previewOrderEp);

    /* 
     if (results.data.success) {
      this.setState({
        data: {
          Active: results.data.data
            .filter(f => !f.isArchived)
            .map(e => ({ ...e, status: "Published" }))
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
          Archive: results.data.data
            .filter(f => f.isArchived)
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        },
        customers: customers.data.data
      });
    } 
 */

    if (results.data.success) {
      this.setState({
        allData: results.data.data
          .filter(f => !f.isArchived)
          .map(e => ({ ...e, status: "Published", classification: "active" }))
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .concat(
            results.data.data
              .filter(f => f.isArchived)
              .map(e => ({ ...e, classification: "archive" }))
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          ),
        onlineVirtualCount: onlineVirtualCount,
        customers: customers.data.data,
        previewOrder: previewOrderResults.data.data
          .filter(f => !f.isArchived && !f.isHidden && f.published)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .sort((a, b) => a.display_order - b.display_order)
      });
    }

    const permissions = await this.getProgramPermissionsCount();
    this.setState({ programPermissions: permissions });
    this.setGlobal({ loading: false });
  };

  getProgramPermissionsCount = async () => {
    const feature_name = "online-virtual";
    const ep = `${process.env.REACT_APP_API}/partner/permissions/feature/${feature_name}`;
    const resp = await axios.get(ep);

    if (resp.data) {
      return resp.data.data;
    }
  };

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
    if (results.data.success) {
      toast.success(`Partner Accepted`);
      this.componentDidMount();
    } else {
      toast.error(`Error, this person already signed-up!`);
    }
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  // To refresh the form page for a new program card
  async resetEvents() {
    await this.dispatch.resetEvent();
  }

  showCreateModalButton = () => {
    const { data, programPermissions } = this.state;
    if (data.Active && programPermissions) {
      return (
        programPermissions.has_access &&
        programPermissions.featureLimit > data.Active.length
      );
    }
    return false;
  };

  handleClose() {
    this.setState({
      popoverAnchorE1: null
    });
  }

  handleFilterClick(event) {
    this.setState({
      popoverAnchorE1: event.currentTarget
    });
  }

  handleFilterListToggle(value) {
    const currentIndex = this.state.filterListChecked.indexOf(value);
    const newChecked = [...this.state.filterListChecked];

    if (currentIndex === -1 && this.state.filterListChecked.length === 0) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      newChecked.push(value);
    }

    const isPreview = value == "Preview Order";
    const isName1 = value == "Name (A to Z)";
    const isDate1 = value == "Created Date (new to old)";
    const isDate2 = value == "Update Date (new to old)";
    const isCat1 = value == "Category (A to Z)";

    this.setState({
      filterListChecked: newChecked,
      isPreview: localStorage.setItem("isPreview", isPreview) || isPreview,
      isName1: localStorage.setItem("isName1", isName1) || isName1,
      isDate1: localStorage.setItem("isDate1", isDate1) || isDate1,
      isDate2: localStorage.setItem("isDate2", isDate2) || isDate2
    });
  }

  pipeData(allData) {
    if (allData.length > 0) {
      return {
        Active: allData.filter(e => e.classification === "active"),
        Archive: allData.filter(
          e => e.classification === "archive" && e.isHidden === false
        )
      };
    } else {
      return {
        Active: [],
        Archive: []
      };
    }
  }

  filterData = () => {
    let data = this.state.allData;

    // Filter programs by filter bar
    if (this.state.filter && this.state.filter !== "") {
      data = data.filter(program => {
        const programName = program.program_name
          .toLowerCase()
          .replace(/\s/g, "");
        const filterText = this.state.filter.toLowerCase().replace(/\s/g, "");
        return programName.includes(filterText);
      });
    }

    // Filter by Preview Order
    if (this.state.isPreview) {
      const currentOrder = data
        .filter(f => !f.isArchived && !f.isHidden && f.published)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .sort((a, b) => a.display_order - b.display_order);
      const filteredData = data
        .filter(f => f.isArchived || f.isHidden || !f.published)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      data = [...currentOrder, ...filteredData];
    }

    // Filter by Created Date (New to Old)
    if (this.state.isDate1) {
      data.sort((a, b) => {
        if (new Date(b.createdAt) < new Date(a.createdAt)) {
          return -1;
        }
        if (new Date(b.createdAt) > new Date(a.createdAt)) {
          return 1;
        }
        return 0;
      });
    }
    // Filter by Updated Date (New to Old)
    if (
      this.state.isDate2 ||
      (this.state.isPreview && this.state.currTab === 1)
    ) {
      data.sort((a, b) => {
        if (new Date(b.updatedAt) < new Date(a.updatedAt)) {
          return -1;
        }
        if (new Date(b.updatedAt) > new Date(a.updatedAt)) {
          return 1;
        }
        return 0;
      });
    }
    // Filter by Program Name (A to Z)
    if (this.state.isName1) {
      data.sort((a, b) =>
        a.program_name > b.program_name
          ? 1
          : b.program_name > a.program_name
            ? -1
            : 0
      );
    }

    return {
      ...this.pipeData(data)
    };
  };

  filterDataCount() {
    let dataCount = { ...this.state.onlineVirtualCount };
    if (this.state.filter && this.state.filter !== "") {
      dataCount["Active"] = dataCount["Active"].filter(program => {
        const programName = program.program_name
          .toLowerCase()
          .replace(/\s/g, "");
        const filterText = this.state.filter.toLowerCase().replace(/\s/g, "");
        return programName.includes(filterText);
      });
      dataCount["Archive"] = dataCount["Archive"].filter(program => {
        const programName = program.program_name
          .toLowerCase()
          .replace(/\s/g, "");
        const filterText = this.state.filter.toLowerCase().replace(/\s/g, "");
        return programName.includes(filterText);
      });
    }

    return dataCount;
  }

  async getCurrTabData() {
    this.setGlobal({ loading: true });

    let tab = "active";
    if (this.state.currTab === 0) {
      tab = "active";
    } else if (this.state.currTab === 1) {
      tab = "archived";
    } else {
      console.log("NO TAB FOUND");
    }

    const ep = `${process.env.REACT_APP_API}/partners/virtual/list/${tab}`;
    console.time("results_fetch");
    const results = await axios.get(ep);
    console.timeEnd("results_fetch");

    // Get customers to check if events have any enrollments.
    const customers_ep = `${process.env.REACT_APP_API}/partners/virtual/customers/active/${this.global.organizationInfo.partnerId}`;
    console.time("online-virtual_customers_fetch");
    const customers = await axios.get(customers_ep);
    console.timeEnd("online-virtual_customers_fetch");

    if (results.data.success) {
      this.setState({
        allData: results.data.data
          .filter(f => !f.isArchived)
          .map(e => ({ ...e, status: "Published", classification: "active" }))
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .concat(
            results.data.data
              .filter(f => f.isArchived)
              .map(e => ({ ...e, classification: "archive" }))
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          ),
        customers: customers.data.data
      });
    }

    this.setGlobal({ loading: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.currTab !== this.state.currTab) {
      this.getCurrTabData();
      if (this.state.currTab === 0) {
        this.setState({
          isPreview: true,
          isDate1: false,
          isDate2: false,
          isName1: false
        });
        this.filterListLabels = [
          "Preview Order",
          "Name (A to Z)",
          "Created Date (new to old)",
          "Update Date (new to old)"
        ];
      } else {
        this.setState({
          isPreview: false,
          isDate1: false,
          isDate2: true,
          isName1: false
        });
        this.filterListLabels = [
          "Name (A to Z)",
          "Created Date (new to old)",
          "Update Date (new to old)"
        ];
      }
    }
  }

  togglePreviewUpdated() {
    this.setState({
      previewUpdated: false,
      currTab: 0,
      isPreview: true,
      isDate1: false,
      isDate2: false,
      isName1: false
    });
  }

  render() {
    console.log("this.state", this.state);
    return (
      <div className="admin">
        {this.state.openCreateOnlineVirtualModal && (
          <CreateOnlineVirtualModal
            onClose={_ =>
              this.setState({ openCreateOnlineVirtualModal: false })
            }
            history={this.props.history}
          />
        )}
        {this.state.openPreviewOnlineListingsModal && (
          <PreviewOnlineListingsModal
            onClose={() =>
              this.setState({ openPreviewOnlineListingsModal: false })
            }
            onOrderSave={newOrder => {
              this.setState({ previewOrder: newOrder, previewUpdated: true });
              this.fetchInitialData();
            }}
            history={this.props.history}
            programs={this.state.previewOrder}
          />
        )}
        {this.state.openCopyExistingProgramModal && (
          <CopyExistingOnlineVirtualModal
            onClose={() => {
              this.setState({ openCopyExistingProgramModal: false })
              this.fetchInitialData();
            }}
          />
        )}
        {this.state.iframeSetUp && (
          <AddIframeModal
            iframe={this.state.selectedIframe}
            open={this.state.iframeSetUp}
            type={this.state.type}
            active={this.state.active}
            onClose={() =>
              this.setState({
                iframeSetUp: false,
                selectedIframe: null,
                type: null,
                active: null
              })
            }
          />
        )}
        {this.state.showConfirmModal && (
          <ConfirmArchiveModal
            onSubmit={() => {
              this.archiveOnline();
            }}
            onClose={_ => this.setState({ showConfirmModal: false })}
            toArchive={this.state.toArchive}
            modalBody={ this.state.toArchive
              ? "UPDATE: This functionality has been updated. " +
              "It will automatically deactivate all enrollments, and archive the online virtual listing. " +
              "All the program data will still be saved. " +
              "Please ensure you want to archive this online virtual listing. "
              : ""
            }
            buttonName={this.state.toArchive ? "Archive" : undefined}
            notAllowed={false}
          />
        )}
        {this.state.showConfirmHideModal && (
          <ConfirmArchiveModal
            onSubmit={() => {
              if (this.state.cancelAllApplications) {
                // Cancel all applications and then hide the OV
                this.cancelOVApplications()
                  .then(() => this.hideOnline())
                  .catch(error => console.log(error));
              } else {
                // Simply hide the OV
                this.hideOnline();
              }
            }}
            onClose={_ => this.setState({ showConfirmHideModal: false })}
            toArchive={this.state.toArchive}
            isPermanent={this.state.toHide}
            notAllowed={false}
            cancelApps={this.state.cancelAllApplications}
          />
        )}
        {this.state.openViewArchivedModal && (
          <ViewArchivedOnlineDetailsModal
          program={this.filterData()["Archive"].filter(data => data.id === this.state.idToEdit)[0]}
          onClose={_ => this.setState({ openViewArchivedModal: false })}
        />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div
              className="col-md-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div style={{ display: "block" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "-30px"
                  }}
                >
                  <h1 style={{ marginRight: "30px" }}>Online Virtual </h1>
                  <LinkIcon
                    style={{
                      transform: "scale(1.5) rotate(130deg)",
                      cursor: "pointer",
                      marginRight: "10px",
                      marginTop: "0.2px",
                      color: "black"
                    }}
                    onClick={_ =>
                      this.setState({
                        iframeSetUp: true,
                        active: "Online"
                      })
                    }
                    className="hovermyX"
                  />
                </div>
                <div
                  style={{ marginLeft: "-6px", marginTop: "4px" }}
                  className="switch-tooltip-group"
                >
                  <Switch
                    checked={
                      !!this.global.organizationInfo.display_online_listings
                    }
                    size="small"
                    onChange={_ => {
                      this.setGlobal({
                        organizationInfo: {
                          ...this.global.organizationInfo,
                          display_online_listings:
                            !this.global.organizationInfo
                              .display_online_listings
                        }
                      });
                      this.dispatch.saveOrganizationalProfile();
                    }}
                    color="primary"
                    disabled={this.canSubUserEdit()}
                  />
                  <i
                    data-tip
                    data-for="view-online-toggle"
                    className="fas fa-info-circle"
                    style={{ color: "#DCDCDC" }}
                  ></i>
                  <ReactTooltip
                    id="view-online-toggle"
                    place="right"
                    effect="solid"
                    type="info"
                  >
                    Toggles online virtual tab visibility for users
                  </ReactTooltip>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  <button
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                    style={{
                      cursor: this.canSubUserEdit()
                        ? "not-allowed"
                        : "pointer",
                      paddingLeft: "20px"
                    }}
                    onClick={_ => {
                      this.setState({
                        openCopyExistingProgramModal: true
                      });
                    }}
                  >
                    Copy Existing
                  </button>
                </div>
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  <button
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      this.setState({ openPreviewOnlineListingsModal: true });
                    }}
                  >
                    Order
                  </button>
                </div>
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  <button
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      this.setState({ openCreateOnlineVirtualModal: true });
                      this.resetEvents();
                    }}
                  // onClick={_ =>
                  //   this.props.history.push("/partners/online-virtual/setup")
                  // }
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Create New
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row cont">
            <div className="col-md-12">
              <div className="forbtnwrap justify-end">
                {/*  ======================== Start of Filter Button ====================== */}
                <button
                  className="newapplications"
                  style={{
                    border: "1px solid #ccc",
                    padding: "4px 20px",
                    borderRadius: "4px",
                    display: "block",
                    background: "#ccc",
                    fontWeight: "700",
                    color: "white"
                  }}
                  aria-describedby={
                    Boolean(this.state.popoverAnchorE1)
                      ? "simple-popover"
                      : undefined
                  }
                  onClick={this.handleFilterClick.bind(this)}
                >
                  Filter
                </button>
                <Popover
                  id={
                    Boolean(this.state.popoverAnchorE1)
                      ? "simple-popover"
                      : undefined
                  }
                  open={Boolean(this.state.popoverAnchorE1)}
                  anchorEl={this.state.popoverAnchorE1}
                  onClose={this.handleClose.bind(this)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                >
                  <List
                    className={
                      this.state.currTab === 0
                        ? this.props.classes.filter
                        : this.props.classes.filterArchived
                    }
                    disablePadding={false}
                  >
                    {this.filterListLabels.map((value, i) => {
                      const labelId = `checkbox-list-label-${i}`;
                      return (
                        <ListItem
                          key={value}
                          role={undefined}
                          dense
                          button
                          onClick={_ => {
                            this.handleFilterListToggle(value);
                          }}
                        >
                          <ListItemIcon
                            classes={{ root: this.props.classes.checkbox }}
                          >
                            <Checkbox
                              name={value}
                              edge="start"
                              checked={
                                value === "Preview Order"
                                  ? this.state.isPreview
                                  : value === "Name (A to Z)"
                                    ? this.state.isName1
                                    : value === "Created Date (new to old)"
                                      ? this.state.isDate1
                                      : value === "Update Date (new to old)"
                                        ? this.state.isDate2
                                        : false
                              }
                              disabled={
                                !this.filterListLabels.filter(f => f == value)
                              }
                              tabIndex={-1}
                              disableRipple
                              primary
                              color="primary"
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={value} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Popover>
                {/*  ======================== End of Filter Button ====================== */}
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="tablecont">
              <div
                style={{
                  display: "inline-flex",
                  flex: "1 1",
                  border: "none",
                  borderRadius: "10px",
                  overflow: "hidden",
                  width: "100%"
                }}
              >
                <i
                  className="fas fa-search"
                  style={{
                    padding: "8px",
                    background: "white",
                    marginTop: "10px"
                  }}
                />
                <input
                  type="text"
                  placeholder="Filter by program name"
                  style={{
                    border: "none",
                    fontSize: "13px",
                    padding: "5px",
                    outline: 0,
                    background: "white",
                    flex: 1,
                    marginTop: "10px"
                  }}
                  onChange={e => this.setState({ 
                    filter: e.target.value,
                    page: 0 })}
                />
              </div>
              <div className="table-responsive">
                <NewTabbedTable
                  tabs={this.tabs}
                  headings={this.headings()}
                  //data={this.state.data}
                  data={this.filterData()}
                  dataCount={this.filterDataCount()}
                  actions={this.actions}
                  page={"Listings>OnlineVirtual"}
                  isFirstTab={this.state.previewUpdated}
                  resetPreview={() => this.togglePreviewUpdated()}
                  setCurrTab={tab => {
                    this.setState({ currTab: tab });
                  }}
                  currentPage={this.state.page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(OnlineVirtual);
