import { setGlobal, addReducer } from "reactn";
import axios from "axios";
import { toast } from "react-toastify";

/**
 * Global State: My Program Information
 */
const myProgramInfo = {
  programName: "",
  programType: "",
  phoneNumber: "",
  email: "",
  educatorName: "",
  educatorTitle: "",
  curriculum: "",
  ageGroups: [],
  capacity: 5
};

setGlobal({ myProgramInfo: myProgramInfo });

/**
 * Global State: My Program Location & Hours
 */
const myProgramLocationHours = {
  address: "",
  majorIntersection: "",
  city: "",
  province: "",
  country: "",
  postalCode: "",
  hoursOfOperationStart: -1,
  hoursOfOperationEnd: -1,
  daysOfWeek: "",
  lat: 0.0,
  lng: 0.0,
  excludedDays: []
};

setGlobal({ myProgramLocationHours: myProgramLocationHours });

/**
 * Global State: My Program Intro
 */
const programIntro = {
  about: "",
  ourEducators: "",
  location: ""
};

setGlobal({ myProgramIntro: programIntro });

/**
 * Global State: My Program Gallery
 */
const programGallery = {
  photos: [],
  profilePhoto: "",
  programCoverPhoto: "",
  searchPhoto: ""
};

setGlobal({ myProgramGallery: programGallery });

/**
 * Global State: My Program Amenities & Schedule
 */

const amenities = {
  mealsProvided: [],
  snacksProvided: 0,
  pottyTrainedRequired: "",
  extendedAMCare: "",
  extendedPMCare: ""
};

const schedule = {
  id: "0",
  time: "",
  activity: ""
};

setGlobal({ myProgramAmenities: amenities });
setGlobal({ myProgramDailySchedule: [] });
addReducer("addProgramSchedule", (global, dispatch) => ({
  myProgramDailySchedule: [
    ...global.myProgramDailySchedule,
    ...[
      {
        ...schedule,
        id: global.myProgramDailySchedule.length.toString(),
        time: global.myProgramLocationHours.hoursOfOperationStart
      }
    ]
  ]
}));
addReducer("removeProgramSchedule", (global, dispatch, obj) => () => {
  return {
    myProgramDailySchedule: [...global.myProgramDailySchedule].filter(
      c => c.id !== obj.id
    )
  };
});

/**
 * Global State: My Program Calendar Dates
 */
// const personalHoliday = {
//   id: "0",
//   date: ""
// };

const vacation = {
  id: "0",
  vacationStart: "",
  vacationEnd: ""
};

const vacations = {
  vacations: [],
  charge: "",
  amount: ""
};

setGlobal({ myProgramHolidays: { programHolidays: [] } });
addReducer("addProgramHoliday", (global, dispatch) => ({
  myProgramHolidays: {
    ...global.myProgramHolidays,
    personalHolidays: [
      ...global.myProgramHolidays.personalHolidays,
      ...[
        {
          ...vacation,
          id: global.myProgramHolidays.personalHolidays.length.toString()
        }
      ]
    ]
  }
}));
addReducer("removeProgramHoliday", (global, dispatch, obj) => () => {
  return {
    myProgramHolidays: {
      ...global.myProgramHolidays,
      personalHolidays: [...global.myProgramHolidays.personalHolidays].filter(
        c => c.id !== obj.id
      )
    }
  };
});

setGlobal({ myProgramVacations: vacations });
addReducer("addProgramVacation", (global, dispatch) => ({
  myProgramVacations: {
    ...global.myProgramVacations,
    vacations: [
      ...global.myProgramVacations.vacations,
      ...[
        {
          ...vacation,
          id: global.myProgramVacations.vacations.length.toString()
        }
      ]
    ]
  }
}));
addReducer("removeProgramVacation", (global, dispatch, obj) => () => {
  return {
    myProgramVacations: {
      ...global.myProgramVacations,
      vacations: [...global.myProgramVacations.vacations].filter(
        c => c.id !== obj.id
      )
    }
  };
});

/**
 * Program Setup: Save Program
 */

addReducer("0", async (global, dispatch, submit) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/edu/program${
      submit ? "/submit" : ""
    }`;
    const data = {
      myProgramInfo: global.myProgramInfo,
      myProgramIntro: global.myProgramIntro,
      myProgramLocationHours: global.myProgramLocationHours,
      myProgramGallery: global.myProgramGallery,
      myProgramAmenities: global.myProgramAmenities,
      myProgramDailySchedule: global.myProgramDailySchedule,
      myProgramHolidays: global.myProgramHolidays,
      myProgramVacations: global.myProgramVacations
    };
    const res = await axios.post(ep, data);
    if (res.data.success && submit) {
      toast("Your Program has been submitted!");
    }
    return {
      ...global,
      ...res.data.data,
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

addReducer("getProgram", async (global, dispatch) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/edu/program`;
    const res = await axios.get(ep);
    if (res.data.success) {
      res.data.data.myProgramInfo.email =
        res.data.data.myProgramInfo.email || global.dsUser.email;
      res.data.data.myProgramInfo.educatorName =
        res.data.data.myProgramInfo.educatorName || global.dsUser.displayName;
      res.data.data.myProgramInfo.ageGroups = res.data.data.myProgramInfo
        .ageGroups.length
        ? res.data.data.myProgramInfo.ageGroups
        : global.myApplicationProfileInfo.ageGroups;
      res.data.data.myProgramInfo.phoneNumber =
        res.data.data.myProgramInfo.phoneNumber ||
        global.myApplicationProfileInfo.homePhone;
      res.data.data.myProgramLocationHours.address =
        res.data.data.myProgramLocationHours.address ||
        global.myApplicationProfileAddress.addressLineOne;
      res.data.data.myProgramLocationHours.city =
        res.data.data.myProgramLocationHours.city ||
        global.myApplicationProfileAddress.city;
      res.data.data.myProgramLocationHours.province =
        res.data.data.myProgramLocationHours.province ||
        global.myApplicationProfileAddress.province;
      res.data.data.myProgramLocationHours.country =
        res.data.data.myProgramLocationHours.country ||
        global.myApplicationProfileAddress.country;
      res.data.data.myProgramLocationHours.postalCode =
        res.data.data.myProgramLocationHours.postalCode ||
        global.myApplicationProfileAddress.postalCode;
    }
    return {
      ...global,
      ...res.data.data,
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

addReducer("uploadProgramPhoto", async (global, dispatch, photo) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/edu/program/photo`;
    const res = await axios.post(ep, photo);
    return {
      ...global,
      ...res.data.data,
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

/**
 * My Application DEV MODE Data
 */
if (process.env.REACT_APP_MODE === "dev") {
  setGlobal({
    myProgramInfo: {
      programName: "Teeny-Tots",
      programType: "Independent Home Unlicensed",
      phoneNumber: "(519) 360-5858",
      email: "jesse@i-leet.com",
      educatorName: "Jesse",
      educatorTitle: "President",
      curriculum: "Play-Based",
      ageGroups: [{ name: "Toddler (2-3 yrs)", value: "Toddler" }],
      capacity: "5"
    }
  });

  setGlobal({
    myProgramLocationHours: {
      address: "123 Fake Street",
      majorIntersection: "Some Intersection",
      city: "Toronto",
      province: "Ontario",
      country: "Canada",
      postalCode: "M5M5M5",
      hoursOfOperationStart: "9:00 AM",
      hoursOfOperationEnd: "5:00 PM",
      daysOfWeek: "Monday - Friday",
      excludedDays: [{ name: "Thursday", value: "Thursday" }]
    }
  });
}
