import React, { useCallback, useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { Button } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ReactTooltip from "react-tooltip";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import _ from "lodash";
import BaseModal from "./baseModal/BaseModal";
import { format } from "date-fns"
import {
  STRING_INPUT,
  GET_HTML,
  DROPDOWN,
  DATE_PICKER,
  TEXT_AREA,
  TITLE
} from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

const CreateOrEditExpenseModal = ({ 
  taxClassification,
  category,
  subCategory,
  expense,
  onClose, 
  setGlobal, 
  global, 
  onSuccess,
  isCreate
}) => {
  const [taxDetails, setTaxDetails] = useState([
    { taxName: "tax_amount_1", classificationName: "classification_1" }
  ]);
  const [selectedId, setSelectedId] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedExpenseDate, setSelectedExpenseDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedSubTotal, setSelectedSubTotal] = useState("");
  const [selectedGrandTotal, setSelectedGrandTotal] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  /**
   * Adds an additional tax line item to the form
   */
  const addTaxLineItem = () => {
    const taxDetailsLength = taxDetails.length;
    if (taxDetailsLength >= 3) {
      return;
    }
    const count = taxDetailsLength + 1;

    const taxInfo = {
      taxName: `tax_amount_${count}`,
      classificationName: `classification_${count}`
    };
    const newTaxDetails = [...taxDetails];
    newTaxDetails.push(taxInfo);

    setTaxDetails(newTaxDetails);
  };

  /**
   * Removes the additional tax line items from the form
   */
  const removeTaxLineItem = (index) => {
    const taxDetailsLength = taxDetails.length;
    if (taxDetailsLength <= 1) {
      return;
    }
    let newTaxDetails = taxDetails.filter((taxDetail, i) => {
      return i !== index;
    })
    let taxSum = 0;
    newTaxDetails.forEach((taxDetail) => {
      taxSum += parseFloat(taxDetail.tax_amount || 0);
    })
    let grandTotal = parseFloat(selectedSubTotal || 0) + parseFloat(taxSum);
    setTaxDetails(newTaxDetails);
    setSelectedGrandTotal(grandTotal);
  };

  useEffect(() => {
    const setFields = async () => {
      if(!expense){
        return;
      }
      if(expense.id) {
        setSelectedId(expense.id);
      }
      if (expense.categoryId) {
        setSelectedCategoryId(expense.categoryId);
        setSelectedCategory(
          category[expense.categoryId - 1].name
        );
      }
      if(expense.subCategoryId) {
        setSelectedSubCategoryId(expense.subCategoryId);
        setSelectedSubCategory(
          subCategory[expense.subCategoryId - 1].name
        )
      }
      if(expense.expense_date) {
        setSelectedExpenseDate(expense.expense_date);
      }
      if(expense.vendor) {
        setSelectedVendor(expense.vendor);
      }
      if(expense.description) {
        setSelectedDescription(expense.description);
      }
      if(expense.sub_total) {
        setSelectedSubTotal(toFixedWithoutZero(expense.sub_total,2));
      }
      if(expense.tax_amount.length !== 0) {
        setTaxDetails(expense.tax_amount.map((tax) => {
          return JSON.parse(tax);
        }));
      }
      if(expense.grand_total) {
        setSelectedGrandTotal(parseFloat(expense.grand_total));
      }
      if(expense.uploaded_expense) {
        setSelectedFile(expense.upload_expense);
      }
    };
    setFields();
  }, []);

  const displaySubCategory = useCallback(() => {
    const filterCategory = category.filter(e => e.name === selectedCategory);
    let filterSubCategory = [];
    if (filterCategory.length > 0) {
      filterSubCategory = subCategory.filter(
        e => e.expenseCategory === filterCategory[0].id
      );
    }

    return filterSubCategory.map(e => {
      return {
        value: e.name,
        text: e.name
      };
      //<option value={e.name}>{e.name}</option>;
    });
  }, [selectedCategory]);

  const uploadNew = () => {
    const uploadEle = document.getElementById("uploadFile");
    uploadEle.click();
  };

  const fileChange = async e => {
    if (e.target.files.length && e.target.files[0]) {
      const fd = new FormData();
      fd.set("image", e.target.files[0]);
      const ep = `${process.env.REACT_APP_API}/partner/expense/document`;
      setGlobal({ loading: true });

      const result = await axios.post(ep, fd);
      setGlobal({ loading: false });
      if (result.data.success) {
        setSelectedFile(result.data.data);
      }
    }
  };

  const savePlan = async () => {
    if (selectedGrandTotal === 0) {
      return;
    }

    const obj = {
      partnerId: global.organizationInfo.partnerId,
      user_name: global.dsUser.displayName,
      categoryId: selectedCategoryId,
      subCategoryId: selectedSubCategoryId,
      expense_date: selectedExpenseDate,
      vendor: selectedVendor,
      description: selectedDescription,
      sub_total: selectedSubTotal || "0",     //server will not accept an empty string, so send in a zero instead.
      tax_amount: taxDetails,
      grand_total: Math.round(selectedGrandTotal * 100) / 100,
      //this code above rounds down to 2 decimal places, and cures some weird
      //floating-point calculation issues, eg. 0.1 + 0.02 => 0.12000000000001
      //or 4.01 + 0.02 = 4.029999999999999
      uploaded_expense: selectedFile
    };
    
    const ep = `${process.env.REACT_APP_API}/partner/expense/${isCreate ? 'create' : selectedId}`;
    const result = await axios.post(ep, {
      ...obj
    });

    if (result.data.success) {
      onSuccess(result.data.data, category, subCategory);
      onClose();
    }
  };

  const debounceSavePlan = _.debounce(_ => savePlan(), 2000, {
    leading: true,
    trailing: false
  });

  const categoryOptions = () => {
    return category.map(cat => ({
      value: cat.name,
      key: cat.id,
      text: cat.name
    }));
  };

  const addTaxFunc = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginBottom: 0
        }}
      >
        <label
          htmlFor="tax_amount"
          style={{
            width: "20%",
            textAlign: "left",
            fontSize: "1.15rem",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262",
            marginTop: "1vh"
          }}
        >
          Add Taxes
        </label>
        <div style={{ display: "block", width: "70%" }}>
          {taxDetails.map((tax, i) => {
            return (
              <div
                key={i}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start"
                }}
                name="tax_amount"
              >
                <div
                  style={{
                    width: "35%",
                    display: "block",
                    alignSelf: "flex-start"
                  }}
                >
                  <label
                    style={{
                      fontSize: "0.7em",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "500",
                      color: "#797979"
                    }}
                  >
                    Amount ($)
                  </label>
                  <input
                    style={{
                      height: "40px",
                      width: "100px",
                      fontSize: "0.93rem",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "300",
                      color: "#494949",
                      border: "1px solid #c5c5c5",
                      borderRadius: "4px"
                    }}
                    name={tax.taxName}
                    id={tax.taxName}
                    type="number"
                    onChange={e => {
                      const newTaxDetails = [...taxDetails];
                      newTaxDetails[i].tax_amount = e.target.value || 0;
                      setTaxDetails(newTaxDetails);
                      let taxSum = 0;
                      taxDetails.forEach((taxDetail) => {
                        taxSum += parseFloat(taxDetail.tax_amount || 0);
                      })
                      let grandTotal = parseFloat(selectedSubTotal || 0) + taxSum;
                      setSelectedGrandTotal(grandTotal);
                    }}
                    value={taxDetails[i].tax_amount ? removeZero(taxDetails[i].tax_amount) : null}
                  />
                </div>
                <div
                  style={{
                    width: "45%",
                    display: "block",
                    marginLeft: "10px"
                  }}
                >
                  <label
                    style={{
                      fontSize: "0.7em",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "500",
                      color: "#797979"
                    }}
                  >
                    Classification
                  </label>
                  <select
                    style={{
                      height: "40px",
                      width: "130px",
                      fontSize: "0.93rem",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "400",
                      color: "#797979",
                      border: "1px solid #c5c5c5",
                      borderRadius: "4px",
                      paddingLeft: "14px"
                    }}
                    name={tax.classificationName}
                    id={tax.classificationName}
                    type="text"
                    onChange={e => {
                      const newTaxDetails = [...taxDetails];
                      newTaxDetails[i].classification_type = e.target.value;
                      setTaxDetails(newTaxDetails);
                    }}
                    value={taxDetails[i].classification_type}
                  >
                    <option value="">Please Select</option>
                    {taxClassification.map(taxClassification => {
                      return (
                        <option
                          value={`${taxClassification.name} (${taxClassification.region})`}
                        >
                          {taxClassification.name} ({taxClassification.region})
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    cursor: "pointer",
                    color: "#9BA7B2",
                    marginLeft: "20px"
                  }}
                  onClick={() => removeTaxLineItem(i)}
                >
                  <CloseIcon color="action" fontSize="large" />
                </div>
              </div>
            );
          })}

          <div
            style={{
              width: "40%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "right",
              alignItems: "right",
              padding: "2px 0",
              marginTop: "10px",
              fontSize: "0.7em",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#797979",
              marginLeft: "150px"
            }}
            onClick={addTaxLineItem}
          >
            + Add another Tax
          </div>
        </div>
      </div>
    );
  };

  const fileattach = () => {
    return (
      <div
        className="form-group"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: 0
        }}
      >
        <label
          htmlFor="uploaded_expense"
          style={{
            width: "35%",
            textAlign: "left",
            fontSize: "1.15rem",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262"
          }}
        >
          File Upload{" "}
          <i
            data-tip
            data-for="uploaded_expense"
            className="fas fa-info-circle"
            style={{ color: "#DCDCDC" }}
          ></i>
          <ReactTooltip
            id="uploaded_expense"
            place="top"
            effect="solid"
            type="info"
            fontSize="1.3px"
          >
            File Upload
          </ReactTooltip>
        </label>
        <div
          style={{
            marginLeft: "0px",
            display: "flex",
            alignItems: "left",
            fontSize: "1.15rem",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262",
            marginBottom: "0.5rem"
          }}
        >
          <Button
            component="span"
            //color="primary"
            size="small"
            startIcon={<AttachFileIcon />}
            onClick={_ => uploadNew()}
          >
            Attach File
          </Button>
          {selectedFile !== undefined && (
            <div
              style={{
                marginLeft: "15px",
                fontSize: "0.7rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Upload Success
            </div>
          )}
          <input
            type="file"
            style={{ display: "none" }}
            id="uploadFile"
            onChange={e => fileChange(e)}
            accept="*"
          />
        </div>
      </div>
    );
  };

  const removeZero = (str) => {
      return /^[+-]{0,1}\d*.\d{0,2}/.exec( str )[0];
  }
  
  const toFixedWithoutZero = (num, precision) => {
    const val = num.toFixed(precision);//.replace(/\.0+$/, '');
    console.log(val);
    return val;
  }

  const getBaseModalFields = () => {
    const username = {
      type: STRING_INPUT,
      data: {
        name: "Username",
        required: false,
        isDisabled: true,
        value: global.dsUser.displayName,
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const categoryInput = {
      type: DROPDOWN,
      data: {
        name: "Category",
        info: "Expense Category",
        required: false,
        placeholder: "Please Select",
        handleChange: e => {
          setSelectedCategory(e.target.value);
          if (e.target.value) {
            const filterCategoryId = category.filter(
              catFil => catFil.name === e.target.value
            )[0].id;
            setSelectedCategoryId(filterCategoryId);
          } else {
            setSelectedCategoryId("");
          }
        },
        value: selectedCategory,
        choices: categoryOptions(),
        iconStyles: {
          paddingLeft: "10px"
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const subcategoryInput = {
      type: DROPDOWN,
      data: {
        name: "Sub Category",
        info: "Expense Sub Category",
        required: false,
        placeholder: "Please Select",
        handleChange: e => {
          setSelectedSubCategory(e.target.value);
          if (e.target.value) {
            const filterSubCategoryId = subCategory.filter(
              catFil => catFil.name === e.target.value
            )[0].id;
            setSelectedSubCategoryId(filterSubCategoryId);
          } else {
            setSelectedSubCategoryId("");
          }
        },
        value: selectedSubCategory,
        choices: displaySubCategory(),
        iconStyles: {
          paddingLeft: "10px"
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const date = {
      type: DATE_PICKER,
      data: {
        name: "Date",
        info: "Date",
        required: false,
        selected: selectedExpenseDate,
        handleChange: (newDate) => {
          console.log('newDate: ', newDate);
          const formattedNewDate = format(new Date(newDate), 'yyyy-MM-dd');
          console.log('formattedNewDate: ', formattedNewDate);
          setSelectedExpenseDate(formattedNewDate);
        },
        // minDate: new Date(),
        dateFormat: "MMMM d, yyyy",
        placeholder: "Pick a date",
        value: new Date(selectedExpenseDate + 'T00:00'),
        iconStyles: {
          paddingLeft: "10px"
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const vendor = {
      type: STRING_INPUT,
      data: {
        name: "Vendor",
        required: false,
        placeholder: "",
        handleChange: e => setSelectedVendor(e.target.value),
        value: selectedVendor,
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const description = {
      type: TEXT_AREA,
      data: {
        name: "Description",
        info: "Description",
        required: false,
        handleChange: e => setSelectedDescription(e.target.value),
        value: selectedDescription,
        iconStyles: {
          paddingLeft: "10px"
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const subtotal = {
      type: STRING_INPUT,
      data: {
        name: "Sub Total",
        type: "number",
        required: false,
        placeholder: "",
        handleChange: e => {
          let twoDecimal = parseFloat(e.target.value || 0);
          setSelectedSubTotal(e.target.value || 0);
          let taxSum = 0;
          taxDetails.forEach((taxDetail) => {
            taxSum += parseFloat(taxDetail.tax_amount || 0);
          })
          let grandTotal = twoDecimal + taxSum;
          setSelectedGrandTotal(grandTotal);
        },
        value: selectedSubTotal ? removeZero(selectedSubTotal) : null,
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    /*     const AddTaxTitle = {
      type: TITLE,
      data: { name: "Add Taxes" }
    };
 */
    const addtax = {
      type: GET_HTML,
      data: {
        gethtml: addTaxFunc
      }
    };

    const grandtotal = {
      type: STRING_INPUT,
      data: {
        name: "Grand Total",
        type: "number",
        // required: true,
        placeholder: "",
        value: toFixedWithoutZero(selectedGrandTotal, 2),
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        isDisabled: true
      }
    };

    /*     const attachTitle = {
      type: TITLE,
      data: { name: "File Upload", info: "File Upload" }
    };
 */
    const attach = {
      type: GET_HTML,
      data: {
        gethtml: fileattach
      }
    };

    const fields = [
      username,
      categoryInput,
      subcategoryInput,
      date,
      vendor,
      description,
      subtotal,
      addtax,
      grandtotal,
      attach,
    ];
    console.log(`fields sent by ${isCreate ? 'createExpenseModal' : 'editExpenseModal'}: `, fields);
    return fields;
  };

  const getBaseModalButtons = () => {
    const submitButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => debounceSavePlan()
    };

    return [submitButton];
  };

  const getBaseModalProps = () => {
    return {
      title: isCreate ? "Create Expense" : "Edit Expense",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      handleClose: onClose,
      height: "65vh",
      midSectionHeight: "49vh"
    };
  };

  return <BaseModal {...getBaseModalProps()} />;
};

export default CreateOrEditExpenseModal;
