import React from "reactn";
import { formTypes, BoxForm } from "../../../../components/UI/form";

class ProgramScheduleForm extends React.PureComponent {
  // const [vals, setVals] = useGlobal("myProgramDailySchedule");
  // const addSchedule = useDispatch("addProgramSchedule");
  // const [locHoursVals] = useGlobal("myProgramLocationHours");
  constructor() {
    super();
    this.state = {
      timeRanges: formTypes.SelectOptions.timeRanges
    };
    this.handleChange = this.handleChange.bind(this);
    this.getAvailableTimes = this.getAvailableTimes.bind(this);
    this.dispatchNewActivity = this.dispatchNewActivity.bind(this);
    this.customForm = this.customForm.bind(this);
  }

  componentDidMount() {
    this.getAvailableTimes();
  }

  handleChange(e, v, sch) {
    const c = { ...sch, [e]: v };
    const n = this.global.myProgramDailySchedule.map(s =>
      sch.id !== s.id ? s : c
    );
    this.setGlobal({ myProgramDailySchedule: n });
  }

  getAvailableTimes() {
    const start = formTypes.SelectOptions.timeRanges.findIndex(
      t =>
        t.value ===
        parseFloat(this.global.myProgramLocationHours.hoursOfOperationStart)
    );
    const end = formTypes.SelectOptions.timeRanges.findIndex(
      t =>
        t.value ===
        parseFloat(this.global.myProgramLocationHours.hoursOfOperationEnd)
    );
    const remaining = formTypes.SelectOptions.timeRanges.slice(start, end + 1);
    // .filter(
    //   o =>
    //     this.global.myProgramDailySchedule.findIndex(
    //       v => v.time !== o.value
    //     ) === -1
    // );
    this.setState({ timeRanges: remaining });
  }

  dispatchNewActivity(e) {
    e.preventDefault();
    this.dispatch.addProgramSchedule();
  }

  customForm() {
    return (
      <div className="formbody">
        <form>
          {!this.global.myProgramDailySchedule.length ? (
            <p className="w-100 text-center mt-3 requiredField">
              Daily outline is required.
            </p>
          ) : null}
          {this.global.myProgramDailySchedule.map((sch, i) => (
            <div className="row" key={i}>
              <div className="col-5">
                <formTypes.Select
                  name="time"
                  value={parseFloat(sch.time)}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value, sch)
                  }
                  // options={this.getAvailableTimes()}
                  options={this.state.timeRanges}
                  isDisabled={
                    this.global.profile.programSubmitted &&
                    this.global.dsUser.accountType !== "Admin"
                  }
                />
              </div>
              <div className="col-7 d-flex">
                <formTypes.Input
                  name="activity"
                  value={sch.activity}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value, sch)
                  }
                  placeholder="Activity"
                  readOnly={
                    !this.global.profile.programSubmitted ||
                    this.global.dsUser.accountType === "Admin"
                      ? false
                      : true
                  }
                />
                {this.global.profile.programSubmitted &&
                this.global.dsUser.accountType !== "Admin" ? null : (
                  <button
                    className="remove-box ml-2"
                    type="button"
                    onClick={() => this.dispatch.removeProgramSchedule(sch)}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                )}
              </div>
            </div>
          ))}
          <div className="row">
            <div className="col-12" style={{ textAlign: "center" }}>
              <button
                onClick={this.dispatchNewActivity}
                className="add-form-button"
                disabled={
                  this.global.profile.programSubmitted &&
                  this.global.dsUser.accountType !== "Admin"
                }
              >
                Add Activity
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <>
        <BoxForm
          handleChange={this.handleChange}
          name={"DAILY SCHEDULE"}
          customForm={this.customForm()}
        />
      </>
    );
  }
}

export default ProgramScheduleForm;
