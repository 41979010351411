import React, { useState, useEffect, useGlobal } from "reactn";
import { withRouter } from "react-router-dom";
import BaseModal from "./baseModal/BaseModal";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { DROPDOWN } from "./baseModal/FieldTypes";
import axios from "axios";

const AddTaxModal = ({ onClose, addedTax, match }) => {
  const [tax, setTax] = useState("");
  const [taxOptions, setTaxOptions] = useState([]);

  const [global, setGlobal] = useGlobal();

  const fetchTaxData = async () => {
    const stripeTaxId = global.organizationInfo.stripeTaxId;
    console.log("stripeTaxId", stripeTaxId);
    const DATA = [];
    for (let i = 0; i < stripeTaxId.length; i++) {
      DATA.push(JSON.parse(stripeTaxId[i]));
    }
    console.log("DATA", DATA);

    setTaxOptions(DATA);
  };

  const getTaxOptions = () => {
    return taxOptions.map((e, i) => ({
      value: i,
      text: e.display_name + " " + e.jurisdiction + e.percentage + "%"
    }));
  };

  useEffect(() => {
    fetchTaxData();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    console.log("submitting");

    const ep = `${process.env.REACT_APP_API}/partners/orders/invoice/add-tax`;
    const cb = await axios.post(ep, {
      id: match.params.id,
      tax: tax
    });
    console.log("cb", cb);

    if (cb.data.success) {
      onClose();
      addedTax();
    }
  };

  const getBaseModalFields = () => {
    const taxField = {
      type: DROPDOWN,
      data: {
        name: "Pick a Tax Plan",
        required: true,
        placeholder: "Pick a tax plan ...",
        handleChange: e => {
          if (e.target.value === "") {
            setTax("");
          } else {
            console.log("setting tax", taxOptions[e.target.value]);
            setTax(taxOptions[e.target.value]);
          }
        },
        choices: getTaxOptions()
      },
      validators: {
        validateSubmit: () => tax !== ""
      }
    };
    return [taxField];
  };

  const getBaseModalButtons = () => {
    const submitButton = {
      name: "Add Tax",
      handleClick: handleSubmit,
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit"
    };
    return [submitButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Add Tax to Invoice",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: onClose
    };
  };

  return <BaseModal {...getBaseModalProps()} />;
};

export default withRouter(AddTaxModal);
