import { useState, useEffect, useGlobal } from "reactn";
import axios from "axios";
import moment from "moment";
import _ from "lodash";

const MILLISECONDS_IN_SECONDS = 1000;
const CENTS_IN_DOLLAR = 100;

/**
 * Custom hooks used for reports and analytics pages.
 */

/**
 * Hook gets all programs, events, online, and memberships.
 * Only returns non-archived listings.
 *
 * @returns {Object} In the form {programs, events, memberships, onlineVirtuals}.
 *
 */
const useListings = () => {
  const [programs, setPrograms] = useState([]);
  const [events, setEvents] = useState([]);
  const [memberships, setMemberships] = useState([]);
  const [onlineVirtuals, setOnlineVirtuals] = useState([]);

  useEffect(() => {
    (async () => {
      const ep = `${process.env.REACT_APP_API}/partners/organization_profile/preview`;
      const results = await axios.get(ep);

      if (results.data.success) {
        const data = results.data.data;

        console.log("results data", data);

        // Set data for each listing type, pre-parsing JSON fields.
        setPrograms(
          data.programs
            .map(e => {
              // console.log("plan", e.plans);
              const parsePlans = e.plans.map(t => JSON.parse(t));
              e.plans = parsePlans;

              // Rename name property to listingName to stay consistent across listings.
              e.listingName = e.program_name;
              return e;
            })
            .filter(e => {
              return !e.isArchived;
            })
        );
        setEvents(
          data.events
            .map(e => {
              const parseTicket = e.tickets.map(t => JSON.parse(t));
              e.tickets = parseTicket;
              e.listingName = e.event_title;
              return e;
            })
            .filter(e => {
              return !e.isArchived;
            })
        );
        setMemberships(
          data.memberships
            .map(e => {
              const parseMembershipPrices = e.prices.map(t => JSON.parse(t));
              e.prices = parseMembershipPrices;
              e.listingName = e.membership_name;
              return e;
            })
            .filter(e => {
              return !e.isArchived;
            })
        );
        setOnlineVirtuals(
          data.virtual
            .map(e => {
              const parsePlans = e.plans.map(t => JSON.parse(t));
              e.plans = parsePlans;
              e.listingName = e.program_name;
              return e;
            })
            .filter(e => {
              return !e.isArchived;
            })
        );
      } else {
        console.error("Could not get data.");
      }
    })();
  }, []);

  return {
    programs: programs,
    events: events,
    memberships: memberships,
    onlineVirtuals: onlineVirtuals
  };
};

/**
 * Hook gets and returns all enrollments, given arrays of listings by type.
 * Might be deprecated.
 *
 * @returns {Object} In the form {programEnrollments, eventEnrollments, membershipEnrollments, onlineEnrollments}.
 *
 * @param {Array} programs Array of all programs created by current user.
 * @param {Array} events Array of all events created by current user.
 * @param {Array} memberships Array of all memberships created by current user.
 * @param {Array} virtual Array of all online virtual programs created by current user.
 */
const useEnrollments = () => {
  const [programEnrollments, setProgramEnrollments] = useState([]);
  const [eventEnrollments, setEventEnrollments] = useState([]);
  const [membershipEnrollments, setMembershipEnrollments] = useState([]);
  const [onlineVirtualEnrollments, setOnlineVirtualEnrollments] = useState([]);

  const partnerId = useGlobal("organizationInfo")[0].partnerId;

  /**
   * TODO: Make these more efficient by reducing number of API calls.
   */
  useEffect(() => {
    const lookupArray = [
      { ep: "programs", plans: "plans", setCustomers: setProgramEnrollments },
      { ep: "events", plans: "tickets", setCustomers: setEventEnrollments },
      {
        ep: "memberships",
        plans: "prices",
        setCustomers: setMembershipEnrollments
      },
      {
        ep: "virtual",
        plans: "plans",
        setCustomers: setOnlineVirtualEnrollments
      }
    ];
    lookupArray.forEach(listingType => {
      const { ep, plans, setCustomers } = listingType;
      const getCustomers = async () => {
        const customers_ep = `${process.env.REACT_APP_API}/partners/${ep}/customers/all/${partnerId}`;
        const customers = await axios.get(customers_ep);

        if (customers.data.success) {
          // Parse JSON inside response.
          const customerData = customers.data.data.map(e => {
            e[plans] = e[plans].map(t => JSON.parse(t));
            return e;
          });

          setCustomers(customerData);
        }
      };

      getCustomers();
    });
  }, [partnerId]);

  return {
    programEnrollments: programEnrollments,
    eventEnrollments: eventEnrollments,
    membershipEnrollments: membershipEnrollments,
    onlineVirtualEnrollments: onlineVirtualEnrollments
  };
};

const useForms = () => {
  const [forms, setForms] = useState([]);
  useEffect(() => {
    (async () => {
      const forms_ep = `${process.env.REACT_APP_API}/partners/forms/all/form_fields`;
      const forms_results = await axios.get(forms_ep);
      if (forms_results.data.success) {
        const partnerForms = forms_results.data.data;
        setForms(partnerForms);
      } else {
        console.error("Could not get data.");
      }
    })();
  }, []);

  return forms;
};

/**
 * Gets all recurring, one-time, and installment orders from backend. Returns the raw JSON.
 *
 * @returns {Object} Object {recurring: [...], installments: [...], oneTime: [...]}
 */
const useOrders = () => {
  const [recurring, setRecurring] = useState(null);
  const [installments, setInstallments] = useState(null);
  const [oneTime, setOneTime] = useState(null);
  const setLoading = useGlobal("loading")[1];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const ep = `${process.env.REACT_APP_API}/partners/orders/total`;
        const results = await axios.get(ep);
        const data = results.data.data;

        if (results.data.success) {
          setRecurring(data.recurring);
          setOneTime(data.totalOneTime);
          setInstallments(data.Installments);
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);
  return { recurring: recurring, installments: installments, oneTime: oneTime };
};

/**
 * Aggregates all orders and invoices into one single array.
 *
 * @returns {Array} All orders in a single array.
 */
const useAggregatedOrders = () => {
  const { recurring, installments, oneTime } = useOrders();
  const [allOrders, setAllOrders] = useState([]);
  // Processes the raw order data and separate into listing types, then stores into state.
  useEffect(() => {
    if (!recurring || !installments || !oneTime) return;

    // Lookup table relating database tables to listing arrays.
    const tablesMap = {
      partner_programs: "program_name",
      partner_event: "event_title",
      partner_memberships: "membership_name",
      partner_online: "program_name"
    };

    // Aggregate array for all charges and invoices.
    let allCharges = [].concat(
      recurring.deposits,
      recurring.invoices.filter(
        // Filters all the trial $0 invoices.
        invoice => {
          // Sanity check to ensure description exists.
          if (!invoice.lines || !invoice.lines.data[0].description) {
            return true;
          }
          return !invoice.lines.data[0].description.includes("Trial");
        }
      ),
      oneTime.charges
    );

    // Installments object is nested under each charge - add each nested invoice into the array.
    installments.charges.forEach(charge => {
      charge.payments.forEach(payment => {
        allCharges.push(payment.invoice);
      });
    });

    // Add Id, name, and revenue properties for each charge.
    allCharges = allCharges.map(charge => {
      const id = charge.productId;

      const nameProperty = tablesMap[charge.product_table];
      const name = charge.product[nameProperty];

      let amount;
      if (charge.amount_paid !== undefined) {
        // Invoice object has property amount_paid.
        amount = charge.amount_paid;
      } else if (charge.amount_captured !== undefined) {
        // Charge object has property amount_captured.
        amount = charge.amount_captured;
      } else if (charge.amount !== undefined) {
        // Manual payments have property amount.
        amount = charge.amount;
      } else {
        // Manual recurring have property total.
        amount = charge.total;
      }

      return {
        ...charge,
        productId: id,
        productName: name,
        revenue: parseInt(amount)
      };
    });

    // Filter out archived programs.
    setAllOrders(
      allCharges.filter(charge => {
        if (charge.product === undefined) {
        }
        return !charge.product.isArchived;
      })
    );
  }, [recurring, installments, oneTime]);

  return allOrders;
};

/**
 * Same functionality as useAggregatedOrders, but groups the orders by listing type (Programs, events, etc.).
 *
 * @returns {Object} {programOrders, eventOrders, onlineOrders, membershipOrders}
 */
const useGroupedOrders = () => {
  const [programOrders, setProgramOrders] = useState([]);
  const [eventOrders, setEventOrders] = useState([]);
  const [onlineOrders, setOnlineOrders] = useState([]);
  const [membershipOrders, setMembershipOrders] = useState([]);

  const allOrders = useAggregatedOrders();

  useEffect(() => {
    if (allOrders.length === 0) return;

    let groupedCharges = _.groupBy(allOrders, charge => charge.product_table);
    setProgramOrders(groupedCharges.partner_programs || []);
    setEventOrders(groupedCharges.partner_event || []);
    setOnlineOrders(groupedCharges.partner_online || []);
    setMembershipOrders(groupedCharges.partner_memberships || []);
  }, [allOrders]);

  return {
    programOrders,
    eventOrders,
    onlineOrders,
    membershipOrders
  };
};

/**
 * Initializes stateful date values with defaults for use with date filters.
 *
 * By default, if no params given, end is set to today and start is set to one month prior.
 *
 * @returns {Array} In the form [dateFrom, dateTo, setDateFrom, setDateTo];
 *
 * @param {String} start Start date in the form "YYYY-MM-DD". Optional.
 * @param {String} end End date in the form "YYYY-MM-DD". Optional.
 */
const useDateFilter = (start, end) => {
  const DEFAULT_END_DATE = moment().format("YYYY-MM-DD");
  const DEFAULT_START_DATE = moment()
    .subtract(1, "months")
    .format("YYYY-MM-DD");

  const [dateFrom, setDateFrom] = useState(start || DEFAULT_START_DATE);
  const [dateTo, setDateTo] = useState(end || DEFAULT_END_DATE);
  return [dateFrom, dateTo, setDateFrom, setDateTo];
};

/**
 * Gets connections and returns array of active connections and pending connections.
 *
 * @returns {Object} {Active, Pending}
 */
const useConnections = () => {
  const [active, setActive] = useState([]);
  const [pending, setPending] = useState([]);
  const setLoading = useGlobal("loading")[1];

  useEffect(() => {
    const fetchInitialData = async () => {
      const sortByClass = (a, b) => {
        if (a.classification < b.classification) {
          return -1;
        }
        if (a.classification > b.classification) {
          return 1;
        }
        return 0;
      };

      setLoading(true);

      const ep = `${process.env.REACT_APP_API}/partners/connections/${true}`;
      const results = await axios.get(ep);
      if (results.data.success) {
        const data = [];
        const active = [];
        const pending = [];

        const connections = results.data.data.connections;

        connections
          .filter(f => !f.isArchived)
          .sort(sortByClass)
          .map(e => !data.filter(f => f.email === e.email)[0] && data.push(e));

        connections
          .filter(f => !f.isArchived)
          .sort(sortByClass)
          .map(e => {
            let childNames = [];
            const allChild = connections
              .filter(f => !f.isArchived)
              .filter(h => h.dsUserId == e.dsUserId)
              .map(
                k =>
                  !childNames.filter(f => f == k.childName)[0] &&
                  k.childName &&
                  childNames.push(k.childName)
              );
            !active.filter(f => f.email === e.email)[0] &&
              e.classification === "active" &&
              active.push({ ...e, childNames });
          });

        connections
          .filter(f => !f.isArchived)
          .sort(sortByClass)
          .map(e => {
            let childNames = [];
            const allChild = connections
              .filter(h => h.dsUserId == e.dsUserId)
              .map(
                k =>
                  !childNames.filter(f => f == k.childName)[0] &&
                  k.childName &&
                  childNames.push(k.childName)
              );
            !active.filter(f => f.email === e.email)[0] &&
              !pending.filter(f => f.email === e.email)[0] &&
              pending.push({ ...e, childNames });
          });

        setActive(
          active
            .filter(f => !f.isArchived)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
        setPending(
          pending
            .filter(f => !f.isArchived)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
      }
      setLoading(false);
    };
    fetchInitialData();
  }, []);
  return { active, pending };
};
export {
  useListings,
  useForms,
  useEnrollments,
  useOrders,
  useAggregatedOrders,
  useGroupedOrders,
  useDateFilter,
  useConnections,
  MILLISECONDS_IN_SECONDS,
  CENTS_IN_DOLLAR
};
