import React, { Fragment } from "reactn";
import { MilestoneCheck, MilestoneCheck2, MilestoneCheck3 } from "../../assets";
import "./milestone.css";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import { withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

const icons = [
  {
    image: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="74.28"
        height="70.566"
        viewBox="0 0 74.28 70.566"
      >
        <g
          id="Group_883"
          data-name="Group 883"
          transform="translate(-356.404 -339.963)"
        >
          <path
            id="path"
            d="M851.48,35.714l-8.1,9.454,1.013,12.493-12.155,2.7-6.415,10.8L814.34,66.1l-11.48,5.065L796.445,60.7,784.29,58,785.3,45.506l-8.1-9.454,8.1-9.454L784.29,14.105l12.155-2.7L802.86.6l11.48,5.065L825.82.6l6.415,10.8,12.155,2.7L843.377,26.6Zm-34.1,3.376V18.832h-6.753V39.091Zm0,13.505V45.843h-6.753V52.6Z"
            transform="translate(-420.796 339.363)"
            fill="#ff6763"
          />
          <rect
            id="Rectangle_1319"
            data-name="Rectangle 1319"
            width="21.646"
            height="42.426"
            transform="translate(379.609 353.816)"
            fill="#ff6763"
          />
          <text
            id="A"
            transform="translate(382.938 354.414)"
            fill="#fff"
            fontSize="30"
            fontFamily="OpenSans-Bold, Open Sans"
            fontWeight="700"
          >
            <tspan x="0" y="32">
              A
            </tspan>
          </text>
        </g>
      </svg>
    ),
    color: "#FF6763"
  },
  {
    image: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="74.28"
        height="70.565"
        viewBox="0 0 74.28 70.565"
      >
        <g
          id="Group_884"
          data-name="Group 884"
          transform="translate(-356.404 -487.156)"
        >
          <path
            id="Union_4"
            data-name="Union 4"
            d="M37.14,65.5,25.66,70.566,19.245,60.1,7.091,57.4,8.1,44.906,0,35.452,8.1,26,7.091,13.505l12.155-2.7L25.66,0,37.14,5.064,48.62,0l6.416,10.8,12.155,2.7L66.177,26l8.1,9.116-8.1,9.454L67.19,57.06l-12.155,2.7-6.416,10.8Z"
            transform="translate(356.404 487.156)"
            fill="#63abe0"
          />
          <text
            id="B"
            transform="translate(382.938 502.606)"
            fill="#fff"
            fontSize="30"
            fontFamily="OpenSans-Bold, Open Sans"
            fontWeight="700"
          >
            <tspan x="0" y="32">
              B
            </tspan>
          </text>
        </g>
      </svg>
    ),
    color: "#63ABE0"
  },
  {
    image: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="74.28"
        height="70.565"
        viewBox="0 0 74.28 70.565"
      >
        <g
          id="Group_885"
          data-name="Group 885"
          transform="translate(-356.404 -634.349)"
        >
          <path
            id="Union_5"
            data-name="Union 5"
            d="M37.14,65.5,25.66,70.566,19.245,60.1,7.091,57.4,8.1,44.906,0,35.452,8.1,26,7.091,13.505l12.155-2.7L25.66,0,37.14,5.064,48.62,0l6.416,10.8,12.155,2.7L66.177,26l8.1,9.116-8.1,9.454L67.19,57.06l-12.155,2.7-6.416,10.8Z"
            transform="translate(356.404 634.349)"
            fill="#7a92ff"
          />

          <text
            id="C"
            transform="translate(381.938 649.8)"
            fill="#fff"
            font-size="30"
            font-family="OpenSans-Bold, Open Sans"
            font-weight="700"
          >
            <tspan x="0" y="32">
              C
            </tspan>
          </text>
        </g>
      </svg>
    ),
    color: "#7A92FF"
  }
];

class RightSideBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedDropdown: null,
      selectedMilestone: null
    };
    this.checkMilestone = this.checkMilestone.bind(this);
    this.setDropdown = this.setDropdown.bind(this);
    this.menu = this.menu.bind(this);
  }

  menu = e => {
    return (
      <Menu
        onClick={e =>
          this.props.updateMilestone(e, this.state.selectedMilestone)
        }
      >
        <MenuItem key="Not Complete">Not Complete</MenuItem>
        <MenuItem key="In Progress">In Progress</MenuItem>
        <MenuItem key="Complete">Complete</MenuItem>
      </Menu>
    );
  };

  setDropdown(msId) {
    if (this.state.selectedDropdown === null) {
      this.setState({ selectedDropdown: msId });
    } else if (this.state.selectedDropdown === msId) {
      this.setState({ selectedDropdown: null });
    } else {
      this.setState({ selectedDropdown: msId });
    }
  }

  checkMilestone(ms) {
    let exists = this.props.milestones[
      this.props.selectedGroup
    ].earned.findIndex(earned => earned.milestone === ms.id);
    if (exists > -1) return "Complete";
    exists = this.props.milestones[this.props.selectedGroup].progress.findIndex(
      earned => earned.milestone === ms.id
    );
    if (exists > -1) return "In Progress";
    return "Not Complete";
  }

  images = {
    Complete: MilestoneCheck2,
    "In Progress": MilestoneCheck,
    "Not Complete": MilestoneCheck3
  };

  render() {
    return (
      <div className="right-cont">
        <Scrollbars style={{ height: "calc(100vh - 55px)" }}>
          <div style={{ marginLeft: 40, marginRight: 40 }}>
            {this.props.milestones.length ? (
              <div className="bigbox">
                {/* <img src={ALine} className="line" alt="" /> */}
                <div className="line">
                  {icons[this.props.selectedGroup].image}
                  <div
                    className="h-line"
                    style={{
                      backgroundColor: icons[this.props.selectedGroup].color
                    }}
                  ></div>
                </div>
                {this.props.milestones[this.props.selectedGroup].milestones.map(
                  ms => {
                    const msStatus = this.checkMilestone(ms);
                    return (
                      <Fragment key={ms.id}>
                        <p className={msStatus === "Complete" ? "active" : ""}>
                          {this.global.dsUser.accountType === "Admin" ? (
                            <Dropdown
                              trigger={["click"]}
                              overlay={this.menu}
                              animation="slide-up"
                            >
                              <img
                                src={this.images[msStatus]}
                                alt="Milestone Status"
                                onClick={() =>
                                  this.setState({ selectedMilestone: ms })
                                }
                              />
                            </Dropdown>
                          ) : (
                            <img
                              src={this.images[msStatus]}
                              alt="Milestone Status"
                            />
                          )}
                          <span
                            onClick={() => this.setDropdown(ms.id)}
                            style={{ cursor: "pointer" }}
                          >
                            {ms.name}
                          </span>
                        </p>
                        <div
                          className={
                            this.state.selectedDropdown === ms.id
                              ? "ms-sub-show ms-sub"
                              : "ms-sub"
                          }
                        >
                          {ms.subtext}
                        </div>
                      </Fragment>
                    );
                  }
                )}
                <div
                  className="w-100 py-3"
                  style={{
                    backgroundColor: icons[this.props.selectedGroup].color,
                    textAlign: "center",
                    color: "white",
                    fontSize: "18px",
                    fontWeight: "bold"
                  }}
                >
                  {this.props.milestones[this.props.selectedGroup].description}
                </div>
              </div>
            ) : null}
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default withRouter(RightSideBox);
