import axios from "axios";

/**
 * Determines if user can create another listing, based on limits set in admin console.
 * Used in listing creation modals, as well as listing setup pages.
 * @returns {Object} Contains properties "success" -> bool, and "message" -> string.
 *
 * @param {string} listingType Listing limit to check. Can be "program", "event", "online", "membership", "giftcard", "update", "promotion".
 */
const canUserCreate = async listingType => {
  const baseURL = `${process.env.REACT_APP_API}/partners/permissions`;
  let ep;
  switch (listingType) {
    case "program":
      ep = new URL(`${baseURL}/programs/can_user_create_program`);
      break;

    case "event":
      ep = new URL(`${baseURL}/events/can_user_create_event`);
      break;
    
    case "party":
      ep = new URL(`${baseURL}/parties/can_user_create_party`);
      break;

    case "online":
      ep = new URL(`${baseURL}/online_virtual/can_user_create_online_virtual`);
      break;

    case "membership":
      ep = new URL(`${baseURL}/membership/can_user_create_membership`);
      break;

    case "giftcard":
      ep = new URL(`${baseURL}/giftcards/can_user_create_giftcards`);
      break;

    case "update":
      ep = new URL(`${baseURL}/news_updates/can_user_create_news_updates`);
      break;

    case "promotion":
      // TODO: No endpoint yet.
      break;

    default:
      return false;
  }
  try {
    const resp = await axios.get(ep);

    if (resp.data) {
      return resp.data;
    }
    return {
      success: false,
      message: "Something went wrong when querying the data"
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Something went wrong when querying the data"
    };
  }
};

export { canUserCreate };
