import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";
import "../../../../assets/css/componentSpecificCss/eventListings.css";
import AddEventTicketModal from "../../../../components/modals/AddEventTicketModal";
import EditEventTicketModal from "../../../../components/modals/EditEventTicketModal";
import AddBirthdayPartyModal from "../../../../components/modals/AddBirthdayPartyModal";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import Dropdown from "rc-dropdown";
import axios from "axios";
import { toast } from "react-toastify";

class SectionTwo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openAddEventTicketModal: false,
      openEditEventTicketModal: false,
      openAddBirthdayPartyModal: false,
      openEditBirthdayPartyModal: false,
      isTaxEnabled: false,
      taxInfo: [],
      ticketType: "",
      selectedItem: null,
      customers: []
    };
  }
  handleChange = (e, v) => {
    this.setGlobal({
      event: {
        ...this.global.event,
        [e]: v
      }
    });
  };

  async componentDidMount() {
    this.setGlobal({
      loading: true
    });
    const param = this.props.match.params.id;
    const customers_ep = `${process.env.REACT_APP_API}/partners/events/customers/${this.global.event.id}`;
    const customers = await axios.get(customers_ep);
    if (customers.data.success) {
      this.setState({
        customers: customers.data.data
      });
      this.setGlobal({
        loading: false
      });
    }
  }

  deletePlan = async (plan, key) => {
    if (plan.ticket_status !== "Free") {
      const active = plan.active === false;

      const ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: plan.ticket_stripe,
        active: active
      });

      this.setGlobal({ loading: false });

      if (res.data.success) {
        this.setGlobal({
          event: {
            ...this.global.event,
            tickets: this.global.event.tickets.map((e, i) =>
              i === key ? { ...plan, archived: true, active: active } : e
            )
          }
        });
      }
      await this.dispatch.saveEvent();
      return;
    }

    this.setGlobal({
      event: {
        ...this.global.event,
        tickets: this.global.event.tickets.map((e, i) =>
          i === key ? { ...plan, archived: true } : e
        )
      }
    });
    await this.dispatch.saveEvent();
  };

  onSelect = async (element, planKey, event) => {
    if (event.key === "inactive") {
      const active = element.active === false;

      const ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: element.ticket_stripe,
        active: active
      });

      this.setGlobal({ loading: false });
      if (res.data.success) {
        this.setGlobal({
          event: {
            ...this.global.event,
            tickets: this.global.event.tickets.map((e, i) => {
              const ele = typeof e === "string" ? JSON.parse(e) : e;

              return ele.id == planKey ? { ...element, active: active } : e;
            })
          }
        });
      }
      await this.dispatch.saveEvent();
    } else if (event.key === "edit") {
      this.setState({
        openEditEventTicketModal: true,
        selectedItem: element
      });
    } else {
      this.deletePlan(element, planKey);
    }
  };

  menu = (element, planId) => {
    return (
      <Menu onClick={event => this.onSelect(element, planId, event)}>
        {element.ticket_status !== "Free" && (
          <MenuItem key="inactive">
            {element.active === false ? "Make Active" : "Make Inactive"}
          </MenuItem>
        )}
        {this.countEnrolledAppsInPlan(element) === 0 && (
          <MenuItem key="edit">Edit</MenuItem>
        )}
        {this.countEnrolledAppsInPlan(element) === 0 && (
          <MenuItem key="delete">Delete</MenuItem>
        )}
      </Menu>
    );
  };

  customForm = () => {
    return (
      <div className="formbody">
        <form>
          <div className="row" style={{ marginLeft: "5px" }}>
            <div className="col-3">
              <h3 className="label-names">
                Add Ticket<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-9 d-flex">
              {!this.props.birthdayEvent && (
                <>
                  <button
                    className="newapplications button-tags"
                    type="button"
                    onClick={_ => {
                      this.setState({
                        ticketType: "Free",
                        openAddEventTicketModal: true
                      });
                    }}
                  >
                    Free
                  </button>
                  <button
                    className="newapplications button-tags"
                    type="button"
                    onClick={_ => {
                      this.setState({
                        ticketType: "Paid",
                        openAddEventTicketModal: true
                      });
                    }}
                  >
                    Paid
                  </button>
                  <button
                    className="newapplications button-tags"
                    type="button"
                    onClick={_ => {
                      this.setState({
                        ticketType: "Membership",
                        openAddEventTicketModal: true
                      });
                    }}
                  >
                    Member
                  </button>
                  <button
                    className="newapplications button-tags"
                    type="button"
                    onClick={_ => {
                      this.setState({
                        ticketType: "Donation",
                        openAddEventTicketModal: true
                      });
                    }}
                  >
                    Donation
                  </button>
                </>
              )}
              {this.props.birthdayEvent && (
                <button
                  className="newapplications button-tags"
                  type="button"
                  onClick={_ => {
                    this.setState({
                      ticketType: "Birthday Event",
                      openAddBirthdayPartyModal: true
                    });
                  }}
                >
                  Birthday
                </button>
              )}

            </div>
          </div>

          {this.global.event.tickets.length ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <table className="tableticket">
                <thead>
                  <tr>
                    <th
                      style={{ padding: "10px" }}
                      className="input-fields-no-border"
                    >
                      Ticket Name
                    </th>
                    <th
                      style={{ padding: "10px" }}
                      className="input-fields-no-border"
                    >
                      Price
                    </th>
                    <th
                      style={{ padding: "10px" }}
                      className="input-fields-no-border"
                    >
                      Ticket Quantity
                    </th>
                    <th
                      style={{ padding: "10px" }}
                      className="input-fields-no-border"
                    >
                      Tax % (if any)
                    </th>
                    <th
                      style={{ padding: "10px" }}
                      className="input-fields-no-border"
                    >
                      Enrolled / Apps
                    </th>
                    <th
                      style={{ padding: "10px" }}
                      className="input-fields-no-border"
                    >
                      Ticket Status
                    </th>
                  </tr>
                </thead>
                {this.global.event.tickets
                  .filter(ele => {
                    const e = typeof ele === "string" ? JSON.parse(ele) : ele;
                    if (e.archived) {
                      return;
                    } else return e;
                  })
                  .map((ele, key) => {
                    const e = typeof ele === "string" ? JSON.parse(ele) : ele;

                    return (
                      <tbody key={key}>
                        <tr>
                          <td style={{ padding: "10px" }}>
                            <span name="ticket_name">{e.ticket_name}</span>
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            <span name="ticket_price">${e.ticket_price}</span>
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            <span name="ticket_quantity">
                              {e.ticket_quantity}
                            </span>
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            <span name="isTaxEnabled">
                              {e.isTaxEnabled
                                ? `${e.taxInfo[0].percentage}`
                                : 0}
                              %
                            </span>
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            <span name="enrollment_num">
                              {this.countEnrolledAppsInPlan(e)}
                            </span>
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            <div
                              name="ticket_status"
                              className={`${e.active === false
                                ? "ticket-status"
                                : "ticket-status-inverse"
                                }`}
                            >
                              {e.ticket_status}
                            </div>
                          </td>
                          <td style={{ padding: "10px", cursor: "pointer" }}>
                            <div>
                              <Dropdown
                                trigger={["click"]}
                                overlay={this.menu(e, e.id)}
                                animation="slide-up"
                              >
                                <button
                                  style={{
                                    borderRadius: "50%",
                                    width: 25,
                                    height: 25,
                                    outline: "none",
                                    border: 0,
                                    background: "#f4f6fd",
                                    fontSize: "14px"
                                  }}
                                >
                                  <i className="fas fa-ellipsis-v"></i>
                                </button>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </table>
            </div>
          ) : (
            <p style={{ marginTop: "10px", textAlign: "center" }}>
              No tickets!
            </p>
          )}
        </form>
      </div>
    );
  };

  countEnrolledAppsInPlan(plan) {
    let count = 0;
    let pricingPlan = plan.ticket_stripe;

    if (this.state.customers.length > 0) {
      this.state.customers.forEach(purchase => {
        if (
          purchase.status_stage !== "Inactive" &&
          purchase.plan_id !== null &&
          JSON.parse(purchase.tickets[purchase.plan_id]).ticket_stripe ===
          pricingPlan
        ) {
          count++;
        }
      });
    }

    return count;
  }

  render() {
    return (
      <>
        {this.state.openAddEventTicketModal && (
          <AddEventTicketModal
            onClose={_ => this.setState({ openAddEventTicketModal: false })}
            ticketType={this.state.ticketType}
          />
        )}
        {this.state.openAddBirthdayPartyModal && (
          <AddBirthdayPartyModal
            onClose={_ => this.setState({ openAddBirthdayPartyModal: false })}
            ticketType={this.state.ticketType}
          />
        )}
        {this.state.openEditEventTicketModal && (
          <EditEventTicketModal
            onClose={() => {
              this.setState({ openEditEventTicketModal: false });
            }}
            ticketType={this.state.ticketType}
            selectedItem={this.state.selectedItem}
          />
        )}
        <BoxForm
          handleChange={this.handleChange}
          name={"Create Ticket"}
          customForm={this.customForm()}
        />
      </>
    );
  }
}

export default SectionTwo;
