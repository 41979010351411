import React, { useState, useGlobal } from "reactn";
import "./RequestInfo.css";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { toast } from "react-toastify";
import axios from "axios";
import { CONTENT_BLOCK, STRING_INPUT } from "./baseModal/FieldTypes";
import BaseModal from "./baseModal/BaseModal";

const EditFormNameModal = ({ prevForm, onSave, onClose }) => {
  const [formName, setFormName] = useState(prevForm.form_sub_name);
  const setLoading = useGlobal("loading")[1];

  const handleSave = async e => {
    e.preventDefault();
    if (formName === "") {
      toast.error("Form name cannot be empty!");
      return;
    }
    setLoading(true);
    const ep = `${process.env.REACT_APP_API}/partners/forms/edit-name/${prevForm.id}`;
    try {
      const results = await axios.post(ep, { name: formName });
      if (results.data.success) {
        await onSave();
        toast.success(
          `Form ${prevForm.form_sub_name} changed to ${results.data.data.form_sub_name}!`
        );
        onClose();
      } else {
        toast.error(results.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  const getBaseModalProps = () => {
    return {
      title: "Edit Form Name",
      fields: [
        {
          type: STRING_INPUT,
          data: {
            placeholder: "New Name (50 characters max)",
            required: true,
            value: formName,
            handleChange: (e) => setFormName(e.target.value.substring(0, 50)),
            name: "New Name",
          }
        }],
      buttons: [{
        name: "Save",
        handleClick: handleSave,
        buttonColour: "#3B4EFF",
        textColour: "#fff",
      }],
      height: '40vh',
      handleClose: () => onClose()
    };
  };

  return (
    <BaseModal {...getBaseModalProps()} />
  );
};

export default EditFormNameModal;
