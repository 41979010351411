import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { toast } from "react-toastify";
import { ModalBanner } from "../../assets";
import { NewTabbedTable } from "../../components/UI";
import { MilestonesCanvas } from "../../assets";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import axios from "axios";
import NoDataAvailable from "../../components/front/partner/NoDataAvailable";
import {
  constructDateOnlyWithTimeZone,
  constructTimeRangeFromDecimalHours,
  getFormattedTimeRange,
  getFormattedDateRange,
  getGroupedWeekdayRange,
  buildScheduleForProduct,
  prepareClosureDates,
  makeUTCDateTimeAsLocalTZ
} from "../../pages/front/SpecificPageUtils/utils";
import "../../assets/css/componentSpecificCss/PreviewProgramListingsModal.css";

class PreviewOnlineListingsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      programs: []
    };
  }

  onBeforeDragStart = () => {
    this.setState({ isDragging: true });
  };

  onDragEndHandler = async (destination, source, draggableId) => {
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const itemList = this.state.programs;

    const newList = [...itemList];
    const draggedItem = newList[source.index];
    newList.splice(source.index, 1);
    newList.splice(destination.index, 0, draggedItem);

    this.setState({ programs: newList });
    console.log("THE LIST", this.state.programs, newList);
  };

  onDragEnd = ({ destination, source, draggableId }) => {
    this.onDragEndHandler(destination, source, draggableId);
    this.setState({ isDragging: false });
  };

  componentDidMount() {
    this.addScrollWheelListener();

    this.setState({
      programs: this.props.programs
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isDragging !== this.state.isDragging) {
      this.addScrollWheelListener();
    }
  }

  componentWillUnmount() {
    this.removeScrollWheelListener();
  }

  addScrollWheelListener() {
    if (this.state.isDragging) {
      window.addEventListener("wheel", this.handleScroll, { passive: false });
    } else {
      this.removeScrollWheelListener();
    }
  }

  removeScrollWheelListener() {
    window.removeEventListener("wheel", this.handleScroll);
  }

  handleScroll = event => {
    event.preventDefault();
  };

  getDisplayableDateAndTimeRangeForPrograms = (p, alsoDateRange) => {
    //Changes were made in the API to return IANA timezone info (eg. America/Toronto) with every product listing.
    console.assert(p.timezone, "timezone not provided for partner/educator!");

    let { dateOnlyWithTZ, formattedDate } = constructDateOnlyWithTimeZone(
      p.program_start,
      p.timezone
    );

    let formattedTimeRange = "--";
    if (
      p.timing &&
      p.timing.length === 2 &&
      p.timing[0] !== "-1" &&
      p.timing[1] !== "-1"
    ) {
      // timezone_offset is a deprecated column and marked for removal in the future!
      const timezone_offset = p.timezone_offset || 0;
      //column was only available for online virtual products! So flag it, if non-zero or non-null!
      console.assert(
        timezone_offset === 0,
        `expecting timezone_offset column for OnlineVirtual#${p.id} to be zero or null!`
      );

      //Below, "dateOnlyWithTZ" is used to frame the time ranges within the correct timezone.
      let { startTime, endTime } = constructTimeRangeFromDecimalHours(
        parseFloat(p.timing[0]) -
          timezone_offset /*@@DEPRECATED@@: Will remove timezone_offset column in the future!*/,
        parseFloat(p.timing[1]) -
          timezone_offset /*@@DEPRECATED@@: Will remove timezone_offset column in the future!*/,
        dateOnlyWithTZ
      );
      formattedTimeRange = getFormattedTimeRange(startTime, endTime);
    }

    if (alsoDateRange) {
      const formattedDateRange = getFormattedDateRange(
        dateOnlyWithTZ,
        p.program_final_date,
        " to ",
        true
      );
      return { formattedTimeRange, formattedDate, formattedDateRange };
    } else {
      return { formattedTimeRange, formattedDate };
    }
  };

  getStyle(scrollable) {
    if (scrollable) {
      return {
        background: "#f7f7f8",
        height: "auto",
        width: "auto"
      }
    } else {
      return {
        background: "#f7f7f8",
        height: "inherit",
        width: "inherit"
      }
    }
  }

  render() {
    console.log("MODAL STATE", this.state);
    return (
      <div className="request-modal-cont">
        <div
          className="request-modal lesspadding"
          id="program-listing-preview"
          style={{ maxWidth: "650px", maxHeight: "100%", overflowY: "hidden" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              //   alignItems: "center",
              height: "48px",
              paddingLeft: "20px",
              borderBottom: "1px solid lightgrey"
            }}
          >
            <h3 style={{ color: "#2938be", paddingLeft: "20px" }}>
              Online Virtual Listing Preview
            </h3>
            <IconButton
              style={{
                borderRadius: "50%",
                color: "#2880FF",
                marginBottom: "0.5rem"
              }}
              onClick={_ => this.props.onClose()}
            >
              <Close />
            </IconButton>
          </div>
          <div
            style={{ overflowY: "auto", overflowX: "hidden", height: "400px" }}
          >
            <div>
              <div
                className="container-fluid adminprofiles"
                style={{ background: "#f7f7f8" }}
              >
                <div
                  className="row"
                  style={{
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    paddingBottom: "1rem",
                    paddingTop: "5px"
                  }}
                >
                  <>
                    <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                      <div className="tablecont">
                        <div
                          className="table-responsive"
                          style={{ fontSize: "16px", overflowY: "hidden" }}
                        >
                          {/* <NewTabbedTable
                              tabs={this.tabs}
                              headings={this.headings}
                              data={this.state.data}
                              actions={this.actions}
                            /> */}
                          {this.state.programs.length > 0 ? (
                            <DragDropContext
                              onDragEnd={this.onDragEnd}
                              onBeforeDragStart={this.onBeforeDragStart}
                            >
                              <Droppable droppableId="onlineTableItems">
                                {(provided, snapshot) => (
                                  <tbody
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    // style={getListStyle(snapshot.isDraggingOver)}
                                  >
                                    {this.state.programs
                                      .filter(e => e.published)
                                      .map((row, k) => {
                                        const {
                                          formattedTimeRange,
                                          formattedDate
                                        } =
                                          this.getDisplayableDateAndTimeRangeForPrograms(
                                            row
                                          );

                                        // Get list of sorted prices per week.
                                        let prices = row.plans
                                          .map(f => JSON.parse(f))
                                          .filter(plan => !plan.archived)
                                          .filter(plan => {
                                            if (plan.hasOwnProperty("active")) {
                                              return plan.active;
                                            }
                                            return true;
                                          })
                                          .map(plan => {
                                            if (plan.type === "Free") {
                                              return 0;
                                            } else if (
                                              plan.type === "Semester" ||
                                              plan.type === "One-Time" ||
                                              plan.type === "Single Sessions"
                                            ) {
                                              return parseInt(plan.total_price);
                                            }
                                            // Recurring programs - normalize price -> $/wk
                                            else {
                                              return parseInt(
                                                plan.tuition_rate
                                              );
                                            }
                                          })
                                          .sort((a, b) => a - b);

                                        let priceText;
                                        if (prices.length === 1) {
                                          if (prices[0] === 0) {
                                            priceText = "Free";
                                          } else {
                                            priceText = `$${prices[0]}`;
                                          }
                                        } else {
                                          priceText = `$${prices[0]} to $${
                                            prices[prices.length - 1]
                                          }`;
                                        }

                                        return (
                                          <Draggable
                                            draggableId={`${row.id}`}
                                            index={k}
                                            key={row.id}
                                          >
                                            {(provided, snapshot) => {
                                              return (
                                                <tr
                                                  key={k}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  ref={provided.innerRef}
                                                >
                                                  <div
                                                    className={`preview-card`}
                                                    style={{
                                                      height: "60px",
                                                      marginBottom: "5px"
                                                    }}
                                                  >
                                                    <LazyLoadImage
                                                      src={`${process.env.REACT_APP_DS_FILES_S3}/${row.program_photo}`}
                                                      alt="Program"
                                                      // className={
                                                      //   this.props.classes
                                                      //     .cardImg
                                                      // }
                                                      //   wrapperClassName="card-img"
                                                      className={
                                                        "preview-image"
                                                      }
                                                      effect="opacity"
                                                    />

                                                    <div className="card-content-preview">
                                                      <div className="card-sub1 card-sub1-preview">
                                                        <h4
                                                          className="sub1-name"
                                                          id="sub1-name-preview"
                                                        >
                                                          {row.program_name}
                                                        </h4>
                                                      </div>
                                                      <div className="card-sub2 card-sub2-preview">
                                                        <h4
                                                          className="sub2-timing sub2-timing-preview"
                                                          style={{
                                                            fontSize: "12px"
                                                          }}
                                                        >
                                                          {formattedTimeRange}
                                                        </h4>
                                                        <div
                                                          className="sub2-date-preview"
                                                          style={{
                                                            fontSize: "10px"
                                                          }}
                                                        >
                                                          {formattedDate}
                                                        </div>
                                                      </div>
                                                      {/* <div className="card-sub3">
                                                        <h4 className="sub3-pricing">
                                                          {priceText}
                                                        </h4>
                                                      </div> */}
                                                    </div>
                                                  </div>
                                                </tr>
                                              );
                                            }}
                                          </Draggable>
                                        );
                                      })}
                                  </tbody>
                                )}
                              </Droppable>
                            </DragDropContext>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="preview-listing-footer">
            <button
              className="preview-listing-btn"
              onClick={async () => {
                const updateOrderEp = `${process.env.REACT_APP_API}/partners/virtual/updateOrder`;
                const resultUpdateOrder = await axios.put(
                  updateOrderEp,
                  this.state.programs
                );
                if (resultUpdateOrder.data.success) {
                  toast.success("Listings Order Saved!");
                  this.props.onOrderSave(this.state.programs);
                  this.props.onClose();
                }
              }}
            >
              Save Listing Order
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PreviewOnlineListingsModal);
