// import { useState, useEffect, useGlobal } from "reactn";
// import axios from "axios";
// import { format, addWeeks, parse } from "date-fns";

// import _ from "lodash";

// /**
//  * Structures the order info into the table format for use of the orders receipt
//  * @param {Array} - Invoice Line Item data
//  * @param {Object} - Order data from the backend, can be program, online, event or membership
//  * @returns {Object} {bodyData, footData, invoiceTotal}
//  */
// export const useInvoiceItemData = (invoiceDetails, data) => {
//   const bodyData = [];
//   const footData = [];
//   let total = 0;
//   let overallItemDiscount = 0;

//   console.log("invoiceDetails", invoiceDetails);
//   console.log("data", data);

//   const invoiceTotalDiscount = data.invoice_discount;
//   console.log("invoiceTotalDiscount", invoiceTotalDiscount);
//   let discountOnTotal = 0;

//   invoiceDetails.map((invoice, i) => {
//     const itemName = invoice.itemName;
//     const rate = `$${parseFloat(invoice.rate / 100).toFixed(2)}`;
//     let discountPrice = 0;

//     if (invoice?.discount?.type === "Percentage") {
//       console.log(
//         "parseFloat(invoice.amount_off / 100)",
//         parseFloat(invoice?.discount?.amount_off) / 100
//       );
//       console.log("parseFloat(invoice.rate)", parseFloat(invoice.rate));

//       const price =
//         (parseFloat(invoice.rate / 100) *
//           parseInt(invoice.quantity) *
//           parseFloat(invoice?.discount?.amount_off)) /
//         100;
//       discountPrice = price;
//     }
//     if (invoice?.discount?.type === "Amount") {
//       discountPrice = parseFloat(invoice?.discount?.amount_off);
//     }

//     console.log("discountPrice", discountPrice);

//     const discount = invoice.discount
//       ? `- $${parseFloat(discountPrice).toFixed(2)}`
//       : "---";
//     const quantity = invoice.quantity;
//     const amount = `$${parseFloat(
//       parseFloat(invoice.amount / 100) * parseInt(invoice.quantity) -
//         discountPrice
//     ).toFixed(2)}`;

//     const totalAmt =
//       parseFloat(invoice.rate / 100) * parseInt(invoice.quantity) -
//       discountPrice;
//     overallItemDiscount = overallItemDiscount + discountPrice;
//     total = total + totalAmt;

//     const isAddDiscount = invoice?.discount === null ? true : false;

//     const data = [
//       { value: itemName, type: "display" },
//       { value: rate, type: "display" },
//       { value: discount, type: "display" },
//       { value: quantity, type: "display" },
//       { value: amount, type: "display" },
//       { value: "", type: "action", isAddDiscount: isAddDiscount, indexValue: i }
//     ];
//     console.log("data", data);

//     // Remove the action item if noEdit flag (for the original listing line item).
//     if (invoice.noEdit) {
//       data.pop();
//     }

//     bodyData.push(data);
//     return invoice;
//   });

//   console.log("discountOnTotal 1", discountOnTotal);

//   if (invoiceTotalDiscount?.type === "Percentage") {
//     console.log("percentage discount tot");
//     discountOnTotal =
//       total * parseFloat(invoiceTotalDiscount?.amount_off / 100);
//   }
//   if (invoiceTotalDiscount?.type === "Amount") {
//     console.log("amt discount tot");
//     discountOnTotal = parseFloat(invoiceTotalDiscount?.amount_off);
//   }
//   console.log("discountOnTotal 2", discountOnTotal);

//   const discountTotal = discountOnTotal * 100;
//   const subTotal = total * 100;
//   const finalTotal = (total - discountOnTotal) * 100;
//   console.log("finalTotal", finalTotal);

//   footData.push([
//     { value: "Sub Total" },
//     { value: "" },
//     { value: `- $${parseFloat(overallItemDiscount).toFixed(2)}` },
//     { value: "" },
//     { value: `$${parseFloat(total).toFixed(2)}` }
//   ]);
//   footData.push([
//     { value: "Total", style: { fontWeight: "bold", color: "black" } },
//     { value: "" },
//     { value: `- $${parseFloat(discountOnTotal).toFixed(2)}` },
//     { value: "" },
//     {
//       value: `$${parseFloat(total - discountOnTotal).toFixed(2)}`,
//       style: { fontWeight: "bold", color: "black" }
//     }
//   ]);

//   if (data.userPaidAmount != 0) {
//     footData.push([
//       {
//         value: `User Paid ${
//           data.userPaymentMethod
//             ? `(${data.userPaymentMethod}${
//                 data.userPaymentMethod === "Credit Card" ? " 3%" : ""
//               })`
//             : ""
//         }`,
//         style: { fontWeight: "bold", color: "black" }
//       },
//       { value: "" },
//       { value: "" },
//       { value: "" },
//       {
//         value: `$${parseFloat(data.userPaidAmount / 100).toFixed(2)}`,
//         style: { fontWeight: "bold", color: "black" }
//       }
//     ]);
//   }

//   return {
//     bodyData,
//     footData,
//     subTotal,
//     finalTotal,
//     discountTotal
//   };
// };

import { useState, useEffect, useGlobal } from "reactn";
import axios from "axios";
import { format, addWeeks, parse } from "date-fns";

import _, { orderBy } from "lodash";

/**
 * Structures the order info into the table format for use of the orders receipt
 * @param {Array} - Invoice Line Item data
 * @param {Object} - Order data from the backend, can be program, online, event or membership
 * @returns {Object} {bodyData, footData, invoiceTotal}
 */
export const useInvoiceItemData = (invoiceDetails, data) => {
  const bodyData = [];
  const footData = [];
  let total = 0;
  let originalTotal = 0;
  let overallItemDiscount = 0;
  let tax = 0;

  console.log("invoiceDetails", invoiceDetails);
  console.log("data", data);

  const invoiceTotalDiscount = data.invoice_discount;
  const invoiceTax = data.invoice_tax;
  console.log("invoiceTotalDiscount", invoiceTotalDiscount);
  let discountOnTotal = 0;

  invoiceDetails.map((invoice, i) => {
    const itemName = invoice.itemName;
    // const rate = `$${parseFloat(invoice.rate / 100).toFixed(2)}`;
    const rate =
      data?.original_amount && parseFloat(data.original_amount) !== 0
        ? `$${(parseFloat(data.original_amount) / 100).toFixed(2)}`
        : `$${parseFloat(invoice.rate / 100).toFixed(2)}`;
    let discountPrice = 0;

    if (invoice?.discount?.type === "Percentage") {
      console.log(
        "parseFloat(invoice.amount_off / 100)",
        parseFloat(invoice?.discount?.amount_off) / 100
      );
      console.log("parseFloat(invoice.rate)", parseFloat(invoice.rate));

      const price =
        (parseFloat(invoice.rate / 100) *
          parseInt(invoice.quantity) *
          parseFloat(invoice?.discount?.amount_off)) /
        100;
      discountPrice = price;
    }
    if (invoice?.discount?.type === "Amount") {
      discountPrice = parseFloat(invoice?.discount?.amount_off);
    }

    console.log("discountPrice", discountPrice);

    const discount = invoice.discount
      ? `- $${parseFloat(discountPrice).toFixed(2)}`
      : "---";
    const quantity = invoice.quantity;
    const amount = `$${parseFloat(
      parseFloat(invoice.amount / 100) * parseInt(invoice.quantity) -
        discountPrice
    ).toFixed(2)}`;

    const totalAmt =
      parseFloat(invoice.rate / 100) * parseInt(invoice.quantity) -
      discountPrice;
    overallItemDiscount = overallItemDiscount + discountPrice;
    originalTotal +=
      parseFloat(invoice.rate / 100) * parseInt(invoice.quantity);
    total = total + totalAmt;

    const isAddDiscount = invoice?.discount === null ? true : false;

    const newdata = [
      { value: itemName, type: "display" },
      { value: rate, type: "display" },
      { value: discount, type: "display" },
      { value: quantity, type: "display" },
      { value: amount, type: "display" },
      { value: "", type: "action", isAddDiscount: isAddDiscount, indexValue: i }
    ];
    console.log("body data", newdata);

    // Remove the action item if noEdit flag (for the original listing line item).
    if (invoice.noEdit) {
      newdata.pop();
    }

    bodyData.push(newdata);
    return invoice;
  });

  console.log("discountOnTotal 1", discountOnTotal);

  if (invoiceTax && !data.justDeposit) {
    tax = total * (invoiceTax.percentage / 100);
  }

  if (invoiceTotalDiscount?.type === "Percentage") {
    console.log("percentage discount tot");
    discountOnTotal =
      (total + tax) * parseFloat(invoiceTotalDiscount?.amount_off / 100);
  }
  if (invoiceTotalDiscount?.type === "Amount") {
    console.log("amt discount tot");
    discountOnTotal = parseFloat(invoiceTotalDiscount?.amount_off);
  }
  console.log("discountOnTotal 2", discountOnTotal);

  const discountTotal = discountOnTotal * 100;
  // const subTotal = total * 100;

  console.log(
    "data.irem amount",
    data.itemAmount,
    "next",
    total,
    "after",
    data.itemAmount || total * 100
  );
  const subTotal = originalTotal * 100;
  // const subTotal =
  //   data.itemAmount && data.itemAmount !== 0 && parseInt(data.itemAmount) !== 0
  //     ? data.itemAmount
  //     : total * 100;
  // const subTotal = data.itemAmount || total * 100;
  const finalTotal = Math.round((total - discountOnTotal + tax) * 100);
  console.log("finalTotal", finalTotal);

  footData.push([
    { value: "Sub Total" },
    { value: "" },
    { value: `- $${parseFloat(overallItemDiscount).toFixed(2)}` },
    { value: "" },
    { value: `$${parseFloat(total).toFixed(2)}` }
  ]);
  if ((!data.justDeposit && parseInt(data.taxAmount) !== 0) || tax !== 0) {
    footData.push([
      { value: "Taxes" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: `$${(parseFloat(data.taxAmount) / 100).toFixed(2)}` }
    ]);
  }
  console.log("DAATATATA", data);
  footData.push([
    { value: "Total", style: { fontWeight: "bold", color: "black" } },
    { value: "" },
    { value: `- $${parseFloat(discountOnTotal).toFixed(2)}` },
    { value: "" },
    {
      value: `$${parseFloat(
        total -
          discountOnTotal +
          parseFloat(data.creditCardFeeAmount / 100) +
          parseFloat(data.justDeposit ? 0 : data.taxAmount) / 100
      ).toFixed(2)}`,
      style: { fontWeight: "bold", color: "black" }
    }
  ]);

  if (data.userPaidAmount != 0) {
    footData.push([
      {
        value: `User Paid ${
          data.userPaymentMethod
            ? `(${data.userPaymentMethod}${
                data.userPaymentMethod === "Credit Card" ? " 3%" : ""
              })`
            : ""
        }`,
        style: { fontWeight: "bold", color: "black" }
      },
      { value: "" },
      { value: "" },
      { value: "" },
      {
        value: `$${parseFloat(data.userPaidAmount / 100).toFixed(2)}`,
        style: { fontWeight: "bold", color: "black" }
      }
    ]);
  }

  return {
    bodyData,
    footData,
    subTotal,
    finalTotal,
    discountTotal,
    tax: Math.round(tax * 100)
  };
};
