import React from "react";
import { DdIcon, CheckImg } from "../../../assets";
import { format, isSameDay } from "date-fns";

class RecentPayments extends React.PureComponent {
  render() {
    const { invoices } = this.props;

    return (
      <div className="col-xl-6 col-xl-6-new">
        <div className="tablebox" style={{ height: "80%" }}>
          <div className="tableheader">
            <h2>
              RECENT PAYMENTS <img src={DdIcon} alt="" />
            </h2>
          </div>

          <div className="tablebody" style={{ padding: "0 10px" }}>
            <div
              className="row"
              style={{ marginTop: "15px", marginBottom: "5px" }}
            >
              <div className="col-2" style={{ padding: 0 }}></div>
              <div className="col-4" style={{ padding: 0 }}>
                <h3 style={{ textAlign: "center" }}>DESCRIPTION</h3>
              </div>
              <div className="col-3" style={{ padding: 0 }}>
                <h3 style={{ textAlign: "center" }}>STATUS</h3>
              </div>
              <div className="col-3" style={{ padding: 0 }}>
                <h3 style={{ textAlign: "center" }}>DATE</h3>
              </div>
            </div>
            {invoices.map(inv => (
              <div className="mysuperdiv" key={inv.id}>
                <div
                  className="row"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="col-2" style={{ padding: 0 }}>
                    <h2
                      className="price"
                      style={{
                        fontWeight: "450",
                        fontSize: "16px",
                        lineHeight: "normal",
                        textAlign: "center"
                      }}
                    >
                      ${parseFloat(inv.amount / 100).toFixed(2)}
                    </h2>
                  </div>
                  <div className="col-4" style={{ padding: 0 }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "#a8acb3",
                        textAlign: "center"
                      }}
                    >
                      {inv.description}
                    </p>
                  </div>
                  <div className="col-3" style={{ padding: 0 }}>
                    <div
                      className="divcenterspan"
                      style={{
                        display: "flex",
                        justifyContent: "space-around"
                      }}
                    >
                      {inv.status.toLocaleLowerCase() === "succeeded" ? (
                        <span
                          style={{
                            backgroundColor: "#1bc88a",
                            borderRadius: "13px",
                            padding: "3px 10px 3px 10px",

                            color: "#FFFFFF",
                            display: "inline-block",
                            textAlign: "center"
                          }}
                        >
                          {inv.status[0].toLocaleUpperCase() +
                            inv.status.slice(1)}
                        </span>
                      ) : (
                        <span
                          style={{
                            backgroundColor: "#ff3b8e",
                            borderRadius: "13px",
                            padding: "3px 10px 3px 10px",

                            color: "#FFFFFF",
                            display: "inline-block",
                            textAlign: "center"
                          }}
                        >
                          {inv.status}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-3" style={{ padding: 0 }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "#a8acb3",
                        textAlign: "center"
                      }}
                    >
                      {new Date(inv.created * 1000).toDateString()}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {invoices.length ? null : (
            <p className="p-5 text-center w-100">
              Nothing to show...{" "}
              <span style={{ fontStyle: "italic" }}>Yet!</span>
            </p>
          )}
        </div>
      </div>
    );
  }
}
export default RecentPayments;
