import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "../../assets/css/componentSpecificCss/applicationViewModal.css";
import moment from "moment";
import {
  format,
  addWeeks,
  addDays,
  addHours,
  subDays,
  isAfter
} from "date-fns";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
// import { ReactPDF, PDFViewer , BlobProvider, PDFDownloadLink, pdf, Document, Page } from '@react-pdf/renderer';
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import SwitchEnrollmentModal from "../../components/modals/SwitchEnrollmentModal";
import ApplicationDenyNotificationModal from "./ApplicationDenyNotificationModal";
import ViewPartyAddonsModal from "./ViewPartyAddonsModal";

import {
  CONTENT_BLOCK,
  BUTTON,
  TEXT_AREA,
  IMAGE_CONTENT,
  DROPDOWN,
  DATE_PICKER
} from "./baseModal/FieldTypes";
import BaseModal from "./baseModal/BaseModal";
import ApplicationCancelModal from "./ApplicationCancelModal";
import {
  BLUE_BUTTON,
  GREEN_BUTTON,
  RED_BUTTON,
  WHITE,
  YELLOW_BUTTON
} from "./baseModal/colours";
import { result } from "lodash";

const daysOfWeekConversion = day => {
  const table = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  };
  return table[day];
};

const getFirstDay = (startingDate, daysOfWeek) => {
  // Code to compensate for the local timezone of the starting date
  startingDate.setTime(
    startingDate.getTime() + startingDate.getTimezoneOffset() * 60 * 1000
  );

  if (!daysOfWeek) {
    return undefined;
  }
  if (Object.keys(daysOfWeek).length === 0) return;

  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  while (true) {
    if (convertedDaysOfWeek.includes(startingDate.getDay())) {
      return startingDate;
    }
    startingDate = addDays(startingDate, 1);
  }
};

const getLastDay = (lastDay, daysOfWeek) => {
  console.log("lastOfWeek...", daysOfWeek);
  if (!daysOfWeek) {
    return undefined;
  }
  if (Object.keys(daysOfWeek).length === 0) return;
  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  lastDay = addWeeks(lastDay, 1);
  lastDay = subDays(lastDay, 1);
  while (true) {
    if (convertedDaysOfWeek.includes(lastDay.getDay())) {
      return lastDay;
    }
    lastDay = subDays(lastDay, 1);
  }
};

class EditPartyStartDateModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(props.program.partyDetails.startDate)
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  getBaseModalFields() {
    let allFields = []
    
    const startDate = {
      type: DATE_PICKER,
      data: {
        name: "New Start Date",
        required: false,
        // selected: new Date(this.state.date),
        showTimeSelect: true,
        handleChange: date => this.setState({ startDate: date }),
        minDate: new Date(),
        dateFormat: "MMMM dd yyyy, h:mm:ss a",
        placeholder: "Pick a date",
        value: this.state.startDate
      }
    };

    allFields.push(startDate)

    return allFields;
  }

  getBaseModalButtons() {
    const saveButton = {
      name: "Save",
      handleClick: async () => {
        const ep = `${process.env.REACT_APP_API}/partners/parties/update-start-date`
        const results = await axios.post(ep, {
          program: this.props.program,
          startDate: this.state.startDate
        })

        if (results.data.success) {
          this.props.updatePartyDetails(results.data.data.partyDetails);
          this.props.onClose();
        }
      },
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
    };

    return [saveButton];
  }

  getBaseModalProps() {
    const baseModalProps = {
      title: "Application Details",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };

    return baseModalProps;
  }

  render() {
    if (!this.props) {
      return null;
    }
    console.log("this.state", this.state);
    console.log("this.props", this.props);

    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(EditPartyStartDateModal);
