import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import SignatureCanvas from "react-signature-canvas";
class SignModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      val: -1
    };
    this.sigPad = {};
  }

  requestTour = async e => {
    e.preventDefault();
  };
  trim = () => {
    this.props.complete(this.sigPad.getTrimmedCanvas().toDataURL("image/png"));
    this.props.onClose();
  };
  async submitForm(e) {
    e.preventDefault();
    if (this.state.val > -1) {
      if (this.state.val > 10 && !this.state.question) {
        toast.error("Please Complete the form!");
      } else {
        this.props.addField(
          this.state.val,
          this.state.question,
          this.state.options
        );
        this.props.onClose();
      }
    } else {
      toast.error("Please Complete the form!");
    }
  }

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div>
            <h3>Sign</h3>
            <form onSubmit={e => this.submitForm(e)}>
              <SignatureCanvas
                penColor="green"
                canvasProps={{
                  width: 450,
                  height: 200,
                  className: "sigCanvas",
                  style: { border: "1px solid black", borderRadius: "10px" }
                }}
                ref={r => {
                  this.sigPad = r;
                }}
              />

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={_ => this.trim()}
                  className="btn profile-btn"
                >
                  Sign
                </button>
                <button
                  type="button"
                  className="btn profile-btn"
                  onClick={_ => this.sigPad.clear()}
                >
                  Clear
                </button>
              </div>
            </form>

            <IconButton
              style={{ position: "absolute", right: "0", top: "0" }}
              onClick={_ => this.props.onClose()}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SignModal);
