import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "../../assets/css/componentSpecificCss/applicationViewModal.css";
import BaseModal from "./baseModal/BaseModal";
import { GET_HTML } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import axios from "axios";

class ConnectionsViewModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      formSections: [],
      came_from: ""      
    };
  }

  async fetchData() {
    this.setGlobal({ loading: true });
    
    const ep = `${process.env.REACT_APP_API}/partners/connections/latest-info`;
    const results = await axios.get(ep, {
      params: { 
        table: this.props.program.table,
        id: this.props.program.id
      }
    });

    if (results.data.success) {
      if (!results.data.data) {
        this.setState({ came_from: this.props.program.came_from });
      } else {
        const info = results.data.data;

        const status =
          info.application_required &&
            (info.status_stage === "Accepted_Unpaid" ||
              info.status_stage === "New_Pending")
            ? "Application"
            : "Enrolled";
        
        let name_column = "";

        switch (this.props.program.table) {
          case "programs_customers":
          case "online_customers":
            name_column = "program_name"
            break;
          case "membership_customers":
            name_column = "membership_name";
            break;
          case "event_customers":
            name_column = "event_title";
            break;      
        }
        console.log(info[name_column]);
        this.setState({ came_from: `${status}: ${info[name_column]}`});
      }     
    }
    this.setGlobal({ loading: false });

  }

  async componentDidMount() {
    await this.fetchData();
  }

  requestTour = async e => {
    e.preventDefault();
  };

  displayModalData = () => {
    return (
      /*       <div className="request-modal-cont">
        <div className="request-modal lesspadding"> */
      <div>
        {/* <h3>Connection Details</h3> */}
        <div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Name
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.displayName}
            </span>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Came From
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.state.came_from}
            </span>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Email
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.email}
            </span>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Cell Phone
            </label>
            <span
              style={{
                width: "70%",
                fontSize: "16px",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#626262"
              }}
            >
              {this.props.program.phone}
            </span>
          </div>
        </div>
      </div>
      /*         </div>
      </div> */
    );
  };

  getBaseModalFields() {
    const allFields = {
      type: GET_HTML,
      data: {
        gethtml: this.displayModalData
      }
    };

    const fields = [];
    fields.push(allFields);

    return fields;
  }

  getBaseModalButtons = () => {};

  getBaseModalProps = () => {
    return {
      title: `Connection Details`,
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      height: "65vh",
      midSectionHeight: "49vh"
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div>
            <h3>Connection Details</h3>
            <div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Name
                </label>
                <span
                  style={{ width: "70%", color: "black", fontSize: "16px" }}
                >
                  {this.props.program.displayName}
                </span>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Came From
                </label>
                <span
                  style={{ width: "70%", color: "black", fontSize: "16px" }}
                >
                  {this.props.program.came_from}
                </span>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Email
                </label>
                <span
                  style={{ width: "70%", color: "black", fontSize: "16px" }}
                >
                  {this.props.program.email}
                </span>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Cell Phone
                </label>
                <span
                  style={{ width: "70%", color: "black", fontSize: "16px" }}
                >
                  {this.props.program.phone}
                </span>
              </div>
            </div>

            <IconButton
              style={{ position: "absolute", right: "0", top: "0" }}
              onClick={_ => this.props.onClose()}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ConnectionsViewModal);
