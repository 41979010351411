import axios from "axios";
import { format, addWeeks } from "date-fns";

export const PERCENT = "Percentage";
export const FIXED_AMOUNT = "Fixed Amount";

export async function getPromotionsBySlug(slug) {
  if (!slug) {
    throw new Error("slug is a required field");
  }

  const resp = await axios.get(
    `${process.env.REACT_APP_API}/search/partners/promotions/front/${slug}`
  );

  if (resp) {
    return resp.data;
  }
  return {
    success: false,
    message: "Something went wrong when retreiving Promotions Data"
  };
}

export function convertCentsToDollars(cents) {
  const dollars = cents / 100;
  return dollars.toLocaleString("en-US", {
    style: "currency",
    currency: "CAD"
  });
}

export async function getPromotionBasedOnId(promotionId) {
  if (!promotionId) {
    throw new Error("Promotion Id is required");
  }
  const ep = `${process.env.REACT_APP_API}/search/partners/promotions/front/id/${promotionId}`;
  const resp = await axios.get(ep);
  if (resp.data) {
    return resp.data;
  }
  return { success: false, message: "Something went wrong when getting data " };
}

export function formatExpiryDate(expiry_date) {
  return format(new Date(expiry_date), "dd/MM/yyyy");
}

export function getFormattedProgramDate(date_string, number_of_weeks) {
  return (
    format(new Date(date_string), "LL/dd/yy") +
    " - " +
    format(addWeeks(new Date(date_string), number_of_weeks), "LL/dd/yy")
  );
}

export function isPromotionAppliedToProgramPlan(promotion, plan) {
  if (!promotion.plan_price_ticketId) {
    return false;
  }
  const filterApplied = promotion.plan_price_ticketId.filter(
    aPlan => aPlan === plan.id
  );
  return filterApplied.length === 1;
}

export function getDiscountPrice(promotion, plan) {
  if (promotion.discount_type === "Percentage") {
    return plan.tuition_rate * (1 - promotion.discount_percent / 100);
  } else if (promotion.discount_type === "Fixed Amount") {
    const price = plan.tuition_rate - promotion.discount_fixed / 100;
    return Math.max(price, 0); // do not return negative number
  }
}

export function promotion_discount(promotion, number) {
  if (promotion.discount_type === PERCENT) {
    return getPromotionPercentAmount(number, promotion.discount_percent);
  } else if (promotion.discount_type === FIXED_AMOUNT) {
    return getPromotionFixedAmount(promotion.discount_fixed);
  }
}

export function getPromotionPercentAmount(number, discount) {
  return (number * discount) / 100;
}

export function getPromotionFixedAmount(cents) {
  return cents / 100;
}
