import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import ProgramSearchInput from "../../../../components/common/ProgramSearchInput";

class SectionOne extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  handleChange = (e, v) => {
    this.setGlobal({
      organizationInfo: {
        ...this.global.organizationInfo,
        [e]: v
      }
    });
  };

  customForm = () => {
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-5">
              <h3>
                Organization Title<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  name="organization_title"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.organization_title}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                Address Line 1<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <ProgramSearchInput
                  placeholder="Find a Program"
                  noicon={true}
                  name="search"
                  style={{ marginTop: "0px" }}
                  value={this.global.organizationInfo.address1}
                  gLoc={(e, loc) => {
                    const address = e.split(",");

                    this.handleChange("address1", address[0].trim());
                    this.handleChange("city", address[1].trim());
                    this.handleChange(
                      "province",
                      address[2].trim().split(" ")[0]
                    );
                    this.handleChange(
                      "postalcode",
                      address[2].trim().split(" ")[1] +
                        address[2].trim().split(" ")[2]
                    );
                    this.handleChange("country", address[3].trim());
                    this.handleChange("main_lat", loc.lat);
                    this.handleChange("main_long", loc.lng);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>Address Line 2</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  name="address2"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.address2}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                City<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  name="city"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.city}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                Region / Province<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  name="province"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.province}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                Postal / Zip<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  name="postalcode"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.postalcode}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                Country<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  name="country"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.country}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>Target Ages</h3>
            </div>
            <div className="col-7 d-flex">
              <formTypes.ChildAgeGroupSelect
                value={
                  this.global.organizationInfo.target_ages.map(e =>
                    typeof e === "string" ? JSON.parse(e) : e
                  ) || []
                }
                onChange={(_, value) =>
                  this.handleChange(
                    "target_ages",
                    value.map(e => ({
                      name: e.name
                    }))
                  )
                }
              ></formTypes.ChildAgeGroupSelect>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                Classification<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <select
                  className="input-fields"
                  style={{ width: "100%" }}
                  name="classification"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.classification}
                >
                  <option value="">Please Select</option>
                  <option value="Academics">Academics</option>
                  <option value="After School">After School</option>
                  <option value="Arts">Arts</option>
                  <option value="Athletics">Athletics</option>
                  <option value="Before & After School">
                    Before & After School
                  </option>
                  <option value="Camps">Camps</option>
                  <option value="Cooking">Cooking</option>
                  <option value="Creative">Creative</option>
                  <option value="Dance">Dance</option>
                  <option value="Daycare / Preschool">
                    Daycare / Preschool
                  </option>
                  <option value="Drama">Drama</option>
                  <option value="Exercise">Exercise</option>
                  <option value="Facilities (Rec Centre)">
                    Facilities (Rec Centre)
                  </option>
                  <option value="Faith">Faith</option>
                  <option value="Language / Culture">Language / Culture</option>
                  <option value="Leadership">Leadership</option>
                  <option value="Life Skills">Life Skills</option>
                  <option value="Mixed">Mixed</option>
                  <option value="Music">Music</option>
                  <option value="Outdoors">Outdoors</option>
                  <option value="Playgrounds">Playgrounds</option>
                  <option value="Service Volunteer">Service Volunteer</option>
                  <option value="STEM">STEM</option>
                  <option value="Special Needs">Special Needs</option>
                  <option value="Theatre">Theatre</option>
                  <option value="Tutoring">Tutoring</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                Format<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <select
                  className="input-fields"
                  style={{ width: "100%" }}
                  name="format"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.format}
                >
                  <option value="">Please Select</option>
                  <option value="In-Person">In-Person</option>
                  <option value="Online">Online</option>
                  <option value="Both">Both</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Organization Info"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionOne;
