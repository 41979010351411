import React, { useGlobal } from "reactn";
import { formTypes, BoxForm } from "../../../../components/UI/form";

class SectionOne extends React.PureComponent {
  constructor(props) {
    super(props);
    /*this.setAddress = this.setAddress.bind(this);
    this.customForm = this.customForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._geoSuggest = null;
    this._geoSuggestPrev = null;*/
  }
  handleChange = (e, v) => {
    this.setGlobal({
      news_and_updates: {
        ...this.global.news_and_updates,
        [e]: v
      }
    });
  };

  customForm = () => {
    return (
      <div className="formbody">
        {/* {!this.state.timeChangeShown ? null : (
          <ConfirmTimeChangeForm onClose={this.setTime} />
        )} */}
        <form>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Title<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  className="input-fields"
                  name="title"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.news_and_updates.title}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="label-names">
                Main Body<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-12 d-flex">
              <div className={`w-100`}>
                <textarea
                  className="input-text-fields"
                  name="mainbody"
                  rows={8}
                  style={{ width: "100%" }}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.news_and_updates.mainbody}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="label-names">Bottom Section</h3>
            </div>
            <div className="col-12 d-flex">
              <div className={`w-100`}>
                <textarea
                  className="input-text-fields"
                  name="bottomsection"
                  rows={6}
                  style={{ width: "100%" }}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.news_and_updates.bottomsection}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Organization Info"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionOne;
