import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import { MiniPlus } from "../../../../assets";
import ImageCropModal from "../../../../components/modals/ImageCropModal";
import "../../../../assets/css/componentSpecificCss/eventListings.css";
import { Switch } from "@material-ui/core";
import ReactTooltip from "react-tooltip";

class SectionTwo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { openImageCropModal: false, image: "" };
  }

  handleChange = (e, v) => {
    this.setGlobal({
      party: {
        ...this.global.party,
        [e]: v
      }
    });
  };
  uploadNew() {
    const uploadEle = document.getElementById("uploadPhoto");
    uploadEle.click();
  }

  fileChange(e) {
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      const file = new FileReader();
      file.readAsDataURL(e.target.files[0]);
      file.onloadend = () => {
        document.getElementById("uploadPhoto").value = "";
        this.setState({ image: file.result, openImageCropModal: true });
      };
    }
  }

  customForm = () => {
    return (
      <div className="formbody">
        {this.state.openImageCropModal && (
          <ImageCropModal
            sourceImage={this.state.image}
            onClose={() => {
              this.setState({ openImageCropModal: false });
            }}
            aspectRatio={4 / 3}
            uploadImage={croppedImageURL => {
              const fd = new FormData();
              fd.set("image", croppedImageURL);
              this.dispatch.uploadPartyPhoto(fd);
              this.setState({ openImageCropModal: false });
            }}
          />
        )}
        <form>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Party Image<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <figure
                className="photoContainer"
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginLeft: "6px"
                }}
                onClick={this.uploadNew}
              >
                {this.global.party.party_image ? (
                  <div
                    style={{
                      padding: "2px",
                      width: "130px",
                      height: "80px",
                      border: "1px solid #e3e3e3",
                      display: "inline-block",
                      borderRadius: "8px",
                      cursor: "pointer",
                      backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${this.global.party.party_image})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat"
                    }}
                  ></div>
                ) : (
                  <div
                    style={{
                      padding: "2px",
                      width: "130px",
                      height: "80px",
                      border: "1px solid #e3e3e3",
                      display: "inline-block",
                      borderRadius: "8px",
                      cursor: "pointer"
                    }}
                  >
                    <img
                      src={MiniPlus}
                      alt="Add"
                      style={{ marginTop: "calc(50% - 4.5px)" }}
                    />
                  </div>
                )}
              </figure>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">All Ages</h3>
            </div>
            <div className="col-7 d-flex" style={{ flexWrap: "wrap" }}>
              <Switch
                name="all_ages"
                checked={this.global.party.all_ages}
                onClick={_ => {
                  this.handleChange("all_ages", !this.global.party.all_ages);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Enable Waitlist{" "}
                <i
                  data-tip
                  data-for="enable_waitlist"
                  className="fas fa-info-circle"
                  style={{ color: "#DCDCDC" }}
                ></i>
                <ReactTooltip
                  id="enable_waitlist"
                  place="right"
                  effect="solid"
                  type="info"
                >
                  Customers will be waitlisted once all total spots get filled
                </ReactTooltip>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className="w-100">
                <Switch
                  name="enable_waitlist"
                  checked={this.global.party.enable_waitlist || false}
                  onClick={_ => {
                    this.handleChange(
                      "enable_waitlist",
                      !this.global.party.enable_waitlist
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Enable Continuous Registration{" "}
                <i
                  data-tip
                  data-for="enable_continuous_registration"
                  className="fas fa-info-circle"
                  style={{ color: "#DCDCDC" }}
                ></i>
                <ReactTooltip
                  id="enable_continuous_registration"
                  place="right"
                  effect="solid"
                  type="info"
                >
                  Ability for parents to enroll / register after the party's
                  start date up until the party's end date
                </ReactTooltip>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className="w-100">
                <Switch
                  name="continuous_registration"
                  checked={this.global.party.continuous_registration || false}
                  onClick={_ => {
                    this.handleChange(
                      "continuous_registration",
                      !this.global.party.continuous_registration
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Target Age Groups</h3>
            </div>
            <div className="col-7 d-flex" style={{ alignItems: "center" }}>
              <div style={{ width: "40%" }}>
                <formTypes.Select
                  options={new Array(62)
                    .fill(1)
                    .map((_, k) =>
                      k === 0
                        ? { label: `Please Select`, value: `Please Select` }
                        : { label: `${k - 1} years`, value: k - 1 }
                    )}
                  value={this.global.party.target_ages[0]}
                  onChange={e => {
                    const ageEntered = e.target.value;
                    const prevRange = this.global.party.target_ages;
                    if (parseInt(ageEntered) > parseInt(prevRange[1])) {
                      // If first age larger than second age, adjust second age
                      // to match first age.
                      this.handleChange("target_ages", [
                        ageEntered,
                        ageEntered
                      ]);
                    } else {
                      this.handleChange("target_ages", [
                        ageEntered,
                        prevRange[0]
                      ]);
                    }
                  }}
                  isDisabled={this.global.party.all_ages}
                />
              </div>
              To
              <div style={{ width: "40%" }}>
                <formTypes.Select
                  options={new Array(62)
                    .fill(1)
                    .map((_, k) =>
                      k === 0
                        ? { label: `Please Select`, value: "Please Select" }
                        : { label: `${k - 1} years`, value: k - 1 }
                    )}
                  value={this.global.party.target_ages[1]}
                  onChange={e => {
                    const ageEntered = e.target.value;
                    const prevRange = this.global.party.target_ages;
                    if (parseInt(ageEntered) < parseInt(prevRange)) {
                      // If first age larger than second age, adjust second age
                      // to match first age.
                      this.handleChange("target_ages", [
                        ageEntered,
                        ageEntered
                      ]);
                    } else {
                      this.handleChange("target_ages", [
                        prevRange[0],
                        ageEntered
                      ]);
                    }
                  }}
                  isDisabled={this.global.party.all_ages}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="label-names">
                Party Description<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-12 d-flex" style={{ flexWrap: "wrap" }}>
              <textarea
                className="input-text-fields"
                rows="6"
                style={{ width: "100%" }}
                name="description"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={this.global.party.description}
              />
            </div>
          </div>
          <input
            type="file"
            style={{ display: "none" }}
            id="uploadPhoto"
            onChange={e => this.fileChange(e)}
            accept="image/x-png,image/gif,image/jpeg"
          />
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Set-up"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionTwo;
