import React from "reactn";
import axios from "axios";
import BaseModal from "./baseModal/BaseModal";
import {
  CONTENT_BLOCK,
  TABLE
} from "./baseModal/FieldTypes";
import { addWeeks, differenceInSeconds, format, isBefore, isSameDay, isSameHour, isSameMinute, isSameSecond, max, min } from "date-fns";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { withRouter } from "react-router-dom";

class ViewArchivedOnlineDetailsModal extends React.PureComponent {
  constructor (props){
    super(props);
    this.state = {
      customers: []
    }
  }

  async componentDidMount () {
    const ep = `${process.env.REACT_APP_API}/partners/virtual/customers/listing/${this.props.program.id}`;
    const customers = await axios.get(ep);
    const sortedCustomers = {
      Enrollments: [],
      Applications: [],
      Inactive: []
    }
    /* 
     * This section is used to recreate the state of the customers 
     * table before the Partner made them all inactive and archived
     * the listing. There are a few key assumptions that are made 
     * in order for this to accurately recreate the table:
     *    1. The Partner makes the customers inactive by either clicking "Cancel Program"
     *        or by manually making them all inactive
     *    2. The Partner intended to make the customer inactive for the purpose of
     *        archiving the listing rather than for any other reason if they do so within 1 hour
     *        of archiving the listing
     *    3. If the Partner is manually making the customers inactive, then they will be able to
     *        make all of the customers inactive within 1 hour
     *    4. If the Partner clicked "Cancel Program", then all the customers that needed to be cancelled
     *        will be finished within 1 minute
     *    4. The Partner will archive the listing within 1 day of changing making the customers inactive
     *    5. Once the customers are Inactive, the customers' updatedAt time will no longer be changed
     *    6. The Inactive status can only be achieved by enrolling into the class and appearing under the 
     *        Enrollments tab at some point but then being moved into the Inactive tab
     */
    
    console.log(customers.data.data);
    if (customers.data.success) {
      const lastUpdateTime = max(customers.data.data.map(customer => new Date(customer.updatedAt)));
      const archivedCustomers = customers.data.data.filter(customer => customer.archivedDate != null).map(customer => new Date(customer.archivedDate));
      const maxArchivedDate = max(archivedCustomers);
      const filteredArchivedCustomers = archivedCustomers.filter(date => !isSameSecond(date, maxArchivedDate));
      const usedCancelProgram = this.props.program.isRecurring
        ? filteredArchivedCustomers.length < archivedCustomers.length
        : customers.data.data.reduce((prev, curr) => prev || (curr.archivedDate !== null && curr.status_stage === 'Cancelled'), false);

      for (let customer of customers.data.data) {
        const ep2 = `${process.env.REACT_APP_API}/par/orders/customer/online_customers/${customer.id}/${this.props.program.auto_withdraw}`;

        const res = await axios.get(ep2);
        const order = res.data.data[0];
        console.log("ordre", customer.id, order);
        if (order) {
          customer.startDate = customer.startDate || order.orderStartDate || this.props.program.program_start;
          customer.endDate = this.props.program.isRecurring ? this.props.program.archivedDate : addWeeks(new Date(this.props.program.program_start), this.props.program.number_of_weeks)
        }

        if(customer.status_stage === 'Cancelled') {
          if (order && order.status === "Paid" && isSameHour(new Date(customer.archivedDate), new Date(this.props.program.archivedDate))) {
            sortedCustomers.Enrollments.push(customer);
          } else if (order && order.status === "Paid") {
            console.log("Order into inactive", order);
            sortedCustomers.Inactive.push(customer);
          } else {
            console.log("Order into applications", order);
            console.log("customer:", customer);
            sortedCustomers.Applications.push(customer);
          }
        } else if (customer.status_stage === 'Inactive') {
          if ((isSameHour(new Date(customer.updatedAt), lastUpdateTime) || 
              isSameDay(new Date(this.props.program.archivedDate), new Date(customer.updatedAt))) 
              && order.status === 'Paid'
              && !usedCancelProgram) {
            sortedCustomers.Enrollments.push(customer);
          } else if (order.status === 'Paid') {
            sortedCustomers.Inactive.push(customer);
          }
        } else if (customer.status_stage === 'Cancelled_Unpaid') {
          if (isSameDay(new Date(this.props.program.archivedDate), new Date(customer.updatedAt))
            && !usedCancelProgram) {
            sortedCustomers.Applications.push(customer);
          } else {
            sortedCustomers.Inactive.push(customer);
          }
        } else if (customer.status_stage === 'Rejected') {
          if (isSameDay(new Date(this.props.program.archivedDate), new Date(customer.updatedAt))
            && !usedCancelProgram) {
            sortedCustomers.Applications.push(customer);
          } else {
            sortedCustomers.Inactive.push(customer);
          }
        }
      }

      this.setState({customers: sortedCustomers});
    } else {
      console.log("Something went wrong while retrieving customer information");
    }
  }

  getBaseModalFields() {
    const {
      addChild,
      all_ages,
      age_range,
      createdAt,
      days_of_week,
      program_category,
      program_details,
      program_name,
      timing
    } = this.props.program;

    const formatContentField = ({ name, content }) => ({
      type: CONTENT_BLOCK,
      data: {
        name,
        content,
        titleStyles: { fontSize: "2rem" },
        style: { fontSize: "1.5rem" }
      }
    });

    const programNameField = formatContentField({
      name: "Program Name",
      content: program_name
    });

    const programCreatedAtField = formatContentField({
      name: "Created On",
      content: format(new Date(createdAt), "MMMM dd, yyyy")
    });

    const programCategoryField = formatContentField({
      name: "Program Category",
      content: program_category
    });

    const programDetailField = formatContentField({
      name: "Program Type",
      content: program_details
    });

    const programAgeField = formatContentField({
      name: "Target Age Range",
      content: all_ages ? "All Ages" : `${age_range[0]} ${age_range[0] === "1" ? "year" : "years"} - ${age_range[1]} ${age_range[1] === "1" ? "year" : "years"}`
    });

    const programTargetChildrenField = formatContentField({
      name:"Target Children",
      content: addChild ? "Yes" : "No"
    });

    const programDaysField = formatContentField({
      name: "Active Days",
      content: Object.keys(days_of_week).filter(day => days_of_week[day]).join(", ")
    });

    const startTime = timing[0].split(".");
    const endTime = timing[1].split(".");
    const startHour = startTime[0];
    const startMin = parseFloat("." + startTime[1]) * 60;
    const endHour = endTime[0];
    const endMin = parseFloat("." + endTime[1]) * 60;
    const programTimingField = formatContentField({
      name: "Active Times",
      content: `${startHour}:${startTime.length === 2 ? startMin : "00"}-${endHour}:${endTime.length === 2 ? endMin : "00"}`
    });

    const programCustomersTable = {
      type: TABLE,
      data: {
        name: "Customers Snapshot before Archiving",
        addChild: addChild,
        customers: this.state.customers,
        plans: this.props.program.plans,
        tableStyles: {
          marginTop: "-60px"
        },
        enrollmentTabName: "Enrollments"
      }
    }

    let allFields = [];
    allFields.push(programNameField);
    allFields.push(programCreatedAtField);
    allFields.push(programCategoryField);
    allFields.push(programDetailField);
    allFields.push(programAgeField);
    allFields.push(programTargetChildrenField);
    allFields.push(programDaysField);
    allFields.push(programTimingField);
    allFields.push(programCustomersTable);

    return allFields;
  }

  getBaseModalButtons () {
    const closeButton = {
      name: "Close",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      handleClick: this.props.onClose
    };

    return [closeButton];
  };

  getBaseModalProps() {
    const baseModalProps = {
      title: "Online Virtual Details",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };

    return baseModalProps;
  }

  render () {
    return <BaseModal {...this.getBaseModalProps()} />
  }
};

export default withRouter(ViewArchivedOnlineDetailsModal);