import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";

class SectionTwo extends React.PureComponent {
  handleChange = (e, v) => {
    this.setGlobal({
      membership: {
        ...this.global.membership,
        [e]: v
      }
    });
  };

  customForm = () => {
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-12">
              <h3 className="label-names">Membership Description</h3>
            </div>
            <div className="col-12 d-flex">
              <textarea
                className="input-text-fields"
                rows="6"
                style={{ width: "100%" }}
                name="description"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={this.global.membership.description}
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="label-names">Additional Perks (optional)</h3>
            </div>
            <div className="col-12 d-flex">
              <textarea
                className="input-text-fields"
                rows="6"
                style={{ width: "100%" }}
                name="additional_perks"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={this.global.membership.additional_perks}
              ></textarea>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Set-up"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionTwo;
