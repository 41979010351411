import React, { useEffect, useState }from "react";
import { Link } from "react-router-dom";
import {
  getReportsAndAnalyticsPermissions,
} from "../../pages/partners/Reporting/utils";
import "../../assets/css/componentSpecificCss/inviteOverview.css";

export const InviteOverview = ({totalInvites, invitesAccepted, listingType}) => {
  const [viewDetails, setViewDetails] = useState(true);
  useEffect(() => {
    const fetchPermissions = async () => {
      const perms = await getReportsAndAnalyticsPermissions();
      console.log("PERMS", perms);
      const inviteReportPerms = perms.data && perms.data.length > 0 ? perms.data.filter(feature => feature.feature === "Invites Report")[0] : undefined;

      setViewDetails(inviteReportPerms && inviteReportPerms.has_access);
    }

    fetchPermissions();
  }, []);
  return (
    <div className="invite-overview-wrapper">
      <div className="invite-overview-content">
        <div>
          <span>
            Total Invites:
          </span>
          <span> {totalInvites} </span>
        </div>
        <div>
          <span>
            Accepted Invites:
          </span>
          <span>{invitesAccepted}</span>
        </div>
        <div>
          <span>
            Invite Conversions:
          </span>
          <span> {totalInvites === 0 ? "N/A" : `${(invitesAccepted / totalInvites * 100).toFixed(1)}%`}</span>
        </div>
       {viewDetails && <Link to={
          {
            pathname: "../reports/invites-report",
            state: {
              listingType: listingType
            }
          }
        }>
          Details
        </Link>}
      </div>
    </div>
  );
};