import React from "reactn";
import { Table } from "../../components/UI";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import axios from "axios";
import FReg from "../../components/table/admins/FRegistrationModal";
import ComModal from "../../components/table/admins/CommentsModal";
import { format } from "date-fns";
class FamilyProfiles extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: "",
      tours: [],
      original: []
    };
    this.headings = [
      { id: "displayName", label: "Parent" },
      { id: "programName", label: "Program" },
      {
        id: "applicationStage",
        label: "Last Action"
      },
      {
        id: "signInCount",
        label: "Sign-in Count"
      },
      {
        id: "trStat",
        label: "Tour status",
        customCell: r => (
          <td>
            {r.accepted ? "Accepted" : r.canceled ? "Canceled" : "Pending"}
          </td>
        )
      },
      {
        id: "email",
        label: "Email"
      },
      // { id: "programName", label: "Program Name" },
      {
        id: "phone",
        label: "Phone Number"
      },
      {
        id: "createdAt",
        label: "# of Days in System",
        customCell: this.daysInSystem
      },
      {
        id: "referral",
        label: "Came from",
        customCell: r => (
          <td>
            {!r.referral
              ? "-"
              : r.referral === "facebook"
              ? "FB/IG"
              : r.referral}
          </td>
        )
      },
      { id: "action", label: "Actions" }
    ];
    this.tourHeading = [
      { id: "displayName", label: "Parent" },
      {
        id: "programName",
        label: "Program"
      },

      {
        id: "email",
        label: "Email"
      },
      {
        id: "phone",
        label: "Phone"
      },
      // { id: "programName", label: "Program Name" },
      {
        id: "requestedTourDate",
        label: "Tour Date",
        customCell: row => (
          <td key={`${row.id}-acc`}>
            {format(new Date(row.requestedTourDate), "LLL dd, yyyy - h:mm a")}
          </td>
        )
      },
      {
        id: "tourStatus",
        label: "Tour Status",
        customCell: this.acceptedCell
      },
      {
        id: "currentIP",
        label: "Location / IP",
        customCell: this.location
      }
    ];
    this.actions = [
      { name: "View Details", action: this.viewDetail },
      { name: "Notes", action: this.viewComments }
    ];
    this.filterData = this.filterData.bind(this);
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/family/profiles`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      this.setState({
        data: res.data.data.parents,
        original: res.data.data.parents
      });

      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  acceptedCell = (r, i) => {
    return (
      <td key={`${i}-${r.id}-acc`}>
        {r.canceled
          ? `Canceled by : ${r.canceledBy}`
          : r.accepted
          ? "Tour Accepted"
          : Date.now() > new Date(r.requestedTourDate).getTime()
          ? "Tour Expired"
          : !r.rescheduled
          ? `Pending Acceptation from ${r.educatorname}`
          : parseInt(r.rescheduledBy) === parseInt(r.dsUserId)
          ? `Pending Acceptation from ${r.educatorname}`
          : `Pending Acceptation from ${r.displayName}`}
      </td>
    );
  };
  enrollCountCell = (r, i) => {
    return (
      <td key={i}>
        {r.enrollCount || 0} / {r.childCount || 0}
      </td>
    );
  };

  childCountCell = (r, i) => {
    return <td key={i}>{r.childCount || 0}</td>;
  };

  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };
  viewDetail = async row => {
    this.setState({ detailsModalShown: true, modalData: row });
  };

  fetchTours(filter) {
    const ep = `${process.env.REACT_APP_API}/adm/booked/${filter.id}`;
    axios.get(ep).then(res => {
      this.setState({
        tours: res.data.data
      });
    });

    return null;
  }

  filterData(filter) {
    const filteredData = this.state.original.filter(d =>
      d.displayName.toLowerCase().includes(filter.toLowerCase())
    );
    if (filteredData.length === 1) this.fetchTours(filteredData[0]);
    this.setState({
      data: filteredData
    });
  }
  viewComments = async row => {
    this.setState({ commentsModalShown: true, modalData: row });
  };

  render() {
    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          <div className="cont">
            <h1>Parents Leads</h1>
          </div>
          <FReg
            open={this.state.detailsModalShown}
            data={this.state.modalData}
            onClose={() =>
              this.setState({
                detailsModalShown: false,
                modalData: null
              })
            }
          />
          <ComModal
            open={this.state.commentsModalShown}
            data={this.state.modalData}
            onClose={() =>
              this.setState({
                commentsModalShown: false,
                modalData: null
              })
            }
          />
          <div className="cont">
            <div className="tablecont">
              <div className="table-responsive">
                <input
                  type="text"
                  onChange={e => this.filterData(e.target.value)}
                  placeholder="Search for family..."
                  style={{
                    marginBottom: 15,
                    width: "100%",
                    borderRadius: 5,
                    border: "2px solid #E3E6F0",
                    fontSize: "13px",
                    padding: 3,
                    outline: 0
                  }}
                />
                <Table
                  headings={this.headings}
                  data={this.state.data}
                  actions={this.actions}
                />
              </div>
            </div>
            {this.state.data.length === 1 && (
              <>
                <h1>Tour Requests</h1>
                <div className="tablecont">
                  <div className="table-responsive">
                    <Table
                      headings={this.tourHeading}
                      data={this.state.tours}
                      actions={this.actions}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FamilyProfiles;
