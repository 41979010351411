import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";
import { Dropdown } from "semantic-ui-react";
import ConfirmGiftAmountModal from "../../../../components/modals/ConfirmGiftAmountModal";
class SectionOne extends React.PureComponent {
  constructor(props) {
    super(props);
    this.image_links = [
      "partners/Gift Cards/zeus-01.png",
      "partners/Gift Cards/zeus-02.png",
      "partners/Gift Cards/zeus-03.png",
      "partners/Gift Cards/zeus-04.png",
      "partners/Gift Cards/zeus-05.png",
      "partners/Gift Cards/zeus-06.png",
      "partners/Gift Cards/zeus-07.png",
      "partners/Gift Cards/zeus-08.png"
    ];
    this.state = {
      openConfirmGiftAmountModal: false
    };
  }
  handleChange = (e, v) => {
    this.setGlobal({
      gift_card: {
        ...this.global.gift_card,
        [e]: v
      }
    });
  };
  setAmount(amount) {
    this.handleChange("amount", amount);
  }

  customForm = () => {
    return (
      <div className="formbody">
        {/* {this.state.openConfirmGiftAmountModal && (
          <ConfirmGiftAmountModal
            onClose={() =>
              this.setState({
                openConfirmGiftAmountModal: false
              })s
            }
          />
        )} */}
        <form>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Amount<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <div
                  className="row days"
                  validation={"validDays"}
                  style={{ margin: 0, padding: 0 }}
                >
                  <select
                    className="input-fields"
                    style={{ width: "100%" }}
                    name="program_details"
                    onChange={e =>
                      this.setAmount(e.target.value)
                    }
                    value={this.global.gift_card.amount}
                  >
                    <option value="">Please Select</option>
                    <option value="10">$10</option>
                    <option value="15">$15</option>
                    <option value="20">$20</option>
                    <option value="25">$25</option>
                    <option value="30">$30</option>
                    <option value="35">$35</option>
                    <option value="40">$40</option>
                    <option value="45">$45</option>
                    <option value="50">$50</option>
                    <option value="55">$55</option>
                    <option value="60">$60</option>
                    <option value="65">$65</option>
                    <option value="70">$70</option>
                    <option value="75">$75</option>
                    <option value="80">$80</option>
                    <option value="85">$85</option>
                    <option value="90">$90</option>
                    <option value="95">$95</option>
                    <option value="100">$100</option>
                  </select>
                  {/* <div
                    data-day="10"
                    className={`day-monday daytoni ${
                      this.global.gift_card &&
                      this.global.gift_card.amount === "10"
                        ? "selecta"
                        : ""
                    }`}
                    onClick={e => {
                      this.setState({
                        openConfirmGiftAmountModal: true
                      });
                      this.setAmount(e.target.getAttribute("data-day"));
                    }}
                  >
                    $10
                  </div>
                  <div
                    data-day="25"
                    className={`day-tuesday daytoni ${
                      this.global.gift_card &&
                      this.global.gift_card.amount === "25"
                        ? "selecta"
                        : ""
                    }`}
                    onClick={e => {
                      this.setState({
                        openConfirmGiftAmountModal: true
                      });
                      this.setAmount(e.target.getAttribute("data-day"));
                    }}
                  >
                    $25
                  </div>
                  <div
                    data-day="50"
                    className={`day-wednesday daytoni ${
                      this.global.gift_card &&
                      this.global.gift_card.amount === "50"
                        ? "selecta"
                        : ""
                    }`}
                    onClick={e => {
                      this.setState({
                        openConfirmGiftAmountModal: true
                      });
                      this.setAmount(e.target.getAttribute("data-day"));
                    }}
                  >
                    $50
                  </div>
                  <div
                    data-day="75"
                    className={`day-thursday daytoni ${
                      this.global.gift_card &&
                      this.global.gift_card.amount === "75"
                        ? "selecta"
                        : ""
                    }`}
                    onClick={e => {
                      this.setState({
                        openConfirmGiftAmountModal: true
                      });
                      this.setAmount(e.target.getAttribute("data-day"));
                    }}
                  >
                    $75
                  </div>
                  <div
                    data-day="100"
                    className={`day-sunday daytoni ${
                      this.global.gift_card &&
                      this.global.gift_card.amount === "100"
                        ? "selecta"
                        : ""
                    }`}
                    onClick={e => {
                      this.setState({
                        openConfirmGiftAmountModal: true
                      });
                      this.setAmount(e.target.getAttribute("data-day"));
                    }}
                  >
                    $100
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Gift Card Image:</h3>
            </div>
            <div className="col-7 d-flex">
              <Dropdown
                placeholder="Gift Card Image"
                fluid
                selection
                value={this.global.gift_card && this.global.gift_card.image}
                onChange={(_, { value }) =>
                  this.setGlobal({
                    gift_card: {
                      ...this.global.gift_card,
                      image: value
                    }
                  })
                }
                options={this.image_links.map((e, i) => ({
                  text: `Gift Card ${i + 1}`,
                  value: i + 1,
                  key: i + 1,
                  image: {
                    bordered: true,
                    src: `${process.env.REACT_APP_DS_FILES_S3}/${e}`
                  }
                }))}
              />
            </div>
          </div>
          <div className="row">
            <div style={{ display: "flex", position: "relative" }}>
              <img
                style={{ width: "50%", margin: "20px auto" }}
                src={`${process.env.REACT_APP_DS_FILES_S3}/${
                  this.image_links[this.global.gift_card.image - 1]
                }`}
              />
              <p
                style={{
                  position: "absolute",
                  fontSize: this.global.gift_card.image < 4 ? "18px" : "22px",
                  fontWeight: "bold",
                  top:
                    this.global.gift_card.image === 1
                      ? "23%"
                      : this.global.gift_card.image < 4
                      ? "81%"
                      : "50%",
                  left:
                    this.global.gift_card.image === 1
                      ? "32%"
                      : this.global.gift_card.image < 4
                      ? "69%"
                      : "50%",
                  transform: "translate(-50%,-50%)",
                  color: this.global.gift_card.image < 4 ? "#1f3b89" : "white",
                  fontFamily: "Montserrat"
                }}
              >
                ${this.global.gift_card.amount}
              </p>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Set-Up"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionOne;
