import React from "reactn";
import { Helmet } from "react-helmet";
import axios from "axios";

import RecentPayments from "../home/recent-payments";
import Program from "../home/program";
import Finance from "../home/finance";
class Parent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      counts: {
        intPCount: 0,
        intPWeekCount: 0,
        openAppCount: 0,
        openAppWeekCount: 0,
        pendPaymentsCount: 0,
        pendPaymentsWeekCount: 0,
        overduePaymentCount: 0,
        overduePaymentWeekCount: 0
      },
      recentInvoices: [],
      activeEnrollments: []
    };
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1)]
    });
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/par/dashboard`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      if (res.data.success) {
        this.setState({ ...res.data.data });
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (er) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    return (
      // <!-- Begin Page Content -->
      <div className="container-fluid hero">
        <div className="cont">
          <Helmet>
            <title>Dreamschools - Dashboard</title>
            <link rel="canonical" href="https://www.dreamschools.com/parents" />
          </Helmet>

          {/* <!-- Page Heading --> */}
          <div className="d-sm-flex align-items-center justify-content-between">
            <h1 className="">Welcome, {this.global.dsUser.displayName}!</h1>
          </div>
          <Program counts={this.state.counts} />
          <div className="row">
            <RecentPayments invoices={this.state.recentInvoices} />
            <Finance enrollments={this.state.activeEnrollments} />
          </div>
        </div>
      </div>
    );
  }
}

export default Parent;
