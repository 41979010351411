import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "./Form";

const BoxFormTabbed = props => {
  const [tab, setTab] = useState("0");
  const {
    fields,
    tabs,
    name,
    removable,
    object,
    handleChange,
    handleRemove,
    removeAfterSaved
  } = props;

  const canRemove = () => {
    return (
      removeAfterSaved || (!removeAfterSaved && typeof object.id !== "number")
    );
  };
  return (
    <div className="box">
      <div className="bgofbox">
        <div className="header">
          <div className="row">
            <div className="col-4">
              <h2>{name}</h2>
            </div>
            <div className="col-8 d-flex">
              {tabs.map((t, i) => (
                <button
                  key={t.name}
                  className={parseInt(i) === parseInt(tab) ? "active" : ""}
                  onClick={() => setTab(i.toString())}
                >
                  {t.name}
                </button>
              ))}
              {removable && canRemove() ? (
                <>
                  <span className="spacer"></span>
                  <button
                    className="remove-box"
                    onClick={() => handleRemove(object)}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </div>
        {Object.keys(fields).map(fieldTab => {
          return fields[fieldTab].customForm ? (
            fields[fieldTab].customForm
          ) : fieldTab === tab ? (
            <Form
              key={fieldTab}
              handleChange={handleChange}
              fields={fields[fieldTab]}
            />
          ) : (
            ""
          );
        })}
      </div>
    </div>
  );
};

BoxFormTabbed.propTypes = {
  tabs: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  removable: PropTypes.bool,
  handleRemove: PropTypes.func,
  object: PropTypes.object,
  removeAfterSaved: PropTypes.bool
};

BoxFormTabbed.defaultProps = {
  removable: false,
  handleRemove: () => false,
  object: {},
  removeAfterSaved: true
};

export default BoxFormTabbed;
