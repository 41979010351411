import React from "reactn";
import { Link } from "react-router-dom";
import { Redesign_Logo } from "../../../../assets";
import "./tosForm.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
class TOSForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dsContractAccepted: false
    };
    this.saveApplication = this.saveApplication.bind(this);
  }

  saveApplication = async () => {
    try {
      if (this.props.reaccept && this.state.dsContractAccepted) {
        const ep = `${process.env.REACT_APP_API}/edu/application/tos`;
        const res = await axios.post(ep, {});
        if (res.data.success) {
          this.setGlobal({ profile: res.data.data });
          toast("You've agreed to the TOS");
          this.props.history.push("/educators/program-setup");
          this.props.onClose();
        }
      } else if (!this.props.reaccept && this.state.dsContractAccepted) {
        await this.dispatch.saveApplication(true);
        this.props.history.push("/educators/program-setup");
        this.props.onClose();
      } else {
      }
    } catch (ex) {}
  };

  render() {
    return (
      <div className="tos-modal-cont">
        <div className="tos-modal">
          <img
            className="school-logo"
            src={Redesign_Logo}
            alt="Dreamschools"
            style={{ width: "100%" }}
          />
          <p>
            By accepting these Terms and Conditions, you agree to work with
            Dreamschools to dedicate{" "}
            {this.global.profile.allSlots ? "all" : this.global.profile.spots}{" "}
            spot(s) in your program for a period of{" "}
            {this.global.profile.termLength} year(s) starting from the date you
            submit your Program Setup and to when your program goes live. All
            families that send their children to your program must continue to
            use the Dreamschools platform to register and enroll for each
            enrollment period and / or program offering. There will be no
            up-front fees for you to join our platform. Dreamschools will earn a{" "}
            {this.global.profile.dsFee}% commission of transactions conducted
            through the platform.
          </p>
          <input
            type="checkbox"
            name="dsContractAccepted"
            id="dsContractAccepted"
            checked={this.state.dsContractAccepted ? "checked" : ""}
            onChange={() => {
              this.setState({
                dsContractAccepted: !this.state.dsContractAccepted
              });
            }}
          />{" "}
          <label htmlFor="dsContractAccepted">
            I accept these terms and conditions.
          </label>
          <br />
          <button onClick={this.props.onClose} className="mx-2 backbtn">
            Save for Later
          </button>
          <button className="mx-2" onClick={() => this.saveApplication()}>
            Accept &amp; Continue
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(TOSForm);
