import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "../../assets/css/componentSpecificCss/imageCropModal.css";
import {
  CalendarCreate2,
  CalendarCreate3,
  CalendarCreate4
} from "../../assets";
import "../../assets/css/componentSpecificCss/calendarCreateModal.css";
import Radio from "@material-ui/core/Radio";
import BaseModal from "./baseModal/BaseModal";
import { ITEM_CONTENT } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

const image_choices = [
  { name: "Reminders", image: CalendarCreate2 },
  { name: "Events", image: CalendarCreate3 },
  { name: "Programs", image: CalendarCreate4 }
];

const CalendarCreateModal = ({ onClose, history, openRemindersModal }) => {
  const [colorSelection, setColorSelection] = useState(0);
  const [openModal, setOpenModal] = useState("");

  const onSubmitClick = event => {
    console.log("openModal", openModal);
    if (openModal === "Events") {
      history.push("/partners/events/list");
    }
    if (openModal === "Programs") {
      history.push("/partners/programs/list");
    }
    if (openModal === "Reminders") {
      openRemindersModal(true);
      onClose();
    }
  };

  const getImage = name => {
    return image_choices.filter(img => img.name === name)[0].image;
  };

  /// ================== NEW MODAL ========================== ///

  const getBaseModalFields = () => {
    const imageSelections = {
      type: ITEM_CONTENT,
      data: {
        name: "",
        handleChange: value => {
          setOpenModal(value);
          setColorSelection(value);
        },

        content: [
          {
            src: getImage("Reminders"),
            content: "Reminders",
            value: "Reminders"
          },
          { src: getImage("Events"), content: "Events", value: "Events" },
          { src: getImage("Programs"), content: "Programs", value: "Programs" }
        ]
      }
    };

    return [imageSelections];
  };

  const getBaseModalButtons = () => {
    const submitButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: event => onSubmitClick(event)
    };

    return [submitButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Calendar Create",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: onClose
    };
  };

  /// ================== END NEW MODAL ===================== ///

  return <BaseModal {...getBaseModalProps()} />;
};

export default CalendarCreateModal;
