import React from "reactn";
import axios from "axios";
import { Modal, Select } from "../UI";
import { formTypes } from "../../components/UI/form";
import { withRouter } from "react-router-dom";
import { Select as DDSelect } from "react-dropdown-select";
import { toast } from "react-toastify";

class CreatePromotionModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      programs: [],
      open: props.open,
      promoType: "Flat",
      promoCodeData: {
        id: "",
        duration: "once",
        amount_off: "",
        currency: "cad",
        duration_in_months: "",
        max_redemptions: "",
        metadata: {
          ds_pays: "",
          educators: [],
          ds_fee_promo: "false",
          max_global_redemptions: ""
        },
        name: "",
        percent_off: "",
        redeem_by: ""
      }
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e, v) => {
    this.setState({
      promoCodeData: { ...this.state.promoCodeData, [e]: v }
    });
  };

  async componentDidMount() {
    try {
      const ep = `${process.env.REACT_APP_API}/edu/program/all/info`;
      this.setGlobal({ loading: true });
      const res = await axios.get(`${ep}`);
      if (res.data.success) {
        this.setState({ programs: res.data.data });
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
    this.setState({ detailsModalShown: false, modalData: null });
  }

  submitPromoCode = async () => {
    const ep = `${process.env.REACT_APP_API}/stripe/coupon`;
    const v = this.state.promoCodeData;
    try {
      this.setGlobal({ loading: true });
      // if (!v.id) throw new Error("Name required.");
      if (!v.duration_in_months && v.duration === "repeating")
        throw new Error("Number of Months required.");
      // if (!v.max_redemptions) throw new Error("Max Redemptions required.");
      // if (!v.redeem_by) throw new Error("Redeem By required.");
      if (!this.state.promoType) throw new Error("Discount Type required.");
      if (!v.amount_off && !v.percent_off && this.state.promoType !== "DSFee")
        throw new Error("Discount Amount or Discount Percentage required.");
      if (this.state.promoType === "Flat") {
        delete v.percent_off;
        v.amount_off = v.amount_off * 100;
      }
      if (this.state.promoType === "Percent") delete v.amount_off;
      if (this.state.promoType === "DSFee") {
        v.metadata.ds_fee_promo = "true";
        v.amount_off = 5;
        delete v.percent_off;
      }
      if (!v.id) delete v.id;
      if (!v.name) delete v.name;
      if (v.duration !== "repeating") delete v.duration_in_months;
      if (!v.redeem_by) delete v.redeem_by;
      if (!v.max_redemptions) delete v.max_redemptions;
      if (v.redeem_by) v.redeem_by = Date.parse(v.redeem_by) / 1000;
      const res = await axios.post(`${ep}`, v);
      this.setGlobal({ loading: false, lastAPICall: res });
      this.props.onClose(res.data.data);
      // window.location.reload();
    } catch (err) {
      toast.error(err.message);
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  };

  render() {
    const { open, onClose } = this.props;
    const vals = this.state.promoCodeData;
    return (
      <div>
        <Modal
          open={open}
          onClose={() => onClose(null)}
          heading="CREATE NEW PROMOTION"
        >
          <div className="row">
            <div className="col-12 text-center">
              <p>
                <i>Items with * are required.</i>
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <p>Promo Code</p>
            </div>
            <div className="col-md-6">
              <input
                name="id"
                value={vals.id}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <p>Promo Code Name</p>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="name"
                value={vals.name}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <p>Max Redemptions / Educator</p>
            </div>
            <div className="col-md-6">
              <input
                type="number"
                min={0}
                value={vals.max_redemptions}
                name="max_redemptions"
                onChange={e =>
                  this.handleChange(
                    e.target.name,
                    parseInt(e.target.value) || ""
                  )
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <p>Max Global Redemptions</p>
            </div>
            <div className="col-md-6">
              <input
                type="number"
                min={0}
                value={vals.metadata.max_global_redemptions}
                name="max_global_redemptions"
                onChange={e =>
                  this.setState({
                    promoCodeData: {
                      ...vals,
                      metadata: {
                        ...vals.metadata,
                        [e.target.name]: parseInt(e.target.value) || ""
                      }
                    }
                  })
                }
              />
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-md-6">
              <p>Redeem By</p>
            </div>
            <div className="col-md-6">
              <formTypes.Date
                name="redeem_by"
                value={vals.redeem_by}
                onChange={this.handleChange}
                minDate={new Date()}
              />
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-md-6">
              <p>Discount Type *</p>
            </div>
            <div className="col-md-6">
              <Select
                name="promoType"
                onChange={e =>
                  this.setState({
                    [e.target.name]: e.target.value,
                    promoCodeData: {
                      ...vals,
                      amount_off: "",
                      percent_off: ""
                    }
                  })
                }
                value={this.state.promoType}
                options={[
                  { value: "", label: "Select..." },
                  { value: "Percent", label: "Percentage" },
                  { value: "Flat", label: "Flat" },
                  { value: "DSFee", label: "DS Fee" }
                ]}
              />
            </div>
          </div>

          {this.state.promoType === "DSFee" ? null : (
            <div className="row">
              {this.state.promoType !== "Percent" ? null : (
                <>
                  <div className="col-6">
                    <p>Discount Percentage</p>
                  </div>
                  <div className="col-6">
                    <input
                      name="percent_off"
                      value={vals.percent_off}
                      onChange={e =>
                        this.handleChange(e.target.name, e.target.value)
                      }
                      type="number"
                    />
                  </div>
                </>
              )}

              {this.state.promoType !== "Flat" ? null : (
                <>
                  <div className="col-6">
                    <p>Discount Amount *</p>
                  </div>
                  <div className="col-6">
                    <input
                      name="amount_off"
                      value={vals.amount_off}
                      onChange={e =>
                        this.handleChange(e.target.name, e.target.value)
                      }
                      type="number"
                    />
                  </div>
                </>
              )}
            </div>
          )}

          <div className="row">
            <div className="col-md-6">
              <p>Applied *</p>
            </div>
            <div className="col-md-6">
              <Select
                name="duration"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={vals.duration}
                options={[
                  { value: "", label: "Select..." },
                  { value: "once", label: "Once" },
                  { value: "repeating", label: "{x} Months" },
                  { value: "forever", label: "Forever" }
                ]}
              />
            </div>
          </div>

          {vals.duration !== "repeating" ? null : (
            <div className="row">
              <div className="col-md-6">
                <p>Duration (In Months) *</p>
              </div>
              <div className="col-md-6">
                <input
                  type="number"
                  min={0}
                  value={vals.duration_in_months}
                  name="duration_in_months"
                  onChange={e =>
                    this.handleChange(
                      e.target.name,
                      parseInt(e.target.value) || ""
                    )
                  }
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-6">
              <p>Dreamschools Pays</p>
            </div>
            <div className="col-6">
              <input
                name="ds_pays"
                type="number"
                value={vals.metadata.ds_pays}
                onChange={e =>
                  this.setState({
                    promoCodeData: {
                      ...vals,
                      metadata: {
                        ...vals.metadata,
                        [e.target.name]: parseInt(e.target.value) || ""
                      }
                    }
                  })
                }
              />
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-md-12">
              <p>Educators</p>
            </div>
            <div className="col-md-12">
              {/* <TextArea name="educator" /> */}
              <DDSelect
                multi={true}
                options={this.state.programs}
                values={this.state.promoCodeData.metadata.educators}
                name="educators"
                onChange={values => {
                  this.setState({
                    promoCodeData: {
                      ...vals,
                      metadata: {
                        ...vals.metadata,
                        educators: values
                      }
                    }
                  });
                }}
                labelField="programName"
                valueField="id"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="buttons">
                <button className="nextbtn" onClick={this.submitPromoCode}>
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(CreatePromotionModal);
