import React from "reactn";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { SendOutlined } from "@material-ui/icons";
class SendMessage extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      message: ""
    };
    this.sendMessage = this.sendMessage.bind(this);
  }
  async sendMessage(e) {
    e.preventDefault();
    if (this.state.message.trim().length > 0) {
      await this.dispatch.ccSendMessage(this.state.message);
      this.setState({ message: "" });
    }
  }
  render() {
    return (
      <div className="sendmsg">
        <div className="search">
          <form>
            <div>
              <TextField
                id="outlined-textarea"
                placeholder="Type a message..."
                variant="outlined"
                multiline
                rowsMax="4"
                fullWidth
                disabled={!this.global.ccSelectedContact.conversationId}
                value={this.state.message}
                onChange={e => this.setState({ message: e.target.value })}
                onKeyPress={e => {
                  if (e.key === "Enter") this.sendMessage(e);
                }}
                style={{
                  border: "1px solid grey",
                  borderRadius: "10px"
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={!this.global.ccSelectedContact.conversationId}
                        type="submit"
                        onClick={this.sendMessage}
                      >
                        <SendOutlined />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SendMessage;
