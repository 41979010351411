import React from "reactn";
import { Modal } from "../../UI";
import { Fragment } from "react";
import { TextField } from "@material-ui/core";
class ContactDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { open, onClose } = this.props;
    return (
      <div>
        <Modal open={open} heading="Contact Details" onClose={onClose}>
          {this.props.data &&
            Object.keys(this.props.data).map((e, i) => {
              if (e !== "comments")
                return (
                  <div key={i}>
                    <h3>{e}</h3>
                    <p>
                      <b>Email : </b> {this.props.data[e].email || "N/A"}
                    </p>
                    <p>
                      <b>Phone : </b> {this.props.data[e].phone || "N/A"}
                    </p>
                  </div>
                );
            })}
        </Modal>
      </div>
    );
  }
}

export default ContactDetails;
