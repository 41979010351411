import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import Switch from "@material-ui/core/Switch";
import axios from "axios";
import FormPreviewModal from "../../../../components/modals/FormPreviewModal";
import ReactTooltip from "react-tooltip";
import Validators from "../../../../validators/validators";

const { isSelectedFormId } = Validators;

class SectionFive extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      forms: [],
      registrationForms: [],
      applicationForms: [],
      isAdvancedOptionsChecked: false,
      isAddChildChecked: false,
      openPreviewModal: false,
      customers: []
    };
  }
  handleChange = (e, v) => {
    if (e === "participant_type_parent") {
      if (!this.global.program.participant_type) {
        this.setGlobal({
          program: {
            ...this.global.program,
            participant_type: {
              parent: v,
              student: true,
              volunteer: true
            }
          }
        });
      } else {
        this.setGlobal({
          program: {
            ...this.global.program,
            participant_type: {
              parent: v,
              student:
                this.global.program.participant_type?.student === true
                  ? true
                  : false,
              volunteer:
                this.global.program.participant_type?.volunteer === true
                  ? true
                  : false
            }
          }
        });
      }
    } else if (e === "participant_type_student") {
      if (!this.global.program.participant_type) {
        this.setGlobal({
          program: {
            ...this.global.program,
            participant_type: {
              parent: true,
              student: v,
              volunteer: true
            }
          }
        });
      } else {
        this.setGlobal({
          program: {
            ...this.global.program,
            participant_type: {
              parent:
                this.global.program.participant_type?.parent === true
                  ? true
                  : false,
              student: v,
              volunteer:
                this.global.program.participant_type?.volunteer === true
                  ? true
                  : false
            }
          }
        });
      }
    } else if (e === "participant_type_volunteer") {
      if (!this.global.program.participant_type) {
        this.setGlobal({
          program: {
            ...this.global.program,
            participant_type: {
              parent: true,
              student: true,
              volunteer: v
            }
          }
        });
      } else {
        this.setGlobal({
          program: {
            ...this.global.program,
            participant_type: {
              parent:
                this.global.program.participant_type?.parent === true
                  ? true
                  : false,
              student:
                this.global.program.participant_type?.student === true
                  ? true
                  : false,
              volunteer: v
            }
          }
        });
      }
    } else {
      this.setGlobal({
        program: {
          ...this.global.program,
          [e]: v
        }
      });
    }

    console.log("GLOBAL", this.global.program.participant_type);
  };
  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/forms/list`;
    this.setGlobal({ loading: true });
    const programs = await axios.get(ep);

    const customers_ep = `${process.env.REACT_APP_API}/partners/programs/customers/${this.global.program.id}`;
    // Get all customers to perform check if program has any enrollments.
    const customers = await axios.get(customers_ep);

    this.setState({
      forms: programs.data.data,
      registrationForms: programs.data.data.filter(
        e =>
          (e.form_name === "Registration" || e.form_name === "Waiver") &&
          !e.isArchived
      ),
      applicationForms: programs.data.data.filter(
        e => e.is_enabled && e.form_name === "Application" && !e.isArchived
      ),
      customers: customers.data.data
    });


    this.setGlobal({ 
      loading: false, 
      lastAPICall: programs,
      program: {
        ...this.global.program,
        attendance_roaster: this.global.program.number_of_weeks > 0 ||
                            this.global.program.manual_invoices,
        check_in_roaster: this.global.program.number_of_weeks === 0 && 
                          !this.global.program.manual_invoices
      }
    });
  }

  onSwitchChange(event) {
    this.setState({
      isAdvancedOptionsChecked: !this.state.isAdvancedOptionsChecked
    });
  }

  customForm = () => {
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="label-names">
                Application Required?
              </h3>
            </div>
            <div
              className="col-7 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                disabled={
                  this.state.customers.length > 0 ||
                  this.global.program.program_category === "Single Sessions"
                }
                name="application_required"
                checked={!!this.global.program.application_required}
                onClick={_ =>
                  this.handleChange(
                    "application_required",
                    !this.global.program.application_required
                  )
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Select Checkout Form<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              {this.global.program.formId ? (
                <formTypes.Select
                  options={[
                    ...(() =>
                      !this.global.program.application_required
                        ? this.state.registrationForms
                        : this.state.applicationForms)().map((v, k) => ({
                      label: v.form_name + ` (${v.form_sub_name})`,
                      value: v.id
                    }))
                  ]}
                  value={this.global.program.formId}
                  onChange={v => this.handleChange("formId", v.target.value)}
                />
              ) : (
                <formTypes.Select
                  options={[
                    { label: "Please Select", value: 0 },
                    ...(() =>
                      !this.global.program.application_required
                        ? this.state.registrationForms
                        : this.state.applicationForms)().map((v, k) => ({
                      label: v.form_name + ` (${v.form_sub_name})`,
                      value: v.id
                    }))
                  ]}
                  value={this.global.program.formId}
                  onChange={v => this.handleChange("formId", v.target.value)}
                />
              )}
            </div>
            {/* {!this.global.program.application_required ? (
              <div className="col-7 d-flex">
                <formTypes.Select
                  options={[
                    { label: "Please Select", value: 0 },
                    ...this.state.registrationForms.map((v, k) => ({
                      label: v.form_name + ` (${v.form_sub_name})`,
                      value: v.id
                    }))
                  ]}
                  value={this.global.program.formId}
                  onChange={v => this.handleChange("formId", v.target.value)}
                ></formTypes.Select>
              </div>
            ) : (
              <div className="col-7 d-flex">
                <formTypes.Select
                  options={[
                    { label: "Please Select", value: 0 },
                    ...this.state.applicationForms.map((v, k) => ({
                      label: v.form_name + ` (${v.form_sub_name})`,
                      value: v.id
                    }))
                  ]}
                  value={this.global.program.formId}
                  onChange={v => this.handleChange("formId", v.target.value)}
                ></formTypes.Select>
              </div>
            )} */}
          </div>

          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Checkout Form</h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`newapplications ${
                  !isSelectedFormId(this.global.program.formId)
                    ? "button-disabled"
                    : ""
                }`}
                style={{
                  // regular colour or greyed out depending on the select field
                  border: `1px solid ${
                    isSelectedFormId(this.global.program.formId)
                      ? "#7ea1ff"
                      : "#cccccc"
                  }`,
                  padding: "6px 20px",
                  color: isSelectedFormId(this.global.program.formId)
                    ? "#7ea1ff"
                    : "#cccccc",
                  backgroundColor: "#FFF",
                  borderRadius: "15px",
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "700",
                  fontFamily: "Open Sans",
                  cursor: "pointer"
                }}
                onClick={() => {
                  if (isSelectedFormId(this.global.program.formId)) {
                    this.setState({ openPreviewModal: true });
                  }
                }}
              >
                Preview
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="label-names">
                Target: Children
              </h3>
            </div>
            <div
              className="col-7 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                disabled={this.state.customers.length > 0 ? true : false}
                name="addChild"
                checked={!!this.global.program.addChild}
                onClick={_ =>
                  this.handleChange("addChild", !this.global.program.addChild)
                }
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 style={{ margin: 0, padding: 0 }} className="label-names">
                Advanced Options
              </h3>
            </div>
            <div
              className="col-7 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                name="advanced_options"
                checked={this.state.isAdvancedOptionsChecked}
                onClick={e => this.onSwitchChange(e)}
              />
            </div>
          </div>

          {this.state.isAdvancedOptionsChecked && (
            <div>
              {/* <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Program Restrictions
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.program.members_only_program}
                    name="members_only_program"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Members only program
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Open Registrations
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.program.open_registration}
                    name="open_registration"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Keep Registrations open
                </div>
              </div> */}
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Roster Format
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.program.attendance_roaster}
                    name="attendance_roaster"
                    disabled={
                      this.global.program.number_of_weeks === 0 ||
                      !this.global.program.manual_invoices
                    }
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Attendance : Present / Absent
                </div>
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  {/* Empty heading */}
                  <h3
                    style={{ margin: 0, padding: 0 }}
                    className="sub-labels"
                  ></h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    name="check_in_roaster"
                    style={{ marginTop: "7px" }}
                    disabled
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Check-in / Check-out Time
                </div>
                {this.global.program.program_category !== "Single Sessions" && (
                  <>
                    <div
                      className="col-4"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "30px"
                      }}
                    >
                      {/* Empty Heading */}
                      <h3
                        style={{ margin: 0, padding: 0 }}
                        className="sub-labels"
                      ></h3>
                    </div>
                    <div
                      className="col-2 d-flex"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        type="checkbox"
                        checked={this.global.program.check_in_roaster}
                        disabled={this.global.program.attendance_roaster}
                        name="check_in_roaster"
                        onChange={e =>
                          this.handleChange(e.target.name, e.target.checked)
                        }
                      />
                    </div>
                    <div
                      className="col-5 d-flex sub-labels"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Advanced Attendance: Impacts Billing
                    </div>
                  </>
                )}
              </div>
              {/* <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Milestones
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.program.program_milestones}
                    name="program_milestones"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Enable Milestones
                </div>
              </div> */}
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Rooms
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.program.rooms}
                    name="rooms"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Enable Rooms
                </div>
              </div>

              { /*
                <div className="row">
                  <div
                    className="col-4"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "30px"
                    }}
                  >
                    <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                      Recurring Payments?
                    </h3>
                  </div>
                  <div
                    className="col-2 d-flex"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type="checkbox"
                      checked={this.global.program.isRecurring}
                      name="isRecurring"
                      disabled={true}
                      onChange={e =>
                        this.handleChange(e.target.name, e.target.checked)
                      }
                    />
                  </div>
                  <div
                    className="col-5 d-flex sub-labels"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Is Recurring?
                  </div>
                </div>
                */
              }

              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3
                    style={{ margin: 0, padding: 0 }}
                    className="sub-labels"
                  >
                    Transaction Cost{" "}
                    <i
                      data-tip
                      data-for="transaction_cost"
                      className="fas fa-info-circle"
                      style={{ color: "#DCDCDC" }}
                    ></i>
                    <ReactTooltip
                      id="transaction_cost"
                      place="top"
                      effect="solid"
                      type="info"
                    >
                      A small percentage will be passed on to customers
                    </ReactTooltip>
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    disabled={this.state.customers.length > 0 ? true : false}
                    type="checkbox"
                    checked={this.global.program.transaction_cost}
                    name="transaction_cost"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Share Transaction Fee
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Offline Payments
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.program.manual_invoices}
                    name="manual_invoices"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Collect Invoices Offline
                </div>
              </div>
              {this.global.program.isRecurring &&
                <div className="row">
                  <div
                    className="col-4"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "30px"
                    }}
                  >
                    <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                      Make Autopay Mandatory
                    </h3>
                  </div>
                  <div
                    className="col-2 d-flex"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      disabled
                      type="checkbox"
                      checked={this.global.program.auto_withdraw}
                      name="manual_invoices"
                      // onChange={e =>
                      //   this.handleChange(e.target.name, e.target.checked)
                      // }
                    />
                  </div>
                  <div
                    className="col-5 d-flex sub-labels"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Automatically collect payments for recurring plans.
                  </div>
                </div>
              }
              {this.global.program.isRecurring && (
                <div className="row">
                  <div
                    className="col-4"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "30px"
                    }}
                  >
                    <h3
                      style={{ margin: 0, padding: 0 }}
                      className="sub-labels"
                    >
                      Credit Card Deposit + Offline Payments
                    </h3>
                  </div>
                  <div
                    className="col-2 d-flex"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      disabled={
                        this.global.program.manual_invoices ? false : true
                      }
                      type="checkbox"
                      checked={this.global.program.auto_deposit}
                      name="auto_deposit"
                      onChange={e =>
                        this.handleChange(e.target.name, e.target.checked)
                      }
                    />
                  </div>
                  <div
                    className="col-5 d-flex sub-labels"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Credit Card Deposit + Collect Invoices Offline (Recurring
                    only)
                  </div>
                </div>
              )}
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Disable EFT Payments
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.program.isEftDisabled}
                    name="isEftDisabled"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Disable EFT Debit payments, which are direct bank transfers
                  that can take several days to process.
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Email Form Submission to User
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.program.emailFormSubmission}
                    name="emailFormSubmission"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Contain form submission details in user enrollment emails
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Email Form Submission to Organizer
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.program.receiveUserFormSubmission}
                    name="receiveUserFormSubmission"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Receive User Form Submission Details via Email
                </div>
              </div>
              
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Limit Same Participant
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.program.allow_same_enrollment}
                    name="allow_same_enrollment"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Do NOT allow the same Participant to apply / register more than once
                </div>
              </div>
              { !this.global.program.isRecurring && 
                <div className="row">
                  <div
                    className="col-4"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "30px"
                    }}
                  >
                    <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                      Disable Add-to-Cart
                    </h3>
                  </div>
                  <div
                    className="col-2 d-flex"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type="checkbox"
                      checked={this.global.program.isCartDisabled}
                      name="isCartDisabled"
                      onChange={e =>
                        this.handleChange(e.target.name, e.target.checked)
                      }
                    />
                  </div>
                  <div
                    className="col-5 d-flex sub-labels"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Disables the ability for adding this item to the cart
                  </div>
                </div>
              }
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Participant Type
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={
                      this.global.program.addChild ||
                      (this.global.program.participant_type &&
                        this.global.program.participant_type.parent) ||
                      (!this.global.program.addChild
                        ? !this.global.program.participant_type
                          ? true
                          : this.global.program.participant_type.parent
                        : false)
                    }
                    name="participant_type_parent"
                    disabled={this.global.program.addChild}
                    // style={{ marginTop: "7px" }}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Parent
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                ></div>
                {console.log("PROGRAM", this.global.program)}
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={
                      (this.global.program.participant_type &&
                        this.global.program.participant_type.student) ||
                      (!this.global.program.addChild
                        ? !this.global.program.participant_type
                          ? true
                          : this.global.program.participant_type.student
                        : false)
                    }
                    disabled={this.global.program.addChild}
                    name="participant_type_student"
                    // style={{ marginTop: "-20px" }}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  Student
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                ></div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={
                      (this.global.program.participant_type &&
                        this.global.program.participant_type.volunteer) ||
                      (!this.global.program.addChild
                        ? !this.global.program.participant_type
                          ? true
                          : this.global.program.participant_type.volunteer
                        : false)
                    }
                    disabled={this.global.program.addChild}
                    name="participant_type_volunteer"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  Volunteer
                </div>
              </div>
              {/* <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Min Cancellation <br />
                    (Prior to start date)
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.program.min_cancellation}
                    name="min_cancellation"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex sub-labels"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <formTypes.Select
                    options={new Array(14)
                      .fill(1)
                      .map((_, k) => ({ label: `${k + 1} days`, value: k }))}
                  ></formTypes.Select>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px"
                  }}
                >
                  <h3 style={{ margin: 0, padding: 0 }} className="sub-labels">
                    Cancellation Policy
                  </h3>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    checked={this.global.program.show_cancellation_policy}
                    name="show_cancellation_policy"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.checked)
                    }
                  />
                </div>
                <div
                  className="col-5 d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <textarea
                    className="input-text-fields"
                    name="cancellation_policy"
                    rows="4"
                    cols="50"
                    style={{ fontSize: "12px" }}
                    value={this.global.program.cancellation_policy}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                  />
                </div>
              </div> */}
            </div>
          )}
        </form>
      </div>
    );
  };

  render() {
    console.log("this.global", this.global);
    return (
      <>
        {this.state.openPreviewModal && (
          <FormPreviewModal
            selectedFormId={this.global.program.formId}
            onClose={_ => this.setState({ openPreviewModal: false })}
          />
        )}
        <BoxForm
          handleChange={this.handleChange}
          name={"Checkout Details"}
          customForm={this.customForm()}
        />
      </>
    );
  }
}

export default SectionFive;
