import React from "reactn";
import "./paymentMethods.css";
import ACHForm from "./forms/ach";
import BankForm from "./forms/bank";
import CreditCardForm from "./forms/creditCard";
import { Helmet } from "react-helmet";
import axios from "axios";
import { toast } from "react-toastify";

class PaymentMethods extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tab: "bank",
      isUpdate: true
    };
    this.tabs = {
      bank: { name: "Connect to Bank", tab: "bank", right: "Select Your Bank" }
    };
    if (props.showACH || process.env.REACT_APP_MODE === "staging") {
      this.tabs["ach"] = {
        name: "Manual ACH Entry",
        tab: "ach",
        right: "Account Information"
      };
    }
    if (props.showCC) {
      this.tabs["cc"] = {
        name: "Credit Card",
        sub: "(Additional 2% charge may apply)",
        tab: "cc",
        right: "Account Information"
      };
    }
    this.updateBilling = this.updateBilling.bind(this);
  }

  async componentDidMount() {
    try {
      const ep = `${process.env.REACT_APP_API}/pmnt/billing`;
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      if (res.data.data) {
        this.setGlobal({
          loading: false,
          lastAPICall: res,
          payment: res.data.data
        });
      } else {
        this.setGlobal({ loading: false, lastAPICall: res });
      }
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  getForm() {
    const { tab } = this.state;
    switch (tab) {
      case "bank":
        return <BankForm />;
      case "ach":
        return this.props.showACH ||
          process.env.REACT_APP_MODE === "staging" ? (
          <ACHForm vals={this.global.achPayment} />
        ) : (
          <BankForm />
        );
      case "cc":
        return this.props.showCC ? (
          <CreditCardForm vals={this.global.ccPayment} />
        ) : (
          <BankForm />
        );
      default:
        return <BankForm />;
    }
  }

  async updateBilling() {
    const ep = `${process.env.REACT_APP_API}/pmnt/billing`;
    let res = null;
    try {
      if (process.env.REACT_APP_MODE === "staging" && this.state.tab === "cc") {
        this.setGlobal({ loading: true });
        res = await axios.post(ep, { ccToken: true });
        this.setGlobal({ payment: res.data.data });
      } else if (this.state.tab === "cc") {
        const finalCC = { ...this.global.ccPayment };
        if (!finalCC.cardNo) throw new Error("Credit Card is required");
        if (!parseInt(finalCC.cardNo))
          throw new Error("Credit Card must be numbers only");
        if (finalCC.expiry.length !== 4)
          throw new Error("Expiry must be 4 digits (ex: 0423)");
        if (!parseInt(finalCC.expiry))
          throw new Error("Expiry must be numbers only");
        if (!parseInt(finalCC.cvvNo))
          throw new Error("CVC must be numbers only");
        if (!finalCC.cvvNo) throw new Error("CVC is required");
        if (!finalCC.addressOne) throw new Error("Address is required");
        if (!finalCC.city) throw new Error("City is required");
        if (!finalCC.province) throw new Error("Province is required");
        if (!finalCC.country) throw new Error("Country is required");
        if (!finalCC.postalCode) throw new Error("Postal Code is required");
        finalCC.expiry =
          finalCC.expiry.substring(0, 2) + "/" + finalCC.expiry.substring(2);
        this.setGlobal({ loading: true });
        res = await axios.post(ep, finalCC);
        if (res.data.success) {
          const ach = {
            fullName: "",
            birthDate: "",
            addressOne: "",
            addressTwo: "",
            city: "",
            province: "",
            country: "",
            postalCode: "",
            accountType: "",
            transitNo: "",
            institutionNo: "",
            accountNo: ""
          };

          this.setGlobal({ achPayment: ach });

          const creditCard = {
            fullName: "",
            birthDate: "",
            addressOne: "",
            addressTwo: "",
            city: "",
            province: "",
            country: "",
            postalCode: "",
            cardNo: "",
            cvvNo: "",
            expiry: ""
          };

          this.setGlobal({ ccPayment: creditCard, payment: res.data.data });
        }
      } else {
        this.setGlobal({ loading: true });
        const res = await axios.post(
          ep,
          this.state.tab === "ach"
            ? this.global.achPayment
            : {
                ...this.global.ccPayment,
                ccToken: true
              }
        );
        if (res.data.success) {
          const ach = {
            fullName: "",
            birthDate: "",
            addressOne: "",
            addressTwo: "",
            city: "",
            province: "",
            country: "",
            postalCode: "",
            accountType: "",
            transitNo: "",
            institutionNo: "",
            accountNo: ""
          };

          this.setGlobal({ achPayment: ach });

          const creditCard = {
            fullName: "",
            birthDate: "",
            addressOne: "",
            addressTwo: "",
            city: "",
            province: "",
            country: "",
            postalCode: "",
            cardNo: "",
            cvvNo: "",
            expiry: ""
          };

          this.setGlobal({ ccPayment: creditCard });
          this.setGlobal({ payment: res.data.data });
        }
      }

      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (er) {
      toast.error(er.message);
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  getCard(card) {
    switch (card) {
      case "VI":
        return "VISA";
      case "MC":
        return "MASTERCARD";
      case "AM":
        return "AMERICAN EXPRESS";
      case "JB":
        return "JCB";
      case "NN":
        return "DISCOVER";
      case "DI":
        return "DINERS";
      default:
        return "";
    }
  }

  render() {
    return (
      <div className="container-fluid paymentmethod">
        <Helmet>
          <title>Dreamschools - Payment Settings</title>
        </Helmet>
        <div className="cont">
          <h1>Payment Settings</h1>
        </div>
        {!this.global.payment ||
        (!this.global.payment.vpToken &&
          !this.global.payment.bbToken) ? null : (
          <div className="cont">
            <div className="paymentbox">
              <div className="px-5">
                <strong>Currently connected account:</strong>
                <br />
                {this.global.payment.type === "VersaPay"
                  ? this.global.payment.data.name
                  : `${this.getCard(
                      this.global.payment.data.card.card_type
                    )} credit card (${this.global.payment.data.card.number})`}
              </div>
            </div>
            <hr />
          </div>
        )}
        {!this.global.payment.loading ? (
          <div className="cont">
            <div className="paymentbox">
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-12">
                  <div className="leftsidebox">
                    <h2>
                      <span>1</span> CHOOSE PAYMENT METHOD
                    </h2>

                    {Object.keys(this.tabs).map((tab, i) => (
                      <button
                        onClick={() =>
                          this.setState({ tab: this.tabs[tab].tab })
                        }
                        key={i}
                        className="method-button"
                      >
                        <div
                          className={
                            (this.tabs[tab].tab === this.state.tab
                              ? "active "
                              : "") + "leftcircle"
                          }
                        >
                          <div className="activecircle" />
                        </div>
                        {this.tabs[tab].name}
                        {this.tabs[tab].sub ? <p>{this.tabs[tab].sub}</p> : ""}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="col-xl-8 col-lg-6 col-md-12">
                  <div className="rightside">
                    <div className="contofinfo">
                      <h2>
                        <span>2</span> {this.tabs[this.state.tab].right}
                      </h2>
                      {this.getForm()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{ display: this.state.tab === "bank" ? "none" : "block" }}
            >
              <div className="col-md-12">
                <div className="buttons">
                  <button
                    className="savebtn"
                    onClick={this.updateBilling}
                    disabled={this.state.tab === "bank"}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="cont">
            <div className="paymentbox">
              <div className="px-5">
                <strong>
                  Currently verifying account information, please try again in
                  another one to two minutes.
                </strong>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default PaymentMethods;
