import React, { Component } from "reactn";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK, STRING_INPUT, DROPDOWN, TITLE } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { format } from "date-fns";

class CopyExistingOnlineVirtualModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_program_name: "",
      copyProgram: "",
      programList: [],
      canUserCreate: false
    };
  }

  async componentDidMount() {
    await this.fetchInitialData();
  }

  async fetchInitialData() {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API}/partners/virtual/list`
      );
      if (resp.data.success) {
        const currentOrder = resp.data.data
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .sort((a, b) => a.display_order - b.display_order);
        this.setState({ programList: currentOrder });
      } else {
        console.error("error getting online -----");
      }

      const result = await axios.get(`${process.env.REACT_APP_API}/partners/permissions/online_virtual/can_user_create_online`);
      if (result.data.success) {
        this.setState({
          canUserCreate: true
        })
      }
      
    } catch (err) {
      console.error(err);
    }
  }

  copyExistingProgram = async () => {
    const copyFromProgram = { ...this.state.programList.find(program => parseInt(program.id) === parseInt(this.state.copyProgram)) }

    if (copyFromProgram.program_name === this.state.new_program_name) {
      toast.error("You cannot choose the same name");
      return;
    }

    let product_stripe = null;
    const copyOfPlans = [...this.global.online.plans];
    this.global.online.plans = [];
    try {
      for (const plan of copyOfPlans) {
        const parse_plan = JSON.parse(plan);

        if (parse_plan.type === "Free") {

        } else {
          const ep = `${process.env.REACT_APP_API}/partners/stripe/add_products`;
          const res = await axios.post(ep, {
            ...parse_plan,
            parent: "Online",
            name: this.global.online.program_name,
            type: parse_plan.type ? parse_plan.type : "Recurring",
            product: product_stripe
          });

          if (parse_plan.type) {
            if (parse_plan.type === "Single Session") {
              product_stripe = res.data.data.product;
              this.setGlobal(
                {
                  online: {
                    ...this.global.online,
                    product_stripe: res.data.data.product,
                    plans: [
                      ...this.global.online.plans,
                      JSON.stringify({
                        ...parse_plan,
                        id: this.global.online.plans.length,
                        prodId: res.data.data.id
                      })
                    ]
                  }
                },
              );
            } else {
              product_stripe = res.data.data.product;
              this.setGlobal(
                {
                  online: {
                    ...this.global.online,
                    product_stripe: res.data.data.product,
                    plans: [
                      ...this.global.online.plans,
                      JSON.stringify({
                        ...parse_plan,
                        id: this.global.online.plans.length,
                        prodId: res.data.data.id,
                        method: "Online",
                        type: parse_plan.type,
                        dropIn_stripe: res.data.data.dropIn_stripe,
                        semester_installments_plan_stripe:
                          res.data.data.semester_installments_plan_stripe
                      })
                    ]
                  }
                },
              );
            }

          } else {
            product_stripe = res.data.data.product;
            this.setGlobal(
              {
                online: {
                  ...this.global.online,
                  product_stripe: res.data.data.product,
                  plans: [
                    ...this.global.online.plans,
                    JSON.stringify({
                      ...parse_plan,
                      prodId: res.data.data,
                      id: this.global.online.plans.length
                    })
                  ]
                }
              },
            );
          }
        }
      }
      await this.dispatch.saveVirtual();
    } catch (err) {
      console.error(err)
    }

    this.props.onClose();
  }

  getListChoices = () => {
    const programChoices = this.state.programList.map(program => {
      return {
        value: program.id,
        text: program.program_name
      };
    })
    return programChoices;
  };

  getBaseModalFields() {
    const fieldArr = [];

    const copyFromProgram = {
      type: DROPDOWN,
      data: {
        name: "Copy From Program",
        placeholder: "Choose here",
        value: this.state.copyProgram,
        required: true,
        choices: this.getListChoices(),
        handleChange: e => {
          this.setState({ copyProgram: e.target.value })
          const copyFromProgram = { ...this.state.programList.find(program => parseInt(program.id) === parseInt(e.target.value)) }
          copyFromProgram.id = null;
          copyFromProgram.program_name = "";
          copyFromProgram.display_order = 0;
          copyFromProgram.published = false;
          copyFromProgram.isArchived = false;
          copyFromProgram.daily_co_room_name = null;

          delete copyFromProgram.timezone;
          delete copyFromProgram.createdAt;
          delete copyFromProgram.updatedAt;
          delete copyFromProgram.product_stripe;
          this.setGlobal({
            online: {
              ...copyFromProgram
            }
          });
        }
      },
      validators: { validateSubmit: () => this.state.copyProgram != "" }
    };

    const newProgramName = {
      type: STRING_INPUT,
      data: {
        name: "New Program Name",
        placeholder: "Enter new name here",
        required: true,
        value: this.state.new_program_name,
        handleChange: e => {
          this.setState({
            new_program_name: e.target.value
          })
          this.setGlobal({
            online: {
              ...this.global.online,
              program_name: e.target.value
            }
          })
        },
      },
      validators: { validateSubmit: () => this.state.new_program_name != "" }
    }

    fieldArr.push(copyFromProgram);

    if (this.state.copyProgram) {
      fieldArr.push(newProgramName);
    }

    return fieldArr;
  }

  getLimitReachedModal = () => {
    const props = {
      title: "Create program",
      fields: [
        { type: TITLE, data: { name: "You have reached your program limit." } }
      ],
      handleClose: this.props.onClose
    };
    return <BaseModal {...props} />;
  };

  getBaseModalButtons = () => {
    const copyButton = {
      name: "Copy",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.copyExistingProgram(e)
    };

    return [copyButton];
  };

  getBaseModalProps = () => {
    console.log("STATE", this.state, this.props);
    return {
      title: "Copy Milestone",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    if (!this.state.canUserCreate) {
      return this.getLimitReachedModal()
    }

    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default CopyExistingOnlineVirtualModal;
