import React from "reactn";
import { Table } from "../../components/UI";
import Axios from "axios";
import { format } from "date-fns";
import ChildComment from "../../components/table/admins/ChildComment";
class Enrolled extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
    this.headings = [
      { id: "fullName", label: "Student" },
      {
        id: "startDate",
        label: "Start Date",
        infoBalloon: (
          <>
            <p>
              <span className="green-circle mr-1"></span>
              Currently attending
            </p>
            <p>
              <span className="orange-circle mr-1"></span>
              Deposit paid, start date pending
            </p>
          </>
        ),
        customCell: row => (
          <td key={`${row.id}-acc`}>
            {format(new Date(row.startDate), "LLLL dd, yyyy")}
          </td>
        )
      },
      {
        id: "endDate",
        label: "End Date",
        customCell: (r, i) => {
          return (
            <td key={i + `Hallo`}>
              {r.endDate ? format(new Date(r.endDate), "LLLL dd, yyyy") : "N/A"}
            </td>
          );
        }
      },
      {
        id: "deposit",
        label: "Deposit Paid",
        customCell: (r, i) => {
          return <td key={i + `depo`}>{r.depositPaid ? "Yes" : "No"}</td>;
        }
      },
      {
        id: "programPlan",
        label: "Program Plan",
        customCell: this.planCell
      },
      { id: "action", label: "Action(s)" }
    ];

    this.actions = [
      { name: "View", action: this.viewEnrollment },
      { name: "Notes", action: this.viewComments }
    ];
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "Enrolled"]
    });
  }

  viewEnrollment = async row => {
    this.props.history.push(
      `/educators/applications/${row.slug}/${row.submittedDate}`
    );
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/edu/enrollment`;
    const results = await Axios.get(ep);

    if (results.data.success) {
      this.setState({
        data: results.data.data
      });
    }
  }
  planCell = (r, i) => {
    if (r.categoryName) {
      return (
        <td key={`${i}-pl`}>
          {r.categoryName}
          <br />
          {r.numDays} days/week
        </td>
      );
    } else return <td key={`${i}-pl`}>Not Applicable</td>;
  };
  viewComments = async row => {
    this.setState({ commentsModalShown: true, modalData: row });
  };

  render() {
    return (
      <div className="container-fluid enrolled">
        <div className="cont">
          <h1>Enrolled</h1>
        </div>
        <ChildComment
          open={this.state.commentsModalShown}
          data={this.state.modalData}
          onClose={() =>
            this.setState({
              commentsModalShown: false,
              modalData: null
            })
          }
        />
        {/* <Table.Educators.EnrolledTable
          useEnrolledStudents={this.global.enrolledStudents}
        /> */}
        <div className="cont">
          <Table
            headings={this.headings}
            data={this.state.data}
            actions={this.actions}
          />
        </div>
      </div>
    );
  }
}

export default Enrolled;
