import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, RED_BUTTON, WHITE } from "./baseModal/colours";
import StaffScheduleCheckInConfirmationModal from "./StaffScheduleCheckInConfirmationModal";
import StaffScheduleCheckOutConfirmationModal from "./StaffScheduleCheckOutConfirmationModal";

class StaffScheduleCheckInModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      staff: this.props.selectedStaff.username,
      task: this.props.selectedSchedule.task_name,
      taskDescription: this.props.selectedSchedule.task_description,
      isRepeatingWeekly: this.props.selectedSchedule.isRepeatingWeekly,
      startDate: this.props.selectedSchedule.start_time,
      endDate: this.props.selectedSchedule.end_time,
      daysOfTheWeek: this.props.selected_days_of_week,
      checkedIn: this.props.selectedSchedule.checked_in,
      checkedOut: this.props.selectedSchedule.checked_out,
      openCheckInConfirmationModal: false,
      openCheckOutConfirmationModal: false
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/roles`;
    const users = await axios.get(ep);
    if (users.data.success) {
      this.setState({
        users: users.data.data.users
      });
    }
  }

  getAllStaff = () => {
    return [
      { value: this.global.dsUser.id, text: this.global.dsUser.name },
      ...this.state.users
        .filter(f => !f.isArchived && f.sub_user)
        .map(({ id, username }) => ({ value: id, text: username }))
    ];
  };

  hourOptions = () => {
    const options = [];
    for (let hour = 1; hour <= 12; hour++) {
      const time = hour.toString().padStart(2, "0") + ":00";
      options.push({
        value: hour * 60,
        key: hour * 60,
        text: time
      });
    }
    return options;
  };

  minuteOptions = () => {
    const options = [];
    for (let minute = 0; minute < 60; minute++) {
      const time = minute.toString().padStart(2, "0");
      options.push({
        value: minute,
        key: minute,
        text: time
      });
    }
    return options;
  };

  timePeriodOptions = () => {
    const options = [
      { value: "AM", text: "AM" },
      { value: "PM", text: "PM" }
    ];
    return options;
  };

  getDaysOfTheWeek = () => {
    const options = [
      { value: "Sunday", text: "Sunday" },
      { value: "Monday", text: "Monday" },
      { value: "Tuesday", text: "Tuesday" },
      { value: "Wednesday", text: "Wednesday" },
      { value: "Thursday", text: "Thursday" },
      { value: "Friday", text: "Friday" },
      { value: "Saturday", text: "Saturday" }
    ];
    return options;
  };

  getBaseModalFields() {
    const fields = [];

    const staffField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Staff",
        content: this.state.staff
      }
    };

    const taskName = {
      type: CONTENT_BLOCK,
      data: {
        name: "Task Name",
        content: this.state.task
      }
    };

    // const taskDescription = {
    //   type: TEXT_AREA,
    //   data: {
    //     name: "Task Description",
    //     value: this.state.taskDescription,
    //     isDisabled: true
    //   }
    // };

    // const isRepeatingField = {
    //   type: SWITCH,
    //   data: {
    //     name: "Repeats every week",
    //     required: false,
    //     checked: this.state.isRepeatingWeekly,
    //     disabled: true
    //   }
    // };

    const startTime = new Date(this.state.startDate);
    const year = startTime.getFullYear();
    const month = String(startTime.getMonth() + 1).padStart(2, "0");
    const day = String(startTime.getDate()).padStart(2, "0");

    const timeString = startTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true
    });

    const dateString = `${year}-${month}-${day}`;

    const startTimeString = `${dateString} ${timeString}`;

    const startDate = {
      type: CONTENT_BLOCK,
      data: {
        name: "Start Date/Time",
        content: startTimeString
      }
    };

    const endTime = new Date(this.state.endDate);
    const endYear = endTime.getFullYear();
    const endMonth = String(endTime.getMonth() + 1).padStart(2, "0");
    const endDay = String(endTime.getDate()).padStart(2, "0");

    const timeStringEnd = endTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true
    });
    const dateStringEnd = `${endYear}-${endMonth}-${endDay}`;
    const endTimeString = `${dateStringEnd} ${timeStringEnd}`;

    const endDate = {
      type: CONTENT_BLOCK,
      data: {
        name: "End Date/Time",
        content: endTimeString
      }
    };

    const daysOfTheWeek = {
      type: CONTENT_BLOCK,
      data: {
        name: "Days of the week",
        content: ""
      }
    };

    fields.push(staffField);
    fields.push(taskName);
    fields.push(startDate);
    fields.push(endDate);

    return fields;
  }

  getBaseModalButtons = () => {
    const fields = [];

    const checkInButton = {
      name: "Check-In",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatoryKey: "validateSubmit",
      handleClick: () => this.props.onCheckIn()
    };

    const checkOutButton = {
      name: "Check-Out",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatoryKey: "validateSubmit",
      handleClick: () => this.props.onCheckOut()
    };

    if (!this.state.checkedIn) {
      fields.push(checkInButton);
    } else if (!this.state.checkedOut) {
      fields.push(checkOutButton);
    }

    return fields;
  };

  getBaseModalProps = () => {
    return {
      title: "Schedule Info",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(StaffScheduleCheckInModal);
