import React from "react";
import PropTypes from "prop-types";
// import axios from 'axios'
// import { toast } from "react-toastify";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const DeleteRoomModal = ({ room, onClose, deleteRoom }) => {
  return (
    <div className="request-modal-cont">
      <div className="request-modal lesspadding">
        <div style={{ display: "block" }}>
          <img
            src={ModalBanner}
            alt="Modal Banner"
            style={{
              height: "40px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "48px",
              marginBottom: ".5rem"
            }}
          >
            <Typography data-testid="create-rooms-modal-heading">
              Delete Room
            </Typography>
            <IconButton
              style={{
                borderRadius: "50%",
                color: "#2880FF",
                marginBottom: "1rem"
              }}
              onClick={_ => onClose()}
            >
              <Close />
            </IconButton>
          </div>
          <Grid container direction="column">
            <Grid item>
              <Typography>
                Are you sure you want to delete this room?
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems="stretch" justify="center">
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => deleteRoom(room)}
                >
                  Yes
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={_ => onClose()}
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

DeleteRoomModal.propTypes = {
  room: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteRoom: PropTypes.func.isRequired
};

export default DeleteRoomModal;
