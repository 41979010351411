import React from "reactn";
import { TabbedTable } from "../../components/UI";
import FReg from "../../components/table/admins/FRegistrationModal";
import axios from "axios";
import { format, subDays } from "date-fns";
import PauseModal from "../../components/modals/PauseModal";
import "react-datepicker/dist/react-datepicker.css";

class FamilyApplications extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      programs: [],
      selectedParent: "",
      detailsModalShown: false,
      modalData: null,
      startDate: null,
      endDate: null
    };
    this.tabs = ["ACTIVE", "INACTIVE"];
    this.headings = {
      ACTIVE: [
        { id: "programName", label: "PROGRAM" },
        { id: "fullName", label: "CHILD NAME" },
        {
          id: "enrollmentPeriod",
          label: "ENROLLMENT PERIOD",
          customCell: this.stepsCell
        },
        {
          id: "plan",
          label: "PRICING PLAN",
          customCell: this.lastActionCell
        },

        ,
        {
          id: "actions",
          label: "",
          customCell: row => (
            <td style={{ whiteSpace: "nowrap" }}>
              <button onClick={_ => this.viewDetail(row)}>View Details</button>
              <button
                onClick={_ =>
                  this.props.history.push(
                    `/admin/parent-tuition/${row.dsUserId}`
                  )
                }
              >
                Tuition Page
              </button>
              <button
                onClick={_ =>
                  this.setState({ pausemodal: true, pausedata: row })
                }
                style={{ display: !!row.paused ? "none" : "inherit" }}
              >
                Pause Enrollment
              </button>
              {!!row.paused && (
                <span style={{ marginLeft: "10px" }}>
                  {format(new Date(row.from), "LL/dd/yy")} -{" "}
                  {format(subDays(new Date(row.to), 1), "LL/dd/yy")}
                </span>
              )}
            </td>
          )
        }
      ],
      INACTIVE: [
        { id: "programName", label: "PROGRAM" },
        { id: "fullName", label: "CHILD NAME" },
        {
          id: "enrollmentPeriod",
          label: "ENROLLMENT PERIOD",
          customCell: this.stepsCell
        },
        {
          id: "plan",
          label: "PRICING PLAN",
          customCell: this.lastActionCell
        },
        { id: "action", label: "ACTION" }
      ]
    };

    this.actions = [
      { name: "View Details", action: this.viewDetail },
      {
        name: "Tuition Page",
        action: row =>
          this.props.history.push(`/admin/parent-tuition/${row.dsUserId}`)
      }
    ];
    this.viewDetail = this.viewDetail.bind(this);
    this.filterData = this.filterData.bind(this);
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/family/enrollments`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setState({
        data: {
          ACTIVE: res.data.data.enrollments.filter(
            e => new Date() < new Date(e.endDate) || !e.endDate
          ),
          INACTIVE: res.data.data.enrollments.filter(
            e => e.endDate && new Date() > new Date(e.endDate)
          )
        }
      });

      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  filterData = () => {
    let newData = this.state.enrollments;
    if (this.state.selectedParent) {
      newData = newData.filter(d => {
        return d.programId.toString() === this.state.selectedParent;
      });
    }
    if (this.state.startDate) {
      newData = newData.filter(
        d => new Date(d.startDate) >= new Date(this.state.startDate)
      );
    }
    if (this.state.endDate) {
      newData = newData.filter(
        d => new Date(d.endDate) <= new Date(this.state.endDate)
      );
    }
    return newData;
  };

  lastActionCell = (r, i) => {
    return <td key={i}>{`${r.categoryName} - ${r.numDays} days / wk`}</td>;
  };

  stepsCell = (r, i) => {
    return (
      <td key={i}>
        {format(new Date(r.startDate), "LLL dd, yyyy")} -{" "}
        {r.endDate ? format(new Date(r.endDate), "LLL dd, yyyy") : "N/A"}
      </td>
    );
  };

  viewDetail = async row => {
    this.setState({ detailsModalShown: true, modalData: row });
  };

  render() {
    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          {this.state.pausemodal && (
            <PauseModal
              onClose={_ => {
                this.setState({
                  pausemodal: false
                });
                this.forceUpdate();
              }}
              data={this.state.pausedata}
            />
          )}
          <div className="cont">
            <h1>Active Enrollments</h1>
          </div>
          <div className="body">
            <FReg
              open={this.state.detailsModalShown}
              data={this.state.modalData}
              onClose={() =>
                this.setState({
                  detailsModalShown: false,
                  modalData: null
                })
              }
            />
            <div className="cont">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="table-responsive">
                    <TabbedTable
                      headings={this.headings}
                      data={this.state.data}
                      actions={this.actions}
                      tabs={this.tabs}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FamilyApplications;
