import React from "react";
import "../fieldComponent.css";
import "./Button.css";
import Title from "../Title";

const Button = props => {
  const {
    name,
    buttonText,
    handleClick,
    buttonColour,
    titleStyle,
    textColour,
    disabled
  } = props;

  return (
    <div>
      {/* <p className="field-component-title">{name}</p> */}
      <Title name={name} titleStyle={titleStyle} />
      <button
        className="button-field-button"
        onClick={handleClick}
        style={{ backgroundColor: buttonColour, color: textColour, cursor: disabled ? "not-allowed" : "pointer" }}
        disabled={disabled || false}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default Button;
