import React from "reactn";
import ProfilePhotos from "./profilePhotos";
import ProfileForm from "./profileForm";
import Billing from "./Billing";
import Axios from "axios";
import { toast } from "react-toastify";
class AccountSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      avatar_name: this.global.dsUser.avatar_name,
      login_name: this.global.dsUser.login_name
    };
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Account Settings", to: "/settings" }
      ]
    });
  }
  saveInfo = async () => {
    if (this.state.avatar_name.length < 5) {
      toast.error("Minimum length of account name must be 5 characters.");
      return;
    } else if (this.state.login_name.length < 5) {
      toast.error("Minimum length of Login ID must be 5 characters.");
      return;
    } else if (this.state.login_name.length > 255) {
      toast.error("Maximum length of Login ID is 255 characters.");
      return;
    }

    const ep1 = `${process.env.REACT_APP_API}/adm/partner/update-login`;

    const ep2 = `${process.env.REACT_APP_API}/adm/partner/update`;
    
    const result1 = await Axios.post(ep1, {
      login_name: this.state.login_name
    });

    let result2;
    if (result1.data.success) {
      result2 = await Axios.post(ep2, {
        avatar_name: this.state.avatar_name,
        login_name: this.state.login_name,
        // email: this.global.dsUser.email
      });
    } else {
      toast.error(result1.data.message);
      return;
    }

    if (result2 && result2.data.success) {
      toast.success(`Profile Updated!`);
    } else {
      toast.error("Failed to update profile");
    }
  }; 

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/partner/billing`;
    const res = await Axios.get(ep);
    if (res.data.success && res.data.data?.sources?.data?.length > 0) {
      const sources = res.data.data.sources.data;
      console.log("billing sources", res.data.data);
      this.setState({
        card: {
          last4: sources[sources.length - 1].last4,
          exp_month: sources[sources.length - 1].exp_month,
          exp_year: sources[sources.length - 1].exp_year,
          brand: sources[sources.length - 1].brand
        }
      });
    }
  }
  async refreshCC() {
    const ep = `${process.env.REACT_APP_API}/adm/partner/billing`;
    const res = await Axios.get(ep);
    if (res.data.success) {
      this.setState({
        card: {
          last4: res.data.data.sources.data[0].last4,
          exp_month: res.data.data.sources.data[0].exp_month,
          exp_year: res.data.data.sources.data[0].exp_year,
          brand: res.data.data.sources.data[0].brand
        }
      });
    }
  }
  render() {
    return (
      // <!-- Begin Page Content -->
      <div className="container-fluid">
        <div className="cont">
          <h1>Account Settings</h1>
        </div>
        <div className="cont boxes-row">
          <ProfileForm 
            nameCount={e => this.setState({ avatar_name: e })} 
            updateLoginName={e => this.setState({ login_name: e })}
          />
          <Billing card={this.state.card} refreshCC={_ => this.refreshCC()} />
          <ProfilePhotos />
        </div>
        <div className="cont">
          <div className="buttons">
            <button className="savebtn" onClick={this.saveInfo}>
              SAVE
            </button>
            <button className="nextbtn">CANCEL</button>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountSettings;
