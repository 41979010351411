import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";
import axios from "axios";

class SectionSix extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { days: {} };
  }
  handleChange = (e, v) => {
    this.setGlobal({
      organizationInfo: {
        ...this.global.organizationInfo,
        [e]: v
      }
    });
  };

  updateBankAccount = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/organization_profile/update-bank`;
    const results = await axios.post(ep);

    if (results.data.success) {
      window.open(results.data.data.url);
    }
  };

  customForm = () => {
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-12">
              <h3>
                If you want to opt-in to receive payment electronically via
                Dreamschools. Please link your bank account below.
              </h3>
            </div>
            <div className="col-12 d-flex" style={{ justifyContent: "center" }}>
              {this.global.organizationInfo.stripeId ? (
                <h2 style={{ paddingRight: '25px', paddingLeft: '25px' }}>Bank Account Linked!</h2>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_ =>
                    (window.location.href =
                      process.env.REACT_APP_MODE === "staging"
                        ? "https://dashboard.stripe.com/express/oauth/authorize?redirect_uri=http://localhost:3000/partners/profile&response_type=code&client_id=ca_D8Tdn74PMebZ7CrToxdjz96Xszl0FEv2&scope=read_write"
                        : `https://dashboard.stripe.com/express/oauth/authorize?redirect_uri=${process.env.REACT_APP_REDIRECT_URI}/partners/profile&response_type=code&client_id=${process.env.REACT_APP_STRIPE_OAUTH_CLIENT_ID}&scope=read_write`)
                  }
                  disabled={this.global.organizationInfo.manual_payments}
                >
                  Link my bank account!
                </button>
              )}
            </div>
            <div className="col-12 d-flex" style={{ justifyContent: "center" }}>
              {this.global.organizationInfo.stripeId && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_ => this.updateBankAccount()}
                >
                  Update bank account!
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Link your bank account!"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionSix;
