import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import Switch from "@material-ui/core/Switch";
import { Dropdown } from "semantic-ui-react";
import AddAddress from "../../../../components/modals/AddAddressModal";

class SectionOne extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.locations = [
      {
        address:
          this.global.organizationInfo.address1 +
          ", " +
          this.global.organizationInfo.city +
          ", " +
          this.global.organizationInfo.province +
          " " +
          this.global.organizationInfo.postalcode +
          ", " +
          this.global.organizationInfo.country
      },
      ...this.global.organizationInfo.other_locations.map(e => ({
        address: e.address
      }))
    ];
    this.theme = [`FF6663`, `039BE5`, `E57403`, `1C2680`, `079475`];

    this.customForm = this.customForm.bind(this);
  }
  handleChange = (e, v) => {
    this.setGlobal({
      program: {
        ...this.global.program,
        [e]: v
      }
    });
  };

  //Loading the initial program category from the create program modal
  componentDidMount() {
    this.props.initialProgramCategory &&
      this.setGlobal({
        program: {
          ...this.global.program,
          program_category: this.props.initialProgramCategory,
          addChild: this.props.initialAddChild,
          manual_invoices: this.props.initialManualInvoices,
          application_required: this.props.initialApplicationRequired,
          transaction_cost: this.props.initialTransactionCost,
          auto_withdraw: this.props.initialAutoWithdraw,
          isRecurring: this.props.initialIsRecurring,
          allow_same_enrollment: this.props.allowSameEnrollment
        }
      });
  }

  customForm = () => {
    return (
      <div className="formbody">
        {/* {!this.state.timeChangeShown ? null : (
          <ConfirmTimeChangeForm onClose={this.setTime} />
        )} */}

        <form>
          {this.state.openAddress && (
            <AddAddress
              onClose={_ => this.setState({ openAddress: false })}
              open={this.state.openAddress}
              addLoc={address => {
                const other_locs = [
                  ...this.global.organizationInfo.other_locations
                ];
                other_locs.push(address);
                this.locations.push(address);
                this.setGlobal({
                  organizationInfo: {
                    ...this.global.organizationInfo,
                    other_locations: [...other_locs]
                  },
                  program: {
                    ...this.global.program,
                    address1: address.address
                  }
                });
                this.dispatch.saveOrganizationalProfile();
              }}
            />
          )}
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Program Category<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <formTypes.MultiProgramCategories
                  //this.props.initialProgramCategory
                  value={this.global.program.program_category}
                  name="program_category"
                  onChange={this.handleChange}
                  isDisabled={
                    !!this.global.program.program_category
                  }
                ></formTypes.MultiProgramCategories>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Program Structure<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <select
                  // Disable "Program Structure" dropdown if an active plan already exists
                  // This is to prevent potential cross wiring between the structure and plans (i.e. recurring plan for a non-recurring program)
                  disabled={
                    this.global.program.plans.filter(plan => {
                      const element = typeof e === "string" ? JSON.parse(plan) : plan;
                      let ret = false;
                      const { archived } = element;
                      if (!archived) ret = true;
                      return ret;
                    }).length !== 0
                  }
                  className="input-fields"
                  style={{ width: "100%" }}
                  name="program_details"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.program.program_details}
                >
                  <option value="">Please Select</option>
                  {this.global.program.program_category ===
                  "Single Sessions" ? (
                    <>
                      <option>Lessons / Sessions</option>
                      <option>Classes</option>
                    </>
                  ) : this.global.program.isRecurring ? (
                    <option>Recurring</option>
                  ) : (
                    <>
                     {/*  <option>Recurring</option> */}
                      <option>Semester</option>
                      <option>Camps</option>
                      <option>Workshops / Classes</option>
                     {/*  <option>Single Sessions</option> */}
                      {/* <option>Slots / Sessions</option>
                    <option>One-on-one Pricing</option> */}
                    </>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Program Name<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  className="input-fields"
                  value={this.global.program.program_name}
                  name="program_name"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Program Color<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <Dropdown
                placeholder="Pick a Color"
                fluid
                selection
                onChange={(_, { value }) => {
                  this.setGlobal({
                    program: {
                      ...this.global.program,
                      program_color: value
                    }
                  });
                }}
                value={this.global.program.program_color}
                options={this.theme.map((e, i) => ({
                  text: `Color ${i + 1}`,
                  value: i,
                  key: e,
                  image: {
                    bordered: true,
                    src: `https://dummyimage.com/1220/${e}/${e}`
                  }
                }))}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">All Ages</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <Switch
                  name="all_ages"
                  checked={this.global.program.all_ages}
                  onClick={_ => {
                    this.handleChange(
                      "all_ages",
                      !this.global.program.all_ages
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Age Groupings</h3>
            </div>
            <div className="col-7 d-flex">
              <formTypes.ChildAgeGroupSelect
                value={this.global.program.age_groupings.map(e =>
                  typeof e === "string" ? JSON.parse(e) : e
                )}
                name="age_groupings"
                onChange={this.handleChange}
                isDisabled={this.global.program.all_ages}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Age Range</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <formTypes.Select
                  options={new Array(61).fill(0).map((_, i) => ({
                    label: i === 1 ? `${i} year` : `${i} years`,
                    value: i
                  }))}
                  onChange={e => {
                    const ageEntered = e.target.value;
                    const prevRange = this.global.program.age_range;
                    if (parseInt(ageEntered) > parseInt(prevRange[1])) {
                      // If first age larger than second age, adjust second age
                      // to match first age.
                      this.handleChange("age_range", [ageEntered, ageEntered]);
                    } else {
                      this.handleChange("age_range", [
                        ageEntered,
                        prevRange[0]
                      ]);
                    }
                  }}
                  value={this.global.program.age_range[0]}
                  isDisabled={this.global.program.all_ages}
                />
              </div>
              <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                To
              </span>
              <div className={`w-100`}>
                <formTypes.Select
                  options={new Array(61).fill(0).map((_, i) => ({
                    label: i === 1 ? `${i} year` : `${i} years`,
                    value: i
                  }))}
                  onChange={e => {
                    const ageEntered = e.target.value;
                    const prevRange = this.global.program.age_range;
                    if (parseInt(ageEntered) < parseInt(prevRange)) {
                      // If first age larger than second age, adjust second age
                      // to match first age.
                      this.handleChange("age_range", [ageEntered, ageEntered]);
                    } else {
                      this.handleChange("age_range", [
                        prevRange[0],
                        ageEntered
                      ]);
                    }
                  }}
                  //this.global.program.age_range[1]
                  value={this.global.program.age_range[1]}
                  isDisabled={this.global.program.all_ages}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Choose an Address<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <select
                  className="input-fields"
                  value={this.global.program.address1}
                  name="address1"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  style={{ width: "100%" }}
                >
                  <option value="" selected disabled hidden>
                    Please Select
                  </option>
                  {this.locations.map(e => (
                    <option value={e.address}>{e.address}</option>
                  ))}
                </select>
              </div>
            </div>
            <div
              className="col-12 d-flex"
              style={{ justifyContent: "flex-end", marginTop: "10px" }}
            >
              <button
                type="button"
                className="btn addNew-btn" //btn-primary
                onClick={_ => this.setState({ openAddress: true })}
              >
                Add New Address
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Show Program Location</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                {/* <input
                  type="checkbox"
                  name="show_location"
                  onChange={e =>
                    this.setGlobal({
                      program: {
                        ...this.global.program,
                        show_location: e.target.checked
                      }
                    })
                  }
                  checked={this.global.program.show_location}
                /> */}
                <Switch
                  name="show_location"
                  checked={this.global.program.show_location}
                  onClick={_ => {
                    this.setGlobal({
                      program: {
                        ...this.global.program,
                        show_location: !this.global.program.show_location
                      }
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Program Details"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionOne;
