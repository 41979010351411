import React from "reactn";
import axios from "axios";
import { format, addDays, getDay } from "date-fns";

import { NewTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
import LinkIcon from "@material-ui/icons/Link";
import { Delete } from "@material-ui/icons";
import { FranchiseImage } from "../../assets";
import FranchiseViewModal from "../../components/modals/FranchiseViewModal";
import AddLeadModal from "../../components/modals/AddLeadModal";
class FranchiseDashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      franchiseInfo: {},
      openFranchiseViewModal: false,
      selectedPartnerId: null,
      leadModal: false
    };

    this.tabs = ["Active", "Archive"];
    this.headings = {
      Active: [
        {
          id: "pic",
          label: "",
          customCell: r => (
            <td key={`${r.id}-image`} style={{ whiteSpace: "nowrap" }}>
              <img
                src={`${FranchiseImage}`}
                style={{
                  width: "130px",
                  height: "78px",
                  borderRadius: "7px",
                  objectFit: "cover"
                }}
              />
            </td>
          )
        },
        { id: "company", label: "Franchisee Name" },
        { id: "name", label: "User Name" },
        {
          id: "status",
          label: "Status",
          customCell: r => {
            return (
              <td key={`${r.id}-status`}>
                {r.isActiveFranchise ? "Active" : "Inactive"}
              </td>
            );
          }
        },
        {
          id: "updatedAt",
          label: "Last Login Time",
          customCell: r => {
            return (
              <td key={`${r.id}-time`}>
                {format(new Date(r.last_logged_in || r.createdAt), "LLLL dd,yyyy hh:mm")}
              </td>
            )
          }
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button
                  style={{ marginRight: "15px" }}
                  onClick={_ =>
                    this.setState({
                      openFranchiseViewModal: true,
                      selectedPartnerId: r.id
                    })
                  }
                >
                  View
                </button>
              </td>
            );
          }
        }
      ],
      Archive: [
        {
          id: "pic",
          label: ""
          // customCell: r => (
          //   <td style={{ whiteSpace: "nowrap" }}>
          //     <img
          //       src={`${process.env.REACT_APP_DS_FILES_S3 + "/" + r.program_photo
          //         }`}
          //       style={{
          //         width: "130px",
          //         height: "78px",
          //         borderRadius: "7px",
          //         objectFit: "cover"
          //       }}
          //     />
          //   </td>
          // )
        },
        { id: "program_name", label: "Program Name" },
        { id: "program_category", label: "Program Type" },
        {
          id: "status",
          label: "Status"
          // customCell: r => {
          //   return <td>{r.published ? "Published" : "Not Published"}</td>;
          // }
        },
        {
          id: "updated",
          label: "Updated"
          // customCell: r => (
          //   <td>{format(new Date(r.updatedAt), "LLLL dd,yyyy hh:mm")}</td>
          // )
        }
      ]
    };

    this.actions = [{ name: "Notes", action: null }];
  }

  async componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    const ep1 = `${process.env.REACT_APP_API}/franchise/dashboard/${this.global.franchiseUser.id}`;
    const ep2 = `${process.env.REACT_APP_API}/franchise/get-profile/${this.global.franchiseUser.id}`;
    const results = await axios.get(ep1);
    const franchiseInfo = await axios.get(ep2);

    if (results.data.success) {
      this.setState({
        data: {
          Active: results.data.data.filter(f => f.isActiveFranchise),
          Archive: results.data.data.filter(f => !f.isActiveFranchise)
        }
      });
    }

    if (franchiseInfo.data.success) {
      this.setState({
        franchiseInfo: franchiseInfo.data.data
      });
    }
  };

  render() {
    return (
      <div className="admin">
        {this.state.openFranchiseViewModal && (
          <FranchiseViewModal
            onClose={_ => this.setState({ openFranchiseViewModal: false })}
            selectedPartnerId={this.state.selectedPartnerId}
          />
        )}
        {this.state.leadModal && (
          <AddLeadModal
            onClose={_ => this.setState({ leadModal: false })}
            addLead={_ => this.fetchInitialData()}
            franchiseInfo={this.state.franchiseInfo}
            leads={this.state.data}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div
              className="col-md-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="d-sm-flex align-items-center justify-content-between">
                <h1 className="">Franchise Dashboard</h1>
              </div>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  <button
                    className="newapplications"
                    onClick={_ => {
                      this.setState({ leadModal: true });
                    }}
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Add New
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="cont">
            <div className="tablecont">
              <div className="table-responsive">
                <NewTabbedTable
                  tabs={this.tabs}
                  headings={this.headings}
                  data={this.state.data}
                  actions={this.actions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FranchiseDashboard;
