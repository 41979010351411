import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";
import { Button } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import ImageCropModal from "../../../../components/modals/ImageCropModal";
import { Dropdown } from "semantic-ui-react";
import {
  Theme1,
  Theme2,
  Theme3,
  Theme4,
  Theme5,
  Theme6,
  Theme7
} from "../../../../assets";
import { toast } from "react-toastify";

class SectionFive extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      openImageCropModal: false,
      image: "",
      marketplaceImage: ""
    };
    this.banners = [
      "partners/Banners/Banner1.jpg",
      "partners/Banners/Banner2.png",
      "partners/Banners/Banner3.jpg",
      "partners/Banners/Banner4.jpg",
      "partners/Banners/Banner5.jpg",
      "partners/Banners/Banner6.png",
      "partners/Banners/Banner7.png",
      "partners/Banners/Banner8.jpg"
    ];
    this.theme = [Theme5, Theme2, Theme3, Theme1, Theme4, Theme6, Theme7];
  }

  handleChange = (e, v) => {
    /*if (
      !this.global.profile.applicationSubmitted ||
      this.global.dsUser.accountType === "Admin"
    ) {
      this.setGlobal({
        myApplicationProfileAddress: {
          ...this.global.myApplicationProfileAddress,
          [e]: v
        }
      });
    }*/
  };
  uploadNew() {
    const uploadEle = document.getElementById("uploadPhoto");
    uploadEle.click();
  }

  fileChange(e) {
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      // const fd = new FormData();
      // fd.set("type", this.state.type);
      // fd.set("image", e.target.files[0]);
      //this.dispatch.uploadProgramPhoto(fd);
      const file = new FileReader();
      file.readAsDataURL(e.target.files[0]);
      file.onloadend = () => {
        document.getElementById("uploadPhoto").value = "";
        this.setState({ image: file.result, openImageCropModal: true });
      };
    }
  }

  uploadNewMarketplaceImage() {
    const uploadEle = document.getElementById("uploadMarketplaceImage");
    uploadEle.click();
  }

  fileMPImageChange(e) {
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      // const fd = new FormData();
      // fd.set("type", this.state.type);
      // fd.set("image", e.target.files[0]);
      //this.dispatch.uploadProgramPhoto(fd);
      const file = new FileReader();
      file.readAsDataURL(e.target.files[0]);
      file.onloadend = () => {
        document.getElementById("uploadMarketplaceImage").value = "";
        this.setState({
          marketplaceImage: file.result,
          openImageCropModal: true
        });
      };
    }
  }

  customForm = () => {
    return (
      <div className="formbody">
        {this.state.openImageCropModal && (
          <ImageCropModal
            sourceImage={
              this.state.type == "logo"
                ? this.state.image
                : this.state.marketplaceImage
            }
            onClose={() => {
              this.setState({ openImageCropModal: false });
            }}
            aspectRatio={1 / 1}
            uploadImage={croppedImageURL => {
              const fd = new FormData();
              fd.set("id", this.global.organizationInfo.id);
              fd.set("type", this.state.type);
              fd.set("image", croppedImageURL);
              this.dispatch.uploadProgramPhoto(fd);
              this.setState({ openImageCropModal: false });
              setTimeout(
                _ => toast.success(`Image Uploaded Successfully`),
                7000
              );
            }}
          />
        )}
        <form>
          <div className="row">
            <div className="col-5">
              <h3>
                Banner Image<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <Dropdown
                placeholder="Select Banner"
                fluid
                selection
                onChange={(_, { value }) =>
                  this.setGlobal({
                    organizationInfo: {
                      ...this.global.organizationInfo,
                      bcg_image: value
                    }
                  })
                }
                value={this.global.organizationInfo.bcg_image}
                options={this.banners.map((e, i) => ({
                  text: `Banner ${i + 1}`,
                  value: e,
                  key: e,
                  image: {
                    bordered: true,
                    src: `${process.env.REACT_APP_DS_FILES_S3}/${e}`
                  }
                }))}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>Marketplace Image</h3>
            </div>
            <div className="col-7 d-flex">
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<CloudUpload />}
                onClick={_ => {
                  this.setState({ type: "marketplace" });
                  this.uploadNewMarketplaceImage();
                }}
                fullWidth
              >
                Upload Image
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-5">
              <h3>
                Dashboard Title
                <span style={{ fontSize: "9px" }}> (16 char max)</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <input
                style={{ width: "100%" }}
                placeholder="XYZ Program"
                name="dashboard_title"
                onChange={e =>
                  this.setGlobal({
                    organizationInfo: {
                      ...this.global.organizationInfo,
                      [e.target.name]: e.target.value.slice(0, 16)
                    }
                  })
                }
                value={this.global.organizationInfo.dashboard_title}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-5">
              <h3>Logo</h3>
            </div>
            <div className="col-7 d-flex">
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<CloudUpload />}
                onClick={_ => {
                  this.setState({ type: "logo" });
                  this.uploadNew();
                }}
                fullWidth
              >
                Upload Image
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 style={{ margin: 0, padding: 0 }}>Pick your theme:</h3>
            </div>
            <div className="col-7 d-flex">
              <Dropdown
                placeholder="Pick a Theme"
                fluid
                selection
                onChange={(_, { value }) => {
                  this.setGlobal({
                    organizationInfo: {
                      ...this.global.organizationInfo,
                      theme: value
                    }
                  });
                }}
                value={this.global.organizationInfo.theme}
                options={this.theme.map((e, i) => ({
                  text: `Theme ${i + 1}`,
                  value: i,
                  key: e,
                  image: {
                    bordered: true,
                    src: `${e}`
                  }
                }))}
              />
            </div>
            <div
              className="col-md-9 col-9"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="file"
                style={{ display: "none" }}
                id="uploadPhoto"
                onChange={e => this.fileChange(e)}
                accept="image/x-png,image/gif,image/jpeg"
              />
            </div>
            <div
              className="col-md-9 col-9"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="file"
                style={{ display: "none" }}
                id="uploadMarketplaceImage"
                onChange={e => this.fileMPImageChange(e)}
                accept="image/x-png,image/gif,image/jpeg"
              />
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Customization"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionFive;
