import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { format, addMonths, addWeeks, getDay } from "date-fns";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, DROPDOWN, GET_HTML } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import Select from "react-dropdown-select";
class CreateCustomerModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayName: "",
      email: "",
      customers: this.props.pendingCustomers,
      selectedCustomer: undefined
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async createCustomer(e) {
    e.preventDefault();

    console.log("customer created");
    const ep = `${process.env.REACT_APP_API}/partners/users/invoice/create_user`;
    const res = await axios.post(ep, {
      displayName: this.state.displayName,
      email: this.state.email
    });
    if (res.data.success) {
      toast.success("Customer Added");
      console.log("res.data.data", res.data.data);

      const ep2 = `${process.env.REACT_APP_API}/partners/orders/invoice/initialize-draft`;
      const results = await axios.post(ep2, {
        partnerId: this.global.dsUser.id,
        dsUserId: res.data.data.id,
        isNewUser: res.data.message === "User Invite Sent" ? false : true,
        orderStartDate: addWeeks(new Date(), 1),
        due_date: addWeeks(new Date(), 3),
        service_start_date: new Date(),
        service_end_date: addMonths(new Date(), 1)
      });
      console.log("results", results);

      if (results.data.success) {
        this.props.history.push({
          pathname: `orders/invoice-builder/${results.data.data.id}`,
          state: { selectedCustomer: [res.data.data], isNewCustomer: true }
        });
      }

      this.props.onClose();
    }
  }

  getAssignOptions = () => {
    return [
      ...this.state.customers
        .map(({ email, displayName }) => ({ value: email, text: displayName }))
        .sort((a, b) => a.text.localeCompare(b.text))
    ];
  };

  pendingCustomersModal = () => {
    return (
      <div>
        <div>
          <label
            htmlFor="displayName"
            style={{
              width: "50%",
              textAlign: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            Pending Customers
          </label>
        </div>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <div
            style={{
              height: "40px",
              width: "100%",
              fontSize: "0.93rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "400",
              color: "#797979"
            }}
          >
            <Select
              options={this.getAssignOptions()}
              name={"selectedUser"}
              placeholder={"Please Select"}
              style={{
                height: "40px",
                width: "100%",
                fontSize: "0.93rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#797979",
                borderRadius: "4px"
              }}
              onChange={e => {
                this.setState({
                  selectedCustomer: e.length === 0 ? null : e[0].value,
                  email: e.length === 0 ? "" : e[0].value,
                  displayName:
                    e && e[0]
                      ? this.state.customers.filter(
                          c => c.email === e[0].value
                        )[0].displayName
                      : ""
                });
              }}
              labelField={"text"}
              valueField={"value"}
              closeOnSelect={true}
              searchable={true}
              keepSelectedInList={true}
              dropdownHandle={true}
              clearable={true}
              searchBy={"text"}
            />
          </div>
        </div>
      </div>
    );
  };

  /// ================== NEW MODAL ========================== ///
  getBaseModalFields() {
    const pendingField = {
      type: DROPDOWN,
      data: {
        name: "Pending Customers",
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            selectedCustomer: e.target.value,
            email: e.target.value,
            displayName: e.target.value
              ? this.state.customers.filter(c => c.email === e.target.value)[0]
                  .displayName
              : ""
          });
        },
        value: this.state.selectedCustomer,
        choices: this.getAssignOptions()
      }
    };

    const pendingFieldNew = {
      type: GET_HTML,
      data: {
        gethtml: this.pendingCustomersModal
      }
    };

    const nameInput = {
      type: STRING_INPUT,
      data: {
        name: "Name",
        required: true,
        placeholder: "Customer Name",
        handleChange: e => this.setState({ displayName: e.target.value }),
        value: this.state.displayName,
        isDisabled: this.state.selectedCustomer ? true : false
      },
      validators: { validateSubmit: notEmptyString(this.state.displayName) }
    };

    const emailInput = {
      type: STRING_INPUT,
      data: {
        name: "Email",
        required: true,
        placeholder: "Customer Email",
        handleChange: e => this.setState({ email: e.target.value }),
        type: "email",
        value: this.state.email,
        isDisabled: this.state.selectedCustomer ? true : false
      },
      validators: { validateSubmit: () => this.state.email !== "" }
    };

    return [pendingFieldNew, nameInput, emailInput];
  }

  getBaseModalButtons = () => {
    const createinvoiceButton = {
      name: "Create Invoice",
      handleClick: e => this.createCustomer(e),
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit"
    };

    return [createinvoiceButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Create New Invoice",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ===================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(CreateCustomerModal);
