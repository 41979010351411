import React from "reactn";
import { TabbedTable } from "../../UI";
// import axios from "axios";
import { withRouter } from "react-router-dom";
import { format } from "date-fns";
import Numeral from "numeral";
class TuitionPaymentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enrollments: [],
      deposits: []
    };
    this.headings = {
      INVOICES: [
        { id: "programName", label: "Program" },
        { id: "amount", label: "Amount", customCell: this.customAmount },
        {
          id: "servicePeriod",
          label: `Service Period`,
          customCell: this.customServicePeriod
        },
        { id: "status", label: "Status", customCell: this.customStatusCell }
      ],
      "SECURITY DEPOSITS": [
        {
          id: "programName",
          label: "Program",
          customCell: r => (
            <td key={`depo-${r.id}`}>{r.description.slice(12)}</td>
          )
        },
        { id: "amount", label: "Amount", customCell: this.customAmountDepo },
        { id: "status", label: "Status", customCell: this.customStatusCell }
      ]
    };
    this.actions = [{ name: "Details", action: this.viewDetail }];
    this.viewDetail = this.viewDetail.bind(this);
  }

  customStatusCell = (r, i) => {
    return (
      <td key={`${i}-stat`}>
        {r.status !== "draft" ? (
          <span className="green">Paid</span>
        ) : (
          <span className="red">Upcoming</span>
        )}
      </td>
    );
  };

  customDepositView = (r, i) => {
    return (
      <td key={`${i}-dep`}>
        <button
          onClick={() =>
            this.props.history.push(
              `/parents/acceptance/${r.slug}/${r.submittedDate}`
            )
          }
        >
          View
        </button>
      </td>
    );
  };

  viewDetail = r => {
    window.open(r.hosted_invoice_url ? r.hosted_invoice_url : r.receipt_url);
  };

  customServicePeriod = r => {
    return (
      <td key={`id`}>
        {r.period_end - r.period_start < 691200
          ? `${format(
              new Date(r.period_start * 1000 + 7 * 24 * 3600000),
              "LLL d"
            )} - ${format(
              new Date(r.period_end * 1000 + 6 * 24 * 3600000),
              "LLL d yyyy"
            )}`
          : `${format(new Date(r.period_start * 1000), "LLL d")} - ${format(
              new Date(r.period_end * 1000),
              "LLL d yyyy"
            )}`}
      </td>
    );
  };

  customDueDate = r => {
    return (
      <td key={`${r.id}-dd`}>
        {format(new Date(r.depositDueDate), "LLL d, yyyy")}
      </td>
    );
  };

  customAmount = r => {
    return (
      <td key={`${r.id}-a`}>{Numeral(r.amount_due / 100).format("$0,0.00")}</td>
    );
  };
  customAmountDepo = r => {
    return (
      <td key={`${r.id}-a`}>{Numeral(r.amount / 100).format("$0,0.00")}</td>
    );
  };

  render() {
    const { enrollments, deposits } = this.props;
    return (
      <TabbedTable
        tabs={["INVOICES", "SECURITY DEPOSITS"]}
        data={{
          INVOICES: enrollments,
          "SECURITY DEPOSITS": deposits
        }}
        headings={this.headings}
        actions={this.actions}
      />
    );
  }
}

export default withRouter(TuitionPaymentTable);
