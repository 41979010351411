import React from "reactn";
import {
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  withStyles,
  Chip
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  infants,
  toddlers,
  preschool,
  tags
} from "../../components/educator/ObservationsData";
import { CloudUpload } from "@material-ui/icons";
import Axios from "axios";
import { toast } from "react-toastify";
const style = {
  formControl: {
    marginBottom: "10px",
    borderBottom: "none",
    borderRadius: "5px 5px 0px 0px"
  },
  input: {
    display: "none"
  }
};
class Observations extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      domains: [],
      skills: [],
      category: {},
      student: "",
      domain: "",
      skill: "",
      notes: "",
      image: undefined,
      tags: []
    };
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "Observations"]
    });
  }
  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/edu/enrollment/onlyds`;
    const result = await Axios.get(ep);
    if (result.data.success) {
      this.setState({
        students: result.data.data
      });
    }
  }
  getCategory(student) {
    const fetch = this.state.students.find(
      s => s.dsUserId === parseInt(student)
    );
    const age = fetch.birthDate;
    const ageNum = Date.now() - new Date(age).getTime();
    const infantmax = 2 * 3600000 * 24 * 365;
    const toddlersmax = infantmax * 1.5;
    return ageNum <= infantmax
      ? infants
      : ageNum <= toddlersmax
      ? toddlers
      : preschool;
  }

  async submitObservation(e) {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/observations`;
    this.setGlobal({
      loading: true
    });
    const formData = new FormData();
    const { skill, domain, notes, student, tags, image } = this.state;
    const finalObject = {
      skill,
      domain,
      notes,
      users: JSON.stringify({ [student]: true }),
      tags: tags.map(e => e.name),
      image,
      programId: this.global.profile.id
    };
    for (let key in finalObject) {
      formData.append(key, finalObject[key]);
    }
    const result = await Axios.post(ep, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    });
    this.setGlobal({
      loading: false
    });
    if (result.data.success) {
      toast.success(`Observation Posted!`);
      this.setState({
        domains: [],
        skills: [],
        category: {},
        student: "",
        domain: "",
        skill: "",
        notes: "",
        image: "",
        tags: []
      });
    }
  }
  render() {
    const { classes } = this.props;

    return (
      <div className="container-fluid enrolled">
        <div className="cont">
          <h1>Observations</h1>
        </div>
        <div
          className="cont"
          style={{
            margin: "0 auto",
            paddingTop: "10px",
            borderRadius: "10px",
            overflow: "auto"
          }}
        >
          <form onSubmit={e => this.submitObservation(e)}>
            <FormControl
              variant="filled"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Choose a Student
              </InputLabel>
              <Select
                native
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                fullWidth
                disabled={this.state.students.length > 0 ? false : true}
                value={this.state.student}
                style={{ backgroundColor: "#FFF" }}
                onChange={e => {
                  this.setState({
                    student: e.target.value,
                    domains: this.getCategory(e.target.value).domainList,
                    category: this.getCategory(e.target.value)
                  });
                }}
              >
                <option value="" disabled hidden></option>

                {this.state.students.map(e => (
                  <option value={e.dsUserId} key={e.id}>
                    {e.fullName}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="filled"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Domain
              </InputLabel>
              <Select
                native
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                fullWidth
                disabled={!this.state.student}
                value={this.state.domain}
                style={{ backgroundColor: "#FFF" }}
                onChange={e =>
                  this.setState({
                    domain: e.target.value,
                    skills: this.state.category.skills[e.target.value]
                  })
                }
              >
                <option value="" disabled hidden></option>
                {this.state.domains.map((e, i) => (
                  <option value={e} key={i}>
                    {e}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="filled"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Skills
              </InputLabel>
              <Select
                native
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                fullWidth
                disabled={!this.state.domain}
                style={{ backgroundColor: "#FFF" }}
                onChange={e =>
                  this.setState({
                    skill: e.target.value
                  })
                }
              >
                <option value="" selected disabled hidden></option>
                {this.state.skills.map((e, i) => (
                  <option value={e} key={i}>
                    {e}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="filled"
              fullWidth
              className={classes.formControl}
            >
              <TextField
                rows={6}
                multiline
                variant="outlined"
                placeholder="Notes"
                disabled={!this.state.student}
                style={{
                  backgroundColor: "#FFF",
                  borderBottom: "1px solid black"
                }}
                value={this.state.notes}
                onChange={e =>
                  this.setState({
                    notes: e.target.value
                  })
                }
              ></TextField>
            </FormControl>
            {/* <FormControl
              variant="filled"
              fullWidth
              className={classes.formControl}
            >
              <Autocomplete
                multiple
                id="tags-standard"
                options={tags}
                size="medium"
                getOptionLabel={option => option.name}
                groupBy={option => option.category}
                value={this.state.tags}
                disabled={!this.state.notes}
                onChange={(_, v) =>
                  this.setState({
                    tags: v
                  })
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="default"
                      color="primary"
                      label={option.name}
                      {...getTagProps({ index })}
                      key={option.name}
                    />
                  ))
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    style={{ backgroundColor: "#FFF" }}
                    variant="outlined"
                    label="Pick your Tags"
                  />
                )}
              />
                </FormControl>*/}
            <FormControl fullWidth>
              {this.state.image && (
                <p>1 attachment : {this.state.image.name}</p>
              )}
            </FormControl>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              onChange={e =>
                this.setState({
                  image: e.target.files[0]
                })
              }
            />
            <label
              htmlFor="contained-button-file"
              style={{ marginLeft: "10px", float: "right" }}
            >
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<CloudUpload />}
              >
                Upload an Image
              </Button>
            </label>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              style={{ float: "right" }}
              disabled={!this.state.notes}
            >
              POST
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default withStyles(style)(Observations);
