import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Select from "react-dropdown-select";
import "../../assets/css/componentSpecificCss/addEnrollmentsModal.css";

class InviteGiftModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      addNew: false,
      addExisting: false,
      user_connections: [],
      newLeads: [],
      selectedUser: null,
      selectedLead: null
    };
    this.required = [
      { name: "name", validation: e => (this.state.addExisting ? false : !e) },
      { name: "email", validation: e => (this.state.addExisting ? false : !e) },
      {
        name: "selectedUser",
        validation: e => (this.state.addExisting ? !e : false)
      }
    ];
  }

  async submitForm(e) {
    e.preventDefault();
    let cancel = false;
    for (let requiredField of this.required) {
      if (
        this.state.selectedLead === null &&
        requiredField.validation(this.state[requiredField.name])
      ) {
        document.getElementsByName(requiredField.name)[0].style.boxShadow =
          "none";
        document.getElementsByName(requiredField.name)[0].style.border =
          "1px solid red";
        cancel = true;
      }
    }
    if (cancel) return;
    const endp = `${process.env.REACT_APP_API}/partners/gifts/invite`;
    const result = await axios.post(endp, {
      name:
        this.state.selectedLead !== null
          ? this.state.newLeads.filter(f => f.id == this.state.selectedLead)[0][
              "Full Name"
            ]
          : this.state.name,
      email:
        this.state.selectedLead !== null
          ? this.state.newLeads.filter(f => f.id == this.state.selectedLead)[0][
              "Email"
            ]
          : this.state.email,
      selectedUser: this.state.selectedUser
    });
    if (result.data.success) {
      toast.success("User Invite Successful!");
      this.props.onClose();
    } else {
      toast.error(`User already exists!`);
    }
  }
  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/connections`;
    const results = await axios.get(ep);

    const ep2 = `${process.env.REACT_APP_API}/partners/leads/all/${this.global.organizationInfo.partnerId}`;
    const results2 = await axios.get(ep2);

    this.setState({
      user_connections: results.data.data.connections,
      newLeads: results2.data.data
    });
  }

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>Invite Contact to Buy Gift Card</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            {!this.state.addNew && !this.state.addExisting && (
              <div className="modal-footer" style={{ display: "block" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button
                    className="btn profile-btn"
                    onClick={_ => this.setState({ addNew: true })}
                  >
                    Invite New Client
                  </button>
                  <button
                    className="btn profile-btn"
                    onClick={_ => this.setState({ addExisting: true })}
                  >
                    Invite Existing Client
                  </button>
                </div>
              </div>
            )}
            {this.state.addNew && (
              <form onSubmit={e => this.submitForm(e)}>
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "25%", textAlign: "left" }}
                  >
                    Contacts
                  </label>
                  <div style={{ width: "70%" }}>
                    <Select
                      options={this.state.newLeads}
                      // values={this.state.selectedLead}
                      name={"selectedLead"}
                      placeholder={"Select a Connection"}
                      style={{ background: "white" }}
                      onChange={e => {
                        this.setState({
                          selectedLead: e.length == 0 ? null : e[0].id
                        });
                      }}
                      labelField={"Full Name"}
                      valueField={"id"}
                      closeOnSelect={true}
                      searchable={true}
                      keepSelectedInList={true}
                      dropdownHandle={true}
                      clearable={true}
                      searchBy={"Full Name"}
                    />
                  </div>
                </div>

                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "25%", textAlign: "left" }}
                  >
                    Name *
                  </label>
                  <input
                    name="name"
                    id="displayName"
                    type="text"
                    className="form-control"
                    placeholder="Client's name..."
                    style={{ width: "70%" }}
                    disabled={this.state.selectedLead ? true : false}
                    value={
                      this.state.selectedLead !== null
                        ? this.state.newLeads.filter(
                            f => f.id == this.state.selectedLead
                          )[0]["Full Name"]
                        : this.state.name
                    }
                    onChange={e => {
                      this.setState({
                        [e.target.name]: e.target.value
                      });
                    }}
                  />
                </div>
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "25%", textAlign: "left" }}
                  >
                    Email *
                  </label>
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Client's email..."
                    disabled={this.state.selectedLead ? true : false}
                    value={
                      this.state.selectedLead !== null
                        ? this.state.newLeads.filter(
                            f => f.id == this.state.selectedLead
                          )[0]["Email"]
                        : this.state.email
                    }
                    style={{ width: "70%" }}
                    onChange={e => {
                      this.setState({
                        [e.target.name]: e.target.value.toLowerCase()
                      });
                    }}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    onClick={this.cancelThis}
                    className="btn profile-btn"
                  >
                    Invite
                  </button>
                </div>
              </form>
            )}
            {this.state.addExisting && (
              <form onSubmit={e => this.submitForm(e)}>
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "25%", textAlign: "left" }}
                  >
                    Select Client *
                  </label>
                  <select
                    id="user_select"
                    style={{ width: "70%" }}
                    name="selectedUser"
                    onChange={e => {
                      this.setState({
                        [e.target.name]: e.target.value.trim()
                      });
                    }}
                    className="form-control"
                  >
                    <option value={null} disabled hidden selected>
                      Please Select
                    </option>
                    {this.state.user_connections.map(u => (
                      <option value={u.parentId} key={u.parentId}>
                        {u.displayName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    onClick={this.cancelThis}
                    className="btn profile-btn"
                  >
                    Invite
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(InviteGiftModal);
