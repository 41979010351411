/**
 *  Get the last 10 numeric digits of a phone number,
 *  for easier comparison between different formats.
 *
 * @returns {String} Last 10 digits of the phone number.
 *
 * @param {String} phone
 */
const getLastDigits = phone => {
  // /^\($/.match("+143243");
  let numbers = phone.replace(/[^0-9+]/g, "");
  if (numbers.startsWith("+1"))
    numbers = numbers.substring(2); // North America is our current business focus, so strip off any "+1" prefix.
  if (numbers.length < 10) {
    return numbers;
  } else {
    return numbers.substring(numbers.length - 10);
  }
};

/**
 * Get the non-partner number from a twilio message object.
 * Picks the appropriate inbound/outbound number to signify the other party.
 *
 * @returns {String} A phone number.
 */
const getOtherPhone = messageObj => {
  return messageObj.direction === "inbound" ? messageObj.from : messageObj.to;
};

/**
 * Finds dsUser data for a messageObj.
 * Uses either the phone number/email to search for a specific user.
 *
 * @returns {Object} dsUser data for one specific user.
 *
 * @param {Object} messageObj A single message object.
 * @param {Array} userData An array of dsUser Data.
 */
const findUserData = (messageObj, userData) => {
  const found = userData.find(user => {
    if (messageObj.is_email) {
      return user.email === messageObj.to[0].email;
    }
    if (messageObj.id) {
      return (
        getLastDigits(user.phone || "") === getLastDigits(messageObj.to[0].phone)
      );
    }
    return (
      getLastDigits(user.phone || "") === getLastDigits(getOtherPhone(messageObj))
    );
  });
  return found;
};


/**
 * Takes a phone number of a North-American format ([+1] optional followed by 10-digits),
 * and formats it to look pretty as (xxx) xxx-xxxx.
 * @param {String} phoneNum 
 * @returns {String}
 */
const formatPhoneNumber = (phoneNum) => {
  return phoneNum && phoneNum.replace(/^(\+1|1)?(\d\d\d)(\d\d\d)(\d\d\d\d)$/,"($2) $3-$4");
};

export { getLastDigits, findUserData, getOtherPhone, formatPhoneNumber };
