import React from "reactn";
import { TabbedTable } from "../../components/UI";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import PlanModal from "../../components/table/admins/planModal";
import axios from "axios";
import ComModal from "../../components/table/admins/CommentsModal";
import { format } from "date-fns";
class PlanRequests extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      filter: "",
      detailsModalShown: false,
      modalData: [],
      original: {}
    };
    this.tabs = [
      "New",
      "Warm Matches",
      "Warm Matches - Actioned",
      "Cold Matches"
    ];
    this.headings = {
      New: [
        { id: "parentname", label: "Parent" },
        {
          id: "programName",
          label: "Program",
          customCell: this.childCountCell
        },
        {
          id: "email",
          label: "Email"
        },
        {
          id: "phone",
          label: "Phone"
        },

        // { id: "programName", label: "Program Name" },
        {
          id: "signInCount",
          label: "Sign in Count",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`}>
                {r.signInCount ? r.signInCount : "Unavailable"}
              </td>
            );
          }
        },
        {
          id: "currentIP",
          label: "Location / IP",
          customCell: this.location
        },
        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        { id: "action", label: "ACTION" }
      ],
      "Warm Matches": [
        { id: "parentname", label: "Parent" },
        {
          id: "programName",
          label: "Program",
          customCell: this.childCountCell
        },
        {
          id: "email",
          label: "Email"
        },
        {
          id: "phone",
          label: "Phone"
        },
        // { id: "programName", label: "Program Name" },
        {
          id: "signInCount",
          label: "Sign in Count",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`}>
                {r.signInCount ? r.signInCount : "Unavailable"}
              </td>
            );
          }
        },
        {
          id: "currentIP",
          label: "Location / IP",
          customCell: this.location
        },
        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        {
          id: "actionable",
          label: "ACTIONS",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewDetail(r)}
                >
                  View Details
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.actionParent(r, 3)}
                >
                  Action Match
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.actionParent(r, 1)}
                >
                  Cold Match
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewComments(r)}
                >
                  Notes
                </button>
              </td>
            );
          }
        }
      ],
      "Warm Matches - Actioned": [
        { id: "parentname", label: "Parent" },
        {
          id: "programName",
          label: "Program",
          customCell: this.childCountCell
        },
        {
          id: "email",
          label: "Email"
        },
        {
          id: "phone",
          label: "Phone"
        },
        // { id: "programName", label: "Program Name" },
        {
          id: "signInCount",
          label: "Sign in Count",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`}>
                {r.signInCount ? r.signInCount : "Unavailable"}
              </td>
            );
          }
        },
        {
          id: "currentIP",
          label: "Location / IP",
          customCell: this.location
        },
        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        {
          id: "actionable",
          label: "ACTIONS",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewDetail(r)}
                >
                  View Details
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewComments(r)}
                >
                  Notes
                </button>
              </td>
            );
          }
        }
      ],
      "Cold Matches": [
        { id: "parentname", label: "Parent" },
        {
          id: "programName",
          label: "Program",
          customCell: this.childCountCell
        },
        {
          id: "email",
          label: "Email"
        },
        {
          id: "phone",
          label: "Phone"
        },
        // { id: "programName", label: "Program Name" },
        {
          id: "signInCount",
          label: "Sign in Count",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`}>
                {r.signInCount ? r.signInCount : "Unavailable"}
              </td>
            );
          }
        },
        {
          id: "currentIP",
          label: "Location / IP",
          customCell: this.location
        },
        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        {
          id: "actionable",
          label: "ACTIONS",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewDetail(r)}
                >
                  View Details
                </button>

                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewComments(r)}
                >
                  Notes
                </button>
              </td>
            );
          }
        }
      ]
    };
    this.actions = [
      { name: "View Details", action: this.viewDetail },
      { name: "Notes", action: this.viewComments },
      {
        name: "Warm Match",
        action: r => this.actionParent(r, 2)
      },
      {
        name: "Cold Match",
        action: r => this.actionParent(r, 1)
      }
    ];
    this.filterData = this.filterData.bind(this);
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/eduplans`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setState({
        data: {
          New: res.data.data.filter(parent =>
            !parent.actionedPlan ? true : false
          ),
          "Warm Matches": res.data.data.filter(
            parent => parent.actionedPlan === 2
          ),
          "Warm Matches - Actioned": res.data.data.filter(
            parent => parent.actionedPlan === 3
          ),
          "Cold Matches": res.data.data.filter(
            parent => parent.actionedPlan === 1
          )
        },
        original: res.data.data
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt((Date.now() - new Date(r.insys).getTime()) / (3600000 * 24))}
      </td>
    );
  };
  viewDetail = async row => {
    this.setState({ detailsModalShown: true, modalData: row });
  };
  viewComments = async row => {
    this.setState({ commentsModalShown: true, modalData: row });
  };
  actionParent = async (row, action) => {
    const ep = `${process.env.REACT_APP_API}/adm/parent/notes/action`;
    const result = await axios.post(ep, {
      actionedPlan: action,
      id: row.surveyid,
      dsUserId: row.id
    });
    if (result.data.success) {
      const arr = [...this.state.original];
      const indextoChange = arr.indexOf(arr.find(e => e.id === row.id));
      const obj = arr[indextoChange];
      arr[indextoChange] = {
        ...obj,
        actionedPlan: action,
        surveyid: result.data.data.id
      };
      this.setState({
        data: {
          New: arr.filter(parent => (parent.actionedPlan ? false : true)),
          "Warm Matches": arr.filter(parent => parent.actionedPlan === 2),
          "Warm Matches - Actioned": arr.filter(
            parent => parent.actionedPlan === 3
          ),
          "Cold Matches": arr.filter(parent => parent.actionedPlan === 1)
        },
        original: [...arr]
      });
    }
  };

  acceptedCell = (r, i) => {
    return (
      <td key={`${i}-${r.id}-acc`}>
        {r.canceled
          ? `Canceled by : ${r.canceledBy}`
          : r.accepted
          ? "Tour Accepted"
          : Date.now() > new Date(r.requestedTourDate).getTime()
          ? "Tour Expired"
          : !r.rescheduled
          ? `Pending Acceptation from ${r.educatorname}`
          : parseInt(r.rescheduledBy) === r.parentid
          ? `Pending Acceptation from ${r.educatorname}`
          : `Pending Acceptation from ${r.displayName}`}
      </td>
    );
  };

  filterData(val) {
    this.setState({
      data: {
        New: this.state.original.filter(
          parent =>
            parent.actionedPlan === 0 &&
            parent.parentname
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase())
        ),
        "Warm Matches": this.state.original.filter(
          parent =>
            parent.actionedPlan === 2 &&
            parent.parentname
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase())
        ),
        "Warm Matches - Actioned": this.state.original.filter(
          parent =>
            parent.actionedPlan === 3 &&
            parent.parentname
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase())
        ),
        "Cold Matches": this.state.original.filter(
          parent =>
            parent.actionedPlan === 1 &&
            parent.parentname
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase())
        )
      }
    });
  }

  render() {
    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          <div className="cont">
            <h1>Plan Requests</h1>
          </div>
          <PlanModal
            open={this.state.detailsModalShown}
            data={this.state.modalData}
            onClose={() =>
              this.setState({
                detailsModalShown: false,
                modalData: null
              })
            }
          />
          <ComModal
            open={this.state.commentsModalShown}
            data={this.state.modalData}
            onClose={() =>
              this.setState({
                commentsModalShown: false,
                modalData: null
              })
            }
          />
          <div className="cont">
            <div className="tablecont">
              <div className="table-responsive">
                <input
                  type="text"
                  onChange={e => this.filterData(e.target.value)}
                  placeholder="Search by name..."
                  style={{
                    marginBottom: 15,
                    width: "100%",
                    borderRadius: 5,
                    border: "2px solid #E3E6F0",
                    fontSize: "13px",
                    padding: 3,
                    outline: 0
                  }}
                />
                {
                  <TabbedTable
                    tabs={this.tabs}
                    headings={this.headings}
                    data={this.state.data}
                    actions={this.actions}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlanRequests;
