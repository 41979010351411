import React from "reactn";
import axios from "axios";
import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import DatePicker from "react-datepicker";
import { withStyles } from "@material-ui/core";
class Signup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: "",
        displayName: "",
        password: "",
        phone: "",
        location: ""
      },
      days: {},
      requestedDate: "",
      dropOff: "",
      pickUp: "",
      childcareneed: "",
      successfulRegister: null
    };
  }
  setDay(day) {
    const days = { ...this.state.days };
    if (days[day]) delete days[day];
    else days[day] = true;
    this.setState({
      days
    });
  }

  clickRadio = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSubmit = async e => {
    e.preventDefault();
    try {
      this.setGlobal({ loading: true });
      const ep = `${process.env.REACT_APP_API}/auth/register`;
      const body = { ...this.state, ...this.state.user };
      delete body.user;
      const res = await axios.post(ep, body);
      if (res.data.success) {
        // localStorage.setItem("ds_token", res.data.data.token);
        this.setGlobal({ loading: false, lastAPICall: res });
        this.setState({ successfulRegister: true });
      } else {
        this.setGlobal({ loading: false, lastAPICall: res });
        this.setState({ successfulRegister: false });
      }
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
      this.setState({ successfulRegister: false });
    }
  };
  componentDidMount() {
    document.getElementsByClassName("react-datepicker-wrapper")[0].style.width =
      "75%";
  }

  handleChange = e => {
    this.setState({
      user: {
        ...this.state.user,
        [e.target.name]: e.target.value
      }
    });
  };

  render() {
    const vals = this.state;
    return (
      <div className="login-signup-form mb-5">
        <div className="form-content custom-form-content">
          <h2>Family Sign Up</h2>
          {!vals.successfulRegister ? (
            <form
              id="new_parent"
              className="new_user"
              method="post"
              noValidate="novalidate"
              onSubmit={this.handleSubmit}
              style={{ marginTop: "40px" }}
            >
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "20%", textAlign: "left" }}
                >
                  Name{" "}
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="displayName"
                  style={{ width: "75%" }}
                  value={vals.user.displayName}
                  onChange={this.handleChange}
                  id="displayName"
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <label
                  htmlFor="email"
                  style={{ width: "20%", textAlign: "left" }}
                >
                  Email
                </label>
                <input
                  className="form-control"
                  type="email"
                  style={{ width: "75%" }}
                  value={vals.user.email}
                  name="email"
                  onChange={this.handleChange}
                  id="email"
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <label
                  htmlFor="search"
                  style={{ width: "20%", textAlign: "left" }}
                >
                  Program location
                </label>
                <ProgramSearchInput
                  placeholder="Find a Program"
                  noicon={true}
                  name="search"
                  style={{ marginTop: "0px" }}
                  gLoc={e =>
                    this.setState({
                      user: { ...this.state.user, location: e }
                    })
                  }
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <label
                  htmlFor="phone"
                  style={{ width: "20%", textAlign: "left" }}
                >
                  Telephone
                </label>
                <input
                  className="form-control"
                  type="tel"
                  value={vals.user.phone}
                  style={{ width: "75%" }}
                  name="phone"
                  onChange={this.handleChange}
                  id="phone"
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <label
                  htmlFor="password"
                  style={{ width: "20%", textAlign: "left" }}
                >
                  Password
                </label>
                <input
                  autoComplete="off"
                  className="form-control"
                  type="password"
                  name="password"
                  style={{ width: "75%" }}
                  value={vals.user.password}
                  onChange={this.handleChange}
                  id="password"
                  minLength="6"
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <label
                  htmlFor="agedesired"
                  style={{ width: "20%", textAlign: "left" }}
                >
                  Child's Date of birth
                </label>
                <DatePicker
                  selected={this.state.requestedDate}
                  onChange={date => this.setState({ requestedDate: date })}
                  maxDate={new Date()}
                  style={{ width: "75%" }}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="MMMM d, yyyy"
                  className="w-100 form-control"
                  placeholderText="Date of birth"
                  name="requestedDate"
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <label
                  className="input-label"
                  style={{ width: "20%", textAlign: "left" }}
                >
                  Days of care
                </label>
                <div
                  name="days"
                  className="row days"
                  validation={"validDays"}
                  style={{ width: "75%", margin: 0 }}
                >
                  <div
                    data-day="Monday"
                    className={`day-monday day ${
                      this.state.days["Monday"] ? "selecta" : ""
                    }`}
                    onClick={e =>
                      this.setDay(e.target.getAttribute("data-day"))
                    }
                  >
                    M
                  </div>
                  <div
                    data-day="Tuesday"
                    className={`day-tuesday day ${
                      this.state.days["Tuesday"] ? "selecta" : ""
                    }`}
                    onClick={e =>
                      this.setDay(e.target.getAttribute("data-day"))
                    }
                  >
                    Tu
                  </div>
                  <div
                    data-day="Wednesday"
                    className={`day-wednesday day ${
                      this.state.days["Wednesday"] ? "selecta" : ""
                    }`}
                    onClick={e =>
                      this.setDay(e.target.getAttribute("data-day"))
                    }
                  >
                    W
                  </div>
                  <div
                    data-day="Thursday"
                    className={`day-thursday day ${
                      this.state.days["Thursday"] ? "selecta" : ""
                    }`}
                    onClick={e =>
                      this.setDay(e.target.getAttribute("data-day"))
                    }
                  >
                    Th
                  </div>
                  <div
                    data-day="Friday"
                    className={`day-friday day ${
                      this.state.days["Friday"] ? "selecta" : ""
                    }`}
                    onClick={e =>
                      this.setDay(e.target.getAttribute("data-day"))
                    }
                  >
                    F
                  </div>
                  <div
                    data-day="Saturday"
                    className={`day-saturday day ${
                      this.state.days["Saturday"] ? "selecta" : ""
                    }`}
                    onClick={e =>
                      this.setDay(e.target.getAttribute("data-day"))
                    }
                  >
                    Sa
                  </div>
                  <div
                    data-day="Sunday"
                    className={`day-sunday day ${
                      this.state.days["Sunday"] ? "selecta" : ""
                    }`}
                    onClick={e =>
                      this.setDay(e.target.getAttribute("data-day"))
                    }
                  >
                    Su
                  </div>
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <label
                  className="input-label"
                  style={{ width: "27%", textAlign: "left" }}
                >
                  Drop Off
                </label>
                <select
                  className="form-control"
                  name="dropOff"
                  onChange={e =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                  style={{ width: "30%" }}
                  title="Drop Off Time"
                  validation={"isNotEmpty"}
                >
                  <option value="" defaultValue>
                    Select time
                  </option>
                  <option value="6:00 am">6:00 am</option>
                  <option value="7:00 am">7:00 am</option>
                  <option value="8:00 am">8:00 am</option>
                  <option value="9:00 am">9:00 am</option>
                  <option value="10:00 am">10:00 am</option>
                  <option value="11:00 am">11:00 am</option>
                  <option value="12:00 pm">12:00 pm</option>
                  <option value="1:00 pm">1:00 pm</option>
                  <option value="2:00 pm">2:00 pm</option>
                  <option value="3:00 pm">3:00 pm</option>
                  <option value="4:00 pm">4:00 pm</option>
                  <option value="5:00 pm">5:00 pm</option>
                  <option value="6:00 pm">6:00 pm</option>
                  <option value="7:00 pm">7:00 pm</option>
                </select>
                <label
                  className="input-label"
                  style={{ width: "20%", textAlign: "center" }}
                >
                  Pick up
                </label>
                <select
                  className="form-control"
                  name="pickUp"
                  onChange={e =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                  style={{ width: "30%" }}
                  validation={"isNotEmpty"}
                  title="Pick Up Time"
                >
                  <option value="" defaultValue>
                    Select time
                  </option>
                  <option value="6:00 am">6:00 am</option>
                  <option value="7:00 am">7:00 am</option>
                  <option value="8:00 am">8:00 am</option>
                  <option value="9:00 am">9:00 am</option>
                  <option value="10:00 am">10:00 am</option>
                  <option value="11:00 am">11:00 am</option>
                  <option value="12:00 pm">12:00 pm</option>
                  <option value="1:00 pm">1:00 pm</option>
                  <option value="2:00 pm">2:00 pm</option>
                  <option value="3:00 pm">3:00 pm</option>
                  <option value="4:00 pm">4:00 pm</option>
                  <option value="5:00 pm">5:00 pm</option>
                  <option value="6:00 pm">6:00 pm</option>
                  <option value="7:00 pm">7:00 pm</option>
                </select>
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <label className="input-label">Ideal Start Date</label>
                <div>
                  <input
                    type="radio"
                    name="childcareneed"
                    value="Immediately"
                    onClick={e => this.clickRadio(e)}
                  />{" "}
                  Immediately
                </div>
                <div>
                  <input
                    type="radio"
                    name="childcareneed"
                    value="1-3 Months"
                    onClick={e => this.clickRadio(e)}
                  />{" "}
                  1-3 Months
                </div>
                <div>
                  <input
                    type="radio"
                    name="childcareneed"
                    value="3+ Months"
                    onClick={e => this.clickRadio(e)}
                  />{" "}
                  3+ Months
                </div>
              </div>
              {vals.successfulRegister === false ? (
                <p style={{ color: "red", textAlign: "center" }}>
                  Failed to register, please ensure all fields are correct and
                  you are not already registered with the entered email.
                </p>
              ) : null}
              <div className="form-actions">
                <input
                  type="submit"
                  name="commit"
                  value="Sign up"
                  className="btn btn-custom"
                  data-disable-with="Sign up"
                />
              </div>
            </form>
          ) : (
            <p>
              Thank you for registering, you may now sign in with the specified
              email.
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default Signup;
