import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";
import "../../../../assets/css/componentSpecificCss/eventListings.css";
import AddBirthdayPartyModal from "../../../../components/modals/AddBirthdayPartyModal";
import EditBirthdayPartyModal from "../../../../components/modals/EditBirthdayPartyModal";
import AddFieldTripModal from "../../../../components/modals/AddFieldTripModal";
import EditFieldTripModal from "../../../../components/modals/EditFieldTripModal";
import AddPrivateEventModal from "../../../../components/modals/AddPrivateEventModal";
import EditPrivateEventModal from "../../../../components/modals/EditPrivateEventModal";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import Dropdown from "rc-dropdown";
import axios from "axios";
import { toast } from "react-toastify";

class SectionTwo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openAddBirthdayPartyModal: false,
      openEditBirthdayPartyModal: false,
      openAddFieldTripModal: false,
      openEditFieldTripModal: false,
      openAddPrivateEventModal: false,
      openEditPrivateEventModal: false,
      viewOnlyMode: false,
      isTaxEnabled: false,
      taxInfo: [],
      ticketType: "",
      selectedItem: null,
      customers: []
    };
  }
  handleChange = (e, v) => {
    this.setGlobal({
      party: {
        ...this.global.party,
        [e]: v
      }
    });
  };

  async componentDidMount() {
    console.log("HERE", this.props)
    this.setGlobal({
      loading: true
    });
    const param = this.props.match.params.id;
    const customers_ep = `${process.env.REACT_APP_API}/partners/parties/customers/${this.global.party.id}`;
    const customers = await axios.get(customers_ep);
    if (customers.data.success) {
      this.setState({
        customers: customers.data.data
      });
      this.setGlobal({
        loading: false
      });
    }
  }

  deletePlan = async (plan, key) => {
    if (plan.ticket_status !== "Free") {
      const active = plan.active === false;

      const ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: plan.ticket_stripe,
        active: active
      });

      this.setGlobal({ loading: false });

      if (res.data.success) {
        this.setGlobal({
          party: {
            ...this.global.party,
            tickets: this.global.party.tickets.map((e, i) =>
              i === key ? { ...plan, archived: true, active: active } : e
            )
          }
        }, () => console.log("PARTY", this.global.party));
      }
      await this.dispatch.saveParty();
      return;
    }

    this.setGlobal({
      party: {
        ...this.global.party,
        tickets: this.global.party.tickets.map((e, i) =>
          i === key ? { ...plan, archived: true } : e
        )
      }
    });
    await this.dispatch.saveParty();
  };

  onSelect = async (element, planKey, party) => {
    if (party.key === "inactive") {
      const active = element.active === false;

      const ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: element.ticket_stripe,
        active: active
      });

      this.setGlobal({ loading: false });
      if (res.data.success) {
        this.setGlobal({
          party: {
            ...this.global.party,
            tickets: this.global.party.tickets.map((e, i) => {
              const ele = typeof e === "string" ? JSON.parse(e) : e;

              return ele.id == planKey ? { ...element, active: active } : e;
            })
          }
        });
      }
      await this.dispatch.saveParty();
    } else if (party.key === "edit") {
      if (element.ticket_status === "Field Trip") {
        console.log("ENTERD IF");
        this.setState({
          openEditFieldTripModal: true
        });
      } else if (element.ticket_status === "Private Event") {
        console.log("Entered private event");
        this.setState({
          openEditPrivateEventModal: true,
        });
      } else {
        this.setState({
          openEditBirthdayPartyModal: true
        });
      }

      this.setState({
        viewOnlyMode: false,
        selectedItem: element
      });
    } else if (party.key === "view") {
      if (element.ticket_status === "Field Trip") {
        console.log("ENTERD IF");
        this.setState({
          openEditFieldTripModal: true
        });
      } else if (element.ticket_status === "Private Event") {
        console.log("Entered private event");
        this.setState({
          openEditPrivateEventModal: true
        });
      } else {
        this.setState({
          openEditBirthdayPartyModal: true
        });
      }
      this.setState({
        viewOnlyMode: true,
        selectedItem: element
      });
    } else if (party.key === "delete") {
      this.deletePlan(element, planKey);
    }
  };

  menu = (element, planId) => {
    return (
      <Menu onClick={party => this.onSelect(element, planId, party)}>
        {element.ticket_status !== "Free" && (
          <MenuItem key="inactive">
            {element.active === false ? "Make Active" : "Make Inactive"}
          </MenuItem>
        )}
        {this.countEnrolledAppsInPlan(element) > 0 && (
          <MenuItem key="view">View</MenuItem>
        )}
        {this.countEnrolledAppsInPlan(element) === 0 && (
          <MenuItem key="edit">Edit</MenuItem>
        )}
        {this.countEnrolledAppsInPlan(element) === 0 && (
          <MenuItem key="delete">Delete</MenuItem>
        )}
      </Menu>
    );
  };

  customForm = () => {
    console.log("HERE", this.props)
    return (
      <div className="formbody">
        <form>
          <div className="row" style={{ marginLeft: "5px" }}>
            <div className="col-3">
              <h3 className="label-names">
                Add Package<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-9 d-flex">
              {!this.props.partyType && (
                <>
                  <button
                    className="newapplications button-tags"
                    type="button"
                    onClick={_ => {
                      this.setState({
                        ticketType: "Free",
                        openAddEventTicketModal: true
                      });
                    }}
                  >
                    Free
                  </button>
                  <button
                    className="newapplications button-tags"
                    type="button"
                    onClick={_ => {
                      this.setState({
                        ticketType: "Paid",
                        openAddEventTicketModal: true
                      });
                    }}
                  >
                    Paid
                  </button>
                  <button
                    className="newapplications button-tags"
                    type="button"
                    onClick={_ => {
                      this.setState({
                        ticketType: "Membership",
                        openAddEventTicketModal: true
                      });
                    }}
                  >
                    Member
                  </button>
                  <button
                    className="newapplications button-tags"
                    type="button"
                    onClick={_ => {
                      this.setState({
                        ticketType: "Donation",
                        openAddEventTicketModal: true
                      });
                    }}
                  >
                    Donation
                  </button>
                </>
              )}
              {(this.props.partyType === "Party" || this.props.birthdayParty) && (
                <button
                  className="newapplications button-tags"
                  type="button"
                  onClick={_ => {
                    this.setState({
                      ticketType: "Birthday Party",
                      openAddBirthdayPartyModal: true
                    });
                  }}
                >
                  Birthday
                </button>
              )}
              {this.props.partyType === "Field Trip" && (
                <button
                  className="newapplications button-tags"
                  type="button"
                  onClick={_ => {
                    this.setState({
                      ticketType: "Field Trip",
                      openAddFieldTripModal: true
                    });
                  }}
                >
                  Field Trip
                </button>
              )}
              {this.props.partyType === "Private Event" && (
                <button
                  className="newapplications button-tags"
                  type="button"
                  onClick={_ => {
                    this.setState({
                      ticketType: "Private Event",
                      openAddPrivateEventModal: true
                    });
                  }}
                >
                  Private Event
                </button>
              )}

            </div>
          </div>

          {this.global.party.tickets.length ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <table className="tableticket">
                <thead>
                  <tr>
                    <th
                      style={{ padding: "10px" }}
                      className="input-fields-no-border"
                    >
                      Name
                    </th>
                    <th
                      style={{ padding: "10px" }}
                      className="input-fields-no-border"
                    >
                      Price
                    </th>
                    <th
                      style={{ padding: "10px" }}
                      className="input-fields-no-border"
                    >
                      Quantity
                    </th>
                    <th
                      style={{ padding: "10px" }}
                      className="input-fields-no-border"
                    >
                      Tax % (if any)
                    </th>
                    <th
                      style={{ padding: "10px" }}
                      className="input-fields-no-border"
                    >
                      Enrolled / Apps
                    </th>
                    <th
                      style={{ padding: "10px" }}
                      className="input-fields-no-border"
                    >
                      Package Status
                    </th>
                  </tr>
                </thead>
                {this.global.party.tickets
                  .filter(ele => {
                    const e = typeof ele === "string" ? JSON.parse(ele) : ele;
                    if (e.archived) {
                      return;
                    } else return e;
                  })
                  .map((ele, key) => {
                    const e = typeof ele === "string" ? JSON.parse(ele) : ele;

                    return (
                      <tbody key={key}>
                        <tr>
                          <td style={{ padding: "10px" }}>
                            <span name="ticket_name">{e.ticket_name}</span>
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            <span name="ticket_price">${e.ticket_price}</span>
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            <span name="ticket_quantity">
                              {e.ticket_quantity}
                            </span>
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            <span name="isTaxEnabled">
                              {e.isTaxEnabled
                                ? `${e.taxInfo[0].percentage}`
                                : 0}
                              %
                            </span>
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            <span name="enrollment_num">
                              {this.countEnrolledAppsInPlan(e)}
                            </span>
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            <div
                              name="ticket_status"
                              className={`${e.active === false
                                ? "ticket-status"
                                : "ticket-status-inverse"
                                }`}
                            >
                              {e.ticket_status}
                            </div>
                          </td>
                          <td style={{ padding: "10px", cursor: "pointer" }}>
                            <div>
                              <Dropdown
                                trigger={["click"]}
                                overlay={this.menu(e, e.id)}
                                animation="slide-up"
                              >
                                <button
                                  style={{
                                    borderRadius: "50%",
                                    width: 25,
                                    height: 25,
                                    outline: "none",
                                    border: 0,
                                    background: "#f4f6fd",
                                    fontSize: "14px"
                                  }}
                                >
                                  <i className="fas fa-ellipsis-v"></i>
                                </button>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </table>
            </div>
          ) : (
            <p style={{ marginTop: "10px", textAlign: "center" }}>
              No packages!
            </p>
          )}
        </form>
      </div>
    );
  };

  countEnrolledAppsInPlan(plan) {
    let count = 0;
    // let pricingPlan = plan.ticket_stripe;
    let planId = plan.id

    if (this.state.customers.length > 0) {
      this.state.customers.forEach(purchase => {
        if (
          purchase.status_stage !== "Inactive" &&
          purchase.plan_id !== null &&
          purchase.plan_id === planId
        ) {
          count++;
        }
      });
    }

    return count;
  }

  render() {
    return (
      <>
        {this.state.openAddBirthdayPartyModal && (
          <AddBirthdayPartyModal
            onClose={_ => this.setState({ openAddBirthdayPartyModal: false })}
            ticketType={this.state.ticketType}
          />
        )}
        {this.state.openEditBirthdayPartyModal && (
          <EditBirthdayPartyModal
            onClose={() => {
              this.setState({ openEditBirthdayPartyModal: false });
            }}
            viewOnlyMode={this.state.viewOnlyMode}
            ticketType={this.state.ticketType}
            selectedItem={this.state.selectedItem}
          />
        )}
        {this.state.openAddFieldTripModal && (
          <AddFieldTripModal
            onClose={_=> this.setState({ openAddFieldTripModal: false })}
            ticketType={this.state.ticketType}
          />
        )}
        {this.state.openEditFieldTripModal && (
          <EditFieldTripModal
            onClose={() => {
              this.setState({ openEditFieldTripModal: false });
            }}
            viewOnlyMode={this.state.viewOnlyMode}
            ticketType={this.state.ticketType}
            selectedItem={this.state.selectedItem}
          />
        )}
        {this.state.openAddPrivateEventModal && (
          <AddPrivateEventModal
            onClose={_=> this.setState({ openAddPrivateEventModal: false })}
            ticketType={this.state.ticketType}
          />
        )}
        {this.state.openEditPrivateEventModal && (
          <EditPrivateEventModal
            onClose={() => {
              this.setState({ openEditPrivateEventModal: false });
            }}
            viewOnlyMode={this.state.viewOnlyMode}
            ticketType={this.state.ticketType}
            selectedItem={this.state.selectedItem}
          />
        )}
        <BoxForm
          handleChange={this.handleChange}
          name={"Create Package"}
          customForm={this.customForm()}
        />
      </>
    );
  }
}

export default SectionTwo;
