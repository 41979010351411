import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { toast } from "react-toastify";
import { Close } from "@material-ui/icons";
class PauseModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      endDate: null
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  pauseThis = async e => {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/adm/parent/notes/pause`;
    const result = await axios.post(ep, {
      enrollmentId: this.props.data.id,
      startDate: this.state.date,
      endDate: this.state.endDate
    });
    if (result.data.success) {
      toast.success(`Enrollment Paused !`);
      this.props.data.paused = true;
      this.props.data.from = this.state.date;
      this.props.data.to = this.state.endDate;
      this.props.onClose();
    }
  };

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal">
          {this.props.data && (
            <div>
              <>
                <h3>Pause an Enrollment</h3>
                <small>Pausing Enrollment for {this.props.data.fullName}</small>
                <form onSubmit={this.cancelThis}>
                  <label htmlFor="requestedDate">Start Date *</label>
                  <DatePicker
                    selected={this.state.date}
                    minDate={new Date()}
                    onChange={date => this.setState({ date })}
                    dateFormat="MMMM d, yyyy"
                    className="w-100 form-control"
                    placeholderText="Start Date"
                    name="requestedDate"
                    id="requestedDate"
                    validation="isNotEmpty"
                    autoComplete="off"
                  />
                  <label htmlFor="requestedDate">End Date *</label>
                  <DatePicker
                    selected={this.state.endDate}
                    minDate={
                      new Date(
                        new Date(this.state.date).getTime() + 3600000 * 24 * 7
                      )
                    }
                    onChange={date => this.setState({ endDate: date })}
                    dateFormat="MMMM d, yyyy"
                    className="w-100 form-control"
                    placeholderText="End date"
                    name="requestedDate"
                    id="requestedDate"
                    validation="isNotEmpty"
                    autoComplete="off"
                    disabled={!this.state.date}
                  />

                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn profile-btn"
                      disabled={!this.state.endDate}
                      onClick={e => this.pauseThis(e)}
                    >
                      Pause Enrollment
                    </button>
                    <button
                      onClick={this.props.onClose}
                      className="mx-2 backbtn btn profile-btn"
                    >
                      Close
                    </button>
                  </div>
                </form>
              </>
              <IconButton
                style={{ position: "absolute", right: "0", top: "0" }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(PauseModal);
