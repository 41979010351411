import React from "reactn";
import { TabbedTable } from "../../UI";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { format } from "date-fns";
import { toast } from "react-toastify";
import ComModal from "./EduCommentModal";
class EducatorApplicationsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      allData: {
        "IN PROGRESS": [],
        SUBMITTED: [],
        PAUSED: [],
        LIVE: []
      },
      data: {
        "IN PROGRESS": [],
        SUBMITTED: [],
        PAUSED: [],
        LIVE: []
      }
    };
    this.tabs = [
      "IN PROGRESS",
      "SUBMITTED",
      "PAUSED",
      "LIVE",
      "TESTING",
      "LOST INTEREST"
    ];
    this.testIds = [
      161, 162, 164, 179, 180, 186, 188, 189, 207, 209, 259, 204, 196, 200, 202
    ];
    this.headings = {
      "IN PROGRESS": [
        { id: "fullName", label: "Educator Name", customCell: this.nameCell },
        { id: "city", label: "City" },
        { id: "homePhone", label: "Phone Number" },
        { id: "email", label: "Email" },
        { id: "completed", label: "% Completed", customCell: this.precent },
        { id: "signInCount", label: "Sign-in Count" },
        {
          id: "lastUpdated",
          label: "Last Updated",
          customCell: this.lastUpdateCell
        },
        { id: "action", label: "" }
      ],
      SUBMITTED: [
        { id: "fullName", label: "Educator Name", customCell: this.nameCell },
        { id: "city", label: "City" },
        { id: "homePhone", label: "Phone Number" },
        { id: "email", label: "Email" },
        { id: "signInCount", label: "Sign-in Count" },
        {
          id: "outStandingDocs",
          label: "Outstanding Documents",
          customCell: r => {
            return (
              <td>
                {r.criminalCheck === 0 && " Background "}
                {r.firstAid === 0 && " FirstAID "}
                {r.childCPR === 0 && " CPR "}
                {r.healthAssessment === 0 && " Health "}
                {r.immunization === 0 && " Immuniz "}
              </td>
            );
          }
        },
        {
          id: "lastUpdated",
          label: "Last Updated",
          customCell: this.lastUpdateCell
        },
        {
          id: "dateSubmitted",
          label: "Date Submitted",
          customCell: this.submittedCell
        },
        { id: "action", label: "" }
      ],
      PAUSED: [
        { id: "fullName", label: "Educator Name", customCell: this.nameCell },
        { id: "city", label: "City" },
        { id: "province", label: "Province" },
        { id: "homePhone", label: "Phone Number" },
        { id: "email", label: "Email" },
        { id: "signInCount", label: "Sign-in Count" },
        {
          id: "actions",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewDetail(r)}
                >
                  View
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.sendNudge(r)}
                >
                  Nudge
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewComments(r)}
                >
                  Notes
                </button>
              </td>
            );
          }
        }
      ],
      LIVE: [
        { id: "fullName", label: "Educator Name", customCell: this.nameCell },
        { id: "city", label: "City" },
        { id: "province", label: "Province" },
        { id: "homePhone", label: "Phone Number" },
        { id: "email", label: "Email" },
        { id: "signInCount", label: "Sign-in Count" },
        {
          id: "actions",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewDetail(r)}
                >
                  View
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.sendNudge(r)}
                >
                  Nudge
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewComments(r)}
                >
                  Notes
                </button>
              </td>
            );
          }
        }
      ],
      TESTING: [
        { id: "fullName", label: "Educator Name", customCell: this.nameCell },
        { id: "city", label: "City" },
        { id: "province", label: "Province" },
        { id: "homePhone", label: "Phone Number" },
        { id: "email", label: "Email" },
        { id: "signInCount", label: "Sign-in Count" },
        {
          id: "actions",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewDetail(r)}
                >
                  View
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.sendNudge(r)}
                >
                  Nudge
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewComments(r)}
                >
                  Notes
                </button>
              </td>
            );
          }
        }
      ],
      "LOST INTEREST": [
        { id: "fullName", label: "Educator Name", customCell: this.nameCell },
        { id: "city", label: "City" },
        { id: "province", label: "Province" },
        { id: "homePhone", label: "Phone Number" },
        { id: "email", label: "Email" },
        { id: "signInCount", label: "Sign-in Count" },
        {
          id: "actions",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.viewDetail(r)}
                >
                  View
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.sendNudge(r)}
                >
                  Nudge
                </button>
              </td>
            );
          }
        }
      ]
    };
    this.viewDetail = this.viewDetail.bind(this);
    this.sendNudge = this.sendNudge.bind(this);
    this.filterApplications = this.filterApplications.bind(this);
    this.actions = [
      { name: "View", action: this.viewDetail },
      { name: "Nudge", action: this.sendNudge },
      { name: "Pause", action: r => this.changeAction(r, 1) },
      { name: "Lost Interest", action: r => this.changeAction(r, 3) },
      { name: "Notes", action: r => this.viewComments(r) }
    ];
  }

  viewComments = async row => {
    this.setState({ commentsModalShown: true, modalData: row });
  };

  changeAction = (row, action) => {
    const ep = `${process.env.REACT_APP_API}/adm/educator/application/action`;
    axios.post(ep, {
      id: row.id,
      action: action
    });
    const oldArray = [...this.state.allData];
    oldArray[oldArray.indexOf(oldArray.find(e => e.id === row.id))].action =
      action;
    this.setState({
      data: {
        "IN PROGRESS": oldArray.filter(d => {
          return (
            !d.applicationSubmitted && !d.programPublished && d.action === 0
          );
        }),
        SUBMITTED: oldArray.filter(d => {
          return (
            d.applicationSubmitted && !d.programPublished && d.action === 0
          );
        }),
        PAUSED: oldArray.filter(d => {
          return d.action === 1 && !d.programPublished;
        }),
        LIVE: oldArray.filter(d => {
          return d.programPublished && d.action === 0;
        }),
        "LOST INTEREST": oldArray.filter(d => {
          return !d.programPublished && d.action === 3;
        })
      },
      allData: [...oldArray]
    });
  };
  nameCell = (r, i) => {
    return <td key={`${i}-cc`}>{r.fullName || r.displayName}</td>;
  };
  precent = (r, i) => {
    return <td key={`${i}-cc`}>{r.progress || 0}%</td>;
  };

  lastUpdateCell = (r, i) => {
    return (
      <td key={`${i}-ua`}>
        {r.updatedAt
          ? format(new Date(r.updatedAt), "LLL d, yyyy h:mm a")
          : "N/A"}
      </td>
    );
  };

  submittedCell = (r, i) => {
    return (
      <td key={`${i}-sub`}>
        {r.applicationSubmittedDate
          ? format(new Date(r.applicationSubmittedDate), "LLL d, yyyy h:mm a")
          : "N/A"}
      </td>
    );
  };

  filterApplications = e => {
    const v = e.target.value.trim().toLowerCase();
    this.setState({
      data: {
        "IN PROGRESS": this.state.allData.filter(
          d =>
            !this.testIds.includes(d.id) &&
            !d.applicationSubmitted &&
            !d.programPublished &&
            d.action === 0 &&
            (d.displayName.toLowerCase().includes(v) ||
              d.email.toLowerCase().includes(v))
        ),
        SUBMITTED: this.state.allData.filter(
          d =>
            !this.testIds.includes(d.id) &&
            d.applicationSubmitted &&
            !d.programPublished &&
            (d.displayName.toLowerCase().includes(v) ||
              d.email.toLowerCase().includes(v))
        ),
        PAUSED: this.state.allData.filter(
          d =>
            !this.testIds.includes(d.id) &&
            d.action === 1 &&
            !d.programPublished &&
            (d.displayName.toLowerCase().includes(v) ||
              d.email.toLowerCase().includes(v))
        ),
        LIVE: this.state.allData.filter(
          d =>
            !this.testIds.includes(d.id) &&
            d.programPublished &&
            (d.displayName.toLowerCase().includes(v) ||
              d.email.toLowerCase().includes(v))
        ),
        TESTING: this.state.allData.filter(
          d =>
            this.testIds.includes(d.id) &&
            (d.displayName.toLowerCase().includes(v) ||
              d.email.toLowerCase().includes(v))
        ),
        "LOST INTEREST": this.state.allData.filter(d => {
          return (
            !d.programPublished &&
            d.action === 3 &&
            (d.displayName.toLowerCase().includes(v) ||
              d.email.toLowerCase().includes(v))
          );
        })
      }
    });
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/educator/application`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setState({
        data: {
          "IN PROGRESS": res.data.data.filter(d => {
            return (
              !d.applicationSubmitted &&
              !d.programPublished &&
              d.action === 0 &&
              !this.testIds.includes(d.id)
            );
          }),
          SUBMITTED: res.data.data.filter(d => {
            return (
              d.applicationSubmitted &&
              !d.programPublished &&
              d.action === 0 &&
              !this.testIds.includes(d.id)
            );
          }),
          PAUSED: res.data.data.filter(d => {
            return (
              d.action === 1 &&
              !d.programPublished &&
              !this.testIds.includes(d.id)
            );
          }),
          LIVE: res.data.data.filter(d => {
            return (
              d.programPublished &&
              d.action === 0 &&
              !this.testIds.includes(d.id)
            );
          }),
          TESTING: res.data.data.filter(d => {
            return this.testIds.includes(d.id);
          }),
          "LOST INTEREST": res.data.data.filter(d => {
            return !d.programPublished && d.action === 3;
          })
        },
        allData: res.data.data
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  viewDetail = async row => {
    this.props.history.push(
      `/admin/educator-applications/view/${row.dsUserId}`
    );
  };

  sendNudge = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/educator/nudge`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.post(ep, { email: row.email });
      if (res.data.success) {
        toast(`Nudged ${row.fullName}`);
      } else toast.error("Failed to nudge educator");
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  };

  render() {
    return (
      <div className="cont">
        <ComModal
          open={this.state.commentsModalShown}
          data={this.state.modalData}
          onClose={() =>
            this.setState({
              commentsModalShown: false,
              modalData: null
            })
          }
        />
        <input
          type="text"
          onChange={this.filterApplications}
          style={{
            marginBottom: 15,
            width: "100%",
            borderRadius: 5,
            border: "2px solid #E3E6F0",
            fontSize: "13px",
            padding: 3,
            outline: 0
          }}
          placeholder="Filter by name or email..."
        />
        <TabbedTable
          tabs={this.tabs}
          headings={this.headings}
          data={this.state.data}
          actions={this.actions}
        />
      </div>
    );
  }
}

export default withRouter(EducatorApplicationsTable);
