import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { formTypes } from "../../components/UI/form";
import ReactTooltip from "react-tooltip";
import BaseModal from "./baseModal/BaseModal";
import { addDays } from "date-fns";
import {
  STRING_INPUT,
  IMAGE_DROPDOWN,
  SWITCH,
  DROPDOWN,
  DATE_PICKER,
  SELECT_MULTIPLE_DROPDOWN
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

const INPUT_WIDTH = "58%";
class CreateCouponModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discount: "Percentage",
      redeem_by: null,
      programs: [],
      online: [],
      events: [],
      parties: [],
      programs_picked: [],
      online_picked: [],
      event_picked: [],
      duration: "forever",
      duration_in_months: null
    };
  }
  async create_coupon(e) {
    e.preventDefault();

    const code = this.state.code;
    const codeRGEX = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    const codeResult = codeRGEX.test(code);

    if (codeResult) {
      toast.error("Do not include special characters in coupon code");
      return;
    }

    const ep = `${process.env.REACT_APP_API}/partners/stripe/coupon`;
    let { programs, online, events, ...rest } = this.state;
    const cb = await axios.post(ep, rest);
    if (cb.data.success) {
      toast.success(`Coupon Created!`);
      this.props.addOne(cb.data.data);
      this.props.onClose();
    } else {
      toast.error(`Coupon code already exists.`);
    }
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/programs/list`;
    const ep2 = `${process.env.REACT_APP_API}/partners/virtual/list`;
    const ep3 = `${process.env.REACT_APP_API}/partners/events/list`;
    const results = await axios.get(ep);
    const online = await axios.get(ep2);
    const events = await axios.get(ep3);
    if (results.data.success) {
      this.setState({
        programs: results.data.data.filter(
          f => !f.isArchived && f.product_stripe
        ),
        online: online.data.data.filter(f => !f.isArchived && f.product_stripe),
        events: events.data.data.filter(f => !f.isArchived && f.product_stripe)
      });
    }
  }

  /// ================== NEW MODAL ========================== ///

  programOptions = () => {
    return [
      ...this.state.programs.map(u => ({
        value: {
          id: u.id,
          product_stripe: u.product_stripe,
          manual_invoices: u.manual_invoices
        },
        text: u.program_name
      }))
    ];
  };

  onlineOptions = () => {
    return [
      ...this.state.online.map(u => ({
        value: {
          id: u.id,
          product_stripe: u.product_stripe,
          manual_invoices: u.manual_invoices
        },
        text: u.program_name
      }))
    ];
  };

  eventOptions = () => {
    return [
      ...this.state.events.map(u => ({
        value: {
          id: u.id,
          product_stripe: u.product_stripe,
          manual_invoices: u.manual_invoices
        },
        text: u.event_title
      }))
    ];
  };

  getBaseModalFields() {
    const couponcodeInput = {
      type: STRING_INPUT,
      data: {
        name: "Coupon Code",
        required: true,
        handleChange: e => {
          this.setState({
            code: e.target.value
          });
        },
        value: this.state.code,
        styles: {
          width: INPUT_WIDTH
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      },
      validators: {
        validateSubmit: () =>
          this.state.code !== undefined && this.state.code !== ""
      }
    };

    const internalName = {
      type: STRING_INPUT,
      data: {
        name: "Internal Name",
        required: true,
        placeholder: "",
        handleChange: e => {
          this.setState({
            name: e.target.value
          });
        },
        value: this.state.name,
        styles: {
          width: INPUT_WIDTH
        },
        maxLength: "40",
        info: "Limit: 40 characters",
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      },
      validators: {
        validateSubmit: () =>
          this.state.code !== undefined && this.state.name !== ""
      }
    };

    const discountType = {
      type: DROPDOWN,
      data: {
        name: "Discount Type",
        required: true,
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            discount: e.target.value
          });
        },
        choices: [
          { value: "Percentage", text: "Percentage" },
          { value: "Amount", text: "Fixed Amount" }
        ],
        styles: {
          width: INPUT_WIDTH
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      },
      validators: {
        validateSubmit: () =>
          this.state.code !== undefined && this.state.discount !== ""
      }
    };

    const discountPercentage = {
      type: STRING_INPUT,
      data: {
        //name: "Discount Percentage (%)",
        name: `${
          this.state.discount === "Percentage"
            ? "Discount Percentage (%)"
            : "Discount Amount ($)"
        }`,
        type: "number",
        required: true,
        handleChange: e => {
          this.setState({
            percentage: e.target.value
          });
        },
        value: this.state.percentage,
        /*         titleStyle: {
          width: "25%"
        }, */
        styles: {
          width: INPUT_WIDTH
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      },

      validators: {
        validateSubmit: () =>
          this.state.code !== undefined && this.state.percentage !== ""
      }
    };

    const typeInput = {
      type: DROPDOWN,
      data: {
        name: "Type",
        required: false,
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            type: e.target.value
          });
        },
        choices: [
          { value: "Program", text: "Program" },
          { value: "Online", text: "Online" },
          { value: "Event", text: "Event" }
        ],
        styles: {
          width: INPUT_WIDTH
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const dueField = {
      type: DATE_PICKER,
      data: {
        name: "Redeem By",
        required: false,
        selected: new Date(this.state.redeem_by),
        handleChange: date => {
          this.setState({
            redeem_by: date
          });
        },
        styles: {
          width: INPUT_WIDTH
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        minDate: addDays(new Date(), 1),
        dateFormat: "MMMM d, yyyy",
        placeholder: "Pick a date",
        value: this.state.redeem_by
      }
    };

    const duration = {
      type: DROPDOWN,
      data: {
        name: "Duration",
        required: true,
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            duration: e.target.value
          });
        },
        styles: {
          width: INPUT_WIDTH
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        choices: [
          { value: "once", text: "Once" },
          { value: "repeating", text: "Limited Months" },
          { value: "forever", text: "Forever" }
        ]
      },
      validators: {
        validateSubmit: () =>
          this.state.code !== undefined && this.state.duration !== ""
      }
    };

    const programtypeInput = {
      type: SELECT_MULTIPLE_DROPDOWN,
      data: {
        name: "Program",
        required: false,
        placeholder: "Please Select",
        value: this.state.programs_picked,
        handleChange: e =>
          this.setState({
            programs_picked: e
          }),
        choices: this.programOptions(),
        titleStyle: {
          width: "210px"
        },
        styles: {
          width: "21.5vw",
          height: "auto"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const onlinetypeInput = {
      type: SELECT_MULTIPLE_DROPDOWN,
      data: {
        name: "Online",
        required: false,
        placeholder: "Please Select",
        value: this.state.online_picked,
        handleChange: e =>
          this.setState({
            online_picked: e
          }),
        choices: this.onlineOptions(),
        titleStyle: {
          width: "210px"
        },
        styles: {
          width: "21.5vw",
          height: "auto"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const eventTypeInput = {
      type: SELECT_MULTIPLE_DROPDOWN,
      data: {
        name: "Event",
        required: false,
        placeholder: "Please Select",
        value: this.state.event_picked,
        handleChange: e =>
          this.setState({
            event_picked: e
          }),
        choices: this.eventOptions(),
        titleStyle: {
          width: "210px"
        },
        styles: {
          width: "21.5vw",
          height: "auto"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const durationinMonths = {
      type: DROPDOWN,
      data: {
        name: "Duration In Months",
        info: "Values between 1 - 12",
        type: "number",
        required: true,
        choices: [{
          value: 1,
          text: "1",
        },{
          value: 2,
          text: "2",
        },{
          value: 3,
          text: "3",
        },{
          value: 4,
          text: "4",
        },{
          value: 5,
          text: "5",
        },{
          value: 6,
          text: "6",
        },{
          value: 7,
          text: "7",
        },{
          value: 8,
          text: "8",
        },{
          value: 9,
          text: "9",
        },{
          value: 10,
          text: "10",
        },{
          value: 11,
          text: "11",
        },{
          value: 12,
          text: "12",
        }],
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            duration_in_months: e.target.value
          });
        },
        iconStyles: {
          paddingLeft: "10px"
        },
        styles: {
          width: INPUT_WIDTH,
          marginLeft: "10px"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        value: this.state.duration_in_months
      },
      validators: {
        validateSubmit: () =>
          this.state.code !== undefined && this.state.duration_in_months !== null
      }
    };

    const fields = [];
    fields.push(couponcodeInput);
    fields.push(internalName);
    fields.push(discountType);
    fields.push(discountPercentage);
    fields.push(typeInput);
    if (this.state.type === "Program") {
      fields.push(programtypeInput);
    } else if (this.state.type === "Online") {
      fields.push(onlinetypeInput);
    } else if (this.state.type === "Event") {
      fields.push(eventTypeInput);
    }
    fields.push(dueField);
    fields.push(duration);
    if (this.state.duration === "repeating") {
      fields.push(durationinMonths);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.create_coupon(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Create New Coupon",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      height: "65vh",
      midSectionHeight: "52vh"
    };
  };

  /// ================== END NEW MODAL ===================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    /*     return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>Create New Coupon</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  color: "#999999",
                  fontSize: ".8rem",
                  marginBottom: "20px"
                }}
              >
                Items with <span style={{ color: "orange" }}>*</span> are
                required
              </div>
            </div>
            <form onSubmit={e => this.create_coupon(e)}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="code"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Coupon Code <span style={{ color: "orange" }}>*</span>
                </label>
                <input
                  name="code"
                  id="code"
                  type=""
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={this.state.code}
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="coupon_name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Internal Name <span style={{ color: "orange" }}>*</span>
                </label>
                <input
                  name="name"
                  id="name"
                  type="text"
                  maxLength="40"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={this.state.name}
                />
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="discount_type"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Discount Type <span style={{ color: "orange" }}>*</span>
                </label>
                <select
                  name="discount"
                  id="discount_type"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={this.state.discount}
                >
                  <option value="Percentage">Percentage</option>
                  <option value="Amount">Fixed Amount</option>
                </select>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="discount_amount"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Discount{" "}
                  {this.state.discount === "Percentage"
                    ? "Percentage (%)"
                    : "Amount ($)"}{" "}
                  <span style={{ color: "orange" }}>*</span>
                </label>
                <input
                  name="percentage"
                  id="percentage"
                  type=""
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={this.state.percentage}
                />
              </div>
              <ModalFields.custom label="Type" name="type">
                <select
                  onChange={e => this.setState({ type: e.currentTarget.value })}
                  id="type"
                  className="form-control"
                  style={{ width: "70%" }}
                >
                  <option value="" selected disabled hidden>
                    Choose here
                  </option>
                  <option value="Program">Program</option>
                  <option value="Online">Online</option>
                </select>
              </ModalFields.custom>
              {this.state.type === "Program" && (
                <ModalFields.custom label="Program" name="program">
                  <div style={{ width: "70%" }}>
                    <formTypes.UserDropDown
                      value={this.state.programs_picked}
                      options={[
                        ...this.state.programs.map(u => ({
                          name: u.program_name,
                          value: u.id,
                          product_stripe: u.product_stripe,
                          manual_invoices: u.manual_invoices
                        }))
                      ]}
                      onChange={(_, v) =>
                        this.setState({
                          programs_picked: v
                        })
                      }
                    />
                  </div>
                </ModalFields.custom>
              )}
              {this.state.type === "Online" && (
                <ModalFields.custom label="Online" name="online">
                  <div style={{ width: "70%" }}>
                    <formTypes.UserDropDown
                      value={this.state.online_picked}
                      options={[
                        ...this.state.online.map(u => ({
                          name: u.program_name,
                          value: u.id,
                          product_stripe: u.product_stripe,
                          manual_invoices: u.manual_invoices
                        }))
                      ]}
                      onChange={(_, v) =>
                        this.setState({
                          online_picked: v
                        })
                      }
                    />
                  </div>
                </ModalFields.custom>
              )}
              {(this.state.online_picked.length > 0 &&
                this.state.online_picked.filter(f => f.manual_invoices).length >
                  0) ||
              (this.state.programs_picked.length > 0 &&
                this.state.programs_picked.filter(f => f.manual_invoices)
                  .length > 0) ? (
                <div></div>
              ) : (
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="discount_amount"
                    style={{ width: "25%", textAlign: "left" }}
                  >
                    Redeem By
                  </label>
                  <input
                    name="redeem_by"
                    id="redeem_by"
                    type="date"
                    className="form-control"
                    style={{ width: "70%" }}
                    onChange={e => {
                      // const dateToUnix = new Date(e.target.value).getTime();

                      this.setState({
                        [e.target.name]: e.target.value
                      });
                    }}
                    value={this.state.redeem_by}
                  />
                </div>
              )}

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="duration"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Duration{" "}
                  <i
                    data-tip
                    data-for="durationTip"
                    className="fas fa-info-circle"
                    style={{ color: "#DCDCDC" }}
                  ></i>
                  <ReactTooltip
                    id="durationTip"
                    place="top"
                    effect="solid"
                    type="info"
                  >
                    Does not apply to Offline checkouts
                  </ReactTooltip>
                </label>
                <select
                  name="duration"
                  id="duration"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    if (
                      e.target.value === "once" ||
                      e.target.value === "forever"
                    ) {
                      this.setState({
                        duration_in_months: null
                      });
                    }

                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={this.state.duration}
                >
                  <option value="once">Once</option>
                  <option value="repeating">Limited Months</option>
                  <option value="forever">Forever</option>
                </select>
              </div>

              {this.state.duration === "repeating" && (
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="duration_in_months"
                    style={{ width: "25%", textAlign: "left" }}
                  >
                    Duration in Months{" "}
                    <i
                      data-tip
                      data-for="monthsTip"
                      className="fas fa-info-circle"
                      style={{ color: "#DCDCDC" }}
                    ></i>
                    <ReactTooltip
                      id="monthsTip"
                      place="top"
                      effect="solid"
                      type="info"
                    >
                      Values between 1 - 12
                    </ReactTooltip>
                  </label>
                  <input
                    name="duration_in_months"
                    id="duration_in_months"
                    type="number"
                    min={1}
                    max={12}
                    className="form-control"
                    style={{ width: "70%" }}
                    onChange={e => {
                      this.setState({
                        [e.target.name]: e.target.value
                      });
                    }}
                    value={this.state.duration_in_months}
                  />
                </div>
              )}

              <div className="modal-footer" style={{ marginTop: "25px" }}>
                <button
                  type="submit"
                  // onClick={_ => this.savePlan()}
                  className="btn profile-btn"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    ); */
  }
}

export default CreateCouponModal;
