import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import {
  CONTENT_BLOCK,
  STRING_INPUT,
  DATE_PICKER
} from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { format } from "date-fns";

class EditProgramStartDateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(
        new Date(this.props.programStartDate).getTime() + 24 * 60 * 60 * 1000
      )
    };
  }

  getBaseModalFields() {
    const startDateField = {
      type: DATE_PICKER,
      data: {
        name: "Start Date",
        value: this.state.startDate,
        handleChange: e => this.setState({ startDate: e }),
        minDate: new Date(),
        dateFormat: "MMMM d, yyyy",
        placeholder: "Start Date"
      }
    };

    return [startDateField];
  }

  getBaseModalButtons = () => {
    const confirmButton = {
      name: "Change Start Date",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.props.onDateChange(this.state.startDate)
    };

    return [confirmButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Edit Program Start Date",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default EditProgramStartDateModal;
