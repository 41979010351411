import "./paymentMethods";
import "./messaging";
import axios from "axios";
import { setGlobal, getGlobal } from "reactn";
axios.interceptors.request.use(
  function (config) {
    try {
      if (window.localStorage) {
        const token =
          localStorage.getItem("ds_token") ||
          localStorage.getItem("franchiser_token");

        if (token != null) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        //For /api/telemetry/log-transfer, ignore_global_lastPost=true, and that bypasses this if-block.
        //Reason: because many render functions do a "console.log(this.global)", which causes data to be
        //added to the log, which causes our telemetry module to send data back to server, which would have
        //set global.lastPost=true (or false), which would cause the render to repeat again, in an endless cycle.
        if (config.method === "post" && !config.ignore_global_lastPost) {
          const global = getGlobal();
          if (!global.lastPost) {
            setGlobal({
              lastPost: true
            });
          } else {
            throw new axios.Cancel(`Operation not completed`);
          }
        }
        return config;
      }
    } catch (e) {
      //if "window.localStorage" above is denied access for security reasons,
      //then the browser will throw a DOMException where e.name='SecurityError'.
      console.error("axios_req_interceptor: ",
        (e instanceof DOMException ? `${e.name}: ${e.message}` : e)
      );
      return config;
    }
  },
  function (err) {
    return Promise.reject(err);
  }
);


axios.interceptors.response.use(
  response => {
    //See comments above about "ignore_global_lastPost".
    if (!response.config?.ignore_global_lastPost) {
      setGlobal({ lastPost: false });
    }
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      /**
       * //Temporarily disabling this. Caused an unnecessary amount of page reloads!
       * //Sometimes reload works - the parent component (eg. AdminLayout) might redirect to a sign-in page!
       * //Other times, (eg. /preview) will cause this to get into an endless reload loop.
      window.location.reload();
      **/
      window.location.assign(`/sign-in?follow=${window.location.pathname}`);
    }
    return Promise.reject(error);   //this must do a Promise.reject() to allow other interceptors to work!
  }
);
