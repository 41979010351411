import React from "react";
import PropTypes from "prop-types";
import Line from "../charts/Line";
import { format } from "date-fns";
// import { ArrowDownSelect } from "../../assets";

const GrossTuition = ({ earnings, total }) => {
  return (
    <div className="tablebox w-100 mr-2 my-0">
      <div className="tableheader">
        <h2>GROSS TUITION</h2>
        {/* <div className="col-md-5">
            <div className="select">
              <select>
                <option value="0">By Year:</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
              <img src={ArrowDownSelect} className="arrowdownselect" alt="" />
            </div>
          </div> */}
      </div>
      <div className="tablebody padded">
        <h2 className="graph-header">
          <span>{`$${parseFloat(typeof total !== "number" ? 0 : total).toFixed(
            2
          )}`}</span>{" "}
          Cumulative
        </h2>
        <div className="chart-area">
          <Line
            mapData={
              earnings
                ? earnings.map(e => (e.total ? parseFloat(e.total) : 0))
                : []
            }
            labels={
              earnings
                ? earnings.map(e => format(new Date(e.month), "LLL"))
                : []
            }
          />
        </div>
      </div>
    </div>
  );
};

GrossTuition.propTypes = {
  tution: PropTypes.shape({
    cumulative: PropTypes.string
  })
};

GrossTuition.defaultProps = {
  tution: {
    cumulative: "$0"
  }
};

export default GrossTuition;
