import React from "reactn";
import PhysicianContact from "./forms/PhysicianContact";

class PhysicianContactsPage extends React.Component {
  handChange = e => {
    const vals = this.global.physicians.map(c => (c.id === e.id ? e : c));
    this.setGlobal({ physicians: vals });
  };

  handRemove = e => {
    this.dispatch.removePhysicianFromFamilyProfile(e);
  };

  render() {
    return (
      <div className="cont boxes-row mt-50px">
        {this.global.physicians.map((physician, i) => (
          <PhysicianContact
            key={i}
            physicianContact={physician}
            contactNo={i}
            onChange={this.handChange}
            onRemove={this.handRemove}
          />
        ))}
        <button
          className="addchild"
          onClick={() => this.dispatch.addPhysicianToFamilyProfile()}
        >
          <h3>Add Physician Contact</h3>
        </button>
      </div>
    );
  }
}

export default PhysicianContactsPage;
