import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import AddAddress from "../../../../components/modals/AddAddressModal";
import "../../../../assets/css/componentSpecificCss/eventListings.css";
import { DateTime } from "luxon";
import { zonedTimeToUtc } from "date-fns-tz";

class SectionOne extends React.PureComponent {
  constructor(props) {
    super(props);
    this.locations = [
      {
        address:
          this.global.organizationInfo.address1 +
          ", " +
          this.global.organizationInfo.city +
          ", " +
          this.global.organizationInfo.province +
          " " +
          this.global.organizationInfo.postalcode +
          ", " +
          this.global.organizationInfo.country
      },
      ...this.global.organizationInfo.other_locations.map(e => ({
        address: e.address
      }))
    ];
    this.state = {};
  }
  handleChange = (e, v) => {
    this.setGlobal({
      event: {
        ...this.global.event,
        addChild: this.props.initialAddChild,
        application_required: this.props.initialApplicationRequired,
        allow_same_enrollment: this.props.allowSameEnrollment,
        transaction_cost: this.props.initialTransactionCost,
        manual_invoices: this.props.initialManualInvoices,
        [e]: v
      }
    });
  };

  //Loading the initial event addChild and manualInvoices from the create event modal
  async componentDidMount() {
    (this.props.initialManualInvoices ||
      this.props.initialAddChild ||
      this.props.initialApplicationRequired ||
      this.props.initialTransactionCost) &&
      this.setGlobal(
        {
          event: {
            ...this.global.event,
            manual_invoices: this.props.initialManualInvoices,
            transaction_cost: this.props.initialTransactionCost,
            addChild: this.props.initialAddChild,
            application_required: this.props.initialApplicationRequired,
            allow_same_enrollment: this.props.allowSameEnrollment
          }
        }
        // async () => await this.dispatch.saveEvent()
      );
  }

  customForm = () => {
    // const dt = new Date(this.global.event.event_start).toISOString();
    const eventStartDisplay = DateTime.fromISO(this.global.event.event_start);
    const rezonedStartDisplay = eventStartDisplay.setZone(
      this.global.organizationInfo.timezone
    );

    const eventEndDisplay = DateTime.fromISO(this.global.event.event_end);
    const rezonedEndDisplay = eventEndDisplay.setZone(
      this.global.organizationInfo.timezone
    );
    console.log("global event end: ", this.global.event.event_end);
    console.log("rezonedStartDisplay: ", rezonedStartDisplay.toHTTP());
    console.log("rezonedEndDisplay:", rezonedEndDisplay.toHTTP());

    return (
      <div className="formbody">
        {this.state.openAddress && (
          <AddAddress
            onClose={_ => this.setState({ openAddress: false })}
            open={this.state.openAddress}
            addLoc={address => {
              const other_locs = [
                ...this.global.organizationInfo.other_locations
              ];
              other_locs.push(address);
              this.locations.push(address);
              this.setGlobal({
                organizationInfo: {
                  ...this.global.organizationInfo,
                  other_locations: [...other_locs]
                },
                event: {
                  ...this.global.event,
                  address1: address.address
                }
              });
              this.dispatch.saveOrganizationalProfile();
            }}
          />
        )}
        <form>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Title<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  name="event_title"
                  className="input-fields"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.event.event_title}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Starts{" "}
                {this.global.event.event_start
                  ? `(${rezonedStartDisplay.toFormat("ZZZZ")})`
                  : ""}
                <span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <formTypes.Date
                time={true}
                name="event_start"
                timezone={this.global.organizationInfo.timezone}
                minDate={new Date()}
                onChange={(n, v) => {
                  const dt = zonedTimeToUtc(v, this.global.organizationInfo.timezone).toISOString();
                  const eventStart = DateTime.fromISO(dt);
                  const rezonedStart = eventStart.setZone(
                    this.global.organizationInfo.timezone
                  );

                  // const rezonedStartToString = rezonedStart.toString();
                  // const convertedDate = new Date(rezonedStartToString);
                  // const overrideZone = DateTime.fromISO(new Date(v).toISOString(), { zone: this.global.organizationInfo.timezone });
                  // const convertedDate = new Date(overrideZone.toString()).toLocaleString("en-US", { timeZone: this.global.organizationInfo.timezone });
                  // const utc = DateTime.utc(overrideZone.toString());

                  // [n]: v.toISOString()
                  
                  // Change other time input field
                  if (this.global.event.event_end) {
                    const currEnd = this.global.event.event_end;
                    const minDate = new Date(v).getTime() > new Date(this.global.event.event_end).getTime() ? rezonedStart : currEnd;
                    this.setGlobal({
                      event: {
                        ...this.global.event,
                        'event_end': minDate
                      }
                    });
                  } else {
                    this.setGlobal({
                      event: {
                        ...this.global.event,
                        'event_end': rezonedStart
                      }
                    });
                  }

                  this.setGlobal({
                    event: {
                      ...this.global.event,
                      [n]: rezonedStart.toString()
                    }
                  });
                }}
                value={rezonedStartDisplay.toHTTP()}
                shouldStayOpen={true}
              ></formTypes.Date>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Ends{" "}
                {this.global.event.event_end
                  ? `(${rezonedEndDisplay.toFormat("ZZZZ")})`
                  : ""}
                <span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <formTypes.Date
                  time={true}
                  name="event_end"
                  timezone={this.global.organizationInfo.timezone}
                  minDate={
                    this.global.event.event_start
                      ? new Date(this.global.event.event_start)
                      : new Date()
                  }
                  onChange={(n, v) => {
                    const dt = zonedTimeToUtc(v, this.global.organizationInfo.timezone).toISOString();
                    const eventEnd = DateTime.fromISO(dt);
                    const rezonedEnd = eventEnd.setZone(
                      this.global.organizationInfo.timezone
                    );
                    
                    // Change other input field if necessary
                    if (this.global.event.event_start) {
                      const currStart = this.global.event.event_start;
                      const minDate = new Date(v).getTime() < new Date(this.global.event.event_start).getTime() ? rezonedEnd : currStart;
                      this.setGlobal({
                        event: {
                          ...this.global.event,
                          'event_start': minDate
                        }
                      })
                    }

                    this.setGlobal({
                      event: {
                        ...this.global.event,
                        [n]: rezonedEnd.toString()
                      }
                    });
                  }}
                  value={rezonedEndDisplay.toHTTP()}
                  shouldStayOpen={true}
                ></formTypes.Date>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3
                className="label-names"
                style={{ fontWeight: "bold", textDecoration: "underline" }}
              >
                Location Details
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Choose an Address<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <select
                  value={this.global.event.address1}
                  className="input-fields"
                  name="address1"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  style={{ width: "100%", color: "#696969" }}
                >
                  <option value="">Please select</option>
                  {this.locations.map(e => (
                    <option value={e.address}>{e.address}</option>
                  ))}
                </select>
              </div>
            </div>
            <div
              className="col-12 d-flex"
              style={{ justifyContent: "flex-end", marginTop: "10px" }}
            >
              <button
                type="button"
                className="btn addNew-btn" //btn-primary
                onClick={_ => this.setState({ openAddress: true })}
              >
                Add New Address
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Set-Up"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionOne;
