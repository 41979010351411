import React from "react";

import SectionOne from "./form/SectionOne";
import SectionTwo from "./form/SectionTwo";

const Step0 = () => {
  return (
    <div data-testid="root" className="cont boxes-row mt-50px">
      <SectionOne />
      <SectionTwo />
    </div>
  );
};

Step0.propTypes = {};

export default Step0;
