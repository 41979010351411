import React from "reactn";
import { Link } from "react-router-dom";
import axios from "axios";
import LinkIcon from "@material-ui/icons/Link";
import {
  Edit,
  Delete,
  SettingsBackupRestore as Restore
} from "@material-ui/icons";
import AddIframeModal from "../../components/modals/AddIframeModal";
import AddFormModal from "../../components/modals/AddFormModal";
import FormPreviewModal from "../../components/modals/FormPreviewModal";
import ConfirmArchiveModal from "../../components/modals/ConfirmArchiveModal";
import Switch from "@material-ui/core/Switch";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import EditFormNameModal from "../../components/modals/EditFormNameModal";
import ReplicateFormModal from "../../components/modals/ReplicateFormModal";
import FormUsageInfoModal from "../../components/modals/FormUsageInfoModal";
class Forms extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tourModal: false,
      infoModal: false,
      selectedProgram: null,
      data: [],
      forms: [],
      openPreviewModal: false,
      openConfirmationModal: false,
      openChangeNameModal: false,
      openReplicateFormModal: false,
      openFormUsageInfoModal: false,
      selectedFormId: null,
      page: "active"
    };

    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Forms", to: "/forms" }
      ]
    });
    this.forms = [
      "Registration,General",
      "Registration,Event",
      "Application,Daycare / Preschool",
      "Application, Summer Camp",
      "Contact Us"
    ];
    this.images = [
      "https://dreamschoolsimages.s3.us-east-2.amazonaws.com/uploads/gallary/image/Test/Modern+blue-01.jpg",
      "https://dreamschoolsimages.s3.us-east-2.amazonaws.com/uploads/gallary/image/Test/Modern+blue-02.jpg",
      "https://dreamschoolsimages.s3.us-east-2.amazonaws.com/uploads/gallary/image/Test/Modern+blue-03.jpg",
      "https://dreamschoolsimages.s3.us-east-2.amazonaws.com/uploads/gallary/image/Test/Modern+blue-04.jpg"
    ];
  }
  async update_status(form_id, published) {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/forms/forms`;
    try {
      await axios.put(ep, { id: form_id, published: published });
      toast.success(published ? "Form Published!" : "Form Unpublished!");
      await this.fetchInitialData();
      this.setGlobal({ loading: false });
    } catch {
      toast.error("Something went wrong.");
      this.setGlobal({ loading: false });
    }
  }

  async componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/forms/list`;
    this.setGlobal({ loading: true });
    const programs = await axios.get(ep);
    const data = programs.data.data.sort((form1, form2) => form1.id - form2.id);
    this.setState({
      data: data.filter(e => e.is_enabled),
      forms: data.filter(e => e.is_enabled && !e.isArchived)
    });
    this.setGlobal({ loading: false, lastAPICall: programs });
  };

  /**
   * This function handles the edit button functionality of a sub user
   */
  canSubUserEdit = () => {
    return !(
      !this.global.dsUser.sub_user ||
      this.global?.dsUser?.accesspoints?.Listings?.Forms?.edit
    );
  };

  archiveForm = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/forms/delete/${this.state.selectedFormId}`;
    const results = await axios.post(ep, {
      toArchive: this.state.page === "active"
    });

    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  render() {
    const activeForms = this.state.data.filter(form => !form.isArchived);

    // Component for a single form box on the page.
    const SingleForm = (form, key) => {
      const isEditable = !(
        this.canSubUserEdit() ||
        form.form_sub_name === "Children" ||
        form.form_sub_name === "General" ||
        this.state.page === "archived"
      );
      return (
        <div className="interested-program-box" key={key}>
          <div className="imghere">
            <div
              className="divforimg"
              style={{
                backgroundImage: `url(${
                  form.image_url ||
                  `https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTCHrgi_IxP_DxApW-K7qNGrvRxU0m7RRXQnimq0tFBew0g5YPB&usqp=CAU`
                })`,
                width: "100%",
                height: "210px",
                backgroundPosition: "center",
                backgroundSize: "cover",
                position: "relative"
              }}
            >
              <div className="paragraph">
                <div
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "3px"
                  }}
                >
                  <i
                    className="fas fa-info option-img"
                    data-tip
                    data-for="form_usage"
                    style={{
                      cursor: "pointer",
                      color: "white",
                      fontSize: "14px"
                    }}
                    onClick={() =>
                      this.setState({
                        openFormUsageInfoModal: true,
                        selectedFormId: form.id
                      })
                    }
                  ></i>
                  <ReactTooltip
                    id="form_usage"
                    place="right"
                    effect="solid"
                    type="info"
                  >
                    Form Usage Info
                  </ReactTooltip>
                </div>
                {isEditable && (
                  <div
                    style={{
                      position: "absolute",
                      right: "45px",
                      top: "3px"
                    }}
                  >
                    <i
                      className="far fa-edit option-img"
                      data-tip
                      data-for="replicate_form"
                      style={{ cursor: "pointer", color: "white" }}
                      onClick={() =>
                        this.setState({
                          openReplicateFormModal: true,
                          selectedFormId: form.id
                        })
                      }
                    ></i>
                    <ReactTooltip
                      id="replicate_form"
                      place="right"
                      effect="solid"
                      type="info"
                    >
                      Replicate Form
                    </ReactTooltip>
                  </div>
                )}
                {isEditable && (
                  <div
                    style={{
                      position: "absolute",
                      right: "25px",
                      top: "1px"
                    }}
                  >
                    <Edit
                      data-tip
                      data-for="edit_name"
                      style={{ cursor: "pointer", color: "white" }}
                      onClick={() =>
                        this.setState({
                          openChangeNameModal: true,
                          selectedFormId: form.id
                        })
                      }
                    />
                    <ReactTooltip
                      id="edit_name"
                      place="right"
                      effect="solid"
                      type="info"
                    >
                      Edit Form Name
                    </ReactTooltip>
                  </div>
                )}
                <p>
                  {form.form_name} <br />
                  {form.form_sub_name ? `(${form.form_sub_name})` : ``}
                </p>
              </div>

              {/* <img src={HeartIcon} className="heart" alt="" /> */}
            </div>
          </div>

          <div className="continbox">
            <div className="row">
              <div className="col-4">
                <h2>Status:</h2>
              </div>
              <div className="col-4" style={{ maxWidth: "unset", flexGrow: 1 }}>
                <p>
                  {form.isArchived
                    ? "Deleted"
                    : form.published
                    ? "Live"
                    : "Draft"}
                </p>
              </div>
              <div
                className="col-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {this.state.page === "active" ? (
                  <>
                    <Delete
                      data-tip
                      data-for="remove_form"
                      onClick={_ => {
                        this.setState({
                          openConfirmationModal: true,
                          selectedFormId: form.id
                        });
                      }}
                      style={{
                        cursor: "pointer",
                        marginTop: "-6px",
                        color: "grey"
                      }}
                      className="hovermyX"
                    />
                    <ReactTooltip
                      id="remove_form"
                      place="right"
                      effect="solid"
                      type="info"
                    >
                      Remove Form
                    </ReactTooltip>
                    {form.form_name !== "Registration" &&
                    form.form_name !== "Application" &&
                    form.published ? (
                      <>
                        <LinkIcon
                          data-tip
                          data-for="form_iframe"
                          style={{
                            marginTop: "-6px",
                            transform: "rotate(130deg)",
                            cursor: "pointer",
                            color: "grey"
                          }}
                          disabled={
                            form.form_name === "Application" ||
                            form.form_name === "Registration" ||
                            !form.published
                          }
                          onClick={_ => {
                            if (
                              form.form_name === "Application" ||
                              form.form_name === "Registration" ||
                              !form.published
                            ) {
                              return;
                            }

                            this.setState({
                              iframeSetUp: true,
                              selectedFormId: form.id
                            });
                          }}
                          className="hovermyX"
                        />
                        <ReactTooltip
                          id="remove_form"
                          place="right"
                          effect="solid"
                          type="info"
                        >
                          Remove Form
                        </ReactTooltip>
                      </>
                    ) : null}
                  </>
                ) : (
                  <Restore
                    onClick={_ =>
                      this.setState({
                        openConfirmationModal: true,
                        selectedFormId: form.id
                      })
                    }
                    style={{ cursor: "pointer", marginTop: "-6px" }}
                    className="hovermyX"
                    disabled={this.canSubUserEdit()}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="continbox">
            <div className="row">
              <div className="col-12">
                <p className="justify-evenly">
                  <button
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        selectedFormId: form.id,
                        openPreviewModal: true
                      });
                    }}
                    disabled={this.state.page === "archived"}
                  >
                    Preview
                  </button>
                  <button
                    onClick={e => {
                      e.preventDefault();
                      this.update_status(form.id, !form.published);
                    }}
                    disabled={this.state.page === "archived"}
                    className={this.state.page === "archived" ? "disabled" : ""}
                  >
                    {form.published ? "Unpublish" : "Publish"}
                  </button>
                  {isEditable && (
                    <Link
                      to={`/partners/forms/builder/${form.id}`}
                      disabled={!isEditable}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      Edit
                    </Link>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const formsToDisplay = this.state.data.filter(
      form => form.isArchived !== (this.state.page === "active")
    );

    const isDefaultForm = form => {
      const defaultForms = [
        {
          form_name: "Registration",
          form_sub_name: "General"
        },
        {
          form_name: "Appointments",
          form_sub_name: "General"
        },
        {
          form_name: "Application",
          form_sub_name: "General"
        },
        {
          form_name: "Contact-Us",
          form_sub_name: "General"
        },
        {
          form_name: "Survey",
          form_sub_name: "General"
        },
        {
          form_name: "Donation",
          form_sub_name: "General"
        },
        {
          form_name: "Application",
          form_sub_name: "Children"
        },
        {
          form_name: "Waiver",
          form_sub_name: "Children"
        }
      ];
      return (
        defaultForms.filter(
          ({ form_name, form_sub_name }) =>
            form.form_name === form_name && form.form_sub_name === form_sub_name
        ).length > 0
      );
    };

    return (
      <>
        <div className="container-fluid interested_programs">
          {this.state.iframeSetUp && (
            <AddIframeModal
              iframe={this.state.selectedFormId}
              open={this.state.iframeSetUp}
              onClose={() =>
                this.setState({
                  iframeSetUp: false,
                  selectedFormId: null
                })
              }
            />
          )}
          {this.state.addForm && (
            <AddFormModal
              open={this.state.addForm}
              forms={activeForms}
              onClose={() =>
                this.setState({
                  addForm: false
                })
              }
            />
          )}
          {this.state.openPreviewModal && (
            <FormPreviewModal
              onClose={() =>
                this.setState({
                  openPreviewModal: false
                })
              }
              selectedFormId={this.state.selectedFormId}
            />
          )}
          {this.state.openConfirmationModal && (
            <ConfirmArchiveModal
              onClose={() =>
                this.setState({
                  openConfirmationModal: false
                })
              }
              onSubmit={() => {
                this.archiveForm();
              }}
              toArchive={this.state.page === "active"}
              modalBody={
                this.state.page === "active"
                  ? "Are you sure you would like to remove and unpublish this form?"
                  : "Are you sure you would like to restore this form?"
              }
            />
          )}
          {this.state.openReplicateFormModal && (
            <ReplicateFormModal
              onClose={() => this.setState({ openReplicateFormModal: false })}
              onRefresh={() => this.fetchInitialData()}
              selectedForm={this.state.forms.find(
                form => this.state.selectedFormId === form.id
              )}
            />
          )}
          {this.state.openChangeNameModal && (
            <EditFormNameModal
              prevForm={this.state.forms.find(
                form => this.state.selectedFormId === form.id
              )}
              onSave={() => {
                this.fetchInitialData();
              }}
              onClose={() => {
                this.setState({ openChangeNameModal: false });
              }}
            />
          )}
          {this.state.openFormUsageInfoModal && (
            <FormUsageInfoModal
              onClose={() => this.setState({ openFormUsageInfoModal: false })}
              selectedForm={this.state.forms.find(
                form => this.state.selectedFormId === form.id
              )}
            />
          )}
          <div className="cont">
            <div style={{ display: "block" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "-30px"
                }}
              >
                <h1>Forms</h1>
                <select
                  name="page"
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  style={{
                    alignSelf: "center",
                    height: 40,
                    borderRadius: 5,
                    background: "none",
                    padding: "0 8px"
                  }}
                >
                  <option value="active">Active</option>
                  <option value="archived">Archived</option>
                </select>
              </div>
              <div
                style={{
                  marginLeft: "-6px",
                  marginTop: "4px",
                  marginBottom: "30px"
                }}
                className="switch-tooltip-group"
              >
                <Switch
                  checked={
                    !!this.global.organizationInfo.display_forms_listings
                  }
                  size="small"
                  onChange={_ => {
                    this.setGlobal({
                      organizationInfo: {
                        ...this.global.organizationInfo,
                        display_forms_listings:
                          !this.global.organizationInfo.display_forms_listings
                      }
                    });
                    this.dispatch.saveOrganizationalProfile();
                  }}
                  color="primary"
                  disabled={this.canSubUserEdit()}
                />
                <i
                  data-tip
                  data-for="view-forms-toggle"
                  className="fas fa-info-circle"
                  style={{ color: "#DCDCDC" }}
                />
                <ReactTooltip
                  id="view-forms-toggle"
                  place="right"
                  effect="solid"
                  type="info"
                >
                  Toggles forms tab visibility for users
                </ReactTooltip>
              </div>
            </div>
          </div>
          {this.state.page === "active" ? (
            <>
              <div className="cont">
                <div className="boxes-row ip-boxes">
                  {formsToDisplay
                    .filter(form => isDefaultForm(form))
                    .map((form, key) => SingleForm(form, key))}
                </div>
              </div>
              <div className="cont">
                <h2 style={{ fontWeight: "700" }}>Custom Forms</h2>
                <div className="boxes-row ip-boxes">
                  {formsToDisplay
                    .filter(form => !isDefaultForm(form))
                    .map((form, key) => SingleForm(form, key))}
                  <button
                    className="addchild"
                    style={{
                      minHeight: "unset",
                      minWidth: "unset",
                      maxWidth: "unset",
                      maxHeight: "unset",
                      flexGrow: 0,
                      width: "298px",
                      height: "348px",
                      margin: "0px 8px 16px 8px"
                    }}
                    onClick={_ => this.setState({ addForm: true })}
                  >
                    <h3>Add Form</h3>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="cont">
              <div className="boxes-row ip-boxes">
                {formsToDisplay.map((form, key) => SingleForm(form, key))}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Forms;
