import React from "reactn";
import axios from "axios";
import { TabbedTable } from "../../components/UI";
import { format } from "date-fns";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import ComModal from "../../components/table/admins/EduCommentModal";
class ProgramSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        "IN PROGRESS (NOT STARTED)": [],
        "IN PROGRESS (STARTED)": [],
        LIVE: []
      },
      allData: []
    };
    this.testIds = [
      161, 162, 164, 179, 180, 186, 188, 189, 207, 209, 259, 204, 196, 200, 202
    ];
    this.tabs = [
      "IN PROGRESS (NOT STARTED)",
      "IN PROGRESS (STARTED)",
      "LIVE",
      "TESTING",
      "LOST INTEREST"
    ];
    this.headings = {
      "IN PROGRESS (NOT STARTED)": [
        { id: "fullName", label: "Educator" },
        { id: "programName", label: "Program Name" },
        { id: "status", label: "Setup Status", customCell: this.statusCell },
        {
          id: "lastSignIn",
          label: "Last Sign In",
          customCell: this.signInCell
        },
        { id: "action", label: "", customStyle: { width: 80 } }
      ],
      "IN PROGRESS (STARTED)": [
        { id: "fullName", label: "Educator" },
        { id: "programName", label: "Program Name" },
        { id: "status", label: "Setup Status", customCell: this.statusCell },
        { id: "progression", label: "% Completion", customCell: this.precent },
        {
          id: "lastSignIn",
          label: "Last Sign In",
          customCell: this.signInCell
        },
        { id: "action", label: "", customStyle: { width: 80 } }
      ],
      LIVE: [
        { id: "fullName", label: "Educator" },
        { id: "programName", label: "Program Name" },
        { id: "status", label: "Setup Status", customCell: this.statusCell },
        {
          id: "lastSignIn",
          label: "Last Sign In",
          customCell: this.signInCell
        },
        { id: "action", label: "", customStyle: { width: 80 } }
      ],
      TESTING: [
        { id: "fullName", label: "Educator" },
        { id: "programName", label: "Program Name" },
        { id: "status", label: "Setup Status", customCell: this.statusCell },
        {
          id: "lastSignIn",
          label: "Last Sign In",
          customCell: this.signInCell
        },
        { id: "action", label: "", customStyle: { width: 80 } }
      ],
      "LOST INTEREST": [
        { id: "fullName", label: "Educator" },
        { id: "programName", label: "Program Name" },
        { id: "status", label: "Setup Status", customCell: this.statusCell },
        {
          id: "lastSignIn",
          label: "Last Sign In",
          customCell: this.signInCell
        },
        { id: "action", label: "", customStyle: { width: 80 } }
      ]
    };

    this.actions = [
      { name: "Application", action: this.viewApplication },
      { name: "Pricing Plans", action: this.viewPricingPlans },
      { name: "Prgm Setup", action: this.viewProgram },
      { name: "Milestones", action: this.viewMilestones },
      { name: "Notes", action: r => this.viewComments(r) }
    ];

    this.viewMilestones = this.viewMilestones.bind(this);
    this.viewPricingPlans = this.viewPricingPlans.bind(this);
    this.viewProgram = this.viewProgram.bind(this);
    this.viewApplication = this.viewApplication.bind(this);
    this.filterData = this.filterData.bind(this);
  }

  viewComments = async row => {
    this.setState({ commentsModalShown: true, modalData: row });
  };
  signInCell = (r, i) => {
    return (
      <td key={`${i}-signIn`}>
        {r.lastSignIn
          ? format(new Date(r.lastSignIn), "yyyy-MM-dd hh:mm a")
          : "N/A"}
      </td>
    );
  };
  precent = (r, i) => {
    return <td key={`${i}-cc`}>{r.progressPrg || 0}%</td>;
  };

  statusCell = (r, k) => {
    let cName = "";
    if (r.programPublished) {
      cName = "green-circle";
    } else if (r.programSubmitted) {
      cName = "orange-circle";
    } else {
      cName = "red-circle";
    }
    return (
      <td key={k}>
        <div
          className={cName}
          style={{ width: "14px", height: "14px", borderRadius: "14px" }}
        ></div>
      </td>
    );
  };

  viewPricingPlans = r => {
    // this.props.history.push(`/admin/program-settings/plans/${r.dsUserId}`);
    window.open(`/admin/program-settings/plans/${r.dsUserId}`);
  };

  viewProgram = async r => {
    this.props.history.push(`/admin/program-settings/setup/${r.dsUserId}`);
    //window.open(`/admin/program-settings/setup/${r.dsUserId}`);
  };

  viewMilestones = r => {
    // this.props.history.push(`/admin/program-settings/milestones/${r.dsUserId}`);
    window.open(`/admin/program-settings/milestones/${r.dsUserId}`);
  };

  viewApplication = r => {
    // this.props.history.push(
    //   `/admin/educator-applications/view/${r.dsUserId}`
    // );
    window.open(`/admin/educator-applications/view/${r.dsUserId}`);
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/educator/program`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setState({
        data: {
          [this.tabs[1]]: res.data.data.filter(
            prg =>
              prg.prgid !== null &&
              !prg.programPublished &&
              !this.testIds.includes(prg.id) &&
              prg.action !== 3
          ),
          [this.tabs[0]]: res.data.data.filter(
            prg =>
              prg.prgid === null &&
              !prg.programPublished &&
              !this.testIds.includes(prg.id) &&
              prg.action !== 3
          ),
          [this.tabs[2]]: res.data.data.filter(
            prg =>
              prg.programPublished &&
              !this.testIds.includes(prg.id) &&
              prg.action !== 3
          ),
          [this.tabs[3]]: res.data.data.filter(prg =>
            this.testIds.includes(prg.id)
          ),
          [this.tabs[4]]: res.data.data.filter(prg => prg.action === 3)
        },
        allData: res.data.data
      });

      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  filterData = e => {
    const v = e.target.value.trim().toLowerCase();
    this.setState({
      data: {
        [this.tabs[1]]: this.state.allData.filter(
          prg =>
            prg.prgid !== null &&
            !prg.programPublished &&
            prg.fullName.toLocaleLowerCase().includes(v) &&
            !this.testIds.includes(prg.id)
        ),
        [this.tabs[0]]: this.state.allData.filter(
          prg =>
            prg.prgid === null &&
            !prg.programPublished &&
            prg.fullName.toLocaleLowerCase().includes(v) &&
            !this.testIds.includes(prg.id)
        ),
        [this.tabs[2]]: this.state.allData.filter(
          prg =>
            prg.programPublished &&
            prg.fullName.toLocaleLowerCase().includes(v) &&
            !this.testIds.includes(prg.id)
        ),
        [this.tabs[3]]: this.state.allData.filter(
          prg =>
            this.testIds.includes(prg.id) &&
            prg.fullName.toLocaleLowerCase().includes(v)
        )
      }
    });
  };

  render() {
    return (
      <div className="admin">
        <div className="container-fluid programsettings">
          <div className="cont">
            <h1>Program Settings</h1>
          </div>
          <ComModal
            open={this.state.commentsModalShown}
            data={this.state.modalData}
            onClose={() =>
              this.setState({
                commentsModalShown: false,
                modalData: null
              })
            }
          />

          <div className="cont">
            <input
              type="text"
              onChange={this.filterData}
              style={{
                marginBottom: 15,
                width: "100%",
                borderRadius: 5,
                border: "2px solid #E3E6F0",
                fontSize: "13px",
                padding: 3,
                outline: 0
              }}
              placeholder="Filter by name or program name..."
            />
            <div className="tablecont">
              <div className="table-responsive">
                <TabbedTable
                  tabs={this.tabs}
                  headings={this.headings}
                  data={this.state.data}
                  actions={this.actions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProgramSettings;
