const validateNumberOfWeeks = val => {
  if (val === 0) return false;
  else return true;
};

const programDuration = val => {
  if (parseInt(val) === 0 || parseInt(val) === -1) return false;
  return true;
};

const isEmpty = val => {
  if (!val) return false;
  // if (val === null) return false;
  if (val.length === 0) return false;
  if (typeof val === "string") {
    return val.trim().length > 0;
  }
  if (val instanceof Object) {
    return Object.keys(val).length > 0;
  }
  if (!val) {
    return false;
  } else {
    return true;
  }
};
// a formId is "0" or null when the value is "Please Select" otherwise its something else
// eg. Waiver (children) is "15"
const isEmptyFormSelect = val => {
  if (val === "0" || val === null || val === "") return false;
  return true;
};

const isSelectedFormId = formId => {
  const unselectedIds = ["0", "", null];
  return !unselectedIds.includes(formId);
};

const isNotEnough = val => {
  if (val)
    if (typeof val === "string") if (val.trim().length < 40) return false;
  return true;
};
const isNotEnough100 = val => {
  if (!val) return false;
  if (val.length === 0) return false;
  if (val)
    if (typeof val === "string") if (val.trim().length < 100) return false;
  return true;
};
const isEmptyNonZero = val => {
  if (!val && val !== 0) return false;
  // if (val === null) return false;
  if (val.length === 0) return false;
  if (typeof val === "string") {
    return val.trim().length > 0;
  }
  if (val instanceof Object) {
    return Object.keys(val).length > 0;
  }
  if (!val && val !== 0) {
    return false;
  } else {
    return true;
  }
};

const programType = val => {
  if (val === -1) return false;
  if (val === null) return false;
  else return true;
};

const activePlansExist = val => {
  if (!val) return false;

  if (val.length === 0) return false;

  let ret = false;
  val.forEach(plan => {
    if (typeof plan === "string") {
      const { active, archived } = JSON.parse(plan);
      if ((active === undefined || active) && !archived) ret = true;
    } else {
      if ((plan.active === undefined || plan.active) && !plan.archived) ret = true;
    }
  })
  return ret;
}

const Validators = {
  isEmpty: isEmpty,
  validateNumberOfWeeks,
  isNotEnough,
  isNotEnough100,
  isEmptyNonZero: isEmptyNonZero,
  isEmptyFormSelect,
  isSelectedFormId,
  programDuration,
  programType,
  activePlansExist
};

export default Validators;
