import React, { useGlobal, useEffect } from "reactn";
// import SectionSix from './forms/SectionSix'
import SectionSeven from "./forms/SectionSeven";
import SectionEight from "./forms/SectionEight";

const ZoomDetails = () => {
  const [global, _] = useGlobal();

  useEffect(() => {}, []);
  return (
    <div className="cont boxes-row mt-50px">
      <SectionEight />
      {/* <SectionSix /> */}
      {global.online.manual_zoom && (
        <>
          <SectionSeven />
        </>
      )}
    </div>
  );
};

export default ZoomDetails;
