import React from "reactn";
import { Link } from "react-router-dom";
import { CMale, CFemale, CUnisex } from "../../../assets";
import axios from "axios";

class ChooseChild extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedChild: {}
    };
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.setGlobal({
      pathway: [
        ...this.global.pathway.slice(0, 1),
        "Interested Program",
        "File an Application"
      ]
    });
  }
  async componentDidMount() {
    const params = this.props.match.params;
    try {
      const ep = `${process.env.REACT_APP_API}/frnt/program/${params.slug}`;
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      if (res.data.success) {
        this.setGlobal({ school: res.data.data });
        this.forceUpdate();
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (er) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  prevStep() {
    this.props.history.push("/parents/interested-programs");
  }

  nextStep() {
    const slug = this.props.match.params.slug;
    this.setGlobal({
      enrApp: {
        slug: slug,
        ...this.state
      }
    });
    this.props.history.push(`/parents/interested-programs/${slug}/application`);
  }
  render() {
    return (
      <div
        className="container-fluid new_application new_application_2"
        // style={{
        //   position: "absolute",
        //   background: `url(${
        //     this.global.school && this.global.school.programCoverPhoto
        //       ? `${process.env.REACT_APP_DS_FILES_S3}/${this.global.school.programCoverPhoto}`
        //       : ""
        //   })`,
        //   backgroundPosition: "center",
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat"
        // }}
      >
        <div className="cont">{/* <h1>New Application</h1> */}</div>
        <div className="cont mt-5 pt-4">
          {this.global.children.length ? (
            <div className="contbox">
              <p className="nom">1</p>
              <div className="header">
                <h2>Choose a Child</h2>
                <p>Select which child you are applying for</p>
              </div>
              <div className="body">
                <div className="row">
                  <div className="col-6">
                    <p>My Children</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="boxes">
                      {this.global.children.map((c, i) =>
                        typeof c.id === "number" ? (
                          <div className="box" key={i}>
                            <div className="divforimg">
                              <img
                                src={
                                  c.photo
                                    ? `${process.env.REACT_APP_DS_FILES_S3}/${c.photo}`
                                    : c.gender === "Male"
                                    ? CMale
                                    : c.gender === "Female"
                                    ? CFemale
                                    : CUnisex
                                }
                                alt=""
                              />
                            </div>
                            <p
                              style={{
                                textAlign: "center"
                              }}
                            >
                              {c.fullName}
                            </p>
                            <button
                              onClick={() => {
                                this.setState({ selectedChild: c });
                              }}
                              className={
                                this.state.selectedChild.id === c.id
                                  ? "selectedButton"
                                  : "selectButton"
                              }
                            ></button>
                          </div>
                        ) : null
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="buttons mb-0">
                    <button onClick={this.prevStep} className="savebtn">
                      BACK
                    </button>
                    <button
                      disabled={!this.state.selectedChild.id}
                      onClick={this.nextStep}
                      className="savebtn"
                    >
                      NEXT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="contbox">
              <p className="nom">1</p>
              <div className="header">
                <h2 className="text-center">
                  You haven't added your children to your Family Profile!
                </h2>
                <p className="mt-5 text-center">
                  <Link
                    to="/parents/family-profile"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    Go to Your Family Profile
                  </Link>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ChooseChild;
