import React from "reactn";
import AttendanceCalendarTable from "./AttendanceCalendarTable/AttendanceCalendarTableNoChild";
import { format, addDays, subDays, startOfWeek, endOfWeek } from "date-fns";
import axios from "axios";
import { DataArrowLeft, DataArrowRight } from "../../../assets";

class Attendance extends React.PureComponent {
  constructor() {
    super();
    const today = new Date();
    this.state = {
      start: startOfWeek(today),
      end: endOfWeek(today),
      students: [],
      attendance: {},
      programId: null
    };
    this.changeDatePeriod = this.changeDatePeriod.bind(this);
    this.setChildStatus = this.setChildStatus.bind(this);
  }

  getNumericDay(day) {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    return days.indexOf(day);
  }

  async componentDidMount() {
    const type = this.props.location.search.split("?")[1];

    if (type === "Program") {
      const ep = `${process.env.REACT_APP_API}/partner/attendance/user/${
        this.props.match.params.id
      }/${format(this.state.start, "yyyy-MM-dd")}/${format(
        this.state.end,
        "yyyy-MM-dd"
      )}`;

      this.setState({
        programId: this.props.match.params.id
      });

      try {
        this.setGlobal({ loading: true });
        const res = await axios.get(ep);

        this.setState({
          students: res.data.data.students,
          attendance: res.data.data.attendance
        });
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (ex) {
        console.error(ex);
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }

    if (type === "Online") {
      const ep = `${
        process.env.REACT_APP_API
      }/partner/attendance/user/online-recurring/${
        this.props.match.params.id
      }/${format(this.state.start, "yyyy-MM-dd")}/${format(
        this.state.end,
        "yyyy-MM-dd"
      )}`;

      this.setState({
        programId: this.props.match.params.id
      });

      try {
        this.setGlobal({ loading: true });
        const res = await axios.get(ep);

        this.setState({
          students: res.data.data.students,
          attendance: res.data.data.attendance
        });
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (ex) {
        console.error(ex);
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }

    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway,
        { label: "Attendance", to: this.props.location.pathname }
      ]
    });
  }

  async changeDatePeriod(direction) {
    const type = this.props.location.search.split("?")[1];

    const start =
      direction === "next"
        ? startOfWeek(addDays(new Date(this.state.start), 7))
        : startOfWeek(subDays(new Date(this.state.start), 7));
    // this.dispatch.setWeeklySlotsPeriod(start);

    if (type === "Program") {
      const ep = `${process.env.REACT_APP_API}/partner/attendance/user/${
        this.props.match.params.id
      }/${format(start, "yyyy-MM-dd")}/${format(
        endOfWeek(start),
        "yyyy-MM-dd"
      )}`;

      try {
        this.setGlobal({ loading: true });
        const res = await axios.get(ep);
        this.setState({
          students: res.data.data.students,
          attendance: res.data.data.attendance,
          start: start,
          end: endOfWeek(start)
        });
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (ex) {
        console.error(ex);
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }

    if (type === "Online") {
      const ep = `${
        process.env.REACT_APP_API
      }/partner/attendance/user/online-recurring/${
        this.props.match.params.id
      }/${format(start, "yyyy-MM-dd")}/${format(
        endOfWeek(start),
        "yyyy-MM-dd"
      )}`;

      try {
        this.setGlobal({ loading: true });
        const res = await axios.get(ep);
        this.setState({
          students: res.data.data.students,
          attendance: res.data.data.attendance,
          start: start,
          end: endOfWeek(start)
        });
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (ex) {
        console.error(ex);
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }
  }

  async setChildStatus(child) {
    const type = this.props.location.search.split("?")[1];

    if (type === "Program") {
      const attendance = {
        ...child.status,
        dsUserId: child.child.student.dsUserId,
        enrollmentId: child.child.student.enrollmentId,
        attDate: child.child.day,
        extendedHours: parseInt(child.status.extendedHours) || 0,
        extendedMinutes: parseInt(child.status.extendedMinutes) || 0,
        programId: this.state.programId,
        partnerId: this.global.organizationInfo.partnerId
      };
      const ep = `${process.env.REACT_APP_API}/partner/attendance`;
      try {
        this.setGlobal({ loading: true });
        const res = await axios.post(ep, attendance);
        const ns = {
          attendance: {
            ...this.state.attendance,
            [child.child.student.enrollmentId]: {
              ...this.state.attendance[child.child.student.enrollmentId],
              [child.child.day]: res.data.data
            }
          }
        };
        this.setState(ns);
        this.fetchInitialData();
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (ex) {
        console.error(ex);
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }

    if (type === "Online") {
      const attendance = {
        ...child.status,
        dsUserId: child.child.student.dsUserId,
        onlineEnrollmentId: child.child.student.enrollmentId,
        attDate: child.child.day,
        extendedHours: parseInt(child.status.extendedHours) || 0,
        extendedMinutes: parseInt(child.status.extendedMinutes) || 0,
        onlineId: this.state.programId,
        partnerId: this.global.organizationInfo.partnerId
      };
      const ep = `${process.env.REACT_APP_API}/partner/attendance/online`;
      try {
        this.setGlobal({ loading: true });
        const res = await axios.post(ep, attendance);
        const ns = {
          attendance: {
            ...this.state.attendance,
            [child.child.student.enrollmentId]: {
              ...this.state.attendance[child.child.student.enrollmentId],
              [child.child.day]: res.data.data
            }
          }
        };
        this.setState(ns);
        this.fetchInitialData();
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (ex) {
        console.error(ex);
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }
    // this.dispatch.setAttendanceChildStatus(child);*/
  }

  render() {
    return (
      <div className="admin">
        <div className="container-fluid">
          <div className="cont">
            <h1>Attendance</h1>
          </div>
          <div className="cont">
            <div className="row">
              <div className="col-lg-12">
                <div className="data">
                  <p>
                    <button
                      style={{ background: "none", border: "none" }}
                      className="pl-3 pr-3 mr-2"
                      onClick={() => this.changeDatePeriod("prev")}
                    >
                      <img src={DataArrowLeft} alt="Previous" />
                    </button>
                    <span>
                      {format(this.state.start, "LLL d")} -&nbsp;
                      {format(this.state.end, "LLL d")},&nbsp;
                      {format(this.state.start, "yyyy")}
                    </span>
                    <button
                      style={{ background: "none", border: "none" }}
                      className="pl-3 pr-3 ml-2"
                      onClick={() => this.changeDatePeriod("next")}
                    >
                      <img src={DataArrowRight} alt="Next" />
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <AttendanceCalendarTable
            setChildStatus={this.setChildStatus}
            students={this.state.students}
            attendance={this.state.attendance}
            start={this.state.start}
            end={this.state.end}
          />
        </div>
      </div>
    );
  }
}

export default Attendance;
