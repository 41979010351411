import Validators from "../validators";

const ValidateChildren = children => {
  if (!children.length) return false;
  const fields = {
    fullName: [Validators.isEmpty],
    birthDate: [Validators.isEmpty],
    gender: [Validators.isEmpty],
    languages: [Validators.isEmpty],
    allergies: [Validators.isEmpty],
    immunized: [Validators.isEmpty],
    medication: [Validators.isEmpty]
  };
  for (let child of children) {
    for (let key of Object.keys(fields)) {
      for (let validator of fields[key]) {
        if (!validator(child[key])) return false;
      }
    }
  }
  return true;
};

const ValidateGuardians = vals => {
  if (!vals.length) return false;
  const fields = {
    fullName: [Validators.isEmpty],
    address: [Validators.isEmpty],
    city: [Validators.isEmpty],
    province: [Validators.isEmpty],
    country: [Validators.isEmpty],
    postalCode: [Validators.isEmpty],
    email: [Validators.isEmpty],
    homePhone: [Validators.isEmpty]
  };
  for (let val of vals) {
    for (let key of Object.keys(fields)) {
      for (let validator of fields[key]) {
        if (!validator(val[key])) return false;
      }
    }
  }
  return true;
};

const ValidateEmergencyContacts = vals => {
  if (!vals.length) return false;
  const fields = {
    children: [Validators.isEmpty],
    relationship: [Validators.isEmpty],
    fullName: [Validators.isEmpty],
    address: [Validators.isEmpty],
    phone: [Validators.isEmpty]
  };
  for (let val of vals) {
    for (let key of Object.keys(fields)) {
      for (let validator of fields[key]) {
        if (!validator(val[key])) return false;
      }
    }
  }
  return true;
};

const ValidatePhysicianContacts = vals => {
  if (!vals.length) return false;
  const fields = {
    children: [Validators.isEmpty],
    fullName: [Validators.isEmpty],
    address: [Validators.isEmpty],
    phone: [Validators.isEmpty],
    company: [Validators.isEmpty]
  };
  for (let val of vals) {
    for (let key of Object.keys(fields)) {
      for (let validator of fields[key]) {
        if (!validator(val[key])) return false;
      }
    }
  }
  return true;
};

const Profile = {
  ValidateChildren: ValidateChildren,
  ValidateGuardians: ValidateGuardians,
  ValidateEmergencyContacts: ValidateEmergencyContacts,
  ValidatePhysicianContacts: ValidatePhysicianContacts
};

export default Profile;
