const tags = [
  { name: "Emotions & Feelings", category: "Social & Emotional Development" },
  { name: "Gender Development", category: "Social & Emotional Development" },
  { name: "Peer Relations", category: "Social & Emotional Development" },
  { name: "Play-fighting", category: "Social & Emotional Development" },
  { name: "Prosocial Development", category: "Social & Emotional Development" },
  { name: "Resilience", category: "Social & Emotional Development" },
  { name: "Cognition", category: "Early Learning for Every Child" },
  {
    name: "Communication Language and Literacy",
    category: "Early Learning for Every Child"
  },
  { name: "Emotional", category: "Early Learning for Every Child" },
  { name: "Physical", category: "Early Learning for Every Child" },
  { name: "Social", category: "Early Learning for Every Child" },
  { name: "Health and Physical Activity", category: "Full day Learning" },
  { name: "Health and Well-Being", category: "Full day Learning" },
  { name: "Physical Development and Activity", category: "Full day Learning" },
  { name: "Oral Communication", category: "Language" },
  { name: "Reading", category: "Language" }
];

const infants = {
  domainList: [
    "Social",
    "Emotional",
    "Communication Language & Literacy",
    "Cognition",
    "Physical"
  ],
  skills: {
    Social: [
      "Social Interest",
      "Imitation",
      "Simple Turn Taking",
      "Maintaining Connection Across Space"
    ],
    Emotional: [
      "Expression of Emotion",
      "Self-Regulation",
      "Sense of Self",
      "Empathy",
      "Agency"
    ],
    "Communication Language & Literacy": [
      "Non-verbal Communication Skills",
      "Receptive Language Skills",
      "Expressive Language Skills"
    ],
    Cognition: [
      "Attention Regulation",
      "Problem Solving",
      "Cause-and-Effect Exploration",
      "Spatial Exploration",
      "Spatial Problem-solving",
      "Object Permanence",
      "Symbolic Thought, Representation and Root Skills of Literacy",
      "Memory",
      "Sorting"
    ],
    Physical: [
      "Gross Motor",
      "Fine Motor",
      "Senses",
      "Sensory Motor Integration"
    ]
  }
};
const toddlers = {
  domainList: [
    "Social",
    "Emotional",
    "Communication Language & Literacy",
    "Cognition",
    "Physical"
  ],
  skills: {
    Social: ["Social Interest", "Perspective Taking", "Parallel Play"],
    Emotional: [
      "Expression of Feelings",
      "Sense of Self",
      "Empathy",
      "Autonomy",
      "Identity Formation"
    ],
    "Communication Language & Literacy": [
      "Receptive Language",
      "Expressive Language"
    ],
    Cognition: [
      "Attention Regulation",
      "Self-Regulation",
      "Problem Solving",
      "Cause-and-Effect Exploration",
      "Spatial Exploration",
      "Spatial Problem-solving",
      "Temporal",
      "Symbolic Thought, Representation and Root Skills of Literacy",
      "Memory",
      "Sorting"
    ],
    Physical: ["Gross Motor", "Fine Motor", "Senses"]
  }
};
const preschool = {
  domainList: [
    "Social",
    "Emotional",
    "Communication Language & Literacy",
    "Cognition",
    "Physical"
  ],
  skills: {
    Social: [
      "Making Friends",
      "Conflict Resolution and Social Problem-Solving Skills",
      "Peer Group Entry Skills",
      "Helping skills",
      "Interacting Positively and Respectfully",
      "Co-operating",
      "Empathy",
      "Agreeableness",
      "Interacting with Adults"
    ],
    Emotional: [
      "Self-Concept",
      "Identity Formation",
      "Self-esteem",
      "Recognizing and Expressing Emotions",
      "Regulating Attention, Emotions and Behaviour",
      "Positive Attitudes towards Learning"
    ],
    "Communication Language & Literacy": [
      "Using Verbal and non-Verbal Communication",
      "Using English and the Child's mother tongue",
      "Vocabulary",
      "Conversing with Peers and Adults",
      "Using Descriptive Language to Explain, Explore and Extend",
      "Listening to Others",
      "Enjoying Literacy",
      "Using and Understanding the Power of Literacy",
      "Retelling Stories",
      "Phonological Awareness",
      "Letter Recognition",
      "Understanding of Orientation and Familiar Conventions of Print",
      "Matching Spoked Words with Written Ones",
      "Beginning to Write Letters of the Alphabet and Some High-Frequency Words"
    ],
    Cognition: [
      "Self-Regulation",
      "Problem Solving",
      "Representation",
      "Questioning",
      "Observing",
      "Collecting and Organizing Information",
      "Reflecting and Reaching Conclusions",
      "Communicating Findings",
      "Reasoning Logically",
      "Classifying",
      "Seriating",
      "Counting",
      "Determining Quantity",
      "Comparing Quantities",
      "Representing Numbers",
      "Describing and Determining Ordinal Number and Position",
      "Understanding Two-Dimensional and Three-Dimensional Shapes",
      "Indentifying Patters",
      "Measuring Length, Weight and Capacity, Temperature, Time and Money",
      "Completing Simple Number Operations",
      "Using Number Symbols and Operations",
      "Using Spatial Relations, Directions, Maps"
    ],
    Physical: [
      "Gross Motor Skills",
      "Fine Motor Skills",
      "Auditory Skills and Music",
      "Increasing Levels of Activity, Endurance, and Variation in Types of Activity and Skills"
    ]
  }
};
export { preschool, infants, toddlers, tags };
