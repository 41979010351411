import React from "reactn";
import axios from "axios";
import BaseModal from "./baseModal/BaseModal";
import {
  CONTENT_BLOCK,
  TABLE
} from "./baseModal/FieldTypes";
import { differenceInSeconds, format, isBefore, isSameDay, isSameHour, isSameMinute, isSameSecond, max, min } from "date-fns";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { withRouter } from "react-router-dom";

class ViewArchivedEventsDetailsModal extends React.PureComponent {
  constructor (props){
    super(props);
    this.state = {
      customers: []
    }
  }

  async componentDidMount () {
    const ep = `${process.env.REACT_APP_API}/partners/events/customers/listing/${this.props.program.id}`;
    const customers = await axios.get(ep);
    const sortedCustomers = {
      Attendees: [],
      Applications: [],
      Inactive: []
    }
    /* 
     * This section is used to recreate the state of the customers 
     * table before the Partner made them all inactive and archived
     * the listing. There are a few key assumptions that are made 
     * in order for this to accurately recreate the table:
     *    1. The Partner makes the customers inactive by either clicking "Cancel Program"
     *        or by manually making them all inactive
     *    2. The Partner intended to make the customer inactive for the purpose of
     *        archiving the listing rather than for any other reason if they do so within 1 hour
     *        of archiving the listing
     *    3. If the Partner is manually making the customers inactive, then they will be able to
     *        make all of the customers inactive within 1 hour
     *    4. If the Partner clicked "Cancel Program", then all the customers that needed to be cancelled
     *        will be finished within 1 minute
     *    4. The Partner will archive the listing within 1 day of changing making the customers inactive
     *    5. Once the customers are Inactive, the customers' updatedAt time will no longer be changed
     *    6. The Inactive status can only be achieved by enrolling into the class and appearing under the 
     *        Attendees tab at some point but then being moved into the Inactive tab
     */
    
    console.log(customers.data.data);
    if (customers.data.success) {
      const lastUpdateTime = max(customers.data.data.map(customer => new Date(customer.updatedAt)));
      const archivedCustomers = customers.data.data.filter(customer => customer.archivedDate != null).map(customer => new Date(customer.archivedDate));
      const maxArchivedDate = max(archivedCustomers);
      const filteredArchivedCustomers = archivedCustomers.filter(date => !isSameSecond(date, maxArchivedDate));
      const usedCancelProgram = this.props.program.isRecurring
        ? filteredArchivedCustomers.length < archivedCustomers.length
        : customers.data.data.reduce((prev, curr) => prev || (curr.archivedDate !== null && curr.status_stage === 'Cancelled'), false);

      for (let customer of customers.data.data) {
        const ep2 = `${process.env.REACT_APP_API}/par/orders/customer/event_customers/${customer.id}/false`;

        const res = await axios.get(ep2);
        const order = res.data.data[0];
        console.log("ordre", customer.id, order);
        if (order) {
          customer.startDate = customer.startDate || order.orderStartDate || this.props.program.event_start;
          customer.endDate = this.props.program.event_end;
        }

        if(customer.status_stage === 'Cancelled') {
          if (order && order.status === "Paid" && isSameHour(new Date(customer.archivedDate), new Date(this.props.program.archivedDate))) {
            sortedCustomers.Attendees.push(customer);
          } else if (order && order.status === "Paid") {
            console.log("Order into inactive", order);
            sortedCustomers.Inactive.push(customer);
          } else {
            console.log("Order into applications", order);
            console.log("customer:", customer);
            sortedCustomers.Applications.push(customer);
          }
        } else if (customer.status_stage === 'Inactive') {
          if ((isSameHour(new Date(customer.updatedAt), lastUpdateTime) || 
              isSameDay(new Date(this.props.program.archivedDate), new Date(customer.updatedAt))) 
              && order.status === 'Paid'
              && !usedCancelProgram) {
            sortedCustomers.Attendees.push(customer);
          } else if (order.status === 'Paid') {
            sortedCustomers.Inactive.push(customer);
          }
        } else if (customer.status_stage === 'Cancelled_Unpaid') {
          if (isSameDay(new Date(this.props.program.archivedDate), new Date(customer.updatedAt))
            && !usedCancelProgram) {
            console.log("entered into applications", customer);
            sortedCustomers.Applications.push(customer);
          } else {
            sortedCustomers.Inactive.push(customer);
          }
        } else if (customer.status_stage === 'Rejected') {
          if (isSameDay(new Date(this.props.program.archivedDate), new Date(customer.updatedAt))
            && !usedCancelProgram) {
            sortedCustomers.Applications.push(customer);
          } else {
            sortedCustomers.Inactive.push(customer);
          }
        }
      }

      this.setState({customers: sortedCustomers});
    } else {
      console.log("Something went wrong while retrieving customer information");
    }
  }

  getBaseModalFields() {
    const {
      addChild,
      all_ages,
      target_ages,
      createdAt,
      event_title
    } = this.props.program;

    const formatContentField = ({ name, content }) => ({
      type: CONTENT_BLOCK,
      data: {
        name,
        content,
        titleStyles: { fontSize: "2rem" },
        style: { fontSize: "1.5rem" }
      }
    });

    const programNameField = formatContentField({
      name: "Event Title",
      content: event_title
    });

    const programCreatedAtField = formatContentField({
      name: "Created On",
      content: format(new Date(createdAt), "MMMM dd, yyyy")
    });

    const programAgeField = formatContentField({
      name: "Target Age Range",
      content: all_ages ? "All Ages" : `${target_ages[0]} ${target_ages[0] === "1" ? "year" : "years"} - ${target_ages[1]} ${target_ages[1] === "1" ? "year" : "years"}`
    });

    const programTargetChildrenField = formatContentField({
      name:"Target Children",
      content: addChild ? "Yes" : "No"
    });

    const programCustomersTable = {
      type: TABLE,
      data: {
        name: "Customers Snapshot before Archiving",
        addChild: addChild,
        customers: this.state.customers,
        tickets: this.props.program.tickets,
        tableStyles: {
          marginTop: "-60px"
        },
        enrollmentTabName: "Attendees"
      }
    }

    let allFields = [];
    allFields.push(programNameField);
    allFields.push(programCreatedAtField);
    allFields.push(programAgeField);
    allFields.push(programTargetChildrenField);
    allFields.push(programCustomersTable);

    return allFields;
  }

  getBaseModalButtons () {
    const closeButton = {
      name: "Close",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      handleClick: this.props.onClose
    };

    return [closeButton];
  };

  getBaseModalProps() {
    const baseModalProps = {
      title: "Event Details",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };

    return baseModalProps;
  }

  render () {
    console.log("props:", this.props);
    return <BaseModal {...this.getBaseModalProps()} />
  }
};

export default withRouter(ViewArchivedEventsDetailsModal);