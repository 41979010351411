import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton, Switch } from "@material-ui/core";
import { formTypes } from "../UI/form";
import { Edit, Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, SWITCH, BUTTON, DROPDOWN, TITLE, GET_HTML } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, RED_BUTTON, WHITE } from "./baseModal/colours";

class YesNoModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  requestTour = async e => {
    e.preventDefault();
  };

  render() {
    return (
      <div
        className="request-modal-cont base-modal-outer-wrapper"
      >
        <div
          id="base-modal-wrapper"
          className="base-modal-wrapper"
          style={{
            width: "330px",
            height: "145px"
          }}
          ref={(node) => {
            if (node) {
              node.style.setProperty("min-height", "145px", "important");
            }
          }}
        >
          <div id="base-modal-title">
            <div className="base-modal-title-wrapper">
              <h3 className="base-modal-title">Are you sure you want to delete this?</h3>
              <div onClick={() => {
                this.props.onClose();
              }}>
                <Close className="base-modal-exit-image" />
                {/* <img className="base-modal-exit-image" src={closeButton} alt="" /> */}
              </div>
            </div>
          </div>
          <div id="base-modal-buttons">
            <div id="base-modal-buttons">
              <div className="base-modal-buttons">
                <button
                  style={{
                    backgroundColor: RED_BUTTON,
                    color: WHITE,
                  }}
                  onClick={e => {
                    this.props.noClick();
                    this.props.onClose();
                  }}
                  className="base-modal-button"
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty("margin-top", "16px", "important");
                      node.style.setProperty("margin-bottom", "16px", "important");
                    }
                  }}
                  type={BUTTON}
                >
                  {"No"}
                </button>
                <button
                  style={{
                    backgroundColor: BLUE_BUTTON,
                    color: WHITE,
                  }}
                  onClick={e => {
                    this.props.yesClick();
                    this.props.onClose();
                  }}
                  className="base-modal-button"
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty("margin-top", "16px", "important");
                      node.style.setProperty("margin-bottom", "16px", "important");
                    }
                  }}
                  type={BUTTON}
                >
                  {"Yes"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class AddSkillsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // ...props.data,
      manageDomain: props.manageDomain,
      selected_domain: -1,
      display_domain: "",
      domains: [],
      skills: {},
      editSkills: [],
      newSkills: {},
      skills_desc: "",
      editMode: false,
      deleteModal: null
    };
  }

  async componentDidMount() {
    await this.fetchInitialData()
  }

  async fetchInitialData() {
    try {
      const ep = `${process.env.REACT_APP_API}/partner/observations/customization`;
      const results = await axios.get(ep);

      if (results.data.success) {
        const newSkills = {}
        Object.keys(results.data.data.skills).forEach((key, i) => {
          if (results.data.data.skills[key].length === 0) {
            newSkills[key] = ['']
          } else {
            newSkills[key] = []
          }
        })

        const editMode = results.data.data.skills[this.props.manageDomain.id].length === 0 ? true : false
        this.setState({
          ...this.state,
          classification1: results.data.data.classification1,
          classification2: results.data.data.classification2,
          skills: results.data.data.skills,
          editSkills: Array(results.data.data.skills[this.props.manageDomain.id].length).fill(false),
          newSkills: newSkills,
          editMode: editMode,
          selected_domain: this.props.manageDomain.id,
          domains: results.data.data.domain,
          deleteModal: null
        })
      }
    } catch (err) {
      console.error(err);
    }
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async savePlan() {
    if (this.checkFields) {
      try {
        const ep = `${process.env.REACT_APP_API}/partner/observations/customization/domain`;
        // const data = (this.state.newDomains.length <= 1 && this.state.newDomains[0].name === "") ?
        //   this.state.domain : [...this.state.domain, ...this.state.newDomains]
        const data = []
        for (const domain of this.state.domains) {
          if (parseInt(domain.id) === parseInt(this.state.selected_domain)) {
            data.push({ id: this.state.selected_domain, name: this.state.manageDomain.name })
          } else {
            data.push(domain)
          }
        }
        if (parseInt(this.state.selected_domain) > parseInt(this.state.domains[this.state.domains.length - 1])) {
          data.push({ id: this.state.selected_domain, name: this.state.manageDomain.name })
        }
        const result = await axios.post(ep, { domains: data })
        console.log(result)
        // save domain name first
        if (result.data.success) {
          const ep = `${process.env.REACT_APP_API}/partner/observations/customization/skills`;

          const allSkills = {};

          // combine skills and newSkills
          Object.entries(this.state.skills).forEach((pair, i) => {
            const key = pair[0];
            const value = pair[1];

            allSkills[key] = [...value];
          })
          Object.entries(this.state.newSkills).forEach((pair, i) => {
            const key = pair[0];
            const value = pair[1];

            value.forEach((skill) => {
              allSkills[key].push(skill);
            })
          })

          // remove empty strings
          const data = {}
          Object.entries(allSkills).forEach((pair, i) => {
            const key = pair[0];
            const value = pair[1]
            if (value.length === 1 && value[0] === '') {
              data[key] = []
            } else {
              data[key] = value
            }
          })

          const result = await axios.post(ep, { skills: data })

          if (result.data.success) {
            toast.success("Saved Observation Settings");
            await this.fetchInitialData();

            const editSkills = [...this.state.editSkills]
            this.setState({
              ...this.state,
              editSkills: editSkills.fill(false)
            })
            // this.props.onClose();
          } else {
            toast.error("Observation settings could not be saved")
          }
        } else {
          toast.error("Observation settings could not be saved")
        }
      } catch (err) {
        console.error(err)
      }
    } else {
      toast.error("Please fill in all fields")
    }


  }

  checkFields = () => {
    const allSkills = {};

    // combine skills and newSkills
    Object.entries(this.state.skills).forEach((pair, i) => {
      const key = pair[0];
      const value = pair[1];

      allSkills[key] = [...value];
    })
    Object.entries(this.state.newSkills).forEach((pair, i) => {
      const key = pair[0];
      const value = pair[1];

      value.forEach((skill) => {
        allSkills[key].push(skill);
      })
    })


    for (const pair of Object.entries(allSkills)) {
      const key = pair[0];
      const value = pair[1]

      if (value.includes('') && value.length !== 1) {
        return false
      }
    }
    return true
  }

  /// ================== NEW MODAL ========================== ///

  getSkillsFunc = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginBottom: 0
        }}
      >
        <div style={{ display: "block", width: "100%" }}>
          {this.state.skills[this.state.selected_domain].map((skill, i) => {
            return (
              <div
                key={i}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start"
                }}
                name=""
              >
                <div
                  style={{
                    width: "90%",
                    display: "block",
                    alignSelf: "flex-start"
                  }}
                >
                  <input
                    style={{
                      height: "40px",
                      width: "100%",
                      fontSize: "0.93rem",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "300",
                      color: "#494949",
                      border: "1px solid #c5c5c5",
                      borderRadius: "4px",
                      paddingLeft: "14px"
                    }}
                    disabled={!this.state.editSkills[i]}
                    name={i}
                    id={i}
                    type="text"
                    onChange={e => {
                      const currSkills = this.state.skills[this.state.selected_domain]
                      currSkills[i] = e.target.value

                      const skills = {
                        ...this.state.skills,
                        [this.state.selected_domain]: currSkills
                      }
                      this.setState({
                        skills: skills
                      })
                    }}
                    value={skill}
                  />
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    color: "#9BA7B2",
                    marginLeft: "20px"
                  }}
                  onClick={() => {
                    const editSkills = [...this.state.editSkills]
                    editSkills[i] = true;

                    this.setState({
                      ...this.state,
                      editSkills: editSkills
                    });
                  }}
                >
                  <Edit color="action" fontSize="large" />
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    color: "#9BA7B2",
                    marginLeft: "10px"
                  }}
                  onClick={() => {
                    this.setState({
                      deleteModal: {
                        noClick: () => {

                        },
                        yesClick: () => {
                          const currSkills = this.state.skills[this.state.selected_domain]
                          if (currSkills.length <= 0) {
                            return;
                          }

                          currSkills.splice(i, 1)
                          const skills = {
                            ...this.state.skills,
                            [this.state.selected_domain]: currSkills
                          }
                          this.setState({
                            skills: skills
                          })
                        },
                        onClose: () => {
                          this.setState({
                            deleteModal: null
                          })
                        }
                      }
                    })
                  }}
                >
                  <Close color="action" fontSize="large" />
                </div>
              </div>
            );
          })}
          {this.state.newSkills[this.state.selected_domain].map((skill, i) => {
            return (
              <div
                key={i}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start"
                }}
                name=""
              >
                <div
                  style={{
                    width: "90%",
                    display: "block",
                    alignSelf: "flex-start"
                  }}
                >
                  <input
                    style={{
                      height: "40px",
                      width: "100%",
                      fontSize: "0.93rem",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "300",
                      color: "#494949",
                      border: "1px solid #c5c5c5",
                      borderRadius: "4px",
                      paddingLeft: "14px"
                    }}
                    name={i}
                    id={i}
                    type="text"
                    onChange={e => {
                      const currNewSkills = this.state.newSkills[this.state.selected_domain]
                      currNewSkills[i] = e.target.value

                      const newSkills = {
                        ...this.state.newSkills,
                        [this.state.selected_domain]: currNewSkills
                      }
                      this.setState({
                        newSkills: newSkills
                      })
                    }}
                    value={skill}
                  />
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    color: "#9BA7B2",
                    marginLeft: "20px"
                  }}
                  onClick={() => {
                    this.setState({
                      deleteModal: {
                        noClick: () => {

                        },
                        yesClick: () => {
                          const currNewSkills = this.state.newSkills[this.state.selected_domain]
                          if (currNewSkills.length <= 0) {
                            return;
                          }

                          currNewSkills.splice(i, 1)
                          const newSkills = {
                            ...this.state.newSkills,
                            [this.state.selected_domain]: currNewSkills
                          }
                          this.setState({
                            newSkills: newSkills
                          })
                        },
                        onClose: () => {
                          this.setState({
                            deleteModal: null
                          })
                        }
                      }
                    })
                  }}
                >
                  <Close color="action" fontSize="large" />
                </div>
                <div
                  style={{
                    visibility: "hidden",
                    color: "#9BA7B2",
                    marginLeft: "10px"
                  }}
                >
                  <Close color="action" fontSize="large" />
                </div>
              </div>
            );
          })}

          <div
            style={{
              width: "60%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "right",
              alignItems: "right",
              padding: "2px 0",
              marginTop: "10px",
              fontSize: "0.7em",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#797979",
              marginLeft: "100px"
            }}
            onClick={() => {
              const currNewSkills = this.state.newSkills[this.state.selected_domain];
              currNewSkills.push("");

              const newSkills = {
                ...this.state.newSkills,
                [this.state.selected_domain]: currNewSkills
              }
              this.setState({
                newSkills: newSkills
              })
            }}
          >
            + Add another {this.state.classification2}
          </div>
        </div>
      </div>
    );
  }

  getBaseModalFields() {
    // const domainName = {
    //   type: STRING_INPUT,
    //   data: {
    //     name: this.state.classification1,
    //     required: false,
    //     placeholder: "Please select a category",
    //     handleChange: e => {
    //       this.setState({
    //         ...this.state,
    //         display_domain: e.target.options[e.target.selectedIndex].text,
    //         selected_domain: e.target.value,
    //       });
    //     },
    //     value: this.state.manageDomain.name
    //     // choices: this.state.domains
    //   },
    //   validators: { validateSubmit: () => this.state.selected_domain !== -1 }
    // };
    const domainName = {
      type: STRING_INPUT,
      data: {
        name: "Change " + this.state.classification1 + " Name",
        required: false,
        placeholder: "Please enter a Classification",
        handleChange: e => {
          this.setState({
            ...this.state,
            manageDomain: {
              id: this.state.manageDomain.id,
              name: e.target.value
            }
          });
        },
        value: this.state.manageDomain.name
      },
      validators: { validateSubmit: () => this.state.manageDomain.name !== "" }
    };

    const skillsList = {
      type: GET_HTML,
      data: {
        name: this.state.classification2,
        gethtml: this.getSkillsFunc
      }
    }

    // const skillsDesc = {
    //   type: STRING_INPUT,
    //   data: {
    //     name: this.state.classification2,
    //     required: true,
    //     disabled: this.state.selected_domain !== -1,
    //     placeholder: "Please enter skill description",
    //     handleChange: e => {
    //       this.setState({
    //         ...this.state,
    //         skills_desc: e.target.value,
    //       });
    //     },
    //     value: this.state.skills_desc
    //   },
    //   validators: { validateSubmit: () => this.state.skills_desc !== "" }
    // };

    const fields = [];
    fields.push(domainName);

    if (this.state.classification2 !== "" && this.state.selected_domain !== -1) {
      fields.push(skillsList);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const backButton = {
      name: "Back",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: async _ => {
        // if (this.state.editMode) {
        // toast.error("Please finish making your changes")
        // return;
        // }
        await this.savePlan();
        await this.props.backPage();
        // this.props.onClose();
      }
    }

    const addButton = {
      name: "Save",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: async _ => await this.savePlan()
    };

    return [backButton, addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Manage Classification",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ========================== ///

  render() {
    return (
      <>
        <BaseModal {...this.getBaseModalProps()} />;
        {this.state.deleteModal && (
          <YesNoModal
            yesClick={this.state.deleteModal.yesClick}
            noClick={this.state.deleteModal.noClick}
            onClose={this.state.deleteModal.onClose}
          />
        )}
      </>
    )
  }
}

export default withRouter(AddSkillsModal);
