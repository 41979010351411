import React, {
  // useRef,
  useState
  // useEffect
} from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import PropTypes from "prop-types";
import { ModalBanner } from "../../assets";
// import { Button, Typography } from "@material-ui/core";
// import AttachFileIcon from '@material-ui/icons/AttachFile';
// import ReactTooltip from 'react-tooltip';
import axios from "axios";
import Switch from "@material-ui/core/Switch";

const CreateRoomsModal = ({ onClose, programId }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [is_active, setIsActive] = useState(true);

  const submitForm = async e => {
    e.preventDefault();
    const data = {
      name,
      description,
      is_active,
      programId
    };

    const ep = `${process.env.REACT_APP_API}/partners/rooms/add`;
    const resp = await axios.post(ep, data);
    if (resp && resp.data && resp.data.success) {
      onClose();
    } else {
      // TODO: display error message
    }
  };
  return (
    <div className="request-modal-cont">
      <div className="request-modal lesspadding">
        <div style={{ display: "block" }}>
          <img
            src={ModalBanner}
            alt="Modal Banner"
            style={{
              height: "40px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "48px",
              marginBottom: ".5rem"
            }}
          >
            <h3 data-testid="create-rooms-modal-heading">Create Room</h3>
            <IconButton
              style={{
                borderRadius: "50%",
                color: "#2880FF",
                marginBottom: "1rem"
              }}
              onClick={_ => onClose()}
            >
              <Close />
            </IconButton>
          </div>

          <form onSubmit={submitForm}>
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                data-testid="room-label-name"
                htmlFor="room_name"
                style={{ width: "25%", textAlign: "left" }}
              >
                Room Name
              </label>
              <input
                name="room_name"
                id="room_name"
                value={name}
                type="text"
                className="form-control"
                style={{ width: "70%" }}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                data-testid="room-label-description"
                htmlFor="description"
                style={{ width: "25%", textAlign: "left" }}
              >
                Description
              </label>
              <textarea
                value={description}
                onChange={e => setDescription(e.target.value)}
                name="description"
                id="description"
                type="text"
                className="form-control"
                style={{ width: "70%" }}
                rows="4"
                columns="12"
              />
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                data-testid="room-label-active"
                htmlFor="active"
                style={{ width: "25%", textAlign: "left" }}
              >
                Active
              </label>
              <Switch
                name="active"
                checked={is_active}
                onClick={_ => setIsActive(!is_active)}
                color="primary"
              />
            </div>

            <div className="modal-footer" style={{ marginTop: "25px" }}>
              <button type="submit" className="btn profile-btn">
                Create Room
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

PropTypes.CreateRoomsModal = {
  onClose: PropTypes.func.isRequired,
  programId: PropTypes.number.isRequired
};

export default CreateRoomsModal;
