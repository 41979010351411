import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";
import ProgramSearchInput from "../../../../components/common/ProgramSearchInput";
import Switch from "@material-ui/core/Switch";
class SectionFour extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { 
      days: {}
    };
  }
  handleChange = (e, v) => {
    /*if (
      !this.global.profile.applicationSubmitted ||
      this.global.dsUser.accountType === "Admin"
    ) {
      this.setGlobal({
        myApplicationProfileAddress: {
          ...this.global.myApplicationProfileAddress,
          [e]: v
        }
      });
    }*/
  };

  customForm = () => {
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-5">
              <h3>Locations #1</h3>
            </div>
            <div
              className="col-7 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                {this.global.organizationInfo.address1 &&
                  `${this.global.organizationInfo.address1}, `}
                {this.global.organizationInfo.city &&
                  `${this.global.organizationInfo.city}, `}
                {this.global.organizationInfo.province &&
                  `${this.global.organizationInfo.province}, `}
                {this.global.organizationInfo.postalcode}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>New Locations</h3>
            </div>
            <div className="col-7 d-flex">
              <button
                type="button"
                className="btn addNew-btn"
                onClick={e =>
                  this.setGlobal({
                    organizationInfo: {
                      ...this.global.organizationInfo,
                      other_locations: [
                        ...this.global.organizationInfo.other_locations,
                        { address: "", lat: null, lng: null, visible: true }
                      ]
                    }
                  })
                }
              >
                Add New Address
              </button>
            </div>
          </div>
          {this.global.organizationInfo.other_locations &&
            this.global.organizationInfo.other_locations.map((e, i) => (
              <div className="row">
                <div className="col-3">
                  <h3>Additional Location #{i + 2}</h3>
                </div>
                <div className="col-7">
                  <ProgramSearchInput
                    placeholder="Address1"
                    noicon={true}
                    name="search"
                    style={{ marginTop: "0px" }}
                    value={e.address}
                    gLoc={(e, loc) => {
                      const other_locs = [
                        ...this.global.organizationInfo.other_locations
                      ];
                      other_locs[i].address = e;
                      other_locs[i].lat = loc.lat;
                      other_locs[i].lng = loc.lng;
                      other_locs[i].visible = true;
                      this.setGlobal({
                        organizationInfo: {
                          ...this.global.organizationInfo,
                          other_locations: [...other_locs]
                        }
                      });
                    }}
                  />
                </div>
                <div>
                  <Switch
                    checked={e.visible === true || e.visible === null ? true : false}
                    onClick={c => {
                      const other_locs = [
                        ...this.global.organizationInfo.other_locations
                      ];
                      if (!other_locs[i].visible) {
                        other_locs[i].visible = true
                      } else {
                        other_locs[i].visible = !other_locs[i].visible;
                      }
                      this.setGlobal({
                        organizationInfo: {
                          ...this.global.organizationInfo,
                          other_locations: [...other_locs]
                        }
                      });
                    }}
                    color="primary"
                  />
                </div>
              </div>
            ))}
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Additional Locations"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionFour;
