import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton, Switch } from "@material-ui/core";
import { formTypes } from "../UI/form";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, SWITCH, DROPDOWN, TITLE } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class ViewMilestoneFileUploadsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    console.log("STATE PROPS MS", this.state, this.props);
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px",
                marginBottom: ".5rem"
              }}
            >
              <h3>Uploaded Files</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form>
              {this.props.milestoneData.file_uploads &&
                Object.values(this.props.milestoneData.file_uploads).map(
                  (file, index) => {
                    if (!file?.isHidden) {
                      return (
                        <div
                          className="form-group"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 0
                          }}
                        >
                          <label
                            htmlFor={"file_" + index}
                            style={{ textAlign: "left" }}
                          >
                            File ({file.file_name})
                            <br /> Uploaded on{" "}
                            {new Date(file.upload_date).toLocaleString()}
                          </label>
                          <a
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            href={file.file_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <DocumentIcon />
                            </span>
                            <span
                              style={{ margin: "0 0.4rem", fontSize: "0.6em" }}
                            >
                              View Upload
                            </span>
                          </a>
                        </div>
                      );
                    }
                  }
                )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function getFileExtention(file_url) {
  if (file_url) {
    const split = file_url.split(".");
    return split[split.length - 1];
  }
}

const DocumentIcon = () => {
  return (
    <svg
      style={{ width: "15px", height: "15px" }}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
      ></path>
    </svg>
  );
};

export default withRouter(ViewMilestoneFileUploadsModal);
