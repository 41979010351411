import React, { useContext, useGlobal, useDispatch, useState } from "reactn";
import PropTypes from "prop-types";

import {
  PromotionContext,
  setSelected
  // setCheckout,
  // setSignIn
} from "./PromotionDetail.context";
import BookingDetailPromotionCard from "./BookingDetailPromotionCard";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import SummarySubTotalMembership from "../SpecificPageUtils/SummarySubTotalMembership";

const styles = {
  radio: {
    color: "#ccc",
    "&$checked": {
      color: "#FF6666"
    },
    "& .MuiButtonBase-root": {
      padding: "0px !important",
      marginRight: "0px !important"
    }
  },
  checkedRadio: {
    color: "#FF6666"
  }
};

const MembershipBookingDetail = props => {
  const { state, dispatch } = useContext(PromotionContext);
  const { membership, promotion, selected } = state;
  // global state
  const [global, _] = useGlobal();
  const globalDispatch = useDispatch();
  const isShowPromoOnly = promotion?.show_promo_only;
  const [selectedPlan, setSelectedPlan] = useState(null);

  return (
    <>
      <h2 className="right-cont-name">Membership Plans</h2>
      <div style={{ width: "80%", margin: "0 auto" }}>
        {membership &&
          membership.prices &&
          membership.prices.map((ele, key) => {
            const e = JSON.parse(ele);

            if (isShowPromoOnly) {
              if (!isPricePartOfPromotion(promotion, e)) {
                return null;
              }
            }

            return (
              <div
                className={
                  selected === key
                    ? props.classes.planSelected + " pricingcard"
                    : props.classes.plan + " pricingcard"
                }
                onClick={() => {
                  setSelected(dispatch, key);
                  setSelectedPlan(e);
                }}
                key={key}
              >
                <div className="radio-btn-positioning-container">
                  <Radio
                    checked={selected === key}
                    // onChange={handleChange}
                    value="d"
                    color="default"
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "D" }}
                    size="large"
                    classes={{
                      root: props.classes.radio,
                      checked: props.classes.checkedRadio
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <p className="pricingcard-name">
                    {e.membership_term} Month Term <br />{" "}
                    {isPricePartOfPromotion(promotion, e) && (
                      <BookingDetailPromotionCard promotion={promotion} />
                    )}
                  </p>

                  <p className="pricingcard-days">
                    {e.auto_renew
                      ? "Subscription Membership"
                      : "Standard Membership"}
                  </p>
                </div>
                <div style={{ width: "70px" }}>
                  <p className="pricingcard-amount">
                    {e.price_per_term ? (
                      <>
                        ${" "}
                        {isPricePartOfPromotion(promotion, e)
                          ? displayPromotionMembershipValue(
                              promotion,
                              e.price_per_term
                            )
                          : parseFloat(e.price_per_term).toFixed(2)}
                      </>
                    ) : (
                      <>FREE</>
                    )}
                  </p>
                </div>
              </div>
            );
          })}

        {selected !== -1 && (
          <SummarySubTotalMembership
            plans={membership.prices}
            selected={selected}
            isPromo={isPricePartOfPromotion(
              promotion,
              JSON.parse(membership.prices[selected])
            )}
            promotion={promotion}
          />
        )}
        {membership.isCartDisabled ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(52, 63, 100)`,
                color: `white`,
                marginTop: "10px",
                fontWeight: `bold`,
                border: 0,
                cursor: "not-allowed"
              }}
            >
              Register Here Now
            </button>
          </div>
        ) : selectedPlan?.auto_renew ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(52, 63, 100)`,
                color: `white`,
                marginTop: "10px",
                fontWeight: `bold`,
                border: 0,
                cursor: "not-allowed"
              }}
            >
              Register Here Now
            </button>
            <div>
              <i className="bi bi-info-circle" /> Recurring memberships must be
              checked out individually.
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(52, 63, 100)`,
                color: `white`,
                fontWeight: `bold`,
                border: 0,
                marginTop: "40px",
                cursor: "not-allowed"
              }}
            >
              Add to Cart
            </button>
            <span>-------- OR --------</span>
            <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(211,52,49)`,
                color: `white`,
                marginTop: "10px",
                fontWeight: `bold`,
                border: 0,
                cursor: "not-allowed"
              }}
            >
              Register Here Now
            </button>
          </div>
        )}
      </div>
    </>
  );
};

MembershipBookingDetail.propTypes = {
  classes: PropTypes.object
};
const PERCENT = "Percentage";
const FIXED_AMOUNT = "Fixed Amount";

function displayPromotionMembershipValue(promotion, currentPrice) {
  if (promotion.discount_type === PERCENT) {
    return ((1 - promotion.discount_percent / 100) * currentPrice).toFixed(2);
  } else if (promotion.discount_type === FIXED_AMOUNT) {
    return Math.max(currentPrice - promotion.discount_fixed / 100, 0).toFixed(
      2
    );
  }
}

function isPricePartOfPromotion(promotion, price) {
  return promotion.plan_price_ticketId.indexOf(price.id) !== -1;
}

export default withStyles(styles)(MembershipBookingDetail);
