import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import DatePicker from "react-datepicker";
class ProposalModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { page: 0 };
    this.required = [
      { name: "name", validation: e => !e, page: 0 },
      { name: "company", validation: e => !e, page: 0 },
      {
        name: "email",
        validation: e =>
          !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(e),
        page: 0
      },
      {
        name: "phone",
        validation: e =>
          !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
            e
          ),
        page: 0
      },
      { name: "city", validation: e => !e, page: 0 },
      { name: "amount", validation: e => !e, page: 1 }
    ];
  }

  requestTour = async e => {
    e.preventDefault();
  };
  componentDidUpdate() {
    if (document.getElementsByClassName("react-datepicker-wrapper")[0])
      document.getElementsByClassName(
        "react-datepicker-wrapper"
      )[0].style.width = "70%";
  }
  async submitForm(e, save = false) {
    if (!save) e.preventDefault();
    let cancel = false;
    for (let requiredField of this.required.filter(
      e => e.page === this.state.page
    )) {
      document.getElementsByName(requiredField.name)[0].style.border =
        "1px solid #d1d3e2";
      if (
        requiredField.validation(
          this.state[requiredField.name] === undefined
            ? this.props.data[requiredField.name]
            : this.state[requiredField.name]
        )
      ) {
        document.getElementsByName(requiredField.name)[0].style.boxShadow =
          "none";
        document.getElementsByName(requiredField.name)[0].style.border =
          "1px solid red";
        document.getElementsByName(requiredField.name)[0].scrollIntoView();
        cancel = true;
      }
    }
    if (cancel) return;
    const endp = `${process.env.REACT_APP_API}/adm/partner/update`;
    const noPage = { ...this.state };
    delete noPage.page;
    const result = await axios.put(endp, {
      ...noPage,
      categorie: !save ? 2 : this.props.data.categorie,
      id: this.props.data.id
    });
    if (result.data.success) {
      toast.success(save ? "Save Successful!" : "Updated Lead!");
      const objToUpdate = {
        ...noPage,
        categorie: !save ? 2 : this.props.data.categorie,
        id: this.props.data.id
      };
      if (save) delete objToUpdate.categorie;
      this.props.update(objToUpdate);
      this.props.onClose();
    }
  }
  validate(e, end = false) {
    if (e) e.preventDefault();
    let cancel = false;
    for (let requiredField of this.required.filter(
      e => e.page === this.state.page
    )) {
      document.getElementsByName(requiredField.name)[0].style.border =
        "1px solid #d1d3e2";
      if (
        requiredField.validation(
          this.state[requiredField.name] === undefined
            ? this.props.data[requiredField.name]
            : this.state[requiredField.name]
        )
      ) {
        document.getElementsByName(requiredField.name)[0].style.boxShadow =
          "none";
        document.getElementsByName(requiredField.name)[0].style.border =
          "1px solid red";
        document.getElementsByName(requiredField.name)[0].scrollIntoView();
        cancel = true;
      }
    }
    if (cancel) return false;
    if (end) return true;
    this.setState({ page: this.state.page + 1 });
  }
  render() {
    const bottomText = `Client to start the Children's Program (DS) package for $${
      this.state.amount || this.props.data.amount || "N/A"
    } a month starting ${
      this.state.startDate
        ? new Date(this.state.startDate).toDateString()
        : this.props.data.startDate
        ? new Date(this.props.data.startDate).toDateString()
        : "N/A"
    }. Client has selected ${
      this.state.users || this.props.data.users || "0"
    } Users, and ${
      this.state.program_cap || this.props.data.program_cap || 0
    } Program / Event Cap. With the promotions, client will pay $${Math.round(
      (this.state.amount || this.props.data.amount) * 0.75
    )} a month for 6 months
      There is a $${
        (this.state.amount || this.props.data.amount) * 2
      } deposit that is required.`;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ position: "relative" }}>
            <h3>Proposal</h3>
            {this.state.page === 0 && (
              <>
                <form onSubmit={e => this.validate(e)}>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Name *
                    </label>
                    <input
                      name="name"
                      id="displayName"
                      type="text"
                      className="form-control"
                      placeholder="Lead's full name..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.name === undefined
                          ? this.props.data.name
                          : this.state.name
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Organization *
                    </label>
                    <input
                      name="company"
                      id="organization"
                      type="text"
                      className="form-control"
                      placeholder="Organization's name..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.company === undefined
                          ? this.props.data.company
                          : this.state.company
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Postal / Zip
                    </label>
                    <input
                      name="postalcode"
                      id="postal"
                      type="text"
                      className="form-control"
                      placeholder="Postal Code..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.postalcode === undefined
                          ? this.props.data.postalcode
                          : this.state.postalcode
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="email"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Email *
                    </label>
                    <input
                      name="email"
                      id="email"
                      type="email"
                      placeholder="Lead's Email..."
                      className="form-control"
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.email === undefined
                          ? this.props.data.email
                          : this.state.email
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Mobile *
                    </label>
                    <input
                      name="phone"
                      id="mobile"
                      type="text"
                      className="form-control"
                      placeholder="Phone Number..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.phone === undefined
                          ? this.props.data.phone
                          : this.state.phone
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Notes
                    </label>
                    <textarea
                      name="notes"
                      id="notes"
                      type="text"
                      className="form-control"
                      style={{ width: "70%" }}
                      rows={4}
                      placeholder="Notes..."
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.notes === undefined
                          ? this.props.data.notes
                          : this.state.notes
                      }
                    ></textarea>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Address 1
                    </label>
                    <input
                      name="address1"
                      id="postal"
                      type="text"
                      className="form-control"
                      placeholder="Address 1..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.address1 === undefined
                          ? this.props.data.address1
                          : this.state.address1
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Address 2
                    </label>
                    <input
                      name="address2"
                      id="postal"
                      type="text"
                      className="form-control"
                      placeholder="Address 2..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.address2 === undefined
                          ? this.props.data.address2
                          : this.state.address2
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      City *
                    </label>
                    <input
                      name="city"
                      id="postal"
                      type="text"
                      className="form-control"
                      placeholder="City..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.city === undefined
                          ? this.props.data.city
                          : this.state.city
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Plan Type *
                    </label>
                    <select
                      name="plan_type"
                      id="postal"
                      type="text"
                      className="form-control"
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.plan_type === undefined
                          ? this.props.data.plan_type
                          : this.state.plan_type
                      }
                    >
                      <option>Children's Program - DS</option>
                      <option>Children's Program - Custom</option>
                      <option>Daycare / Preschool - DS</option>
                      <option>Daycare / Preschool - Custom</option>
                      <option>Kids Summer Camp - DS</option>
                      <option>Kids Summer Camp - Custom</option>
                      <option>Kids Athletic Program - DS</option>
                      <option>Kids Athletic Program - Custom</option>
                      <option>Service Volunteer- Custom</option>
                      <option>Educational Program - Custom</option>
                      <option>Foundation Program - Custom</option>
                      <option>Community Center - Custom</option>
                    </select>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Stage *
                    </label>
                    <select
                      name="stage"
                      id="postal"
                      type="text"
                      className="form-control"
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.stage === undefined
                          ? this.props.data.stage
                          : this.state.stage
                      }
                    >
                      <option>1st Meeting</option>
                      <option>Follow-up</option>
                      <option>Demo & Education</option>
                    </select>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Interest
                    </label>
                    <select
                      name="interest"
                      id="postal"
                      type="text"
                      className="form-control"
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.interest === undefined
                          ? this.props.data.interest
                          : this.state.interest
                      }
                    >
                      <option>Low</option>
                      <option>Medium</option>
                      <option>High</option>
                    </select>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={_ => this.submitForm(null, true, 5)}
                      className="btn profile-btn"
                    >
                      Save
                    </button>
                    <button className="btn profile-btn">Next</button>
                  </div>
                </form>
              </>
            )}
            {this.state.page === 1 && (
              <>
                <form onSubmit={e => this.submitForm(e)}>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="amount"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Amount *
                    </label>
                    <input
                      name="amount"
                      id="amount"
                      type="number"
                      className="form-control"
                      placeholder="Amount..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.amount === undefined
                          ? this.props.data.amount
                          : this.state.amount
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="frequency"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Frequency *
                    </label>
                    <select
                      name="frequency"
                      id="frequency"
                      type="text"
                      className="form-control"
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.frequency === undefined
                          ? this.props.data.frequency
                          : this.state.frequency
                      }
                    >
                      <option value={1}>Monthly</option>
                      <option value={4}>Quarterly</option>
                      <option value={12}>Yearly</option>
                    </select>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="promocode"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Promo Code
                    </label>
                    <input
                      name="promocode"
                      id="promocode"
                      type="text"
                      className="form-control"
                      placeholder="Promo..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.promocode === undefined
                          ? this.props.data.promocode
                          : this.state.promocode
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="startDate"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Start Date
                    </label>
                    <DatePicker
                      selected={
                        this.state.startDate
                          ? this.state.startDate
                          : this.props.data.startDate
                          ? new Date(this.props.data.startDate)
                          : null
                      }
                      onChange={date => this.setState({ startDate: date })}
                      minDate={new Date()}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="MMMM d, yyyy"
                      className="w-100 form-control"
                      placeholderText="Start Date..."
                      name="requestedDate"
                      autoComplete="off"
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="deposit"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Deposit
                    </label>
                    <select
                      name="deposit"
                      id="deposit"
                      type="text"
                      className="form-control"
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.deposits === undefined
                          ? this.props.data.deposits
                          : this.state.deposits
                      }
                    >
                      <option value={2}>2 months</option>
                    </select>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="users"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Users
                    </label>
                    <input
                      name="users"
                      id="users"
                      type="text"
                      className="form-control"
                      style={{ width: "70%" }}
                      placeholder="Users..."
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.users === undefined
                          ? this.props.data.users
                          : this.state.users
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="program_cap"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Program / Event Cap
                    </label>
                    <input
                      name="program_cap"
                      id="program_cap"
                      type="text"
                      className="form-control"
                      placeholder="Program / Event Cap..."
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={
                        this.state.program_cap === undefined
                          ? this.props.data.program_cap
                          : this.state.program_cap
                      }
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="trial"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Trial Period
                    </label>
                    <select
                      name="trial"
                      id="trial"
                      type="text"
                      className="form-control"
                      style={{ width: "70%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={this.props.data.trial}
                    >
                      <option value={2}>2 Weeks</option>
                    </select>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0,
                      width: "85%",
                      margin: "10px auto"
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={e =>
                        this.setState({ subdomain_required: e.target.checked })
                      }
                      checked={this.props.data.subdomain_required}
                    />
                    <label
                      style={{ margin: 0, color: "black", fontWeight: "bold" }}
                    >
                      Subdomain Required
                    </label>

                    <input
                      type="checkbox"
                      onChange={e => this.setState({ list: e.target.checked })}
                      checked={this.props.data.list}
                    />
                    <label
                      style={{ margin: 0, color: "black", fontWeight: "bold" }}
                    >
                      List in DS MarketPlace
                    </label>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      MarketPlace Commission
                    </label>
                    <input
                      name="commission"
                      id="commission"
                      type="number"
                      className="form-control"
                      placeholder="Commission..."
                      style={{ width: "30%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={this.props.data.commission}
                    />
                    <select
                      name="commission_length"
                      id="commission_length"
                      type="text"
                      className="form-control"
                      style={{ width: "30%" }}
                      onChange={e => {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }}
                      value={this.props.data.commission_length}
                    >
                      <option>First Month</option>
                    </select>
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "10px",
                      color: "grey",
                      marginTop: "30px"
                    }}
                  >
                    {bottomText}
                  </p>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn profile-btn"
                      onClick={_ => this.submitForm(null, true)}
                    >
                      Save
                    </button>
                    {!this.props.edit && (
                      <button type="submit" className="btn profile-btn">
                        Create Proposal
                      </button>
                    )}
                  </div>
                </form>
              </>
            )}
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                left: "5",
                color: "grey",
                fontSize: "14px"
              }}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={_ => this.setState({ page: 0 })}
                className={this.state.page === 0 ? "spanpageselected" : ""}
              >
                1
              </span>
              <span
                className={this.state.page === 1 ? "spanpageselected" : ""}
                onClick={_ =>
                  this.validate(null, true) && this.setState({ page: 1 })
                }
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                2
              </span>
            </div>
          </div>
          <IconButton
            style={{ position: "absolute", right: "0", top: "0" }}
            onClick={_ => this.props.onClose()}
          >
            <Close />
          </IconButton>
        </div>
      </div>
    );
  }
}

export default withRouter(ProposalModal);
