import React, { useState, useEffect, useGlobal } from "react";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import "../../assets/css/componentSpecificCss/searchBar.css";

export const SearchBar = ({ setIsFocused, setSearch, partnerId }) => {
  const location = useLocation();
  const [input, setInput] = useState("");
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState({ Active: [], Pending: [] });
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    fetchInitialData();
  }, []);

  const handleFocus = () => {
    setIsFocused(true);
    setFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setFocused(false);
  };

  useEffect(() => {
    setInput("");
    setSearch(allData, "");
  }, [location.pathname]);

  const fetchInitialData = async value => {
    if (partnerId !== 86) {
      const ep = `${process.env.REACT_APP_API}/partners/connections/${true}`;
      const results = await axios.get(ep);

      if (results.data.success) {
        const dataArr = [];

        results.data.data.connections
          .filter(f => !f.isArchived)
          .sort((a, b) => {
            if (a.classification < b.classification) {
              return -1;
            }
            if (a.classification > b.classification) {
              return 1;
            }
            return 0;
          })
          .map(async e => {
            let childrens = e.childNames ? e.childNames : [];
            // if (e.dsUserId) {
            //   const childrenEp = `${process.env.REACT_APP_API}/partners/connections/child/${e.dsUserId}`;
            //   const allChildrens = await axios.get(childrenEp);
            //   allChildrens.data.data.childrens.map(child => {
            //     childrens.push(child);
            //   });
            // }

            if (!dataArr.some(f => f.email === e.email)) {
              dataArr.push({
                ...e,
                childrens: childrens
              });
            }
          });

        const uniqueEmails = new Map();
        dataArr.forEach(item => {
          if (
            !uniqueEmails.has(item.email) ||
            item.childrens.length >
              uniqueEmails.get(item.email).childrens.length
          ) {
            uniqueEmails.set(item.email, item);
          }
        });

        const filteredDataArr = Array.from(uniqueEmails.values());

        setAllData(
          filteredDataArr.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
        );
      }
    } else {
      setAllData([]);
    }
  };

  const handleChange = e => {
    const value = e.target.value.toLowerCase().replace(/\s/g, "");
    setInput(e.target.value);
    setSearch(allData, value);
  };

  return (
    <div className={`input-wrapper ${focused ? "focused" : ""}`}>
      <FaSearch id="search-icon" />
      <input
        id="universal-search-bar"
        placeholder="Type to search..."
        value={input}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <span
        onClick={() => {
          setSearch(allData, "");
          setInput("");
        }}
        style={{ cursor: "pointer" }}
      >
        x
      </span>
    </div>
  );
};
