import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { CloudUpload } from "@material-ui/icons";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { formTypes } from "../../components/UI/form";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  TEXT_AREA,
  GET_HTML,
  IMAGE_DROPDOWN,
  SWITCH,
  DROPDOWN,
  DATE_PICKER,
  SELECT_MULTIPLE_DROPDOWN
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

const categories = [
  { name: "Select", value: "" },
  { name: "First Aid", value: "First Aid" },
  { name: "CPR", value: "CPR" },
  { name: "Background Check", value: "Background Check" },
  { name: "Degree", value: "Degree" },
  { name: "Certificate", value: "Certificate" },
  { name: "Accreditation", value: "Accreditation" },
  { name: "Other", value: "Other" }
];
class EditCertificationsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
      other: "",
      title: "",
      details: "",
      certifications: []
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };
  // partner_login_id: this.props.match.params.id,
  async savePlan() {
    const data = {
      certifications: this.state.certifications
    };
    const ep = `${process.env.REACT_APP_API}/partners/users/update-user-info/${this.props.match.params.id}`;
    const results = await axios.post(ep, data);

    if (results.data.success) {
      this.props.update(results.data.data.certifications);
      this.props.onClose();

      this.setState({
        data: results.data.data
      });
    }
  }

  componentDidMount() {
    this.setState({
      ...JSON.parse(
        this.props.certifications[this.props.selectedCertification]
      ),
      certifications: this.props.certifications.map(e => JSON.parse(e))
    });
  }

  async fileChangeDoc(e) {
    if (e.target.files.length && e.target.files[0]) {
      const fd = new FormData();
      fd.set("image", e.target.files[0]);
      const ep = `${process.env.REACT_APP_API}/partners/users/document`;
      this.setGlobal({ loading: true });
      const result = await axios.post(ep, fd);
      this.setGlobal({ loading: false });
      if (result.data.success) {
        const newCert = this.state.certifications;
        newCert[this.props.selectedCertification].file = result.data.data;

        this.setState({
          certifications: [...newCert]
        });
      }
    }
  }

  documentModal = () => {
    return (
      <div
        className="form-group"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: 0
        }}
      >
        <label
          htmlFor="file"
          style={{
            width: "25%",
            textAlign: "left",
            fontSize: "1.15rem",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262"
          }}
        >
          Upload <br />
          <span
            style={{
              fontSize: "9px",
              fontFamily: "futura-pt, sans-serif",
              color: "#626262"
            }}
          >
            (max. 20mb)
          </span>
        </label>
        <div
          style={{
            display: "flex",
            marginLeft: "45px",
            fontFamily: "futura-pt, sans-serif"
          }}
        >
          <Button
            variant="contained"
            color="primary"
            component="span"
            startIcon={<CloudUpload />}
            fullWidth
            onClick={_ => {
              document.getElementById("uploadDocument").click();
            }}
          >
            Document
          </Button>
          {this.state.file && (
            <span
              style={{
                fontSize: "9px",
                color: "black",
                fontFamily: "futura-pt, sans-serif"
              }}
            >
              File Uploaded!
            </span>
          )}
        </div>

        <input
          type="file"
          style={{ display: "none" }}
          id="uploadDocument"
          onChange={e => this.fileChangeDoc(e)}
          accept="*"
        />
      </div>
    );
  };

  getBaseModalFields() {
    const categoryType = {
      type: DROPDOWN,
      data: {
        name: "Category",
        required: true,
        placeholder: "Select",
        value:
          this.state.certifications[this.props.selectedCertification].category,
        handleChange: e => {
          const newCert = this.state.certifications;
          newCert[this.props.selectedCertification].category = e.target.value;
          this.setState({
            certifications: [...newCert]
          });
        },
        choices: [
          { text: "First Aid", value: "First Aid" },
          { text: "CPR", value: "CPR" },
          { text: "Background Check", value: "Background Check" },
          { text: "Degree", value: "Degree" },
          { text: "Certificate", value: "Certificate" },
          { text: "Accreditation", value: "Accreditation" },
          { text: "Other", value: "Other" }
        ],
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      },
      validators: { validateSubmit: () => this.state.certifications[this.props.selectedCertification].category !== "" }
    };

    const otherInput = {
      type: STRING_INPUT,
      data: {
        name: "",
        required: false,
        value:
          this.state.certifications[this.props.selectedCertification].other,
        handleChange: e => {
          const newCert = this.state.certifications;
          newCert[this.props.selectedCertification].other = e.target.value;

          this.setState({
            certifications: [...newCert]
          });
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const titleInput = {
      type: STRING_INPUT,
      data: {
        name: "Title",
        required: true,
        value:
          this.state.certifications[this.props.selectedCertification].title,
        handleChange: e => {
          const newCert = this.state.certifications;
          newCert[this.props.selectedCertification].title = e.target.value;

          this.setState({
            certifications: [...newCert]
          });
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      },
      validators: { validateSubmit: () => this.state.certifications[this.props.selectedCertification].title !== "" }
    };

    const detailsInput = {
      type: TEXT_AREA,
      data: {
        name: "Details",
        required: false,
        value:
          this.state.certifications[this.props.selectedCertification].details,
        handleChange: e => {
          const newCert = this.state.certifications;
          newCert[this.props.selectedCertification].details = e.target.value;

          this.setState({
            certifications: [...newCert]
          });
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const documentInput = {
      type: GET_HTML,
      data: {
        gethtml: this.documentModal
      }
    };

    const fields = [];
    fields.push(categoryType);
    if (
      this.state.certifications[this.props.selectedCertification].category ===
      "Other"
    ) {
      fields.push(otherInput);
    }
    fields.push(titleInput);
    fields.push(detailsInput);
    fields.push(documentInput);

    return fields;
  }

  getBaseModalButtons = () => {
    const submitButton = {
      name: "Edit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.savePlan()
    };

    return [submitButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Edit Certification",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: _ => this.props.onClose()
    };
  };

  render() {
    if (this.state.certifications.length === 0) {
      return null;
    }
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px",
                marginBottom: ".5rem"
              }}
            >
              <h3>Edit Certification</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="category"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Category <span style={{ color: "orange" }}>*</span>
                </label>
                <select
                  name="category"
                  id="category"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    const newCert = this.state.certifications;
                    newCert[this.props.selectedCertification].category =
                      e.target.value;

                    this.setState({
                      certifications: [...newCert]
                    });
                  }}
                  value={
                    this.state.certifications[this.props.selectedCertification]
                      .category
                  }
                >
                  {categories.map((e, i) => {
                    return (
                      <option key={i} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {this.state.certifications[this.props.selectedCertification]
                .category === "Other" && (
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="other"
                    style={{ width: "25%", textAlign: "left" }}
                  >
                    {""}
                  </label>
                  <input
                    name="other"
                    id="other"
                    type="text"
                    className="form-control"
                    style={{ width: "70%" }}
                    onChange={e => {
                      const newCert = this.state.certifications;
                      newCert[this.props.selectedCertification].other =
                        e.target.value;

                      this.setState({
                        certifications: [...newCert]
                      });
                    }}
                    value={
                      this.state.certifications[
                        this.props.selectedCertification
                      ].other
                    }
                  />
                </div>
              )}
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="title"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Title <span style={{ color: "orange" }}>*</span>
                </label>
                <input
                  name="title"
                  id="title"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    const newCert = this.state.certifications;
                    newCert[this.props.selectedCertification].title =
                      e.target.value;

                    this.setState({
                      certifications: [...newCert]
                    });
                  }}
                  value={
                    this.state.certifications[this.props.selectedCertification]
                      .title
                  }
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="details"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Details
                </label>
                <textarea
                  rows="4"
                  name="details"
                  id="details"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    const newCert = this.state.certifications;
                    newCert[this.props.selectedCertification].details =
                      e.target.value;

                    this.setState({
                      certifications: [...newCert]
                    });
                  }}
                  value={
                    this.state.certifications[this.props.selectedCertification]
                      .details
                  }
                />
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="file"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Upload <br />
                  <span style={{ fontSize: "9px" }}>(max. 20mb)</span>
                </label>
                <div style={{ display: "flex", marginLeft: "15px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CloudUpload />}
                    fullWidth
                    onClick={_ => {
                      document.getElementById("uploadDocument").click();
                    }}
                  >
                    Document
                  </Button>
                  {this.state.certifications[this.props.selectedCertification]
                    .file && (
                    <span style={{ fontSize: "9px", color: "black" }}>
                      File Uploaded!
                    </span>
                  )}
                </div>

                <input
                  type="file"
                  style={{ display: "none" }}
                  id="uploadDocument"
                  onChange={e => this.fileChangeDoc(e)}
                  accept="*"
                />
              </div>
            </form>

            <div className="modal-footer">
              <button
                type="submit"
                onClick={_ => this.savePlan()}
                className="btn profile-btn"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditCertificationsModal);
