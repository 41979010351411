import React from "reactn";
import { Link } from "react-router-dom";
import { DdIcon } from "../../../assets";
import { ArrowRight, ArrowLeft } from "@material-ui/icons";
import { format } from "date-fns";
import { Modal } from "../../../components/UI";
import Axios from "axios";
import { Icon, IconButton } from "@material-ui/core";
class RecentDeposits extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalData: null,
      page: 0
    };
  }
  closeModal() {
    this.setState({
      modalData: null,
      modalOpen: false
    });
  }
  async setData(payout) {
    this.setGlobal({
      loading: true
    });
    const ep = this.props.id
      ? `${process.env.REACT_APP_API}/edu/payout/breakdown/${payout.id}/${this.props.id}`
      : `${process.env.REACT_APP_API}/edu/payout/breakdown/${payout.id}`;
    const results = await Axios.get(ep);
    if (results.data.success) {
      this.setGlobal({
        loading: false
      });
      this.props.setDeposit({ ...payout, transactions: results.data.data });
    }
  }
  render() {
    return (
      <div className="col-xl-6 col-xl-6-new">
        {this.state.modalData && (
          <Modal
            open={this.state.modalOpen}
            heading={"Payout Details"}
            onClose={_ => this.closeModal()}
          ></Modal>
        )}
        <div
          className="tablebox"
          style={{ justifyContent: "flex-start", height: "375px" }}
        >
          <div className="tableheader">
            <h2 style={{ flexGrow: 1 }}>
              ALL RECENT DEPOSITS <img src={DdIcon} alt="" />
            </h2>
            <IconButton
              disabled={this.state.page === 0}
              onClick={e =>
                this.setState({
                  page: this.state.page - 1
                })
              }
            >
              <ArrowLeft />
            </IconButton>
            <IconButton
              disabled={
                this.props.earnings.slice(
                  6 * this.state.page,
                  6 * (this.state.page + 1)
                ).length < 6
              }
              onClick={e =>
                this.setState({
                  page: this.state.page + 1
                })
              }
            >
              <ArrowRight />
            </IconButton>
          </div>
          <div className="tablebody" style={{ padding: "5px 18px" }}>
            <div className="table-responsive">
              <table className="w-100">
                <thead>
                  <tr
                    style={{ color: "#333", borderBottom: "1px solid #ececec" }}
                  >
                    <th className="p-2">Deposited</th>
                    <th className="p-2">Amount</th>
                    <th className="p-2">Service Period</th>
                    <th className="p-2">Status</th>
                    <th className="p-2">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.earnings.length ? null : (
                    <tr>
                      <td colSpan="5" className="p-5 text-center">
                        Nothing to show...{" "}
                        <span style={{ fontStyle: "italic" }}>Yet!</span>
                      </td>
                    </tr>
                  )}
                  {this.props.earnings
                    .slice(6 * this.state.page, 6 * (this.state.page + 1))
                    .map((e, i) => (
                      <tr key={i}>
                        <td className="p-2">
                          {e.arrival_date
                            ? format(
                                new Date(e.arrival_date * 1000),
                                "LLLL d, yyyy"
                              )
                            : " - "}
                        </td>
                        <td className="p-2">
                          $
                          {e.amount
                            ? parseFloat(e.amount / 100).toFixed(2)
                            : "0.00"}
                        </td>
                        <td className="p-2">
                          {e.arrival_date
                            ? `${format(
                                new Date(
                                  e.arrival_date * 1000 - 3600000 * 24 * 7
                                ),
                                "LLL d"
                              )} - ${format(
                                new Date(e.arrival_date * 1000),
                                "LLL d, yyyy"
                              )}`
                            : " - "}
                        </td>
                        <td className="p-2">
                          {e.status === "paid" ? (
                            <span
                              style={{
                                backgroundColor: "#1bc88a",
                                borderRadius: "13px",
                                padding: "3px 10px 3px 10px",

                                color: "#FFFFFF",
                                display: "inline-block",
                                textAlign: "center"
                              }}
                            >
                              {e.status[0].toLocaleUpperCase() +
                                e.status.slice(1)}
                            </span>
                          ) : (
                            <span
                              style={{
                                backgroundColor: "#ff3b8e",
                                borderRadius: "13px",
                                padding: "3px 10px 3px 10px",

                                color: "#FFFFFF",
                                display: "inline-block",
                                textAlign: "center"
                              }}
                            >
                              {e.status[0].toLocaleUpperCase() +
                                e.status.slice(1)}
                            </span>
                          )}
                        </td>
                        <td className="p-2" onClick={_ => this.setData(e)}>
                          <a href="#/" style={{ padding: 0 }}>
                            View Details
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecentDeposits;
