import React from "react";
import LineChart from "../../../../components/charts/Line";
import { NotImg4 } from "../../../../assets";
import { isBefore, isAfter, subDays, addDays, format } from "date-fns";
class Transactions extends React.PureComponent {
  constructor (props) {
    super(props);
  }

  // including start, excluding end
  getNumberBetweenDates(start, end, arr) {
    let number = 0;
    arr.forEach(e => {
      if (isBefore(new Date(e.date), new Date(end)) && isAfter(new Date(e.date), subDays(new Date(start), 1))) {
        number += 1;
      }
    });
    return number;
  }

  newGroupByWeek = transactions => {
    let date = transactions[0].date;
    let endDate = transactions[transactions.length - 1].date;
    const result = [];
    while (isBefore(new Date(date), addDays(new Date(endDate), 7))) {
      const number = this.getNumberBetweenDates(
        date,
        addDays(new Date(date), 7),
        transactions
      );
      const formattedDate = format(new Date(date), "LLL dd");
      result.push({ date: formattedDate, number: parseInt(number) });
      date = addDays(new Date(date), 7);
    }

    return result;
  }; 
  

  render() {
    let chart;
    let transactions = [...this.props.transactions];
    transactions.sort((a, b) => new Date(a.date) - new Date(b.date));

    if (transactions.length > 0) {
      chart = this.newGroupByWeek(transactions);
    }

    const total = chart ? chart.reduce((prev, curr) => prev + curr.number, 0) : 0;

    return (
      <div className="col-xl-6 col-xl-6-new">
        <div className="tablebox">
          <div className="tableheader">
            <h2>
              TRANSACTIONS
              <img src={NotImg4} alt="My Earnings" className="imgfix" />
            </h2>
          </div>
          <div className="tablebody padded">
            <h2 className="graph-header">
              <span>{total.toLocaleString()}</span> Total {"    "}
            </h2>
            <div className="chart-area">
              {chart ? (
                <LineChart
                  mapData={chart.map(e => e.number)}
                  labels={chart.map(e => e.date)}
                  noYAxesSymbol={true}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Transactions;