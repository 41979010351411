import React from "reactn";
import { TabbedTable } from "../../components/UI";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import axios from "axios";
import FReg from "../../components/table/admins/FRegistrationModal";
import CancelModal from "../../components/modals/CancelModal";
import Reschedule from "../../components/modals/RescheduleModal";
class TourRequests extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        "MY TOUR REQUESTS": []
      }
    };

    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "Tour Requests"]
    });
    this.tabs = ["MY TOUR REQUESTS"];
    this.headings = {
      "MY TOUR REQUESTS": [
        { id: "displayName", label: "Parent" },
        {
          id: "requestedTourDate",
          label: "Requested Date",
          customCell: this.dateCell
        },
        {
          id: "accepted",
          label: "Accepted Request?",
          customCell: this.acceptedCell
        },
        {
          id: "details",
          label: "",
          customCell: this.details
        },
        {
          id: "reschedule",
          label: "",
          customCell: this.rescheduleTourCell,
          customStyle: { width: 180 }
        },
        {
          id: "accept",
          label: "",
          customCell: this.acceptNow,
          customStyle: { width: 180 }
        }

        /*{
          id: "cancel",
          label: "",
          customCell: this.cancelTourCell,
          customStyle: { width: 180 }
        }*/
      ]
    };
  }
  acceptTour = async r => {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/edu/tour/accept`;
    const result = await axios.post(ep, {
      ...r
    });
    this.setGlobal({ loading: false });
    if (result.data.success) {
      this.setState({
        data: {
          "MY TOUR REQUESTS": this.state.data["MY TOUR REQUESTS"].map(e =>
            e.id === result.data.data.id
              ? { ...e, accepted: result.data.data.accepted }
              : e
          )
        }
      });
    }
  };

  cancelTourCell = (r, i) => {
    if (new Date(r.requestedTourDate).getTime() > Date.now() && !r.canceled) {
      return (
        <td key={`${i}-btn`}>
          <button
            onClick={() =>
              this.setState({ cancelModalOpen: true, tourCancel: r })
            }
          >
            CANCEL TOUR
          </button>
        </td>
      );
    }
    return <td key={`${i}-btn`} colSpan={1}></td>;
  };
  rescheduleTourCell = (r, i) => {
    if (new Date(r.requestedTourDate).getTime() > Date.now() && !r.canceled) {
      return (
        <td key={`${i}-btn`}>
          <button
            onClick={() =>
              this.setState({ rescheduleModalOpen: true, tourReschedule: r })
            }
          >
            RESCHEDULE TOUR
          </button>
        </td>
      );
    }
    return <td key={`${i}-btn`} colSpan={1}></td>;
  };
  acceptNow = (r, i) => {
    if (
      new Date(r.requestedTourDate).getTime() > Date.now() &&
      !r.accepted &&
      !r.canceled &&
      parseInt(r.rescheduledBy) !== this.global.dsUser.id
    ) {
      return (
        <td key={`${i}-btn`}>
          <button onClick={() => this.acceptTour(r)}>ACCEPT TOUR</button>
        </td>
      );
    }
    return <td key={`${i}-btn`} colSpan={1}></td>;
  };

  acceptedCell = (r, i) => {
    return (
      <td key={`${i}-${r.id}-acc`}>
        {r.canceled
          ? `Canceled by : ${r.canceledBy}`
          : r.accepted
          ? "Tour Accepted"
          : Date.now() > new Date(r.requestedTourDate).getTime()
          ? "Tour Expired"
          : "Pending Acceptation"}
      </td>
    );
  };
  details = (r, i) => {
    return (
      <td key={`${i}-${r.id}-det`}>
        <button
          onClick={() =>
            this.setState({ detailsModalShown: true, modalData: r })
          }
        >
          View Details
        </button>
      </td>
    );
  };

  dateCell = (r, i) => {
    return (
      <td key={`${i}-${r.id}`}>
        {format(new Date(r.requestedTourDate), "LLL dd, yyyy - h:mm a")}
      </td>
    );
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/par/tour/educator`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setState({
        data: {
          "MY TOUR REQUESTS": res.data.data
        }
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="cont">
          <div className="row">
            <div className="col-md-6">
              <h1>Tour Requests</h1>
            </div>
            <FReg
              open={this.state.detailsModalShown}
              data={this.state.modalData}
              onClose={() =>
                this.setState({
                  detailsModalShown: false,
                  modalData: null
                })
              }
            />
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp">
                  <Link
                    to="/educators/tour-requests/availability"
                    className="newapplications"
                  >
                    <img src="img/MyTourAvailability.svg" alt="" />
                    My Tour Availability
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cont">
          <TabbedTable
            tabs={this.tabs}
            headings={this.headings}
            data={this.state.data}
            // actions={this.actions}
          />
        </div>
        {this.state.cancelModalOpen && (
          <CancelModal
            tour={this.state.tourCancel}
            openSchedule={() => this.setState({ rescheduleModalOpen: true })}
            onClose={() => this.setState({ cancelModalOpen: false })}
          />
        )}
        {this.state.rescheduleModalOpen && (
          <Reschedule
            tour={this.state.tourReschedule}
            onClose={() => this.setState({ rescheduleModalOpen: false })}
          />
        )}
      </div>
    );
  }
}

export default TourRequests;
