import {
  NotImg1,
  Reporting8 as Check, // Green Check
  Reporting7 as Browser, // Browser Window
  Reporting2 as Team,
  Letter,
  Heart,
  Bill,
  LiveView1 as Clock, // Clock
  Reporting10 as Messaging // Messaging
} from "../../../assets";
import Milestone from "../../../assets/img/MilestoneSubmission.png";

/**
 * Gets notification header and body of a single notification based on the activites.
 *
 * An array is passed because multiple activites can share the same notificaiton slot.
 *
 * Generally displayed in the form below.
 *
 * 					<actor(s)> 					<verb> 							<object>.
 * E.g - John and 1 other || enrolled in || a program: "My Fun Daycare"
 *
 * @param {Array<Object>} activities Array of activites fetched from getStream part of one group.
 */
const getNotificationText = activities => {
  const actors = [];

  const [latestActivity, ...rest] = activities;

  // Compiles all dsUser names involved in the activity.
  activities.forEach(activity => {
    if (activity.dsUserData && activity.dsUserData.displayName) {
      actors.push(activity.dsUserData.displayName);
    }
  });

  const header = latestActivity.header?.startsWith("New Milestone Submission")
    ? `${latestActivity.header}: ${latestActivity.dsUserData?.displayName}`
    : latestActivity.header;

  const actorString = getActorString(actors);
  const { verbString } = parseVerb(latestActivity.verb);
  const objectString = getObjectString(
    latestActivity.object,
    latestActivity.objectData
  );

  let body = `${actorString} ${verbString} ${objectString}`;

  return { header, body };
};

/**
 *
 */
const getActorString = actors => {
  if (actors.length === 0) {
    return "You";
  }
  if (actors.length <= 1) {
    return actors[0];
  }
  if (actors.length <= 2) {
    return `${actors[0]} and 1 other`;
  }
  return `${actors[0]} and ${actors.length - 1} others`;
};

/**
 * Depending on the verb of activity, return appropriate verb string to use in sentence.
 * Additionally, return the image that should be used.
 */
const parseVerb = verb => {
  let verbString = "";
  let imageSrc = NotImg1;
  switch (verb) {
    case "created":
      verbString = "created";
      imageSrc = Browser;
      break;

    case "enrolled":
      verbString = "enrolled in";
      imageSrc = Bill;
      break;

    case "applied":
      verbString = "applied to";
      imageSrc = Letter;
      break;

    case "confirmed":
      verbString = "confirmed their enrollment for";
      imageSrc = Check;
      break;

    case "interested":
      verbString = "showed interest in your organization!";
      imageSrc = Heart;
      break;

    case "booked_appointment":
      verbString = "booked";
      imageSrc = Clock;
      break;

    case "joined_org":
      verbString = "joined your team!";
      imageSrc = Team;
      break;

    case "payment_failed":
      verbString = "has";
      imageSrc = Clock;
      break;

    case "sms_reply":
      verbString = "sent new message(s)!";
      imageSrc = Messaging;
      break;

    case "milestone_journal":
      verbString = "has submitted";
      imageSrc = Milestone;
      break;

    case "milestone_upload":
      verbString = "has submitted";
      imageSrc = Milestone;
      break;

    default:
      verbString = "...";
      break;
  }
  return { verbString, imageSrc };
};

/**
 * Get object string of notification body.
 *
 * @returns {String}
 *
 * @param {String} object GetStream object in the form "objectType:objectId"
 * @param {Object} objectData
 */
const getObjectString = (object, objectData) => {
  let [objectType, objectId] = object.split(":");

  objectType = objectType.toLowerCase();

  let objectString = "";
  try {
    switch (objectType) {
      case "appointment":
        const time = objectData["Appointment Time"];
        const dateString = time
          ? `for ${new Date(time).toLocaleDateString()}`
          : "";
        objectString = `an appointment ${dateString}!`;
        break;

      case "program":
        objectString = `a program: ${objectData.program_name}`;
        break;

      case "event":
        objectString = `an event: ${objectData.event_title}`;
        break;

      case "online":
        objectString = `an online program: ${objectData.program_name}`;
        break;

      case "membership":
        objectString = `a membership: ${objectData.membership_name}`;
        break;

      case "gift":
        objectString = `a $${objectData.amount} gift card.`;
        break;

      case "failedPayment":
        objectString = `a failed payment.`;
        break;

      case "milestone_journal":
        objectString = `a journal entry for Milestone: ${objectData.name}`;
        break;

      case "milestone_upload":
        objectString = `a file upload for Milestone: ${objectData.name}`;

      default:
        break;
    }
    return objectString;
  } catch (error) {
    return objectString;
  }
};

export { getNotificationText, parseVerb };
