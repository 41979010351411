import React from "reactn";
import { TabbedTable } from "../../components/UI";
import axios from "axios";
import { format } from "date-fns";
import FReg from "../../components/table/admins/FRegistrationModal";
import ComModal from "../../components/table/admins/CommentsModal";

class FamilyApplications extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      applications: {},
      programs: [],
      selectedParent: "",
      detailsModalShown: false,
      modalData: null
    };
    this.tabs = ["New", "Pending", "Accepted", "Waitlisted", "Rejected"];
    this.headings = {
      New: [
        { id: "parentname", label: "Parent Name" },
        { id: "programName", label: "PROGRAM" },
        { id: "fullName", label: "CHILD NAME" },
        {
          id: "enrollmentPeriod",
          label: "ENROLLMENT PERIOD",
          customCell: this.stepsCell
        },
        {
          id: "plan",
          label: "PRICING PLAN",
          customCell: this.lastActionCell
        },
        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        { id: "action", label: "ACTION" }
      ],
      Pending: [
        { id: "parentname", label: "Parent Name" },
        { id: "programName", label: "PROGRAM" },
        { id: "fullName", label: "CHILD NAME" },
        {
          id: "enrollmentPeriod",
          label: "ENROLLMENT PERIOD",
          customCell: this.stepsCell
        },
        {
          id: "plan",
          label: "PRICING PLAN",
          customCell: this.lastActionCell
        },
        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        { id: "action", label: "ACTION" }
      ],
      Accepted: [
        { id: "parentname", label: "Parent Name" },
        { id: "programName", label: "PROGRAM" },
        { id: "fullName", label: "CHILD NAME" },
        {
          id: "enrollmentPeriod",
          label: "ENROLLMENT PERIOD",
          customCell: this.stepsCell
        },
        {
          id: "plan",
          label: "PRICING PLAN",
          customCell: this.lastActionCell
        },
        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        { id: "action", label: "ACTION" }
      ],
      Waitlisted: [
        { id: "parentname", label: "Parent Name" },
        { id: "programName", label: "PROGRAM" },
        { id: "fullName", label: "CHILD NAME" },
        {
          id: "enrollmentPeriod",
          label: "ENROLLMENT PERIOD",
          customCell: this.stepsCell
        },
        {
          id: "plan",
          label: "PRICING PLAN",
          customCell: this.lastActionCell
        },
        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        { id: "action", label: "ACTION" }
      ],
      Rejected: [
        { id: "parentname", label: "Parent Name" },
        { id: "programName", label: "PROGRAM" },
        { id: "fullName", label: "CHILD NAME" },
        {
          id: "enrollmentPeriod",
          label: "ENROLLMENT PERIOD",
          customCell: this.stepsCell
        },
        {
          id: "plan",
          label: "PRICING PLAN",
          customCell: this.lastActionCell
        },
        {
          id: "childCount",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        { id: "action", label: "ACTION" }
      ]
    };

    this.actions = [
      { name: "View Details", action: this.viewDetail },
      { name: "Notes", action: this.viewComments }
    ];
    this.viewDetail = this.viewDetail.bind(this);
    this.filterData = this.filterData.bind(this);
  }
  viewComments = async row => {
    this.setState({ commentsModalShown: true, modalData: row });
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt((Date.now() - new Date(r.insys).getTime()) / (3600000 * 24))}
      </td>
    );
  };
  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/family/applications`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setState({
        applications: {
          New: res.data.data.applications.filter(
            app =>
              Date.now() - new Date(app.createdAt).getTime() <= 3600000 * 24
          ),
          Pending: res.data.data.applications.filter(
            app => app.educatorResponse === "New"
          ),
          Accepted: res.data.data.applications.filter(
            app => app.educatorResponse === "Accepted"
          ),
          Waitlisted: res.data.data.applications.filter(
            app => app.educatorResponse === "Waitlisted"
          ),
          Rejected: res.data.data.applications.filter(
            app => app.educatorResponse === "Rejected"
          )
        },
        original: res.data.data.applications
      });

      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  appStatusCell = (r, i) => {
    return (
      <td key={i}>
        <div
          className={
            r.educatorResponse === "Rejected"
              ? "red-circle"
              : r.depositPaid
              ? "green-circle"
              : "orange-circle"
          }
        ></div>
      </td>
    );
  };

  filterData = val => {
    this.setState({
      applications: {
        New: this.state.original.filter(
          parent =>
            Date.now() - new Date(parent.createdAt).getTime() <= 3600000 * 24 &&
            parent.parentname
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase())
        ),
        Pending: this.state.original.filter(
          parent =>
            parent.educatorResponse === "New" &&
            parent.parentname
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase())
        ),
        Accepted: this.state.original.filter(
          parent =>
            parent.educatorResponse === "Accepted" &&
            parent.parentname
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase())
        ),
        Waitlisted: this.state.original.filter(
          parent =>
            parent.educatorResponse === "Waitlisted" &&
            parent.parentname
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase())
        ),
        Rejected: this.state.original.filter(
          parent =>
            parent.educatorResponse === "Rejected" &&
            parent.parentname
              .toLocaleLowerCase()
              .includes(val.toLocaleLowerCase())
        )
      }
    });
  };

  lastActionCell = (r, i) => {
    return <td key={i}>{`${r.categoryName} - ${r.numDays} days / wk`}</td>;
  };

  stepsCell = (r, i) => {
    return (
      <td key={i}>
        {format(new Date(r.startDate), "LLL dd, yyyy")} -{" "}
        {r.endDate ? format(new Date(r.endDate), "LLL dd, yyyy") : "N/A"}
      </td>
    );
  };

  viewDetail = async row => {
    this.setState({ detailsModalShown: true, modalData: row });
  };

  render() {
    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          <div className="cont">
            <h1>Family Applications</h1>
          </div>
          <div className="body">
            <FReg
              open={this.state.detailsModalShown}
              data={this.state.modalData}
              onClose={() =>
                this.setState({
                  detailsModalShown: false,
                  modalData: null
                })
              }
            />
            <ComModal
              open={this.state.commentsModalShown}
              data={this.state.modalData}
              onClose={() =>
                this.setState({
                  commentsModalShown: false,
                  modalData: null
                })
              }
            />
            <div className="cont">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="table-responsive">
                    <input
                      type="text"
                      onChange={e => this.filterData(e.target.value)}
                      placeholder="Search for applications..."
                      style={{
                        marginBottom: 15,
                        width: "100%",
                        borderRadius: 5,
                        border: "2px solid #E3E6F0",
                        fontSize: "13px",
                        padding: 3,
                        outline: 0
                      }}
                    />
                    <TabbedTable
                      headings={this.headings}
                      data={this.state.applications}
                      actions={this.actions}
                      tabs={this.tabs}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FamilyApplications;
