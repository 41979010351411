import React from "reactn";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK } from "./baseModal/FieldTypes";

const AppointmentDetailsModal = ({ message, onClose }) => {
  const getBaseModalProps = () => {
    return {
      title: "Appointment Details",
      fields: [
        {
          type: CONTENT_BLOCK,
          data: {
            content: (message ? message : "(No message was given)"),
            name: "Message"
          }
        }],
      buttons: [],
      height: "40vh",
      midSectionHeight: "40vh",
      handleClose: () => onClose()
    };
  };

  return (
    <BaseModal {...getBaseModalProps()} />
  );
};

export default AppointmentDetailsModal;
