import React from "reactn";
import ChildProfile from "./forms/ChildProfile";

class ProfileChildrenPage extends React.PureComponent {
  handChange = e => {
    const children = this.global.children.map(c => (c.id === e.id ? e : c));
    this.setGlobal({ children: children });
  };

  handRemove = e => {
    this.dispatch.removeChildFromFamilyProfile(e);
  };

  render() {
    return (
      <div className="cont boxes-row mt-50px">
        {this.global.children.map((child, i) => (
          <ChildProfile
            key={i}
            child={child}
            childNo={i}
            onChange={this.handChange}
            onRemove={this.handRemove}
          />
        ))}
        <button
          className="addchild"
          onClick={() => this.dispatch.addChildToFamilyProfile()}
        >
          <h3>Add Child</h3>
        </button>
      </div>
    );
  }
}

export default ProfileChildrenPage;
