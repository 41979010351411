import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { formTypes } from "../../components/UI/form";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
class ResetPasswordModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      oldpass: "",
      newpass: "",
      confpass: ""
    };
    this.required = [
      { name: "oldpass", validation: e => !e },
      { name: "newpass", validation: e => !e },
      { name: "confpass", validation: e => !e }
    ];
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async submitForm(e) {
    e.preventDefault();
    let cancel = false;
    for (let requiredField of this.required) {
      if (requiredField.validation(this.state[requiredField.name])) {
        document.getElementsByName(requiredField.name)[0].style.boxShadow =
          "none";
        document.getElementsByName(requiredField.name)[0].style.border =
          "1px solid red";
        cancel = true;
      }
    }
    if (cancel) return;

    if (this.props.user.accountType === "Partner") {
      const endp = `${process.env.REACT_APP_API}/adm/partner/resetpassword`;
      const result = await axios.put(endp, this.state);
      if (result.data.success) {
        toast.success("Password Updated");
        this.props.onClose();
      } else {
        toast.error(result.data.message);
      }
    } else {
      const endp = `${process.env.REACT_APP_API}/adm/franchise/resetpassword`;
      const result = await axios.put(endp, this.state);
      if (result.data.success) {
        toast.success("Password Updated");
        this.props.onClose();
      } else {
        toast.error(result.data.message);
      }
    }
  }

  getBaseModalFields() {
    const oldPass = {
      type: STRING_INPUT,
      data: {
        name: "Old Password",
        placeholder: "Old Password...",
        type: "password",
        required: true,
        value: this.state.oldpass,
        handleChange: e => {
          this.setState({
            oldpass: e.target.value
          });
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      },
      validators: { validateSubmit: () => this.state.oldpass !== "" }
    };

    const newPass = {
      type: STRING_INPUT,
      data: {
        name: "New Password",
        type: "password",
        placeholder: "New Password...",
        required: true,
        value: this.state.newpass,
        handleChange: e => {
          this.setState({
            newpass: e.target.value
          });
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      },
      validators: { validateSubmit: () => this.state.newpass !== "" }
    };

    const confPass = {
      type: STRING_INPUT,
      data: {
        name: "Confirm Password",
        type: "password",
        placeholder: "Confirm Password...",
        required: true,
        value: this.state.confpass,
        handleChange: e => {
          this.setState({
            confpass: e.target.value
          });
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      },
      validators: { validateSubmit: () => this.state.confpass !== "" }
    };

    const fields = [];
    fields.push(oldPass);
    fields.push(newPass);
    fields.push(confPass);

    return fields;
  }

  getBaseModalButtons = () => {
    const submitButton = {
      name: "Save",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.submitForm(e)
    };

    return [submitButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Reset Password",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: _ => this.props.onClose(),
      width: "500px"
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div>
            <h3>Reset Password</h3>
            <form onSubmit={e => this.submitForm(e)}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Old Password *
                </label>
                <input
                  name="oldpass"
                  id="displayName"
                  type="password"
                  className="form-control"
                  placeholder="Old Password..."
                  style={{ width: "70%", border: "1px solid gainsboro" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  New Password *
                </label>
                <input
                  name="newpass"
                  type="password"
                  className="form-control"
                  placeholder="New Password..."
                  style={{ width: "70%", border: "1px solid gainsboro" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Confirm Password *
                </label>
                <input
                  name="confpass"
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password..."
                  style={{ width: "70%", border: "1px solid gainsboro" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  onClick={this.cancelThis}
                  className="btn profile-btn"
                >
                  Save
                </button>
              </div>
            </form>

            <IconButton
              style={{ position: "absolute", right: "0", top: "0" }}
              onClick={_ => this.props.onClose()}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPasswordModal);
