import React from "reactn";

const TuitionPaymentOverview = ({ values }) => {
  return (
    <div className="money-box mt-0">
      <div className="row">
        <div className="col-lg-12 col-sm-6">
          <div className="money-box-inner">
            <h2>RECENT PAYMENT</h2>
            <h3>${parseFloat(values.amtRecent).toFixed(2)}</h3>
          </div>
        </div>
        <div className="col-lg-12 col-sm-6">
          <div className="money-box-inner">
            <h2>NEXT PAYMENT</h2>
            <h3>${parseFloat(values.amtNext).toFixed(2)}</h3>
          </div>
        </div>
        <div className="col-lg-12 col-sm-6">
          <div className="money-box-inner">
            <h2>AMOUNT PENDING</h2>
            <h3>${parseFloat(values.amtPending || 0).toFixed(2)}</h3>
          </div>
        </div>
        <div className="col-lg-12 col-sm-6">
          <div className="money-box-inner">
            <h2>AMOUNT OVERDUE</h2>
            <h3>${parseFloat(values.amtOverdue).toFixed(2)}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TuitionPaymentOverview;
