import React, { useState, useEffect, useGlobal } from "reactn";
import { toast } from "react-toastify";

import "./reporting.css";
import {
  getReportsAndAnalyticsPermissions,
  getReportingDataFromPermissions
} from "./utils";
import ReportingCard from "./ReportingCard";

const Reporting = () => {
  const [permissions, setPermissions] = useState([]);
  const [ReportingData, setReportingData] = useState([]);
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");

  const initalPermissions = async () => {
    const thePermissions = await getReportsAndAnalyticsPermissions();
    if (thePermissions.success) {
      const setData = thePermissions.data;

      setPermissions(setData);

      const reportingcardsdata = getReportingDataFromPermissions(setData);
      setReportingData(reportingcardsdata);
    } else {
      toast.error(thePermissions.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };
  useEffect(() => {
    initalPermissions();
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" }
    ]);
  }, []);

  // useEffect(() => {

  // }, [permissions]);

  return (
    <div className="admin">
      <div className="container-fluid adminprofiles">
        <div className="row cont">
          <div className="col-md-6">
            <h1>Reporting</h1>
          </div>
        </div>
        <div className="row cont">
          <div className="reporting-grid-cards">
            {ReportingData.map(card => {
              return <ReportingCard key={card.title} card={card} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reporting;
