import React from "react";
// import { Select } from "../../../components/UI";
import LineChart from "../../../components/charts/Line";
// import { ArrowDownSelect } from "../../../assets";
import { NotImg4 } from "../../../assets";
import { format } from "date-fns";

class EarningOverview extends React.PureComponent {
  render() {
    const { earnings, total } = this.props;
    return (
      <div className="col-xl-6 col-xl-6-new">
        <div className="tablebox">
          <div className="tableheader">
            <h2>
              EARNINGS OVERVIEW
              <img src={NotImg4} alt="My Earnings" className="imgfix" />
            </h2>
            {/* <div className="col-md-5">
                <div className="select">
                  <Select
                    name="filter"
                    options={[
                      { label: "By Year:", value: 0 },
                      { label: "1", value: 1 },
                      { label: "2", value: 2 }
                    ]}
                  />

                  <img
                    src={ArrowDownSelect}
                    className="arrowdownselect"
                    alt=""
                  />
                </div>
              </div> */}
          </div>
          <div className="tablebody padded">
            <h2 className="graph-header">
              <span>${total ? parseFloat(total || 0).toFixed(2) : 0}</span>{" "}
              Cumulative
            </h2>
            <div className="chart-area">
              {earnings ? (
                <LineChart
                  mapData={earnings.map(e =>
                    e.amount ? parseFloat(e.amount / 100).toFixed(2) : 0
                  )}
                  labels={earnings.map(e => e.date)}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EarningOverview;
