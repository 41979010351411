import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import BaseModal from "./baseModal/BaseModal";
import { TITLE } from "./baseModal/FieldTypes";
import { RED_BUTTON, WHITE } from "./baseModal/colours";
import { toast } from "react-toastify";

class CancelProgramModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  getBaseModalFields = () => {
    const titleField = {
      type: TITLE,
      data: {
        name: `Are you sure you want to cancel your ${this.state.name}? This will cancel all your enrollments and applications.`
      }
    };

    const fields = [];

    fields.push(titleField);

    return fields;
  };

  getBaseModalButtons = () => {
    const cancelButton = {
      name: "Submit Cancellation",
      buttonColour: RED_BUTTON,
      textColour: WHITE,
      handleClick: () => this.cancelThis()
    };
    return [cancelButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Cancel " + this.state.name + "?",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: () => this.props.onClose()
    };
  };

  cancelThis = async () => {
    let type;
    switch (this.props.table) {
      case "partner_programs":
        type = "programs";
        break;
      case "partner_event":
        type = "events";
        break;
      case "partner_memberships":
        type = "memberships";
        break;
      case "partner_online":
        type = "virtual";
        break;
      case "partner_party":
        type = "parties";
        break;
    }

    const ep = `${process.env.REACT_APP_API}/partners/${type}/cancel/${this.props.data.id}`;
    
    const result = await axios.post(ep);
    if (result.data.success) {
      // this.props.success();
      this.props.onClose();
      toast.success(`${this.state.name} cancelled successfully.`);
    }
  };

  render() {
    if (this.props.table === "partner_programs") {
      this.setState({
        name: "Program"
      });
    }
    if (this.props.table === "partner_event") {
      this.setState({
        name: "Event"
      });
    }
    if (this.props.table === "partner_online") {
      this.setState({
        name: "Virtual Program"
      });
    }
    if (this.props.table === "partner_memberships") {
      this.setState({
        name: "Membership"
      });
    }

    if (this.props.table === "partner_party") {
      this.setState({
        name: "Party"
      });
    }

    return (
      // <div className="request-modal-cont">
      //   <div className="request-modal lesspadding">
      //     <div style={{ display: "block" }}>
      //       <img
      //         src={ModalBanner}
      //         alt="Modal Banner"
      //         style={{
      //           height: "40px",
      //           width: "100%",
      //           position: "absolute",
      //           top: 0,
      //           left: 0
      //         }}
      //       />
      //       <div
      //         style={{
      //           display: "flex",
      //           justifyContent: "space-between",
      //           alignItems: "center",
      //           height: "48px",
      //           marginBottom: ".5rem"
      //         }}
      //       >
      //         <h3>
      //           {this.props.table === "partner_programs" && "Cancel Program?"}
      //           {this.props.table === "partner_event" && "Cancel Event?"}
      //           {this.props.table === "partner_online" &&
      //             "Cancel Virtual Program?"}
      //           {this.props.table === "partner_memberships" &&
      //             "Cancel Memberships?"}
      //         </h3>
      //         <IconButton
      //           style={{
      //             borderRadius: "50%",
      //             color: "#2880FF",
      //             marginBottom: "1rem"
      //           }}
      //           onClick={_ => this.props.onClose()}
      //         >
      //           <Close />
      //         </IconButton>
      //       </div>
      //       <p>
      //         Are you sure you want to cancel your {Name}? This will cancel all
      //         your enrollments and applications
      //       </p>
      //       <div className="modal-footer">
      //         <button
      //           type="submit"
      //           onClick={_ => this.cancelThis()}
      //           className="btn profile-btn"
      //         >
      //           Submit Cancellation
      //         </button>
      //         {/* <button
      //           onClick={_ => this.props.onClose()}
      //           className="mx-2 backbtn btn profile-btn"
      //         >
      //           Close
      //         </button> */}
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <BaseModal {...this.getBaseModalProps()} />
    );
  }
}

export default withRouter(CancelProgramModal);
