import React from "reactn";
import SectionFive from "./forms/SectionFive";
import SectionEight from "./forms/SectionEight";
import SectionNine from "./forms/SectionNine";
import "moment-timezone";  //not sure if I need to import this, but it is used below in the form of "moment.tz"
import moment from "moment";
import Select from "react-dropdown-select";
import "../../../assets/css/componentSpecificCss/timeZonePicker.css";



class Setup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTZ: undefined,
      allTimezones: [],
    };
  }

  getAllTimezones() {
    console.info("getAllTimezones() called!");
    const timestampNow = Date.now();
    const tzNames = moment.tz.names();
    const allTimezones = tzNames
      .map( tzName => {
        //@@FUTURE@@: Some test to determine if this timezone name is accepted by this browser or the date-time-calculations library!
        const zone = moment.tz.zone(tzName);
        const offsetMins = zone.utcOffset(timestampNow);
        const abbr = zone.abbr(timestampNow);

        const absOffsetMins = Math.abs(offsetMins);
        const prefix = ((offsetMins<0) ? "(UTC-" : "(UTC+")
          + Math.trunc(absOffsetMins/60).toString().padStart(2,"0")
          + ':'
          + (absOffsetMins%60).toString().padStart(2,"0")
          + ") ";

        //this filters out abbreviated names that are just offsets, eg. +04.
        const suffix = (/^[+\-]\d\d$/.test(abbr))  ?  ""  :  " - "+abbr;

        return {
          ianaName: tzName,
          displayName: prefix+tzName+suffix,
          offset: offsetMins,        //to be used for sort!
        }
      })
      .sort( (a,b)=>b.offset-a.offset );
    //allTimezones.forEach( e=> console.log(e.label));
    return allTimezones;
  }

  findAndOrCreateTimezone( tzToSearch, allTimezonesArr ) {
    if (!tzToSearch) return;

    let selectedTZ = allTimezonesArr.find( tz => tz.ianaName===tzToSearch );
    if (selectedTZ) return selectedTZ;

    selectedTZ = {
      ianaName: tzToSearch,
      displayName: "Unsupported Timezone: "+tzToSearch,
      //offset: undefined,
    }
    allTimezonesArr.unshift( selectedTZ );
    return selectedTZ;
  }


  componentDidMount() {
    const allTimezonesArr = this.getAllTimezones();
    console.assert( !!this.props.timezone, `this.props.timezone value is missing!`);
    const selectedTZ = this.findAndOrCreateTimezone( this.props.timezone, allTimezonesArr );
    this.setState({
      selectedTZ,        /* this.global.organizationInfo.timezone */
      allTimezones: allTimezonesArr,
    });
  }

  
  tzPickerOnChangeHandler = (newTZ) => {
    console.info(`onChangeHandler() => prev: ${this.state?.selectedTZ?.ianaName} => new: ${newTZ?.[0]?.ianaName}`);
    if (newTZ?.[0].ianaName !== this.state.selectedTZ?.ianaName) {
      this.setState({
        selectedTZ: newTZ,
      });
      //propagate the update back to the parent component!
      this.props.updateTimeZoneFn( newTZ?.[0].ianaName );
    }
  };

  tzPickerOnDropdownOpenHandler = (args)=>{
    /* NOTE: This is cheating!
       We bypass React and just find the selected item in the dropdown to bring it into view */
    document.querySelectorAll(".timezonepicker-container .react-dropdown-select-item-selected")
      .forEach( elem => {
        elem.scrollIntoView();
        //console.log("elem:",elem);
      });
  }


  render() {
    return (
      <div style={{ display: "block" }}>
        <div className="timezonepicker-row">
          <div className="timezone-section-header">
            <h2
              style={{ fontSize: "17px", fontWeight: "600", color: "#042470" }}
            >
              Timezone
            </h2>
          </div>
          <div className="timezone-content">
            <div className="timezonepicker-label">
              <h3 className="timezonepicker-title">
                Timezone<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="timezonepicker-container">

              {/* ====================
                  NOTE: There are a few different focus and focus-within pseudo-selectors in timeZonePicker.css
                  which allows us to style this SELECT component's internal text box. If this dropdown doesn't
                  work like it used to, it could be that the internals have changed when the package was updated.
                  ====================
                */}
              <Select
                options={this.state.allTimezones}
                values={(!this.state.selectedTZ ? [] : [this.state.selectedTZ])}
                placeholder="Select..."
                addPlaceholder="Select..."
                onChange={this.tzPickerOnChangeHandler}
                labelField={"displayName"}
                valueField={"ianaName"}
                required={true}
                searchable={true}
                searchBy={"displayName"}
                clearable={false}
                closeOnSelect={true}
                closeOnScroll={false}
                dropdownHandle={true}
                keepSelectedInList={true}
                multi={false}
                pattern={false}
                onDropdownOpen={this.tzPickerOnDropdownOpenHandler}
              />
              {/* ==================== END of Timezone picker dropdown ==================== */}

            </div>
          </div>
        </div>
        <div
          className="cont boxes-row mt-50px"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {/* <SectionFive vals={this.global.myApplicationProfileInfo} />
          <SectionEight /> */}
          <SectionNine />
        </div>
      </div>
    );
  }
}

export default Setup;
