import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
import axios from "axios";
import AddResource from "../../components/modals/AddResourcesModal";
import {
  Delete,
  SettingsBackupRestore as Restore,
  Create as Edit
} from "@material-ui/icons";

import ReactTooltip from "react-tooltip";
import ConfirmArchiveModal from "../../components/modals/ConfirmArchiveModal";
import EditResourcesModal from "../../components/modals/EditResourcesModal";

class Resources extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      filter: "",
      tours: [],
      original: [],
      showEditModal: false,
      moduleToEdit: {},
      showConfirmModal: false,
      toArchive: true,
      idToEdit: 0
    };

    this.tabs = ["Active", "Archive"];

    this.actions = [{ name: "Edit", action: null }];
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Resources", to: "/resources" }
      ]
    });
  }

  async componentDidMount() {
    this.fetchInitialData();
  }

  /**
   * Fetch (or re-fetch) the module data from the server.
   */
  fetchInitialData = async () => {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/resources/list`;
    const results = await axios.get(ep);

    if (results.data.success) {
      // Filter the modules by active/archived, and sort by update date.
      const activeModules = results.data.data
        .filter(module => !module.isArchived)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      const archiveModules = results.data.data
        .filter(module => module.isArchived)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      this.setState({
        data: {
          Active: activeModules,
          Archive: archiveModules
        }
      });
    }
    this.setGlobal({ loading: false });
  };

  headings = () => {
    return {
      Active: [
        { id: "module", label: "Modules" },
        {
          id: "program",
          label: "Program",
          customCell: (r, i) => (
            <td key={i}>
              {r.programschosen.map(e => e.program_name).join(", ")}{" "}
              {r.onlinechosen.map(e => e.program_name).join(", ")}
            </td>
          )
        },
        {
          id: "access",
          label: "Access",
          customCell: (r, i) => (
            <td key={i}>{r.userschosen.map(e => e.username).join(", ")}</td>
          )
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <div className="action-group">
                  <button
                    style={{ marginRight: "30px" }}
                    onClick={_ =>
                      this.props.history.push(
                        `/partners/resources-manage/${r.id}`
                      )
                    }
                  >
                    Manage
                  </button>
                  {!this.canSubUserEdit() && (
                    <>
                      <Edit
                        onClick={() => {
                          this.setState({
                            showEditModal: true,
                            moduleToEdit: r
                          });
                        }}
                        data-for="edit"
                        data-tip
                      />
                      <ReactTooltip
                        id="edit"
                        place="top"
                        effect="solid"
                        type="info"
                      >
                        Edit Module
                      </ReactTooltip>
                      <Delete
                        onClick={() => {
                          this.setState({
                            showConfirmModal: true,
                            idToEdit: r.id,
                            toArchive: true
                          });
                        }}
                        data-for="archive"
                        data-tip
                      />
                      <ReactTooltip
                        id="archive"
                        place="top"
                        effect="solid"
                        type="info"
                      >
                        Delete Module
                      </ReactTooltip>
                    </>
                  )}
                </div>
              </td>
            );
          }
        }
      ],
      Archive: [
        { id: "module", label: "Modules" },
        {
          id: "program",
          label: "Program",
          customCell: (r, i) => (
            <td key={i}>
              {r.programschosen.map(e => e.program_name).join(", ")}{" "}
              {r.onlinechosen.map(e => e.program_name).join(", ")}
            </td>
          )
        },
        {
          id: "access",
          label: "Access",
          customCell: (r, i) => (
            <td key={i}>{r.userschosen.map(e => e.username).join(", ")}</td>
          )
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                {!this.canSubUserEdit() && (
                  <div className="action-group">
                    <Restore
                      onClick={() => {
                        this.setState({
                          showConfirmModal: true,
                          idToEdit: r.id,
                          toArchive: false
                        });
                      }}
                      style={{ color: "#BBB" }}
                      data-for="restore"
                      data-tip
                    />
                    <ReactTooltip
                      id="restore"
                      place="top"
                      effect="solid"
                      type="info"
                    >
                      Restore Module
                    </ReactTooltip>
                  </div>
                )}
              </td>
            );
          }
        }
      ]
    };
  };

  /**
   * Makes request to backend to archive/unarchive module.
   * Uses state to determine options (what to delete, archive/unarchive).
   */
  archiveModule = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/resources/delete-module/${this.state.idToEdit}`;
    const results = await axios.post(ep, { toArchive: this.state.toArchive });

    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  /**
   * This function handles the edit button functionality of a sub user
   * returns a boolean value
   */
  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return this.global.dsUser.accesspoints.Manage.Resources.edit
        ? false
        : true;
    }
  };

  render() {
    return (
      <div className="admin">
        {this.state.resourceModal && (
          <AddResource
            onClose={_ => this.setState({ resourceModal: false })}
            onAdd={() => this.fetchInitialData()}
          />
        )}
        {this.state.showConfirmModal && (
          <ConfirmArchiveModal
            onSubmit={() => {
              this.archiveModule();
            }}
            onClose={_ => this.setState({ showConfirmModal: false })}
            toArchive={this.state.toArchive}
          />
        )}
        {this.state.showEditModal && (
          <EditResourcesModal
            module={this.state.moduleToEdit}
            onClose={_ => this.setState({ showEditModal: false })}
            onSave={() => this.fetchInitialData()}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>Resource Modules</h1>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp">
                  <button
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => this.setState({ resourceModal: true })}
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Module
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="tablecont">
              <div className="table-responsive">
                <NewTabbedTable
                  tabs={this.tabs}
                  headings={this.headings()}
                  data={this.state.data}
                  actions={this.actions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Resources;
