import React from "react";
import ProgramIntroForm from "./forms/programIntro";

class ProgramIntro extends React.PureComponent {
  render() {
    return (
      <div className="cont boxes-row mt-50px">
        <ProgramIntroForm />
      </div>
    );
  }
}
export default ProgramIntro;
