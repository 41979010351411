import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import { Switch } from "@material-ui/core";
import axios from "axios";
import Validators from "../../../../validators/validators";

const { isSelectedFormId } = Validators;

class SectionFour extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { days: {}, forms: [] };
  }
  handleChange = (e, v) => {
    this.setGlobal({
      membership: {
        ...this.global.membership,
        [e]: v
      }
    });
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/forms/list`;
    this.setGlobal({ loading: true });
    const programs = await axios.get(ep);

    this.setState({ forms: programs.data.data });
    this.setGlobal({ loading: false, lastAPICall: programs });
  }

  customForm = () => {
    const vals = this.global.myProgramLocationHours;

    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-5">
              <h3>
                Select Checkout Form<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <formTypes.Select
                options={[
                  { label: "Please Select", value: 0 },
                  ...this.state.forms.map((v, k) => ({
                    label: v.form_name + ` (${v.form_sub_name})`,
                    value: v.id
                  }))
                ]}
                value={this.global.membership.formId}
                onChange={v => this.handleChange("formId", v.target.value)}
              ></formTypes.Select>
            </div>
          </div>

          <div className="row">
            <div className="col-5">
              <h3>Checkout Form</h3>
            </div>
            <div className="col-7 d-flex">
              <button
                className="newapplications"
                style={{
                  border: "1px solid #7ea1ff",
                  padding: "6px 20px",
                  color: "#7ea1ff",
                  backgroundColor: "#FFF",
                  borderRadius: "15px",
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "700",
                  fontFamily: "Open Sans"
                }}
              >
                Preview
              </button>
              <button
                className="newapplications"
                style={{
                  border: "1px solid #7ea1ff",
                  padding: "6px 20px",
                  color: "#7ea1ff",
                  backgroundColor: "#FFF",
                  borderRadius: "15px",
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "700",
                  fontFamily: "Open Sans"
                }}
              >
                Edit
              </button>
            </div>
          </div>
          <div className="row">
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 style={{ margin: 0, padding: 0 }}>Advanced Option</h3>
            </div>
            <div
              className="col-7 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch checked={true} />
            </div>
          </div>
          <div className="row">
            <div
              className="col-4"
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "30px"
              }}
            >
              <h3 style={{ margin: 0, padding: 0 }}>
                Min Cancellation <br />
                (Prior to start date)
              </h3>
            </div>
            <div
              className="col-2 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input type="checkbox" />
            </div>
            <div
              className="col-5 d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <formTypes.Select
                options={new Array(14)
                  .fill(1)
                  .map((_, k) => ({ label: `${k + 1} days`, value: k }))}
              ></formTypes.Select>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Form Selection"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionFour;
