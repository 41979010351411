import React from "react";
import ModalFormLabel from "./ModalFormLabel";

import "./ModalFormGroup.css";

/**
 * Textarea Form Group component.
 *
 * @param label Display Label of the form group.
 * @param name Input attribute and label htmlFor attribute.
 *
 * @param tooltip Text of tooltip (optional).
 *
 * @param value Value of input element.
 * @param onChange OnChange handler of element.
 */

const ModalAreaGroup = ({
  label,
  name,
  tooltip,
  value,
  required,
  onChange,
  ...options
}) => {
  return (
    <div className="form-group modal-form-group">
      <ModalFormLabel
        label={label}
        name={name}
        required={required}
        tooltip={tooltip}
      />
      <textarea
        name={name}
        id={name}
        className={options.className || "form-control"}
        style={!options.style && { width: "70%" }}
        value={value}
        onChange={onChange}
        rows="4"
        columns="12"
        required={required}
        {...options}
      />
    </div>
  );
};

export default ModalAreaGroup;
