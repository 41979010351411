import React, { useState, useGlobal, useEffect } from "reactn";
import _ from "lodash";
import { DateTime } from "luxon";

import {
  useListings,
  useEnrollments,
  useForms
} from "../../../providers/reports";

import {
  ReportContainer,
  ReportTable,
  FilterButton
} from "../../../components/UI/reportsAnalytics";

const StudentReports = () => {
  const { programs, events, memberships, onlineVirtuals } = useListings();
  const forms = useForms();
  const orgData = useGlobal("organizationInfo")[0];
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");

  const [listingType, setListingType] = useState("Programs");
  const [selectedId, setSelectedId] = useState(-1);
  const [statusStage, setStatusStage] = useState("AllStatusStage");

  const [checkedColumns, setCheckedColumns] = useState([]);
  const [checkedCustom, setCheckedCustom] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [customHeaders, setCustomHeaders] = useState([]);

  const {
    programEnrollments,
    eventEnrollments,
    membershipEnrollments,
    onlineVirtualEnrollments
  } = useEnrollments();

  // Lookup table to provide some consistency between listing types.
  const lookupMap = {
    Programs: {
      listingArray: programs,
      nameProperty: "program_name",
      idProperty: "programId",
      enrollments: programEnrollments
    },
    Events: {
      listingArray: events,
      nameProperty: "event_title",
      idProperty: "eventId",
      enrollments: eventEnrollments
    },
    Memberships: {
      listingArray: memberships,
      nameProperty: "membership_name",
      idProperty: "membershipId",
      enrollments: membershipEnrollments
    },
    "Online Virtual": {
      listingArray: onlineVirtuals,
      nameProperty: "program_name",
      idProperty: "programId",
      enrollments: onlineVirtualEnrollments
    }
  };

  useEffect(() => {
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" },
      { label: "Participant Reports", to: "/reports/participant-reports" }
    ]);
  }, []);
  /**
   * Get headers for csv. Re-runs every time a new listing is selected.
   */
  useEffect(() => {
    const csvRegularHeaders = [
      { label: "Name", key: "displayName" },
      { label: "Email", key: "email" },
      { label: "Address", key: "address" },
      { label: "Phone", key: "homePhone" },
      { label: "City", key: "city" },
      { label: "Province", key: "province" },
      { label: "Country", key: "country" },
      { label: "Postal Code", key: "postalCode" },
      { label: "Submission", key: "createdAt" }
      // { label: "Application Status", key: "status_stage" }
    ];

    const csvChildHeaders = [
      { label: "User Name", key: "parentName" },
      { label: "Child Name", key: "childName" },
      { label: "Child Birth Date", key: "birthDate" },
      { label: "Email", key: "email" },
      { label: "Address", key: "address" },
      { label: "Phone", key: "homePhone" },
      { label: "City", key: "city" },
      { label: "Province", key: "province" },
      { label: "Country", key: "country" },
      { label: "Postal Code", key: "postalCode" },
      { label: "Submission", key: "createdAt" },
      { label: "Application Status", key: "status_stage" },
      // Child Details
      { label: "Child Gender", key: "gender" },
      { label: "Allergies", key: "allergies" },
      // { label: "Likes", key: "likes" },
      // { label: "Dislikes", key: "dislikes" },
      // { label: "Health Concerns", key: "healthConcerns" },
      // { label: "Immunized", key: "immunized" },
      // { label: "Languages", key: "languages" },
      // { label: "Potty Trained", key: "pottyTrained" },
      // { label: "Special Food", key: "specialFood" },
      { label: "Medication", key: "medication" }
      // { label: "Additional Comments", key: "comments" }
    ];
    // If no enrollments, avoid edge case altogether by simply returning.
    if (filteredEnrollments.length === 0) {
      setHeaders(csvRegularHeaders.map((e, i) => ({ ...e, listingId: i })));
    } else {
      setHeaders(
        (filteredEnrollments[0].childId
          ? csvChildHeaders
          : csvRegularHeaders
        ).map((e, i) => ({ ...e, listingId: i }))
      );

      let customHeaders = [];
      filteredEnrollments.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });

      const latestForm =
        filteredEnrollments.length > 0 ? filteredEnrollments[0].custom : [];
      let filteredForm = [];
      if (filteredEnrollments.length > 0) {
        const formId = filteredEnrollments[0].formId;

        forms.map(field => {
          if (
            field.formId === formId &&
            field.form_section_name === "Profile Information"
          ) {
            filteredForm.push(field);
          }
        });

        forms.map(field => {
          if (
            field.formId === formId &&
            field.form_section_name !== "Profile Information"
          ) {
            filteredForm.push(field);
          }
        });

        console.log("FORMS ORDER", forms, formId);
      }
      console.log("FILTERED FORM", filteredForm);

      filteredForm.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateA - dateB;
      });

      console.log("SORTED FILTERED FORM", filteredForm);
      // Add all the custom fields by iterating over entries of the "custom" property.
      filteredEnrollments.forEach((enrollment, index) => {
        const fieldsArr = [];
        if (index === 0) {
          const sectionFields = filteredForm.map(fieldList => {
            fieldList.fields.map(field => {
              fieldsArr.push(JSON.parse(field));
            });
          });
          console.log("SECTION FIELDS", sectionFields, fieldsArr);
          fieldsArr.forEach(label => {
            console.log("FIELD ORDER", label, label.first_label);
            // Object.entries(enrollment.custom).forEach(([key, value]) => {
            // if (key.indexOf(label.first_label) !== -1) {
            if (
              label.first_label !== "Full Name" &&
              label.first_label !== "Cell Phone" &&
              label.first_label !== "Email" &&
              label.first_label !== "Themes" &&
              label.first_label !== "Program: Code of Conduct agreement" &&
              label.first_label !== "Address" &&
              label.first_label !== "City" &&
              label.first_label !== "Region" &&
              label.first_label !== "Postal / Zip" &&
              label.first_label !== "Country"
            ) {
              const newKey = label.first_label.replace(/^\d+/, "");
              const keyExists = customHeaders.some(
                header => header.key === newKey
              );
              if (!keyExists) {
                customHeaders.push({
                  label: newKey,
                  key: newKey
                });
              }
            }
            // });
          });
        } else {
          Object.entries(enrollment.custom).forEach(([key, value]) => {
            const newKey = key.replace(/^\d+/, "");
            const keyExists = customHeaders.some(
              header => header.key === newKey
            );
            if (!keyExists) {
              customHeaders.push({
                label: newKey,
                key: newKey
              });
            }
          });
        }
      });

      setCustomHeaders(customHeaders.map((e, i) => ({ ...e, listingId: i })));
    }
    setCheckedColumns([]);
    setCheckedCustom([]);
  }, [selectedId]);

  /**
   * Gets the CSV for the CSVLink component to use.
   *
   * @returns {Array<Object>} Each property of each object corresponds to a column.
   */
  const getCsvData = () => {
    const csvRows = [];

    const enrollmentsCopy = getEnrollmentsCopy();

    enrollmentsCopy.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });

    enrollmentsCopy.forEach(row => {
      const csvRow = {};
      // For each column, add the appropriate value.
      combinedHeaders.forEach(column => {
        if (checkedColumns.includes(column) || checkedCustom.includes(column)) {
          csvRow[column.key] = getCellValue(row, column) || "";
        }
      });
      csvRows.push(csvRow);
    });
    return csvRows;
  };

  /**
   * Gets data for the embedded table. Similar to getCsvData except for return type.
   *
   * @returns {Array<Array>}
   */
  const getTableData = () => {
    const tableRows = [];

    const enrollmentsCopy = getEnrollmentsCopy();

    enrollmentsCopy.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
    enrollmentsCopy.forEach(row => {
      const csvRow = [];
      // For each column, add the appropriate value.
      combinedHeaders.forEach(column => {
        if (checkedColumns.includes(column) || checkedCustom.includes(column)) {
          csvRow.push(getCellValue(row, column) || "");
        }
      });
      tableRows.push(csvRow);
    });
    return tableRows;
  };

  /**
   * Given an enrollment (row) and a column with a "key" property, find the cell value.
   *
   * @returns {String}
   *
   * @param {Object} row Enrollment
   * @param {Object} column Must have a key property, representing the property to read from row.
   */
  const getCellValue = (row, column) => {
    let cellValue = row[column.key];

    // If the field is an array, join by comma and stringify.
    if (Array.isArray(cellValue)) {
      cellValue = cellValue.reduce((a, c, i) => {
        // On the first element, don't add a comma.
        if (i === 0) {
          return c.name;
        } else {
          return a + ", " + c.name;
        }
      }, "");
    }

    // Handle special cases where the data needs to be formatted/processed.
    switch (column.key) {
      // Format date fields properly.
      case "createdAt":
        cellValue = DateTime.fromISO(row[column.key]).toFormat("fff");
        break;

      case "birthDate":
        cellValue = DateTime.fromISO(row[column.key]).toFormat("yyyy, LLL dd");
        break;
      default:
        break;
    }
    return cellValue;
  };

  /**
   * Get a deep copy of the filtered Enrollment array.
   * Also, add some additional properties to make it easier to work with (childDetails and custom).
   *
   * @returns {Array}
   */
  const getEnrollmentsCopy = () => {
    if (filteredEnrollments.length === 0) return [];
    let flatEnrollments = _.cloneDeep(filteredEnrollments);

    // Flatten childDetails so that they are easier to access.
    if (flatEnrollments[0].childId) {
      flatEnrollments = flatEnrollments.map(enrollment => {
        return {
          ...enrollment,
          ...enrollment.childDetails,
          updatedAt: enrollment.updatedAt,
          createdAt: enrollment.createdAt
        };
      });
    }
    if (flatEnrollments[0].custom) {
      flatEnrollments = flatEnrollments.map(enrollment => {
        const customProperties = {};
        Object.entries(enrollment.custom).forEach(([key, value]) => {
          const newKey = key.replace(/^\d+/, "");
          customProperties[newKey] = value;
        });
        return { ...enrollment, ...customProperties };
      });
    }
    return flatEnrollments;
  };

  let listingArray, nameProperty, enrollments, idProperty;
  let selectedListing;

  if (listingType) {
    ({ listingArray, nameProperty, idProperty, enrollments } =
      lookupMap[listingType]);
    selectedListing = listingArray.find(listing => {
      return listing.id.toString() === selectedId;
    });
  }

  // Filters all the enrollments by the selected listing.
  const filteredEnrollments = enrollments.filter(enrollment => {
    return (
      enrollment[idProperty].toString() === selectedId &&
      (statusStage === "AllStatusStage" ||
        enrollment.status_stage === statusStage)
    );
  });

  // Combines custom + regular headers. Also filters by the dropdowns.
  const combinedHeaders = [
    ...headers.filter(header => checkedColumns.includes(header)),
    ...customHeaders.filter(header => checkedCustom.includes(header))
  ];

  let reportSubtitles = [orgData.organization_title];
  if (selectedListing) {
    reportSubtitles.push(`${selectedListing[nameProperty]}`);
  }

  return (
    <div className="admin">
      <div className="container-fluid adminprofiles">
        <div className="row cont">
          <div className="col-md-6">
            <h1>Participant Reports</h1>
          </div>
        </div>

        <div className="filter-container">
          <div
            className="filter-section"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="filter-item grid-new-col margin-left-offset grid-new-col">
              <label htmlFor="client_name" className="filter-label">
                Select Listing:
              </label>
              <select
                name="client_name"
                id="client_name"
                type="text"
                className="form-control filter-input"
                onChange={e => {
                  setListingType(e.target.value);
                  setSelectedId("-1");
                }}
              >
                <option value="Programs">Program</option>
                <option value="Events">Events</option>
                <option value="Memberships">Memberships</option>
                <option value="Online Virtual">Online Virtual</option>
              </select>
              <select
                name="program"
                id="program"
                type="text"
                className="form-control filter-input"
                onChange={e => {
                  setSelectedId(e.target.value);
                }}
                value={selectedId}
              >
                <option value="-1" selected disabled hidden>
                  Please Select...
                </option>
                {listingType &&
                  listingArray.map((listing, index) => {
                    return (
                      <option key={index} value={listing.id}>
                        {listing[nameProperty]}
                      </option>
                    );
                  })}
              </select>
              <select
                name="status_stage"
                id="status_stage"
                type="text"
                className="form-control filter-input"
                onChange={e => {
                  setStatusStage(e.target.value);
                }}
                value={statusStage}
              >
                <option value="AllStatusStage">All</option>
                <option value="Accepted_Paid">Accepted Paid</option>
                <option value="Accepted_Unpaid">Accepted Unpaid</option>
                <option value="New_Pending">New Pending</option>
                <option value="Waitlisted_Unpaid">Waitlisted Unpaid</option>
                <option value="Rejected">Rejected</option>
                <option value="Inactive">Inactive</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Cancelled_Unpaid">Cancelled Unpaid</option>
              </select>
            </div>
            <div
              className="filter-item grid-new-col margin-left-offset grid-new-col"
              style={{ marginLeft: "100px" }}
            >
              <FilterButton
                label="Select Columns..."
                checkedOptions={checkedColumns}
                setCheckedOptions={setCheckedColumns}
                allOptions={headers}
                stringProperty="label"
              />
              <FilterButton
                label="Custom Columns..."
                checkedOptions={checkedCustom}
                setCheckedOptions={setCheckedCustom}
                allOptions={customHeaders}
                stringProperty="label"
              />
            </div>
          </div>
        </div>
        <ReportContainer
          title="Participant Reports"
          subtitles={reportSubtitles}
          csvHeaders={combinedHeaders}
          csvData={getCsvData()}
          fileName={`Students_${selectedListing?.[nameProperty]}.csv`}
          isLarge
        >
          <div className={"report-table-large"}>
            <ReportTable headings={combinedHeaders} body={getTableData()} />
            {checkedColumns.length === 0 && checkedCustom.length === 0 && (
              <p className="report-no-results-text">
                Please select columns to display!
              </p>
            )}
          </div>
        </ReportContainer>
      </div>
    </div>
  );
};

export default StudentReports;
