import BaseModal from "./baseModal/BaseModal";
import React, { useEffect, useGlobal, useState } from "reactn";
import { withRouter } from "react-router-dom";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import axios from "axios";
import { toast } from "react-toastify";
import { SWITCH } from "./baseModal/FieldTypes";

const UserListingsModal = ({
  onClose,
  listingType,
  role,
  updateListing,
  program,
  event,
  party,
  online,
  membership,
  user
}) => {
  const [global, setGlobal] = useGlobal();
  const [data, setData] = useState({});
  const [programs, setPrograms] = useState([]);
  const [events, setEvents] = useState([]);
  const [parties, setParties] = useState([]);
  const [onlineVirtuals, setOnlineVirtuals] = useState([]);
  const [listings, setListings] = useState({
    Program: [],
    Event: [],
    Online: [],
    Party: []
  });

  const fetchInitialData = async () => {
    await setGlobal({ loading: true });

    if (program.length > 0 && listingType === "Program") {
      const obj = {};
      program.map(e => obj[e.id] = true);
      const listingsCopy = { ...listings };
      listingsCopy.Program = obj;
      setListings(listingsCopy);
    }

    if (event.length > 0 && listingType === "Event") {
      const obj = {};
      event.map(e => obj[e.id] = true);
      const listingsCopy = { ...listings };
      listingsCopy.Event = obj;
      setListings(listingsCopy);
    }

    if (online.length > 0 && listingType === "Online") {
      const obj = {};
      online.map(e => obj[e.id] = true);
      const listingsCopy = { ...listings };
      listingsCopy.Online = obj;
      setListings(listingsCopy);
    }

    if (party.length > 0 && listingType === "Party") {
      const obj = {};
      party.map(e => obj[e.id] = true);
      const listingsCopy = { ...listings };
      listingsCopy.Online = obj;
      setListings(listingsCopy);
    }

    const ep = `${process.env.REACT_APP_API}/partners/organization_profile/preview`;
    const results = await axios.get(ep);
    await setGlobal({ loading: false });
    if (results.data.success) {
      // Setting the programs data
      const programIds = [];
      const programData = results.data.data.programs
        .filter(f => !f.isArchived)
        .map(e => {
          programIds.push(e.id);
          const parsePlans = e.plans.map(t => JSON.parse(t));
          e.plans = parsePlans;
          return e;
        });

      setPrograms(programData);
      setData({
        ...data,
        Program: {
          isOpen: false,
          list: programData
        }
      });

      // Setting the events data
      const eventIds = [];
      const eventData = results.data.data.events
        .filter(f => !f.isArchived)
        .map(e => {
          eventIds.push(e.id);
          const parseTicket = e.tickets.map(t => JSON.parse(t));
          e.tickets = parseTicket;
          return e;
        });

      setEvents(eventData);
      setData({
        ...data,
        Event: {
          isOpen: false,
          list: eventData
        }
      });

      // Setting the parties data
      const partyIds = [];
      const partyData = results.data.data.parties
        .filter(f => !f.isArchived)
        .map(e => {
          partyIds.push(e.id);
          const parseTicket = e.tickets.map(t => JSON.parse(t));
          e.tickets = parseTicket;
          return e;
        });

      setParties(partyData);
      setData({
        ...data,
        Party: {
          isOpen: false,
          list: partyData
        }
      });

      // Setting Online Data
      const onlineIds = [];
      const onlineVirtualData = results.data.data.virtual
        .filter(f => !f.isArchived)
        .map(e => {
          onlineIds.push(e.id);
          const parsePlans = e.plans.map(t => JSON.parse(t));
          e.plans = parsePlans;
          return e;
        });

      setOnlineVirtuals(onlineVirtualData);
      setData({
        ...data,
        Online: {
          isOpen: false,
          list: onlineVirtualData
        }
      });
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const updateRole = async () => {
    let data = {};
    const arr = [];
    switch (listingType) {
      case "Program":
        const programId = Object.keys(listings.Program).map(sub => {
          if (listings.Program[sub])
            arr.push(parseInt(sub));
        });
        data.programlist = arr;
        break;
      case "Event":
        const eventId = Object.keys(listings.Event).map(sub => {
          if (listings.Event[sub])
            arr.push(parseInt(sub));
        });
        data.eventlist = arr;
        break;
      case "Party":
        const partyId = Object.keys(listings.Party).map(sub => {
          if (listings.Party[sub])
            arr.push(parseInt(sub));
        });
        data.partylist = arr;
        break;
      case "Online":
        const onlineId = Object.keys(listings.Online).map(sub => {
          if (listings.Online[sub])
            arr.push(parseInt(sub));
        });
        data.onlinelist = arr;
        break;
    }

    const ep = `${process.env.REACT_APP_API}/partners/users/update-user/${user.id}`;
    const result = await axios.post(ep, data);

    console.log("result", result);
    if (result.data.success) {
      toast.success(`Listings Updated!`);
      updateListing(result.data.data);
      onClose();
    }
  };

  const getBaseModalFields = () => {
    switch (listingType) {
      case "Program":
        return programs.map(e => {
          return {
            type: SWITCH,
            data: {
              name: e.program_name,
              checked: listings.Program[e.id],
              handleClick: () => {
                setListings({
                  ...listings,
                  Program: {
                    ...listings.Program,
                    [e.id]: !listings.Program[e.id]
                  }
                })
              }
            }
          }
        });
      case "Event":
        return events.map(e => {
          return {
            type: SWITCH,
            data: {
              name: e.event_title,
              checked: listings.Event[e.id],
              handleClick: () => {
                setListings({
                  ...listings,
                  Event: {
                    ...listings.Event,
                    [e.id]: !listings.Event[e.id]
                  }
                })
              }
            }
          }
        });
      case "Payrt":
        return parties.map(e => {
          return {
            type: SWITCH,
            data: {
              name: e.party_title,
              checked: listings.Party[e.id],
              handleClick: () => {
                setListings({
                  ...listings,
                  Party: {
                    ...listings.Party,
                    [e.id]: !listings.Party[e.id]
                  }
                })
              }
            }
          }
        });
      case "Online":
        return onlineVirtuals.map(e => {
          return {
            type: SWITCH,
            data: {
              name: e.program_name,
              checked: listings.Online[e.id],
              handleClick: () => {
                setListings({
                  ...listings,
                  Online: {
                    ...listings.Online,
                    [e.id]: !listings.Online[e.id]
                  }
                })
              }
            }
          }
        });
    }
  };

  const getBaseModalButtons = () => {
    const updateButton = {
      name: "Update",
      handleClick: updateRole,
      buttonColour: BLUE_BUTTON,
      textColour: WHITE
    };
    return [updateButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Select Listings",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: onClose
    };
  };

  return (
    <BaseModal {...getBaseModalProps()} />
  );
};

export default withRouter(UserListingsModal);