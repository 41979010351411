import React from "reactn";

const GuardianContact = ({ contacts }) => {
  return (
    <div className="formbody">
      {contacts.map((contact, i) => (
        <React.Fragment key={`${i}-contact`}>
          <div className="row">
            <div className="col-4">
              <h4>Full Name</h4>
            </div>
            <div className="col-8">{contact.fullName}</div>
          </div>
          <div className="row">
            <div className="col-4">
              <h4>Address</h4>
            </div>
            <div className="col-8">{contact.address}</div>
          </div>
          <div className="row">
            <div className="col-4">
              <h4>City</h4>
            </div>
            <div className="col-8">{contact.city}</div>
          </div>
          <div className="row">
            <div className="col-4">
              <h4>Province</h4>
            </div>
            <div className="col-8">{contact.province}</div>
          </div>
          <div className="row">
            <div className="col-4">
              <h4>Country</h4>
            </div>
            <div className="col-8">{contact.country}</div>
          </div>
          <div className="row">
            <div className="col-4">
              <h4>Postal Code</h4>
            </div>
            <div className="col-8">{contact.postalCode}</div>
          </div>
          <div className="row">
            <div className="col-4">
              <h4>Email</h4>
            </div>
            <div className="col-8">{contact.email}</div>
          </div>
          <div className="row">
            <div className="col-4">
              <h4>Home Phone</h4>
            </div>
            <div className="col-8">{contact.homePhone}</div>
          </div>
          <div className="row">
            <div className="col-4">
              <h4>Work Phone</h4>
            </div>
            <div className="col-8">{contact.workPhone}</div>
          </div>
          {i !== contacts.length - 1 ? <hr /> : null}
        </React.Fragment>
      ))}
    </div>
  );
};

export default GuardianContact;
