import React from "reactn";
import { Modal } from "../../UI";
import { Fragment } from "react";
class PlanModal extends React.Component {
  render() {
    const { open, onClose } = this.props;
    return (
      <div>
        <Modal open={open} heading="Plan Info" onClose={onClose}>
          {this.props.data && (
            <Fragment>
              <div className="row">
                <p style={{ textAlign: "center" }}></p>
              </div>
              <>
                <div className="row">
                  <p>
                    <b>Preferred days of care : </b>
                    {this.props.data.daysRequested &&
                      Object.keys(
                        JSON.parse(this.props.data.daysRequested)
                      ).join(", ")}
                    .
                  </p>
                </div>
                <div className="row">
                  <p>
                    <b>Preferred Drop-Off time : </b>
                    {this.props.data.dropOff}.
                  </p>
                </div>
                <div className="row">
                  <p>
                    <b>Preferred Pick-Up time : </b>
                    {this.props.data.pickUp}.
                  </p>
                </div>
                <div className="row">
                  <p>
                    <b> Child's Months :</b> {this.props.data.months}
                  </p>
                </div>
                <div className="row">
                  <p>
                    <b> Child's Years :</b> {this.props.data.years}
                  </p>
                </div>
                <div className="row">
                  <p>
                    <b> How soon do you need childcare :</b>{" "}
                    {this.props.data.childcareneed
                      ? this.props.data.childcareneed
                      : "Not available"}
                  </p>
                </div>
              </>
            </Fragment>
          )}
        </Modal>
      </div>
    );
  }
}

export default PlanModal;
