import React from "react";
import { Grid, makeStyles, IconButton } from "@material-ui/core";
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatSize,
  FormatListBulleted,
  FormatListNumbered,
  FormatStrikethrough,
  CheckBoxOutlineBlank
} from "@material-ui/icons";
import { EditorState, RichUtils } from "draft-js";
import "draft-js/dist/Draft.css";
import "./toolbar.css";

const useStyles = makeStyles({
  iconContainer: {
    display: "inline-flex",
    flexDirection: "row"
  },
  button: {
    cursor: "pointer"
  }
});

const Toolbar = props => {
  const classes = useStyles();
  const { editorState, setEditorState, styles, isNotes } = props;

  const styleButtons = [
    { icon: FormatBold, id: "BOLD", category: "style", fontSize: "default" },
    {
      icon: FormatItalic,
      id: "ITALIC",
      category: "style",
      fontSize: "default"
    },
    {
      icon: FormatUnderlined,
      id: "UNDERLINE",
      category: "style",
      fontSize: "default"
    },
    {
      icon: FormatStrikethrough,
      id: "STRIKETHROUGH",
      category: "style",
      fontSize: "default"
    },
    {
      icon: FormatSize,
      id: "LARGE",
      fontSize: "large",
      textSize: "24px",
      category: "fontSize"
    },
    {
      icon: FormatSize,
      id: "MEDIUM",
      fontSize: "default",
      textSize: "16px",
      category: "fontSize"
    },
    {
      icon: FormatSize,
      id: "SMALL",
      fontSize: "small",
      textSize: "12px",
      category: "fontSize"
    }
  ];
  const HOLLOW_SQUARE_LIST_ITEM = "hollow-square-list-item";

  const handleMouseDown = (e, category, textSize) => {
    e.preventDefault();
    if (category === "fontSize") {
      setFontSize(textSize);
    } else if (category === "style")
      // toggles Bold, Italic and Underline
      setEditorState(RichUtils.toggleInlineStyle(editorState, e.target.id));
  };

  const toggleBlockType = (e, type) => {
    e.preventDefault();
    if (type === HOLLOW_SQUARE_LIST_ITEM) {
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const blockMap = contentState.getBlockMap();
      const currentBlockKey = selection.getStartKey();
      const currentBlock = blockMap.get(currentBlockKey);

      // Check if the current block is already a hollow square list item
      if (currentBlock.getType() === HOLLOW_SQUARE_LIST_ITEM) {
        // Set the block type back to normal paragraph
        const newBlock = currentBlock.merge({
          type: "unstyled"
        });

        const newContentState = contentState.merge({
          blockMap: blockMap.set(currentBlockKey, newBlock)
        });

        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          "change-block-type"
        );
        setEditorState(newEditorState);
      } else {
        // Create a new block with the desired block type
        const newBlock = currentBlock.merge({
          type: HOLLOW_SQUARE_LIST_ITEM
        });

        const newContentState = contentState.merge({
          blockMap: blockMap.set(currentBlockKey, newBlock)
        });

        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          "change-block-type"
        );
        setEditorState(newEditorState);
      }
    } else {
      setEditorState(RichUtils.toggleBlockType(editorState, type));
    }
  };

  const setFontSize = value => {
    //remove current font size at selection
    const newEditorState = styles.fontSize.remove(editorState);
    //set editorState to display new font size
    setEditorState(styles.fontSize.add(newEditorState, value));
  };

  return (
    <Grid container className={classes.iconContainer}>
      {styleButtons.map(btn => {
        const BtnComponent = btn.icon;
        return (
          <IconButton
            key={btn.id}
            onMouseDown={e => {
              handleMouseDown(e, btn.category, btn.textSize);
            }}
          >
            <BtnComponent
              id={btn.id}
              className={classes.button}
              fontSize={btn.fontSize && btn.fontSize}
            />
          </IconButton>
        );
      })}
      <IconButton>
        <FormatListBulleted
          onMouseDown={e => {
            toggleBlockType(e, "unordered-list-item");
          }}
        />
      </IconButton>
      <IconButton>
        <FormatListNumbered
          onMouseDown={e => {
            toggleBlockType(e, "ordered-list-item");
          }}
        />
      </IconButton>
      {isNotes && (
        <IconButton>
          <CheckBoxOutlineBlank
            onMouseDown={e => {
              toggleBlockType(e, HOLLOW_SQUARE_LIST_ITEM);
            }}
          />
        </IconButton>
      )}
    </Grid>
  );
};

export default Toolbar;
