import React from "react";
import ProgramAmenitiesForm from "./forms/programAmenities";
import ProgramScheduleForm from "./forms/programSchedule";

class ProgramAmenitiesSchedule extends React.PureComponent {
  render() {
    return (
      <div className="cont boxes-row mt-50px">
        <ProgramAmenitiesForm />
        <ProgramScheduleForm />
      </div>
    );
  }
}
export default ProgramAmenitiesSchedule;
