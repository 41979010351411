import React, { useState, useEffect, useGlobal } from "reactn";
import { CircleCheck, PartyIcon, FieldTripIcon, PrivateEventIcon } from "../../assets";
import axios from "axios";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { canUserCreate } from "../../utilities/listingCreation";
import { notEmptyString } from "./baseModal/validators";

import { SWITCH, TITLE, IMAGE_DROPDOWN } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import BaseModal from "./baseModal/BaseModal";

const CreatePartyModal = ({ onClose, history, dispatch }) => {
  const [addChild, setAddChild] = useState(false);
  const [manualInvoices, setManualInvoices] = useState(false);
  const [applicationRequired, setApplicationRequired] = useState(true);
  const [birthdayParty, setBirthdayParty] = useState(false);
  const [displayManualInvoices, setDisplayManualInvoices] = useState(false);
  const [canUserMake, setCanUserMake] = useState(false);
  const [partyType, setPartyType] = useState("");
  const [allowSameEnrollment, setAllowSameEnrollment] = useState(true);
  const [loading, setLoading] = useGlobal("loading");

  async function checkManual() {
    const feature_category = "Customization";
    const ep = `${process.env.REACT_APP_API}/partner/permissions/${feature_category}`;
    const resp = await axios.get(ep);

    if (resp.data) {
      // return resp.data
      const customizationPermissions = resp.data.data.filter(
        e => e.feature === "Accept Manual Payments"
      );
      const checkManual =
        customizationPermissions.length > 0
          ? customizationPermissions[0].has_access
          : false;
      setDisplayManualInvoices(checkManual);
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch.resetEvent();
      await checkManual();

      // Check if user can create more listings.
      const resp = await canUserCreate("party");
      setCanUserMake(resp.success);
      setLoading(false);
    })();
  }, []);

  const getBaseModalFields = () => {
    const partyTypeField = {
      type: IMAGE_DROPDOWN,
      data: {
        name: "Party Type",
        required: true,
        placeholder: "Pick a type",
        handleChange: value => {
          if (value === "Party" || value === "Field Trip")
            setAddChild(true);
          else 
            setAddChild(false);
          setPartyType(value);
        },
        choices: [
          {
            text: "Party",
            value: "Party",
            key: 1,
            image: {
              bordered: true,
              src: PartyIcon
            }
          },
          {
            text: "Field Trip",
            value: "Field Trip",
            key: 2,
            image: {
              bordered: true,
              src: FieldTripIcon
            }
          },
          {
            text: "Private Event",
            value: "Private Event",
            key: 3,
            image: {
              bordered: true,
              src: PrivateEventIcon
            }
          }
        ],
        value: partyType
      },
      validators: { validateSubmit: notEmptyString(partyType)}
    };

    const targetChildren = {
      type: SWITCH,
      data: {
        name: "Target Children",
        checked: addChild,
        handleClick: () => setAddChild(!addChild)
      }
    };

     const offlinePayment = {
       type: SWITCH,
       data: {
         name: "Offline Payment",
         checked: manualInvoices,
         handleClick: () => setManualInvoices(!manualInvoices),
         info: "Allows your client to pass through checkout without paying online"
       }
     };

    const applicationRequiredField = {
      type: SWITCH,
      data: {
        name: "Application Required",
        checked: applicationRequired,
        handleClick: () => setApplicationRequired(!applicationRequired)
      }
    };

    const BirthdayParty = {
      type: SWITCH,
      data: {
        name: "Birthday Event",
        checked: birthdayParty,
        handleClick: () => setBirthdayParty(!birthdayParty)
      }
    };

    const allFields = [];
    allFields.push(partyTypeField);
    allFields.push(targetChildren);
    if (displayManualInvoices) {
      allFields.push(offlinePayment);
    }
    // allFields.push(applicationRequiredField);
    // allFields.push(BirthdayParty);

    return allFields;
  };

  const getBaseModalButtons = () => {
    const createButton = {
      name: "Create",
      handleClick: () => {
        history.push({
          pathname: "/partners/parties/setup",
          state: {
            partyType: partyType,
            addChild: addChild,
            manualInvoices: manualInvoices,
            applicationRequired: applicationRequired,
            allowSameEnrollment: allowSameEnrollment,
          }
        });
      },
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit"
    };

    return [createButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Create Party",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: onClose
    };
  };

  const getLimitReachedModal = () => {
    const props = {
      title: "Create Party",
      fields: [
        { type: TITLE, data: { name: "You have reached your party limit." } }
      ],
      handleClose: onClose
    };
    return <BaseModal {...props} />;
  };
  
  return canUserMake ? (
    <BaseModal {...getBaseModalProps()} />
  ) : (
    getLimitReachedModal()
  );
};

export default CreatePartyModal;
