import React from "reactn";
import axios from "axios";
import ChildProfile from "../shared/applicationView/ChildProfile";
import EmergencyContact from "../shared/applicationView/EmergencyContact";
import PhysicianContact from "../shared/applicationView/PhysicianContact";
import GuardianContact from "../shared/applicationView/ParentGuardian";
import EnrollmentDetails from "../shared/applicationView/EnrollmentDetails";
import RejectModal from "./applicationView/RejectModal";
import WaitlistModal from "./applicationView/WaitlistModal";

class ApplicationView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      child: null,
      application: null,
      contacts: null,
      guardians: null,
      physicians: null,
      contactTab: 0,
      waitlistShown: false,
      rejectShown: false
    };
    this.setGlobal({
      pathway: [
        ...this.global.pathway.slice(0, 1),
        "Applications",
        "Application View"
      ]
    });
  }

  async componentDidMount() {
    const params = this.props.match.params;
    if (!params.slug || !params.time) {
      this.props.history.push("/parents/applications");
    }
    const ep = `${process.env.REACT_APP_API}/edu/enrollment/application/${params.slug}/${params.time}`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      this.setGlobal({
        loading: false,
        lastAPICall: res
      });
      this.setState({
        ...res.data.data
      });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
    this.handleAccept = this.handleAccept.bind(this);
  }

  handleAccept = async () => {
    this.setGlobal({ loading: true });
    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/edu/enrollment/application/${params.slug}/${params.time}`;
    try {
      const res = await axios.post(ep, { action: "Accept" });
      this.setGlobal({ loading: false, lastAPICall: res });
      if (res.data.success) {
        this.props.history.push("/educators/applications");
      }
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  };

  render() {
    return (
      <div className="container-fluid">
        {this.global.dsUser.accountType === "Educator" ? (
          <>
            {!this.state.rejectShown ? null : (
              <RejectModal
                onClose={() => this.setState({ rejectShown: false })}
              />
            )}
          </>
        ) : null}
        {this.global.dsUser.accountType === "Educator" ? (
          <>
            {!this.state.waitlistShown ? null : (
              <WaitlistModal
                onClose={() => this.setState({ waitlistShown: false })}
              />
            )}
          </>
        ) : null}
        <div className="cont">
          <div className="row">
            <div className="col-md-12">
              <h1>
                {this.global.dsUser.accountType === "Parent"
                  ? this.state.application
                    ? `${this.state.application.programName}`
                    : "Application"
                  : this.state.child
                  ? this.state.child.fullName
                  : "Application"}
              </h1>
            </div>
          </div>
        </div>
        <div className="cont">
          <div className="boxes d-flex" style={{ flexWrap: "wrap" }}>
            {!this.state.application ? null : (
              <EnrollmentDetails application={this.state.application} />
            )}
            {!this.state.child ? null : (
              <ChildProfile
                childNo={this.state.child.fullName}
                child={this.state.child}
              />
            )}

            {!this.state.guardians ||
            !this.state.physicians ||
            !this.state.contacts ? null : (
              <div
                className="box"
                style={{
                  maxWidth: 540,
                  minWidth: 380,
                  height: "fit-content",
                  flexGrow: 1
                }}
              >
                <div className="bgofbox">
                  <div className="header">
                    <div className="row">
                      <div className="col-4">
                        <h2>CONTACTS</h2>
                      </div>
                      <div className="col-8 d-flex">
                        <button
                          className={
                            0 === this.state.contactTab ? "active" : ""
                          }
                          onClick={() => this.setState({ contactTab: 0 })}
                        >
                          Parent / Guardians
                        </button>
                        <button
                          className={
                            1 === this.state.contactTab ? "active" : ""
                          }
                          onClick={() => this.setState({ contactTab: 1 })}
                        >
                          Emergency
                        </button>
                        <button
                          className={
                            2 === this.state.contactTab ? "active" : ""
                          }
                          onClick={() => this.setState({ contactTab: 2 })}
                        >
                          Physicians
                        </button>
                      </div>
                    </div>
                  </div>
                  {this.state.contactTab !== 0 ? null : (
                    <GuardianContact contacts={this.state.guardians} />
                  )}
                  {this.state.contactTab !== 1 ? null : (
                    <EmergencyContact contacts={this.state.contacts} />
                  )}
                  {this.state.contactTab !== 2 ? null : (
                    <PhysicianContact contacts={this.state.physicians} />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {this.global.dsUser.accountType === "Educator" ? (
          <div className="cont">
            <div className="row">
              <div className="col-md-12">
                <div className="buttons">
                  <button className="savebtn" onClick={this.handleAccept}>
                    ACCEPT
                  </button>
                  <button
                    className="savebtn"
                    onClick={() => this.setState({ waitlistShown: true })}
                  >
                    WAITLIST
                  </button>
                  <button
                    className="savebtn"
                    onClick={() => this.setState({ rejectShown: true })}
                  >
                    REJECT
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ApplicationView;
