import Parents from "./parents";
import Educators from "./educators";
import Admins from "./admins";

const Tables = {
  Parents: Parents,
  Educators: Educators,
  Admins: Admins
};

export default Tables;
