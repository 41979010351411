import React from "react";

/**
 * Renders a single report table to the screen.
 *
 * @returns {JSX}
 *
 * @param {Array<Object>} headings Headings of the table.
 *   Each heading is represented by Object, {label, style, className}. Style and className apply to whole column.
 *
 * @param {Array<Array>} body Main data, represented by Array of arrays.
 *   Each subArray is a row in the table.
 *
 * @param {String} noDataText Text to be shown when there is no data found.
 *
 * @param {bool} scrollable Whether the table should be horizontally scrollable.
 */
const ReportTable = ({ headings, body, noDataText, scrollable }) => {
  const groupedBody = body.reduce((acc, curr, i) => {
    if (i < 14) {
      acc[0].push(curr);
    } else {
      const index = parseInt((i - 14) / 30) + 1;
      console.log("INDEX:", index);
      if (!acc[index]) {
        acc[index] = [];
      }
      acc[index].push(curr);
    }

    return acc;
  }, [[]]);
  return (
    <div className="table-container">
      <table className="report-new-table">
        <thead id="table-headings">
          <tr className="report-table-header-section">
            {headings?.map(heading => {
              // Define the maximum character limit for the text
              const maxCharacterLimit = 50;

              // Truncate the text if it exceeds the maximum character limit
              const truncatedText =
                heading.label.length > maxCharacterLimit
                  ? heading.label.substring(0, maxCharacterLimit) + "..."
                  : heading.label;

              return (
                <th
                  key={heading.label}
                  style={heading.style}
                  className="report-table-header"
                  title={
                    heading.label.length > maxCharacterLimit
                      ? heading.label
                      : null
                  }
                >
                  {truncatedText}
                </th>
              );
            })}
          </tr>
        </thead>
          {groupedBody?.map((group, groupIndex) => {
            return (
              <tbody id={`item-grouping-${groupIndex}`}>
                {group.map((row, rowIndex) => {
                  return (
                    <tr key={rowIndex} className="table-item-section">
                      {row.map((cell, cellIndex) => {
                        // Define the maximum character limit for the text
                        const maxCharacterLimit = 50;
                        if (typeof cell === "object") {
                          if (cell.$$typeof) {
                            return <td
                              key={cellIndex}
                              style={headings?.[cellIndex].style}
                              className={`table-item-content ${
                                headings?.[cellIndex].className || ""
                              }`}
                              title={cell.length > maxCharacterLimit ? cell : null}
                            >
                            {cell}
                          </td>;
                          }

                          let selectedDays = "";
                          if (cell["Monday"]) {
                            selectedDays += "Monday, ";
                          }
                          if (cell["Tuesday"]) {
                            selectedDays += "Tuesday, ";
                          }
                          if (cell["Wednesday"]) {
                            selectedDays += "Wednesday, ";
                          }
                          if (cell["Thursday"]) {
                            selectedDays += "Thursday, ";
                          }
                          if (cell["Friday"]) {
                            selectedDays += "Friday, ";
                          }
                          if (cell["Saturday"]) {
                            selectedDays += "Saturday, ";
                          }
                          if (cell["Sunday"]) {
                            selectedDays += "Sunday";
                          }
                          return (
                            <td
                              key={cellIndex}
                              style={headings?.[cellIndex].style}
                              className={`table-item-content ${
                                headings?.[cellIndex].className || ""
                              }`}
                              title={cell.length > maxCharacterLimit ? cell : null}
                            >
                              {selectedDays}
                            </td>
                          );
                        }
                        // Truncate the text if it exceeds the maximum character limit
                        let truncatedText =
                          cell.length > maxCharacterLimit
                            ? cell.substring(0, maxCharacterLimit) + "..."
                            : cell;
                        if (
                          typeof truncatedText === "string" &&
                          truncatedText.startsWith("data:image")
                        ) {
                          return (
                            <td
                              key={cellIndex}
                              style={headings?.[cellIndex].style}
                              className={`table-item-content ${
                                headings?.[cellIndex].className || ""
                              }`}
                              title={cell.length > maxCharacterLimit ? cell : null}
                            >
                              <img
                                className="image-content-image"
                                src={cell}
                                alt=""
                              />
                            </td>
                          );

                          // truncatedText = "Signature";
                        }
                        if (
                          typeof truncatedText === "string" &&
                          truncatedText.startsWith("http://dreamschools-files.s3")
                        ) {
                          return (
                            <td
                              key={cellIndex}
                              style={headings?.[cellIndex].style}
                              className={`table-item-content ${
                                headings?.[cellIndex].className || ""
                              }`}
                              title={cell.length > maxCharacterLimit ? cell : null}
                            >
                              <a
                                href={cell}
                                style={{ textDecoration: "underline" }}
                                target="_blank"
                              >
                                View File
                              </a>
                            </td>
                          );

                          // truncatedText = "Attachment";
                        }
                        if (typeof truncatedText === "boolean") {
                          if (cell) {
                            truncatedText = "True";
                          } else {
                            truncatedText = "False";
                          }
                        }
                        return (
                          <td
                            key={cellIndex}
                            style={headings?.[cellIndex].style}
                            className={`table-item-content ${
                              headings?.[cellIndex].className || ""
                            }`}
                            title={cell.length > maxCharacterLimit ? cell : null}
                          >
                            {truncatedText}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
            );
          })}
      </table>
      {body?.length === 0 && (
        <p className="report-no-results-text">{noDataText}</p>
      )}
    </div>
  );
};

export default ReportTable;
