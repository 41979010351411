import React from "reactn";
import axios from "axios";
import { toast } from "react-toastify";
import { StripeProvider } from "react-stripe-elements";
import { Elements } from "react-stripe-elements";
import PaymentSettingsCard from "./paymentSettingsCard";

class PaymentSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null
    };
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "Payment Settings"]
    });
  }

  async componentDidMount() {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE) });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE) });
      });
    }

    const ep = `${process.env.REACT_APP_API}/stripe/client`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      let con = null;
      if (res.data.success) {
        con = res.data.data;
      }
      this.setGlobal({ loading: false, lastAPICall: res, stripe: con });
    } catch (ex) {
      console.error(ex);
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="cont">
          <div className="row">
            <div className="col-md-6">
              <h1>Payment Settings</h1>
            </div>
          </div>
        </div>
        <div className="cont boxes-row boxes-row-fluid mb-5">
          <div className="box" style={{ width: "50%", margin: "0 auto" }}>
            <div className="bgofbox">
              <div className="header">
                <h2>Payment Settings</h2>
              </div>
              <div className="formbody">
                {!this.global.stripe ? null : (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <strong>Currently connected payment method:</strong>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-3">
                        <strong>Brand:</strong>
                      </div>
                      <div className="col-9">
                        {this.global.stripe.sources.data[0].brand}
                      </div>
                      <div className="col-3">
                        <strong>Last 4 Digits:</strong>
                      </div>
                      <div className="col-9">
                        {this.global.stripe.sources.data[0].last4}
                      </div>
                      <div className="col-3">
                        <strong>Expiry:</strong>
                      </div>
                      <div className="col-9">
                        {this.global.stripe.sources.data[0].exp_month} /{" "}
                        {this.global.stripe.sources.data[0].exp_year}
                      </div>
                    </div>
                    <hr />
                  </>
                )}

                <div className="row">
                  <div className="col-12">
                    <StripeProvider apiKey={process.env.REACT_APP_STRIPE}>
                      <Elements>
                        <PaymentSettingsCard stripe={this.state.stripe} />
                      </Elements>
                    </StripeProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentSettings;
