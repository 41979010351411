import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { createElement } from "react";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, SWITCH } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

const DOLLAR_TO_CENT = 100;

class EditItemModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      itemName: props.invoiceDetails[props.selectedItemIndex].itemName,
      description: props.invoiceDetails[props.selectedItemIndex].description,
      rate: props.invoiceDetails[props.selectedItemIndex].rate,
      isSaveItem: false,
      existingLineItems: [],
      selectedExistingLineItem: "",
      existingItem: null,
      quantity: props.invoiceDetails[props.selectedItemIndex].quantity,
      invoiceDetails: props.invoiceDetails
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async editLineItem(e) {
    e.preventDefault();
    console.log("editLineItem");

    if (
      this.props.invoiceDetails[this.props.selectedItemIndex].pricingItemId &&
      this.state.isSaveItem
    ) {
      const ep = `${process.env.REACT_APP_API}/partners/pricing_items/update`;
      const res = await axios.post(ep, {
        id: this.props.invoiceDetails[this.props.selectedItemIndex]
          .pricingItemId,
        itemName: this.state.itemName,
        description: this.state.description,
        rate: this.state.rate
      });
      console.log("res", res);
      if (res.data.success) {
        toast.success("Item Updated");
        const createdLine = this.state.invoiceDetails;
        delete createdLine.isSaveItem;
        this.props.lineItem(createdLine);
        this.props.onClose();
      }
    } else {
      const createdLine = this.state.invoiceDetails;
      // createdLine[this.props.selectedItemIndex].rate = createdLine[this.props.selectedItemIndex].rate * DOLLAR_TO_CENT
      delete createdLine.isSaveItem;
      console.log("createdLine", createdLine);
      this.props.lineItem(createdLine);
      this.props.onClose();
    }
  }

  getBaseModalFields() {
    const nameInput = {
      type: STRING_INPUT,
      data: {
        name: "Name",
        required: false,
        placeholder: "Item Name",
        handleChange: e => {
          const updatedInvoiceDetails = this.props.invoiceDetails;
          updatedInvoiceDetails[this.props.selectedItemIndex].itemName =
            e.target.value;

          this.setState({
            itemName: e.target.value,
            invoiceDetails: updatedInvoiceDetails
          });
        },
        value: this.state.itemName
      }
    };

    const internaldescriptionInput = {
      type: STRING_INPUT,
      data: {
        name: "Internal Description (Optional)",
        required: false,
        placeholder: "Enter Internal Description",
        handleChange: e => {
          const updatedInvoiceDetails = this.props.invoiceDetails;
          updatedInvoiceDetails[this.props.selectedItemIndex].description =
            e.target.value;

          this.setState({
            description: e.target.value,
            invoiceDetails: updatedInvoiceDetails
          });
        },
        value: this.state.description
      }
    };

    const amountInput = {
      type: STRING_INPUT,
      data: {
        name: "Amount",
        type: "number",
        required: false,
        placeholder: "",
        handleChange: e => {
          const updatedInvoiceDetails = this.props.invoiceDetails;
          updatedInvoiceDetails[this.props.selectedItemIndex].rate =
            parseFloat(e.target.value) * DOLLAR_TO_CENT;
          updatedInvoiceDetails[this.props.selectedItemIndex].amount =
            parseFloat(e.target.value) * DOLLAR_TO_CENT;

          this.setState({
            rate: parseFloat(e.target.value * DOLLAR_TO_CENT),
            invoiceDetails: updatedInvoiceDetails
          });
        },
        value: parseFloat(this.state.rate / DOLLAR_TO_CENT)
      }
    };

    const quanityInput = {
      type: STRING_INPUT,
      data: {
        name: "Quantity",
        type: "number",
        required: false,
        handleChange: e => {
          const updatedInvoiceDetails = this.props.invoiceDetails;
          updatedInvoiceDetails[this.props.selectedItemIndex].quantity =
            parseInt(e.target.value);

          this.setState({
            quantity: e.target.value,
            invoiceDetails: updatedInvoiceDetails
          });
        },
        value: this.state.quantity
      }
    };

    const saveUpdates = {
      type: SWITCH,
      data: {
        name: "Save Updates for future use?",
        info: "Updates Will be available for future use",
        isDisabled: false,
        checked: this.state.isSaveItem,
        handleClick: e => {
          this.setState({ isSaveItem: e.target.checked });
        }
      }
    };

    const fields = [];
    if (this.state.selectedExistingLineItem === "") {
      fields.push(nameInput);
      fields.push(internaldescriptionInput);
      fields.push(amountInput);
      fields.push(quanityInput);
      fields.push(saveUpdates);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Update Item",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.editLineItem(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Edit Invoice Item",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    console.log("this.state", this.state);
    console.log("this.props", this.props);
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <ModalTemplate
        heading="Edit Invoice Item"
        onClose={this.props.onClose}
        style={{ overflow: "visible" }}
      >
        <form onSubmit={e => this.editLineItem(e)}>
          <div id="heightset" className="modal-main">
            {this.state.selectedExistingLineItem === "" && (
              <>
                <ModalFields.input
                  label="Name"
                  name="itemName"
                  type="text"
                  required={false}
                  placeholder="Item Name"
                  onChange={e => {
                    const updatedInvoiceDetails = this.props.invoiceDetails;
                    updatedInvoiceDetails[
                      this.props.selectedItemIndex
                    ].itemName = e.target.value;

                    this.setState({
                      itemName: e.target.value,
                      invoiceDetails: updatedInvoiceDetails
                    });
                  }}
                  value={this.state.itemName}
                />
                <ModalFields.input
                  label="Internal Description (Optional)"
                  name="description"
                  type="text"
                  required={false}
                  placeholder="Enter Internal Description"
                  onChange={e => {
                    const updatedInvoiceDetails = this.props.invoiceDetails;
                    updatedInvoiceDetails[
                      this.props.selectedItemIndex
                    ].description = e.target.value;

                    this.setState({
                      description: e.target.value,
                      invoiceDetails: updatedInvoiceDetails
                    });
                  }}
                  value={this.state.description}
                />
                <ModalFields.input
                  label="Amount"
                  name="rate"
                  type="number"
                  min={1}
                  value={parseFloat(this.state.rate / DOLLAR_TO_CENT)}
                  required={false}
                  placeholder="$"
                  onChange={e => {
                    const updatedInvoiceDetails = this.props.invoiceDetails;
                    updatedInvoiceDetails[this.props.selectedItemIndex].rate =
                      parseFloat(e.target.value) * DOLLAR_TO_CENT;
                    updatedInvoiceDetails[this.props.selectedItemIndex].amount =
                      parseFloat(e.target.value) * DOLLAR_TO_CENT;

                    this.setState({
                      rate: parseFloat(e.target.value * DOLLAR_TO_CENT),
                      invoiceDetails: updatedInvoiceDetails
                    });
                  }}
                />
                <ModalFields.input
                  label="Quantity"
                  name="quantity"
                  type="number"
                  min={1}
                  value={this.state.quantity}
                  required={false}
                  onChange={e => {
                    const updatedInvoiceDetails = this.props.invoiceDetails;
                    updatedInvoiceDetails[
                      this.props.selectedItemIndex
                    ].quantity = parseInt(e.target.value);

                    this.setState({
                      quantity: e.target.value,
                      invoiceDetails: updatedInvoiceDetails
                    });
                  }}
                />
                <ModalFields.switch
                  label="Save Updates for future use?"
                  name="isSaveItem"
                  checked={this.state.isSaveItem}
                  onClick={e => {
                    this.setState({ [e.target.name]: e.target.checked });
                  }}
                  tooltip="Updates Will be available for future use"
                />
              </>
            )}
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <img
                  src={CircleCheck}
                  alt=""
                  style={{
                    color: "white",
                    height: "15px",
                    width: "15px",
                    marginRight: "8px"
                  }}
                />
                <span>Update Item</span>
              </div>
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(EditItemModal);
