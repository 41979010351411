import React, { useGlobal, useState } from "reactn";
import PropTypes from "prop-types";
import Form from "./Form";
import StatusForm from "./StatusForm";
import "../../../assets/css/componentSpecificCss/eventListings.css";
import EditProgramStartDateModal from "../../../components/modals/EditProgramStartDateModal";

const BoxForm = props => {
  const [dsUser] = useGlobal("dsUser");
  const {
    fields,
    name,
    handleChange,
    removable,
    handleRemove,
    object,
    removeAfterSaved,
    formType,
    statuses,
    customForm,
    editStartDate,
    handleStartDateChange,
    programStartDate,
    onCloseStartDateModal
  } = props;
  const [startDateNotificationModal, setStartDateNotificationModal] =
    useState(false);

  const canRemove = () => {
    return (
      removeAfterSaved || (!removeAfterSaved && typeof object.id !== "number")
    );
  };
  return (
    <div
      className={
        name !== "Create Ticket" &&
        name !== "Create Package" &&
        name !== "Membership Plans" &&
        name !== "Credit Card" &&
        name !== "Additional Settings" &&
        name !== "Checkout Details" &&
        name !== "Tax Rate Allotment"
          ? "box"
          : "box custom-width-box"
      }
    >
      <div className="bgofbox">
        {editStartDate && (
          <EditProgramStartDateModal
            onDateChange={date => {
              handleStartDateChange(date);
              onCloseStartDateModal();
            }}
            onClose={() => onCloseStartDateModal()}
            programStartDate={programStartDate}
          />
        )}
        <div className="header">
          <div className="row">
            <div className="col-12 d-flex">
              <h2 style={{ fontSize: "17px", color: "#042470" }}>{name}</h2>{" "}
              {/** font size added here to enhance events styling fonts */}
              {(removable && canRemove()) ||
              (removable && dsUser.accountType === "Admin") ? (
                <>
                  <span className="spacer"></span>
                  <button
                    className="remove-box"
                    onClick={() => handleRemove(object)}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </div>
        {!customForm ? (
          formType === "normal" ? (
            <Form handleChange={handleChange} fields={fields} />
          ) : (
            <StatusForm
              handleChange={handleChange}
              fields={fields}
              statuses={statuses}
            />
          )
        ) : (
          customForm
        )}
      </div>
    </div>
  );
};

BoxForm.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  removable: PropTypes.bool,
  handleRemove: PropTypes.func,
  object: PropTypes.object,
  removeAfterSaved: PropTypes.bool,
  formType: PropTypes.string,
  statuses: PropTypes.object,
  customForm: PropTypes.any
};

BoxForm.defaultProps = {
  removable: false,
  handleRemove: () => false,
  object: {},
  removeAfterSaved: true,
  formType: "normal",
  customForm: null
};

export default BoxForm;
