import axios from "axios";
export async function postPromotionToggle(promotion) {
  const ep = `${process.env.REACT_APP_API}/parnters/promotions/manage/toggle_active`;
  const resp = await axios.post(ep, promotion);
  if (resp.data) {
    return resp.data;
  }
  return {
    success: false,
    message: "Something went wrong when tryping to toggle values."
  };
}

export async function postPublishPromotion(promotion) {
  const ep = `${process.env.REACT_APP_API}/partners/promotions/manage/toggle_published`;
  const resp = await axios.post(ep, promotion);
  if (resp.data) {
    return resp.data;
  }
  return {
    success: false,
    message: "Something went wrong when trying to toggle values."
  };
}
