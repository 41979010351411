import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { NewTabbedTable } from "../../components/UI";
import { MilestonesCanvas } from "../../assets";
import moment from "moment";
import "../../assets/css/componentSpecificCss/parentMilestoneViewModal.css";
import ViewParentMilestoneJournalModal from "../../components/modals/ViewParentMilestoneJournalModal";
import ViewParentMilestoneUploadsModal from "../../components/modals/ViewParentMilestoneUploadsModal";

class ParentMilestoneViewModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedMilestone: null
    };

    this.tabs = ["Active"];
    this.headings = {
      Active: [
        {
          id: "name",
          label: "Order",
          customCell: milestone => {
            return (
              <td className="order-width">
                <h3 id="milestone-title">{milestone.name}</h3>
                <div
                  className="order-description"
                  style={{
                    fontSize: "10px",
                    lineHeight: "15px",
                    fontStyle: "italic"
                  }}
                  dangerouslySetInnerHTML={{
                    __html: this.urlify(milestone.description)
                  }}
                ></div>
              </td>
            );
          }
        },
        {
          id: "due_date",
          label: "Date",
          customCell: milestone => {
            return (
              <td className="milestone-date" style={{ width: "100px" }}>
                <p style={{ width: "100px", textAlign: "center" }}>
                  {milestone.due_date &&
                    moment(milestone.due_date).format("MMM Do YYYY")}
                </p>
              </td>
            );
          }
        },
        {
          id: "journal",
          label: "Journal",
          customCell: milestone => {
            if (!milestone.user_journal) {
              return <td className="journal-width"></td>;
            }

            return (
              <td className="journal-width">
                <textarea
                  className="form-control"
                  style={{ width: "200px" }}
                  value={milestone.journal}
                  readOnly={true}
                />
              </td>
            );
          }
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            const showViewUploadsButton = r.can_upload;
            const showViewJournalButton = r.user_journal;
            return (
              <td
                key={`${i}-btn`}
                className="buttons-container"
                style={{ width: "100px", textAlign: "center" }}
              >
                {showViewUploadsButton && (
                  <button
                    className="action-buttons parent-milestone-details-btn"
                    style={{
                      marginRight: "10px",
                      width: "124px",
                      borderRadius: "5px"
                    }}
                    onClick={() =>
                      this.setState({
                        openViewUploadsModal: true,
                        selectedMilestone: r
                      })
                    }
                  >
                    View Uploads
                  </button>
                )}
                {/* {showViewJournalButton && (
                  <button
                    className="action-buttons parent-milestone-details-btn"
                    style={{ marginRight: "10px" }}
                    onClick={() =>
                      this.setState({
                        openViewJournalModal: true,
                        selectedMilestone: r
                      })
                    }
                  >
                    View Journal
                  </button>
                )} */}
              </td>
            );
          }
        }
      ]
    };
    this.actions = [{ name: "Edit", action: null }];
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {
    await this.fetchInitialData();
    // this.setState({
    //   data: { Active: this.props.milestone }
    // });
  }

  async fetchInitialData() {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/milestones/get_for_parents/${this.props.program.programId}`;
    const res = await axios.get(ep, {
      params: { parentId: this.props.program.dsUserId, type: this.props.type }
    });

    if (res.data.success) {
      this.setState({
        data: {
          Active: res.data.data.sort(
            (a, b) => a.display_order - b.display_order
          )
        }
      });
    }
    this.setGlobal({ loading: false });
  }

  urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return (
        '<a target="_blank" href="' +
        url +
        '" style="width: 250px; word-wrap: break-word;">' +
        url +
        "</a>"
      );
    });
  }

  render() {
    return (
      <div className="request-modal-cont">
        <div
          className="request-modal lesspadding"
          style={{ maxWidth: "910px", maxHeight: "700px", overflow: "clip" }}
          id="parent-milestone-details-modal"
        >
          {this.state.openViewJournalModal && (
            <ViewParentMilestoneJournalModal
              onClose={() => this.setState({ openViewJournalModal: false })}
              journalText={this.state.selectedMilestone?.journal}
            />
          )}
          {this.state.openViewUploadsModal && (
            <ViewParentMilestoneUploadsModal
              onClose={() => this.setState({ openViewUploadsModal: false })}
              milestoneDetails={this.state.selectedMilestone}
            />
          )}
          <div class="parent-milestone-details-modal-header">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px",
                paddingBottom: "25px"
              }}
            >
              <h3 className="parent-milestone-details-title">
                Milestone Preview ({this.props.program.displayName})
              </h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "0.5rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <div
              className="container-fluid adminprofiles"
              style={{ background: "#f7f7f8" }}
            >
              <div
                className="row"
                style={{
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  overflow: "scroll",
                  height: "350px"
                }}
              >
                {/* Milestones */}
                {this.props.milestone ? (
                  <>
                    <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                      <div className="tablecont">
                        <div
                          className="table-responsive"
                          style={{ fontSize: "16px" }}
                        >
                          <NewTabbedTable
                            tabs={this.tabs}
                            showTab={false}
                            headings={this.headings}
                            data={this.state.data}
                            actions={this.actions}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <p>There are no milestones to display right now.</p>
                )}
              </div>
            </div>
            <h3></h3>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ParentMilestoneViewModal);
