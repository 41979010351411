import React from "reactn";
import Tables from "../../components/table";
import { TabbedTable } from "../../components/UI";
import axios from "axios";
import {
  differenceInCalendarMonths,
  startOfMonth,
  addMonths,
  format,
  getMonth
} from "date-fns";
import "./expenseTracker/expenseTracker.css";
import AddExpenseForm from "./expenseTracker/AddExpenseForm";
import { toast } from "react-toastify";

class ExpenseTracker extends React.Component {
  constructor() {
    super();
    this.state = {
      tabs: [],
      headings: {},
      data: {},
      filteredData: {},
      selectedCategory: 0,
      months: [],
      selectedMonth: 0,
      expensesOverview: {
        totalMonthly: 0,
        yearlySpending: 0,
        averageMonthly: 0,
        ytdTotal: 0
      },
      addExpenseModalShown: false,
      // expense: { ...expenseModel }
      groupMappings: []
    };
    this.headings = [
      {
        id: "category",
        label: "Category",
        customCell: this.categoryCell,
        customStyle: { width: 200 }
      },
      {
        id: "amount",
        label: "Cost",
        customCell: this.costCell,
        customStyle: { width: 180 }
      },
      { id: "notes", label: "Items / Notes" }
    ];
    this.updateMonth = this.updateMonth.bind(this);
    this.filterData = this.filterData.bind(this);
    this.addExpense = this.addExpense.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "Expense Tracker"]
    });
  }

  getCategories() {
    return this.state.groupMappings[this.state.selectedCategory] || [];
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/edu/expense`;
    try {
      this.setGlobal({ loading: true });

      const numMonths = differenceInCalendarMonths(
        new Date(),
        new Date(this.global.profile.createdAt)
      );
      let months = [];
      for (let i = 0; i <= numMonths; i++) {
        months.push(
          format(
            addMonths(startOfMonth(new Date(this.global.profile.createdAt)), i),
            "LLL yyyy"
          )
        );
      }

      /**
       * LATER: const res = await axios.get(`${ep}/${months[0].split(" ").join("-")}`);
       */
      const res = await axios.get(`${ep}`);

      const groupCatMap = res.data.data.groups
        .map((g, i) => {
          return res.data.data.categories.filter(
            (c, j) => c.expenseGroup === g.id
          );
        })
        .reverse();

      const tabs = res.data.data.groups
        .map(g => g.name.toUpperCase())
        .reverse();
      const headings = {};
      const data = {};
      let curG = 0;
      let ids = [];
      for (let h of res.data.data.groups.reverse()) {
        headings[h.name.toUpperCase()] = [...this.headings];
        ids = groupCatMap[curG].map(id => id.id);
        data[h.name.toUpperCase()] = res.data.data.expenses.filter(e =>
          ids.includes(e.category)
        );
        curG++;
      }

      this.setState({
        tabs,
        headings,
        data,
        months: months.reverse(),
        categories: res.data.data.categories,
        groups: res.data.data.groups,
        groupMappings: groupCatMap,
        expensesOverview: {
          ...this.state.expensesOverview,
          ...res.data.data.expensesOverview
        }
      });
      this.filterData();

      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      console.error(err);
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  filterData(month = null) {
    let newData = {};
    let monthlyTotal = 0;
    let curMonth = new Date(
      month ? month : this.state.months[this.state.selectedMonth]
    );
    for (let k of Object.keys(this.state.data)) {
      newData[k] = this.state.data[k].filter(ex => {
        if (getMonth(new Date(ex.createdAt)) === getMonth(curMonth)) {
          monthlyTotal += parseFloat(ex.amount);
          return true;
        }
        return false;
      });
    }
    this.setState({
      filteredData: newData,
      expensesOverview: {
        ...this.state.expensesOverview,
        totalMonthly: monthlyTotal
      }
    });
  }

  categoryCell = (r, i) => {
    return <td key={`${i}-ca`}>{r.categoryName}</td>;
  };

  costCell = (r, i) => {
    return <td key={`${i}-cost`}>${parseFloat(r.amount).toFixed(2)}</td>;
  };

  async updateMonth(e) {
    this.setState({ selectedMonth: e.target.value });
    this.filterData(this.state.months[e.target.value]);
  }

  async addExpense(expense) {
    const ep = `${process.env.REACT_APP_API}/edu/expense`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.post(ep, {
        ...expense.expense,
        amount: parseFloat(expense.expense.amount)
      });
      this.setGlobal({ loading: false, lastAPICall: res });
      if (res.data.success) {
        toast("Added new expense.");
        const heading = this.state.tabs[expense.category.expenseGroup - 1];
        const newExp = {
          ...res.data.data.expense,
          categoryName: expense.category.name,
          expenseGroup: expense.category.expenseGroup
        };
        const data = {
          data: {
            ...this.state.data,
            [heading.toUpperCase()]: [
              newExp,
              ...this.state.data[heading.toUpperCase()]
            ]
          },
          expensesOverview: {
            ...this.state.expensesOverview,
            ...res.data.data.expensesOverview
          }
        };
        this.setState(data);
      }

      this.filterData();
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="cont">
          <div className="row">
            <div className="col-lg-7 col-sm-12">
              <h1>Expense Tracker</h1>
            </div>
            <div
              className="col-lg-5 col-sm-12 d-flex"
              style={{
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%"
              }}
            >
              <div>
                Expenses for Month:
                <select
                  value={this.state.selectedMonth}
                  onChange={this.updateMonth}
                  className="edu__expense_tracker_select ml-2"
                >
                  {this.state.months.map((m, i) => (
                    <option key={i} value={i}>
                      {this.state.months[i]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="cont">
          <div className="row">
            <div className="col-lg-3">
              <Tables.Educators.ExpenseOverviewTable
                expenses={this.state.expensesOverview}
              />
            </div>
            <div className="col-lg-9">
              <div>
                <TabbedTable
                  tabs={this.state.tabs}
                  headings={this.state.headings}
                  data={this.state.filteredData}
                  onTabChange={i =>
                    this.setState({ selectedCategory: parseInt(i) })
                  }
                  prePaginator={
                    <AddExpenseForm
                      categories={this.getCategories()}
                      addExpense={this.addExpense}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExpenseTracker;
