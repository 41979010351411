import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";

class SectionTwo extends React.PureComponent {
  handleChange = (e, v) => {
    this.setGlobal({
      organizationInfo: {
        ...this.global.organizationInfo,
        [e]: v
      }
    });
  };

  setDay(day) {
    const days = { ...this.global.organizationInfo.days_of_week };
    if (days[day]) delete days[day];
    else days[day] = true;
    this.handleChange("days_of_week", days);
  }
  customForm = () => {
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-5">
              <h3>
                Program Types<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <formTypes.MultipleSelectProgramCategories
                  value={
                    this.global.organizationInfo.program_types.map(e =>
                      typeof e === "string" ? JSON.parse(e) : e
                    ) || []
                  }
                  onChange={(_, value) =>
                    this.handleChange(
                      "program_types",
                      value.map(e => ({
                        name: e.name
                      }))
                    )
                  }
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-5">
              <h3>Website URL</h3>
            </div>
            <div className="col-7 d-flex">
              <input
                style={{ width: "100%" }}
                name="website_url"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={this.global.organizationInfo.website_url}
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3>Days of Week</h3>
            </div>
            <div className="col-7 d-flex">
              <div
                name="days"
                className="row days"
                validation={"validDays"}
                style={{ width: "100%", margin: 0, padding: 0 }}
              >
                <div
                  data-day="Monday"
                  className={`day-monday day ${
                    this.global.organizationInfo.days_of_week["Monday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => this.setDay(e.target.getAttribute("data-day"))}
                >
                  M
                </div>
                <div
                  data-day="Tuesday"
                  className={`day-tuesday day ${
                    this.global.organizationInfo.days_of_week["Tuesday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => this.setDay(e.target.getAttribute("data-day"))}
                >
                  Tu
                </div>
                <div
                  data-day="Wednesday"
                  className={`day-wednesday day ${
                    this.global.organizationInfo.days_of_week["Wednesday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => this.setDay(e.target.getAttribute("data-day"))}
                >
                  W
                </div>
                <div
                  data-day="Thursday"
                  className={`day-thursday day ${
                    this.global.organizationInfo.days_of_week["Thursday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => this.setDay(e.target.getAttribute("data-day"))}
                >
                  Th
                </div>
                <div
                  data-day="Friday"
                  className={`day-friday day ${
                    this.global.organizationInfo.days_of_week["Friday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => this.setDay(e.target.getAttribute("data-day"))}
                >
                  F
                </div>
                <div
                  data-day="Saturday"
                  className={`day-saturday day ${
                    this.global.organizationInfo.days_of_week["Saturday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => this.setDay(e.target.getAttribute("data-day"))}
                >
                  Sa
                </div>
                <div
                  data-day="Sunday"
                  className={`day-sunday day ${
                    this.global.organizationInfo.days_of_week["Sunday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => this.setDay(e.target.getAttribute("data-day"))}
                >
                  Su
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>Timing</h3>
            </div>
            <div className="col-7 d-flex" style={{ alignItems: "center" }}>
              <div style={{ width: "40%" }}>
                <formTypes.TimeDropdownSelect
                  value={parseFloat(
                    this.global.organizationInfo.timing[0] || -1
                  )}
                  name="timing"
                  onChange={(e, v) =>
                    this.handleChange(e, [
                      v,
                      this.global.organizationInfo.timing[1]
                    ])
                  }
                ></formTypes.TimeDropdownSelect>
              </div>
              To
              <div style={{ width: "40%" }}>
                <formTypes.TimeDropdownSelect
                  name="timing"
                  value={parseFloat(
                    this.global.organizationInfo.timing[1] || -1
                  )}
                  onChange={(e, v) =>
                    this.handleChange(e, [
                      this.global.organizationInfo.timing[0],
                      v
                    ])
                  }
                ></formTypes.TimeDropdownSelect>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>Facebook Link</h3>
            </div>
            <div className="col-7 d-flex">
              <input
                style={{ width: "100%" }}
                name="facebook"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={this.global.organizationInfo.facebook}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>𝕏 Link</h3>
            </div>
            <div className="col-7 d-flex">
              <input
                style={{ width: "100%" }}
                name="twitter"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={this.global.organizationInfo.twitter}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>Instagram Link</h3>
            </div>
            <div className="col-7 d-flex">
              <input
                style={{ width: "100%" }}
                name="instagram"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={this.global.organizationInfo.instagram}
              />
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Additional Details"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionTwo;
