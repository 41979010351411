import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import * as dateFns from "date-fns";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Survey from "../UI/Survey";
class RequestTourrmationModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      displayName: this.global.dsUser ? this.global.dsUser.displayName : "",
      email: this.global.dsUser ? this.global.dsUser.email : "",
      requestedDate: "",
      requestedTime: "",
      requestPhone: this.global.dsUser ? this.global.dsUser.phone : "",
      requestMessage: "",
      isWithinSetTime: true,
      otp: "",
      otherShown: false
    };
    this.requestTour = this.requestTour.bind(this);
    this.filterTimes = this.filterTimes.bind(this);
    this.filterDate = this.filterDate.bind(this);
  }
  surveyForm = async information => {
    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/frnt/program/request-tour/${
      params && params.slug ? params.slug : this.props.program.slug
    }/survey`;
    const res = await axios.post(ep, {
      requestPhone: this.state.requestPhone || this.global.dsUser.phone,
      email: this.state.email || this.global.dsUser.email,
      displayName: this.state.displayName || this.global.dsUser.displayName,
      ...information
    });
    if (res.data.success) {
      this.setState({ code: true, survey: false });
    }
  };
  verifyCode = async e => {
    e.preventDefault();
    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/frnt/program/request-tour/${
      params && params.slug ? params.slug : this.props.program.slug
    }/confirm`;
    const theDate = new Date(this.state.requestedDate).setHours(
      this.state.requestedTime,
      0,
      0
    );
    const res = await axios.post(ep, {
      requestedTourDate: theDate,
      isWithin: this.state.isWithinSetTime,
      requestPhone: this.state.requestPhone || this.global.dsUser.phone,
      email: this.state.email || this.global.dsUser.email,
      displayName: this.state.displayName || this.global.dsUser.displayName,
      message: this.state.requestMessage,
      otp: this.state.otp
    });
    if (!res.data.success) {
      this.setState({ codeError: true });
    } else {
      this.setState({ code: false, done: true });
    }
  };
  //new Date(Date.now() + 24 * 60 * 60 * 1000)
  componentWillReceiveProps(props) {}
  requestTour = async e => {
    e.preventDefault();

    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/frnt/program/request-tour/${
      params && params.slug ? params.slug : this.props.program.slug
    }/verification`;
    this.setState({
      displayName: this.state.displayName.trim(),
      email: this.state.email.trim()
    });
    try {
      if (!this.state.displayName && !this.global.dsUser) {
        document.getElementsByName("displayName")[0].style.border =
          "1px solid red";
        throw new Error("Name must be supplied.");
      }
      if (!this.state.email && !this.global.dsUser) {
        document.getElementsByName("displayName")[0].style.border =
          "1px solid #ccc";
        document.getElementsByName("email")[0].style.border = "1px solid red";
        throw new Error("Email must be supplied.");
      }
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.state.email
        ) &&
        !this.global.dsUser
      ) {
        document.getElementsByName("displayName")[0].style.border =
          "1px solid #ccc";
        document.getElementsByName("email")[0].style.border = "1px solid red";
        throw new Error("Please enter a valid email.");
      }
      if (
        (!this.state.requestPhone ||
          this.state.requestPhone.trim().length !== 10 ||
          isNaN(this.state.requestPhone.trim())) &&
        !this.global.dsUser
      ) {
        document.getElementsByName("displayName")[0].style.border =
          "1px solid #ccc";

        document.getElementsByName("email")[0].style.border = "1px solid #ccc";
        document.getElementsByName("requestPhone")[0].style.border =
          "1px solid red";
        throw new Error("The Phone provided is invalid");
      }
      if (!this.state.requestedDate) {
        document.getElementsByName("email")[0].style.border = "1px solid #ccc";
        document.getElementsByName("displayName")[0].style.border =
          "1px solid #ccc";
        document.getElementsByName("requestPhone")[0].style.border =
          "1px solid #ccc";
        document.getElementsByName("requestedDate")[0].style.border =
          "1px solid red";
        throw new Error("Date must be supplied.");
      }
      if (!this.state.requestedTime) {
        document.getElementsByName("email")[0].style.border = "1px solid #ccc";
        document.getElementsByName("displayName")[0].style.border =
          "1px solid #ccc";
        document.getElementsByName("requestPhone")[0].style.border =
          "1px solid #ccc";
        document.getElementsByName("requestedDate")[0].style.border =
          "1px solid #ccc";
        document.getElementsByName("requestedTime")[0].style.border =
          "1px solid red";
        throw new Error("Time must be supplied.");
      }
      const theDate = new Date(this.state.requestedDate).setHours(
        this.state.requestedTime,
        0,
        0
      );
      const res = await axios.post(ep, {
        requestPhone: this.state.requestPhone || this.global.dsUser.phone,
        email: this.state.email || this.global.dsUser.email,
        displayName: this.state.displayName || this.global.dsUser.displayName
      });
      if (res.data.success) {
        this.setState({ ...res.data.data });
      }
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
  };

  filterDate(d) {
    const availDays = [
      ...new Set(
        (this.global.school
          ? this.global.school.tourAvail
          : this.props.program
        ).availability.map(x => dateFns.getDay(new Date(x)))
      )
    ];
    return availDays.includes(dateFns.getDay(new Date(d)));
  }

  filterTimes() {
    const avail = this.global.school
      ? this.global.school.tourAvail
      : this.props.program;
    if (this.state.requestedDate) {
      const day = dateFns.getDay(this.state.requestedDate);
      return avail.availability
        .filter(d => {
          const dateNow = new Date();
          const requestDate = new Date(this.state.requestedDate).setHours(
            new Date(d).getHours()
          );
          return (
            dateFns.getDay(new Date(d)) === day &&
            requestDate - dateNow >= 3600000 * 4
          );
        })
        .map(h => dateFns.getHours(new Date(h)));
    }
    return [];
  }

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal">
          <div>
            {!this.state.done && !this.state.survey && !this.state.code && (
              <>
                <h3>Request Tour</h3>
                <form>
                  {this.global.dsUser ? null : (
                    <>
                      <label htmlFor="displayName">Name *</label>
                      <input
                        name="displayName"
                        id="displayName"
                        type="text"
                        className="form-control"
                        placeholder="Your Name..."
                        value={this.state.displayName}
                        onChange={e => {
                          this.setState({
                            [e.target.name]: e.target.value
                          });
                        }}
                      />
                      <label htmlFor="email">Email *</label> <br />
                      <input
                        name="email"
                        id="email"
                        type="email"
                        value={this.state.email}
                        placeholder="Your Email..."
                        className="form-control"
                        onChange={e => {
                          this.setState({
                            [e.target.name]: e.target.value
                          });
                        }}
                      />
                      <label htmlFor="requestPhone">
                        Mobile / Cell Phone *
                      </label>{" "}
                      <br />
                      <input
                        name="requestPhone"
                        id="requestPhone"
                        value={this.state.requestPhone}
                        type="test"
                        placeholder="Your Phone..."
                        className="form-control"
                        onChange={e => {
                          this.setState({
                            [e.target.name]: e.target.value
                          });
                        }}
                      />
                      <label htmlFor="requestMessage">
                        Message to the educator : (Optional)
                      </label>
                      <textarea
                        rows="4"
                        cols="20"
                        name="requestMessage"
                        id="requestMessage"
                        type="text"
                        placeholder="Enter your message..."
                        className="form-control"
                        value={this.state.requestMessage}
                        onChange={e => {
                          this.setState({
                            [e.target.name]: e.target.value
                          });
                        }}
                      />
                    </>
                  )}

                  {!this.state.otherShown ? (
                    <>
                      <label htmlFor="requestedDate">Date Requested *</label>
                      <DatePicker
                        selected={this.state.requestedDate}
                        minDate={new Date("2020-04-01T04:00:00.000Z")}
                        maxDate={
                          new Date(Date.now() + 21 * 24 * 60 * 60 * 1000)
                        }
                        onChange={date =>
                          this.setState({ requestedDate: date })
                        }
                        dateFormat="MMMM d, yyyy"
                        className="w-100 form-control"
                        placeholderText="Date Requested"
                        filterDate={this.filterDate}
                        name="requestedDate"
                        autoComplete="off"
                      />

                      <label htmlFor="requestedTime">Requested Time *</label>
                      <select
                        name="requestedTime"
                        id="requestedTime"
                        className="w-100 form-control"
                        disabled={!this.state.requestedDate}
                        onChange={e =>
                          this.setState({ [e.target.name]: e.target.value })
                        }
                      >
                        <option value="">Please select a time...</option>
                        {this.filterTimes().map((t, i) => (
                          <option value={parseInt(t)} key={i}>
                            {dateFns.format(
                              new Date().setHours(t, 0, 0),
                              "hh:mm a"
                            )}
                          </option>
                        ))}
                      </select>

                      <div
                        className="w-100 text-center mt-5"
                        style={{
                          fontSize: "9pt",
                          fontStyle: "italic",
                          color: "#2750CC"
                        }}
                        onClick={() =>
                          this.setState({
                            otherShown: true,
                            requestedDate: "",
                            requestedTime: "",
                            isWithinSetTime: false
                          })
                        }
                      >
                        Or select a time outside of the Educator's availability!
                        <i className="ml-3 fas fa-arrow-right"></i>
                      </div>
                    </>
                  ) : (
                    <>
                      <label htmlFor="requestedDate">Date Requested</label>
                      <DatePicker
                        selected={this.state.requestedDate}
                        minDate={new Date("2020-04-01T04:00:00.000Z")}
                        maxDate={
                          new Date(Date.now() + 21 * 24 * 60 * 60 * 1000)
                        }
                        onChange={date =>
                          this.setState({ requestedDate: date })
                        }
                        dateFormat="MMMM d, yyyy"
                        className="w-100 form-control"
                        autoComplete="off"
                        placeholderText="Date Requested"
                      />

                      <label htmlFor="requestedTime">Reqested Time</label>
                      <select
                        name="requestedTime"
                        id="requestedTime"
                        className="w-100 form-control"
                        disabled={!this.state.requestedDate}
                        onChange={e =>
                          this.setState({ [e.target.name]: e.target.value })
                        }
                      >
                        <option value="">Please select a time...</option>
                        {Array.from(Array(16).keys()).map((t, i) => (
                          <option value={parseInt(t) + 7} key={i}>
                            {dateFns.format(
                              new Date().setHours(parseInt(t) + 7, 0, 0),
                              "hh:mm a"
                            )}
                          </option>
                        ))}
                      </select>

                      <div
                        className="w-100 text-center mt-5"
                        style={{
                          fontSize: "9pt",
                          fontStyle: "italic",
                          color: "#2750CC"
                        }}
                        onClick={() =>
                          this.setState({
                            otherShown: false,
                            requestedDate: "",
                            requestedTime: "",
                            isWithinSetTime: true
                          })
                        }
                      >
                        <i className="mr-3 fas fa-arrow-left"></i>Or select a
                        time inside of the Educator's availability
                      </div>
                    </>
                  )}

                  <div className="modal-footer">
                    <button
                      onClick={this.props.onClose}
                      className="mx-2 backbtn btn profile-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      onClick={this.requestTour}
                      className="btn profile-btn"
                    >
                      Request Tour
                    </button>
                  </div>
                </form>
              </>
            )}
            {this.state.survey && (
              <>
                <h3>Please fill this quick survey to continue !</h3>
                <Survey
                  submit={info => this.surveyForm(info)}
                  back={() =>
                    this.setState({
                      survey: false
                    })
                  }
                />
              </>
            )}
            {!this.state.survey && this.state.code && (
              <>
                <h3>Verify your phone</h3>
                <h4
                  style={{
                    textAlign: "center",
                    color: "red",
                    display: this.state.codeError ? "block" : "none"
                  }}
                >
                  The code entered is invalid!
                </h4>
                <p>
                  Hey {this.state.displayName}, we just sent a verification code
                  to : {this.state.requestPhone}.
                </p>
                <p>Please enter the code below to send your tour request :</p>
                <form onSubmit={e => this.verifyCode(e)}>
                  <input
                    name="phoneCode"
                    type="text"
                    placeholder="Enter your verification code here..."
                    className="form-control"
                    value={this.state.otp}
                    onChange={e => this.setState({ otp: e.target.value })}
                  />
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="mx-2 backbtn btn profile-btn"
                      onClick={() =>
                        this.setState({
                          code: false,
                          survey:
                            this.state.survey === undefined ? undefined : true
                        })
                      }
                    >
                      Back
                    </button>
                    <button type="submit" className="btn profile-btn">
                      Next
                    </button>
                  </div>
                </form>
              </>
            )}

            {this.state.done && (
              <>
                <h3>Thank you for requesting a tour!</h3>
                <p>
                  The educator will review your tour request and get back to you
                  shortly. Please keep an eye on your emails or sms, so you know
                  when your tour gets accepted.
                </p>
              </>
            )}
            <IconButton
              style={{ position: "absolute", right: "0", top: "0" }}
              onClick={_ => this.props.onClose()}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RequestTourrmationModal);
