import React from "reactn";
import { TabbedTable } from "../../UI";

class DSFeesTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tabs = ["Recent"];
    this.headings = {
      Recent: [
        { id: "educator", label: "EDUCATOR" },
        { id: "program", label: "PROGRAM" },
        { id: "contractPeriod", label: "CONTRACT PERIOD" },
        { id: "feePercentage", label: "FEE PERCENTAGE" },
        { id: "noOfSlots", label: "NUMBER OF SLOTS" },
        { id: "action", label: "" }
      ]
    };
    this.data = {
      Recent: [
        {
          educator: "Sarah Lin",
          program: "Ladybug Dreamschool",
          contractPeriod: "Jan 3 2019 - Jan 3 2020",
          feePercentage: "5%",
          noOfSlots: "3/13"
        }
      ]
    };
    this.actions = [{ name: "EDIT", action: this.editFee }];
  }
  editFee = async row => {};

  render() {
    return (
      <div>
        <TabbedTable
          tabs={this.tabs}
          headings={this.headings}
          data={this.data}
          actions={this.actions}
        />
      </div>
    );
  }
}

export default DSFeesTable;
