import React from "reactn";
import { TabbedTable } from "../../UI";
import PrescreeningAcceptModal from "./PrescreeningAcceptModal";
import axios from "axios";
import { format } from "date-fns";
import ComModal from "./EduCommentModal";
class PreScreeningTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      acceptModalShown: false,
      modalData: null,
      rejectModalShown: false,
      data: {
        NEW: [],
        GHOSTED: [],
        PAUSED: [],
        "LOST INTEREST": []
      },
      allData: {
        NEW: [],
        GHOSTED: [],
        PAUSED: [],
        "LOST INTEREST": []
      }
    };
    this.tabs = ["NEW", "LOST INTEREST", "GHOSTED", "PAUSED"];
    this.headings = {
      NEW: [
        { id: "fullName", label: "Educator Name" },
        { id: "email", label: "Email" },
        { id: "phoneNumber", label: "Phone Number" },
        { id: "postalCode", label: "Postal Code" },
        {
          id: "createdAt",
          label: "Submission Date",
          customCell: this.submissionDateCell
        },
        { id: "address", label: "Address" },
        { id: "homeStructure", label: "Home Structure" },
        { id: "ownership", label: "Ownership" },
        { id: "outdoor", label: "Outdoor" },
        { id: "experience", label: "Experience" },
        { id: "smartPhone", label: "Smart Phone" },
        { id: "action", label: "Action(s)" }
      ],
      "LOST INTEREST": [
        { id: "fullName", label: "Educator Name" },
        { id: "email", label: "Email" },
        { id: "phoneNumber", label: "Phone Number" },
        { id: "postalCode", label: "Postal Code" },
        {
          id: "createdAt",
          label: "Submission Date",
          customCell: this.submissionDateCell
        },
        { id: "address", label: "Address" },
        { id: "homeStructure", label: "Home Structure" },
        { id: "ownership", label: "Ownership" },
        { id: "outdoor", label: "Outdoor" },
        { id: "experience", label: "Experience" },
        { id: "smartPhone", label: "Smart Phone" },
        { id: "action", label: "Action(s)" }
      ],
      GHOSTED: [
        { id: "fullName", label: "Educator Name" },
        { id: "email", label: "Email" },
        { id: "phoneNumber", label: "Phone Number" },
        { id: "postalCode", label: "Postal Code" },
        {
          id: "createdAt",
          label: "Submission Date",
          customCell: this.submissionDateCell
        },
        {
          id: "actionable",
          label: "ACTIONS",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button onClick={() => this.acceptEducator(r)}>Accept</button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.changeAction(r, 2)}
                >
                  Pause
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={_ => this.viewComments(r)}
                >
                  Notes
                </button>
              </td>
            );
          }
        }
      ],
      PAUSED: [
        { id: "fullName", label: "Educator Name" },
        { id: "email", label: "Email" },
        { id: "phoneNumber", label: "Phone Number" },
        { id: "postalCode", label: "Postal Code" },
        {
          id: "createdAt",
          label: "Submission Date",
          customCell: this.submissionDateCell
        },
        {
          id: "actionable",
          label: "ACTIONS",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button onClick={() => this.acceptEducator(r)}>Accept</button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={_ => this.viewComments(r)}
                >
                  Notes
                </button>
              </td>
            );
          }
        }
      ]
    };

    this.actions = [
      { name: "Notes", action: r => this.viewComments(r) },
      { name: "Accept", action: this.acceptEducator },
      { name: "Ghosted", action: row => this.changeAction(row, 1) },
      { name: "Pause", action: row => this.changeAction(row, 2) },
      { name: "Lost Interest", action: row => this.changeAction(row, 3) }
    ];

    this.filterApplications = this.filterApplications.bind(this);
  }

  viewComments = async row => {
    this.setState({ commentsModalShown: true, modalData: row });
  };

  filterApplications = e => {
    const v = e.target.value.trim().toLowerCase();
    this.setState({
      data: {
        NEW: this.state.allData.filter(
          d =>
            d.action === 0 &&
            (d.fullName.toLowerCase().includes(v) ||
              d.email.toLowerCase().includes(v))
        ),
        GHOSTED: this.state.allData.filter(
          d =>
            d.action === 1 &&
            (d.fullName.toLowerCase().includes(v) ||
              d.email.toLowerCase().includes(v))
        ),
        PAUSED: this.state.allData.filter(
          d =>
            d.action === 2 &&
            (d.fullName.toLowerCase().includes(v) ||
              d.email.toLowerCase().includes(v))
        ),
        "LOST INTEREST": this.state.allData.filter(
          d =>
            d.action === 3 &&
            (d.fullName.toLowerCase().includes(v) ||
              d.email.toLowerCase().includes(v))
        )
      }
    });
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/prescreen`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setState({
        data: {
          NEW: res.data.data.filter(d => {
            return d.action === 0 && !d.accepted;
          }),
          GHOSTED: res.data.data.filter(d => {
            return d.action === 1 && !d.accepted;
          }),
          PAUSED: res.data.data.filter(d => {
            return d.action === 2 && !d.accepted;
          }),
          "LOST INTEREST": res.data.data.filter(d => {
            return d.action === 3 && !d.accepted;
          })
        },
        allData: res.data.data
      });

      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  acceptEducator = async row => {
    this.setState({ acceptModalShown: true, modalData: row });
  };

  rejectEducator = async row => {
    this.setState({ rejectModalShown: true, modalData: row });
  };

  changeAction = (row, action) => {
    const ep = `${process.env.REACT_APP_API}/adm/prescreen/actioned`;
    axios.post(ep, {
      ...row,
      action: action
    });
    const oldArray = [...this.state.allData];
    oldArray[oldArray.indexOf(oldArray.find(e => e.id === row.id))].action =
      action;
    this.setState({
      data: {
        NEW: oldArray.filter(d => {
          return d.action === 0 && !d.accepted;
        }),
        "LOST INTEREST": oldArray.filter(d => {
          return d.action === 3 && !d.accepted;
        }),
        GHOSTED: oldArray.filter(d => {
          return d.action === 1 && !d.accepted;
        }),
        PAUSED: oldArray.filter(d => {
          return d.action === 2 && !d.accepted;
        })
      },
      allData: oldArray
    });
  };

  submissionDateCell = (r, i) => {
    return (
      <td key={`${i}-sdc`}>{format(new Date(r.createdAt), "LLL dd, yyyy")}</td>
    );
  };

  render() {
    return (
      <>
        <PrescreeningAcceptModal
          open={this.state.acceptModalShown}
          data={this.state.modalData}
          onClose={accept => {
            if (accept === true) {
              this.setState({
                data: {
                  ...this.state.data,
                  NEW: this.state.data.NEW.filter(
                    d => d.id !== this.state.modalData.id
                  )
                }
              });
            }
            this.setState({
              acceptModalShown: false,
              modalData: null
            });
          }}
        />
        <div className="cont">
          <ComModal
            open={this.state.commentsModalShown}
            data={this.state.modalData}
            prescreen={true}
            onClose={() =>
              this.setState({
                commentsModalShown: false,
                modalData: null
              })
            }
          />
          <input
            type="text"
            onChange={this.filterApplications}
            style={{
              marginBottom: 15,
              width: "100%",
              borderRadius: 5,
              border: "2px solid #E3E6F0",
              fontSize: "13px",
              padding: 3,
              outline: 0
            }}
            placeholder="Filter by name or email..."
          />
          <TabbedTable
            tabs={this.tabs}
            headings={this.headings}
            data={this.state.data}
            actions={this.actions}
          />
        </div>
      </>
    );
  }
}

export default PreScreeningTable;
