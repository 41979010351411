import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";

const ReassignRoomsModal = ({ rooms, user, onClose }) => {
  const [selectedRoom, setSelectedRoom] = useState(null);

  const submitReassignRoomAssignment = async e => {
    e.preventDefault();
    if (!selectedRoom) {
      toast.error("Please select a room", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return;
    }

    const data = {
      room: selectedRoom,
      assignedUser: user
    };

    const ep = `${process.env.REACT_APP_API}/partners/rooms/assignment/update`;
    const resp = await axios.post(ep, data);
    if (resp.data) {
      if (resp.data.success) {
        toast(resp.data.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        onClose();
      } else {
        toast.error(resp.data.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    } else {
      toast.error("Something went wrong when trying to submit data", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };
  if (rooms.length == 0) {
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px",
                marginBottom: ".5rem"
              }}
            >
              <h3>Assign Room</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={onClose}
              >
                <Close />
              </IconButton>
            </div>
            There are no rooms to select from.
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="request-modal-cont">
      <div className="request-modal lesspadding">
        <div style={{ display: "block" }}>
          <img
            src={ModalBanner}
            alt="Modal Banner"
            style={{
              height: "40px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "48px",
              marginBottom: ".5rem"
            }}
          >
            <h3>Assign Room</h3>
            <IconButton
              style={{
                borderRadius: "50%",
                color: "#2880FF",
                marginBottom: "1rem"
              }}
              onClick={_ => onClose()}
            >
              <Close />
            </IconButton>
          </div>

          <form onSubmit={submitReassignRoomAssignment}>
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                htmlFor="selectRoom"
                style={{ width: "25%", textAlign: "left" }}
              >
                Select Room
              </label>
              <Dropdown
                placeholder="Pick a Room"
                fluid
                selection
                onChange={(_, { value }) => {
                  setSelectedRoom(rooms.filter(room => room.id === value)[0]);
                }}
                className="form-control"
                value={selectedRoom ? selectedRoom.id : null}
                options={rooms.map(room => {
                  return {
                    text: room.name,
                    value: room.id
                  };
                })}
              />
            </div>
            <div className="modal-footer" style={{ marginTop: "25px" }}>
              <button type="submit" className="btn profile-btn">
                Reassign
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ReassignRoomsModal.propTypes = {
  rooms: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ReassignRoomsModal;
