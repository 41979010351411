import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
class EditSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      section: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async submitForm(e) {
    e.preventDefault();
    if (this.state.section.trim()) {
      this.props.editSection(this.state.section);
      this.props.onClose();
    }
  }

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div>
            <h3>Edit Section</h3>
            <form onSubmit={e => this.submitForm(e)}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Section Name *
                </label>
                <input
                  name="section"
                  id="organization"
                  type="text"
                  className="form-control"
                  placeholder={this.props.name}
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
              </div>

              <div className="modal-footer">
                <button type="submit" className="btn profile-btn">
                  Edit
                </button>
              </div>
            </form>

            <IconButton
              style={{ position: "absolute", right: "0", top: "0" }}
              onClick={_ => this.props.onClose()}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditSection);
