import React from "reactn";
import { TabbedTable } from "../../UI";

class TourRequestTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.tabs = ["MY PREFERENCE", "VISITOR PREFERENCE"];
    this.headings = {
      "MY PREFERENCE": [
        { id: "visitor", label: "Visitor" },
        { id: "date", label: "Date" },
        { id: "action", label: "", customStyle: { width: 180 } }
      ],
      "VISITOR PREFERENCE": [
        { id: "visitor", label: "Visitor" },
        { id: "date", label: "Date" },
        { id: "action", label: "", customStyle: { width: 180 } }
      ]
    };
    this.data = {
      "MY PREFERENCE": [],
      "VISITOR PREFERENCE": []
    };
    this.actions = [
      { name: "ACCEPT", action: this.acceptTour },
      { name: "DENY", action: this.denyTour }
    ];
  }

  acceptTour = async row => {};

  denyTour = async row => {};

  render() {
    return (
      <div className="cont">
        <TabbedTable
          tabs={this.tabs}
          headings={this.headings}
          data={this.data}
          actions={this.actions}
        />
      </div>
    );
  }
}

export default TourRequestTable;
