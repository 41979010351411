import React, { useGlobal, useEffect, useState } from "reactn";
import "../../../assets/css/componentSpecificCss/reportAnalysisPages.css";
import { format, parse, fromUnixTime, addDays } from "date-fns";
import _ from "lodash";
import axios from "axios";

import { useDateFilter, CENTS_IN_DOLLAR } from "../../../providers/reports";
import {
  DateFilter,
  ReportContainer,
  ReportTable
} from "../../../components/UI/reportsAnalytics";

import {
  showPlan,
  getPlusMinus,
  getSubtotal,
  formatMoney
} from "../../../utilities/reportsAnalytics";

const TransactionsReport = () => {
  const orgData = useGlobal("organizationInfo")[0];
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");

  const [dateFrom, dateTo, setDateFrom, setDateTo] = useDateFilter();

  const [orders, setOrders] = useState([]);
  const [displayOrders, setDisplayOrders] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalInvoiceRevenue, setTotalInvoiceRevenue] = useState(0);

  const [totalRefunds, setTotalRefunds] = useState(0);

  useEffect(() => {
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" },
      { label: "Transactions Report", to: "/reports/transactions-report" }
    ]);
    fetchInitialData();
  }, []);

  useEffect(() => {
    setDisplayOrders(getDisplayableData(orders));
    setTotalRevenue(getTotalRevenue(orders));
    setTotalInvoiceRevenue(getTotalInvoiceRevenue(orders));
  }, [dateFrom, dateTo]);

  const fetchInitialData = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/order-tracking`;

    const result = await axios.get(ep);

    const allOrders = [
      ...result.data.data.Once.payments,
      ...result.data.data.Recurring.payments
    ];

    setOrders(allOrders);
    setDisplayOrders(getDisplayableData(allOrders));
    setTotalRevenue(getTotalRevenue(allOrders));
    setTotalInvoiceRevenue(getTotalInvoiceRevenue(allOrders));

    setTotalRefunds(
      formatMoney(
        allOrders.reduce(
          (prev, curr) => prev + parseInt(curr.refundedAmount),
          0
        ) / 100
      )
    );
  };

  const cleanData = orders => {
    return orders.map(o => ({
      customer: o.purchaser,
      date: o.date,
      listing: o.item,
      modifiers: getModifierString(o),
      total: o.total
    }));
  };

  const getDisplayableData = orders => {
    const dataWithinRange = orders.filter(
      e =>
        new Date(e.date) > new Date(dateFrom) &&
        new Date(e.date) < addDays(new Date(dateTo), 1) &&
        e.type !== "Invoice"
    );
    const sortedData = dataWithinRange.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    const cleanedData = cleanData(sortedData);
    const formatDateTotal = cleanedData.map(o => ({
      ...o,
      date: format(new Date(o.date), "MMM d, yyyy"),
      total: formatMoney(o.total / 100)
    }));
    return formatData(formatDateTotal);
  };

  const formatData = orders =>
    orders.map(o => [o.customer, o.date, o.listing, o.modifiers, o.total]);

  const getModifierString = order => {
    // modifiers are: tax, credit card fee, promotion, coupon, addOnSemesterItemAmount, gift discount, discount amount, refund
    return Object.keys(order.modifiers)
      .map(modifier =>
        parseInt(order.modifiers[modifier])
          ? `${getModifierName(modifier)} (${formatMoney(
              order.modifiers[modifier] / 100
            )})`
          : ""
      )
      .filter(e => e !== "")
      .join(" / ");
  };

  // tax -> Tax
  // creditCardFee -> Credit Card Fee
  // promotion -> Promotion
  // coupon -> Coupon
  // addOnSemesterItem -> Add On Semester Item
  // giftDiscount -> Gift Discount
  // discount -> Discount
  // refunded -> Refunded
  const getModifierName = modifier => {
    switch (modifier) {
      case "tax":
        return "Tax";
      case "creditCardFee":
        return "Credit Card Fee";
      case "promotion":
        return "Promotion";
      case "coupon":
        return "Coupon";
      case "addOnSemesterItem":
        return "Add On Semester Item";
      case "giftDiscount":
        return "Gift Discount";
      case "discount":
        return "Discount";
      case "refunded":
        return "Refunded";
      default:
        return "";
    }
  };

  const getRevenueChange = orders => {
    let revenueChange = 0;
    orders.forEach(order => {
      revenueChange += parseInt(order.modifiers.tax) || 0;
      revenueChange += parseInt(order.modifiers.creditCardFee) || 0;
      revenueChange -= parseInt(order.modifiers.promotion) || 0;
      revenueChange -= parseInt(order.modifiers.coupon) || 0;
      revenueChange += parseInt(order.modifiers.addOnSemesterItem) || 0;
      revenueChange -= parseInt(order.modifiers.giftDiscount) || 0;
      revenueChange -= parseInt(order.modifiers.discount) || 0;
      revenueChange -= parseInt(order.modifiers.refunded) || 0;
    });

    return revenueChange;
  };

  /**
   * Gets total revenue by aggregating all order data.
   *
   * @returns {Number} Total revenue.
   */
  const getTotalRevenue = orders => {
    const dataWithinRange = orders.filter(
      e =>
        new Date(e.date) > new Date(dateFrom) &&
        new Date(e.date) < addDays(new Date(dateTo), 1) &&
        e.type !== "Invoice"
    );
    const totalRevenue = dataWithinRange.reduce(
      (a, c) => a + parseInt(c.total),
      0
    );

    return formatMoney(totalRevenue / CENTS_IN_DOLLAR);
  };

  const getTotalInvoiceRevenue = (orders) => {
    console.log("DATES: ", new Date(dateFrom), new Date(dateTo));
    const invoiceRevenue = orders
      .filter(
        e =>
          e.type === "Invoice" &&
          new Date(e.date) >= new Date(dateFrom) &&
          new Date(e.date) <= addDays(new Date(dateTo), 1)
      )
      .reduce((prev, curr) => prev + parseInt(curr.total), 0);

    return formatMoney(invoiceRevenue / CENTS_IN_DOLLAR);
  };

  const csvHeaders = [
    { label: "Customer Name", key: "name" },
    { label: "Transaction Datetime", key: "dateTime" },
    { label: "Listing", key: "listingName" },
    { label: "Promo", key: "promo" },
    { label: "Coupon", key: "coupon" },
    { label: "Refunds", key: "refund" },
    { label: "Tax", key: "tax" },
    { label: "Credit Card Fee", key: "creditCardFee" },
    { label: "Add On Semester Item", key: "addOnSemesterItem" },
    { label: "Amount", key: "amount" }
  ];

  /**
   * Gets the CSV for the CSVLink component to use.
   *
   * @returns {Array<Object>} Each property of each object corresponds to a column.
   */
  const getCsvData = () => {
    const dataWithinRange = orders
      .filter(
        e =>
          new Date(e.date) > new Date(dateFrom) &&
          new Date(e.date) < addDays(new Date(dateTo), 1)
      )
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    return dataWithinRange.map(row => ({
      name: row.purchaser,
      dateTime: row.date,
      listingName: row.item,
      promo: row.modifiers.promotion,
      coupon: row.modifiers.coupon,
      refund: row.modifiers.refunded,
      tax: row.modifiers.tax,
      creditCardFee: row.modifiers.creditCardFee,
      addOnSemesterItem: row.modifiers.addOnSemesterItem,
      amount: row.total
    }));
  };

  const reportSubtitles = [
    orgData.organization_title,
    `For ${format(parse(dateFrom, "yyyy-MM-dd", new Date()), "MMM d, yyyy")}
    - 
    ${format(parse(dateTo, "yyyy-MM-dd", new Date()), "MMM d, yyyy")} 
    `
  ];

  return (
    <div className="admin">
      <div className="container-fluid adminprofiles">
        <div className="row cont">
          <div className="col-md-6">
            <h1>Transaction Report</h1>
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-section">
            <DateFilter
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
            />
          </div>
        </div>
        <ReportContainer
          title="Transaction Report"
          subtitles={reportSubtitles}
          csvHeaders={csvHeaders}
          csvData={getCsvData()}
          fileName={`Transaction_${dateFrom}-${dateTo}.csv`}
          isLarge
        >
          <div className="report-table-large">
            <ReportTable
              headings={[
                { label: "Summary", className: "table-item-bold" },
                { label: "", className: "table-item-money" }
              ]}
              body={[
                ["Total Listings Revenue for Time Period", totalRevenue],
                ["Total Invoice Revenue for Time Period", totalInvoiceRevenue],
                ["Total Refunds", totalRefunds]
              ]}
            />
            <ReportTable
              headings={[
                { label: "Customer" },
                { label: "Date" },
                { label: "Item" },
                { label: "+ / -" },
                {
                  label: "Total",
                  className: "table-item-money",
                  style: { textAlign: "right" }
                }
              ]}
              // body={getTransactionData()}
              body={displayOrders}
            />
          </div>
        </ReportContainer>
      </div>
    </div>
  );
};

export default TransactionsReport;
// import React, { useGlobal, useEffect } from "reactn";
// import "../../../assets/css/componentSpecificCss/reportAnalysisPages.css";
// import { format, parse, fromUnixTime } from "date-fns";
// import _ from "lodash";

// import {
//   useAggregatedOrders,
//   useDateFilter,
//   MILLISECONDS_IN_SECONDS,
//   CENTS_IN_DOLLAR
// } from "../../../providers/reports";
// import {
//   DateFilter,
//   ReportContainer,
//   ReportTable
// } from "../../../components/UI/reportsAnalytics";

// import {
//   showPlan,
//   getPlusMinus,
//   getSubtotal,
//   formatMoney,
//   getRevenueChange
// } from "../../../utilities/reportsAnalytics";

// const TransactionsReport = () => {
//   const orgData = useGlobal("organizationInfo")[0];
//   const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");

//   const [dateFrom, dateTo, setDateFrom, setDateTo] = useDateFilter();

//   const allOrders = useAggregatedOrders();

//   useEffect(() => {
//     setPartnerPathway([
//       ...partnerPathway.slice(0, 1),
//       { label: "Reports & Analytics", to: "/reporting" },
//       { label: "Transactions Report", to: "/reports/transactions-report" }
//     ]);
//   }, []);
//   /**
//    * Function used to filter orders.
//    *
//    * @returns {Boolean} Whether or not the order should be displayed/considered.
//    *
//    * @param {Object} order The order to filter.
//    */
//   const filterOrder = order => {
//     // Converts the YYYY-MM-DD date filter dates to unix timestamps.
//     const dateFromUnix = new Date(dateFrom).getTime() / MILLISECONDS_IN_SECONDS;
//     const tempDateTo = new Date(dateTo);
//     // Offset by a day to provide the correct filter.
//     const dateToUnix =
//       tempDateTo.setDate(tempDateTo.getDate() + 1) / MILLISECONDS_IN_SECONDS;
//     return order.created > dateFromUnix && order.created < dateToUnix;
//   };

//   /**
//    * Displays the table rows for the revenue summary table, for a specific listing type.
//    *
//    * @returns {JSX}
//    */
//   const getTransactionData = () => {
//     return filteredOrders.map(row => {
//       const manualStatus = row.paid ? "(Manual - Paid)" : "(Manual - Pending)";
//       const productName =
//         row.product.program_name ||
//         row.product.event_title ||
//         row.product.membership_name;
//       // additional fees / discounts
//       const plusMinus = getPlusMinus(row);
//       const modifiers = plusMinus
//         .map(obj => {
//           return `${obj.label} (${obj.text})`;
//         })
//         .join(" / ");

//       return [
//         row.customer_name,
//         format(fromUnixTime(row.created), "MMM d, yy"),
//         productName,
//         `${showPlan(row)} ${row.manual ? manualStatus : ""}`,
//         modifiers,
//         formatMoney(row.revenue / CENTS_IN_DOLLAR)
//       ];
//     });
//   };

//   /**
//    * Gets total revenue by aggregating all order data.
//    *
//    * @returns {Number} Total revenue.
//    */
//   const getTotalRevenue = () => {
//     const totalRevenue = filteredOrders.reduce((a, c) => a + c.revenue, 0);
//     const totalRevenueChange = getRevenueChange(filteredOrders);

//     return formatMoney((totalRevenue + totalRevenueChange) / CENTS_IN_DOLLAR);
//   };

//   const csvHeaders = [
//     { label: "Customer Name", key: "name" },
//     { label: "Transaction Datetime", key: "dateTime" },
//     { label: "Listing", key: "listingName" },
//     { label: "Product Description", key: "description" },
//     { label: "Manual", key: "manual" },
//     { label: "Promo", key: "promo" },
//     { label: "Subtotal", key: "subtotal" },
//     { label: "Coupon", key: "coupon" },
//     { label: "Refunds", key: "refund" },
//     { label: "Tax", key: "tax" },
//     { label: "Amount", key: "amount" }
//   ];

//   /**
//    * Gets the CSV for the CSVLink component to use.
//    *
//    * @returns {Array<Object>} Each property of each object corresponds to a column.
//    */
//   const getCsvData = () => {
//     return filteredOrders.map(row => {
//       const manualStatus = row.paid ? "Paid" : "Pending";
//       const productName =
//         row.product.program_name ||
//         row.product.event_title ||
//         row.product.membership_name;

//       const modifiers = getPlusMinus(row);

//       const findModifierByLabel = label => {
//         const modifier = modifiers.find(mod => mod.label === label);
//         return modifier ? modifier.text : "";
//       };

//       return {
//         name: row.customer_name,
//         dateTime: format(fromUnixTime(row.created), "yyyy-MM-dd'T'HH:mm:ss"),
//         listingName: productName,
//         description: showPlan(row),
//         manual: row.manual ? manualStatus : "",
//         promo: findModifierByLabel("Promo"),
//         subtotal: getSubtotal(row),
//         coupon: findModifierByLabel("Coupon"),
//         tax: findModifierByLabel("Tax"),
//         refund: findModifierByLabel("Refund"),
//         amount: formatMoney(row.revenue / CENTS_IN_DOLLAR)
//       };
//     });
//   };

//   const filteredOrders = allOrders
//     .filter(order => {
//       return filterOrder(order);
//     })
//     .sort((order1, order2) => {
//       return order2.created - order1.created;
//     });

//   const reportSubtitles = [
//     orgData.organization_title,
//     `For ${format(parse(dateFrom, "yyyy-MM-dd", new Date()), "MMM d, yyyy")}
//     -
//     ${format(parse(dateTo, "yyyy-MM-dd", new Date()), "MMM d, yyyy")}
//     `
//   ];

//   return (
//     <div className="admin">
//       <div className="container-fluid adminprofiles">
//         <div className="row cont">
//           <div className="col-md-6">
//             <h1>Transaction Report</h1>
//           </div>
//         </div>

//         <div className="filter-container">
//           <div className="filter-section">
//             <DateFilter
//               dateFrom={dateFrom}
//               setDateFrom={setDateFrom}
//               dateTo={dateTo}
//               setDateTo={setDateTo}
//             />
//           </div>
//         </div>
//         <ReportContainer
//           title="Transaction Report"
//           subtitles={reportSubtitles}
//           csvHeaders={csvHeaders}
//           csvData={getCsvData()}
//           fileName={`Transaction_${dateFrom}-${dateTo}.csv`}
//           isLarge
//         >
//           <div className="report-table-large">
//             <ReportTable
//               headings={[
//                 { label: "Summary", className: "table-item-bold" },
//                 { label: "", className: "table-item-money" }
//               ]}
//               body={[["Total Revenue for Time Period", getTotalRevenue()]]}
//             />
//             <ReportTable
//               headings={[
//                 { label: "Customer" },
//                 { label: "Date" },
//                 { label: "Listing" },
//                 { label: "Product" },
//                 { label: "+ / -" },
//                 {
//                   label: "Total",
//                   className: "table-item-money",
//                   style: { textAlign: "right" }
//                 }
//               ]}
//               body={getTransactionData()}
//             />
//           </div>
//         </ReportContainer>
//       </div>
//     </div>
//   );
// };

// export default TransactionsReport;
