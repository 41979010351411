import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalTemplate } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import { TITLE } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, RED_BUTTON, WHITE } from "./baseModal/colours";

/**
 * Modal can be used as an archive/unarchive confirmation modal.
 *
 * @callback onSubmit Function executed upon user confirmation
 * @callback onClose Function executed when modal is closed.
 *
 * @param {Boolean} toArchive true means archival, false means unarchival.
 * @param {Boolean} confirmHeader Optional custom modal header.
 * @param {String | JSX} modalBody Optional custom modal body.
 *
 * @param {Boolean} isPermanent Whether the deletion is permanent. Will show extra text prompt.
 * @param {Boolean} notAllowed If true, there will be no confirmation button.
 *
 */

const ConfirmInactiveModal = ({
  onSubmit,
  onClose,
  confirmHeader,
  toArchive,
  modalBody,
  isPermanent,
  notAllowed,
  toStatusStage
}) => {
  const confirmationHandler = async e => {
    const successMsg = toArchive
      ? "Successfully deleted!"
      : "Successfully restored!";
    const errorMsg = toArchive ? "Could not delete." : "Could not restore.";
    try {
      e.preventDefault();
      await onSubmit();
      onClose();
      toast.success(successMsg);
    } catch (error) {
      toast.error(errorMsg);
    }
  };

  const getBaseModalFields = () => {
    const titleField = {
      type: TITLE,
      data: {
        name:
          modalBody ||
          (toArchive
            ? `Are you sure you would like to remove this listing? ${
                isPermanent
                  ? "This will be a permanent deletion, and cannot be re-archived later."
                  : ""
              }`
            : toStatusStage &&
              (toStatusStage === "Rejected" ||
                toStatusStage === "Cancelled_Unpaid")
            ? "Are you sure you would like to restore this application?"
            : "Are you sure you would like to restore this enrollment?")
      }
    };
    const fields = [];

    fields.push(titleField);

    return fields;
  };

  const getBaseModalButtons = () => {
    const button = {
      name: "Confirm",
      buttonColour: toArchive ? RED_BUTTON : BLUE_BUTTON,
      textColour: WHITE,
      handleClick: confirmationHandler
    };

    return [button];
  };

  const getBaseModalProps = () => {
    return {
      title: confirmHeader || "Confirmation",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: onClose
    };
  };

  const getNotAllowedProps = () => {
    return {
      title: confirmHeader || "Confirmation",
      fields: getBaseModalFields(),
      handleClose: onClose
    };
  };

  return (
    // <ModalTemplate heading={confirmHeader || "Confirmation"} onClose={onClose}>
    //   <form onSubmit={e => confirmationHandler(e)}>
    //     <div className="modal-main">
    //       <p className="confirmation">
    //         {modalBody ||
    //           (toArchive
    //             ? `Are you sure you would like to remove this listing? ${
    //                 isPermanent
    //                   ? "This will be a permanent deletion, and cannot be re-archived later."
    //                   : ""
    //               }`
    //             : "Are you sure you would like to restore this enrollment?")}
    //       </p>
    //     </div>
    //     <div className="modal-footer">
    //       {notAllowed || (
    //         <button
    //           type="submit"
    //           className={toArchive ? "btn backbtn deletebtn" : "btn"}
    //           onClick={confirmationHandler}
    //         >
    //           <div
    //             style={{
    //               display: "flex",
    //               alignItems: "center",
    //               justifyContent: "center"
    //             }}
    //           >
    //             <span>Confirm</span>
    //           </div>
    //         </button>
    //       )}
    //     </div>
    //   </form>
    // </ModalTemplate>
    <>
      {notAllowed ? (
        <BaseModal {...getNotAllowedProps()} />
      ) : (
        <BaseModal {...getBaseModalProps()} />
      )}
    </>
  );
};

export default withRouter(ConfirmInactiveModal);
