import React from "reactn";
import RecurringPlan from "./AddPricingPlanModal";
import SemesterPlan from "./AddPlanSemester";
import SingleSessionPlan from "./AddSingleSessionPlanModal";
import FreePlan from "./AddFreePlan";
import FreeTrialPlan from "./AddFreeTrialPlanModal";
import DropInPlan from "./AddDropInPlanModal";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  IMAGE_DROPDOWN,
  SWITCH,
  DROPDOWN
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class PickPlanModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { changed: null, selected: "" };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  typeOptions = () => {
    if (this.global.program.program_category === "Single Sessions") {
      return [
        { value: "Single Sessions", text: "Single Sessions" },
        { value: "Free Trial", text: "Free Trial"}
      ];
    } else if (this.global.program.number_of_weeks == 0) {
      return [
        { value: "Recurring", text: "Recurring" },
        { value: "Drop-In", text: "Drop-In"},
        { value: "Free Trial", text: "Free Trial"},
        { value: "Free", text: "Free" }
      ];
    } else {
      return [
        { value: "Semester", text: "Semester" },
        { value: "Drop-In", text: "Drop-In"},
        { value: "Free Trial", text: "Free Trial"},
        { value: "Free", text: "Free" }
      ];
    }
  };

  getBaseModalFields() {
    const planType = {
      type: DROPDOWN,
      data: {
        name: "Plan Type",
        required: true,
        info: "Free Trial plans are now available!",
        placeholder: "Please Select",
        value: this.state.changed,
        handleChange: e => {
          this.setState({
            changed: e.target.value
          });
        },
        choices: this.typeOptions()
      },
      validators: { validateSubmit: () => this.state.changed !== null }
    };
    return [planType];
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Select",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.setState({ selected: this.state.changed })
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Add Plan",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    console.log("this.state.selected", this.state.selected);
    console.log("props in pickplanmodal: ", this.props);
    return !this.state.selected ? (
      <BaseModal {...this.getBaseModalProps()} />
    ) : this.state.selected === "Recurring" ? (
      <RecurringPlan onClose={_ => this.props.onClose()} />
    ) : this.state.selected === "Semester" ? (
      <SemesterPlan onClose={_ => this.props.onClose()} />
    ) : this.state.selected === "Single Sessions" ? (
      <SingleSessionPlan onClose={_ => this.props.onClose()} />
    ) : this.state.selected === "Free Trial" ? (
      <FreeTrialPlan onClose={_=> this.props.onClose()} addChild={this.props.addChild}/>
    ) : this.state.selected === "Drop-In" ? (
      <DropInPlan onClose={_=> this.props.onClose()}/>
    ) : ( <FreePlan onClose={_ => this.props.onClose()} />);


// "eslint" tells me the following piece of code is unreachable. I agree!
// Just going to keep it as-is (commented-out), until a few months pass, and then delete it!
/*
    const footerContent = (
      <button
        type="submit"
        className="btn"
        onClick={_ => this.setState({ selected: this.state.changed })}
      >
        Select
      </button>
    );
    return !this.state.selected ? (
      <ModalTemplate
        heading="Add Plan"
        onClose={_ => this.props.onClose()}
        footerContent={footerContent}
      >
        <div>
          <form>
            <ModalFields.select
              label="Plan Type"
              name="plan_type"
              required
              onChange={e => {
                this.setState({
                  changed: e.target.value
                });
              }}
              value={this.state.changed}
            >
              {this.global.program.program_category === "Single Sessions" ? (
                <>
                  <option value="Single Sessions">Single Sessions</option>
                </>
              ) : this.global.program.number_of_weeks == 0 ? (
                <>
                  <option value="Recurring">Recurring</option>
                  <option value="Free">Free</option>
                </>
              ) : (
                <>
                  <option value="Semester">Semester</option>
                  <option value="Free">Free</option>
                </>
              )}
            </ModalFields.select>
          </form>
        </div>
      </ModalTemplate>
    ) : this.state.selected === "Recurring" ? (
      <RecurringPlan onClose={_ => this.props.onClose()} />
    ) : this.state.selected === "Semester" ? (
      <SemesterPlan onClose={_ => this.props.onClose()} />
    ) : this.state.selected === "Single Sessions" ? (
      <SingleSessionPlan onClose={_ => this.props.onClose()} />
    ) : (
      <FreePlan onClose={_ => this.props.onClose()} />
    );
*/
  }
}

export default withRouter(PickPlanModal);
