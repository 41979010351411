import React from "reactn";
import MyHomeRegularForm from "./forms/myHomeRegular";
import MyHomeInfoForm from "./forms/myHomeInfo";

class MyHomePage extends React.Component {
  handChangeRegulars = e => {
    if (
      !this.global.profile.applicationSubmitted ||
      this.global.dsUser.accountType === "Admin"
    ) {
      const vals = this.global.myApplicationHomeRegulars.map(c =>
        c.id === e.id ? e : c
      );
      this.setGlobal({ myApplicationHomeRegulars: vals });
    }
  };

  handRemove = e => {
    if (
      !this.global.profile.applicationSubmitted ||
      this.global.dsUser.accountType === "Admin"
    )
      this.dispatch.removeApplicationHomeRegular(e);
  };

  render() {
    return (
      <div className="cont boxes-row mt-50px">
        <MyHomeInfoForm />
        {this.global.myApplicationHomeRegulars.map((reg, i) => (
          <MyHomeRegularForm
            key={i}
            vals={reg}
            itemIndex={i}
            onChange={this.handChangeRegulars}
            onRemove={this.handRemove}
          />
        ))}
        <button
          className="addchild"
          onClick={() =>
            !this.global.profile.applicationSubmitted ||
            this.global.dsUser.accountType === "Admin"
              ? this.dispatch.addApplicationHomeRegular()
              : null
          }
        >
          <h3>
            Add Visitor
            <br />
            or Resident
          </h3>
        </button>
      </div>
    );
  }
}

export default MyHomePage;
