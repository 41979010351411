import React from "react";
import "../fieldComponent.css";
import "./ImageContent.css";
import Title from "../Title";

const ImageContent = props => {
  const { name, src, refer } = props;

  return (
    <div>
      {/* <p className="field-component-title">{name}</p> */}
      <Title name={name} />
      <img className="image-content-image" src={src} ref={refer} alt="" />
    </div>
  );
};

export default ImageContent;
