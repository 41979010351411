import React from "reactn";
import { LeftMsgArrow, RightMsgArrow } from "../../../../assets";
import { format } from "date-fns";
import ScrollToBottom from "react-scroll-to-bottom";
import Avatar from "../../../../assets/img/msgicon.png";
import SendMessage from "./SendMessage";
import { CometChat } from "@cometchat-pro/chat";
import { IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
class CenterMessagingPane extends React.PureComponent {
  checkAsRead = () => {
    // const allMessages = this.global.ccSelectedMessages;
    // if (allMessages) {
    //   const lastItem = allMessages[allMessages.length - 1];
    //   if (lastItem) {
    //     CometChat.markAsRead(
    //       lastItem.id,
    //       lastItem.receiverId,
    //       CometChat.RECEIVER_TYPE.USER
    //     );
    //   }
    //   CometChat.getUnreadMessageCountForAllUsers().then(val => {
    //     if (Object.keys(val).length === 0) this.setGlobal({ unread: false });
    //   });
    // }
  };
  componentDidUpdate() {
    this.checkAsRead();
  }
  componentDidMount() {
    this.checkAsRead();
  }
  componentWillUnmount() {
    this.setGlobal({
      ccSelectedContact: {},
      ccSelectedMessages: []
    });
  }
  render() {
    return (
      <div className="centerm">
        <ScrollToBottom className="paddingmsg">
          {this.global.ccSelectedMessages.map((msg, key) => {
            if (msg.receiver.uid === this.global.dsUser.cometchat) {
              return (
                <div key={key} className="leftsidemsg">
                  <div className="row">
                    <div className="col-2">
                      <div
                        className="divforimg activecircle"
                        style={{
                          backgroundImage: `url(${
                            this.global.ccSelectedContact.conversationWith
                              .avatar || Avatar
                          })`,
                          backgroundPosition: "center",
                          width: "45px",
                          height: "45px",
                          borderRadius: "50%",
                          backgroundSize: "cover"
                        }}
                      >
                        {/* <div className="mincircle" /> */}
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="msg">
                        <p>
                          <img
                            src={LeftMsgArrow}
                            className="leftmsgarrow"
                            alt=""
                          />
                          {msg.text}{" "}
                        </p>

                        <p className="time">
                          {format(
                            new Date(msg.sentAt * 1000),
                            "LLL dd, yyyy - hh:mm:ss aa"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div key={key} className="floatright">
                  <div className="rightsidemsg">
                    <div className="row">
                      <div className="col-10">
                        <div className="msg">
                          <p>
                            <img
                              src={RightMsgArrow}
                              className="rightmsgarrow"
                              alt=""
                            />
                            {msg.text}{" "}
                          </p>

                          <p className="time">
                            {format(
                              new Date(msg.sentAt * 1000),
                              "LLL dd, yyyy - hh:mm:ss aa"
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="col-2">
                        <div
                          className="divforimg"
                          style={{
                            backgroundImage: `url(${
                              this.global.dsUser.photo
                                ? process.env.REACT_APP_DS_FILES_S3 +
                                  "/" +
                                  this.global.dsUser.photo
                                : Avatar
                            })`,
                            backgroundPosition: "center",
                            width: "45px",
                            height: "45px",
                            borderRadius: "50%",
                            backgroundSize: "cover"
                          }}
                        >
                          {/* <div className="mincircle" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
          {this.props.smallScreen && (
            <IconButton
              style={{ position: "absolute", top: "0px", left: "0px" }}
              onClick={() => {
                this.setGlobal({ ccSelectedContact: {} });
              }}
            >
              <ArrowBack />
            </IconButton>
          )}
        </ScrollToBottom>

        <SendMessage />
      </div>
    );
  }
}

export default CenterMessagingPane;
