import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK, STRING_INPUT } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { format } from "date-fns";

class FormUsageInfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = { formName: "", formList: [] };
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/forms/form-usage/${this.props.selectedForm.id}`;
    const results = await axios.get(ep);

    if (results.data.success) {
      this.setState({ formList: results.data.data });
    }
  }

  getBaseModalFields() {
    const programNameArr = this.state.formList;

    const formUsageField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Form is currently used by:",
        content:
          programNameArr?.length <= 0
            ? "None"
            : programNameArr.map(item => item.name).join(", ")
      }
    };

    return [formUsageField];
  }

  getBaseModalButtons = () => {
    const closeButton = {
      name: "Close",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.props.onClose()
    };

    return [closeButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Form Usage Info",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default FormUsageInfoModal;
