import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK, STRING_INPUT, DROPDOWN } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { format } from "date-fns";

class PromptInactiveMilestoneModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getBaseModalFields() {
    const fieldArr = [];

    const promptInactiveMessage = {
      type: CONTENT_BLOCK,
      data: {
        name: "Please ensure that there are currently no active milestones. If there are active milestones, they need to be inactive first before a replication from another set can occur."
      }
    };

    fieldArr.push(promptInactiveMessage);

    return fieldArr;
  }

  getBaseModalButtons = () => {
    const closeButton = {
      name: "Close",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.props.onClose()
    };

    return [closeButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Copy Milestone",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default PromptInactiveMilestoneModal;
