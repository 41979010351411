import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { DateTime } from "luxon";

class PaidOrdersModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  requestTour = async e => {
    e.preventDefault();
  };

  showProgramPlan = r => {
    if (r.planId === null) {
      return null;
    }
    const plan = JSON.parse(r.product.plans[r.planId]);
    const objConv = {
      week: "Weekly",
      "bi-week": "Bi-Weekly",
      month: "Monthly"
    };
    return (
      <span>
        {plan.type
          ? r.drop_in_dates
            ? `Semester Drop-in - $${parseFloat(
                (plan.total_price / plan.dayCount) *
                  (1 + parseInt(plan.dropin_interest) / 100)
              ).toFixed(0)}`
            : plan.installments
            ? `Semester - Inst - $${parseFloat(
                (plan.total_price / plan.installments_breakdown) *
                  (1 + parseInt(plan.installments_interest) / 100)
              ).toFixed(0)} - ${plan.installments_plan}`
            : `Semester - $${plan.total_price}`
          : `Recurring - $${plan.tuition_rate} / ${
              objConv[plan.billing_cycle]
            }, \n ${plan.timing[0]} to ${plan.timing[1]}`}
      </span>
    );
  };

  showEventPlan = r => {
    const plan = JSON.parse(r.product.tickets[r.planId]);
    const ticketName =
      plan.ticket_status === "Paid" ? "Standard" : plan.ticket_status;
    const ticketPrice = plan.ticket_price ? `$${plan.ticket_price}` : "Free";
    return (
      <span>
        {ticketName} Ticket / {ticketPrice}
      </span>
    );
  };

  showMembershipPlan = r => {
    const plan = JSON.parse(r.product.prices[r.planId]);
    const membershipTerm = plan.membership_term;
    const renewalStatus = plan.auto_renew
      ? "Subscription Membership"
      : "Standard Membership";
    const pricePerTerm = plan.price_per_term;
    return (
      <span>
        {membershipTerm} Month Term - {renewalStatus} - {pricePerTerm}
      </span>
    );
  };

  showOnlinePlan = r => {
    const plan = JSON.parse(r.product.plans[r.planId]);
    const objConv = {
      week: "Weekly",
      "bi-week": "Bi-Weekly",
      month: "Monthly"
    };
    if (!plan.type) {
      return (
        <span>
          Recurring - ${plan.tuition_rate} / {objConv[plan.billing_cycle]}
        </span>
      );
    }
    if (plan.type === "Free") {
      return <span>Free</span>;
    } else {
      return <span>Semester - ${plan.total_price}</span>;
    }
  };

  displaySubTotal = r => {
    const tableName = r.product_table;
    let taxPercentage;
    if (tableName === "partner_programs") {
      taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
        ? JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage
        : 0;
      if (r.isInstallment) {
        return (
          <span style={{ fontFamily: "Open Sans, sans-serif" }}>
            ${parseFloat(r.payments[0].amount).toFixed(2)}
          </span>
        );
      }
    } else if (tableName === "partner_event") {
      taxPercentage = JSON.parse(r.product.tickets[r.planId]).isTaxEnabled
        ? JSON.parse(r.product.tickets[r.planId]).taxInfo[0].percentage
        : 0;
    } else if (tableName === "partner_memberships") {
      taxPercentage = JSON.parse(r.product.prices[r.planId]).isTaxEnabled
        ? JSON.parse(r.product.prices[r.planId]).taxInfo[0].percentage
        : 0;
    } else if (tableName === "partner_online") {
      taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
        ? JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage
        : 0;
    }
    return (
      <span style={{ fontFamily: "Open Sans, sans-serif" }}>
        ${parseFloat(r.amount / (100 + taxPercentage)).toFixed(2)}
      </span>
    );
  };

  displayTaxOrders = r => {
    const tableName = r.product_table;
    if (tableName === "partner_programs") {
      const taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
        ? `${JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage}%`
        : "0%";
      return (
        <span style={{ fontFamily: "Open Sans, sans-serif" }}>
          {taxPercentage}
        </span>
      );
    } else if (tableName === "partner_event") {
      const taxPercentage = JSON.parse(r.product.tickets[r.planId]).isTaxEnabled
        ? `${JSON.parse(r.product.tickets[r.planId]).taxInfo[0].percentage}%`
        : "0%";
      return (
        <span style={{ fontFamily: "Open Sans, sans-serif" }}>
          {taxPercentage}
        </span>
      );
    } else if (tableName === "partner_memberships") {
      const taxPercentage = JSON.parse(r.product.prices[r.planId]).isTaxEnabled
        ? `${JSON.parse(r.product.prices[r.planId]).taxInfo[0].percentage}%`
        : "0%";
      return (
        <span style={{ fontFamily: "Open Sans, sans-serif" }}>
          {taxPercentage}
        </span>
      );
    } else if (tableName === "partner_online") {
      const taxPercentage = JSON.parse(r.product.plans[r.planId]).isTaxEnabled
        ? `${JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage}%`
        : "0%";
      return (
        <span style={{ fontFamily: "Open Sans, sans-serif" }}>
          {taxPercentage}
        </span>
      );
    }
  };

  orderStatus = (row, i) => {
    const isRefunded =
      !row.amount_refunded || row.amount_refunded === 0 ? false : true;

    return (
      <td key={`id-${i}`}>
        {row.paid ? (
          <span className="green table-custom-text">
            Paid {row.manual && row.amount != 0 && "(Manual)"}
          </span>
        ) : (
          <span className="red table-custom-text">Pending Collection</span>
        )}
        {isRefunded && (
          <span>{row.amount_refunded === 0 ? "" : "(Refunded)"}</span>
        )}
      </td>
    );
  };

  showDates = r => {
    // unused var
    // const createdDate = new Date(r.created * 1000);
    const local = DateTime.fromSeconds(r.created);
    const rezoned = local.setZone(this.global.organizationInfo.timezone);
    return <span>{rezoned.toFormat("DD")}</span>;
  };

  showProgramPlanCoupon = r => {
    const plan = JSON.parse(r.product.plans[r.planId]);
    const objConv = {
      week: "Weekly",
      "bi-week": "Bi-Weekly",
      month: "Monthly"
    };

    if (!plan.type) {
      let couponDiscount = 0;
      // Coupon Applied
      if (r.coupon) {
        couponDiscount =
          r.coupon.type === "Percentage"
            ? parseFloat(
                parseFloat(plan.tuition_rate) *
                  (parseFloat(r.coupon.amount_off) / 100)
              )
            : parseFloat(r.coupon.amount_off);
      }

      return (
        <span>
          ({r.coupon.coupon_id}) / ${couponDiscount}
        </span>
      );
    }

    if (plan.type) {
      let couponDiscount = 0;
      // Coupon Applied
      if (r.coupon) {
        couponDiscount =
          r.coupon.type === "Percentage"
            ? parseFloat(
                parseFloat(plan.total_price) *
                  (parseFloat(r.coupon.amount_off) / 100)
              )
            : parseFloat(r.coupon.amount_off);
      }

      if (r.drop_in_dates && r.coupon) {
        const plan = JSON.parse(r.product.plans[r.planId]);

        couponDiscount =
          r.coupon.type === "Percentage"
            ? parseFloat(
                parseFloat(parseFloat(plan.total_price) / plan.dayCount) *
                  (1 + parseInt(plan.dropin_interest) / 100) *
                  (parseFloat(r.coupon.amount_off) / 100)
              ).toFixed(2)
            : parseFloat(r.coupon.amount_off);
      }

      if (r.isInstallment && r.coupon) {
        const plan = JSON.parse(r.product.plans[r.planId]);

        const price = parseFloat(
          (plan.total_price / plan.installments_breakdown) *
            (1 + parseInt(plan.installments_interest) / 100)
        );

        couponDiscount =
          r.coupon.type === "Percentage"
            ? parseFloat(
                price * (parseFloat(r.coupon.amount_off) / 100)
              ).toFixed(2)
            : parseFloat(r.coupon.amount_off);
      }

      return (
        <span>
          ({r.coupon.coupon_id}) / ${couponDiscount}
        </span>
      );
    }
  };

  showEventPlanCoupon = r => {
    const plan = JSON.parse(r.product.tickets[r.planId]);
    const ticketPrice = parseFloat(plan.ticket_price);

    let couponDiscount = 0;
    // Coupon Applied
    if (r.coupon) {
      couponDiscount =
        r.coupon.type === "Percentage"
          ? parseFloat(
              parseFloat(ticketPrice) * (parseFloat(r.coupon.amount_off) / 100)
            )
          : parseFloat(r.coupon.amount_off);
    }
    return (
      <span>
        ({r.coupon.coupon_id}) / ${couponDiscount}
      </span>
    );
  };

  showOnlinePlanCoupon = r => {
    const plan = JSON.parse(r.product.plans[r.planId]);
    const objConv = {
      week: "Weekly",
      "bi-week": "Bi-Weekly",
      month: "Monthly"
    };

    if (!plan.type) {
      let couponDiscount = 0;
      // Coupon Applied
      if (r.coupon) {
        couponDiscount =
          r.coupon.type === "Percentage"
            ? parseFloat(
                parseFloat(plan.tuition_rate) *
                  (parseFloat(r.coupon.amount_off) / 100)
              )
            : parseFloat(r.coupon.amount_off);
      }

      return (
        <span>
          ({r.coupon.coupon_id}) / ${couponDiscount}
        </span>
      );
    }
    if (plan.type === "Free") {
      return <span>Free</span>;
    } else {
      let couponDiscount = 0;
      // Coupon Applied
      if (r.coupon) {
        couponDiscount =
          r.coupon.type === "Percentage"
            ? parseFloat(
                parseFloat(plan.total_price) *
                  (parseFloat(r.coupon.amount_off) / 100)
              )
            : parseFloat(r.coupon.amount_off);
      }

      if (r.drop_in_dates && r.coupon) {
        const plan = JSON.parse(r.product.plans[r.planId]);

        couponDiscount =
          r.coupon.type === "Percentage"
            ? parseFloat(
                parseFloat(parseFloat(plan.total_price) / plan.dayCount) *
                  (1 + parseInt(plan.dropin_interest) / 100) *
                  (parseFloat(r.coupon.amount_off) / 100)
              ).toFixed(2)
            : parseFloat(r.coupon.amount_off);
      }

      return (
        <span>
          ({r.coupon.coupon_id}) / ${couponDiscount}
        </span>
      );
    }
  };

  showMembershipPlanCoupon = r => {
    const plan = JSON.parse(r.product.prices[r.planId]);
    const pricePerTerm = parseFloat(plan.price_per_term);

    let couponDiscount = 0;
    // Coupon Applied
    if (r.coupon) {
      couponDiscount =
        r.coupon.type === "Percentage"
          ? parseFloat(
              parseFloat(pricePerTerm) * (parseFloat(r.coupon.amount_off) / 100)
            )
          : parseFloat(r.coupon.amount_off);
    }
    return (
      <span>
        ({r.coupon.coupon_id}) / ${couponDiscount}
      </span>
    );
  };

  render() {
    console.log("this.props", this.props);
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px",
                marginBottom: ".5rem"
              }}
            >
              <h3>View Order Details</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="display_name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  For
                </label>
                <div
                  style={{
                    width: "70%",
                    fontSize: ".9rem",
                    fontWeight: "600",
                    lineHeight: "1.5",
                    color: "#8c8ea0"
                  }}
                >
                  {this.props.selectedItem.product_table ===
                    "partner_programs" &&
                    this.props.selectedItem.product.program_name}
                  {this.props.selectedItem.product_table === "partner_event" &&
                    this.props.selectedItem.product.event_title}
                  {this.props.selectedItem.product_table ===
                    "partner_memberships" &&
                    this.props.selectedItem.product.membership_name}
                  {this.props.selectedItem.product_table === "partner_online" &&
                    this.props.selectedItem.product.program_name}
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="display_name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Item
                </label>
                <div
                  style={{
                    width: "70%",
                    fontSize: ".9rem",
                    fontWeight: "600",
                    lineHeight: "1.5",
                    color: "#8c8ea0"
                  }}
                >
                  {this.props.selectedItem.product_table ===
                    "partner_programs" &&
                    this.showProgramPlan(this.props.selectedItem)}
                  {this.props.selectedItem.product_table === "partner_event" &&
                    this.showEventPlan(this.props.selectedItem)}
                  {this.props.selectedItem.product_table ===
                    "partner_memberships" &&
                    this.showMembershipPlan(this.props.selectedItem)}
                  {this.props.selectedItem.product_table === "partner_online" &&
                    this.showOnlinePlan(this.props.selectedItem)}
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="display_name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Sub-total
                </label>
                <div
                  style={{
                    width: "70%",
                    fontSize: ".9rem",
                    fontWeight: "600",
                    lineHeight: "1.5",
                    color: "#8c8ea0"
                  }}
                >
                  {this.displaySubTotal(this.props.selectedItem)}
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="display_name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Tax
                </label>
                <div
                  style={{
                    width: "70%",
                    fontSize: ".9rem",
                    fontWeight: "600",
                    lineHeight: "1.5",
                    color: "#8c8ea0"
                  }}
                >
                  {this.displayTaxOrders(this.props.selectedItem)}
                </div>
              </div>
              {this.props.selectedItem.coupon && (
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "25%", textAlign: "left" }}
                  >
                    Coupon Applied
                  </label>
                  <span
                    style={{ width: "70%", color: "black", fontSize: "14px" }}
                  >
                    {this.props.selectedItem.product_table ===
                      "partner_programs" &&
                      this.showProgramPlanCoupon(this.props.selectedItem)}
                    {this.props.selectedItem.product_table ===
                      "partner_event" &&
                      this.showEventPlanCoupon(this.props.selectedItem)}
                    {this.props.selectedItem.product_table ===
                      "partner_memberships" &&
                      this.showMembershipPlanCoupon(this.props.selectedItem)}
                    {this.props.selectedItem.product_table ===
                      "partner_online" &&
                      this.showOnlinePlanCoupon(this.props.selectedItem)}
                  </span>
                </div>
              )}
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="display_name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Promo
                </label>
                <div
                  style={{
                    width: "70%",
                    fontSize: ".9rem",
                    fontWeight: "600",
                    lineHeight: "1.5",
                    color: "#8c8ea0"
                  }}
                >
                  {" "}
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="display_name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Gift Card
                </label>
                <div
                  style={{
                    width: "70%",
                    fontSize: ".9rem",
                    fontWeight: "600",
                    lineHeight: "1.5",
                    color: "#8c8ea0"
                  }}
                >
                  - ${(this.props.selectedItem.giftDiscount / 100).toFixed(2)}
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="display_name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Total
                </label>
                <div
                  style={{
                    width: "70%",
                    fontSize: ".9rem",
                    fontWeight: "600",
                    lineHeight: "1.5",
                    color: "#8c8ea0"
                  }}
                >
                  $
                  {this.props.selectedItem.isInstallment
                    ? this.props.selectedItem.payments[0].amount
                    : parseFloat(this.props.selectedItem.amount / 100).toFixed(
                        2
                      )}
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="display_name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Purchaser
                </label>
                <div
                  style={{
                    width: "70%",
                    fontSize: ".9rem",
                    fontWeight: "600",
                    lineHeight: "1.5",
                    color: "#8c8ea0"
                  }}
                >
                  {this.props.selectedItem.customer_name}
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="display_name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Status
                </label>
                <div
                  style={{
                    width: "70%",
                    fontSize: ".9rem",
                    fontWeight: "600",
                    lineHeight: "1.5",
                    color: "#8c8ea0"
                  }}
                >
                  {this.orderStatus(this.props.selectedItem)}
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="display_name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Date
                </label>
                <div
                  style={{
                    width: "70%",
                    fontSize: ".9rem",
                    fontWeight: "600",
                    lineHeight: "1.5",
                    color: "#8c8ea0"
                  }}
                >
                  {this.showDates(this.props.selectedItem)}
                </div>
              </div>
            </form>
            <div className="modal-footer">
              <button
                type="submit"
                onClick={_ => this.props.onClose()}
                className="btn profile-btn"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PaidOrdersModal);
