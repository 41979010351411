import React from "reactn";
import { Table } from "../../components/UI";
import InterestedProgramsModal from "../../components/table/admins/InterestedProgramsModal";
import axios from "axios";
import { format } from "date-fns";

class InterestedPrograms extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      parents: [],
      selectedParent: "",
      detailsModalShown: false,
      modalData: null
    };
    this.headings = [
      { id: "displayName", label: "PARENT" },
      { id: "programName", label: "PROGRAM" },
      { id: "applicationStage", label: "LAST PROGRESS" },
      {
        id: "requestedTourDate",
        label: "Tour Date",
        customCell: this.tourDate
      },
      {
        id: "updatedAt",
        label: "DATE OF LAST PROGRESS",
        customCell: this.lastActionCell
      },
      { id: "signInCount", label: "Sign-in counts" },
      { id: "action", label: "ACTION" }
    ];

    this.actions = [{ name: "VIEW DETAIL", action: this.viewDetail }];
    this.viewDetail = this.viewDetail.bind(this);
    this.filterData = this.filterData.bind(this);
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/family/intp`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      this.setState({
        ...res.data.data
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  filterData = () => {
    return this.state.selectedParent
      ? this.state.data.filter(
          d => d.dsUserId.toString() === this.state.selectedParent
        )
      : this.state.data;
  };

  tourDate = (r, i) => {
    return (
      <td key={i}>
        {r.requestedTourDate
          ? format(new Date(r.requestedTourDate), "LLL dd, yyyy hh:mm a")
          : "Unavailable"}
      </td>
    );
  };
  lastActionCell = (r, i) => {
    return (
      <td key={i}>{format(new Date(r.updatedAt), "LLL dd, yyyy hh:mm a")}</td>
    );
  };

  stepsCell = (r, i) => {
    return (
      <td key={i}>
        {r.requestedInfo ? "Info" : null} {r.requestedTour ? "Tour" : null}
      </td>
    );
  };

  viewDetail = async row => {
    this.setState({ detailsModalShown: true, modalData: row });
  };

  render() {
    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          <div className="cont">
            <h1>Interested Programs</h1>
          </div>
          <div className="body">
            <InterestedProgramsModal
              open={this.state.detailsModalShown}
              data={this.state.modalData}
              onClose={() =>
                this.setState({
                  detailsModalShown: false,
                  modalData: null
                })
              }
            />
            <div className="cont">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <select
                    className="w-100"
                    value={this.state.selectedParent}
                    style={{ width: "100%" }}
                    onChange={e =>
                      this.setState({ selectedParent: e.target.value })
                    }
                  >
                    <option value="">Select a Parent</option>
                    {this.state.data.map((p, k) => (
                      <option value={p.dsUserId} key={k}>
                        {p.displayName}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className="col-md-12 col-sm-12"
                  style={{ marginTop: "20px" }}
                >
                  <div className="table-responsive">
                    <Table
                      headings={this.headings}
                      data={this.filterData()}
                      actions={this.actions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InterestedPrograms;
