import React from "reactn";
import { MiniPlus } from "../../../../assets";
import { withRouter } from "react-router-dom";

class ProgramPhotos extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      type: ""
    };
    this.fileChange = this.fileChange.bind(this);
    this.uploadNew = this.uploadNew.bind(this);
  }

  uploadNew(type) {
    if (
      !this.global.profile.programSubmitted ||
      this.global.dsUser.accountType === "Admin"
    ) {
      this.setState({
        type: type
      });
      const uploadEle = document.getElementById("uploadProgramPhoto");
      uploadEle.click();
    }
  }

  fileChange(e) {
    const params = this.props.match.params;
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      const fd = new FormData();
      if (params.id) {
        fd.set("dsUserId", params.id);
      }
      fd.set("image", e.target.files[0]);
      fd.set("type", this.state.type);
      this.dispatch.uploadProgramPhoto(fd);
    }
  }

  render() {
    return (
      <div className="box" style={{ maxWidth: 440 }}>
        <div className="bgofbox">
          <div className="header">
            <div className="row">
              <div className="col-12">
                <h2>PROGRAM PHOTOS</h2>
              </div>
            </div>
          </div>
          <div
            className="photos"
            style={{
              display: "flex",
              flexWrap: "wrap",
              padding: "0.5rem 0.5rem 0",
              justifyContent: "center"
            }}
          >
            <figure
              className="photoContainer"
              style={{
                width: "100%",
                textAlign: "center",
                border: !this.global.myProgramGallery.programCoverPhoto
                  ? "1px solid red"
                  : "inherit"
              }}
              onClick={() => this.uploadNew("cover")}
            >
              <div
                style={{
                  padding: "2px",
                  width: "100%",
                  height: "140px",
                  border: "1px solid #e3e3e3",
                  display: "inline-block",
                  borderRadius: "8px",
                  cursor: "pointer",
                  backgroundImage: `url(${
                    this.global.myProgramGallery.programCoverPhoto
                      ? `${process.env.REACT_APP_DS_FILES_S3}/${this.global.myProgramGallery.programCoverPhoto}`
                      : MiniPlus
                  })`,
                  backgroundPosition: "center",
                  backgroundSize: `${
                    this.global.myProgramGallery.programCoverPhoto
                      ? "cover"
                      : "auto"
                  }`,
                  backgroundRepeat: "no-repeat"
                }}
              ></div>
              Cover Photo
            </figure>

            <figure
              className="photoContainer mr-3"
              style={{
                width: "220px",
                height: "120px",
                textAlign: "center"
                // border: !this.global.myProgramGallery.profilePhoto
                //   ? "1px solid red"
                //   : "inherit"
              }}
              onClick={() => this.uploadNew("search")}
            >
              <div
                style={{
                  padding: "2px",
                  width: "220px",
                  height: "120px",
                  border: "1px solid #e3e3e3",
                  display: "inline-block",
                  borderRadius: "8px",
                  cursor: "pointer",
                  backgroundImage: `url(${
                    this.global.myProgramGallery.searchPhoto
                      ? `${process.env.REACT_APP_DS_FILES_S3}/${this.global.myProgramGallery.searchPhoto}`
                      : MiniPlus
                  })`,
                  backgroundPosition: "center",
                  backgroundSize: `${
                    this.global.myProgramGallery.searchPhoto ? "cover" : "auto"
                  }`,
                  backgroundRepeat: "no-repeat"
                }}
              ></div>
              Search Photo
            </figure>

            <figure
              className="photoContainer"
              style={{
                width: "120px",
                height: "120px",
                textAlign: "center"
                // border: !this.global.myProgramGallery.profilePhoto
                //   ? "1px solid red"
                //   : "inherit"
              }}
              onClick={() => this.uploadNew("avatar")}
            >
              <div
                style={{
                  padding: "2px",
                  width: "120px",
                  height: "120px",
                  border: "1px solid #e3e3e3",
                  display: "inline-block",
                  borderRadius: "8px",
                  cursor: "pointer",
                  backgroundImage: `url(${
                    this.global.myProgramGallery.profilePhoto
                      ? `${process.env.REACT_APP_DS_FILES_S3}/${this.global.myProgramGallery.profilePhoto}`
                      : MiniPlus
                  })`,
                  backgroundPosition: "center",
                  backgroundSize: `${
                    this.global.myProgramGallery.profilePhoto ? "cover" : "auto"
                  }`,
                  backgroundRepeat: "no-repeat"
                }}
              ></div>
              Educator Photo
            </figure>
            <input
              type="file"
              style={{ display: "none" }}
              id="uploadProgramPhoto"
              onChange={this.fileChange}
              accept="image/x-png,image/gif,image/jpeg"
            />
          </div>
          <p className="w-100 text-center mt-3" style={{ fontSize: 12 }}>
            A cover photo is required, educator photo and search photo are
            optional.
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(ProgramPhotos);
