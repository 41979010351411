import React from "reactn";
import { Link, withRouter } from "react-router-dom";
import { Redesign_Logo, DreamHouseLogo2 } from "../../assets";
import ProgramSearchInput from "../common/ProgramSearchInput";
import "../../assets/css/componentSpecificCss/header.css";

class Header extends React.PureComponent {
  getPath = () => {
    if (this.global.dsUser.accountType) {
      switch (this.global.dsUser.accountType) {
        case "Admin":
          return "/admin";
        case "Parent":
          return "/parents";
        case "Educator":
          return "/educators";
        case "Partner":
          return "/partners";
        default:
          return "";
      }
    }
  };
  shrinkNavbar = () => {
    const div = document.getElementById("navbar-collapse");
    div.classList.remove("show");
  };
  render() {
    return (
      <header className="fixed-header">
        <nav className="navbar navbar-default custom-navbar" role="navigation">
          <div className="col-lg-3 col-md-3">
            <div className="navbar-header">
              {this.props.match.path == "/" && (
                <Link
                  to="/"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    this.props.history.push("/");
                  }}
                >
                  <img
                    className="school-logo"
                    src={Redesign_Logo}
                    alt="Dreamschools"
                  />
                </Link>
              )}
              {this.props.match.path.includes("/preview") &&
                this.global.organizationInfo && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "end",
                      marginLeft: "24px"
                    }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      this.props.history.push("/");
                    }}
                  >
                    <img
                      // className="school-logo"
                      style={{
                        objectFit: "contain",
                        height: "28px",
                        width: "28px",
                        marginRight: "12px"
                      }}
                      src={DreamHouseLogo2}
                      alt="Dreamschools"
                    />
                    {/* <div style={{ display: "flex", alignItems: "flex-end" }}> */}
                    <div className="nav-bar-title">
                      {this.global.organizationInfo.organization_title}
                    </div>
                  </div>
                )}
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#navbar-collapse"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
          </div>

          {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
          <div className="col-lg-9 col-md-9">
            <div
              className="collapse navbar-collapse flex-important"
              id="navbar-collapse"
            >
              <div className="row">
                <div className="col-12 right" style={{ padding: 0 }}>
                  <ul className="nav navbar-nav navbar-right custom-right-navbar">
                    {this.global.dsUser && (
                      <>
                        <li>
                          <Link
                            className="nav-link"
                            to={`${this.getPath()}`}
                            onClick={() => {
                              window.scrollTo(0, 0);
                              this.shrinkNavbar();
                              this.props.history.push(`${this.getPath()}`);
                            }}
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="nav-link"
                            to={`/`}
                            onClick={() => {
                              window.scrollTo(0, 0);
                              this.shrinkNavbar();
                              this.dispatch.logout();
                            }}
                          >
                            Log-out
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default withRouter(Header);
