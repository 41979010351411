import React from "react";
import "../fieldComponent.css";
import "./DatePicker.css";
import Title from "../Title";

import DatePicker from "react-datepicker";

const DatePickerField = props => {
  const {
    name,
    required,
    placeholder,
    value,
    handleChange,
    minDate,
    dateFormat,
    fieldValid,
    setFieldValid,
    isDisabled,
    info,
    styles,
    wrapperStyles,
    titleStyle,
    filterDate,
    includeTimes,
    showTimeSelect,
    timeIntervals,
    iconStyles,
    inline,
    selectedDates,
    disabledKeyboardNavigation
  } = props;

  console.log(value);
  console.log("selectedDates: ", selectedDates);
  return (
    <div style={wrapperStyles}>
      <Title name={name} required={required || false} info={info} titleStyle={titleStyle} iconStyles={iconStyles}/>
      <div style={styles}>
        <DatePicker
          selected={value || null}
          highlightDates={selectedDates}
          onChange={handleChange}
          minDate={minDate}
          showYearDropdown
          showMonthDropdown
          dateFormat={dateFormat}
          disabled={isDisabled}
          className={`field-component-input-box ${
            !fieldValid ? "date-picker-invalid" : ""
          }`}
          filterDate={filterDate}
          placeholderText={placeholder}
          showTimeSelect={showTimeSelect}
          timeIntervals={timeIntervals}
          includeTimes={includeTimes}
          autoComplete="off"
          inline={inline}
          disabledKeyboardNavigation={disabledKeyboardNavigation}
        />
      </div>
    </div>
  );
};

export default DatePickerField;
