import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";
import { MiniPlus } from "../../../../assets";
import ImageCropModal from "../../../../components/modals/ImageCropModal";

class SectionTwo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { openImageCropModal: false, image: "" };
  }
  handleChange = (e, v) => {
    this.setGlobal({
      online: {
        ...this.global.online,
        [e]: v
      }
    });
  };

  uploadNew() {
    const uploadEle = document.getElementById("uploadPhoto");
    uploadEle.click();
  }

  fileChange(e) {
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      const file = new FileReader();
      file.readAsDataURL(e.target.files[0]);
      file.onloadend = () => {
        document.getElementById("uploadPhoto").value = "";
        this.setState({ image: file.result, openImageCropModal: true });
      };
    }
  }

  customForm = () => {
    return (
      <div className="formbody">
        {this.state.openImageCropModal && (
          <ImageCropModal
            sourceImage={this.state.image}
            onClose={() => {
              this.setState({ openImageCropModal: false });
            }}
            aspectRatio={4 / 3}
            uploadImage={croppedImageURL => {
              const fd = new FormData();
              fd.set("image", croppedImageURL);
              this.dispatch.uploadVRPhoto(fd);
              this.setState({ openImageCropModal: false });
            }}
          />
        )}

        <form>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Program Photo</h3>
            </div>
            <div className="col-7 d-flex">
              <figure
                className="photoContainer"
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginLeft: "6px"
                }}
                onClick={this.uploadNew}
              >
                {this.global.online.program_photo ? (
                  <div
                    style={{
                      padding: "2px",
                      width: "130px",
                      height: "80px",
                      border: "1px solid #e3e3e3",
                      display: "inline-block",
                      borderRadius: "8px",
                      cursor: "pointer",
                      backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${this.global.online.program_photo})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat"
                    }}
                  ></div>
                ) : (
                  <div
                    style={{
                      padding: "2px",
                      width: "130px",
                      height: "80px",
                      border: "1px solid #e3e3e3",
                      display: "inline-block",
                      borderRadius: "8px",
                      cursor: "pointer"
                    }}
                  >
                    <img
                      src={MiniPlus}
                      alt="Add"
                      style={{ marginTop: "calc(50% - 4.5px)" }}
                    />
                  </div>
                )}
              </figure>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="label-names">Additional Notes</h3>
            </div>
            <div className="col-12 d-flex">
              <div className={`w-100`}>
                <textarea
                  className="input-text-fields"
                  rows="8"
                  value={this.global.online.additional_notes}
                  style={{ width: "100%" }}
                  name="additional_notes"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
          </div>

          <input
            type="file"
            style={{ display: "none" }}
            id="uploadPhoto"
            onChange={e => this.fileChange(e)}
            accept="image/x-png,image/gif,image/jpeg"
          />
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Program Details"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionTwo;
