import React from "reactn";
import BoxOrange from "../../../components/common/BoxOrange";
import BoxBlue from "../../../components/common/BoxBlue";

class Leads extends React.PureComponent {
  render() {
    const { leads, finance } = this.props;
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="info-boxes">
            <div className="orange blue section">
              <BoxOrange
                boxName="Tour Requests"
                boxCount={leads.tourTotal || "0"}
                boxNew={leads.tourNew || "0"}
                link={
                  this.global.profile.publishStage > 0
                    ? "/educators/tour-requests"
                    : "/educators"
                }
              />
              <BoxOrange
                boxName="Connections"
                boxCount={leads.infoTotal || "0"}
                boxNew={leads.infoNew || "0"}
                title="LEADS"
                link={
                  this.global.profile.publishStage > 0
                    ? "/educators/connections"
                    : "/educators"
                }
              />

              <BoxOrange
                boxName="New Applications"
                boxCount={leads.appTotal || "0"}
                boxNew={leads.appNew || "0"}
                link={
                  this.global.profile.publishStage > 0
                    ? "/educators/applications"
                    : "/educators"
                }
              />
              <BoxBlue
                boxName="Pending Payments"
                boxCount={finance.pending || "0"}
                boxNew={finance.pendingWeek || "0"}
                title="TUITION"
                link={
                  this.global.profile.publishStage > 1
                    ? "/educators/my-earnings"
                    : "/educators"
                }
              />
              <BoxBlue
                boxName="Overdue Payments"
                boxCount={finance.overdue || "0"}
                boxNew={finance.overdueWeek || "0"}
                link={
                  this.global.profile.publishStage > 1
                    ? "/educators/my-earnings"
                    : "/educators"
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Leads;
