import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

const assignRoomRequest = async data => {
  const ep = `${process.env.REACT_APP_API}/partners/rooms/assignment/add`;
  const resp = await axios.post(ep, data);

  if (!resp.data) {
    return {
      success: false,
      message: "An unknown error occured. Cannot Access Server."
    };
  }
  return resp.data;
};
const AssignRoomsModal = ({ onClose, rooms, user }) => {
  const [selectedRoom, setSelectedRoom] = useState(-1);

  const submitRoomAssignment = async e => {
    e.preventDefault();
    let roomObj;
    if (selectedRoom === -1) {
      setSelectedRoom(rooms[0].id);
      roomObj = rooms[0];
    } else {
      roomObj = rooms.filter(room => room.id === parseInt(selectedRoom))[0];
    }

    const data = {
      room: roomObj,
      assignUser: user
    };

    const serverRequest = await assignRoomRequest(data);

    if (!serverRequest.success) {
      toast.error(serverRequest.message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    } else {
      toast.success(serverRequest.message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }

    onClose();
  };
  if (rooms.length == 0) {
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px",
                marginBottom: ".5rem"
              }}
            >
              <h3>Assign Room</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => onClose()}
              >
                <Close />
              </IconButton>
            </div>

            <p>
              There are no rooms to select from. Please create a room first.
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="request-modal-cont">
      <div className="request-modal lesspadding">
        <div style={{ display: "block" }}>
          <img
            src={ModalBanner}
            alt="Modal Banner"
            style={{
              height: "40px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "48px",
              marginBottom: ".5rem"
            }}
          >
            <h3>Assign Room</h3>
            <IconButton
              style={{
                borderRadius: "50%",
                color: "#2880FF",
                marginBottom: "1rem"
              }}
              onClick={_ => onClose()}
            >
              <Close />
            </IconButton>
          </div>

          <form onSubmit={submitRoomAssignment}>
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                htmlFor="selectRoom"
                style={{ width: "25%", textAlign: "left" }}
              >
                Select Room
              </label>
              <select
                name="selectRoom"
                id="selectRoom"
                type="text"
                className="form-control"
                style={{ width: "70%" }}
                defaultValue={rooms[0].id}
                onChange={e => setSelectedRoom(e.target.value)}
              >
                {/* only map over the active room */}
                {rooms.map(room => (
                  <option value={room.id} key={room.id}>
                    {room.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="modal-footer" style={{ marginTop: "25px" }}>
              <button type="submit" className="btn profile-btn">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

AssignRoomsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  rooms: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
};

export default AssignRoomsModal;
