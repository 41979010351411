import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import InviteGiftModal from "../../components/modals/InviteGiftModal";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { format } from "date-fns";

const DATE_FORMAT_STR = "MMM do, yyyy";

class GiftCardPurchases extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      images_keys: [
        "partners/Gift Cards/zeus-01.png",
        "partners/Gift Cards/zeus-02.png",
        "partners/Gift Cards/zeus-03.png",
        "partners/Gift Cards/zeus-04.png",
        "partners/Gift Cards/zeus-05.png",
        "partners/Gift Cards/zeus-06.png",
        "partners/Gift Cards/zeus-07.png",
        "partners/Gift Cards/zeus-08.png"
      ]
    };

    this.tabs = ["Gift Cards Purchases"];
    this.headings = {
      "Gift Cards Purchases": [
        {
          id: "giftno",
          label: "User Details",
          customCell: (r, i) => (
            <td key={i}>
              <div style={{ display: "block" }}>
                <div>{r.recipient_name}</div>
                <div style={{ color: "slategray" }}>{r.recipient_email}</div>
              </div>
            </td>
          )
        },
        {
          id: "amount",
          label: "Amount Remaining",
          customCell: (r, i) => <td key={i}>${r.amtRemaining}</td>
        },
        {
          id: "giftno",
          label: "Most Recent Transaction",
          customCell: (r, i) => {
            let content;
            if (!r.activatedAt) {
              content = "N/A";
            } else {
              const activatedAt = new Date(r.activatedAt);
              const updatedAt = new Date(r.updatedAt);
              // If updatedAt and activatedAt are equal, no transactions have been made yet.
              content =
                activatedAt.getTime() === updatedAt.getTime()
                  ? "N/A"
                  : format(updatedAt, DATE_FORMAT_STR);
            }
            return <td key={i}>{content}</td>;
          }
        },
        {
          id: "giftno",
          label: "Purchase Details",
          customCell: (r, i) => (
            <td key={i}>
              <div style={{ display: "block" }}>
                <div>{r.from}</div>
                <div>{format(new Date(r.createdAt), DATE_FORMAT_STR)}</div>
                <div>{r.gift_card_number}</div>
              </div>
            </td>
          )
        }
      ]
    };
    this.actions = [{ name: "Notes", action: null }];
  }

  /**
   * This function handles the edit button functionality of a sub user
   * returns a boolean value
   */
  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return this.global.dsUser.accesspoints.Manage["Gift Card"].edit
        ? false
        : true;
    }
  };

  async componentDidMount() {
    const param = this.props.match.params.id;
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/gifts/list_purchases/${param}`;
    const results = await axios.get(ep);

    if (results.data.success) {
      this.setState({
        data: {
          ...results.data.data
        },
        purchases: {
          "Gift Cards Purchases": results.data.data.purchases
        }
      });
    }
    this.setGlobal({ loading: false });

    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Gift Cards", to: "/gift_cards/manage" },
        {
          label: "Gift Card Purchases",
          to: this.props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
        }
      ]
    });
  }

  render() {
    return (
      <div className="admin">
        {this.state.showInviteModal && (
          <InviteGiftModal
            onClose={() => {
              this.setState({ showInviteModal: false });
            }}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>Gift Cards</h1>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  <button
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => this.setState({ showInviteModal: true })}
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Invite
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="display-container">
              <div className="left-container">
                <div className="image-container">
                  <LazyLoadImage
                    src={`${process.env.REACT_APP_DS_FILES_S3}/${
                      this.state.images_keys[this.state.data.image - 1]
                    }`}
                    alt="Gift Card"
                    className="image"
                    wrapperClassName="image"
                    effect="opacity"
                  />
                </div>
                <p className="membership-name">
                  {this.state.data.gift_name || "Gift Card"} $
                  {this.state.data.amount}
                </p>
                {/* <div style={{ 
                  height: "100px",
                  padding: "15px",
                  overflowY: "auto",
                  backgroundColor: "rgb(242, 242, 240)",
                  justifyContent: "center",
                  alignItems: "center",
                  overflowWrap: "break-word",
                  wordBreak: "break-all"
                }}>
                  {this.state.data.description}
                </div> */}
                <div className="options-container">
                  <div
                    className="option-list"
                    onClick={() => {
                      this.props.history.push(
                        `/partners/gift_cards/setup/${this.props.match.params.id}`
                      );
                    }}
                  >
                    <h3 className="option-name">Edit Gift Card</h3>
                    <i className="far fa-edit option-img"></i>
                  </div>
                </div>
              </div>
              <div className="tablecont">
                <div className="table-responsive">
                  <NewTabbedTable
                    tabs={this.tabs}
                    headings={this.headings}
                    data={this.state.purchases}
                    actions={this.actions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GiftCardPurchases;
