import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  DROPDOWN,
  DATE_PICKER,
  SELECT_MULTIPLE_DROPDOWN,
  GET_HTML
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class DeleteInvoiceConfirmationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      existingDiscounts: [],
      selectedDiscount: null,
      discountObj: {}
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async deleteInvoice(e) {
    e.preventDefault();
    console.log("cancelInvoice");
    const ep = `${process.env.REACT_APP_API}/partners/orders/update`;

    const res = await axios.post(ep, {
      id: this.props.selectedItem.id,
      isArchived: true,
      archivedDate: new Date()
    });

    console.log("res", res);
    if (res.data.success) {
      toast.success("Invoice Deleted");

      this.props.confirmDelete(true);
      this.props.onClose();
    }
  }

  descriptionInput = () => {
    return (
      <div id="heightset" className="modal-main">
        <p
          style={{
            fontSize: "16px",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262"
          }}
        >
          Are you sure you want to delete this invoice?
        </p>
        <p
          style={{
            fontSize: "16px",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262"
          }}
        >
          Deleting cannot be undone - a new invoice would have to be created.
        </p>
      </div>
    );
  };

  getBaseModalFields() {
    const descriptionText = {
      type: GET_HTML,
      data: {
        gethtml: this.descriptionInput
      }
    };

    const fields = [];
    fields.push(descriptionText);

    return fields;
  }

  getBaseModalButtons = () => {
    const confirmSend = {
      name: "Delete",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.deleteInvoice(e)
    };

    const fieldsbutton = [];
    fieldsbutton.push(confirmSend);

    return fieldsbutton;
  };

  getBaseModalProps = () => {
    return {
      title: "Delete Invoice",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    console.log("this.state", this.state);
    console.log("this.global", this.global);
    console.log("this.props", this.props);
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <ModalTemplate
        heading="Delete Invoice"
        onClose={this.props.onClose}
        style={{ overflow: "visible" }}
      >
        <form onSubmit={e => this.deleteInvoice(e)}>
          <div id="heightset" className="modal-main">
            <p style={{ fontSize: "16px" }}>
              Are you sure you want to delete this invoice?
            </p>
            <p style={{ fontSize: "16px" }}>
              Deleting cannot be undone - a new invoice would have to be
              created.
            </p>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <img
                  src={CircleCheck}
                  alt=""
                  style={{
                    color: "white",
                    height: "15px",
                    width: "15px",
                    marginRight: "8px"
                  }}
                />
                <span>Delete</span>
              </div>
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(DeleteInvoiceConfirmationModal);
