import React from "reactn";
import axios from "axios";
import { toast } from "react-toastify";

class StripeExpress extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null
    };
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "Payment Settings"]
    });
  }

  async componentDidMount() {
    const params = this.props.location.query;
    const ep = `${process.env.REACT_APP_API}/stripe`;
    if (!params || !params.code) {
      try {
        this.setGlobal({ loading: true });
        const res = await axios.get(ep);
        if (res.data.success) {
          this.setState({
            stripe: res.data.data
          });
        } else {
          // toast.error(res.data.message);
        }
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (ex) {
        console.error(ex);
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    } else {
      try {
        if (!params.code) throw new Error("Authorization code not present");
        this.setGlobal({ loading: true });
        const res = await axios.post(ep, { code: params.code });
        if (res.data.success) {
          this.setState({
            stripe: res.data.data
          });
        } else {
          toast(res.data.message);
        }
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (ex) {
        toast.error(ex.message);
        console.error(ex);
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="cont">
          <div className="row">
            <div className="col-md-6">
              <h1>Stripe Connect</h1>
            </div>
          </div>
        </div>
        <div className="cont boxes-row boxes-row-fluid mb-5">
          <div className="box" style={{ maxWidth: "none" }}>
            <div className="bgofbox">
              <div className="header">
                <h2>Payment Settings</h2>
              </div>
              <div className="formbody">
                {this.state.stripe ? null : (
                  <div className="row mt-2">
                    <div
                      className="col-12 text-center"
                      style={{ fontSize: "10pt" }}
                    >
                      To make paying you as easy and seamless as possible, we've
                      partnered with Stripe to ensure the best possible
                      experience when receiving your payouts.
                      <br />
                      <br />
                      <a
                        href={
                          process.env.REACT_APP_MODE === "staging"
                            ? "https://dashboard.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_D8Tdn74PMebZ7CrToxdjz96Xszl0FEv2&scope=read_write"
                            : "https://dashboard.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_D8Td9AxMiQDJNHfsp3oYbuWOsAtFt4iD&scope=read_write"
                        }
                        className="parent__handbook_link"
                        style={{
                          width: "fit-content",
                          paddingTop: 4,
                          paddingBottom: 4,
                          paddingLeft: 14,
                          paddingRight: 14,
                          margin: "auto auto"
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Connect With Stripe
                      </a>
                    </div>
                  </div>
                )}

                {!this.state.stripe ? null : (
                  <>
                    <div className="row mt-2" style={{ fontSize: "10pt" }}>
                      <div className="col-12">
                        You're already connected with the following account:
                      </div>
                    </div>
                    <div className="row mt-2" style={{ fontSize: "10pt" }}>
                      <div className="col-3">
                        <b>Bank:</b>
                      </div>
                      <div className="col-9">
                        {
                          this.state.stripe.stripeCon.external_accounts.data[0]
                            .bank_name
                        }
                      </div>
                      <div className="col-3">
                        <b>Account Number:</b>
                      </div>
                      <div className="col-9">
                        {
                          this.state.stripe.stripeCon.external_accounts.data[0]
                            .last4
                        }
                      </div>
                    </div>
                    <div className="row mt-2" style={{ fontSize: "10pt" }}>
                      <div className="col-12">
                        If you'd like to make changes to your payout settings
                        please use the link below:
                        <br />
                        <br />
                        <a
                          href={this.state.stripe.stripeLogin.url}
                          className="parent__handbook_link"
                          style={{
                            width: "fit-content",
                            paddingTop: 4,
                            paddingBottom: 4,
                            paddingLeft: 14,
                            paddingRight: 14,
                            margin: "auto auto"
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Update Payout Settings
                        </a>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StripeExpress;
