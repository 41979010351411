import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "../../assets/css/componentSpecificCss/applicationViewModal.css";
import moment from "moment";
import {
  format,
  addWeeks,
  addDays,
  addHours,
  subDays,
  isAfter
} from "date-fns";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
// import { ReactPDF, PDFViewer , BlobProvider, PDFDownloadLink, pdf, Document, Page } from '@react-pdf/renderer';
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import SwitchEnrollmentModal from "../../components/modals/SwitchEnrollmentModal";
import ApplicationDenyNotificationModal from "./ApplicationDenyNotificationModal";
import ViewPartyAddonsModal from "./ViewPartyAddonsModal";

import {
  CONTENT_BLOCK,
  BUTTON,
  TEXT_AREA,
  IMAGE_CONTENT,
  DROPDOWN,
  DATE_PICKER,
  GET_HTML
} from "./baseModal/FieldTypes";
import BaseModal from "./baseModal/BaseModal";
import ApplicationCancelModal from "./ApplicationCancelModal";
import {
  BLUE_BUTTON,
  GREEN_BUTTON,
  RED_BUTTON,
  WHITE,
  YELLOW_BUTTON
} from "./baseModal/colours";
import { result } from "lodash";

const daysOfWeekConversion = day => {
  const table = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  };
  return table[day];
};

const getFirstDay = (startingDate, daysOfWeek) => {
  // Code to compensate for the local timezone of the starting date
  startingDate.setTime(
    startingDate.getTime() + startingDate.getTimezoneOffset() * 60 * 1000
  );

  if (!daysOfWeek) {
    return undefined;
  }
  if (Object.keys(daysOfWeek).length === 0) return;

  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  while (true) {
    if (convertedDaysOfWeek.includes(startingDate.getDay())) {
      return startingDate;
    }
    startingDate = addDays(startingDate, 1);
  }
};

const getLastDay = (lastDay, daysOfWeek) => {
  console.log("lastOfWeek...", daysOfWeek);
  if (!daysOfWeek) {
    return undefined;
  }
  if (Object.keys(daysOfWeek).length === 0) return;
  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  lastDay = addWeeks(lastDay, 1);
  lastDay = subDays(lastDay, 1);
  while (true) {
    if (convertedDaysOfWeek.includes(lastDay.getDay())) {
      return lastDay;
    }
    lastDay = subDays(lastDay, 1);
  }
};

class EditPartyAttendeesModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      attendees: props.program.partyDetails.attendees
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  getModal = () => {
    return (
      <>
        {this.state.attendees.map((attendee, index) => {
          return (
            <div
              style={{
                fontSize: "1rem",
                color: "#797979",
                marginTop: "2px !important",
                marginBottom: "5px",
                display: "flex",
                width: "100%",
                alignItems: "center"
              }}
            >
              Name:
              <input
                disabled={this.props.disabled}
                type="text"
                style={{
                  marginLeft: "5px",
                  marginRight: "10px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                  width: "30%",
                  fontSize: "1rem",
                  color: "#797979",
                }}
                onChange={e => {
                  const attendees = [...this.state.attendees];
                  attendees[index].name = e.target.value
                  this.setState({
                    attendees: attendees
                  })
                }}
                value={attendee.name}
              />
              Email:
              <input
                disabled={this.props.disabled}
                type="text"
                style={{
                  marginLeft: "5px",
                  marginRight: "5px",
                  borderRadius: "5px",
                  borderWidth: "1px",
                  width: "30%",
                  fontSize: "1rem",
                  color: "#797979",
                }}
                onChange={e => {
                  const attendees = [...this.state.attendees];
                  attendees[index].email = e.target.value
                  this.setState({
                    attendees: attendees
                  })
                }}
                value={this.state.attendees[index].email}
              />
              <IconButton
                style={{
                  margin: "0px",
                  padding: "5px",
                  borderRadius: "50%",
                  color: "#ff6663"
                }}
                disabled={this.props.disabled}
                onClick={_ => {
                  const attendees = [...this.state.attendees];
                  attendees.splice(index, 1);
                  this.setState({
                    attendees: attendees
                  })
                }}
              >
                <Close />
              </IconButton>
            </div>
          )
        })}
        {!this.props.disabled && (
          <button
            style={{
              backgroundColor: "#5cc8dc",
              color: WHITE,
              marginRight: "50px",
              float: "right",
            }}
            className="button-field-button"
            onClick={e => {
              // if (this.state.attendees.length > this.props.program.headcount) {
              //   toast.error("You have exceeded the recommended headcount")
              // }
              this.setState({
                attendees: [...this.state.attendees, { name: "", email: "" }]
              })
            }}
            type={BUTTON}
          >
            {"+ Add"}
          </button>
        )}
      </>
    )
  }

  getBaseModalFields() {
    let allFields = []

    const startDate = {
      type: GET_HTML,
      data: {
        name: "Attendees",
        gethtml: this.getModal
      }
    };

    allFields.push(startDate)

    return allFields;
  }

  getBaseModalButtons() {
    const saveButton = {
      name: "Save",
      handleClick: async () => {
        const ep = `${process.env.REACT_APP_API}/partners/parties/update-attendees-list`
        const results = await axios.post(ep, {
          program: this.props.program,
          attendees: this.state.attendees
        })

        if (results.data.success) {
          this.props.updatePartyDetails(results.data.data.partyDetails);
          this.props.onClose();
        }
      },
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
    };

    if (this.props.disabled) {
      return []
    }
    return [saveButton];
  }

  getBaseModalProps() {
    const baseModalProps = {
      title: "Application Details",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };

    return baseModalProps;
  }

  render() {
    if (!this.props) {
      return null;
    }
    console.log("this.state", this.state);
    console.log("this.props", this.props);

    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(EditPartyAttendeesModal);
