import React from "react";
import "./fieldComponent.css";
import ReactTooltip from "react-tooltip";

const Title = props => {
  const { name, required, info, icon, titleStyle, iconStyles } = props;
  const regex = /<i>(.*?)<\/i>/; // Regular expression to match <i>...</i> pattern
  const italicText = name && name.match(regex);

  // const beforeContent = name.slice(0, italicText.index);
  let containItalicTitle = false;

  const wrappedText = italicText ? italicText[1] : "";
  const afterContent = italicText
    ? name.slice(italicText.index + italicText[0].length)
    : "";

  if (italicText) {
    containItalicTitle = true;
  }

  return (
    <div className="base-modal-name-wrapper">
      <div className="field-component-title" style={{ ...titleStyle }}>
        {containItalicTitle ? (
          <>
            <span style={{ fontStyle: "italic" }}>{wrappedText} </span>
            {afterContent}
          </>
        ) : (
          name
        )}
        {required ? <span className="required-star"> *</span> : ""}{" "}
        {info && (
          <span style={{ height: 0 }}>
            <i
              data-tip
              data-for={name}
              className="fas fa-info-circle"
              style={{ color: "#DCDCDC", fontSize: "1.3rem", ...iconStyles }}
            />

            <ReactTooltip id={name} place="top" effect="solid" type="info">
              {info}
            </ReactTooltip>
          </span>
        )}
      </div>
      {icon && (
        <i
          className={icon.className}
          onClick={icon.onClick}
          style={{ fontSize: "1.2rem" }}
        ></i>
      )}
    </div>
  );
};

export default Title;
