import React, { useGlobal, useEffect } from "reactn";
import { NewApplicationBtn } from "../../assets";
import Tables from "../../components/table";

const Application = () => {
  const [pathway, setPathway] = useGlobal("pathway");
  useEffect(() => {
    setPathway([...pathway.slice(0, 1), "Applications"]);
  }, []);
  return (
    <div className="container-fluid">
      <div className="cont">
        <div className="row">
          <div className="col-md-6">
            <h1>Applications</h1>
          </div>
          <div className="col-md-6">
            <div className="forbtnwrap justify-end">
              <div className="forbtnapp">
                <a href="#/" className="newapplications">
                  <img src={NewApplicationBtn} alt="" />
                  New Application
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cont">
        <Tables.Parents.ApplicationsTable />
      </div>
    </div>
  );
};

export default Application;
