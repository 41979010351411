import React from "reactn";
import { formTypes, BoxForm } from "../../../../components/UI/form";

class ProgramHolidayForm extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      timeRanges: formTypes.SelectOptions.timeRanges
    };
    this.handleChange = this.handleChange.bind(this);
    this.dispatchNewHoliday = this.dispatchNewHoliday.bind(this);
    this.customForm = this.customForm.bind(this);
  }

  handleChange(e, v, sch) {
    const c = { ...sch, [e]: v };
    const n = this.global.myProgramHolidays.personalHolidays.map(s =>
      sch.id !== s.id ? s : c
    );
    this.setGlobal({
      myProgramHolidays: {
        ...this.global.myProgramHolidays,
        personalHolidays: n
      }
    });
  }

  dispatchNewHoliday(e) {
    e.preventDefault();
    this.dispatch.addProgramHoliday();
  }

  customForm() {
    return (
      <div className="formbody">
        <form>
          {this.global.myProgramHolidays.personalHolidays.map((sch, i) => (
            <div className="row" key={i}>
              <div className="col-5">Personal Holiday</div>
              <div className="col-7 d-flex">
                <formTypes.Date
                  name="date"
                  value={sch.date}
                  onChange={(e, v) => this.handleChange(e, v, sch)}
                  placeholder="Holiday"
                  isDisabled={
                    this.global.profile.programSubmitted &&
                    this.global.dsUser.accountType !== "Admin"
                  }
                />
                {this.global.profile.programSubmitted &&
                this.global.dsUser.accountType !== "Admin" ? null : (
                  <button
                    className="remove-box ml-2"
                    type="button"
                    onClick={() => this.dispatch.removeProgramHoliday(sch)}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                )}
              </div>
            </div>
          ))}
          <div className="row">
            <div className="col-12" style={{ textAlign: "center" }}>
              <button
                onClick={this.dispatchNewHoliday}
                className="add-form-button"
                disabled={
                  this.global.profile.programSubmitted &&
                  this.global.dsUser.accountType !== "Admin"
                }
              >
                Add Personal Holiday
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-5">Charge Families?</div>
            <div className="col-7 d-flex">
              <input value="No" disabled></input>
            </div>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <>
        <BoxForm
          handleChange={this.handleChange}
          name={"PERSONAL HOLIDAYS"}
          customForm={this.customForm()}
        />
      </>
    );
  }
}

export default ProgramHolidayForm;
