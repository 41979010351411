import React, { Component } from "reactn";
import { format } from "date-fns";
import numeral from "numeral";
import InvoiceModal from "./InvoiceModal";
class DepositsModal extends Component {
  constructor(props) {
    super(props);
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 2), "Deposit Details"]
    });
  }
  state = {
    invoice: null
  };
  render() {
    const { item, setItem } = this.props;

    return this.state.invoice ? (
      <InvoiceModal
        invoice={this.state.invoice}
        setInvoice={_ => this.setState({ invoice: null })}
        slice={true}
      />
    ) : (
      <>
        <div className="cont">
          <div className="row">
            <div className="col-md-6">
              <h1>
                <a
                  role="button"
                  onClick={() => {
                    setItem(null);
                    this.setGlobal({
                      pathway: [...this.global.pathway.slice(0, 2)]
                    });
                  }}
                >
                  <i className="fas fa-arrow-left"></i>
                </a>{" "}
                Deposit Details
              </h1>
            </div>
          </div>
        </div>
        {
          <div className="cont">
            <div className="row">
              <div
                style={{
                  width: window.innerWidth > 700 ? "60%" : "90%",
                  backgroundColor: "white",
                  margin: window.innerWidth > 700 ? "0" : "0 auto",
                  position: "relative"
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    color: "white",
                    background:
                      "linear-gradient(to right, #7ea0ff 0%, #915aff 100%)"
                  }}
                >
                  <p style={{ marginBottom: 0 }}>
                    <b>Service-Period</b>:{" "}
                    {`${format(
                      new Date(item.arrival_date * 1000 - 3600000 * 24 * 7),
                      "LLL d"
                    )} - ${format(
                      new Date(item.arrival_date * 1000),
                      "LLL d, yyyy"
                    )}`}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    <b>Deposited</b>:{" "}
                    {format(new Date(item.arrival_date * 1000), "LLLL d, yyyy")}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "5px",
                    padding: "5px 20px 5px 20px",
                    borderBottom: "1px solid #dadbeb",
                    color: "black"
                  }}
                >
                  <b style={{ width: "30%" }}>Amount</b>
                  <b style={{ width: "30%" }}>Type</b>
                  <b style={{ width: "30%" }}>Action</b>
                </div>
                {item.transactions.slice(1).map(item => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                      padding: "5px 20px 5px 20px"
                    }}
                    key={item.id}
                  >
                    <p style={{ fontWeight: "bold" }} style={{ width: "30%" }}>
                      {numeral(item.net / 100).format("($0,0.00)")}
                    </p>
                    <p style={{ fontWeight: "bold" }} style={{ width: "30%" }}>
                      {item.invoice
                        ? "Enrollment"
                        : item.reporting_category === "charge"
                        ? "Dreamschools Credit"
                        : "Refund"}
                    </p>
                    <p style={{ fontWeight: "bold" }} style={{ width: "30%" }}>
                      {item.invoice && (
                        <button
                          style={{
                            fontFamily: "Open Sans",
                            fontWeight: "700",
                            fontSize: "10px",
                            lineHeight: "14px",
                            color: "#fff",
                            padding: "10px 25px",
                            background: "#9bb0eb",
                            transition: "all 0.15s ease",
                            border: 0,
                            margin: "0 1px"
                          }}
                          onClick={_ =>
                            this.setState({
                              invoice: { ...item.invoice, fee: item.fee }
                            })
                          }
                        >
                          View
                        </button>
                      )}
                    </p>
                  </div>
                ))}
                <h5
                  style={{
                    textAlign: "left",
                    color: "#042470",
                    marginTop: "10px",
                    marginLeft: "10px"
                  }}
                >
                  Total Deposit Amount :{" "}
                  {numeral(Math.abs(item.transactions[0].amount) / 100).format(
                    "($0,0.00)"
                  )}
                </h5>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default DepositsModal;
