import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { format, isToday } from "date-fns";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  DROPDOWN,
  DATE_PICKER,
  SELECT_MULTIPLE_DROPDOWN,
  GET_HTML
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class PaidOfflineModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isToday: false
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async paidOffline(e) {
    e.preventDefault();
    console.log("paidOffline");
    const ep = `${process.env.REACT_APP_API}/partners/orders/invoice/paid-offline`;
    const res = await axios.post(ep, {
      id: this.props.match.params.id,
      invoice_status: "Paid Offline",
      status: "Paid",
      organizationName: this.props.organizationName,
      userName: this.props.userName,
      userEmail: this.props.userEmail,
      totalAmount: this.props.totalAmount / 100
    });

    console.log("res", res);
    if (res.data.success) {
      toast.success("Mark Paid Offline");
      this.props.refreshPage();
      this.props.onClose();
    }
  }

  // componentDidMount() {
  //   this.setState({
  //     isToday: isToday(new Date(this.props.data.orderStartDate))
  //   });
  // }

  descriptionInput = () => {
    return (
      <div id="heightset" className="modal-main">
        <p
          style={{
            fontSize: "16px",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262"
          }}
        >
          Are you sure you want to mark this invoice as Paid Offline?
        </p>
        <p
          style={{
            fontSize: "16px",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262"
          }}
        >
          Only proceed with this, if your client has indicated that they will be
          using either cash, check, or an eTransfer to pay for this invoice.
        </p>
        <p
          style={{
            fontSize: "16px",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262"
          }}
        >
          An email will also be sent to them stating that this invoice is to be
          paid offline.
        </p>
      </div>
    );
  };

  getBaseModalFields() {
    const descriptionText = {
      type: GET_HTML,
      data: {
        gethtml: this.descriptionInput
      }
    };

    const fields = [];
    fields.push(descriptionText);

    return fields;
  }

  getBaseModalButtons = () => {
    const confirmSend = {
      name: "Mark Paid Offline",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.paidOffline(e)
    };

    const fieldsbutton = [];
    fieldsbutton.push(confirmSend);

    return fieldsbutton;
  };

  getBaseModalProps = () => {
    return {
      title: "Mark Paid Offline",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    console.log("this.state", this.state);
    console.log("this.global", this.global);
    console.log("this.props", this.props);
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <ModalTemplate
        heading="Mark Paid Offline"
        onClose={this.props.onClose}
        style={{ overflow: "visible" }}
      >
        <form onSubmit={e => this.paidOffline(e)}>
          <div id="heightset" className="modal-main">
            <p style={{ fontSize: "16px" }}>
              Are you sure you want to mark this invoice as Paid Offline?
            </p>
            <p style={{ fontSize: "16px" }}>
              Only proceed with this, if your client has indicated that they
              will be using either cash, check, or an eTransfer to pay for this
              invoice.
            </p>
            <p style={{ fontSize: "16px" }}>
              An email will also be sent to them stating that this invoice is to
              be paid offline.
            </p>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <img
                  src={CircleCheck}
                  alt=""
                  style={{
                    color: "white",
                    height: "15px",
                    width: "15px",
                    marginRight: "8px"
                  }}
                />
                <span>Mark Paid Offline</span>
              </div>
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(PaidOfflineModal);
