import React from "react";
import { ArrowLeftBox } from "../../assets";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
const BoxOrange = ({ boxName, boxCount, boxNew, title, link, history }) => (
  <div
    className="boxorange"
    onClick={_ => history.push(link)}
    style={{ cursor: "pointer" }}
  >
    {title ? <h4>{title}</h4> : null}
    <h2>{boxName}</h2>
    <div className="inlinebox">
      <h3>{boxCount}</h3>
    </div>
    <div className="inlinebox">
      <p>+{boxNew}</p>
      <p>This Week</p>
    </div>
    <img src={ArrowLeftBox} className="arrowright" alt="" />
  </div>
);

BoxOrange.propTypes = {
  boxName: PropTypes.string,
  boxCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  boxNew: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string
};

BoxOrange.defaultProps = {
  boxName: "",
  boxCount: 0,
  boxNew: 0,
  title: null
};

export default withRouter(BoxOrange);
