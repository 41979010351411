import React, { useGlobal } from "reactn";
import { Link } from "react-router-dom";
import {
  DSfees,
  Promotion,
  Credit,
  Invoice,
  EducatorPayout,
  Overview,
  Enrollment,
  PaymentMethod,
  Profile,
  InterestedProgram,
  ProgramOperation,
  ProgramSettings,
  FamilyProfile,
  Document
} from "../../assets";

const AccountLinks = [
  {
    name: "Pre-Screening",
    icon: FamilyProfile,
    to: "/admin/pre-screening"
  },
  {
    name: "Applications",
    icon: PaymentMethod,
    i: "fa-cog",
    to: "/admin/educator-applications"
  },
  {
    name: "Program Settings",
    icon: ProgramSettings,
    to: "/admin/program-settings"
  },
  {
    name: "Program Operations",
    icon: ProgramOperation,
    to: "/admin/program-operations"
  },
  {
    name: "Resources",
    icon: Document,
    to: "/admin/resources"
  }
];

const FamilyLinks = [
  {
    name: "Family Registration",
    icon: Profile,
    to: "/admin/family-registration"
  },
  {
    name: "Plan Requests",
    icon: Document,
    to: "/admin/plan-requests"
  },
  {
    name: "Requested Tours",
    icon: PaymentMethod,
    to: "/admin/booked-tours"
  },
  {
    name: "Applications",
    icon: PaymentMethod,
    to: "/admin/family-applications"
  },
  {
    name: "Enrollments",
    icon: Enrollment,
    to: "/admin/active-enrollments"
  },
  /*{
    name: "Interested Programs",
    icon: InterestedProgram,
    to: "/admin/interested-programs"
  },*/
  {
    name: "Parent Leads",
    icon: Profile,
    to: "/admin/family-profiles"
  }
];
const FollowUps = [
  {
    name: "Follow-ups (Auto)",
    icon: ProgramSettings,
    to: "/admin/auto-followups"
  },
  {
    name: "Search by phone",
    icon: PaymentMethod,
    to: "/admin/search-phone"
  }
];
const FinanceLinks = [
  {
    name: "Overview",
    icon: Overview,
    to: "/admin/finances-overview"
  },
  {
    name: "Educator Payouts",
    icon: EducatorPayout,
    to: "/admin/educator-payouts"
  },
  {
    name: "Invoices",
    icon: Invoice,
    to: "/admin/invoices"
  },
  {
    name: "Credits",
    icon: Credit,
    to: "/admin/credits"
  },
  {
    name: "Promotions",
    icon: Promotion,
    to: "/admin/promotions"
  },
  {
    name: "DS Fees",
    icon: DSfees,
    to: "/admin/dsfees"
  }
];

const PlatformTools = [
  {
    name: "Interested Leads",
    icon: Profile,
    to: "/admin/partner-leads"
  },
  {
    name: "Software Clients",
    icon: Overview,
    to: "/admin"
  },
  {
    name: "Client Invoices",
    icon: Invoice,
    to: "/admin"
  }
];

const SideMenu = () => {
  const [global, setGlobal] = useGlobal();

  return (
    <>
      <div className="sidebar-heading mt-0">EDUCATORS</div>

      <li className="nav-item active">
        {AccountLinks.map((link, key) => {
          return (
            <Link
              className="nav-link"
              to={
                global.dsUser.displayName !== "Dreamschools Community"
                  ? link.to
                  : "/admin"
              }
              key={key}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              disabled={global.dsUser.displayName === "Dreamschools Community"}
            >
              {link.icon && <img src={link.icon} alt="" />}
              {/* {link.i && <i class={`fas ${link.i}`}></i>} */}
              <span>{link.name}</span>
            </Link>
          );
        })}
      </li>

      {/* <!-- Divider --> */}
      <hr className="sidebar-divider my-0" />

      {/* <!-- Heading --> */}
      <div className="sidebar-heading">FAMILIES</div>

      {/* <!-- Nav Item - Dashboard --> */}
      <li className="nav-item active">
        {FamilyLinks.map((link, key) => {
          return (
            <Link
              className="nav-link"
              to={link.to}
              key={key}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img src={link.icon} alt="" />
              <span>{link.name}</span>
            </Link>
          );
        })}
      </li>

      <div className="sidebar-heading">FOLLOW-UPS</div>
      <li className="nav-item active">
        {FollowUps.map((link, key) => {
          return (
            <Link
              className="nav-link"
              to={link.to}
              key={key}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img src={link.icon} alt="" />
              <span>{link.name}</span>
            </Link>
          );
        })}
      </li>
      {/* <!-- Heading --> */}
      <div className="sidebar-heading">FINANCES</div>

      {/* <!-- Nav Item - Dashboard --> */}
      <li className="nav-item active">
        {FinanceLinks.map((link, key) => {
          return (
            <Link
              className="nav-link"
              to={
                global.dsUser.displayName !== "Dreamschools Community"
                  ? link.to
                  : "/admin"
              }
              key={key}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              disabled={global.dsUser.displayName === "Dreamschools Community"}
            >
              <img src={link.icon} alt="" />
              <span>{link.name}</span>
            </Link>
          );
        })}
      </li>
      <div className="sidebar-heading">Platform Tools</div>

      {/* <!-- Nav Item - Dashboard --> */}
      <li className="nav-item active">
        {PlatformTools.map((link, key) => {
          return (
            <Link
              className="nav-link"
              to={
                global.dsUser.displayName !== "Dreamschools Community"
                  ? link.to
                  : "/admin"
              }
              key={key}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              disabled={global.dsUser.displayName === "Dreamschools Community"}
            >
              <img src={link.icon} alt="" />
              <span>{link.name}</span>
            </Link>
          );
        })}
      </li>
    </>
  );
};

export default SideMenu;
