import React from "reactn";
import { Link } from "react-router-dom";
import {
  FamilyProfile,
  ProgramSetup,
  PricingPlans,
  Milestones,
  TourRequests,
  Applications,
  Connections,
  Enrolled,
  Attendance,
  WeeklySlots,
  MyEarnings,
  FamilyPayments,
  PaymentSettings,
  ExpenseTracker,
  Tasks,
  Document
} from "../../assets";

const ProfileLinks = [
  {
    name: "My Application",
    icon: FamilyProfile,
    to: "/educators/my-application"
  },
  {
    name: "Program Setup",
    icon: ProgramSetup,
    to: "/educators/program-setup",
    applicationDisabled: true
  },
  {
    name: "Pricing Plans",
    icon: PricingPlans,
    to: "/educators/pricing-plans",
    applicationDisabled: true
  },
  {
    name: "Milestones",
    icon: Milestones,
    to: "/educators/milestones"
  },
  {
    name: "Checklist",
    icon: Tasks,
    to: "/educators/checklist"
  }
];

const MyLeadsLinks = [
  {
    name: "Tour Requests",
    icon: TourRequests,
    to: "/educators/tour-requests",
    programDisabled: true,
    applicationDisabled: true,
    publish: 1
  },
  {
    name: "Applications",
    icon: Applications,
    to: "/educators/applications",
    programDisabled: true,
    applicationDisabled: true,
    publish: 1
  },
  {
    name: "Connections",
    icon: Connections,
    to: "/educators/connections",
    publish: 1
  }
];

const EnrollmentLinks = [
  {
    name: "Enrolled",
    icon: Enrolled,
    to: "/educators/enrolled",
    programDisabled: true,
    applicationDisabled: true,
    publish: 2
  },
  {
    name: "Attendance",
    icon: Attendance,
    to: "/educators/attendance",
    programDisabled: true,
    applicationDisabled: true,
    publish: 2
  },
  {
    name: "Weekly Slots",
    icon: WeeklySlots,
    to: "/educators/weekly-slots",
    programDisabled: true,
    applicationDisabled: true,
    publish: 2
  },
  {
    name: "Resources",
    icon: Document,
    to: "/educators/resources",
    programDisabled: true,
    applicationDisabled: true,
    publish: 2
  },
  {
    name: "Observations",
    icon: PaymentSettings,
    to: "/educators/createobservation",
    programDisabled: true,
    applicationDisabled: true,
    publish: 2
  },
  {
    name: "News Feed",
    icon: Document,
    to: "/educators/newsfeed",
    programDisabled: true,
    applicationDisabled: true,
    publish: 2
  }
];

const FinanceLink = [
  {
    name: "My Earnings",
    icon: MyEarnings,
    to: "/educators/my-earnings",
    programDisabled: true,
    applicationDisabled: true,
    publish: 2
  },
  {
    name: "Family Payments",
    icon: FamilyPayments,
    to: "/educators/family-payments",
    programDisabled: true,
    applicationDisabled: true,
    publish: 2
  },
  {
    name: "Expense Tracker",
    icon: ExpenseTracker,
    to: "/educators/expense-tracker",
    programDisabled: true,
    applicationDisabled: true,
    publish: 2
  },
  {
    name: "Payment Settings",
    icon: PaymentSettings,
    to: "/educators/connected",
    publish: 2
  },
  {
    name: "Tax Summary",
    icon: Document,
    to: "/",
    disabled: true
  }
];

class SideBar extends React.PureComponent {
  constructor() {
    super();
    this.isEnabled = this.isEnabled.bind(this);
  }

  isEnabled = link => {
    if (link.disabled) return true;
    if (this.global.profile.publishStage < link.publish) return true;
    if (link.applicationDisabled && link.programDisabled) {
      return (
        !this.global.profile.applicationSubmitted ||
        !this.global.profile.programSubmitted
      );
    } else if (link.applicationDisabled) {
      return !this.global.profile.applicationSubmitted;
    } else if (link.programDisabled) {
      return (
        this.global.profile.applicationSubmitted &&
        !this.global.profile.programSubmitted
      );
    }
    return false;
  };

  render() {
    return (
      <>
        <hr className="sidebar-divider my-0" />

        <div className="sidebar-heading mt-0">PROFILE</div>

        <li className="nav-item active">
          {ProfileLinks.map((link, key) => {
            return (
              <Link
                className={
                  this.isEnabled(link) ? "nav-link disabled-link" : "nav-link"
                }
                to={link.to}
                key={key}
                disabled={this.isEnabled(link)}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                {<img src={link.icon} alt="" />}
                <span>{link.name}</span>
              </Link>
            );
          })}
        </li>

        <hr className="sidebar-divider my-0" />

        <div className="sidebar-heading">MY LEADS</div>
        <li className="nav-item active">
          {MyLeadsLinks.map((link, key) => {
            return (
              <Link
                className={
                  this.isEnabled(link) ? "nav-link disabled-link" : "nav-link"
                }
                to={link.to}
                key={key}
                disabled={this.isEnabled(link)}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                {<img src={link.icon} alt="" />}
                <span>{link.name}</span>
              </Link>
            );
          })}
        </li>

        <hr className="sidebar-divider my-0" />

        <div className="sidebar-heading">ENROLLMENT</div>

        <li className="nav-item active">
          {EnrollmentLinks.map((link, key) => {
            return (
              <Link
                className={
                  this.isEnabled(link) ? "nav-link disabled-link" : "nav-link"
                }
                to={link.to}
                key={key}
                disabled={this.isEnabled(link)}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                {<img src={link.icon} alt="" />}
                <span>{link.name}</span>
              </Link>
            );
          })}
        </li>
        <hr className="sidebar-divider my-0" />

        <div className="sidebar-heading">FINANCES</div>
        <li className="nav-item active">
          {FinanceLink.map((link, key) => {
            return (
              <Link
                className={
                  this.isEnabled(link) ? "nav-link disabled-link" : "nav-link"
                }
                to={link.to}
                key={key}
                disabled={this.isEnabled(link)}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                {<img src={link.icon} alt="" />}
                <span>{link.name}</span>
              </Link>
            );
          })}
        </li>
      </>
    );
  }
}

export default SideBar;
