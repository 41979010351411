import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import AddAddress from "../../../../components/modals/AddAddressModal";
import ProgramSearch from "../../../../components/common/ProgramSearchInput";
import "../../../../assets/css/componentSpecificCss/eventListings.css";
import { DateTime } from "luxon";
import { zonedTimeToUtc } from "date-fns-tz";

class SectionOne extends React.PureComponent {
  constructor(props) {
    super(props);
    this.locations = [
      {
        address:
          this.global.organizationInfo.address1 +
          ", " +
          this.global.organizationInfo.city +
          ", " +
          this.global.organizationInfo.province +
          " " +
          this.global.organizationInfo.postalcode +
          ", " +
          this.global.organizationInfo.country
      },
      ...this.global.organizationInfo.other_locations.map(e => ({
        address: e.address
      }))
    ];
    this.state = {};
  }
  handleChange = (e, v) => {
    this.setGlobal({
      party: {
        ...this.global.party,
        partyType: this.props.initialPartyType,
        addChild: this.props.initialAddChild,
        application_required: this.props.initialApplicationRequired,
        allow_same_enrollment: this.props.allowSameEnrollment,
        manual_invoices: this.props.initialManualInvoices,
        [e]: v
      }
    });
  };

  //Loading the initial party addChild and manualInvoices from the create party modal
  async componentDidMount() {
    console.log(this.props);
    (this.props.initialManualInvoices ||
      this.props.initialAddChild ||
      this.props.initialApplicationRequired ||
      this.props.initialPartyType
    ) &&
      this.setGlobal(
        {
          party: {
            ...this.global.party,
            partyType: this.props.initialPartyType,
            manual_invoices: this.props.initialManualInvoices,
            addChild: this.props.initialAddChild,
            application_required: this.props.initialApplicationRequired,
            allow_same_enrollment: this.props.allowSameEnrollment
          }
        }
        // async () => await this.dispatch.saveParty()
      );
  }

  customForm = () => {
    console.log(this.global.party);
    // const dt = new Date(this.global.party.party_start).toISOString();
    const partyStartDisplay = DateTime.fromISO(this.global.party.party_start);
    const rezonedStartDisplay = partyStartDisplay.setZone(
      this.global.organizationInfo.timezone
    );

    const partyEndDisplay = DateTime.fromISO(this.global.party.party_end);
    const rezonedEndDisplay = partyEndDisplay.setZone(
      this.global.organizationInfo.timezone
    );

    return (
      <div className="formbody">
        {this.state.openAddress && (
          <AddAddress
            onClose={_ => this.setState({ openAddress: false })}
            open={this.state.openAddress}
            addLoc={address => {
              const other_locs = [
                ...this.global.organizationInfo.other_locations
              ];
              other_locs.push(address);
              this.locations.push(address);
              this.setGlobal({
                organizationInfo: {
                  ...this.global.organizationInfo,
                  other_locations: [...other_locs]
                },
                party: {
                  ...this.global.party,
                  address1: address.address
                }
              });
              this.dispatch.saveOrganizationalProfile();
            }}
          />
        )}
        <form>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Title<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  name="party_title"
                  className="input-fields"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.party.party_title}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Starts{" "}
                {this.global.party.party_start
                  ? `(${rezonedStartDisplay.toFormat("ZZZZ")})`
                  : ""}
                <span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <formTypes.Date
                time={true}
                name="party_start"
                timezone={this.global.organizationInfo.timezone}
                minDate={new Date()}
                onChange={(n, v) => {
                  let rezonedStart = "";
                  try {
                    const dt = zonedTimeToUtc(v, this.global.organizationInfo.timezone).toISOString();
                    const partyStart = DateTime.fromISO(dt);
                    rezonedStart = partyStart.setZone(
                      this.global.organizationInfo.timezone
                    );
                  } catch (error) {
                    rezonedStart = "";
                  }
                  // const rezonedStartToString = rezonedStart.toString();
                  // const convertedDate = new Date(rezonedStartToString);
                  // const overrideZone = DateTime.fromISO(new Date(v).toISOString(), { zone: this.global.organizationInfo.timezone });
                  // const convertedDate = new Date(overrideZone.toString()).toLocaleString("en-US", { timeZone: this.global.organizationInfo.timezone });
                  // const utc = DateTime.utc(overrideZone.toString());

                  // [n]: v.toISOString()

                  // Change other time input field
                  if (this.global.party.party_end && rezonedStart !== "") {
                    const currEnd = this.global.party.party_end;
                    const minDate = new Date(v).getTime() > new Date(this.global.party.party_end).getTime() ? rezonedStart : currEnd;
                    this.setGlobal({
                      party: {
                        ...this.global.party,
                        'party_end': minDate
                      }
                    })
                  } else {
                    this.setGlobal({
                      party: {
                        ...this.global.party,
                        'party_end': rezonedStart
                      }
                    })
                  }

                  this.setGlobal({
                    party: {
                      ...this.global.party,
                      [n]: rezonedStart.toString()
                    }
                  });
                }}
                value={rezonedStartDisplay.toHTTP()}
                shouldStayOpen={true}
              ></formTypes.Date>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Ends{" "}
                {this.global.party.party_end
                  ? `(${rezonedEndDisplay.toFormat("ZZZZ")})`
                  : ""}
                <span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <formTypes.Date
                  time={true}
                  name="party_end"
                  timezone={this.global.organizationInfo.timezone}
                  minDate={
                    this.global.party.party_start
                      ? new Date(this.global.party.party_start)
                      : null
                  }
                  onChange={(n, v) => {
                    let rezonedEnd = ""
                    try {
                      const dt = zonedTimeToUtc(v, this.global.organizationInfo.timezone).toISOString();
                      const partyEnd = DateTime.fromISO(dt);
                      rezonedEnd = partyEnd.setZone(
                        this.global.organizationInfo.timezone
                      );
                    } catch (err) {
                      console.log(err);
                      rezonedEnd = "";
                    }
                    
                    console.log("REZONED END", rezonedEnd);
                    // Change other input field if necessary
                    if (this.global.party.party_start && rezonedEnd !== "") {
                      const currStart = this.global.party.party_start;
                      const minDate = new Date(v).getTime() < new Date(this.global.party.party_start).getTime() ? rezonedEnd : currStart;
                      this.setGlobal({
                        party: {
                          ...this.global.party,
                          'party_start': minDate
                        }
                      })
                    }

                    this.setGlobal({
                      party: {
                        ...this.global.party,
                        [n]: rezonedEnd.toString()
                      }
                    });
                  }}
                  value={rezonedEndDisplay.toHTTP()}
                  shouldStayOpen={true}
                ></formTypes.Date>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Headcount<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  type="number"
                  min={0}
                  name="headcount"
                  className="input-fields"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.party.headcount}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Duration<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <select
                  value={this.global.party.duration}
                  className="input-fields"
                  name="duration"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  style={{ width: "100%", color: "#696969" }}
                >
                  <option value="">Please select</option>
                  <option value="0.5 hours">0.5 hours</option>
                  <option value="1 hour">1 hour</option>
                  <option value="1.5 hours">1.5 hours</option>
                  <option value="2 hours">2 hours</option>
                  <option value="2.5 hours">2.5 hours</option>
                  <option value="3 hours">3 hours</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3
                className="label-names"
                style={{ fontWeight: "bold", textDecoration: "underline" }}
              >
                Location Details
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                {this.global.party.partyType === "Field Trip" ? "Meet-up Address" : "Choose an Address"}<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <select
                  value={this.global.party.address1}
                  className="input-fields"
                  name="address1"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  style={{ width: "100%", color: "#696969" }}
                >
                  <option value="">Please select</option>
                  {this.locations.map(e => (
                    <option value={e.address}>{e.address}</option>
                  ))}
                </select>
              </div>
            </div>
            <div
              className="col-12 d-flex"
              style={{ justifyContent: "flex-end", marginTop: "10px" }}
            >
              <button
                type="button"
                className="btn addNew-btn" //btn-primary
                onClick={_ => this.setState({ openAddress: true })}
              >
                Add New Address
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Set-Up"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionOne;
