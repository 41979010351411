import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

const StepIndicator = props => {
  return (
    <div className="statusIndicator ml-0 pl-0">
      <div className="cont">
        <ProgressBar
          label={Math.floor(props.value) + "% Complete"}
          now={props.value}
        />
      </div>
    </div>
  );
};
export default StepIndicator;
