import React, { useState } from "react";
import PropTypes from "prop-types";
import InfoBalloon from "./InfoBalloon";
import { ImageActiveA } from "../../assets";
import Pagination from "../pagination/pagination";

const TabbedTable = props => {
  const { data, headings, tabs, actions, onTabChange } = props;
  const [useTab, setTab] = useState(0);
  const [usePage, setPage] = useState(0);
  const [useDataLength, setDataLength] = useState(
    data[tabs[0]] ? data[tabs[0]].length : 0
  );

  React.useEffect(() => {
    setDataLength(data[tabs[useTab]] ? data[tabs[useTab]].length : 0);

    //TEMPORARY
  }, [data, tabs, useTab]);

  const changeTab = i => {
    setDataLength(data[tabs[i]] ? data[tabs[i]].length : 0);
    setPage(0);
    setTab(i);
    if (onTabChange) onTabChange(i);
  };

  return (
    <>
      <div className="tablecont">
        <ul>
          {tabs.map((tab, i) => (
            <li key={tab} className={i === useTab ? "active" : ""}>
              <button onClick={() => changeTab(i)}>
                <img src={ImageActiveA} className="foractive" alt="" />
                {tab + `${data[tab] ? ` (${data[tab].length})` : ""}`}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="tablecont overflow-x-scroll">
        <table className="table">
          <thead>
            <tr>
              {headings[tabs[useTab]] &&
                headings[tabs[useTab]].map((heading, index) => (
                  <th
                    scope="col"
                    key={index}
                    style={heading.customStyle ? heading.customStyle : {}}
                  >
                    {heading.label}
                    {heading.infoBalloon && (
                      <InfoBalloon body={heading.infoBalloon} />
                    )}
                  </th>
                ))}
            </tr>
          </thead>

          <tbody>
            {data[tabs[useTab]] &&
              data[tabs[useTab]]
                .slice(usePage * 7, usePage * 7 + 7)
                .map((row, k) => {
                  return (
                    <tr key={k}>
                      {headings[tabs[useTab]] &&
                        headings[tabs[useTab]].map((heading, index) => {
                          if (heading.id === "action") {
                            return (
                              <td key={index} style={{ whiteSpace: "nowrap" }}>
                                {actions.map((action, i) => (
                                  <button
                                    onClick={() => action.action(row)}
                                    key={i}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    {action.name}
                                  </button>
                                ))}
                              </td>
                            );
                          } else {
                            return heading.customCell ? (
                              heading.customCell(row, index)
                            ) : (
                              <td key={index}>{row[heading.id]}</td>
                            );
                          }
                        })}
                    </tr>
                  );
                })}

            {!data || !data[tabs[useTab]] || !data[tabs[useTab]].length ? (
              <tr>
                <td
                  colSpan={
                    headings[tabs[useTab]] ? headings[tabs[useTab]].length : 5
                  }
                  className="text-center"
                >
                  Nothing to Show
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      {props.prePaginator ? props.prePaginator : null}
      <Pagination
        pageCount={Math.ceil(useDataLength / 7)}
        onPageChange={setPage}
        forcePage={usePage}
      />
    </>
  );
};

TabbedTable.propTypes = {
  headings: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  actions: PropTypes.array
};

TabbedTable.defaultProps = {
  headings: {},
  data: {},
  tabs: [],
  actions: []
};

export default TabbedTable;
