import React from "reactn";
import BoxOrange from "../../../components/common/BoxOrange";
import BoxBlue from "../../../components/common/BoxBlue";

class Leads extends React.PureComponent {
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="info-boxes">
            <div className="orange blue section">
              <BoxOrange
                boxName="Connections"
                boxCount={`${this.props.connectionsCount}`}
                boxNew={"0"}
              />
              <BoxOrange
                boxName="Leads"
                boxCount={`${
                  parseInt(this.props.formLeadsCount) +
                  parseInt(this.props.appointmentsCount)
                }`}
                boxNew={"0"}
              />
              <BoxOrange
                boxName="Applications"
                boxCount={`${this.props.applicationsCount}`}
                boxNew={"0"}
              />
              <BoxBlue
                boxName="Total Listings"
                boxCount={`${this.props.listingsCount}`}
                boxNew={"0"}
              />
              <BoxBlue
                boxName="Enrollments"
                boxCount={`${this.props.enrollmentsCount}`}
                boxNew={"0"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Leads;
