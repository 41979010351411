import React from "reactn";
import { Table, Modal } from "../../components/UI";
import axios from "axios";
import { toast } from "react-toastify";

const emptyFile = {
  file: {
    file: null,
    name: "",
    active: "Yes"
  }
};

class Documents extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [],
      ...emptyFile
    };

    this.makeActive = this.makeActive.bind(this);
    this.makeInactive = this.makeInactive.bind(this);
    this.addFile = this.addFile.bind(this);
    this.activeCell = this.activeCell.bind(this);

    this.headings = [
      { id: "name", label: "Document" },
      { id: "active", label: "Active", customCell: this.activeCell },
      { id: "action", label: "", customStyle: { width: 180 } }
    ];
    this.actions = [
      { name: "VIEW", action: this.viewDocument },
      { name: "ACTIVATE", action: this.makeActive },
      { name: "DEACTIVATE", action: this.makeInactive }
    ];
  }

  activeCell = (row, i) => {
    return <td key={`${i}-act`}>{row.active ? "Yes" : "No"}</td>;
  };

  async makeActive(row) {
    const ep = `${process.env.REACT_APP_API}/edu/document/activate/${row.id}`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      this.setState({
        data: this.state.data.map(d =>
          d.id === row.id ? { ...res.data.data, active: true } : { ...d }
        )
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  async makeInactive(row) {
    const ep = `${process.env.REACT_APP_API}/edu/document/deactivate/${row.id}`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      this.setState({
        data: this.state.data.map(d =>
          d.id === row.id ? { ...res.data.data, active: false } : { ...d }
        )
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  viewDocument(row) {
    window.open(`${process.env.REACT_APP_DS_FILES_S3}/${row.file}`);
  }

  async addFile() {
    try {
      const ep = `${process.env.REACT_APP_API}/edu/document`;
      if (!this.state.file.name) throw new Error("Name must not be empty");
      if (!this.state.file.active) throw new Error("Active must not be empty");
      if (!this.state.file.file) throw new Error("File must not be empty");
      const fd = new FormData();
      fd.set("name", this.state.file.name);
      fd.set("active", this.state.file.active);
      fd.set("file", this.state.file.file);
      const res = await axios.post(ep, fd);
      if (res.data.success) {
        this.setState({
          open: false,
          ...emptyFile,
          data: [...this.state.data, res.data.data]
        });
        toast("Added new file!");
      } else {
        throw new Error("Failed to add file.");
      }
    } catch (ex) {
      toast.error(ex.message);
    }
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/edu/document`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setState({ data: res.data.data });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    const { open } = this.state;
    return (
      <div className="container-fluid">
        <Modal
          open={open}
          heading="Add File"
          onClose={() =>
            this.setState({
              ...emptyFile,
              open: false
            })
          }
        >
          <div className="row">
            <div className="col-3">
              <p>Name</p>
            </div>
            <div className="col-9">
              <input
                type="text"
                value={this.state.file.name}
                onChange={e =>
                  this.setState({
                    file: { ...this.state.file, name: e.target.value }
                  })
                }
              />
            </div>

            <div className="col-3">
              <p>File</p>
            </div>
            <div className="col-9">
              <input
                type="file"
                onChange={e =>
                  this.setState({
                    file: {
                      ...this.state.file,
                      file: e.target.files.length ? e.target.files[0] : null
                    }
                  })
                }
              />
            </div>

            <div className="col-3">
              <p>Active</p>
            </div>
            <div className="col-9">
              <select
                value={this.state.file.active}
                onChange={e =>
                  this.setState({
                    file: { ...this.state.file, active: e.target.value }
                  })
                }
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-12 buttons">
              <button
                onClick={this.addFile}
                className="savebtn"
                style={{
                  // background: "#da4747",
                  // borderColor: "#d43636",
                  color: "white"
                }}
              >
                Add File
              </button>
            </div>
          </div>
        </Modal>
        <div className="cont justify-evenly">
          <h1>Resources</h1>
          <button
            className="header-button"
            onClick={() => this.setState({ open: true })}
          >
            Add New File
          </button>
        </div>
        <div className="cont">
          <Table
            headings={this.headings}
            data={this.state.data}
            actions={this.actions}
          />
        </div>
      </div>
    );
  }
}
export default Documents;
