import React from "reactn";
import axios from "axios";
import { toast } from "react-toastify";

class CreatePassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      verifyPassword: "",
      token: props.match.params.token
    };
    this.updatePassword = this.updatePassword.bind(this);
  }

  updatePassword = async e => {
    // const passRegex = new RegExp(
    //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/g
    // );
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/auth/reset-partner`;
    const location = this.props.location.search;
    try {
      if (this.state.password !== this.state.verifyPassword)
        throw new Error("Passwords do not match.");
      if (this.state.password.length < 6)
        throw new Error("Password must be at least 6 characters long.");
      // if (!passRegex.test(this.state.password))
      // throw new Error("Password does not meet the requirements.");
      this.setGlobal({ loading: true });
      const res = await axios.post(ep, this.state);
      if (res.data.success) {
        localStorage.setItem("ds_token", res.data.data.token);

        if (res.data.data.user.accountType === "Partner")
          this.props.history.push(
            !location ? "/partners" : location.slice(location.indexOf("=") + 1)
          );
        this.setState({ passReset: true });
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
      toast.error(ex.message);
    }
  };

  render() {
    return (
      <div className="login-signup-form">
        <div className="form-content custom-form-content">
          <h2>Create a Password</h2>
          {!this.state.passReset ? (
            <form id="new_parent" className="new_user" noValidate="novalidate">
              <div className="form-group">
                <label htmlFor="user_password">Password</label>{" "}
                <em>(Minimum 6 characters)</em>
                <br />
                <input
                  autoComplete="off"
                  className="form-control"
                  type="password"
                  name="password"
                  id="user_password"
                  minLength="6"
                  onChange={e =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                />
              </div>

              <div className="form-group">
                <label htmlFor="user_vpassword">Confirm Password</label>
                <br />
                <input
                  autoComplete="off"
                  className="form-control"
                  type="password"
                  name="verifyPassword"
                  id="user_vpassword"
                  minLength="6"
                  onChange={e =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                />
              </div>

              <div className="form-actions">
                <button
                  type="button"
                  className="btn btn-custom"
                  onClick={this.updatePassword}
                >
                  Create Password
                </button>
              </div>
            </form>
          ) : (
            <div className="text-center">
              Your password has been created!
              <br />
              You will be automatically signed in.
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CreatePassword;
