import React, { useState, useEffect } from "reactn";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { CircleCheck } from "../../../assets";
import {
  ModalTemplate,
  ModalFields
} from "../../../components/UI/modalComponents";

const DOLLAR_TO_CENT = 100;

const AddLineSubscriptionModal = ({
  subscriptionId,
  customerId,
  onClose,
  saveItem
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(1);
  // Unit Amount
  const [selectedItem, setSelectedItem] = useState();
  const [saveFuture, setSaveFuture] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const [existingLineItems, setExistingLineItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const ep = `${process.env.REACT_APP_API}/partners/pricing_items`;
      const res = await axios.get(ep);
      console.log("res", res);
      if (res.data.success) {
        setExistingLineItems(res.data.data);
      }
    };
    fetchData();
  }, []);

  const onSubmit = async e => {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/partners/sub-invoice/line`;

    let postObj;

    if (selectedItem != null) {
      const item = existingLineItems.find(
        item => item.id === parseInt(selectedItem)
      );
      postObj = {
        name: item.itemName,
        description: item.description,
        amount: item.rate,
        saveFuture: false,
        quantity,
        subscriptionId,
        customerId
      };
    } else {
      postObj = {
        name,
        description,
        amount: parseInt(amount * 100),
        saveFuture,
        quantity,
        subscriptionId,
        customerId
      };
    }

    await axios.post(ep, postObj);
    saveItem();
    onClose();
    return;
  };

  return (
    <ModalTemplate
      heading="Add Invoice Item"
      onClose={onClose}
      style={{ overflow: "visible" }}
    >
      <form onSubmit={e => onSubmit(e)}>
        <div id="heightset" className="modal-main">
          <ModalFields.select
            label="Existing Item"
            name="selectedExistingLineItem"
            onChange={e => {
              setSelectedItem(e.target.value);
            }}
            required={false}
          >
            <option value={""}>Please Select</option>
            {existingLineItems.map((e, i) => (
              <option value={e.id} key={e.id}>
                {e.itemName}
              </option>
            ))}
          </ModalFields.select>

          {!selectedItem && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "16px",
                  color: "#7B7B90",
                  fontWeight: 600,
                  marginBottom: "10px"
                }}
              >
                OR
              </div>
              <ModalFields.input
                label="Name"
                name="itemName"
                type="text"
                required={false}
                placeholder="Item Name"
                onChange={e => {
                  setName(e.target.value);
                }}
                value={name}
              />
              <ModalFields.input
                label="Internal Description (Optional)"
                name="description"
                type="text"
                required={false}
                placeholder="Enter Internal Description"
                onChange={e => {
                  setDescription(e.target.value);
                }}
                value={description}
              />
              <ModalFields.input
                label="Unit Amount ($)"
                name="rate"
                type="number"
                value={amount}
                required={false}
                step={0.01}
                placeholder="$"
                onChange={e => {
                  setAmount(e.target.value);
                }}
              />
              <ModalFields.switch
                label="Save for future use?"
                name="isSaveItem"
                checked={saveFuture}
                onClick={e => {
                  setSaveFuture(!saveFuture);
                }}
                tooltip="Will be available for future use"
              />
            </>
          )}
          <ModalFields.input
            label="Quantity"
            name="rate"
            type="number"
            min={1}
            value={quantity}
            required={false}
            onChange={e => {
              setQuantity(e.target.value);
            }}
          />
        </div>
        <div className="modal-footer">
          <button type="submit" className="btn">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <img
                src={CircleCheck}
                alt=""
                style={{
                  color: "white",
                  height: "15px",
                  width: "15px",
                  marginRight: "8px"
                }}
              />
              <span>Create Invoice</span>
            </div>
          </button>
        </div>
      </form>
    </ModalTemplate>
  );
};

export default AddLineSubscriptionModal;
