import React from "reactn";
import { withRouter } from "react-router-dom";
import { format } from "date-fns";
import { Table } from "../../UI";

class PromotionsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      statusActive: false
    };
    this.headings = [
      { id: "id", label: "Promo Code" },
      { id: "name", label: "Promo Name" },
      { id: "validFor", label: "Redeem By", customCell: this.validForCell },
      {
        id: "max_redemptions",
        label: "Redemptions Per Educator",
        customCell: this.redemptionCell
      },
      {
        id: "promoAppliesTo",
        label: "Applied",
        customCell: (r, i) => (
          <td key={`${i}-app`} style={{ textTransform: "capitalize" }}>
            {r.duration}
            {r.duration === "repeating" ? (
              <div>({r.duration_in_months} months)</div>
            ) : null}
          </td>
        )
      },
      {
        id: "promoDiscountValue",
        label: "Discount Amount",
        customCell: this.discountCell
      },
      { id: "action", label: "" }
    ];
    this.actions = [{ name: "VIEW", action: this.viewPromo }];
    this.viewPromo = this.viewPromo.bind(this);
  }

  componentWillReceiveProps = props => {
    if (props.data.length) {
      this.setState({ data: props.data });
    }
  };

  statusCell = (r, i) => {
    let statusSet = "";
    if (new Date(r.dateEnd) < new Date()) {
      statusSet = "Expired";
    } else if (new Date(r.dateStart) > new Date()) {
      statusSet = "Pending";
    } else {
      statusSet = "Active";
    }
    return <td key={`${i}-s`}>{statusSet}</td>;
  };

  viewPromo = (r, i) => {
    // this.props.viewPromo(r);
    alert("Temporarily Disabled");
  };

  discountCell = (r, i) => {
    if (!r.id) return;
    return (
      <td key={`${i}-disc`}>
        {r.metadata.ds_fee_promo === "true" ? "DS Fee & " : ""}
        {r.amount_off ? `$${parseFloat(r.amount_off / 100).toFixed(2)}` : ""}
        {r.percent_off ? `${r.percent_off}%` : ""}
      </td>
    );
  };

  redemptionCell = (r, i) => {
    if (!r.id) return;
    return (
      <td key={`${i}-red`}>
        {/* {r.times_redeemed} */}
        {r.max_redemptions ? `${r.max_redemptions}` : "-"}
      </td>
    );
  };

  validForCell = (r, i) => {
    if (!r.id) return;
    return (
      <td key={`${i}-valF`}>
        {r.redeem_by
          ? format(new Date(r.redeem_by * 1000), "LLL dd, yyyy")
          : "-"}
      </td>
    );
  };

  render() {
    return (
      <Table
        headings={this.headings}
        actions={this.actions}
        data={this.state.data}
      />
    );
  }
}

export default withRouter(PromotionsTable);
