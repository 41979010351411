import React from "reactn";
import axios from "axios";
import { format, differenceInBusinessDays } from "date-fns";
import { Modal } from "../../../components/UI";
import PaymentSettings from "../../../pages/parents/paymentSettings";
import { toast } from "react-toastify";
import { TextField } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import {
  StripeProvider,
  CardElement,
  Elements,
  injectStripe
} from "react-stripe-elements";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
const dateFns = require("date-fns");
const moment = require("moment");
// const momentTZ = require("moment-timezone");

class _CardForm extends React.Component {
  state = {
    errorMessage: ""
  };

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  handleSubmit = async evt => {
    evt.preventDefault();
    const ep = `${process.env.REACT_APP_API}/stripe/client`;
    if (this.props.stripe) {
      try {
        const token = await this.props.stripe.createToken({
          name: this.global.dsUser.displayName
        });
        if (token.error)
          throw new Error("There was an error processing your card!");
        const res = await axios.post(ep, {
          source: token
        });
        if (res.data.success) {
          this.setGlobal({
            stripe: res.data.data
          });
          this.props.update(res.data.data);
          toast("Successfully saved payment info!");
        } else {
          toast(res.data.message);
        }
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (ex) {
        toast.error(ex.message);
        console.error(ex);
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    } else {
    }
  };

  render() {
    return (
      <>
        <CardElement onChange={this.handleChange} />
        <p style={{ color: "red" }}>{this.state.errorMessage}</p>
        <button
          type="button"
          className="parent__handbook_link mt-3"
          onClick={e => this.handleSubmit(e)}
          style={{
            borderRadius: "8px"
          }}
        >
          Save
        </button>
      </>
    );
  }
}
const CardForm = injectStripe(_CardForm);
class Acceptance extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      application: { daysOfWeek: [] },
      program: null,
      checklist: null,
      info: null,
      child: null,
      gallery: null,
      credentials: null,
      plan: null,
      paymentInfo: null,
      promo: null,
      promoDiscount: 0,
      promoValid: false,
      paymentModalShown: false,
      payNowModalShown: false,
      couponEntered: "",
      promoError: false,
      stripe: null,
      cardElement: null,
      backgroundFile: null
    };
    this.CardEl = React.createRef();
    this.closePaymentMethods = this.closePaymentMethods.bind(this);
    this.payNowModal = this.payNowModal.bind(this);
    this.payDeposit = this.payDeposit.bind(this);
    this.updatePayment = this.updatePayment.bind(this);
  }

  updatePayment = async () => {
    const ep = `${process.env.REACT_APP_API}/stripe/client`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      if (res.data.success) {
        this.setState({ paymentModalShown: false, paymentInfo: res.data.data });
        this.forceUpdate();
      } else {
        throw new Error("Failed to update payment info.");
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      console.error(ex);
      toast.error("Couldn't update payment info.");
      this.setGlobal({ loading: false, lastAPICall: null });
      this.setState({ paymentModalShown: false });
    }
  };

  closePaymentMethods = () => {
    this.setState({
      paymentModalShown: false
    });
  };

  payNowModal = res => {
    this.setState({ payNowModalShown: false, bgcOpen: false });
  };

  getFirstEnrollmentInvoiceDate(application) {
    if (
      Math.abs(
        differenceInBusinessDays(
          new Date(application.depositDueDate),
          new Date(application.startDate)
        )
      ) >= 3
    ) {
      return format(
        new Date(dateFns.subBusinessDays(new Date(application.startDate), 3)),
        "LLL dd, yyyy"
      );
    }
    if (dateFns.isWeekend(new Date())) {
      return format(
        new Date(
          dateFns.startOfWeek(dateFns.addWeeks(new Date(), 1), {
            weekStartsOn: 1
          })
        ),
        "LLL dd, yyyy"
      );
    }
    if (parseInt(moment(new Date()).tz("America/Toronto").format("HH")) > 14) {
      return format(
        new Date(dateFns.addBusinessDays(new Date(), 4)),
        "LLL dd, yyyy"
      );
    }
    return format(new Date(), "LLL dd, yyyy");
  }

  async payDeposit() {
    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/stripe/deposit/${params.slug}/${params.time}`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.post(ep, {});
      if (res.data.success) {
        toast("Successfully paid deposit!");
        this.setState({ application: res.data.data, payNowModalShown: false });
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (er) {
      toast.error("Failed to pay deposit.");
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }
  async checkPromoCode() {
    const endPoint = `${process.env.REACT_APP_API}/stripe/coupon/save`;
    const result = await axios.post(endPoint, {
      enrollmentId: this.state.application.id,
      couponId: this.state.couponEntered.trim()
    });

    if (!result.data.success) this.setState({ promoError: true });
    else {
      let discAmount = parseFloat(this.state.plan.amount);
      if (result.data.data.percent_off) {
        discAmount = parseFloat(
          (discAmount * parseFloat(result.data.data.percent_off)) / 100
        ).toFixed(2);
      } else {
        discAmount = parseFloat(result.data.data.amount_off / 100).toFixed(2);
      }
      this.setState({
        application: {
          ...this.state.application,
          promoCodeId: this.state.couponEntered.trim(),
          promoCodeName: result.data.data.name
        },
        promoError: false,
        promoDiscount: discAmount,
        promoValid: true,
        promo: result.data.data
      });
    }
  }

  async componentDidMount() {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE) });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE) });
      });
    }
    //const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/par/enrollment/app/view/${this.props.id}`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      this.setGlobal({
        loading: false,
        lastAPICall: res
      });
      this.setState({
        ...res.data.data
      });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  convertToRealTime(time) {
    const fraction = time - parseInt(time);
    const minutes = (fraction * 15) / 0.25;
    const fullTime =
      fraction === 0
        ? parseInt(time) - 12 < 0
          ? time + " A.M. "
          : parseInt(time) !== 12
          ? time - 12 + " P.M. "
          : time + " P.M. "
        : parseInt(time) - 12 < 0
        ? parseInt(time) + `:${minutes} A.M. `
        : parseInt(time) !== 12
        ? parseInt(time) - 12 + `:${minutes} P.M. `
        : parseInt(time) + `:${minutes} P.M. `;

    return fullTime;
  }
  render() {
    const { program, credentials } = this.state;
    return (
      <>
        {/* <Modal
          open={this.state.paymentModalShown}
          heading="Payment Methods"
          onClose={this.closePaymentMethods}
        >
          <PaymentMethods />
        </Modal> */}
        {this.state.backgroundFile && (
          <Modal
            open={this.state.bgcOpen}
            heading="Background Check Results"
            onClose={this.payNowModal}
            style={{ zIndex: 99999 }}
          >
            <div className="row">
              <div className="col-12">
                <div
                  style={{
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor:
                      this.state.backgroundFile.classification === "clear"
                        ? "lightgreen"
                        : "#ebbf96",
                    marginBottom: "20px"
                  }}
                >
                  <div
                    style={{
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor:
                        this.state.backgroundFile.classification === "clear"
                          ? "darkgreen"
                          : "#b0590c"
                    }}
                  >
                    <CheckCircle
                      style={{
                        color: "white",
                        height: "50%",
                        width: "40px"
                      }}
                    />
                  </div>
                  <p
                    style={{
                      color:
                        this.state.backgroundFile.classification === "clear"
                          ? "darkgreen"
                          : "#b0590c",
                      marginBottom: "0"
                    }}
                  >
                    <b style={{ marginLeft: "10px" }}>
                      Completed - Criminal Record Search -{" "}
                      {this.state.backgroundFile.classification[0].toLocaleUpperCase() +
                        this.state.backgroundFile.classification.slice(1)}
                    </b>
                  </p>
                </div>
                <p>
                  <b>First Name</b>:{" "}
                  {this.state.backgroundFile.candidate.profile.firstName}
                </p>
                <p>
                  <b>Last Name</b>:{" "}
                  {this.state.backgroundFile.candidate.profile.lastName}
                </p>
                <p>
                  <b>Gender</b>:{" "}
                  {this.state.backgroundFile.candidate.profile.gender}
                </p>
                <p>
                  <b>Date of Birth</b>:{" "}
                  {dateFns.format(
                    new Date(
                      this.state.backgroundFile.candidate.profile.dateOfBirth
                    ),
                    "dd LLLL, yyyy"
                  )}
                </p>
                <p>
                  <b>Phone #</b>:{" "}
                  {
                    this.state.backgroundFile.candidate.profile.contactInfo
                      .phone
                  }
                </p>
                <p>
                  <b>Email</b>:{" "}
                  {
                    this.state.backgroundFile.candidate.profile.contactInfo
                      .email
                  }
                </p>
              </div>
            </div>
          </Modal>
        )}
        {/*this.state.paymentModalShown ? (
          <>
            <div
              style={{
                position: "fixed",
                right: 20,
                top: 60,
                lineHeight: "28px",
                background: "rgba(0, 0, 0, 0.3)",
                borderRadius: 5,
                textAlign: "center",
                color: "white",
                cursor: "pointer",
                zIndex: 9999
              }}
              className="px-3"
              onClick={() => this.updatePayment()}
            >
              <i className="fas fa-angle-left"></i> Back to Application
            </div>
            <PaymentSettings settingUpPayment={true} />
          </>
            ) : null*/}

        {!this.state.program || this.state.paymentModalShown ? null : (
          <>
            {/*<Modal
              open={this.state.payNowModalShown}
              heading="Pay Deposit"
              onClose={this.payNowModal}
            >
              <div className="row">
                <div className="col-12">
                  <p>
                    Upon successful payment of your deposit you will be billed
                    automatically on a recurring basis up until the point of
                    cancellation. The minimum comitement required to this
                    program is 3 weeks.
                  </p>
                  <p>
                    Pay before end of day{" "}
                    {format(
                      new Date(application.depositDueDate),
                      "LLLL dd, yyyy"
                    )}{" "}
                    to ensure you get the start date you wish. If paid after,
                    the start date will be modified to reflect 3 business days
                    out. The application will expire after 7 days of
                    non-payment.
                  </p>
                  <p>Your payment breakdowns are as follows:</p>
                  <br />
                  <p>
                    <span className="font-weight-bold">Deposit:</span> $
                    {parseFloat(plan.depositAmount).toFixed(2)}
                  </p>
                  {parseInt(this.state.application.dsFee) <= 7 && (
                    <p>
                      <span className="font-weight-bold">
                        Deposit Service Fee:
                      </span>{" "}
                      $
                      {parseFloat(
                        parseFloat(plan.depositAmount) * 0.01
                      ).toFixed(2)}{" "}
                      (1% of deposit)
                    </p>
                  )}
                  <p>
                    <span className="font-weight-bold">Deposit Total:</span> $
                    {parseFloat(
                      parseFloat(plan.depositAmount) +
                        (this.state.application.dsFee <= 7
                          ? parseFloat(plan.depositAmount) * 0.01
                          : 0)
                    ).toFixed(2)}{" "}
                    (Payable now)
                  </p>
                  <hr />
                  <p>
                    <span className="font-weight-bold">Enrollment:</span> $
                    {parseFloat(plan.amount).toFixed(2)}
                  </p>
                  {parseInt(this.state.application.dsFee) <= 7 && (
                    <p>
                      <span className="font-weight-bold">Service Fee:</span> $
                      {parseFloat(parseFloat(plan.amount) * 0.01).toFixed(2)}{" "}
                      (1% of enrollment)
                    </p>
                  )}
                  {!this.state.promoDiscount &&
                  (!this.state.promo ||
                    !this.state.promo.valid ||
                    !this.state.promoValid) ? null : (
                    <p>
                      <span className="font-weight-bold">Discount:</span> $
                      {this.state.promoDiscount} (Applied{" "}
                      {this.state.promo.duration === "repeating"
                        ? `for ${this.state.promo.duration_in_months} months`
                        : `${this.state.promo.duration}`}
                      )
                    </p>
                  )}
                  <p>
                    <span className="font-weight-bold">Enrollment Total:</span>{" "}
                    $
                    {parseFloat(
                      parseFloat(plan.amount) +
                        (this.state.application.dsFee <= 7
                          ? parseFloat(plan.depositAmount) * 0.01
                          : 0) -
                        (this.state.promo ? this.state.promoDiscount : 0)
                    ).toFixed(2)}{" "}
                    (Automatically billed{" "}
                    {/* {this.getFirstEnrollmentInvoiceDate(application)})}
                    {format(new Date(application.startDate), "LLL dd, yyyy")})
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="buttons">
                    <button onClick={this.payDeposit} className="nextbtn">
                      Pay Now
                      <br />
                      ($
                      {parseFloat(
                        parseFloat(plan.depositAmount) +
                          (this.state.application.dsFee <= 7
                            ? parseFloat(plan.depositAmount) * 0.01
                            : 0)
                      ).toFixed(2)}
                      )
                    </button>
                  </div>
                </div>
              </div>
            </Modal>*/}

            <div
              className="w-100"
              style={{
                minHeight: 240,
                backgroundImage: `url(${`${process.env.REACT_APP_DS_FILES_S3}/${this.state.gallery.programCoverPhoto}`})`,
                backgroundPosition: "center",
                backgroundSize: "cover"
              }}
            />
            <div
              className="cont mb-5"
              style={{ position: "relative", marginTop: "-100px" }}
            >
              <div className="row">
                <div className="col-md-9 col-12">
                  <div
                    className="w-100 p-3 bg-white"
                    style={{
                      borderRadius: "8px",
                      overflow: "hidden",
                      marginTop: 125
                    }}
                  >
                    <h6 className="text-center" style={{ fontStyle: "italic" }}>
                      You've been accepted to...
                    </h6>
                    <h3 className="text-center" style={{ color: "#0e0e0e" }}>
                      {this.state.info.programName}!
                    </h3>
                    <hr />
                    <p style={{ textIndent: "1rem" }} className="mt-3">
                      We at Dreamschools would first like to congratulate you on
                      your acceptance. You may refer to this page at any time to
                      read up on all of the information the Educator has
                      provided. Be sure to perform due diligence before
                      finalizing your enrollment and paying your deposit.
                    </p>
                    <h3 className="text-center" style={{ color: "#0e0e0e" }}>
                      Weekly Plan (# days / week)
                    </h3>
                    <ul>
                      <li>Days of Care : Mon - Fri .</li>
                      <li>Age Group : Toddlers.</li>
                      <li>Hours : From 5 to 9</li>
                      <li>Price : $50 / week.</li>
                    </ul>
                    {this.state.info.programType ===
                      "Licensed Daycare Center" && (
                      <h4
                        style={{
                          textAlign: "center",
                          color: "green",
                          marginTop: "10px",
                          fontWeight: "bold"
                        }}
                      >
                        This program is a licensed Child Care Centre. Approved
                        by the Ministry of Education.
                      </h4>
                    )}
                  </div>
                  {this.state.info.programType !==
                    "Licensed Daycare Center" && (
                    <div
                      className="w-100 p-3 bg-white mt-4 mb-3"
                      style={{
                        borderRadius: "8px",
                        overflow: "hidden"
                      }}
                    >
                      {}
                      <h5 className="text-center" style={{ color: "#0e0e0e" }}>
                        {this.state.info.educatorName}'s Checklist
                      </h5>
                      <hr />
                      <div className="boxes-row boxes-row-fluid">
                        {Object.keys(this.state.checklist).map(
                          (category, i) => {
                            const c = this.state.checklist[category];
                            return (
                              <div className="box box-50" key={i}>
                                <div className="bgofbox">
                                  <div className="header">
                                    <h2>{c.category}</h2>
                                  </div>
                                  <div className="formbody">
                                    {Object.keys(c.items).length ? null : (
                                      <div className="row">
                                        <div className="text-center col-12">
                                          No items to report.
                                        </div>
                                      </div>
                                    )}
                                    {Object.keys(c.items).map((item, j) => {
                                      const itm = c.items[item];
                                      return (
                                        <div className="row" key={j}>
                                          <div
                                            className="col-1"
                                            style={{ color: "#1cc88a" }}
                                          >
                                            <i className="fas fa-check"></i>
                                          </div>
                                          <div className="col-11">
                                            {itm.item}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-md-3 col-12">
                  <div className="w-100 text-center">
                    <div
                      style={{
                        padding: "20px",
                        borderRadius: "50%",
                        // background: "rgba(255, 255, 255, 0.3)"
                        background: "white",
                        maxHeight: "350px",
                        maxWidth: "350px",
                        margin: "auto"
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "100%",
                          width: "100%",
                          backgroundImage: `url(${`${process.env.REACT_APP_DS_FILES_S3}/${this.state.gallery.profilePhoto}`})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          borderRadius: "50%"
                        }}
                      />
                    </div>

                    <div
                      className="mt-3 sticky-top p-3 bg-white text-left"
                      style={{
                        borderRadius: "8px",
                        overflow: "hidden"
                      }}
                    >
                      <span className="font-weight-bold">
                        {this.state.info.educatorName}
                      </span>
                      <hr />
                      <span
                        style={{
                          fontSize: "12px"
                        }}
                      >
                        With Dreamschools since:{" "}
                        {format(new Date(program.createdAt), "LLL yyyy")}
                      </span>
                      <ul style={{ fontSize: "12px" }} className="pl-3 mb-1">
                        {credentials.firstAid === 2 ? (
                          <li>First Aid Certification</li>
                        ) : null}
                        {credentials.childCPR === 2 ? (
                          <li>CPR-C Certification</li>
                        ) : null}
                        {credentials.healthAssessment === 2 ? (
                          <li>Health Assessment</li>
                        ) : null}
                        {credentials.immunization === 2 ? (
                          <li>Immunized</li>
                        ) : null}
                        {credentials.registeredEducator === 2 ? (
                          <li>Registered Educator</li>
                        ) : null}
                        {credentials.registeredECE === 2 ? (
                          <li>
                            Registered Early Childhood Educator{" "}
                            {credentials.registeredECENumber
                              ? `#${credentials.registeredECENumber}`
                              : null}
                          </li>
                        ) : null}
                        {this.state.backgroundFile ? (
                          <>
                            <li>
                              Enhanced Background Check -{" "}
                              {this.state.backgroundFile.classification[0].toLocaleUpperCase() +
                                this.state.backgroundFile.classification.slice(
                                  1
                                )}
                              <a
                                href="#"
                                onClick={() => this.setState({ bgcOpen: true })}
                              >
                                {" "}
                                (View)
                              </a>
                            </li>
                          </>
                        ) : null}
                        {credentials.vsc === 2 ? (
                          <li>Vulnerable Sector Check</li>
                        ) : null}
                      </ul>
                      {!program.parentHandbook ? null : (
                        <button
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_DS_FILES_S3}/${program.parentHandbook}`
                            )
                          }
                          className="parent__handbook_link"
                        >
                          View Parent Handbook
                        </button>
                      )}
                    </div>
                  </div>

                  <div
                    className="mt-3 sticky-top p-3 bg-white text-left"
                    style={{
                      borderRadius: "8px",
                      overflow: "hidden"
                    }}
                  >
                    <span className="font-weight-bold">Deposit</span>
                    <hr />

                    <button
                      className="ds-button w-100"
                      style={{ borderRadius: "8px" }}
                      onClick={() => this.setState({ payNowModalShown: true })}
                      disabled={true}
                    >
                      PAY NOW
                    </button>
                  </div>
                  <div
                    className="mt-3 sticky-top p-3 bg-white text-left"
                    style={{
                      borderRadius: "8px",
                      overflow: "hidden"
                    }}
                  >
                    <span className="font-weight-bold">Promotions</span>
                    <hr />
                    {this.state.application.promoCodeId ? (
                      <div style={{ fontSize: "12px" }}>
                        <p>
                          Coupon <b>{this.state.application.promoCodeId}</b>{" "}
                          applied. ({this.state.application.promoCodeName})
                        </p>
                      </div>
                    ) : (
                      <div style={{ fontSize: "12px" }}>
                        <b>
                          Do you have a promo code ? <br />
                        </b>
                        {this.state.promoError && (
                          <b style={{ color: "red" }}>
                            Sorry, the code you provided is invalid!
                          </b>
                        )}
                        <TextField
                          placeholder="Enter your promo code here..."
                          fullWidth
                          variant="outlined"
                          disabled
                          style={{
                            border: "1px solid grey",
                            borderRadius: "5px"
                          }}
                          value={this.state.couponEntered}
                          onChange={e =>
                            this.setState({ couponEntered: e.target.value })
                          }
                          InputProps={{
                            endAdornment: (
                              <AddCircleOutlineIcon
                                onClick={() => this.checkPromoCode()}
                                style={{ cursor: "pointer" }}
                              />
                            )
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Acceptance;
