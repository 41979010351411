import React, { useState, useEffect, useGlobal } from "reactn";
import { CircleCheck } from "../../assets";
import axios from "axios";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { canUserCreate } from "../../utilities/listingCreation";
import { toast } from "react-toastify";
import AddNewAddonsModal from "./AddNewAddonsModal";

import { BUTTON, GET_HTML, SWITCH, TITLE } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import BaseModal from "./baseModal/BaseModal";

const PartyAddonsModal = ({ onClose, history, dispatch }) => {
  const [loading, setLoading] = useGlobal("loading");
  const [addons, setAddons] = useState([]);
  const [openAddNewAddonsModal, setOpenAddNewAddonsModal] = useState(false)
  const [selectedAddon, setSelectedAddon] = useState(undefined)

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/parties/addons`
    const result = await axios.get(ep);

    if (result.data.success) {
      setAddons(result.data.data);
    } else {
      toast.error("Failed to load add-ons")
    }
  }

  const getModal = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: 9
          }}
        >
          <div style={{ display: "block", width: "100%" }}>
            {addons.map((addon, i) => {
              return (
                <>
                  <label
                    htmlFor=""
                    style={{
                      width: "40%",
                      textAlign: "left",
                      fontSize: "1.15rem",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "500",
                      color: "#626262",
                      marginTop: "1vh",
                    }}
                  >
                    {addon.name}:
                  </label>
                  <label
                    htmlFor=""
                    style={{
                      width: "30%",
                      textAlign: "left",
                      fontSize: "1.15rem",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "500",
                      color: "#626262",
                      marginTop: "1vh",
                    }}
                  >
                    &nbsp;${((addon.price * 100) / 100).toFixed(2)}
                  </label>
                  <div
                    key={i}
                    style={{
                      width: "25%",
                      display: "inline-flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start"
                    }}
                    name=""
                  >
                    <button
                      style={{
                        backgroundColor: BLUE_BUTTON,
                        color: WHITE,
                        marginRight: 40,
                        width: 75
                      }}
                      className="button-field-button"
                      onClick={e => {
                        setSelectedAddon(addon.id)
                        setOpenAddNewAddonsModal(true)
                      }}
                      type={BUTTON}
                    >
                      {"Edit"}
                    </button>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "flex-start",
            marginBottom: 0
          }}
        >
          <button
            style={{
              backgroundColor: "#5cc8dc",
              color: WHITE,
              marginRight: 40,
              width: 75
            }}
            className="button-field-button"
            onClick={e => {
              setSelectedAddon(undefined)
              setOpenAddNewAddonsModal(true)
            }}
            type={BUTTON}
          >
            {"+ Add"}
          </button>
        </div>
      </>
    )
  }

  const getBaseModalFields = () => {
    const modal = {
      type: GET_HTML,
      data: {
        gethtml: getModal
      }
    };

    return [modal];
  };

  const getBaseModalButtons = () => {
    const closeButton = {
      name: "Close",
      handleClick: () => {
        onClose()
      },
      buttonColour: BLUE_BUTTON,
      textColour: WHITE
    };

    return [closeButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Manage Addons",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: onClose
    };
  };

  return (
    <>
      {openAddNewAddonsModal && (
        <AddNewAddonsModal
          selectedAddon={selectedAddon}
          onClose={() => {
            setOpenAddNewAddonsModal(false);
          }}
          refetch={async () => {
            await fetchData()
          }}
        />
      )}
      {!openAddNewAddonsModal && (
        <BaseModal {...getBaseModalProps()} />
      )}
    </>
  )
};

export default PartyAddonsModal;
