import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import { format } from "date-fns";

import { Promotions } from "../../assets";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const PromotionsPreview = ({ classes, history }) => {
  const [promotions, setPromotions] = useState([]);
  const getPromtions = async () => {
    const resp = await axios.get(
      `${process.env.REACT_APP_API}/partners/promotions/all`
    );

    if (resp.data.success) {
      const pros = resp.data.data.filter(f => f.isPublished);

      setPromotions(pros);
    }
  };
  useEffect(() => {
    getPromtions();
  }, []);
  return (
    <div>
      {promotions.map(promo => (
        <div
          onClick={() => {
            // push to program
            history.push(`/preview/promotion/${promo.id}`);
          }}
          key={promo.id}
          className={`${classes.plan} card-area`}
        >
          <LazyLoadImage
            src={Promotions}
            alt="Promotions"
            style={{
              width: "22%",
              height: "147px",
              objectFit: "cover",
              borderRadius: "7px",
              boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.1)"
            }}
            className="card-img"
            style={{ width: "100%" }}
          />
          <div className="card-content">
            <div className="card-sub1">
              <h4 className="sub1-name">
                {promo.title}{" "}
                {promo.custom_bill_name ? `: ${promo.custom_bill_name}` : null}
              </h4>
            </div>
            <div className="card-sub2">
              <span className="sub2-date">
                {promo.expiry_date
                  ? `Expires On: ${format(
                      new Date(promo.expiry_date),
                      "dd/MM/yyyy"
                    )}`
                  : ""}
              </span>
            </div>
            <div className="card-sub3">
              <h5 className="sub3-pricing">
                {promo.discount_type === "Percentage"
                  ? `${promo.discount_percent}%`
                  : promo.discount_type === "Fixed Amount"
                  ? `${convertCentsToDollars(promo.discount_fixed)}`
                  : "Promo"}
              </h5>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

function convertCentsToDollars(cents) {
  const dollars = cents / 100;
  return dollars.toLocaleString("en-US", {
    style: "currency",
    currency: "CAD"
  });
}
PromotionsPreview.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object
};

export default PromotionsPreview;
