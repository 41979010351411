import React from "react";
import {Redesign_Logo} from "./assets";
import { toast } from "react-toastify";
import axios from "axios";

const APP_NAME = "partner-site";             //@@FUTURE@@: Move this into the .env file.

var currentReturnURL = "/partners";          //@@FUTURE@@: Move this into the .env file.




/* ================================================================================================================= */
/* ================================================================================================================= */
/* ================================================================================================================= */


/**
 * This implements the React Error Boundary, and should trap all rendering-related errors,
 * except errors that happen in event handlers and asynchronous-functions not called by React.
 */
export default class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      incidentRefUid: null,
    };
    this.spinnerRef = React.createRef();

    this.formSubmitHandler = async (evt) => {
      evt.preventDefault();
      const incidentRefUid = this.state.incidentRefUid;
      const comments = evt.target?.comments?.value;
      //this.sendWsodComments.bind(this);
      this.slightlyDelayedSpinner(10);   //this starts the spinner (in the Send button) after 10ms delay.
      await AppErrorBoundary.sendWsodComments( incidentRefUid, comments );
      window.location.href = currentReturnURL;
    }
  }

  // there's a hidden spinner icon in the Send button. This function just displays it after a slight delay.
  // first priority is form submission - but when that's taking a little long then this spinner is needed.
  slightlyDelayedSpinner(delay) {
    const spinnerRef = this.spinnerRef.current;
    setTimeout(()=>{
      spinnerRef.style.removeProperty('display');  //default is display:none, so this line just unhides it!
    }, delay );
  }


  /**
   * One of two functions required for React's Error Boundary functionality to work!
   * When an error occurs during rendering (not events, not async) this will be triggered *first*!
   * Then some rendering happens, because we change the state. And then it calls "componentDidCatch()"!
   * @param {Error} error - it's the same(===) Error object received by componentDidCatch().
   * @returns {Object} - just returns values that affect the React state variables (ie. this.state)
   */
  static getDerivedStateFromError(error) {
    console.assert( !!error.incidentRefUid, "Was expecting UncaughtErrorHandler to have already assigned an incident Ref Id!" );
    if (!error.incidentRefUid)
      error.incidentRefUid = simpleUidGenerator();

    //there's an Uncaught & Unhandled Error Reporter that will check for this flag a few seconds from now.
    error.isHandled = true;

    // Update state so the next render will show the fallback UI.
    return { hasError: true, incidentRefUid: error.incidentRefUid };
  }


  /**
   * This notifies the server of an Uncaught Error or React White Screen of Death.
   * It's not asynchronous, and we continue with other client-side operations after this.
   * @param {Error} error 
   * @param {Object} errorInfo - whatever
   * @returns {string} - a randomly generated Incident Reference Id (generated client-side).
   */
  static notifyServerOfError( {isWSOD, incidentRefUid, error, errorInfo} ) {
    const ep = `${process.env.REACT_APP_API}/telemetry/error-alert`;
    let errorData = {
      isWSOD,
      incidentRefUid,
      error: error?.name,
      errorMsg: error?.message,
      errorStack: (typeof error?.stack === "string" ? error.stack : JSON.stringify(error?.stack)),
            //don't recall why I made this ^^^^ check for string. Maybe to reduce circular errors?
      componentStack: errorInfo?.componentStack,
      appName: APP_NAME,
      appVersion: process.env.REACT_APP_BUILD_DATE,
      currentUrl: window.location.href,
      userTime: new Date(),
      userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (console.IS_LOGGED_IN) {
      if (console.forceLogTransferNow) console.forceLogTransferNow();    //not intended to be an async function! 
    } else {
      errorData["logData"] = console.__capturedLogs.splice(0);
    }

    //Also make sure it uses the same methods to retry operation if not succeed at first.
    axios.post(ep, errorData /*,{timeout:5000}*/ )
      .then( (response) => {
        console.__log("notifyServerOfError response:",response.status,response.statusText);
      })
      .catch( (error) => {
        console.error("notifyServerOfError failed:",error);
      });
    return incidentRefUid;
  }


  static async sendWsodComments(incidentRefUid, comments) {
    const ep = `${process.env.REACT_APP_API}/telemetry/wsod-report`;
    const wsodUserReport = {
      incidentRefUid,
      appName: APP_NAME,
      comments
    };

    //Also make sure it uses the same methods to retry operation if not succeed at first.
    await axios.post(ep, wsodUserReport, {timeout:30000})
      .then( (response) => {
        console.__log("sendWsodComments response:",response.status,response.statusText);
      })
      .catch( (error) => {
        console.error("sendWsodComments failed:",error);
      });
  }


  /**
   * One of two functions required for React's Error Boundary functionality to work!
   * When an error occurs during rendering (not events, not async) this will be triggered *second*!
   * First, it calls getDerivedStateFromError(), and then some rendering, and then this function.
   * @param {Error} error - it's the same(===) Error object received by componentDidCatch().
   * @param {Object} errorInfo - is an object that contains a "componentStack" property.
   */
  componentDidCatch(error, errorInfo) {
    // generate Uid again if the "getDerivedStateFromError" above was too slow to create one.
    const incidentRefUid = this.state.incidentRefUid || simpleUidGenerator();
    AppErrorBoundary.notifyServerOfError({ isWSOD:true, incidentRefUid, error, errorInfo });

    // This is incase the "getDerivedStateFromError" function was too slow to create one.
    if (!this.state.incidentRefUid || this.state.incidentRefUid!==incidentRefUid)
      this.setState( incidentRefUid );
  }

  render() {
    if (this.state.hasError) {
      return this.renderErrorScreen();  //custom fallback UI
    }
    return this.props.children; 
  }

  renderErrorScreen() {
    return (
      <div className="container d-flex justify-content-center align-items-center min-vh-100">
        <div className="align-items-center flex-column row">
          <div className="col-5 align-items-center d-flex flex-column mb-3">
            <img src={Redesign_Logo} alt="logo" width="50%" height="50%" />
          </div>
          <div className="col-5">
            <h4>Oops! Something went wrong.</h4>
            <hr />
            <p>
              Sorry for the inconvenience. Please describe your actions before the screen went blank. Feel free to mention any information that could help us figure out how and why the problem is happening. Thank you.
            </p>
            <form id="incident-report-form" className="w-100" onSubmit={this.formSubmitHandler} >
              <div className="form-group">
                <textarea id="comments" name="comments" className="w-100" style={{height:"11rem"}} required></textarea>
              </div>
              <div className="d-flex flex-column mb-3">
                <button type="submit" id="submit-button" className="btn-outline-primary col-3">
                  Send &nbsp;
                  <i style={{display:"none"}} className="fas fa-circle-notch fa-spin" ref={this.spinnerRef} />
                </button>
              </div>
            </form>
            <hr />
          </div>
          <div className="col-5 mb-6">
            <a href={currentReturnURL}>Back to Home Screen</a>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
          <div style={{fontSize:"smaller"}}>Incident Ref.#: {this.state.incidentRefUid?.toUpperCase()}</div>
        </div>
      </div>
    )
  }
}




/* ================================================================================================================= */
/* ================================================================================================================= */
/* ================================================================================================================= */


/**
 * This is the Uncaught Error Handler! It should trap all remaining errors not caught
 * by our Error Boundary implementation above.
 * This is a general browser-side JS feature, not a React feature!
 */
window.addEventListener('error', function UncaughtErrorHandler(errorEvent) {

  //the following is a bypass for a recent Chrome bug that causes error events
  //to be raised when you start typing away in Chrome DevTools' console:
  //more info at: https://stackoverflow.com/q/72396527
  if (errorEvent.error?.constructor?.name === "EvalError"
    || errorEvent.error?.constructor?.name === "SyntaxError") {
    return;
  }
  //And this check below is to ignore an odd circumstance when CometChat's websocket is in an invalid state.
  //...but this particular error seems to happen exclusively with Safari on Mac!
  if (errorEvent.error?.name==="InvalidStateError"
    && errorEvent.error?.message==="The object is in an invalid state."
    && errorEvent.error?.stack?.startsWith("send@[native code]") ) {
    return;
  }
  //Ignore a common and annoying CometChat websocket error.
  if (errorEvent.error?.name==="InvalidStateError"
    && errorEvent.error?.message==="Failed to execute 'send' on 'WebSocket': Still in CONNECTING state." ) {
    return;
  }
  //Ignore another common and annoying CometChat websocket error.
  if (errorEvent.error?.name==="TypeError"
    && errorEvent.error?.message==="Cannot read properties of null (reading 'send')" ) {
    return;
  }

  // This filters out errors caused by some unknown extension that has the name or id: dbilanlcioamaadkbepcenpombaejbla.
  // The script of the extension seems to have an issue which causes a bunch of incident reports. It has been recommended
  // that these errors be silenced according to:
  // https://github.com/getsentry/sentry-javascript/issues/5289
  // https://github.com/getsentry/sentry-javascript/discussions/7668
  if ((errorEvent.message === "Uncaught TypeError: Illegal invocation" || errorEvent.error?.message === "Illegal invocation") &&
    errorEvent.error.stack &&
    (
      (typeof errorEvent.error.stack === "string" &&
        errorEvent.error.stack?.includes("chrome-extension://dbilanlcioamaadkbepcenpombaejbla")) ||
      (typeof errorEvent.error.stack !== "string" &&
        JSON.stringify(errorEvent.error.stack)?.includes("chrome-extension://dbilanlcioamaadkbepcenpombaejbla"))
    )) {
    return;
  }

  //===========================================================================

  //first assign an incident reference Id that we can later refer to.
  errorEvent.error.incidentRefUid = simpleUidGenerator();

  //and now log it! So incident reference id also associated with this error.
  if (console.logUncaught) {
    const {filename, lineno, colno, message, error:{stack, incidentRefUid}} = errorEvent;
    const _type = errorEvent instanceof ErrorEvent ? "ErrorEvent" : errorEvent.constructor.name;
    console.logUncaught( {_type, incidentRefUid, message, filename, lineno, colno, stack} );
  }

  //now delay reporting of this error, to give React a chance to handle it.
  //if React doesn't handle it within 8 secs, then we report to the server.
  const UNCAUGHT_ERROR_REPORTING_DELAY = 8_000;   //8sec.
  const error = errorEvent.error || {};
  setTimeout(()=>{
    console.assert( !!error.incidentRefUid, `Expected error to have an "incidentRefUid" property.`)
    if (error.incidentRefUid && !error.isHandled) {
      error.isHandled = true;   //this prevents duplicate processing in the dev environment, because of some weird duplication issue.

      AppErrorBoundary.notifyServerOfError({
        isWSOD: false,
        incidentRefUid: error.incidentRefUid,
        error,
      });
    }
  }, UNCAUGHT_ERROR_REPORTING_DELAY );

  toast.error("An error occurred!");
});





/**
 * This is the Unhandled Promise-Rejection Handler! It should trap all cases where a Promise throws
 * an error or rejects for some reason, but there's nothing but the window object to catch it.
 * In dev, React will show an error screen. In production, React won't give any indication of error.
 * This is a general browser-side JS feature, not a React feature!
 */
window.addEventListener('unhandledrejection', function UnhandledRejectionHandler(rejectionEvent) {
  //This is a bypass for Microsoft Outlook/Office365's Safe Links feature (possibly),
  //which triggers a barrage of uncaught rejection errors and incident report emails.
  //And it seems to happen with the links we send out over email.
  //It's possibly due to an error thrown inside their embedded, headless, chromium app, according to:
  //https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/13
  //https://github.com/getsentry/sentry-javascript/issues/3440
  if ((typeof rejectionEvent.reason === "string" || rejectionEvent.reason instanceof String)
      && rejectionEvent.reason.startsWith("Object Not Found Matching Id:")) {
    return;
  }

  // The Coupert Safari Extension for iOS throws this specific error when on a Dreamschools page. The error
  // occurs inside the extension's code but still causes Incident Reports. This is to silence the error and
  // prevent the toast UI error from popping up. 
  if ((typeof rejectionEvent.reason === "object") && 
    rejectionEvent.reason?.toString() === "TypeError: undefined is not an object (evaluating 'n.length')") {
    return;
  }

  if (rejectionEvent.reason instanceof Error) {
    console.error("unhandledrejection handler ignoring this:",rejectionEvent.reason);

    //This is a bypass for CometChat's uncaught exception when it can't access "localStorage".
    if (rejectionEvent.reason.message === "No available storage method found.") {
      return;
    }
    //This one is to ignore 401 Unauthorized errors. Someone may leave a browser tab open for too long.
    //The session may timeout, and we don't need to be alerted each time that happens.
    if (rejectionEvent.reason.isAxiosError && rejectionEvent.reason.response?.status===401) {
      return;
    }
    //This filters out Google Maps loading errors on iPhone, slow-loading connections, and other unknown reasons.
    if (rejectionEvent.reason.message?.startsWith(`Could not load "`)
        && rejectionEvent.reason.stack?.includes("https://maps.google.com/maps/api/js")) {
      return;
    }
    //This filters out a specific error that seems to happen with bots (AdsBot-Google, AhrefsBot, AppleBot)
    if (rejectionEvent.reason.message?.includes("google map initialization error (not loaded)")
        /*&& this.window.navigator.userAgent.includes("bot")*/) {
      return;
    }

    // This filters out network errors that are caused by internet issues on the user's end which resulting in
    // axios request timeouts that generate incident reports
    if (rejectionEvent.reason.message?.includes("Network Error") && !this.navigator.onLine) {
      return;
    }

    // This filters out Reference Errors that are caused by the user using Safari 14.x which doesn't have support
    // for the HTMLDialogElement.
    if (rejectionEvent.reason.message?.includes("HTMLDialogElement")) {
      return;
    }
  }

  if (typeof rejectionEvent.reason === "object") {
    //This filters out CometChat promise rejections that use this object as a reason:
    //{"code":"USER_NOT_LOGED_IN","name":"User not logged-in","message":"An authToken is need to use the appSettings end-point. PS- We are aware of the spelling mistake, but in order to maintain backward compatibility we cannot change it :("}
    if (rejectionEvent.reason.code === "USER_NOT_LOGED_IN") {
      return;
    }
    //This filters out a (GetStream)Stream error that mostly seems to happen when you logout of an impersonated partner.
    //rejectionEvent.reason = {"code":null,"params":[],"message":"JWT token was wrong: signature is invalid"}
    //The error message itself (JWT token was wrong...) is not found in source code, but sent through a websocket
    //connection through wss://faye-us-east.stream-io-api.com/faye
    if (rejectionEvent.reason.code === null
        && rejectionEvent.reason.message === "JWT token was wrong: signature is invalid"
        && !localStorage.getItem("ds_token")) {    //user not logged-in
      return;
    }
  }

  //===========================================================================

  //first, a precautionary measure, incase "reason" is not an Error object.
  const error = rejectionEvent.reason instanceof Error
    ? rejectionEvent.reason
    : {
        name: `Unhandled[Promise]Rejection`,
        message: `rejected value = ${
          typeof rejectionEvent.reason === "object" 
            ? rejectionEvent.reason?.toString() 
              ? rejectionEvent.reason.toString()
              : JSON.stringify(rejectionEvent.reason)
            : JSON.stringify(rejectionEvent.reason)
          }`
      };

  //next, create the incident reference Id that we can later refer to.
  const incidentRefUid = error.incidentRefUid = simpleUidGenerator();
  
  //and now log it! So incident reference id also associated with this error.
  if (console.logUncaught) {
    const _type = rejectionEvent instanceof PromiseRejectionEvent
      ? "PromiseRejectionEvent"
      : rejectionEvent.constructor.name;

    const uncaughtReport = { _type, incidentRefUid, message: error.message, stack: error.stack };
    
    //if Axios triggered an error, we add the relevant info to our logs...
    if (error.isAxiosError) {
      uncaughtReport["axios_request"] = error.config?.method?.toUpperCase() + ' ' + error.config?.url;
      uncaughtReport["axios_req_data"] = error.config?.data?.length || 0;
      const {startTime, endTime=0, loaded, total} = error.config?.metadata || {};
      uncaughtReport["axios_conn_time"] = (startTime>0) ? Number(endTime-startTime).toLocaleString()+" ms" : "???";
      uncaughtReport["axios_data_recv"] = `${Number(loaded).toLocaleString()} of ${Number(total).toLocaleString()} bytes`;
    }
    //This "logUncaught" function just adds to the log messages submitted with an error report.
    console.logUncaught(uncaughtReport);
  }

  AppErrorBoundary.notifyServerOfError({ isWSOD: false, incidentRefUid, error });

  toast.error("An internal error occurred!");
});




function simpleUidGenerator() {
  return Math.floor(Date.now()/1000).toString(16)                   //seconds since epoch, in hex
    + "-"
    + (Math.floor(Math.random()*65536)+0x10000).toString(16).substring(1,5);   //4 random hex chars
}