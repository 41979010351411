import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import {
  Avatar1b,
  Avatar2b,
  Avatar3b,
  Avatar4b,
  Avatar5b,
  Avatar6b,
  Avatar7b,
  Avatar8b,
  Avatar9b,
  Megaphone
} from "../../assets";
import BaseModal from "./baseModal/BaseModal";
import {
  IMAGE_DROPDOWN,
  STRING_INPUT,
  TEXT_IMAGE_LIST
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class AddInstructor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      avatar: "",
      instructors: [],
      personnel: this.props.type === "Party" ? "Organizer" : "Instructor"
    };

    this.avatar_images = [
      { name: "1b", image: Avatar1b },
      { name: "2b", image: Avatar2b },
      { name: "3b", image: Avatar3b },
      { name: "4b", image: Avatar4b },
      { name: "5b", image: Avatar5b },
      { name: "6b", image: Avatar6b },
      { name: "7b", image: Avatar7b },
      { name: "8b", image: Avatar8b },
      { name: "9b", image: Avatar9b },
      { name: "megaphone", image: Megaphone }
    ];
  }

  requestTour = async e => {
    e.preventDefault();
  };

  getAvatar(name) {
    return this.avatar_images.filter(img => img.name === name)[0].image;
  }

  async submitForm(e) {
    e.preventDefault();
    if (!this.props.type) return;

    const epEndingTable = {
      Program: "program_specific",
      Online: "online_specific",
      Event: "event_specific",
      Party: "party_specific"
    };

    const ep = `${process.env.REACT_APP_API}/partners/users/${
      epEndingTable[this.props.type]
    }`;

    const programNameTable = {
      Program: this.props?.program?.program_name,
      Online: this.props?.program?.program_name,
      Event: this.props?.program?.event_title,
      Party: this.props?.program?.party_title
    };

    const dataToSend = {
      user: {
        username: this.state.name,
        email: this.state.email,
        avatar: this.state.avatar
      },
      program: this.props.program.id,
      program_name: programNameTable[this.props.type]
    };

    const result = await axios.post(ep, dataToSend);
    if (result.data.success) {
      toast.success(`${this.state.personnel} added successfully`);
      this.props.onClose();
    } else {
      toast.error(`User already exists!`);
    }
  }

  filterAccessUsers(usersAndRole) {
    // filter down to those with access to programs/online/event
    const accessListingTable = {
      Program: ur => ur?.role?.accesspoints?.Manage?.Programs?.edit,
      Online: ur => ur?.role?.accesspoints?.Manage?.["Online Virtual"]?.edit,
      Event: ur => ur?.role?.accesspoints?.Manage?.Events?.edit,
      Party: ur => ur?.role?.accesspoints?.Manage?.Parties?.edit
    };
    // filter down the ones that have access only to specific listings
    // Note that having {0} in your list gives you full access
    const accessSpecificTable = {
      Program: ur =>
        ur.user.programlist?.includes(this.props.program.id) || ur.user.programlist?.includes(0),
      Online: ur =>
        ur.user.onlinelist?.includes(this.props.program.id) || ur.user.onlinelist?.includes(0),
      Event: ur =>
        ur.user.eventlist?.includes(this.props.program.id) || ur.user.eventlist?.includes(0),
      Party: ur =>
        ur.user.partylist?.includes(this.props.program.id) || ur.user.partylist?.includes(0),
    };

    return usersAndRole
      .filter(ur => !ur?.user?.isArchived)
      .filter(ur => accessListingTable[this.props.type](ur))
      .filter(ur => accessSpecificTable[this.props.type](ur));
  }

  // data of general team members
  async getCurrentAccess() {
    const ep = `${process.env.REACT_APP_API}/partners/roles`;
    const response = await axios.get(ep);
    const { roles, users } = response.data.data;

    const usersAndRole = users.map(user => {
      const usersRole = roles.filter(role => role.id === user.role)[0];
      return { user, role: usersRole };
    });

    const usersWithAccess = this.filterAccessUsers(usersAndRole);
    this.setState({ instructors: usersWithAccess });
  }

  getBaseModalProps() {
    const nameInput = {
      type: STRING_INPUT,
      data: {
        name: "Name",
        required: true,
        placeholder: `${this.state.personnel}'s name . . .`,
        handleChange: e => this.setState({ name: e.target.value }),
        value: this.state.name
      },
      validators: { validateSubmit: notEmptyString(this.state.name) }
    };

    const emailInput = {
      type: STRING_INPUT,
      data: {
        name: "Email",
        required: true,
        placeholder: `${this.state.personnel}'s email . . .`,
        handleChange: e => this.setState({ email: e.target.value }),
        value: this.state.email,
        type: "email"
      },
      validators: { validateSubmit: validateEmail(this.state.email) }
    };

    const avatarDropdown = {
      type: IMAGE_DROPDOWN,
      data: {
        name: "Avatar",
        required: true,
        placeholder: "Select avatar",
        handleChange: value => this.setState({ avatar: value }),
        choices: this.avatar_images
          .filter(image => image.name !== "megaphone")
          .map((e, i) => ({
            text: `Person ${i + 1}`,
            value: e.name,
            key: e.name,
            image: {
              avatar: true,
              src: `${e.image}`
            }
          })),
        value: this.state.avatar
      },
      validators: { validateSubmit: notEmptyString(this.state.avatar) }
    };

    const currentAccessField = {
      type: TEXT_IMAGE_LIST,
      data: {
        name: "Current Access",
        content: [
          { src: this.getAvatar("megaphone"), content: "Admin (Owner)" },
          ...this.state.instructors.map(({ user: { avatar, username } }) => ({
            src: this.getAvatar(avatar),
            content: username
          }))
        ]
      }
    };

    const addButton = {
      name: `Add ${this.state.personnel}`,
      handleClick: e => this.submitForm(e),
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit"
    };

    const baseModalProps = {
      title: `Add ${this.state.personnel}`,
      fields: [nameInput, emailInput, avatarDropdown, currentAccessField],
      buttons: [addButton],
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };

    return baseModalProps;
  }

  getInitialData() {
    this.getCurrentAccess();
  }

  componentDidMount() {
    this.getInitialData();
  }

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(AddInstructor);
