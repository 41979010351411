import React from "reactn";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../assets/css/componentSpecificCss/ListingCard.css";

class ListingCard extends React.Component {
  constructor (props) {
    super(props);
  }

  dayMap (day) {
    switch (day) {
      case "Mon":
        return 0;
      case "Tue":
        return 1;
      case "Wed":
        return 3;
      case "Thu":
        return 4;
      case "Fri":
        return 5;
      case "Sat":
        return 6;
      case "Sun":
        return 7;
    }
  }

  render () {
    console.log(this.props);
    const days_of_week = Object.keys(this.props.days_of_week).map(day => day.substring(0, 3)).sort((a, b) => this.dayMap(a) - this.dayMap(b));
    return (
      <>
        <div 
          className="listing-card-wrapper"
          onClick={_ =>
            this.props.history.push(
              `/preview/${this.props.type}/${this.props.id}`
            )
          }
        >
          <LazyLoadImage
            src={`${process.env.REACT_APP_DS_FILES_S3}/${this.props.image}`}
            alt="Program"
            wrapperClassName="card-img"
            style={{ width: "100%" }}
            effect="opacity"
          />
          <div className="listing-content-wrapper">
            <div className="column-left">
              <div className="listing-info">
                <div>
                  <div 
                    className="listing-name"
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: "bold",
                      color: "#252F8A",
                      fontSize: "22px"
                    }}
                  >{this.props.listingName}</div>
                  <span 
                    className="listing-days-of-week"
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: "17px"
                    }}
                  >
                    {days_of_week.length > 0 ? days_of_week.join(", ") + ", " : ""}{this.props.time} {this.props.date !== "" ? "| " + this.props.date : ""}
                  </span>
                  <div
                    className="listing-description"
                    style={{
                      fontFamily: "Open Sans",
                      paddingTop: "10px",
                      fontSize: "15px",
                      whiteSpace: "wrap"
                    }}
                  >
                    {this.props.description.length > 117
                      ? `${this.props.description.substring(0, 117)}...`
                      : this.props.description
                    }
                  </div>
                </div>
                <div 
                  className="listing-age-text"
                  style={{
                    fontFamily: "Open Sans",
                    fontSize: "15px",
                    fontWeight: "bold"
                  }}
                >
                  {this.props.ageText}
                </div>
              </div>
            </div>

            {(this.props.type === "event" || this.props.type === "party") &&
              <div className="column-center">
                {this.props.location &&
                  <div>
                    <span 
                      className="location-header"
                      style={{
                        fontWeight: "900",
                        fontSize: "18px",
                        textAlign: "center",
                        color: "#252F8A"
                      }}>
                      Location:
                    </span>
                    <div className="location-text" style={{fontSize: "15px", color: "black"}}>
                      {this.props.location.split(",").slice(0, -2).join(", ")}
                    </div>
                  </div>
                }

                {this.props.headcount && 
                  <div 
                    className="location-header"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      marginTop: "auto"
                    }}>
                    Headcount: {this.props.headcount}
                  </div>
                }
              </div>
            }

            <div className="column-right">
              <div 
                className="listing-price-text"
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "20px",
                  color: "#0B0894",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >{this.props.priceText}</div>
              <div className="listing-plans" style={{ display: "flex", flexDirection: "row"}}>
                {this.props.planTypes.map(type => (
                  <div 
                    className="listing-plan-types"
                    style={{
                      borderRadius: "5px",
                      borderStyle: "solid",
                      borderColor: "#ADD8E6",
                      width: "fit-content",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      whiteSpace: "nowrap",
                      fontFamily: "Open Sans",
                      color: "#7A7878",
                      fontWeight: "bold",
                      marginLeft: "15px",
                      textAlign: "center"
                    }}>
                    {type}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default ListingCard;