import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, RED_BUTTON, WHITE } from "./baseModal/colours";

class StaffScheduleCheckInConfirmationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async staffCheckIn() {
    const ep = `${process.env.REACT_APP_API}/partners/users/schedule/update/${this.props.selectedSchedule.id}`;

    this.setGlobal({ loading: true });

    const results = await axios.post(ep, {
      checked_in: true,
      check_in_date: new Date()
    });

    const ep2 = `${process.env.REACT_APP_API}/partners/users/work-log/${this.props.selectedStaff.id}`;
    const results2 = await axios.post(ep2, {
      task_name: this.props.selectedSchedule.task_name,
      start_time: new Date(),
      scheduleId: this.props.selectedSchedule.id
    });

    if (results2.data.success) {
      toast.success("Successfully Checked-In!");
      this.props.onRefresh();
      this.props.onClose();
      this.setGlobal({ loading: false });
    } else {
      toast.error("Error while Checking in.");
    }
  }

  getBaseModalFields() {
    const fields = [];

    const checkInConfirmationField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Are you sure you would like to check-in?"
      }
    };

    fields.push(checkInConfirmationField);

    return fields;
  }

  getBaseModalButtons = () => {
    console.log("EBOOOO", this.props);
    const checkInButton = {
      name: "Confirm",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatoryKey: "validateSubmit",
      handleClick: () => this.staffCheckIn()
    };

    return [checkInButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Schedule Check-In",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(StaffScheduleCheckInConfirmationModal);
