import React from "reactn";
import axios from "axios";
// import { format } from "date-fns";
import Program from "./program";
import WeeklySlots from "./weeklySlots/weeklySlots";
import Connections from "./connections";
import TourAvailability from "./tourAvailability";
import Attendance from "./attendance/attendance";
import EducatorApplicationsTable from "./applications";
import Checklist from "../../educators/checklist";
import Calendar from "../../../components/calendar/Calendar";
import TourRequests from "./tourRequests";
import Messaging from "./messaging/messaging";
import Enrollments from "./enrollments";
import MyEarnings from "./EducatorsEarning";
import Checkout from "./Checkout";
// import { Table } from "../../../components/UI";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";

class ViewProgram extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      program: null,
      page: "program"
    };
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/educator/program/${this.props.match.params.id}`;
    try {
      this.setGlobal({ loading: true, program: null });
      const res = await axios.get(ep);
      // this.setState({
      //   program: res.data.data
      // });
      this.setGlobal({
        loading: false,
        program: res.data.data,
        lastAPICall: res
      });
      this.forceUpdate();
    } catch (ex) {
      console.error(ex);
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    return (
      <div className="admin">
        <div className="container-fluid programsettings">
          <div className="cont justify-evenly">
            <h1>
              Program Operations{" "}
              <span>
                {this.global.program
                  ? this.global.program.myProgramInfo.programName
                  : null}
              </span>
            </h1>
            <select
              name="page"
              onChange={e => this.setState({ [e.target.name]: e.target.value })}
              style={{
                alignSelf: "center",
                height: 40,
                borderRadius: 5,
                background: "none",
                padding: "0 8px"
              }}
            >
              <option value="program">Program Overview</option>
              <option value="checklist">Checklist</option>
              <option value="connections">Connections</option>
              <option value="touravailability">Tour Availability</option>
              <option value="attendance">Attendance</option>
              <option value="applications">Applications</option>
              <option value="slots">Weekly Slots</option>
              <option value="calendar">Calendar</option>
              <option value="tourRequests">Tour Requests</option>
              <option value="chat">Chat Logs</option>
              <option value="enrollment">Enrollments</option>
              <option value="Earnings">Earnings</option>
              <option value="Checkout">Checkout Page</option>
            </select>
          </div>

          {!this.global.program ? null : (
            <>
              {this.state.page === "program" ? (
                <Program program={this.global.program} />
              ) : null}
              {this.state.page === "checklist" ? (
                <Checklist program={this.global.program} />
              ) : null}
              {this.state.page === "connections" ? (
                <Connections program={this.global.program} />
              ) : null}
              {this.state.page === "touravailability" ? (
                <TourAvailability program={this.global.program} />
              ) : null}
              {this.state.page === "attendance" ? (
                <Attendance program={this.global.program} />
              ) : null}
              {this.state.page === "applications" ? (
                <EducatorApplicationsTable program={this.global.program} />
              ) : null}
              {this.state.page === "slots" ? (
                <WeeklySlots program={this.global.program} />
              ) : null}
              {this.state.page === "calendar" ? (
                <div
                  className="calendarcf"
                  style={{ position: "absolute", width: "100%" }}
                >
                  <Calendar program={this.global.program} />
                </div>
              ) : null}
              {this.state.page === "tourRequests" && (
                <TourRequests program={this.global.program} />
              )}
              {this.state.page === "chat" && <Messaging />}
              {this.state.page === "enrollment" && (
                <Enrollments id={this.props.match.params.id} />
              )}
              {this.state.page === "Earnings" && (
                <MyEarnings id={this.props.match.params.id} />
              )}
              {this.state.page === "Checkout" && (
                <Checkout id={this.props.match.params.id} />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ViewProgram;
