import React from "reactn";
import "./RequestInfo.css";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import ProgramSearch from "../common/ProgramSearchInput";
//import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import { GET_HTML, STRING_INPUT } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
class AddAddress extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      address1: "",
      address2: "",
      city: "",
      province: "",
      country: "",
      postalcode: ""
    };
    this.required = [{ name: "address", validation: e => !e }];
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async submitForm(e) {
    e.preventDefault();
    if (!this.state.address) {
      toast.error("Please select an address from the dropdown!");
      return;
    }
    this.props.addLoc({
      address: this.state.address,
      address2: this.state.address2,
      lat: this.state.lat,
      lng: this.state.lng
    });
    this.props.onClose();
    toast.success(`Successfully added address!`);
  }

  addnewmodal = () => {
    return (
      <div>
        <div>
          <label
            htmlFor="displayName"
            style={{
              width: "25%",
              textAlign: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            Address 1
          </label>
        </div>
        <div
          style={{
            width: "100%",
            height: "40px",
            fontSize: "0.93rem",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "400",
            color: "#797979",
            borderRadius: "4px"
          }}
        >
          <ProgramSearch
            placeholder="Find a Program"
            noicon={true}
            blueborder={true}
            name="search"
            style={{
              marginTop: "0px",
              width: "100%",
              height: "40px",
              fontSize: "0.93rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "400",
              color: "#797979",
              borderRadius: "4px"
            }}
            gLoc={(e, loc) => {
              const address = e.split(",");
              this.setState({
                address: e,
                city: address[1].trim(),
                province: address[2].trim().split(" ")[0],
                country: address[3].trim(),
                postalcode:
                  address[2].trim().split(" ")[1] +
                  address[2].trim().split(" ")[2],
                lat: loc.lat,
                lng: loc.lng
              });
            }}
          />
        </div>
      </div>
    );
  };

  getBaseModalFields() {
    const searchInput = {
      type: GET_HTML,
      data: {
        gethtml: this.addnewmodal
      }
    };

    const address2Input = {
      type: STRING_INPUT,
      data: {
        name: "Address 2",
        required: false,
        placeholder: "Address 2...",
        isDisabled: !this.state.address,
        handleChange: event => {
          this.setState({
            address2: event.target.value
          });
        },
        value: this.state.address2
      }
    };

    const cityInput = {
      type: STRING_INPUT,
      data: {
        name: "City",
        required: false,
        placeholder: "City...",
        isDisabled: !this.state.address,
        handleChange: event => {
          this.setState({
            city: event.target.value
          });
        },
        value: this.state.city
      }
    };

    const provinceInput = {
      type: STRING_INPUT,
      data: {
        name: "Province",
        required: false,
        placeholder: "Province...",
        isDisabled: !this.state.address,
        handleChange: event => {
          this.setState({
            province: event.target.value
          });
        },
        value: this.state.province
      }
    };

    const countryInput = {
      type: STRING_INPUT,
      data: {
        name: "Country",
        required: false,
        placeholder: "Country...",
        isDisabled: !this.state.address,
        handleChange: event => {
          this.setState({
            country: event.target.value
          });
        },
        value: this.state.country
      }
    };

    const PostalCodeInput = {
      type: STRING_INPUT,
      data: {
        name: "Postal Code",
        required: false,
        placeholder: "Postal Code...",
        isDisabled: !this.state.address,
        handleChange: event => {
          this.setState({
            postalcode: event.target.value
          });
        },
        value: this.state.postalcode
      }
    };

    const fields = [];
    fields.push(searchInput);
    fields.push(address2Input);
    fields.push(cityInput);
    fields.push(provinceInput);
    fields.push(countryInput);
    fields.push(PostalCodeInput);
    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Add New Address",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.submitForm(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Add Address",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    /*     const fields = [
      { name: "address2", label: "Address 2" },
      { name: "city", label: "City" },
      { name: "province", label: "Province" },
      { name: "country", label: "Country" },
      { name: "postalcode", label: "Postal Code" }
    ];

    
    return (
       <ModalTemplate heading="Add Address" onClose={this.props.onClose}>
        <form onSubmit={e => this.submitForm(e)}>
          <div className="modal-main">
            <ModalFields.custom label="Address 1" name="address_1" required>
              <div style={{ width: "70%" }}>
                <ProgramSearch
                  placeholder="Find a Program"
                  noicon={true}
                  blueborder={true}
                  name="search"
                  style={{ marginTop: "0px" }}
                  gLoc={(e, loc) => {
                    const address = e.split(",");
                    this.setState({
                      address: e,
                      city: address[1].trim(),
                      province: address[2].trim().split(" ")[0],
                      country: address[3].trim(),
                      postalcode:
                        address[2].trim().split(" ")[1] +
                        address[2].trim().split(" ")[2],
                      lat: loc.lat,
                      lng: loc.lng
                    });
                  }}
                />
              </div>
            </ModalFields.custom>
            {fields.map(e => (
              <ModalFields.input
                label={e.label}
                name={e.name}
                placeholder={`${e.label}...`}
                type="text"
                value={this.state[e.name]}
                onChange={event => {
                  this.setState({
                    [e.target.name]: event.target.value
                  });
                }}
                disabled={!this.state.address}
                key="e.name"
              />
            ))}
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn profile-btn">
              Add New Address
            </button>
          </div>
        </form>
      </ModalTemplate>
    ); */
  }
}

export default withRouter(AddAddress);
