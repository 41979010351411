import React from "react";
import PropTypes from "prop-types";
import "../../assets/css/componentSpecificCss/stepBar.css";

const StepBar = props => {
  const { current, steps, changeFn } = props;

  const canChange = page => {
    // for (let step = 0; step < page; step++) {
    //     if (!steps[step].validateFn()) return false;
    // }
    changeFn(page);
  };

  return (
    <div className="completeline ml-0 pl-0 pb-0">
      <div className="cont justify-evenly">
        {steps.map((step, i) => (
          <span
            className={
              (i === current ? "stepActive " : "step-color ") + "stepStatus"
            }
            key={i}
            onClick={() => canChange(i)}
          >
            <i
              className={
                (step.validateFn() ? "stepComplete " : "") + "fas fa-check"
              }
            ></i>
            <span className="step-name">{step.name}</span>
          </span>
        ))}
      </div>
    </div>
  );
};
export default StepBar;

StepBar.propTypes = {
  steps: PropTypes.array.isRequired,
  current: PropTypes.number,
  changeFn: PropTypes.func
};
