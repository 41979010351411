import React from "react";
// import { Select } from "../../../components/UI";
import ConnectionLineChart from "../../../components/charts/ConnectionLine";
// import { ArrowDownSelect } from "../../../assets";
import { NotImg4 } from "../../../assets";
// import { format } from "date-fns";
import { withRouter } from "react-router-dom";

class ConnectionsOverview extends React.PureComponent {
  render() {
    const { connections, total } = this.props;

    return (
      <div className="col-xl-6 col-xl-6-new">
        <div className="tablebox" style={{ height: "450px" }}>
          <div
            className="tableheader"
            onClick={() => this.props.history.push("/partners/connections")}
            style={{ cursor: "pointer" }}
          >
            <h2>
              CONNECTIONS OVERVIEW
              <img src={NotImg4} alt="My Connections" className="imgfix" />
            </h2>
          </div>
          <div className="tablebody padded">
            <h2 className="graph-header">
              <span>{total ? parseFloat(total || 0).toFixed(0) : 0}</span>{" "}
              Cumulative
            </h2>
            <div className="chart-area" style={{ height: "18rem" }}>
              {connections ? (
                <ConnectionLineChart
                  mapData={connections.map(e => e.amount)}
                  labels={connections.map(e => e.date)}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ConnectionsOverview);
