import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { BUTTON, CONTENT_BLOCK, IMAGE_CONTENT } from "./baseModal/FieldTypes";
import BaseModal from "./baseModal/BaseModal";
import { BLUE_BUTTON } from "./baseModal/colours";
import moment from "moment";
class DetailsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      val: -1,
      question: "",
      leadsData: null,
      data: [],
      form: null
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {
    // const arr = []
    const obj = {};
    if (this.props.lead) {
      Object.keys(this.props.lead).map(lead => {
        const isDOB = lead === "Date of Birth" ? true : false;
        // const modifiedLeadkey = lead.substr((this.props.noF || isDOB) ? 0 : lead.match(/\d+/)[0].length);
        const modifiedLeadkey = lead;
        const leadValue = this.props.lead[lead];
        obj[modifiedLeadkey] = leadValue;

        // arr.push({ [modifiedLeadkey]: leadValue });
      });
    }
    // arr.sort();

    const ep = `${process.env.REACT_APP_API}/partners/forms/${this.props.formId}`;
    this.setGlobal({ loading: true });
    const res = await axios.get(ep);

    console.log("res", res);

    this.setState({
      leadsData: obj,
      data: res.data.data.sections,
      form: res.data.data.form
    });
    this.setGlobal({ loading: false, lastAPICall: res });
  }

  getSortedWeekdays = selectedDays => {
    const WEEKDAY_ORDER = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ];
    return WEEKDAY_ORDER.filter(wd => selectedDays[wd]).join(", ");
  };

  getLeadDetails() {
    if (this.state.data.length === 0) return [];

    const sortedData = this.state.data.sort((a, b) =>
      a.id > b.id ? 1 : a.id < b.id ? -1 : 0
    );

    const parsedData = sortedData.reduce((prev, val) => {
      const currentData = val.fields.reduce((prev, field) => {
        const fieldObject =
          typeof field === "string" ? JSON.parse(field) : field;
        const key = val.id + fieldObject.first_label;

        if (!this.state.leadsData[key]) {
          return prev;
        }

        const newFields = {
          name: fieldObject.first_label,
          content: this.state.leadsData[key]
        };

        return [...prev, newFields];
      }, []);
      return [...prev, ...currentData];
    }, []);
    return parsedData;
  }

  getFormattedLeadDetails() {
    const leadDetails = this.getLeadDetails();
    const formattedLeadDetails =
      leadDetails.length < 1
        ? []
        : leadDetails.map(({ name, content }) => {
            console.log("name", name);
            console.log("content", content);
            if (
              content &&
              typeof content === "string" &&
              content.startsWith("http")
            ) {
              // For links
              return {
                type: BUTTON,
                data: {
                  name: name,
                  buttonText: "View File",
                  buttonColour: BLUE_BUTTON,
                  textColour: "white",
                  handleClick: () => {
                    console.log("Navigating to", content);
                    window.open(content, "_blank");
                  }
                }
              };
            }

            if (
              typeof content === "string" &&
              content?.includes("data:image/png")
            ) {
              return {
                type: IMAGE_CONTENT,
                data: { name: name, src: content }
              };
            } else {
              if (content === true) {
                return {
                  type: CONTENT_BLOCK,
                  data: { name: name, content: "Yes" }
                };
              }
              if (content === false) {
                return {
                  type: CONTENT_BLOCK,
                  data: { name: name, content: "No" }
                };
              }
              if (typeof content === "boolean") {
                return content ? "Yes" : "No";
              }
              if (content && Array.isArray(content)) {
                return {
                  type: CONTENT_BLOCK,
                  data: {
                    name: name,
                    content:
                      content.join("; ") + (content.length > 0 ? "." : "")
                  }
                };
              }

              if (
                content &&
                typeof content === "string" &&
                content.includes("data:image/png")
              ) {
                return `<img src="${content}"" width="150px" />`;
              }

              if (
                content &&
                typeof content === "string" &&
                content.includes("-")
              ) {
                return {
                  type: CONTENT_BLOCK,
                  data: {
                    name: name,
                    content: moment(
                      new Date(content.replace(/-/g, "/").replace(/T.+/, ""))
                    ).format("MMMM Do, YYYY")
                  }
                };
              }

              if (
                content &&
                content !== null &&
                content !== undefined &&
                typeof content === "object"
              ) {
                return {
                  type: CONTENT_BLOCK,
                  data: {
                    name: name,
                    content: Object.keys(content).join(" ")
                  }
                };
              }
              return {
                type: CONTENT_BLOCK,
                data: { name: name, content: content }
              };
            }
          });

    return formattedLeadDetails;
  }

  getBaseModalProps() {
    return {
      title: "Lead Details",
      fields: this.getFormattedLeadDetails(),
      buttons: [],
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: () => this.props.onClose()
    };
  }

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }

  // render() {
  //   return (
  //     <div className="request-modal-cont">
  //       <div className="request-modal lesspadding">
  //         <div>
  //           <h3>Lead Details</h3>
  //           {this.getFormattedLeadDetails()}
  //           {this.state.data.length > 0 &&
  //             this.state.data
  //               .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
  //               .map(
  //                 (val, i) =>
  //                   val.fields.map((v, index) => {
  //                     const values = typeof v === "string" ? JSON.parse(v) : v;

  //                     if (
  //                       !this.state.leadsData[`${val.id}${values.first_label}`]
  //                     ) {
  //                       return;
  //                     }

  //                     return (
  //                       <div style={{ display: "flex" }}>
  //                         <label
  //                           style={{
  //                             marginBottom: 0,
  //                             width: "35%",
  //                             fontSize: "14px"
  //                           }}
  //                         >
  //                           {values.first_label}
  //                           {": "}
  //                         </label>
  //                         {/* {this.state.leadsData[values.first_label]} */}
  //                         {this.state.leadsData[
  //                           `${val.id}${values.first_label}`
  //                         ] &&
  //                         this.state.leadsData[
  //                           `${val.id}${values.first_label}`
  //                         ].includes("data:image/png") ? (
  //                           <img
  //                             src={
  //                               this.state.leadsData[
  //                                 `${val.id}${values.first_label}`
  //                               ]
  //                             }
  //                             width="150px"
  //                             alt=""
  //                           />
  //                         ) : (
  //                           <span style={{ fontSize: "15px", width: "80%" }}>
  //                             {
  //                               this.state.leadsData[
  //                                 `${val.id}${values.first_label}`
  //                               ]
  //                             }
  //                           </span>
  //                         )}
  //                       </div>
  //                     );
  //                   })
  //                 // }
  //               )}

  //           {/* {Object.keys(this.props.lead).map(lead => (
  //             <p style={{ fontSize: "14px" }}>
  //               {lead.substr(this.props.noF ? 0 : lead.match(/\d+/)[0].length)}{" "}
  //               :{" "}
  //               {this.props.lead[lead].includes("http") ? (
  //                 <a href={this.props.lead[lead]}>View File</a>
  //               ) : this.props.lead[lead].includes("data:image/png") ? (
  //                 <img src={this.props.lead[lead]} width="150px" />
  //               ) : (
  //                 this.props.lead[lead]
  //               )}
  //             </p>
  //           ))} */}

  //           <IconButton
  //             style={{ position: "absolute", right: "0", top: "0" }}
  //             onClick={_ => this.props.onClose()}
  //           >
  //             <Close />
  //           </IconButton>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
}

export default withRouter(DetailsModal);
