import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
// import { MiniPlus } from "../../assets";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import axios from "axios";
import { toast } from "react-toastify";
import Details from "../../components/modals/DetailsModal";
import { isFuture, format, add, addWeeks, addDays, subDays } from "date-fns";
import { RepeatOneSharp } from "@material-ui/icons";

const daysOfWeekConversion = day => {
  const table = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  };
  return table[day];
};

const getFirstDay = (startingDate, daysOfWeek) => {
  // Code to compensate for the local timezone of the starting date
  startingDate.setTime(
    startingDate.getTime() + startingDate.getTimezoneOffset() * 60 * 1000
  );

  if (!daysOfWeek) {
    return undefined;
  }
  if (Object.keys(daysOfWeek).length === 0) return;

  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  while (true) {
    if (convertedDaysOfWeek.includes(startingDate.getDay())) {
      return startingDate;
    }
    startingDate = addDays(startingDate, 1);
  }
};

const getLastDay = (lastDay, daysOfWeek) => {
  console.log("lastOfWeek...", daysOfWeek);
  if (!daysOfWeek) {
    return undefined;
  }
  if (Object.keys(daysOfWeek).length === 0) return;
  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  lastDay = addWeeks(lastDay, 1);
  lastDay = subDays(lastDay, 1);
  while (true) {
    if (convertedDaysOfWeek.includes(lastDay.getDay())) {
      return lastDay;
    }
    lastDay = subDays(lastDay, 1);
  }
};

class Connections extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      // headings: {},
      tabs: [],
      forms: [],
      listingTypes: [],
      page: -1
    };
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Active", to: "/active-leads" }
      ]
    });
    this.classificationTable = {
      All: () => true,
      Programs: e => e.classificationType === "Program",
      Events: e => e.classificationType === "Event",
      "Online Virtual": e => e.classificationType === "Online",
      Membership: e => e.classificationType === "Membership"
    };
  }

  getEnrollmentStatus(r, i) {
    if (r.is_paused) {
      console.log("paused", r);
      return (
        <td key={i}>
          <p
            style={{
              backgroundColor: "rgb(235, 169, 62)",
              padding: "6px 8px",
              borderRadius: "13px",
              color: "#fff",
              textAlign: "center",
              margin: "auto"
            }}
          >
            Paused
          </p>
        </td>
      );
    }

    const userStart = r.startDate
      ? new Date(r.startDate)
      : new Date(r.program_start);

    const status = isFuture(userStart) ? "Upcoming" : "Enrolled";
    return (
      <td key={i}>
        <p
          style={{
            backgroundColor: `${
              status === "Upcoming" ? "rgb(235, 169, 62)" : "#1bc88a"
            }`,
            padding: "6px 8px",
            borderRadius: "13px",
            color: "#fff",
            textAlign: "center",
            margin: "auto"
          }}
        >
          {status}
        </p>
      </td>
    );
  }

  getPlan(r, i) {
    if (!r.plans) {
      return <td key={i}>N/A</td>;
    }
    if (r.plan_id === null) {
      return <td key={i}>Auto Register</td>;
    }
    const plan = JSON.parse(
      r.plans.filter(e => JSON.parse(e).id == r.plan_id)[0]
    );
    const objConv = {
      week: "Weekly",
      "bi-week": "Bi-Weekly",
      month: "Monthly"
    };

    return (
      <td key={i}>
        {plan.type === "Semester"
          ? r.drop_in_dates
            ? `Drop-in`
            : `Semester - $${plan.total_price}`
          : plan.type === "Free"
          ? "Free"
          : plan.type === "Free Trial"
          ? plan.description && plan.description !== ""
            ? `Free Trial - ${plan.description} (${plan.trialPeriod} classes)`
            : `Free Trial (${plan.trialPeriod} classes)`
          : plan.type === "Drop-In"
            ? plan.description && plan.description !== ""
              ? `Drop-In - ${plan.description} x ${this.props.program.drop_in_dates?.length}`
              : `Drop-In x ${this.props.program.drop_in_dates?.length}`
          : plan.type === "Single Sessions"
          ? `Single Sessions - $${plan.total_price}`
          : `Recurring - $${plan.tuition_rate} / ${
              objConv[plan.billing_cycle]
            }, \n ${
              plan.isReplaceTimeEnabled
                ? `${plan.description}`
                : `${plan.timing ? plan.timing[0] : ""} to ${
                    plan.timing ? plan.timing[1] : ""
                  }`
            }`}
      </td>
    );
  }

  getDurationDate(r, i) {
    console.log("r....", r);
    let startDate = null;
    let formattedStartDate = null;
    if (r.startDate || r.program_start) {
      startDate = r.startDate
        ? new Date(r.startDate)
        : new Date(r.program_start);
      formattedStartDate = format(
        getFirstDay(new Date(r.program_start), r.days_of_week) || startDate,
        "LLL do, yyyy"
      );
      //formattedStartDate = format(startDate, "LLL do, yyyy");
    }

    let endDate = null;
    let formattedEndDate = null;

    if (r.endDate) {
      endDate = new Date(r.endDate);
      formattedEndDate = format(
        getLastDay(
          addWeeks(
            getFirstDay(new Date(r.program_start), r.days_of_week) || endDate,
            r.number_of_weeks - 1
          ),
          r.days_of_week
        ) || endDate,
        "LLL do, yyyy"
      );

      //formattedEndDate = format(endDate, "LLL do, yyyy");
    } else if (r.number_of_weeks) {
      endDate = add(startDate, {
        weeks: r.number_of_weeks
      });
      formattedEndDate = format(
        getLastDay(
          addWeeks(
            getFirstDay(new Date(r.program_start), r.days_of_week) || endDate,
            r.number_of_weeks - 1
          ),
          r.days_of_week
        ) || endDate,
        "LLL do, yyyy"
      );
      //formattedEndDate = format(endDate, "LLL do, yyyy");
    }

    return (
      <td key={i}>
        {formattedStartDate ? formattedStartDate : "N/A"}{" "}
        {formattedEndDate ? ` to ${formattedEndDate}` : ""}
      </td>
    );
  }

  formatName(data) {
    return `${data.displayName}${data.childName ? ` (${data.childName})` : ""}`;
  }

  getHeadings() {
    // Name (also Child's Name, if child's name exists)
    // Status Upcoming or Enrolled
    // Type: Program, Online, Event, Membership
    // Type Name: e.g., Toddler (2-3 years)
    // Plan: e.g., Full-Time (4-days)
    // Duration: e.g., start-date, or start & end date
    const headings = {};
    const activeArr = this.organizeData(
      this.state.filteredData,
      this.classificationTable
    );

    const numUsers =
      activeArr[this.state.tabs ? this.state.tabs[0] : "All"]?.length;

    const general_headings = [
      {
        id: "displayName",
        label: `Username (${numUsers})`,
        customCell: (r, i) => <td key={i}>{this.formatName(r)}</td>
      },
      { id: "status", label: "Status", customCell: this.getEnrollmentStatus },
      { id: "classificationType", label: "Type" },
      // could be the age groupings
      { id: "title", label: "Type Name" },
      { id: "Plans", label: "Plan", customCell: this.getPlan },
      { id: "createdAt", label: "Duration", customCell: this.getDurationDate }
    ];
    const types = ["All", "Programs", "Events", "Online Virtual", "Membership"];
    types.forEach(type => {
      headings[type] = general_headings;
    });

    return headings;
  }

  // returns an object where data is filtered into categories according to the table
  organizeData(data, table) {
    const result = {};
    for (const key in table) {
      result[key] = data.filter(e => table[key](e));
    }

    return result;
  }

  async componentDidMount() {
    this.setGlobal({ loading: true });

    const all_customers_ep = `${process.env.REACT_APP_API}/partners/customers/all/${this.global.organizationInfo.partnerId}`;
    const { data } = await axios.get(all_customers_ep);

    // Events and Online virtual dates
    const event_ep = `${process.env.REACT_APP_API}/partners/events/list`;
    const online_ep = `${process.env.REACT_APP_API}/partners/virtual/list`;
    const [eventResults, onlineResults] = await Promise.all([
      axios.get(event_ep),
      axios.get(online_ep)
    ]);

    let eventData = eventResults?.data?.data;
    let eventDates = {};

    if (eventData) {
      eventData.forEach(e => {
        eventDates[e.id] = { start: e.event_start, end: e.event_end };
      });
    }

    let onlineData = onlineResults.data.data;
    let onlineDates = {};

    if (onlineResults?.data?.data) {
      onlineData.forEach(e => {
        onlineDates[e.id] = { start: e.program_start };
      });
    }

    if (data.success) {
      const allActiveCustomers = data.data
      console.log(allActiveCustomers);
      allActiveCustomers.forEach(c => {
        if (c.classificationType === "Event") {
          c.startDate = eventDates[c.eventId]?.start;
          c.endDate = eventDates[c.eventId]?.end;
        }
        if (c.classificationType === "Online") {
          c.startDate = onlineDates[c.programId]?.start;
        }
      });

      this.setState({ data: allActiveCustomers });
      this.setState({ filteredData: allActiveCustomers });
    }

    this.setState({ tabs: ["All"] });
    this.setState({
      listingTypes: ["Programs", "Events", "Online Virtual", "Membership"]
    });

    this.setGlobal({ loading: false });
  }

  cleanString(s) {
    return s.trim().toLocaleLowerCase();
  }

  // filter by the this.state.filter
  inputFilter(data) {
    return this.cleanString(this.formatName(data)).includes(
      this.cleanString(this.state.filter)
    );
  }

  setFilteredData() {
    if (this.state.filter === "") {
      this.setState({ filteredData: this.state.data });
      return;
    }

    const filteredData = this.state.data.filter(e => this.inputFilter(e));

    this.setState({ filteredData });
  }

  render() {
    return (
      <div className="admin">
        {this.state.details && (
          <Details
            lead={this.state.selected}
            formId={this.state.formId}
            onClose={_ => this.setState({ details: false })}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>Active</h1>
            </div>
          </div>
          <div className="cont">
            <div className="tablecont">
              <div
                style={{
                  display: "inline-flex",
                  flex: "1 1",
                  border: "none",
                  borderRadius: "10px",
                  overflow: "hidden",
                  width: "100%",
                  marginBottom: 15
                }}
              >
                <i
                  className="fas fa-search"
                  style={{ padding: "8px", background: "white" }}
                />
                <input
                  type="text"
                  placeholder="Filter by username"
                  style={{
                    border: "none",
                    fontSize: "13px",
                    padding: "5px",
                    outline: 0,
                    background: "white",
                    flex: 1
                  }}
                  onChange={e => {
                    this.setState({ 
                      filter: e.target.value,
                      page: 0
                    }, () =>
                      this.setFilteredData()
                    );
                  }}
                />
              </div>
              <div className="table-responsive">
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <select
                    name="selectForm"
                    id="selectForm"
                    type="text"
                    className="form-control"
                    style={{ width: "22%" }}
                    onChange={e => {
                      this.setState({ 
                        tabs : [e.target.value],
                        page: 0 
                      });
                    }}
                  >
                    <option value="All">All</option>
                    {this.state.listingTypes.map((listingType, i) => (
                      <option key={i} value={listingType}>
                        {listingType}
                      </option>
                    ))}
                  </select>
                </div>
                <NewTabbedTable
                  tabs={this.state.tabs}
                  showTab={false}
                  headings={this.getHeadings()}
                  data={this.organizeData(
                    this.state.filteredData,
                    this.classificationTable
                  )}
                  actions={this.actions}
                  page={"Leads>Active"}
                  currentPage={this.state.page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Connections;
