import React, { Component } from "react";
import { ExpandMore } from "@material-ui/icons";
import Axios from "axios";
import SubTask from "./SubTask";
import ContactDetails from "../table/admins/ContactDetails";
import Comments from "../table/admins/CommentsModal";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Button,
  Typography
} from "@material-ui/core";
class Task extends Component {
  state = {
    subTask: [],
    lastAction: 0
  };
  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/followups/subtask/${this.props.task.id}`;
    const result = await Axios.get(ep);
    let days = Infinity;
    for (let task of result.data.data) {
      if (
        parseInt((Date.now() - new Date(task.updatedAt)) / (3600000 * 24)) <=
        days
      )
        days = parseInt(
          (Date.now() - new Date(task.updatedAt)) / (3600000 * 24)
        );
    }
    this.setState({
      subTask: result.data.data,
      lastAction: days
    });
  }
  checkComplete() {
    for (let subtask of this.state.subTask)
      if (!subtask.completed) return false;
    return true;
  }
  render() {
    const comments = {
      id: this.props.task.dsUserId,
      comments: this.props.task.comments,
      surveyid: this.props.task.surveyid
    };
    return (
      <>
        <ContactDetails
          open={this.state.contactShown}
          data={this.props.task.details}
          onClose={() =>
            this.setState({
              contactShown: false
            })
          }
        />
        <Comments
          open={this.state.commentShown}
          data={comments}
          real={this.props.task}
          onClose={() =>
            this.setState({
              commentShown: false
            })
          }
        />
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            style={{ alignItems: "center", flexWrap: "wrap" }}
          >
            <div
              style={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center"
              }}
            >
              {this.props.task.taskName}
            </div>
            <Typography style={{ marginBottom: 0, padding: "5px" }}>
              {this.state.lastAction} Day(s)
            </Typography>
            <Button
              variant="outlined"
              onClick={e => {
                e.stopPropagation();
                this.setState({ commentShown: true });
              }}
              onFocus={e => e.stopPropagation()}
            >
              Comments
            </Button>
            <Button
              variant="outlined"
              onClick={e => {
                e.stopPropagation();
                this.setState({ contactShown: true });
              }}
              onFocus={e => e.stopPropagation()}
              style={{ marginLeft: "5px" }}
            >
              View Contact Details
            </Button>
          </ExpansionPanelSummary>
          {this.state.subTask.map((element, i) => (
            <SubTask
              key={element.id}
              task={element}
              preTour={this.props.task.taskType === 1}
              saveNote={note => {
                const arr = [...this.state.subTask];
                arr[i].notes = note;
                arr[i].updatedAt = new Date();
                this.setState({
                  subTask: arr,
                  lastAction: 0
                });
              }}
              update={e => {
                const arr = [...this.state.subTask];
                arr[i].completed = e;
                arr[i].updatedAt = new Date();
                if (this.checkComplete() && this.props.task.taskType !== 1) {
                  const ep = `${process.env.REACT_APP_API}/adm/followups/task/complete`;
                  Axios.post(ep, {
                    id: this.props.task.id,
                    completed: true
                  });
                  this.props.completed(this.props.task);
                }
                this.setState({
                  subTask: arr
                });
              }}
              updateSteps={e => {
                const arr = [...this.state.subTask];
                arr[i].stepscompleted = e;
                arr[i].updatedAt = new Date();
                this.setState({
                  subTask: arr,
                  lastAction: 0
                });
              }}
            />
          ))}
        </ExpansionPanel>
      </>
    );
  }
}

export default Task;
