import React, { useGlobal, useState, useEffect, useRef } from "reactn";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import {  differenceInSeconds, format, isAfter } from "date-fns";


import { FilterButton, ReportTable } from "../../../components/UI/reportsAnalytics";
import { CSVLink } from "react-csv";
import "./InvitesReport.css";

const InvitesReport = () => {
  const [listingTypeOptions, setListingTypeOptions] = useState([]);
  const [listingTypeFilter, setListingTypeFilter] = useState([]);
  const [listingOptions, setListingOptions] = useState([]);
  const [listingFilter, setListingFilter] = useState([]);
  const [totalInvitesNew, setTotalInvitesNew] = useState(0);
  const [totalInvitesExisting, setTotalInvitesExisting] = useState(0);
  const [totalNewConverted, setTotalNewConverted] = useState(0);
  const [totalExistingConverted, setTotalExistingConverted] = useState(0);
  const [avgResponseTime, setAvgResponseTime] = useState("");
  const [allInvites, setAllInvites] = useState([]);
  const location = useLocation();
  const listingType = location?.state?.listingType;
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");
  const [loading, setLoading] = useGlobal("loading");
  const orgData = useGlobal("organizationInfo")[0];

  useEffect(() => {
    const fetchFilterTypeOptions = async () => {
      const listings_ep = `${process.env.REACT_APP_API}/partner/permissions/fetch/new/listings-permissions`;
      const results = await axios.get(listings_ep);
      console.log(global);
      console.log("LISTINGS PERMISSIONS:", results.data.data);
      
      if (results.data.success) {
        const allowedListings = results.data.data.filter(listing => 
          listing.feature === "Programs" || 
          listing.feature === "Online Virtual" || 
          listing.feature === "Membership" || 
          listing.feature === "Events" || 
          listing.feature === "Parties"
        ).map(listing => ({listingId: listing.feature, item: listing.feature}));

        setListingTypeOptions(allowedListings);

        if (listingType) {
          setListingTypeFilter([{ listingId: listingType, item: listingType }]);
        } else {
          setListingTypeFilter(allowedListings)
        }
      }
    }
    fetchFilterTypeOptions();
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" },
      { label: "Invites Report", to: "/reports/invites-report" }
    ]);
  }, []);

  useEffect(() => {
    const fetchListingFilterOptions = async () => {
      const promises = listingTypeFilter.map(async type => {
        let ep = `${process.env.REACT_APP_API}/partners/${type.item.toLowerCase()}/list`;
        if (type.item === "Membership") {
          ep = `${process.env.REACT_APP_API}/partners/memberships/list`;
        } else if (type.item === "Online Virtual") {
          ep = `${process.env.REACT_APP_API}/partners/virtual/list`;
        }

        const result = await axios.get(ep);
        
        let options = [];
        if (result.data.success) {
          if (type.item === "Events") {
            options = result.data.data.map(data => 
              ({ 
                listingId: type.item.toLowerCase() + String(data.id), 
                item: data.event_title,
                type: "events",
                id: data.id
              })
            );
          } else if (type.item === "Parties") {
            options = result.data.data.map(data => 
              ({ 
                listingId: type.item.toLowerCase() + String(data.id), 
                item: data.party_title,
                type: "parties",
                id: data.id
              })
            );
          } else if (type.item === "Programs") {
            options = result.data.data.map(data => 
              ({ 
                listingId: type.item.toLowerCase() + String(data.id), 
                item: data.program_name,
                type: "programs",
                id: data.id
              })
            );
          } else if (type.item === "Online Virtual") {
            options = result.data.data.map(data => 
              ({ 
                listingId: type.item.toLowerCase() + String(data.id), 
                item: data.program_name,
                type: "online",
                id: data.id
              })
            );
          } else if (type.item === "Membership") {
            options = result.data.data.map(data =>
              ({ 
                listingId: type.item.toLowerCase() + String(data.id), 
                item: data.membership_name,
                type: "memberships",
                id: data.id
              })
            );
          }
        }

        return options;
      });

      const optionsData = (await Promise.all(promises)).flat();
      setListingOptions(optionsData);
      setListingFilter(optionsData);
    }

    fetchListingFilterOptions();
  }, [listingTypeFilter]);

  useEffect(() => {
    const fetchInvitesData = async () => {
      const productIds = {};

      listingFilter.forEach(listing => {
        if (!productIds[listing.type])
          productIds[listing.type] = [];

        productIds[listing.type].push(listing.id);
      });

      let totalInvites = [];
      let newInvites = 0;
      let existingInvites = 0;
      let newConverted = 0;
      let existingConverted = 0;
      let totalResponseTime = 0;
      for (let type of Object.keys(productIds)) {
        console.log("IDS:", productIds[type])
        const ep = `${process.env.REACT_APP_API}/partners/invitations/get-by-ids/${type}`;
        const result = await axios.post(ep, {
            productIds: productIds[type]
        });

        if (result.data.success) {
          const invites = result.data.data;
          totalInvites.push(...invites);

          newInvites += invites.filter(invite => !invite.isExistingUser).length;
          existingInvites += invites.filter(invite => invite.isExistingUser).length;

          newConverted += invites.filter(invite => !invite.isExistingUser && invite.convertedDate).length;
          existingConverted += invites.filter(invite => invite.isExistingUser && invite.convertedDate).length;

          totalResponseTime += invites.filter(invite => invite.convertedDate).reduce((acc, curr) => {
            const diffSeconds = differenceInSeconds(new Date(curr.convertedDate), new Date(curr.createdAt));
            console.log(diffSeconds);
            return acc + diffSeconds;
          }, 0);
        }
      }

      setAllInvites(totalInvites);
      setTotalInvitesExisting(existingInvites);
      setTotalInvitesNew(newInvites);
      setTotalExistingConverted(existingConverted);
      setTotalNewConverted(newConverted);
      const secondsPerDay = 60 * 60 * 24;
      const secondsPerHour = 60 * 60;
      const secondsPerMin = 60;

      let avgTime = (totalResponseTime) / (newConverted + existingConverted);
      if (isNaN(avgTime)) {
        setAvgResponseTime("N/A");
        return;
      }

      if (avgTime < secondsPerDay) {
        if (avgTime < secondsPerHour) {
          setAvgResponseTime(`${(avgTime / secondsPerMin).toFixed(1)} mins`);
        } else {
          setAvgResponseTime(`${(avgTime / secondsPerHour).toFixed(1)} hrs`);
        }
      } else {
        setAvgResponseTime(`${(avgTime / secondsPerDay).toFixed(1)} days`);
      }
    }
    
    fetchInvitesData();
  }, [listingFilter]);

  const getInfoComponent = (total, redeemed, type)=> {
    return (
      <>
        <p
        style={{
          color: "#a1a1a1",
          fontStyle: "italic",
          fontSize: "0.8rem"
        }}
        className="info-components"
        >
          New Clients {type}:{" "}
          {total}
        </p>
        <p
        style={{
          color: "#a1a1a1",
          paddingBottom: "20px",
          fontStyle: "italic",
          fontSize: "0.8rem"
        }}
        className="info-components"
        >
          Existing Clients {type}:{" "}
          {redeemed}
        </p>
      </>
    );
  };

  const getAmountComponent = (amount, time) => {
    return <h3>{amount.toLocaleString("en-US")} {time}</h3>;
  };

  const formatData = (data, type) => {
    console.log(type);
    let table = "";
    switch (type) {
      case "Programs":
        table = "partner_programs";
        break;
      case "Online Virtual":
        table = "partner_online";
        break;
      case "Events":
        table = "partner_event";
        break;
      case "Parties":
        table = "partner_party";
        break;
      case "Membership":
        table = "partner_memberships";
        break;
    }
    console.log(table);
    const formattedData = data.filter(row => row.product_table === table).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(row => {
      let responseTimeText = "N/A";

      if (row.convertedDate) {
        const responseTime = differenceInSeconds(new Date(row.convertedDate), new Date(row.createdAt));
        const secondsPerDay = 60 * 60 * 24;
        const secondsPerHour = 60 * 60;
        const secondsPerMin = 60;
  
        if (responseTime < secondsPerDay) {
          if (responseTime < secondsPerHour) {
            responseTimeText = `${(responseTime / secondsPerMin).toFixed(1)} mins`;
          } else {
            responseTimeText = `${(responseTime / secondsPerHour).toFixed(1)} hrs`;
          }
        } else {
          responseTimeText = `${(responseTime / secondsPerDay).toFixed(1)} days`;
        }  
      }

      return [
        row["Full Name"],
        row.email,
        format(new Date(row.createdAt), "PP"),
        row.came_from.split(" ").slice(1).join(" "),
        row.isExistingUser ? "Existing" : "New",
        !!row.convertedDate,
        responseTimeText
      ];
    });

    console.log(formattedData);
    return formattedData;
  }

  const sanitizeString = (desc) => {
    let itemDesc = desc;
    if (desc && typeof(itemDesc) === 'string') {
        itemDesc = itemDesc.replace(/"/g, '""');
        itemDesc = itemDesc.replace(/ +(?= )/g,'');
    }
    return itemDesc;
  }

  const getCsvData = (data) => {
    const csvData = data.map(row => {
      let responseTimeText = "N/A";

      if (row.convertedDate) {
        const responseTime = differenceInSeconds(new Date(row.convertedDate), new Date(row.createdAt));
        const secondsPerDay = 60 * 60 * 24;
        const secondsPerHour = 60 * 60;
        const secondsPerMin = 60;
  
        if (responseTime < secondsPerDay) {
          if (responseTime < secondsPerHour) {
            responseTimeText = `${(responseTime / secondsPerMin).toFixed(1)} mins`;
          } else {
            responseTimeText = `${(responseTime / secondsPerHour).toFixed(1)} hrs`;
          }
        } else {
          responseTimeText = `${(responseTime / secondsPerDay).toFixed(1)} days`;
        }  
      }

      return {
        name: sanitizeString(row["Full Name"]),
        email: sanitizeString(row.email),
        invitedDate: sanitizeString(format(new Date(row.createdAt), "PP")),
        listingName: sanitizeString(row.came_from.split(" ").slice(1).join(" ")),
        type: row.isExistingUser ? "Existing" : "New",
        converted: !!row.convertedDate,
        responseTime: sanitizeString(responseTimeText)
      };
    });

    return csvData;
  }

  const csvHeaders = [
    { label: "Full Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Invite Date", key: "invitedDate" },
    { label: "Listing Name", key: "listingName" },
    { label: "Client Type", key: "type" },
    { label: "Converted?", key: "converted"},
    { label: "Response Time", key: "responseTime"}
  ];

  return (
    <>
    <div className="admin">
      <div className="container-fluid adminprofiles">
        <div className="row cont">
          <div className="col-md-6">
            <h1>Invites Report</h1>
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-section">
            <div
                className="filter-item grid-new-col margin-left-offset"
              >
              <FilterButton
                label="Listing Types"
                checkedOptions={listingTypeFilter}
                setCheckedOptions={setListingTypeFilter}
                allOptions={listingTypeOptions}
                stringProperty="item"
              />
              <FilterButton
                label="Listings"
                checkedOptions={listingFilter}
                setCheckedOptions={setListingFilter}
                allOptions={listingOptions}
                stringProperty="item"
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center"}}className="container-fluid my_earning">
        <div className="cont report-card">
          <div id="discounts-report">
            <div className="report-heading">
              <h1 id="report-title">Invites report </h1>
              <CSVLink
                headers={csvHeaders}
                data={getCsvData(allInvites)}
                filename={"InvitesReport.csv"}
              > 
                <button id="discounts-export-button" className="discounts-export filter-button" >Export</button>
              </CSVLink>
            </div>
            <div>
              <div className="boxes">
                <div className="discount-box box">
                  <h2>Total Invites Sent</h2>
                  <div
                    className="info"
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                  >
                    {getAmountComponent(totalInvitesNew + totalInvitesExisting)}
                    {getInfoComponent(totalInvitesNew, totalInvitesExisting, "")}
                  </div>
                </div>
                <div className="discount-box box">
                  <h2>Total Invites Converted</h2>
                  <div
                    className="info"
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                  >
                    {getAmountComponent(totalNewConverted + totalExistingConverted)}
                    {getInfoComponent(totalNewConverted, totalExistingConverted, "Converted")}
                  </div>
                  <p className="blurb" >Converted invites are defined as invites to a customer that has an associated enrollment</p>
                </div>
                <div className="discount-box box">
                  <h2>Average Response Time</h2>
                  <div
                    className="info"
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                  >
                    {getAmountComponent(avgResponseTime)} 
                  </div>
                  <p className="blurb">
                    Only enrollments made to the listing within 30 days of the invite are considered in the calculation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {
            listingTypeOptions.map(option => {
              return (
                <ReportTable
                  headings={[
                    { label: "Customer", className: "table-item-bold" },
                    { label: "Email", className: "table-item-bold" },
                    { label: "Invite Date", className: "table-item-bold" },
                    { label: option.item, className: "table-item-bold" },
                    { label: "Client Type", className: "table-item-bold" },
                    { label: "Converted?", className: "table-item-bold" },
                    { label: "Response Time", className: "table-item-bold"}
                  ]}
                  body={formatData(allInvites, option.item)}
                />
              );
            })
          }
        </div>
      </div>
    </div>    
    </>
  );
};

export default InvitesReport;