import React from "reactn";
import { Table } from "../../UI";
import axios from "axios";
class FinancesOverviewTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { data: [] };
    this.headings = [
      { id: "created", label: "Payment Received On" },
      { id: "amount", label: "Gross Amount" },
      { id: "net", label: "Net Amount" },
      { id: "status", label: "Transaction Status" },
      { id: "description", label: "Description" },
      { id: "action", label: " " }
    ];
    this.actions = [{ name: "VIEW", action: this.viewPayment }];
  }

  viewPayment = async row => {};

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/finances/overview`;
    const results = await axios.get(ep);

    this.setState({
      data: results.data.data.map(e => ({
        ...e,
        created: new Date(e.created * 1000).toDateString(),
        status: e.status[0].toLocaleUpperCase() + e.status.slice(1),
        amount: `$${parseFloat(e.amount / 100).toFixed(2)} CAD`,
        net: `$${parseFloat(e.net / 100).toFixed(2)} CAD`
      }))
    });
  }

  render() {
    return (
      <div>
        <Table
          headings={this.headings}
          data={this.state.data}
          actions={this.actions}
        />
      </div>
    );
  }
}

export default FinancesOverviewTable;
