import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";
import Calendar from "react-calendar";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { addMonths, subMonths, addWeeks, format } from "date-fns";
import { addDays } from "date-fns/esm";
import "../../../../assets/css/componentSpecificCss/reactCalendar.css";
import _ from "lodash";

class SectionFour extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      days: {},
      actualMonth: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      nextMonth: addMonths(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        1
      ),
      selectedDays: null
    };
  }
  componentDidMount() {
    const rangeArr = this.getRange();
    const closureRange = this.getClosureDateRange();
    if (rangeArr.length) {
      for (let date of rangeArr) {
        const doc = document.querySelector(
          `[aria-label="${format(date, "LLLL d, yyyy")}"]`
        );
        if (doc) {
          doc.style.color = "#FFF";
          doc.parentNode.style.backgroundColor = "#FF6663";
          doc.parentNode.style.borderRadius = "50%";
        }
      }
    }
    if (closureRange.length) {
      for (let date of closureRange) {
        const doc = document.querySelector(
          `[aria-label="${format(date, "LLLL d, yyyy")}"]`
        );
        if (doc) {
          doc.style.color = "#000";
          doc.parentNode.style.backgroundColor = "#EBEBEB";
        }
      }
    }
    if (this.global.program.closure_dates.length) {
      for (let date of this.global.program.closure_dates) {
        const doc = document.querySelector(
          `[aria-label="${format(new Date(date), "LLLL d, yyyy")}"]`
        );
        if (doc) {
          doc.style.color = "#000";
          doc.parentNode.style.backgroundColor = "#EBEBEB";
        }
      }
    }

    this.setState({
      selectedDays: rangeArr
    });
  }
  componentDidUpdate(pastState) {
    const rangeArr = this.getRange();
    const closureRange = this.getClosureDateRange();

    const unselectedDays = _.differenceWith(
      this.state.selectedDays,
      rangeArr,
      _.isEqual
    );
      
    if (!(_.isEqual(rangeArr, this.state.selectedDays))) {
      this.setState({
        selectedDays: rangeArr
      });
    }

    for (let date of unselectedDays) {
      const doc = document.querySelector(
        `[aria-label="${format(date, "LLLL d, yyyy")}"]`
      );

      if (doc) {
        doc.style.color = "black";
        doc.parentNode.style.backgroundColor = "#FFF";
      }
    }

    for (let date of rangeArr) {
      const doc = document.querySelector(
        `[aria-label="${format(date, "LLLL d, yyyy")}"]`
      );
      if (doc) {
        doc.style.color = "#FFF";
        doc.parentNode.style.backgroundColor = "#FF6663";
        doc.parentNode.style.borderRadius = "50%";
      }
    }

    for (let date of closureRange) {
      const doc = document.querySelector(
        `[aria-label="${format(date, "LLLL d, yyyy")}"]`
      );
      if (doc) {
        doc.style.color = "#000";
        doc.parentNode.style.backgroundColor = "#EBEBEB";
      }
    }
    if (this.global.online.closure_dates.length) {
      for (let date of this.global.online.closure_dates) {
        const doc = document.querySelector(
          `[aria-label="${format(new Date(date), "LLLL d, yyyy")}"]`
        );
        if (doc) {
          doc.style.color = "#000";
          doc.parentNode.style.backgroundColor = "#EBEBEB";
        }
      }
    }
  }

  getClosureDateRange() {
    const days = {
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
      0: "Sunday"
    };

    if (
      this.global.online.closure_timeframe &&
      this.global.online.closure_timeframe[0] &&
      this.global.online.closure_timeframe &&
      this.global.online.closure_timeframe[1]
    ) {
      const range = [
        new Date(this.global.online.closure_timeframe[0]),
        addDays(new Date(this.global.online.closure_timeframe[1]), 1)
      ];
      let date = range[0];
      const daysArr = [];
      if (range[1]) {
        while (date.toDateString() !== range[1].toDateString()) {
          if (this.global.online.days_of_week[days[date.getDay()]])
            daysArr.push(date);
          date = addDays(date, 1);
        }
        return daysArr;
      }
    }
    return [];
  }

  getRange() {
    const days = {
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
      0: "Sunday"
    };
    if(parseInt(this.global.online.program_type) === 0){
      let program_start = this.global.online.program_start;
      if (program_start !== format(new Date(program_start), "yyyy-MM-dd")) {
        program_start = addDays(new Date(program_start), 1);
      }
      const daysArr = [];
      daysArr.push(new Date(program_start));
      return daysArr;
    }
    else if (this.global.online.number_of_weeks > 0) {
      let program_start = this.global.online.program_start;
      if (program_start !== format(new Date(program_start), "yyyy-MM-dd")) {
        program_start = addDays(new Date(program_start), 1);
      }
      const range = [
        new Date(program_start),
        addWeeks(
          new Date(program_start),
          this.global.online.number_of_weeks
        )
      ];
      let date = range[0];
      const daysArr = [];

      while (date.toDateString() !== range[1].toDateString()) {
        if (this.global.online.days_of_week[days[date.getDay()]])
          daysArr.push(date);
        date = addDays(date, 1);
      }
      return daysArr;
    } else {
      let program_start = this.global.online.program_start;
      if (program_start !== format(new Date(program_start), "yyyy-MM-dd")) {
        program_start = addDays(new Date(program_start), 1);
      }
      if (program_start) {
        const dateMonth = addMonths(
          new Date(program_start),
          24
        );
        const range = [
          new Date(program_start),
          new Date(dateMonth.getFullYear(), dateMonth.getMonth(), 28)
        ];
        let date = range[0];
        const daysArr = [];
        while (date.toDateString() !== range[1].toDateString()) {
          if (this.global.online.days_of_week[days[date.getDay()]])
            daysArr.push(date);
          date = addDays(date, 1);
        }
        return daysArr;
      }
      return [];
    }
  }
  handleChange = (e, v) => {
    this.setGlobal({
      online: {
        ...this.global.online,
        [e]: v
      }
    });
  };

  customForm = () => {
    return (
      <div className="formbody">
        <form>
          <div className="row" style={{ justifyContent: "center" }}>
            <div>
              <Calendar
                minDetail="month"
                activeStartDate={this.state.actualMonth}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <Calendar
                minDetail="month"
                activeStartDate={this.state.nextMonth}
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              alignItems: "center",
              justifyContent: "space-around"
              // marginTop: "20px"
            }}
          >
            <div
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                backgroundColor: "#FF6663"
              }}
            ></div>
            Program Dates
            <div
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                backgroundColor: "#EBEBEB"
              }}
            ></div>
            Closure Dates
            <div>
              <IconButton
                onClick={_ =>
                  this.setState({
                    actualMonth: subMonths(this.state.actualMonth, 1),
                    nextMonth: subMonths(this.state.nextMonth, 1)
                  })
                }
              >
                <ArrowUpward />
              </IconButton>
              <IconButton
                onClick={_ =>
                  this.setState({
                    actualMonth: addMonths(this.state.actualMonth, 1),
                    nextMonth: addMonths(this.state.nextMonth, 1)
                  })
                }
              >
                <ArrowDownward />
              </IconButton>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Calendar View"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionFour;
