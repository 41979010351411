import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { Button } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import BaseModal from "./baseModal/BaseModal";
import {
  CONTENT_BLOCK,
  BUTTON,
  TEXT_AREA,
  IMAGE_CONTENT,
  DROPDOWN,
  STRING_INPUT
} from "./baseModal/FieldTypes";
import {
  BLUE_BUTTON,
  GREEN_BUTTON,
  RED_BUTTON,
  WHITE,
  YELLOW_BUTTON
} from "./baseModal/colours";

class DeleteExpenseConfirmationModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  getBaseModalFields() {
    const baseModalFieldsArr = [];

    const confirmationPrompt = {
      type: CONTENT_BLOCK,
      data: {
        name: "Are you sure you would like to remove this expense? This action cannot be undone."
      }
    };

    baseModalFieldsArr.push(confirmationPrompt);
    return baseModalFieldsArr;
  }

  getBaseModalButtons() {
    const confirmButton = {
      name: "Confirm",
      buttonColour: RED_BUTTON,
      textColour: WHITE,
      handleClick: async () => {
        await this.props.onDelete();
        toast.success("Expense successfully removed!");
        this.props.onClose();
      }
    };

    return [confirmButton];
  }

  getBaseModalProps() {
    const baseModalProps = {
      title: "Confirmation",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: () => this.props.onClose()
    };

    return baseModalProps;
  }

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default DeleteExpenseConfirmationModal;
