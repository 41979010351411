import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import axios from "axios";
import moment from "moment";
import PartnerJournalModal from "../../components/modals/PartnerJournalModal";
// import Typography from "@material-ui/core/Typography";
// import { MiniPlus } from "../../assets";
import AddMilestoneParticipantModal from "../../components/modals/AddMilestoneParticipantModal";
import ViewMilestoneFileUploadsModal from "../../components/modals/ViewMilestoneFileUploadsModal";
import ViewJournalModal from "../../components/modals/ViewJournalModal";

/*
Route: /partners/milestones/view/:milestoneId/
*/

class ViewMilestones extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      isCompletedChecked: false,
      showJournalModal: false,
      modalJournalText: "",
      modalJournalAuthor: "",
      milestone: {},
      openAddParticipantModal: false,
      openViewMilestoneFileUploadsModal: false,
      selectedMilestoneView: null,
      Enrollments: [],
      openViewJournalModal: false
    };

    this.tabs = ["Participants"];
    this.headings = {
      Participants: [
        {
          id: "user_name",
          label: "User's Name",
          customCell: (r, i) => <td key={i}>{r.fullName}</td>
        },
        {
          id: "journal",
          label: "Journal",
          customCell: (r, i) => (
            <td key={i}>
              {r.journal ? (
                <button
                  onClick={() => {
                    // this.setState({ showJournalModal: true });
                    this.setState({ modalJournalText: r.journal });
                    this.setState({ modalJournalAuthor: r.fullName });
                    this.setState({ openViewJournalModal: true });
                  }}
                >
                  View Journal
                </button>
              ) : (
                <p>Journal Not Submitted</p>
              )}
            </td>
          )
        },
        {
          id: "upload",
          label: "Uploads",
          customCell: (r, i) => {
            return (
              <td key={r.id}>
                {r.can_upload ? (
                  <button
                    name="is_active"
                    onClick={_ => {
                      console.log("clicked", r);
                      this.setState({
                        openViewMilestoneFileUploadsModal: true,
                        selectedMilestoneView: r
                      });
                    }}
                  >
                    View Uploads
                  </button>
                ) : null}
                {/* {r.can_upload ? (
                  <a
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    href={r.file_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <DocumentIcon />
                    </span>
                    <span style={{ margin: "0 0.4rem" }}>View Upload</span>
                    <span className="badge badge-info">
                      {getFileExtention(r.file_url)}
                    </span>
                  </a>
                ) : null} */}
              </td>
            );
          }
        },
        {
          id: "createdAt",
          label: "Upload Date",
          customCell: (r, i) => {
            return (
              <td key={i}>
                {moment(r.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}
              </td>
            );
          }
        }
      ]
    };
    this.actions = [{ name: "Edit", action: null }];
  }

  async componentDidMount() {
    const milestone_id = this.props.match.params.id;
    // get the milestone
    const getMilestone = async () => {
      const ep = `${process.env.REACT_APP_API}/partners/milestones/get_one/${milestone_id}`;
      const milestone_data = await axios.get(ep);

      if (
        milestone_data &&
        milestone_data.data &&
        milestone_data.data.success
      ) {
        this.setState({ milestone: milestone_data.data.data });
      }
    };
    await getMilestone();

    const ep = `${process.env.REACT_APP_API}/partners/milestones_submissions/get/${milestone_id}`;
    const milestone_submissions = await axios.get(ep);

    if (
      milestone_submissions &&
      milestone_submissions.data &&
      milestone_submissions.data.success
    ) {
      const Participants = milestone_submissions.data.Participants;
      this.setState({ data: { Participants } });
    }

    if (this.state.milestone.milestone_type === "Program") {
      // Fetching the child and parent name for the milestone program
      const customers_ep = `${process.env.REACT_APP_API}/partners/programs/customers/${this.state.milestone.program}`;
      const customers = await axios.get(customers_ep);

      if (customers.data.success) {
        customers.data.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        this.setState({
          Enrollments: customers.data.data.filter(
            e =>
              e.status_stage === "Accepted_Paid" ||
              e.status_stage === "Accepted_Manual"
          ) // Fetching the enrollments for the customers who are enrolled
        });
      }
    }

    if (this.state.milestone.milestone_type === "Online") {
      // Fetching the child and parent name for the milestone online
      const customers_ep = `${process.env.REACT_APP_API}/partners/virtual/customers/${this.state.milestone.onlineId}`;
      const customers = await axios.get(customers_ep);

      if (customers.data.success) {
        customers.data.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        this.setState({
          Enrollments: customers.data.data.filter(
            e =>
              e.status_stage === "Accepted_Paid" ||
              e.status_stage === "Accepted_Manual"
          ) // Fetching the enrollments for the customers who are enrolled
        });
      }
    }
  }

  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  render() {
    console.log("STATE PROPS", this.state, this.props);
    return (
      <div className="admin">
        {this.state.showJournalModal && (
          <PartnerJournalModal
            author={this.state.modalJournalAuthor}
            journalText={this.state.modalJournalText}
            onClose={_ => this.setState({ showJournalModal: false })}
          />
        )}

        {this.state.openAddParticipantModal && (
          <AddMilestoneParticipantModal
            onClose={_ => this.setState({ openAddParticipantModal: false })}
            enrollments={this.state.Enrollments}
            programId={this.state.milestone.program}
            onlineId={this.state.milestone.onlineId}
            milestoneId={this.state.milestone.id}
          />
        )}
        {this.state.openViewMilestoneFileUploadsModal && (
          <ViewMilestoneFileUploadsModal
            onClose={() =>
              this.setState({ openViewMilestoneFileUploadsModal: false })
            }
            milestoneData={this.state.selectedMilestoneView}
          />
        )}
        {this.state.openViewJournalModal && (
          <ViewJournalModal
            journalText={this.state.modalJournalText}
            onClose={() => this.setState({ openViewJournalModal: false })}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>{this.state.milestone.name}</h1>
            </div>
            {/* <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp">
                  <button
                    className="newapplications"
                    onClick={_ => this.setState({ openAddParticipantModal: true })}
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Participant
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          <div className="cont">
            <div className="tablecont">
              <div className="table-responsive">
                <NewTabbedTable
                  tabs={this.tabs}
                  headings={this.headings}
                  data={this.state.data}
                  actions={this.actions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function getFileExtention(file_url) {
  if (file_url) {
    const split = file_url.split(".");
    return split[split.length - 1];
  }
}
const DocumentIcon = () => {
  return (
    <svg
      style={{ width: "15px", height: "15px" }}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
      ></path>
    </svg>
  );
};
export default ViewMilestones;
