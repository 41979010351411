import React, { useGlobal, useState, useEffect, useRef } from "reactn";
import { format, parse, addWeeks, addDays, subDays, isAfter, isBefore, isSameDay } from "date-fns";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { useDateFilter } from "../../../providers/reports";
import { DateFilter, ReportContainer, ReportTable } from "../../../components/UI/reportsAnalytics";
import FilterButton from "../../../assets/img/FilterButton.png";
import  Doughnut from "../../../components/charts/Doughnut";
import "./DiscountsReport.css";
import "../../../assets/css/componentSpecificCss/reportAnalysisPages.css";

const DiscountsReport = () => {
  const [couponDiscounts, setCouponDiscounts] = useState(0);
  const [promoDiscounts, setPromoDiscounts] = useState(0);
  const [freeTrialDiscounts, setFreeTrialDiscounts] = useState(0);
  const [totalCoupons, setTotalCoupons] = useState(0);
  const [totalPromos, setTotalPromos] = useState(0);
  const [totalFreeTrials, setTotalFreeTrials] = useState(0);
  const [couponRedemptionCount, setCouponRedemptionCount] = useState(0);
  const [promoRedemptionCount, setPromoRedemptionCount] = useState(0);
  const [freeTrialRedemptionCount, setFreeTrialRedemptionCount] = useState(0);
  const [allDiscounts, setAllDiscounts] = useState([]);
  const [nonDiscountsCount, setNonDiscountsCount] = useState(0);
  const [convertedToDropIns, setConvertedToDropIns] = useState(0);
  const [convertedToLongTerm, setConvertedToLongTerm] = useState(0);
  const [couponsChecked, setCouponsChecked] = useState(true);
  const [promosChecked, setPromosChecked] = useState(true);
  const [freeTrialsChecked, setFreeTrialsChecked] = useState(true);
  const [filterMenuVisible, setFilterMenuVisible] = useState(false);
  const [dateFrom, dateTo, setDateFrom, setDateTo] = useDateFilter();
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");
  const [loading, setLoading] = useGlobal("loading");
  const orgData = useGlobal("organizationInfo")[0];

  const filterMenu = useRef(null);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  useEffect(() => {
    const fetchDiscounts = async () => {
      const ep = `${process.env.REACT_APP_API}/partners/orders/discounts`;

      const results = await axios.get(ep, {
        params: {
          start: format(parse(dateFrom, "yyyy-MM-dd", new Date()), "yyyy-MM-dd"),
          end: format(parse(dateTo, "yyyy-MM-dd", new Date()), "yyyy-MM-dd")
        }
      });

      if (results.data.success) {
        const data = results.data.data;
        setCouponDiscounts(data.couponDiscounts);
        setTotalCoupons(data.totalCoupons);
        setCouponRedemptionCount(data.redeemedCoupons);
        setPromoDiscounts(data.promoDiscounts);
        setTotalPromos(data.totalPromos);
        setPromoRedemptionCount(data.redeemedPromos);
        setFreeTrialDiscounts(data.freeTrialDiscounts);
        setTotalFreeTrials(data.totalFreeTrials);
        setFreeTrialRedemptionCount(data.redeemedFreeTrials);
        setAllDiscounts(data.allDiscounts);
      }

      const ep2 = `${process.env.REACT_APP_API}/partners/orders/non-discounts-count`;
      const results2 = await axios.get(ep2, {
        params: {
          start: format(parse(dateFrom, "yyyy-MM-dd", new Date()), "yyyy-MM-dd"),
          end: format(parse(dateTo, "yyyy-MM-dd", new Date()), "yyyy-MM-dd")
        }
      });

      if (results2.data.success) {
        console.log(results2.data.data);
        setNonDiscountsCount(results2.data.data);
      }

      const ep3 = `${process.env.REACT_APP_API}/partners/orders/free-trials/conversions`;
      const results3 = await axios.get(ep3, {
        params: {
          start: format(parse(dateFrom, "yyyy-MM-dd", new Date()), "yyyy-MM-dd"),
          end: format(parse(dateTo, "yyyy-MM-dd", new Date()), "yyyy-MM-dd")
        }
      });

      if (results3.data.success) {
        setConvertedToDropIns(results3.data.data.convertedToDropIns);
        setConvertedToLongTerm(results3.data.data.convertedToLongTerm);
      }
    }

    fetchDiscounts();
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" },
      { label: "Discounts Report", to: "/reports/discounts-report" }
    ]);
  }, [dateFrom, dateTo]);
  useEffect(() => {
    document.addEventListener("mousedown", event => {
      if (!filterMenu.current?.contains(event.target)) {
        setFilterMenuVisible(false);
      }
    });
  });
  const getInfoComponent = (total, redeemed, type)=> {
    return (
      <>
        <p
        style={{
          color: "#a1a1a1",
          fontStyle: "italic",
          fontSize: "0.8rem"
        }}
        className="info-components"
        >
          Total {type} Created (to-date):{" "}
          {total}
        </p>
        <p
        style={{
          color: "#a1a1a1",
          paddingBottom: "40px",
          fontStyle: "italic",
          fontSize: "0.8rem"
        }}
        className="info-components"
        >
          Total {type} Redeemed (to-date):{" "}
          {redeemed}
        </p>
      </>
    );
  };

  const getAmountComponent = amount => {
    return <h3>{amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD"
    })}</h3>;
  };

  const formatData = (data) => {
    console.log("data: ", data);
    const filteredData = data.filter(row => (couponsChecked && (row.couponId || row.couponAmount > 0)) || (promosChecked && (row.promotionId || row.promotionAmount > 0)) || (freeTrialsChecked && row.type === "Free Trial"));
    const formattedData = filteredData.map(row => {
      let discountAmount = 0;
      let discountType = "";

      if (row.couponId || (row.couponAmount && row.couponAmount > 0)) {
        discountAmount += parseFloat(row.couponAmount) / 100;
        discountType = "Coupon ";
      }

      if (row.promotionId || (row.promotionAmount && row.promotionAmount > 0)) {
        discountAmount += parseFloat(row.promotionAmount) / 100;
        discountType += "Promotion";
      }

      discountAmount = discountAmount.toFixed(2);

      if (row.type === "Free Trial") {
        discountAmount = "Free"
        discountType = "Free Trial";
      }

      let item = row.program_name;
      if (row.invoice_details && row.invoice_details.length > 0) {
        const invoice = JSON.parse(row.invoice_details[0]);
        if (invoice && invoice.itemName)
          item = invoice.itemName;
      }

      const date = row.userPaidDate || row.orderStartDate || row.updatedAt;

      return [row.displayName, format(new Date(date), "PP").toString(), item, discountType, discountAmount];
    });
    console.log("formatted data", formattedData);
    return formattedData;
  }

  const getCSVData = (data) => {
    const filteredData = data.filter(row => (couponsChecked && (row.couponId || row.couponAmount > 0)) || (promosChecked && (row.promotionId || row.promotionAmount > 0)) || (freeTrialsChecked && row.type === "Free Trial"));

    const csvData = filteredData.map(row => {
      let discountAmount = 0;
      let discountType = "";

      if (row.couponId) {
        discountAmount += parseFloat(row.couponAmount) / 100;
        discountType = "Coupon ";
      }

      if (row.promotionId) {
        discountAmount += parseFloat(row.promotionAmount) / 100;
        discountType += "Promotion";
      }

      discountAmount = discountAmount.toFixed(2);

      if (row.type === "Free Trial") {
        discountAmount = "Free"
        discountType = "Free Trial";
      }

      let item = row.program_name;
      if (row.invoice_details && row.invoice_details.length > 0) {
        const invoice = JSON.parse(row.invoice_details[0]);
        if (invoice && invoice.itemName)
          item = invoice.itemName;
      }

      const date = row.userPaidDate || row.orderStartDate || row.updatedAt;

      return {
        customer: row.displayName,
        date: format(new Date(date), "PP"),
        item,
        discountType,
        discountAmount
      };
    });

    return csvData;
  }

  const downloadPDF = async () => {
    setLoading(true);
    const discountsReport = document.getElementById("discounts-report");
    const exportButton = discountsReport.querySelector("#discounts-export-button");
    const buttonParent = exportButton.parentElement;
    exportButton.remove();
    const discountsCanvas = await html2canvas(discountsReport);    
    buttonParent.appendChild(exportButton);

    const pdf = new jsPDF("p", "mm", "letter", false);
    let img = discountsCanvas.toDataURL("image/png");
    const imgProps = pdf.getImageProperties(img);
    let width = pdf.internal.pageSize.getWidth() - 40;
    let height = (imgProps.height * width) / imgProps.width;
    pdf.addImage(img, "PNG", 20, 20, width, height);

    const tableTitle = document.getElementById("table-title");
    const titleCanvas = await html2canvas(tableTitle);
    img = titleCanvas.toDataURL("image/png");
    let titleHeight = tableTitle.clientHeight * width / imgProps.width;
    let titleWidth = tableTitle.clientWidth * width / imgProps.width;
    pdf.addImage(img, "PNG", 20, height+20, titleWidth, titleHeight);

    let rowGrouping = document.getElementById("item-grouping-0");
    const tableHeading = document.getElementById("table-headings");
    let counter = 0;
    while (rowGrouping) {
      const headingCanvas = await html2canvas(tableHeading);
      const headingImg = headingCanvas.toDataURL("image/png");
      const headingProperties = pdf.getImageProperties(headingImg);
      const headingWidth = pdf.internal.pageSize.getWidth() - 40;
      const headingHeight  = (headingProperties.height * width) / headingProperties.width;

      const canvas = await html2canvas(rowGrouping);
      const canvasImg = canvas.toDataURL("image/png");
      const canvasProperties = pdf.getImageProperties(canvasImg);
      const groupWidth = pdf.internal.pageSize.getWidth() - 40;
      const groupHeight = (canvasProperties.height * width) / canvasProperties.width;
      counter++;
      if (counter > 1)
        pdf.addPage();
      pdf.addImage(headingImg, "JPEG", 20, height+titleHeight+20, headingWidth, headingHeight);
      pdf.addImage(canvasImg, "JPEG", 20, height+titleHeight+headingHeight+20, groupWidth, groupHeight);
      height = 0;
      titleHeight = 0;
      rowGrouping = document.getElementById(`item-grouping-${counter}`);
    }

    pdf.save("DiscountsReport.pdf");
    setLoading(false);
  }

  const reportSubtitles = [
    orgData.organization_title,
    `For ${format(parse(dateFrom, "yyyy-MM-dd", new Date()), "MMM d, yyyy")}
    - 
    ${format(parse(dateTo, "yyyy-MM-dd", new Date()), "MMM d, yyyy")} 
    `
  ];
  const csvHeaders = [
    { label: "Customer", key: "customer" },
    { label: "Date", key: "date" },
    { label: "Item", key: "item" },
    { label: "Discount Type", key: "discountType" },
    { label: "Discount Amount", key: "discountAmount" }
  ];

  return (
    <>
    <div className="admin">
      <div className="container-fluid adminprofiles">
        <div className="row cont">
          <div className="col-md-6">
            <h1>Discounts Report</h1>
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-section">
            <DateFilter
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
            />
          </div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center"}}className="container-fluid my_earning">
        <div className="cont report-card">
          <div id="discounts-report">
            <div className="report-heading">
              <h1 id="report-title">Discounts report </h1>
              <button id="discounts-export-button" className="discounts-export filter-button" onClick={downloadPDF}>Export Report</button>
            </div>
            <div>
              <div className="boxes">
                <div style={{minWidth: "max-content"}} className="discount-box box">
                  <h2>Total Coupon Discounts</h2>
                  <div
                    className="info"
                    style={{ paddingTop: "20px", paddingBottom: "20px" }}
                  >
                    {getAmountComponent(couponDiscounts)}
                    {getInfoComponent(totalCoupons, couponRedemptionCount, "Coupons")}
                    <p>
                      Since{" "}
                      {format(parse(dateFrom, "yyyy-MM-dd", new Date()), "LLL yyyy")}
                    </p>
                  </div>
                </div>
                <div style={{minWidth: "max-content"}} className="discount-box box">
                  <h2>Total Promotion Discounts</h2>
                  <div
                    className="info"
                    style={{ paddingTop: "20px", paddingBottom: "20px" }}
                  >
                    {getAmountComponent(promoDiscounts)}
                    {getInfoComponent(totalPromos, promoRedemptionCount, "Promotions")}
                    <p>
                      Since{" "}
                      {format(parse(dateFrom, "yyyy-MM-dd", new Date()), "LLL yyyy")}
                    </p>
                  </div>
                </div>
                <div style={{minWidth: "max-content"}} className="discount-box box">
                  <h2>Total Free Trial Discounts</h2>
                  <div
                    className="info"
                    style={{ paddingTop: "20px", paddingBottom: "20px" }}
                  >
                    {getAmountComponent(freeTrialDiscounts)}
                    {getInfoComponent(totalFreeTrials, freeTrialRedemptionCount, "Free Trials")}
                    <p>
                      Since{" "}
                      {format(parse(dateFrom, "yyyy-MM-dd", new Date()), "LLL yyyy")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="boxes">
                <div className="discount-box box discount-graphs">
                  <h2>Discount Distributions</h2>
                  <div className="discount-doughnut">
                    <Doughnut
                      labels={["Free Trials Orders", "Promotion Orders", "Coupon Orders", "Regular Orders"]}
                      data={[freeTrialRedemptionCount, promoRedemptionCount, couponRedemptionCount, nonDiscountsCount]}
                      cutoutPercentage={63}
                      legendPositions={"bottom"}
                    />
                  </div>
                </div>
                <div className="discount-box box discount-graphs">
                  <h2>Free Trial Conversions</h2>
                  <div className="discount-doughnut">
                    <Doughnut
                      labels={["Converted to Long-term plans", "Converted to Drop-Ins", "Failed to convert"]}
                      data={[convertedToLongTerm, convertedToDropIns, freeTrialRedemptionCount - convertedToDropIns - convertedToLongTerm]}
                      cutoutPercentage={63}
                      legendPositions={"bottom"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
            <ReportContainer 
              title="Discount Customers"
              subtitles={reportSubtitles}
              csvHeaders={csvHeaders}
              csvData={getCSVData(allDiscounts)}
              fileName={`Discounts_${dateFrom}-${dateTo}.csv`}
              buttonText="Export Table"
              isLarge
            >
              <button className="customers-filter"
                onClick={() => {
                  setFilterMenuVisible(true);
                }}
              >
                <img src={FilterButton} width={25} height={25}/>
              </button>
              {filterMenuVisible && (
                <div 
                  class="customers-filter-menu"
                  ref={filterMenu}
                >
                  Filter by Discount Type
                  <div 
                    style={{
                      height: "1px", 
                      width:"auto", 
                      margin: "5px 0px 5px 0px", 
                      backgroundColor: "grey",
                    }}
                  /> 
                  <input
                    type="checkbox"
                    style={{marginRight: "15px"}}
                    checked={couponsChecked}
                    onChange={() => setCouponsChecked(!couponsChecked)}
                  />
                  <span>Coupons</span>
                  <br/>
                  <input
                    type="checkbox"
                    style={{marginRight: "15px"}}
                    checked={promosChecked}
                    onChange={() => setPromosChecked(!promosChecked)}
                  />
                  <span>Promotions</span>
                  <br/>
                  <input
                    type="checkbox"
                    style={{marginRight: "15px"}}
                    checked={freeTrialsChecked}
                    onChange={() => setFreeTrialsChecked(!freeTrialsChecked)}
                  />
                  <span>Free Trials</span>
                </div>
              )}
              <ReportTable
                headings={[
                  { label: "Customer", className: "table-item-bold" },
                  { label: "Date", className: "table-item-bold" },
                  { label: "Item", className: "table-item-bold" },
                  { label: "Discount Type", className: "table-item-bold" },
                  { label: "Discount Amount", className: "table-item-bold" },
                ]}
                body={formatData(allDiscounts)}
              />
          </ReportContainer>
        </div>
      </div>
    </div>    
    </>
  );
};

export default DiscountsReport;