import React from "reactn";
import axios from "axios";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/core";
import "../../../assets/css/componentSpecificCss/userAccess.css";
import { Switch } from "@material-ui/core";
import UserListingModal from "../../../components/modals/UserListingsModal";

const styles = {
  iconButton: {
    backgroundColor: "transparent !important",
    padding: "1em !important",
    color: "rgba(0, 0, 0, 0.54) !important",
    "&:focus": {
      outline: "none !important"
    }
  },
  avatar: {
    width: "50px",
    height: "50px"
  },
  item: {
    padding: "5px 10px",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "13px"
  },
  list: {
    height: "60px",
    padding: "0px"
  },
  tabRoot: {
    color: "#61616F !important",
    fontFamily: "Open Sans,sans-serif !important",
    fontWeight: "600 !important",
    fontSize: "1rem !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "#61616F !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "#61616F !important"
    }
  },
  tabSelected: {
    color: "black !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "black !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "black !important"
    }
  },
  tabIndicator: {
    backgroundColor: "#61616F",
    height: "3px",
    marginTop: "20px"
  }
};

class AccessDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      subActive: "Access",
      canEdit: false,
      role: [],
      hasAllAccess: false,
      editRole: false,
      updatedRole: null,
      program: [],
      event: [],
      membership: [],
      online: [],
      party: [],
      openListingsModal: false,
      listingType: ""
    };

    this.locations = [
      {
        address:
          this.global.organizationInfo.address1 +
          ", " +
          this.global.organizationInfo.city +
          ", " +
          this.global.organizationInfo.province +
          " " +
          this.global.organizationInfo.postalcode +
          ", " +
          this.global.organizationInfo.country
      },
      ...this.global.organizationInfo.other_locations.map(e => ({
        address: e.address
      }))
    ];
  }

  componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    const filterRole = this.props.roles.filter(
      e => e.id == this.props.selectedUser.role
    );

    console.log('filterRole Mount', filterRole);

    let roles = this.props.allRoles.filter(e => e.role === "Administrator" && !e.isArchived);
    roles.push(...this.props.allRoles.filter(e => e.role === "Programs (all)" && !e.isArchived));
    roles.push(...this.props.allRoles.filter(e => e.role === "Online Virtual (all)" && !e.isArchived));
    roles.push(...this.props.allRoles.filter(e => e.role === "Events (all)" && !e.isArchived));
    roles.push(...this.props.allRoles.filter(e => e.role === "Listings (Specific)" && !e.isArchived))

    const reservedRoles = ["Administrator", "Programs (all)", "Online Virtual (all)", "Events (all)", "Listings (Specific)"];

    // Add all remaining roles
    this.props.allRoles.map(e => {
      if (
        e.role.startsWith("Program Specific") ||
        e.role.startsWith("Event Specific") ||
        e.role.startsWith("Online Specific") ||
        reservedRoles.includes(e.role) ||
        e.role === "Owner" ||
        e.isArchived
      ) {
        return;
      } else {
        roles.push(e);
      }
    });

    this.setState({
      role: filterRole,
      selectedUser: this.props.selectedUser,
      allRoles: roles,
      updatedRole: this.props.selectedUser.role
    });
    console.log('filterRole', filterRole);
    if (this.props.selectedUser.programlist) {
      this.fetchProgramListData(this.props.selectedUser.programlist);
    }
    if (this.props.selectedUser.eventlist) {
      this.fetchEventListData(this.props.selectedUser.eventlist);
    }
    if (this.props.selectedUser.onlinelist) {
      this.fetchOnlineListData(this.props.selectedUser.onlinelist);
    }
    if (this.props.selectedUser.partylist) {
      this.fetchPartyListData(this.props.selectedUser.partylist);
    }
  }

  fetchProgramListData = async programIds => {
    return Promise.all(
      programIds.map(async id => {
        // console.log('id', id);
        const ep = `${process.env.REACT_APP_API}/partners/programs/find-program/${id}`;
        const results = await axios.get(ep);

        if (results.data.success) {
          const programData = [...this.state.program, ...results.data.data];
          console.log('programData', programData);
          this.setState({
            program: programData
          });
        }
      })
    );    
  };

  fetchEventListData = async eventIds => {
    return Promise.all(
      eventIds.map(async id => {
        const ep = `${process.env.REACT_APP_API}/partners/events/find-event/${id}`;
        const results = await axios.get(ep);

        if (results.data.success) {
          const eventData = [...this.state.event, ...results.data.data];
          console.log('eventData', eventData);
          this.setState({
            event: eventData
          });
        }
      })
    );  
  };

  fetchPartyListData = async partyIds => {
    return Promise.all(
      partyIds.map(async id => {
        const ep = `${process.env.REACT_APP_API}/partners/parties/find-party/${id}`;
        const results = await axios.get(ep);

        if (results.data.success) {
          const partyData = [...this.state.party, ...results.data.data];
          console.log('partyData', partyData);
          this.setState({
            party: partyData
          });
        }
      })
    );  
  };


  fetchOnlineListData = async onlineIds => {
    return Promise.all(
      onlineIds.map(async id => {
        // console.log('id', id);
        const ep = `${process.env.REACT_APP_API}/partners/virtual/find-online/${id}`;
        const results = await axios.get(ep);

        if (results.data.success) {
          const onlineData = [...this.state.online, ...results.data.data];
          console.log('onlineData', onlineData);
          this.setState({
            online: onlineData
          });
        }
      })
    );
  };

  displayAccess = () => {
    const r = this.state.role[0];

    if (r.role === "Administrator" || r.role === "Owner")
      return <span>All</span>;
    const rolesToShow = {};
    Object.keys(r.accesspoints).forEach(e =>
      Object.keys(r.accesspoints[e]).map(
        k =>
          (r.accesspoints[e][k].read || r.accesspoints[e][k].edit) &&
          (rolesToShow[e] = r.accesspoints[e][k])
      )
    );
    const arrResult = Object.keys(rolesToShow);

    return (
      <span>
        {arrResult.map((val, key) =>
          key + 1 < arrResult.length
            ? rolesToShow[val].edit
              ? val + ", "
              : `${val} - Read Only, `
            : rolesToShow[val].edit
            ? val + ""
            : `${val} - Read Only`
        )}
      </span>
    );
  };

  updateIndividualData = async () => {
    let data = {
      receives_emails: this.state.selectedUser.receives_emails,
      role: this.state.updatedRole,
    };

    // Update the user specific listings if they are in one of these pre-defined roles
    // NOTE: "(All)" is denoted in the database with {0} in the list column (since no program will have the id of 0)
    const roleName = this.props.allRoles.filter(e => e.id === this.state.updatedRole)[0].role
    switch (roleName) {
      case "Programs (all)":
        data = {
          ...data,
          programlist: [0],
          eventlist: [],
          partylist: [],
          onlinelist: []
        }
        break;
      case "Events (all)":
        data = {
          ...data,
          programlist: [],
          eventlist: [0],
          partylist: [],
          onlinelist: []
        }
        break;
      case "Online Virtual (all)":
        // Add all online
        data = {
          ...data,
          programlist: [],
          eventlist: [],
          partylist: [],
          onlinelist: [0]
        }
        break;
      case "Parties (all)":
        data = {
          ...data,
          programlist: [],
          eventlist: [],
          partylist: [0],
          onlinelist: []
        }
        break;
      case "Listings (Specific)":
        data = {
          ...data,
          programlist: this.state.program.filter(e => e !== null && e.id !== 0).map(e => e.id),
          eventlist: this.state.event.filter(e => e !== null && e.id !== 0).map(e => e.id),
          partylist: this.state.party.filter(e => e !== null && e.id !== 0).map(e => e.id),
          onlinelist: this.state.online.filter(e => e !== null && e.id !== 0).map(e => e.id)
        }
        break;
      default:  // Administrator and ALL OTHER CUSTOM ROLES should have access to everything
        data = {
          ...data,
          programlist: [0],
          eventlist: [0],
          onlinelist: [0],
          membershiplist: [0],
          partylist: [0]
        }
    }

    const ep = `${process.env.REACT_APP_API}/partners/users/update-user/${this.props.match.params.id}`;
    const results = await axios.post(ep, data);

    if (results.data.success) {
      await this.props.update(results.data);
    }

    const data2 = {
      location: this.state.selectedUser.location
    };
    const ep2 = `${process.env.REACT_APP_API}/partners/users/update-individual/${this.props.match.params.id}`;
    const results2 = await axios.post(ep2, data2);

    if (results2.data.success) {
      await this.props.update(results.data);
      this.setState({
        program: [],
        event: [],
        membership: [],
        online: [],
        party: [],
        role: [],
        updatedRole: null,
        editRole: false,
      }, this.fetchInitialData);
      toast.success(`Saved Info!`);
    }
  };

  render() {
    if (this.state.role.length === 0) {
      return null;
    }

    if (!this.state.updatedRole) {
      return null;
    }

    return (
      <div className="admin">
        {this.state.openListingsModal && (
          <UserListingModal 
            onClose={_ => this.setState({ openListingsModal: false })}
            listingType={this.state.listingType}
            role={this.state.role}
            updateListing={e => {
              this.props.update();
              this.setState({
                program: [],
                event: [],
                online: [],
                party: [],
              })
              if (e.programlist) {
                this.fetchProgramListData(e.programlist);
              }
              if (e.eventlist) {
                this.fetchEventListData(e.eventlist);
              }
              if (e.onlinelist) {
                this.fetchOnlineListData(e.onlinelist);
              }
              if (e.partylist) {
                this.fetchPartyListData(e.partylist);
              }
            }}
            program={this.state.program}
            event={this.state.event}
            party={this.state.party}
            online={this.state.online}
            membership={this.state.membership}
            user={this.state.selectedUser}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="user-task-box">
            <div className="user-nav-line">
              <div className="user-task-header-box">
                <div className="user-access-header">Access</div>
                <div className="user-access-header">Item</div>
              </div>
            </div>

            <div className="user-task-body-box">
              <div className="user-access-item-row">
                <div className="user-access-label color2">Emails</div>
                <div className="user-access-item">
                  <Switch
                    checked={!!this.state.selectedUser.receives_emails}
                    onChange={e =>
                      this.setState({
                        selectedUser: {
                          ...this.state.selectedUser,
                          receives_emails:
                            !this.state.selectedUser.receives_emails
                        }
                      })
                    }
                    color="primary"
                  />
                </div>
              </div>
              <div className="user-access-item-row">
                <div className="user-access-label color3">Address</div>
                <div className="user-access-role-dropdown">
                  <select
                    className="input-fields"
                    value={this.state.selectedUser.location}
                    name="location"
                    onChange={e =>
                      this.setState({
                        selectedUser: {
                          ...this.state.selectedUser,
                          location: e.target.value
                        }
                      })
                    }
                    style={{ width: "100%" }}
                  >
                    <option value="" selected disabled hidden>
                      Please Select
                    </option>
                    {this.locations.map(e => (
                      <option value={e.address}>{e.address}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="user-access-item-row">
                <div className="user-access-label color3">Role</div>
                {!this.state.editRole ? (
                  <div className="user-access-item">
                    {
                      this.state?.allRoles?.filter(
                        e => e.id === this.state?.updatedRole
                      )[0]?.role ? this.state?.allRoles?.filter(
                        e => e.id === this.state?.updatedRole
                      )[0]?.role : this.state.role[0]?.role
                    }{" "}
                    <i
                      className="fas fa-pencil-alt pencil-style"
                      onClick={_ =>
                        this.setState({ editRole: !this.state.editRole })
                      }
                    ></i>
                  </div>
                ) : (
                  <div className="user-access-role-dropdown">
                    <select
                      className="input-fields"
                      value={this.state.updatedRole}
                      name="location"
                      onChange={e =>
                        this.setState({
                          updatedRole: parseInt(e.target.value)
                        })
                      }
                      style={{ width: "90%", marginRight: "15px" }}
                    >
                      <option value="" selected disabled hidden>
                        Please Select
                      </option>
                      {this.state.allRoles.map(e => (
                        <option value={e.id}>{e.role}</option>
                      ))}
                    </select>
                    <i
                      className="fas fa-pencil-alt pencil-style"
                      onClick={_ =>
                        this.setState({ editRole: !this.state.editRole })
                      }
                    ></i>
                  </div>
                )}
              </div>
              <div className="user-access-item-row">
                <div className="user-access-label color1">Access</div>
                <div className="user-access-item">{this.displayAccess()}</div>
              </div>
              <div className="user-access-item-row">
                <div className="user-access-label color2">Listings</div>
                <div className="user-access-item">
                  {!["Programs (all)", "Events (all)", "Online Virtual (all)", "Parties (all)", "Listings (Specific)"]
                    .includes(this.state.role[0].role) ? "All" : (
                    <>
                      {
                        this.state.role[0].role === "Programs (all)" ? (
                          <div>
                            <em>Programs: </em>All
                          </div>
                        ): this.state.role[0].role === "Events (all)" ? (
                          <div>
                            <em>Events: </em>All
                          </div>
                        ) : this.state.role[0].role === "Online Virtual (all)" ? (
                          <div>
                            <em>Online: </em>All
                          </div>
                        ) : this.state.role[0].role === "Parties (all)" ? (
                          <div>
                            <em>Parties: </em>All
                          </div>
                        ) : (
                          <>
                            <div>
                              <em>Programs: </em>{this.state.program.length > 0 ? this.state.program.map((e, i) => this.state.program.length - 1 === i ? e.program_name : `${e.program_name}, `) : "None"} <i onClick={_ => this.setState({ openListingsModal: true, listingType: "Program" })} style={{ marginLeft: "10px" }} className="fas fa-pencil-alt pencil-style" />
                            </div>
                            <div>
                              <em>Online: </em>{this.state.online.length > 0 ? this.state.online.map((e, i) => this.state.online.length - 1 === i ? e.program_name : `${e.program_name}, `) : "None"} <i onClick={_ => this.setState({ openListingsModal: true, listingType: "Online" })} style={{ marginLeft: "10px" }} className="fas fa-pencil-alt pencil-style" />
                            </div>
                            <div>
                              <em>Events: </em>{this.state.event.length > 0 ? this.state.event.map((e, i) => this.state.event.length - 1 === i ? e.event_title : `${e.event_title}, `) : "None"} <i onClick={_ => this.setState({ openListingsModal: true, listingType: "Event" })} style={{ marginLeft: "10px" }} className="fas fa-pencil-alt pencil-style" />
                            </div>
                            <div>
                              <em>Parties: </em>{this.state.party.length > 0 ? this.state.party.map((party, i) => this.state.party.length - 1 === i ? party.party_title : `${party.party_title}, `) : "None"} <i onClick={_ => this.setState({ openListingsModal: true, listingType: "Party"})} style={{ marginLeft: "10px" }} className="fas fa-pencil-alt pencil-style" />
                            </div>
                          </>
                        )
                      }
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="user-save-row">
              <button
                className="user-save-btn"
                onClick={_ => this.updateIndividualData()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AccessDetails);
