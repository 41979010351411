import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";

// const timeRanges = [
//   { name: "Select", value: -1 },
//   { name: "5:00 AM", value: 5 },
//   { name: "5:30 AM", value: 5.5 },
//   { name: "6:00 AM", value: 6 },
//   { name: "6:30 AM", value: 6.5 },
//   { name: "7:00 AM", value: 7 },
//   { name: "7:30 AM", value: 7.5 },
//   { name: "8:00 AM", value: 8 },
//   { name: "8:30 AM", value: 8.5 },
//   { name: "9:00 AM", value: 9 },
//   { name: "9:30 AM", value: 9.5 },
//   { name: "10:00 AM", value: 10 },
//   { name: "10:30 AM", value: 10.5 },
//   { name: "11:00 AM", value: 11 },
//   { name: "11:30 AM", value: 11.5 },
//   { name: "12:00 PM", value: 12 },
//   { name: "12:30 PM", value: 12.5 },
//   { name: "1:00 PM", value: 13 },
//   { name: "1:30 PM", value: 13.5 },
//   { name: "2:00 PM", value: 14 },
//   { name: "2:30 PM", value: 14.5 },
//   { name: "3:00 PM", value: 15 },
//   { name: "3:30 PM", value: 15.5 },
//   { name: "4:00 PM", value: 16 },
//   { name: "4:30 PM", value: 16.5 },
//   { name: "5:00 PM", value: 17 },
//   { name: "5:30 PM", value: 17.5 },
//   { name: "6:00 PM", value: 18 },
//   { name: "6:30 PM", value: 18.5 },
//   { name: "7:00 PM", value: 19 },
//   { name: "7:30 PM", value: 19.5 },
//   { name: "8:00 PM", value: 20 },
//   { name: "8:30 PM", value: 20.5 },
//   { name: "9:00 PM", value: 21 },
//   { name: "9:30 PM", value: 21.5 },
//   { name: "10:00 PM", value: 22 },
//   { name: "10:30 PM", value: 22.5 },
//   { name: "11:00 PM", value: 23 }
// ];
class TotalRecurringModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  requestTour = async e => {
    e.preventDefault();
  };

  getProductName(r) {
    if (
      r.product_table === "partner_programs" ||
      r.product_table === "partner_online"
    ) {
      return {
        name: r.product.program_name,
        type: "Program",
        price:
          r.type === "Recurring"
            ? JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).tuition_rate
            : r.type === "Drop-in"
            ? (JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).total_price /
                JSON.parse(
                  r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
                ).dayCount) *
              r.selectedDays.length *
              (1 +
                parseInt(
                  JSON.parse(
                    r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
                  ).dropin_interest
                ) /
                  100)
            : r.type === "Installment"
            ? JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).total_price *
              (1 +
                parseInt(
                  JSON.parse(
                    r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
                  ).installments_interest
                ) /
                  100)
            : JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).total_price,
        deposit:
          r.type === "Recurring"
            ? JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).deposit
            : null,
        isTaxEnabled: JSON.parse(
          r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
        ).isTaxEnabled
          ? true
          : false,
        taxPercentage:
          JSON.parse(
            r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
          ).isTaxEnabled &&
          JSON.parse(
            r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
          ).taxInfo[0].percentage / 100,
        taxAmount: JSON.parse(
          r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
        ).isTaxEnabled
          ? r.type === "Recurring"
            ? JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).tuition_rate *
              (JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).taxInfo[0].percentage /
                100)
            : r.type === "Drop-in"
            ? (JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).total_price /
                JSON.parse(
                  r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
                ).dayCount) *
              r.selectedDays.length *
              (1 +
                parseInt(
                  JSON.parse(
                    r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
                  ).dropin_interest
                ) /
                  100) *
              (JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).taxInfo[0].percentage /
                100)
            : r.type === "Installment"
            ? JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).total_price *
              (1 +
                parseInt(
                  JSON.parse(
                    r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
                  ).installments_interest
                ) /
                  100) *
              (JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).taxInfo[0].percentage /
                100)
            : JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).total_price *
              (JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).taxInfo[0].percentage /
                100)
          : 0,
        isCreditCareFee: r.product.transaction_cost,
        creditCardFee: r.product.transaction_cost
          ? r.type === "Recurring"
            ? JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).tuition_rate * 0.015 // 1.5 percent
            : r.type === "Drop-in"
            ? (JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).total_price /
                JSON.parse(
                  r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
                ).dayCount) *
              r.selectedDays.length *
              (1 +
                parseInt(
                  JSON.parse(
                    r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
                  ).dropin_interest
                ) /
                  100) *
              0.015
            : r.type === "Installment"
            ? JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).total_price *
              (1 +
                parseInt(
                  JSON.parse(
                    r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
                  ).installments_interest
                ) /
                  100) *
              0.015
            : JSON.parse(
                r.product.plans.filter(e => JSON.parse(e).id == r.planId)[0]
              ).total_price * 0.015
          : 0
      };
    }
    if (r.product_table === "partner_event") {
      return {
        name: r.product.event_title,
        type: "Event",
        price:
          JSON.parse(r.product.tickets[r.planId]).ticket_status === "Free"
            ? 0
            : JSON.parse(r.product.tickets[r.planId]).ticket_price,
        isTaxEnabled: JSON.parse(r.product.tickets[r.planId]).isTaxEnabled
          ? true
          : false,
        taxPercentage:
          JSON.parse(r.product.tickets[r.planId]).isTaxEnabled &&
          JSON.parse(r.product.tickets[r.planId]).taxInfo[0].percentage / 100,
        taxAmount: JSON.parse(r.product.tickets[r.planId]).isTaxEnabled
          ? JSON.parse(r.product.tickets[r.planId]).ticket_price *
            (JSON.parse(r.product.tickets[r.planId]).taxInfo[0].percentage /
              100)
          : 0,
        isCreditCareFee: false
      };
    }
    if (r.product_table === "partner_memberships") {
      return {
        name: r.product.membership_name,
        type: "Membership",
        price: JSON.parse(r.product.prices[r.planId]).price_per_term,
        isTaxEnabled: JSON.parse(r.product.prices[r.planId]).isTaxEnabled
          ? true
          : false,
        taxPercentage:
          JSON.parse(r.product.prices[r.planId]).isTaxEnabled &&
          JSON.parse(r.product.prices[r.planId]).taxInfo[0].percentage / 100,
        taxAmount: JSON.parse(r.product.prices[r.planId]).isTaxEnabled
          ? JSON.parse(r.product.prices[r.planId]).price_per_term *
            (JSON.parse(r.product.tickets[r.planId]).taxInfo[0].percentage /
              100)
          : 0,
        isCreditCareFee: false
      };
    }
  }

  displayCouponAmount = () => {
    if (
      this.props.selectedItem.stripe &&
      this.props.selectedItem.stripe?.total_discount_amounts.length > 0
    ) {
      return parseFloat(
        this.props.selectedItem.stripe?.total_discount_amounts[0].amount / 100
      ).toFixed(2);
    }

    return this.props.selectedItem.coupon.type === "Percentage"
      ? parseFloat(
          this.getProductName(this.props.selectedItem).price *
            (parseFloat(this.props.selectedItem.coupon.amount_off) / 100)
        ).toFixed(2)
      : parseFloat(
          parseFloat(this.props.selectedItem.coupon.amount_off)
        ).toFixed(2);
  };

  render() {
    let stripeTotal;
    let otherTotal;
    if (
      this.props.selectedItem.stripe &&
      this.props.selectedItem.stripe.total
    ) {
      stripeTotal = parseFloat(
        this.props.selectedItem.stripe.total / 100
      ).toFixed(2);
    }
    if (
      this.props.selectedItem.stripe &&
      this.props.selectedItem.stripe.amount
    ) {
      stripeTotal = parseFloat(
        this.props.selectedItem.stripe.amount / 100 -
          this.props.selectedItem.stripe.amount_refunded / 100
      ).toFixed(2);
    }
    if (
      !this.props.selectedItem.stripe &&
      this.props.selectedItem.type !== "Installment"
    ) {
      otherTotal = parseFloat(
        this.getProductName(this.props.selectedItem).price
      ).toFixed(2);
    }
    if (this.props.selectedItem.type === "Installment") {
      otherTotal = parseFloat(
        this.getProductName(this.props.selectedItem).price +
          this.getProductName(this.props.selectedItem).taxAmount
      ).toFixed(2);
    }
    if (
      !this.props.selectedItem.isDeposit &&
      this.props.selectedItem.product.transaction_cost
    ) {
      const tranCost = parseFloat(
        this.getProductName(this.props.selectedItem).price * 0.015
      );

      otherTotal = parseFloat(
        parseInt(this.getProductName(this.props.selectedItem).price) + tranCost
      ).toFixed(2);
    }

    let subTotal;
    if (!this.props.selectedItem.promotionId) {
      subTotal = parseFloat(
        this.getProductName(this.props.selectedItem).price
      ).toFixed(2);
    }

    let promoCreditFee;
    if (this.props.selectedItem.promotionId) {
      if (this.props.selectedItem.promotion.discount_type === "Percentage") {
        const discount = parseInt(
          this.getProductName(this.props.selectedItem).price *
            (this.props.selectedItem.promotion.discount_percent / 100)
        );
        subTotal = parseFloat(
          this.getProductName(this.props.selectedItem).price - discount
        ).toFixed(2);
        const creditAmount =
          parseFloat(
            this.getProductName(this.props.selectedItem).price - discount
          ) * 0.015;

        promoCreditFee = parseFloat(
          (this.getProductName(this.props.selectedItem).price - discount) *
            0.015
        ).toFixed(2);
      } else {
        subTotal = parseFloat(
          parseInt(
            this.getProductName(this.props.selectedItem).price -
              this.props.selectedItem.promotion.discount_fixed / 100
          )
        ).toFixed(2);
      }
    }

    // Coupon Applied
    if (this.props.selectedItem.manual && this.props.selectedItem.coupon) {
      const couponDiscount =
        this.props.selectedItem.coupon.type === "Percentage"
          ? parseFloat(
              this.getProductName(this.props.selectedItem).price *
                (parseFloat(this.props.selectedItem.coupon.amount_off) / 100)
            )
          : parseFloat(parseFloat(this.props.selectedItem.coupon.amount_off));

      otherTotal = parseFloat(parseFloat(otherTotal) - couponDiscount).toFixed(
        2
      );
    }

    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <img
            src={ModalBanner}
            alt="Modal Banner"
            style={{
              height: "40px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>View Details</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form>
              <div>
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "65%", textAlign: "left" }}
                  >
                    Item ({this.getProductName(this.props.selectedItem).name})
                  </label>
                  <span
                    style={{ width: "35%", color: "black", fontSize: "16px" }}
                  >
                    $
                    {parseFloat(
                      this.getProductName(this.props.selectedItem).price
                    ).toFixed(2)}
                  </span>
                </div>

                {this.props.selectedItem.promotionId &&
                  !this.props.selectedItem.isDeposit && (
                    <div
                      className="form-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 0
                      }}
                    >
                      <label
                        htmlFor="displayName"
                        style={{ width: "65%", textAlign: "left" }}
                      >
                        Promo
                      </label>
                      <span
                        style={{
                          width: "35%",
                          color: "black",
                          fontSize: "16px"
                        }}
                      >
                        - $
                        {this.props.selectedItem.promotion.discount_percent
                          ? parseFloat(
                              this.getProductName(this.props.selectedItem)
                                .price *
                                (this.props.selectedItem.promotion
                                  .discount_percent /
                                  100)
                            ).toFixed(2)
                          : parseFloat(
                              this.props.selectedItem.promotion.discount_fixed /
                                100
                            ).toFixed(2)}
                      </span>
                    </div>
                  )}

                {!this.props.selectedItem.isDeposit && (
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "65%", textAlign: "left" }}
                    >
                      Sub Total
                    </label>
                    <span
                      style={{ width: "35%", color: "black", fontSize: "16px" }}
                    >
                      ${subTotal}
                    </span>
                  </div>
                )}

                {this.props.selectedItem.coupon && (
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "65%", textAlign: "left" }}
                    >
                      Coupon Applied
                    </label>
                    <span
                      style={{
                        width: "35%",
                        color: "black",
                        fontSize: "16px"
                      }}
                    >
                      - ${this.displayCouponAmount()}
                    </span>
                  </div>
                )}

                {this.props.selectedItem.charge &&
                  this.props.selectedItem.charge.amount_refunded !== 0 &&
                  !this.props.selectedItem.isDeposit && (
                    <div
                      className="form-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 0
                      }}
                    >
                      <label
                        htmlFor="displayName"
                        style={{ width: "65%", textAlign: "left" }}
                      >
                        Refunded
                      </label>
                      <span
                        style={{
                          width: "35%",
                          color: "black",
                          fontSize: "16px"
                        }}
                      >
                        {this.props.selectedItem.charge.amount_refunded !== 0
                          ? `$${parseFloat(
                              this.props.selectedItem.charge.amount_refunded /
                                100
                            ).toFixed(2)}`
                          : ""}
                      </span>
                    </div>
                  )}

                {this.getProductName(this.props.selectedItem).isTaxEnabled &&
                  !this.props.selectedItem.isDeposit && (
                    <div
                      className="form-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 0
                      }}
                    >
                      <label
                        htmlFor="displayName"
                        style={{ width: "65%", textAlign: "left" }}
                      >
                        Tax
                      </label>
                      <span
                        style={{
                          width: "35%",
                          color: "black",
                          fontSize: "16px"
                        }}
                      >
                        $
                        {parseFloat(
                          subTotal *
                            this.getProductName(this.props.selectedItem)
                              .taxPercentage
                        ).toFixed(2)}
                      </span>
                    </div>
                  )}

                {this.getProductName(this.props.selectedItem).isCreditCareFee &&
                  !this.props.selectedItem.isDeposit && (
                    <div
                      className="form-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 0
                      }}
                    >
                      <label
                        htmlFor="displayName"
                        style={{ width: "65%", textAlign: "left" }}
                      >
                        Credit Card Fee
                      </label>
                      <span
                        style={{
                          width: "35%",
                          color: "black",
                          fontSize: "16px"
                        }}
                      >
                        $
                        {!this.props.selectedItem.promotionId
                          ? parseFloat(
                              this.getProductName(this.props.selectedItem)
                                .creditCardFee
                            ).toFixed(2)
                          : promoCreditFee}
                      </span>
                    </div>
                  )}
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "65%", textAlign: "left" }}
                  >
                    Total{" "}
                    {!this.props.selectedItem.isDeposit ? "" : "(Deposit)"}
                  </label>
                  <span
                    style={{ width: "35%", color: "black", fontSize: "16px" }}
                  >
                    ${this.props.selectedItem.stripe ? stripeTotal : otherTotal}
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TotalRecurringModal);
