import React from "reactn";
import { Table } from "../../components/UI";
import axios from "axios";

class Documents extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };

    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "Resources"]
    });
    this.headings = [
      { id: "name", label: "Document" },
      { id: "action", label: "", customStyle: { width: 180 } }
    ];
    this.actions = [{ name: "VIEW", action: this.viewDocument }];
  }

  viewDocument(row) {
    window.open(`${process.env.REACT_APP_DS_FILES_S3}/${row.file}`);
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/edu/document/active`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setState({ data: res.data.data });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="cont">
          <h1>Resources</h1>
        </div>
        <div className="cont">
          <Table
            headings={this.headings}
            data={this.state.data}
            actions={this.actions}
          />
        </div>
      </div>
    );
  }
}
export default Documents;
