import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { formTypes } from "../UI/form";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import {
  DROPDOWN,
  STRING_INPUT,
  DATE_PICKER,
  GET_HTML,
  SELECT_MULTIPLE_DROPDOWN
} from "./baseModal/FieldTypes";
import { notEmptyString } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";




class EditResourcesModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      programs: [],
      online: [],
      users: [],
      users_picked: [],
      programs_picked: [],
      online_picked: [],
      type: ""
    };
  }

  async componentDidMount() {
    // Fetch all program, online, and user data.
    const ep = `${process.env.REACT_APP_API}/partners/programs/list`;
    const ep2 = `${process.env.REACT_APP_API}/partners/roles`;
    const ep3 = `${process.env.REACT_APP_API}/partners/virtual/list`;
    const results = await axios.get(ep);
    const users = await axios.get(ep2);
    const online = await axios.get(ep3);

    // Load the existing module data from props.
    const module = this.props.module;

    /*     const programs_picked = module.programschosen.map(program => {
      return { value: program.id, name: program.program_name };
    });
    const online_picked = module.onlinechosen.map(online => {
      return { value: online.id, name: online.program_name };
    });
    const users_picked = module.userschosen.map(user => {
      return { value: user.id, name: user.username };
    }); */

    // ===== New Modal ===== //
    const programs_picked = module.programschosen.map(program => {
      return { value: program.id, name: program.program_name };
    });
    const online_picked = module.onlinechosen.map(online => {
      return { value: online.id, name: online.program_name };
    });
    const users_picked = module.userschosen.map(user => {
      return { value: user.id, name: user.username };
    });
    // ===== END New Modal ===== //


    this.setState({
      module: module.module,
      programs_picked: programs_picked,
      online_picked: online_picked,
      users_picked: users_picked,
      type: module.resource_type
    });

    if (results.data.success) {
      this.setState({
        programs: results.data.data.filter(f => !f.isArchived),
        users: users.data.data.users,
        online: online.data.data.filter(f => !f.isArchived)
      });
    }
  }
  async addNewResource(e) {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/partners/resources/save`;
    const obj = {
      module: this.state.module,
      access: this.state.users_picked.map(e => e.value),
      programs: this.state.programs_picked.map(e => e.value),
      online: this.state.online_picked.map(e => e.value),
      resource_type: this.state.type,
      id: this.props.module.id
    };
    const result = await axios.post(ep, obj);
    if (result.data.success) {
      this.props.onSave();
      toast.success(`Module saved!`);
      this.props.onClose();
    }
  }

  /// ================== NEW MODAL ========================== ///


  getallContent = () => {
    console.log("this.state.programs_picked",this.state.programs_picked)
    console.log("this.state.online_picked", this.state.online_picked)
    return (
      <div>
        {this.state.type === "Program" && (
          <div>
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Program
            </label>
            <div
              style={{
                width: "100%",
                marginBottom: "22px"
              }}
            >
              <formTypes.UserDropDown
                value={this.state.programs_picked.filter(f => f.value && f.name)}
                options={[
                  ...this.state.programs.map(u => ({
                    name: u.program_name,
                    value: u.id
                  }))
                ]}
                onChange={(_, v) =>
                  this.setState({
                    programs_picked: v
                  })
                }
              />
            </div>
          </div>
        )}
        {this.state.type === "Online" && (
          <div>
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Online
            </label>
            <div
              style={{
                width: "100%",
                marginBottom: "22px"
              }}
            >
              <formTypes.UserDropDown
                value={this.state.online_picked.filter(f => f.value && f.name)}
                options={[
                  ...this.state.online.map(u => ({
                    name: u.program_name,
                    value: u.id
                  }))
                ]}
                onChange={(_, v) =>
                  this.setState({
                    online_picked: v
                  })
                }
              />
            </div>
          </div>
        )}
        <div>
          <label
            htmlFor="displayName"
            style={{
              width: "25%",
              textAlign: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            Access
          </label>
          <div
            style={{
              width: "100%"
            }}
          >
            <formTypes.UserDropDown
              value={this.state.users_picked}
              options={[
                ...this.state.users.filter(f => f.sub_user).map(u => ({
                  name: u.username,
                  value: u.id
                }))
              ]}
              onChange={(_, v) =>
                this.setState({
                  users_picked: v
                })
              }
            />
          </div>
        </div>
      </div>
    );
  };

  programOptions = () => {
    return [
      ...this.state.programs.map(({ id, program_name }) => ({
        value: id,
        text: program_name
      }))
    ];
  };

  onlineOptions = () => {
    return [
      ...this.state.online.map(({ id, program_name }) => ({
        value: id,
        text: program_name
      }))
    ];
  };

  accessOptions = () => {
    return [
      ...this.state.users
        .filter(f => f.id != f.partner_id)
        .map(({ id, username }) => ({
          value: id,
          text: username
        }))
    ];
  };

  getBaseModalFields() {
    const descriptionInput = {
      type: STRING_INPUT,
      data: {
        name: "Module Name",
        required: true,
        placeholder: "Module's name...",
        handleChange: e => {
          this.setState({
            module: e.target.value
          });
        },
        value: this.state.module
      },
      validators: { validateSubmit: notEmptyString(this.state.module) }
    };

    const typeInput = {
      type: DROPDOWN,
      data: {
        name: "Type",
        required: false,
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            type: e.currentTarget.value
          });
        },
        choices: [
          { value: "Program", text: "Program" },
          { value: "Online", text: "Online" }
        ]
      }
    };

    const getContentInput = {
      type: GET_HTML,
      data: {
        gethtml: this.getallContent
      }
    };

    /*       const accessInput = {
        type: SELECT_MULTIPLE_DROPDOWN,
        data: {     
          name: "Access",
          required: false,
          placeholder: "Please Select",
          value: this.state.users_picked,
          handleChange: e => {
            this.setState({
              users_picked: e
            });
          },
          choices: this.accessOptions()
        }
      };
      
      
       if (this.state.type === "Program") {
        const programtypeInput = {
          type: SELECT_MULTIPLE_DROPDOWN,
          data: {
            name: "Program",
            required: false,
            placeholder: "Please Select",
            value: this.state.programs_picked,
            handleChange: e =>
            this.setState({
              programs_picked: e
            }),
            choices: this.programOptions()
          }
          
        };
        return [descriptionInput, typeInput, programtypeInput, accessInput];
      } else if (this.state.type === "Online") {
        const programtypeInput = {
          type: SELECT_MULTIPLE_DROPDOWN,
          data: {
            name: "Online",
            required: false,
            placeholder: "Please Select",
            value: this.state.online_picked,
            handleChange: e =>
            this.setState({
              online_picked: e
            }),
            choices: this.onlineOptions()
          },
          
        };
        return [descriptionInput, typeInput, programtypeInput, accessInput];
      } */

    const fields = [];
    fields.push(descriptionInput);
    fields.push(typeInput);
    fields.push(getContentInput);

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Save",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.addNewResource(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Edit Resource Module",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ===================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <ModalTemplate
        heading="Edit Resource Module"
        onClose={this.props.onClose}
      >
        <form onSubmit={e => this.addNewResource(e)}>
          <div className="modal-main">
            <ModalFields.input
              label="Module Name"
              name="module"
              required={true}
              type="text"
              placeholder="Module's name..."
              onChange={e => {
                this.setState({
                  module: e.target.value
                });
              }}
              value={this.state.module}
            />
            <ModalFields.custom label="Type" name="type">
              <select
                onChange={e => this.setState({ type: e.currentTarget.value })}
                id="type"
                className="form-control"
                style={{ width: "70%" }}
                value={this.state.type}
              >
                <option value="" selected disabled hidden>
                  Choose here
                </option>
                <option value="Program">Program</option>
                <option value="Online">Online</option>
              </select>
            </ModalFields.custom>
            {this.state.type === "Program" && (
              <ModalFields.custom label="Program" name="program">
                <div style={{ width: "70%" }}>
                  <formTypes.UserDropDown
                    value={this.state.programs_picked}
                    options={[
                      ...this.state.programs.map(u => ({
                        name: u.program_name,
                        value: u.id
                      }))
                    ]}
                    onChange={(_, v) =>
                      this.setState({
                        programs_picked: v
                      })
                    }
                  />
                </div>
              </ModalFields.custom>
            )}
            {this.state.type === "Online" && (
              <ModalFields.custom label="Online" name="online">
                <div style={{ width: "70%" }}>
                  <formTypes.UserDropDown
                    value={this.state.online_picked}
                    options={[
                      ...this.state.online.map(u => ({
                        name: u.program_name,
                        value: u.id
                      }))
                    ]}
                    onChange={(_, v) =>
                      this.setState({
                        online_picked: v
                      })
                    }
                  />
                </div>
              </ModalFields.custom>
            )}
            <ModalFields.custom label="Access" name="access">
              <div style={{ width: "70%" }}>
                <formTypes.UserDropDown
                  value={this.state.users_picked}
                  options={[
                    ...this.state.users.map(u => ({
                      name: u.username,
                      value: u.id
                    }))
                  ]}
                  onChange={(_, v) =>
                    this.setState({
                      users_picked: v
                    })
                  }
                />
              </div>
            </ModalFields.custom>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn">
              Save
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(EditResourcesModal);
