import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import BaseModal from "./baseModal/BaseModal";
import {
  GET_HTML
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class WelcomePreviewModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      program: {}
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/welcome/${this.props.table}/${this.props.match.params.id}`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setGlobal({
        loading: false,
        lastAPICall: res
      });
      this.setState({
        data: { ...res.data.data.data },
        program: { ...res.data.data.program }
      });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  addnewmodal = () => {
    return (
      <div>
      <div
        className="w-100"
        style={{
          minHeight: 240,
          backgroundImage: `url(${`${
            process.env.REACT_APP_DS_FILES_S3
          }/${
            this.state.data.image || this.state.program.program_photo
          }`})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundColor: "rgb(116 116 116 / 60%)",
          backgroundBlendMode: "multiply",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div
          style={{
            color: "white",
            fontSize: "30px",
            fontWeight: "bold",
            fontFamily: "futura-pt, sans-serif"
          }}
        >
          {this.state.data.title || this.state.program.program_name}
        </div>
      </div>
      <div
        className="cont mb-5"
        style={{ position: "relative", marginTop: "-100px" }}
      >
        <div className="row">
          <div className="w-100">
            <div
              className="w-100 p-3 bg-white"
              style={{
                borderRadius: "0px",
                overflow: "hidden",
                marginTop: 125
              }}
            >
              <h2
                className="text-center"
                style={{
                  color: "#2938be",
                  fontFamily: "futura-pt, sans-serif",
                  fontWeight: 700
                }}
              >
                Welcome Note
              </h2>
              <hr />
              <p
                style={{
                  fontFamily: "futura-pt, sans-serif",
                  fontSize: "16px",
                  lineHeight: "20px"
                }}
                className="mt-3"
              >
                {this.state.data.welcome ||
                  this.state.program.description}
              </p>
            </div>
            <div
              className="w-100 p-3 bg-white"
              style={{
                borderRadius: "0px",
                overflow: "hidden",
                marginTop: 20
              }}
            >
              <h2
                className="text-center"
                style={{
                  color: "#2938be",
                  fontFamily: "futura-pt, sans-serif",
                  fontWeight: 700
                }}
              >
                Navigation Tips
              </h2>
              <hr />
              <p
                style={{
                  fontFamily: "futura-pt, sans-serif",
                  fontSize: "16px",
                  lineHeight: "20px"
                }}
                className="mt-3"
              >
                {this.state.data.navigation
                  ? this.state.data.navigation
                      .split("\n")
                      .map(e => <p>{e}</p>)
                  : "Navigation Tips not provided!"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }

  getBaseModalFields() {
    const newClient = {
      type: GET_HTML,
      data: {
        styles: {overflow: "scroll", height: "550px"},
        gethtml: this.addnewmodal
      }
    };

    const fields = [];
    fields.push(newClient);

    return fields;
  }

  getBaseModalButtons = () => {

  };

  getBaseModalProps = () => {
    return {
      title: "Preview",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      height: "742px",
      width: "800px"
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()}/>
    return (
      <div className="request-modal-cont">
        <div
          className="request-modal lesspadding"
          style={{ maxWidth: "800px", maxHeight: "645px" }}
        >
          <img
            src={ModalBanner}
            alt="Modal Banner"
            style={{
              height: "40px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>Preview</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>

            <div>
              <div
                className="w-100"
                style={{
                  minHeight: 240,
                  backgroundImage: `url(${`${
                    process.env.REACT_APP_DS_FILES_S3
                  }/${
                    this.state.data.image || this.state.program.program_photo
                  }`})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundColor: "rgb(116 116 116 / 60%)",
                  backgroundBlendMode: "multiply",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontSize: "30px",
                    fontWeight: "bold"
                  }}
                >
                  {this.state.data.title || this.state.program.program_name}
                </div>
              </div>
              <div
                className="cont mb-5"
                style={{ position: "relative", marginTop: "-100px" }}
              >
                <div className="row">
                  <div className="w-100">
                    <div
                      className="w-100 p-3 bg-white"
                      style={{
                        borderRadius: "8px",
                        overflow: "hidden",
                        marginTop: 125
                      }}
                    >
                      <h2
                        className="text-center"
                        style={{
                          color: "#232795",
                          fontFamily: "Open Sans",
                          fontWeight: 700
                        }}
                      >
                        Welcome Note
                      </h2>
                      <hr />
                      <p
                        style={{
                          fontFamily: "Open Sans",
                          fontSize: "16px",
                          lineHeight: "20px"
                        }}
                        className="mt-3"
                      >
                        {this.state.data.welcome ||
                          this.state.program.description}
                      </p>
                    </div>
                    <div
                      className="w-100 p-3 bg-white"
                      style={{
                        borderRadius: "8px",
                        overflow: "hidden",
                        marginTop: 20
                      }}
                    >
                      <h2
                        className="text-center"
                        style={{
                          color: "#232795",
                          fontFamily: "Open Sans",
                          fontWeight: 700
                        }}
                      >
                        Navigation Tips
                      </h2>
                      <hr />
                      <p
                        style={{
                          fontFamily: "Open Sans",
                          fontSize: "16px",
                          lineHeight: "20px"
                        }}
                        className="mt-3"
                      >
                        {this.state.data.navigation
                          ? this.state.data.navigation
                              .split("\n")
                              .map(e => <p>{e}</p>)
                          : "Navigation Tips not provided!"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(WelcomePreviewModal);
