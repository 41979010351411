import React from "reactn";
import { withRouter } from "react-router-dom";
import { TabbedTable } from "../../UI";
import { format } from "date-fns";
import axios from "axios";

class ApplicationsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        "PENDING RESPONSE": [],
        ACCEPTED: [],
        WAITLISTED: [],
        DECLINED: []
      }
    };
    this.tabs = ["PENDING RESPONSE", "ACCEPTED", "WAITLISTED", "DECLINED"];

    this.headings = {
      "PENDING RESPONSE": [
        { id: "programName", label: "Program" },
        { id: "fullName", label: "Child" },
        { id: "plan", label: `Plan`, customCell: this.planCell },
        { id: "duration", label: "Duration", customCell: this.durationCell },
        {
          id: "submittedDate",
          label: "Application Date",
          customCell: this.applicationDateCell
        },
        { id: "action", label: "" }
      ],
      ACCEPTED: [
        { id: "programName", label: "Program" },
        { id: "fullName", label: "Child" },
        { id: "numDays", label: `Plan` },
        { id: "duration", label: "Duration", customCell: this.durationCell },
        {
          id: "acceptedDate",
          label: "Accepted Date",
          customCell: this.acceptedDateCell
        },
        {
          id: "viewDeposit",
          label: "View Acceptance",
          customCell: this.viewDepositCell
        },
        { id: "action", label: "" }
      ],
      WAITLISTED: [
        { id: "programName", label: "Program" },
        { id: "fullName", label: "Child" },
        { id: "numDays", label: `Plan` },
        { id: "duration", label: "Duration", customCell: this.durationCell },
        {
          id: "submittedDate",
          label: "Application Date",
          customCell: this.applicationDateCell
        },
        { id: "educatorMessage", label: "Educator Message" },
        { id: "action", label: "" }
      ],
      DECLINED: [
        { id: "programName", label: "Program" },
        { id: "fullName", label: "Child" },
        { id: "numDays", label: `Plan` },
        { id: "duration", label: "Duration", customCell: this.durationCell },
        {
          id: "rejectedDate",
          label: "Rejected Date",
          customCell: this.rejectedDateCell
        },
        { id: "educatorMessage", label: "Reason" },
        { id: "action", label: "" }
      ]
    };

    this.actions = [{ name: "VIEW", action: this.viewApplication }];
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/par/applications`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      const accApp = res.data.data.filter(d => {
        return d.educatorResponse === "Accepted";
      });
      const rejApp = res.data.data.filter(d => {
        return d.educatorResponse === "Rejected";
      });
      const waitApp = res.data.data.filter(d => {
        return d.educatorResponse === "Waitlisted";
      });
      const pendApp = res.data.data.filter(d => {
        return d.educatorResponse === "New" && d.submittedDate;
      });
      this.setState({
        data: {
          ACCEPTED: accApp,
          DECLINED: rejApp,
          WAITLISTED: waitApp,
          "PENDING RESPONSE": pendApp
        }
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  viewDepositCell = (r, i) => {
    return (
      <td key={`${i}-dc`}>
        <button onClick={() => this.viewDeposit(r)}>VIEW ACCEPTANCE</button>
      </td>
    );
  };

  viewDeposit = row => {
    this.props.history.push(
      `/parents/acceptance/${row.slug}/${row.submittedDate}`
    );
  };

  viewApplication = row => {
    this.props.history.push(
      `/parents/applications/${row.slug}/${row.submittedDate}`
    );
  };

  applicationDateCell = (r, i) => {
    return (
      <td key={`${i}-ad`}>
        {format(new Date(r.submittedDate), "LLL dd, yyyy")}
      </td>
    );
  };

  rejectedDateCell = (r, i) => {
    return (
      <td key={`${i}-rd`}>{format(new Date(r.updatedAt), "LLL dd, yyyy")}</td>
    );
  };

  acceptedDateCell = (r, i) => {
    return (
      <td key={`${i}-acd`}>
        {format(new Date(r.acceptedDate), "LLL dd, yyyy")}
      </td>
    );
  };

  durationCell = (r, i) => {
    return (
      <td key={`${i}-dur`}>
        {format(new Date(r.startDate), "LLL dd, yyyy")} -{" "}
        {r.endDate ? format(new Date(r.endDate), "LLL dd, yyyy") : "N/A"}
      </td>
    );
  };

  planCell = (r, i) => {
    return (
      <td key={`${i}-pl`}>
        {r.categoryName}
        <br />
        {r.numDays} days/week
      </td>
    );
  };

  render() {
    return (
      <TabbedTable
        tabs={this.tabs}
        headings={this.headings}
        actions={this.actions}
        data={this.state.data}
      />
    );
  }
}

export default withRouter(ApplicationsTable);
