import React from "reactn";
import { StepIndicator } from "../../../components/UI";
import Setup from "./Setup";
import AdditionalDetails from "./AdditionalDetails";
import Customization from "./Customization";
import Checkout from "./Checkout";
import StepBar from "../../../components/UI/StepBar";
import Validators from "../../../validators";
import PricingPlan from "../../../components/modals/PickPlanModal";
import { withRouter } from "react-router-dom";
import "../../../assets/css/componentSpecificCss/eventListings.css";
import { canUserCreate } from "../../../utilities/listingCreation";
import axios from "axios";

class ProgramStructure extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      step: 0,
      plan: false,
      canUserMakeNewProgram: false,
      makeProgramReqMessage: ""
    };
    this.titles = ["Program Detail", "Schedule", "Program Plans", "Checkout"];
    this.validate = [
      () =>
        Validators.Partners.Profile.ValidateProgramsOne(this.global.program) &&
        Validators.Partners.Profile.ValidateProgramsTwo(this.global.program),
      () =>
        Validators.Partners.Profile.ValidateProgramsThree(this.global.program),
      () =>
        Validators.Partners.Profile.ValidateProgramsFour(this.global.program),
      () =>
        Validators.Partners.Profile.ValidateProgramsFive(this.global.program)
    ];
    this.steps = [
      { name: "Program Detail", validateFn: this.validate[0] },
      { name: "Schedule", validateFn: this.validate[1] },
      { name: "Program Plans", validateFn: this.validate[2] },
      { name: "Checkout", validateFn: this.validate[3] }
    ];
  }

  async componentDidMount() {
    const resultMake = await canUserCreate("program");
    const makeProgramReqMessage = resultMake.message;
    this.setState({
      canUserMakeNewProgram: resultMake.success,
      makeProgramReqMessage
    });
    if (this.props.match.params.id) {
      await this.dispatch.getProgram(this.props.match.params.id);
    } else {
      //commenting below line to enable program_category linking functionality
      // await this.dispatch.resetEvent();
    }

    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Programs", to: "/programs/list" },
        {
          label: this.global.program.program_name || "Program Setup",
          to: this.props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
        }
      ]
    });
  }

  /**
   * To be run before saving. If any page is not setup correctly, it will unpublish the page.
   */
  ensureValidPublish = () => {
    // If the page is currently published
    if (this.global.program.published) {
      // Check validity of all pages
      for (let i = 0; i < this.steps.length; i++) {
        if (this.checkValid(i)) {
          // If any page is invalid, set publish to false
          this.setGlobal({
            program: {
              ...this.global.program,
              published: false
            }
          });
          break;
        }
      }
    }
  }

  saveProfile = async e => {
    const { step } = this.state;

    this.ensureValidPublish();

    switch (e.target.name) {
      case "next":
        this.setState({ step: step + 1 });
        await this.dispatch.saveProgram();
        break;
      case "back":
        this.setState({ step: step - 1 });
        await this.dispatch.saveProgram();
        break;
      case "save":
        await this.dispatch.saveProgram();
        break;
      default:
        return;
    }
  };

  changePage = async page => {
    this.ensureValidPublish();

    if (!this.checkValid()) {
      // Only allow the user to progress to the earliest invalid page
      for (let i = 0; i < page; i++) {
        if (this.checkValid(i)) {
          return; // Prevent the page from changing
        }
      }
      await this.dispatch.saveProgram();
      this.setState({ step: page });
    } else if (page < this.state.step) { // Allow the user to backtrack to previous pages
      await this.dispatch.saveProgram();
      this.setState({ step: page });
    }
  };

  getPage = () => {
    switch (this.state.step) {
      case 0:
        return (
          <Setup
            initialProgramCategory={
              this.props.location.state
                ? this.props.location.state.selectedProgramCategory
                : this.global.program.program_category
            }
            initialAddChild={
              this.props.location.state
                ? this.props.location.state.addChild
                : this.global.program.addChild
            }
            initialManualInvoices={
              this.props.location.state
                ? this.props.location.state.manualInvoices
                : this.global.program.manual_invoices
            }
            initialApplicationRequired={
              this.props.location.state
                ? this.props.location.state.applicationRequired
                : this.global.program.application_required
            }
            initialTransactionCost={
              this.props.location.state
                ? this.props.location.state.transactionCost
                : this.global.program.transaction_cost
            }
            initialAutoWithdraw={
              this.props.location.state
                ? this.props.location.state.isRecurring && this.props.location.state.autoWithdraw
                : this.global.program.autoWithdraw
            }
            initialIsRecurring={
              this.props.location.state
                ? this.props.location.state.isRecurring
                : this.global.program.isRecurring
            }
            allowSameEnrollment={
              this.props.location.state
                ? this.props.location.state.allowSameEnrollment
                : this.global.program.allowSameEnrollment
            }
          />
        );
      case 1:
        return <AdditionalDetails />;
      case 2:
        return <Customization openModal={_ => this.setState({ plan: true })} 
          addChild={this.props.location.state && Object.keys(this.props.location.state).length > 0
            ? this.props.location.state.addChild
            : this.global.program.addChild} />;
      default:
        return <Checkout />;
    }
  };

  checkValid(step = this.state.step) {
    return this.validate[step] && !this.validate[step]();
  }

  getCompletion() {
    let completed = 0;
    for (let v of this.validate) {
      completed += v();
    }

    return (completed / this.validate.length) * 100;
  }

  getCompletionStage() {
    let completed = 0;
    for (let v of this.validate) {
      completed += v();
    }

    return completed;
  }

  render() {
    if (!this.state.canUserMakeNewProgram && !this.props.match.params.id) {
      return (
        <div className="container-fluid">
          {/* Commented out to make space for Top Bar */}
          {/* <div className="cont">
            <h1 className="events-title-header">Program Structure</h1>
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "50vh",
              alignItems: "center"
            }}
          >
            <p>{this.state.makeProgramReqMessage}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="container-fluid">
        {/* Commented out to make space for Top Bar */}
        {/* <div className="cont">
          <h1 className="events-title-header">Program Structure</h1>
        </div> */}
        {this.state.plan && (
          <PricingPlan onClose={_ => this.setState({ plan: false })} 
            addChild={
              this.props.location.state && Object.keys(this.props.location.state).length > 0
                ? this.props.location.state.addChild
                : this.global.program.addChild} />
        )}
        <StepBar
          {...{
            steps: this.steps,
            changeFn: this.changePage,
            current: this.state.step
          }}
        />
        <StepIndicator value={Math.round(this.getCompletion())} />
        {this.getPage()}
        <div id={this.state.step === 2 ? "buttons-wrapper" : ""} className="cont mb-5">
          <div
            className="buttons w-100"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: "10px",
              marginBottom: "0px"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "60%",
                flexGrow: "1"
              }}
            >
              <div
                name="back"
                disabled={this.state.step === 0}
                onClick={e => {
                  e.target.name = "back";
                  if (this.state.step === 0) return;
                  this.saveProfile(e);
                }}
              >
                <i
                  name="back"
                  style={{ paddingRight: "24px" }}
                  className={`fas fa-chevron-left ${
                    this.state.step === 0
                      ? "listing-step-btn-inactive"
                      : "listing-step-btn-active"
                  }`}
                ></i>
              </div>
              <div
                name="next"
                onClick={e => {
                  e.target.name = "next";
                  if (
                    this.state.step === this.titles.length - 1 ||
                    this.checkValid()
                  )
                    return;
                  this.saveProfile(e);
                }}
              >
                <i
                  name="next"
                  style={{ paddingLeft: "24px" }}
                  className={`fas fa-chevron-right ${
                    this.state.step === this.titles.length - 1 ||
                    this.checkValid()
                      ? "listing-step-btn-inactive"
                      : "listing-step-btn-active"
                  }`}
                ></i>
              </div>
            </div>
            {/* <button
              onClick={this.saveProfile}
              disabled={this.state.step === 0}
              name="back"
              className="nextbtn"
            >
              BACK
            </button> */}
            {/* <i className="fas fa-chevron-circle-left"></i>
            <i className="fas fa-chevron-circle-right"></i> */}

            <div
              style={{
                width: "fit-content",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              }}
            >
              {this.state.step === this.titles.length - 1 && (
                <button
                  onClick={_ => {
                    this.setGlobal(
                      {
                        program: {
                          ...this.global.program,
                          published: !this.global.program.published
                        }
                      },
                      _ => this.dispatch.saveProgram()
                    );
                  }}
                  disabled={
                    // this.state.step === this.titles.length - 1 ||
                    this.checkValid() || this.getCompletionStage() !== 4
                  }
                  name="next"
                  className={
                    this.checkValid() || this.getCompletionStage() !== 4
                      ? "nextbtnDisabled"
                      : "nextbtn"
                  }
                >
                  {this.global.program.published ? "Unpublish" : "Publish"}
                </button>
              )}

              <button
                onClick={this.saveProfile}
                disabled={this.checkValid()}
                name="save"
                className="savebtn"
                style={{ background: "#b2b8c7", border: "1px solid #b2b8c7" }}
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ProgramStructure);
