import React from "reactn";

const ExpenseOverviewTable = ({ expenses }) => {
  return (
    <div className="money-box mt-0">
      <div className="row">
        <div className="col-lg-12 col-sm-6">
          <div className="money-box-inner mb-4">
            <h2>TOTAL MONTH SPENDING</h2>
            <h3>
              $
              {
              expenses.totalMonthly
                ? parseFloat(expenses.totalMonthly).toLocaleString("en-CA",{minimumFractionDigits:2,maximumFractionDigits:2})
                : parseFloat(0.0).toFixed(2)}
            </h3>
          </div>
        </div>
        <div className="col-lg-12 col-sm-6">
          <div className="money-box-inner mb-4">
            <h2>
              YEARLY SPENDING
              {/* ({format(subYears(new Date(), 1), "LLL d, yyyy")}{" "}
              - {format(new Date(), "LLL d, yyyy")}) */}
            </h2>
            <h3>
              $
              {expenses.yearlySpending
                ? parseFloat(expenses.yearlySpending).toLocaleString("en-CA",{minimumFractionDigits:2,maximumFractionDigits:2})
                : parseFloat(0.0).toFixed(2)}
            </h3>
          </div>
        </div>
        {/* {<div className="col-lg-12 col-sm-6">
          <div className="money-box-inner mb-4">
            <h2>AVERAGE MONTHLY SPENDING</h2>
            <h3>
              $
              {expenses.averageMonthly
                ? parseFloat(expenses.averageMonthly).toLocaleString("en-CA",{minimumFractionDigits:2,maximumFractionDigits:2})
                : parseFloat(0.0).toFixed(2)}
            </h3>
          </div>
        </div>} */}
        <div className="col-lg-12 col-sm-6">
          <div className="money-box-inner mb-4">
            <h2>YEAR-TO-DATE TOTAL</h2>
            <h3>
              $
              {expenses.ytdTotal
                ? parseFloat(expenses.ytdTotal).toLocaleString("en-CA",{minimumFractionDigits:2,maximumFractionDigits:2})
                : parseFloat(0.0).toFixed(2)}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseOverviewTable;
