import React from "react";
import { DdIcon } from "../../../assets";

class MyProgram extends React.PureComponent {
  render() {
    const { enrollments, name } = this.props;
    return (
      <div className="col-xl-6 d-flex">
        <div className="tablebox">
          <div className="tableheader">
            <h2>
              MY PROGRAM <img src={DdIcon} alt="" />
            </h2>
            {/* <ul className="leftlist">
                  <li>
                    <Link to="#/" className="active">
                      Details
                    </Link>
                  </li>
                  <li>
                    <a href="#/">Slots</a>
                  </li>
                </ul> */}
          </div>
          <div className="tablebody padded text-center">
            <div className="row">
              <div className="col-md-12">
                <div style={{ height: 118 }}>
                  <div
                    style={{
                      backgroundImage: `url(${`${process.env.REACT_APP_DS_FILES_S3}/${this.props.gallery.programCoverPhoto}`})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      padding: "40px 0",
                      borderRadius: "8px"
                    }}
                  >
                    <h2
                      style={{
                        width: "100%",
                        background: "rgba(0, 0, 0, 0.6)",
                        color: "white",
                        textAlign: "center"
                      }}
                      className="m-0"
                    >
                      {name}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row bl-bbody mt-4 mb-2">
              <div className="col-6">
                <p className="text-center">Active Students:</p>
              </div>
              <div className="col-6">
                <p className="text-center">{enrollments.activeTotal || 0}</p>
              </div>
            </div>
            <div className="row bl-bbody mb-2">
              <div className="col-6">
                <p className="text-center">Pending Students:</p>
              </div>
              <div className="col-6">
                <p className="text-center">{enrollments.pendingTotal || 0}</p>
              </div>
            </div>
            <div className="row bl-bbody mb-2">
              <div className="col-6">
                <p className="text-center">Waitlisted Students:</p>
              </div>
              <div className="col-6">
                <p className="text-center">{enrollments.waitlistTotal || 0}</p>
              </div>
            </div>
            <div className="row bl-bbody mb-2">
              <div className="col-6">
                <p className="text-center">Past Students:</p>
              </div>
              <div className="col-6">
                <p className="text-center">{enrollments.pastTotal || 0}</p>
              </div>
            </div>
            {/* <div className="row mt-4">
              <div className="col-6">
                <p className="text-center">Average Income:</p>
              </div>
              <div className="col-6"><p className="text-center">$5000 / month</div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
export default MyProgram;
