import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { Delete, SettingsBackupRestore as Restore } from "@material-ui/icons";
import Switch from "@material-ui/core/Switch";
import LinkIcon from "@material-ui/icons/Link";
import AddIframeModal from "../../components/modals/AddIframeModal";
import ReactTooltip from "react-tooltip";
import ConfirmArchiveModal from "../../components/modals/ConfirmArchiveModal";

class NewsUpdates extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      showConfirmModal: false,
      toArchive: true,
      idToEdit: 0
    };

    this.tabs = ["News & Updates", "Archive"];

    this.actions = [{ name: "Notes", action: null }];

    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "News and Updates", to: "/news-updates" }
      ]
    });
  }

  headings = () => {
    return {
      "News & Updates": [
        { id: "title", label: "Title" },
        {
          id: "blurb",
          label: "Blurb",
          customCell: (r, i) => (
            <td key={i}>
              {r.mainbody.length > 100
                ? r.mainbody.slice(0, 100) + "..."
                : r.mainbody}
            </td>
          )
        },
        {
          id: "date",
          label: "Date",
          customCell: (r, i) => (
            <td key={i}>{format(new Date(r.date), "LLLL dd, yyyy")}</td>
          )
        },
        {
          id: "status",
          label: "Status"
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <div className="action-group">
                  <button
                    style={{
                      marginRight: "30px",
                      sor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    disabled={this.canSubUserEdit()}
                    onClick={_ =>
                      this.props.history.push(`/partners/news-create/${r.id}`)
                    }
                  >
                    Edit
                  </button>
                  {!this.canSubUserEdit() && (
                    <>
                      <Delete
                        onClick={() => {
                          this.setState({
                            showConfirmModal: true,
                            idToEdit: r.id,
                            toArchive: true
                          });
                        }}
                        data-for="archive"
                        data-tip
                      />
                      <ReactTooltip
                        id="archive"
                        place="top"
                        effect="solid"
                        type="info"
                      >
                        Delete News
                      </ReactTooltip>
                    </>
                  )}
                </div>
              </td>
            );
          }
        }
      ],
      Archive: [
        { id: "title", label: "Title" },
        {
          id: "blurb",
          label: "Blurb",
          customCell: (r, i) => (
            <td key={i}>
              {r.mainbody.length > 100
                ? r.mainbody.slice(0, 100) + "..."
                : r.mainbody}
            </td>
          )
        },
        {
          id: "date",
          label: "Date",
          customCell: (r, i) => (
            <td key={i}>{format(new Date(r.date), "LLLL dd, yyyy")}</td>
          )
        },
        {
          id: "status",
          label: "Status"
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                {!this.canSubUserEdit() && (
                  <div className="action-group">
                    <Restore
                      onClick={() => {
                        this.setState({
                          showConfirmModal: true,
                          idToEdit: r.id,
                          toArchive: false
                        });
                      }}
                      style={{ color: "#BBB" }}
                      data-for="restore"
                      data-tip
                    />
                    <ReactTooltip
                      id="restore"
                      place="top"
                      effect="solid"
                      type="info"
                    >
                      Restore News
                    </ReactTooltip>
                  </div>
                )}
              </td>
            );
          }
        }
      ]
    };
  };

  /**
   * This function handles the edit button functionality of a sub user
   */
  canSubUserEdit = () => {
    return !(!this.global.dsUser.sub_user ||
      this.global?.dsUser?.accesspoints?.Listings?.["News & Updates"]?.edit);
  };

  /**
   * Makes request to backend to archive/unarchive program.
   * Uses state to determine options (what to delete, archive/unarchive).
   */
  archiveNews = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/news/delete/${this.state.idToEdit}`;
    const results = await axios.post(ep, { toArchive: this.state.toArchive });

    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  async componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/news/list`;
    const results = await axios.get(ep);

    if (results.data.success) {
      this.setState({
        data: {
          "News & Updates": results.data.data
            .filter(f => !f.isArchived)
            .map(e => ({
              ...e,
              status: "Published"
            }))
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
          Archive: results.data.data
            .filter(f => f.isArchived)
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        }
      });
    }
    this.setGlobal({ loading: false });
  };

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
    if (results.data.success) {
      toast.success(`Partner Accepted`);
      this.componentDidMount();
    } else {
      toast.error(`Error, this person already signed-up!`);
    }
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  render() {
    return (
      <div className="admin">
        {this.state.iframeSetUp && (
          <AddIframeModal
            iframe={this.state.selectedIframe}
            open={this.state.iframeSetUp}
            type={this.state.type}
            active={this.state.active}
            onClose={() =>
              this.setState({
                iframeSetUp: false,
                selectedIframe: null,
                type: null,
                active: null
              })
            }
          />
        )}
        {this.state.showConfirmModal && (
          <ConfirmArchiveModal
            onSubmit={() => {
              this.archiveNews();
            }}
            onClose={_ => this.setState({ showConfirmModal: false })}
            toArchive={this.state.toArchive}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <div style={{ display: "block" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "-30px"
                  }}
                >
                  <h1 style={{ marginRight: "30px" }}>News & Updates</h1>
                  <LinkIcon
                    style={{
                      transform: "scale(1.5) rotate(130deg)",
                      cursor: "pointer",
                      marginRight: "10px",
                      marginTop: "0.2px",
                      color: "black"
                    }}
                    onClick={_ =>
                      this.setState({
                        iframeSetUp: true,
                        active: "News"
                      })
                    }
                    className="hovermyX"
                  />
                </div>

                <div
                  style={{ marginLeft: "-6px", marginTop: "4px" }}
                  className="switch-tooltip-group"
                >
                  <Switch
                    checked={
                      !!this.global.organizationInfo.display_news_listings
                    }
                    size="small"
                    onChange={_ => {
                      this.setGlobal({
                        organizationInfo: {
                          ...this.global.organizationInfo,
                          display_news_listings:
                            !this.global.organizationInfo.display_news_listings
                        }
                      });
                      this.dispatch.saveOrganizationalProfile();
                    }}
                    color="primary"
                    disabled={this.canSubUserEdit()}
                  />
                  <i
                    data-tip
                    data-for="view-news-toggle"
                    className="fas fa-info-circle"
                    style={{ color: "#DCDCDC" }}
                  ></i>
                  <ReactTooltip
                    id="view-news-toggle"
                    place="right"
                    effect="solid"
                    type="info"
                  >
                    Toggles news tab visibility for users
                  </ReactTooltip>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp">
                  <button
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ =>
                      this.props.history.push("/partners/news-create")
                    }
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Create New
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="cont">
            <div className="tablecont">
              <div className="table-responsive">
                <NewTabbedTable
                  tabs={this.tabs}
                  headings={this.headings()}
                  data={this.state.data}
                  actions={this.actions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsUpdates;
