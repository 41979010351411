import React, { useState } from "reactn";
import { MiniCheck, MiniPlus, MiniIks, MiniMinus } from "../../../../../assets";

const StatusSelectionModal = ({
  child,
  onClose,
  setModalScreen,
  setChildStatus
}) => {
  const [useChildState, setChildState] = useState(child);

  return (
    <>
      <h3>Mark {`${child.child.student.fullName} (${child.child.day})`}:</h3>
      <div
        className="modal-row"
        style={{ cursor: "pointer" }}
        onClick={() => setModalScreen(1)}
      >
        <img src={MiniCheck} alt="Present" />
        Present
      </div>
      <div
        className="modal-row"
        style={{ cursor: "pointer" }}
        onClick={() => setModalScreen(2)}
      >
        <img src={MiniMinus} className="minus" alt="Reduced Day" />
        Reduced Day
      </div>
      <div
        className="modal-row"
        style={{ cursor: "pointer" }}
        onClick={() => setModalScreen(3)}
      >
        <img src={MiniPlus} className="plus" alt="Extended Day" />
        Extended Day
      </div>
      <div
        className="modal-row"
        style={{ cursor: "pointer" }}
        onClick={() => setModalScreen(4)}
      >
        <img src={MiniIks} alt="Absent" />
        Absent
      </div>
      <button
        onClick={() => {
          setChildStatus({
            ...useChildState,
            status: { ...useChildState.status, status: "Select" }
          });
          onClose();
        }}
      >
        RESET
      </button>
      <button onClick={() => onClose()}>CANCEL</button>
    </>
  );
};

export default StatusSelectionModal;
