import React from "reactn";
// import axios from "axios";
import { format } from "date-fns";
import axios from "axios";
// import { Table } from "../../../components/UI";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";

class Program extends React.PureComponent {
  constructor(props) {
    super(props);
    this.fileChange = this.fileChange.bind(this);
  }
  async fileChange(e) {
    if (e.target.files.length) {
      const fd = new FormData();
      fd.set("dsUserId", this.props.program.profile.dsUserId);
      fd.set("handbook", e.target.files[0]);

      const ep = `${process.env.REACT_APP_API}/adm/educator/handbook`;
      try {
        this.setGlobal({ loading: true });
        const res = await axios.post(ep, fd);
        this.setGlobal({
          loading: false,
          program: { ...this.global.program, profile: res.data.data },
          lastAPICall: res
        });
      } catch (ex) {
        console.error(ex);
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }
  }

  render() {
    return (
      <div className="cont d-flex" style={{ justifyContent: "center" }}>
        <div
          style={{
            width: "100%",
            maxWidth: 480,
            padding: 8,
            backgroundColor: "white"
          }}
        >
          <div
            style={{
              height: 320,
              width: "100%",
              backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${this.props.program.myProgramGallery.programCoverPhoto})`,
              backgroundSize: "cover",
              backgroundPosition: "center"
            }}
            className="mb-2"
          ></div>
          <div className="d-flex">
            <img
              src={`${process.env.REACT_APP_DS_FILES_S3}/${this.props.program.myProgramGallery.profilePhoto}`}
              style={{ borderRadius: "50%", height: 80, width: 80 }}
              alt={`${this.props.program.myProgramInfo.educatorName}`}
            />
            <div className="ml-2">
              <h4 className="mt-3 mb-0" style={{ color: "#3e3e3e" }}>
                {this.props.program.myProgramInfo.programName}
              </h4>
              <p className="mt-0">
                {this.props.program.myProgramInfo.educatorName}
              </p>
            </div>
          </div>
          <hr />
          <div className="d-flex mt-2">
            <i
              className="far fa-calendar-alt pt-1 ml-4"
              style={{ width: 40 }}
            ></i>
            <p>
              Joined Dreamschools:{" "}
              {format(
                new Date(this.props.program.profile.createdAt),
                "LLL d, yyyy"
              )}
            </p>
          </div>
          <div className="d-flex mt-2">
            <i
              className="fas fa-graduation-cap pt-1 ml-4"
              style={{ width: 40 }}
            ></i>
            <p>Enrolled: {this.props.program.enrollCount}</p>
          </div>
          <div className="d-flex mt-2">
            <i
              className="fas fa-user-friends pt-1 ml-4"
              style={{ width: 40 }}
            ></i>
            <p>Connections: {this.props.program.connectionCount}</p>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: 480,
            padding: 8,
            backgroundColor: "white",
            fontSize: "10pt"
          }}
          className="ml-5"
        >
          <h6 style={{ color: "black" }}>Program Profile</h6>
          <hr />
          <div className="row">
            <div className="col-4 d-flex">Parent Handbook</div>
            <div className="col-8 d-flex">
              {!this.props.program.profile.parentHandbook ? (
                "Upload a copy of the handbook"
              ) : (
                <a
                  href={`${process.env.REACT_APP_DS_FILES_S3}/${this.props.program.profile.parentHandbook}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Parent Handbook
                </a>
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 text-right">
              <button
                className="ds-button"
                onClick={() => document.getElementById("uploadFile").click()}
              >
                Upload Parent Handbook
              </button>
            </div>
          </div>
        </div>
        <input
          type="file"
          style={{ display: "none" }}
          id="uploadFile"
          onChange={this.fileChange}
        />
      </div>
    );
  }
}

export default Program;
