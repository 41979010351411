import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Switch } from "@material-ui/core";
import { toast } from "react-toastify";
import _ from "lodash";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  IMAGE_DROPDOWN,
  SWITCH,
  DROPDOWN
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

const timeRanges = [
  { name: "Select", value: -1 },
  { name: "5:00 AM", value: 5 },
  { name: "5:15 AM", value: 5.25 },
  { name: "5:30 AM", value: 5.5 },
  { name: "5:45 AM", value: 5.75 },
  { name: "6:00 AM", value: 6 },
  { name: "6:15 AM", value: 6.25 },
  { name: "6:30 AM", value: 6.5 },
  { name: "6:45 AM", value: 6.75 },
  { name: "7:00 AM", value: 7 },
  { name: "7:15 AM", value: 7.25 },
  { name: "7:30 AM", value: 7.5 },
  { name: "7:45 AM", value: 7.75 },
  { name: "8:00 AM", value: 8 },
  { name: "8:15 AM", value: 8.25 },
  { name: "8:30 AM", value: 8.5 },
  { name: "8:45 AM", value: 8.75 },
  { name: "9:00 AM", value: 9 },
  { name: "9:15 AM", value: 9.25 },
  { name: "9:30 AM", value: 9.5 },
  { name: "9:45 AM", value: 9.75 },
  { name: "10:00 AM", value: 10 },
  { name: "10:15 AM", value: 10.25 },
  { name: "10:30 AM", value: 10.5 },
  { name: "10:45 AM", value: 10.75 },
  { name: "11:00 AM", value: 11 },
  { name: "11:15 AM", value: 11.25 },
  { name: "11:30 AM", value: 11.5 },
  { name: "11:45 AM", value: 11.75 },
  { name: "12:00 PM", value: 12 },
  { name: "12:15 PM", value: 12.25 },
  { name: "12:30 PM", value: 12.5 },
  { name: "12:45 PM", value: 12.75 },
  { name: "1:00 PM", value: 13 },
  { name: "1:15 PM", value: 13.25 },
  { name: "1:30 PM", value: 13.5 },
  { name: "1:45 PM", value: 13.75 },
  { name: "2:00 PM", value: 14 },
  { name: "2:15 PM", value: 14.25 },
  { name: "2:30 PM", value: 14.5 },
  { name: "2:45 PM", value: 14.75 },
  { name: "3:00 PM", value: 15 },
  { name: "3:15 PM", value: 15.25 },
  { name: "3:30 PM", value: 15.5 },
  { name: "3:45 PM", value: 15.75 },
  { name: "4:00 PM", value: 16 },
  { name: "4:15 PM", value: 16.25 },
  { name: "4:30 PM", value: 16.5 },
  { name: "4:45 PM", value: 16.75 },
  { name: "5:00 PM", value: 17 },
  { name: "5:15 PM", value: 17.25 },
  { name: "5:30 PM", value: 17.5 },
  { name: "5:45 PM", value: 17.75 },
  { name: "6:00 PM", value: 18 },
  { name: "6:15 PM", value: 18.25 },
  { name: "6:30 PM", value: 18.5 },
  { name: "6:45 PM", value: 18.75 },
  { name: "7:00 PM", value: 19 },
  { name: "7:15 PM", value: 19.25 },
  { name: "7:30 PM", value: 19.5 },
  { name: "7:45 PM", value: 19.75 },
  { name: "8:00 PM", value: 20 },
  { name: "8:15 PM", value: 20.25 },
  { name: "8:30 PM", value: 20.5 },
  { name: "8:45 PM", value: 20.75 },
  { name: "9:00 PM", value: 21 },
  { name: "9:15 PM", value: 21.25 },
  { name: "9:30 PM", value: 21.5 },
  { name: "9:45 PM", value: 21.75 },
  { name: "10:00 PM", value: 22 },
  { name: "10:15 PM", value: 22.25 },
  { name: "10:30 PM", value: 22.5 },
  { name: "10:45 PM", value: 22.75 },
  { name: "11:00 PM", value: 23 }
];
class AddOnlinePricingPlanModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checkmark: false,
      days_per_week: null,
      billing_cycle: "",
      timing: [-1, -1],
      tuition_rate: null,
      day_slot: "",
      deposit: "",
      isStartDateEnabled: false,
      isTaxEnabled: false,
      taxInfo: [],
      newTimeRanges: [],
      isReplaceTimeEnabled: false,
      description: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async savePlan() {
    if (
      (!this.state.tuition_rate || this.state.tuition_rate != 0) &&
      !this.global.organizationInfo.stripeId
    ) {
      toast.error("Denied. Please connect your Bank Account first");
      return;
    }

    if (this.state.timing[0] === -1 && this.state.description === "") {
      toast.error(
        "Please ensure a Timeframe or Description is added to the Price Plan"
      );
      return;
    }

    if (this.state.isTaxEnabled && this.state.taxInfo.length == 0) {
      toast.error("Please select a tax plan, otherwise disable taxes");
      return;
    }

    const obj = { ...this.state };
    if (!this.state.checkmark) {
      delete obj.checkmark;
      delete obj.deposit;
      delete obj.newTimeRanges;
    } else {
      delete obj.checkmark;
      delete obj.newTimeRanges;
    }
    // obj.tuition_rate = parseFloat(obj.tuition_rate).toFixed(2);

    const ep = `${process.env.REACT_APP_API}/partners/stripe/add_products`;
    const res = await axios.post(ep, {
      ...obj,
      name: this.global.online.program_name,
      parent: "Online",
      type: "Recurring",
      product: this.global.online.product_stripe
    });

    if (res.data.success) {
      this.setGlobal(
        {
          online: {
            ...this.global.online,
            product_stripe: res.data.data.product,
            plans: [
              ...this.global.online.plans,
              {
                ...obj,
                prodId: res.data.data,
                id: this.global.online.plans.length
              }
            ]
          }
        },
        async () => {
          await this.dispatch.saveVirtual();
        }
      );
      this.props.onClose();
    }
  }

  componentDidMount() {
    const indexStart = timeRanges
      .map(e => e.value)
      .indexOf(parseFloat(this.global.online.timing[0]));
    const indexEnd = timeRanges
      .map(e => e.value)
      .indexOf(parseFloat(this.global.online.timing[1]));
    const newTimeRange = timeRanges.slice(indexStart, indexEnd + 1);

    this.setState({
      newTimeRanges: newTimeRange
    });
  }

  /// ================== NEW MODAL ========================== ///

  taxesOptions = () => {
    //const global_organization = [JSON.parse(this.global.organizationInfo.stripeTaxId)]
    const global_organization = this.global.organizationInfo.stripeTaxId;
    console.log("global_organization", global_organization);
    const DATA = [];
    for (let i = 0; i < global_organization.length; i++) {
      DATA.push(JSON.parse(global_organization[i]));
    }
    console.log("DATA", DATA);

    return [
      ...DATA.map((e, i) => ({
        value: i,
        text: e.display_name + " " + e.jurisdiction + e.percentage + "%"
      }))
    ];
  };

  timingOptions = () => {
    return [
      ...this.state.newTimeRanges.map(val => ({
        value: val.name,
        text: val.name
      }))
    ];
  };

  billingcycleOptions = () => {
    return [
      { value: "week", text: "Weekly" },
      { value: "bi-week", text: "Bi-Weekly" },
      { value: "month", text: "Monthly" }
    ];
  };

  dayslotOptions = () => {
    return [
      { value: "Full", text: "Full Day" },
      { value: "Partial", text: "Partial Day" },
      { value: "Half", text: "Half Day" }
    ];
  };

  daysOptions = () => {
    return Object.keys(this.global.online.days_of_week).map((e, i) => ({
      value: i + 1,
      text: i + 1
    }));
  };

  getBaseModalFields() {
    const daysInput = {
      type: DROPDOWN,
      data: {
        name: "Days Per Week",
        required: true,
        placeholder: "Please Select",
        value: this.state.days_per_week,
        handleChange: e => {
          this.setState({
            days_per_week: e.target.value
          });
        },
        choices: this.daysOptions()
      },
      validators: { validateSubmit: () => this.state.days_per_week !== null }
    };

    const billingcycleInput = {
      type: DROPDOWN,
      data: {
        name: "Billing Cycle",
        required: true,
        placeholder: "Please Select",
        value: this.state.billing_cycle,
        handleChange: e => {
          this.setState({
            billing_cycle: e.target.value
          });
        },
        choices: this.billingcycleOptions()
      },
      validators: { validateSubmit: () => this.state.billing_cycle !== "" }
    };

    const replacetimeSelection = {
      type: SWITCH,
      data: {
        name: "Replace Time",
        isDisabled: false,
        checked: this.state.isReplaceTimeEnabled,
        handleClick: e =>
          this.setState({ isReplaceTimeEnabled: e.target.checked })
      }
    };

    const timefromInput = {
      type: DROPDOWN,
      data: {
        name: "Timing (from)",
        required: false,
        placeholder: "Please Select",
        value: this.state.timing[0],
        handleChange: e => {
          this.setState({
            timing: [e.target.value, this.state.timing[1]]
          });
        },
        choices: this.timingOptions()
      }
    };

    const timetoInput = {
      type: DROPDOWN,
      data: {
        name: "Timing (to)",
        required: false,
        placeholder: "Please Select",
        value: this.state.timing[1],
        handleChange: e => {
          this.setState({
            timing: [this.state.timing[0], e.target.value]
          });
        },
        choices: this.timingOptions()
      }
    };

    const descriptionInput = {
      type: STRING_INPUT,
      data: {
        name: "Description",
        required: false,
        maxLength: 32,
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description
      }
    };

    const tuitionrateInput = {
      type: STRING_INPUT,
      data: {
        name: "Tuition Rate",
        required: true,
        type: "number",
        handleChange: e => {
          let price = parseFloat(e.target.value || 0);
          let decimalValue = e.target.value.split(".")[1];

          if (decimalValue && decimalValue.length > 2) {
            return;
          } else if (!e.target.value.endsWith("0")) {
            price = Math.round(price * 100) / 100;
          } else {
            price = e.target.value;
          }

          this.setState({
            tuition_rate: price.toString()
          });
        },
        value: this.state.tuition_rate
      },
      validators: { validateSubmit: () => this.state.tuition_rate !== null }
    };

    const dayslotInput = {
      type: DROPDOWN,
      data: {
        name: "Day Slot",
        required: true,
        placeholder: "Please Select",
        value: this.state.day_slot,
        handleChange: e => {
          this.setState({
            day_slot: e.target.value
          });
        },
        choices: this.dayslotOptions()
      },
      validators: { validateSubmit: () => this.state.day_slot !== "" }
    };

    const depositSelection = {
      type: SWITCH,
      data: {
        name: "Deposit",
        isDisabled: false,
        checked: this.state.checkmark,
        handleClick: e => this.setState({ checkmark: e.target.checked })
      }
    };

    const depositdescriptionInput = {
      type: STRING_INPUT,
      data: {
        name: "",
        required: false,
        type: "number",
        handleChange: e => {
          let price = parseFloat(e.target.value || 0);
          let decimalValue = e.target.value.split(".")[1];

          if (decimalValue && decimalValue.length > 2) {
            return;
          } else if (!e.target.value.endsWith("0")) {
            price = Math.round(price * 100) / 100;
          } else {
            price = e.target.value;
          }

          this.setState({
            deposit: price.toString()
          });
        },
        value: this.state.deposit
      }
    };

    const enabledateSelection = {
      type: SWITCH,
      data: {
        name: "Enable Date Selection",
        isDisabled: false,
        checked: this.state.isStartDateEnabled,
        handleClick: e =>
          this.setState({ isStartDateEnabled: e.target.checked })
      }
    };

    const enableTaxes = {
      type: SWITCH,
      data: {
        name: "Enable Taxes",
        isDisabled: false,
        checked: this.state.isTaxEnabled,
        handleClick: e =>
          this.setState({
            isTaxEnabled: e.target.checked,
            taxInfo: []
          })
      }
    };

    const selectTaxes = {
      type: DROPDOWN,
      data: {
        name: "Tax Plan",
        required: false,
        placeholder: "Please Select" /* 
            value: this.state.taxInfo, */,
        handleChange: e => {
          const item = JSON.parse(
            this.global.organizationInfo.stripeTaxId[e.target.value]
          );
          this.setState({
            taxInfo: [
              {
                id: item.id,
                percentage: item.percentage,
                jurisdiction: item.jurisdiction,
                display_name: item.display_name,
                description: item.description
              }
            ]
          });
        },
        choices: this.taxesOptions()
      }
    };

    const fields = [];
    fields.push(daysInput);
    fields.push(billingcycleInput);
    fields.push(replacetimeSelection);
    if (!this.state.isReplaceTimeEnabled) {
      fields.push(timefromInput);
      fields.push(timetoInput);
    } else if (this.state.isReplaceTimeEnabled) {
      fields.push(descriptionInput);
    }
    fields.push(tuitionrateInput);
    fields.push(dayslotInput);
    fields.push(depositSelection);
    if (this.state.checkmark) {
      fields.push(depositdescriptionInput);
    }
    fields.push(enabledateSelection);
    fields.push(enableTaxes);
    if (this.state.isTaxEnabled) {
      fields.push(selectTaxes);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const debounceSavePlan = _.debounce(_ => this.savePlan(), 2000, {
      leading: true,
      trailing: false
    });
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => {
        e.preventDefault();
        debounceSavePlan();
      }
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Pricing Plan",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ========================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    const debounceSavePlan = _.debounce(_ => this.savePlan(), 2000, {
      leading: true,
      trailing: false
    });

    return (
      <ModalTemplate heading="Pricing Plan" onClose={_ => this.props.onClose()}>
        <form
          onSubmit={e => {
            e.preventDefault();
            debounceSavePlan();
          }}
        >
          <div className="modal-main">
            <ModalFields.select
              label="Days Per Week"
              name="days_per_week"
              onChange={e => {
                this.setState({
                  days_per_week: e.target.value
                });
              }}
            >
              {Object.keys(this.global.online.days_of_week).map((e, i) => {
                return <option value={i + 1}>{i + 1}</option>;
              })}
            </ModalFields.select>
            <ModalFields.select
              label="Billing Cycle"
              name="billing_cycle"
              onChange={e => {
                this.setState({
                  billing_cycle: e.target.value
                });
              }}
            >
              <option value="week">Weekly</option>
              <option value="bi-week">Bi-Weekly</option>
              <option value="month">Monthly</option>
            </ModalFields.select>
            <ModalFields.switch
              label="Replace Time"
              name="replace_time"
              checked={this.state.isReplaceTimeEnabled}
              onChange={e =>
                this.setState({ isReplaceTimeEnabled: e.target.checked })
              }
            />
            {!this.state.isReplaceTimeEnabled ? (
              <ModalFields.custom label="Timing" name="timing">
                <div
                  style={{
                    display: "flex",
                    width: "70%",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <select
                    name="timing"
                    id="displayName"
                    type="text"
                    className="form-control"
                    onChange={e => {
                      this.setState({
                        [e.target.name]: [e.target.value, this.state.timing[1]]
                      });
                    }}
                    value={this.state.timing[0]}
                  >
                    <option selected>Please Select</option>
                    {this.state.newTimeRanges.map(val => (
                      <option>{val.name}</option>
                    ))}
                  </select>
                  <span style={{ fontSize: "12px", margin: "0px 10px" }}>
                    To
                  </span>
                  <select
                    name="timing"
                    id="displayName"
                    type="text"
                    className="form-control"
                    onChange={e => {
                      this.setState({
                        [e.target.name]: [this.state.timing[0], e.target.value]
                      });
                    }}
                    value={this.state.timing[1]}
                  >
                    <option selected>Please Select</option>

                    {this.state.newTimeRanges.map(val => (
                      <option>{val.name}</option>
                    ))}
                  </select>
                </div>
              </ModalFields.custom>
            ) : (
              <ModalFields.input
                label="Description"
                name="description"
                type="text"
                onChange={e => {
                  this.setState({
                    [e.target.name]: e.target.value
                  });
                }}
                value={this.state.description}
              />
            )}
            <ModalFields.input
              label="Tuition Rate"
              name="tuition_rate"
              type="number"
              step="any"
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
              value={this.state.tuition_rate}
              required
            />
            <ModalFields.select
              label="Day Slot"
              name="day_slot"
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
              value={this.state.day_slot}
            >
              <option value="Full">Full Day</option>
              <option value="Partial">Partial Day</option>
              <option value="Half">Half Day</option>
            </ModalFields.select>
            <ModalFields.custom label="Deposit" name="deposit">
              <Switch
                checked={this.state.checkmark}
                onChange={e => this.setState({ checkmark: e.target.checked })}
                color="primary"
              />
              <input
                name="email"
                id="email"
                type="number"
                className="form-control"
                style={{ width: "55%" }}
                onChange={e => this.setState({ deposit: e.target.value })}
                value={this.state.deposit}
                disabled={!this.state.checkmark}
              />
            </ModalFields.custom>
            <ModalFields.switch
              label="Enable Date Selection"
              name="date_selection"
              checked={this.state.isStartDateEnabled}
              onChange={e =>
                this.setState({ isStartDateEnabled: e.target.checked })
              }
            />
            <ModalFields.switch
              label="Enable Taxes"
              name="taxes"
              checked={this.state.isTaxEnabled}
              onChange={e =>
                this.setState({
                  isTaxEnabled: e.target.checked,
                  taxInfo: []
                })
              }
            />

            {this.state.isTaxEnabled && (
              <ModalFields.select
                label="Tax Plan"
                name="taxInfo"
                onChange={e => {
                  const item = JSON.parse(
                    this.global.organizationInfo.stripeTaxId[e.target.value]
                  );
                  this.setState({
                    taxInfo: [
                      {
                        id: item.id,
                        percentage: item.percentage,
                        jurisdiction: item.jurisdiction,
                        display_name: item.display_name,
                        description: item.description
                      }
                    ]
                  });
                }}
              >
                {this.global.organizationInfo.stripeTaxId.map((ele, i) => {
                  const e = typeof ele === "string" ? JSON.parse(ele) : ele;
                  return (
                    <>
                      <option value={i}>
                        {e.display_name} {e.jurisdiction} {e.percentage}%
                      </option>
                    </>
                  );
                })}
              </ModalFields.select>
            )}
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn profile-btn">
              Submit
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(AddOnlinePricingPlanModal);
