import React from "reactn";
import { TabbedTable } from "../../components/UI";
import { format } from "date-fns";
import axios from "axios";
import CancelModal from "../../components/modals/CancelModal";
import Reschedule from "../../components/modals/RescheduleModal";
class TourRequests extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        "MY TOUR REQUESTS": []
      }
    };
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "Tour Requests"]
    });
    this.tabs = ["MY TOUR REQUESTS"];
    this.headings = {
      "MY TOUR REQUESTS": [
        { id: "programName", label: "Program" },
        {
          id: "requestedTourDate",
          label: "Requested Date",
          customCell: this.dateCell
        },
        {
          id: "accepted",
          label: "Accepted Request?",
          customCell: this.acceptedCell
        },
        {
          id: "reschedule",
          label: "",
          customCell: this.rescheduleTourCell,
          customStyle: { width: 180 }
        },
        {
          id: "cancel",
          label: "",
          customCell: this.cancelTourCell,
          customStyle: { width: 180 }
        },
        {
          id: "accept",
          label: "",
          customCell: this.accept
        },
        {
          id: "apply",
          label: "",
          customCell: this.applyNow,
          customStyle: { width: 180 }
        }
      ]
    };
  }

  cancelTourCell = (r, i) => {
    if (new Date(r.requestedTourDate).getTime() > Date.now() && !r.canceled) {
      return (
        <td key={`${i}-btn`}>
          <button
            onClick={() =>
              this.setState({ cancelModalOpen: true, tourCancel: r })
            }
          >
            CANCEL TOUR
          </button>
        </td>
      );
    }
    return ``;
  };
  rescheduleTourCell = (r, i) => {
    if (new Date(r.requestedTourDate).getTime() > Date.now() && !r.canceled) {
      return (
        <td key={`${i}-btn`}>
          <button
            onClick={() =>
              this.setState({ rescheduleModalOpen: true, tourReschedule: r })
            }
          >
            RESCHEDULE TOUR
          </button>
        </td>
      );
    }
    return ``;
  };
  applyNow = (r, i) => {
    if (
      new Date(r.requestedTourDate).getTime() < Date.now() &&
      !r.canceled &&
      r.accepted
    ) {
      return (
        <td key={`${i}-btn`} colSpan={3}>
          <button
            onClick={() =>
              this.props.history.push(
                `/parents/interested-programs/${r.slug}/choose-child`
              )
            }
          >
            APPLY NOW
          </button>
        </td>
      );
    }
    return <td key={`${i}-btn`} colSpan={3}></td>;
  };
  acceptTour = async r => {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/par/tour/accept`;
    const result = await axios.post(ep, {
      ...r
    });
    this.setGlobal({ loading: false });
    if (result.data.success) {
      this.setState({
        data: {
          "MY TOUR REQUESTS": this.state.data["MY TOUR REQUESTS"].map(e =>
            e.id === result.data.data.id
              ? { ...e, accepted: result.data.data.accepted }
              : e
          )
        }
      });
    }
  };
  accept = (r, i) => {
    if (
      r.rescheduled &&
      parseInt(r.rescheduledBy) !== this.global.dsUser.id &&
      !r.accepted &&
      new Date(r.requestedTourDate).getTime() > Date.now()
    )
      return (
        <td key={`${i}-${r.id}-ok`}>
          <button onClick={() => this.acceptTour(r)}>ACCEPT NEW TIME</button>
        </td>
      );
    return <td key={`${i}-${r.id}-ok`} colSpan={1}></td>;
  };

  acceptedCell = (r, i) => {
    return (
      <td key={`${i}-${r.id}-acc`}>
        {r.canceled
          ? `Canceled by : ${r.canceledBy}`
          : r.accepted
          ? "Tour Accepted"
          : Date.now() > new Date(r.requestedTourDate).getTime()
          ? "Tour Expired"
          : "Pending Acceptation"}
      </td>
    );
  };

  dateCell = (r, i) => {
    return (
      <td key={`${i}-${r.id}`}>
        {format(new Date(r.requestedTourDate), "LLL dd, yyyy - h:mm a")}
      </td>
    );
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/par/tour`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);

      this.setState({
        data: {
          "MY TOUR REQUESTS": res.data.data
        }
      });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="cont">
          <div className="row">
            <div className="col-md-6">
              <h1>Tour Requests</h1>
            </div>
          </div>
        </div>
        <div className="cont">
          {window.innerWidth < 750 ? (
            <div>
              {this.state.data["MY TOUR REQUESTS"].map(e => (
                <div
                  style={{
                    background:
                      "linear-gradient(to right, #7ea0ff 0%, #915aff 100%)",
                    padding: "5px",
                    margin: "auto auto 10px",
                    borderRadius: "15px",
                    color: "black"
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#FFF",
                      padding: "15px",
                      borderRadius: "13px"
                    }}
                  >
                    <p>
                      <b>Program</b>: {e.programName}
                    </p>
                    <p>
                      <b>Requested Date</b>:{" "}
                      {format(
                        new Date(e.requestedTourDate),
                        "LLL dd, yyyy - h:mm a"
                      )}
                    </p>
                    <p>
                      <b>Status</b>:{" "}
                      {e.canceled ? (
                        <span style={{ color: "red" }}>{`Canceled`}</span>
                      ) : e.accepted ? (
                        <span style={{ color: "green" }}>Tour Accepted</span>
                      ) : Date.now() >
                        new Date(e.requestedTourDate).getTime() ? (
                        <span style={{ color: "grey" }}>Tour Expired</span>
                      ) : (
                        <span style={{ color: "black" }}>
                          Pending Acceptation
                        </span>
                      )}
                    </p>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {new Date(e.requestedTourDate).getTime() > Date.now() &&
                          !e.canceled && (
                            <button
                              style={{
                                background: "#9bb0eb",
                                color: "white",
                                padding: "5px",
                                marginBottom: "5px",
                                border: 0
                              }}
                              onClick={() =>
                                this.setState({
                                  rescheduleModalOpen: true,
                                  tourReschedule: e
                                })
                              }
                            >
                              RESCHEDULE TOUR
                            </button>
                          )}
                        {new Date(e.requestedTourDate).getTime() > Date.now() &&
                          !e.canceled && (
                            <button
                              style={{
                                background: "#9bb0eb",
                                color: "white",
                                padding: "5px",
                                marginBottom: "5px",
                                border: 0
                              }}
                              onClick={() =>
                                this.setState({
                                  cancelModalOpen: true,
                                  tourCancel: e
                                })
                              }
                            >
                              CANCEL TOUR
                            </button>
                          )}
                        {e.rescheduled &&
                          parseInt(e.rescheduledBy) !== this.global.dsUser.id &&
                          !e.accepted &&
                          new Date(e.requestedTourDate).getTime() >
                            Date.now() && (
                            <button
                              onClick={() => this.acceptTour(e)}
                              style={{
                                background: "#9bb0eb",
                                color: "white",
                                padding: "5px",
                                marginBottom: "5px",
                                border: 0
                              }}
                            >
                              ACCEPT NEW TIME
                            </button>
                          )}
                        {new Date(e.requestedTourDate).getTime() < Date.now() &&
                          !e.canceled &&
                          e.accepted && (
                            <button
                              style={{
                                background: "#9bb0eb",
                                color: "white",
                                padding: "5px",
                                marginBottom: "5px",
                                border: 0
                              }}
                              onClick={() =>
                                this.props.history.push(
                                  `/parents/interested-programs/${e.slug}/choose-child`
                                )
                              }
                            >
                              APPLY NOW
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <TabbedTable
              tabs={this.tabs}
              headings={this.headings}
              data={this.state.data}
              // actions={this.actions}
            />
          )}
        </div>
        {this.state.cancelModalOpen && (
          <CancelModal
            tour={this.state.tourCancel}
            openSchedule={() => this.setState({ rescheduleModalOpen: true })}
            onClose={() => this.setState({ cancelModalOpen: false })}
          />
        )}
        {this.state.rescheduleModalOpen && (
          <Reschedule
            tour={this.state.tourReschedule}
            onClose={() => this.setState({ rescheduleModalOpen: false })}
          />
        )}
      </div>
    );
  }
}

export default TourRequests;
