import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";

class ViewDonationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  // async create_coupon(e) {
  //   e.preventDefault();
  //   const ep = `${process.env.REACT_APP_API}/partners/stripe/edit/coupon`;
  //   const cb = await axios.put(ep, { id: this.props.item.stripe.id, dbId: this.props.item.id, ...this.state });
  //   if (cb.data.success) {
  //     toast.success(`Coupon Updated!`);
  //     this.props.onEdit();
  //     this.props.onClose();
  //   }
  // }
  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>View Donation</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="message"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Message
                </label>
                <div
                  style={{
                    width: "70%",
                    fontSize: "16px",
                    color: "darkslategrey"
                  }}
                >
                  {this.props.selectedItem.message}
                </div>
              </div>

              {/* <div className="modal-footer" style={{ marginTop: "25px" }}>
                <button
                  type="submit"
                  // onClick={_ => this.savePlan()}
                  className="btn profile-btn"
                >
                  Submit
                </button>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewDonationModal;
