import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK, TITLE } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { isAfter, addHours, format } from "date-fns";

class NudgeInvitationModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  sendEmail = async () => {
    const endp = this.props.selectedItem.isPromotion
      ? `${process.env.REACT_APP_API}/partners/users/promo/invite_parent`
      : `${process.env.REACT_APP_API}/partners/users/invite_parent`;

    let amount = 0;
    if (this.props.selectedItem.product_table === "partner_programs") {
      const ep = `${process.env.REACT_APP_API}/partners/programs/find-program/${this.props.selectedItem.productId}`;
      const results = await axios.get(ep);
      if (results.data.success) {
        amount = JSON.parse(
          results.data.data[0]?.plans[this.props.selectedItem.planId]
        ).total_price;
      }
    } else if (this.props.selectedItem.product_table === "partner_online") {
      const ep = `${process.env.REACT_APP_API}/partners/virtual/find-online/${this.props.selectedItem.productId}`;
      const results = await axios.get(ep);
      if (results.data.success) {
        amount = JSON.parse(
          results.data.data[0]?.plans[this.props.selectedItem.planId]
        ).total_price;
      }
    } else if (this.props.selectedItem.product_table === "partner_event") {
      const ep = `${process.env.REACT_APP_API}/partners/events/find-event/${this.props.selectedItem.productId}`;
      const results = await axios.get(ep);
    }

    let result;

    if (this.props.selectedItem.table === "partner_leads") {
      const ep = `${process.env.REACT_APP_API}/partners/users/invite-form/nudge/${this.props.selectedItem.id}`;
      const formName = `${this.props.selectedItem.form.form_name}: ${this.props.selectedItem.form.form_sub_name}`;
      result = await axios.put(ep, {
        form_name: formName,
        email: this.props.selectedItem.email
      });
    } else {
      result = await axios.post(endp, {
        name: this.props.selectedItem.displayName,
        email: this.props.selectedItem.email,
        program: this.props.selectedItem.productId,
        program_name: this.props.selectedItem.program_name,
        plan: this.props.selectedItem.planId,
        modifier: this.props.selectedItem.modifier,
        selectedUser: this.props.selectedItem.dsUserId,
        type: this.props.selectedItem.type,
        isParent: this.props.selectedItem.isParent,
        amount: this.props.selectedItem.amount,
        ticket: this.props.selectedItem.ticket,
        product_table: this.props.selectedItem.product_table,
        promotion: this.props.selectedItem.promo
          ? this.props.selectedItem.promo
          : null,
        startDate: this.props.selectedItem.startDate,
        recurringCustomerSelectedDays:
          this.props.selectedItem.recurringCustomerSelectedDays,
        isSkipForm: this.props.selectedItem.isSkipForm,
        isWaiveDeposit: this.props.selectedItem.isWaiveDeposit,
        isStartSet: this.props.selectedItem.isStartSet,
        isNudgeOnly: true,
        nudgeCount: this.props.selectedItem.nudgeCount,
        invitationId: this.props.selectedItem.id,
        message: this.props.selectedItem.partnerMessage,
        singleSessionQuantity: this.props.singleSessionQuantity
          ? this.props.singleSessionQuanttiy
          : 1,
        singleSessionSelectedDates: this.props.singleSessionQuantity
          ? this.props.singleSessionQuantity
          : [],
        leadId:
          this.props.table === "partner_leads"
            ? this.props.selectedItem.id
            : null
      });
    }

    if (result.data.success) {
      toast.success("Email sent");
      this.props.fetchData();
      this.props.onClose();
    } else {
      toast.success("Error occured while sending email");
      this.props.onClose();
    }
  };

  getBaseModalFields = () => {
    const confirmField = {
      type: TITLE,
      data: {
        name:
          this.props.selectedItem.lastNudgeDate === null ||
          (this.props.selectedItem.lastNudgeDate !== null &&
            isAfter(
              new Date(),
              addHours(new Date(this.props.selectedItem.lastNudgeDate), 1)
            ))
            ? `Re-send invitation email to ${this.props.selectedItem.displayName} (${this.props.selectedItem.email})?`
            : `You may only nudge a user once per hour.`
      }
    };

    //Only show this field when nudge limit reached.
    const dateExists = {
      type: CONTENT_BLOCK,
      data: {
        content: `Last nudge date: ${format(
          new Date(this.props.selectedItem.lastNudgeDate),
          "PPpp"
        )}`
      }
    };

    const fields = [];

    fields.push(confirmField);
    if (this.props.selectedItem.lastNudgeDate) {
      fields.push(dateExists);
    }

    return fields;
  };

  getBaseModalButtons = () => {
    const buttons = [];

    const nudgeButton = {
      name: "Nudge",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      isDisabled:
        this.props.selectedItem.lastNudgeDate === null ||
        (this.props.selectedItem.lastNudgeDate !== null &&
          isAfter(
            new Date(),
            addHours(new Date(this.props.selectedItem.lastNudgeDate), 1)
          ))
          ? false
          : true,
      handleClick: () => this.sendEmail()
    };

    buttons.push(nudgeButton);

    return buttons;
  };

  getBaseModalProps = () => {
    return {
      title: "Nudge User",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: () => this.props.onClose()
    };
  };

  render() {
    console.log("this.state", this.state);
    console.log("this.props", this.props);
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(NudgeInvitationModal);
