import { format } from "date-fns";

/**
 * Takes an hour value converted to a number and converts back to a textual representation.
 * ex: 8.5 => "8:30 AM", 16.5 => "4:30 PM"
 */
export const numToHours = hour => {
  const d = new Date();
  return format(
    new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      Math.floor(hour),
      Math.ceil((hour % 1) * 60)
    ),
    "h:mm a"
  );
};

const functionStore = {
  isNotEmpty: e => e.length > 0,
  isValidEmail: e => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e),
  isValidPhone: e =>
    /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/.test(e),
  isNumber: e => e.length > 0 && !isNaN(e),
  validDays: e => Object.keys(e).length > 1
};
/**
 *
 * @param {{}} state
 * @param {HTMLFormElement} form
 */
export const validateForm = (state, form) => {
  for (let element in state) {
    let formele = form[element] ? form[element] : form.children[element];
    if (formele) {
      if (formele.length) formele = formele[0];
      const validation = formele.getAttribute("validation");
      if (validation) {
        if (!functionStore[validation](state[element])) {
          formele.focus();
          formele.style.border = "1px solid red";
          return false;
        } else formele.style.border = null;
      }
    }
  }
  return true;
};


export const convertToDateOnlyISOString = (d) => {
  /* return d.toISOString().substring(0,10);
    // ^^^^ didn't use this one, because .toISOString() converts to UTC+0 time, and if you're in a GMT+ timezone,
    //      then your date will be a day before!
    //      Example: picking Feb 15th in the DatePicker running on a browser in EST, would make d.toISOString()
    //      result in 2022-02-15T05:00:00Z, so substring(0,10) will produce correct results, ....but if you're in
    //      France, then Feb 15th using DatePicker will result in 2022-02-14T23:00:00Z (due to GMT+1), and substring
    //      will result in one day back.
  */
  /* return new Intl.DateTimeFormat('sv-SE').format(d);
    // ^^^^ the Swedes adopted ISO8601 date-time format, so "sv-SE" formats to yyyy-mm-dd.
    //      But!!!! Unsure of how many browsers support Intl.DateTimeFormat and the "sv-SE" format.
  */
  let year = d.getFullYear();
  let month = d.getMonth() + 1;   //getMonth() returns 0 to 11.
  let date = d.getDate();
  return `${year}-${month<10?"0":""}${month}-${date<10?"0":""}${date}`;
};

export const getTodayDateInLocalTZ = () => {
  return new Date( new Date().setHours(0,0,0,0) );
}