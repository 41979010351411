import React from "reactn";
import ProfilePhotos from "./profilePhotos";
import ProfileForm from "./profileForm";
import Billing from "./Billing";
import Axios from "axios";
import { toast } from "react-toastify";
class AccountSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "Account Settings"]
    });
  }
  saveInfo = async () => {
    const ep = `${process.env.REACT_APP_API}/adm/franchise/update`;
    const result = await Axios.post(ep, {
      id: this.global.franchiseUser.id,
      name: this.global.franchiseUser.name,
      phone: this.global.franchiseUser.phone
    });

    if (result.data.success) {
      toast.success(`Profile Updated!`);
    } else {
      toast.error(`Failed to Update`);
    }
  };
  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/franchise/billing`;
    const res = await Axios.get(ep);

    if (res.data.success) {
      this.setState({
        card: {
          last4: res.data.data.sources.data[0].last4,
          exp_month: res.data.data.sources.data[0].exp_month,
          exp_year: res.data.data.sources.data[0].exp_year,
          brand: res.data.data.sources.data[0].brand
        }
      });
    }
  }
  async refreshCC() {
    const ep = `${process.env.REACT_APP_API}/adm/franchise/billing`;
    const res = await Axios.get(ep);
    if (res.data.success) {
      this.setState({
        card: {
          last4: res.data.data.sources.data[0].last4,
          exp_month: res.data.data.sources.data[0].exp_month,
          exp_year: res.data.data.sources.data[0].exp_year,
          brand: res.data.data.sources.data[0].brand
        }
      });
    }
  }
  render() {
    return (
      // <!-- Begin Page Content -->
      <div className="container-fluid">
        <div className="cont">
          <h1>Account Settings</h1>
        </div>
        <div className="cont boxes-row">
          <ProfileForm />
          <Billing card={this.state.card} refreshCC={_ => this.refreshCC()} />
          {/* <ProfilePhotos /> */}
        </div>
        <div className="cont">
          <div className="buttons">
            <button className="savebtn" onClick={this.saveInfo}>
              SAVE
            </button>
            <button className="nextbtn">CANCEL</button>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountSettings;
