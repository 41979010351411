import React from "reactn";
import { Modal } from "../UI";
import { format } from "date-fns";

const gradients = [
  "gra-blue-purple",
  "gra-yellow-pink",
  "gra-purple-blue",
  "gra-teal-green",
  "gra-yellow-orange"
];

const eventModel = {
  id: null,
  category: "time",
  calendarId: 0,
  title: "",
  child: null,
  startTime: "",
  endTime: "",
  isAllDay: false,
  isPrivate: false,
  colour: 0,
  body: "",
  type: "",
  parent: null
};

class CalendarDetailsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...eventModel
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.open) {
      this.setState({ ...eventModel, ...newProps.selectedEvent });
    }
  }

  render() {
    const { open, onClose, student } = this.props;
    const evt = this.state;

    return (
      <div>
        <Modal
          open={open}
          heading={`${evt.title}`}
          onClose={() => onClose(false)}
        >
          {evt.id ? (
            <>
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  left: 0,
                  top: 0,
                  height: 8
                }}
                className={gradients[evt.colour]}
              ></div>
              <div className="row">
                <div className="col-9">
                  <p>
                    <span className="font-weight-bold">
                      {format(new Date(evt.startTime), "LLL d")}
                      {evt.endTime
                        ? ` - ${format(new Date(evt.endTime), "LLL d")}`
                        : ""}
                    </span>
                    {evt.isAllDay
                      ? " (All Day)"
                      : ` (${format(new Date(evt.startTime), "h:mm a")}` +
                        `${
                          evt.endTime
                            ? ` - ${format(new Date(evt.endTime), "h:mm a")})`
                            : ")"
                        }`}
                  </p>
                </div>
                {this.global.dsUser.accountType !== "Parent" ? (
                  <div className="col-3 text-right">
                    <p>
                      {evt.isPrivate ? (
                        <i className="fas fa-lock"></i>
                      ) : (
                        <i className="fas fa-lock-open"></i>
                      )}
                    </p>
                  </div>
                ) : null}
              </div>
              {!student ? null : (
                <div className="row">
                  <div className="col-4">
                    <p className="font-weight-bold">Student</p>
                  </div>
                  <div className="col-8">
                    <p>{student ? student.childName : ""}</p>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-4">
                  <p className="font-weight-bold">Type</p>
                </div>
                <div className="col-8">
                  <p>{evt.type}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className="font-weight-bold">Notes</p>
                </div>
                <div className="col-12">
                  <p>{evt.body}</p>
                </div>
              </div>
            </>
          ) : null}
          {this.global.dsUser.accountType !== "Parent" ? (
            <div className="row">
              <div className="col-12">
                <div className="buttons mt-3">
                  <button onClick={() => onClose(true)} className="deletebtn">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </Modal>
      </div>
    );
  }
}

export default CalendarDetailsModal;
