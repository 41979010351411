import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK, TITLE } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { isAfter, addHours, format } from "date-fns";

class NudgeInviteModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  sendEmail = async () => {
    const endp = this.props.invite.isPromotion
      ? `${process.env.REACT_APP_API}/partners/users/promo/invite_parent`
      : `${process.env.REACT_APP_API}/partners/users/invite_parent`;

    const result = await axios.post(endp, {
      name: this.props.invite.displayName,
      email: this.props.invite.email,
      program: this.props.invite.productId,
      program_name: this.props.invite.program_name,
      plan: this.props.invite.planId,
      modifier: this.props.invite.modifier,
      selectedUser: this.props.invite.dsUserId,
      type: this.props.invite.type,
      isParent: this.props.invite.isParent,
      amount: this.props.invite.amount,
      ticket: this.props.invite.ticket,
      product_table: this.props.program_table,
      promotion: this.props.invite.promo ? this.props.invite.promo : null,
      startDate: this.props.invite.startDate,
      recurringCustomerSelectedDays:
        this.props.invite.recurringCustomerSelectedDays,
      isSkipForm: this.props.invite.isSkipForm,
      isWaiveDeposit: this.props.invite.isWaiveDeposit,
      isStartSet: this.props.invite.isStartSet,
      isNudgeOnly: true,
      nudgeCount: this.props.invite.nudgeCount,
      invitationId: this.props.invite.id,
      message: this.props.invite.partnerMessage,
      singleSessionQuantity: this.props.singleSessionQuantity
        ? this.props.singleSessionQuanttiy
        : 1,
      singleSessionSelectedDates: this.props.singleSessionQuantity
        ? this.props.singleSessionQuantity
        : []
    });

    if (result.data.success) {
      toast.success("Email sent");
      this.props.fetchData();
      this.props.onClose();
    } else {
      toast.success("Error occured while sending email");
      this.props.onClose();
    }
  };

  getBaseModalFields = () => {
    console.log(
      "=====================================================================================================================================================================",
      this.props
    );
    const confirmField = {
      type: TITLE,
      data: {
        name:
          this.props.invite.lastNudgeDate === null ||
          (this.props.invite.lastNudgeDate !== null &&
            isAfter(
              new Date(),
              addHours(new Date(this.props.invite.lastNudgeDate), 1)
            ))
            ? `Re-send invitation email to ${this.props.invite.displayName} (${this.props.invite.email})?`
            : `You may only nudge a user once per hour.`
      }
    };

    //Only show this field when nudge limit reached.
    const dateExists = {
      type: CONTENT_BLOCK,
      data: {
        content: `Last nudge date: ${format(
          new Date(this.props.invite.lastNudgeDate),
          "PPpp"
        )}`
      }
    };

    const fields = [];

    fields.push(confirmField);
    if (this.props.invite.lastNudgeDate) {
      fields.push(dateExists);
    }

    return fields;
  };

  getBaseModalButtons = () => {
    const nudgeButton = {
      name: "Nudge",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      isDisabled:
        this.props.invite.lastNudgeDate === null ||
        (this.props.invite.lastNudgeDate !== null &&
          isAfter(
            new Date(),
            addHours(new Date(this.props.invite.lastNudgeDate), 1)
          ))
          ? false
          : true,
      handleClick: () => this.sendEmail()
    };

    const buttons = [];

    buttons.push(nudgeButton);

    return buttons;
  };

  getBaseModalProps = () => {
    return {
      title: "Nudge User",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: () => this.props.onClose()
    };
  };

  render() {
    console.log("this.state", this.state);
    console.log("this.props", this.props);
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(NudgeInviteModal);
