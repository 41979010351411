import React, { useState, useEffect, useGlobal } from "reactn";
import "../../../assets/css/componentSpecificCss/reportAnalysisPages.css";
import _ from "lodash";
import { format, parse, isAfter, parseISO, subDays, addDays } from "date-fns";

import { useDateFilter, CENTS_IN_DOLLAR } from "../../../providers/reports";
import {
  FilterButton,
  DateFilter,
  ReportContainer,
  ReportTable
} from "../../../components/UI/reportsAnalytics";

import { formatMoney } from "../../../utilities/reportsAnalytics";
import axios from "axios";

const RevenueSummary = () => {
  const orgData = useGlobal("organizationInfo")[0];
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");
  const [dateFrom, dateTo, setDateFrom, setDateTo] = useDateFilter();

  // all of the orders
  const [programOrders, setProgramOrders] = useState([]);
  const [eventOrders, setEventOrders] = useState([]);
  const [membershipOrders, setMembershipOrders] = useState([]);
  const [onlineOrders, setOnlineOrders] = useState([]);
  const [giftCardOrders, setGiftCardOrders] = useState([]);

  // a list of objects for each listing {listingId, item}
  const [filterProgramsChecked, setFilterProgramsChecked] = useState([]);
  const [filterEventsChecked, setFilterEventsChecked] = useState([]);
  const [filterMembershipsChecked, setFilterMembershipsChecked] = useState([]);
  const [filterOnlineChecked, setFilterOnlineChecked] = useState([]);
  const [filterGiftCardsChecked, setFilterGiftCardsChecked] = useState([]);

  // all of the orders which should be displayed
  const [displayPrograms, setDisplayPrograms] = useState([]);
  const [displayEvents, setDisplayEvents] = useState([]);
  const [displayMemberships, setDisplayMemberships] = useState([]);
  const [displayOnline, setDisplayOnline] = useState([]);
  const [displayGiftCards, setDisplayGiftCards] = useState([]);

  const [allOrders, setAllOrders] = useState([]);

  const [invoiceRevenue, setInvoiceRevenue] = useState(0);

  const [totalRefunds, setTotalRefunds] = useState(0);

  useEffect(() => {
    setDisplayPrograms(filterData(programOrders, filterProgramsChecked));
    setDisplayEvents(filterData(eventOrders, filterEventsChecked));
    setDisplayMemberships(
      filterData(membershipOrders, filterMembershipsChecked)
    );
    setDisplayOnline(filterData(onlineOrders, filterOnlineChecked));
    setDisplayGiftCards(filterData(giftCardOrders, filterGiftCardsChecked));
  }, [
    filterProgramsChecked,
    filterEventsChecked,
    filterMembershipsChecked,
    filterOnlineChecked,
    filterGiftCardsChecked,
    dateFrom,
    dateTo,
    programOrders,
    eventOrders
  ]);

  const filterData = (orders, listingFilter) => {
    return orders.filter(order => filterOrder(order, listingFilter));
  };

  const getFilterChecked = orders => {
    const grouped = groupOrders(orders);
    return Object.keys(grouped).map(key => ({
      listingId: key,
      item: grouped[key][0].item
    }));
  };

  /**
   * Once useListings is done setting all listings, set checked listings to each listing array.
   * This makes all filter listing options checked by default.
   */
  useEffect(() => {
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" },
      { label: "Revenue Summary", to: "/reports/revenue-summary" }
    ]);
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API}/partners/order-tracking`
    );
    const allOrders = cleanName([
      ...result.data.data.Once.payments,
      ...result.data.data.Recurring.payments
    ]);

    setAllOrders(allOrders);

    const totalRefunds = formatMoney(
      allOrders.reduce(
        (prev, curr) => prev + parseInt(curr.refundedAmount),
        0
      ) / 100
    );
    setTotalRefunds(totalRefunds);

    console.log("total gotten records are", allOrders);
    const allProgramOrders = allOrders.filter(e => e.listingType === "program");
    const allOnlineOrders = allOrders.filter(e => e.listingType === "online");
    const allEventOrders = allOrders.filter(e => e.listingType === "event");
    const allMembershipOrders = allOrders.filter(
      e => e.listingType === "membership"
    );
    const allGiftCardOrders = allOrders.filter(
      e => e.listingType === "gift card"
    );

    const invoiceRevenue = allOrders
      .filter(
        e =>
          e.type === "Invoice" &&
          new Date(e.date) >= new Date(dateFrom) &&
          new Date(e.date) <= new Date(dateTo)
      )
      .reduce((prev, curr) => prev + parseInt(curr.total), 0);

    console.log(
      "all invoices are ",
      allOrders.filter(e => e.type === "Invoice")
    );

    setInvoiceRevenue(formatMoney(invoiceRevenue / 100));

    setProgramOrders(allProgramOrders);
    setOnlineOrders(allOnlineOrders);
    setEventOrders(allEventOrders);
    setMembershipOrders(allMembershipOrders);
    setGiftCardOrders(allGiftCardOrders);

    setFilterEventsChecked(getFilterChecked(allEventOrders));
    setFilterProgramsChecked(getFilterChecked(allProgramOrders));
    setFilterOnlineChecked(getFilterChecked(allOnlineOrders));
    setFilterMembershipsChecked(getFilterChecked(allMembershipOrders));
    setFilterGiftCardsChecked(getFilterChecked(allGiftCardOrders));
  };
  // change the name from "Program: XXXX" to "XXXX"
  const cleanName = orders => {
    return orders.map(o => ({
      ...o,
      item: o.item.slice(o.item.indexOf(":") + 1).trim()
    }));
  };

  const formatCentsToDollars = cents => {
    return (cents / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
  };

  const groupOrders = orders => {
    return _.groupBy(orders, "listingId");
  };

  const aggregateOrders = orders => {
    const keys = Object.keys(orders).sort((a, b) => a - b);
    return keys.map(key => {
      const order = orders[key];
      const name = order[0].item;
      const mostRecentDate = order.reduce((prev, curr) => {
        if (isAfter(parseISO(curr.date), parseISO(prev.date))) {
          return curr;
        } else {
          return prev;
        }
      }, order[0].date);
      const revenue = formatCentsToDollars(
        order.reduce((prev, curr) => {
          return prev + parseInt(curr.total);
        }, 0)
      );
      const type = order[0].listingType;
      return [name, mostRecentDate, revenue, type];
    });
  };

  /**
   * Function used to filter orders.
   *
   * @returns {Boolean} Whether or not the order should be displayed/considered.
   *
   * @param {Object} order The order to filter.
   * @param {Array} listingFilter The order must belong to one of the listings in this filter.
   */
  const filterOrder = (order, listingFilter) => {
    const startDate = new Date(dateFrom);
    const endDate = addDays(new Date(dateTo), 1);
    if (
      listingFilter.find(
        listing => parseInt(listing.listingId) === parseInt(order.listingId)
      ) === undefined &&
      listingFilter.length !== 0
    ) {
      console.log({ order, listingFilter });
    }
    return (
      new Date(order.date) > startDate &&
      new Date(order.date) < endDate &&
      listingFilter.find(
        listing => parseInt(listing.listingId) === parseInt(order.listingId)
      ) !== undefined
    );
  };

  const lookup = [
    {
      name: "Program",
      orders: displayPrograms
    },
    {
      name: "Event",
      orders: displayEvents
    },
    {
      name: "Membership",
      orders: displayMemberships
    },
    {
      name: "Online",
      orders: displayOnline
    },
    {
      name: "Gift Card",
      orders: displayGiftCards
    }
  ];
  /**
   * Display all revenue summary data, for all listing types.
   *
   * @returns {JSX}}
   */
  const displayAllData = () => {
    return lookup.map(listingType => {
      const headings = [
        { label: `${listingType.name}` },
        { label: `Transaction Date` },
        { label: "", className: "table-item-money" }
      ];
      const orders = aggregateOrders(groupOrders(listingType.orders)).map(e => [
        e[0],
        e[1],
        e[2]
      ]);
      const body = orders
        .sort((a, b) => new Date(a[1]) - new Date(b[1]))
        .map(o => [o[0], format(new Date(o[1]), "MMM d, yyyy"), o[2]]);

      if (listingType.orders.length === 0) {
        return null;
      }
      return (
        <ReportTable key={listingType.name} headings={headings} body={body} />
      );
    });
  };

  /**
   * Gets total revenue by aggregating all order data.
   *
   * @returns {String} Total revenue.
   */
  const getTotalRevenue = () => {
    let totalRevenue = 0;

    [
      ...displayPrograms,
      ...displayEvents,
      ...displayMemberships,
      ...displayOnline,
      ...displayGiftCards
    ].forEach(o => {
      totalRevenue += parseInt(o.total);
    });

    return formatMoney(totalRevenue / CENTS_IN_DOLLAR);
  };

  const getTotalInvoiceRevenue = () => {
    console.log("DATES: ", new Date(dateFrom), new Date(dateTo));
    const invoiceRevenue = allOrders
      .filter(
        e =>
          e.type === "Invoice" &&
          new Date(e.date) >= new Date(dateFrom) &&
          new Date(e.date) <= addDays(new Date(dateTo), 1)
      )
      .reduce((prev, curr) => prev + parseInt(curr.total), 0);

    return formatMoney(invoiceRevenue / 100);
  };

  const getTotalRefunds = () => {
    let totalRefunds = 0;
    [
      ...programOrders,
      ...eventOrders,
      ...membershipOrders,
      ...onlineOrders,
      ...giftCardOrders
    ].forEach(o => {
      totalRefunds += parseInt(o.refundedAmount);
    });

    return formatMoney(totalRefunds / CENTS_IN_DOLLAR);
  };

  const csvHeaders = [
    { label: "Listing", key: "listingName" },
    { label: "Most Recent Transaction", key: "dateTime" },
    { label: "Total", key: "total" },
    { label: "Type", key: "type" }
  ];

  /**
   * Gets the CSV for the CSVLink component to use.
   *
   * @returns {Array<Object>} Each property of each object corresponds to a column.
   */
  const getCsvData = () => {
    let csvRows = [];
    lookup.forEach(listingType => {
      const orders = aggregateOrders(groupOrders(listingType.orders));
      console.log("aggregated orders", orders);
      const body = orders
        .sort((a, b) => new Date(a[1]) - new Date(b[1]))
        .map(o => [o[0], format(new Date(o[1]), "MMM d, yyyy"), o[2], o[3]]);
      body.forEach(row => {
        csvRows.push({
          listingName: row[0],
          dateTime: row[1],
          total: row[2],
          type: row[3]
        });
      });
    });

    console.log("csvRows", csvRows);

    csvRows.push({
      listingName: "TOTAL",
      total: getTotalRevenue()
    });
    return csvRows;
  };

  const reportSubtitles = [
    orgData.organization_title,
    `For ${format(parse(dateFrom, "yyyy-MM-dd", new Date()), "MMM d, yyyy")}
    - 
    ${format(parse(dateTo, "yyyy-MM-dd", new Date()), "MMM d, yyyy")} 
    `
  ];

  return (
    <div className="admin">
      <div className="container-fluid adminprofiles">
        <div className="row cont">
          <div className="col-md-6">
            <h1>Revenue Summary</h1>
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-section">
            <DateFilter
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
            />
            <div
              className="filter-item grid-new-col margin-left-offset"
              style={{ marginLeft: "100px" }}
            >
              <FilterButton
                label="Programs"
                checkedOptions={filterProgramsChecked}
                setCheckedOptions={setFilterProgramsChecked}
                allOptions={getFilterChecked(programOrders)}
                stringProperty="item"
              />
              <FilterButton
                label="Events"
                checkedOptions={filterEventsChecked}
                setCheckedOptions={setFilterEventsChecked}
                allOptions={getFilterChecked(eventOrders)}
                stringProperty="item"
              />
            </div>
            <div className="filter-item margin-left-offset">
              <FilterButton
                label="Online Virtual"
                checkedOptions={filterOnlineChecked}
                setCheckedOptions={setFilterOnlineChecked}
                allOptions={getFilterChecked(onlineOrders)}
                stringProperty="item"
              />
              <FilterButton
                label="Memberships"
                checkedOptions={filterMembershipsChecked}
                setCheckedOptions={setFilterMembershipsChecked}
                allOptions={getFilterChecked(membershipOrders)}
                stringProperty="item"
              />
              <FilterButton
                label="Gift Cards"
                checkedOptions={filterGiftCardsChecked}
                setCheckedOptions={setFilterGiftCardsChecked}
                allOptions={getFilterChecked(giftCardOrders)}
                stringProperty="item"
              />
            </div>
          </div>
        </div>
        <ReportContainer
          title="Revenue Summary"
          subtitles={reportSubtitles}
          csvHeaders={csvHeaders}
          csvData={getCsvData()}
          fileName={`Report_Summary_${dateFrom}-${dateTo}.csv`}
        >
          <div className="report-table-large">
            <ReportTable
              headings={[
                { label: "Summary", className: "table-item-bold" },
                { label: "", className: "table-item-money" }
              ]}
              body={[
                ["Total Listings Revenue", getTotalRevenue()],
                ["Total Invoice Revenue", getTotalInvoiceRevenue()],
                ["Total Refunds", totalRefunds]
                // ["Total Refunds", getTotalRefunds()]
              ]}
            />

            {displayAllData()}
          </div>
        </ReportContainer>
      </div>
    </div>
  );
};

export default RevenueSummary;
// import React, { useState, useEffect, useGlobal } from "reactn";
// import "../../../assets/css/componentSpecificCss/reportAnalysisPages.css";
// import _ from "lodash";
// import { format, parse, fromUnixTime } from "date-fns";
// import {
//   getPlusMinus,
//   getRevenueChange
// } from "../../../utilities/reportsAnalytics";

// import {
//   useListings,
//   useGroupedOrders,
//   useDateFilter,
//   MILLISECONDS_IN_SECONDS,
//   CENTS_IN_DOLLAR
// } from "../../../providers/reports";
// import {
//   FilterButton,
//   DateFilter,
//   ReportContainer,
//   ReportTable
// } from "../../../components/UI/reportsAnalytics";

// import { formatMoney } from "../../../utilities/reportsAnalytics";
// import axios from "axios";

// const RevenueSummary = () => {
//   const { programs, events, memberships, onlineVirtuals } = useListings();
//   const orgData = useGlobal("organizationInfo")[0];
//   const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");
//   const [dateFrom, dateTo, setDateFrom, setDateTo] = useDateFilter();

//   const [filterProgramsChecked, setFilterProgramsChecked] = useState([]);
//   const [filterEventsChecked, setFilterEventsChecked] = useState([]);
//   const [filterMembershipsChecked, setFilterMembershipsChecked] = useState([]);
//   const [filterOnlineChecked, setFilterOnlineChecked] = useState([]);

//   const { programOrders, eventOrders, onlineOrders, membershipOrders } =
//     useGroupedOrders();

//   /**
//    * Once useListings is done setting all listings, set checked listings to each listing array.
//    * This makes all filter listing options checked by default.
//    */
//   useEffect(() => {
//     setFilterEventsChecked(events);
//     setFilterProgramsChecked(programs);
//     setFilterOnlineChecked(onlineVirtuals);
//     setFilterMembershipsChecked(memberships);
//     setPartnerPathway([
//       ...partnerPathway.slice(0, 1),
//       { label: "Reports & Analytics", to: "/reporting" },
//       { label: "Revenue Summary", to: "/reports/revenue-summary" }
//     ]);
//     fetchInitialData();
//   }, [events, programs, onlineVirtuals, memberships]);

//   const fetchInitialData = async () => {
//     const result = await axios.get(
//       `${process.env.REACT_APP_API}/partners/order-tracking`
//     );
//     const allOrders = [
//       ...result.data.data.Once.payments,
//       ...result.data.data.Recurring.payments
//     ];
//     console.log("reveunue summary result", result);
//   };

//   /**
//    * Function used to filter orders.
//    *
//    * @returns {Boolean} Whether or not the order should be displayed/considered.
//    *
//    * @param {Object} order The order to filter.
//    * @param {Array} listingFilter The order must belong to one of the listings in this filter.
//    */
//   const filterOrder = (order, listingFilter) => {
//     // Converts the YYYY-MM-DD date filter dates to unix timestamps.
//     const dateFromUnix = new Date(dateFrom).getTime() / MILLISECONDS_IN_SECONDS;
//     const tempDateTo = new Date(dateTo);
//     // Offset by a day to provide the correct filter.
//     const dateToUnix =
//       tempDateTo.setDate(tempDateTo.getDate() + 1) / MILLISECONDS_IN_SECONDS;
//     return (
//       order.created > dateFromUnix &&
//       order.created < dateToUnix &&
//       listingFilter.find(listing => listing.id === order.productId)
//     );
//   };

//   /**
//    * Displays the table rows for the revenue summary table, for a specific listing type.
//    *
//    * @returns {Array} Array representing the rows of the table.
//    *
//    * @param {Array} orders Array of orders from one type of listing, e.g Programs.
//    * @param {Array} listingFilter Array of filtered listings from the filter checklist (same type as @param orders).
//    */
//   const getListingData = (orders, listingFilter) => {
//     let groupedByListing = [];

//     // Only filter data if orders is not undefined (i.e there are orders for the given listing).
//     if (orders) {
//       // Filter the orders both by date and by the listingFilter.
//       const filteredOrders = orders.filter(order =>
//         filterOrder(order, listingFilter)
//       );

//       // Group the orders by individul listings, then sum each group's revenues.
//       groupedByListing = Object.values(
//         _.groupBy(filteredOrders, order => order.productId)
//       ).map(order => {
//         const maxDate = order.reduce((a, c) => {
//           return a.created < c.created ? c : a;
//         }).created;
//         // change from refunds, coupons, ...
//         const revenueChange = getRevenueChange(order);
//         return {
//           ...order[0],
//           revenue: _.sumBy(order, "revenue") + revenueChange,
//           mostRecentDate: format(
//             fromUnixTime(maxDate),
//             "MMM d, yyyy-h:mm:ssaaa"
//           )
//         };
//       });
//     }

//     // Add any missing listings in listingFilter with 0 customers.
//     listingFilter.forEach(listing => {
//       if (!groupedByListing.find(a => a.productId === listing.id)) {
//         groupedByListing.push({
//           productId: listing.id,
//           productName:
//             listing.program_name ||
//             listing.event_title ||
//             listing.membership_name,
//           revenue: 0,
//           mostRecentDate: "-"
//         });
//       }
//     });

//     return groupedByListing.map(row => {
//       return [
//         row.productName,
//         row.mostRecentDate,
//         formatMoney(row.revenue / CENTS_IN_DOLLAR)
//       ];
//     });
//   };

//   const lookup = [
//     {
//       name: "Program",
//       checked: filterProgramsChecked,
//       orders: programOrders
//     },
//     { name: "Event", checked: filterEventsChecked, orders: eventOrders },
//     {
//       name: "Membership",
//       checked: filterMembershipsChecked,
//       orders: membershipOrders
//     },
//     { name: "Online", checked: filterOnlineChecked, orders: onlineOrders }
//   ];
//   /**
//    * Display all revenue summary data, for all listing types.
//    *
//    * @returns {JSX}}
//    */
//   const displayAllData = () => {
//     return lookup.map(listingType => {
//       const headings = [
//         { label: `${listingType.name} Revenue`, className: "table-item-bold" },
//         { label: `Most Recent Transaction Date` },
//         { label: "", className: "table-item-money" }
//       ];
//       const body = getListingData(listingType.orders, listingType.checked);
//       return (
//         <ReportTable key={listingType.name} headings={headings} body={body} />
//       );
//     });
//   };

//   /**
//    * Gets total revenue by aggregating all order data.
//    *
//    * @returns {String} Total revenue.
//    */
//   const getTotalRevenue = () => {
//     let totalRevenue = 0;
//     // change in total revenue from refunds, coupons, ...
//     let totalRevenueChange = 0;

//     for (const [orders, checked] of [
//       [programOrders, filterProgramsChecked],
//       [eventOrders, filterEventsChecked],
//       [membershipOrders, filterMembershipsChecked],
//       [onlineOrders, filterOnlineChecked]
//     ]) {
//       if (!orders) continue;
//       totalRevenue += orders
//         .filter(order => filterOrder(order, checked))
//         .reduce((a, c) => a + c.revenue, 0);

//       const revenueChange = getRevenueChange(orders);
//       totalRevenueChange += revenueChange;
//     }
//     return formatMoney((totalRevenue + totalRevenueChange) / CENTS_IN_DOLLAR);
//   };

//   const csvHeaders = [
//     { label: "Listing", key: "listingName" },
//     { label: "Most Recent Transaction", key: "dateTime" },
//     { label: "Total", key: "total" }
//   ];

//   /**
//    * Gets the CSV for the CSVLink component to use.
//    *
//    * @returns {Array<Object>} Each property of each object corresponds to a column.
//    */
//   const getCsvData = () => {
//     let csvRows = [];
//     lookup.forEach(listingType => {
//       const body = getListingData(listingType.orders, listingType.checked);
//       body.forEach(row => {
//         csvRows.push({
//           listingName: row[0],
//           dateTime: row[1],
//           total: row[2]
//         });
//       });
//     });

//     csvRows.push({
//       listingName: "TOTAL",
//       total: getTotalRevenue()
//     });
//     return csvRows;
//   };

//   const reportSubtitles = [
//     orgData.organization_title,
//     `For ${format(parse(dateFrom, "yyyy-MM-dd", new Date()), "MMM d, yyyy")}
//     -
//     ${format(parse(dateTo, "yyyy-MM-dd", new Date()), "MMM d, yyyy")}
//     `
//   ];

//   return (
//     <div className="admin">
//       <div className="container-fluid adminprofiles">
//         <div className="row cont">
//           <div className="col-md-6">
//             <h1>Revenue Summary</h1>
//           </div>
//         </div>

//         <div className="filter-container">
//           <div className="filter-section">
//             <DateFilter
//               dateFrom={dateFrom}
//               setDateFrom={setDateFrom}
//               dateTo={dateTo}
//               setDateTo={setDateTo}
//             />
//             <div
//               className="filter-item grid-new-col margin-left-offset"
//               style={{ marginLeft: "100px" }}
//             >
//               <FilterButton
//                 label="Programs"
//                 checkedOptions={filterProgramsChecked}
//                 setCheckedOptions={setFilterProgramsChecked}
//                 allOptions={programs}
//                 stringProperty="listingName"
//               />
//               <FilterButton
//                 label="Events"
//                 checkedOptions={filterEventsChecked}
//                 setCheckedOptions={setFilterEventsChecked}
//                 allOptions={events}
//                 stringProperty="listingName"
//               />
//             </div>
//             <div className="filter-item margin-left-offset">
//               <FilterButton
//                 label="Online Virtual"
//                 checkedOptions={filterOnlineChecked}
//                 setCheckedOptions={setFilterOnlineChecked}
//                 allOptions={onlineVirtuals}
//                 stringProperty="listingName"
//               />
//               <FilterButton
//                 label="Memberships"
//                 checkedOptions={filterMembershipsChecked}
//                 setCheckedOptions={setFilterMembershipsChecked}
//                 allOptions={memberships}
//                 stringProperty="listingName"
//               />
//             </div>
//           </div>
//         </div>
//         <ReportContainer
//           title="Revenue Summary"
//           subtitles={reportSubtitles}
//           csvHeaders={csvHeaders}
//           csvData={getCsvData()}
//           fileName={`Report_Summary_${dateFrom}-${dateTo}.csv`}
//         >
//           <div className="report-table-large">
//             <ReportTable
//               headings={[
//                 { label: "Summary", className: "table-item-bold" },
//                 { label: "", className: "table-item-money" }
//               ]}
//               body={[["Total Revenue", getTotalRevenue()]]}
//             />
//             {displayAllData()}
//           </div>
//         </ReportContainer>
//       </div>
//     </div>
//   );
// };

// export default RevenueSummary;
