import React from "reactn";
import { TabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import AddLeadModal from "../../components/modals/AddLeadModal";
import NextStageModal from "../../components/modals/NextStageModal";
import ProposalModal from "../../components/modals/ProposalModal";
import InitiateModal from "../../components/modals/InitiateModal";
import CloseModal from "../../components/modals/CloseModal";
class FamilyProfiles extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      edit: false
    };

    this.tabs = ["New", "Stages", "Proposal", "Initiate", "Wins", "Closed"];
    this.headings = {
      New: [
        { id: "name", label: "Partner Name" },
        { id: "company", label: "Organization" },
        {
          id: "postalcode",
          label: "Postal Code"
        },
        {
          id: "email",
          label: "Email"
        },
        {
          id: "phone",
          label: "Phone"
        },
        {
          id: "createdAt",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        { id: "action", label: "Actions" }
      ],
      Stages: [
        { id: "name", label: "Partner Name" },
        { id: "company", label: "Organization" },
        {
          id: "postalcode",
          label: "Postal Code"
        },
        {
          id: "email",
          label: "Email"
        },
        {
          id: "phone",
          label: "Phone"
        },
        {
          id: "createdAt",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        {
          id: "actionable",
          label: "ACTIONS",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={_ =>
                    this.setState({
                      stageModal: true,
                      stage: 0,
                      row: r,
                      edit: true
                    })
                  }
                >
                  Edit
                </button>
                <button style={{ marginLeft: "10px" }}>Notes</button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={_ =>
                    this.setState({ proposalModal: true, row: r, edit: false })
                  }
                >
                  Proposal
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={_ =>
                    this.setState({ closeModal: true, row: r, edit: false })
                  }
                >
                  Close
                </button>
              </td>
            );
          }
        }
      ],
      Proposal: [
        { id: "name", label: "Partner Name" },
        { id: "company", label: "Organization" },
        {
          id: "postalcode",
          label: "Postal Code"
        },
        {
          id: "email",
          label: "Email"
        },
        {
          id: "phone",
          label: "Phone"
        },
        {
          id: "createdAt",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        {
          id: "actionable",
          label: "ACTIONS",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={_ =>
                    this.setState({ proposalModal: true, row: r, edit: true })
                  }
                >
                  Edit
                </button>
                <button style={{ marginLeft: "10px" }}>Notes</button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={_ =>
                    this.setState({ initiateModal: true, row: r, edit: false })
                  }
                >
                  Initiate
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={_ => this.setState({ closeModal: true, row: r })}
                >
                  Close
                </button>
              </td>
            );
          }
        }
      ],
      Initiate: [
        { id: "name", label: "Partner Name" },
        { id: "company", label: "Organization" },
        {
          id: "postalcode",
          label: "Postal Code"
        },
        {
          id: "email",
          label: "Email"
        },
        {
          id: "phone",
          label: "Phone"
        },
        {
          id: "createdAt",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        {
          id: "actionable",
          label: "ACTIONS",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={_ =>
                    this.setState({ initiateModal: true, row: r, edit: true })
                  }
                >
                  Edit
                </button>
                <button style={{ marginLeft: "10px" }}>Notes</button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={_ => this.setState({ stageModal: true, stage: 3 })}
                  disabled
                >
                  Won
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={_ => this.setState({ closeModal: true, row: r })}
                >
                  Close
                </button>
              </td>
            );
          }
        }
      ],
      Wins: [
        { id: "name", label: "Partner Name" },
        { id: "company", label: "Organization" },
        {
          id: "postalcode",
          label: "Postal Code"
        },
        {
          id: "email",
          label: "Email"
        },
        {
          id: "phone",
          label: "Phone"
        },
        {
          id: "createdAt",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        {
          id: "actionable",
          label: "ACTIONS",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button style={{ marginLeft: "10px" }}>View Details</button>
                <button style={{ marginLeft: "10px" }}>Notes</button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={_ => this.setState({ closeModal: true, row: r })}
                >
                  Close
                </button>
              </td>
            );
          }
        }
      ],
      Closed: [
        { id: "name", label: "Partner Name" },
        { id: "company", label: "Organization" },
        {
          id: "postalcode",
          label: "Postal Code"
        },
        {
          id: "email",
          label: "Email"
        },
        {
          id: "phone",
          label: "Phone"
        },
        {
          id: "createdAt",
          label: "# of Days in System",
          customCell: this.daysInSystem
        },
        {
          id: "actionable",
          label: "ACTIONS",
          customCell: (r, i) => {
            return (
              <td key={`${i}-btn`} style={{ whiteSpace: "nowrap" }}>
                <button style={{ marginLeft: "10px" }}>Edit</button>
                <button style={{ marginLeft: "10px" }}>Notes</button>
              </td>
            );
          }
        }
      ]
    };
    /*this.actions = [
      { name: "Notes", action: row => this.acceptPartnership(row) }
    ];*/
    this.actions = [
      { name: "Notes", action: null },
      {
        name: "Next Stage",
        action: r =>
          this.setState({ stageModal: true, stage: 0, row: r, edit: false })
      },
      {
        name: "Close",
        action: r => this.setState({ closeModal: true, row: r })
      }
    ];
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/adm/partner/leads`;
    const results = await axios.get(ep);

    if (results.data.success) {
      //this.setState({ data: results.data.data.filter(e => !e.accepted) });
      const myObj = {};
      this.tabs.forEach(
        (e, i) => (myObj[e] = results.data.data.filter(d => d.categorie === i))
      );
      this.setState({ data: myObj, allData: results.data.data });
    }
  }
  update(subject) {
    const datatoChange = this.state.allData.map(e =>
      e.id === subject.id ? { ...e, ...subject } : e
    );
    const myObj = {};
    this.tabs.forEach(
      (e, i) => (myObj[e] = datatoChange.filter(d => d.categorie === i))
    );
    this.setState({ data: myObj, allData: datatoChange });
  }

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
    if (results.data.success) {
      toast.success(`Partner Accepted`);
      this.componentDidMount();
    } else {
      toast.error(`Error, this person already signed-up!`);
    }
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  render() {
    return (
      <div className="admin">
        {this.state.leadModal && (
          <AddLeadModal
            onClose={_ => this.setState({ leadModal: false })}
            addLead={lead =>
              this.setState({
                data: {
                  ...this.state.data,
                  New: [lead, ...this.state.data.New]
                },
                allData: [...this.state.allData, lead]
              })
            }
          />
        )}
        {this.state.stageModal && (
          <NextStageModal
            onClose={_ => this.setState({ stageModal: false })}
            data={this.state.row}
            edit={this.state.edit}
            page={this.state.stage}
            update={e => this.update(e)}
          />
        )}
        {this.state.proposalModal && (
          <ProposalModal
            onClose={_ => this.setState({ proposalModal: false })}
            data={this.state.row}
            edit={this.state.edit}
            update={e => this.update(e)}
          />
        )}
        {this.state.initiateModal && (
          <InitiateModal
            onClose={_ => this.setState({ initiateModal: false })}
            data={this.state.row}
            edit={this.state.edit}
            update={e => this.update(e)}
          />
        )}
        {this.state.closeModal && (
          <CloseModal
            onClose={_ => this.setState({ closeModal: false })}
            data={this.state.row}
            update={e => this.update(e)}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>Partner Leads</h1>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp">
                  <button
                    className="newapplications"
                    onClick={_ => this.setState({ leadModal: true })}
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Add Lead
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="tablecont">
              <div className="table-responsive">
                <TabbedTable
                  tabs={this.tabs}
                  headings={this.headings}
                  data={this.state.data}
                  actions={this.actions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FamilyProfiles;
