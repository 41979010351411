import React from "reactn";
import { Link } from "react-router-dom";
import axios from "axios";
// import { withStyles } from "@material-ui/core";
import { ChevronRight, ExpandMore } from "@material-ui/icons";
import {
  FamilyProfile,
  ProgramSetup,
  PricingPlans,
  Milestones,
  TourRequests,
  // Applications,
  Connections,
  // Enrolled,
  Attendance,
  WeeklySlots,
  MyEarnings,
  FamilyPayments,
  // PaymentSettings,
  ExpenseTracker,
  Tasks,
  Document
} from "../../assets";

import "../../assets/css/componentSpecificCss/sideMenu.css";

const ProfileLinks = [
  {
    name: "Dashboard",
    icon: Tasks,
    to: "#/",
    nested: [
      { name: "Dashboard", to: "/franchiser", icon: ProgramSetup },
      { name: "Financials", to: "/franchiser/financials", icon: MyEarnings }
    ]
  },
];

class SideBar extends React.PureComponent {
  constructor() {
    super();
    this.state = { show: [] };
  }

  render() {
    return (
      <>
        <hr className="sidebar-divider my-0" />

        <li
          className="nav-item active"
          style={{ marginTop: "30px", fontFamily: "Montserrat" }}
        >
          {ProfileLinks.map((link, key) => {
            return (
              <div
                style={{
                  margin: "5px 0"
                }}
                key={key}
              >
                <span
                  className={"nav-linkpartner"}
                  to={link.to}
                  key={key}
                  // disabled={this.isEnabled(link)}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    this.state.show.includes(link.name)
                      ? this.setState({
                          show: [
                            ...this.state.show.filter(e => e !== link.name)
                          ]
                        })
                      : this.setState({
                          show: [...this.state.show, link.name]
                        });
                  }}
                >
                  {
                    <img
                      src={link.icon}
                      alt=""
                      style={{ width: "10px", height: "10px" }}
                    />
                  }
                  <span style={{ flexGrow: 1, fontWeight: "300" }}>
                    {link.name}
                  </span>

                  <div style={{ marginBottom: "3px", paddingRight: "10px" }}>
                    {this.state.show.includes(link.name) && link.nested && (
                      <ExpandMore style={{ height: "17px", width: "17px" }} />
                    )}
                    {!this.state.show.includes(link.name) && link.nested && (
                      <ChevronRight style={{ height: "17px", width: "17px" }} />
                    )}
                  </div>
                </span>
                {this.state.show.includes(link.name) && link.nested && (
                  <ul
                    style={{
                      listStyleType: "none",
                      padding: 0,
                      width: "85%",
                      margin: "0px auto",
                      borderRadius: "10px",
                      paddingLeft: "20px",
                      fontSize: "14px"
                    }}
                  >
                    {link.nested.map((e, key) => (
                      <li
                        style={{ paddingBottom: "2px" }}
                        className="side-menu-sub-list"
                        key={key}
                      >
                        <span key={key}>
                          {
                            <img
                              src={e.icon}
                              alt=""
                              style={{
                                width: "10px",
                                height: "10px",
                                marginRight: "18px"
                              }}
                            />
                          }
                          <Link
                            to={e.to}
                            style={{
                              marginLeft: "8px",
                              fontSize: "12px",
                              color: "white",
                              fontWeight: "300"
                            }}
                            className="submenu"
                          >
                            {e.name}
                          </Link>
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            );
          })}
        </li>
      </>
    );
  }
}

export default SideBar;
