import React, { useContext } from "reactn";
import PropTypes from "prop-types";

import { PromotionContext, setSelected } from "./PromotionDetail.context";
import BookingDetailPromotionCard from "./BookingDetailPromotionCard";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import SummarySubTotalEvents from "../SpecificPageUtils/SummarySubTotalEvents";

const styles = {
  radio: {
    color: "#ccc",
    "&$checked": {
      color: "#FF6666"
    },
    "& .MuiButtonBase-root": {
      padding: "0px !important",
      marginRight: "0px !important"
    }
  },
  checkedRadio: {
    color: "#FF6666"
  }
};

const EventBookingDetail = props => {
  const { state, dispatch } = useContext(PromotionContext);
  // global state
  const { promotion } = state;
  const isShowPromoOnly = promotion?.show_promo_only;

  return (
    <>
      <h2 className="right-cont-name">Select Plan</h2>

      <div style={{ width: "80%", margin: "0 auto" }}>
        {state.event &&
          state.event.tickets &&
          state.event.tickets.map((ele, key) => {
            const e = JSON.parse(ele);

            if (isShowPromoOnly) {
              if (!isTicketPartOfPromotion(promotion, e)) {
                return null;
              }
            }

            return (
              <div
                className={
                  state.selected === key
                    ? props.classes.planSelected + " pricingcard"
                    : props.classes.plan + " pricingcard"
                }
                onClick={_ => setSelected(dispatch, key)}
                key={key}
              >
                <div className="radio-btn-positioning-container">
                  <Radio
                    checked={state.selected === key}
                    // onChange={handleChange}
                    value="d"
                    color="default"
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "D" }}
                    size="large"
                    classes={{
                      root: props.classes.radio,
                      checked: props.classes.checkedRadio
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <p className="pricingcard-name">
                    {e.ticket_name} <br />{" "}
                    {isTicketPartOfPromotion(promotion, e) && (
                      <div>
                        <BookingDetailPromotionCard promotion={promotion} />
                      </div>
                    )}
                    <span className="pricingcard-days">
                      {e.ticket_status === "Paid"
                        ? "Standard"
                        : e.ticket_status}{" "}
                      Ticket
                    </span>
                  </p>
                </div>

                <div style={{ width: "50px" }}>
                  <p className="pricingcard-amount">
                    {e.ticket_price
                      ? `$${getPromotionTicketPrice(
                          state.promotion,
                          e.ticket_price
                        )}`
                      : "Free"}
                  </p>
                </div>
              </div>
            );
          })}

        {state?.selected !== -1 && (
          <SummarySubTotalEvents
            plans={state?.event?.tickets}
            selected={state?.selected}
            isPromo={isTicketPartOfPromotion(
              promotion,
              JSON.parse(state?.event?.tickets[state?.selected])
            )}
            promotion={promotion}
          />
        )}

        {state.event.isCartDisabled ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(52, 63, 100)`,
                color: `white`,
                marginTop: "10px",
                fontWeight: `bold`,
                border: 0,
                cursor: "not-allowed"
              }}
            >
              Register Here Now
            </button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(52, 63, 100)`,
                color: `white`,
                fontWeight: `bold`,
                border: 0,
                marginTop: "40px",
                cursor: "not-allowed"
              }}
            >
              Add to Cart
            </button>
            <span>-------- OR --------</span>
            <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(211,52,49)`,
                color: `white`,
                marginTop: "10px",
                fontWeight: `bold`,
                border: 0,
                cursor: "not-allowed"
              }}
            >
              Register Here Now
            </button>
          </div>
        )}
      </div>
    </>
  );
};

EventBookingDetail.propTypes = {
  classes: PropTypes.object
};

const PERCENT = "Percentage";
const FIXED_AMOUNT = "Fixed Amount";

function getPromotionTicketPrice(promotion, ticketPrice) {
  if (promotion.discount_type === PERCENT) {
    return ((1 - promotion.discount_percent / 100) * ticketPrice).toFixed(2);
  } else if (promotion.discount_type === FIXED_AMOUNT) {
    return Math.max(ticketPrice - promotion.discount_fixed / 100, 0).toFixed(2);
  }
}

function isTicketPartOfPromotion(promotion, ticket) {
  return promotion.plan_price_ticketId.indexOf(ticket.id) !== -1;
}

export default withStyles(styles)(EventBookingDetail);
