import React from "reactn";
import { Link } from "react-router-dom";
import { formTypes } from "../../../components/UI/form";
import { Input } from "../../../components/UI";
import {
  MyEnrollmentImg,
  SelectArrow,
  CMale,
  CFemale,
  CUnisex
} from "../../../assets";
import axios from "axios";

class ApplicationConfirmation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      promoCodeId: null,
      promoInvalid: true,
      priceTotal: 0.0,
      promoCode: "",
      discountAmount: 0,
      ...this.global.enrApp
    };

    this.handleChange = this.handleChange.bind(this);

    if (!this.global.enrApp || !this.global.enrApp.selectedChild.id) {
      this.props.history.push(
        `/parents/interested-programs/${props.match.params.slug}/choose-child`
      );
    }
  }

  submitApplication = async () => {
    const ep = `${process.env.REACT_APP_API}/par/enrollment/application/submit`;
    try {
      const enroll = {
        programId: this.global.school.id,
        planId: this.global.enrApp.selectedPlan.id,
        childId: this.global.enrApp.selectedChild.id,
        categoryName: this.global.enrApp.selectedCat.name,
        startDate: this.global.enrApp.startDate,
        endDate: this.global.enrApp.endDate,
        daysOfWeek: this.global.enrApp.daysOfWeek,
        promoCodeId: this.state.promoCodeId ? this.state.promoCodeId.id : null
      };
      await axios.post(ep, enroll);
    } catch (err) {}
  };

  handleChange = (e, v) => {
    this.setState({
      [e]: v
    });
  };

  promoCodeCheck = async () => {
    const ep = `${process.env.REACT_APP_API}/stripe/coupon`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(`${ep}/${this.state.promoCode}`);
      if (res.data.success && res.data.data.valid) {
        const pCode = res.data.data;
        if (pCode.metadata.educators) {
          if (
            !!!Object.keys(pCode.metadata).includes(
              this.global.school.id.toString()
            )
          )
            throw new Error("Invalid");
        }
        if (pCode.metadata.max_global_redemptions) {
          if (
            pCode.metadata.max_global_redemptions ===
            pCode.metadata.global_redemptions
          )
            throw new Error("Invalid");
        }
        this.setState({
          promoInvalid: true,
          promoCodeId: pCode,
          discountAmount: pCode.amount_off
            ? parseFloat(pCode.amount_off / 100).toFixed(2)
            : parseFloat(
                (parseFloat(pCode.percent_off) / 100) *
                  parseFloat(this.global.enrApp.selectedPlan.amount)
              )
        });
      }
      //this.setState({ promoCode: promocode.data.data });
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      this.setState({ promoInvalid: false });
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  };

  getPromoString(promo) {
    if (!promo) return null;
    let str = "";
    if (promo.amount_off) {
      str += `$${parseFloat(promo.amount_off / 100).toFixed(2)}`;
    } else {
      str += `%${promo.percent_off}`;
    }
    str += ` off `;
    switch (promo.duration) {
      case "once":
        str += "first enrollment invoice!";
        break;
      case "repeating":
        str += `first ${promo.duration_in_months} months!`;
        break;
      case "forever":
        str += "every enrollment invoice!";
        break;
      default:
        str = "";
    }
    return str;
  }

  render() {
    const { slug } = this.props.match.params;
    const vals = this.state;
    const glbl = this.global.school;
    return (
      <div className="container-fluid new_application new_application_3">
        {!glbl ? null : (
          <>
            <div className="cont">{/* <h1>New Application</h1> */}</div>

            <div className="cont mt-5 pt-5">
              <div className="contbox">
                <p className="nom">3</p>
                <div className="header">
                  <h2>Confirm</h2>
                </div>
                <div className="body">
                  <div className="row">
                    <div className="col-md-7 col-lg-6">
                      {/* leftside row */}
                      <div className="row">
                        <div className="col-6">
                          <h2>Program</h2>
                        </div>
                        {/* <div className="col-3">
                          <Link
                            to="/parents/interested-programs"
                            className="changebtn"
                          >
                            Change
                          </Link>
                        </div> */}
                        <div className="col-6">
                          <h2>Child</h2>
                        </div>
                        {/* <div className="col-3">
                          <Link
                            to={`/parents/interested-programs/${slug}/choose-child`}
                            className="changebtn"
                          >
                            Change
                          </Link>
                        </div> */}
                      </div>
                      {/*end of first box */}
                      <div className="boxes mt-1">
                        <div className="row">
                          <div className="col-6">
                            <div className="box">
                              <div
                                className="divforimg"
                                style={{
                                  backgroundImage: `url(
                                      ${
                                        this.global.school.programCoverPhoto
                                          ? `${process.env.REACT_APP_DS_FILES_S3}/${this.global.school.programCoverPhoto}`
                                          : MyEnrollmentImg
                                      }
                                    )`,
                                  backgroundPosition: "center",
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  width: "100%",
                                  height: "92px"
                                }}
                              ></div>
                              <p>{this.global.school.programName}</p>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="box">
                              <div className="divforimg">
                                <img
                                  src={
                                    this.global.enrApp.selectedChild.photo
                                      ? `${process.env.REACT_APP_DS_FILES_S3}/${this.global.enrApp.selectedChild.photo}`
                                      : this.global.enrApp.selectedChild
                                          .gender === "Male"
                                      ? CMale
                                      : this.global.enrApp.selectedChild
                                          .gender === "Female"
                                      ? CFemale
                                      : CUnisex
                                  }
                                  alt={
                                    this.global.enrApp.selectedChild.fullName
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    maxWidth: "92px",
                                    height: "92px"
                                  }}
                                />
                              </div>
                              <p>{this.global.enrApp.selectedChild.fullName}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*end of second row*/}
                      <div className="row mt-3">
                        <div className="col-12">
                          <h2 className="d-inline-block mb-2">Plan</h2>
                          <Link
                            to={`/parents/interested-programs/${slug}/application`}
                            className="changebtn ml-3"
                            onClick={() => {
                              window.scrollTo(0, 0);
                            }}
                          >
                            Change
                          </Link>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <Input
                            isDisabled={true}
                            value={`${this.global.enrApp.selectedCat.name} - ${this.global.enrApp.selectedPlan.numDays} days / week`}
                            onClick={() => {
                              window.scrollTo(0, 0);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12">
                          <h2 className="d-inline-block mb-2 mr-3">
                            Promo Code
                          </h2>
                          <p className="d-inline-block">(if applicable)</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 d-flex">
                          <input
                            name="promoCode"
                            value={this.state.promoCode}
                            onChange={e =>
                              this.handleChange(e.target.name, e.target.value)
                            }
                            placeholder={"Promo Code..."}
                          />
                          <button
                            onClick={() =>
                              this.promoCodeCheck(this.state.promoCode)
                            }
                            style={{
                              fontFamily: "Open Sans, sans-serif",
                              fontWeight: 700,
                              fontSize: "10px",
                              lineHeight: "14px",
                              color: "#fff",
                              padding: "10px 25px",
                              background: "#9bb0eb",
                              transition: "all 0.15s ease",
                              border: 0,
                              margin: "0 1px"
                            }}
                          >
                            Apply
                          </button>
                        </div>
                        <div className="col-12" style={{ minHeight: 32 }}>
                          {!this.state.promoInvalid ? (
                            <p
                              style={{
                                color: "red"
                              }}
                            >
                              NOT VALID
                            </p>
                          ) : (
                            <p>{this.getPromoString(this.state.promoCodeId)}</p>
                          )}
                        </div>
                      </div>
                      {/*end of 3rd row*/}
                    </div>
                    {/* end of leftside*/}

                    <div className="col-md-5 col-lg-6">
                      <div className="rightside">
                        <div className="wraper">
                          {/*drown downs*/}
                          <h2>Days of the Week</h2>
                          <div className="select">
                            <formTypes.DaysDropdownSelect
                              name="daysOfWeek"
                              isDisabled={true}
                              value={vals.daysOfWeek}
                              onChange={this.handleChange}
                              options={formTypes.SelectOptions.daysOfWeekRanges}
                              className="w-100"
                            />
                          </div>
                          <h2 className="mt-3">Start Date</h2>
                          <div className="select">
                            <img src={SelectArrow} alt="" />

                            <formTypes.Date
                              name="startDate"
                              isDisabled={true}
                              value={vals.startDate}
                              onChange={this.handleChange}
                            />
                          </div>
                          {/* <h2>End Date</h2>
                          <div className="select">
                            <img src={SelectArrow} alt="" />
                            <formTypes.Date
                              name="endDate"
                              isDisabled={true}
                              selected={this.endDate}
                              value={vals.endDate}
                              onChange={this.handleChange}
                            />
                          </div> */}
                          {/*end of dropdowns*/}
                          <div className="row mt-5">
                            <div className="col-12">
                              <div
                                className="py-2"
                                style={{
                                  borderBottom: "1px solid #cecece",
                                  position: "relative"
                                }}
                              >
                                Deposit
                                <span
                                  className="ml-1"
                                  style={{ fontSize: "12px" }}
                                >
                                  (Payable Upon Acceptance)
                                </span>
                                <span
                                  className="d-inline-block"
                                  style={{ position: "absolute", right: 0 }}
                                >
                                  $
                                  {parseInt(
                                    this.global.enrApp.selectedPlan
                                      .depositAmount
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div
                                className="py-2"
                                style={{
                                  borderBottom: "1px solid #cecece",
                                  position: "relative"
                                }}
                              >
                                Enrollment
                                <span
                                  className="ml-1"
                                  style={{ fontSize: "12px" }}
                                >
                                  (Payable Before 1<sup>st</sup> Day)
                                </span>
                                <span
                                  className="d-inline-block"
                                  style={{ position: "absolute", right: 0 }}
                                >
                                  $
                                  {parseInt(
                                    this.global.enrApp.selectedPlan.amount
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div
                                className="py-2"
                                style={{
                                  color: "black",
                                  borderBottom: "1px solid #cecece",
                                  position: "relative"
                                }}
                              >
                                Subtotal
                                <span
                                  className="d-inline-block"
                                  style={{ position: "absolute", right: 0 }}
                                >
                                  $
                                  {parseInt(
                                    this.global.enrApp.selectedPlan.amount
                                  ) +
                                    parseInt(
                                      this.global.enrApp.selectedPlan
                                        .depositAmount
                                    )}
                                </span>
                              </div>
                            </div>
                          </div>
                          {!this.state.promoCodeId ? null : (
                            <div className="row">
                              <div className="col-12">
                                <div
                                  className="py-2"
                                  style={{
                                    color: "black",
                                    borderBottom: "1px solid #cecece",
                                    position: "relative"
                                  }}
                                >
                                  Discount
                                  <span
                                    className="d-inline-block"
                                    style={{ position: "absolute", right: 0 }}
                                  >
                                    -$
                                    {parseFloat(
                                      this.state.discountAmount
                                    ).toFixed(2)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-12">
                              <div
                                className="py-2"
                                style={{
                                  color: "black",
                                  position: "relative"
                                }}
                              >
                                Total
                                <span
                                  className="d-inline-block"
                                  style={{ position: "absolute", right: 0 }}
                                >
                                  $
                                  {parseInt(
                                    this.global.enrApp.selectedPlan.amount
                                  ) +
                                    parseInt(
                                      this.global.enrApp.selectedPlan
                                        .depositAmount
                                    ) -
                                    parseFloat(
                                      this.state.discountAmount
                                    ).toFixed(2)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*body*/}
                <div className="row">
                  <div className="col-md-12">
                    <div className="buttons mb-0">
                      <Link
                        to={`/parents/interested-programs/${slug}/application`}
                        className="savebtn"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        BACK
                      </Link>
                      <Link
                        to={`/parents/interested-programs/${slug}/confirm`}
                        className="savebtn"
                        onClick={this.submitApplication}
                      >
                        SUBMIT APPLICATION
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ApplicationConfirmation;
