import React, { useContext, useGlobal, useDispatch } from "reactn";

import PropTypes from "prop-types";

import { format, addWeeks } from "date-fns";
import {
  PromotionContext,
  setSelected,
  setDropIns,
  setInstallments,
  setQuantity
} from "./PromotionDetail.context";

import {
  getFormattedProgramDate,
  isPromotionAppliedToProgramPlan,
  getDiscountPrice,
  promotion_discount
} from "./utils";

import BookingDetailPromotionCard from "./BookingDetailPromotionCard";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import SummarySubTotal from "../SpecificPageUtils/SummarySubTotal";

const styles = {
  radio: {
    color: "#ccc",
    "&$checked": {
      color: "#FF6666"
    },
    "& .MuiButtonBase-root": {
      padding: "0px !important",
      marginRight: "0px !important"
    }
  },
  checkedRadio: {
    color: "#FF6666"
  }
};

const ProgramBookingDetail = props => {
  const { state, dispatch } = useContext(PromotionContext);
  // const [global, _] = useGlobal()
  // const globalDispatch = useDispatch()
  const { program, promotion, selected, dropIns, quantity, installments } =
    state;
  const isShowPromoOnly = promotion?.show_promo_only;

  return (
    <>
      <h2 className="right-cont-name">Booking Details</h2>

      <div style={{ width: "80%", margin: "0 auto" }}>
        {state.program &&
          state.program.plans &&
          state.program.plans.map((e, i) => {
            const element = parseProgramPlanFromStringToJSON(e);
            if (element.archived || element.active === false) {
              return null;
            }

            if (isShowPromoOnly) {
              if (!isPlanPartOfPromotion(promotion, element)) {
                return null;
              }
            }

            if (!element.type)
              return (
                <div
                  className={
                    state.selected === i
                      ? props.classes.planSelected + " pricingcard"
                      : props.classes.plan + " pricingcard"
                  }
                  onClick={() => setSelected(dispatch, i)}
                  key={i}
                >
                  <div className="radio-btn-positioning-container">
                    <Radio
                      checked={state.selected === i}
                      // onChange={handleChange}
                      value="d"
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "D" }}
                      size="large"
                      classes={{
                        root: props.classes.radio,
                        checked: props.classes.checkedRadio
                      }}
                    />
                  </div>
                  <div style={{ width: "90%" }}>
                    <p className="pricingcard-name">
                      Recurring ({element.billing_cycle})
                      {isPlanPartOfPromotion(promotion, element) && (
                        <>
                          {" "}
                          {"- "}
                          <BookingDetailPromotionCard promotion={promotion} />
                        </>
                      )}
                    </p>

                    <p className="pricingcard-days">
                      {element.days_per_week} days / week
                      {displayElementTiming(element)}
                    </p>
                  </div>
                  <div style={{ width: "60px" }}>
                    <p className="pricingcard-amount">
                      {isPromotionAppliedToProgramPlan(promotion, element) ? (
                        <>${getDiscountPrice(promotion, element)} / week</>
                      ) : (
                        <> ${element.tuition_rate} / week</>
                      )}
                    </p>
                  </div>
                </div>
              );

            if (element.type === "Single Sessions") {
              return (
                <>
                  <div
                    className={
                      state.selected === i &&
                      !state.dropIns &&
                      !state.installments
                        ? props.classes.planSelected + " pricingcard"
                        : props.classes.plan + " pricingcard"
                    }
                    onClick={() => {
                      setSelected(dispatch, i);
                      setDropIns(dispatch, false);
                      setInstallments(dispatch, false);
                    }}
                    key={i}
                  >
                    <div className="radio-btn-positioning-container">
                      <Radio
                        checked={state.selected === i}
                        // onChange={handleChange}
                        value="d"
                        color="default"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "D" }}
                        size="large"
                        classes={{
                          root: props.classes.radio,
                          checked: props.classes.checkedRadio
                        }}
                      />
                    </div>
                    <div style={{ width: "90%" }}>
                      <p className="pricingcard-name">
                        Single Sessions x({quantity[i] ? quantity[i] : 1})
                        {isPlanPartOfPromotion(promotion, element) && (
                          <>
                            {" "}
                            {"- "}
                            <BookingDetailPromotionCard promotion={promotion} />
                          </>
                        )}
                      </p>
                      <p className="pricingcard-days">
                        {program.number_of_weeks !== 0 &&
                        state?.online?.number_of_weeks !== -1
                          ? getFormattedProgramDate(
                              program.program_start,
                              program.number_of_weeks
                            )
                          : ""}
                      </p>
                      <span
                        style={{ fontWeight: "normal", fontSize: "12px" }}
                      ></span>
                    </div>

                    <div style={{ width: "60px" }}>
                      <p className="pricingcard-amount">
                        {isPromotionAppliedToProgramPlan(promotion, element) ? (
                          <>
                            $
                            {Math.max(
                              0,
                              element.total_price -
                                promotion_discount(
                                  promotion,
                                  element.total_price
                                )
                            ).toFixed(2)}
                          </>
                        ) : (
                          <>${element.total_price}</>
                        )}

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#36b9cc",
                            marginLeft: "22px"
                          }}
                        >
                          <i
                            className="fas fa-minus-circle"
                            style={{ fontSize: "13px" }}
                            onClick={_ => {
                              let quantityValue = { ...quantity };
                              quantityValue[i] =
                                (quantityValue[i] ? quantityValue[i] : 1) - 1;
                              console.log("quantityValue", quantityValue);

                              if (quantity[i] === 1 || !quantityValue[i])
                                return;
                              setQuantity(dispatch, quantityValue);
                            }}
                          ></i>
                          <i
                            className="fas fa-plus-circle"
                            style={{ fontSize: "13px" }}
                            onClick={_ => {
                              let quantityValue = { ...quantity };
                              quantityValue[i] =
                                (quantityValue[i] ? quantityValue[i] : 1) + 1;
                              console.log("quantityValue", quantityValue);

                              if (quantityValue[i] > parseInt(element.quantity))
                                return;
                              setQuantity(dispatch, quantityValue);
                            }}
                          ></i>
                        </div>
                      </p>
                    </div>
                  </div>
                </>
              );
            }

            return (
              <>
                <div
                  className={
                    state.selected === i &&
                    !state.dropIns &&
                    !state.installments
                      ? props.classes.planSelected + " pricingcard"
                      : props.classes.plan + " pricingcard"
                  }
                  onClick={() => {
                    setSelected(dispatch, i);
                    setDropIns(dispatch, false);
                    setInstallments(dispatch, false);
                  }}
                  key={i}
                >
                  <div className="radio-btn-positioning-container">
                    <Radio
                      checked={state.selected === i}
                      // onChange={handleChange}
                      value="d"
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "D" }}
                      size="large"
                      classes={{
                        root: props.classes.radio,
                        checked: props.classes.checkedRadio
                      }}
                    />
                  </div>
                  <div style={{ width: "90%" }}>
                    <p className="pricingcard-name">
                      {element.description ? (
                        <span style={{ fontStyle: "italic" }}>
                          {element.description}
                        </span>
                      ) : (
                        "Semester"
                      )}
                      {isPlanPartOfPromotion(promotion, element) && (
                        <>
                          {" "}
                          {"- "}
                          <BookingDetailPromotionCard promotion={promotion} />
                        </>
                      )}
                    </p>
                    <p className="pricingcard-days">
                      {getFormattedProgramDate(
                        program.program_start,
                        program.number_of_weeks
                      )}
                    </p>
                    <span
                      style={{ fontWeight: "normal", fontSize: "12px" }}
                    ></span>
                  </div>

                  <div style={{ width: "60px" }}>
                    <p className="pricingcard-amount">
                      {isPromotionAppliedToProgramPlan(promotion, element) ? (
                        <>
                          $
                          {Math.max(
                            0,
                            element.total_price -
                              promotion_discount(promotion, element.total_price)
                          ).toFixed(2)}
                        </>
                      ) : (
                        <>${element.total_price}</>
                      )}
                    </p>
                  </div>
                </div>
                {element.installments && (
                  <div
                    className={
                      selected === i && installments
                        ? props.classes.planSelected + " pricingcard"
                        : props.classes.plan + " pricingcard"
                    }
                    onClick={() => {
                      setSelected(dispatch, i);
                      setDropIns(dispatch, false);
                      setInstallments(dispatch, true);
                    }}
                    key={i}
                  >
                    <div className="radio-btn-positioning-container">
                      <Radio
                        checked={state.selected === i}
                        // onChange={handleChange}
                        value="d"
                        color="default"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "D" }}
                        size="large"
                        classes={{
                          root: props.classes.radio,
                          checked: props.classes.checkedRadio
                        }}
                      />
                    </div>
                    <div style={{ width: "90%" }}>
                      <p className="pricingcard-name">
                        Semester - Inst
                        {isPlanPartOfPromotion(promotion, element) && (
                          <>
                            {" "}
                            {"- "}
                            <BookingDetailPromotionCard promotion={promotion} />
                          </>
                        )}
                      </p>
                      <p className="pricingcard-days">
                        {format(new Date(program.program_start), "LL/dd/yy")} -{" "}
                        {format(
                          addWeeks(
                            new Date(program.program_start),
                            program.number_of_weeks
                          ),
                          "LL/dd/yy"
                        )}
                      </p>
                      <span
                        style={{ fontWeight: "normal", fontSize: "12px" }}
                      ></span>
                    </div>

                    <div style={{ width: "60px" }}>
                      <p className="pricingcard-amount">
                        $
                        {parseFloat(
                          (element.total_price /
                            element.installments_breakdown) *
                            (1 + parseInt(element.installments_interest) / 100)
                        ).toFixed(0)}{" "}
                        / {element.installments_plan}
                      </p>
                    </div>
                  </div>
                )}
                {element.dropIns && (
                  <div
                    className={
                      state.selected === i && state.dropIns
                        ? props.classes.planSelected + " pricingcard"
                        : props.classes.plan + " pricingcard"
                    }
                    onClick={() => {
                      setSelected(dispatch, i);
                      setDropIns(dispatch, true);
                      setInstallments(dispatch, false);
                    }}
                    key={i}
                  >
                    <div className="radio-btn-positioning-container">
                      <Radio
                        checked={state.selected === i}
                        // onChange={handleChange}
                        value="d"
                        color="default"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "D" }}
                        size="large"
                        classes={{
                          root: props.classes.radio,
                          checked: props.classes.checkedRadio
                        }}
                      />
                    </div>
                    <div style={{ width: "90%" }}>
                      <p className="pricingcard-name">
                        Semester - Drop-in
                        {isPlanPartOfPromotion(promotion, element) && (
                          <>
                            {" "}
                            {"- "}
                            <BookingDetailPromotionCard promotion={promotion} />
                          </>
                        )}
                      </p>
                      <p className="pricingcard-days">
                        {getFormattedProgramDate(
                          program.program_start,
                          program.number_of_weeks
                        )}
                      </p>
                      <span
                        style={{ fontWeight: "normal", fontSize: "12px" }}
                      ></span>
                    </div>

                    <div style={{ width: "60px" }}>
                      <p className="pricingcard-amount">
                        $
                        {parseFloat(
                          (element.total_price / element.dayCount) *
                            (1 + parseInt(element.dropin_interest) / 100)
                        ).toFixed(0)}
                      </p>
                    </div>
                  </div>
                )}
              </>
            );
          })}

        {state.selected !== -1 && (
          <SummarySubTotal
            plans={state?.program?.plans}
            selected={state?.selected}
            quantity={quantity[state?.selected] ? quantity[state?.selected] : 1}
            isPromo={isPromotionAppliedToProgramPlan(
              promotion,
              JSON.parse(state?.program?.plans[state?.selected])
            )}
            promotion={promotion}
          />
        )}

        {state.program.isCartDisabled ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(52, 63, 100)`,
                color: `white`,
                marginTop: "10px",
                fontWeight: `bold`,
                border: 0,
                cursor: "not-allowed"
              }}
            >
              Enroll Here Now
            </button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(52, 63, 100)`,
                color: `white`,
                fontWeight: `bold`,
                border: 0,
                marginTop: "40px",
                cursor: "not-allowed"
              }}
            >
              Add to Cart
            </button>
            <span>-------- OR --------</span>
            <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(211,52,49)`,
                color: `white`,
                marginTop: "10px",
                fontWeight: `bold`,
                border: 0,
                cursor: "not-allowed"
              }}
            >
              Enroll Here Now
            </button>
          </div>
        )}
      </div>
    </>
  );
};

function parseProgramPlanFromStringToJSON(e) {
  return typeof e === "string" ? JSON.parse(e) : e;
}

function isPlanPartOfPromotion(promotion, plan) {
  return promotion.plan_price_ticketId.indexOf(plan.id) !== -1;
}

function displayElementTiming(element) {
  if (!element.isReplaceTimeEnabled) {
    return (
      <span style={{ fontStyle: "italic" }}>
        {" ("}
        {element.timing[0].replace(/\s/g, "").toLowerCase()} -{" "}
        {element.timing[1].replace(/\s/g, "").toLowerCase()})
      </span>
    );
  } else {
    return (
      <span>
        {" ("}
        {element.description}
        {")"}
      </span>
    );
  }
}

ProgramBookingDetail.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(ProgramBookingDetail);
