import React, { useGlobal } from "reactn";
import { format } from "date-fns";

const MyEarningsModal = ({ earnings, next }) => {
  const [dsUser] = useGlobal("dsUser");

  const getRefundComponent = amount => {
    return (
      <p
        style={{
          color: "#a1a1a1",
          paddingBottom: "40px",
          fontStyle: "italic",
          fontSize: "0.9rem"
        }}
      >
        Refunds (to-date):{" "}
        {amount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD"
        })}
      </p>
    );
  };

  const getAmountComponent = amount => {
    return <h3>${amount}</h3>;
  };

  return (
    <div className="boxes">
      <div className="box">
        <h2>PAID ORDERS</h2>
        <div
          className="info"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          {getAmountComponent(
            earnings.oneTime
              ? parseFloat(earnings.oneTime)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : "0.00"
          )}
          {getRefundComponent(earnings.refundedOneTime)}
          <p>
            Since{" "}
            {dsUser ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
          </p>
        </div>
      </div>
      <div className="box">
        <h2>RECURRING PAYMENTS</h2>
        <div
          className="info"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          {getAmountComponent(
            earnings.recurring
              ? parseFloat(earnings.recurring)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : "0.00"
          )}
          {getRefundComponent(earnings.refundedRecurring)}
          <p>
            Since{" "}
            {dsUser ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
          </p>
        </div>
      </div>
      <div className="box">
        <h2>CUMULATIVE AMOUNT</h2>
        <div
          className="info"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          {getAmountComponent(
            earnings.recurring || earnings.oneTime
              ? parseFloat((earnings.recurring || 0) + (earnings.oneTime || 0))
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          )}
          {getRefundComponent(
            earnings.refundedOneTime + earnings.refundedRecurring
          )}
          <p>
            Since{" "}
            {dsUser ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MyEarningsModal;

// import React, { useGlobal } from "reactn";
// import { format } from "date-fns";

// const MyEarningsModal = ({ earnings, next }) => {
//   const [dsUser] = useGlobal("dsUser");

//   return (
//     <div className="boxes">
//       <div className="box">
//         <h2>PAID ORDERS</h2>
//         <div className="info">
//           <h3>
//             $
//             {earnings.oneTime
//               ? parseFloat(earnings.oneTime)
//                   .toFixed(2)
//                   .replace(/\d(?=(\d{3})+\.)/g, "$&,")
//               : "0.00"}
//           </h3>
//           <p>
//             Since{" "}
//             {dsUser ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
//           </p>
//         </div>
//       </div>
//       <div className="box">
//         <h2>RECURRING PAYMENTS</h2>
//         <div className="info">
//           <h3>
//             $
//             {earnings.recurring
//               ? parseFloat(earnings.recurring)
//                   .toFixed(2)
//                   .replace(/\d(?=(\d{3})+\.)/g, "$&,")
//               : "0.00"}
//           </h3>
//           <p>
//             Since{" "}
//             {dsUser ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
//           </p>
//         </div>
//       </div>
//       <div className="box">
//         <h2>CUMULATIVE AMOUNT</h2>
//         <div className="info">
//           <h3>
//             $
//             {earnings.recurring || earnings.oneTime
//               ? parseFloat((earnings.recurring || 0) + (earnings.oneTime || 0))
//                   .toFixed(2)
//                   .replace(/\d(?=(\d{3})+\.)/g, "$&,")
//               : 0}
//           </h3>
//           <p>
//             Cumulative total since{" "}
//             {dsUser ? format(new Date(dsUser.createdAt), "LLL yyyy") : "N/A"}
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MyEarningsModal;
