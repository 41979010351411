import React, { useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  makeStyles,
  InputBase,
  Popover,
  List,
  Button
} from "@material-ui/core";
import { Edit, Delete, Clear } from "@material-ui/icons";
import ReactTooltip from "react-tooltip";
import ConfirmArchiveModal from "../../components/modals/ConfirmArchiveModal";

import ListItemBtn from "../charts/ListItemBtn";
const colors = {
  green: "#2AA897",
  lightGreen: "#4FBE75",
  white: "#FFFFFF",
  black: "#000000",
  gray: "#F4F6FC",
  midGray: "#EEEEEE",
  borderGray: "#BBBBBB",
  darkGray: "#808080"
};

const useStyles = makeStyles(theme => ({
  header: {
    paddingBottom: "1.5rem",
    borderBottom: `1px solid ${colors.midGray}`,
    alignItems: "center",
    justifyContent: "flex-start",
    direction: "row"
  },
  templateName: {
    fontWeight: "bold",
    borderRight: `1px solid ${colors.midGray}`
  },
  editTemplateText: {
    color: colors.darkGray,
    paddingLeft: "1rem"
  },
  typeSubjectRows: {
    alignItems: "center",
    padding: "0.5rem 0 0.5rem 0",
    borderBottom: `1px solid ${colors.midGray}`
  },
  typeSubjectTitles: {
    color: colors.darkGray
  },
  formControl: {
    minWidth: 120
  },
  popover: {
    zIndex: "9999999999 !important"
  }
}));

const TemplateEditorHeader = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const classes = useStyles();
  const {
    allTemplates,
    templateToEdit,
    setTemplateToEdit,
    handleClose,
    handleDelete,
    subject,
    setSubject,
    title,
    setTitle
  } = props;

  const handlePopperClick = event => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Sets the template.
   *
   * @param {Object} e An object representing a template.
   */
  const handleTemplateClick = e => {
    const obj = {
      ...allTemplates.find(tmp => tmp.id === e.id)
    };
    setTemplateToEdit(obj);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Grid container>
      {showConfirmModal && (
        <ConfirmArchiveModal
          onClose={_ => setShowConfirmModal(false)}
          onSubmit={_ => handleDelete()}
          toArchive={true}
          modalBody="Are you sure you would like to delete this template? This cannot be undone."
        />
      )}
      <Grid container item xs={12} className={classes.header}>
        <Grid item container className={classes.editTemplateText} xs={4}>
          {templateToEdit && (
            <Button
              id="newButton"
              onClick={() => {
                setAnchorEl(null);
                setTemplateToEdit(null);
              }}
              variant="outlined"
              color="primary"
            >
              New
            </Button>
          )}
          <Button
            id="editButton"
            onClick={handlePopperClick}
            variant="outlined"
            color="primary"
            aria-describedby={id}
          >
            Edit Template
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            className={classes.popover}
          >
            <List>
              {allTemplates.length === 0 && (
                <ListItemBtn
                  text="No Templates to Edit!"
                  onClick={() => {
                    setAnchorEl(null);
                  }}
                />
              )}
              {allTemplates.map((e, key) => (
                <ListItemBtn
                  key={key}
                  text={e.title}
                  onClick={() => {
                    handleTemplateClick(e);
                  }}
                />
              ))}
            </List>
          </Popover>
        </Grid>
        <Grid item className={classes.templateName} xs={5}>
          <InputBase
            autoComplete="off"
            fullWidth={true}
            value={title}
            placeholder="New Template Title..."
            onChange={e => setTitle(e.target.value)}
          />
        </Grid>
        <Grid item container xs={3} alignItems="flex-start" justify="flex-end">
          {templateToEdit && (
            <>
              <IconButton
                onClick={() => {
                  setShowConfirmModal(true);
                }}
                data-for="delete"
                data-tip
              >
                <Delete />
              </IconButton>
              <ReactTooltip id="delete" place="top" effect="solid" type="info">
                Delete Template
              </ReactTooltip>
            </>
          )}
          <IconButton tabIndex={-1} onClick={handleClose}>
            <Clear />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container item className={classes.typeSubjectRows} xs={12}>
        <Grid item xs={12}>
          <InputBase
            autoComplete="off"
            placeholder="Subject..."
            fullWidth={true}
            value={subject}
            onChange={e => setSubject(e.target.value)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TemplateEditorHeader;
