import React from "reactn";
import StepBar from "../../../components/UI/StepBar";
import { StepIndicator } from "../../../components/UI";
import Validators from "../../../validators";
import ProgramSetupBasicInfo from "./BasicInfo";
import ProgramIntro from "./ProgramIntro";
import Gallery from "./Gallery";
import ProgramAmenitiesSchedule from "./ProgramAmenitiesSchedule";
import ProgramHolidays from "./ProgramHolidays";
import axios from "axios";
import ConfirmProgramForm from "./forms/confirmProgramForm";

class ProgramSetup extends React.PureComponent {
  constructor() {
    super();
    if (this.global.dsUser.accountType === "Admin") {
      require("../../../store/educators/programSetup");
    }
    this.state = {
      step: 0,
      certifyModalShown: false
    };
    this.titles = [
      "Basic Info",
      "Program Intro",
      "Gallery",
      "Amenities & Schedule",
      "Calendar"
    ];
    this.validate = [
      () =>
        Validators.Educators.MyProgram.ValidateProgramBasicInfo(
          this.global.myProgramInfo
        ) &&
        Validators.Educators.MyProgram.ValidateProgramLocationHours(
          this.global.myProgramLocationHours
        ),
      () =>
        Validators.Educators.MyProgram.ValidateProgramIntro(
          this.global.myProgramIntro
        ),
      () =>
        this.global.myProgramGallery.programCoverPhoto !== "" &&
        this.global.myProgramGallery.profilePhoto !== "",
      () => this.global.myProgramDailySchedule.length > 0,
      () =>
        this.global.myProgramVacations.amount !== "" &&
        this.global.myProgramVacations.charge !== ""
    ];
    this.steps = [
      { name: "Basic Info", validateFn: this.validate[0] },
      { name: "Program Intro", validateFn: this.validate[1] },
      { name: "Gallery", validateFn: this.validate[2] },
      { name: "Amenities & Schedule", validateFn: this.validate[3] },
      { name: "Calendar", validateFn: this.validate[4] }
    ];
    this.setGlobal({
      pathway: [
        ...this.global.pathway.slice(0, 1),
        "Program Setup",
        this.titles[this.state.step]
      ]
    });
    this.saveProfile = this.saveProfile.bind(this);
    this.publishProgram = this.publishProgram.bind(this);
    this.previewProgram = this.previewProgram.bind(this);
  }

  async componentDidMount() {
    const params = this.props.match.params;
    if (!params.id) {
      this.dispatch.getProgram();
    } else {
      try {
        const ep = `${process.env.REACT_APP_API}/adm/educator/program/${params.id}`;
        this.setGlobal({ loading: true });
        const res = await axios.get(ep);
        if (res.data.success) {
          this.setGlobal(res.data.data);
          this.forceUpdate();
        }
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (er) {
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }
  }

  getStep = () => {
    const { step } = this.state;
    switch (step) {
      case 0:
        return <ProgramSetupBasicInfo />;
      case 1:
        return <ProgramIntro />;
      case 2:
        return <Gallery />;
      case 3:
        return <ProgramAmenitiesSchedule />;
      case 4:
        return <ProgramHolidays />;
      default:
        return;
    }
  };

  changePage = async page => {
    this.setState({ step: page });
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 2), this.titles[page]]
    });
    const params = this.props.match.params;
    if (!params.id) {
      if (!this.global.profile.programSubmitted) {
        await this.dispatch.saveProgram();
      }
    }
  };

  getCompletion() {
    let completed = 0;
    const ep = `${process.env.REACT_APP_API}/adm/educator/program/progress`;
    for (let v of this.validate) {
      completed += v();
    }
    if (this.global.profile && this.global.profile.id)
      axios.post(ep, {
        id: this.global.profile.id,
        progressPrg: (completed / this.validate.length) * 100
      });
    return (completed / this.validate.length) * 100;
  }

  checkValid() {
    const { step } = this.state;
    return this.validate[step] && !this.validate[step]();
  }

  async saveProfile(e) {
    const { step } = this.state;
    const params = this.props.match.params;
    switch (e.target.name) {
      case "next":
        this.setState({ step: step + 1 });
        if (!this.global.profile.programSubmitted && !params.id) {
          await this.dispatch.saveProgram();
        }
        break;
      case "back":
        this.setState({ step: step - 1 });
        if (!this.global.profile.programSubmitted && !params.id) {
          await this.dispatch.saveProgram();
        }
        break;
      case "save":
        if (
          (!this.global.profile.programSubmitted && !params.id) ||
          this.global.dsUser.accountType === "Admin"
        ) {
          await this.dispatch.saveProgram();
        }
        break;
      case "submit":
        if (
          !this.global.profile.programSubmitted &&
          this.getCompletion() === 100
        ) {
          await this.dispatch.saveProgram(true);
        }
        break;
      // if (
      //   window.confirm(
      //     "Once submitted, you can no longer make changes to your program."
      //   )
      // ) {
      //   await this.dispatch.saveProgram(true);
      // }
      // break;
      default:
        return;
    }
  }

  previewProgram() {
    const windowReference = window.open();
    if (this.validate[0]() && this.validate[1]()) {
      this.saveProfile({ target: { name: "save" } }).then(_ => {
        windowReference.location = `/preview/${this.global.profile.dsUserId}`;
      });
    }
  }

  async componentWillUnmount() {
    const params = this.props.match.params;
    if (!this.global.profile.programSubmitted && !params.id) {
      await this.dispatch.saveProgram();
    }
  }

  publishProgram = async (stage = 1) => {
    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/adm/educator/program/${params.id}/publish`;
    if (this.global.profile.programSubmitted) {
      try {
        this.setGlobal({ loading: true });
        await this.dispatch.saveProgram();
        if (this.global.lastAPICall.data.success) {
          const res = await axios.post(ep, { publish: true, stage: stage });
          if (res.data.success) {
            this.setGlobal({
              profile: res.data.data
            });
          }
          this.setGlobal({ lastAPICall: res });
        }
        this.setGlobal({ loading: false });
      } catch (ex) {
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }
  };

  render() {
    return !this.global.myProgramInfo || !this.global.profile ? null : (
      <div className="container-fluid">
        {!this.state.certifyModalShown ? null : (
          <ConfirmProgramForm
            onClose={certified => {
              if (certified) {
                this.setState({
                  tosModalShown: true,
                  certifyModalShown: false
                });
              } else {
                this.setState({ certifyModalShown: false });
              }
            }}
          />
        )}
        <div className="cont">
          <h1>
            Program Setup <span>{this.titles[this.state.step]}</span>
          </h1>
        </div>
        <StepBar
          {...{
            steps: this.steps,
            changeFn: this.changePage,
            current: this.state.step
          }}
        />
        <StepIndicator value={this.getCompletion()} />
        {this.getStep()}
        <div className="cont mb-5 justify-evenly">
          <div className="buttons">
            <button
              onClick={() => {
                this.previewProgram();
              }}
              disabled={!this.validate[0]() || !this.validate[1]()}
              name="preview"
              className="savebtn"
            >
              PREVIEW
            </button>
            <button onClick={this.saveProfile} name="save" className="savebtn">
              SAVE
            </button>
            {this.global.dsUser.accountType !== "Admin" ? null : (
              <button
                onClick={() => this.publishProgram(0)}
                disabled={!this.global.profile}
                name="publish"
                className="savebtn"
              >
                PUBLISH (STG 0)
              </button>
            )}
            {this.global.dsUser.accountType !== "Admin" ? null : (
              <button
                onClick={() => this.publishProgram()}
                disabled={!this.global.profile}
                name="publish"
                className="savebtn"
              >
                PUBLISH (STG 1)
              </button>
            )}
            {this.global.dsUser.accountType !== "Admin" ? null : (
              <button
                onClick={() => this.publishProgram(2)}
                disabled={!this.global.profile}
                name="publish"
                className="savebtn"
              >
                PUBLISH (STG 2)
              </button>
            )}
            {this.global.dsUser.accountType !== "Admin" ? null : (
              <button
                onClick={() => this.publishProgram(-1)}
                disabled={!this.global.profile}
                name="publish"
                className="cancelbtn"
              >
                UNPUBLISH
              </button>
            )}
            <button
              onClick={this.saveProfile}
              disabled={
                this.global.profile.programSubmitted ||
                this.getCompletion() !== 100
              }
              name="submit"
              className="nextbtn"
            >
              SUBMIT <i className="fas fa-arrow-right"></i>
            </button>
          </div>
          <div className="buttons">
            <button
              onClick={this.saveProfile}
              disabled={this.state.step === 0}
              name="back"
              className="nextbtn"
            >
              BACK
            </button>
            <button
              onClick={this.saveProfile}
              disabled={
                this.state.step === this.titles.length - 1 ||
                this.global.profile.programSubmitted
              }
              name="next"
              className="nextbtn"
            >
              NEXT
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default ProgramSetup;
