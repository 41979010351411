import React from "reactn";
import { getNotificationText, parseVerb } from "./notificationUtils";
import moment from "moment";
import { useHistory } from "react-router-dom";

const SingleNotification = ({ activityGroup, closeDropdown, ...props }) => {
  const { activities } = activityGroup;

  const humanizeTimestamp = timestamp => {
    const time = moment.utc(timestamp); // parse time as UTC

    const now = moment(); // Not in future humanized time

    return moment.min(time, now).from(now);
  };

  const history = useHistory();

  const { header, body } = getNotificationText(activities);
  const latestActivity = activities[0];

  const handleNotificationClick = () => {
    const { link } = latestActivity;
    if (link) {
      history.push(link);
      closeDropdown();
    }
  };

  const { imageSrc } = parseVerb(latestActivity.verb);

  return (
    <div
      className={`notification ${activityGroup.is_seen ? "seen" : ""} ${
        latestActivity.link ? "link" : ""
      }`}
      onClick={handleNotificationClick}
    >
      <div className="img-wrapper">
        <img src={imageSrc} alt="" />
      </div>
      <div className="main">
        <strong className="head">{header}</strong>
        <br />
        <span className="body">{body}</span>
      </div>
      <div className="timestamp">
        <sub>{humanizeTimestamp(latestActivity.time)}</sub>
      </div>
    </div>
  );
};

export default SingleNotification;
