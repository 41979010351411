import { setGlobal, addReducer } from "reactn";
import axios from "axios";
import { CometChat } from "@cometchat-pro/chat";
import {
  setEducators,
  setAdmin,
  setParents,
  setPartner,
  setFranchiser
} from "../";
import { toast } from "react-toastify";
import Home from "../../pages/partners/home/home";
import TaskReminders from "../../pages/partners/TasksReminders";
import Reporting from "../../pages/partners/Reporting/Reporting";
import UserManagement from "../../pages/partners/UserManagement";
import UserManagementDetails from "../../pages/partners/teamManagment/UserManagementDetails";
import Resources from "../../pages/partners/Resources";
import ResourcesManage from "../../pages/partners/ManageResources";
import NewsUpdates from "../../pages/partners/NewsUpdates";
import CreateNewUpdates from "../../pages/partners/updatesandnews/Updates";
import Profile from "../../pages/partners/profile/Profile";
import Connections from "../../pages/partners/Connections";
import SingleConnectionsOverview from "../../pages/partners/SingleConnectionsOverview";
import SendMessage from "../../pages/partners/communications/SendMessage";
import Communications from "../../pages/partners/communications/Communications";
import Settings from "../../pages/parents/accountSettings/accountSettings";
import Expenses from "../../pages/partners/expenseTracker/expenseTracker";
import Calendar from "../../pages/partners/Calendar";
import StaffSchedules from "../../pages/partners/StaffSchedules";
import Forms from "../../pages/partners/Forms";
import FormsLeads from "../../pages/partners/FormLeads";
import Applications from "../../pages/partners/Applications";
import ProgramList from "../../pages/partners/ProgramList";
import PromotionList from "../../pages/partners/promotions/PromotionList";
import PromotionSetup from "../../pages/partners/promotions/PromotionSetup";
import EventsList from "../../pages/partners/EventList";
import MembershipsList from "../../pages/partners/MembershipList";
import PartiesList from "../../pages/partners/PartiesList";
import PartiesSetup from "../../pages/partners/PartiesCreate/PartiesSetup";
import Coupon from "../../pages/partners/Coupon";
import OnlineVirtual from "../../pages/partners/OnlineVirtual";
import ProgramStructure from "../../pages/partners/programSetup/Program";
import MembershipsCreate from "../../pages/partners/MembershipsCreate/MembershipSetup";
import ManagePrograms from "../../pages/partners/programManage";
import ManageProgramsSingle from "../../pages/partners/manageSingleProgram";
import ManageEvent from "../../pages/partners/eventsManage";
import ManageEventSingle from "../../pages/partners/eventsManageSingle";
import ManageParty from "../../pages/partners/partiesManage";
import ManagePartySingle from "../../pages/partners/partiesManageSingle";
import Membershipmanage from "../../pages/partners/membershipManage";
import ApplicationView from "../../pages/partners/viewApplication";
import MembershipmanageSingle from "../../pages/partners/membershipManageSingle";
import Orders from "../../pages/partners/Orders";
import Appointments from "../../pages/partners/Appointments";
import Appointments_Avail from "../../pages/partners/availability";
import FormBuilder from "../../pages/partners/Formbuilder";
import EventsSetup from "../../pages/partners/EventsCreate/EventsSetup";
import FamilyApp from "../../pages/partners/FamilyProfile/familyProfile";
import MyEarnings from "../../pages/partners/myEarnings/myEarnings";
import RecurringPayments from "../../pages/partners/recurringPayments";
import Attendance from "../../pages/partners/attendance/attendance";
import AttendanceNoChild from "../../pages/partners/attendance/attendanceNoChild";
import GiftCard from "../../pages/partners/GiftCards/GiftCardSetup";
import GiftCardPurchases from "../../pages/partners/giftpurchases";
import ManageGift from "../../pages/partners/ManageGift";
import GiftCardList from "../../pages/partners/Giftcardslist";
import SimpleAttendance from "../../pages/partners/attendancePresentAbsent/attendance";
import SimpleAttendanceNoChild from "../../pages/partners/attendancePresentAbsent/attendanceNoChild";
import ReportsAttendance from "../../pages/partners/reportAnalysis/ReportsAttendance";
import ExpenseReport from "../../pages/partners/reportAnalysis/ExpenseReport";
import ListingSales from "../../pages/partners/reportAnalysis/ListingSales";
import OverdueInvoices from "../../pages/partners/reportAnalysis/OverdueInvoices";
import RevenueClient from "../../pages/partners/reportAnalysis/RevenueClient";
import RevenueSummary from "../../pages/partners/reportAnalysis/RevenueSummary";
import InvoiceSummary from "../../pages/partners/reportAnalysis/InvoiceSummary";
import ParticipantReports from "../../pages/partners/reportAnalysis/StudentReports";
import ContactsReport from "../../pages/partners/reportAnalysis/ContactsReport";
import TaxSummary from "../../pages/partners/reportAnalysis/TaxSummary";
import TransactionsReport from "../../pages/partners/reportAnalysis/TransactionsReport";
import DiscountsReport from "../../pages/partners/reportAnalysis/DiscountsReport";
import InvitesReport from "../../pages/partners/reportAnalysis/InvitesReport";
import OnlineVirtualSetup from "../../pages/partners/onlineVirtual/OnlineVirtualSetup";
import Milestones from "../../pages/partners/Milestones";
import ViewMilestones from "../../pages/partners/ViewMilestones";
import Customization from "../../pages/partners/Customization";
import OnlineVirtualManage from "../../pages/partners/OnlineVirtualManage";
import OnlineVirtualSingle from "../../pages/partners/OnlineVirtualSingle";
import ManageOnlineVirtualSingle from "../../pages/partners/manageSingleOnlineVirtual";
import Rooms from "../../pages/partners/Rooms";
import Observations from "../../pages/partners/Observations";
import NewsFeed from "../../pages/partners/newsFeed";
import ChildProfile from "../../pages/partners/childProfile/ChildProfile";
import InvoiceView from "../../pages/partners/invoices/NewInvoiceView";
import InvoiceBuilder from "../../pages/partners/invoices/invoiceBuilder";
import SubscriptionEditor from "../../pages/partners/subscriptionEditor/SubscriptionEditor";
import SubscriptionView from "../../pages/partners/subscriptionEditor/SubscriptionEditor";
import Active from "../../pages/partners/Active";

import FranchiseDashboard from "../../pages/franchiser/FranchiseDashboard";
import FranchiseSettings from "../../pages/franchiser/accountSettings/accountSettings";
import { deleteConnectionsData } from "../../Caching";
import FranchiseFinancials from "../../pages/franchiser/FranchiseFinancials";

function generateFranchiseRoutes() {
  return [
    { path: "/", component: FranchiseDashboard },
    { path: "/settings", component: FranchiseSettings },
    { path: "/financials", component: FranchiseFinancials }
  ];
}

function generateRoutes(subpartner) {
  if (subpartner)
    return [
      {
        path: "/",
        component: Calendar
      },
      { path: "/newsfeed", component: NewsFeed },
      { path: "/observation", component: Observations },
      { path: "/rooms/program/:id", component: Rooms },
      { path: "/online/manage", component: OnlineVirtualManage },
      { path: "/customization", component: Customization },
      { path: "/milestones/view/:id", component: ViewMilestones },
      { path: "/milestones", component: Milestones },
      { path: "/online-virtual/setup", component: OnlineVirtualSetup },
      { path: "/online-virtual/setup/:id", component: OnlineVirtualSetup },
      { path: "/reports/attendance", component: ReportsAttendance },
      { path: "/reports/expense-report", component: ExpenseReport },
      { path: "/reports/listing-sales", component: ListingSales },
      { path: "/reports/overdue-invoices", component: OverdueInvoices },
      { path: "/reports/client-revenue", component: RevenueClient },
      { path: "/reports/revenue-summary", component: RevenueSummary },
      { path: "/reports/participant-reports", component: ParticipantReports },
      { path: "/reports/contacts-report", component: ContactsReport },
      { path: "/reports/tax-summary", component: TaxSummary },
      { path: "/reports/transactions-report", component: TransactionsReport },
      { path: "/reports/invoice-summary", component: InvoiceSummary },
      { path: "/reports/discounts-report", component: DiscountsReport },
      { path: "/reports/invites-report", component: InvitesReport },
      { path: "/online", component: OnlineVirtual },
      { path: "/coupon", component: Coupon },
      { path: "/reporting", component: Reporting },
      { path: "/expense", component: Expenses },
      { path: "/tasks", component: TaskReminders },
      { path: "/team-management", component: UserManagement },
      { path: "/team-management/:id", component: UserManagementDetails },
      { path: "/resources", component: Resources },
      { path: "/my-earnings", component: MyEarnings },
      { path: "/complete", component: FamilyApp },
      { path: "/resources-manage/:id", component: ResourcesManage },
      { path: "/news-updates", component: NewsUpdates },
      { path: "/news-create", component: CreateNewUpdates },
      { path: "/settings", component: Settings },
      { path: "/profile", component: Profile },
      { path: "/recurring", component: RecurringPayments },
      {
        path: "/recurring/upcoming/:subId",
        component: SubscriptionEditor
      },
      {
        path: "/recurring/invoice/:invoiceId",
        component: SubscriptionEditor
      },
      { path: "/connections", component: Connections },
      {
        path: "/single/connections/:email/:isActive",
        component: SingleConnectionsOverview
      },
      { path: "/communications", component: Communications },
      { path: "/send-message", component: SendMessage },
      { path: "/calendar", component: Calendar },
      { path: "/schedules", component: StaffSchedules },
      { path: "/forms", component: Forms },
      { path: "/forms/builder", component: FormBuilder },
      { path: "/form-leads", component: FormsLeads },
      { path: "/applications", component: Applications },
      { path: "/active-leads", component: Active },
      { path: "/programs/list", component: ProgramList },
      { path: "/programs/setup", component: ProgramStructure },
      { path: "/promotion/list", component: PromotionList },
      { path: "/promotion/setup", component: PromotionSetup },
      { path: "/promotion/setup/:id", component: PromotionSetup },
      { path: "/events/list", component: EventsList },
      { path: "/events/setup", component: EventsSetup },
      { path: "/memberships/list", component: MembershipsList },
      { path: "/memberships/setup", component: MembershipsCreate },
      { path: "/parties/list", component: PartiesList },
      { path: "/parties/setup", component: PartiesSetup },
      { path: "/parties/setup/:id", component: PartiesSetup },
      { path: "/programs/manage", component: ManagePrograms },
      { path: "/manage/attendance/:id", component: Attendance },
      { path: "/manage/attendance/user/:id", component: AttendanceNoChild },
      { path: "/manage/simple-attendance/:id", component: SimpleAttendance },
      {
        path: "/manage/simple-attendance/user/:id",
        component: SimpleAttendanceNoChild
      },
      {
        path: "/programs/manage/single/:id",
        component: ManageProgramsSingle
      },
      {
        path: "/online/manage/single/:id",
        component: ManageOnlineVirtualSingle
      },
      { path: "/events/manage", component: ManageEvent },
      {
        path: "/events/manage/single/:id",
        component: ManageEventSingle
      },
      { path: "/parties/manage", component: ManageParty },
      {
        path: "/parties/manage/single/:id",
        component: ManagePartySingle
      },
      { path: "/gift_cards", component: GiftCardList },
      { path: "/gift_cards/setup", component: GiftCard },
      { path: "/gift_cards/setup/:id", component: GiftCard },
      { path: "/gift_cards/manage", component: ManageGift },
      { path: "/gift_cards/manage/single/:id", component: GiftCardPurchases },
      { path: "/memberships/manage", component: Membershipmanage },
      { path: "/application/:id", component: ApplicationView },
      {
        path: "/memberships/manage/single/:id",
        component: MembershipmanageSingle
      },
      {
        path: "/virtual/manage/single/:id",
        component: OnlineVirtualSingle
      },
      { path: "/events/setup/:id", component: EventsSetup },
      { path: "/memberships/setup/:id", component: MembershipsCreate },
      { path: "/forms/builder/:id", component: FormBuilder },
      { path: "/programs/setup/:id", component: ProgramStructure },
      { path: "/news-create/:id", component: CreateNewUpdates },
      { path: "/orders", component: Orders },
      { path: "/orders/orders-receipt/:id", component: InvoiceView },
      { path: "/orders/invoice-builder/:id", component: InvoiceBuilder },
      { path: "/appointments", component: Appointments },
      {
        path: "/appointments/availability",
        component: Appointments_Avail
      },
      { path: "/child-profile/:dsUserId/:childId", component: ChildProfile }
    ];
  else
    return [
      {
        path: "/",
        component: Home
      },
      { path: "/newsfeed", component: NewsFeed },
      { path: "/observation", component: Observations },
      { path: "/rooms/program/:id", component: Rooms },
      { path: "/online/manage", component: OnlineVirtualManage },
      { path: "/customization", component: Customization },
      { path: "/milestones/view/:id", component: ViewMilestones },
      { path: "/milestones", component: Milestones },
      { path: "/online-virtual/setup", component: OnlineVirtualSetup },
      { path: "/online-virtual/setup/:id", component: OnlineVirtualSetup },
      { path: "/reports/attendance", component: ReportsAttendance },
      { path: "/reports/expense-report", component: ExpenseReport },
      { path: "/reports/listing-sales", component: ListingSales },
      { path: "/reports/overdue-invoices", component: OverdueInvoices },
      { path: "/reports/client-revenue", component: RevenueClient },
      { path: "/reports/revenue-summary", component: RevenueSummary },
      { path: "/reports/participant-reports", component: ParticipantReports },
      { path: "/reports/contacts-report", component: ContactsReport },
      { path: "/reports/tax-summary", component: TaxSummary },
      { path: "/reports/transactions-report", component: TransactionsReport },
      { path: "/reports/invoice-summary", component: InvoiceSummary },
      { path: "/reports/discounts-report", component: DiscountsReport },
      { path: "/reports/invites-report", component: InvitesReport },
      { path: "/online", component: OnlineVirtual },
      { path: "/coupon", component: Coupon },
      { path: "/reporting", component: Reporting },
      { path: "/tasks", component: TaskReminders },
      { path: "/team-management", component: UserManagement },
      { path: "/team-management/:id", component: UserManagementDetails },
      { path: "/resources", component: Resources },
      { path: "/expense", component: Expenses },
      { path: "/my-earnings", component: MyEarnings },
      { path: "/complete", component: FamilyApp },
      { path: "/resources-manage/:id", component: ResourcesManage },
      { path: "/news-updates", component: NewsUpdates },
      { path: "/news-create", component: CreateNewUpdates },
      { path: "/settings", component: Settings },
      { path: "/profile", component: Profile },
      { path: "/recurring", component: RecurringPayments },
      {
        path: "/recurring/upcoming/:subId",
        component: SubscriptionEditor
      },
      {
        path: "/recurring/invoice/:invoiceId",
        component: SubscriptionEditor
      },
      { path: "/connections", component: Connections },
      {
        path: "/single/connections/:email/:isActive",
        component: SingleConnectionsOverview
      },
      { path: "/communications", component: Communications },
      { path: "/send-message", component: SendMessage },
      { path: "/calendar", component: Calendar },
      { path: "/schedules", component: StaffSchedules },
      { path: "/forms", component: Forms },
      { path: "/forms/builder", component: FormBuilder },
      { path: "/form-leads", component: FormsLeads },
      { path: "/applications", component: Applications },
      { path: "/active-leads", component: Active },
      { path: "/programs/list", component: ProgramList },
      { path: "/programs/setup", component: ProgramStructure },
      { path: "/promotion/list", component: PromotionList },
      { path: "/promotion/setup", component: PromotionSetup },
      { path: "/promotion/setup/:id", component: PromotionSetup },
      { path: "/events/list", component: EventsList },
      { path: "/events/setup", component: EventsSetup },
      { path: "/gift_cards", component: GiftCardList },
      { path: "/gift_cards/setup", component: GiftCard },
      { path: "/gift_cards/manage", component: ManageGift },
      { path: "/gift_cards/manage/single/:id", component: GiftCardPurchases },
      { path: "/gift_cards/setup/:id", component: GiftCard },
      { path: "/memberships/list", component: MembershipsList },
      { path: "/memberships/setup", component: MembershipsCreate },
      { path: "/parties/list", component: PartiesList },
      { path: "/parties/setup", component: PartiesSetup },
      { path: "/parties/setup/:id", component: PartiesSetup },
      { path: "/programs/manage", component: ManagePrograms },
      { path: "/manage/attendance/:id", component: Attendance },
      { path: "/manage/attendance/user/:id", component: AttendanceNoChild },
      { path: "/manage/simple-attendance/:id", component: SimpleAttendance },
      {
        path: "/manage/simple-attendance/user/:id",
        component: SimpleAttendanceNoChild
      },
      {
        path: "/programs/manage/single/:id",
        component: ManageProgramsSingle
      },
      {
        path: "/online/manage/single/:id",
        component: ManageOnlineVirtualSingle
      },
      { path: "/events/manage", component: ManageEvent },
      {
        path: "/events/manage/single/:id",
        component: ManageEventSingle
      },
      { path: "/parties/manage", component: ManageParty },
      {
        path: "/parties/manage/single/:id",
        component: ManagePartySingle
      },
      { path: "/memberships/manage", component: Membershipmanage },
      { path: "/application/:id", component: ApplicationView },
      {
        path: "/memberships/manage/single/:id",
        component: MembershipmanageSingle
      },
      {
        path: "/virtual/manage/single/:id",
        component: OnlineVirtualSingle
      },
      { path: "/events/setup/:id", component: EventsSetup },
      { path: "/memberships/setup/:id", component: MembershipsCreate },
      { path: "/forms/builder/:id", component: FormBuilder },
      { path: "/programs/setup/:id", component: ProgramStructure },
      { path: "/news-create/:id", component: CreateNewUpdates },
      { path: "/orders", component: Orders },
      { path: "/orders/orders-receipt/:id", component: InvoiceView },
      { path: "/orders/invoice-builder/:id", component: InvoiceBuilder },
      { path: "/appointments", component: Appointments },
      {
        path: "/appointments/availability",
        component: Appointments_Avail
      },
      { path: "/child-profile/:dsUserId/:childId", component: ChildProfile }
    ];
}

addReducer("performLogin", async (global, dispatch, user) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/auth`;
    const res = await axios.post(ep, user);
    if (res.data.success) {
      toast("Successfully logged in");
      localStorage.setItem("ds_token", res.data.data.token);
      if (res.data.data.user.accountType === "Educator") {
        setEducators();
      } else if (res.data.data.user.accountType === "Admin") {
        setAdmin();
      } else if (res.data.data.user.accountType === "Parent") {
        setParents();
      } else if (res.data.data.user.accountType === "Partner") {
        setPartner();
      }
      // const userPromise = CometChat.login(
      //   res.data.data.user.cometchat,
      //   process.env.REACT_APP_COMETCHAT_API
      // );

      /**
       * MESSAGE LISTENER: Listen for new messages
       */

      // (async () =>
      //   CometChat.addMessageListener(
      //     "ds-message-listener",
      //     new CometChat.MessageListener({
      //       onTextMessageReceived: textMessage => {
      //         dispatch.ccReceiveMessage(textMessage);
      //       },
      //       onMediaMessageReceived: mediaMessage => {
      //         // Handle media message
      //       },
      //       onCustomMessageReceived: customMessage => {
      //         console.log(
      //           "Custom message received successfully",
      //           customMessage
      //         );
      //         // Handle custom message
      //       }
      //     })
      //   ))();
      // userPromise.then(_ =>
      //   CometChat.getUnreadMessageCountForAllUsers().then(val =>
      //     setGlobal({ unread: Object.keys(val).length > 0 })
      //   )
      // );

      return {
        ...global,
        pathway: "Home",
        partnerPathway: [{ label: "Home", to: "/" }],
        loading: false,
        lastAPICall: res,
        dsUser: res.data.data.user,
        ...res.data.data.data,
        // userPromise
      };
    } else {
      toast.error("Failed to login");
    }
    return { ...global, loading: false, lastAPICall: res };
  } catch (ex) {}
  return { ...global, loading: false, lastAPICall: null };
});

addReducer("performLoginFranchiser", async (global, dispatch, user) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/auth/franchiser`;
    const res = await axios.post(ep, user);

    if (res.data.success) {
      localStorage.setItem("franchiser_token", res.data.data.token);
      localStorage.setItem("franchiser", true);
      if (res.data.data.user) {
        setFranchiser();
      }

      return {
        ...global,
        pathway: ["Home"],
        partnerPathway: [{ label: "Home", to: "/" }],
        loading: false,
        routes: generateFranchiseRoutes(),
        lastAPICall: res,
        franchiseUser: res.data.data.user
      };
    } else {
      toast.error("Failed to login");
    }
    return { ...global, loading: false, lastAPICall: res };
  } catch (ex) {}
  return { ...global, loading: false, lastAPICall: null };
});

addReducer("performLoginPartners", async (global, dispatch, user) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/auth/partners`;
    const res = await axios.post(ep, user);

    if (res.data.success) {
      localStorage.setItem("ds_token", res.data.data.token);
      localStorage.setItem("partner", true);
      if (res.data.data.user) {
        setPartner();
      }

      return {
        ...global,
        pathway: ["Home"],
        partnerPathway: [{ label: "Home", to: "/" }],
        loading: false,
        routes: generateRoutes(res.data.data.user.sub_user),
        lastAPICall: res,
        dsUser: res.data.data.user,
        ...res.data.data.data
      };
    } else {
      toast.error("Failed to login");
    }
    return { ...global, loading: false, lastAPICall: res };
  } catch (ex) {}
  return { ...global, loading: false, lastAPICall: null };
});

addReducer("performImpersonatePartners", async (global, dispatch, user) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/auth/impersonate/partners/${user.id}`;
    const res = await axios.post(ep, { token: user.token });

    if (res.data.success) {
      localStorage.clear();
      await deleteConnectionsData();
      localStorage.setItem("ds_token", res.data.data.token);
      localStorage.setItem("partner", true);
      if (res.data.data.user) {
        setPartner();
      }

      return {
        ...global,
        pathway: ["Home"],
        partnerPathway: [{ label: "Home", to: "/" }],
        loading: false,
        routes: generateRoutes(res.data.data.user.sub_user),
        lastAPICall: res,
        dsUser: res.data.data.user,
        ...res.data.data.data
      };
    } else {
      toast.error("Failed to login");
    }
    return { ...global, loading: false, lastAPICall: res };
  } catch (ex) {}
  return { ...global, loading: false, lastAPICall: null };
});

addReducer("performImpersonateFranchise", async (global, dispatch, user) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/auth/impersonate/franchiser/${user.id}`;
    const res = await axios.post(ep, user);
    
    if (res.data.success) {
      localStorage.setItem("franchiser_token", res.data.data.token);
      localStorage.setItem("franchiser", true);
      if (res.data.data.user) {
        setFranchiser();
      }

      return {
        ...global,
        pathway: ["Home"],
        partnerPathway: [{ label: "Home", to: "/" }],
        loading: false,
        routes: generateFranchiseRoutes(),
        lastAPICall: res,
        franchiseUser: res.data.data.user
      };
    } else {
      toast.error("Failed to login");
    }
    return { ...global, loading: false, lastAPICall: res };
  } catch (ex) {}
  return { ...global, loading: false, lastAPICall: null };
});

addReducer("logout", async (global, dispatch) => {
  // CometChat.logout().then(
  //   () => {
  //     //Logout completed successfully
  //   },
  //   error => {
  //     //Logout failed with exception
  //   }
  // );
  localStorage.removeItem("ds_token");
  localStorage.removeItem("partner");
  await deleteConnectionsData();

  return {
    ...global,
    dsUser: null,
    ccUser: null,
    ccSelectedContact: {},
    ccSelectedMessages: [],
    unread: false
  };
});

addReducer("logoutFranchiser", async (global, dispatch) => {
  localStorage.removeItem("franchiser_token");
  localStorage.removeItem("franchiser");
  return {
    ...global,
    franchiseUser: null,
    ccUser: null,
    ccSelectedContact: {},
    ccSelectedMessages: [],
    unread: false
  };
});

addReducer("validateToken", async (global, dispatch) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/auth/verify`;
    const res = await axios.post(ep, {
      token: localStorage.getItem("ds_token")
    });
    if (res.data.success) {
      if (res.data.data.user.accountType === "Educator") {
        setEducators();
      } else if (res.data.data.user.accountType === "Admin") {
        setAdmin();
      } else if (res.data.data.user.accountType === "Parent") {
        setParents();
      } else if (res.data.data.user.accountType === "Partner") {
        setPartner();
      }

      // const userPromise = CometChat.login(
      //   res.data.data.user.cometchat,
      //   process.env.REACT_APP_COMETCHAT_API
      // );

      // /**
      //  * MESSAGE LISTENER: Listen for new messages
      //  */

      // (async () => {
      //   CometChat.addUserListener(
      //     "ds-user-listener",
      //     new CometChat.UserListener({
      //       onUserOnline: onlineUser => {},
      //       onUserOffline: offlineUser => {}
      //     })
      //   );

      //   CometChat.addMessageListener(
      //     "ds-message-listener",
      //     new CometChat.MessageListener({
      //       onTextMessageReceived: textMessage => {
      //         dispatch.ccReceiveMessage(textMessage);
      //       },
      //       onMediaMessageReceived: mediaMessage => {
      //         // Handle media message
      //       },
      //       onCustomMessageReceived: customMessage => {
      //         console.log(
      //           "Custom message received successfully",
      //           customMessage
      //         );
      //         // Handle custom message
      //       }
      //     })
      //   );
      // })();
      // userPromise.then(_ =>
      //   CometChat.getUnreadMessageCountForAllUsers().then(val =>
      //     setGlobal({ unread: Object.keys(val).length > 0 })
      //   )
      // );
      // await this.dispatch.ccLogin(res.data.data.email);
      return {
        ...global,
        loading: false,
        pathway: ["Home"],
        partnerPathway: [{ label: "Home", to: "/" }],
        lastAPICall: res,
        dsUser: res.data.data.user,
        ...res.data.data.data,
        // userPromise
      };
    } else {
      localStorage.removeItem("ds_token");
      return {
        ...global,
        loading: false,
        lastAPICall: res,
        dsUser: null
      };
    }
  } catch (ex) {
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("validateTokenPartner", async (global, dispatch) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/auth/verify/partner`;
    const res = await axios.post(ep, {
      token: localStorage.getItem("ds_token")
    });

    if (res.data.success) {
      setPartner();
      /*const userPromise = CometChat.login(
        res.data.data.user.cometchat,
        process.env.REACT_APP_COMETCHAT_API
      );

      /**
       * MESSAGE LISTENER: Listen for new messages
       */

      /*(async () => {
        CometChat.addUserListener(
          "ds-user-listener",
          new CometChat.UserListener({
            onUserOnline: onlineUser => {

            },
            onUserOffline: offlineUser => {

            }
          })
        );

        CometChat.addMessageListener(
          "ds-message-listener",
          new CometChat.MessageListener({
            onTextMessageReceived: textMessage => {

              dispatch.ccReceiveMessage(textMessage);
            },
            onMediaMessageReceived: mediaMessage => {

              // Handle media message
            },
            onCustomMessageReceived: customMessage => {
              console.log(
                "Custom message received successfully",
                customMessage
              );
              // Handle custom message
            }
          })
        );
      })();
      userPromise.then(_ =>
        CometChat.getUnreadMessageCountForAllUsers().then(val =>
          setGlobal({ unread: Object.keys(val).length > 0 })
        )
      );*/
      // await this.dispatch.ccLogin(res.data.data.email);
      return {
        ...global,
        loading: false,
        pathway: ["Home"],
        partnerPathway: [{ label: "Home", to: "/" }],
        lastAPICall: res,
        routes: generateRoutes(res.data.data.user.sub_user),
        dsUser: res.data.data.user,
        ...res.data.data.data
      };
    } else {
      localStorage.removeItem("ds_token");
      return {
        ...global,
        loading: false,
        lastAPICall: res,
        dsUser: null
      };
    }
  } catch (ex) {
    return { ...global, loading: false, lastAPICall: null };
  }
});

addReducer("validateTokenFranchiser", async (global, dispatch) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/auth/verify/franchiser`;
    const res = await axios.post(ep, {
      token: localStorage.getItem("franchiser_token")
    });

    if (res.data.success) {
      setFranchiser();

      return {
        ...global,
        pathway: ["Home"],
        partnerPathway: [{ label: "Home", to: "/" }],
        loading: false,
        routes: generateFranchiseRoutes(),
        lastAPICall: res,
        franchiseUser: res.data.data.user
      };
    } else {
      localStorage.removeItem("franchiser_token");
      return {
        ...global,
        loading: false,
        lastAPICall: res,
        franchiseUser: null
      };
    }
  } catch (ex) {
    return { ...global, loading: false, lastAPICall: null };
  }
});
