import React, { useState, useGlobal, useEffect } from "reactn";
import _ from "lodash";
import { DateTime } from "luxon";

import { useConnections } from "../../../providers/reports";
import { Link } from "react-router-dom";

import {
  ReportContainer,
  ReportTable,
  FilterButton
} from "../../../components/UI/reportsAnalytics";

const DEFAULT_TYPES = [{ label: "Active", listingId: 0 }, { label: "Pending", listingId: 1 }];

const DEFAULT_COLUMNS = [
  { label: "Name", key: "displayName", listingId: 0},
  { label: "Email", key: "email", listingId: 1 },
  { label: "Phone", key: "homePhone", listingId: 2 },
  { label: "Date Joined", key: "createdAt", listingId: 3 },
  { label: "Came From", key: "came_from", listingId: 4 },
  { label: "Children", key: "childNames", listingId: 5 },
  { label: "Active Connection", key: "classification", listingId: 6 }
];

const ContactsReport = () => {
  const orgData = useGlobal("organizationInfo")[0];
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");

  const { active, pending } = useConnections();
  const [checkedConnectionTypes, setCheckedConnectionTypes] =
    useState(DEFAULT_TYPES);
  const [checkedColumns, setCheckedColumns] = useState(DEFAULT_COLUMNS);

  // Stateful variables are used here, or else filter button won't work.
  const [allConnectionTypes, setAllConnectionTypes] = useState(DEFAULT_TYPES);
  const [allColumns, setAllColumns] = useState(DEFAULT_COLUMNS);

  useEffect(() => {
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" },
      { label: "Contacts Report", to: "/reports/contacts-report" }
    ]);
  }, []);

  /**
   * Gets the CSV for the CSVLink component to use.
   *
   * @returns {Array<Object>} Each property of each object corresponds to a column.
   */
  const getCsvData = () => {
    const csvRows = [];

    connections.forEach(row => {
      const csvRow = {};
      // For each column, add the appropriate value.
      columnsInOrder.forEach(column => {
        if (checkedColumns.includes(column)) {
          csvRow[column.key] = getCellValue(row, column) || "";
        }
      });
      csvRows.push(csvRow);
    });
    return csvRows;
  };

  /**
   * Gets data for the embedded table. Similar to getCsvData except for return type.
   *
   * @returns {Array<Array>}
   */
  const getTableData = () => {
    const tableRows = [];

    connections.forEach(row => {
      const csvRow = [];
      // For each column, add the appropriate value.
      columnsInOrder.forEach(column => {
        if (checkedColumns.includes(column)) {
          // Handle special case for rendering the name cell with link.
          if (column.key === "displayName") {
            csvRow.push(
              <Link
                to={`/partners/single/connections/${row.email}/${
                  row.classification === "active" ? "true" : "false"
                }?parentId=${row.parentId || row.dsUserId}`}
              >
                {row.displayName}
              </Link>
            );
            // csvRow.push(getCellValue(row, column) || "");
          } else {
            csvRow.push(getCellValue(row, column) || "");
          }
        }
      });
      tableRows.push(csvRow);
    });
    return tableRows;
  };

  /**
   * Given an enrollment (row) and a column with a "key" property, find the cell value.
   *
   * @returns {String}
   *
   * @param {Object} row Enrollment
   * @param {Object} column Must have a key property, representing the property to read from row.
   */
  const getCellValue = (row, column) => {
    let cellValue = row[column.key];

    // Handle special cases where the data needs to be formatted/processed.
    switch (column.key) {
      // Format date fields properly.
      case "createdAt":
        cellValue = DateTime.fromISO(row[column.key]).toLocaleString(
          DateTime.DATE_MED
        );
        break;

      // Convert "active" or "pending" into a yes/no field.
      case "classification":
        cellValue = cellValue === "active" ? "Yes" : "No";
        break;

      case "childNames":
        cellValue = row[column.key].join(", ");
        break;

      default:
        break;
    }
    return cellValue;
  };

  let connections = [];

  // Changes which connections to show depending on the filter.
  checkedConnectionTypes.forEach(type => {
    if (type.label === "Active") {
      connections = [...connections, ...active];
    } else if (type.label === "Pending") {
      connections = [...connections, ...pending];
    }
  });

  const columnsInOrder = [
    ...allColumns.filter(header => checkedColumns.includes(header))
  ];

  let reportSubtitles = [orgData.organization_title];

  return (
    <div className="admin">
      <div className="container-fluid adminprofiles">
        <div className="row cont">
          <div className="col-md-6">
            <h1>Contacts Report</h1>
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-section">
            <div className="filter-item grid-new-col margin-left-offset grid-new-col">
              <FilterButton
                label="Select Status..."
                checkedOptions={checkedConnectionTypes}
                setCheckedOptions={setCheckedConnectionTypes}
                allOptions={allConnectionTypes}
                stringProperty="label"
              />
              <FilterButton
                label="Select Columns..."
                checkedOptions={checkedColumns}
                setCheckedOptions={setCheckedColumns}
                allOptions={allColumns}
                stringProperty="label"
              />
            </div>
          </div>
        </div>
        <ReportContainer
          title="Contacts Report"
          subtitles={reportSubtitles}
          csvHeaders={columnsInOrder}
          csvData={getCsvData()}
          fileName={`Contacts${orgData.organization_title}.csv`}
          isLarge
        >
          <div className={"report-table-large"}>
            <ReportTable headings={columnsInOrder} body={getTableData()} />
            {checkedColumns.length === 0 && (
              <p className="report-no-results-text">
                Please select columns to display!
              </p>
            )}
          </div>
        </ReportContainer>
      </div>
    </div>
  );
};

export default ContactsReport;
