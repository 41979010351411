import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  IMAGE_DROPDOWN,
  SWITCH,
  DROPDOWN
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class AddFreePlan extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      total_price: 0,
      description: "",
      isCapEnabled: false,
      planCap: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async savePlan(e) {
    e.preventDefault();
    const obj = { ...this.state, type: "Free" };
    obj.description.substring(0, 50);
    if (this.global.program.total_spots && this.global.program.total_spots < parseInt(obj.planCap)) {
      obj.planCap = this.global.program.total_spots;
    } else {
      if (parseInt(obj.planCap) > 200) {
        obj.planCap = 200;
      }
    }

    this.setGlobal(
      {
        program: {
          ...this.global.program,
          // product_stripe: res.data.data.product,
          plans: [
            ...this.global.program.plans,
            {
              ...obj,
              id: this.global.program.plans.length
            }
          ]
        }
      },
      () => {
        this.dispatch.saveProgram();
      }
    );
    this.props.onClose();
  }

  getBaseModalFields() {
    const descriptionInput = {
      type: STRING_INPUT,
      data: {
        name: "Description",
        required: true,
        info: "max 50 char",
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description.substring(0, 50)
      },
      validators: {validateSubmit: () => this.state.description.trim()}
    };

    const AddCap = {
      type: SWITCH,
      data: {
        name: "Enable Program Plan Cap",
        isDisabled: false,
        checked: this.state.isCapEnabled,
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleClick: e =>
          this.setState({
            isCapEnabled: e.target.checked,
            planCap: ""
          })
      }
    };

    const PlanCap = {
      type: STRING_INPUT,
      data: {
        type: "number",
        name: this.global.program.total_spots ? `Plan Cap (Max: ${this.global.program.total_spots})` : "Plan Cap",
        required: false,
        placeholder: "Enter Cap",
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        handleChange: e => {
          this.setState({
            planCap: e.target.value
          });
        }
      }
    }; 

    const fields = [];

    fields.push(descriptionInput);
    if (this.global.program.program_details !== "Recurring") {
      fields.push(AddCap);
    }
    if (this.state.isCapEnabled) {
      fields.push(PlanCap);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Create",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.savePlan(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Create Free Plan",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    const label = (
      <>
        Description{" "}
        <span style={{ fontStyle: "italic", fontWeight: "normal" }}>
          (max 50 char)
        </span>
      </>
    );

    return (
      <ModalTemplate
        heading="Create Free Plan"
        onClose={_ => this.props.onClose()}
      >
        <form onSubmit={e => this.savePlan(e)}>
          <div className="modal-main">
            <ModalFields.input
              label={label}
              name="description"
              type="text"
              onChange={e => {
                this.setState({
                  description: e.target.value
                });
              }}
              value={this.state.description.substring(0, 50)}
            />
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn">
              Create
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(AddFreePlan);
