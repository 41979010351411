import React from "reactn";
import SectionThree from "./forms/SectionThree";
class Final extends React.Component {
  render() {
    return (
      <div className="cont boxes-row mt-50px">
        <SectionThree />
      </div>
    );
  }
}

export default Final;
