import React, { useState } from "reactn";
import AttendanceCalendarModal from "../AttendanceCalendarModal/AttendanceCalendarModal";
import {
  GreenCheck,
  MarkIcon,
  PlusIcon,
  IksIcon,
  MinusIcon
} from "../../../../assets";
import OrangeCheck from "../../../../assets/img/orangecheck.svg";
import "./AttendanceCalendarTable.css";

import {
  format,
  eachDayOfInterval,
  startOfDay,
  isSameWeek,
  getDay,
  addWeeks,
  isSameDay
} from "date-fns";

const AttendanceCalendarCell = ({ day, cellName, cellClick }) => {
  const statuses = {
    Present: GreenCheck,
    Late: OrangeCheck,
    Extended: PlusIcon,
    Reduced: MinusIcon,
    Absent: IksIcon,
    Select: MarkIcon
  };
  const cellProps = {
    src: statuses[day ? day.status : "Select"],
    alt: day ? day.status : "Select",
    style: { cursor: "pointer" },
    id: cellName
  };

  const getSub = () => {
    if (day && (day.status === "Extended" || day.status === "Reduced")) {
      return (
        <p>
          {day.status === "Extended"
            ? `Fee: ${day.fee !== 0 ? `$${day.fee}` : `${day.percent}%`}`
            : `Discount: ${day.percent}%`}
        </p>
      );
    } else {
      return null;
    }
  };
  return (
    <div className="vertical">
      <div className="child">
        <img alt="" {...cellProps} />
        {getSub()}
      </div>
    </div>
  );
};

const buildModalCell = (student, attendance, day, i) => {
  return (
    <AttendanceCalendarCell
      day={
        attendance[student.enrollmentId][format(day, "yyyy-MM-dd")]
          ? attendance[student.enrollmentId][format(day, "yyyy-MM-dd")]
          : null
      }
      cellName={student.fullName + "-" + student.enrollmentId + "-" + i}
      id={student.fullName + "-" + student.enrollmentId + "-" + i}
    />
  );
};

const getNumericDay = day => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  return days.indexOf(day);
};

const AttendanceCalendarTable = ({
  setChildStatus,
  students,
  attendance,
  start,
  end,
  program
}) => {
  const [useModalState, setModalState] = useState("");

  const showModal = day => {
    setModalState(day);
  };

  const closeModal = () => {
    setModalState("");
  };

  console.log("students", students);
  console.log("attendance", attendance);
  console.log("program", program);

  const checkIfValid = (student, day, i) => {
    let startValidation = true;
    startValidation = student.startDate
      ? startOfDay(new Date(student.startDate)) <= startOfDay(new Date(day))
      : true;

    const check =
      startOfDay(new Date(program.program_start)) > startOfDay(new Date(day))
        ? false
        : true;

    //  Session Dates
    if (student.sessionDates?.length > 0) {
      let availableStudentDay = false;
      const validateDays = student.sessionDates.map(e => {
        if (isSameDay(new Date(day), new Date(e))) {
          availableStudentDay = true;
        }
        return e;
      });

      return (
        student.selected_days.includes(i) &&
        startValidation &&
        check &&
        (startOfDay(new Date(student.endDate)) >= startOfDay(new Date(day)) ||
          !student.endDate) &&
        availableStudentDay
      );
    }

    // Session Timings
    if (student.sessionTiming?.length > 0) {
      let availableStudentDay = false;
      const validateDays = student.sessionTiming.map(e => {
        const element = typeof e === "string" ? JSON.parse(e) : e;
        if (isSameDay(new Date(day), new Date(element.date))) {
          availableStudentDay = true;
        }
        return e;
      });

      return (
        student.selected_days.includes(i) &&
        startValidation &&
        check &&
        (startOfDay(new Date(student.endDate)) >= startOfDay(new Date(day)) ||
          !student.endDate) &&
        availableStudentDay
      );
    }

    // Session Weekly logic
    if (student.sessionsCustomerSelectedDays?.length > 0) {
      const endDate = addWeeks(
        new Date(student.startDate),
        student.userSelectedQuantity
      );
      const hasSessionEnded = new Date(day) < endDate;

      let availableStudentDay = false;
      const findDay = getDay(new Date(day));
      const validateDays = student.sessionsCustomerSelectedDays.map(e => {
        const element = typeof e === "string" ? JSON.parse(e) : e;
        if (getNumericDay(element.value) === findDay) {
          availableStudentDay = true;
        }
        return e;
      });

      return (
        student.selected_days.includes(i) &&
        startValidation &&
        check &&
        (startOfDay(new Date(student.endDate)) >= startOfDay(new Date(day)) ||
          !student.endDate) &&
        availableStudentDay &&
        hasSessionEnded
      );
    }

    return (
      student.selected_days.includes(i) &&
      startValidation &&
      check &&
      (startOfDay(new Date(student.endDate)) >= startOfDay(new Date(day)) ||
        !student.endDate)
    );
  };

  return (
    <div className="cont pb-5">
      <div className="row">
        <div className="col-12">
          <div id="attendance-calendar">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" />
                    {eachDayOfInterval({
                      start: start,
                      end: end
                    }).map((day, i) => (
                      <th key={i} scope="col">
                        {format(day, "LLL dd")}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {students.map((student, i) => (
                    <tr key={i}>
                      <th scope="row">
                        <h2>{student.fullName}</h2>
                      </th>
                      {eachDayOfInterval({ start: start, end: end }).map(
                        (day, i) => (
                          <td
                            key={`${i}-day`}
                            onClick={() => {
                              if (
                                checkIfValid(student, day, i) &&
                                isSameWeek(new Date(day), new Date())
                              ) {
                                showModal(
                                  student.fullName +
                                    "-" +
                                    student.enrollmentId +
                                    "-" +
                                    i
                                );
                              }
                            }}
                            className={
                              checkIfValid(student, day, i) ? "" : "cell-lines"
                            }
                          >
                            {checkIfValid(student, day, i)
                              ? buildModalCell(student, attendance, day, i)
                              : null}

                            {useModalState ===
                            student.fullName +
                              "-" +
                              student.enrollmentId +
                              "-" +
                              i ? (
                              <AttendanceCalendarModal
                                onClose={closeModal}
                                child={{
                                  status:
                                    attendance[student.enrollmentId][
                                      format(day, "yyyy-MM-dd")
                                    ],
                                  child: {
                                    student: student,
                                    day: format(day, "yyyy-MM-dd")
                                  }
                                }}
                                setChildStatus={setChildStatus}
                              />
                            ) : null}
                          </td>
                        )
                      )}
                    </tr>
                  ))}
                  {students.length ? null : (
                    <tr>
                      <td colSpan="8" className="text-center p-5">
                        Nothing to Show... <i>Yet!</i>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceCalendarTable;
