import React from "reactn";
import Tables from "../../components/table";
import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import InvoiceModal from "../../components/modals/InvoiceModal";
const useStyles = makeStyles(theme => ({
  boldText: {
    fontWeight: "bold!important"
  }
}));
const Invoices = () => {
  const classes = useStyles();
  const [invoice, setInvoice] = useState(null);
  const [maps, setMaps] = useState({});
  const [search, setSearch] = useState("");
  return (
    <div className="container-fluid">
      {!invoice ? (
        <>
          <div className="cont">
            <div className="row">
              <div className="col-md-6">
                <h1>Search Invoices</h1>
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="row">
              <input
                type="text"
                placeholder="Search by educator or parent's name..."
                onChange={e => setSearch(e.target.value)}
                style={{
                  marginBottom: 15,
                  width: "100%",
                  borderRadius: 5,
                  border: "2px solid #E3E6F0",
                  fontSize: "13px",
                  padding: 3,
                  outline: 0
                }}
                value={search}
              />
              <Tables.Admins.InvoicesTable
                invoice={(invoice, maps) => {
                  setInvoice(invoice);
                  setMaps(maps);
                }}
                search={search}
              />
            </div>
          </div>
        </>
      ) : (
        <InvoiceModal
          invoice={invoice}
          setInvoice={e => setInvoice(e)}
          maps={maps}
        />
      )}
    </div>
  );
};

export default Invoices;
