import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { useGlobal } from "reactn";
import BaseModal from "./baseModal/BaseModal";
import {
  CONTENT_BLOCK, DATE_PICKER,
  DROPDOWN,
  STRING_INPUT, SWITCH,
  TEXT_AREA
} from "./baseModal/FieldTypes";

const CreateMilestonesModal = ({ milestone, onClose }) => {
  const setLoading = useGlobal("loading")[1];
  console.log(milestone);

  let oldMilestoneName, oldMilestoneDescription, oldUserJournal, oldUploadAbility, oldDueDate, oldProgram, oldMilestoneType;
  if (milestone) {
    oldMilestoneName = milestone.name;
    oldMilestoneDescription = milestone.description;
    oldUserJournal = milestone.user_journal;
    oldUploadAbility = milestone.can_upload;
    oldDueDate = milestone.due_date;
    oldProgram = milestone.program;
    oldMilestoneType = milestone.milestone_type === "Program";
  }

  const [isProgram, setIsProgram] = useState(oldMilestoneType === undefined ? "" : oldMilestoneType);
  const [program, setProgram] = useState(oldProgram || "");
  const [milestoneName, setMilestoneName] = useState(oldMilestoneName || "");
  const [milestoneDescription, setMilestoneDescription] = useState(oldMilestoneDescription || "");
  const [dueDate, setDueDate] = useState(oldDueDate || null);
  const [userJournal, setUserJournal] = useState(oldUserJournal || false);
  const [uploadAbility, setUploadAbility] = useState(oldUploadAbility || false);

  const [programsList, setProgramsList] = useState(null);
  const [onlineVirtualsList, setOnlineVirtualsList] = useState(null);

  const programsSetup = async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API}/partners/programs/list`
      );
      if (resp.data.success) {
        setProgramsList(resp.data.data);
      } else {
        console.error("error getting programs -----");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onlineSetup = async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API}/partners/virtual/list`
      );
      if (resp.data.success) {
        setOnlineVirtualsList(resp.data.data);
      } else {
        console.error("error getting online -----");
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    programsSetup();
    onlineSetup();
    setLoading(false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!program || isProgram === "") {
      toast.error("You need to select a program");
      return;
    }
    if (!milestoneName) {
      toast.error("You need to enter a milestone name");
      return;
    }

    const partnerId = isProgram ? programsList[0].partnerId : onlineVirtualsList[0].partnerId;

    const dataSubmission = {
      name: milestoneName,
      description: milestoneDescription,
      reminder_notification: false,
      user_journal: userJournal,
      can_upload: uploadAbility,
      can_communicate: !(userJournal || uploadAbility),
      due_date: dueDate,
      program: isProgram ? program : null,
      partner: partnerId, // TODO: make sure there is at least one program
      milestone_type: isProgram ? "Program" : "Online",
      onlineId: isProgram ? null : program,
      id: milestone ? milestone.id : null
    }
    console.log("data", dataSubmission);
    if (!milestone) {
      // Adding a new milestone
      const ep = `${process.env.REACT_APP_API}/partners/milestones/add`;
      const resp = await axios.post(ep, dataSubmission);
      if (resp) {
        const ms_data = resp.data;
        if (ms_data.success) {
          toast.success("Milestone added successfully");
          onClose();
        }
      }
    } else {
      // Updating a milestone
      const ep = `${process.env.REACT_APP_API}/partners/milestones/update`;
      const resp = await axios.post(ep, dataSubmission);

      if (resp) {
        const ms_data = resp.data;
        if (ms_data.success) {
          onClose();
          toast.success("Milestone saved!");
        }
      }
    }
  };

  const getListChoices = () => {
    if (isProgram === "") {
      return [];
    }
    if (isProgram) {
      return programsList?.map((program) => {
        return {
          value: program.id,
          text: program.program_name
        }
      }) || [];
    } else {
      return onlineVirtualsList?.map((online) => {
        return {
          value: online.id,
          text: online.program_name
        }
      }) || [];
    }
  }

  const getBaseModalProps = () => {
    return {
      title: milestone ? "Edit Milestone" : "Create New Milestone",
      fields: [
        {
          type: DROPDOWN,
          data: {
            name: "Type",
            required: true,
            placeholder: "Choose here",
            value: isProgram === "" ? "" : (isProgram ? "Program" : "Online"),
            choices: [
              {
                value: "Program",
                text: "Program"
              },
              {
                value: "Online",
                text: "Online"
              }
            ],
            handleChange: (e) => {
              setIsProgram(e.target.value === "" ? null : e.target.value === "Program");
              setProgram("");
            }
          }
        },
        {
          type: DROPDOWN,
          data: {
            name: "Program",
            placeholder: "Choose here",
            value: program,
            required: true,
            choices: getListChoices(),
            handleChange: (e) => setProgram(e.target.value),
          }
        },
        {
          type: STRING_INPUT,
          data: {
            name: "Milestone Name",
            value: milestoneName,
            required: true,
            handleChange: (e) => setMilestoneName(e.target.value)
          }
        },
        {
          type: TEXT_AREA,
          data: {
            name: "Description",
            value: milestoneDescription,
            handleChange: (e) => setMilestoneDescription(e.target.value)
          }
        },
        {
          type: DATE_PICKER,
          data: {
            name: "Due Date",
            value: dueDate ? new Date(dueDate) : "",
            placeholder: "Choose a date",
            handleChange: (date) => setDueDate(date)
          }
        },
        {
          type: SWITCH,
          data: {
            name: "User Journal",
            checked: userJournal,
            handleClick: () => setUserJournal(!userJournal)
          }
        },
        {
          type: SWITCH,
          data: {
            name: "Upload Ability",
            checked: uploadAbility,
            handleClick: (e) => setUploadAbility(!uploadAbility)
          }
        }
      ],
      buttons: [
        {
          name: milestone ? "Edit Milestone" : "Create Milestone",
          handleClick: handleSubmit,
          buttonColour: "#3B4EFF",
          textColour: "#fff",
        }
      ],
      handleClose: () => onClose()
    };
  }

  return (
    <BaseModal {...getBaseModalProps()} />
  )
};

export default CreateMilestonesModal;
