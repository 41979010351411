import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ProgramSearchInput from "../common/ProgramSearchInput";
import { formTypes } from "../UI/form/index";

import {
  CONTENT_BLOCK,
  STRING_INPUT,
  BUTTON,
  TEXT_AREA,
  IMAGE_CONTENT,
  DROPDOWN,
  DATE_PICKER,
  TITLE,
  GET_HTML
} from "./baseModal/FieldTypes";
import { Close as ClearIcon } from "@material-ui/icons";
import BaseModal from "./baseModal/BaseModal";
import {
  BLUE_BUTTON,
  GREEN_BUTTON,
  RED_BUTTON,
  WHITE,
  YELLOW_BUTTON
} from "./baseModal/colours";
import { Editor, convertFromRaw, convertToRaw, EditorState } from "draft-js";
class AddLeadModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      company: "",
      postalcode: "",
      email: "",
      login_name: "",
      phone: "",
      notes: ""
    };
    this.required = [
      { name: "name", validation: e => !e },
      { name: "company", validation: e => !e },
      { name: "postalcode", validation: e => !e },
      { name: "email", validation: e => !e },
      { name: "login_name", validation: e => !/^[^\s]*$/.test(e) },
      { name: "password", validation: e => !e },
      { name: "phone", validation: e => !e },
      { name: "currency", validation: e => !e },
      { name: "address1", validation: e => !e },
      { name: "city", validation: e => !e },
      { name: "selected_forms", validation: e => !e }
    ];
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async submitForm(e) {
    e.preventDefault();
    let cancel = false;
    for (let requiredField of this.required) {
      if (requiredField.validation(this.state[requiredField.name])) {
        document.getElementsByName(requiredField.name)[0].style.boxShadow =
          "none";
        document.getElementsByName(requiredField.name)[0].style.border =
          "1px solid red";
        cancel = true;
      }
    }
    if (cancel) return;
    const endp = `${process.env.REACT_APP_API}/adm/partner/franchiser-add-lead`;
    const savedInfo = {
      ...this.state
    }
    delete savedInfo.editorState;

    const result = await axios.post(endp, {
      franchiserId: this.global.franchiseUser.id,
      ...savedInfo
    });
    if (result.data.success) {
      toast.success("Lead Added Successfully");
      this.props.addLead();
      this.props.onClose();
    } else {
      toast.error(result.data.message);
    }
  }

  getModal = () => {
    const isZipCode = (code) => {
      if (code.split(" ").length === 2) {
        return true;
      }

      return false;
    }
    return (
      <form onSubmit={e => this.submitForm(e)}>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            htmlFor="displayName"
            style={{
              width: "25%",
              textAlign: "left",
              margin: "14px 0 0",
              color: 'gray',
              paddingBottom: "3px",
              textAlign: "left",
              fontSize: "1rem",
              fontWeight: "normal",
              marginBottom: "8px",
            }}
          >
            Contact Name <span style={{ color: "orange" }}>*</span>
          </label>
          <input
            name="name"
            id="displayName"
            type="text"
            className="form-control"
            placeholder="Lead's full name..."
            style={{ width: "70%" }}
            onChange={e => {
              this.setState({
                [e.target.name]: e.target.value
              });
            }}
          />
        </div>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            htmlFor="company"
            style={{
              width: "25%",
              textAlign: "left",
              margin: "14px 0 0",
              color: 'gray',
              paddingBottom: "3px",
              textAlign: "left",
              fontSize: "1rem",
              fontWeight: "normal",
              marginBottom: "8px",
            }}
          >
            Organization <span style={{ color: "orange" }}>*</span>
          </label>
          <input
            name="company"
            id="organization"
            type="text"
            className="form-control"
            placeholder="Organization's name..."
            style={{ width: "70%" }}
            onChange={e => {
              this.setState({
                [e.target.name]: e.target.value
              });
            }}
          />
        </div>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            htmlFor="email"
            style={{
              width: "25%",
              textAlign: "left",
              margin: "14px 0 0",
              color: 'gray',
              paddingBottom: "3px",
              textAlign: "left",
              fontSize: "1rem",
              fontWeight: "normal",
              marginBottom: "8px",
            }}
          >
            Email <span style={{ color: "orange" }}>*</span>
          </label>
          <input
            name="email"
            id="email"
            type="email"
            placeholder="Lead's Email..."
            className="form-control"
            style={{ width: "70%" }}
            onChange={e => {
              this.setState({
                [e.target.name]: e.target.value
              });
            }}
          />
        </div>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            htmlFor="login_name"
            style={{
              width: "25%",
              textAlign: "left",
              margin: "14px 0 0",
              color: 'gray',
              paddingBottom: "3px",
              textAlign: "left",
              fontSize: "1rem",
              fontWeight: "normal",
              marginBottom: "8px",
            }}
          >
            Login ID <span style={{ color: "orange" }}>*</span>
          </label>
          <input
            name="login_name"
            id="login_name"
            type="text"
            placeholder="Login ID..."
            className="form-control"
            style={{ width: "70%" }}
            onChange={e => {
              this.setState({
                [e.target.name]: e.target.value
              });
            }}
          />
        </div>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            htmlFor="password"
            style={{
              width: "25%",
              textAlign: "left",
              margin: "14px 0 0",
              color: 'gray',
              paddingBottom: "3px",
              textAlign: "left",
              fontSize: "1rem",
              fontWeight: "normal",
              marginBottom: "8px",
            }}
          >
            Password <span style={{ color: "orange" }}>*</span>
          </label>
          <input
            name="password"
            id="password"
            type="password"
            placeholder="Password..."
            className="form-control"
            style={{ width: "70%" }}
            onChange={e => {
              this.setState({
                [e.target.name]: e.target.value
              });
            }}
          />
        </div>

        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            htmlFor="phone"
            style={{
              width: "25%",
              textAlign: "left",
              margin: "14px 0 0",
              color: 'gray',
              paddingBottom: "3px",
              textAlign: "left",
              fontSize: "1rem",
              fontWeight: "normal",
              marginBottom: "8px",
            }}
          >
            Mobile <span style={{ color: "orange" }}>*</span>
          </label>
          <input
            name="phone"
            id="mobile"
            type="text"
            className="form-control"
            placeholder="Phone Number..."
            style={{ width: "70%" }}
            onChange={e => {
              this.setState({
                [e.target.name]: e.target.value
              });
            }}
          />
        </div>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            htmlFor="currency"
            style={{
              width: "25%",
              textAlign: "left",
              margin: "14px 0 0",
              color: 'gray',
              paddingBottom: "3px",
              textAlign: "left",
              fontSize: "1rem",
              fontWeight: "normal",
              marginBottom: "8px",
            }}
          >
            Currency <span style={{ color: "orange" }}>*</span>
          </label>
          <select
            name="currency"
            id="currency"
            type="text"
            className="form-control"
            style={{ width: "70%" }}
            onChange={e => {
              this.setState({
                [e.target.name]: e.target.value
              });
            }}
          >
            <option value={""} disabled selected>
              Please Select
            </option>
            <option value={"cad"}>CAD</option>
            <option value={"usd"}>USD</option>
            <option value={"aud"}>AUD</option>
          </select>
        </div>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            htmlFor="displayName"
            style={{
              width: "25%",
              textAlign: "left",
              margin: "14px 0 0",
              color: 'gray',
              paddingBottom: "3px",
              textAlign: "left",
              fontSize: "1rem",
              fontWeight: "normal",
              marginBottom: "8px",
            }}
          >
            Address <span style={{ color: "orange" }}>*</span>
          </label>
          <div style={{ width: "70%" }}>
            <ProgramSearchInput
              placeholder="Find a Program"
              noicon={true}
              blueborder={true}
              name="search"
              style={{ marginTop: "0px" }}
              className="input-fields"
              gLoc={(e, loc) => {
                const address = e.split(",");
                this.setState({
                  address1: e,
                  city: address[1].trim(),
                  postalcode: isZipCode(address[2].trim()) 
                    ? address[2].trim().split(" ")[1]
                    : address[2].trim().split(" ")[1] + address[2].trim().split(" ")[2]
                });
              }}
            />
          </div>
        </div>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            htmlFor="city"
            style={{
              width: "25%",
              textAlign: "left",
              margin: "14px 0 0",
              color: 'gray',
              paddingBottom: "3px",
              textAlign: "left",
              fontSize: "1rem",
              fontWeight: "normal",
              marginBottom: "8px",
            }}
          >
            City <span style={{ color: "orange" }}>*</span>
          </label>
          <input
            name="city"
            id="city"
            type="text"
            className="form-control"
            placeholder="City"
            style={{ width: "70%" }}
            value={this.state.city}
            onChange={e => {
              this.setState({
                [e.target.name]: e.target.value
              });
            }}
          />
        </div>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            htmlFor="postalcode"
            style={{
              width: "25%",
              textAlign: "left",
              margin: "14px 0 0",
              color: 'gray',
              paddingBottom: "3px",
              textAlign: "left",
              fontSize: "1rem",
              fontWeight: "normal",
              marginBottom: "8px",
            }}
          >
            Postal Code <span style={{ color: "orange" }}>*</span>
          </label>
          <input
            name="postalcode"
            id="postalcode"
            type="text"
            className="form-control"
            placeholder="Postal Code"
            style={{ width: "70%" }}
            value={this.state.postalcode}
            onChange={e => {
              this.setState({
                [e.target.name]: e.target.value
              });
            }}
          />
        </div>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            htmlFor="address2"
            style={{
              width: "25%",
              textAlign: "left",
              margin: "14px 0 0",
              color: 'gray',
              paddingBottom: "3px",
              textAlign: "left",
              fontSize: "1rem",
              fontWeight: "normal",
              marginBottom: "8px",
            }}
          >
            Unit
          </label>
          <input
            name="address2"
            id="address2"
            type="text"
            className="form-control"
            placeholder="Unit Number..."
            style={{ width: "70%" }}
            onChange={e => {
              this.setState({
                [e.target.name]: e.target.value
              });
            }}
          />
        </div>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            htmlFor="categories"
            style={{
              width: "25%",
              textAlign: "left",
              margin: "14px 0 0",
              color: 'gray',
              paddingBottom: "3px",
              textAlign: "left",
              fontSize: "1rem",
              fontWeight: "normal",
              marginBottom: "8px",
            }}
          >
            Categories
          </label>
          <div style={{ width: "70%" }}>
            <formTypes.MultipleSelectProgramCategories
              value={this.state.categories || []}
              name="categories"
              onChange={(n, v) =>
                this.setState({
                  [n]: v
                })
              }
            ></formTypes.MultipleSelectProgramCategories>
          </div>
        </div>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            htmlFor="selected_forms"
            style={{
              width: "25%",
              textAlign: "left",
              margin: "14px 0 0",
              color: 'gray',
              paddingBottom: "3px",
              textAlign: "left",
              fontSize: "1rem",
              fontWeight: "normal",
              marginBottom: "8px",
            }}
          >
            Selected Forms <span style={{ color: "orange" }}>*</span>
          </label>
          <div style={{ width: "70%" }}>
            <formTypes.FormGroupSelect
              value={[]}
              name="selected_forms"
              onChange={(n, v) =>
                this.setState({
                  [n]: v
                })
              }
            />
          </div>
        </div>

        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            htmlFor="displayName"
            style={{
              width: "25%",
              textAlign: "left",
              margin: "14px 0 0",
              color: 'gray',
              paddingBottom: "3px",
              textAlign: "left",
              fontSize: "1rem",
              fontWeight: "normal",
              marginBottom: "8px",
            }}
          >
            Notes
          </label>
          <div
            className="form-control"
            style={{
              height: "120px",
              width: "70%", 
              paddingLeft: "0px", 
              paddingRight: "0px" 
            }}
          >
            <Editor
              editorState={
                this.state.editorState
                  ? this.state.editorState
                  : this.state.notes 
                    ? EditorState.createWithContent(convertFromRaw(JSON.parse(this.state.notes)))
                    : EditorState.createEmpty()
              }
              onChange={(editor) => {
                this.setState({ 
                  editorState: editor,
                  notes: JSON.stringify(convertToRaw(editor.getCurrentContent())) 
                });
              }}
            />
          </div>
        </div>
      </form >
    )
  }

  getBaseModalFields() {
    let allFields = []

    const modal = {
      type: GET_HTML,
      data: {
        gethtml: this.getModal
      }
    };

    allFields.push(modal)

    return allFields;
  }

  getBaseModalButtons() {
    const saveButton = {
      name: "Add New",
      buttonClass: "client-settings-button",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      handleClick: e => this.submitForm(e)
    };

    return [saveButton];
  }

  getBaseModalProps() {
    const baseModalProps = {
      title: "Add New Lead",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      width: "550px",
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };

    return baseModalProps;
  }
  
  getLimitReachedModal = () => {
    const props = {
      title: "Add New Lead",
      fields: [
        { type: TITLE, data: { name: "You have reached your franchise limit." } }
      ],
      handleClose: this.props.onClose
    };
    return <BaseModal {...props} />;
  };

  render() {
    console.log("HEREEEE", this.props)
    if (this.props.franchiseInfo?.number_franchises <= this.props.leads?.Active?.length) {
      return this.getLimitReachedModal()
    }
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(AddLeadModal);
