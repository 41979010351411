import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { Button } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import BaseModal from "./baseModal/BaseModal";

import {
  CONTENT_BLOCK,
  BUTTON,
  TEXT_AREA,
  IMAGE_CONTENT,
  DROPDOWN,
  STRING_INPUT
} from "./baseModal/FieldTypes";
import {
  BLUE_BUTTON,
  GREEN_BUTTON,
  RED_BUTTON,
  WHITE,
  YELLOW_BUTTON
} from "./baseModal/colours";

class ViewParentMilestoneJournalModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      journalText: this.props.journalText
    };
  }

  getBaseModalFields() {
    const baseModalFieldsArr = [];

    const journalText = {
      type: CONTENT_BLOCK,
      data: {
        name: "Journal Text",
        content:
          this.state.journalText && this.state.journalText !== ""
            ? this.state.journalText
            : "Journal has not yet been submitted"
      }
    };

    baseModalFieldsArr.push(journalText);

    return baseModalFieldsArr;
  }

  getBaseModalButtons() {
    let baseModalBtnArr = [];

    return baseModalBtnArr;
  }

  getBaseModalProps() {
    const baseModalProps = {
      title: "Milestone Submission Journal Details",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };

    return baseModalProps;
  }

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default ViewParentMilestoneJournalModal;
