import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import BaseModal from "./baseModal/BaseModal";
import {
  TITLE
} from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class CancelConfirmationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  cancelInvoice = async (e) => {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/partners/orders/update`;

    const res = await axios.post(ep, {
      id: this.props.id,
      invoice_status: "Cancelled"
    });

    if (res.data.success) {
      this.props.confirmCancel();
      this.props.onClose();
    }
  }

  getBaseModalFields() {
    const descriptionText = {
      type: TITLE,
      data: {
        name: "Are you sure you want to cancel issuing this invoice? Cancelling cannot be undone - a new invoice would have to be created."
      }
    };

    const fields = [];

    fields.push(descriptionText);

    return fields;
  }

  getBaseModalButtons = () => {
    const confirmCancel = {
      name: "Cancel",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      handleClick: e => this.cancelInvoice(e)
    };

    const buttonFields = [];

    buttonFields.push(confirmCancel);

    return buttonFields;
  };

  getBaseModalProps = () => {
    return {
      title: "Cancel Invoice",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(CancelConfirmationModal);