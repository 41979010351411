import React, { Component } from "reactn";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { formTypes } from "../../components/UI/form";
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router-dom";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  DROPDOWN,
  DATE_PICKER,
  SELECT_MULTIPLE_DROPDOWN,
  TEXT_AREA,
  CONTENT_BLOCK
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class AddTotalDiscountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "Percentage",
      duration: "once",
      amount_off: 0,
      coupons: [],
      selectedCoupon: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/stripe/coupon`;
    const cb = await axios.get(ep);
    console.log("cb", cb);
    if (cb.data.success) {

      // Sort by most recent to oldest
      cb.data.data.sort((coupon1, coupon2) => {
        return new Date(coupon2.updatedAt) - new Date(coupon1.updatedAt)
      })

      this.setState(
        {
          coupons: cb.data.data.filter(e => !e.isArchived)
        }
      );
    }
  }

  async create_discount(e) {
    e.preventDefault();
    if (!this.props.isStripe) {
      let cb;
      if (!this.state.selectedCoupon) {
        if (this.state.amount_off === 0) return null;
        let discountAmount = 0;
        if (this.state.type === "Percentage") {
          discountAmount =
            this.props.subTotal * parseFloat(this.state.amount_off / 100);
        } else {
          discountAmount = parseFloat(this.state.amount_off) * 100;
        }

        const totalAmount = this.props.subTotal - discountAmount;
        console.log("totalAmount", totalAmount);

        const ep = `${process.env.REACT_APP_API}/partners/orders/invoice/add-discount`;
        cb = await axios.post(ep, {
          id: this.props.match.params.id,
          type: this.state.type,
          duration: this.state.duration,
          amount_off: this.state.amount_off,
          discountAmount: discountAmount,
          totalAmount: totalAmount,
          coupon: false
        });

        console.log("cb", cb);
      } else {
        const coupon = this.state.coupons.find(e => e.coupon_id === this.state.selectedCoupon);

        if (coupon.amount_off === 0) return null;
        let discountAmount = 0;
        if (coupon.type === "Percentage") {
          discountAmount =
            this.props.subTotal * parseFloat(coupon.amount_off / 100);
        } else {
          discountAmount = parseFloat(coupon.amount_off) * 100;
        }

        const totalAmount = this.props.subTotal - discountAmount;
        console.log("totalAmount", totalAmount);

        const ep = `${process.env.REACT_APP_API}/partners/orders/invoice/add-discount`;
        cb = await axios.post(ep, {
          id: this.props.match.params.id,
          type: coupon.type,
          duration: coupon.duration,
          amount_off: coupon.amount_off,
          discountAmount: discountAmount,
          totalAmount: totalAmount,
          coupon: coupon.id
        });

        console.log("cb", cb);
      }

      if (cb.data.success) {
        toast.success(`Discount Created!`);
        this.props.onClose();
        this.props.addedDiscount();
      }
    } else {
      const ep = `${process.env.REACT_APP_API}/partners/sub-invoice/${this.props.invoice.subscription}/discount`;
      await axios.post(ep, {
        amount_off: this.state.amount_off,
        type: this.state.type,
        duration: this.state.duration
      });
      this.props.onClose();
    }
  }

  getBaseModalFields() {
    const invoicesubtotaltInput = {
      type: STRING_INPUT,
      data: {
        name: "Invoice Subtotal",
        required: false,
        isDisabled: true,
        value: this.props.invoice
          ? `$${(this.props.invoice?.subtotal / 100).toFixed(2)}`
          : this.props.footData[0][4].value
      }
    };

    const discountType = {
      type: DROPDOWN,
      data: {
        name: "Discount Type",
        required: this.state.selectedCoupon ? false : true,
        placeholder: "Please Select",
        value: this.state.type,
        handleChange: e => {
          this.setState({
            type: e.target.value
          });
        },
        choices: [
          { value: "Percentage", text: "Percentage" },
          { value: "Amount", text: "Fixed Amount" }
        ]
      },
      validators: {
        validateSubmit: () => this.state.type.length !== 0
      }
    };

    const discountpercentageInput = {
      type: STRING_INPUT,
      data: {
        name: `Discount${" "} ${
          this.state.type === "Percentage" ? "Percentage (%)" : "Amount ($)"
        }`,
        type: "number",
        required: this.state.selectedCoupon ? false : true,
        placeholder: "",
        handleChange: e => {
          this.setState({
            amount_off: e.target.value
          });
        },
        value: this.state.amount_off
      }
      // validators: {
      //   validateSubmit: () => this.state.amount_off !== 0
      // }
    };

    const durationInput = {
      type: DROPDOWN,
      data: {
        name: "Duration",
        required: false,
        placeholder: "Please Select",
        value: this.state.duration,
        // isDisabled: true,
        isDisabled: !this.props.allowForever,
        handleChange: e => {
          this.setState({
            duration: e.target.value
          });
        },
        choices: [
          { value: "once", text: "Once" },
          { value: "forever", text: "Forever" }
        ]
      }
    };

    const orField = {
      type: CONTENT_BLOCK,
      data: {
        name: "OR",
        content: ""
      }
    };

    const couponsField = {
      type: DROPDOWN,
      data: {
        name: "Coupons",
        placeholder: "Select a coupon",
        handleChange: e => {
          this.setState({ selectedCoupon: e.target.value });
        },
        choices: this.state.coupons.map(e => ({
          text: e.coupon_name,
          value: e.coupon_id
        }))
      }
    };

    const fields = [];
    fields.push(invoicesubtotaltInput);
    fields.push(discountType);
    fields.push(discountpercentageInput);
    fields.push(durationInput);
    fields.push(orField);
    fields.push(couponsField);

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.create_discount(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Add a Discount to Entire Invoice",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    console.log("this.state", this.state);
    console.log("this.global", this.global);
    console.log("this.props", this.props);
    return <BaseModal {...this.getBaseModalProps()} />;

    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>Add a Discount to Entire Invoice</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  color: "#999999",
                  fontSize: ".8rem",
                  marginBottom: "20px"
                }}
              >
                Items with <span style={{ color: "orange" }}>*</span> are
                required
              </div>
            </div>
            <form onSubmit={e => this.create_discount(e)}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="type"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Invoice Subtotal
                </label>

                <span style={{ width: "70%", fontSize: "16px" }}>
                  {this.props.invoice
                    ? `$${(this.props.invoice?.subtotal / 100).toFixed(2)}`
                    : this.props.footData[0][4].value}
                </span>
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="type"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Discount Type <span style={{ color: "orange" }}>*</span>
                </label>

                <select
                  name="type"
                  id="type"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={this.state.type}
                >
                  <option value="Percentage">Percentage</option>
                  <option value="Amount">Fixed Amount</option>
                </select>
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="amount_off"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Discount{" "}
                  {this.state.type === "Percentage"
                    ? "Percentage (%)"
                    : "Amount ($)"}{" "}
                  <span style={{ color: "orange" }}>*</span>
                </label>
                <input
                  name="amount_off"
                  id="amount_off"
                  type="number"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      amount_off: e.target.value
                    });
                  }}
                  value={this.state.amount_off}
                />
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="duration"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Duration
                </label>
                <select
                  name="duration"
                  id="duration"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={this.state.duration}
                >
                  <option value="once">Once</option>
                  <option value="forever">Forever</option>
                </select>
              </div>

              <div className="modal-footer" style={{ marginTop: "25px" }}>
                <button
                  type="submit"
                  // onClick={_ => this.savePlan()}
                  className="btn profile-btn"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddTotalDiscountModal);
