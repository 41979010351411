import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import { CloudUpload, FilterTiltShiftSharp, TrendingUpSharp } from "@material-ui/icons";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ReactTooltip from "react-tooltip";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import { DROPDOWN, STRING_INPUT, GET_HTML, TEXT_AREA, SWITCH, DATE_PICKER } from "./baseModal/FieldTypes";
import { notEmptyString } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { DatePickerCalendar } from "react-nice-dates";

const POLICY_VAL = "20";
class AddFieldModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      val: -1,
      question: "",
      options: [{}, {}, {}],
      policyText: "",
      isFileUpload: false,
      checkboxText: "I agree to the listed policies.",
      file: null,
      link: ""
    };
  }

  async fileChangeDoc(e) {
    if (e.target.files.length && e.target.files[0]) {
      const fd = new FormData();
      fd.set("image", e.target.files[0]);
      const ep = `${process.env.REACT_APP_API}/partners/forms/document`;
      this.setGlobal({ loading: true });
      const result = await axios.post(ep, fd);
      this.setGlobal({ loading: false });
      if (result.data.success) {
        this.setState({ file: result.data.data });
      }
    }
  }
  async submitForm(e) {
    e.preventDefault();
    // Validate Field Type.
    if (this.state.val < 0) {
      toast.error("Please select a field type!");
      return;
    }

    // Validate Label.
    if (this.state.val > 11 && !this.state.question) {
      toast.error("Please enter a label!");
      return;
    }

    // Validate file upload.
    if (
      this.state.val === POLICY_VAL &&
      this.state.isFileUpload &&
      !this.state.file
    ) {
      toast.error("Please upload a file!");
      return;
    }

    // Validate policy text.
    if (
      this.state.val === POLICY_VAL &&
      !this.state.policyText &&
      !this.state.isFileUpload
    ) {
      toast.error("Please enter either policy text or upload a file!");
      return;
    }

    this.props.addField(
      this.state.val,
      this.state.question,
      this.state.options,
      {
        isFileUpload: this.state.isFileUpload,
        policyText: this.state.policyText,
        checkboxText: this.state.checkboxText,
        file: this.state.file,
        link: this.state.link
      }
    );
    this.props.onClose();
  }

  optionsFunc = () => {
    return (
      this.state.options.map((e, i) => (
        <div
          className="form-group"
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 0
          }}
          key={i}
        >
          <input
            name="question"
            id="organization"
            type="text"
            className="string-input-field field-component-input-box"
            placeholder={`Option text`}
            style={{
              width: i === 0 ? "79%" : "80%",
              marginRight: "10px"
            }}
            onChange={e => {
              {
                const options = [...this.state.options];
                options[i] = {
                  text: e.target.value,
                  value: e.target.value
                };
                this.setState({ options });
              }
            }}
            value={this.state.options[i].text || ""}
          />

          <button
            type="button"
            onClick={_ =>
              this.setState({
                options: [...this.state.options, {}]
              })
            }
            style={{
              width: "8%",
              backgroundColor: "yellowgreen",
              border: "none",
              color: "white",
              borderRadius: "3px",
              marginRight: "10px",
              marginTop: "8px"
            }}
          >
            +
          </button>

          {i !== 0 && (
            <button
              style={{
                width: "8%",
                backgroundColor: "red",
                border: "none",
                color: "white",
                borderRadius: "3px",
                marginTop: "8px"
              }}
              type="button"
              onClick={_ =>
                this.setState({
                  options: this.state.options.filter(
                    (_, index) => index !== i
                  )
                })
              }
            >
              -
            </button>
          )}
        </div>
      )
    ))
  }

  uploadFileFunc = () => {
    return (
      <div
        className="form-group"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: 0
        }}
      >
        <label
          htmlFor="uploaded_expense"
          style={{
            width: "35%",
            textAlign: "left",
            fontSize: "1.15rem",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262"
          }}
        >
          Policy Document Upload{" "}
        </label>
        {this.state.isFileUpload && (
          <div
            style={{
              marginLeft: "0px",
              display: "flex",
              alignItems: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262",
              marginBottom: "0.5rem"
            }}
          >
            <Button
              component="span"
              //color="primary"
              size="small"
              startIcon={<AttachFileIcon />}
              onClick={_ => {
                document.getElementById("uploadDocument").click();
              }}
            >
              Attach File
            </Button>
            
          </div>
        )}
        {this.state.file && this.state.isFileUpload && (
          <p className="confirmation" style={{ marginBottom: "10px" }}>
            File Uploaded!
          </p>
        )}
      </div>
    )
  }

  calendarFunc = () => {
    return (
      <div style={{ height: "50%" }}>
        <DatePickerCalendar
          format="MMMM d, yyyy"
          onDateChange={e => {
          }}
        />
      </div>
    );
  }

  hiddenFieldFunc = () => {
    return (
      <input
        type="file"
        style={{ display: "none" }}
        id="uploadDocument"
        onChange={e => this.fileChangeDoc(e)}
        accept="*"
      />
    )
  }

  getBaseModalFields = () => {
    const fieldTypeField = {
      type: DROPDOWN,
      data: {
        name: "Field Type",
        required: true,
        placeholder: "Please select:",
        handleChange: e => {
          this.setState({
            val: e.target.value
          });
        },
        value: this.state.val,
        choices: [
          {
            value: 0,
            text: "Full Name"
          },
          {
            value: 1,
            text: "Email"
          },
          {
            value: 2,
            text: "Address"
          },
          {
            value: 3,
            text: "Home Phone"
          },
          {
            value: 4,
            text: "Cell Phone"
          },
          {
            value: 5,
            text: "City"
          },
          {
            value: 6,
            text: "Region"
          },
          {
            value: 7,
            text: "Postal / Zip"
          },
          {
            value: 8,
            text: "Country"
          },
          {
            value: 9,
            text: "Date of Birth"
          },
          {
            value: 10,
            text: "Ideal Days"
          },
          {
            value: 11,
            text: "Ideal Start Date"
          },
          {
            value: 12,
            text: "Custom Date"
          },
          {
            value: 13,
            text: "File Upload"
          },
          {
            value: 14,
            text: "Short Custom"
          },
          {
            value: 15,
            text: "Long Custom"
          },
          {
            value: 16,
            text: "Signature"
          },
          {
            value: 17,
            text: "Dropdown"
          },
          {
            value: 18,
            text: "Multiple Choices"
          },
          {
            value: 19,
            text: "Checkboxes"
          },
          {
            value: POLICY_VAL,
            text: "Policy"
          }
        ]
      },
      validators: { validateSubmit: notEmptyString(this.state.val) }
    }
    
    const labelField = {
      type: STRING_INPUT,
      data: {
        name: "Label",
        required: true,
        placeholder: "Custom Label...",
        handleChange: e => this.setState({ question: e.target.value }),
        value: this.state.question
      },
      validators: { validateSubmit: notEmptyString(this.state.question) }
    }

    const optionField = {
      type: GET_HTML,
      data: {
        name: "Options",
        required: true,
        gethtml: this.optionsFunc
      }
    };

    const policyTextField = {
      type: TEXT_AREA,
      data: {
        name: "Policy Text",
        required: !this.state.isFileUpload,
        placeholder: "Enter your policy here...",
        value: this.state.policyText,
        handleChange: e => this.setState({ policyText: e.target.value }),
      }
    }

    const uploadFileToggleField = {
      type: SWITCH,
      data: {
        name: "Upload File?",
        info: "Check to upload a PDF of your policy",
        checked: this.state.isFileUpload,
        handleClick: e => this.setState({ isFileUpload: e.target.checked }),
      }
    }

    const uploadFileField = {
      type: GET_HTML,
      data: {
        gethtml: this.uploadFileFunc
      }
    }

    const embedLinkField = {
      type: STRING_INPUT,
      data: {
        name: "Embed Link",
        placeholder: "Enter link to waiver...",
        info: "Must start with http:// or https://",
        handleChange: e => this.setState({ link: e.target.value }),
        value: this.state.link
      },
      validators: { validateSubmit: notEmptyString(this.state.link) }
    }

    const checkboxTextField = {
      type: STRING_INPUT,
      data: {
        name: "Checkbox Text",
        required: true,
        info: "This text will appear next to the checkbox",
        handleChange: e => this.setState({ checkboxText: e.target.value }),
        value: this.state.checkboxText
      },
      validators: { validateSubmit: notEmptyString(this.state.checkboxText) }
    }

    const dateField = {
      type: GET_HTML,
      data: {
        gethtml: this.calendarFunc
      }
    }

    const hiddenField = {
      type: GET_HTML,
      data: {
        gethtml: this.hiddenFieldFunc
      }
    }

    const fields = [];

    fields.push(fieldTypeField);
    if (this.state.val > 11) {
      fields.push(labelField);
    }

    if (parseInt(this.state.val) === 9 || parseInt(this.state.val) === 12) {
      fields.push(dateField);
    }

    if (this.state.val > 16 && this.state.val < POLICY_VAL) {
      fields.push(optionField);
    }

    if (this.state.val === POLICY_VAL) {
      fields.push(policyTextField);
      fields.push(uploadFileToggleField);
      if (this.state.isFileUpload) {
        fields.push(uploadFileField);
      } else {
        fields.push(embedLinkField);
      }
      fields.push(checkboxTextField);
    }

    fields.push(hiddenField);

    return fields;
  }

  getBaseModalButtons = () => {
    const addFieldButton = {
      name: "Add New",
      handleClick: e => this.submitForm(e),
      buttonColour: BLUE_BUTTON,
      textColour: WHITE
    };

    return [addFieldButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Add Form Field",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  }

  render() {
    return (<BaseModal {...this.getBaseModalProps()}/>)
    return (
      <ModalTemplate
        heading="Add Form Field"
        onClose={_ => this.props.onClose()}
      >
        <form onSubmit={e => this.submitForm(e)}>
          <div className="modal-main">
            <ModalFields.select
              label="Field Type"
              name="val"
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
              value={this.state.val}
              required
            >
              <option value="-1">Please Select :</option>
              <option value="0">Full Name</option>
              {/* <option value="0">First Name</option>
                  <option value="1">Last Name</option> */}
              <option value="1">Email</option>
              <option value="2">Address</option>
              <option value="3">Home Phone</option>
              <option value="4">Cell Phone</option>
              <option value="5">City</option>
              <option value="6">Region</option>
              <option value="7">Postal / Zip</option>
              <option value="8">Country</option>
              <option value="9">Date of Birth</option>
              <option value="10">Ideal Days</option>
              <option value="11">Ideal Start Date</option>
              <option value="12">File Upload</option>
              <option value="13">Short Custom</option>
              <option value="14">Long Custom</option>
              <option value="15">Signature</option>
              <option value="16">Dropdown</option>
              <option value="17">Multiple Choices</option>
              <option value="18">Checkboxes</option>
              <option value={POLICY_VAL}>Policy</option>
            </ModalFields.select>
            {this.state.val > 11 && (
              <ModalFields.input
                label="Label"
                name="question"
                type="text"
                placeholder="Custom Label..."
                required
                onChange={e => {
                  this.setState({
                    [e.target.name]: e.target.value
                  });
                }}
                value={this.state.question}
              />
            )}
            {this.state.val > 15 &&
              this.state.val < POLICY_VAL &&
              this.state.options.map((e, i) => (
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                  key={i}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "30%", textAlign: "left" }}
                  >
                    {i === 0 && "Options *"}
                  </label>
                  <input
                    name="question"
                    id="organization"
                    type="text"
                    className="form-control"
                    placeholder={`Option text`}
                    style={{
                      width: "50%",
                      marginRight: i === 0 ? "7px" : "10px"
                    }}
                    onChange={e => {
                      {
                        const options = [...this.state.options];
                        options[i] = {
                          text: e.target.value,
                          value: e.target.value
                        };
                        this.setState({ options });
                      }
                    }}
                    value={this.state.options[i].text || ""}
                  />

                  <button
                    type="button"
                    onClick={_ =>
                      this.setState({
                        options: [...this.state.options, {}]
                      })
                    }
                    style={{
                      width: "8%",
                      backgroundColor: "yellowgreen",
                      border: "none",
                      color: "white",
                      borderRadius: "3px",
                      marginRight: "10px"
                    }}
                  >
                    +
                  </button>

                  {i !== 0 && (
                    <button
                      style={{
                        width: "8%",
                        backgroundColor: "red",
                        border: "none",
                        color: "white",
                        borderRadius: "3px"
                      }}
                      type="button"
                      onClick={_ =>
                        this.setState({
                          options: this.state.options.filter(
                            (_, index) => index !== i
                          )
                        })
                      }
                    >
                      -
                    </button>
                  )}
                </div>
              ))}
            {this.state.val === POLICY_VAL && (
              <>
                <ModalFields.textarea
                  label="Policy Text"
                  name="policyText"
                  value={this.state.policyText}
                  placeholder="Enter your policy here..."
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  required={!this.state.isFileUpload}
                />
                <ModalFields.switch
                  label="Upload File?"
                  name="isFileUpload"
                  checked={this.state.isFileUpload}
                  onClick={e => {
                    this.setState({ [e.target.name]: e.target.checked });
                  }}
                  tooltip="Check to upload a PDF of your policy"
                />
                {this.state.isFileUpload && (
                  <ModalFields.custom
                    label="Policy Document Upload"
                    name="policyDocument"
                    required
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      startIcon={<CloudUpload />}
                      style={{ width: "65%", marginRight: "0px !important" }}
                      onClick={_ => {
                        document.getElementById("uploadDocument").click();
                      }}
                    >
                      Upload
                    </Button>
                  </ModalFields.custom>
                )}
                {this.state.file && this.state.isFileUpload && (
                  <p className="confirmation" style={{ marginBottom: "10px" }}>
                    File Uploaded!
                  </p>
                )}
                {!this.state.isFileUpload && (
                  <ModalFields.input
                    label="Embed link"
                    name="link"
                    type="text"
                    placeholder="Enter link to waiver..."
                    onChange={e => {
                      this.setState({
                        [e.target.name]: e.target.value
                      });
                    }}
                    value={this.state.link}
                    tooltip="Must start with http:// or https://"
                  />
                )}
                <ModalFields.input
                  label="Checkbox Text"
                  name="checkboxText"
                  value={this.state.checkboxText}
                  type="text"
                  required
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  tooltip="This text will appear next to the checkbox"
                />
              </>
            )}
            <input
              type="file"
              style={{ display: "none" }}
              id="uploadDocument"
              onChange={e => this.fileChangeDoc(e)}
              accept="*"
            />
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn profile-btn">
              Add new
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(AddFieldModal);
