import React, { useState } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import { toast } from "react-toastify";

import { ModalTemplate, ModalFields } from "../UI/modalComponents";

const UpdateRoomsModal = ({ room, onClose }) => {
  const [name, setName] = useState(room.name);
  const [description, setDescription] = useState(room.description);
  const submitForm = async e => {
    e.preventDefault();

    const ep = `${process.env.REACT_APP_API}/partners/rooms/update`;
    const updated_room = { ...room, name: name, description: description };
    const data = { room: updated_room };
    const resp = await axios.post(ep, data);
    if (resp.data) {
      if (resp.data.success) {
        toast(resp.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        onClose();
      } else {
        toast.error(resp.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    } else {
      toast.error("something went wrong when updating room", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };
  return (
    <ModalTemplate heading="Create Room" onClose={onClose}>
      <form onSubmit={submitForm}>
        <div className="modal-main">
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              data-testid="room-label-name"
              htmlFor="room_name"
              style={{ width: "25%", textAlign: "left" }}
            >
              Room Name
            </label>
            <input
              name="room_name"
              id="room_name"
              value={name}
              type="text"
              className="form-control"
              style={{ width: "70%" }}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              data-testid="room-label-description"
              htmlFor="description"
              style={{ width: "25%", textAlign: "left" }}
            >
              Description
            </label>
            <textarea
              value={description}
              onChange={e => setDescription(e.target.value)}
              name="description"
              id="description"
              type="text"
              className="form-control"
              style={{ width: "70%" }}
              rows="4"
              columns="12"
            />
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 0
            }}
          ></div>
        </div>

        <div className="modal-footer" style={{ marginTop: "25px" }}>
          <button type="submit" className="btn profile-btn">
            Update Room
          </button>
        </div>
      </form>
    </ModalTemplate>
    // <div style={{ display: "block" }}>
    //   <form onSubmit={submitForm}>
    //     <div
    //       className="form-group"
    //       style={{
    //         display: "flex",
    //         justifyContent: "space-between",
    //         alignItems: "center",
    //         marginBottom: 0
    //       }}
    //     >
    //       <label
    //         data-testid="room-label-name"
    //         htmlFor="room_name"
    //         style={{ width: "25%", textAlign: "left" }}
    //       >
    //         Room Name
    //       </label>
    //       <input
    //         name="room_name"
    //         id="room_name"
    //         value={name}
    //         type="text"
    //         className="form-control"
    //         style={{ width: "70%" }}
    //         onChange={e => setName(e.target.value)}
    //       />
    //     </div>
    //     <div
    //       className="form-group"
    //       style={{
    //         display: "flex",
    //         justifyContent: "space-between",
    //         alignItems: "center",
    //         marginBottom: 0
    //       }}
    //     >
    //       <label
    //         data-testid="room-label-description"
    //         htmlFor="description"
    //         style={{ width: "25%", textAlign: "left" }}
    //       >
    //         Description
    //       </label>
    //       <textarea
    //         value={description}
    //         onChange={e => setDescription(e.target.value)}
    //         name="description"
    //         id="description"
    //         type="text"
    //         className="form-control"
    //         style={{ width: "70%" }}
    //         rows="4"
    //         columns="12"
    //       />
    //     </div>
    //     <div
    //       className="form-group"
    //       style={{
    //         display: "flex",
    //         justifyContent: "flex-start",
    //         alignItems: "center",
    //         marginBottom: 0
    //       }}
    //     ></div>

    //     <div className="modal-footer" style={{ marginTop: "25px" }}>
    //       <button type="submit" className="btn profile-btn">
    //         Update Room
    //       </button>
    //     </div>
    //   </form>
    // </div>
  );
};

UpdateRoomsModal.propTypes = {
  room: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired
};

export default UpdateRoomsModal;
