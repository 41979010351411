import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  withStyles
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { toast } from "react-toastify";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, GET_HTML } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

const styles = {
  accordionRoot: {
    fontFamily: "futura-pt, sans-serif",
    boxShadow: "none"
  },
  accordionExpanded: {
    boxShadow: "0 2px 15px 0 #797979"
  },
  accordionSummary: {
    "& p": {
      marginBottom: 0
    }
  },
  accordionDetails: {
    padding: 0
  }
};

class CreateRoleModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      role: "",
      roles: {
        Upcoming: { Calendar: { read: true, edit: true } },
        Account: {},
        Leads: {},
        Manage: {},
        Financials: {},
        Contacts: {},
        Listings: {}
      },
      data: {
        Upcoming: { isOpen: false, list: ["Calendar", "Tasks & Reminders"] },
        Account: {
          isOpen: false,
          list: ["Profile Setup", "Reporting"]
        },
        Leads: {
          isOpen: false,
          list: ["Form Leads", "Applications", "Appointments"]
        },
        Manage: {
          isOpen: false,
          list: [
            "Programs",
            "Events",
            "Online Virtual",
            "Memberships",
            "Parties",
            "Resources",
            "Gift Card"
          ]
        },
        Financials: {
          isOpen: false,
          list: [
            "Total Earnings",
            "Paid Orders",
            "Recurring Payments",
            "Coupons",
            "Expense Tracker"
          ]
        },
        Contacts: { isOpen: false, list: ["Connections", "Communications", "Send Message"] },
        Listings: {
          isOpen: false,
          list: [
            "Programs",
            "Events",
            "Online Virtual",
            "Memberships",
            "Parties",
            "Gift Card",
            "Forms",
            "Promotions",
            "News & Updates"
          ]
        }
      }
    };
  }

  mapListing(val) {
    return val;
  }
  filterRoles() {
    const obj = {};
    Object.keys(this.state.roles).map(e => {
      if (Object.keys(this.state.roles[e]).length === 0) {
        return;
      } else {
        Object.keys(this.state.roles[e]).map(sub => {
          if (this.state.roles[e][sub].read || this.state.roles[e][sub].edit)
            obj[e] = { ...this.state.roles[e] };
        });
      }
    });
    return obj;
  }

  async create_role() {
    const ep = `${process.env.REACT_APP_API}/partners/roles`;
    const result = await axios.post(ep, {
      accesspoints: this.filterRoles(),
      role: this.state.role
    });
    if (result.data.success) {
      toast.success(`Role Created!`);
      this.props.role(result.data.data);
      this.props.onClose();
    }
  }
  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {
    let newLinks = this.state.data;

    const ep_listings = `${process.env.REACT_APP_API}/partner/permissions/fetch/new/listings-permissions`;
    const listings_resp = await axios.get(ep_listings);
    console.log(listings_resp);

    const ep_coupon = `${process.env.REACT_APP_API}/partner/permissions/coupons`;
    const coupon_resp = await axios.get(ep_coupon);

    const ep_expense = `${process.env.REACT_APP_API}/partner/permissions/expense-tracker`;
    const expense_resp = await axios.get(ep_expense);

    console.log("coupon_resp", coupon_resp);
    console.log("expense_resp", expense_resp);

    const manage = this.state.data.Manage.list;
    const listings = this.state.data.Listings.list;

    /**
     * Enabled the has access hiding capability on listings
     */
    if (listings_resp.data && listings_resp.data.success) {
      const listNames = [];
      const listPermissions = listings_resp.data.data.filter(
        e => !e.has_access
      );
      const featureListings = listPermissions.map(e =>
        listNames.push(e.feature)
      );

      const new_nested = listings.filter(n => {
        if (n === "Memberships") {
          const isTrue = listNames.filter(f => f === "Membership")[0];
          console.log(isTrue);
          if (isTrue === "Membership") {
            return;
          }
          return n;
        } else {
          const isTrue = listNames.filter(f => n === f)[0];
          if (n === isTrue) {
            return;
          }
          return n;
        }
        // const isTrue = listNames.filter(f => n === f)[0];
        // if (n === isTrue) {
        //   return;
        // }
        // return n;
      });

      newLinks.Listings.list = new_nested;
      const new_manage = manage.filter(n => {
        if (n === "Memberships") {
          const isTrue = listNames.filter(f => f === "Membership")[0];
          console.log(isTrue);
          if (isTrue === "Membership") {
            return;
          }
          return n;
        } else {
          const isTrue = listNames.filter(f => n === f)[0];
          if (n === isTrue) {
            return;
          }
          return n;
        }
      });

      newLinks.Manage.list = new_manage;

      this.setState({ data: newLinks });
    }

    if (coupon_resp.data.success && expense_resp.data.success) {
      let permissions = this.state.data;
      const new_financial = ["Total Earnings", "Paid Orders", "Recurring Payments"];

      if (coupon_resp.data.permission.has_access) {
        new_financial.push("Coupons");
      }

      if (expense_resp.data.permission.has_access) {
        new_financial.push("Expense Tracker");
      }

      permissions.Financials.list = new_financial;
      this.setState({ data: permissions });
    }

    if (
      this.global.dsUser.plan_type === "Daycare / Preschool - DS" ||
      this.global.dsUser.plan_type === "Daycare / Preschool - Custom"
    ) {
      if (newLinks.Manage.list[5] !== "Observation") {
        newLinks.Manage.list.splice(5, 0, "Observation", "Newsfeeds");

        this.setState({ data: newLinks });
      }
    }
  }

  accordionHTML = () => {
    return Object.keys(this.state.data).map(d => (
      <Accordion
        key={d}
        classes={{
          expanded: this.props.classes.accordionExpanded,
          root: this.props.classes.accordionRoot
        }}
        expanded={this.state.data[d].isOpen}
        onChange={(event, expanded) => {
          if (event.target.nodeName !== "INPUT") {
            this.setState({
              data: {
                ...this.state.data,
                [d]: {
                  ...this.state.data[d],
                  isOpen: !this.state.data[d].isOpen
                }
              }
            });
          }
        }}
        style={{ marginRight: "10px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          className={this.props.classes.accordionSummary}
        >
          <div style={{ display: "flex", width: "90%" }}>
            <p
              style={{
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#797979",
                flexGrow: 1
              }}
            >
              {d}
            </p>
            <Switch
              size="small"
              color="primary"
              checked={
                (() => {
                  let check_state = false
                  Object.values(this.state.roles[d]).forEach(v => {
                      Object.values(v).forEach(value => {
                        if (value) check_state = true
                      })
                    })
                  return check_state
                })()
              }
              onChange={e => {
                const obj = {};
                this.state.data[d].list.forEach(
                  v =>
                    (obj[this.mapListing(v)] = {
                      read: e.target.checked,
                      edit: e.target.checked
                    })
                );
                this.setState({
                  roles: { ...this.state.roles, [d]: { ...obj } }
                });
              }}
            />
          </div>
        </AccordionSummary>

        <AccordionDetails className={this.props.classes.accordionDetails}>
          <List
            style={{
              width: "100%",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#797979"
            }}
          >
            {this.state.data[d].list.map(sub => (
              <ListItem key={sub}>
                <ListItemText
                  primary={sub}
                  //theme={theme}
                />
                <ListItemSecondaryAction>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px",
                        fontSize: "1.15rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#797979"
                      }}
                    >
                      <label
                        style={{
                          margin: "0 7px 0 7px",
                          padding: 0,
                          fontWeight: "300 !important !important",
                          fontSize: "0.7em"
                        }}
                      >
                        Read
                      </label>

                      <input
                        type="checkbox"
                        name={this.mapListing(sub)}
                        onClick={e => {
                          if (!e.target.checked)
                            this.setState({
                              roles: {
                                ...this.state.roles,
                                [d]: {
                                  ...this.state.roles[d],
                                  [e.target.name]: {
                                    ...this.state.roles[d][e.target.name],
                                    read: e.target.checked,
                                    edit: e.target.checked
                                  }
                                }
                              }
                            });
                          else
                            sub === "Profile Setup"
                              ? this.setState({
                                  roles: {
                                    ...this.state.roles,
                                    [d]: {
                                      ...this.state.roles[d],
                                      [e.target.name]: {
                                        ...this.state.roles[d][e.target.name],
                                        read: e.target.checked,
                                        edit: e.target.checked
                                      }
                                    }
                                  }
                                })
                              : this.setState({
                                  roles: {
                                    ...this.state.roles,
                                    [d]: {
                                      ...this.state.roles[d],
                                      [e.target.name]: {
                                        ...this.state.roles[d][e.target.name],
                                        read: e.target.checked
                                      }
                                    }
                                  }
                                });
                        }}
                        checked={
                          this.state.roles[d][this.mapListing(sub)]
                            ? this.state.roles[d][this.mapListing(sub)].read
                            : false
                        }
                        style={{ marginRight: "3px" }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "1.15rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#797979"
                      }}
                    >
                      <label
                        style={{
                          margin: "0 7px 0 7px",
                          padding: 0,
                          fontWeight: "300 !important",
                          fontSize: "0.7em"
                        }}
                      >
                        Edit
                      </label>

                      <input
                        type="checkbox"
                        name={this.mapListing(sub, d)}
                        onClick={e => {
                          sub === "Profile Setup"
                            ? this.setState({
                                roles: {
                                  ...this.state.roles,
                                  [d]: {
                                    ...this.state.roles[d],
                                    [e.target.name]: {
                                      ...this.state.roles[d][e.target.name],
                                      read: e.target.checked,
                                      edit: e.target.checked
                                    }
                                  }
                                }
                              })
                            : this.setState({
                                roles: {
                                  ...this.state.roles,
                                  [d]: {
                                    ...this.state.roles[d],
                                    [e.target.name]: {
                                      ...this.state.roles[d][e.target.name],
                                      edit: e.target.checked
                                    }
                                  }
                                }
                              });
                        }}
                        disabled={
                          !this.state.roles[d][this.mapListing(sub)] ||
                          !this.state.roles[d][this.mapListing(sub)].read
                        }
                        checked={
                          this.state.roles[d][this.mapListing(sub)]
                            ? this.state.roles[d][this.mapListing(sub)].edit
                            : false
                        }
                        style={{ marginRight: "3px" }}
                      />
                    </div>
                  </div>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    ));
  };

  getBaseModalFields() {
    const internalName = {
      type: STRING_INPUT,
      data: {
        name: "Role Name",
        required: false,
        placeholder: "Role Name...",
        handleChange: e => {
          this.setState({
            role: e.target.value
          });
        },
        value: this.state.role
      }
    };

    const accordion = {
      type: GET_HTML,
      data: {
        gethtml: this.accordionHTML
      }
    };

    return [internalName, accordion];
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Create",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.create_role()
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Role Permissions",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose
    };
  };

  render() {
    console.log("this.state.data", this.state.data);
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <ModalTemplate
        heading="Role Permissions"
        onClose={_ => this.props.onClose()}
      >
        <form style={{ marginTop: ".4em" }}>
          <div className="modal-main">
            {/* This field is currently useless - keep in case? - Moh */}
            {/* <ModalFields.select
              label="Role"
              name="custom_role"
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
            >
              <option>Create custom new</option>
            </ModalFields.select> */}
            <ModalFields.input
              label="Role Name"
              name="role"
              type="text"
              placeholder="Role Name..."
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
              value={this.state.role}
            />

            <div style={{ height: "1rem" }}></div>
            {this.accordionHTML()}

            {Object.keys(this.state.data).map(d => (
              <Accordion
                key={d}
                classes={{
                  expanded: this.props.classes.accordionExpanded,
                  root: this.props.classes.accordionRoot
                }}
                expanded={this.state.data[d].isOpen}
                onChange={(event, expanded) => {
                  if (event.target.nodeName !== "INPUT") {
                    this.setState({
                      data: {
                        ...this.state.data,
                        [d]: {
                          ...this.state.data[d],
                          isOpen: !this.state.data[d].isOpen
                        }
                      }
                    });
                  }
                }}
                style={{ marginLeft: "10px" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className={this.props.classes.accordionSummary}
                >
                  <div style={{ display: "flex", width: "90%" }}>
                    <p
                      style={{
                        fontSize: "0.6em",
                        fontWeight: "bold",
                        flexGrow: 1
                      }}
                    >
                      {d}
                    </p>
                    <Switch
                      size="small"
                      color="primary"
                      onChange={e => {
                        const obj = {};
                        this.state.data[d].list.forEach(
                          v =>
                            (obj[this.mapListing(v)] = {
                              read: e.target.checked,
                              edit: e.target.checked
                            })
                        );
                        this.setState({
                          roles: { ...this.state.roles, [d]: { ...obj } }
                        });
                      }}
                    />
                  </div>
                </AccordionSummary>

                <AccordionDetails
                  className={this.props.classes.accordionDetails}
                >
                  <List style={{ width: "100%" }}>
                    {this.state.data[d].list.map(sub => (
                      <ListItem key={sub}>
                        <ListItemText primary={sub} />
                        <ListItemSecondaryAction>
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "10px"
                              }}
                            >
                              <label
                                style={{
                                  margin: "0 7px 0 7px",
                                  padding: 0,
                                  fontWeight: "300 !important !important",
                                  fontSize: "0.5em"
                                }}
                              >
                                Read
                              </label>

                              <input
                                type="checkbox"
                                name={this.mapListing(sub)}
                                onClick={e => {
                                  if (!e.target.checked)
                                    this.setState({
                                      roles: {
                                        ...this.state.roles,
                                        [d]: {
                                          ...this.state.roles[d],
                                          [e.target.name]: {
                                            ...this.state.roles[d][
                                              e.target.name
                                            ],
                                            read: e.target.checked,
                                            edit: e.target.checked
                                          }
                                        }
                                      }
                                    });
                                  else
                                    sub === "Profile Setup"
                                      ? this.setState({
                                          roles: {
                                            ...this.state.roles,
                                            [d]: {
                                              ...this.state.roles[d],
                                              [e.target.name]: {
                                                ...this.state.roles[d][
                                                  e.target.name
                                                ],
                                                read: e.target.checked,
                                                edit: e.target.checked
                                              }
                                            }
                                          }
                                        })
                                      : this.setState({
                                          roles: {
                                            ...this.state.roles,
                                            [d]: {
                                              ...this.state.roles[d],
                                              [e.target.name]: {
                                                ...this.state.roles[d][
                                                  e.target.name
                                                ],
                                                read: e.target.checked
                                              }
                                            }
                                          }
                                        });
                                }}
                                checked={
                                  this.state.roles[d][this.mapListing(sub)]
                                    ? this.state.roles[d][this.mapListing(sub)]
                                        .read
                                    : false
                                }
                                style={{ marginRight: "3px" }}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              <label
                                style={{
                                  margin: "0 7px 0 7px",
                                  padding: 0,
                                  fontWeight: "300 !important",
                                  fontSize: "0.5em"
                                }}
                              >
                                Edit
                              </label>

                              <input
                                type="checkbox"
                                name={this.mapListing(sub, d)}
                                onClick={e => {
                                  sub === "Profile Setup"
                                    ? this.setState({
                                        roles: {
                                          ...this.state.roles,
                                          [d]: {
                                            ...this.state.roles[d],
                                            [e.target.name]: {
                                              ...this.state.roles[d][
                                                e.target.name
                                              ],
                                              read: e.target.checked,
                                              edit: e.target.checked
                                            }
                                          }
                                        }
                                      })
                                    : this.setState({
                                        roles: {
                                          ...this.state.roles,
                                          [d]: {
                                            ...this.state.roles[d],
                                            [e.target.name]: {
                                              ...this.state.roles[d][
                                                e.target.name
                                              ],
                                              edit: e.target.checked
                                            }
                                          }
                                        }
                                      });
                                }}
                                disabled={
                                  !this.state.roles[d][this.mapListing(sub)] ||
                                  !this.state.roles[d][this.mapListing(sub)]
                                    .read
                                }
                                checked={
                                  this.state.roles[d][this.mapListing(sub)]
                                    ? this.state.roles[d][this.mapListing(sub)]
                                        .edit
                                    : false
                                }
                                style={{ marginRight: "3px" }}
                              />
                            </div>
                          </div>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </form>

        <div className="modal-footer">
          <button
            type="submit"
            onClick={_ => this.create_role()}
            className="btn profile-btn"
          >
            Create
          </button>
        </div>
      </ModalTemplate>
    );
  }
}

export default withStyles(styles)(withRouter(CreateRoleModal));
