import React from "reactn";
import RecurringPlan from "./AddPricingPlanModal";
import SemesterPlan from "./AddPlanSemester";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { addWeeks } from "date-fns/esm";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  IMAGE_DROPDOWN,
  SWITCH,
  DROPDOWN
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
class PickPlanModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { changed: null, selected: "" };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  typeOptions = () => {
    if (this.global.online.program_category === "Single Sessions") {
      return [{ value: "Single Sessions", text: "Single Sessions" }];
    } else if (
      this.global.online.program_type == 0 &&
      !this.global.online.isRecurring
    ) {
      return [
        { value: "One-Time", text: "One-Time" },
        { value: "Free", text: "Free" }
      ];
    } else if (
      this.global.online.program_type == 0 &&
      this.global.online.isRecurring
    ) {
      return [
        { value: "Recurring", text: "Recurring" },
        { value: "Free", text: "Free" }
      ];
    } else if (this.global.online.program_type == 1) {
      return [
        { value: "Semester", text: "Semester" },
        { value: "Free", text: "Free" }
      ];
    }
  };

  getBaseModalFields() {
    const planType = {
      type: DROPDOWN,
      data: {
        name: "Plan Type",
        required: true,
        placeholder: "Please Select",
        value: this.state.changed,
        handleChange: e => {
          this.setState({
            changed: e.target.value
          });
        },
        choices: this.typeOptions()
      },
      validators: { validateSubmit: () => this.state.changed !== null }
    };
    return [planType];
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Select",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => {
        /*
        if (this.state.changed === "Free") {
          this.setGlobal(
            {
              online: {
                ...this.global.online,
                plans: [
                  ...this.global.online.plans,
                  {
                    total_price: 0,
                    type: "Free",
                    method: "Online",
                    id: this.global.online.plans.length
                  }
                ]
              }
            },
            () => {
              this.props.onClose();
              this.dispatch.saveVirtual();
            }
          );
        }
        */
        this.props.selectedPlanType(this.state.changed);
        this.props.onClose();
      }
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Add Plan",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>Add Plan!</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form>
              {this.global.online.program_category === "Single Sessions" ? (
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "25%", textAlign: "left" }}
                  >
                    Plan Type <span style={{ color: "orange" }}>*</span>
                  </label>
                  <select
                    name="displayName"
                    id="displayName"
                    type="text"
                    className="form-control"
                    style={{ width: "70%" }}
                    onChange={e => {
                      this.setState({
                        changed: e.target.value
                      });
                    }}
                  >
                    <option value="">Please Select</option>
                    <option value="Single Sessions">Single Sessions</option>
                  </select>
                </div>
              ) : (
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <label
                    htmlFor="displayName"
                    style={{ width: "25%", textAlign: "left" }}
                  >
                    Plan Type <span style={{ color: "orange" }}>*</span>
                  </label>
                  <select
                    name="displayName"
                    id="displayName"
                    type="text"
                    className="form-control"
                    style={{ width: "70%" }}
                    onChange={e => {
                      this.setState({
                        changed: e.target.value
                      });
                    }}
                  >
                    <option value="Free">Free</option>
                    {this.global.online.program_type == 0 &&
                      !this.global.online.isRecurring && (
                        <option value="One-Time">One-Time</option>
                      )}
                    {this.global.online.program_type == 0 &&
                      this.global.online.isRecurring && (
                        <option value="Recurring">Recurring</option>
                      )}
                    {this.global.online.program_type == 1 && (
                      <option value="Semester">Semester</option>
                    )}
                  </select>
                </div>
              )}
            </form>
            <div className="modal-footer">
              <button
                type="submit"
                onClick={this.cancelThis}
                className="btn profile-btn"
                onClick={_ => {
                  if (this.state.changed === "Free") {
                    this.setGlobal(
                      {
                        online: {
                          ...this.global.online,
                          plans: [
                            ...this.global.online.plans,
                            {
                              total_price: 0,
                              type: "Free",
                              method: "Online",
                              id: this.global.online.plans.length
                            }
                          ]
                        }
                      },
                      () => {
                        this.props.onClose();
                        this.dispatch.saveVirtual();
                      }
                    );
                  }
                  this.props.selectedPlanType(this.state.changed);
                  this.props.onClose();
                }}
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PickPlanModal);
