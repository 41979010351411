import React from "reactn";
import axios from "axios";
import "../../assets/css/componentSpecificCss/membershipManage.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

class ManageGift extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: "",
      detailsModalShown: false,
      modalData: [],
      original: {},
      images_keys: [
        "partners/Gift Cards/zeus-01.png",
        "partners/Gift Cards/zeus-02.png",
        "partners/Gift Cards/zeus-03.png",
        "partners/Gift Cards/zeus-04.png",
        "partners/Gift Cards/zeus-05.png",
        "partners/Gift Cards/zeus-06.png",
        "partners/Gift Cards/zeus-07.png",
        "partners/Gift Cards/zeus-08.png"
      ]
    };
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Manage Gift Cards", to: "/gift_cards/manage" }
      ]
    });
  }

  async componentDidMount() {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/gifts/list`;
    const results = await axios.get(ep);

    if (results.data.success) {
      this.setState({
        data: results.data.data.filter(r => !r.isArchived)
      });
    }
    this.setGlobal({ loading: false });
  }

  render() {
    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          <div className="cont">
            <h1>Gift Cards</h1>
          </div>
          <div className="cont">
            {this.state.data.length > 0 ? (
              <div className="tablecont">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {this.state.data.map((e, key) => (
                    <div
                      className="membership-cards"
                      style={{
                        backgroundColor: "white",
                        width: "300px",
                        borderRadius: "10px",
                        marginRight: "10px",
                        marginBottom: "10px",
                        cursor: "pointer"
                      }}
                      onClick={_ =>
                        this.props.history.push(
                          `/partners/gift_cards/manage/single/${e.id}`
                        )
                      }
                      key={key}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "180px",
                          position: "relative"
                        }}
                      >
                        <LazyLoadImage
                          src={`${process.env.REACT_APP_DS_FILES_S3}/${
                            this.state.images_keys[e.image - 1]
                          }`}
                          alt="Membership"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                          }}
                          wrapperProps={{
                            style: {
                              width: "100%",
                              height: "100%",
                              objectFit: "cover"
                            }
                          }}
                          effect="opacity"
                        />
                        <p
                          style={{
                            position: "absolute",
                            fontSize: e.image < 4 ? "30px" : "36px",
                            fontWeight: "bold",
                            top:
                              e.image === 1
                                ? "15%"
                                : e.image < 4
                                ? "90%"
                                : "50%",
                            left:
                              e.image === 1
                                ? "15%"
                                : e.image < 4
                                ? "85%"
                                : "50%",
                            transform: "translate(-50%,-50%)",
                            color: e.image < 4 ? "#1f3b89" : "white",
                            fontFamily: "Montserrat"
                          }}
                        >
                          ${e.amount}
                        </p>
                      </div>
                      <p
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "12px",
                          marginTop: "5px",
                          marginBottom: "5px"
                        }}
                      >
                        {e.gift_name || "Gift Card"} ${e.amount}
                      </p>
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          width: "280px",
                          overflow: "hidden",
                          margin: "5px auto",
                          color: "grey",
                          fontSize: "10px",
                          textOverflow: "ellipsis",
                          textAlign: "center"
                        }}
                      >
                        {e.description}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "5px"
                        }}
                      >
                        <span
                          style={{
                            flexGrow: 1,
                            color: "lightgrey",
                            fontSize: "12px",
                            marginLeft: "5px"
                          }}
                        >
                          {key + 1} / {this.state.data.length}
                        </span>
                        <img
                          src="/static/media/monthlysurvey.122ae06e.svg"
                          alt=""
                          style={{ marginRight: "10px" }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "60vh",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <p
                  style={{
                    fontStyle: "italic",
                    textAlign: "center",
                    fontSize: "18px"
                  }}
                >
                  Click{" "}
                  <b>
                    <Link to="/partners/gift_cards">here</Link>
                  </b>{" "}
                  to start creating your own gift cards!
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ManageGift;
