import React, { Component } from "reactn";
import { format } from "date-fns";
import numeral from "numeral";
class InvoiceModal extends Component {
  constructor(props) {
    super(props);
    if (this.props.slice)
      this.setGlobal({
        pathway: [...this.global.pathway.slice(0, 3), "Invoice Details"]
      });
  }
  state = {};
  render() {
    const { setInvoice, invoice, maps } = this.props;
    const dsFee = invoice.fee ? parseInt(this.global.profile.dsFee) : 0;
    return (
      <>
        <div className="cont">
          <div className="row">
            <div className="col-md-6">
              <h1>
                <a
                  role="button"
                  onClick={() => {
                    setInvoice(null);
                    if (this.props.slice)
                      this.setGlobal({
                        pathway: [...this.global.pathway.slice(0, 3)]
                      });
                  }}
                >
                  <i className="fas fa-arrow-left"></i>
                </a>{" "}
                View Invoice Details
              </h1>
            </div>
          </div>
        </div>
        <div className="cont">
          <div className="row">
            <div
              style={{
                width: window.innerWidth > 700 ? "60%" : "90%",
                backgroundColor: "white",
                margin: window.innerWidth > 700 ? "0" : "0 auto",
                position: "relative"
              }}
            >
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  backgroundColor: "#1bc88a",
                  top: "-20px",
                  right: "-20px",
                  color: "white",
                  width: "50px",
                  height: "50px",
                  fontWeight: "bold",
                  borderRadius: "50%",
                  border: "1px solid white",
                  textAlign: "center"
                }}
              >
                Paid
              </div>
              <h5
                style={{
                  padding: "10px",
                  color: "white",
                  fontWeight: "bold",
                  background:
                    "linear-gradient(to right, #7ea0ff 0%, #915aff 100%)"
                }}
              >
                Invoice #{invoice.number}
              </h5>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px"
                }}
              >
                <div>
                  <p style={{ fontWeight: "bold" }}>
                    <b>By {invoice.account_name}</b> <br /> For{" "}
                    {maps
                      ? maps[invoice.subscription]
                        ? maps[invoice.subscription].child
                        : ""
                      : invoice.student}{" "}
                    (Student)
                  </p>
                </div>
                <div>
                  <p style={{ fontWeight: "bold" }}>
                    <b>Service Period</b>:{" "}
                    {invoice.period_end - invoice.period_start < 691200
                      ? `${format(
                          new Date(
                            invoice.period_start * 1000 + 7 * 24 * 3600000
                          ),
                          "LLL d"
                        )} - ${format(
                          new Date(
                            invoice.period_end * 1000 + 7 * 24 * 3600000
                          ),
                          "LLL d yyyy"
                        )}`
                      : `${format(
                          new Date(invoice.period_start * 1000),
                          "LLL d"
                        )} - ${format(
                          new Date(invoice.period_end * 1000),
                          "LLL d yyyy"
                        )}`}
                    <br />
                    <b>Due Date</b>:{" "}
                    {invoice.next_payment_attempt
                      ? `${format(
                          new Date(invoice.next_payment_attempt * 1000),
                          "LLL d yyyy"
                        )}`
                      : `N/A`}
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "5px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  borderBottom: "1px solid #dadbeb"
                }}
              >
                <b style={{ width: "30%" }}>Amount</b>
                <b style={{ width: "30%" }}>Type</b>
                <b style={{ width: "30%" }}>Date Charged</b>
              </div>
              {invoice.lines.data.map(item => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    padding: "20px"
                  }}
                  key={item.id}
                >
                  <p style={{ fontWeight: "bold" }} style={{ width: "30%" }}>
                    {dsFee
                      ? dsFee > 7
                        ? item.amount > 0
                          ? numeral(
                              (item.amount -
                                (item.amount * (dsFee / 100 - 0.003) + 30)) /
                                100
                            ).format("($0,0.00)")
                          : numeral(
                              (item.amount +
                                (Math.abs(item.amount) * (dsFee / 100 - 0.003) +
                                  30)) /
                                100
                            ).format("($0,0.00)")
                        : item.amount > 0
                        ? numeral(
                            (item.amount -
                              (item.amount * (dsFee / 100 - 0.003 + 0.01) +
                                30)) /
                              100
                          ).format("($0,0.00)")
                        : numeral(
                            (item.amount +
                              (Math.abs(item.amount) *
                                (dsFee / 100 - 0.003 + 0.01) +
                                30)) /
                              100
                          ).format("($0,0.00)")
                      : numeral(item.amount / 100).format("($0,0.00)")}
                  </p>
                  <p style={{ fontWeight: "bold" }} style={{ width: "30%" }}>
                    {item.description.includes("1 × ")
                      ? item.description.includes("Service")
                        ? "Service Fee"
                        : "Enrollment"
                      : item.description.slice(
                          0,
                          item.description.indexOf("(")
                        )}
                  </p>
                  <p style={{ fontWeight: "bold" }} style={{ width: "30%" }}>
                    {invoice.next_payment_attempt
                      ? `${format(
                          new Date(invoice.next_payment_attempt * 1000),
                          "LLL d yyyy"
                        )}`
                      : `${
                          invoice.period_end - invoice.period_start < 691200
                            ? `${format(
                                new Date(
                                  invoice.period_end * 1000 + 7 * 24 * 3600000
                                ),
                                "LLL d yyyy"
                              )}`
                            : `${format(
                                new Date(invoice.period_end * 1000),
                                "LLL d yyyy"
                              )}`
                        }`}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <h3
            style={{
              textAlign: window.innerWidth > 700 ? "center" : "right",
              color: "#042470",
              marginTop: "22px"
            }}
          >
            Total Gross Amount :{" "}
            {invoice.fee
              ? numeral((invoice.amount_due - invoice.fee) / 100).format(
                  "($0,0.00)"
                )
              : numeral(invoice.amount_due / 100).format("($0,0.00)")}
          </h3>
        </div>
      </>
    );
  }
}

export default InvoiceModal;
