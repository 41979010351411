import React from "react";
import Title from "../Title";
//import "../fieldComponent.css";

const DEFAULT_HEIGHT = "100px";

const htmlcomp = props => {
  const {
    name,
    required,
    gethtml,
    styles
  } = props;

  const height = props.height || DEFAULT_HEIGHT;

  return (
    <div style={styles}>
      <Title
        name={name}
        required={required}
      />
        {gethtml()}
    </div>
  );
};

export default htmlcomp;
