import React from "reactn";
import EmergencyContact from "./forms/EmergencyContact";

class EmergencyContactsPage extends React.Component {
  handChange = e => {
    const vals = this.global.pendingParent.emergency.map(c =>
      c.id === e.id ? e : c
    );
    this.setGlobal({
      pendingParent: { ...this.global.pendingParent, emergency: vals }
    });
  };

  handRemove = e => {
    this.dispatch.removeEmergencyContactFromFamilyProfile(e);
  };

  render() {
    return (
      <div className="cont boxes-row mt-50px">
        {this.global.pendingParent.emergency.map((emergencyContact, i) => (
          <EmergencyContact
            key={i}
            emergencyContact={emergencyContact}
            contactNo={i}
            onChange={this.handChange}
            onRemove={this.handRemove}
          />
        ))}
        <button
          className="addchild"
          onClick={() => this.dispatch.addEmergencyContactToFamilyProfile()}
        >
          <h3>Add Emergency Contact</h3>
        </button>
      </div>
    );
  }
}

export default EmergencyContactsPage;
