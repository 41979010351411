import React from "reactn";
import axios from "axios";
import { toast } from "react-toastify";
import { TextareaAutosize, withStyles } from "@material-ui/core";
import "../../../assets/css/componentSpecificCss/userAccess.css";
import Avatar from "../../../assets/img/msgicon.png";
import { CMale, MiniPlus } from "../../../assets";
import { Link } from "react-router-dom";
import GuardianDetailModal from "../../../components/modals/GuardianDetailModal";
import { NewTabbedTable } from "../../../components/UI";
import { format } from "date-fns";
import UploadFileModal from "../../../components/modals/UploadFileModal";
import { Delete, Remove } from "@material-ui/icons";
import ReactTooltip from "react-tooltip";
import RemoveFileConfirmationModal from "../../../components/modals/RemoveFileConfirmationModal";

const styles = {
  iconButton: {
    backgroundColor: "transparent !important",
    padding: "1em !important",
    color: "rgba(0, 0, 0, 0.54) !important",
    "&:focus": {
      outline: "none !important"
    }
  },
  avatar: {
    width: "50px",
    height: "50px"
  },
  item: {
    padding: "5px 10px",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "13px"
  },
  list: {
    height: "60px",
    padding: "0px"
  },
  tabRoot: {
    color: "#61616F !important",
    fontFamily: "Open Sans,sans-serif !important",
    fontWeight: "600 !important",
    fontSize: "1rem !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "#61616F !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "#61616F !important"
    }
  },
  tabSelected: {
    color: "black !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "black !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "black !important"
    }
  },
  tabIndicator: {
    backgroundColor: "#61616F",
    height: "3px",
    marginTop: "20px"
  }
};

class Profile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      subActive: "Info",
      canEdit: false,
      role: [],
      hasAllAccess: false,
      data: { Active: [], Uploads: [] },
      parent: [],
      child: [],
      emergency: [],
      viewParentDetailsModal: false,
      currentParent: {},
      openUploadFileModal: false,
      location: {},
      has_submitted: false,
      organizerUploads: [],
      fileName: "",
      openRemoveFileConfirmationModal: false,
      selectedFile: null,
      name: "",
      cellphone: "",
      address: "",
      city: "",
      region: "",
      postalCode: "",
      country: ""
    };

    this.tabsUploads = ["Uploads"];
    this.headingsUploads = {
      Uploads: [
        {
          id: "fileName",
          label: "File Name",
          customCell: (r, i) => {
            return (
              <td key={`${i}-fileName`}>
                {r.file_uploads.file_display_name
                  ? r.file_uploads.file_display_name
                  : r.file_uploads.file_name}
              </td>
            );
          }
        },
        {
          id: "type",
          label: "Type",
          customCell: (r, i) => {
            if (r.contactUploads || (!r.programId && !r.onlineId)) {
              return <td key={`${i}-name`}>Organizer</td>;
            }
            return (
              <td key={`${i}-name`}>
                {r.milestoneName} ({r.milestoneType})
              </td>
            );
          }
        },
        {
          id: "date",
          label: "Date",
          customCell: (r, i) => {
            return (
              <td key={`${i}-date`}>
                {format(new Date(r.file_uploads.upload_date), "LLLL dd, yyyy")}
              </td>
            );
          }
        },
        {
          id: "file",
          label: "File",
          customCell: (r, i) => {
            this.setState({ selectedFile: r });
            return (
              <td key={`${i}-file`}>
                <a href={r.file_uploads.file_url} target="_blank">
                  View File
                </a>
                {r.file_uploads.file_display_name && (
                  <>
                    <Delete
                      onClick={() => {
                        console.log("DELETE FILE", r);
                        this.setState({
                          openRemoveFileConfirmationModal: true
                        });
                      }}
                      style={{
                        fill: "grey",
                        marginLeft: "3px",
                        cursor: "pointer"
                      }}
                      data-for="remove-file"
                      data-tip
                    />
                    <ReactTooltip
                      id="remove-file"
                      place="top"
                      effect="solid"
                      type="info"
                    >
                      Remove File
                    </ReactTooltip>
                  </>
                )}
              </td>
            );
          }
        }
      ]
    };
    this.actions = [{ name: "Notes", action: null }];
  }

  // async componentDidUpdate(prevProp, prevState) {
  //   if (
  //     prevState.fileSubmitted !== this.state.fileSubmitted ||
  //     (prevState.openRemoveFileConfirmationModal &&
  //       prevState.openRemoveFileConfirmationModal !==
  //         this.state.openRemoveFileConfirmationModal)
  //   ) {
  //     this.setGlobal({ loading: true });

  //     let dsUserId = this.state.selectedParent;
  //     const ep3 = `${process.env.REACT_APP_API}/partners/milestones_submissions/get/all/${dsUserId}`;
  //     const milestone_submissions = await axios.get(ep3);
  //     const ep4 = `${process.env.REACT_APP_API}/partners/file_uploads/get/all/${dsUserId}`;
  //     const organizer_file_uploads = await axios.get(ep4);
  //     console.log("MEMEMEME", milestone_submissions, organizer_file_uploads);
  //     let fileArr = [];
  //     milestone_submissions.data.Participants.map(item => {
  //       if (item && item.file_uploads) {
  //         let count = 1;
  //         while (item.file_uploads["file_" + count]) {
  //           if (!item.file_uploads["file_" + count]?.isHidden) {
  //             fileArr.push({
  //               ...item,
  //               file_uploads: item.file_uploads["file_" + count]
  //             });
  //           }
  //           count++;
  //         }
  //       }
  //     });
  //     organizer_file_uploads.data.data.map(item => {
  //       if (item && item.contactUploads) {
  //         let count = 1;
  //         while (item.contactUploads["file_" + count]) {
  //           if (!item.contactUploads["file_" + count]?.isHidden) {
  //             fileArr.push({
  //               ...item,
  //               file_uploads: item.contactUploads["file_" + count]
  //             });
  //           }
  //           count++;
  //         }
  //       }
  //     });

  //     this.setState({
  //       data: {
  //         ...this.state.data,
  //         Uploads: fileArr
  //       }
  //     });

  //     this.setGlobal({ loading: false });
  //   }
  // }

  async componentDidMount() {
    this.setGlobal({ loading: true });
    await this.fetchInitialData();
    this.setGlobal({ loading: false });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.connectedEmail !== this.props.connectedEmail) {
      this.setGlobal({ loading: true });
      await this.fetchInitialData();
      this.setGlobal({ loading: false });
    }
  }

  async fetchInitialData() {
    const searchParams = new URLSearchParams(window.location.search);
    let parentId = searchParams.get("parentId");

    if (parentId === "undefined") {
      parentId = false;
    }

    const ep = `${process.env.REACT_APP_API}/partners/connections/${true}`;
    const results = await axios.get(ep, {
      params: {
        parentId: parentId
      }
    });
    this.setState({ selectedParent: parentId });

    console.log("PARENT ID PROFILE", parentId);
    if (parentId) {
      const ep2 = `${process.env.REACT_APP_API}/partners/connections/profile-info/${parentId}`;
      const results2 = await axios.get(ep2);
      const parentInfo = results2.data.data[0];

      console.log("PARENTINFO 123", parentInfo, parentInfo?.homePhone);
      this.setState({
        name: parentInfo?.fullName,
        address: parentInfo?.address,
        cellphone: parentInfo?.homePhone,
        city: parentInfo?.city,
        region: parentInfo?.province,
        postalCode: parentInfo?.postalCode,
        country: parentInfo?.country
      });
    }

    let dsUserId;
    if (results.data.success) {
      results.data.data.connections
        .filter(f => f.email === this.props.connectedEmail)
        .map(e => (dsUserId = e.dsUserId));

      this.setState({
        data: {
          Active: results.data.data.connections
            .filter(f => f.email === this.props.connectedEmail)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        },
        updatedName: results.data.data.connections
          .filter(f => f.email === this.props.connectedEmail)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]
          .displayName,
        updatedPhone: results.data.data.connections
          .filter(f => f.email === this.props.connectedEmail)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]
          .phone
      });
    }

    if (this.props.isActive && dsUserId) {
      const ep2 = `${process.env.REACT_APP_API}/partners/connections/par-child/${dsUserId}`;
      const results2 = await axios.get(ep2);

      if (results.data.success) {
        this.setState({
          parent: results2.data.data.parent.filter(
            parent => !parent.isArchived
          ),
          child: results2.data.data.child.filter(child => !child.isArchived),
          emergency: results2.data.data.emergency,
          physician: results2.data.data.physician
        });

        // const ep3 = `${process.env.REACT_APP_API}/partners/milestones_submissions/get/all/${dsUserId}`;
        // const milestone_submissions = await axios.get(ep3);
        // const ep4 = `${process.env.REACT_APP_API}/partners/file_uploads/get/all/${dsUserId}`;
        // const organizer_file_uploads = await axios.get(ep4);

        // if (organizer_file_uploads.data.data.length === 0) {
        //   this.setState({ has_submitted: false });
        // } else {
        //   this.setState({ has_submitted: true });
        // }
        // this.setState({ organizerUploads: organizer_file_uploads.data.data });

        // let fileArr = [];
        // milestone_submissions.data.Participants.map(item => {
        //   if (item && item.file_uploads) {
        //     let count = 1;
        //     while (item.file_uploads["file_" + count]) {
        //       if (!item.file_uploads["file_" + count]?.isHidden) {
        //         fileArr.push({
        //           ...item,
        //           file_uploads: item.file_uploads["file_" + count]
        //         });
        //       }
        //       count++;
        //     }
        //   }
        // });
        // organizer_file_uploads.data.data.map(item => {
        //   if (item && item.contactUploads) {
        //     let count = 1;
        //     while (item.contactUploads["file_" + count]) {
        //       if (!item.contactUploads["file_" + count]?.isHidden) {
        //         fileArr.push({
        //           ...item,
        //           file_uploads: item.contactUploads["file_" + count]
        //         });
        //       }
        //       count++;
        //     }
        //   }
        // });

        // this.setState({
        //   data: {
        //     ...this.state.data,
        //     Uploads: fileArr
        //   }
        // });
      }
    }
  }

  async updateAddedContactData() {
    if (this.state.data.Active[0].added_contact) {
      const obj = this.state.data.Active[0].custom;
      const nameKey = Object.keys(obj).filter(f => f.includes("Full Name"))[0];
      const phoneKey = Object.keys(obj).filter(f =>
        f.includes("Cell Phone")
      )[0];

      obj[nameKey] = this.state.updatedName;
      obj[phoneKey] = this.state.updatedPhone;

      const endp = `${process.env.REACT_APP_API}/partners/leads/update/added-contact`;
      const result = await axios.post(endp, {
        id: this.state.data.Active[0].id,
        name: this.state.updatedName,
        custom: obj
        //notes: this.state.data.Active[0].notes
      });

      if (result.data.success) {
        this.setState({ canEdit: false });
        this.props.updated(result.data);
        toast.success("User Info Updated!");
      } else {
        toast.error(`Unable to update!`);
      }
    }
  }

  // async fileChange(e) {
  //   let count = 1;
  //   // if (this.state.selectedMilestoneView.file_uploads) {
  //   //   count =
  //   //     Object.keys(this.state.selectedMilestoneView.file_uploads).length + 1;
  //   // }

  //   let fileNum = "";
  //   while (e.length >= 1) {
  //     fileNum = "file_" + count;
  //     console.log("FILE NAME", fileNum);
  //     let fileName = e[0].name;
  //     if (e.length && e[0]) {
  //       const fd = new FormData();
  //       fd.set("file", e[0]);

  //       const ep = `${process.env.REACT_APP_API}/partners/file_uploads/file/upload`;
  //       const postreq = await axios.post(ep, fd, {
  //         onUploadProgress: progressEvent => {
  //           let progress =
  //             Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
  //             "%";
  //           this.setState({ progress: progress });
  //         }
  //       });

  //       if (postreq.data && postreq.data.success) {
  //         const location = {
  //           name: postreq.data.name,
  //           path: postreq.data.path,
  //           fileName: fileName
  //         };
  //         this.setState({
  //           location: {
  //             ...this.state.location,
  //             [`${fileNum}`]: location
  //           }
  //         });
  //         count++;
  //         e.shift();
  //       }
  //     }
  //   }
  //   return;
  // }

  // submitFileUpload = async e => {
  //   this.setGlobal({ loading: true });
  //   const ep_uploads = `${process.env.REACT_APP_API}/partners/file_uploads/get/all/${this.state.selectedParent}`;
  //   const result_uploads = await axios.get(ep_uploads);

  //   let dupeFile = false;
  //   result_uploads.data.data.map(file => {
  //     console.log("FILE: ", file);
  //     if (file.contactUploads) {
  //       if (
  //         !file.contactUploads["file_1"]?.isHidden &&
  //         file.contactUploads["file_1"]?.file_name ===
  //           this.state.location["file_1"]?.fileName
  //       ) {
  //         toast.error("You have already uploaded this file!");
  //         dupeFile = true;
  //         return;
  //       }
  //     }
  //   });

  //   let fileUploads = {};

  //   let count = 1;

  //   // while (this.state.location["file_" + count]) {
  //   //   fileUploads["file_" + count] = {
  //   //     file_url: this.state.location["file_" + count].path,
  //   //     file_name: this.state.fileName,
  //   //     upload_date: new Date(),
  //   //     isHidden: false
  //   //   };
  //   //   this.state.data.Uploads.push({
  //   //     file_uploads: fileUploads["file_" + count]
  //   //   });
  //   //   count++;
  //   // }

  //   if (!dupeFile) {
  //     fileUploads["file_" + count] = {
  //       file_url: this.state.location["file_" + count].path,
  //       file_name: this.state.location["file_" + count].fileName,
  //       file_display_name: this.state.fileName,
  //       upload_date: new Date(),
  //       isHidden: false
  //     };

  //     // this.state.data.Uploads.push({
  //     //   file_uploads: fileUploads["file_" + count]
  //     // });

  //     const dataSubmission = {
  //       file_uploads: fileUploads,
  //       parentId: this.state.selectedParent
  //     };

  //     const ep = `${process.env.REACT_APP_API}/partners/file_uploads/add`;
  //     const submitFileUploadData = await axios.post(ep, dataSubmission);
  //     if (submitFileUploadData.data && submitFileUploadData.data.success) {
  //       toast.success("Successfully Submitted");
  //       this.setState({
  //         location: null,
  //         openUploadFileModal: false,
  //         fileSubmitted: true
  //       });
  //       this.setGlobal({ loading: false });
  //       // window.location = `/user/milestones/partner_programs/${this.props.match.params.id}`
  //     } else {
  //       // todo account for if no success
  //       console.log("CANT SUBMIT");
  //     }
  //   }
  //   this.setGlobal({ loading: false });
  // };

  // // editFileUpload = async e => {
  // //   console.log("EDIT FILENAME", this.state.fileName);
  // //   let fileUploads = this.state.organizerUploads[0].contactUploads;

  // //   let numFiles = 1;
  // //   Object.keys(fileUploads).forEach(file => {
  // //     if (!fileUploads[file].isHidden) {
  // //       numFiles++;
  // //     }
  // //   });

  // //   // if (numFiles > 6) {
  // //   //   toast.error("Cannot upload more than 6 files!");
  // //   //   return;
  // //   // }

  // //   Object.keys(this.state.location).forEach(file => {
  // //     Object.keys(fileUploads).forEach(uploadedFile => {
  // //       if (
  // //         !fileUploads[uploadedFile].isHidden &&
  // //         fileUploads[uploadedFile].file_name ===
  // //           this.state.location[file].fileName
  // //       ) {
  // //         console.log(
  // //           "DUPE FILE",
  // //           fileUploads[uploadedFile].file_name,
  // //           this.state.location[file].fileName
  // //         );
  // //       }
  // //     });
  // //     fileUploads = {
  // //       ...fileUploads,
  // //       ["file_" + numFiles]: {
  // //         file_url: this.state.location[file].path,
  // //         file_name: this.state.fileName,
  // //         upload_date: new Date(),
  // //         isHidden: false
  // //       }
  // //     };
  // //     this.state.data.Uploads.push({
  // //       file_uploads: fileUploads["file_" + numFiles]
  // //     });
  // //     numFiles++;
  // //   });

  // //   const dataSubmission = {
  // //     file_uploads: fileUploads,
  // //     parentId: this.state.selectedParent
  // //   };

  // //   const ep = `${process.env.REACT_APP_API}/partners/file_uploads/edit`;
  // //   const editFileData = await axios.put(ep, dataSubmission);

  // //   if (editFileData.data && editFileData.data.success) {
  // //     toast.success("Successfully Submitted");
  // //     this.setState({ location: null });
  // //     // window.location = `/user/milestones/partner_programs/${this.props.match.params.id}`
  // //   } else {
  // //     // todo account for if no success
  // //     console.log("CANT SUBMIT");
  // //   }
  // // };

  // editFileName = name => {
  //   console.log("THE NAME", name);
  //   // this.setState({ fileName: name });
  //   this.state.fileName = name;
  // };

  render() {
    if (!this.state.data.Active[0]) {
      return null;
    }
    console.log("this.state", this.state);

    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          <div className="user-task-box">
            {/* {this.state.openUploadFileModal && (
              <UploadFileModal
                onClose={() => {
                  this.setState({ openUploadFileModal: false });
                }}
                onSubmit={async () => {
                  // if (this.state.has_submitted) {
                  //   this.editFileUpload();
                  // } else {
                  await this.submitFileUpload();
                  //}
                }}
                onFileChange={e => this.fileChange(e)}
                onFileNameChange={name => this.editFileName(name)}
              />
            )}
            {this.state.openRemoveFileConfirmationModal && (
              <RemoveFileConfirmationModal
                onClose={() => {
                  this.setState({ openRemoveFileConfirmationModal: false });
                  console.log("THE CHANGED FILE", this.state.selectedFIle);
                }}
                selectedFile={this.state.selectedFile}
              />
            )} */}
            <div
              className="user-nav-line"
              style={{ justifyContent: "space-around" }}
            >
              {this.state.data.Active[0].added_contact && (
                <div className="left-box">
                  <i
                    className="fas fa-pencil-alt pencil-style"
                    onClick={_ =>
                      this.setState({ canEdit: !this.state.canEdit })
                    }
                  ></i>
                </div>
              )}
              <div
                className="right-box"
                style={{ justifyContent: "space-between", marginRight: "10px" }}
              >
                <span
                  className={`user-sub-header ${
                    this.state.subActive === "Info" && "active-user-sub-header"
                  }`}
                  onClick={_ => this.setState({ subActive: "Info" })}
                >
                  Info
                </span>
                <span
                  className={`user-sub-header ${
                    this.state.subActive === "Family" &&
                    "active-user-sub-header"
                  }`}
                  onClick={_ => this.setState({ subActive: "Family" })}
                >
                  Family
                </span>
                <span
                  className={`user-sub-header ${
                    this.state.subActive === "Additional" &&
                    "active-user-sub-header"
                  }`}
                  onClick={_ => this.setState({ subActive: "Additional" })}
                >
                  Additional
                </span>
                <span
                  className={`user-sub-header ${
                    this.state.subActive === "Notes" && "active-user-sub-header"
                  }`}
                  onClick={_ => this.setState({ subActive: "Notes" })}
                >
                  Notes
                </span>
                {/* <span
                  className={`user-sub-header ${
                    this.state.subActive === "Uploads" &&
                    "active-user-sub-header"
                  }`}
                  onClick={_ => this.setState({ subActive: "Uploads" })}
                >
                  Uploads
                </span> */}
              </div>
            </div>

            {this.state.subActive === "Info" && (
              <div className="individual-info">
                <div className="user-info-section">
                  <div className="user-row">
                    <label className="user-label">Name</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="conn-input-field"
                        value={
                          this.state.name
                            ? this.state.name
                            : this.state.updatedName
                        }
                        disabled={!this.state.canEdit}
                        onChange={e =>
                          this.setState({
                            updatedName: e.target.value,
                            name: e.target.value
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Email</label>
                    <div className="user-item-content">
                      <input
                        type="email"
                        className="conn-input-field"
                        value={this.state.data.Active[0].email}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Cell Phone</label>
                    <div className="user-item-content">
                      {console.log("LOOK HERE", this.state.cellphone)}
                      <input
                        type="input"
                        className="conn-input-field"
                        value={
                          this.state.cellphone
                            ? this.state.cellphone
                            : this.state.updatedPhone
                        }
                        disabled={!this.state.canEdit}
                        onChange={e =>
                          this.setState({ updatedPhone: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Address</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="conn-input-field"
                        value={
                          this.state.address
                            ? this.state.address
                            : this.state.data.Active[0].address
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">City</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="conn-input-field"
                        value={
                          this.state.city
                            ? this.state.city
                            : this.state.data.Active[0].city
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Region</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="conn-input-field"
                        value={
                          this.state.region
                            ? this.state.region
                            : this.state.data.Active[0].province
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Postal / Zip</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="conn-input-field"
                        value={
                          this.state.postalCode
                            ? this.state.postalCode
                            : this.state.data.Active[0].postalCode
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="user-row">
                    <label className="user-label">Country</label>
                    <div className="user-item-content">
                      <input
                        type="input"
                        className="conn-input-field"
                        value={
                          this.state.country
                            ? this.state.country
                            : this.state.data.Active[0].country
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.viewParentDetailsModal && (
              <>
                <GuardianDetailModal
                  fullName={this.state.currentParent.fullName}
                  address={this.state.currentParent.address}
                  city={this.state.currentParent.city}
                  country={this.state.currentParent.country}
                  email={this.state.currentParent.email}
                  homePhone={this.state.currentParent.homePhone}
                  postalCode={this.state.currentParent.postalCode}
                  province={this.state.currentParent.province}
                  onClose={() =>
                    this.setState({
                      viewParentDetailsModal: false
                    })
                  }
                />
              </>
            )}

            {this.state.subActive === "Family" && (
              <>
                {this.state.parent.map(e => {
                  return (
                    <div className="fam-parent-item-row">
                      <div
                        className="fam-parent-avatar"
                        style={{
                          backgroundImage: `url(${Avatar})`,
                          backgroundPosition: "center",
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          backgroundSize: "cover"
                        }}
                      />
                      <div className="fam-parent-name">{e.fullName}</div>
                      {this.state.child.length > 0 && (
                        <div className="fam-parent-type">
                          Parent / Guardian
                          <span
                            className="p-2"
                            style={{ color: "mediumblue", cursor: "pointer" }}
                            onClick={_ => {
                              this.setState({
                                viewParentDetailsModal:
                                  !this.state.viewParentDetailsModal,
                                currentParent: e
                              });
                            }}
                          >
                            View
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}

                {this.state.child.map(e => {
                  return (
                    <div className="fam-child-item-row">
                      <div
                        className="fam-child-avatar"
                        style={{
                          backgroundImage: `url(${CMale})`,
                          backgroundPosition: "center",
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          backgroundSize: "cover"
                        }}
                      />
                      <div className="fam-child-name">{e.fullName}</div>
                      <div className="fam-child-type">
                        Child{" "}
                        <Link
                          to={`/partners/child-profile/${e.dsUserId}/${e.id}`}
                        >
                          (view more details)
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </>
            )}

            {this.state.subActive === "Additional" && (
              <>
                {this.state.emergency.length > 0 ? (
                  this.state.emergency.map(e => {
                    return (
                      <div className="individual-info">
                        <div className="user-info-section">
                          <div className="user-row">
                            <label className="user-label">Name</label>
                            <div className="user-item-content">
                              <input
                                type="input"
                                className="conn-input-field"
                                value={e.fullName}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="user-row">
                            <label className="user-label">Relationship</label>
                            <div className="user-item-content">
                              <input
                                type="input"
                                className="conn-input-field"
                                value={e.relationship}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="user-row">
                            <label className="user-label">Cell Phone</label>
                            <div className="user-item-content">
                              <input
                                type="input"
                                className="conn-input-field"
                                value={e.phone}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="user-row">
                            <label className="user-label">Address</label>
                            <div className="user-item-content">
                              <input
                                type="input"
                                className="conn-input-field"
                                value={e.address}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "25px",
                      textAlign: "center"
                    }}
                  >
                    <span>No emergency contact provided by the user.</span>
                  </div>
                )}
              </>
            )}

            {this.state.subActive === "Notes" && (
              <>
                <div
                  className="schedule-notes"
                  style={{ height: "75%", marginBottom: "15px" }}
                >
                  {this.state.data.Active.find(e => e.notes !== null) ? (
                    this.state.data.Active.map((e, i) => {
                      if (e.notes !== null) {
                        return (
                          <div
                            style={{ paddingLeft: "5%", marginBottom: "10px" }}
                            key={i}
                          >
                            <span style={{ fontWeight: "550" }}>
                              {e.came_from}
                            </span>
                            <TextareaAutosize
                              name="notes"
                              id="notes"
                              type="text"
                              disabled={true}
                              value={e.notes}
                              style={{
                                width: "95%",
                                border: "1px solid lightgrey",
                                padding: "8px 12px",
                                borderRadius: "10px",
                                backgroundColor: "#fff",
                                resize: "none"
                              }}
                              columns="12"
                            />
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <span>No notes available for the user.</span>
                    </div>
                  )}
                </div>
              </>
            )}
            {/* {this.state.subActive === "Uploads" && (
              <>
                {true ? (
                  <NewTabbedTable
                    tabs={this.tabsUploads}
                    headings={this.headingsUploads}
                    data={this.state.data}
                    actions={this.actions}
                    hideHeader={true}
                  />
                ) : (
                  // this.state.data.Active.map((e, i) => {
                  //   if (e.notes !== null) {
                  //     return (
                  //       <div
                  //         style={{ paddingLeft: "5%", marginBottom: "10px" }}
                  //         key={i}
                  //       >
                  //         <span style={{ fontWeight: "550" }}>
                  //           {e.came_from}
                  //         </span>
                  //         <TextareaAutosize
                  //           name="notes"
                  //           id="notes"
                  //           type="text"
                  //           disabled={true}
                  //           value={e.notes}
                  //           style={{
                  //             width: "95%",
                  //             border: "1px solid lightgrey",
                  //             padding: "8px 12px",
                  //             borderRadius: "10px",
                  //             backgroundColor: "#fff",
                  //             resize: "none"
                  //           }}
                  //           columns="12"
                  //         />
                  //       </div>
                  //     );
                  //   }
                  // })
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <span>No Files Uploaded.</span>
                  </div>
                )}
              </>
            )} */}

            {this.state.subActive === "Info" &&
              this.state.data.Active[0].added_contact &&
              this.state.canEdit && (
                <div className="user-save-row">
                  <button
                    className="user-save-btn"
                    onClick={_ => this.updateAddedContactData()}
                  >
                    Save
                  </button>
                </div>
              )}
          </div>
          {/* <div className="col-md-6">
            <div className="forbtnwrap justify-end">
              <div className="forbtnapp" style={{ paddingBottom: "30px" }}>
                {this.state.subActive === "Uploads" ? (
                  <button
                    className="newapplications"
                    id="add-file-upload-button"
                    style={{
                      cursor: "pointer",
                      width: "140px"
                    }}
                    onClick={_ => {
                      this.setState({
                        openUploadFileModal: true,
                        fileSubmitted: false
                      });
                    }}
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Upload Files
                  </button>
                ) : null}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Profile);
