import React from "reactn";
import Tables from "../../../components/table";
import { NewTabbedTable } from "../../../components/UI";
import axios from "axios";
import {
  differenceInCalendarMonths,
  startOfMonth,
  addMonths,
  format,
  getMonth,
  getYear
} from "date-fns";
import "./expenseTracker.css";
import { toast } from "react-toastify";
import {
  MiniPlus
  // background1
} from "../../../assets";
import CreateOrEditExpenseModal from "../../../components/modals/CreateOrEditExpenseModal";
import DeleteExpenseConfirmationModal from "../../../components/modals/DeleteExpenseConfirmationModal";
import { Delete, LensTwoTone, Create as Edit } from "@material-ui/icons";
import ReactTooltip from "react-tooltip";
import "../../../assets/css/componentSpecificCss/expenseTracker.css";

class ExpenseTracker extends React.Component {
  constructor() {
    super();
    this.state = {
      tabs: [], //Mock Data: "DAILY PROGRAM"
      headings: {
        // Mock Data
        // "DAILY PROGRAM": [
        //   { id: "Vendor", label: "Vendor" },
        //   { id: "Description", label: "Description" },
        //   { id: "Amount", label: "Amount" },
        //   { id: "Date", label: "Date" },
        // ]
      },
      data: {},
      filteredData: {},
      selectedCategory: 0,
      months: [],
      selectedMonth: 0,
      selectedYear: 0,
      selectedDate: "All",
      expensesOverview: {
        totalMonthly: 0,
        yearlySpending: 0,
        averageMonthly: 0,
        ytdTotal: 0
      },
      addExpenseModalShown: false,
      // expense: { ...expenseModel }
      groupMappings: [],
      permissionToView: true,
      showEditModal: false,
      expenseToEdit: {},
      openDeleteExpenseConfirmationModal: false
    };

    this.updateDate = this.updateDate.bind(this);
    this.filterData = this.filterData.bind(this);
    this.addExpense = this.addExpense.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.fetchInitialData = this.fetchInitialData.bind(this);
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Expense Tracker", to: "/expense" }
      ]
    });
  }

  headings = () => {
    return [
      {
        id: "vendor",
        label: "Vendor",
        customCell: this.vendorCell,
        customStyle: { width: 50 }
      },
      {
        id: "description",
        label: "Description",
        customCell: this.descriptionCell,
        customStyle: { width: 200 }
      },
      {
        id: "user_name",
        label: "User Name",
        customCell: this.userNameCell,
        customStyle: { width: 50 }
      },
      {
        id: "sub_total",
        label: "Sub Total",
        customCell: this.subtotalCell,
        customStyle: { width: 50 }
      },
      {
        id: "tax_amount",
        label: "Tax Amount",
        customCell: this.taxamountCell,
        customStyle: { width: 50 }
      },
      {
        id: "amount",
        label: "Amount",
        customCell: this.amountCell,
        customStyle: { width: 50 }
      },
      {
        id: "date",
        label: "Date",
        customCell: this.dateCell,
        customStyle: { width: 50 }
      },
      {
        id: "actionf",
        label: "",
        customCell: (r, i) => {
          return (
            <td
              key={`${i}-btn`}
              style={{ whiteSpace: "nowrap", textAlign: "center" }}
            >
              <button
                className="view-btn"
                onClick={_ => {
                  r.uploaded_expense &&
                    window.open(
                      `${
                        process.env.REACT_APP_DS_FILES_S3 +
                        "/" +
                        r.uploaded_expense
                      }`
                    );
                }}
              >
                Attachment
              </button>

              {!this.canSubUserEdit() && (
                <>
                  <Edit
                    style={{
                      color: "lightgrey",
                      cursor: "pointer",
                      marginRight: "10px"
                    }}
                    onClick={() => {
                      this.setState({
                        showEditModal: true,
                        expenseToEdit: r
                      });
                    }}
                    data-for="edit"
                    data-tip
                  />
                  <ReactTooltip
                    id="edit"
                    place="top"
                    effect="solid"
                    type="info"
                  >
                    Edit expense
                  </ReactTooltip>
                </>
              )}

              {!this.canSubUserEdit() && (
                <>
                  <Delete
                    data-for="delete"
                    data-tip
                    style={{ color: "lightgrey", cursor: "pointer" }}
                    onClick={() => {
                      this.setState({
                        openDeleteExpenseConfirmationModal: true,
                        expenseToEdit: r
                      });
                    }}
                  />
                  <ReactTooltip
                    id="delete"
                    place="top"
                    effect="solid"
                    type="info"
                  >
                    Remove expense
                  </ReactTooltip>
                </>
              )}
            </td>
          );
        },
        customStyle: { width: 90 }
      }
    ];
  };

  /**
   * This function handles the edit button functionality of a sub user
   *  returns boolean
   */
  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return this.global.dsUser.accesspoints.Financials["Expense Tracker"].edit
        ? false
        : true;
    }
  };

  getCategories() {
    return this.state.groupMappings[this.state.selectedCategory] || [];
  }

  componentDidMount() {
    this.fetchInitialData();
  }

  async fetchInitialData() {
    const ep1 = `${process.env.REACT_APP_API}/partner/expense`;
    try {
      this.setGlobal({ loading: true });

      let months = [];
      const startMonth = startOfMonth(
        new Date(new Date().setFullYear(new Date().getFullYear() - 1))
      );

      for (let i = 0; i <= 12; i++) {
        months.push(format(addMonths(startMonth, i), "LLL yyyy"));
      }

      /**
       * LATER: const res = await axios.get(`${ep}/${months[0].split(" ").join("-")}`);
       */
      const res = await axios.get(ep1);
      console.log("res.data", res.data);
      if (res.data && !res.data.success) {
        this.setState({ permissionToView: false });
      } else {
        const groupCatMap = res.data.data.groups
          .map((g, i) => {
            return res.data.data.categories.filter(
              (c, j) => c.expenseGroup === g.id
            );
          })
          .reverse();
        const tabs = res.data.data.groups
          .map(g => g.name.toUpperCase())
          .reverse();
        const headings = {};
        const data = {};
        for (let h of res.data.data.groups.reverse()) {
          headings[h.name.toUpperCase()] = [...this.headings()];
          data[h.name.toUpperCase()] = res.data.data.expenses;
        }

        res.data.data.expenses.forEach(e => {
          //"grand_total" and "sub_total" are stored in 2 decimal place numeric in db,
          //and returned as strings from db, and then returned by the API call as a string.
          e.grand_total = parseFloat(e.grand_total || "0");
          e.sub_total = parseFloat(e.sub_total || "0");
          if (isNaN(e.grand_total) || isNaN(e.sub_total)) {
            console.error(
              `Invalid "grand_total" or "sub_total" in expense id=${e.id}`
            );
          }
        });
        console.log("DATA", data);
        this.setState({
          tabs,
          headings,
          data,
          months: months.reverse(),
          categories: res.data.data.categories,
          subcategories: res.data.data.subcategories,
          groups: res.data.data.groups,
          groupMappings: groupCatMap,
          selectedDate: "All",
          expensesOverview: {
            ...this.state.expensesOverview,
            ...res.data.data.expensesOverview
          }
        });
        this.filterData(true);
      }
      this.setGlobal({ loading: false, lastAPICall: res });

      const ep2 = `${process.env.REACT_APP_API}/partner/tax_classification`;
      this.setGlobal({ loading: true });
      const results = await axios.get(ep2);
      if (!results.data.success) {
        this.setState({ permissionToView: false });
      } else {
        this.setState({
          taxClassification: results.data.data.taxClassification
        });
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (err) {
      console.error(err);
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  filterData(allSelected, month = null, year = null) {
    if (allSelected) {
      this.setState({
        filteredData: this.state.data,
        expensesOverview: {
          ...this.state.expensesOverview,
          totalMonthly: 0
        }
      });
    } else {
      let newData = {};
      let monthlyTotal = 0;
      let curDate = new Date(
        year ? year : this.state.selectedYear,
        month ? month : this.state.selectedMonth
      );
      for (let k of Object.keys(this.state.data)) {
        newData[k] = this.state.data[k].filter(ex => {
          const expenseDate = new Date(ex.expense_date);
          const expenseFullDate = new Date(
            expenseDate.getFullYear(),
            expenseDate.getMonth() + 1,
            28
          );
          if (
            expenseFullDate.getMonth() + 1 === curDate.getMonth() + 1 &&
            expenseFullDate.getFullYear() === curDate.getFullYear()
          ) {
            monthlyTotal += parseFloat(ex.grand_total);
            return true;
          }
          return false;
        });
      }
      console.log("newData['EXPENSES']: ", newData["EXPENSES"]);
      this.setState({
        filteredData: newData,
        expensesOverview: {
          ...this.state.expensesOverview,
          totalMonthly: monthlyTotal
        }
      });
    }
  }

  vendorCell = (r, i) => {
    return <td key={`${i}-vendor`}>{r.vendor}</td>;
  };

  descriptionCell = (r, i) => {
    return <td key={`${i}-desc`}>{r.description}</td>;
  };

  userNameCell = (r, i) => {
    return <td key={`${i}-user_name`}>{r.user_name}</td>;
  };

  subtotalCell = (r, i) => {
    return (
      <td key={`${i}-sub_total`}>
        $
        {parseFloat(r.sub_total).toLocaleString("en-CA", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </td>
    );
  };

  taxamountCell = (r, i) => {
    let total = 0;
    for (let i = 0; i < r.tax_amount.length; i++) {
      if (parseFloat(JSON.parse(r.tax_amount[i]).tax_amount)) {
        total += parseFloat(JSON.parse(r.tax_amount[i]).tax_amount);
      }
    }
    return (
      <td key={`${i}-tax_amount`}>
        {total === 0
          ? "-"
          : `$${total.toLocaleString("en-CA", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`}
      </td>
    );
  };

  amountCell = (r, i) => {
    return (
      <td key={`${i}-amount`}>
        $
        {parseFloat(r.grand_total).toLocaleString("en-CA", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </td>
    );
  };

  dateCell = (r, i) => {
    return (
      <td key={`${i}-date`}>
        {format(new Date(r.expense_date + "T00:00"), "yyyy-MM-dd")}
      </td>
    );
  };

  async updateDate(e) {
    if (e.target.value !== "All") {
      const date = format(new Date(e.target.value), "M yyyy");
      const month = date.substring(0, date.indexOf(" "));
      const year = date.substring(date.indexOf(" ") + 1);
      this.setState(
        {
          selectedMonth: month,
          selectedYear: year,
          selectedDate: e.target.value
        },
        () => {
          this.filterData(
            false,
            this.state.selectedMonth,
            this.state.selectedYear
          );
        }
      );
    } else {
      this.setState({ selectedDate: "All" });
      this.filterData(true);
    }
  }

  async addExpense(expense) {
    const ep = `${process.env.REACT_APP_API}/edu/expense`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.post(ep, {
        ...expense.expense,
        amount: parseFloat(expense.expense.amount)
      });
      this.setGlobal({ loading: false, lastAPICall: res });
      if (res.data.success) {
        toast("Added new expense.");
        const heading = this.state.tabs[expense.category.expenseGroup - 1];
        const newExp = {
          ...res.data.data.expense,
          categoryName: expense.category.name,
          expenseGroup: expense.category.expenseGroup
        };
        const data = {
          data: {
            ...this.state.data,
            [heading.toUpperCase()]: [
              newExp,
              ...this.state.data[heading.toUpperCase()]
            ]
          },
          expensesOverview: {
            ...this.state.expensesOverview,
            ...res.data.data.expensesOverview
          }
        };
        this.setState(data);
      }

      this.filterData();
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    if (!this.state.permissionToView) {
      return (
        <div className="container-fluid">
          <div className="cont">
            <div className="row">
              <h1 data-testid="heading-no-permission">Expense Tracker</h1>
            </div>
            <div className="row">
              <p>
                You do not have permission to view this page. Contact admin.{" "}
              </p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="admin">
        {this.state.showEditModal && (
          <CreateOrEditExpenseModal
            expense={this.state.expenseToEdit}
            onClose={_ => this.setState({ showEditModal: false })}
            setGlobal={this.setGlobal}
            global={this.global}
            onSuccess={(data, category, subCategory) => {
              this.fetchInitialData();
            }}
            taxClassification={this.state.taxClassification}
            category={this.state.categories}
            subCategory={this.state.subcategories}
            isCreate={false}
          />
        )}
        <div className="container-fluid">
          {this.state.addExpenseModalShown && (
            <CreateOrEditExpenseModal
              onClose={_ => this.setState({ addExpenseModalShown: false })}
              setGlobal={this.setGlobal}
              global={this.global}
              onSuccess={(data, category, subCategory) => {
                console.log("On Success: ", data, category, subCategory);
                this.fetchInitialData();
              }}
              taxClassification={this.state.taxClassification}
              category={this.state.categories}
              subCategory={this.state.subcategories}
              isCreate={true}
            />
          )}
          {this.state.openDeleteExpenseConfirmationModal && (
            <DeleteExpenseConfirmationModal
              onClose={() =>
                this.setState({ openDeleteExpenseConfirmationModal: false })
              }
              onDelete={async () => {
                const ep = `${process.env.REACT_APP_API}/partner/expense/${this.state.expenseToEdit.id}`;
                const result = await axios.delete(ep);
                if (result.data.success) {
                  this.fetchInitialData();
                }
              }}
            />
          )}
          <div className="cont">
            <div className="row">
              <div className="col-lg-7 col-sm-12">
                <h1 data-testid="heading-permission">Expense Tracker</h1>
              </div>
              <div
                className="col-lg-5 col-sm-12 d-flex"
                style={{
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%"
                }}
              >
                <div>
                  Expenses for Month:
                  <select
                    value={this.state.selectedDate}
                    onChange={this.updateDate}
                    className="edu__expense_tracker_select ml-2"
                  >
                    {this.state.months.map((date, i) => (
                      <option key={i} value={date}>
                        {date}
                      </option>
                    ))}
                    <option value={"All"}>All</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="row">
              <div className="col-lg-3">
                <Tables.Educators.ExpenseOverviewTable
                  expenses={this.state.expensesOverview}
                />
              </div>
              <div className="col-lg-9">
                <div
                  className="forbtnwrap"
                  style={{
                    height: "0%",
                    display: "flex",
                    justifyContent: "flex-end"
                  }}
                >
                  <div
                    className="forbtnapp expense-btn"
                    style={{ marginLeft: "20px" }}
                  >
                    <button
                      className="newapplications"
                      disabled={this.canSubUserEdit()}
                      style={{
                        cursor: this.canSubUserEdit()
                          ? "not-allowed"
                          : "pointer"
                      }}
                      onClick={_ =>
                        this.setState({ addExpenseModalShown: true })
                      }
                    >
                      <img
                        src={MiniPlus}
                        alt=""
                        style={{ alignSelf: "center" }}
                      />
                      Create Expense
                    </button>
                  </div>
                </div>
                <div className="expense-table-container">
                  <NewTabbedTable
                    tabs={this.state.tabs}
                    headings={this.state.headings}
                    data={this.state.filteredData}
                    // onTabChange={i =>
                    //   this.setState({ selectedCategory: parseInt(i) })
                    // }
                  />
                  {/* <AddExpenseForm
                      categories={this.getCategories()}
                      addExpense={this.addExpense}
                    /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExpenseTracker;
