import React from "reactn";
import MyCredentialsReferenceForm from "./forms/myCredentialsReference";
import MyCredentialsDocumentationForm from "./forms/myCredentialsDocumentation";
import MyCredentialsDocumentationStatusForm from "./forms/myCredentialsDocumentationStatus";

class MyCredentialsPage extends React.Component {
  handChange = e => {
    const vals = this.global.myApplicationReferences.map(c =>
      c.id === e.id ? e : c
    );
    this.setGlobal({ myApplicationReferences: vals });
  };

  handRemove = e => {
    this.dispatch.removeApplicationReference(e);
  };

  componentDidUpdate() {
    if (this.global.myApplicationReferences.length === 0)
      this.dispatch.addApplicationReference();
  }

  render() {
    return (
      <div className="cont boxes-row mt-50px">
        {this.global.dsUser.accountType !== "Admin" ? null : (
          <MyCredentialsDocumentationStatusForm />
        )}
        <MyCredentialsDocumentationForm />
        {this.global.myApplicationReferences.map((reg, i) => (
          <MyCredentialsReferenceForm
            key={i}
            vals={reg}
            itemIndex={i}
            onChange={this.handChange}
            onRemove={this.handRemove}
          />
        ))}
        <button
          className="addchild"
          onClick={() =>
            !this.global.profile.applicationSubmitted ||
            this.global.dsUser.accountType === "Admin"
              ? this.dispatch.addApplicationReference()
              : null
          }
        >
          <h3>Add Reference</h3>
        </button>
      </div>
    );
  }
}

export default MyCredentialsPage;
