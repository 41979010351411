import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { Button } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import BaseModal from "./baseModal/BaseModal";
import {
  CONTENT_BLOCK,
  BUTTON,
  TEXT_AREA,
  IMAGE_CONTENT,
  DROPDOWN,
  STRING_INPUT
} from "./baseModal/FieldTypes";
import {
  BLUE_BUTTON,
  GREEN_BUTTON,
  RED_BUTTON,
  WHITE,
  YELLOW_BUTTON
} from "./baseModal/colours";

class RemoveFileConfirmationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isRemoved: [] };
  }

  getBaseModalFields() {
    const baseModalFieldsArr = [];

    const confirmationPrompt = {
      type: CONTENT_BLOCK,
      data: {
        name: "Are you sure you would like to remove this file? This action cannot be undone."
      }
    };

    baseModalFieldsArr.push(confirmationPrompt);
    return baseModalFieldsArr;
  }

  getBaseModalButtons() {
    const confirmButton = {
      name: "Confirm",
      buttonColour: RED_BUTTON,
      textColour: WHITE,
      handleClick: async () => {
        await this.removeFile();
        await this.props.onSubmit();
        toast.success("File successfully removed!");
        this.props.onClose();
      }
    };

    return [confirmButton];
  }

  getBaseModalProps() {
    const baseModalProps = {
      title: "Confirmation",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: () => this.props.onClose()
    };

    return baseModalProps;
  }

  async removeFile() {
    console.log("FILE REMOVED", this.props.selectedFile);
    const files = this.props.selectedFile.file_uploads;

    files.isHidden = true;
    this.props.selectedFile.file_uploads.isHidden = true;
    console.log("FILES,", files);
    this.setState({
      isRemoved: [...this.state.isRemoved, files]
    });

    const dataSubmission = {
      file_uploads: { ["file_1"]: files },
      fileUploadId: this.props.selectedFile.id,
      partnerId: this.props.selectedFile.partnerId,
      dsUserId: this.props.selectedFile.dsUserId
    };
    const ep = `${process.env.REACT_APP_API}/partners/file_uploads/edit/remove_file`;
    const removeFile = await axios.put(ep, dataSubmission);
  }

  render() {
    console.log("RENDER", this.state, this.props);
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default RemoveFileConfirmationModal;
