import React from "reactn";
import { NewDraggableTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import AddTaskModal from "../../components/modals/AddTaskModal";
import EditTaskModal from "../../components/modals/EditTaskModal";
import AddReminderModal from "../../components/modals/AddReminderModal";
import EditReminderModal from "../../components/modals/EditReminderModal";
import TaskReminderNotesModal from "../../components/modals/TaskReminderNotesModal";
import ConfirmArchiveModal from "../../components/modals/ConfirmArchiveModal";
import ReactTooltip from "react-tooltip";

import { Delete, Create as Edit } from "@material-ui/icons";

class TaskReminders extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: { Tasks: [], Reminders: [] },
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      color: {
        0: "linear-gradient(to right, rgba(126,160,255,1) 0%, rgba(145,90,255,1) 100%)",
        1: `linear-gradient(to right, rgba(245,198,140,1) 0%, rgba(253,132,192,1) 100%)`,
        2: "linear-gradient(to right, rgba(172,147,255,1) 0%, rgba(152,223,232,1) 100%)",
        3: "linear-gradient(to right, rgba(44,214,214,1) 0%, rgba(174,232,94,1) 100%)",
        4: "linear-gradient(to right, rgba(255,212,70,1) 0%, rgba(252,140,126,1) 100%)"
      },
      openNotesModal: false,
      selectedItem: null,
      type: null,
      editTaskModal: false,
      editReminderModal: false,
      confirmArchiveTaskModal: false,
      confirmArchiveReminderModal: false
    };

    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Tasks & Reminders", to: "/tasks" }
      ]
    });
    this.tabs = ["Tasks", "Reminders"];

    this.actions = [{ name: "Notes", action: null }];
  }

  headings = () => {
    return {
      Tasks: [
        {
          id: "description",
          label: "Description",
          customCell: (r, i, setRef) => (
            <td ref={setRef} style={{ width: "356.8px" }}>
              {r.description}
            </td>
          )
        },
        {
          id: "assigned",
          label: "Assigned",
          customCell: (r, i, setRef) => (
            <td ref={setRef} style={{ width: "174.4px" }}>
              {r.username
                ? r.username
                : this.global.dsUser.sub_user
                ? this.global.dsUser.avatar_name
                : "Myself"}
            </td>
          )
        },
        {
          id: "due",
          label: "Due / Priority",

          customCell: (r, i, setRef) => (
            <td ref={setRef} style={{ width: "380px" }}>
              {r.due ? format(new Date(r.due), "LLLL dd, yyyy") : "N/A"} /{" "}
              {r.priority == "1"
                ? "Low"
                : r.priority == "2"
                ? "Medium"
                : "High"}
            </td>
          )
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i, setRef) => {
            return (
              <td
                key={`${i}-btn`}
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "right",
                  width: "364.8px"
                }}
                ref={setRef}
              >
                <button
                  style={{ marginRight: "30px" }}
                  onClick={_ =>
                    this.setState({
                      openNotesModal: true,
                      selectedItem: r,
                      type: "Task"
                    })
                  }
                >
                  Notes
                </button>
                {this.canSubUserEdit() ? (
                  ""
                ) : (
                  <>
                    <Edit
                      onClick={() => {
                        this.setState({
                          editTaskModal: true,
                          selectedItem: r
                        });
                      }}
                      style={{ color: "lightgrey", cursor: "pointer" }}
                      data-for="edit"
                      data-tip
                    />
                    <ReactTooltip
                      id="edit"
                      place="top"
                      effect="solid"
                      type="info"
                    >
                      Edit Task
                    </ReactTooltip>
                    <Delete
                      // onClick={_ => this.archiveTasks(r)}
                      onClick={() => {
                        this.setState({
                          confirmArchiveTaskModal: true,
                          selectedItem: r
                        });
                      }}
                      style={{ color: "lightgrey", cursor: "pointer" }}
                    />
                  </>
                )}
              </td>
            );
          }
        }
      ],
      Reminders: [
        {
          id: "description",
          label: "Reminder",
          customCell: (r, i, setRef) => (
            <td ref={setRef} style={{ width: "356px" }}>
              {r.description}
            </td>
          )
        },
        {
          id: "date",
          label: "Date",
          customCell: (r, i, setRef) => (
            <td ref={setRef} style={{ width: "232.8px" }}>
              {r.date ? format(new Date(r.date), "LLLL dd, yyyy") : "--"}
            </td>
          )
        },
        {
          id: "color",
          label: "Color",
          customCell: (r, i, setRef) => (
            <td ref={setRef} style={{ width: "263.2px" }}>
              <div
                style={{
                  width: "80px",
                  height: "30px",
                  marginLeft: "10px",
                  cursor: "pointer",
                  background: this.state.color[r.color],
                  borderRadius: "10px",
                  display: "inline-block"
                }}
              ></div>
            </td>
          )
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i, setRef) => {
            return (
              <td
                ref={setRef}
                key={`${i}-btn`}
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "right",
                  width: "364px"
                }}
              >
                <button
                  style={{ marginRight: "30px" }}
                  onClick={_ =>
                    this.setState({
                      openNotesModal: true,
                      selectedItem: r,
                      type: "Reminder"
                    })
                  }
                >
                  Notes
                </button>
                {this.canSubUserEdit() ? (
                  ""
                ) : (
                  <>
                    <Edit
                      onClick={() => {
                        this.setState({
                          editReminderModal: true,
                          selectedItem: r
                        });
                      }}
                      style={{ color: "lightgrey", cursor: "pointer" }}
                      data-for="editReminder"
                      data-tip
                    />
                    <ReactTooltip
                      id="editReminder"
                      place="top"
                      effect="solid"
                      type="info"
                    >
                      Edit Reminder
                    </ReactTooltip>
                    <Delete
                      // onClick={_ => this.archiveReminders(r)}
                      onClick={() => {
                        this.setState({
                          confirmArchiveReminderModal: true,
                          selectedItem: r
                        });
                      }}
                      style={{ color: "lightgrey", cursor: "pointer" }}
                    />
                  </>
                )}
              </td>
            );
          }
        }
      ]
    };
  };

  archiveReminders = async item => {
    const ep = `${process.env.REACT_APP_API}/partners/reminders/edit`;
    const results = await axios.put(ep, {
      id: item.id,
      isArchived: true,
      archivedDate: new Date()
    });
    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  archiveTasks = async item => {
    const ep = `${process.env.REACT_APP_API}/partners/tasks/edit`;
    const results = await axios.put(ep, {
      id: item.id,
      isArchived: true,
      archivedDate: new Date()
    });
    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  async componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/reminders`;
    const results = await axios.get(ep);
    const ep2 = `${process.env.REACT_APP_API}/partners/tasks`;
    const tasks = await axios.get(ep2);

    if (results.data.success) {
      //this.setState({ data: results.data.data.filter(e => !e.accepted) });
      this.setState({
        data: {
          Reminders: results.data.data.filter(e => !e.isArchived),
          Tasks: tasks.data.data.filter(e => !e.isArchived),
          selectedItem: null
        }
      });
    }
    this.setGlobal({ loading: false });
  };

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
    if (results.data.success) {
      toast.success(`Partner Accepted`);
      this.componentDidMount();
    } else {
      toast.error(`Error, this person already signed-up!`);
    }
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  onDragEndHandler = async (destination, source, draggableId, useTab) => {
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const itemList = this.state.data[this.tabs[useTab]];
    const newList = [...itemList];
    const draggedItem = newList[source.index];
    newList.splice(source.index, 1);
    newList.splice(destination.index, 0, draggedItem);

    this.setState({
      data: { ...this.state.data, [this.tabs[useTab]]: newList }
    });

    if (this.tabs[useTab] === "Tasks") {
      const updateOrderEp = `${process.env.REACT_APP_API}/partners/tasks/updateOrder`;
      const updatedTask = await axios.put(updateOrderEp, {
        originalOrder: source.index,
        newOrder: destination.index,
        taskId: draggableId
      });
    } else {
      const updateOrderEp = `${process.env.REACT_APP_API}/partners/reminders/updateOrder`;
      const updatedReminder = await axios.put(updateOrderEp, {
        originalOrder: source.index,
        newOrder: destination.index,
        taskId: draggableId
      });
    }

    const getRemindersEp = `${process.env.REACT_APP_API}/partners/reminders`;
    const results = await axios.get(getRemindersEp);
    const getTasksEp = `${process.env.REACT_APP_API}/partners/tasks`;
    const tasks = await axios.get(getTasksEp);
    if (results.data.success) {
      //this.setState({ data: results.data.data.filter(e => !e.accepted) });
      this.setState({
        data: {
          Reminders: results.data.data.filter(e => !e.isArchived),
          Tasks: tasks.data.data.filter(e => !e.isArchived)
        }
      });
    }
  };

  /**
   * This function handles the edit button functionality of a sub user
   * returns a boolean value
   */
  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return this.global.dsUser.accesspoints.Upcoming["Tasks & Reminders"].edit
        ? false
        : true;
    }
  };

  render() {
    return (
      <div className="admin">
        {this.state.taskModal && (
          <AddTaskModal
            onClose={_ => {
              this.setState({ taskModal: false });
            }}
            tasks={
              r => this.fetchInitialData()
              // this.setState({
              //   data: {
              //     ...this.state.data,
              //     Tasks: [...this.state.data["Tasks"], { ...r }]
              //   }
              // })
            }
          />
        )}
        {this.state.editTaskModal && (
          <EditTaskModal
            onClose={_ => {
              this.setState({ editTaskModal: false });
            }}
            tasks={r => this.fetchInitialData()}
            item={this.state.selectedItem}
            notAllowed={
              this.state.selectedItem.createdBy !==
                this.global.dsUser.user_id &&
              this.state.selectedItem.createdBy !== null
            }
          />
        )}
        {this.state.reminderModal && (
          <AddReminderModal
            reminder={r =>
              this.setState({
                data: {
                  ...this.state.data,
                  Reminders: [...this.state.data["Reminders"], { ...r }]
                }
              })
            }
            onClose={_ => {
              this.setState({ reminderModal: false });
              this.fetchInitialData();
            }}
          />
        )}
        {this.state.editReminderModal && (
          <EditReminderModal
            reminder={r => this.fetchInitialData()}
            onClose={_ => {
              this.setState({ editReminderModal: false });
            }}
            item={this.state.selectedItem}
            notAllowed={
              this.state.selectedItem.createdBy !==
                this.global.dsUser.user_id &&
              this.state.selectedItem.createdBy !== null
            }
          />
        )}
        {this.state.openNotesModal && (
          <TaskReminderNotesModal
            onClose={_ => {
              this.setState({ openNotesModal: false });
              this.fetchInitialData();
            }}
            type={this.state.type}
            item={this.state.selectedItem}
          />
        )}
        {this.state.confirmArchiveTaskModal && (
          <ConfirmArchiveModal
            onClose={_ => {
              this.setState({ confirmArchiveTaskModal: false });
            }}
            onSubmit={_ => {
              this.archiveTasks(this.state.selectedItem);
            }}
            toArchive={true}
            modalBody={
              this.state.selectedItem.createdBy !==
                this.global.dsUser.user_id &&
              this.state.selectedItem.createdBy !== null
                ? `Sorry, you may not delete this task.`
                : ""
            }
            notAllowed={
              this.state.selectedItem.createdBy !==
                this.global.dsUser.user_id &&
              this.state.selectedItem.createdBy !== null
            }
          />
        )}
        {this.state.confirmArchiveReminderModal && (
          <ConfirmArchiveModal
            onClose={_ => {
              this.setState({ confirmArchiveReminderModal: false });
            }}
            onSubmit={_ => {
              this.archiveReminders(this.state.selectedItem);
            }}
            toArchive={true}
            modalBody={
              this.state.selectedItem.createdBy !==
                this.global.dsUser.user_id &&
              this.state.selectedItem.createdBy !== null
                ? `Sorry, you may not delete this reminder.`
                : ""
            }
            notAllowed={
              this.state.selectedItem.createdBy !==
                this.global.dsUser.user_id &&
              this.state.selectedItem.createdBy !== null
            }
          />
        )}

        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>Tasks & Reminders</h1>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp">
                  <button
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => this.setState({ taskModal: true })}
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Tasks
                  </button>
                </div>

                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  <button
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => this.setState({ reminderModal: true })}
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Reminders
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="tablecont">
              <div className="table-responsive">
                <NewDraggableTabbedTable
                  tabs={this.tabs}
                  headings={this.headings()}
                  data={this.state.data}
                  actions={this.actions}
                  onDragEndHandler={this.onDragEndHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TaskReminders;
