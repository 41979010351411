import React from "reactn";
import { formTypes, BoxForm } from "../../../../components/UI/form";

class ProgramAmendments extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      timeRanges: formTypes.SelectOptions.timeRanges
    };
    this.handleChange = this.handleChange.bind(this);
    this.dispatchNewHoliday = this.dispatchNewHoliday.bind(this);
    this.customForm = this.customForm.bind(this);
  }

  handleChange(e, v, sch) {
    const c = { ...sch, [e]: v };
    const n = this.global.myProgramHolidays.personalHolidays.map(s =>
      sch.id !== s.id ? s : c
    );
    this.setGlobal({
      myProgramHolidays: {
        ...this.global.myProgramHolidays,
        personalHolidays: n
      }
    });
  }

  dispatchNewHoliday(e) {
    e.preventDefault();
    this.dispatch.addProgramHoliday();
  }

  customForm() {
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-5">Upcoming Holidays</div>
            <div className="col-7 d-flex">
              <formTypes.Select
                name="date"
                options={[{ label: "No Holiday Selected", value: "" }]}
                placeholder="Choose a Holiday!"
                isDisabled={true}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.global.dsUser.accountType === "Admin" && (
          <BoxForm
            handleChange={this.handleChange}
            name={"PROGRAM AMENDMENTS"}
            customForm={this.customForm()}
          />
        )}
      </>
    );
  }
}

export default ProgramAmendments;
