import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";
import Geosuggest from "react-geosuggest";

class MyAddressForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setAddress = this.setAddress.bind(this);
    this.customForm = this.customForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._geoSuggest = null;
    this._geoSuggestPrev = null;
  }
  handleChange = (e, v) => {
    if (
      !this.global.profile.applicationSubmitted ||
      this.global.dsUser.accountType === "Admin"
    ) {
      this.setGlobal({
        myApplicationProfileAddress: {
          ...this.global.myApplicationProfileAddress,
          [e]: v
        }
      });
    }
  };

  setAddress = e => {
    if (!this.global.profile.applicationSubmitted) {
      if (e && e.gmaps) {
        const addLine1 = e.gmaps.name;
        let city, pc, country, province;
        try {
          city = e.gmaps.address_components.find(
            c => c.types.includes("locality") && c.types.includes("political")
          ).long_name;
        } catch (ex) {}
        try {
          pc = e.gmaps.address_components.find(c =>
            c.types.includes("postal_code")
          ).long_name;
        } catch (ex) {}
        try {
          country = e.gmaps.address_components.find(
            c => c.types.includes("country") && c.types.includes("political")
          ).long_name;
        } catch (ex) {}
        try {
          province = e.gmaps.address_components.find(
            c =>
              c.types.includes("administrative_area_level_1") &&
              c.types.includes("political")
          ).long_name;
        } catch (ex) {}
        this.setGlobal({
          myApplicationProfileAddress: {
            ...this.global.myApplicationProfileAddress,
            addressLineOne: addLine1 ? addLine1 : "",
            city: city ? city : "",
            postalCode: pc ? pc : "",
            country: country ? country : "",
            province: province ? province : ""
          }
        });
        this._geoSuggest.update(addLine1);
      }
    }
  };

  customForm = () => {
    const vals = this.global.myApplicationProfileAddress;
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-5">
              <h3>
                Address Line One<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              {/* <input
              name="addressLineOne"
              value={vals.addressLineOne}
              onChange={e => this.handleChange(e.target.name, e.target.value)}
            /> */}
              <div
                className={`w-100 ${
                  !vals.addressLineOne || !vals.addressLineOne.length
                    ? "formbody-invalid"
                    : ""
                }`}
              >
                <Geosuggest
                  onSuggestSelect={this.setAddress}
                  initialValue={vals.addressLineOne}
                  placeholder="Search for Address"
                  ref={el => (this._geoSuggest = el)}
                  location={
                    new window.google.maps.LatLng(43.6499722, -79.3997312)
                  }
                  radius={30}
                  disabled={
                    this.global.profile.applicationSubmitted &&
                    this.global.dsUser.accountType !== "Admin"
                  }
                  // location={new google.maps.LatLng(53.558572, 9.9278215)}
                  // radius="20"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>Address Line Two</h3>
            </div>
            <div className="col-7 d-flex">
              <input
                name="addressLineTwo"
                value={vals.addressLineTwo}
                placeholder="Unit #, Buzzer #"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                City<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`w-100 ${
                  !vals.city || !vals.city.length ? "formbody-invalid" : ""
                }`}
              >
                <input
                  name="city"
                  value={vals.city}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                Province<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`w-100 ${
                  !vals.province || !vals.province.length
                    ? "formbody-invalid"
                    : ""
                }`}
              >
                <input
                  name="province"
                  value={vals.province}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                Country<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`w-100 ${
                  !vals.country || !vals.country.length
                    ? "formbody-invalid"
                    : ""
                }`}
              >
                <input
                  name="country"
                  value={vals.country}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                Postal Code<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`w-100 ${
                  !vals.postalCode || !vals.postalCode.length
                    ? "formbody-invalid"
                    : ""
                }`}
              >
                <input
                  name="postalCode"
                  value={vals.postalCode}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                Years at Address<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`w-100 ${
                  !vals.numYearsAt ? "formbody-invalid" : ""
                }`}
              >
                <input
                  name="numYearsAt"
                  value={vals.numYearsAt}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  type="number"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3>
                Previous Address
                <br />
                <sub>(If Current is Less Than 5)</sub>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <Geosuggest
                onSuggestSelect={e => {
                  this.handleChange(
                    "previousAddress",
                    e.gmaps.formatted_address
                  );
                }}
                initialValue={vals.previousAddress}
                placeholder="Search for Address"
                ref={el => (this._geoSuggestPrev = el)}
                location={
                  new window.google.maps.LatLng(43.6499722, -79.3997312)
                }
                radius={30}
                disabled={
                  this.global.profile.applicationSubmitted &&
                  this.global.dsUser.accountType !== "Admin"
                }
                // location={new google.maps.LatLng(53.558572, 9.9278215)}
                // radius="20"
              />
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"ADDRESS"}
        customForm={this.customForm()}
      />
    );
  }
}

export default MyAddressForm;
