import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus, MemberShip1, MemberShip2 } from "../../assets";
import NotesModal from "../../components/modals/NotesModal";
import axios from "axios";
import { toast } from "react-toastify";
import "../../assets/css/componentSpecificCss/membershipManageSingle.css";
import ApplicationViewModal from "../../components/modals/ApplicationViewModal";
import CancelProgramModal from "../../components/modals/CancelProgramModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import EndEnrollmentModal from "../../components/modals/EndEnrollmentModal";
import EventShareLinkModal from "../../components/modals/EventShareLinkModal";
import { format } from "date-fns";
import AddEnrollmentModal from "../../components/modals/AddEnrollmentModal";
import {
  Delete,
  SettingsBackupRestore as Restore,
  Create as Edit
} from "@material-ui/icons";
import ReactTooltip from "react-tooltip";

class MembershipManageSingle extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: { prices: ["{}"] },
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      customers: {},
      showApplicationViewModal: false,
      addEnrollment: false,
      selectedProgram: null,
      modalProgram: null,
      type: "",
      displayCategory: "Memberships",
      openCancelMembershipModal: false,
      openMembershipLinkModal: false
    };

    this.tabs = ["Members", "Applications", "Inactive"];
    this.headings = {
      Members: [
        { id: "displayName", label: "Name" },
        { id: "email", label: "Email" },
        {
          id: "start",
          label: "Price",
          customCell: (r, i) => {
            const plan = JSON.parse(this.state.data.prices[r.plan_id]);
            return <td key={i}>${plan.price_per_term}</td>;
          }
        },
        {
          id: "end",
          label: "Term",
          customCell: (r, i) => {
            const plan = JSON.parse(this.state.data.prices[r.plan_id]);
            return (
              <td key={i}>
                {plan.membership_term > 1
                  ? `Every ${plan.membership_term} Months`
                  : "Every Month"}
              </td>
            );
          }
        },
        {
          id: "end",
          label: "Subscription",
          customCell: (r, i) => {
            const plan = JSON.parse(this.state.data.prices[r.plan_id]);
            return <td key={i}>{plan.auto_renew ? "Yes" : "No"}</td>;
          }
        },
        {
          id: "status",
          label: "Status",
          customCell: (r, i) => <td key={i}>Active</td>
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button
                  style={{ marginRight: "15px" }}
                  onClick={_ => {
                    // this.props.history.push(`/partners/application/${r.id}`)
                    let appStatus;
                    if (r.status_stage === "Accepted_Paid" || r.status_stage === "Accepted_Manual") {
                      appStatus = "Enrolled"; 
                    } else if (r.status_stage === "Accepted_Unpaid" && this.state.data.manual_invoices) {
                      appStatus = "Accepted";
                    }

                    this.setState({
                      selectedProgram: r,
                      showApplicationViewModal: true,
                      type: appStatus
                    });
                  }}
                >
                  View Details
                </button>
              </td>
            );
          }
        }
      ],
      Applications: [
        { id: "displayName", label: "Name" },
        { id: "email", label: "Email" },
        {
          id: "start",
          label: "Price",
          customCell: (r, i) => {
            const plan = JSON.parse(this.state.data.prices[r.plan_id]);
            return <td key={i}>${plan.price_per_term}</td>;
          }
        },
        {
          id: "end",
          label: "Term",
          customCell: (r, i) => {
            const plan = JSON.parse(this.state.data.prices[r.plan_id]);
            return (
              <td key={i}>
                {plan.membership_term > 1
                  ? `Every ${plan.membership_term} Months`
                  : "Every Month"}
              </td>
            );
          }
        },
        {
          id: "end",
          label: "Subscription",
          customCell: (r, i) => {
            const plan = JSON.parse(this.state.data.prices[r.plan_id]);
            return <td key={i}>{plan.auto_renew ? "Yes" : "No"}</td>;
          }
        },
        {
          id: "status_stage",
          label: "Status",
          customCell: (r, i) => <td key={i}>{r.status_stage}</td>
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button
                  style={{ marginRight: "15px" }}
                  onClick={_ => {
                    // this.props.history.push(`/partners/application/${r.id}`)
                    let appStatus;
                    if (r.status_stage === "New_Pending") {
                      appStatus = "New";
                    }
                    if (r.status_stage === "Accepted_Unpaid") {
                      appStatus = "Accepted";
                    }
                    if (
                      r.status_stage === "Waitlisted_Unpaid" ||
                      r.status_stage === "Waitlisted_Paid"
                    ) {
                      appStatus = "Waitlisted";
                    }
                    if (r.status_stage === "Rejected") {
                      appStatus = "Rejected";
                    }

                    this.setState({
                      selectedProgram: r,
                      showApplicationViewModal: true,
                      type: appStatus
                    });
                  }}
                >
                  View Details
                </button>
              </td>
            );
          }
        }
      ],
      Inactive: [
        { id: "displayName", label: "Name" },
        { id: "email", label: "Email" },
        {
          id: "start",
          label: "Price",
          customCell: (r, i) => {
            const planId = r.plan_id !== undefined && r.plan_id !== null ? r.plan_id : r.planId;
            const plan = JSON.parse(this.state.data.prices[planId]);
            return <td key={i}>${plan.price_per_term}</td>;
          }
        },
        {
          id: "end",
          label: "Term",
          customCell: (r, i) => {
            const planId = r.plan_id !== undefined && r.plan_id !== null ? r.plan_id : r.planId;
            const plan = JSON.parse(planId);
            return (
              <td key={i}>
                {plan.membership_term > 1
                  ? `Every ${plan.membership_term} Months`
                  : "Every Month"}
              </td>
            );
          }
        },
        {
          id: "end",
          label: "Subscription",
          customCell: (r, i) => {
            const planId = r.plan_id !== undefined && r.plan_id !== null ? r.plan_id : r.planId;
            const plan = JSON.parse(this.state.data.prices[planId]);
            return <td key={i}>{plan.auto_renew ? "Yes" : "No"}</td>;
          }
        },
        {
          id: "endDate",
          label: "End Date",
          customCell: (r, i) => (
            <td key={i}>
              {r.endDate ? format(new Date(r.endDate), "LLLL dd, yyyy") : ""}
            </td>
          )
        },
        {
          id: "status_stage",
          label: "Status",
          customCell: (r, i) => (
            <td key={i}>{r.status_stage ? r.status_stage : "Invited"}</td>
          )
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button
                  style={{ marginRight: "15px" }}
                  onClick={_ => {
                    // this.props.history.push(`/partners/application/${r.id}`)
                    let appStatus;
                    if (r.status_stage === "New_Pending") {
                      appStatus = "New";
                    }
                    if (r.status_stage === "Accepted_Unpaid") {
                      appStatus = "Accepted";
                    }
                    if (
                      r.status_stage === "Waitlisted_Unpaid" ||
                      r.status_stage === "Waitlisted_Paid"
                    ) {
                      appStatus = "Waitlisted";
                    }
                    if (r.status_stage === "Rejected") {
                      appStatus = "Rejected";
                    }

                    this.setState({
                      selectedProgram: r,
                      showApplicationViewModal: true,
                      type: appStatus
                    });
                  }}
                >
                  View Details
                </button>
              </td>
            );
          }
        }
      ]
    };
    this.actions = [{ name: "Notes", action: null }];
  }

  async componentDidMount() {
    await this.fetchInitialData();
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Manage Memberships", to: "/memberships/manage" },
        {
          label: this.state.data.membership_name,
          to: this.props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
        }
      ]
    });
  }

  // If the URL changes, refetch data and rerender componenet.
  async componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      await this.fetchInitialData();
    }
  }

  fetchInitialData = async () => {
    const param = this.props.match.params.id;
    const ep = `${process.env.REACT_APP_API}/partners/memberships/${param}`;
    const customers_ep = `${process.env.REACT_APP_API}/partners/memberships/customers/${param}`;
    const customers = await axios.get(customers_ep);

    const res = await axios.get(ep);

    const ep2 = `${process.env.REACT_APP_API}/partners/invitations/membership/${param}`;
    const res2 = await axios.get(ep2);

    if (res.data.success) {
      customers.data.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      //this.setState({ data: results.data.data.filter(e => !e.accepted) });
      this.setState({
        data: res.data.data,
        customers: {
          Members: customers.data.data.filter(
            e =>
              e.status_stage === "Accepted_Paid" ||
              e.status_stage === "Accepted_Manual" ||
              (e.status_stage === "Accepted_Unpaid" && res.data.data.manual_invoices)
          ),
          Applications: customers.data.data.filter(
            e =>
              e.status_stage === "New_Pending" ||
              (e.status_stage === "Accepted_Unpaid" && !res.data.data.manual_invoices) ||
              e.status_stage === "Waitlisted_Unpaid" ||
              e.status_stage === "Waitlisted_Paid"
          ),
          Inactive: [
            ...customers.data.data.filter(
              e =>
                e.status_stage === "Rejected" ||
                e.status_stage === "Inactive" ||
                e.status_stage === "Cancelled" ||
                e.status_stage === "Cancelled_Unpaid"
            ),
            ...res2.data.data.filter(e => !e.is_archived)
          ]
        }
      });
    }
  };

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
    if (results.data.success) {
      toast.success(`Partner Accepted`);
      this.componentDidMount();
    } else {
      toast.error(`Error, this person already signed-up!`);
    }
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  lowestPrice(arr) {
    return arr.reduce(
      (p, c) =>
        p.price_per_term < JSON.parse(c).price_per_term ? p : JSON.parse(c),
      JSON.parse(arr[0])
    );
  }
  viewNotes = (row, table) => {
    this.setState({
      notesModalShown: true,
      modalData: row,
      table: table
    });
  };

  /**
   * This function handles the edit button functionality of a sub user
   *  returns boolean
   */
  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return !this.global.dsUser.accesspoints.Manage.Memberships.edit;
    }
  };

  pauseEnrollment = async (isStripe, id, resume) => {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/orders/toggle-pause`;
    const res = await axios.post(ep, { id: id, is_stripe: isStripe });
    if (res.data.success) {
      toast.success(`Successfully ${resume ? "resumed" : "paused"} enrollment`);
    } else {
      toast.error("Please wait until the next invoice has been generated!");
    }
    this.setState({ showApplicationViewModal: false });
    await this.fetchInitialData();
    this.setGlobal({ loading: false });
  };

  getFilteredCustomers() {
    // Create a deep copy of the customers object
    const customersArr = JSON.parse(JSON.stringify(this.state.customers));

    if (customersArr["Applications"]) {
      customersArr["Applications"] = customersArr["Applications"].filter(
        customer => {
          const value = customer?.displayName?.toLowerCase().replace(/\s/g, "");
          const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
          return value.includes(filter);
        }
      );
    }

    if (customersArr["Members"]) {
      customersArr["Members"] = customersArr["Members"].filter(customer => {
        const value = customer?.displayName?.toLowerCase().replace(/\s/g, "");
        const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
        return value.includes(filter);
      });
    }

    if (customersArr["Inactive"]) {
      customersArr["Inactive"] = customersArr["Inactive"].filter(customer => {
        const value = customer?.displayName?.toLowerCase().replace(/\s/g, "");
        const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
        return value.includes(filter);
      });
    }

    return customersArr;
  }

  render() {
    console.log(this.state);
    console.log(this.props);
    return (
      <div className="admin">
        {this.state.notesModalShown && (
          <NotesModal
            data={this.state.modalData}
            table={this.state.table}
            onClose={() =>
              this.setState({
                notesModalShown: false,
                modalData: null
              })
            }
          />
        )}

        {this.state.addEnrollment && (
          <AddEnrollmentModal
            open={this.state.addEnrollment}
            program={this.state.modalProgram}
            onClose={() => {
              this.setState({
                addEnrollment: false,
                modalProgram: null
              });
              this.fetchInitialData();
            }}
            table={"partner_memberships"}
            promotion_type="membership"
            type="memberships"
          />
        )}

        {this.state.showApplicationViewModal && (
          <ApplicationViewModal
            refresh={this.fetchInitialData}
            program={this.state.selectedProgram}
            onClose={_ => {
              this.setState({ showApplicationViewModal: false });
            }}
            type={this.state.type}
            displayCategory={this.state.displayCategory}
            isApplication={this.state.data.application_required}
            endEnrollment={_ => {
              this.setState({
                openEndEnrollmentModal: true,
                showApplicationViewModal: false
              });
            }}
            pauseEnrollment={(isStripe, subscription) => {
              this.pauseEnrollment(isStripe, subscription);
            }}
            canSubUserEdit={this.canSubUserEdit()}
          />
        )}

        {this.state.openMembershipLinkModal && (
          <EventShareLinkModal
            onClose={_ => {
              this.setState({ openMembershipLinkModal: false });
            }}
            programId={this.state.data.id}
            type={"Membership"}
          />
        )}

        {this.state.openEndEnrollmentModal && (
          <EndEnrollmentModal
            program={this.state.selectedProgram}
            onClose={_ => {
              this.fetchInitialData();
              this.setState({ openEndEnrollmentModal: false });
            }}
            listingType={"Membership"}
          />
        )}

        {this.state.openCancelMembershipModal && (
          <CancelProgramModal
            onClose={_ => {
              this.fetchInitialData();
              this.setState({ openCancelMembershipModal: false });
            }}
            data={this.state.data}
            // custommers={this.state.customers}
            // success={e => this.fetchInitialData()}
            // setGlobal={this.setGlobal}
            // global={this.global}
            table={"partner_memberships"}
          />
        )}

        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>{this.state.data.membership_name}</h1>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div
                  className="forbtnapp member-btn"
                  style={{ marginLeft: "20px" }}
                >
                  <button
                    className="newapplications"
                    disabled={this.canSubUserEdit()}
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ =>
                      this.setState({
                        addEnrollment: true,
                        modalProgram: this.state.data
                      })
                    }
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Invite
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="display-container">
              <div className="left-container">
                <div className="image-container">
                  <LazyLoadImage
                    src={
                      this.state.data.membership_image === 2
                        ? `${process.env.REACT_APP_DS_FILES_S3}/${this.state.data.membership_image_url}`
                        : this.state.data.membership_image === 0
                        ? MemberShip1
                        : MemberShip2
                    }
                    alt="Membership"
                    className="image"
                    wrapperClassName="image"
                    effect="opacity"
                  />
                </div>
                <p className="membership-name">
                  {this.state.data.membership_name}
                </p>
                <p className="membership-duration">
                  Starting at $
                  {this.lowestPrice(this.state.data.prices).price_per_term} /{" "}
                  {this.lowestPrice(this.state.data.prices).membership_term}{" "}
                  Month(s)
                </p>
                <div className="address-container">
                  <h3 className="address-title">
                    192 Spadina Ave, <br /> Suite 406, Toronto, Ontario
                  </h3>
                </div>
                <div className="options-container">
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.props.history.push(
                            `/preview/membership/${this.state.data.id}`
                          );
                    }}
                  >
                    <h3 className="option-name">Membership Preview</h3>
                    <i className="far fa-calendar-check option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.viewNotes(
                            this.state.data,
                            "partner_memberships"
                          );
                    }}
                  >
                    <h3 className="option-name">Notes</h3>
                    <i className="fas fa-pencil-alt option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.props.history.push({
                            pathname: `/partners/send-message`,
                            search: `?type=Memberships&id=${this.props.match.params.id}`,
                            query: {
                              type: "Memberships",
                              id: `${this.props.match.params.id}`
                            }
                          });
                    }}
                  >
                    <h3 className="option-name">Message Members</h3>
                    <i className="fas fa-info option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={_ => {
                      return this.canSubUserEdit()
                        ? ""
                        : this.setState({ openCancelMembershipModal: true });
                    }}
                  >
                    <h3 className="option-name">Cancel Membership</h3>
                    <i className="fas fa-times-circle option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                  >
                    <h3 className="option-name">Membership List PDF</h3>
                    <i className="far fa-edit option-img"></i>
                  </div>
                  <div
                    className="option-list"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    onClick={e =>
                      this.setState({ openMembershipLinkModal: true })
                    }
                  >
                    <h3 className="option-name">Membership Share Link</h3>
                    <i className="fas fa-share"></i>
                  </div>
                </div>
              </div>
              <div className="tablecont">
                <div className="table-responsive">
                  <div
                    style={{
                      display: "inline-flex",
                      flex: "1 1",
                      border: "none",
                      borderRadius: "10px",
                      overflow: "hidden",
                      width: "100%"
                    }}
                  >
                    <i
                      className="fas fa-search"
                      style={{ padding: "8px", background: "white" }}
                    ></i>
                    <input
                      type="text"
                      placeholder="Search by name"
                      style={{
                        border: "none",
                        fontSize: "13px",
                        padding: "5px",
                        outline: 0,
                        background: "white",
                        flex: 1
                      }}
                      value={this.state.filter}
                      onChange={e => this.setState({ filter: e.target.value })}
                    />
                  </div>
                  <NewTabbedTable
                    tabs={this.tabs}
                    headings={this.headings}
                    data={this.getFilteredCustomers()}
                    actions={this.actions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MembershipManageSingle;
