import React, { useState } from "react";
import PropTypes from "prop-types";
import InfoBalloon from "./InfoBalloon";
import { ImageActiveA } from "../../assets";
// import Pagination from "../pagination/pagination";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { makeStyles } from "@material-ui/core";

// const useStyles = makeStyles(theme => ({
//   table: {
//     tbody: {
//       background: "#fff",
//       "& td": {
//         border: 0,
//         fontFamily: "Open Sans, sans-serif",
//         fontWeight: 400,
//         fontSize: "13px",
//         lineHeight: "19px",
//         color: "#000000",
//         padding: "15px",
//         borderBottom: "1px solid #e0e0e0",
//         verticalAlign: "middle !important"
//       }
//     },
//     thead: {
//       background: "#7ea0ff",
//       background: "-moz-linear-gradient(left, #7ea0ff 0%, #915aff 100%)",
//       background: `-webkit-gradient(
//           left top,
//           right top,
//           color-stop(0%, #7ea0ff),
//           color-stop(100%, #915aff)
//         )`,
//       background: "-webkit-linear-gradient(left, #7ea0ff 0%, #915aff 100%)",
//       background: "-o-linear-gradient(left, #7ea0ff 0%, #915aff 100%)",
//       background: "-ms-linear-gradient(left, #7ea0ff 0%, #915aff 100%)",
//       background: "linear-gradient(to right, #7ea0ff 0%, #915aff 100%)",
//       filter:
//         "progid:DXImageTransform.Microsoft.gradient( startColorstr='#7ea0ff', endColorstr='#915aff', GradientType=1 )",
//       "& th": {
//         border: 0,
//         fontFamily: "Open Sans, sans-serif",
//         fontWeight: 700,
//         fontSize: "13px",
//         lineHeight: "24px",
//         color: "#ffffff",
//         padding: "10px 15px",
//         whiteSpace: "nowrap"
//       }
//     }
//   }
// }));

class DraggableCell extends React.Component {
  constructor() {
    super();

    this.setRef = this.setRef.bind(this);
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (!this.ref) {
      return null;
    }

    const isDragStarting =
      this.props.isDragOccurring && !prevProps.isDragOccurring;

    if (!isDragStarting) {
      return null;
    }

    const { width, height } = this.ref.getBoundingClientRect();

    const snapshot = {
      width,
      height
    };

    return snapshot;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const ref = this.ref;

    if (!ref) {
      return;
    }

    if (snapshot) {
      if (ref.style.width === snapshot.width) {
        return;
      }
      ref.style.width = `${snapshot.width}px`;
      ref.style.maxWidth = `${snapshot.width}px`;
      ref.style.height = `${snapshot.height}px`;
      return;
    }

    if (this.props.isDragOccurring) {
      return;
    }

    // inline styles not applied
    if (ref.style.width == null) {
      return;
    }

    // no snapshot and drag is finished - clear the inline styles
    ref.style.removeProperty("height");
    ref.style.removeProperty("width");
    ref.style.removeProperty("maxWidth");
  }

  setRef(ref) {
    this.ref = ref;
  }

  render() {
    return this.props.customCell
      ? this.props.customCell(this.props.row, this.props.index, this.setRef)
      : null;
  }
}

const DraggedDropTabbedTable = props => {
  const { data, headings, tabs, actions, onTabChange, onDragEndHandler } =
    props;

  const [useTab, setTab] = useState(0);
  const [usePage, setPage] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [useDataLength, setDataLength] = useState(
    data[tabs[0]] ? data[tabs[0]].length : 0
  );

  //   const { td, tbody, th, thead } = useStyles();

  React.useEffect(() => {
    setDataLength(data[tabs[useTab]] ? data[tabs[useTab]].length : 0);

    //TEMPORARY
  }, [data, tabs, useTab]);

  const changeTab = i => {
    setDataLength(data[tabs[i]] ? data[tabs[i]].length : 0);
    setPage(0);
    setTab(i);
    if (onTabChange) onTabChange(i);
  };

  const onBeforeDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = ({ destination, source, draggableId }) => {
    onDragEndHandler(destination, source, draggableId, useTab);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: "white",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: "inherit",
    width: "100%"
  });

  return (
    <div style={{ marginBottom: "3.5em", height: "100%" }}>
      <div className="tablecont">
        <ul>
          {tabs.map((tab, i) => (
            <li key={tab} className={i === useTab ? "active" : ""}>
              <button onClick={() => changeTab(i)}>
                <img src={ImageActiveA} className="foractive" alt="" />
                {tab + `${data[tab] ? ` (${data[tab].length})` : ""}`}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="tablecont">
        <DragDropContext
          onDragEnd={onDragEnd}
          onBeforeDragStart={onBeforeDragStart}
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <table
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                className="table"
                style={{
                  boxShadow: "none"
                }}
              >
                <thead>
                  <tr>
                    {headings[tabs[useTab]] &&
                      headings[tabs[useTab]].map((heading, index) => (
                        <th
                          scope="col"
                          key={index}
                          style={heading.customStyle ? heading.customStyle : {}}
                        >
                          {heading.label}
                          {heading.infoBalloon && (
                            <InfoBalloon body={heading.infoBalloon} />
                          )}
                        </th>
                      ))}
                  </tr>
                </thead>

                <tbody>
                  {data[tabs[useTab]] &&
                    data[tabs[useTab]].map((row, k) => {
                      return (
                        <Draggable
                          draggableId={row.id.toString()}
                          index={k}
                          key={row.id}
                        >
                          {(provided, snapshot) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              {headings[tabs[useTab]] &&
                                headings[tabs[useTab]].map((heading, index) => {
                                  if (heading.id === "action") {
                                    return (
                                      <DraggableCell
                                        isDragOccurring={snapshot.isDragging}
                                        rowProps={{
                                          key: index,
                                          style: { whiteSpace: "nowrap" }
                                        }}
                                        rowContent={actions.map((action, i) => (
                                          <button
                                            onClick={() => action.action(row)}
                                            key={i}
                                            style={{ marginLeft: "10px" }}
                                          >
                                            {action.name}
                                          </button>
                                        ))}
                                      />
                                    );
                                  } else {
                                    return heading.customCell ? (
                                      <DraggableCell
                                        isDragOccurring={snapshot.isDragging}
                                        customCell={heading.customCell}
                                        row={row}
                                        index={index}
                                      />
                                    ) : (
                                      <DraggableCell
                                        isDragOccurring={snapshot.isDragging}
                                        rowProps={{ key: index }}
                                        rowContent={row[heading.id]}
                                      />
                                    );
                                  }
                                })}
                            </tr>
                          )}
                        </Draggable>
                      );
                    })}

                  {!data ||
                  !data[tabs[useTab]] ||
                  !data[tabs[useTab]].length ? (
                    <tr>
                      <td
                        colSpan={
                          headings[tabs[useTab]]
                            ? headings[tabs[useTab]].length
                            : 5
                        }
                        className="text-center"
                      >
                        Nothing to Show
                      </td>
                    </tr>
                  ) : null}
                  {provided.placeholder}
                </tbody>
              </table>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

DraggedDropTabbedTable.propTypes = {
  headings: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  actions: PropTypes.array
};

DraggedDropTabbedTable.defaultProps = {
  headings: {},
  data: {},
  tabs: [],
  actions: []
};

export default DraggedDropTabbedTable;
