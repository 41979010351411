import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { MiniPlus } from "../../assets";
import moment from "moment";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ModalTemplate } from "../UI/modalComponents";
import MilestonePreviewModal from "./MilestonePreviewModal";

const useStyles = makeStyles({
  iconWidthHeight: {
    width: "10px",
    height: "10px"
  }
});
const MilestonesModal = ({ onClose, history, program_id, type }) => {
  const [TableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const classes = useStyles();

  const getMilestones = async () => {
    try {
      const ep = `${process.env.REACT_APP_API}/partners/milestones/get/${program_id}`;
      const resp = await axios.get(ep);

      setTableData(resp.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getOnlineMilestones = async () => {
    try {
      const ep = `${process.env.REACT_APP_API}/partners/milestones/get_online/${program_id}`;
      const resp = await axios.get(ep);

      setTableData(resp.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (type === "Program") {
      getMilestones();
    }
    if (type === "Online") {
      getOnlineMilestones();
    }
  }, []);

  return (
    <ModalTemplate heading="Milestones" onClose={onClose}>
      {showPreviewModal && (
        <MilestonePreviewModal
          milestone={TableData}
          onClose={_ => setShowPreviewModal(false)}
        />
      )}
      <div className="modal-main">
        <div
          className="forbtnwrap justify-end"
          style={{ marginBottom: "5px", display: "flex" }}
        >
          <div className="forbtnapp enrollment-btn">
            <button
              className="newapplications"
              onClick={_ => setShowPreviewModal(true)}
              style={{ marginTop: "5px", marginRight: "10px" }}
            >
              Preview
            </button>
          </div>
          <div className="forbtnapp enrollment-btn">
            <button
              className="newapplications"
              onClick={_ =>
                history.push("/partners/milestones", {
                  data: { program_id: program_id, type: type }
                })
              }
              style={{ marginTop: "5px" }}
            >
              <img src={MiniPlus} alt="" style={{ alignSelf: "center" }} />
              Edit
            </button>
          </div>
        </div>

        <div>
          <table className="table">
            <thead
              style={{
                color: "#A9A9A9",
                fontSize: "11.2px",
                borderBottom: "1px solid gainsboro"
              }}
            >
              <tr>
                <th data-testid="table-header-name">Name</th>
                <th data-testid="table-header-date">Date</th>
                <th data-testid="table-header-upload">Upload</th>
                <th data-testid="table-header-submissions">Submissions</th>
              </tr>
            </thead>

            <tbody>
              {TableData &&
                TableData.map(data => {
                  return (
                    // TODO: format properly
                    <tr
                      key={data.id}
                      style={{
                        fontSize: "12px",
                        color: "#959595",
                        borderBottom: "2px solid gainsboro"
                      }}
                    >
                      <td>{data.name}</td>
                      <td>
                        {data.due_date
                          ? moment(data.due_date, "YYYY-MM-DD").fromNow()
                          : ""}
                      </td>
                      <td>
                        {data.can_upload ? (
                          <CheckIcon className={classes.iconWidthHeight} />
                        ) : (
                          <XIcon className={classes.iconWidthHeight} />
                        )}
                      </td>
                      <td>
                        <Link to={`/partners/milestones/view/${data.id}`}>
                          View
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </ModalTemplate>
  );
};

const XIcon = ({ className }) => {
  return (
    <svg
      className={className}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
      ></path>
    </svg>
  );
};
const CheckIcon = ({ className }) => {
  return (
    <svg
      className={className}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 13l4 4L19 7"
      ></path>
    </svg>
  );
};

export default MilestonesModal;
