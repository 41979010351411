import React from "reactn";
import axios from "axios";
import { format } from "date-fns";
import Avatar from "@material-ui/core/Avatar";
import {
  Avatar1b,
  Avatar2b,
  Avatar3b,
  Avatar4b,
  Avatar5b,
  Avatar6b,
  Avatar7b,
  Avatar8b,
  Avatar9b
} from "../../../assets";
import { withStyles } from "@material-ui/core";
import "../../../assets/css/componentSpecificCss/userDetails.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Profile from "./ProfileDetails";
import TasksReminders from "./TasksReminders";
import Access from "./AccessDetails";
import Schedule from "./Schedule";
import TeamCalendar from "../../../components/calendar/TeamCalendar";
import { NewTabbedTable } from "../../../components/UI";
import AddWorkLogModal from "../../../components/modals/AddWorkLogModal";
import LinkIcon from "@material-ui/icons/Link";
import ReactTooltip from "react-tooltip";
import { Delete, SettingsBackupRestore as Restore } from "@material-ui/icons";

const styles = {
  iconButton: {
    backgroundColor: "transparent !important",
    padding: "1em !important",
    color: "rgba(0, 0, 0, 0.54) !important",
    "&:focus": {
      outline: "none !important"
    }
  },
  avatar: {
    width: "65px",
    height: "65px"
  },
  item: {
    padding: "5px 10px",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "13px"
  },
  list: {
    height: "60px",
    padding: "0px"
  },
  tabRoot: {
    color: "#61616F !important",
    fontFamily: "Open Sans,sans-serif !important",
    fontWeight: "600 !important",
    fontSize: "1.1rem !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "#61616F !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "#61616F !important"
    }
  },
  tabSelected: {
    color: "black !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "black !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "black !important"
    }
  },
  tabIndicator: {
    backgroundColor: "#ff6663",
    height: "3px",
    marginTop: "20px"
  }
};

class UserManagement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      workLog: { Active: [] },
      roles: [],
      allRoles: [],
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      selectedUser: null,
      avatarMap: {
        "1b": Avatar1b,
        "2b": Avatar2b,
        "3b": Avatar3b,
        "4b": Avatar4b,
        "5b": Avatar5b,
        "6b": Avatar6b,
        "7b": Avatar7b,
        "8b": Avatar8b,
        "9b": Avatar9b
      },
      popoverAnchorE1: null,
      openSendInviteModal: false,
      openEmailInviteModal: false,
      openAddWorkLogModal: false,
      active: "Profile",
      subActive: "Individual",
      tasks: []
    };
    this.tabs = ["Active"];
    this.headings = {
      Active: [
        {
          id: "date",
          label: "Date",
          customCell: (r, i) => {
            return (
              <td key={`${i}-date`}>
                {r.work_date
                  ? format(new Date(r.work_date), "LLLL dd, yyyy")
                  : format(new Date(r.start_time), "LLLL dd, yyyy")}
              </td>
            );
          }
        },
        {
          id: "task-activity",
          label: "Task / Activity",
          customCell: (r, i) => {
            console.log("R", r);
            return (
              <td key={`${i}-task-activity`}>
                <span>{r.task_name}</span>
              </td>
            );
          }
        },
        ,
        {
          id: "start-time",
          label: "Start Time",
          customCell: (r, i) => {
            const date = new Date(r.start_time);

            const hours = date.getHours();
            const minutes = date.getMinutes();

            const meridiem = hours >= 12 ? "PM" : "AM";
            const hours12 = hours % 12 || 12;

            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

            const timeString = `${hours12}:${formattedMinutes} ${meridiem}`;

            return (
              <td key={`${i}-start-time`}>
                <span>{timeString}</span>
              </td>
            );
          }
        },
        ,
        {
          id: "end-time",
          label: "End Time",
          customCell: (r, i) => {
            const date = new Date(r.end_time);

            const hours = date.getHours();
            const minutes = date.getMinutes();

            const meridiem = hours >= 12 ? "PM" : "AM";
            const hours12 = hours % 12 || 12;

            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

            const timeString = `${hours12}:${formattedMinutes} ${meridiem}`;
            return (
              <td key={`${i}-end-time`}>
                <span>{r.end_time ? timeString : ""}</span>
              </td>
            );
          }
        },
        {
          id: "time-worked",
          label: "Total Time",
          customCell: (r, i) => {
            const startDate = new Date(r.start_time);
            const endDate = new Date(r.end_time);

            const startTimestamp = startDate.getTime();
            const endTimestamp = endDate.getTime();

            const timeDifference = Math.abs(endTimestamp - startTimestamp);

            const totalHours = (timeDifference / (1000 * 60 * 60)).toFixed(2);

            return r.end_time ? (
              <td key={`${i}-time-worked`}>{totalHours} hr</td>
            ) : (
              <td key={`${i}-time-worked`}></td>
            );
          }
        },
        {
          id: "notes",
          label: "Notes",
          customCell: (r, i) => {
            return <td key={`${i}-notes`}>{r.notes}</td>;
          }
        }
      ]
    };
    this.actions = [{ name: "Notes", action: null }];
    // save which team member to the global store
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway,
        {
          label: this.props.location.state.username,
          to: `/team-management/${this.props.location.state.userId}`
        }
      ]
    });
  }

  isAdmin() {
    if (this.global.dsUser.sub_user === false) {
      return true
    }
    if (this.state.roles && this.state.roles[0] && this.state.roles[0].role === "Administrator") {
      return true
    }
    
    return false
  }

  isOwner() {
    if (this.global.dsUser?.sub_user === false) {
      return true
    }
    return false
  }

  async componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/users/all-details/${this.props.match.params.id}`;
    const results = await axios.get(ep);

    const ep2 = `${process.env.REACT_APP_API}/partners/roles`;
    const results2 = await axios.get(ep2);

    const ep3 = `${process.env.REACT_APP_API}/partners/tasks`;
    const tasks = await axios.get(ep3);

    const ep4 = `${process.env.REACT_APP_API}/partners/users/work-log/${this.props.match.params.id}`;
    const results4 = await axios.get(ep4);

    if (results.data.success) {
      this.setState({
        data: results.data.data,
        workLog: {
          Active: results4.data.data?.filter(worklog => !worklog.isHidden)
        },
        roles: results2.data.data.roles.filter(
          e => e.id === results.data.data.role
        ),
        tasks: tasks.data.data,
        allRoles: results2.data.data.roles
      });
    }

    this.setGlobal({ loading: false });
  };

  render() {
    if (this.state.roles.length === 0) {
      return null;
    }

    return (
      <div className="admin">
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>User Info</h1>
            </div>
          </div>

          <div className="select-tab">
            <div className="user-wrapper">
              <div className="user-block">
                <div className="user-details">
                  <div className="user-img-container">
                    <Avatar
                      src={this.state.avatarMap[this.state.data.avatar]}
                      classes={{ root: this.props.classes.avatar }}
                    />
                  </div>
                  <div className="user-info">
                    <div className="user-name">{this.state.data.username}</div>
                    <div className="user-particular">
                      {this.state.roles[0].role}
                    </div>
                    {this.state.data.last_logged_in && (
                      <div className="user-particular">
                        {this.state.data.last_logged_in &&
                          format(
                            new Date(this.state.data.last_logged_in),
                            "LLLL dd,yyyy"
                          )}
                      </div>
                    )}
                    {this.state.data.receives_emails && (
                      <div className="user-particular">
                        {this.state.data.receives_emails &&
                          "Subscribed to emails"}
                      </div>
                    )}
                  </div>
                </div>
                <div className="user-upper-tabs">
                  <div className="tab-items">
                    <Tabs
                      value={this.state.active}
                      onChange={(event, newValue) =>
                        this.setState({ active: newValue })
                      }
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      TabIndicatorProps={{
                        className: this.props.classes.tabIndicator
                      }}
                      classes={{ root: this.props.classes.tabsHeadings }}
                    >
                      <Tab
                        label="Profile"
                        value="Profile"
                        disableRipple
                        disableFocusRipple
                        classes={{
                          root: this.props.classes.tabRoot,
                          selected: this.props.classes.tabSelected
                        }}
                      />
                      {this.isAdmin() && <Tab
                        label="Access"
                        value="Access"
                        disableRipple
                        disableFocusRipple
                        classes={{
                          root: this.props.classes.tabRoot,
                          selected: this.props.classes.tabSelected
                        }}
                      />}
                      <Tab
                        label="Schedule"
                        value="Schedule"
                        disableRipple
                        disableFocusRipple
                        classes={{
                          root: this.props.classes.tabRoot,
                          selected: this.props.classes.tabSelected
                        }}
                      />
                      <Tab
                        label="Tasks / Reminders"
                        value="Tasks / Reminders"
                        disableRipple
                        disableFocusRipple
                        classes={{
                          root: this.props.classes.tabRoot,
                          selected: this.props.classes.tabSelected
                        }}
                      />
                      <Tab
                        label="Work Log"
                        value="Work Log"
                        disableRipple
                        disableFocusRipple
                        classes={{
                          root: this.props.classes.tabRoot,
                          selected: this.props.classes.tabSelected
                        }}
                      />
                    </Tabs>
                    {/* </div> */}
                  </div>
                </div>
                {this.state.active === "Work Log" && (
                  <div className="conn-btn-section">
                    <button
                      className="newapplications conn-btn"
                      onClick={_ =>
                        this.setState({ openAddWorkLogModal: true })
                      }
                    >
                      Add New Log
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {this.state.openAddWorkLogModal && (
            <AddWorkLogModal
              onClose={() => this.setState({ openAddWorkLogModal: false })}
              onRefresh={() => this.fetchInitialData()}
            />
          )}

          {this.state.active === "Profile" && (
            <Profile
              data={this.state.data}
              match={this.props.match}
              update={_ => this.fetchInitialData()}
            />
          )}

          {this.state.active === "Access" && (
            <Access
              roles={this.state.roles}
              allRoles={this.state.allRoles}
              selectedUser={this.state.data}
              match={this.props.match}
              update={async e => {
                // this.setState({ active: "Profile" });
                this.setState({ active: "Access" });
                await this.fetchInitialData();
              }}
            />
          )}

          {this.state.active === "Schedule" && (
            <TeamCalendar
              view={"Profile View"}
              match={this.props.match}
              refresh={() => this.fetchInitialData()}
            />
          )}

          {this.state.active === "Tasks / Reminders" && (
            <TasksReminders tasks={this.state.tasks} match={this.props.match} />
          )}

          {this.state.active === "Work Log" && (
            <>
              <div className="cont">
                <div className="tablecont">
                  <div className="table-responsive">
                    <NewTabbedTable
                      tabs={this.tabs}
                      headings={this.headings}
                      data={this.state.workLog}
                      actions={this.actions}
                      hideHeader={true}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(UserManagement);
