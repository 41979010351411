import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import { DROPDOWN, STRING_INPUT, DATE_PICKER, TITLE } from "./baseModal/FieldTypes";
import { notEmptyString } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class EditTaskModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      users: [],
      assigned: null,
      priority: 1,
      priority: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {
    // document.getElementsByClassName("react-datepicker-wrapper")[0].style.width =
    //   "70%";
    const ep = `${process.env.REACT_APP_API}/partners/roles`;
    const users = await axios.get(ep);
    console.log("users", users);
    const usersNew = (users.data.data.users[0].username = "Myself");

    if (users.data.success) {
      this.setState({
        users: users.data.data.users.filter(f => !f.isArchived)
      });
    }

    if (this.props.item) {
      this.setState({
        id: this.props.item.id,
        description: this.props.item.description,
        due: this.props.item.due,
        priority: this.props.item.priority,
        assigned: this.props.item.assigned
      });
    }
  }
  async addTask(e) {
    e.preventDefault();

    const close = { ...this.state };
    delete close.users;
    const taskEp = `${process.env.REACT_APP_API}/partners/tasks/edit`;
    const updatedTask = await axios.put(taskEp, {
      ...close
    });

    if (updatedTask.data.success) {
      toast.success(`Tasks Edited`);
      this.props.tasks(updatedTask.data.data);
      this.props.onClose();
    }
  }

  /// ================== NEW MODAL ========================== ///
  getassignOptions = () => {
    return [
      ...this.state.users.map(({ id, username }) => ({
        value: id,
        text: username === "Myself" ? this.global.dsUser.name : username
      }))
    ];
  };

  getBaseModalFields() {
    const descriptionInput = {
      type: STRING_INPUT,
      data: {
        name: "Description",
        required: true,
        placeholder: "Description...",
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description
      }
    };

    const dueField = {
      type: DATE_PICKER,
      data: {
        name: "Due Date",
        required: false,
        selected: new Date(this.state.due),
        handleChange: date => this.setState({ due: date }),
        minDate: new Date(),
        dateFormat: "MMMM d, yyyy",
        placeholder: "Pick a date",
        value: new Date(this.state.due)
      }
    };

    const priorityInput = {
      type: DROPDOWN,
      data: {
        name: "Priority",
        required: false,
        placeholder: "Please Select",
        handleChange: e =>
          this.setState({
            priority: e.target.value
          }),
        value: this.state.priority,
        choices: [
          { value: 1, text: "Low" },
          { value: 2, text: "Medium" },
          { value: 3, text: "High" }
        ]
      }
    };

    const assignInput = {
      type: DROPDOWN,
      data: {
        name: "Assign",
        required: false,
        placeholder: "Please Select",
        handleChange: e =>
          this.setState({
            assigned: e.target.value
          }),
        choices: this.getassignOptions(),
        value: this.state.assigned
      }
    };

    return [descriptionInput, dueField, priorityInput, assignInput];
  }

  getNotAllowedFields = () => {
    const textField = {
      type: TITLE,
      data: {
        name: "Sorry, you may not edit this task."
      }
    }

    return [textField];
  }

  getBaseModalButtons = () => {
    const editButton = {
      name: "Edit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.addTask(e)
    };

    return [editButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Edit Task",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  getNotAllowedProps = () => {
    return {
      title: "Edit Task",
      fields: this.getNotAllowedFields(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  }

  /// ================== END NEW MODAL ===================== ///

  render() {
    if (!this.state.due) {
      return null;
    }

    if (this.props.notAllowed) {
      return <BaseModal {...this.getNotAllowedProps()}/>
    } else {
      return <BaseModal {...this.getBaseModalProps()} />;
    }
  }
}

export default withRouter(EditTaskModal);
