import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close as ClearIcon, Delete, Create as Edit } from "@material-ui/icons";
import "../../assets/css/componentSpecificCss/applicationViewModal.css";
import moment from "moment";
import {
  format,
  addWeeks,
  addDays,
  addHours,
  subDays,
  isAfter
} from "date-fns";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
// import { ReactPDF, PDFViewer , BlobProvider, PDFDownloadLink, pdf, Document, Page } from '@react-pdf/renderer';
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import SwitchEnrollmentModal from "./SwitchEnrollmentModal";
import ApplicationDenyNotificationModal from "./ApplicationDenyNotificationModal";

import {
  CONTENT_BLOCK,
  BUTTON,
  TEXT_AREA,
  IMAGE_CONTENT,
  DROPDOWN,
  GET_HTML,
  STRING_INPUT
} from "./baseModal/FieldTypes";
import BaseModal from "./baseModal/BaseModal";
import ApplicationCancelModal from "./ApplicationCancelModal";
import {
  BLUE_BUTTON,
  GREEN_BUTTON,
  RED_BUTTON,
  WHITE,
  YELLOW_BUTTON
} from "./baseModal/colours";
import { result } from "lodash";

class ViewPartyAddonsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      partyDetails: props.partyDetails,
      addOnItems: [],
      addOns: []
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidMount() {
    this.setGlobal({
      loading: true
    })
    console.log("state in view party addonsmodal:",this.state);
    const ep = `${process.env.REACT_APP_API}/partners/parties/addons`
    const result = await axios.get(ep);

    if (result.data.success) {
      this.setState({
        addOns: result.data.data
      });
    } else {
      toast.error("Failed to load add-ons")
    }

    this.setGlobal({
      loading: false
    })
  }

  addOnOptions = () => {
    return [
      ...this.state.addOns.map((e, i) => ({
        text: e.name,
        value: i,
      }))
    ];
  };

  addOnsField = () => {
    return (
      <div
        className="col-12 d-flex"
        style={{
          padding: "0px",
        }}
      >
        <div
          style={{
            border: "1px solid #cbcbcb",
            borderRadius: "5px",
            padding: "5px",
            width: "100%",
            marginTop: "10px",
            height: "180px",
            overflow: "auto"
          }}
        >
          {this.state.addOnItems.map(e => {
            return (
              <Button
                variant="primary"
                size="sm"
                style={{ margin: "3px 5px" }}
              >
                {`${e.name}: $${parseFloat(e.price).toFixed(2)}`}
                <ClearIcon
                  style={{
                    color: "white",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    this.setState({
                      addOnItems: this.state.addOnItems.filter(addon => addon.name !== e.name)
                    })
                  }}
                />
              </Button>
            );
          })}
        </div>
      </div>
    )
  }

  getBaseModalFields() {
    const allFields = []

    const addOnSelectionField = {
      type: DROPDOWN,
      data: {
        name: "Add-ons",
        required: true,
        placeholder: "Select Add-on",
        value: "Select Add-on",
        handleChange: e => {
          const name = e.target.options[e.target.selectedIndex].text;
          if (e.target.selectedIndex === 0) {

          } else if (this.state.addOnItems.find(addon => addon.name === name) ||
            (this.state.partyDetails.addOnAmounts && this.state.partyDetails.addOnAmounts[name])
          ) {
            toast.error("You have already selected this add-on");
          } else {
            const addon = this.state.addOns.find(addon => addon.name === name)
            this.setState({
              addOnItems: [...this.state.addOnItems, {
                name: addon.name,
                price: addon.price,
                description: addon.description
              }]
            })
          }
        },
        choices: this.addOnOptions()
      },
      validators: { validateSubmit: () => !this.state.isTaxEnabled || this.state.taxInfo.length }
    }

    const addOnOptionField = {
      type: GET_HTML,
      data: {
        gethtml: this.addOnsField
      },
      validators: {}
    };

    allFields.push(addOnSelectionField)
    allFields.push(addOnOptionField)

    return allFields;
  }

  getBaseModalButtons() {
    const buttons = []

    const saveButton = {
      name: "Save",
      handleClick: () => {
        this.props.savePartyAddon(this.state.addOnItems);
        this.props.onClose();
      },
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
    }

    buttons.push(saveButton)

    return buttons;
  }

  getBaseModalProps() {
    const baseModalProps = {
      title: "Party Add-ons",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };

    return baseModalProps;
  }

  render() {
    if (!this.props) {
      return null;
    }
    console.log("this.state", this.state);
    console.log("this.props", this.props);

    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(ViewPartyAddonsModal);
