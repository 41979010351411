import React from "reactn";
import { StepIndicator } from "../../../components/UI";
import Setup from "./Setup";
import AdditionalDetails from "./AdditionalDetails";
import StepBar from "../../../components/UI/StepBar";
import Validators from "../../../validators";
import axios from "axios";
import { canUserCreate } from "../../../utilities/listingCreation";

class GiftCardSetup extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      canUserMakeNewGiftCard: false,
      makeGiftcardReqMessage: "",
      step: 0
    };
    this.titles = ["Set-Up"];
    this.validate = [
      () => Validators.Partners.Profile.ValidateGiftCards(this.global.gift_card)
    ];
    this.steps = [{ name: "Set-Up", validateFn: this.validate[0] }];
  }

  canUserCreateNewGiftCardQuery = async () => {
    const ep = new URL(
      `${process.env.REACT_APP_API}/partners/permissions/giftcards/can_user_create_giftcards`
    );
    const resp = await axios.get(ep);

    if (resp.data) {
      return resp.data;
    }

    return {
      success: false,
      message: "Something went wrong when querying the data"
    };
  };
  async componentDidMount() {
    const canUserMake = await canUserCreate("giftcard");
    this.setState({
      canUserMakeNewGiftCard: canUserMake.success,
      makeGiftcardReqMessage: canUserMake.message
    });

    if (this.props.match.params.id) {
      await this.dispatch.getGift(this.props.match.params.id);
    } else {
      await this.dispatch.resetEvent();
    }

    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Gift Cards", to: "/gift_cards" },
        {
          label: this.global.gift_card.gift_name,
          to: this.props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
        }
      ]
    });
  }

  saveProfile = async e => {
    const { step } = this.state;
    switch (e.target.name) {
      case "next":
        this.setState({ step: step + 1 });
        await this.dispatch.saveGifts();
        break;
      case "back":
        this.setState({ step: step - 1 });
        await this.dispatch.saveGifts();
        break;
      case "save":
        await this.dispatch.saveGifts();
        break;
      default:
        return;
    }
  };

  changePage = async page => {
    if (!this.checkValid()) {
      await this.dispatch.saveGifts();

      this.setState({ step: page });
    }
  };

  getPage = () => {
    switch (this.state.step) {
      case 0:
        return <Setup />;
      case 1:
        return <AdditionalDetails />;
      default:
        return <Setup />;
    }
  };

  checkValid() {
    const { step } = this.state;
    return this.validate[step] && !this.validate[step]();
  }

  getCompletion() {
    let completed = 0;
    for (let v of this.validate) {
      completed += v();
    }
    return (completed / this.validate.length) * 100;
  }

  render() {
    if (!this.state.canUserMakeNewGiftCard && !this.props.match.params.id) {
      return (
        <div className="container-fluid">
          {/* Commented out to make space for Top Bar */}
          {/* <div className="cont">
            <h1 className="events-title-header">Enable Gift Cards</h1>
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "50vh",
              alignItems: "center"
            }}
          >
            <p>{this.state.makeGiftcardReqMessage}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="container-fluid">
        {/* Commented out to make space for Top Bar */}
        {/* <div className="cont">
          <h1 className="events-title-header">Enable Gift Cards</h1>
        </div> */}
        <StepBar
          {...{
            steps: this.steps,
            changeFn: this.changePage,
            current: this.state.step
          }}
        />
        <StepIndicator value={this.getCompletion()} />
        {this.getPage()}
        <div className="cont mb-5">
          <div
            className="buttons w-100"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "80%"
              }}
            >
              <div
                name="back"
                disabled={this.state.step === 0}
                onClick={e => {
                  e.target.name = "back";
                  if (this.state.step === 0) return;
                  this.saveProfile(e);
                }}
              >
                <i
                  name="back"
                  style={{ paddingRight: "24px" }}
                  className={`fas fa-chevron-left ${
                    this.state.step === 0
                      ? "listing-step-btn-inactive"
                      : "listing-step-btn-active"
                  }`}
                ></i>
              </div>
              <div
                name="next"
                onClick={e => {
                  e.target.name = "next";
                  if (
                    this.state.step === this.titles.length - 1 ||
                    this.checkValid()
                  )
                    return;
                  this.saveProfile(e);
                }}
              >
                <i
                  name="next"
                  style={{ paddingLeft: "24px" }}
                  className={`fas fa-chevron-right ${
                    this.state.step === this.titles.length - 1 ||
                    this.checkValid()
                      ? "listing-step-btn-inactive"
                      : "listing-step-btn-active"
                  }`}
                ></i>
              </div>
            </div>
            {/* <button
              onClick={this.saveProfile}
              disabled={this.state.step === 0}
              name="back"
              className="nextbtn"
            >
              BACK
            </button> */}
            <div style={{ width: "10%" }}>
              <button
                onClick={this.saveProfile}
                disabled={this.checkValid()}
                name="save"
                className="savebtn"
                style={{ background: "#b2b8c7", border: "1px solid #b2b8c7" }}
              >
                SAVE
              </button>
            </div>
            {/* <button
              onClick={this.saveProfile}
              disabled={
                this.state.step === this.titles.length - 1 || this.checkValid()
              }
              name="next"
              className="nextbtn"
            >
              NEXT
            </button> */}
          </div>
        </div>
      </div>
    );
  }
}

export default GiftCardSetup;
