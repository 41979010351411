import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { MiniPlus } from "../../assets";
import { toast } from "react-toastify";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  TEXT_AREA,
  GET_HTML,
  IMAGE_DROPDOWN,
  SWITCH,
  DROPDOWN,
  DATE_PICKER,
  SELECT_MULTIPLE_DROPDOWN
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class EditIndvResourceModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  uploadNew() {
    const uploadEle = document.getElementById("uploadPhoto");
    uploadEle.click();
  }

  async saveResource(e) {
    e.preventDefault();

    let resources = this.state.resources;
    const updateResource = resources.filter(
      e => e.id == this.props.selectedResourceId
    )[0];
    updateResource.title = this.state.title;
    updateResource.description = this.state.description;
    updateResource.image = this.state.image;
    updateResource.file = this.state.file;
    updateResource.web_url = this.state.web_url;

    const ep = `${process.env.REACT_APP_API}/partners/resources/update_resource`;
    const res = await axios.post(ep, { resources, id: this.props.resource.id });

    if (res.data.success) {
      toast.success(`Updated Resource`);
      this.props.fetch();
      this.props.onClose();
    }
  }
  async fileChange(e) {
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      const fd = new FormData();
      fd.set("image", e.target.files[0]);
      const ep = `${process.env.REACT_APP_API}/partners/resources/photo`;
      this.setGlobal({ loading: true });
      const result = await axios.post(ep, fd);
      this.setGlobal({ loading: false });
      if (result.data.success) {
        this.setState({ image: result.data.data });
      }
    }
  }
  async fileChangeDoc(e) {
    if (e.target.files.length && e.target.files[0]) {
      const fd = new FormData();
      fd.set("image", e.target.files[0]);
      const ep = `${process.env.REACT_APP_API}/partners/resources/document`;
      this.setGlobal({ loading: true });
      const result = await axios.post(ep, fd);
      this.setGlobal({ loading: false });
      if (result.data.success) {
        this.setState({ file: result.data.data });
      }
    }
  }

  componentDidMount() {
    const indvResource =
      typeof this.props.resource.resources[this.props.selectedResourceId] ===
      "string"
        ? JSON.parse(
            this.props.resource.resources[this.props.selectedResourceId]
          )
        : this.props.resource.resources[this.props.selectedResourceId];
    const allResource = this.props.resource.resources.map(e =>
      typeof e === "string" ? JSON.parse(e) : e
    );
    this.setState({ ...indvResource, resources: allResource });
  }


  listingImageContent = () => {
    return (
      <div
        className="form-group"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: 0
        }}
      >
        <label
          htmlFor="displayName"
          style={{
            width: "33%",
            textAlign: "left",
            fontSize: "1.15rem",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262"
          }}
        >
          Listing Image
        </label>
        <figure
          className="photoContainer"
          style={{
            width: "25%",
            textAlign: "center",
            marginLeft: "6px"
          }}
          onClick={this.uploadNew}
        >
          {this.state.image ? (
            <div
              style={{
                padding: "2px",
                width: "80px",
                height: "80px",
                border: "1px solid #c5c5c5",
                display: "inline-block",
                borderRadius: "8px",
                cursor: "pointer",
                backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${this.state.image})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"
              }}
            ></div>
          ) : (
            <div
              style={{
                padding: "2px",
                width: "80px",
                height: "80px",
                border: "1px solid #c5c5c5",
                display: "inline-block",
                borderRadius: "8px",
                cursor: "pointer"
              }}
            >
              <img
                src={MiniPlus}
                alt="Add"
                style={{ marginTop: "calc(50% - 4.5px)" }}
              />
            </div>
          )}
        </figure>
      </div>
    );
  };

  fileAttachContent = () => {
    return (
      <div
        className="form-group"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 0
        }}
      >
        {this.state.file ? (
          <h4
            style={{
              margin: 0,
              fontSize: "15px",
              color: "black",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            File Uploded !
          </h4>
        ) : (
          <>
            <div style={{ width: "35%" }}>
              <p
                style={{
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                  fontFamily: "futura-pt, sans-serif",
                  fontWeight: "700",
                  color: "#626262",
                  marginBottom: 0
                }}
              >
                Upload Resource <br />
                <span style={{ fontSize: "9px", fontFamily: "futura-pt, sans-serif" }}>(max. 20mb)</span>
              </p>
              <Button
                variant="contained"
                color="primary"
                component="span"
                style={{ fontFamily: "futura-pt, sans-serif" }}
                startIcon={<CloudUpload />}
                fullWidth
                onClick={_ => {
                  document.getElementById("uploadDocument").click();
                }}
              >
                Document
              </Button>
            </div>
            <span
              style={{
                width: "10%",
                fontSize: "14px",
                textAlign: "center",
                marginTop: "15px",
                fontFamily: "futura-pt, sans-serif",
                color: "#626262"
              }}
            >
              OR
            </span>
            <div style={{ width: "50%" }}>
              <p
                style={{
                  fontSize: "0.7rem",
                  marginBottom: 0,
                  fontFamily: "futura-pt, sans-serif",
                  color: "#626262",
                  fontWeight: 700
                }}
              >
                Web URL{" "}
              </p>
              <input
                name="web_url"
                id="displayName"
                type="text"
                className="form-control"
                placeholder="Must start with https://"
                style={{ width: "100%" }}
                onChange={e => {
                  this.setState({
                    [e.target.name]: e.target.value
                  });
                }}
                pattern="^https://.*$"
                title="Enter valid URL beginning with https://"
              />
            </div>
          </>
        )}
        <input
          type="file"
          style={{ display: "none" }}
          id="uploadPhoto"
          onChange={e => this.fileChange(e)}
          accept="image/x-png,image/gif,image/jpeg"
        />
        <input
          type="file"
          style={{ display: "none" }}
          id="uploadDocument"
          onChange={e => this.fileChangeDoc(e)}
          accept="*"
        />
      </div>
    );
  };

  getBaseModalFields = () => {
    const titleInput = {
      type: STRING_INPUT,
      data: {
        name: "Title",
        required: true,
        isDisabled: false,
        placeholder: "Resource name...",
        value: this.state.title,
        handleChange: e => {
          this.setState({
            title: e.target.value
          });
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      },
      validators: { validateSubmit: () => this.state.title !== ""  }
    };

    const descriptionInput = {
      type: TEXT_AREA,
      data: {
        name: "Description",
        placeholder: "Description...",
        required: false,
        handleChange: e => {
          this.setState({
            description: e.target.value
          });
        },
        value: this.state.description,
        iconStyles: {
          paddingLeft: "10px"
        },
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    };

    const listingImageInput = {
      type: GET_HTML,
      data: {
        gethtml: this.listingImageContent
      }
    };

    const uploadAttach = {
      type: GET_HTML,
      data: {
        gethtml: this.fileAttachContent
      }
    };

    const fields = [];
    fields.push(titleInput);
    fields.push(descriptionInput);
    fields.push(listingImageInput);
    fields.push(uploadAttach);

    return fields;
  };

  getBaseModalButtons = () => {
    const submitButton = {
      name: "Save",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.saveResource(e)
    };

    return [submitButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Edit Resource",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose
    };
  };


  render() {
    return <BaseModal {...this.getBaseModalProps()} />
    return (
      <ModalTemplate heading="Edit Resource" onClose={this.props.onClose}>
        <form onSubmit={e => this.saveResource(e)}>
          <div className="modal-main">
            <ModalFields.input
              label="Title"
              name="title"
              required={true}
              type="text"
              value={this.state.title}
              onChange={e => {
                this.setState({
                  title: e.target.value
                });
              }}
              placeholder="Resource name..."
            />
            <ModalFields.textarea
              label="Description"
              name="description"
              placeholder="Description..."
              onChange={e => {
                this.setState({
                  description: e.target.value
                });
              }}
              value={this.state.description}
            />
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <label
                htmlFor="displayName"
                style={{ width: "25%", textAlign: "left" }}
              >
                Listing Image
              </label>
              <figure
                className="photoContainer"
                style={{
                  width: "25%",
                  textAlign: "center",
                  marginLeft: "6px"
                }}
                onClick={this.uploadNew}
              >
                {this.state.image ? (
                  <div
                    style={{
                      padding: "2px",
                      width: "80px",
                      height: "80px",
                      border: "1px solid #e3e3e3",
                      display: "inline-block",
                      borderRadius: "8px",
                      cursor: "pointer",
                      backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${this.state.image})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat"
                    }}
                  ></div>
                ) : (
                  <div
                    style={{
                      padding: "2px",
                      width: "80px",
                      height: "80px",
                      border: "1px solid #e3e3e3",
                      display: "inline-block",
                      borderRadius: "8px",
                      cursor: "pointer"
                    }}
                  >
                    <img
                      src={MiniPlus}
                      alt="Add"
                      style={{ marginTop: "calc(50% - 4.5px)" }}
                    />
                  </div>
                )}
              </figure>
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <>
                <div style={{ width: "35%" }}>
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      marginBottom: 0
                    }}
                  >
                    Upload Resource{" "}
                    <span style={{ fontSize: "9px" }}>(max. 20mb)</span>
                    {this.state.file && (
                      <span style={{ fontSize: "9px", color: "black" }}>
                        File Uploaded!
                      </span>
                    )}
                  </p>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CloudUpload />}
                    fullWidth
                    onClick={_ => {
                      document.getElementById("uploadDocument").click();
                    }}
                  >
                    Document
                  </Button>
                </div>
                <span
                  style={{
                    width: "10%",
                    fontSize: "14px",
                    textAlign: "center",
                    marginTop: "15px",
                    color: "grey"
                  }}
                >
                  OR
                </span>
                <div style={{ width: "50%" }}>
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      marginBottom: 0
                    }}
                  >
                    Web URL{" "}
                  </p>
                  <input
                    name="web_url"
                    id="displayName"
                    type="text"
                    className="form-control"
                    placeholder="Must start with https://"
                    style={{ width: "100%" }}
                    onChange={e => {
                      this.setState({
                        [e.target.name]: e.target.value
                      });
                    }}
                    value={this.state.web_url}
                    pattern="^https://.*$"
                    title="Enter valid URL beginning with https://"
                  />
                </div>
              </>

              <input
                type="file"
                style={{ display: "none" }}
                id="uploadPhoto"
                onChange={e => this.fileChange(e)}
                accept="image/x-png,image/gif,image/jpeg"
              />
              <input
                type="file"
                style={{ display: "none" }}
                id="uploadDocument"
                onChange={e => this.fileChangeDoc(e)}
                accept="*"
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn profile-btn">
              Save
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(EditIndvResourceModal);
