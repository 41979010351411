import React from "reactn";

const expenseModel = {
  id: null,
  category: -1,
  // items: "",
  notes: "",
  amount: ""
};

class AddExpenseForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...expenseModel
    };
  }

  render() {
    return (
      <div className="d-flex" style={{ flexWrap: "wrap" }}>
        <select
          className="mx-1"
          value={this.state.category}
          style={{
            fontSize: "12px",
            borderRadius: "5px",
            border: "1px solid #cccccc",
            minWidth: 150,
            maxWidth: 150
          }}
          onChange={e =>
            this.setState({
              category: parseInt(e.target.value)
            })
          }
        >
          <option value={-1}> Select a category...</option>
          {this.props.categories.map((g, i) => (
            <option key={i} value={g.id}>
              {g.name}
            </option>
          ))}
        </select>
        <input
          type="number"
          placeholder="$0.00"
          name="amount"
          min={0}
          style={{
            fontSize: "12px",
            maxWidth: "85px",
            borderRadius: "5px",
            border: "1px solid #cccccc",
            padding: "10px"
          }}
          value={this.state.amount}
          onChange={e => {
            this.setState({
              amount: e.target.value
            });
          }}
        />
        <input
          style={{
            fontSize: "12px",
            borderRadius: "5px",
            border: "1px solid #cccccc",
            minWidth: 200,
            flexGrow: 1,
            padding: "5px"
          }}
          className="mx-1"
          value={this.state.notes}
          name="notes"
          placeholder="Receipt number, items purchased, etc..."
          onChange={e => {
            this.setState({ notes: e.target.value });
          }}
        />
        <div className="buttons mt-0 ml-1 mb-0">
          <button
            onClick={() => {
              this.props.addExpense({
                expense: { ...this.state },
                category: this.props.categories.find(
                  c => this.state.category === c.id
                )
              });
              this.setState(expenseModel);
            }}
            className="nextbtn"
            disabled={!this.state.amount || this.state.category === -1}
            style={{
              padding: "5px 9px",
              height: "100%"
            }}
          >
            <i className="fas fa-plus"></i>
          </button>
        </div>
      </div>
    );
  }
}

export default AddExpenseForm;
