import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

import { ModalTemplate, ModalFields } from "../UI/modalComponents";

const TaskReminderNotesModal = ({ onClose, global, type, item, setGlobal }) => {
  const [notes, setNotes] = useState(item.notes);

  async function saveWelcome() {
    if (type === "Task") {
      const taskEp = `${process.env.REACT_APP_API}/partners/tasks/edit`;
      const updatedTask = await axios.put(taskEp, {
        id: item.id,
        notes: notes
      });

      if (updatedTask.data.success) {
        toast.success(`Tasks Notes Saved`);
        onClose();
      }
    }
    if (type === "Reminder") {
      const reminderEp = `${process.env.REACT_APP_API}/partners/reminders/edit`;
      const updatedReminder = await axios.put(reminderEp, {
        id: item.id,
        notes: notes
      });

      if (updatedReminder.data.success) {
        toast.success(`Reminder Notes Saved`);
        onClose();
      }
    }
  }

  const mainContent = (
    <form onSubmit={e => e.preventDefault()}>
      <ModalFields.textarea
        label="Note"
        name="notes"
        tooltip="Add a note"
        id="notes"
        rows="8"
        columns="12"
        value={notes}
        onChange={e => {
          setNotes(e.target.value);
        }}
      />
    </form>
  );

  const footerContent = (
    <>
      <button type="submit" onClick={_ => saveWelcome()} className="btn">
        Save
      </button>
    </>
  );

  return (
    <ModalTemplate
      onClose={onClose}
      footerContent={footerContent}
      heading={`${type} Notes`}
    >
      {mainContent}
    </ModalTemplate>
  );
};

export default TaskReminderNotesModal;
