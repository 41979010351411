import React, { Fragment } from "reactn";
import {
  ParaIcon1,
  ParaIcon2,
  ParaIcon3,
  ParaIcon4,
  ParaIcon5
} from "../../../assets";
import { numToHours } from "../../../lib/util";
import { Redirect, Link } from "react-router-dom";
import RequestTourModal from "../../modals/RequestTourModal";
import DifferentPlanModal from "../../modals/DifferentPlanModal";
class CenterColumn extends React.PureComponent {
  state = {
    planModalOpen: false,
    selectedPlan: null,
    selectedCategory: null,
    requestTourModalOpen: false,
    differentPlanModal: false
  };
  render() {
    const vals = this.global.school;

    return (
      <div className="col-lg-8 col-lg-push-2 paragraphs" id="center-column">
        <div className="row margindown">
          <div className="para">
            <div className="flex full-width">
              <h3 style={{ textAlign: "left" }}>ABOUT OUR SCHOOL</h3>
              <img src={ParaIcon1} alt="" />
            </div>
            <p>{vals.about}</p>
          </div>
        </div>
        <div className="row margindown">
          <div className="para">
            <div className="flex full-width">
              <h3>OUR EDUCATORS</h3>
              <img src={ParaIcon2} alt="" />
            </div>
            <p>{vals.ourEducators}</p>
          </div>
        </div>

        <div className="row margindown">
          <div className="para">
            <div className="flex full-width">
              <h3>LOCATION</h3>
              <img src={ParaIcon3} alt="" />
            </div>
            <p>{vals.location}</p>
            {vals.programType === "Independent Program Unlicensed" ? (
              <p
                style={{ fontStyle: "italic", color: "#ababab" }}
                className="mt-3"
              >
                This childcare program is not licensed by the Government of
                Ontario.
              </p>
            ) : null}
          </div>
        </div>

        <div className="row margindown">
          <div className="para">
            <div className="flex full-width">
              <h3>ADMISSIONS</h3>
              <img src={ParaIcon4} alt="" />
            </div>
            <p>
              1) Prospective parents will meet the Program Director by booking a
              Scheduled Tour through our school’s Dreamschools website <br />
              <br />
              2) Once a parent has completed a tour of the school, Parents can
              complete an Enrollment Application through their Dreamschools’
              account <br />
              <br />
              3) When your child has been accepted, we’ll send you an acceptance
              and an enrollment package detailing your next steps.
              <br />
              <br />
              4) Secure your spot by submitting a deposit (usually equal to one
              billing day tuition) through your Dreamschools’ account.
            </p>
          </div>
        </div>

        <div className="row margindown">
          <div className="para pricing" id="pricing">
            <div className="flex full-width">
              <h3>TUITION</h3>
              <img src={ParaIcon5} alt="" />
            </div>
            <p
              style={{ fontStyle: "italic", color: "#ababab" }}
              className="mt-3"
            >
              Subsidized child care is not available for this program.
            </p>
            {this.global.school.pricingPlans
              ? this.global.school.pricingPlans
                  .sort((prev, next) => (prev.orderBy < next.orderBy ? -1 : 1))
                  .map((cat, i) => {
                    return cat.plans.length ? (
                      <Fragment key={`${i}-cat`}>
                        <h5
                          style={{
                            margin: 0,
                            marginTop: i > 0 ? "20px" : "40px",
                            marginBottom: "10px"
                          }}
                        >
                          {cat.name}
                        </h5>
                        <div>
                          {cat.plans
                            .sort((prev, next) =>
                              prev.dataOrder < next.dataOrder ? -1 : 1
                            )
                            .map((plan, j) => (
                              <Fragment key={`${j}-plan`}>
                                <p>
                                  {plan.numDays} days / week (
                                  {numToHours(plan.hoursFrom)} -{" "}
                                  {numToHours(plan.hoursTo)})
                                </p>
                                <button
                                  className="btn-link"
                                  style={{ margin: 0 }}
                                  onClick={e =>
                                    this.setState({
                                      planModalOpen: true,
                                      selectedPlan: plan,
                                      selectedCategory: cat
                                    })
                                  }
                                >
                                  See Pricing
                                </button>
                              </Fragment>
                            ))}
                        </div>
                      </Fragment>
                    ) : null;
                  })
              : null}
            <hr />
            <p style={{ textAlign: "center", width: "100%" }}>
              Want a different plan ?{" "}
              <Link
                to="#"
                onClick={() => this.setState({ differentPlanModal: true })}
              >
                Click here !
              </Link>
            </p>
          </div>
        </div>
        {this.state.differentPlanModal && (
          <DifferentPlanModal
            onClose={() => this.setState({ differentPlanModal: false })}
          />
        )}
        {!this.state.planModalOpen ? null : (
          <>
            {/*this.global.dsUser ? null : (
              <div className="request-modal-cont">
                <div className="request-modal">
                  <h3>Sign In / Register</h3>
                  <p className="text-center">
                    Please <Link to="/sign-in">Sign In</Link> or{" "}
                    <Link to="/register">Register</Link> to view pricing plans.
                  </p>
                  <div className="modal-footer">
                    <button
                      onClick={() =>
                        this.setState({
                          planModalOpen: false,
                          selectedPlan: null,
                          selectedCategory: null
                        })
                      }
                      className="backbtn btn profile-btn"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
                    )*/}

            {
              <div className="request-modal-cont">
                <div className="request-modal">
                  <h3>Pricing Plan</h3>
                  {/*<p>
                    <strong className="mr-2">Category:</strong>
                    {this.state.selectedCategory.name}
                  </p>
                  <p>
                    <strong className="mr-2">Days:</strong>
                    {this.state.selectedPlan.numDays} days / week
                  </p>
                  <p>
                    <strong className="mr-2">Timeframe:</strong>
                    {numToHours(this.state.selectedPlan.hoursFrom)} -{" "}
                    {numToHours(this.state.selectedPlan.hoursTo)}
                  </p>
                  <br />*/}

                  {/*<p>
                    <strong className="mr-2">Deposit Applied To:</strong>
                    Last {this.state.selectedPlan.depositDuration} days
                  </p>*/}
                  <p>
                    <strong className="mr-2">Enrollment (Recurring):</strong>$
                    {this.state.selectedPlan.amount} /{" "}
                    {this.state.selectedPlan.interval}
                  </p>
                  <p>
                    <span
                      className="mr-2"
                      style={{
                        fontStyle: "italic",
                        color: "rgb(171, 171, 171)",
                        fontSize: "12px"
                      }}
                    >
                      One time Deposit (applied to program end) : $
                      {this.state.selectedPlan.depositAmount}
                    </span>
                  </p>
                  <div className="modal-footer">
                    {vals.publishStage == 2 && (
                      <button
                        onClick={() =>
                          this.setState({
                            requestTourModalOpen: true
                          })
                        }
                        className="btn profile-btn"
                      >
                        Request a Tour
                      </button>
                    )}
                    <button
                      onClick={() =>
                        this.setState({
                          planModalOpen: false,
                          selectedPlan: null,
                          selectedCategory: null
                        })
                      }
                      className="backbtn btn profile-btn"
                    >
                      Close
                    </button>
                    {vals.publishStage == 2 && (
                      <button
                        onClick={() =>
                          this.global.dsUser &&
                          this.global.dsUser.accountType === "Parent"
                            ? this.setState({
                                redirect: {
                                  to: `/parents/interested-programs/${vals.slug}/choose-child`
                                }
                              })
                            : this.setState({
                                redirect: {
                                  to: `/sign-in?follow=/parents/interested-programs/${vals.slug}/choose-child`
                                }
                              })
                        }
                        className="btn profile-btn"
                      >
                        Enroll
                      </button>
                    )}
                  </div>
                </div>
              </div>
            }
            {this.state.requestTourModalOpen && (
              <RequestTourModal
                onClose={() => this.setState({ requestTourModalOpen: false })}
              />
            )}

            {this.state.redirect && <Redirect to={this.state.redirect.to} />}
          </>
        )}
      </div>
    );
  }
}

export default CenterColumn;
