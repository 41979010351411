import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { IconButton } from "@material-ui/core";
import { Close, Delete, Create as Edit } from "@material-ui/icons";
import "../../assets/css/componentSpecificCss/applicationViewModal.css";
import moment from "moment";
import {
  format,
  addWeeks,
  addDays,
  addHours,
  subDays,
  isAfter
} from "date-fns";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
// import { ReactPDF, PDFViewer , BlobProvider, PDFDownloadLink, pdf, Document, Page } from '@react-pdf/renderer';
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import SwitchEnrollmentModal from "./SwitchEnrollmentModal";
import ApplicationDenyNotificationModal from "./ApplicationDenyNotificationModal";
import CreateNewPartyAddon from "./CreateNewPartyAddon";

import {
  CONTENT_BLOCK,
  BUTTON,
  TEXT_AREA,
  IMAGE_CONTENT,
  DROPDOWN,
  GET_HTML
} from "./baseModal/FieldTypes";
import BaseModal from "./baseModal/BaseModal";
import ApplicationCancelModal from "./ApplicationCancelModal";
import {
  BLUE_BUTTON,
  GREEN_BUTTON,
  RED_BUTTON,
  WHITE,
  YELLOW_BUTTON
} from "./baseModal/colours";
import { result } from "lodash";

class ViewPartyAddonsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      partyDetails: props.partyDetails,
      addNewPartyAddon: false
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  savePartyAddons = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/parties/customers/update_party_addons/${this.props.applicationInfo.id}`;
    const result = await axios.post(ep, { partyDetails: this.state.partyDetails })

    console.log("RESULTTTTS", result)
  }

  async componentDidMount() {

  }

  getModal = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: 9
          }}
        >
          <div style={{ display: "block", width: "100%" }}>
            {this.state.partyDetails.addOnAmounts && Object.entries(this.state.partyDetails.addOnAmounts).map(([key, value], i) => {
              return (
                <>
                  <label
                    htmlFor=""
                    style={{
                      width: "40%",
                      textAlign: "left",
                      fontSize: "1.15rem",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "500",
                      color: "#626262",
                      marginTop: "1vh",
                    }}
                  >
                    {key}&nbsp;
                    <i
                      data-tip
                      data-for={`${key}_description`}
                      className="fas fa-info-circle"
                      style={{ color: "#DCDCDC" }}
                    ></i>
                    <ReactTooltip
                      id={`${key}_description`}
                      place="top"
                      effect="solid"
                      type="info"
                    >
                      {value.description}
                    </ReactTooltip>
                  </label>
                  <label
                    htmlFor=""
                    style={{
                      width: "30%",
                      textAlign: "right",
                      fontSize: "1.15rem",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "500",
                      color: "#626262",
                      marginTop: "1vh",
                    }}
                  >
                    + ${((value.price * 100) / 100).toFixed(2)} x&nbsp;
                  </label>
                  <div
                    key={i}
                    style={{
                      width: "30%",
                      display: "inline-flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start"
                    }}
                    name=""
                  >
                    <div
                      style={{
                        width: "90%",
                        display: "block",
                        alignSelf: "flex-start"
                      }}
                    >
                      <input
                        disabled={this.props.disabled}
                        style={{
                          height: "40px",
                          width: "100%",
                          fontSize: "0.93rem",
                          fontFamily: "futura-pt, sans-serif",
                          fontWeight: "300",
                          color: "#494949",
                          border: "1px solid #c5c5c5",
                          borderRadius: "4px",
                          paddingLeft: "8px"
                        }}
                        // name={domain.id}
                        // id={domain.id}
                        type="number"
                        min={0}
                        onChange={v => {
                          const newAddOnAmounts = { ...this.state.partyDetails.addOnAmounts }
                          newAddOnAmounts[key].amount = parseInt(v.target.value)
                          this.setState({
                            partyDetails: {
                              ...this.state.partyDetails,
                              addOnAmounts: newAddOnAmounts
                            }
                          })
                        }}
                        value={value.amount}
                      />
                    </div>
                    {/* {this.state.editMode && ( */}
                    <div
                      style={{
                        cursor: "pointer",
                        color: "#9BA7B2",
                        marginLeft: "20px"
                      }}
                      onClick={() => {

                      }}
                    >
                      {/* <Edit color="action" fontSize="large" /> */}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        {!this.props.disabled && (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "flex-start",
              marginBottom: 0
            }}
          >
            <button
              style={{
                backgroundColor: "#5cc8dc",
                color: WHITE,
                marginRight: 40
              }}
              className="button-field-button"
              onClick={e => {
                this.setState({
                  addNewPartyAddon: true
                })
              }}
              type={BUTTON}
            >
              {"+ Add"}
            </button>
          </div>
        )}
      </>
    )
  }

  getBaseModalFields() {
    const allFields = []

    const modal = {
      type: GET_HTML,
      data: {
        gethtml: this.getModal
      }
    };

    const editAddons = {
      type: BUTTON,
      data: {
        buttonText: "Edit",
        handleClick: _ => {

        },
        buttonColour: BLUE_BUTTON,
        textColour: WHITE
      }
    }

    allFields.push(modal)

    return allFields;
  }

  getBaseModalButtons() {
    const buttons = []

    const saveButton = {
      name: "Save",
      handleClick: async () => {
        await this.savePartyAddons()
        this.props.updatePartyDetails(this.state.partyDetails)
        await this.props.refetch()
        this.props.onClose()
      },
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
    }

    buttons.push(saveButton)

    if (this.props.disabled) {
      return []
    }

    return buttons;
  }

  getBaseModalProps() {
    const baseModalProps = {
      title: "Party Add-ons",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };

    return baseModalProps;
  }

  render() {
    if (!this.props) {
      return null;
    }
    console.log("this.state", this.state);
    console.log("this.props", this.props);

    if (this.state.addNewPartyAddon) {
      return (
        <CreateNewPartyAddon
          partyDetails={this.state.partyDetails}
          onClose={_ => {
            this.setState({
              addNewPartyAddon: false
            })
          }}
          savePartyAddon={(items) => {
            const newAddons = {}
            for (const item of items) {
              newAddons[item.name] = { price: item.price, description: item.description, amount: '0'}
            }
            this.setState({
              partyDetails: {
                ...this.state.partyDetails,
                addOnAmounts: {
                  ...this.state.partyDetails.addOnAmounts,
                  ...newAddons
                }
              }
            })
          }}
        />
      )
    }

    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(ViewPartyAddonsModal);
