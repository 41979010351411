import React from "reactn";
import {
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  withStyles
  // Chip
} from "@material-ui/core";
// import { Autocomplete } from "@material-ui/lab";
import {
  infants,
  toddlers,
  preschool,
  tags
} from "../../components/educator/ObservationsData";
import { CloudUpload } from "@material-ui/icons";
import Axios from "axios";
import { toast } from "react-toastify";
import { Select as MultiSelect } from "react-dropdown-select";
import { MiniPlus } from "../../assets";
import "../../assets/css/componentSpecificCss/observation.css";

const style = {
  formControl: {
    marginBottom: "10px",
    borderBottom: "none",
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: "white"
  },
  input: {
    display: "none"
  }
};

class Observations extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      domains: [],
      skills: [],
      category: {},
      customObservations: undefined,
      student: "",
      domain: "",
      skill: "",
      notes: "",
      image: undefined,
      tags: [],
      studentId: null,
      dsUserIds: "",
      childIds: "",
      kidsNamesTags: "",
      programs: [],
      selectedProgram: null,
      selectedStudents: [],
      gallery: []
    };
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Observations", to: "/observation" }
      ]
    });
  }
  async componentDidMount() {
    this.setGlobal({ loading: true });
    const ep2 = `${process.env.REACT_APP_API}/partners/programs/list`;
    const result2 = await Axios.get(ep2);

    if (result2.data.success) {
      this.setState({
        programs: result2.data.data.filter(e => !e.isArchived)
      });
    }

    const ep = `${process.env.REACT_APP_API}/partners/programs/child/customers/${this.global.dsUser.id}`;
    const result = await Axios.get(ep);

    if (result.data.success) {
      this.setState({
        students: result.data.data
      });
    }

    const ep1 = `${process.env.REACT_APP_API}/partner/observations/customization`;
    const results1 = await Axios.get(ep1);

    if (results1.data.success) {
      this.setState({
        customObservations: results1.data.data
      })
    }

    this.setGlobal({ loading: false });
  }

  // getCategory(studentId) {

  //   // const fetch = this.state.students.find(
  //   //   s => s.dsUserId === parseInt(student)
  //   // );
  //   const fetch =
  //     studentId == -1
  //       ? this.state.students[0]
  //       : this.state.students.filter(
  //           f => f.id == studentId && f.programId == this.state.selectedProgram
  //         )[0];

  //   const age = fetch.birthDate;
  //   const ageNum = Date.now() - new Date(age).getTime();
  //   const infantmax = 2 * 3600000 * 24 * 365;
  //   const toddlersmax = infantmax * 1.5;
  //   return ageNum <= infantmax
  //     ? infants
  //     : ageNum <= toddlersmax
  //     ? toddlers
  //     : preschool;
  // }

  getCategory(selectedStudents) {
    // const fetch = this.state.students.find(
    //   s => s.dsUserId === parseInt(student)
    // );
    const fetch =
      selectedStudents.length > 0 &&
      selectedStudents.filter(
        f => f.programId == this.state.selectedProgram
      )[0];

    const age = fetch.birthDate;
    const ageNum = Date.now() - new Date(age).getTime();
    const infantmax = 2 * 3600000 * 24 * 365;
    const toddlersmax = infantmax * 1.5;

    if (this.state.customObservations) {
      console.log("HERE", this.state.customObservations)
      const observations = { domainList: [], skills: [] }
      this.state.customObservations.domain.forEach((domain, index) => {
        observations.domainList.push(domain.name)
        observations.skills[domain.name] = this.state.customObservations.skills[domain.id]
      })
      console.log("HERE", observations)
      return observations
    }

    return ageNum <= infantmax
      ? infants
      : ageNum <= toddlersmax
        ? toddlers
        : preschool;
  }

  /**
   * This function populates the dsUserId, childId and the programId
   * @param {integer || -1} studentId
   */
  // saveChildId(studentId) {

  //   const dsUserId =
  //     studentId == -1
  //       ? this.state.students
  //           .filter(f => f.programId == this.state.selectedProgram)
  //           .map(e => e.dsUserId)
  //           .join(",")
  //       : `${
  //           this.state.students.filter(
  //             f =>
  //               f.id == studentId && f.programId == this.state.selectedProgram
  //           )[0].dsUserId
  //         }`;
  //   const childId =
  //     studentId == -1
  //       ? this.state.students
  //           .filter(f => f.programId == this.state.selectedProgram)
  //           .map(e => e.childId !== null && e.childId)
  //           .join(",")
  //       : `${
  //           this.state.students.filter(
  //             f =>
  //               f.id == studentId && f.programId == this.state.selectedProgram
  //           )[0].childId
  //         }`;

  //   const kidsNames =
  //     studentId == -1
  //       ? this.state.students
  //           .filter(f => f.programId == this.state.selectedProgram)
  //           .map(e => e.fullName)
  //           .join(",")
  //       : this.state.students.filter(
  //           f => f.id == studentId && f.programId == this.state.selectedProgram
  //         )[0].fullName;

  //   this.setState({
  //     dsUserIds: dsUserId,
  //     childIds: childId,
  //     kidsNamesTags: kidsNames
  //   });
  //   return;
  // }

  /**
   * This function populates the dsUserId, childId and the programId
   * @param {Array{}} selectedStudents
   */
  saveChildId(selectedStudents) {
    const dsUserId = selectedStudents
      .filter(f => f.programId == this.state.selectedProgram)
      .map(e => e.dsUserId)
      .join(",");
    const childId = selectedStudents
      .filter(f => f.programId == this.state.selectedProgram)
      .map(e => e.childId !== null && e.childId)
      .join(",");

    const kidsNames = selectedStudents
      .filter(f => f.programId == this.state.selectedProgram)
      .map(e => e.fullName)
      .join(",");

    this.setState({
      dsUserIds: dsUserId,
      childIds: childId,
      kidsNamesTags: kidsNames
    });
    return;
  }

  async submitObservation(e) {
    e.preventDefault();
    const ep = `${process.env.REACT_APP_API}/partner/observations`;
    this.setGlobal({
      loading: true
    });
    const formData = new FormData();
    const {
      skill,
      domain,
      notes,
      student,
      tags,
      image,
      gallery,
      dsUserIds,
      childIds,
      kidsNamesTags,
      selectedProgram
    } = this.state;
    const finalObject = {
      skill,
      domain,
      notes,
      users: dsUserIds.includes(",")
        ? "{" +
        dsUserIds
          .split(",")
          .map(e => `"${e}":true`)
          .join(",") +
        "}"
        : JSON.stringify({ [dsUserIds]: true }),
      kids: childIds.includes(",")
        ? "{" +
        childIds
          .split(",")
          .map(e => `"${e}":true`)
          .join(",") +
        "}"
        : JSON.stringify({ [childIds]: true }),
      // tags: tags.map(e => e.name),
      kidsNamesTags,
      gallery,
      partnerId: this.global.dsUser.id,
      programId: selectedProgram
    };

    for (let key in finalObject) {
      formData.append(key, finalObject[key]);
    }

    // , {
    //   headers: { "Content-Type": "multipart/form-data" }
    // }
    const result = await Axios.post(ep, finalObject);

    this.setGlobal({
      loading: false
    });
    if (result.data.success) {
      toast.success(`Observation Posted!`);
      this.setState({
        domains: [],
        skills: [],
        category: {},
        student: "",
        domain: "",
        skill: "",
        notes: "",
        image: "",
        tags: [],
        studentIndex: null,
        dsUserIds: "",
        childIds: "",
        kidsNamesTags: "",
        selectedProgram: null,
        gallery: []
      });
    }
  }

  /**
   * This function handles the edit button functionality of a sub user
   * returns a boolean value
   */
  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return this.global.dsUser.accesspoints.Manage.Observation.edit
        ? false
        : true;
    }
  };

  uploadNew() {
    const uploadEle = document.getElementById("uploadPhoto");
    uploadEle.click();
  }

  fileChange(e) {
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      const fd = new FormData();
      fd.set("type", "gallery");
      fd.set("image", e.target.files[0]);
      this.uploadObservationPhoto(fd);
    }
  }

  async uploadObservationPhoto(fd) {
    this.setGlobal({
      loading: true
    });

    const ep = `${process.env.REACT_APP_API}/partner/observations/photo`;
    const res = await Axios.post(ep, fd);

    this.setState({ gallery: [...this.state.gallery, res.data.data.gallery] });
    this.setGlobal({
      loading: false
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="container-fluid enrolled">
        <div className="cont">
          <h1>Observations</h1>
        </div>
        <div
          className="cont"
          style={{
            margin: "0 auto",
            paddingTop: "10px",
            borderRadius: "10px",
            overflow: "auto"
          }}
        >
          <form onSubmit={e => this.submitObservation(e)}>
            <FormControl
              variant="filled"
              fullWidth
              className={classes.formControl}
            >
              {/* <InputLabel id="demo-simple-select-outlined-label">
                Select a Program
              </InputLabel> */}
              <Select
                native
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                fullWidth
                disabled={this.state.programs.length > 0 ? false : true}
                // value={this.state.student}
                style={{ backgroundColor: "#FFF" }}
                onChange={e => {
                  this.setState({
                    selectedProgram: e.target.value
                  });
                }}
              >
                <option value={null}>Select a Program</option>
                {/* value={this.state.students.map(e => e.dsUserId).join(",")} */}
                {this.state.programs.map((e, i) => (
                  <option value={e.id} key={e.id}>
                    {e.program_name}
                  </option>
                ))}
              </Select>
            </FormControl>
            {/* value={this.state.students.map(e => e.dsUserId).join(",")} */}

            <FormControl
              variant="filled"
              fullWidth
              className={classes.formControl}
            >
              {/* <InputLabel id="demo-simple-select-outlined-label">
                Choose a Student
              </InputLabel> */}
              {/* <Select
                multiple
                native
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                fullWidth
                disabled={this.state.selectedProgram !== null ? false : true}
                // value={this.state.student}
                style={{ backgroundColor: "#FFF" }}
                onChange={e => {

                  this.saveChildId(e.target.value);

                  this.setState({
                    studentId: e.target.value,
                    domains: this.getCategory(e.target.value).domainList,
                    category: this.getCategory(e.target.value)
                  });
                }}
              >
                <option value="" disabled selected>
                  Select a Student 
                </option>
                {this.state.students.filter(
                  f => f.programId == this.state.selectedProgram
                ).length > 0 && <option value={-1}>All Students</option>}
                {this.state.students
                  .filter(f => f.programId == this.state.selectedProgram)
                  .map((e, i) => (
                    <option value={e.id} key={e.id}>
                      {e.fullName}
                    </option>
                  ))}
              </Select> */}
              <div className={"w-100"}>
                <MultiSelect
                  className="observation-select"
                  placeholder="Select Participant"
                  multi={true}
                  searchable={true}
                  options={this.state.students.filter(
                    f => f.programId == this.state.selectedProgram
                  )}
                  // onDropdownOpen={props.open}
                  values={this.state.selectedStudents}
                  name={"selectedStudents"}
                  style={{ background: "white", top: "0px" }}
                  onChange={e => {
                    this.saveChildId(e);

                    this.setState({
                      selectedStudents: e,
                      domains: this.getCategory(e).domainList,
                      category: this.getCategory(e)
                    });
                  }}
                  labelField={"fullName"}
                  valueField={"id"}
                  disabled={this.state.selectedProgram !== null ? false : true}
                  closeOnSelect={true}
                />
              </div>
            </FormControl>
            <FormControl
              variant="filled"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                {this.state.customObservations?.classification1 || "Domain"}
              </InputLabel>
              <Select
                native
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                fullWidth
                disabled={
                  this.state.selectedStudents.length === 0 ? true : false
                }
                value={this.state.domain}
                style={{ backgroundColor: "#FFF" }}
                onChange={e =>
                  this.setState({
                    domain: e.target.value,
                    skills: this.state.category.skills[e.target.value]
                  })
                }
              >
                <option value="" disabled hidden></option>
                {this.state.domains.map((e, i) => (
                  <option value={e} key={i}>
                    {e}
                  </option>
                ))}
              </Select>
            </FormControl>

            {this.state.customObservations?.classification2 !== '' && (
              <FormControl
                variant="filled"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  {this.state.customObservations?.classification2 || "Skills"}
                </InputLabel>
                <Select
                  native
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  fullWidth
                  disabled={!this.state.domain}
                  style={{ backgroundColor: "#FFF" }}
                  onChange={e =>
                    this.setState({
                      skill: e.target.value
                    })
                  }
                >
                  <option value="" selected disabled hidden></option>
                  {this.state.skills.map((e, i) => (
                    <option value={e} key={i}>
                      {e}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormControl
              variant="filled"
              fullWidth
              className={classes.formControl}
            >
              <TextField
                rows={6}
                multiline
                variant="outlined"
                placeholder="Notes"
                disabled={
                  this.state.selectedStudents.length === 0 ? true : false
                }
                style={{
                  backgroundColor: "#FFF",
                  borderBottom: "1px solid black"
                }}
                value={this.state.notes}
                onChange={e =>
                  this.setState({
                    notes: e.target.value
                  })
                }
              ></TextField>
            </FormControl>
            <div
              className="col-12 d-flex"
              style={{ flexWrap: "wrap", paddingLeft: "0px" }}
            >
              {/* Display Section Here */}
              {this.state.gallery.map((item, index) => {
                return (
                  <figure
                    className="photoContainer observation-gallery-photo-width"
                    style={{
                      // width: "23%",
                      textAlign: "center",
                      // marginLeft: "6px",
                      height: "80px"
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        padding: "2px",
                        width: "80px",
                        height: "80px",
                        border: "1px solid #e3e3e3",
                        display: "inline-block",
                        borderRadius: "8px",
                        cursor: "pointer",
                        backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${item})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat"
                      }}
                      id={index}
                    >
                      <div
                        className="photoContainerX"
                        onClick={_ =>
                          this.setState({
                            gallery: this.state.gallery.filter(
                              (_, i) => i !== index
                            )
                          })
                        }
                      >
                        <div className="innerX">
                          <i className="far fa-times-circle"></i>
                        </div>
                      </div>
                    </div>
                  </figure>
                );
              })}
              {new Array(4 - this.state.gallery.length).fill(4).map((e, i) => (
                <figure
                  className="photoContainer observation-gallery-photo-width"
                  style={{
                    // width: "16%",
                    textAlign: "center"
                    // marginLeft: "6px"
                  }}
                  onClick={this.uploadNew}
                  key={i}
                >
                  <div
                    style={{
                      padding: "2px",
                      width: "80px",
                      height: "80px",
                      border: "1px solid #e3e3e3",
                      display: "inline-block",
                      borderRadius: "8px",
                      cursor: "pointer",
                      background: "white"
                    }}
                  >
                    <img
                      src={MiniPlus}
                      alt="Add"
                      style={{ marginTop: "calc(50% - 4.5px)" }}
                    />
                  </div>
                </figure>
              ))}
            </div>
            <input
              type="file"
              style={{ display: "none" }}
              id="uploadPhoto"
              onChange={e => this.fileChange(e)}
              accept="image/x-png,image/gif,image/jpeg"
            />

            {/* <FormControl fullWidth>
              {this.state.image && (
                <p>1 attachment : {this.state.image.name}</p>
              )}
            </FormControl> */}
            {/* <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              onChange={e =>
                this.setState({
                  image: e.target.files[0]
                })
              }
            /> */}
            {/* <label
              htmlFor="contained-button-file"
              style={{ marginLeft: "10px", float: "right" }}
            >
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<CloudUpload />}
                style={{
                  fontSize: "0.8rem",
                  cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                }}
                disabled={this.canSubUserEdit()}
              >
                Upload an Image
              </Button>
            </label> */}
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              style={{
                float: "right",
                cursor: this.canSubUserEdit() ? "not-allowed" : "pointer",
                fontWeight: 700,
                borderRadius: "8px",
                color: "#fff",
                background: "#5cc8dc",
                border: "1px solid #5cc8dc",
                boxShadow: "2px 2px 5px 2px #cdcdcd"
              }}
              disabled={this.canSubUserEdit() && !this.state.notes}
            >
              POST
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default withStyles(style)(Observations);
