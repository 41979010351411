import React from "reactn";
import Tables from "../../components/table";
import { Button } from "@material-ui/core";
import CreditsModal from "../../components/modals/CreditsModal";
import { useState } from "react";
import { useEffect } from "react";
import Axios from "axios";
const Credits = () => {
  const [open, setOpen] = useState(false);
  const [credits, setCredits] = useState([]);
  useEffect(async () => {
    const ep = `${process.env.REACT_APP_API}/adm/finances/credits`;
    const results = await Axios.get(ep);
    setCredits(results.data.data);
  }, []);
  return (
    <div className="container-fluid">
      {open && (
        <CreditsModal
          setOpen={_ => setOpen(false)}
          addCredit={credit => setCredits([credit, ...credits])}
        />
      )}
      <div className="cont">
        <h1 style={{ display: "flex" }}>
          <h1 style={{ flexGrow: 1 }}>Credits </h1>
          <Button
            variant="filled"
            size="small"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Add Credit!
          </Button>
        </h1>
      </div>
      <div className="cont">
        <div className="row">
          <div className="col-12">
            <Tables.Admins.CreditsTable data={credits} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credits;
