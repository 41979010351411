import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import { Close } from "@material-ui/icons";
import { MiniPlus } from "../../../../assets";
import Geosuggest from "react-geosuggest";

class SectionTwo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { days: {} };
  }
  handleChange = (e, v) => {
    this.setGlobal({
      membership: {
        ...this.global.membership,
        [e]: v
      }
    });
  };

  membershipUpdate(e, key) {
    const tickets = this.global.membership.prices.map(e =>
      typeof e === "string" ? JSON.parse(e) : e
    );

    tickets[key][e.target.name] = e.target.value;
    tickets[key].id = key;
    this.handleChange("prices", tickets);
  }

  setDay(day) {
    const days = { ...this.state.days };
    if (days[day]) delete days[day];
    else days[day] = true;
    this.setState({
      days
    });
  }
  customForm = () => {
    return (
      <div className="formbody">
        <form style={{ textAlign: "center" }}>
          {this.global.membership.prices.length ? (
            this.global.membership.prices.map((ele, key) => {
              const e = typeof ele === "string" ? JSON.parse(ele) : ele;
              return (
                <>
                  <div className="row" style={{ alignItems: "center" }}>
                    <div className="col-5">
                      <h3 style={{ textAlign: "left" }}>
                        Membership Term<span className="requiredField">*</span>
                      </h3>
                    </div>
                    <div className="col-7 d-flex">
                      <formTypes.Select
                        options={[
                          { value: "", label: "Please Select" },
                          { value: "1", label: "1 Month" },
                          { value: "2", label: "2 Months" },
                          { value: "3", label: "3 Months" }
                        ]}
                        value={e.membership_term}
                        onChange={e =>
                          this.membershipUpdate(
                            {
                              target: {
                                name: "membership_term",
                                value: e.target.value
                              }
                            },
                            key
                          )
                        }
                      ></formTypes.Select>
                    </div>
                  </div>
                  <div className="row" style={{ alignItems: "center" }}>
                    <div className="col-5">
                      <h3 style={{ textAlign: "left" }}>Pricing for term *</h3>
                    </div>
                    <div className="col-7 d-flex">
                      <input
                        type="number"
                        name="price_per_term"
                        value={e.price_per_term}
                        onChange={e => this.membershipUpdate(e, key)}
                      />
                    </div>
                  </div>
                  <div className="row" style={{ alignItems: "center" }}>
                    <div className="col-5">
                      <h3 style={{ textAlign: "left" }}>
                        Auto-Renew <br />{" "}
                      </h3>
                    </div>
                    <div className="col-7 d-flex">
                      <input
                        type="checkbox"
                        name="auto_renew"
                        checked={e.auto_renew}
                        onChange={e =>
                          this.membershipUpdate(
                            {
                              target: {
                                name: e.target.name,
                                value: e.target.checked
                              }
                            },
                            key
                          )
                        }
                      />
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <>
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-5">
                  <h3 style={{ textAlign: "left" }}>
                    Membership Term<span className="requiredField">*</span>
                  </h3>
                </div>
                <div className="col-7 d-flex">
                  <formTypes.Select
                    options={[
                      { value: "", label: "Please Select" },
                      { value: "1", label: "1 Month" },
                      { value: "2", label: "2 Months" },
                      { value: "3", label: "3 Months" }
                    ]}
                    value={""}
                    onChange={e =>
                      this.handleChange("prices", [
                        {
                          membership_term: e.target.value
                        }
                      ])
                    }
                  ></formTypes.Select>
                </div>
              </div>
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-5">
                  <h3 style={{ textAlign: "left" }}>Pricing for term *</h3>
                </div>
                <div className="col-7 d-flex">
                  <input type="number" name="price_per_term" value={""} />
                </div>
              </div>
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-5">
                  <h3 style={{ textAlign: "left" }}>
                    Auto-Renew <br />{" "}
                  </h3>
                </div>
                <div className="col-7 d-flex">
                  <input type="checkbox" name="auto_renew" />
                </div>
              </div>
            </>
          )}

          <button
            type="button"
            className="newapplications"
            style={{
              border: "1px solid #7ea1ff",
              padding: "4px 6px",
              color: "#7ea1ff",
              backgroundColor: "#FFF",
              borderRadius: "15px",
              marginRight: "10px",
              fontSize: "13px",
              fontWeight: "700",
              fontFamily: "Open Sans"
            }}
            onClick={_ =>
              this.handleChange("prices", [
                ...this.global.membership.prices,
                {}
              ])
            }
          >
            Add Price
          </button>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Additional"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionTwo;
