import { setGlobal, addReducer } from "reactn";
import { startOfWeek, endOfWeek } from "date-fns";
import axios from "axios";

const today = new Date();

const profile = {
  id: null,
  name: "",
  company: "",
  postalcode: "",
  email: "",
  password: "",
  phone: "",
  address1: "",
  address2: "",
  city: "",
  plan_type: "",
  amount: null,
  number_franchises: null,
  trial: null,
  customer_id: null,
  notes: "",
  franchises: []
};

setGlobal({
  organizationInfo: profile
});
