import React, { useGlobal, useEffect, useState } from "reactn";
import "../../../assets/css/componentSpecificCss/reportAnalysisPages.css";
import { format, parse, fromUnixTime, addDays } from "date-fns";
import _ from "lodash";
import axios from "axios";

import { useDateFilter, CENTS_IN_DOLLAR } from "../../../providers/reports";
import {
  FilterButton,
  DateFilter,
  ReportContainer,
  ReportTable
} from "../../../components/UI/reportsAnalytics";

import {
  showPlan,
  getPlusMinus,
  getSubtotal,
  formatMoney
} from "../../../utilities/reportsAnalytics";

const InvoiceSummary = () => {
  const orgData = useGlobal("organizationInfo")[0];
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");

  const [dateFrom, dateTo, setDateFrom, setDateTo] = useDateFilter();
  const [statusFilter, setStatusFilter] = useState([{ listingId: 0, item: "Paid" }, { listingId: 1, item: "Pending" }]);

  const [paidOrders, setPaidOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [displayPaidOrders, setDisplayPaidOrders] = useState([]);
  const [displayPendingOrders, setDisplayPendingOrders] = useState([]);
  const [totalInvoiceRevenue, setTotalInvoiceRevenue] = useState(0);

  const [totalRefunds, setTotalRefunds] = useState(0);

  useEffect(() => {
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" },
      { label: "Invoice Summary", to: "/reports/invoice-summary" }
    ]);
    fetchInitialData();
  }, []);

  useEffect(() => {
    setDisplayPaidOrders(getPaidDisplayableData(paidOrders));
    setDisplayPendingOrders(getPendingDisplayableData(pendingOrders));
    setTotalInvoiceRevenue(getTotalInvoiceRevenue(paidOrders, pendingOrders));
    setTotalRefunds(
      formatMoney(
        paidOrders.filter(
          e =>
            new Date(e.userPaidDate) > new Date(dateFrom) &&
            new Date(e.userPaidDate) < addDays(new Date(dateTo), 1) &&
            e.type === "Invoice" && !e.cartOrder
        ).reduce(
          (prev, curr) => prev + curr.refundedAmount ? parseInt(curr.refundedAmount) : 0,
          0
        ) / 100
      )
    );
  }, [dateFrom, dateTo, statusFilter]);

  const fetchInitialData = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/order-tracking`;

    const result = await axios.get(ep);

    const paidOrders = [
      ...result.data.data.Once.payments,
      ...result.data.data.Recurring.payments,
    ].filter(order => order.type === "Invoice" && !order.cartOrder);

    const pendingOrders = [
      ...result.data.data.Once.pending,
      ...result.data.data.Recurring.pending
    ].filter(order => order.type === "Invoice" && !order.cartOrder);

    setPaidOrders(paidOrders);
    setPendingOrders(pendingOrders)

    setDisplayPaidOrders(getPaidDisplayableData(paidOrders));
    setDisplayPendingOrders(getPendingDisplayableData(pendingOrders));
    setTotalInvoiceRevenue(getTotalInvoiceRevenue(paidOrders, pendingOrders));

    setTotalRefunds(
      formatMoney(
        paidOrders.filter(
          e =>
            new Date(e.userPaidDate) > new Date(dateFrom) &&
            new Date(e.userPaidDate) < addDays(new Date(dateTo), 1) &&
            e.type === "Invoice" && !e.cartOrder
        ).reduce(
          (prev, curr) => prev + curr.refundedAmount ? parseInt(curr.refundedAmount) : 0,
          0
        ) / 100
      )
    );
  };

  const cleanData = orders => {
    return orders.map(o => ({
      customer: o.purchaser,
      createdDate: o.createdDate,
      dueDate: o.dueDate,
      userPaidDate: o.userPaidDate,
      listing: o.item,
      modifiers: getModifierString(o),
      total: o.total
    }));
  };

  const getPaidDisplayableData = orders => {
    const dataWithinRange = orders.filter(
      e =>
        new Date(e.userPaidDate) > new Date(dateFrom) &&
        new Date(e.userPaidDate) < addDays(new Date(dateTo), 1) &&
        e.type === "Invoice" && !e.cartOrder
    );
    const sortedData = dataWithinRange.sort(
      (a, b) => new Date(b.userPaidDate) - new Date(a.userPaidDate)
    );
    const cleanedData = cleanData(sortedData);
    const formatDateTotal = cleanedData.map(o => ({
      ...o,
      dueDate: format(new Date(o.dueDate), "MMM d, yyyy"),
      createdDate: format(new Date(o.createdDate), "MMM d, yyyy"),
      userPaidDate: format(new Date(o.userPaidDate), "MMM d, yyyy"),
      total: formatMoney(o.total / 100)
    }));
    return formatData(formatDateTotal);
  };

  const getPendingDisplayableData = orders => {
    const dataWithinRange = orders.filter(
      e =>
        new Date(e.dueDate) > new Date(dateFrom) &&
        new Date(e.dueDate) < addDays(new Date(dateTo), 1) &&
        e.type === "Invoice" && !e.cartOrder
    );
    const sortedData = dataWithinRange.sort(
      (a, b) => new Date(b.dueDate) - new Date(a.dueDate)
    );
    const cleanedData = cleanData(sortedData);
    const formatDateTotal = cleanedData.map(o => ({
      ...o,
      dueDate: format(new Date(o.dueDate), "MMM d, yyyy"),
      createdDate: format(new Date(o.createdDate), "MMM d, yyyy"),
      userPaidDate: "",
      total: formatMoney(o.total / 100)
    }));
    return formatData(formatDateTotal);
  };

  const formatData = orders =>
    orders.map(o => [o.customer, o.createdDate, o.dueDate, o.userPaidDate, o.listing, o.modifiers, o.total]);

  const getModifierString = order => {
    // modifiers are: tax, credit card fee, promotion, coupon, addOnSemesterItemAmount, gift discount, discount amount, refund
    if (order.modifiers) {
      return Object.keys(order.modifiers)
        .map(modifier =>
          parseInt(order.modifiers[modifier])
            ? `${getModifierName(modifier)} (${formatMoney(
              order.modifiers[modifier] / 100
            )})`
            : ""
        )
        .filter(e => e !== "")
        .join(" / ");
    }
    return []
  };

  // tax -> Tax
  // creditCardFee -> Credit Card Fee
  // promotion -> Promotion
  // coupon -> Coupon
  // addOnSemesterItem -> Add On Semester Item
  // giftDiscount -> Gift Discount
  // discount -> Discount
  // refunded -> Refunded
  const getModifierName = modifier => {
    switch (modifier) {
      case "tax":
        return "Tax";
      case "creditCardFee":
        return "Credit Card Fee";
      case "promotion":
        return "Promotion";
      case "coupon":
        return "Coupon";
      case "addOnSemesterItem":
        return "Add On Semester Item";
      case "giftDiscount":
        return "Gift Discount";
      case "discount":
        return "Discount";
      case "refunded":
        return "Refunded";
      default:
        return "";
    }
  };

  const getRevenueChange = orders => {
    let revenueChange = 0;
    orders.forEach(order => {
      revenueChange += parseInt(order.modifiers.tax) || 0;
      revenueChange += parseInt(order.modifiers.creditCardFee) || 0;
      revenueChange -= parseInt(order.modifiers.promotion) || 0;
      revenueChange -= parseInt(order.modifiers.coupon) || 0;
      revenueChange += parseInt(order.modifiers.addOnSemesterItem) || 0;
      revenueChange -= parseInt(order.modifiers.giftDiscount) || 0;
      revenueChange -= parseInt(order.modifiers.discount) || 0;
      revenueChange -= parseInt(order.modifiers.refunded) || 0;
    });

    return revenueChange;
  };

  /**
   * Gets total revenue by aggregating all order data.
   *
   * @returns {Number} Total revenue.
   */

  const getTotalInvoiceRevenue = (paidOrders, pendingOrders) => {
    console.log("DATES: ", new Date(dateFrom), new Date(dateTo));
    const paidInvoiceRevenue = statusFilter.find(item => item.item === "Paid") ? paidOrders
      .filter(
        e =>
          e.type === "Invoice" && !e.cartOrder &&
          new Date(e.userPaidDate) >= new Date(dateFrom) &&
          new Date(e.userPaidDate) <= addDays(new Date(dateTo), 1)
      )
      .reduce((prev, curr) => prev + parseInt(curr.total), 0) : 0;

    const pendingInvoiceRevenue = statusFilter.find(item => item.item === "Pending") ? pendingOrders
      .filter(
        e =>
          e.type === "Invoice" && !e.cartOrder &&
          new Date(e.dueDate) >= new Date(dateFrom) &&
          new Date(e.dueDate) <= addDays(new Date(dateTo), 1)
      )
      .reduce((prev, curr) => prev + parseInt(curr.total), 0) : 0;

    return formatMoney((paidInvoiceRevenue + pendingInvoiceRevenue) / CENTS_IN_DOLLAR);
  };

  const csvHeaders = [
    { label: "Customer Name", key: "name" },
    { label: "Invoice Created Date", key: "createdDate" },
    { label: "Due Date", key: "dueDate" },
    { label: "User Paid Date", key: "paidDate" },
    { label: "Listing", key: "listingName" },
    { label: "Promo", key: "promo" },
    { label: "Discount", key: "discount" },
    { label: "Gift Discount", key: "giftDiscount" },
    { label: "Coupon", key: "coupon" },
    { label: "Refunds", key: "refund" },
    { label: "Tax", key: "tax" },
    { label: "Credit Card Fee", key: "creditCardFee" },
    { label: "Add On Semester Item", key: "addOnSemesterItem" },
    { label: "Amount", key: "amount" },
    { label: "Payment Status", key: "status" }
  ];

  /**
   * Gets the CSV for the CSVLink component to use.
   *
   * @returns {Array<Object>} Each property of each object corresponds to a column.
   */
  const getCsvData = () => {
    const paidDataWithinRange = paidOrders
      .filter(
        e =>
          new Date(e.userPaidDate) > new Date(dateFrom) &&
          new Date(e.userPaidDate) < addDays(new Date(dateTo), 1)
      )
      .sort((a, b) => new Date(b.userPaidDate) - new Date(a.userPaidDate));
    const pendingDataWithinRange = pendingOrders
      .filter(
        e =>
          new Date(e.dueDate) > new Date(dateFrom) &&
          new Date(e.dueDate) < addDays(new Date(dateTo), 1)
      )
      .sort((a, b) => new Date(b.userPaidDate) - new Date(a.userPaidDate));
    return [
      ...paidDataWithinRange.map(row => ({
        name: row.purchaser,
        createdDate: row.createdDate,
        dueDate: row.dueDate,
        paidDate: row.userPaidDate,
        listingName: row.item,
        discount: row.modifiers?.discount || 0,
        giftDiscount: row.modifiers?.giftDiscount || 0,
        promo: row.modifiers?.promotion || 0,
        coupon: row.modifiers?.coupon || 0,
        refund: row.modifiers?.refunded || 0,
        tax: row.modifiers?.tax || 0,
        creditCardFee: row.modifiers?.creditCardFee || 0,
        addOnSemesterItem: row.modifiers?.addOnSemesterItem || 0,
        amount: row.total,
        status: "Paid"
      })),
      ...pendingDataWithinRange.map(row => ({
        name: row.purchaser,
        createdDate: row.createdDate,
        dueDate: row.dueDate,
        paidDate: "",
        listingName: row.item,
        discount: row.modifiers?.discount || 0,
        giftDiscount: row.modifiers?.giftDiscount || 0,
        promo: row.modifiers?.promotion || 0,
        coupon: row.modifiers?.coupon || 0,
        refund: row.modifiers?.refunded || 0,
        tax: row.modifiers?.tax || 0,
        creditCardFee: row.modifiers?.creditCardFee || 0,
        addOnSemesterItem: row.modifiers?.addOnSemesterItem || 0,
        amount: row.total,
        status: "Pending"
      }))
    ];
  };

  const reportSubtitles = [
    orgData.organization_title,
    `For ${format(parse(dateFrom, "yyyy-MM-dd", new Date()), "MMM d, yyyy")}
    - 
    ${format(parse(dateTo, "yyyy-MM-dd", new Date()), "MMM d, yyyy")} 
    `
  ];

  return (
    <div className="admin">
      <div className="container-fluid adminprofiles">
        <div className="row cont">
          <div className="col-md-6">
            <h1>Invoice Summary</h1>
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-section">
            <DateFilter
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
            />
            <FilterButton
              label="Status"
              checkedOptions={statusFilter}
              setCheckedOptions={setStatusFilter}
              allOptions={[{ listingId: 0, item: "Paid" }, { listingId: 1, item: "Pending" }]}
              stringProperty="item"
            />
          </div>
        </div>
        <ReportContainer
          title="Invoice Summary"
          subtitles={reportSubtitles}
          csvHeaders={csvHeaders}
          csvData={getCsvData()}
          fileName={`Transaction_${dateFrom}-${dateTo}.csv`}
          isLarge
        >
          <div className="report-table-large">
            <ReportTable
              headings={[
                { label: "Summary", className: "table-item-bold" },
                { label: "", className: "table-item-money" }
              ]}
              body={[
                ["Total Invoice Revenue", totalInvoiceRevenue],
                ["Total Refunds", totalRefunds]
              ]}
            />
            {statusFilter.find(item => item.item === "Paid") && (
              <>
                <h5 style={{
                  color: "#120fa8",
                  fontSize: 25,
                  marginTop: 40,
                  marginRight: 30,
                  marginLeft: 30,
                }}>Paid</h5>
                <ReportTable
                  headings={[
                    { label: "Customer" },
                    { label: "Created Date" },
                    { label: "Due Date" },
                    { label: "Paid Date" },
                    { label: "Item" },
                    { label: "+ / -" },
                    {
                      label: "Total",
                      className: "table-item-money",
                      style: { textAlign: "right" }
                    }
                  ]}
                  // body={getTransactionData()}
                  body={displayPaidOrders}
                />
              </>

            )}
            {statusFilter.find(item => item.item === "Pending") && (
              <>
                <h5 style={{
                  color: "#120fa8",
                  fontSize: 25,
                  marginTop: 40,
                  marginRight: 30,
                  marginLeft: 30,
                }}>Pending</h5>
                <ReportTable
                  headings={[
                    { label: "Customer" },
                    { label: "Created Date" },
                    { label: "Due Date" },
                    { label: "Paid Date" },
                    { label: "Item" },
                    { label: "+ / -" },
                    {
                      label: "Total",
                      className: "table-item-money",
                      style: { textAlign: "right" }
                    }
                  ]}
                  // body={getTransactionData()}
                  body={displayPendingOrders}
                />
              </>
            )}
          </div>
        </ReportContainer>
      </div>
    </div>
  );
};

export default InvoiceSummary;