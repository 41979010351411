import React from "reactn";
import axios from "axios";

import { NewTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
import { toast } from "react-toastify";
import CreateRoomsModal from "../../components/modals/CreateRoomsModal";
import { Delete } from "@material-ui/icons";
import AssignRoomsModal from "../../components/modals/AssignRoomsModal";
import DeleteRoomModal from "../../components/modals/DeleteRoomModal";
import EditIcon from "@material-ui/icons/Edit";
import UpdateRoomsModal from "../../components/modals/UpdateRoomsModal";
import ReassignRoomsModal from "../../components/modals/ReassignRoomsModal";
import { format } from "date-fns";

class Rooms extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      isUploadChecked: false,
      isReminderChecked: false,
      openCreateRoomsModal: false,
      isActiveChecked: false,
      showDeleteModal: false,
      selectedRoomToDelete: {},
      openAssignRoomsModal: false,
      seletedUser: {},
      updateRoomModal: false,
      updateRoom: null,
      reassginRoomModal: false
    };

    const toggleRoomActive = async room => {
      const ep = `${process.env.REACT_APP_API}/partners/rooms/toggle/`;
      const resp = await axios.post(ep, { room });

      const newRooms = this.state.data.Rooms.map(aRoom => {
        if (room.id === aRoom.id) {
          const is_active = !aRoom.is_active;
          return { ...aRoom, is_active };
        } else {
          return { ...aRoom };
        }
      });

      this.setState({
        data: {
          "Assigned Rooms": this.state.data["Assigned Rooms"],
          Rooms: newRooms
        }
      });
    };

    this.tabs = ["Assigned Rooms", "Rooms"];
    this.headings = {
      "Assigned Rooms": [
        {
          id: "user_name",
          label: "User's Name",
          customCell: (r, i) => {
            return <td key={i}>{r.childId ? r.childName : r.displayName}</td>;
          }
        },
        {
          id: "plan",
          label: "Plan (Dates & Times)",
          customCell: (r, i) => (
            <td key={i}>
              {r.program.program_name}:{" "}
              {format(new Date(r.program.program_start), "MMM e, yyyy")} -{" "}
              {r.program.timing[0] === "-1" || r.program.timing[1] === "-1" ? (
                "--"
              ) : (
                <>
                  {parseInt(r.program.timing[0]) > 12
                    ? parseInt(r.program.timing[0]) - 12
                    : parseInt(r.program.timing[0])}
                  :
                  {r.program.timing[0] / parseInt(r.program.timing[0]) > 1
                    ? "30"
                    : "00"}{" "}
                  {r.program.timing[0] < 11.5 ? "am" : "pm"} -{" "}
                  {parseInt(r.program.timing[1]) > 12
                    ? parseInt(r.program.timing[1]) - 12
                    : parseInt(r.program.timing[1])}
                  :
                  {r.program.timing[1] / parseInt(r.program.timing[1]) > 1
                    ? "30"
                    : "00"}{" "}
                  {r.program.timing[1] > 11.5 ? "pm" : "am"}{" "}
                </>
              )}
            </td>
          )
        },
        {
          id: "room_assignments",
          label: "Room Assignments",
          customCell: (r, i) => (
            <td key={i}>
              {r.assignedRoom ? <p>{r.assignedRoom.name}</p> : <p>-</p>}
            </td>
          )
        },
        {
          id: "Assignment Button",
          label: "Assignment",
          customCell: (r, i) => (
            <td key={i}>
              {r.assignedRoom ? (
                <button
                  onClick={() => {
                    this.setState({ seletedUser: r });

                    this.setState({ reassginRoomModal: true });
                  }}
                >
                  Reassign User
                </button>
              ) : (
                <button
                  style={{ marginRight: "30px" }}
                  onClick={() => {
                    this.setState({ seletedUser: r });
                    this.setState({ openAssignRoomsModal: true });
                  }}
                >
                  Assign Room
                </button>
              )}
            </td>
          )
        }
      ],
      Rooms: [
        { id: "name", label: "Rooms" },
        { id: "description", label: "Description" },
        {
          id: "active",
          label: "Active",
          customCell: (r, i) => (
            <td key={i}>
              {r.is_active ? (
                <button onClick={() => toggleRoomActive(r)}>
                  Make Inactive
                </button>
              ) : (
                <button onClick={() => toggleRoomActive(r)}>Make Active</button>
              )}
            </td>
          )
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <button
                  onClick={() => {
                    this.setState({ updateRoomModal: true });
                    this.setState({ updateRoom: r });
                  }}
                >
                  <EditIcon style={{ color: "white" }} />
                </button>
                {/* deleteRoom(r) */}
                <button
                  onClick={() => {
                    this.setState({ selectedRoomToDelete: r });
                    this.setState({ showDeleteModal: true });
                  }}
                >
                  <Delete style={{ color: "white" }} />
                </button>
              </td>
            );
          }
        }
      ]
    };
  }

  deleteRoom = async room => {
    try {
      const ep = `${process.env.REACT_APP_API}/partners/rooms/delete`;
      const deleteRoomResp = await axios.delete(ep, { data: { room } });

      if (deleteRoomResp.data && deleteRoomResp.data.success) {
        const newRooms = this.state.data.Rooms.filter(d => d.id !== room.id);

        this.setState({
          data: {
            "Assigned Rooms": this.state.data["Assigned Rooms"],
            Rooms: newRooms
          }
        });

        toast.success(deleteRoomResp.data.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } else {
        toast.error(deleteRoomResp.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
      this.setState({ showDeleteModal: false });
    } catch (e) {
      console.error(e);
    }
  };
  async componentDidMount() {
    this.fetchInitialData();
  }

  async fetchInitialData() {
    const programId = this.props.match.params.id;
    const ep = `${process.env.REACT_APP_API}/partners/rooms/get/program/${programId}/`;
    const resp = await axios.get(ep);
    if (resp && resp.data && resp.data.success) {
      this.setState({
        data: { "Assigned Rooms": resp.data.assigned, Rooms: resp.data.rooms }
      });
    }
    if (resp && resp.data && !resp.data.success) {
      if (resp.data.rooms && resp.data.rooms.length > 0) {
        toast.error(resp.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        this.setState({
          data: { "Assigned Rooms": resp.data.assigned, Rooms: resp.data.rooms }
        });
      }
    }
  }

  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  filterData = () => {
    if (!this.state.filter) {
      return this.state.data;
    }

    return {
      "Assigned Rooms": this.state.data["Assigned Rooms"]
        .filter(parent => parent.displayName.toLowerCase().includes(this.state.filter.toLowerCase())),
      "Rooms": this.state.data["Rooms"].filter(room => room.name.toLowerCase().includes(this.state.filter.toLowerCase()))
    }
  }

  render() {
    console.log(this.state.data);
    return (
      <div className="admin">
        {this.state.openCreateRoomsModal && (
          <CreateRoomsModal
            programId={this.props.match.params.id}
            onClose={_ => {
              this.setState({ openCreateRoomsModal: false });
              this.fetchInitialData();
            }}
          />
        )}
        {this.state.showDeleteModal && (
          <DeleteRoomModal
            deleteRoom={this.deleteRoom}
            room={this.state.selectedRoomToDelete}
            onClose={() => {
              this.setState({ showDeleteModal: false });
            }}
          />
        )}
        {this.state.openAssignRoomsModal && (
          <AssignRoomsModal
            user={this.state.seletedUser}
            rooms={this.state.data.Rooms.filter(room => room.is_active)}
            onClose={() => {
              this.setState({ openAssignRoomsModal: false });
              this.fetchInitialData();
            }}
          />
        )}
        {this.state.reassginRoomModal && (
          <ReassignRoomsModal
            user={this.state.seletedUser}
            rooms={this.state.data.Rooms.filter(room => room.is_active)}
            onClose={() => {
              this.setState({ reassginRoomModal: false });
              this.fetchInitialData();
            }}
          />
        )}
        {this.state.updateRoomModal && (
          <UpdateRoomsModal
            room={this.state.updateRoom}
            onClose={() => {
              this.setState({ updateRoomModal: false });
              this.fetchInitialData();
            }}
          />
        )}

        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>Rooms</h1>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp">
                  <button
                    className="newapplications"
                    onClick={_ => this.setState({ openCreateRoomsModal: true })}
                  >
                    <img
                      src={MiniPlus}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    Create New
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="tablecont">
              <div
                style={{
                  display: "inline-flex",
                  flex: "1 1",
                  border: "none",
                  borderRadius: "10px",
                  overflow: "hidden",
                  width: "100%",
                  marginBottom: 15
                }}
              >
                <i
                  className="fas fa-search"
                  style={{ padding: "8px", background: "white" }}
                ></i>
                <input
                  type="text"
                  placeholder="Filter by name..."
                  style={{
                    border: "none",
                    fontSize: "13px",
                    padding: "5px",
                    outline: 0,
                    background: "white",
                    flex: 1
                  }}
                  onChange={e => {
                    this.setState({ 
                      filter: e.target.value
                      });
                  }}
                />
              </div>
              <div className="table-responsive">
                <NewTabbedTable
                  tabs={this.tabs}
                  headings={this.headings}
                  data={this.filterData()}
                  actions={this.actions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rooms;
