import React from "react";
import { Button, withStyles } from "@material-ui/core";

const colors = {
  green: "#2AA897",
  lightGreen: "#4FBE75",
  white: "#FFFFFF",
  black: "#000000",
  gray: "#F4F6FC",
  midGray: "#EEEEEE",
  borderGray: "#BBBBBB",
  darkGray: "#808080"
};

const styles = {
  root: {
    color: colors.white,
    border: `1px solid ${colors.white}`,
    padding: "0.8rem 2rem",
    margin: "0.5rem"
  }
};

const StyledButtonTransparent = props => {
  const { classes, ...other } = props;
  return <Button className={classes.root} {...other}></Button>;
};

export default withStyles(styles)(StyledButtonTransparent);
