import React, { useState, useEffect, useGlobal } from "reactn";
import { NewTabbedTable, NewDraggableTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
// import ProgramSearchInput from "../../components/common/ProgramSearchInput"
import axios from "axios";
import { toast } from "react-toastify";
// import { format } from "date-fns"
import CreateMilestonesModal from "../../components/modals/CreateMilestonesModal";
import CopyExistingMilestonesModal from "../../components/modals/CopyExistingMilestonesModal";
import { Delete, Create as Edit } from "@material-ui/icons";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import List from "@material-ui/core/List";

// milestones data
import MilestonesData from "./Milestones.context";
import ConfirmArchiveModal from "../../components/modals/ConfirmArchiveModal";
import PromptInactiveMilestoneModal from "../../components/modals/PromptInactiveMilestoneModal";

const Milestones = props => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCopyExistingModal, setShowCopyExistingModal] = useState(false);
  const [
    showPromptInactiveMilestoneModal,
    setShowPromptInactiveMilestoneModal
  ] = useState(false);

  // State for edit and deletion modals.
  const [milestoneToEdit, setMilestoneToEdit] = useState({});
  const [toArchive, setToArchive] = useState(true);

  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");

  const [programsList, setProgramsList] = useState(null);
  const [onlineVirtualsList, setOnlineVirtualsList] = useState(null);
  const [displayType, setDisplayType] = useState(
    props.location.state?.data?.type
      ? props.location.state.data.type === "Program"
        ? "program"
        : "onlineVirtual"
      : "program"
  );
  const [displayId, setDisplayId] = useState(
    props.location.state?.data?.program_id
      ? props.location.state.data.program_id
      : -1
  );

  const tabs = ["Active", "Inactive"];
  const headings = {
    Active: [
      {
        id: "name",
        label: "Milestones",
        customCell: (r, i) => (
          <td key={i} style={{ width: "212.67px" }}>
            <span>{r.name}</span>
          </td>
        )
      },
      {
        id: "description",
        label: "Description",
        customCell: (r, i) => (
          <td key={i} style={{ width: "212.67px" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: urlify(r.description)
              }}
            ></div>
          </td>
        )
      },
      {
        id: "due_date",
        label: "Due Date",
        customCell: (r, i) => (
          <td key={i} style={{ width: "212.67px" }}>
            {r.due_date && moment(r.due_date, "YYYYMMDD").fromNow()}
          </td>
        )
      },
      {
        id: "can_upload",
        label: "Upload",
        customCell: (r, i) => (
          <td key={i} style={{ width: "212.67px" }}>
            {r.can_upload ? "Y" : "N"}
          </td>
        )
      },
      // {
      //   id: "reminders_notification",
      //   label: "Reminders",
      //   customCell: (r, i) =>(
      //     <td key={i}>
      //       <Switch
      //         name="reminder"
      //         checked={r.reminders_notification}
      //         onClick={(_) => toggleReminder(r)}
      //       />
      //     </td>
      //   )
      // },
      {
        id: "milestone_type",
        label: "Type"
      },
      {
        id: "is_active",
        label: "Active",
        customCell: (r, i) => (
          <td key={i} style={{ width: "212.67px" }}>
            <button name="is_active" onClick={_ => toggleActive(r)}>
              Make Inactive
            </button>
          </td>
        )
      },
      {
        id: "actionf",
        label: "",
        customCell: (r, i) => {
          return (
            <td
              key={`${i}-btn`}
              style={{
                whiteSpace: "nowrap",
                textAlign: "right",
                width: "212.67px"
              }}
            >
              <div className="action-group">
                <Edit
                  onClick={() => {
                    setShowEditModal(true);
                    setMilestoneToEdit(r);
                  }}
                  data-for="edit"
                  data-tip
                />
                <ReactTooltip id="edit" place="top" effect="solid" type="info">
                  Edit Milestone
                </ReactTooltip>
                {/* <Delete
                  onClick={() => {
                    setShowConfirmModal(true);
                    setMilestoneToEdit(r);
                    setToArchive(true);
                  }}
                  data-for="archive"
                  data-tip
                />
                <ReactTooltip
                  id="archive"
                  place="top"
                  effect="solid"
                  type="info"
                >
                  Delete Milestone
                </ReactTooltip> */}
              </div>
              {/* <button
                style={{ marginRight: "30px" }}
                onClick={_ =>
                  this.props.history.push(
                    `/partners/resources-manage/${r.id}`
                  )
                }
              >
                Manage
              </button> */}
              {/* <button className="btn btn-danger" onClick={() => deleteMilestone(r)}> */}
              {/* </button> */}
            </td>
          );
        }
      }
    ],
    Inactive: [
      {
        id: "name",
        label: "Milestones",
        customCell: (r, i) => (
          <td key={i} style={{ width: "550px" }}>
            {r.name}
          </td>
        )
      },
      {
        id: "is_active",
        label: "Active",
        customCell: (r, i) => (
          <td key={i} style={{ width: "396px" }}>
            <button name="is_active" onClick={_ => toggleActive(r)}>
              Make active
            </button>
          </td>
        )
      },
      {
        id: "is_hidden",
        label: "Delete",
        customCell: (r, i) => (
          <td key={i} style={{ width: "398.1px" }}>
            <button
              name="is_hidden"
              onClick={() => {
                setMilestoneToEdit(r);
                setToArchive(true);
                setShowConfirmModal(true);
              }}
            >
              Delete Milestone
            </button>
          </td>
        )
      }
    ]
  };
  const actions = [{ name: "Edit", action: null }];

  const deleteMilestone = async () => {
    // TODO: test
    const milestone = milestoneToEdit;
    const ep = `${process.env.REACT_APP_API}/partners/milestones/delete`;
    const deleteingRoute = await axios.delete(ep, {
      data: { milestone }
    });
    if (!deleteingRoute.data) {
      toast.error("An error occured sending data", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
    if (deleteingRoute.data && !deleteingRoute.data.success) {
      toast.error(deleteingRoute.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }

    // update the state
    const newActive = data.Active.filter(data => data.id !== milestone.id);
    const newInactive = data.Inactive.filter(data => data.id !== milestone.id);
    setData({ Active: newActive, Inactive: newInactive });
  };

  // TODO: make better implementation
  const toggleReminder = async r => {
    //
    const ep = `${process.env.REACT_APP_API}/partners/milestones/reminder/update`;
    const toggle_resp = await axios.post(ep, {
      id: r.id,
      toggle: !r.reminders_notification
    });
    const gotback = toggle_resp.data;
    if (gotback && gotback.success) {
      const updateData = () => {
        let newInactive = [];
        let newActive = [];

        if (r.reminders_notification) {
          // reminder is true then set as false
          newActive = data.Active.filter(activeM => activeM.id !== r.id);
          r.reminders_notification = !r.reminders_notification;
          newInactive = [...data.Inactive, r];
        } else {
          // if reminder is false then set as true
          newInactive = data.Inactive.filter(inactiveM => inactiveM.id != r.id);
          r.reminders_notification = !r.reminders_notification;
          newActive = [...data.Active, r];
        }
        return { Inactive: newInactive, Active: newActive };
      };

      setData(updateData());
    } else {
      // TODO: account for the fail case
    }
  };

  const toggleActive = async r => {
    // send requestion
    const ep = `${process.env.REACT_APP_API}/partners/milestones/acitve/update`;
    const toggle_resp = await axios.post(ep, {
      id: r.id,
      toggle: !r.is_active
    });
    const gotback = toggle_resp.data;
    if (gotback && gotback.success) {
      // r.is_active = !r.is_active
      // update state
      const updateData = () => {
        let newInactive = [];
        let newActive = [];

        if (r.is_active) {
          // if active then set as inactive
          newActive = data.Active.filter(activeM => activeM.id !== r.id);
          r.is_active = !r.is_active;
          newInactive = [...data.Inactive, r];
        } else {
          // if inactive then set as active
          newInactive = data.Inactive.filter(inactiveM => inactiveM.id != r.id);
          r.is_active = !r.is_active;
          newActive = [...data.Active, r];
        }
        return {
          Inactive: newInactive.sort(
            (a, b) => a.display_order - b.display_order
          ),
          Active: newActive.sort((a, b) => a.display_order - b.display_order)
        };
      };
      setData(updateData());
    } else {
      // TODO: account for the fail case
    }
  };

  const hideMilestone = async () => {
    const milestone = milestoneToEdit;
    const ep = `${process.env.REACT_APP_API}/partners/milestones/hide`;
    const ep_results = await axios.put(ep, {
      data: { milestone }
    });
    if (!ep_results.data) {
      toast.error("An error occured sending data", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
    if (ep_results.data && !ep_results.data.success) {
      toast.error(ep_results.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }

    // update the state
    const newActive = data.Active.filter(
      data => data.id !== milestone.id && data.is_active
    ).sort((a, b) => a.display_order - b.display_order);
    const newInactive = data.Inactive.filter(
      data => data.id !== milestone.id && !data.is_hidden && !data.is_active
    ).sort((a, b) => a.display_order - b.display_order);
    setData({ Active: newActive, Inactive: newInactive });
  };

  const getMileStones = async () => {
    // get milestones
    console.log("GETTING MILESTONES", displayType, displayId, typeof displayId);
    const ms_ep = `${process.env.REACT_APP_API}/partners/milestones/get`;
    const resp = await axios.get(ms_ep);
    const activeMilestonesAll = resp.data.Active.filter(
      data => data.is_active && !data.is_hidden
    ).sort((a, b) => a.display_order - b.display_order);

    const inactiveMilestonesAll = resp.data.Inactive.filter(
      data => !data.is_active && !data.is_hidden
    ).sort((a, b) => a.display_order - b.display_order);
    setAllData({
      Active: activeMilestonesAll,
      Inactive: inactiveMilestonesAll
    });
    console.log(
      "MY MILESTONES",
      activeMilestonesAll,
      inactiveMilestonesAll,
      resp.data
    );
    if (displayId) {
      const activeMilestones = resp.data.Active.filter(
        data =>
          data.is_active &&
          !data.is_hidden &&
          (data.program == displayId || data.onlineId == displayId)
      ).sort((a, b) => a.display_order - b.display_order);
      const inactiveMilestones = resp.data.Inactive.filter(
        data =>
          !data.is_active &&
          !data.is_hidden &&
          (data.program == displayId || data.onlineId == displayId)
      ).sort((a, b) => a.display_order - b.display_order);
      setData({ Active: activeMilestones, Inactive: inactiveMilestones });
    } else {
      setData({ Active: activeMilestonesAll, Inactive: inactiveMilestonesAll });
    }

    setLoading(false);
  };

  const programsSetup = async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API}/partners/programs/list/active`
      );
      if (resp.data.success) {
        setProgramsList(resp.data.data);
      } else {
        console.error("error getting programs -----");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onlineSetup = async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API}/partners/virtual/list/active`
      );
      if (resp.data.success) {
        setOnlineVirtualsList(resp.data.data);
      } else {
        console.error("error getting online -----");
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getMileStones();
    programsSetup();
    onlineSetup();
    setPartnerPathway([
      ...partnerPathway,
      { label: "Milestones", to: "/milestones" }
    ]);
    setLoading(false);
  }, []);

  const onDragEndHandler = async (destination, source, draggableId, useTab) => {
    console.log("DRAG", destination, source);
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const itemList = data[tabs[useTab]];

    const newList = [...itemList];
    const draggedItem = newList[source.index];
    newList.splice(source.index, 1);
    newList.splice(destination.index, 0, draggedItem);

    setData({ ...data, [tabs[useTab]]: newList });

    if (tabs[useTab] === "Active") {
      console.log("CASE 1");
      const updateOrderEp = `${process.env.REACT_APP_API}/partners/milestones/updateOrder`;
      const updateMilestone = await axios.put(updateOrderEp, {
        originalOrder: data[tabs[useTab]][source.index].display_order,
        newOrder: data[tabs[useTab]][destination.index].display_order,
        milestoneId: draggableId,
        programId: displayId,
        milestoneType: displayType
      });
    } else if (tabs[useTab] === "Inactive") {
      console.log("CASE 2");
      // const updateOrderEp = `${process.env.REACT_APP_API}/partners/reminders/updateOrder`;
      // const updatedReminder = await axios.put(updateOrderEp, {
      //   originalOrder: source.index,
      //   newOrder: destination.index,
      //   taskId: draggableId
      // });
    }
    getMileStones();
  };

  const urlify = text => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a target="_blank" href="' + url + '">' + url + "</a>";
    });
  };

  return (
    <MilestonesData.Provider value={data}>
      <div className="admin">
        {showCreateModal && (
          <CreateMilestonesModal
            milestone={null}
            onClose={_ => {
              getMileStones();
              setShowCreateModal(false);
            }}
          />
        )}
        {showCopyExistingModal && (
          <CopyExistingMilestonesModal
            milestoneType={displayType}
            milestoneId={displayId}
            onClose={() => {
              getMileStones();
              setShowCopyExistingModal(false);
            }}
          />
        )}
        {showPromptInactiveMilestoneModal && (
          <PromptInactiveMilestoneModal
            onClose={() => {
              setShowPromptInactiveMilestoneModal(false);
            }}
          />
        )}
        {showConfirmModal && (
          <ConfirmArchiveModal
            onSubmit={() => {
              hideMilestone();
            }}
            onClose={_ => setShowConfirmModal(false)}
            toArchive={toArchive}
            modalBody="Are you sure you would like to delete this milestone? This action cannot be undone."
          />
        )}
        {showEditModal && (
          <CreateMilestonesModal
            milestone={milestoneToEdit}
            onClose={_ => {
              getMileStones();
              setShowEditModal(false);
            }}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1 data-testid="milestones-heading">Milestones</h1>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp" style={{ marginRight: "20px" }}>
                  <button
                    className="newapplications"
                    onClick={_ => {
                      if (displayId === -1) {
                        toast.error("Please select a milestone to copy to");
                      } else if (data && data["Active"]?.length > 0) {
                        setShowPromptInactiveMilestoneModal(true);
                      } else {
                        setShowCopyExistingModal(true);
                      }
                    }}
                  >
                    Copy Existing
                  </button>
                </div>
                <div className="forbtnapp">
                  <button
                    className="newapplications"
                    onClick={_ => setShowCreateModal(true)}
                  >
                    <img
                      src={MiniPlus}
                      alt="plus icon"
                      style={{ alignSelf: "center" }}
                    />
                    Create New
                  </button>
                </div>
              </div>
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              <select
                name="selectForm"
                id="selectForm"
                type="text"
                className="form-control"
                //style={{ width: "50%" }}
                defaultValue={
                  props.location.state?.data?.type
                    ? props.location.state.data.type === "Program"
                      ? "program"
                      : "onlineVirtual"
                    : "program"
                }
                onChange={e => {
                  if (e.target.value === "program") {
                    setDisplayType("program");
                    setDisplayId(-1);
                  } else if (e.target.value === "onlineVirtual") {
                    setDisplayType("onlineVirtual");
                    setDisplayId(-1);
                  }

                  const newActive = allData.Active.filter(
                    data =>
                      data.is_active &&
                      (data.program === displayId ||
                        data.onlineId === displayId)
                  ).sort((a, b) => a.display_order - b.display_order);
                  const newInactive = allData.Inactive.filter(
                    data =>
                      !data.is_hidden &&
                      !data.is_active &&
                      (data.program === displayId ||
                        data.onlineId === displayId)
                  ).sort((a, b) => a.display_order - b.display_order);
                  setData({ Active: newActive, Inactive: newInactive });
                }}
              >
                <option key="program" value="program">
                  Program
                </option>
                <option key="onlineVirtual" value="onlineVirtual">
                  Online Virtual
                </option>
              </select>
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: 0
              }}
            >
              {programsList && onlineVirtualsList ? (
                <select
                  name="selectForm"
                  id="selectForm"
                  type="text"
                  className="form-control"
                  //style={{ width: "50%" }}
                  defaultValue={
                    props.location.state?.data?.program_id
                      ? parseInt(props.location.state.data.program_id)
                      : -1
                  }
                  onChange={e => {
                    setDisplayId(e.target.value);
                    const programId = parseInt(e.target.value);
                    const newActive = allData.Active.filter(
                      data =>
                        data.is_active &&
                        (data.program === programId ||
                          data.onlineId === programId)
                    ).sort((a, b) => a.display_order - b.display_order);
                    const newInactive = allData.Inactive.filter(
                      data =>
                        !data.is_hidden &&
                        !data.is_active &&
                        (data.program === programId ||
                          data.onlineId === programId)
                    ).sort((a, b) => a.display_order - b.display_order);
                    setData({ Active: newActive, Inactive: newInactive });
                    console.log("NEW DATA", data, newActive, newInactive);
                  }}
                >
                  <option key={-1} value={-1}>
                    Please Select
                  </option>
                  {displayType === "program" &&
                    programsList?.map(program => {
                      return (
                        <option key={program.id} value={program.id}>
                          {program.program_name}
                        </option>
                      );
                    })}
                  {displayType === "onlineVirtual" &&
                    onlineVirtualsList?.map(program => {
                      return (
                        <option key={program.id} value={program.id}>
                          {program.program_name}
                        </option>
                      );
                    })}
                </select>
              ) : (
                <p></p>
              )}
            </div>
          </div>
          <div className="cont">
            <div className="tablecont">
              <div className="table-responsive">
                <NewDraggableTabbedTable
                  tabs={tabs}
                  headings={headings}
                  data={data}
                  actions={actions}
                  onDragEndHandler={onDragEndHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MilestonesData.Provider>
  );
};

export default Milestones;
