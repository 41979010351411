import React from "reactn";
import axios from "axios";
import { startOfWeek, differenceInCalendarWeeks, addWeeks } from "date-fns";
import ScheduleSelector from "react-schedule-selector";
import { toast } from "react-toastify";

class TourAvailability extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      schedule: []
    };
    this.handleRangeSelection = this.handleRangeSelection.bind(this);
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Appointments", to: "/appointments" },
        { label: "Appointments Availability", to: "/appointments" }
      ]
    });
  }

  async componentDidMount() {
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(
        `${process.env.REACT_APP_API}/partners/appointments/availability`
      );

      if (res.data.success) {
        const mappedTimes = res.data.data.availability.map(time => {
          const wkDiff = Math.abs(
            differenceInCalendarWeeks(new Date(), new Date(time))
          );
          return new Date(addWeeks(new Date(time), wkDiff));
        });

        this.setState({ schedule: mappedTimes, id: res.data.data.id });
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  async handleRangeSelection(item) {
    try {
      this.setGlobal({ loading: true });
      const res = await axios.post(
        `${process.env.REACT_APP_API}/partners/appointments/availability`,
        { availability: this.state.schedule, id: this.state.id }
      );

      if (res.data.success) {
        toast.success(`Availability Saved !`);
        this.setState({ schedule: res.data.data.availability });
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="cont">
          <div className="row">
            <div className="col-md-12">
              <h1>Appointments Availability</h1>
            </div>
          </div>
        </div>
        <div className="cont">
          <ScheduleSelector
            selection={this.state.schedule}
            numDays={7}
            minTime={7}
            maxTime={22}
            startDate={startOfWeek(new Date())}
            onChange={e => this.setState({ schedule: e })}
            dateFormat="ddd"
          />
        </div>
        <div className="cont">
          <div className="buttons">
            <button
              className="savebtn"
              onClick={() => this.handleRangeSelection()}
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default TourAvailability;
