import React from "reactn";
import axios from "axios";
import { isAfter } from "date-fns";
import FranchiserRecurringPaymentsModal from "../../../../components/modals/FranchiserRecurringPaymentsModal";
import FranchiserTrackRecurring from "./FranchiserTrackRecurring";

class FranchiserRecurringPayments extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      partnerIds: parseInt(this.global.franchiseUser.selectedId) === -1 ? this.global.franchiseUser.organizations : [this.global.franchiseUser.selectedId],
      selectedOption: this.global.franchiseUser.selectedId,
      partnerOrg: {
        user: {},
        paymentInfo: {
          lateCount: 0,
          totalCount: 0,
          refunds: 0,
          totalPayments: 0,
          frequencyData: {
            monthly: 0,
            "bi-weekly": 0,
            weekly: 0,
            "3-months": 0,
            "6-months": 0,
            yearly: 0
          }
        }
      },
      data: {
        Pending: [],
        Payments: []
      },
    };

    this.emptyPartnerOrg = {
      user: {},
      paymentInfo: {
        lateCount: 0,
        totalCount: 0,
        refunds: 0,
        totalPayments: 0,
        frequencyData: {
          monthly: 0,
          "bi-weekly": 0,
          weekly: 0,
          "3-months": 0,
          "6-months": 0,
          yearly: 0
        }
      }
    };

    this.emptyData = {
      Pending: [],
      Payments: []
    };
  }
  async componentDidMount () {
    await this.fetchInitialData();
  }

  async componentDidUpdate() {
    if (parseInt(this.state.selectedOption) !== parseInt(this.global.franchiseUser.selectedId) || this.state.partnerIds.length === 0) {
      this.setState({
        partnerOrg: {
          paymentInfo: {
            lateCount: 0,
            totalCount: 0,
            refunds: 0,
            totalPayments: 0,
            frequencyData: {
              monthly: 0,
              "bi-weekly": 0,
              weekly: 0,
              "3-months": 0,
              "6-months": 0,
              yearly: 0
            }
          },
          user: {}
        },
        data: {
          Pending: [],
          Payments: []
        }
      });

      if (parseInt(this.global.franchiseUser.selectedId) === -1) {
        this.setState({partnerIds: this.global.franchiseUser.organizations, selectedOption: -1}, async () => {
          await this.fetchInitialData();
        });
      } else {
        this.setState({partnerIds: [this.global.franchiseUser.selectedId], selectedOption: this.global.franchiseUser.selectedId}, async () => {
          await this.fetchInitialData();
        });
      }
    }
  }

  async fetchInitialData() {
    this.setGlobal({ loading: true });
    let counter = 0;
    this.state.partnerIds.forEach(async (partnerId, i, ids) => {
      const ep = `${process.env.REACT_APP_API}/partners/order-tracking-recurring`;
      const ep2 = `${process.env.REACT_APP_API}/franchise/get-frequency-data/${partnerId}`;
      const ep3 = `${process.env.REACT_APP_API}/franchise/get-partner/${partnerId}`;
      try {
        const results = await axios.get(ep, {params: {partnerId: partnerId}});
        const results2 = await axios.get(ep2);
        const results3 = await axios.get(ep3);
        console.log("results", results);
        let totalCount;
        let totalPayments;
        let lateCount;
        let refunds;
        if (results.data.success) {
          const payments = [
            ...results.data.data.paid,
            ...results.data.data.deposits.filter(
              e =>
                e.status === "Paid" ||
                e.status === "Processing" ||
                e.status === "Paid (refunded)" ||
                e.status === "Paid (late)"
            )
          ].map(order => ({...order, company: results3.data.success ? results3.data.data.company : "N/A"}));

          const pending = [
            ...results.data.data.pending
              .filter(e => {
                if (e.status === "Cancelled") {
                  if (new Date(e.due_date) - new Date() < 0) {
                    return false;
                  }
                }
                return e.date;
              })
              .sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
              }),
            ...results.data.data.pending.filter(e => {
              if (e.status === "Cancelled") {
                if (new Date(e.due_date) - new Date() < 0) {
                  return false;
                }
              }
              return !e.date;
            }),
            ...results.data.data.deposits.filter(
              e =>
                e.status !== "Paid" &&
                e.status !== "Processing" &&
                e.status !== "Paid (refunded)" &&
                e.status !== "Paid (late)"
            )
          ].map(order => ({...order, company: results3.data.success ? results3.data.data.company : "N/A"}));

          totalPayments = payments.reduce((prev, curr) => prev + (parseFloat(curr.total) / 100), 0);
          lateCount = 
            payments.reduce((prev, curr) => {
              if (curr.status === "Paid (late)" || curr.status === "Paused (late)") {
                return prev + 1;
              } else {
                return prev;
              }
            }, 0) +
            pending.reduce((prev, curr) => {
              if (curr.status === "Late") {
                return prev + 1;
              } else {
                return prev;
              }
            }, 0);
          totalCount = payments.length + pending.length;
          refunds = payments.reduce((prev, curr) => prev + parseFloat(curr.refundedAmount) / 100, 0);
          
          this.setState({
            data: {
              Payments: [...this.state.data.Payments, ...payments].sort((a, b) => {
                if (!a.userPaidDate && !b.userPaidDate) {
                  return new Date(b.date) - new Date(a.date);
                } else if (!a.userPaidDate) {
                  return new Date(b.userPaidDate) - new Date(a.date);
                } else if (!b.userPaidDate) {
                  return new Date(b.date) - new Date(a.userPaidDate);
                } else {
                  return new Date(b.userPaidDate) - new Date(a.userPaidDate);
                }
              }),
              Pending: [...this.state.data.Pending, ...pending].sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
              })
            }
          });
        }

        if (results2.data.success && results3.data.success) {
          const frequencyDataState = this.state.partnerOrg.paymentInfo.frequencyData;
          let frequencyData = {
            monthly: frequencyDataState.monthly,
            "bi-weekly": frequencyDataState["bi-weekly"],
            weekly: frequencyDataState.weekly,
            "3-months": frequencyDataState["3-months"],
            "6-months": frequencyDataState["6-months"],
            yearly: frequencyDataState.yearly
          };

          results2.data.data.map((order) => {
            switch(order.recurring_frequency) {
              case "month":
                frequencyData["monthly"] += 1;
                break;
              case "bi-week":
                frequencyData["bi-weekly"] += 1;
                break;
              case "week":
                frequencyData["weekly"] += 1;
                break;
              case "membership-month":
                if (order.membership_term === 1) {
                  frequencyData["monthly"] += 1;
                } else if (order.membership_term === 3) {
                  frequencyData["3-months"] += 1;
                } else if (order.membership_term === 6) {
                  frequencyData["6-months"] += 1;
                } else if (order.membership_term === 12) {
                  frequencyData["yearly"] += 1;
                }
                break;
            }
          });

          const paymentInfo = this.state.partnerOrg.paymentInfo;

          let user = this.state.partnerOrg.user;
          if (!this.state.partnerOrg.user.createdAt || isAfter(new Date(user.createdAt), new Date(results3.data.data.createdAt))) {
            user = results3.data.data;
          }
          
          this.setState({
            partnerOrg: {
              paymentInfo: {
                totalCount: paymentInfo.totalCount + totalCount,
                totalPayments: paymentInfo.totalPayments + totalPayments,
                lateCount: paymentInfo.lateCount + lateCount,
                refunds: paymentInfo.refunds + refunds,
                frequencyData: frequencyData
              },
              user: user
            }
          });
        }

      } catch (error) {
        console.error(error);
      }

      counter++;
      if (counter === ids.length)
        this.setGlobal({ loading: false });
    });
  }
  render () {
    return (
      <>
        <div className="cont heading-wrapper">
          <h1 className="page-heading">Recurring Payments</h1>
        </div>
        <div className="container-fluid my_earning">
          <div className="cont">
            <FranchiserRecurringPaymentsModal partnerOrg={this.global.loading ? this.emptyPartnerOrg : this.state.partnerOrg} />
            <div className="boxes">
              <FranchiserTrackRecurring data={this.global.loading ? this.emptyData : this.state.data} />
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default FranchiserRecurringPayments;