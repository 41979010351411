import React from "reactn";
import { BoxForm } from "../../../../components/UI/form";
import AddMembershipPriceModal from "../../../../components/modals/AddMembershipPriceModal";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import Dropdown from "rc-dropdown";
import axios from "axios";
import { toast } from "react-toastify";
import EditMembershipPriceModal from "../../../../components/modals/EditMembershipPriceModal";

class SectionTwo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      days: {},
      prices: false,
      isTaxEnabled: false,
      taxInfo: [],
      openAddPriceModal: false,
      openEditMembershipPriceModal: false,
      customers: []
    };

    this.membershipUpdate = this.membershipUpdate.bind(this);
  }
  handleChange = async (e, v) => {
    this.setGlobal({
      membership: {
        ...this.global.membership,
        [e]: v
      }
    });
  };

  membershipUpdate(e, key) {
    const tickets = this.global.membership.prices.map(e =>
      typeof e === "string" ? JSON.parse(e) : e
    );

    tickets[key]["isTaxEnabled"] = this.state.isTaxEnabled;
    tickets[key]["taxInfo"] = this.state.taxInfo;
    tickets[key][e.target.name] = e.target.value;
    tickets[key].id = key;

    this.handleChange(e.target.name, e.target.value);
    this.handleChange("prices", tickets);
  }

  setDay(day) {
    const days = { ...this.state.days };
    if (days[day]) delete days[day];
    else days[day] = true;
    this.setState({
      days
    });
  }

  handleEnableTax = value => {
    this.setState({ isTaxEnabled: value, taxInfo: [] });
  };

  async componentDidMount() {
    const tickets = this.global.membership.prices.map(e =>
      typeof e === "string" ? JSON.parse(e) : e
    );

    if (tickets.length > 0) {
      const value = tickets[0].isTaxEnabled ? true : false;
      this.setState({ isTaxEnabled: value });
    }

    this.setGlobal({
      loading: true
    });

    const param = this.props.match.params.id;
    console.log("MEMBERSHIP PARAM", param, this.props, this.global);
    const customers_ep = `${process.env.REACT_APP_API}/partners/memberships/customers/${this.global.membership.id}`;
    const customers = await axios.get(customers_ep);

    if (customers.data.success) {
      this.setState({
        customers: customers.data.data
      });

      this.setGlobal({
        loading: false
      });
    }
  }

  deletePlan = async (plan, key) => {
    if (!this.global.membership.manual_invoices) {
      const active = plan.active === false ? true : false;

      let ep;
      if (plan.price_stripe.includes("price")) {
        ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
      } else {
        ep = `${process.env.REACT_APP_API}/partners/stripe/active_plan`;
      }
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: plan.price_stripe,
        active: active
      });

      this.setGlobal({ loading: false });

      if (res.data.success) {
        this.setGlobal({
          membership: {
            ...this.global.membership,
            prices: this.global.membership.prices.map((e, i) =>
              i === key ? { ...plan, archived: true, active: active } : e
            )
          }
        });
      }
      await this.dispatch.saveMembership();
      return;
    }

    this.setGlobal({
      membership: {
        ...this.global.membership,
        prices: this.global.membership.prices.map((e, i) =>
          i === key ? { ...plan, archived: true } : e
        )
      }
    });
    await this.dispatch.saveMembership();
  };

  onSelect = async (element, planKey, event) => {
    if (event.key === "inactive") {
      const active = element.active === false ? true : false;

      let ep;
      if (element.price_stripe.includes("price")) {
        ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
      } else {
        ep = `${process.env.REACT_APP_API}/partners/stripe/active_plan`;
      }
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: element.price_stripe,
        active: active
      });

      this.setGlobal({ loading: false });
      if (res.data.success) {
        this.setGlobal({
          membership: {
            ...this.global.membership,
            prices: this.global.membership.prices.map((e, i) => {
              const ele = typeof e === "string" ? JSON.parse(e) : e;

              return ele.id == planKey ? { ...element, active: active } : e;
            })
          }
        });
      }
      await this.dispatch.saveMembership();
    } else if (event.key === "edit") {
      this.setState({
        openEditMembershipPriceModal: true,
        selectedItem: element
      });
    } else {
      this.deletePlan(element, planKey);
    }
  };

  menu = (element, planId) => {
    return (
      <Menu onClick={event => this.onSelect(element, planId, event)}>
        <MenuItem key="inactive">
          {element.active === false ? "Make Active" : "Make Inactive"}
        </MenuItem>
        {this.countEnrolledAppsInPlan(element) === 0 && (
          <MenuItem key="edit">Edit</MenuItem>
        )}
        <MenuItem key="delete">Delete</MenuItem>
      </Menu>
    );
  };

  customForm = () => {
    const isTaxEnabled = this.state.isTaxEnabled;

    return (
      <div className="formbody">
        <form style={{ textAlign: "center" }}>
          <div className="row" style={{ marginLeft: "5px" }}>
            <div className="col-3">
              <h3 className="label-names">Add Plan</h3>
            </div>
            <div className="col-9 d-flex">
              <button
                type="button"
                className="newapplications addNew-btn"
                style={{ border: 0, borderRadius: "3px" }}
                onClick={_ => {
                  this.setState({ openAddPriceModal: true });
                }}
              >
                Add Price
              </button>
            </div>
          </div>

          {(this.global.membership.prices &&
            this.global.membership.prices.length) > 0 ? (
            <table className="tableticket" style={{ marginLeft: "25px" }}>
              <thead>
                <tr>
                  <th
                    style={{ padding: "10px" }}
                    className="input-fields-no-border"
                  >
                    Membership Term (month)
                  </th>
                  <th
                    style={{ padding: "10px" }}
                    className="input-fields-no-border"
                  >
                    Pricing for Term
                  </th>
                  <th
                    style={{ padding: "10px" }}
                    className="input-fields-no-border"
                  >
                    Subscription?
                  </th>
                  <th
                    style={{ padding: "10px" }}
                    className="input-fields-no-border"
                  >
                    Tax % (if any)
                  </th>
                  <th
                    style={{ padding: "10px" }}
                    className="input-fields-no-border"
                  >
                    Enrolled / Apps
                  </th>
                </tr>
              </thead>
              {this.global.membership.prices
                .filter(ele => {
                  const e = typeof ele === "string" ? JSON.parse(ele) : ele;
                  if (e.archived) {
                    return;
                  } else return e;
                })
                .map((ele, key) => {
                  const e = typeof ele === "string" ? JSON.parse(ele) : ele;
                  console.log("e", e);
                  return (
                    <tbody key={key}>
                      <tr>
                        <td style={{ padding: "10px" }}>
                          <span name="membership_term">
                            {e.membership_term} Month
                          </span>
                        </td>
                        <td style={{ padding: "10px" }}>
                          <span name="price_per_term">${e.price_per_term}</span>
                        </td>
                        <td style={{ padding: "10px" }}>
                          <span name="auto_renew">
                            {e.auto_renew ? "Y" : "N"}{" "}
                            {e.auto_withdraw ? "(Autopay)" : ""}
                          </span>
                        </td>
                        <td style={{ padding: "10px" }}>
                          <span name="isTaxEnabled">
                            {e.isTaxEnabled ? `${e.taxInfo[0].percentage}` : 0}%
                          </span>
                        </td>
                        <td style={{ padding: "10px" }}>
                          <span name="enrollment_num">
                            {this.state.customers &&
                              this.countEnrolledAppsInPlan(e)}
                          </span>
                        </td>
                        <td style={{ padding: "10px" }}>
                          <div
                            name="active_status"
                            // value={e.ticket_status}
                            // onChange={e => this.ticketUpdate(e, key)}
                            // disabled
                            className={`${
                              e.active === false
                                ? "ticket-status"
                                : "ticket-status-inverse"
                            }`}
                          >
                            {e.active === false ? "Inactive" : "Active"}
                          </div>
                        </td>
                        <td style={{ padding: "10px", cursor: "pointer" }}>
                          <div>
                            <Dropdown
                              trigger={["click"]}
                              overlay={this.menu(e, e.id)}
                              animation="slide-up"
                            >
                              <button
                                style={{
                                  borderRadius: "50%",
                                  width: 25,
                                  height: 25,
                                  outline: "none",
                                  border: 0,
                                  background: "#f4f6fd",
                                  fontSize: "14px"
                                }}
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </button>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
            </table>
          ) : (
            <p style={{ marginTop: "10px", textAlign: "center" }}>
              No Membership Plans Added!
            </p>
          )}
        </form>
      </div>
    );
  };

  countEnrolledAppsInPlan(plan) {
    let count = 0;
    let pricingPlan = plan.price_stripe;

    this.state.customers.forEach(purchase => {
      if (
        purchase.status_stage !== "Inactive" &&
        JSON.parse(purchase.prices[purchase.plan_id]).price_stripe ===
          pricingPlan
      ) {
        count++;
      }
    });

    return count;
  }

  render() {
    return (
      <>
        {this.state.openAddPriceModal && (
          <AddMembershipPriceModal
            onClose={_ => this.setState({ openAddPriceModal: false })}
          />
        )}
        {this.state.openEditMembershipPriceModal && (
          <EditMembershipPriceModal
            selectedItem={this.state.selectedItem}
            onClose={() => {
              this.setState({ openEditMembershipPriceModal: false });
            }}
          />
        )}
        <BoxForm
          handleChange={this.handleChange}
          name={"Membership Plans"}
          customForm={this.customForm()}
        />
      </>
    );
  }
}

export default SectionTwo;
