import React from "react";
import PropTypes from "prop-types";

const Input = props => {
  const newOnChange = !props.valueOnly
    ? props.onChange
    : e => props.onChange(props.name, e.target.value);
  const nProps = { ...props };
  delete nProps.valueOnly;
  delete nProps.isDisabled;
  delete nProps.inputType;
  delete nProps.type;
  delete nProps.onChange;
  nProps.value = props.value === null ? "" : props.value;

  return props.isDisabled ? (
    <input
      {...{ ...nProps, onChange: newOnChange }}
      type={props.inputType ? props.inputType : "text"}
      readOnly
    />
  ) : (
    <input
      {...{ ...nProps, onChange: newOnChange }}
      type={props.inputType ? props.inputType : "text"}
    />
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  type: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  valueOnly: PropTypes.bool,
  isDisabled: PropTypes.bool
};

Input.defaultProps = {
  type: "text",
  valueOnly: false,
  name: "",
  isDisabled: false
};
export default Input;
