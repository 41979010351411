import React from "reactn";
import { StepIndicator } from "../../../components/UI";
import Setup from "./Setup";
import AdditionalDetails from "./AdditionalDetails";
import CreatePackage from "./CreatePackage";
import StepBar from "../../../components/UI/StepBar";
import Validators from "../../../validators";
import { canUserCreate } from "../../../utilities/listingCreation";

class PartiesSetup extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      step: 0, // Section of the form currently active.
      canUserMakeNewParty: false,
      makePartyReqMessage: "",
      birthdayParty: true
    };
    this.titles = ["Set-Up", "Create Package", "Additional Settings"];
    this.validate = [
      () => Validators.Partners.Profile.ValidatePartiesOne(this.global.party),
      () => Validators.Partners.Profile.ValidatePartiesTwo(this.global.party),
      () => Validators.Partners.Profile.ValidatePartiesThree(this.global.party)
    ];
    this.steps = [
      { name: "Set-Up", validateFn: this.validate[0] },
      { name: "Create Package", validateFn: this.validate[1] },
      { name: "Additional Settings", validateFn: this.validate[2] }
    ];
  }

  async componentDidMount() {
    const canUserMake = await canUserCreate("party");
    const makePartyReqMessage = canUserMake.message;
    console.log("HERE", canUserMake);
    this.setState({
      canUserMakeNewParty: canUserMake.success,
      makePartyReqMessage: makePartyReqMessage
    });
    if (this.props.match.params.id) {
      await this.dispatch.getParty(this.props.match.params.id);
    } else {
      await this.dispatch.resetEvent();
    }

    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Parties", to: "/parties/list" },
        {
          label: this.global.party.party_title || "Parties Setup",
          to: this.props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
        }
      ]
    });
  }

  /**
   * To be run before saving. If any page is not setup correctly, it will unpublish the page.
   */
  ensureValidPublish = () => {
    // If the page is currently published
    if (this.global.party.published) {
      // Check validity of all pages
      for (let i = 0; i < this.steps.length; i++) {
        if (this.checkValid(i)) {
          // If any page is invalid, set publish to false
          this.setGlobal({
            party: {
              ...this.global.party,
              published: false
            }
          });
          break;
        }
      }
    }
  };

  saveProfile = async e => {
    const { step } = this.state;

    this.ensureValidPublish();

    switch (e.target.name) {
      case "next":
        this.setState({ step: step + 1 });
        await this.dispatch.saveParty();
        break;
      case "back":
        this.setState({ step: step - 1 });
        await this.dispatch.saveParty();
        break;
      case "save":
        await this.dispatch.saveParty();
        break;
      default:
        return;
    }
  };

  changePage = async page => {
    this.ensureValidPublish();
    if (!this.checkValid()) {
      // Only allow the user to progress to the earliest invalid page
      for (let i = 0; i < page; i++) {
        if (this.checkValid(i)) {
          return; // Prparty the page from changing
        }
      }
      await this.dispatch.saveParty();
      this.setState({ step: page });
    } else if (page < this.state.step) {
      // Allow the user to backtrack to previous pages
      await this.dispatch.saveParty();
      this.setState({ step: page });
    }
  };

  getPage = () => {
    switch (this.state.step) {
      case 0:
        return (
          <Setup
            initialPartyType={
              this.props.location.state
                ? this.props.location.state.partyType
                : this.global.party.partyType
            }
            initialAddChild={
              this.props.location.state
                ? this.props.location.state.addChild
                : this.global.party.addChild
            }
            initialManualInvoices={
              this.props.location.state
                ? this.props.location.state.manualInvoices
                : this.global.party.manual_invoices
            }
            initialApplicationRequired={
              this.props.location.state
                ? this.props.location.state.applicationRequired
                : this.global.party.application_required
            }
            allowSameEnrollment={
              this.props.location.state
                ? this.props.location.state.allowSameEnrollment
                : this.global.party.allowSameEnrollment
            }
          />
        );
      case 1:
        return <CreatePackage
          match={this.props.match}
          partyType={this.global.party.partyType}
        />;
      case 2:
        return <AdditionalDetails />;
      default:
        return <Setup />;
    }
  };

  checkValid(step = this.state.step) {
    return this.validate[step] && !this.validate[step]();
  }

  getCompletion() {
    let completed = 0;
    for (let v of this.validate) {
      completed += v();
    }
    return (completed / this.validate.length) * 100;
  }

  render() {
    if (!this.state.canUserMakeNewParty && !this.props.match.params.id) {
      return (
        <div className="container-fluid">
          {/* Commented out to make space for Top Bar */}
          {/* <div className="cont">
            <h1 className="parties-title-header">Parties</h1>
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "50vh",
              alignItems: "center"
            }}
          >
            <p>{this.state.makePartyReqMessage}</p>
          </div>
        </div>
      );
    }
    return (
      <div className="container-fluid">
        {/* Commented out to make space for Top Bar */}
        {/* <div className="cont">
          <h1 className="parties-title-header">Parties</h1>
        </div> */}
        <StepBar
          {...{
            steps: this.steps,
            changeFn: this.changePage,
            current: this.state.step
          }}
        />
        <StepIndicator value={this.getCompletion()} />
        {this.getPage()}
        <div className="cont mb-5">
          <div
            className="buttons w-100"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "80%"
              }}
            >
              <div
                name="back"
                disabled={this.state.step === 0}
                onClick={e => {
                  e.target.name = "back";
                  if (this.state.step === 0) return;
                  this.saveProfile(e);
                }}
              >
                <i
                  name="back"
                  style={{ paddingRight: "24px" }}
                  className={`fas fa-chevron-left ${this.state.step === 0
                      ? "listing-step-btn-inactive"
                      : "listing-step-btn-active"
                    }`}
                ></i>
              </div>
              <div
                name="next"
                onClick={e => {
                  e.target.name = "next";
                  if (
                    this.state.step === this.titles.length - 1 ||
                    this.checkValid()
                  )
                    return;
                  this.saveProfile(e);
                }}
              >
                <i
                  name="next"
                  style={{ paddingLeft: "24px" }}
                  className={`fas fa-chevron-right ${this.state.step === this.titles.length - 1 ||
                      this.checkValid()
                      ? "listing-step-btn-inactive"
                      : "listing-step-btn-active"
                    }`}
                ></i>
              </div>
            </div>
            {/* <button
              onClick={this.saveProfile}
              disabled={this.state.step === 0}
              name="back"
              className="nextbtn"
            >
              BACK
            </button> */}
            <div
              style={{
                width: "15%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              }}
            >
              {this.state.step === this.titles.length - 1 && (
                <button
                  onClick={_ => {
                    this.setGlobal(
                      {
                        party: {
                          ...this.global.party,
                          published: !this.global.party.published
                        }
                      },
                      _ => this.dispatch.saveParty()
                    );
                  }}
                  name="save"
                  className={this.checkValid() ? "nextbtnDisabled" : "nextbtn"}
                  disabled={this.checkValid()}
                >
                  {this.global.party.published ? "Unpublish" : "Publish"}
                </button>
              )}
              <button
                onClick={this.saveProfile}
                name="save"
                className="savebtn"
                disabled={this.checkValid()}
                style={{ background: "#b2b8c7", border: "1px solid #b2b8c7" }}
              >
                SAVE
              </button>
            </div>

            {/* <button
              onClick={this.saveProfile}
              disabled={
                this.checkValid() || this.state.step === this.titles.length - 1
              }
              name="next"
              className="nextbtn"
            >
              NEXT
            </button> */}
          </div>
        </div>
      </div>
    );
  }
}

export default PartiesSetup;
