import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import { MiniPlus } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import { Delete, SettingsBackupRestore as Restore } from "@material-ui/icons";
import Switch from "@material-ui/core/Switch";
import LinkIcon from "@material-ui/icons/Link";
import AddIframeModal from "../../components/modals/AddIframeModal";
import ReactTooltip from "react-tooltip";
import ConfirmArchiveModal from "../../components/modals/ConfirmArchiveModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
class GiftCardList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      giftcardPermissions: {},
      data: {},
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      images_keys: [
        "partners/Gift Cards/zeus-01.png",
        "partners/Gift Cards/zeus-02.png",
        "partners/Gift Cards/zeus-03.png",
        "partners/Gift Cards/zeus-04.png",
        "partners/Gift Cards/zeus-05.png",
        "partners/Gift Cards/zeus-06.png",
        "partners/Gift Cards/zeus-07.png",
        "partners/Gift Cards/zeus-08.png"
      ],
      showConfirmModal: false,
      showConfirmHideModal: false,
      toArchive: true,
      toHide: true,
      idToEdit: 0
    };

    this.tabs = ["Gift Cards", "Archive"];

    this.actions = [{ name: "Notes", action: null }];
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Gift Cards", to: "/gift_cards" }
      ]
    });
  }

  headings = () => {
    return {
      "Gift Cards": [
        {
          id: "pic",
          label: "",
          customCell: (r, i) => (
            <td key={i} style={{ whiteSpace: "nowrap", maxWidth: "130px" }}>
              <LazyLoadImage
                src={`${process.env.REACT_APP_DS_FILES_S3}/${
                  this.state.images_keys[r.image - 1]
                }`}
                style={{ maxWidth: "130px" }}
                alt="Gift card image"
                effect="opacity"
              />
            </td>
          )
        },
        {
          id: "name",
          label: "Name",
          customCell: (r, i) => <td key={i}>{r.gift_name ? r.gift_name.slice(0, 30) : "(No name)"}</td>
        },
        {
          id: "amount",
          label: "Amount",
          customCell: (r, i) => <td key={i}>${r.amount}</td>
        },
        {
          id: "description",
          label: "Description",
          customCell: (r, i) => <td key={i}>{r.description.slice(0, 30)}</td>
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <div className="action-group">
                  <button
                    style={{
                      marginRight: "15px",
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    disabled={this.canSubUserEdit()}
                    onClick={_ =>
                      this.props.history.push(
                        `/partners/gift_cards/setup/${r.id}`
                      )
                    }
                  >
                    Edit
                  </button>
                  {!this.canSubUserEdit() && (
                    <>
                      <Delete
                        onClick={() => {
                          this.setState({
                            showConfirmModal: true,
                            idToEdit: r.id,
                            toArchive: true
                          });
                        }}
                        data-for="archive"
                        data-tip
                      />
                      <ReactTooltip
                        id="archive"
                        place="top"
                        effect="solid"
                        type="info"
                      >
                        Delete Gift Card
                      </ReactTooltip>
                    </>
                  )}
                </div>
              </td>
            );
          }
        }
      ],
      Archive: [
        {
          id: "pic",
          label: "",
          customCell: (r, i) => (
            <td key={i} style={{ whiteSpace: "nowrap", maxWidth: "130px" }}>
              <LazyLoadImage
                src={`${process.env.REACT_APP_DS_FILES_S3}/${
                  this.state.images_keys[r.image - 1]
                }`}
                style={{ maxWidth: "130px" }}
                alt="Gift card image"
                effect="opacity"
              />
            </td>
          )
        },
        {
          id: "name",
          label: "Name",
          customCell: (r, i) => <td key={i}>{r.gift_name.slice(0, 30)}</td>
        },
        {
          id: "amount",
          label: "Amount",
          customCell: (r, i) => <td key={i}>${r.amount}</td>
        },
        {
          id: "description",
          label: "Description",
          customCell: (r, i) => <td key={i}>{r.description.slice(0, 30)}</td>
        },
        {
          id: "actionf",
          label: "",
          customCell: (r, i) => {
            return (
              <td
                key={`${i}-btn`}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              >
                <div className="action-group">
                {!this.canSubUserEdit() && (
                  <>
                    <Restore
                      onClick={() => {
                        this.setState({
                          showConfirmModal: true,
                          idToEdit: r.id,
                          toArchive: false
                        });
                      }}
                      style={{ color: "#BBB" }}
                      data-for="restore"
                      data-tip
                    />
                    <ReactTooltip
                      id="restore"
                      place="top"
                      effect="solid"
                      type="info"
                    >
                      Restore Gift Card
                    </ReactTooltip>
                  </>
                )}
                {!this.canSubUserEdit() && (
                  <>
                    <Delete
                      onClick={() => {
                        this.setState({
                          showConfirmHideModal: true,
                          idToEdit: r.id,
                          toArchive: true,
                          toHide: true
                        });
                      }}
                      style={{ color: "#BBB" }}
                      data-for="hide"
                      data-tip
                    />
                    <ReactTooltip
                      id="hide"
                      place="top"
                      effect="solid"
                      type="info"
                    >
                      Delete Gift Card
                    </ReactTooltip>
                  </>
                )}
                </div>
              </td>
            );
          }
        }
      ]
    };
  };

  /**
   * This function handles the edit button functionality of a sub user
   * returns a boolean value
   */
  canSubUserEdit = () => {
    return !(!this.global.dsUser.sub_user ||
      this.global?.dsUser?.accesspoints?.Listings?.["Gift Card"]?.edit);
  };

  /**
   * Makes request to backend to archive/unarchive program.
   * Uses state to determine options (what to delete, archive/unarchive).
   */
  archiveGift = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/gifts/delete/${this.state.idToEdit}`;
    const results = await axios.post(ep, { toArchive: this.state.toArchive });

    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  /**
   * Makes request to backend to hide a gift card from archive.
   * Looks like a permanent deletion, but is only hidden from the partner's view.
   * Gift Card still stored in database
   */
   hideGift = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/gifts/hide/${this.state.idToEdit}`;
    const results = await axios.post(ep, { toHide: this.state.toHide });

    if (results.data.success) {
      this.fetchInitialData();
    }
  };

  getGiftCardsPermissions = async () => {
    const feature_name = "gift-card";
    const ep = new URL(
      `${process.env.REACT_APP_API}/partner/permissions/feature/${feature_name}`
    );
    const resp = await axios.get(ep);
    if (resp.data && resp.data.success) {
      const permissions = resp.data.data;
      return permissions;
    }
    return false;
  };
  showCreateNewEventButton = () => {
    const { data, giftcardPermissions } = this.state;
    if (data && giftcardPermissions) {
      return (
        giftcardPermissions.has_access &&
        giftcardPermissions.has_feature_limit &&
        data["Gift Cards"].length < giftcardPermissions.featureLimit
      );
    }
    return false;
  };

  async componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/gifts/list`;
    const results = await axios.get(ep);

    if (results.data.success) {
      this.setState({
        data: {
          "Gift Cards": results.data.data.filter(f => !f.isArchived),
          Archive: results.data.data.filter(f => f.isArchived && !f.isHidden)
        }
      });
    }

    const permissions = await this.getGiftCardsPermissions();
    this.setState({ giftcardPermissions: permissions });
    this.setGlobal({ loading: false });
  };

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
    if (results.data.success) {
      toast.success(`Partner Accepted`);
      this.componentDidMount();
    } else {
      toast.error(`Error, this person already signed-up!`);
    }
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  render() {
    return (
      <div className="admin">
        {this.state.iframeSetUp && (
          <AddIframeModal
            iframe={this.state.selectedIframe}
            open={this.state.iframeSetUp}
            type={this.state.type}
            active={this.state.active}
            onClose={() =>
              this.setState({
                iframeSetUp: false,
                selectedIframe: null,
                type: null,
                active: null
              })
            }
          />
        )}
        {this.state.showConfirmModal && (
          <ConfirmArchiveModal
            onSubmit={() => {
              this.archiveGift();
            }}
            onClose={_ => this.setState({ showConfirmModal: false })}
            toArchive={this.state.toArchive}
          />
        )}
        {this.state.showConfirmHideModal && (
          <ConfirmArchiveModal
            onSubmit={() => {
              this.hideGift();
            }}
            onClose={_ => this.setState({ showConfirmHideModal: false })}
            toArchive={this.state.toArchive}
            isPermanent={this.state.toHide}
          />
        )}
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <div style={{ display: "block" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "-30px"
                  }}
                >
                  <h1 style={{ marginRight: "30px" }}>Gift Cards</h1>
                  <LinkIcon
                    style={{
                      transform: "scale(1.5) rotate(130deg)",
                      cursor: "pointer",
                      marginRight: "10px",
                      marginTop: "0.2px",
                      color: "black"
                    }}
                    onClick={_ =>
                      this.setState({
                        iframeSetUp: true,
                        active: "Gifts"
                      })
                    }
                    className="hovermyX"
                  />
                </div>
                <div
                  style={{ marginLeft: "-6px", marginTop: "4px" }}
                  className="switch-tooltip-group"
                >
                  <Switch
                    checked={
                      !!this.global.organizationInfo.display_gifts_listings
                    }
                    size="small"
                    onChange={_ => {
                      this.setGlobal({
                        organizationInfo: {
                          ...this.global.organizationInfo,
                          display_gifts_listings:
                            !this.global.organizationInfo.display_gifts_listings
                        }
                      });
                      this.dispatch.saveOrganizationalProfile();
                    }}
                    color="primary"
                    disabled={this.canSubUserEdit()}
                  />
                  <i
                    data-tip
                    data-for="view-giftcard-toggle"
                    className="fas fa-info-circle"
                    style={{ color: "#DCDCDC" }}
                  ></i>
                  <ReactTooltip
                    id="view-giftcard-toggle"
                    place="right"
                    effect="solid"
                    type="info"
                  >
                    Toggles gift card tab visibility for users
                  </ReactTooltip>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="forbtnwrap justify-end">
                <div className="forbtnapp" style={{ marginLeft: "20px" }}>
                  {this.showCreateNewEventButton() && (
                    <button
                      className="newapplications"
                      disabled={this.canSubUserEdit()}
                      style={{
                        cursor: this.canSubUserEdit()
                          ? "not-allowed"
                          : "pointer"
                      }}
                      onClick={_ =>
                        this.props.history.push("/partners/gift_cards/setup")
                      }
                    >
                      <img
                        src={MiniPlus}
                        alt=""
                        style={{ alignSelf: "center" }}
                      />
                      Create New
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="cont">
            <div className="tablecont">
              <div className="table-responsive">
                <NewTabbedTable
                  tabs={this.tabs}
                  headings={this.headings()}
                  data={this.state.data}
                  actions={this.actions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GiftCardList;
