import React, { useGlobal } from "reactn";
import { formTypes, BoxFormTabbed } from "../../../../components/UI/form";

const ChildProfile = ({ childNo, child, onChange, onRemove }) => {
  //const app = useGlobal("madeApp");
  const handleChange = (k, v) => {
    const c = { ...child, [k]: v };
    onChange(c);
  };

  const fields = {
    0: {
      fullName: {
        type: formTypes.Input,
        val: child.fullName,
        name: "Full Name",
        required: true
      },
      birthDate: {
        type: formTypes.Datewith,
        val: child.birthDate,
        name: "Birth Date",
        required: true,
        maxDate: new Date()
      },
      gender: {
        type: formTypes.GenderDropdownSelect,
        val: child.gender,
        name: "Gender",
        required: true
      },
      languages: {
        type: formTypes.LanguageSelect,
        val: child.languages,
        name: "Languages",
        required: true
      },
      allergies: {
        type: formTypes.MultiInput,
        val: child.allergies,
        name: "Allergies",
        required: true
      },
      immunized: {
        type: formTypes.YesNoDropdownSelect,
        val: child.immunized,
        name: "Immunized",
        required: true
      },
      medication: {
        type: formTypes.MultiInput,
        val: child.medication,
        name: "Medications",
        required: true
      }
    },
    1: {
      specialFood: {
        type: formTypes.MultiInput,
        val: child.specialFood,
        name: "Special Food"
      },
      pottyTrained: {
        type: formTypes.YesNoDropdownSelect,
        val: child.pottyTrained,
        name: "Potty Trained"
      },
      healthConcerns: {
        type: formTypes.MultiInput,
        val: child.healthConcerns,
        name: "Health Concerns"
      },
      likes: {
        type: formTypes.MultiInput,
        val: child.likes,
        name: "Likes"
      },
      dislikes: {
        type: formTypes.MultiInput,
        val: child.dislikes,
        name: "Dislikes"
      },
      comments: {
        type: formTypes.Input,
        val: child.comments,
        name: "Comments"
      }
    }
  };

  const tabs = [{ name: "Profile" }, { name: "Additional Info" }];

  return (
    <BoxFormTabbed
      handleChange={handleChange}
      name={"CHILD " + (childNo + 1)}
      tabs={tabs}
      fields={fields}
      object={child}
      removable={true}
      handleRemove={onRemove}
      removeAfterSaved={false}
    />
  );
};

export default ChildProfile;
