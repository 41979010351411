import React from "reactn";
import { Input, Modal } from "../../../components/UI";
import axios from "axios";
import { toast } from "react-toastify";

class PricingPlanCategoryModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      type: "service"
    };
    this.addPricingPlanCategory = this.addPricingPlanCategory.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props.open) {
      this.setState({
        name: props.data && props.data.name ? props.data.name : ""
      });
    }
  }

  addPricingPlanCategory = async () => {
    const ep = `${process.env.REACT_APP_API}/stripe/product`;
    try {
      this.setGlobal({ loading: true });
      let vals = { ...this.state };
      if (this.props.params && this.props.params.id) {
        vals["dsUserId"] = this.props.params.id;
      }
      let res = null;
      if (this.props.data && this.props.data.id) {
        res = await axios.patch(ep, { ...vals, id: this.props.data.id });
      } else {
        res = await axios.post(ep, vals);
      }
      this.setState({ name: "" });
      this.setGlobal({ loading: false, lastAPICall: res });
      if (res.data.success) {
        toast("Added new pricing plan category");
      }
      this.props.onClose(res.data.data);
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  };

  render() {
    const { open, onClose } = this.props;
    return (
      <div>
        <Modal
          open={open}
          heading="New Pricing Plan Category"
          onClose={() => {
            onClose(null);
          }}
        >
          <div className="row">
            <div className="col-12">
              <p>
                <strong>Category Name:</strong>
              </p>
            </div>
            <div className="col-12">
              <Input
                name="name"
                value={this.state.name}
                onChange={e => {
                  this.setState({ name: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="buttons">
                <button
                  onClick={this.addPricingPlanCategory}
                  className="nextbtn"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default PricingPlanCategoryModal;
