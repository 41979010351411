import React from "reactn";
import { isAfter } from "date-fns";
import FranchiserOrdersModal from "../../../../components/modals/FranchiserOrdersModal";
import FranchiserTrackOrders from "./FranchiserTrackOrders";
import axios from "axios";

class FranchiserOrders extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      partnerIds: parseInt(this.global.franchiseUser.selectedId) === -1 ? this.global.franchiseUser.organizations : [this.global.franchiseUser.selectedId],
      selectedOption: this.global.franchiseUser.selectedId,
      partnerOrg: {
        user: {},
        orderInfo: {
          pendingCount: 0,
          paidCount: 0,
          failedCount: 0,
          refunds: 0,
          totalEarnings: 0
        }
      },
      data: {
        Pending: [],
        Payments: []
      },
    };

    this.emptyPartnerOrg = {
      user: {},
      orderInfo: {
        pendingCount: 0,
        paidCount: 0,
        failedCount: 0,
        refunds: 0,
        totalEarnings: 0
      }
    };

    this.emptyData = {
      Pending: [],
      Payments: []
    };
  }
  async componentDidMount() {
    await this.fetchInitialData();
  }

  async componentDidUpdate() {
    if (parseInt(this.state.selectedOption) !== parseInt(this.global.franchiseUser.selectedId) || this.state.partnerIds.length === 0) {
      this.setState({
        partnerOrg: {
          orderInfo: {
            pendingCount: 0,
            paidCount: 0,
            failedCount: 0,
            refunds: 0,
            totalEarnings: 0
          },
          user: {}
        },
        data: {
          Pending: [],
          Payments: []
        }
      });

      if (parseInt(this.global.franchiseUser.selectedId) === -1) {
        this.setState({partnerIds: this.global.franchiseUser.organizations, selectedOption: -1}, async () => {
          await this.fetchInitialData();
        });
      } else {
        this.setState({partnerIds: [this.global.franchiseUser.selectedId], selectedOption: this.global.franchiseUser.selectedId}, async () => {
          await this.fetchInitialData();
        });
      }
    }
  }
  
  async fetchInitialData() {
    this.setGlobal({loading: true});
    let counter = 0;
    this.state.partnerIds.forEach(async (partnerId, i, ids) => {
      const ep = `${process.env.REACT_APP_API}/partners/order-tracking`;
      const ep2 = `${process.env.REACT_APP_API}/franchise/get-partner/${partnerId}`;
      const res = await axios.get(ep, { params: { partnerId: partnerId } });
      const res2 = await axios.get(ep2);

      if (res.data?.success) {
        const pending = res.data.data.Once.pending.filter(e => !e.hidden).map((order) => ({...order, company: res2.data.success ? res2.data.data.company : "N/A"}));
        const payments = [
          ...res.data.data.Once.failed,
          ...res.data.data.Once.payments
        ].filter(e => !e.hidden).map((order) => ({...order, company: res2.data.success ? res2.data.data.company : "N/A"}));
        
        const orderInfo = this.state.partnerOrg.orderInfo;
        console.log("data", this.state.data);
        this.setState({
          data: {
            Pending: [...this.state.data.Pending, ...pending]
              .sort(
                (e1, e2) =>
                  new Date(e2.createdDate).getTime() - new Date(e1.createdDate).getTime()
              ),
            Payments: [...this.state.data.Payments, ...payments]
              .sort(
                (e1, e2) =>
                  new Date(e2.createdDate).getTime() - new Date(e1.createdDate).getTime()
              )
          },
          partnerOrg: {
            ...this.state.partnerOrg,
            orderInfo: {
              pendingCount: orderInfo.pendingCount + pending.length,
              paidCount: orderInfo.paidCount + payments.length,
              failedCount: orderInfo.failedCount + res.data.data.Once.failed.length,
              totalEarnings: orderInfo.totalEarnings + res.data.data.Once.payments.reduce(
                (prev, curr) => prev + parseInt(curr.total) / 100, 0
              ),
              refunds: orderInfo.refunds + res.data.data.Once.payments.reduce(
                (prev, curr) => prev + parseInt(curr.refundedAmount) / 100, 0
              )
            }
          }
        });
      } else {
        console.error("Something went wrong fetching the data");
      }

      const createdAt = new Date(this.state.partnerOrg.user.createdAt);
      if (res2.data.success && (!this.state.partnerOrg.user.createdAt || isAfter(createdAt, new Date(res2.data.data.createdAt)))) {
        this.setState({
          partnerOrg: {
            ...this.state.partnerOrg,
            user: res2.data.data
          }
        });
      } else {
        console.error("Something went wrong while fetching for Partner");
      }

      counter++;
      if (counter === ids.length)
        this.setGlobal({loading: false});
    });
  }

  render() {
    return (
      <div className="container-fluid my_earning">
        <div className="cont heading-wrapper">
          <h1 className="page-heading">Order Tracking</h1>
        </div>
        <div className="cont">
          <FranchiserOrdersModal partnerOrg={this.global.loading ? this.emptyPartnerOrg : this.state.partnerOrg} />
          <div className="boxes">
            <FranchiserTrackOrders data={this.global.loading ? this.emptyData: this.state.data} />
          </div>
        </div>
      </div>
    );
  }
};

export default FranchiserOrders;