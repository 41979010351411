import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { format, addMonths, addWeeks, getDay, differenceInMinutes } from "date-fns";
import BaseModal from "./baseModal/BaseModal";
import { DROPDOWN, GET_HTML } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import Select from "react-dropdown-select";
import { fetchConnectionsData } from "../../Caching";
class InvoiceCustomerModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      color: 0,
      date: null,
      customers: [],
      selectedCustomer: undefined
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async selectCustomer(e) {
    e.preventDefault();
    const main_user = this.state.customers.filter(
      e =>
        e.classification === "active" &&
        e.id === parseInt(this.state.selectedCustomer)
    );

    console.log("main_user", main_user);

    if (this.state.selectedCustomer) {
      if (main_user[0].came_from === "Added Contact") {
        console.log("Added Contact");
        const ep = `${process.env.REACT_APP_API}/partners/users/invoice/create_user`;
        const res = await axios.post(ep, {
          displayName: main_user[0].displayName,
          email: main_user[0].email
        });
        console.log("res", res);

        const ep2 = `${process.env.REACT_APP_API}/partners/orders/invoice/initialize-draft`;
        const results = await axios.post(ep2, {
          partnerId: this.global.dsUser.id,
          dsUserId: res.data.data.id,
          isNewUser: false
        });
        console.log("results", results);

        if (results.data.success) {
          this.props.history.push({
            pathname: `orders/invoice-builder/${results.data.data.id}`,
            state: { selectedCustomer: [res.data.data], isNewCustomer: true }
          });
        }
      } else {
        const ep = `${process.env.REACT_APP_API}/partners/orders/invoice/initialize-draft`;
        const results = await axios.post(ep, {
          partnerId: this.global.dsUser.id,
          dsUserId: main_user[0].dsUserId,
          orderStartDate: new Date(),
          due_date: addWeeks(new Date(), 1),
          service_start_date: new Date(),
          service_end_date: addMonths(new Date(), 1)
        });
        console.log("results", results);

        if (results.data.success) {
          this.props.history.push({
            pathname: `orders/invoice-builder/${results.data.data.id}`,
            state: { selectedCustomer: main_user, isNewCustomer: false }
          });
        }
      }
    } else {
      toast.warn("Select a customer to create invoice");
    }
  }

  async componentDidMount() {
    this.setGlobal({ loading: true });
    const connections = await fetchConnectionsData(this.global.dsUser.id);
    const data = [];

    connections
      .filter(f => !f.isArchived)
      .sort((a, b) => {
        if (a.classification < b.classification) {
          return -1;
        }
        if (a.classification > b.classification) {
          return 1;
        }
        return 0;
      })
      .map(e => {
        let childNames = [];
        const allChild = connections
          .filter(f => !f.isArchived)
          .filter(h => h.dsUserId == e.dsUserId)
          .map(
            k =>
              !childNames.filter(f => f == k.childName)[0] &&
              k.childName &&
              childNames.push(k.childName)
          );
        !data.filter(f => f.email === e.email)[0] &&
          data.push({
            ...e,
            childNames,
            childNamesString: childNames.join(", ")
          });
      });

    this.setState({
      customers: data
        .filter(f => !f.isArchived)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
      loadingComplete: true
    });
    
    this.setGlobal({ loading: false });
  }

  existingClientModal = () => {
    return (
      <div>
        <div>
          <label
            htmlFor="displayName"
            style={{
              width: "50%",
              textAlign: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            Existing Customers{" "}
            <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
          </label>
        </div>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <div
            style={{
              height: "40px",
              width: "100%",
              fontSize: "0.93rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "400",
              color: "#797979"
            }}
          >
            <Select
              options={this.getAssignOptions()}
              name={"selectedUser"}
              placeholder={"Please Select"}
              style={{
                height: "40px",
                width: "100%",
                fontSize: "0.93rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#797979",
                borderRadius: "4px"
              }}
              onChange={e => {
                this.setState({
                  selectedCustomer: e.length === 0 ? null : e[0].value
                });
              }}
              labelField={"text"}
              valueField={"value"}
              closeOnSelect={true}
              searchable={true}
              keepSelectedInList={true}
              dropdownHandle={true}
              clearable={true}
              searchBy={"text"}
              loading={!this.state.loadingComplete}
            />
          </div>
        </div>
      </div>
    );
  };

  /// ================== NEW MODAL ========================== ///
  getAssignOptions = () => {
    return [
      ...this.state.customers
        .filter(e => e.classification === "active")
        .map(({ id, displayName }) => ({ value: id, text: displayName }))
        .sort((a, b) => a.text.localeCompare(b.text))
    ];
  };

  getBaseModalFields() {
    const nameInput = {
      type: DROPDOWN,
      data: {
        name: "Existing Customers",
        required: true,
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            selectedCustomer: e.target.value
          });
        },
        value: this.state.selectedCustomer,
        choices: this.getAssignOptions()
      },
      validators: {
        validateSubmit: () => this.state.selectedCustomer !== undefined
      }
    };

    const nameInputNew = {
      type: GET_HTML,
      data: {
        gethtml: this.existingClientModal
      }
    };

    return [nameInputNew];
  }

  getBaseModalButtons = () => {
    const addcustomerButton = {
      name: "Add Customer",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      handleClick: _ => {
        this.props.onClose();
        this.props.createNewCustomer(
          this.state.customers.filter(e => e.classification === "pending")
        );
      }
    };

    const createinvoiceButton = {
      name: "Create Invoice",
      handleClick: e => this.selectCustomer(e),
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit"
    };

    return [addcustomerButton, createinvoiceButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Create New Invoice",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ===================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(InvoiceCustomerModal);
