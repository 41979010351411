import React from "reactn";
import { Link } from "react-router-dom";
import axios from "axios";
import RequestTourModal from "../../components/modals/RequestTourModal";
import RequestInformationModal from "../../components/modals/RequestInformationModal";

class Application extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      programs: [],
      tourModal: false,
      infoModal: false,
      selectedProgram: null
    };
    this.tourClose = this.tourClose.bind(this);
    this.infoClose = this.infoClose.bind(this);
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "Interested Programs"]
    });
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/par/enrollment/interested`;
    this.setGlobal({ loading: true });
    const programs = await axios.get(ep);
    this.setState({ programs: programs.data.data });
    this.setGlobal({ loading: false, lastAPICall: programs });
    try {
    } catch (err) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  tourClose(success) {
    let programs = this.state.programs;
    if (success) {
      programs = programs.map(p =>
        p.slug === this.state.selectedProgram.slug
          ? { ...p, requestedTour: true }
          : p
      );
    }
    this.setState({
      programs: programs,
      tourModal: false,
      selectedProgram: null
    });
  }

  infoClose(success) {
    let programs = this.state.programs;
    if (success) {
      programs = programs.map(p =>
        p.slug === this.state.selectedProgram.slug
          ? { ...p, requestedInfo: true }
          : p
      );
    }
    this.setState({
      programs: programs,
      infoModal: false,
      selectedProgram: null
    });
  }

  render() {
    return (
      <>
        {!this.state.tourModal ? null : (
          <RequestTourModal
            program={this.state.selectedProgram}
            onClose={this.tourClose}
          />
        )}
        {!this.state.infoModal ? null : (
          <RequestInformationModal
            program={this.state.selectedProgram}
            onClose={this.infoClose}
          />
        )}
        <div className="container-fluid interested_programs">
          <div className="cont">
            <h1>Interested Programs</h1>
          </div>
          <div className="cont">
            <div className="boxes-row ip-boxes">
              {this.state.programs.map((program, key) => {
                return (
                  <div className="interested-program-box" key={key}>
                    <div className="imghere">
                      <div
                        className="divforimg"
                        style={{
                          backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${program.programCoverPhoto})`,
                          width: "100%",
                          height: "210px",
                          backgroundPosition: "center",
                          backgroundSize: "cover"
                        }}
                      >
                        <div className="paragraph">
                          <p>{program.programName}</p>
                        </div>

                        {/* <img src={HeartIcon} className="heart" alt="" /> */}
                      </div>
                    </div>

                    <div className="continbox">
                      <div className="row">
                        <div className="col-4">
                          <h2>Completed:</h2>
                        </div>
                        <div className="col-8">
                          <p>
                            {program.applicationStage === "Applicant"
                              ? "Applicant"
                              : `Requested ${program.applicationStage}`}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="continbox">
                      <div className="row">
                        <div className="col-12">
                          <p className="justify-evenly">
                            <button
                              className={
                                program.requestedInfo ? "complete" : ""
                              }
                              disabled={program.requestedInfo}
                              onClick={() =>
                                this.setState({
                                  selectedProgram: program,
                                  infoModal: true
                                })
                              }
                            >
                              INFO
                            </button>
                            <button
                              disabled={program.requestedTour}
                              className={
                                program.requestedTour ? "complete" : ""
                              }
                              onClick={() =>
                                this.setState({
                                  selectedProgram: program,
                                  tourModal: true
                                })
                              }
                            >
                              TOUR
                            </button>
                            <Link
                              to={`/parents/interested-programs/${program.slug}/choose-child`}
                              onClick={() => {
                                window.scrollTo(0, 0);
                              }}
                            >
                              APPLY
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Application;
