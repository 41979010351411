import React from "react";
import "../../assets/css/componentSpecificCss/categoryTags.css";
import Tag from "../../assets/img/tag.png";

const CategoryTags = ({ category, fontSize, paddingTop, paddingBottom }) => {
  return (
    <div className="category-tag" style={{fontSize, paddingTop, paddingBottom}}>
      <img src={Tag} />
      <span> { category } </span>
    </div>
  );
}

export default CategoryTags;