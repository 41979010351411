import React from "reactn";
import { Table } from "../../../components/UI";
import axios from "axios";
import { format } from "date-fns";
import Avatar from "../../../assets/img/msgicon.png";

class Connections extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ccContacts: []
    };
    this.headings = [
      {
        id: "conDisplayName",
        label: "Connection",
        customCell: this.parentCell
      },
      {
        id: "createdAt",
        label: "Date Connected",
        customCell: this.createdAtCell
      },
      {
        id: "lastMessage",
        label: "Last Contact",
        customCell: this.lastContactCell
      },
      { id: "userStatus", label: "User Status", customCell: this.statusCell }
    ];
  }

  parentCell = (r, i) => {
    return (
      <td key={`${i}-pa`}>
        <div
          style={{
            backgroundImage: `url(${
              r.conPhoto
                ? `${process.env.REACT_APP_DS_FILES_S3}/${r.conPhoto}`
                : Avatar
            })`,
            backgroundPosition: "center",
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            backgroundSize: "cover",
            display: "inline-block",
            verticalAlign: "middle"
          }}
          className="mr-3"
        ></div>
        {r.conDisplayName}
      </td>
    );
  };

  createdAtCell = (r, i) => {
    return (
      <td key={`${i}-ca`}>
        {format(new Date(r.createdAt), "LLL dd, yyyy - h:mm a")}
      </td>
    );
  };

  lastContactCell = (r, i) => {
    return (
      <td key={`${i}-lm`}>
        {format(new Date(r.lastMessage), "LLL dd, yyyy - h:mm a")}
      </td>
    );
  };

  statusCell = (r, i) => {
    return (
      <td key={`${i}-st`}>
        {r.isGuest
          ? "Guest User"
          : r.enrEduResponse
          ? `Applicant (${r.enrEduResponse})`
          : r.conAccountType === "Admin"
          ? "Dreamschools Team"
          : "Interested Parent"}
      </td>
    );
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/shared/connection/${this.props.program.profile.dsUserId}`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      this.setGlobal({
        loading: false,
        lastAPICall: res
      });
      this.setState({ ccContacts: res.data.data });
    } catch (ex) {
      this.setGlobal({ loading: false, lastAPICall: null, ccContacts: [] });
    }
  }

  render() {
    return (
      <div className="cont">
        <Table headings={this.headings} data={this.state.ccContacts} />
      </div>
    );
  }
}

export default Connections;
