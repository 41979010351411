import React from "react";

import { ArrowBack, Refresh } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

const MessageViewHeader = ({ text, isSmallScreen, onBack, onRefresh }) => {
  const mainText = (text instanceof Array) ? text[0] : text;
  const secondaryText = (text instanceof Array) ? text[1] : "";

  return (
    <div className="message-top">
      {isSmallScreen && (
        <div
          style={{
            padding: "0",
            position: "absolute",
            left: "2px",
            top: "2px",
            zIndex: "5"
          }}
        >
          <div>
            <IconButton style={{ color: "white" }} onClick={onBack}>
              <ArrowBack />
            </IconButton>
          </div>
        </div>
      )}
      <span className="message-heading" title={(mainText+' '+secondaryText)}>
        {mainText} &nbsp;
        {secondaryText && (<span className="secondary">{secondaryText}</span>)}
      </span>
      <div
        style={{
          padding: "0",
          position: "absolute",
          right: "2px",
          top: "2px",
          zIndex: "5"
        }}
      >
        <div>
          <IconButton style={{ color: "white" }} onClick={onRefresh}>
            <Refresh />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default MessageViewHeader;
