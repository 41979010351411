import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
// import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";
import "../../assets/css/componentSpecificCss/inviteUserModal.css";
import _ from "lodash";
import BaseModal from "./baseModal/BaseModal";
import {
  STRING_INPUT,
  IMAGE_DROPDOWN,
  SWITCH,
  DROPDOWN
} from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class EditScheduleModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      start_hour: "",
      start_minute: "",
      start_time_period: "",
      end_hour: "",
      end_minute: "",
      end_time_period: ""
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async componentDidUpdate() {}

  async editSchedule() {
    console.log(
      "SCHEDULE",
      this.state.start_hour,
      this.state.start_minute,
      this.state.start_time_period,
      this.state.end_hour,
      this.state.end_minute,
      this.state.end_time_period
    );

    const startHour = parseInt(this.state.start_hour);
    const startMinute = parseInt(this.state.start_minute);
    const endHour = parseInt(this.state.end_hour);
    const endMinute = parseInt(this.state.end_minute);
    const CONVERT_TIME_PM = 720;

    const startTime =
      this.state.start_time_period === "AM"
        ? startHour + startMinute
        : startHour + startMinute + CONVERT_TIME_PM;
    const endTime =
      this.state.end_time_period === "AM"
        ? endHour + endMinute
        : endHour + endMinute + CONVERT_TIME_PM;

    if (startTime >= endTime) {
      toast.error("Start Time must be before end time!");
      return;
    }

    console.log(
      "SCHEDULE FINAL",
      startTime,
      endTime,
      this.props.idToEdit,
      this.props.dayToEdit
    );
    // const res = await axios.post(
    //     `${process.env.REACT_APP_API}/partners/users/update-user-info/${this.props.match.params.id}`,
    //     { schedule: this.state.schedule }
    //   );
  }

  getBaseModalFields() {
    const startHour = {
      type: DROPDOWN,
      data: {
        name: "Start Hour",
        required: true,
        placeholder: "Please Select",
        value: this.state.start_hour,
        handleChange: e => {
          this.setState({
            start_hour: e.target.value
          });
        },
        choices: this.hourOptions()
      },
      validators: { validateSubmit: () => this.state.start_hour !== "" }
    };

    const startMinute = {
      type: DROPDOWN,
      data: {
        name: "Start Minute",
        required: true,
        placeholder: "Please Select",
        value: this.state.start_minute,
        handleChange: e => {
          this.setState({
            start_minute: e.target.value
          });
        },
        choices: this.minuteOptions()
      },
      validators: { validateSubmit: () => this.state.start_minute !== "" }
    };

    const startTimePeriod = {
      type: DROPDOWN,
      data: {
        name: "Start Time Period",
        required: true,
        placeholder: "Please Select",
        value: this.state.start_time_period,
        handleChange: e => {
          this.setState({
            start_time_period: e.target.value
          });
        },
        choices: this.timePeriodOptions()
      },
      validators: { validateSubmit: () => this.state.start_time_period !== "" }
    };

    const endHour = {
      type: DROPDOWN,
      data: {
        name: "End Hour",
        required: true,
        placeholder: "Please Select",
        value: this.state.end_hour,
        handleChange: e => {
          this.setState({
            end_hour: e.target.value
          });
        },
        choices: this.hourOptions()
      },
      validators: { validateSubmit: () => this.state.end_hour !== "" }
    };

    const endMinute = {
      type: DROPDOWN,
      data: {
        name: "End Minute",
        required: true,
        placeholder: "Please Select",
        value: this.state.end_minute,
        handleChange: e => {
          this.setState({
            end_minute: e.target.value
          });
        },
        choices: this.minuteOptions()
      },
      validators: { validateSubmit: () => this.state.end_minute !== "" }
    };

    const endTimePeriod = {
      type: DROPDOWN,
      data: {
        name: "End Time Period",
        required: true,
        placeholder: "Please Select",
        value: this.state.end_time_period,
        handleChange: e => {
          this.setState({
            end_time_period: e.target.value
          });
        },
        choices: this.timePeriodOptions()
      },
      validators: { validateSubmit: () => this.state.end_time_period !== "" }
    };

    return [
      startHour,
      startMinute,
      startTimePeriod,
      endHour,
      endMinute,
      endTimePeriod
    ];
  }

  hourOptions = () => {
    const options = [];
    for (let hour = 1; hour <= 12; hour++) {
      const time = hour.toString().padStart(2, "0") + ":00";
      options.push({
        value: hour * 60,
        key: hour * 60,
        text: time
      });
    }
    return options;
  };

  minuteOptions = () => {
    const options = [];
    for (let minute = 0; minute < 60; minute++) {
      const time = minute.toString().padStart(2, "0");
      options.push({
        value: minute,
        key: minute,
        text: time
      });
    }
    return options;
  };

  timePeriodOptions = () => {
    const options = [
      { value: "AM", text: "AM" },
      { value: "PM", text: "PM" }
    ];
    return options;
  };

  getBaseModalButtons = () => {
    const addButton = {
      name: "Confirm",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.editSchedule()
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Edit Schedule",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(EditScheduleModal);
