import React from "reactn";
import { withStyles, Typography, Chip } from "@material-ui/core";
import EduAvatar from "../../assets/img/educator.png";
import { FavoriteBorder, Favorite } from "@material-ui/icons";
import Cognition from "../../assets/img/ObservationCognition.jpg";
import Communication from "../../assets/img/ObservationCommunication.jpg";
import Emotional from "../../assets/img/ObservationEmotional.jpg";
import Physical from "../../assets/img/ObservationPhysical.jpg";
import Social from "../../assets/img/ObservationSocial.jpg";
import Other from "../../assets/img/ObservationOther.jpg";
import Axios from "axios";
const styles = theme => ({
  paper: {
    textAlign: "center",
    marginTop: "30px"
  },
  container: {
    marginTop: "30px",
    width: "65%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexWrap: "wrap",
    marginBottom: "20px",
    "& .post": {
      width: "60%",
      backgroundColor: "white",
      boxSizing: "border-box",
      borderRadius: "20px",
      margin: "0 auto",
      marginBottom: "80px",
      "& .profileimg": {
        width: "50px",
        height: "50px"
      }
    }
  },
  author: {
    display: "flex",
    alignItems: "center",
    width: "90%",
    margin: "20px auto",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
      borderRadius: "50%",
      border: "2px solid #c7c7c7",
      alignSelf: "stretch"
    }
  },
  authorname: {
    flex: 1,
    marginLeft: "10px"
  },
  postImage: {
    width: "90%",
    margin: "0 auto 15px auto",
    "& img": {
      width: "100%",
      height: "300px",
      objectFit: "cover",
      borderRadius: "20px"
    }
  },
  likes: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "20px",
    width: "90%",
    margin: "5px auto"
  },
  description: {
    width: "90%",
    margin: "5px auto",
    color: "grey"
  },
  chip: {
    margin: "3px"
  },
  [theme.breakpoints.down("md")]: {
    container: {
      width: "50%",
      display: "flex",
      margin: "0 auto",
      flexDirection: "column",
      "& .post": {
        width: "100%"
      }
    }
  },
  [theme.breakpoints.down("xs")]: {
    container: {
      width: "90%",
      display: "flex",
      margin: "0 auto",
      flexDirection: "column",
      "& .post": {
        width: "100%"
      }
    }
  }
});
const imgMapping = {
  Social: Social,
  Physical: Physical,
  "Communication Language & Literacy": Communication,
  Emotional: Emotional,
  Cognition: Cognition
};
function calculateTimeElapsed(date) {
  const timeinSeconds =
    (new Date().getTime() - new Date(date).getTime()) / 1000;
  if (timeinSeconds >= 86400)
    return Math.floor(timeinSeconds / 86400) > 1
      ? `${Math.floor(timeinSeconds / 86400)} days`
      : `${Math.floor(timeinSeconds / 86400)} day`;
  if (timeinSeconds >= 3600)
    return Math.floor(timeinSeconds / 3600) > 1
      ? `${Math.floor(timeinSeconds / 3600)} hours`
      : `${Math.floor(timeinSeconds / 3600)} hour`;
  if (timeinSeconds >= 60)
    return Math.floor(timeinSeconds / 60) > 1
      ? `${Math.floor(timeinSeconds / 60)} mins`
      : `${Math.floor(timeinSeconds / 60)} min`;
  return Math.floor(timeinSeconds) + " seconds";
}
class NewsFeed extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      observations: []
    };
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "News Feed"]
    });
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/observations/feed`;
    const results = await Axios.get(ep);

    if (results.data.success) {
      this.setState({
        observations: results.data.data
      });
    }
  }

  like(id) {
    const ep = `${process.env.REACT_APP_API}/observations/like`;
    const Obs = [...this.state.observations];
    const index = Obs.indexOf(Obs.find(e => e.id === id));
    Obs[index].likes = {
      ...Obs[index].likes,
      [this.global.dsUser.id]: Obs[index].likes
        ? !Obs[index].likes[this.global.dsUser.id]
        : true
    };
    this.setState({ observations: Obs });
    Axios.post(ep, { id });
  }

  render() {
    const { classes } = this.props;
    return (
      // <!-- Begin Page Content -->
      <div className="container-fluid hero">
        <div className="cont">
          <h1>News Feed</h1>
          <div className={classes.container}>
            {this.state.observations.map(observation => (
              <div
                className="post"
                key={observation.id}
                style={{
                  boxShadow:
                    "0 4px 15px 0 rgba(0,0,0,.1), 0 25px 45px 0 rgba(0,0,0,.08)"
                }}
              >
                <div className={classes.author}>
                  <img
                    src={
                      observation.photo
                        ? `${process.env.REACT_APP_DS_FILES_S3}/${observation.photo}`
                        : EduAvatar
                    }
                    alt="profilepic"
                    className="profileimg"
                  />
                  <Typography
                    variant="subtitle1"
                    style={{ marginBottom: 0, fontWeight: "bold" }}
                    className={classes.authorname}
                  >
                    {observation.displayName} <br />
                  </Typography>
                  <Typography variant="subtitle2" style={{ marginBottom: 0 }}>
                    {calculateTimeElapsed(observation.createdAt)} ago
                  </Typography>
                </div>
                <div className={classes.postImage}>
                  <img
                    src={
                      observation.image
                        ? `${process.env.REACT_APP_DS_FILES_S3}/${observation.image}`
                        : imgMapping[observation.domain]
                        ? imgMapping[observation.domain]
                        : Other
                    }
                    alt="postimage"
                  />
                </div>

                <div className={classes.description}>
                  <Typography
                    variant="subtitle2"
                    style={{
                      marginBottom: "10px",
                      borderBottom: "1px solid #c7c7c7",
                      paddingBottom: "5px",
                      color: "#83bdf7"
                    }}
                  >
                    <b>
                      {observation.domain
                        ? observation.domain.toLocaleUpperCase()
                        : "OTHER"}
                    </b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {observation.notes}
                  </Typography>
                </div>
                <div className={classes.likes}>
                  <Typography
                    variant="subtitle1"
                    style={{ flexGrow: 1, marginBottom: 0 }}
                  >
                    <b>
                      {observation.skill && (
                        <Chip
                          variant="default"
                          label={
                            observation.skill.length > 25
                              ? observation.skill.slice(0, 25) + "..."
                              : observation.skill
                          }
                          style={{ backgroundColor: "#83bdf7", color: "white" }}
                          className={classes.chip}
                        />
                      )}
                    </b>
                  </Typography>
                  {observation.likes ? (
                    observation.likes[this.global.dsUser.id] ? (
                      <Favorite
                        style={{
                          color: "red",
                          cursor: "pointer"
                        }}
                        onClick={() => this.like(observation.id)}
                      />
                    ) : (
                      <FavoriteBorder
                        style={{
                          color: "red",
                          cursor: "pointer"
                        }}
                        onClick={() => this.like(observation.id)}
                      />
                    )
                  ) : (
                    <FavoriteBorder
                      style={{
                        color: "red",
                        cursor: "pointer"
                      }}
                      onClick={() => this.like(observation.id)}
                    />
                  )}
                  <Typography
                    variant="subtitle2"
                    style={{ marginLeft: "10px", marginBottom: "0" }}
                  >
                    {observation.likes
                      ? Object.keys(observation.likes).filter(
                          e => observation.likes[e]
                        ).length
                      : 0}
                  </Typography>
                </div>

                {/*<div className={classes.description}>
                  {observation.tags && (
                    <>
                      {observation.tags.split(",").map((e, i) => (
                        <Chip
                          variant="default"
                          label={e}
                          color="primary"
                          key={i}
                          className={classes.chip}
                        />
                      ))}
                    </>
                  )}
                      </div>*/}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(NewsFeed);
