import React from "reactn";
import ScrollToBottom from "react-scroll-to-bottom";

import { format } from "date-fns";

import ReactTooltip from "react-tooltip";

import {
  ParentMessage,
  Letter,
  Reporting10 as SMS,
  RightMsgArrow, Megaphone
} from "../../../../assets";
import { getLastDigits, formatPhoneNumber } from "./commUtil";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";




/**
 * Displays SMS from a custom number when the default Dreamschools number is being used.
 *
 * @param {Object[]} messages - An array of message objects containing message (string) and date (Date)
 */
const CustomSMSChat = ({ messages }) => {
  const partnerPhoto = Megaphone


  return (
    <div className="message-center">
      <ScrollToBottom className="partnermsg">
        {
          messages.sort((a, b) => a.date > b.date ? 1 : -1).map((m, i) => {
            return (
              <div key={i} className="floatright">
                <div className="rightsidemsg">
                  <div className="row">
                    <div className="col-10">
                      <div className="msg">
                        <p>
                          <img
                            src={RightMsgArrow}
                            className="rightmsgarrow"
                            alt=""
                          />
                          {m.message}{" "}
                        </p>

                        <p className="time">
                          {format(
                            new Date(m.date),
                            "LLL dd, yyyy - hh:mm:ss aa"
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="col-2">
                      <div
                        className="divforimg"
                        style={{
                          backgroundImage: `url(${partnerPhoto})`,
                          backgroundPosition: "center",
                          width: "45px",
                          height: "45px",
                          borderRadius: "50%",
                          backgroundSize: "cover"
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </ScrollToBottom>

      <div
        style={{
          padding: "0 5px",
          margin: "5px",
          position: "absolute",
          bottom: "0px",
          width: "100%"
        }}
      >
        <p style={{textAlign: "center"}}>
          You do not have a custom number. This chat functionality is limited to one-way messaging. Contact Dreamschools for more information.
        </p>

      </div>
    </div>
  );
};

export default CustomSMSChat;
