import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "../../assets/css/componentSpecificCss/applicationViewModal.css";
import moment from "moment";
import {
  format,
  addWeeks,
  addDays,
  addHours,
  subDays,
  isAfter
} from "date-fns";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
// import { ReactPDF, PDFViewer , BlobProvider, PDFDownloadLink, pdf, Document, Page } from '@react-pdf/renderer';
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import SwitchEnrollmentModal from "../../components/modals/SwitchEnrollmentModal";
import ApplicationDenyNotificationModal from "./ApplicationDenyNotificationModal";
import {
  CONTENT_BLOCK,
  BUTTON,
  TEXT_AREA,
  IMAGE_CONTENT,
  DROPDOWN
} from "./baseModal/FieldTypes";
import BaseModal from "./baseModal/BaseModal";
import ApplicationCancelModal from "./ApplicationCancelModal";
import {
  BLUE_BUTTON,
  GREEN_BUTTON,
  RED_BUTTON,
  WHITE,
  YELLOW_BUTTON
} from "./baseModal/colours";
import { result } from "lodash";

import ViewPartyAddonsModal from "./ViewPartyAddonsModal";
import EditPartyStartDateModal from "./EditPartyStartDateModal";
import EditPartyAttendeesModal from "./EditPartyAttendeesModal";

const daysOfWeekConversion = day => {
  const table = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  };
  return table[day];
};

const getFirstDay = (startingDate, daysOfWeek) => {
  // Code to compensate for the local timezone of the starting date
  startingDate.setTime(
    startingDate.getTime() + startingDate.getTimezoneOffset() * 60 * 1000
  );

  if (!daysOfWeek) {
    return undefined;
  }
  if (Object.keys(daysOfWeek).length === 0) return;

  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  while (true) {
    if (convertedDaysOfWeek.includes(startingDate.getDay())) {
      return startingDate;
    }
    startingDate = addDays(startingDate, 1);
  }
};

const getLastDay = (lastDay, daysOfWeek) => {
  console.log("lastOfWeek...", daysOfWeek);
  if (!daysOfWeek) {
    return undefined;
  }
  if (Object.keys(daysOfWeek).length === 0) return;
  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  lastDay = addWeeks(lastDay, 1);
  lastDay = subDays(lastDay, 1);
  while (true) {
    if (convertedDaysOfWeek.includes(lastDay.getDay())) {
      return lastDay;
    }
    lastDay = subDays(lastDay, 1);
  }
};

class ConnectionsApplicationViewModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      formSections: [],
      displayPDF: false,
      isMembershipAutoRenew: false,
      isOpenSwitchModal: false,
      customFormData: [],
      customFormDataObj: {},
      filteredSections: [],
      toStatusStage: "Inactive",
      idToEdit: 0,
      couponList: [],
      hideCouponField: false,
      selectedCoupon: this.props.program.couponId,
      coupon: this.props.program.coupon ? this.props.program.coupon : null,
      allowDropdown: false,
      openCancelApplicationModal: false,
      openApplicationDenyNotificationModal: false,
      openViewAddonsModal: false,
      refetch: 0,
      openEditPartyAttendeesModal: false,
      openEditPartyStartDateModal: false,
      parentFormData: []
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  acceptApplication = async applicationInfo => {
    let ep;
    if (this.props.displayCategory === "Programs") {
      ep = `${process.env.REACT_APP_API}/partners/programs/customers/application_accept/${applicationInfo.id}`;
    }
    if (this.props.displayCategory === "Events") {
      ep = `${process.env.REACT_APP_API}/partners/events/customers/application_accept/${applicationInfo.id}`;
    }
    if (this.props.displayCategory === "Memberships") {
      ep = `${process.env.REACT_APP_API}/partners/memberships/customers/application_accept/${applicationInfo.id}`;
    }
    if (this.props.displayCategory === "Online Virtual") {
      ep = `${process.env.REACT_APP_API}/partners/virtual/customers/application_accept/${applicationInfo.id}`;
    }

    let status = "Accepted_Unpaid";

    // if (
    //   this.props.displayCategory === "Events" &&
    //   this.getEventTicketType() === "Free"
    // ) {
    //   status = "Accepted_Paid";
    // }

    const results = await axios.put(ep, {
      status_stage: status,
      message: this.state.message
    });

    if (results.data.success) {
      this.props.refresh();
      this.props.onClose();
    }
  };

  waitlistApplication = async applicationInfo => {
    let ep;
    if (this.props.displayCategory === "Programs") {
      ep = `${process.env.REACT_APP_API}/partners/programs/customers/application_waitlist/${applicationInfo.id}`;
    }
    if (this.props.displayCategory === "Events") {
      ep = `${process.env.REACT_APP_API}/partners/events/customers/application_waitlist/${applicationInfo.id}`;
    }
    if (this.props.displayCategory === "Memberships") {
      ep = `${process.env.REACT_APP_API}/partners/memberships/customers/application_waitlist/${applicationInfo.id}`;
    }
    if (this.props.displayCategory === "Online Virtual") {
      ep = `${process.env.REACT_APP_API}/partners/virtual/customers/application_waitlist/${applicationInfo.id}`;
    }

    const results = await axios.put(ep, {
      status_stage: "Waitlisted_Unpaid",
      message: this.state.message
    });

    if (results.data.success) {
      this.props.refresh();
      this.props.onClose();
    }
  };

  cancelApplication = async applicationInfo => {
    let ep;
    if (this.props.displayCategory === "Programs") {
      ep = `${process.env.REACT_APP_API}/partners/programs/customers/unpaid_cancel/${applicationInfo.id}`;
    }
    if (this.props.displayCategory === "Events") {
      ep = `${process.env.REACT_APP_API}/partners/events/customers/unpaid_cancel/${applicationInfo.id}`;
    }
    if (this.props.displayCategory === "Memberships") {
      ep = `${process.env.REACT_APP_API}/partners/memberships/customers/unpaid_cancel/${applicationInfo.id}`;
    }
    if (this.props.displayCategory === "Online Virtual") {
      ep = `${process.env.REACT_APP_API}/partners/virtual/customers/unpaid_cancel/${applicationInfo.id}`;
    }

    const results = await axios.post(ep, {
      status_stage: "Cancelled_Unpaid",
      message: this.state.message
    });

    if (results.data.success) {
      this.props.refresh();
      this.props.onClose();
    }
  };

  rejectApplication = async (applicationInfo, notification) => {
    let ep;

    if (!notification) {
      if (this.props.displayCategory === "Programs") {
        ep = `${process.env.REACT_APP_API}/partners/programs/customers/application_reject_silent/${applicationInfo.id}`;
      }
      if (this.props.displayCategory === "Events") {
        ep = `${process.env.REACT_APP_API}/partners/events/customers/application_reject_silent/${applicationInfo.id}`;
      }
      if (this.props.displayCategory === "Memberships") {
        ep = `${process.env.REACT_APP_API}/partners/memberships/customers/application_reject_silent/${applicationInfo.id}`;
      }
      if (this.props.displayCategory === "Online Virtual") {
        ep = `${process.env.REACT_APP_API}/partners/virtual/customers/application_reject_silent/${applicationInfo.id}`;
      }
    } else {
      if (this.props.displayCategory === "Programs") {
        ep = `${process.env.REACT_APP_API}/partners/programs/customers/application_reject/${applicationInfo.id}`;
      }
      if (this.props.displayCategory === "Events") {
        ep = `${process.env.REACT_APP_API}/partners/events/customers/application_reject/${applicationInfo.id}`;
      }
      if (this.props.displayCategory === "Memberships") {
        ep = `${process.env.REACT_APP_API}/partners/memberships/customers/application_reject/${applicationInfo.id}`;
      }
      if (this.props.displayCategory === "Online Virtual") {
        ep = `${process.env.REACT_APP_API}/partners/virtual/customers/application_reject/${applicationInfo.id}`;
      }
    }

    const results = await axios.put(ep, {
      status_stage: "Rejected",
      message: this.state.message
    });

    if (results.data.success) {
      this.props.refresh();
      this.props.onClose();
    }
  };

  nudgeApplication = async applicationInfo => {
    let ep;
    if (this.props.displayCategory === "Programs") {
      ep = `${process.env.REACT_APP_API}/partners/programs/customers/application_nudge/${applicationInfo.id}`;
    }
    if (this.props.displayCategory === "Events") {
      ep = `${process.env.REACT_APP_API}/partners/events/customers/application_nudge/${applicationInfo.id}`;
    }
    if (this.props.displayCategory === "Memberships") {
      ep = `${process.env.REACT_APP_API}/partners/memberships/customers/application_nudge/${applicationInfo.id}`;
    }
    if (this.props.displayCategory === "Online Virtual") {
      ep = `${process.env.REACT_APP_API}/partners/virtual/customers/application_nudge/${applicationInfo.id}`;
    }

    const results = await axios.put(ep, {
      nudgeCount: parseInt(this.props.program.nudgeCount) + 1
    });

    if (results.data.success) {
      this.props.refresh();
      this.props.onClose();
    }
  };

  getHistoricalCouponsHelper(plan) {
    let couponDiscount = 0;
    let couponRedemptionDate = "";
    let couponDuration;
    // Coupon Applied
    if (this.state.coupon) {
      couponDiscount =
        this.state.coupon.type === "Percentage"
          ? parseFloat(
            parseFloat(plan.tuition_rate) *
            (parseFloat(this.state.coupon.amount_off) / 100)
          )
          : parseFloat(this.state.coupon.amount_off);

      couponRedemptionDate = this.props.program.couponRedemptionDate
        ? moment(this.props.program.couponRedemptionDate).format("L")
        : moment(this.props.program.createdAt).format("L");

      switch (this.state.coupon.duration) {
        case "forever":
          couponDuration = "Forever";
          break;
        case "once":
          couponDuration = "Single Use";
          break;
        case "repeating":
          couponDuration = `${this.state.coupon.duration_in_months} Month${this.state.coupon.duration_in_months === 1 ? "" : "s"
            }`;
          break;
      }
    }
    let ret = `(${this.state.coupon?.coupon_id}) / $${couponDiscount} - ${couponDuration} - Redeemed ${couponRedemptionDate}`;

    // For historical coupons
    for (
      let i = this.props.program.couponRedemptionList.length - 1;
      i >= 0;
      i--
    ) {
      const { couponId, couponRedemptionDate } = JSON.parse(
        this.props.program.couponRedemptionList[i]
      );

      const redemptionDate = moment(couponRedemptionDate).format("L");
      // Get the coupon details
      const couponDetails = this.state.couponList.find(
        coupon => coupon.id === couponId
      );
      if (couponDetails) {
        const { type, amount_off, duration, duration_in_months } =
          couponDetails;
        const couponDiscount =
          type === "Percentage"
            ? parseFloat(
              parseFloat(plan.tuition_rate) * (parseFloat(amount_off) / 100)
            )
            : parseFloat(amount_off);
        switch (duration) {
          case "forever":
            couponDuration = "Forever";
            break;
          case "once":
            couponDuration = "Single Use";
            break;
          case "repeating":
            couponDuration = `${duration_in_months} Month${duration_in_months === 1 ? "" : "s"
              }`;
            break;
        }
        ret += `\n(${couponDetails.coupon_id}) / $${couponDiscount} - ${couponDuration} - Redeemed ${redemptionDate}`;
      }
    }

    return ret;
  }

  showProgramPlanCoupon = () => {
    if (this.props.program.plan_id === null) {
      return "None";
    }
    const plan = JSON.parse(
      this.props.program.product.plans[this.props.program.plan_id]
    );

    if (!plan.type) {
      return this.getHistoricalCouponsHelper(plan);
    }

    if (plan.type) {
      let couponDiscount = 0;
      // Coupon Applied
      if (this.state.coupon) {
        couponDiscount =
          this.state.coupon.type === "Percentage"
            ? parseFloat(
              parseFloat(plan.total_price) *
              (parseFloat(this.state.coupon.amount_off) / 100)
            ) *
            parseInt(
              this.props.program.userSelectedQuantity
                ? this.props.program.userSelectedQuantity
                : 1
            )
            : parseFloat(this.state.coupon.amount_off);
      }

      if (this.props.program.drop_in_dates && this.state.coupon) {
        couponDiscount =
          this.state.coupon.type === "Percentage"
            ? parseFloat(
              parseFloat(parseFloat(plan.total_price) / plan.dayCount) *
              (1 + parseInt(plan.dropin_interest) / 100) *
              (parseFloat(this.state.coupon.amount_off) / 100)
            ).toFixed(2)
            : parseFloat(this.state.coupon.amount_off);
      }
      return `(${this.state.coupon?.coupon_id}) / $${couponDiscount}`;
    }
  };

  showEventPlanCoupon = () => {
    if (this.props.program.plan_id === null) {
      return "None";
    }
    const plan = JSON.parse(
      this.props.program.product.tickets[this.props.program.plan_id]
    );
    const ticketPrice = parseFloat(plan.ticket_price);

    let couponDiscount = 0;
    // Coupon Applied
    if (this.state.coupon) {
      couponDiscount =
        this.state.coupon.type === "Percentage"
          ? parseFloat(
            parseFloat(ticketPrice) *
            (parseFloat(this.state.coupon.amount_off) / 100)
          )
          : parseFloat(this.state.coupon.amount_off);
    }
    return `(${this.state.coupon?.coupon_id}) / $${couponDiscount}`;
  };

  showOnlinePlanCoupon = () => {
    if (this.props.program.plan_id === null) {
      return "None";
    }
    const plan = JSON.parse(
      this.props.program.product.plans[this.props.program.plan_id]
    );

    if (!plan.type) {
      return this.getHistoricalCouponsHelper(plan);
    }
    if (plan.type === "Free") {
      return <span>Free</span>;
    } else {
      let couponDiscount = 0;
      // Coupon Applied
      if (this.state.coupon) {
        couponDiscount =
          this.state.coupon.type === "Percentage"
            ? parseFloat(
              parseFloat(plan.total_price) *
              (parseFloat(this.state.coupon.amount_off) / 100)
            )
            : parseFloat(this.state.coupon.amount_off);
      }
      return `(${this.state.coupon?.coupon_id}) / $${couponDiscount}`;
    }
  };

  showMembershipPlanCoupon = () => {
    const plan = JSON.parse(
      this.props.program.product.prices[
      this.props.program.plan_id !== undefined
        ? this.props.program.plan_id
        : this.props.program.planId
      ]
    );
    const pricePerTerm = parseFloat(plan.price_per_term);

    let couponDiscount = 0;
    // Coupon Applied
    if (this.state.coupon) {
      couponDiscount =
        this.state.coupon.type === "Percentage"
          ? parseFloat(
            parseFloat(pricePerTerm) *
            (parseFloat(this.state.coupon.amount_off) / 100)
          )
          : parseFloat(this.state.coupon.amount_off);
    }
    return `(${this.state.coupon?.coupon_id}) / $${couponDiscount}`;
  };

  showProgramPlan = () => {
    if (this.props.program.plan_id === null) {
      return "Auto Register";
    }
    const plan = JSON.parse(
      this.props.program.product.plans.filter(
        e => JSON.parse(e).id == this.props.program.plan_id
      )?.[0] || null
    );

    const objConv = {
      week: "Weekly",
      "bi-week": "Bi-Weekly",
      month: "Monthly"
    };
    if (!plan) return "null"; //mostly useful in dev, due to erroneous plan data!
    if (this.props.program.product.isSemesterInstallment) {
      return (
        // <span>
        `Semester - Inst - $${parseFloat(
          (plan.total_price / plan.installments_breakdown) *
          (1 + parseInt(plan.installments_interest) / 100)
        ).toFixed(0)} - ${plan.installments_plan} / ${format(
          getFirstDay(
            new Date(this.props.program.product.program_start),
            this.props.program.product.days_of_week
          ),
          "LLL do, yyyy"
        )} -
              ${format(
          getLastDay(
            addWeeks(
              getFirstDay(
                new Date(this.props.program.product.program_start),
                this.props.program.product.days_of_week
              ),
              this.props.program.product.number_of_weeks - 1
            ),
            this.props.program.product.days_of_week
          ),
          "LLL do, yyyy"
        )}`
      );
    }
    return plan.type === "Semester"
      ? this.props.program.product.drop_in_dates
        ? `Semester Drop-in - $${parseFloat(
          (plan.total_price / plan.dayCount) *
          (1 + parseInt(plan.dropin_interest) / 100)
        ).toFixed(0)} / ${format(
          getFirstDay(
            new Date(this.props.program.product.program_start),
            this.props.program.product.days_of_week
          ),
          "LLL do, yyyy"
        )} -
                ${format(
          getLastDay(
            addWeeks(
              getFirstDay(
                new Date(this.props.program.product.program_start),
                this.props.program.product.days_of_week
              ),
              this.props.program.product.number_of_weeks - 1
            ),
            this.props.program.product.days_of_week
          ),
          "LLL do, yyyy"
        )}`
        : `Semester - $${plan.total_price} - ${format(
          getFirstDay(
            new Date(this.props.program.product.program_start),
            this.props.program.product.days_of_week
          ),
          "LLL do, yyyy"
        )} -
                  ${format(
          getLastDay(
            addWeeks(
              getFirstDay(
                new Date(this.props.program.product.program_start),
                this.props.program.product.days_of_week
              ),
              this.props.program.product.number_of_weeks - 1
            ),
            this.props.program.product.days_of_week
          ),
          "LLL do, yyyy"
        )}`
      : plan.type === "Free"
        ? plan.description && plan.description !== ""
          ? `Free - ${plan.description}`
          : "Free"
      : plan.type === "Free Trial"
        ? plan.description && plan.description !== ""
          ? `Free Trial - ${plan.description} (${plan.trialPeriod} classes)`
          : `Free Trial (${plan.trialPeriod} classes)`
        : plan.type === "Drop-In"
          ? plan.description && plan.description !== ""
            ? `Drop-In - ${plan.description} x ${this.props.program.drop_in_dates?.length}`
            : `Drop-In x ${this.props.program.drop_in_dates?.length}`
        : plan.type === "Single Sessions"
          ? `Single Sessions - $${plan.total_price} x(${this.props.program.userSelectedQuantity})`
          : `Recurring - $${plan.tuition_rate} / ${objConv[plan.billing_cycle]
          }, \n ${plan.isReplaceTimeEnabled
            ? `${plan.description}`
            : `${plan.timing[0]} to ${plan.timing[1]}`
          }`;
  };

  showOnlinePlan = () => {
    if (this.props.program.plan_id === null) {
      return "Auto Register";
    }
    const plan = JSON.parse(
      this.props.program.product.plans.filter(
        e => JSON.parse(e).id == this.props.program.plan_id
      )[0]
    );
    const objConv = {
      week: "Weekly",
      "bi-week": "Bi-Weekly",
      month: "Monthly"
    };
    if (!plan.type) {
      return `Recurring - $${plan.tuition_rate} / ${objConv[plan.billing_cycle]
        }`;
    }
    if (plan.type === "Free") {
      return `Free`;
    } else if (plan.type === "Single Sessions") {
      return `Single Sessions - $${plan.total_price} x(${this.props.program.userSelectedQuantity})`;
    } else {
      return `$${plan.total_price}`;
    }
  };

  showEventPlan = () => {
    if (this.props.program.plan_id === null) {
      return "Auto Register";
    }
    const plan = JSON.parse(
      this.props.program.product.tickets[this.props.program.plan_id]
    );
    const ticketName = plan.ticket_name;
    // plan.ticket_status === "Paid" ? "Standard" : plan.ticket_status;
    const ticketPrice = plan.ticket_price ? `$${plan.ticket_price}` : "Free";
    // return `${ticketName} Ticket / ${ticketPrice}`;
    return `${ticketName} / ${ticketPrice}`;
  };

  showMembershipPlan = () => {
    const plan = JSON.parse(
      this.props.program.product.prices[
      this.props.program.plan_id !== undefined
        ? this.props.program.plan_id
        : this.props.program.planId
      ]
    );
    const membershipTerm = plan.membership_term;
    const renewalStatus = plan.auto_renew
      ? "Subscription Membership"
      : "Standard Membership";
    const pricePerTerm = plan.price_per_term;
    return `${membershipTerm} Month Term - ${renewalStatus} - ${pricePerTerm}`;
  };

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/forms/${this.props.program.formId}`;
    const ep2 = `${process.env.REACT_APP_API}/partners/forms/archived/${this.props.program.formId}`;
    const results = await axios.get(ep);
    const results2 = await axios.get(ep2);
    if (results.data.success && results2.data.success) {
      console.log("ENTERED");
      const sectionIds = results.data.data.sections.map((section) => section.id);
      let sectionFieldOrder = {};
      results.data.data.sections.forEach(section => {
        const fields = section.fields.map(field => JSON.parse(field));

        fields.forEach((field, i) => {
          sectionFieldOrder[section.id] = {
            ...sectionFieldOrder[section.id],
            [field.first_label]: i,
            [field.label]: i
          };
        });
      });
      const customFormData = this.props.program.formData
        ? Object.keys(this.props.program.formData)
        : [];
      console.log("props", this.props);
      const formSections = [...results.data.data.sections, ...results2.data.data.sections];
      let parentFormData = [];
      customFormData.map(field => {
        let sectionId = field.match(/\d+/)?.[0];
        if (!sectionIds.includes(sectionId)) {
          for (let id of sectionIds) {
            if (sectionId.indexOf(id) === 0) {
              sectionId = id;
              break;
            }
          }
        } else if (!sectionId) {
          return;
        }
        let fieldQuestion = field.slice(sectionId.toString().length).trimStart();
        let section = formSections.filter(sec => sec.id == sectionId)[0];

        parentFormData.push({
          sectionId: sectionId,
          sectionName: section.form_section_name,
          fieldId: sectionFieldOrder[sectionId][fieldQuestion],
          fieldName: fieldQuestion,
          answer: this.props.program.formData[field],
          isArchived: section.archived
        });
      });

      parentFormData.sort((a, b) =>{ 
        if (parseInt(a.sectionId) - parseInt(b.sectionId) === 0) {
          return parseInt(a.fieldId) - parseInt(b.fieldId);
        }
        return parseInt(a.sectionId) - parseInt(b.sectionId)
      });
      
      console.log("PARENT FORMS", parentFormData);
      this.setState({
        parentFormData: parentFormData
      });
    } else {
      console.log("Something went wrong will retrieving form information");
    }

    if (this.props.displayCategory === "Memberships") {
      const plan = JSON.parse(
        this.props.program.product.prices[
        this.props.program.plan_id !== undefined
          ? this.props.program.plan_id
          : this.props.program.planId
        ]
      );
      this.setState({ isMembershipAutoRenew: !!plan.auto_renew });
    }

    // Only allow changing the coupon before application is "Accepted_Paid"
    // Or if the program is Recurring Autopay
    if (
      this.props.program.stripeSubscription ||
      this.props.program.status_stage === "Accepted_Unpaid" ||
      this.props.program.status_stage === "New_Pending" ||
      this.props.program.status_stage === "Waitlisted_Unpaid" ||
      this.props.program.status_stage === "Rejected"
    ) {
      const epCoupon = `${process.env.REACT_APP_API}/partners/stripe/application/coupon`;
      const resultCoupon = await axios.get(epCoupon);
      if (resultCoupon.data.success) {
        
        // Sort by most recent to oldest
        resultCoupon.data.data.sort((coupon1, coupon2) => {
          return new Date(coupon2.updateAt) - new Date(coupon1.updateAt)
        })

        // Runs when coupon permissions enabled.
        let couponList;
        if (this.props.program.classificationType === "Program") {
          couponList = resultCoupon.data.data.filter(
            e =>
              e.event_picked.length === 0 &&
              e.online_picked.length === 0 &&
              (e.programs_picked.length === 0 ||
                e.programs_picked.find(
                  c => JSON.parse(c).value.id === this.props.program.programId
                ))
          );
        } else if (this.props.program.classificationType === "Online") {
          couponList = resultCoupon.data.data.filter(
            e =>
              e.event_picked.length === 0 &&
              e.programs_picked.length === 0 &&
              (e.online_picked.length === 0 ||
                e.online_picked.find(
                  c => JSON.parse(c).value.id === this.props.program.programId
                ))
          );
        } else if (this.props.program.classificationType === "Event") {
          couponList = resultCoupon.data.data.filter(
            e =>
              e.online_picked.length === 0 &&
              e.programs_picked.length === 0 &&
              (e.event_picked.length === 0 ||
                e.event_picked.find(
                  c => JSON.parse(c).value.id === this.props.program.eventId
                ))
          );
        } else {
          couponList = resultCoupon.data.data.filter(
            e =>
              e.programs_picked.length === 0 &&
              e.online_picked.length === 0 &&
              e.event_picked.length === 0
          );
        }

        this.setState({
          selectedCoupon: this.props.program.couponId,
          couponList: couponList
        });
      } else {
        // Runs when coupon permissions disabled.
        this.setState({ hideCouponField: true });
      }
    }
  }

  saveCoupon = async () => {
    let ep;
    if (this.props.displayCategory === "Programs") {
      if (this.props.program.status_stage !== "Accepted_Paid") {
        ep = `${process.env.REACT_APP_API}/partners/programs/customers/coupon_save/${this.props.program.id}`;
      } else {
        ep = `${process.env.REACT_APP_API}/partners/programs/customers/add-coupon/${this.props.program.id}`;
      }
    }
    if (this.props.displayCategory === "Events") {
      ep = `${process.env.REACT_APP_API}/partners/events/customers/coupon_save/${this.props.program.id}`;
    }
    if (this.props.displayCategory === "Memberships") {
      ep = `${process.env.REACT_APP_API}/partners/memberships/customers/coupon_save/${this.props.program.id}`;
    }
    if (this.props.displayCategory === "Online Virtual") {
      if (this.props.program.status_stage !== "Accepted_Paid") {
        ep = `${process.env.REACT_APP_API}/partners/virtual/customers/coupon_save/${this.props.program.id}`;
      } else {
        ep = `${process.env.REACT_APP_API}/partners/virtual/customers/add-coupon/${this.props.program.id}`;
      }
    }

    const couponArr = this.state.couponList.filter(
      e => e.id === parseInt(this.state.selectedCoupon)
    );
    const couponTemp = couponArr[0];
    let taxRate = 0;
    if (
      this.props.program.classificationType === "Program" ||
      this.props.program.classificationType === "Online"
    ) {
      taxRate =
        JSON.parse(this.props.program.plans[this.props.program.plan_id]).taxInfo
          .length > 0
          ? JSON.parse(this.props.program.plans[this.props.program.plan_id])
            .taxInfo[0].percentage
          : 0;
    } else {
      taxRate =
        JSON.parse(this.props.program.tickets[this.props.program.plan_id])
          .taxInfo.length > 0
          ? JSON.parse(this.props.program.tickets[this.props.program.plan_id])
            .taxInfo[0].percentage
          : 0;
    }

    console.log("APPLICATION REQ?", this.props);
    const result = await axios.put(ep, {
      couponId: this.state.selectedCoupon
        ? parseInt(this.state.selectedCoupon)
        : null,
      partnerId: this.props.program.partnerId,
      type: couponTemp ? couponTemp.type : null,
      rate: couponTemp ? parseInt(couponTemp.amount_off) * 100 : 0,
      tax_rate: taxRate,
      coupon_invoice: couponTemp,
      isApplication: this.props.isApplication
    });
    console.log("result", result);

    if (result.data.success) {
      const coupon = this.state.couponList.filter(
        e => e.id === parseInt(this.state.selectedCoupon)
      );
      this.props.refresh();
      this.setState({ coupon: coupon[0], allowDropdown: false });
      toast.success("Coupon applied");
    } else {
      toast.error("Coupon could not be applied");
    }
  };

  downloadPDF = () => {
    const allFields = this.getBaseModalFields();
    const numFields = allFields.length;
    const domElementIds = [];

    // Determine the number of "base-modal-content-#" to add
    const numBaseModalContents = Math.ceil(numFields / 10);

    // Loop to create the "base-modal-content-#" IDs
    for (let i = 1; i <= numBaseModalContents; i++) {
      domElementIds.push(`base-modal-content-${i}`);
    }

    this.setGlobal({ loading: true });

    const renderPage = (pdf, domElementId, pageNumber) => {
      const domElement = document.getElementById(domElementId);
      html2canvas(domElement, {
        scale: 3,
        scrollX: 0,
        scrollY: 0
      })
        .then(canvas => {
          const PDF_MARGIN = 20;
          const PDF_WIDTH = 140;
          const MAX_HEIGHT = 250;

          const img = canvas.toDataURL("image/png");
          const imgProps = pdf.getImageProperties(img);

          let width = pdf.internal.pageSize.getWidth() - 40;
          let height = (imgProps.height * width) / imgProps.width;

          if (height > MAX_HEIGHT) {
            height = MAX_HEIGHT;
            width = (imgProps.width * height) / imgProps.height;
          }

          if (pageNumber > 1) {
            pdf.addPage();
          }

          pdf.addImage(img, "JPEG", PDF_MARGIN, PDF_MARGIN, width, height);

          // If there are more elements to render, proceed to the next one.
          const nextIndex = domElementIds.indexOf(domElementId) + 1;
          if (nextIndex < domElementIds.length) {
            renderPage(pdf, domElementIds[nextIndex], pageNumber + 1);
          } else {
            pdf.save("Application.pdf");
            this.setGlobal({ loading: false });
          }
        })
        .catch(error => {
          toast.error("Could not generate PDF.");
          this.setGlobal({ loading: false });
        });
    };

    const pdf = new jsPdf("p", "mm", "letter", true);
    renderPage(pdf, domElementIds[0], 1);
  };

  viewAddOns = () => {
    this.setState({
      openViewAddonsModal: true
    })
  }

  getEventTicketType = () => {
    return JSON.parse(this.props.program.tickets[this.props.program.plan_id])
      .ticket_status;
  };

  getBaseModalFields() {
    const {
      displayName,
      title,
      email,
      homePhone,
      address,
      city,
      postalCode,
      province,
      country,
      childName,
      userSelectedQuantity,
      startDate,
      createdAt,
      plans,
      plan_id,
      sessionDates,
      sessionTiming
    } = this.props.program;
    const parsedPlan =
      this.props.program.product.plans && plan_id !== null
        ? JSON.parse(this.props.program.product.plans[plan_id])
        : null;
    console.log("THE PROGRAM", this.props.program, parsedPlan);

    const getPaymentStatus = () =>
      this.props.program.status_stage === "Accepted_Paid"
        ? "Paid"
        : this.props.program.status_stage === "Cancelled" ||
          this.props.program.status_stage === "Cancelled_Unpaid" ||
          this.props.program.status_stage === "Rejected"
          ? "Cancelled"
          : "Pending";

    const getFormattedPlan = () => {
      switch (this.props.displayCategory) {
        case "Programs":
          return this.showProgramPlan();
        case "Events":
          return this.showEventPlan();
        case "Memberships":
          return this.showMembershipPlan();
        case "Online Virtual":
          return this.showOnlinePlan();
      }
    };

    const getCouponApplied = () => {
      switch (this.props.displayCategory) {
        case "Programs":
          return this.showProgramPlanCoupon();
        case "Events":
          return this.showEventPlanCoupon();
        case "Memberships":
          return this.showMembershipPlanCoupon();
        case "Online Virtual":
          return this.showOnlinePlanCoupon();
      }
    };

    const getPromo = () => {
      if (this.props?.program?.promotion?.discount_type === "Percentage") {
        return `${this.props?.program?.promotion?.discount_percent}% ${this.props?.program?.promotion?.duration_applied}`;
      } else {
        return `$${this.props?.program?.promotion?.discount_fixed / 100} ${this.props?.program?.promotion?.duration_applied
          }`;
      }
    };

    const getStartDate = () => {
      if (startDate) {
        return moment(
          new Date(startDate.replace(/-/g, "/").replace(/T.+/, ""))
        ).format("MMMM Do, YYYY");
      } else {
        return moment(
          new Date(createdAt.replace(/-/g, "/").replace(/T.+/, ""))
        ).format("MMMM Do, YYYY");
      }
    };

    const getBillingCycle = () => {
      let order = this.props.program.manualOrder;
      if (!order) {
        if (!parsedPlan) return null;
        order = {
          recurring_frequency: parsedPlan.billing_cycle,
          due_date:
            this.props.program.stripeSubscription?.billing_cycle_anchor * 1000
        };
      }
      const billingCycle = order.recurring_frequency;
      const dueDate = new Date(order.due_date);
      try {
        switch (billingCycle) {
          case "month":
            return `Monthly on the ${format(dueDate, "do")}`;
          case "week":
            return `Weekly on ${format(dueDate, "EEEE")}`;
          case "bi-week":
            return `Bi-Weekly on ${format(dueDate, "EEEE")}`;
        }
      } catch (e) {
        console.error("error in getBillingCycle", e);
        return null;
      }
    };

    // const getAddOnFee = () => {
    //   if (!this.props.program?.product?.plans?.[this.props?.program?.plan_id]) {
    //     return {};
    //   }
    //   return `$${
    //     JSON.parse(
    //       this.props.program?.product?.plans?.[this.props?.program?.plan_id]
    //     )?.addOnItems?.[0]?.addOnFee
    //   }`;
    // };

    const getChildDateOfBirth = () => {
      return moment(
        new Date(
          this.props.program?.birthDate?.replace(/-/g, "/")?.replace(/T.+/, "")
        )
      )?.format("MMMM Do, YYYY");
    };

    const getRecurringSelectedDays = () => {
      return this.props.program?.recurringCustomerSelectedDays?.reduce(
        (prev, e, i) => {
          const element = JSON.parse(e);
          if (this.props.program?.recurringCustomerSelectedDays?.[i + 1]) {
            return prev + `${element?.name}, `;
          } else {
            return prev + element?.name;
          }
        },
        ""
      );
    };

    const getDropInDates = () => {
      return this.props.program?.drop_in_dates?.reduce((prev, e) => {
        return (
          prev +
          moment(new Date(e?.replace(/-/g, "/")?.replace(/T.+/, "")))?.format(
            "MMMM Do, YYYY"
          )
        );
      }, "");
    };

    const getRequestedTimes = () => {
      const lastDate = i => i === sessionTiming?.length - 1;

      return this.props.program?.sessionTiming?.map((e, i) => {
        const element = typeof e === "string" ? JSON.parse(e) : e;
        let date = new Date(element.date);
        date.setHours(element.hours);
        date.setMinutes(
          element.minutes === 5
            ? 30
            : element.minutes === 25
              ? 15
              : element.minutes === 75
                ? 45
                : 0
        );
        const local = DateTime.fromISO(date.toISOString());
        const rezoned = local.setZone(this.global.organizationInfo.timezone);
        let space;
        if (lastDate(i)) {
          space = "";
        } else {
          space = ",\n";
        }
        return rezoned.toFormat("fff") + space;
      }, "");
    };

    const getRequestedDates = () => {
      const lastDate = i => i === sessionDates?.length - 1;
      return sessionDates?.reduce(
        (prev, e, i) =>
          prev + format(new Date(e), "LLLL dd, yyyy") + lastDate(i) ? "" : ", ",
        ""
      );
    };

    const getSessionSelectedDays = () => {
      return this.props.program?.sessionsCustomerSelectedDays?.reduce(
        (prev, e, i) => {
          const element = JSON.parse(e);
          if (this.props?.program?.sessionsCustomerSelectedDays?.[i + 1]) {
            return prev + `${element?.name}, `;
          } else {
            return prev + element?.name;
          }
        },
        ""
      );
    };

    const getSortedWeekdays = selectedDays => {
      const WEEKDAY_ORDER = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ];
      return WEEKDAY_ORDER.filter(wd => selectedDays[wd]).join(", ");
    };

    const getFilteredSectionContent = (value, field) => {
      if (Array.isArray(value)) {
        return value.join("; ") + (value.length > 0 ? "." : "");
      }
      if (typeof value === "object" && value !== null) {
        if (field === "days") return getSortedWeekdays(value);
        else return Object.keys(value).join(" ");
      }
      if (typeof value === "boolean") {
        return value ? "Yes" : "No";
      }
      // if (value.includes("http")) {
      //   return `<a href="${value}">View File</a>`;
      // }
      if (value.includes("data:image/png")) {
        return `<img src="${value}"" width="150px" />`;
      }
      if (value.includes("-") && field === "DOB") {
        return moment(
          new Date(value.replace(/-/g, "/").replace(/T.+/, ""))
        ).format("MMMM Do, YYYY");
      }

      return value;
    };

    const redirectToChild = () => {
      this.props.history.push(
        `/partners/child-profile/${this.props.program.dsUserId}/${this.props.program.childId}`
      );
    };

    const formatContentField = ({ name, content, textStyles = null }) => ({
      type: CONTENT_BLOCK,
      data: { name, content, textStyles }
    });

    const local = DateTime.fromISO(this.props.program.createdAt);
    const rezoned = local.setZone(this.global.organizationInfo.timezone);

    // content fields
    const nameField = formatContentField({
      name: "Name",
      content: displayName
    });
    const appliedToField = formatContentField({
      name: "Applied To",
      content: title
    });
    const paymentStatusField = formatContentField({
      name: "Payment Status",
      content: getPaymentStatus()
    });
    const planField = formatContentField({
      name: "Plan",
      content: getFormattedPlan()
    });
    const emailField = formatContentField({ name: "Email", content: email });
    const cellPhoneField = formatContentField({
      name: "Cell Phone",
      content: homePhone
    });
    const addressField = formatContentField({
      name: "Address",
      content: address
    });
    const cityField = formatContentField({ name: "City", content: city });
    const postalCodeField = formatContentField({
      name: "Postal / Zip",
      content: postalCode
    });
    const regionField = formatContentField({
      name: "Region",
      content: province
    });
    const countryField = formatContentField({
      name: "Country",
      content: country
    });
    const submissionField = formatContentField({
      name: "Submission",
      content: rezoned.toFormat("fff")
    });

    const couponAppliedField = formatContentField({
      name: "Coupon Applied",
      content: getCouponApplied(),
      textStyles: { fontSize: "14px", whiteSpace: "pre-wrap" }
    });
    const promoField = formatContentField({
      name: "Promo",
      content: getPromo()
    });
    // const addOnFeeField = formatContentField({
    //   name: "Add On Fee",
    //   content: getAddOnFee()
    // });
    const childDateOfBirthField = formatContentField({
      name: "Date of Birth",
      content: getChildDateOfBirth()
    });
    const recurringSelectedDaysField = formatContentField({
      name: "Selected Days",
      content: getRecurringSelectedDays()
    });
    const dropInDatesField = formatContentField({
      name: "Drop In Dates",
      content: getDropInDates()
    });
    const sessionsRequestedField = formatContentField({
      name: "Number of sessions requested",
      content: userSelectedQuantity
    });
    const requestedTimesField = formatContentField({
      name: "Requested Times",
      content: getRequestedTimes(),
      textStyles: { whiteSpace: "pre-wrap" }
    });
    const requestedDatesField = formatContentField({
      name: "Requested Dates",
      content: getRequestedDates()
    });
    const sessionSelectedDaysField = formatContentField({
      name: "Selected Days",
      content: getSessionSelectedDays()
    });

    console.log("parentFormData:", this.state.parentFormData);

    const formDataFields = this.state.parentFormData.reduce(
      (prev, formData) => {
        const content = getFilteredSectionContent(formData.answer, formData.fieldName);
        return [
          ...prev,
          {
            name: `<i>${formData.sectionName}:</i> ${formData.fieldName}`,
            content: content,
            field: formData.fieldName,
            isArchived: formData.isArchived,
            isSignature: content.includes('<img src="data:image/')
          }
        ];
      },
      []
    );

    console.log("filtered seciton fields", formDataFields);

    const archivedSectionFields = [
      ...formDataFields.filter(field => field.isArchived)
        .map(field => {
          if (field.isSignature) {
            return {
              type: IMAGE_CONTENT,
              data: {
                name: field.name + " (Archived)",
                src: field.content.slice(10, field.content.length - 37) // remove the surrounding img tag and other data
              }
            };
          }

          return {
            ...field,
            name: field.name + " (Archived)"
          }
        })
    ];
    const customFormFields = 
      [
        ...formDataFields.filter(field => !field.isArchived).map(s => {
          if (s.isSignature) {
            return {
              type: IMAGE_CONTENT,
              data: {
                name: s.name,
                src: s.content.slice(10, s.content.length - 37) // remove the surrounding img tag and other data
              }
            };
          } else if (s.content.startsWith("http")) {
            // For links
            return {
              type: BUTTON,
              data: {
                name: s.name,
                buttonText: "View File",
                buttonColour: BLUE_BUTTON,
                textColour: "white",
                handleClick: () => {
                  console.log("Navigating to", s.content);
                  window.open(s.content, "_blank");
                }
              }
            };
          } else {
            return formatContentField(s);
          }
        }),
        ...archivedSectionFields.map(s => {
          if (s.type === IMAGE_CONTENT) {
            return s;
          }
          if (s.content.startsWith("http")) {
            // For links
            return {
              type: BUTTON,
              data: {
                name: s.name,
                buttonText: "View File",
                buttonColour: BLUE_BUTTON,
                textColour: "white",
                handleClick: () => {
                  console.log("Navigating to", s.content);
                  window.open(s.content, "_blank");
                }
              }
            };
          } else {
            return formatContentField(s);
          }})
      ];
    
    console.log("custom form fields", customFormFields);
    // other fields
    const downloadPDFField = {
      type: BUTTON,
      data: {
        name: "Download PDF",
        buttonText: "Download",
        handleClick: this.downloadPDF,
        buttonColour: BLUE_BUTTON,
        textColour: WHITE
      }
    };

    const couponButtonField = {
      type: BUTTON,
      data: {
        name: this.state.selectedCoupon ? "" : "Coupon Applied",
        buttonText: this.state.selectedCoupon ? "Modify Coupon" : "Add Coupon",
        handleClick: e => this.setState({ allowDropdown: true }),
        buttonColour: BLUE_BUTTON,
        textColour: WHITE
      }
    };

    const couponDropdownField = {
      type: DROPDOWN,
      data: {
        name: "Coupon Applied",
        placeholder: "Select a coupon",
        handleChange: e => {
          this.setState({ selectedCoupon: e.target.value });
        },
        choices: this.state.couponList.map(e => ({
          text: `${e.coupon_id} (${e.type === "Amount" ? "$" : ""}${e.amount_off
            }${e.type === "Percentage" ? "%" : ""})`,
          value: e.id
        })),
        value: this.state.selectedCoupon
      }
    };

    const couponSaveField = {
      type: BUTTON,
      data: {
        buttonText: "Save Coupon",
        handleClick: e => this.saveCoupon(),
        buttonColour: BLUE_BUTTON,
        textColour: WHITE
      }
    };

    const childNameField = {
      type: BUTTON,
      data: {
        name: "Child's Name",
        buttonText: `${childName} (view details)`,
        handleClick: redirectToChild,
        buttonColour: BLUE_BUTTON,
        textColour: WHITE
      }
    };

    // when icon is clicked, call this.props.openEditStart();
    let hourDiff = 0;
    if (startDate) {
      hourDiff = (new Date(startDate.replace(/-/g, "/").replace(/T.+/, "")) - new Date()) / (1000 * 3600)
    }
    const startDateField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Start Date",
        content: getStartDate(),
        icon:
          // new Date() < new Date(startDate.replace(/-/g, "/").replace(/T.+/, ""))
          hourDiff >= 8 ?
            (this.props.displayCategory === "Online Virtual" ||
              this.props.displayCategory === "Programs"
              ? {
                className: "far fa-edit",
                onClick: this.props.openEditStart
              }
              : {}) : undefined
      }
    };

    const billingCycleField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Billing Cycle",
        content: getBillingCycle(),
        icon:
          (this.props.displayCategory === "Online Virtual" ||
            this.props.displayCategory === "Programs") &&
            parsedPlan?.billing_cycle === "month"
            ? {
              className: "far fa-edit",
              onClick: this.props.openEditBillingCycle
            }
            : {}
      }
    };

    const messageField = {
      type: TEXT_AREA,
      data: {
        name: "Message (optional)",
        required: false,
        placeholder: "",
        handleChange: e => this.setState({ message: e.target.value }),
        value: this.state.message,
        isDisabled: this.props.type === "Accepted"
      }
    };

    const viewAddons = {
      type: BUTTON,
      data: {
        name: "View add-ons",
        buttonText: "View",
        handleClick: this.viewAddOns,
        buttonColour: BLUE_BUTTON,
        textColour: WHITE
      }
    }

    const viewAttendees = {
      type: BUTTON,
      data: {
        name: "View attendees",
        buttonText: "View",
        handleClick: () => {
          this.setState({
            openEditPartyAttendeesModal: true
          })
        },
        buttonColour: BLUE_BUTTON,
        textColour: WHITE
      }
    }

    // add fields in order (some conditionally)
    const allFields = [];
    allFields.push(downloadPDFField);
    allFields.push(nameField);
    allFields.push(appliedToField);
    allFields.push(paymentStatusField);
    allFields.push(planField);
    if (this.state.coupon && !this.state.allowDropdown) {
      allFields.push(couponAppliedField);
    }
    if (
      !this.state.allowDropdown &&
      (this.props.program.stripeSubscription ||
        this.props.program.status_stage === "Accepted_Unpaid" ||
        this.props.program.status_stage === "New_Pending" ||
        this.props.program.status_stage === "Waitlisted_Unpaid" ||
        this.props.program.status_stage === "Rejected")
    ) {
      //   allFields.push(couponButtonField);
    }
    if (
      !this.state.hideCouponField &&
      (this.props.program.stripeSubscription ||
        this.props.program.status_stage === "Accepted_Unpaid" ||
        this.props.program.status_stage === "New_Pending" ||
        this.props.program.status_stage === "Waitlisted_Unpaid" ||
        this.props.program.status_stage === "Rejected") &&
      this.state.allowDropdown
    ) {
      //   allFields.push(couponDropdownField);
      //   allFields.push(couponSaveField);
    }
    if (this.props.program.promotion) {
      allFields.push(promoField);
    }
    // if (this.props?.displayCategory === "Programs" && parsedPlan.addOn) {
    //   allFields.push(addOnFeeField);
    // }
    allFields.push(emailField);
    allFields.push(cellPhoneField);
    allFields.push(addressField);
    allFields.push(cityField);
    allFields.push(postalCodeField);
    allFields.push(regionField);
    allFields.push(countryField);
    if (this.props.program.childId) {
      allFields.push(childNameField);
      allFields.push(childDateOfBirthField);
    }
    allFields.push(startDateField);
    if (
      (this.props.displayCategory === "Programs" ||
        this.props.displayCategory === "Online Virtual") &&
      parsedPlan?.billing_cycle &&
      billingCycleField.data.content
    ) {
      allFields.push(billingCycleField);
    }
    if (
      this.props.displayCategory === "Programs" &&
      this.props.program.recurringCustomerSelectedDays.length > 0
    ) {
      allFields.push(recurringSelectedDaysField);
    }
    if (this.props.displayCategory === "Parties" &&
      this.props.program.partyDetails.addOnAmounts
    ) {
      allFields.push(viewAddons)
      allFields.push(viewAttendees)
    }
    if (this.props.program.drop_in_dates) {
      allFields.push(dropInDatesField);
    }
    if (customFormFields) {
      allFields.push(...customFormFields);
    }
    allFields.push(submissionField);
    if (this.props.program.userSelectedQuantity) {
      allFields.push(sessionsRequestedField);
    }
    if (this.props.program.sessionTiming?.length > 0) {
      allFields.push(requestedTimesField);
    }
    if (this.props.program.sessionDates?.length > 0) {
      allFields.push(requestedDatesField);
    }
    if (
      this.props.displayCategory === "Programs" &&
      this.props.program.sessionsCustomerSelectedDays?.length > 0
    ) {
      allFields.push(sessionSelectedDaysField);
    }
    if (this.props.type !== "Enrolled") {
      allFields.push(messageField);
    }

    return allFields;
  }

  inactiveEnrolled = async () => {
    let type;
    switch (this.props.displayCategory) {
      case "Programs":
        type = "programs";
        break;
      case "Online Virtual":
        type = "virtual";
        break;
      case "Memberships":
        type = "memberships";
        break;
      case "Events":
        type = "events";
        break;
      default:
        toast.error("Error: Invalid display category");
        return;
    }
    const ep = `${process.env.REACT_APP_API}/partners/${type}/customers/make-inactive/${this.state.idToEdit}`;
    const results = await axios.post(ep);
    console.log("results", results);
  };

  restoreEnrolled = async () => {
    let type;
    switch (this.props.displayCategory) {
      // case "Programs":
      //   type = "programs";
      //   break;
      // case "Online Virtual":
      //   type = "virtual";
      //   break;
      // case "Memberships":
      //   type = "memberships";
      //   break;
      case "Events":
        type = "events";
        break;
      default:
        toast.error("Error: Invalid display category");
        return;
    }
    const ep = `${process.env.REACT_APP_API}/partners/${type}/customers/restore-inactive/${this.state.idToEdit}`;
    const results = await axios.post(ep);
    console.log("results", results);
  };

  getBaseModalButtons() {
    const makeInactiveButton = {
      name: "Make Inactive",
      handleClick: () => {
        this.setState({ idToEdit: this.props.program.id }, () => {
          this.inactiveEnrolled();
          this.props.refresh();
          this.props.onClose();
        });
      },
      buttonColour: RED_BUTTON,
      textColour: WHITE,
      isDisabled: this.props.canSubUserEdit
    };

    const restoreInactiveButton = {
      name: "Restore from Inactive",
      handleClick: () => {
        this.setState({ idToEdit: this.props.program.id }, () => {
          this.restoreEnrolled();
          this.props.refresh();
          this.props.onClose();
        });
      },
      buttonColour: GREEN_BUTTON,
      textColour: WHITE,
      isDisabled: this.props.canSubUserEdit
    };

    const endEnrollmentButton = {
      name: "End Enrollment",
      handleClick: this.props.endEnrollment,
      buttonColour: RED_BUTTON,
      textColour: WHITE,
      isDisabled: this.props.canSubUserEdit
    };

    const resumePauseEnrollmentButton = {
      name:
        this.props.program.stripeSubscription?.pause_collection ||
          this.props.program.manualOrder?.is_paused
          ? "Resume Enrollment"
          : "Pause Enrollment",
      handleClick: () => {
        const param1 = !!this.props.program.stripeSubscription;
        const param2 = param1
          ? this.props.program.stripeSubscription.id
          : this.props.program.manualOrder.id;

        this.props.pauseEnrollment(
          param1,
          param2,
          this.props.program.stripeSubscription?.pause_collection ||
          this.props.program.manualOrder?.is_paused
        );
      },
      buttonColour: YELLOW_BUTTON,
      textColour: WHITE,
      isDisabled: this.props.canSubUserEdit
    };

    const switchEnrollmentButton = {
      name: "Switch Enrollment",
      handleClick: () => this.setState({ isOpenSwitchModal: true }),
      buttonColour: YELLOW_BUTTON,
      textColour: WHITE,
      isDisabled:
        this.props.canSubUserEdit ||
        JSON.parse(
          this.props.program.product.plans
            ? this.props.program.product.plans[
            this.props.program.plan_id !== undefined
              ? this.props.program.plan_id
              : this.props.program.planId
            ]
            : this.props.program.product.prices
              ? this.props.program.product.prices[
              this.props.program.plan_id !== undefined
                ? this.props.program.plan_id
                : this.props.program.planId
              ]
              : this.props.program.product.tickets[
              this.props.program.plan_id !== undefined
                ? this.props.program.plan_id
                : this.props.program.planId
              ]
        )?.type === "Free"
    };

    const acceptButton = {
      name: "Accept",
      handleClick: () => this.acceptApplication(this.props.program),
      buttonColour: GREEN_BUTTON,
      textColour: WHITE,
      isDisabled: this.props.canSubUserEdit
    };

    const waitlistButton = {
      name: "Waitlist",
      handleClick: () => this.waitlistApplication(this.props.program),
      buttonColour: YELLOW_BUTTON,
      textColour: WHITE,
      isDisabled: this.props.canSubUserEdit
    };

    const denyButton = {
      name: "Deny",
      handleClick: () =>
        this.setState({ openApplicationDenyNotificationModal: true }),
      buttonColour: RED_BUTTON,
      textColour: WHITE,
      isDisabled: this.props.canSubUserEdit
    };

    // For non application programs that are Accepted_Paid
    // Made to replace the Deny button and prevent users from receiving rejection emails
    // even if there are no applications
    const cancelButton = {
      name: "Cancel",
      handleClick: () => this.setState({ openCancelApplicationModal: true }),
      buttonColour: RED_BUTTON,
      textColour: WHITE,
      isDisabled: this.props.canSubUserEdit
    };

    const nudgeButton = {
      name: "Nudge",
      handleClick: () => this.nudgeApplication(this.props.program),
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      isDisabled: this.props.canSubUserEdit
    };

    console.log("this.props.program...", this.props.program);
    if (
      this.props.type === "Enrolled" &&
      (this.props.program.product.number_of_weeks === 0 ||
        (this.props.program.product.prices && JSON.parse(
          this.props.program.product.prices[
          this.props.program.plan_id !== undefined
            ? this.props.program.plan_id
            : this.props.program.planId
          ]
        ).auto_renew))
    ) {
      return this.props.program.classificationType !== "Membership" ? [
        endEnrollmentButton,
        resumePauseEnrollmentButton,
        switchEnrollmentButton
      ] : [
        endEnrollmentButton,
        resumePauseEnrollmentButton,
      ];
    }

    if (this.props.type === "Enrolled" &&
      this.props.program.product.plan_id !== null &&
      (this.props.program.product.number_of_weeks > 0 ||
        (this.props.program.product.prices &&
          !JSON.parse(
            this.props.program.product.prices[
            this.props.program.product.plan_id !== undefined
              ? this.props.program.product.plan_id
              : this.props.program.product.planId
            ]
          ).auto_renew) ||
        this.props.program.classificationType === "Event")
    ) {
      return [makeInactiveButton];
    }

    if (this.props.type === "New") {
      return [acceptButton, waitlistButton, denyButton];
    }
    if (this.props.type === "Waitlisted") {
      return [acceptButton, denyButton];
    }
    if (this.props.type === "Rejected") {
      return [];
    }
    if (this.props.type === "Archived") {
      return [];
    }
    //not sure if this modal is ever invoked with type="Archived" (as in above),
    //but for Events, we will have a type="Inactive":
    if (this.props.type === "Inactive") {
      return [restoreInactiveButton];
    }
    if (
      this.props.type === "Accepted" &&
      this.props.program.status_stage === "Accepted_Unpaid"
    ) {
      let btns;
      if (this.props.program.application_required) {
        btns = [waitlistButton, denyButton, cancelButton];
      } else {
        btns = [cancelButton];
      }

      if (
        this.props.program.nudgeCount === null ||
        isAfter(
          new Date(),
          addHours(new Date(this.props.program.lastNudgeDate), 1)
        )
      ) {
        btns.unshift(nudgeButton);
      }
      return btns;
    }
    return [];
  }

  getBaseModalProps() {
    const baseModalProps = {
      title: "Application Details",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: this.props.onClose
    };

    return baseModalProps;
  }

  render() {
    if (!this.state.customFormDataObj) {
      return null;
    }
    if (!this.props) {
      return null;
    }
    console.log("this.state", this.state);
    console.log("this.props", this.props);

    if (this.state.openEditPartyStartDateModal) {
      return (
        <EditPartyStartDateModal
          disabled={this.props.program.status_stage === "Accepted_Paid"}
          program={this.props.program}
          refetch={() => {
            this.setState({
              refetch: this.state.refetch + 1
            })
          }}
          updatePartyDetails={(partyDetails) => {
            this.props.program.partyDetails = partyDetails
          }}
          onClose={() => {
            this.setState({
              openEditPartyStartDateModal: false,
            })
          }}
        />
      )
    }
    if (this.state.openEditPartyAttendeesModal) {
      return (
        <EditPartyAttendeesModal
          disabled={this.props.program.status_stage === "Accepted_Paid"}
          program={this.props.program}
          refetch={() => {
            this.setState({
              refetch: this.state.refetch + 1
            })
          }}
          updatePartyDetails={(partyDetails) => {
            this.props.program.partyDetails = partyDetails
          }}
          onClose={() => {
            this.setState({
              openEditPartyAttendeesModal: false,
            })
          }}
        />
      )
    }
    if (this.state.isOpenSwitchModal) {
      return (
        <SwitchEnrollmentModal
          onClose={() => {
            this.setState({ isOpenSwitchModal: false });
            this.props.refresh();
            this.props.onClose();
          }}
          program={this.props.program}
          type={this.props.displayCategory}
          plans={
            this.props.program.product.plans ||
            this.props.program.product.tickets ||
            this.props.program.product.prices
          }
          selectedPlan={this.props.program.plan_id}
        />
      );
    } else if (this.state.openCancelApplicationModal) {
      return (
        <ApplicationCancelModal
          onClose={() => this.setState({ openCancelApplicationModal: false })}
          closeApplicationViewModal={() => this.props.onClose}
          cancelApplication={() => this.cancelApplication(this.props.program)}
        />
      );
    } else if (this.state.openApplicationDenyNotificationModal) {
      return (
        <ApplicationDenyNotificationModal
          onClose={() =>
            this.setState({ openApplicationDenyNotificationModal: false })
          }
          rejectApplication={() =>
            this.rejectApplication(this.props.program, true)
          }
          rejectApplicationSilent={() =>
            this.rejectApplication(this.props.program, false)
          }
        />
      );
    } else if (this.state.openViewAddonsModal) {
      return (
        <ViewPartyAddonsModal
          disabled={this.props.program.status_stage === "Accepted_Paid"}
          canSubUserEdit={this.props.canSubUserEdit}
          onClose={() => {
            this.setState({ openViewAddonsModal: false })
          }}
          refetch={async _ => {
            this.setState(
              { refetch: this.state.refetch + 1 }
            )
          }}
          updatePartyDetails={(partyDetails) => {
            this.props.program.partyDetails = partyDetails
          }}
          applicationInfo={this.props.program}
          partyDetails={this.props.program.partyDetails}
        />
      )
    }

    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(ConnectionsApplicationViewModal);
