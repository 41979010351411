import React from "reactn";
import axios from "axios";
import { toast } from "react-toastify";

class Checklist extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checklist: {},
      updated: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.saveChecklist = this.saveChecklist.bind(this);
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 1), "Checklist"]
    });
  }

  handleChange(c, itm) {
    this.setState({
      checklist: {
        ...this.state.checklist,
        [c.id]: {
          ...c,
          items: {
            ...c.items,
            [itm.id]: {
              ...itm,
              checked: !itm.checked
            }
          }
        }
      },
      updated: {
        ...this.state.updated,
        [itm.id]: !itm.checked
      }
    });
  }

  async saveChecklist() {
    const params = this.props.program;
    const ep = `${process.env.REACT_APP_API}/edu/checklist${
      params ? `/${params.user.id}` : ""
    }`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.post(ep, this.state.updated);
      if (res.data.success) {
        this.setState({
          updated: {}
        });
      }
      toast(res.data.message);
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      console.error(ex);
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  async componentDidMount() {
    const params = this.props.program;
    const ep = `${process.env.REACT_APP_API}/edu/checklist${
      params ? `/${params.user.id}` : ""
    }`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(ep);
      if (res.data.success) {
        this.setState({
          checklist: res.data.data
        });
      } else {
        toast(res.data.message);
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (ex) {
      console.error(ex);
      this.setGlobal({ loading: false, lastAPICall: null });
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="cont">
          <div className="row">
            <div className="col-md-6">
              <h1>Checklist</h1>
            </div>
          </div>
        </div>
        <div className="cont boxes-row boxes-row-fluid">
          {Object.keys(this.state.checklist).map((category, i) => {
            const c = this.state.checklist[category];
            return (
              <div className="box box-33" key={i}>
                <div className="bgofbox">
                  <div className="header">
                    <h2>{c.category}</h2>
                  </div>
                  <div className="formbody">
                    {Object.keys(c.items).map((item, j) => {
                      const itm = c.items[item];
                      return (
                        <div className="row" key={j}>
                          <div className="col-2">
                            <input
                              type="checkbox"
                              checked={itm.checked}
                              name={item}
                              onChange={() => this.handleChange(c, itm)}
                            />
                          </div>
                          <div className="col-10">{itm.item}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="cont mb-5 pb-2 text-right">
          <div className="buttons mt-1">
            <button
              onClick={this.saveChecklist}
              name="next"
              disabled={!Object.keys(this.state.updated).length}
              className="savebtn"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Checklist;
