import React, { useState, useGlobal } from 'reactn';
import BaseModal from "./baseModal/BaseModal";
import { BLUE_BUTTON, RED_BUTTON, WHITE } from "./baseModal/colours";
import { CONTENT_BLOCK } from "./baseModal/FieldTypes";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const ArchiveModal = ({ selectedItem, onClose, refresh }) => {
  const archiveInvoice = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/orders/update`;
    const results = await axios.post(ep, {
      id: selectedItem.id,
      isArchived: true,
      archivedDate: new Date()
    });
    if (results.data.success) {
      toast.success("Invoice archived successfully");
      refresh();
      onClose();
    } else {
      console.error(results.data.message);
      toast.error("Failed to archive invoice");
      onClose();
    }
  }

  const getBaseModalFields = () => {
    return [
      {
        name: "",
        type: CONTENT_BLOCK,
        data: {
          content: "Are you sure you want to archive this invoice? This action cannot be undone."
        }
      }
    ]
  }

  const getBaseModalButtons = () => {
    const archiveButton = {
      name: "Archive",
      handleClick: archiveInvoice,
      buttonColour: RED_BUTTON,
      textColour: WHITE
    };
    return [archiveButton];
  };

  const getBaseModalProps = () => {
    return {
      title: "Archive Invoice",
      fields: getBaseModalFields(),
      buttons: getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: onClose
    };
  }
  return (
    <BaseModal {...getBaseModalProps()} />
  )
}

export default withRouter(ArchiveModal);