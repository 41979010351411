import React from "reactn";
import PropTypes from "prop-types";
import { default as ToastCalendar } from "@toast-ui/react-calendar";
import "tui-calendar/dist/tui-calendar.css";
import { addDays } from "date-fns";
import "./calendar.css";
// import CalendarAddModal from "./CalendarAddModal";
import axios from "axios";
// import UpcomingEvents from "./UpcomingEvents";
// import CalendarDetailsModal from "./CalendarDetailsModalTemp";
import { MiniPlus } from "../../assets/index";
import CalendarCreateModal from "../modals/CalendarCreateModal";
import { withRouter } from "react-router-dom";
import AddReminderModal from "../../components/modals/AddReminderModal";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

const today = new Date();
const gradients = [
  `background: rgba(126,160,255,1);
background: -moz-linear-gradient(left, rgba(126,160,255,1) 0%, rgba(145,90,255,1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(126,160,255,1)), color-stop(100%, rgba(145,90,255,1)));
background: -webkit-linear-gradient(left, rgba(126,160,255,1) 0%, rgba(145,90,255,1) 100%);
background: -o-linear-gradient(left, rgba(126,160,255,1) 0%, rgba(145,90,255,1) 100%);
background: -ms-linear-gradient(left, rgba(126,160,255,1) 0%, rgba(145,90,255,1) 100%);
background: linear-gradient(to right, rgba(126,160,255,1) 0%, rgba(145,90,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7ea0ff', endColorstr='#915aff', GradientType=1 ); border-radius: 4px; color: white !important;`,

  `background: rgba(245,198,140,1);
background: -moz-linear-gradient(left, rgba(245,198,140,1) 0%, rgba(253,132,192,1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(245,198,140,1)), color-stop(100%, rgba(253,132,192,1)));
background: -webkit-linear-gradient(left, rgba(245,198,140,1) 0%, rgba(253,132,192,1) 100%);
background: -o-linear-gradient(left, rgba(245,198,140,1) 0%, rgba(253,132,192,1) 100%);
background: -ms-linear-gradient(left, rgba(245,198,140,1) 0%, rgba(253,132,192,1) 100%);
background: linear-gradient(to right, rgba(245,198,140,1) 0%, rgba(253,132,192,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5c68c', endColorstr='#fd84c0', GradientType=1 ); border-radius: 4px`,

  `background: rgba(172,147,255,1);
background: -moz-linear-gradient(left, rgba(172,147,255,1) 0%, rgba(152,223,232,1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172,147,255,1)), color-stop(100%, rgba(152,223,232,1)));
background: -webkit-linear-gradient(left, rgba(172,147,255,1) 0%, rgba(152,223,232,1) 100%);
background: -o-linear-gradient(left, rgba(172,147,255,1) 0%, rgba(152,223,232,1) 100%);
background: -ms-linear-gradient(left, rgba(172,147,255,1) 0%, rgba(152,223,232,1) 100%);
background: linear-gradient(to right, rgba(172,147,255,1) 0%, rgba(152,223,232,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ac93ff', endColorstr='#98dfe8', GradientType=1 ); border-radius: 4px; color: white !important;`,

  `background: rgba(44,214,214,1);
background: -moz-linear-gradient(left, rgba(44,214,214,1) 0%, rgba(174,232,94,1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(44,214,214,1)), color-stop(100%, rgba(174,232,94,1)));
background: -webkit-linear-gradient(left, rgba(44,214,214,1) 0%, rgba(174,232,94,1) 100%);
background: -o-linear-gradient(left, rgba(44,214,214,1) 0%, rgba(174,232,94,1) 100%);
background: -ms-linear-gradient(left, rgba(44,214,214,1) 0%, rgba(174,232,94,1) 100%);
background: linear-gradient(to right, rgba(44,214,214,1) 0%, rgba(174,232,94,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2cd6d6', endColorstr='#aee85e', GradientType=1 ); border-radius: 4px`,

  `background: rgba(255,212,70,1);
background: -moz-linear-gradient(left, rgba(255,212,70,1) 0%, rgba(252,140,126,1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,212,70,1)), color-stop(100%, rgba(252,140,126,1)));
background: -webkit-linear-gradient(left, rgba(255,212,70,1) 0%, rgba(252,140,126,1) 100%);
background: -o-linear-gradient(left, rgba(255,212,70,1) 0%, rgba(252,140,126,1) 100%);
background: -ms-linear-gradient(left, rgba(255,212,70,1) 0%, rgba(252,140,126,1) 100%);
background: linear-gradient(to right, rgba(255,212,70,1) 0%, rgba(252,140,126,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd446', endColorstr='#fc8c7e', GradientType=1 ); border-radius: 4px`
];

const theme = {
  "common.holiday.color": "#333",
  "week.futureTime.color": "#fff",
  "month.holidayExceptThisMonth.color": "rgba(51, 51, 51, 0.4)"
};

class CalendarPartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "month",
      displayed: new Date().toLocaleString("default", {
        month: "long",
        year: "numeric"
      }),
      schedules: [],
      addEventModalShown: false,
      modalData: null,
      children: [],
      upcoming: [],
      detailsModalShown: false,
      selectedChild: null,
      displayCalendarCreateModal: false,
      taskModal: false,
      reminderModal: false,
      popoverAnchorE1: null,
      filterListChecked: ["Reminder", "Event", "Program", "Online"]
    };

    this.calendarRef = React.createRef();
    this.getDate = this.getDate.bind(this);
    this.addEvent = this.addEvent.bind(this);
    this.openDetails = this.openDetails.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.handleFilterListToggle = this.handleFilterListToggle.bind(this);

    this.filterListLabels = ["Reminder", "Event", "Program", "Online"];
  }

  handleRangeChange = action => {
    const ci = this.calendarRef.current.getInstance();
    switch (action) {
      case "next":
        ci.next();
        break;
      case "prev":
        ci.prev();
        break;
      case "today":
        ci.today();
        break;
      default:
        break;
    }
    this.setState({
      displayed: this.getDateRange()
    });
  };

  openDetails(e) {
    const evt = this.props.schedules.find(sch => sch.id === e.schedule.id);
    const student = this.state.children.find(ch => ch.childId === evt.child);
    this.setState({
      modalData: { ...evt },
      detailsModalShown: true,
      selectedChild: student
    });
  }
  // duplicate function definition
  openDetails(e) {
    const evt = this.props.schedules.find(sch => sch.id === e.schedule.id);
    const student = null;
    this.setState({
      modalData: { ...evt },
      detailsModalShown: true
    });
  }

  componentDidUpdate() {
    setTimeout(_ => {
      if (document.getElementsByClassName("tui-full-calendar-timegrid-hour")) {
        for (let element of document.getElementsByClassName(
          "tui-full-calendar-timegrid-hour"
        )) {
          if (element.style.color === "rgb(255, 255, 255)") {
            element.style.color = "#000";
          }
        }
      }
    }, 100);

    /*const ep = `${process.env.REACT_APP_API}/calendar`;
    try {
      this.setGlobal({ loading: true });
      const res = await axios.get(
        `${ep}${this.props.program ? `/${this.props.program.user.id}` : ""}`
      );

      if (res.data.success) {
        let cal = res.data.data.calendar.map(evt => {
          const nevt = {
            ...evt,
            isReadOnly: true,
            customStyle: gradients[evt.colour],
            start: new Date(evt.startTime).toISOString(),
            end: evt.endTime ? new Date(evt.endTime).toISOString() : "",
            calendarId: evt.calendarId.toString()
          };
          return nevt;
        });
        this.setState({
          schedules: cal,
          children: res.data.data.children,
          upcoming: res.data.data.upcoming.filter(
            e => new Date(e.startTime) > Date.now()
          )
        });
      }
      this.setGlobal({ loading: false, lastAPICall: res });
    } catch (er) {
      this.setGlobal({ loading: false, lastAPICall: null });
    }*/
  }

  componentWillReceiveProps(props) {
    if (props.calendarView)
      this.setState({ view: props.calendarView, schedules: props.schedules });
  }
  async deleteEvent(del) {
    if (del) {
      const ep = `${process.env.REACT_APP_API}/calendar/delete`;
      try {
        this.setGlobal({ loading: true });
        const res = await axios.get(`${ep}/${this.state.modalData.id}`);
        if (res.data.success) {
          this.setState({
            schedules: this.state.schedules.filter(
              sch => sch.id !== this.state.modalData.id
            ),
            upcoming: this.state.upcoming.filter(
              sch => sch.id !== this.state.modalData.id
            )
          });
        }
        this.setGlobal({ loading: false, lastAPICall: res });
      } catch (er) {
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    }
    this.setState({ detailsModalShown: false, modalData: null });
  }

  getDateRange = () => {
    const { view } = this.state;
    const ci = this.calendarRef.current.getInstance();
    return new Date(ci.getDate()).toLocaleString("default", {
      month: "long",
      year: "numeric"
    });
  };

  getDate(type, date, amt, action) {
    return addDays(today, Math.round(Math.random() * 30));
  }

  componentWillUnmount() {
    this.setGlobal({
      noShow: false
    });
  }
  async addEvent(added) {
    const ep = `${process.env.REACT_APP_API}/calendar`;
    try {
      if (added) {
        if (!added.id) {
          delete added.id;
        }
        if (this.props.program) {
          added["dsUserId"] = this.props.program.user.id;
        }
        if (!added.endTime) added.endTime = null;
        const res = await axios.post(ep, added);
        if (res.data.success) {
          let newEvt = {
            ...res.data.data,
            isReadOnly: true,
            customStyle: gradients[added.colour],
            start: added.startTime.toISOString(),
            end: added.endTime ? added.endTime.toISOString() : "",
            calendarId: added.calendarId.toString()
          };

          this.setState({
            addEventModalShown: false,
            modalData: null,
            schedules: [...this.state.schedules, newEvt]
          });
        }
      } else {
        this.setState({
          addEventModalShown: false,
          modalData: null
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  handleClose() {
    this.setState({
      popoverAnchorE1: null
    });
  }

  handleFilterClick(event) {
    this.setState({
      popoverAnchorE1: event.currentTarget
    });
  }

  handleFilterListToggle(value) {
    const currentIndex = this.state.filterListChecked.indexOf(value);
    const newChecked = [...this.state.filterListChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    const sch = this.props.schedules.filter(e => newChecked.includes(e.type));

    this.setState({
      filterListChecked: newChecked,
      schedules: sch
    });
  }

  /**
   * This function handles the edit button functionality of a sub user
   * returns a boolean value
   */
  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      if (this.global.dsUser.accesspoints.Upcoming) {
        return !this.global.dsUser.accesspoints.Upcoming.Calendar.edit;
      } else {
        return false;
      }
    }
  };

  render() {
    const { maxWidth } = this.props;
    const { view, displayed } = this.state;

    return (
      <>
        <div className={(maxWidth ? "max-width " : "") + "boxofcalendar"}>
          {this.state.reminderModal && (
            <AddReminderModal
              reminder={r => {
                this.props.newReminderAdded(r);
              }}
              onClose={_ => this.setState({ reminderModal: false })}
            />
          )}
          {/*<CalendarDetailsModal
            open={this.state.detailsModalShown}
            selectedEvent={this.state.modalData}
            onClose={this.deleteEvent}
            student={null}
          />*/}
          {this.state.displayCalendarCreateModal && (
            <CalendarCreateModal
              onClose={() => {
                this.setState({ displayCalendarCreateModal: false });
              }}
              history={this.props.history}
              openTasksModal={isTask => this.setState({ taskModal: isTask })}
              openRemindersModal={isReminder =>
                this.setState({ reminderModal: isReminder })
              }
            />
          )}
          <div
            className="cal-menu d-flex"
            style={{
              position: "relative",
              marginBottom: "0px",
              height: "60px",
              backgroundColor: "#FFF"
            }}
          >
            <button
              type="button"
              className="btn btn-sm move-day"
              data-action="move-prev"
              onClick={() => this.handleRangeChange("prev")}
              style={{ width: "5%", marginRight: "10px", zIndex: 1 }}
            >
              <i className="fas fa-arrow-left"></i>
            </button>
            <button
              type="button"
              className="btn btn-sm move-day"
              data-action="move-next"
              onClick={() => this.handleRangeChange("next")}
              style={{ width: "5%", marginRight: "10px", zIndex: 1 }}
            >
              <i className="fas fa-arrow-right"></i>
            </button>
            <button
              style={{
                marginRight: "20px",
                height: "70%",
                alignSelf: "center",
                zIndex: 1,
                backgroundColor: "#FFF",
                color: "#7ea1ff",
                border: "1px solid #7ea1ff"
              }}
              onClick={_ => this.handleRangeChange("today")}
            >
              Today
            </button>
            <div className="forbtnwrap" style={{ zIndex: "1" }}>
              <div className="forbtnapp">
                <button
                  className="newapplications mobile-calendar-btn"
                  disabled={this.canSubUserEdit()}
                  style={{
                    cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                  }}
                  onClick={_ =>
                    this.setState({
                      displayCalendarCreateModal: true
                    })
                  }
                >
                  <img
                    src={MiniPlus}
                    alt=""
                    style={{ alignSelf: "center" }}
                    className="mobile-calendar-img"
                  />
                  Create
                </button>
              </div>
            </div>
            <span
              id="renderRange"
              className="render-range"
              style={{
                alignSelf: "center",
                position: "absolute",
                width: "100%",
                textAlign: "center",
                fontWeight: "bold"
              }}
            >
              {displayed}
            </span>
            <i
              className="fas fa-filter"
              style={{
                marginLeft: "auto",
                marginRight: "20px",
                height: "30%",
                alignSelf: "center",
                zIndex: 1,
                backgroundColor: "#FFF",
                color: "#7ea1ff",
                cursor: "pointer"
              }}
              aria-describedby={
                Boolean(this.state.popoverAnchorE1)
                  ? "simple-popover"
                  : undefined
              }
              onClick={this.handleFilterClick.bind(this)}
            ></i>
            <Popover
              id={
                Boolean(this.state.popoverAnchorE1)
                  ? "simple-popover"
                  : undefined
              }
              open={Boolean(this.state.popoverAnchorE1)}
              anchorEl={this.state.popoverAnchorE1}
              onClose={this.handleClose.bind(this)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
            >
              <List>
                {this.filterListLabels.map((value, i) => {
                  const labelId = `checkbox-list-label-${i}`;
                  return (
                    <ListItem
                      key={value}
                      role={undefined}
                      dense
                      button
                      onClick={_ => this.handleFilterListToggle(value)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={
                            this.state.filterListChecked.indexOf(value) !== -1
                          }
                          tabIndex={-1}
                          disableRipple
                          primary
                          color="primary"
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={value} />
                    </ListItem>
                  );
                })}
              </List>
            </Popover>
          </div>
          <div className="toast-container">
            <ToastCalendar
              usageStatistics={false}
              ref={this.calendarRef}
              taskView={false}
              scheduleView={["time"]}
              view={view}
              height={"100%"}
              calendars={[
                {
                  id: "0",
                  name: "time",
                  bgColor: "transparent",
                  borderColor: "transparent"
                }
              ]}
              disableDblClick={true}
              disableClick={true}
              isReadOnly={false}
              onClickSchedule={e => this.openDetails(e)}
              month={{
                startDayOfWeek: 0
              }}
              schedules={this.state.schedules}
              theme={theme}
              template={{
                time(sch) {
                  return `<span style="color: white">${sch.title}</span>`;
                }
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

CalendarPartner.propTypes = {
  maxWidth: PropTypes.bool
};

CalendarPartner.defaultProps = {
  maxWidth: false
};

export default withRouter(CalendarPartner);
