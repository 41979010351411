import React from "reactn";

import { format } from "date-fns";

import ReactTooltip from "react-tooltip";

import { ParentMessage, Letter, Reporting10 as SMS } from "../../../../assets";
import { getLastDigits, formatPhoneNumber } from "./commUtil";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";




/**
 * Displays SMS or Emails sent to a given group.
 *
 * @param {Object} message Message object
 * @param {Object} userInfoLT a dictionary of id to user-profile mappings, eg. key=1234 => value=user data for user#1234.
 * @param {callback} onRefCallback This is to allow the parent component to be notified when "ref" is available.
 */
const MessageView = ({ message, userInfoLT, onRefCallback }) => {

  const [showRecipientList,setShowRecipientList] = React.useState(false);
  const [collapsedView,setCollapsedView] = React.useState(message.isCollapsed);

  const prepareToInfo = (message) => {
    const recipientList = (message?.to || []).map((recip, i) => {

      //first locate the user profile info that matches the email or phone#
      const messageUserData = userInfoLT[recip.dsUserId];               //<----------- only used to load the damn photos!?!?

      //get thumbnail photo from user profile.
      const thumbnailPhoto = messageUserData && messageUserData.photo
                        ? `${process.env.REACT_APP_DS_FILES_S3}/${messageUserData.photo}`
                        : ParentMessage;

      //const sentStatus = message.sentStatus[recip.email];
      let sentStatusBadge = null;
      let tooltipWarning = null;
      if (!message.hasSentStatus || recip.sent) {
        //currently no special handling if we don't track the sent status.
        //Just act the same as if the mail APIs did not report any issues.
        // 1.because we weren't tracking it historically.
        // 2.because we're not tracking SendGrid status the same way as Gmail.
      } else if (recip.sent===false) {
        //This is where message was sent, and API server clearly indicated an error!
        sentStatusBadge = <div style={{position:"relative", top:"-0.8em", color:"#ff0000"}}><i className="fas fa-exclamation-circle"></i></div>;
        tooltipWarning = <><br />(failed to send after many attempts)</>;
      } else /*if (recip.sent===null || recip.sent===undefined)*/ {
        //This is where message was sent, but no acknowledgement received from API server yet, or in the process of retrying.
        sentStatusBadge = <div style={{position:"relative", top:"-0.8em", color:"#ff8d00"}}><i className="fas fa-question-circle"></i></div>;
        tooltipWarning = <><br />(waiting to send or retry)</>;
      }
      return {
        name: recip.name,
        email: recip.email,
        phone: recip.phone,
        sentStatusBadge, tooltipWarning,
        thumbnailPhoto: thumbnailPhoto
      };
    });

    const recipientSummary = message.stage
      ? "All "+message.stage                                               //e.g. All Enclosed; All Pending; All Inactive
      : message.to.length + " recipient" + (message.to.length===1?"":"s");  //e.g. 1 recipient; 2 recipients

    return { recipientList, recipientSummary };
  };

  message.isCollapsed = collapsedView;
  //console.log("💾message.isCollapsed="+message.isCollapsed);

  const subject = message.is_email
    ? (message.email_subject.trim().length===0
        ? "(no subject)"
        : message.email_subject)
    : null;
  const date = message.createdAt && format(new Date(message.createdAt), "PPPPp");

  const {recipientList:toList, recipientSummary} = prepareToInfo(message);

  //console.log("🔄 refreshing MessageView");
  return (
    <div
      className="message-view"
      ref={onRefCallback}
    >
      <div
        style={{cursor:"pointer"}}
        onClick={(e) => { e.stopPropagation(); setCollapsedView(!collapsedView); }}
      >
      {/* ======= To: ========================================================================= */}
      <div className="view-row" style={{ marginBottom:0 }}>
        <span className="row-label">
          <b>To</b>
        </span>
        <span className="row-content-emphasis">{recipientSummary}</span> &nbsp;
        (<span
          className="row-content-actionable"
          onClick={(e) => { e.stopPropagation(); setShowRecipientList(!showRecipientList); }}
        >view recipients</span>)
        {message.is_email ? 
          (<div style={{ marginLeft: "auto" }} data-tip data-for="email">
            <LazyLoadImage
              src={Letter}
              className="pfp-alt"
              alt="email"
            />
            <ReactTooltip
              id="email"
              place="top"
              effect="solid"
              type="info"
            >Email</ReactTooltip>
          </div>)
          : 
          (<div style={{ marginLeft: "auto" }} data-tip data-for="text">
            <LazyLoadImage
              src={SMS}
              className="pfp-alt"
              alt="sms"
            />
            <ReactTooltip
              id="text"
              place="top"
              effect="solid"
              type="info"
            >SMS</ReactTooltip>
          </div>)
        }
      </div>

      {/* ======= Expandable Recipient List: ================================================== */}
      {showRecipientList && (
      <div className="view-row">
        <span className="row-label"></span>
        <span className="row-content">
          <div className="users-cont">

          {toList.map((recip, i) => (
            <React.Fragment key={i}>
              <span
                data-tip
                data-for={recip.email || recip.phone}
                key={i}
                className="user-bubble"
              >
                <LazyLoadImage
                  src={recip.thumbnailPhoto}
                  className="pfp"
                  alt="pfp"
                />
                <span>{recip.name}</span>
                {recip.sentStatusBadge}
              </span>
              <ReactTooltip
                id={recip.email || recip.phone}
                place="top"
                effect="solid"
                type="info"
              >
                {recip.email || formatPhoneNumber(recip.phone) || `<unspecified>`}
                {recip.tooltipWarning}
              </ReactTooltip>
            </React.Fragment>
          ))}

          </div>
        </span>
      </div>
      )}

      {/* ======= Subject: ==================================================================== */}
      {subject && (
      <div className="view-row">
        <span className="row-label">
          <b>Subject</b>
        </span>
        <span className="row-content">{subject}</span>
      </div>
      )}

      {/* ======= Date: ======================================================================= */}
      {date && (
      <div className="view-row">
        <span className="row-label">
          <b>Date</b>
        </span>
        <span className="row-content">{date}</span>
      </div>
      )}
      </div>

      {/* ======= Body: ======================================================================= */}
      {!message.isCollapsed && (
      <div className="body">
        {message.is_email ? (
          // For emails, the content is in HTML format.
          <div
            dangerouslySetInnerHTML={{
              __html: message.body
            }}
          ></div>
        ) : (
          // For SMS messages, the content is in plaintext.
          message.body
        )}
      </div>
      )}

    </div>
  );

};



export default MessageView;
