import React from "reactn";
import { NewTabbedTable } from "../../components/UI";
import axios from "axios";
import { toast } from "react-toastify";
import { format, subDays } from "date-fns";
import "../../assets/css/componentSpecificCss/recurringPayments.css";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import RefundModal from "../../components/modals/RefundModal";
import RetryPaymentModal from "../../components/modals/RetryPaymentModal";
import EmailClientModal from "../../components/modals/EmailClientModal";
import TotalRecurringModal from "../../components/modals/TotalRecurringModal";
import AutoPayViewModal from "../../components/modals/AutoPayViewModal";
import AutoPayEditModal from "../../components/modals/AutoPayEditModal";
import ArchiveModal from "../../components/modals/ArchiveModal";

class RecurringPayments extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      filter: "",
      tours: [],
      original: [],
      leadModal: false,
      openRefundModal: false,
      openRetryPaymentModal: false,
      openEmailClientModal: false,
      openConfirmModal: false,
      openEditAmountModal: false,
      onSubmit: () => { },
      selectedItem: null,
      openAutoPayViewModal: false,
      openAutoPayEditModal: false,
      filter: "",
      removeNudge: [],
      page: -1
    };
    // hh:mm aa
    this.tabs = ["Pending", "Payments"];

    this.actions = [{ name: "Notes", action: null }];
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Recurring Payments", to: "/recurring" }
      ]
    });
  }

  getStatusColor = (status, auth) => {
    if (status === "Draft") {
      return "rgb(33 91 255)";
    } else if (status === "Ready to Bill" || status === "Paused") {
      return "#eba93e";
    } else if (status === "Cancelled") {
      return "#ff3b8e";
    } else if (status === "Issued" && auth) {
      return "#ff3b8e";
    } else if (status === "Issued" || status === "Issued (Manual)") {
      return "rgba(92 200 220)";
    } else if (status === "Late" || status === "Failed" || status === "Late (Manual)") {
      return "#ff3b8e";
    } else if (status === "Processing") {
      return "rgb(33 91 255)";
    } else if (status === "Pending") {
      return "";
    } else {
      return "#1bc88a";
    }
  };

  headings = () => {
    return {
      Pending: [
        {
          id: "amount",
          label: "Due Date",
          customCell: (r, i) => {
            return (
              <td key={i}>
                {r.date ? format(new Date(r.date), "LLL dd, yyyy") : ""}
              </td>
            );
          }
        },
        {
          id: "amountOverdue",
          label: "Item",
          customCell: (r, i) => {
            return <td key={i}>{r.item}</td>;
          }
        },
        {
          id: "amountOverdue",
          label: "Total",
          customCell: (r, i) => {
            return <td key={i}>${parseFloat(r.total / 100).toFixed(2)}</td>;
          }
        },
        {
          id: "membership_term",
          label: "Purchaser",
          customCell: (r, i) => {
            if (r.childName?.length > 0) {
              const childNamesConcatenated = r.childName.join(", ");

              const truncatedChildNames =
                childNamesConcatenated.length > 25
                  ? childNamesConcatenated.substring(0, 25) + "..."
                  : childNamesConcatenated;
              return (
                <td className="table-custom-text">
                  {r.purchaser} (
                  <span style={{ fontStyle: "italic" }}>
                    {truncatedChildNames}
                  </span>
                  )
                </td>
              );
            }
            return (
              <td key={i} className="table-custom-text">
                {r.purchaser}
              </td>
            );
          }
        },
        {
          id: "max_amount",
          label: "Status",
          customCell: (r, i) => {
            return (
              <td key={i}>
                <span
                  className="red"
                  style={{
                    maxWidth: "100px",
                    backgroundColor: this.getStatusColor(r.status, r.requires_auth)
                  }}
                >
                  {r.requires_auth && r.status === "Issued" ? "Failed" : r.status}
                </span>
              </td>
            );
          }
        },
        {
          id: "actio",
          label: "",
          customStyle: { width: 80 },
          customCell: (r, i) => (
            <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
              <div>
                <Dropdown
                  trigger={["click"]}
                  overlay={this.pendingMenu(r, i)}
                  animation="slide-up"
                >
                  <button
                    className="table-action-button"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    disabled={this.canSubUserEdit()}
                  >
                    Action
                  </button>
                </Dropdown>
              </div>
            </td>
          )
        }
      ],
      Payments: [
        {
          id: "payment_date",
          label: "Payment Date",
          customCell: (r, i) => {
            return (
              <td key={i}>
                {r.userPaidDate
                  ? format(new Date(r.userPaidDate), "LLL dd, yyyy")
                  : ""}
              </td>
            );
          }
        },
        {
          id: "amount",
          label: "Due Date",
          customCell: (r, i) => {
            return (
              <td key={i}>
                {r.date ? format(new Date(r.date), "LLL dd, yyyy") : ""}
              </td>
            );
          }
        },
        {
          id: "amountOverdue",
          label: "Item",
          customCell: (r, i) => {
            return <td key={i}>{r.item}</td>;
          }
        },
        {
          id: "amountOverdue",
          label: "Total",
          customCell: (r, i) => {
            return <td key={i}>${parseFloat(r.total / 100).toFixed(2)}</td>;
          }
        },
        {
          id: "membership_term",
          label: "Purchaser",
          customCell: (r, i) => {
            if (r.childName?.length > 0) {
              const childNamesConcatenated = r.childName.join(", ");

              const truncatedChildNames =
                childNamesConcatenated.length > 25
                  ? childNamesConcatenated.substring(0, 25) + "..."
                  : childNamesConcatenated;
              return (
                <td className="table-custom-text">
                  {r.purchaser} (
                  <span style={{ fontStyle: "italic" }}>
                    {truncatedChildNames}
                  </span>
                  )
                </td>
              );
            }
            return (
              <td key={i} className="table-custom-text">
                {r.purchaser}
              </td>
            );
          }
        },
        {
          id: "membership_term",
          label: "Paid Using",
          customCell: (r, i) => <td key={i}>{r.paymentMethod}</td>
        },
        {
          id: "max_amount",
          label: "Status",
          customCell: (r, i) => {
            return (
              <td key={i}>
                <span
                  className="red"
                  style={{
                    maxWidth: "100px",
                    backgroundColor: this.getStatusColor(r.status, r.requires_auth)
                  }}
                >
                  {r.requires_auth ? "Requires Action" : r.status}
                </span>
              </td>
            );
          }
        },
        {
          id: "actio",
          label: "",
          customStyle: { width: 80 },
          customCell: (r, i) => (
            <td key={i} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
              <div>
                <Dropdown
                  trigger={["click"]}
                  overlay={this.menu(r, i)}
                  animation="slide-up"
                >
                  <button
                    className="table-action-button"
                    style={{
                      cursor: this.canSubUserEdit() ? "not-allowed" : "pointer"
                    }}
                    disabled={this.canSubUserEdit()}
                    onClick={() => console.log(r)}
                  >
                    Action
                  </button>
                </Dropdown>
              </div>
            </td>
          )
        }
      ]
    };
  };

  /**
   * This function handles the edit button functionality of a sub user
   * returns a boolean value
   */
  canSubUserEdit = () => {
    if (!this.global.dsUser.accesspoints) {
      return false;
    }
    if (this.global.dsUser.accesspoints) {
      return !this.global.dsUser.accesspoints.Financials["Recurring Payments"]
        .edit;
    }
  };

  displayTax = (r, i) => {
    const isInvoice = r.object === "invoice";
    if (
      r.payment_nature === "Subscription" &&
      r.product_table !== "partner_memberships"
    ) {
      if (isInvoice) {
        const plan = r.product
          ? JSON.parse(r.product.plans[r.planId]).isTaxEnabled
          : JSON.parse(r.plans[r.planId]).isTaxEnabled;
        return (
          <td key={i}>{r.tax_percent && plan ? `${r.tax_percent}%` : "0%"}</td>
        );
      } else {
        return (
          <td key={i}>
            {JSON.parse(r.product.plans[r.planId]).isTaxEnabled
              ? `${JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage
              }%`
              : "0%"}
          </td>
        );
      }
    } else if (
      r.payment_nature === "Subscription" &&
      r.product_table === "partner_memberships"
    ) {
      if (isInvoice) {
        const plan = r.product
          ? JSON.parse(r.product.prices[r.planId]).isTaxEnabled
          : JSON.parse(r.plans[r.planId]).isTaxEnabled;
        return (
          <td key={i}>{r.tax_percent && plan ? `${r.tax_percent}%` : "0%"}</td>
        );
      } else {
        return (
          <td key={i}>
            {JSON.parse(r.product.prices[r.planId]).isTaxEnabled
              ? `${JSON.parse(r.product.prices[r.planId]).taxInfo[0].percentage
              }%`
              : "0%"}
          </td>
        );
      }
    } else {
      return <td key={i}>0%</td>;
    }
  };

  onSelect = async (r, i, event) => {
    if (event.key === "MarkPaid") {
      this.markAsPaid(r);
    } else if (event.key === "MarkUnpaid") {
      this.markAsUnpaid(r);
    } else if (event.key === "refund") {
      this.setState({ openRefundModal: true, selectedItem: r });
    } else if (event.key === "retry-payment") {
      this.setState({ openRetryPaymentModal: true, selectedItem: r });
    } else if (event.key === "emailClient") {
      this.setState({ openEmailClientModal: true, selectedItem: r });
    } else if (event.key === "viewDetails") {
      this.setState({ openTotalModal: true, selectedItem: r });
    } else if (event.key === "viewInvoice") {
      // if (r.is_manual_stripe) {
      if (/^in_/.test(r.invoice)) {
        this.props.history.push(
          // `/partners/recurring/invoice/${r.invoice.replace(/in_/, "")}`
          `/partners/orders/orders-receipt/${r.id}`
        );
      } else {
        this.props.history.push({
          pathname: `/partners/orders/orders-receipt/${r.id}`,
          state: { isNewCustomer: false }
        });
      }
    }
  };

  menu = (r, i) => {
    if (!((r.status === "Paid" || r.status === "paid") && !r.justDeposit)) {
      console.log("r", r);
    }
    return (
      <Menu onClick={event => this.onSelect(r, i, event)}>
        {/* {r.manual && !r.status && (
          <MenuItem key="MarkPaid">Mark as Paid</MenuItem>
        )} */}
        {/* {
          // r.manual &&
          r.status === "Paid" && (
            <MenuItem key="MarkUnpaid">Mark as Unpaid</MenuItem>
          )
        } */}
        {/* {!r.manual && r.charge && <MenuItem key="refund">Refund</MenuItem>} */}
        {(r.status === "Paid" ||
          r.status === "paid" ||
          r.status === "Paid (refunded)") && (
            <MenuItem key="refund">Refund</MenuItem>
          )}
        {/* {!r.manual && r.charge && (
          <MenuItem key="emailClient">Email Client</MenuItem>
        )} */}
        {/* {(r.is_manual_stripe || r.subscription) && (
          <MenuItem key="viewInvoice">View Invoice</MenuItem>
        )} */}
        {/* {r.stripe_transaction_id.includes("sub_") ? (
          <MenuItem key="viewDetails">View Details</MenuItem>
        ) : ( */}
        {r.status === "Failed" && (
          <MenuItem key="retry-payment">Retry Payment</MenuItem>
        )}
        <MenuItem key="viewInvoice">View Invoice</MenuItem>
        {/* )} */}
      </Menu>
    );
  };

  getEditDate = r => {
    const createdDate = new Date(r.due_date);
    // const createdDate = new Date(subDays(new Date(), 4));
    const frequency = r.frequency;

    let middleDate = null;
    if (frequency === "week") {
      middleDate = subDays(createdDate, 4);
    } else if (frequency === "month") {
      middleDate = subDays(createdDate, 15);
    } else if (frequency === "bi-week") {
      middleDate = subDays(createdDate, 7);
    } else if (frequency === "membership-month") {
      middleDate = subDays(createdDate, 15);
    }

    return middleDate;
  };

  atEditDate = r => {
    const middleDate = this.getEditDate(r);

    return new Date(middleDate) <= new Date();
  };

  pendingOnSelect = (r, i, event) => {
    if (event.key === "MarkPaid") {
      if (r.payment_nature === "Deposit") {
        this.markDepositAsPaid(r);
      } else {
        this.markAsPaid(r);
      }
    } else if (event.key === "MarkUnpaid") {
      this.markAsUnpaid(r);
    } else if (event.key === "readyToBill") {
      this.changeInvoiceStatus(r, "Ready to Bill");
    } else if (event.key === "returnToDraft") {
      this.changeInvoiceStatus(r, "Draft");
    } else if (event.key === "refund") {
      this.setState({ openRefundModal: true, selectedItem: r });
    } else if (event.key === "emailClient") {
      this.setState({ openEmailClientModal: true, selectedItem: r });
    } else if (event.key === "cancelSub") {
      this.cancelSub(r);
    } else if (event.key === "editSubscription") {
      // if (r.subscription && r.subscription.includes("sub_")) {
      //   if (this.atEditDate(r)) {
      //     console.log("open edit modal");
      //     this.setState({ openAutoPayEditModal: true, selectedItem: r });
      //   } else {
      //     console.log("open view modal");
      //     this.setState({ openAutoPayViewModal: true, selectedItem: r });
      //   }
      //   return;
      // }
      this.props.history.push(
        `/partners/recurring/upcoming/${r.stripe_transaction_id.replace(
          /sub_/,
          ""
        )}`
      );
    } else if (event.key === "overview") {
      this.setState({ openAutoPayViewModal: true, selectedItem: r });
    } else if (event.key === "editManualStripe") {
      this.props.history.push({
        pathname: `/partners/orders/invoice-builder/${r.id}`,
        state: { isNewCustomer: false }
      });
    } else if (event.key === "retry-payment") {
      this.setState({ openRetryPaymentModal: true, selectedItem: r });
    } else if (event.key === "nudgeAuthentication") {
      let ep = `${process.env.REACT_APP_API}/par/nudge-authentication`;

      const results = axios.put(ep, {
        data: r
      });

      this.setState({
        removeNudge: [...this.state.removeNudge, r.id]
      })

    } else if (event.key === "editAmount") {
      this.editAmount(r);
    } else if (event.key === "viewDetails") {
      this.setState({ openTotalModal: true, selectedItem: r });
    } else if (event.key === "archive") {
      this.setState({ openArchiveModal: true, selectedItem: r });
    }
  };

  pendingMenu = (r, i) => {
    const text =
      r.invoice_status === "Issued" ? "View Details" : "Edit Invoice";

    if (r.stripe_transaction_id && r.stripe_transaction_id.includes("sub_")) {
      return (
        <Menu onClick={event => this.pendingOnSelect(r, i, event)}>
          {this.atEditDate(r) && r.status !== "Failed" && (
            <MenuItem key="editSubscription">Edit Invoice</MenuItem>
          )}
          {r.status === "Failed" && (
            <MenuItem key="retry-payment">Retry Payment</MenuItem>
          )}
          {r.requires_auth && r.invoice_status === "Issued" && !this.state.removeNudge.includes(r.id) && (
            <MenuItem key="nudgeAuthentication">Nudge</MenuItem>
          )}
          <MenuItem key="overview">Overview</MenuItem>
          {(r.status_stage === "Cancelled" ||
            r.status_stage === "Rejected" ||
            r.status_stage === "Cancelled_Unpaid") && (
              <MenuItem key="archive">Archive</MenuItem>
            )}
        </Menu>
      );
    } else {
      // before half way point between createdAt and next_invoice_date
      const middleDate = new Date(
        (new Date(r.createdAt).getTime() + new Date(r.due_date).getTime()) / 2
      );
      if (r.id === 1014) {
        console.log("middle date", middleDate);
      }
      if (new Date() < middleDate) {
      }
      if (this.atEditDate(r)) {
        return (
          <Menu onClick={event => this.pendingOnSelect(r, i, event)}>
            <MenuItem key="overview">Overview</MenuItem>
            <MenuItem key="editManualStripe">{text}</MenuItem>
            {/* {r.status === "Ready To Bill" && (
              <MenuItem key="returnToDraft">Return to Draft</MenuItem>
            )} */}
            {(r.status_stage === "Cancelled" ||
              r.status_stage === "Rejected" ||
              r.status_stage === "Cancelled_Unpaid") && (
                <MenuItem key="archive">Archive</MenuItem>
              )}
          </Menu>
        );
      } else {
        return (
          <Menu onClick={event => this.pendingOnSelect(r, i, event)}>
            <MenuItem key="overview">Overview</MenuItem>
            {(r.status_stage === "Cancelled" ||
              r.status_stage === "Rejected" ||
              r.status_stage === "Cancelled_Unpaid") && (
                <MenuItem key="archive">Archive</MenuItem>
              )}
          </Menu>
        );
      }
    }
  };

  async changeInvoiceStatus(r, status) {
    const ep = `${process.env.REACT_APP_API}/partners/orders/update_status`;
    const res = await axios.post(ep, { ...r, updated_status: status });
    if (res.data.success) {
      this.fetchInitialData();
      toast.success("Successfully updated invoice status!");
    }
  }

  async markAsPaid(r) {
    const ep = `${process.env.REACT_APP_API}/partners/orders/mark_paid`;
    const res = await axios.put(ep, r);
    if (res.data.success) {
      this.fetchInitialData();
      // this.setState({
      //   data: {
      //     ...this.state.data,
      //     Pending: this.state.data.Pending.map(e =>
      //       e.id === r.id ? { ...e, status: true } : e
      //     )
      //   }
      // });
    }
  }

  async markAsUnpaid(r) {
    const ep = `${process.env.REACT_APP_API}/partners/orders/mark_unpaid`;
    const res = await axios.put(ep, r);

    if (res.data.success) {
      this.fetchInitialData();
    }
  }

  async markDepositAsPaid(r) {
    const ep = `${process.env.REACT_APP_API}/partners/orders/mark_paid_deposit`;
    const res = await axios.put(ep, r);
    if (res.data.success) {
      this.fetchInitialData();
      // this.setState({
      //   data: {
      //     ...this.state.data,
      //     Pending: this.state.data.Pending.filter(e => {
      //       if(r.payment_nature === "Deposit" && e.depositId === r.depositId){
      //         return
      //       }else return e
      //     }
      //     )
      //   }
      // });
    }
  }

  async componentDidMount() {
    this.global.organizationInfo.stripeId && this.fetchInitialData();
  }

  fetchInitialData = async () => {
    this.setGlobal({ loading: true });
    try {
      const ep = `${process.env.REACT_APP_API}/partners/order-tracking-recurring`;
      const results = await axios.get(ep);
      console.log("results", results);

      if (results.data.success) {
        this.setState({
          data: {
            Payments: [
              ...results.data.data.paid,
              ...results.data.data.deposits.filter(
                e =>
                  e.status === "Paid" ||
                  e.status === "Processing" ||
                  e.status === "Paid (refunded)" ||
                  e.status === "Paid (late)"
              )
            ].sort((a, b) => {
              if (!a.userPaidDate && !b.userPaidDate) {
                return new Date(b.date) - new Date(a.date);
              } else if (!a.userPaidDate) {
                return new Date(b.userPaidDate) - new Date(a.date);
              } else if (!b.userPaidDate) {
                return new Date(b.date) - new Date(a.userPaidDate);
              } else {
                return new Date(b.userPaidDate) - new Date(a.userPaidDate);
              }
            }),
            Pending: [
              ...results.data.data.pending
                .filter(e => {
                  if (e.status === "Cancelled") {
                    if (new Date(e.due_date) - new Date() < 0) {
                      return false;
                    }
                  }
                  return e.date;
                })
                .sort((a, b) => {
                  return new Date(a.date) - new Date(b.date);
                }),
              ...results.data.data.pending.filter(e => {
                if (e.status === "Cancelled") {
                  if (new Date(e.due_date) - new Date() < 0) {
                    return false;
                  }
                }
                return !e.date;
              }),
              ...results.data.data.deposits.filter(
                e =>
                  e.status !== "Paid" &&
                  e.status !== "Processing" &&
                  e.status !== "Paid (refunded)" &&
                  e.status !== "Paid (late)"
              )
            ]
          }
        });
      }
    } catch (error) {
      toast.error("Something went wrong retrieving payments from Stripe.");
      console.error(error);
    }
    this.setGlobal({ loading: false });
  };

  acceptPartnership = async row => {
    const ep = `${process.env.REACT_APP_API}/adm/partner/accept`;
    const results = await axios.post(ep, { partner: row });
    if (results.data.success) {
      toast.success(`Partner Accepted`);
      this.componentDidMount();
    } else {
      toast.error(`Error, this person already signed-up!`);
    }
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt(
          (Date.now() - new Date(r.createdAt).getTime()) / (3600000 * 24)
        )}
      </td>
    );
  };

  getFilteredOrders() {
    const ordersArr = JSON.parse(JSON.stringify(this.state.data));
    if (ordersArr["Pending"]) {
      ordersArr["Pending"] = ordersArr["Pending"].filter(order => {
        const value = order?.purchaser?.toLowerCase().replace(/\s/g, "");
        const item = order?.item?.toLowerCase().replace(/\s/g, "");
        const childName = order?.childName?.map(name => name.toLowerCase().replace(/\s/g, ""));
        const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
        return value.includes(filter) || (item && item.includes(filter)) ||
              (childName && childName.length > 0 
                && childName.filter(name => name.includes(filter)).length > 0);
      });
    }

    if (ordersArr["Payments"]) {
      ordersArr["Payments"] = ordersArr["Payments"].filter(order => {
        const value = order?.purchaser?.toLowerCase().replace(/\s/g, "");
        const item = order?.item?.toLowerCase().replace(/\s/g, "");
        const childNames = order?.childName?.map(name => name.toLowerCase().replace(/\s/g, ""));
        const filter = this.state.filter.toLowerCase().replace(/\s/g, "");
        return value.includes(filter) || (item && item.includes(filter)) ||
              (childNames && childNames.length > 0 
                && childNames.filter(name => name.includes(filter)).length > 0);
      });
    }

    return ordersArr;
  }

  render() {
    console.log("this.state", this.state);
    return (
      <>
        {this.state.openRefundModal && (
          <RefundModal
            selectedItem={this.state.selectedItem}
            amountRefunded={
              // this.state.selectedItem?.charge?.amount_refunded ||
              // this.state.selectedItem?.stripe?.charges?.data?.[0]
              //   ?.amount_refunded
              this.state.selectedItem.refundedAmount
            }
            post_payment_credit_notes_amount={
              this.state.selectedItem?.post_payment_credit_notes_amount || 0
            }
            refresh={this.fetchInitialData}
            onClose={_ => {
              // this.fetchInitialData();
              this.setState({ openRefundModal: false });
            }}
            usage={"recurring_payments"}
          />
        )}
        {this.state.openRetryPaymentModal && (
          <RetryPaymentModal
            selectedItem={this.state.selectedItem}
            refresh={this.fetchInitialData}
            onClose={_ => {
              // this.fetchInitialData();
              this.setState({ openRetryPaymentModal: false });
            }}
            usage={"recurring_payments"}
          />
        )}
        {this.state.openEmailClientModal && (
          <EmailClientModal
            selectedItem={this.state.selectedItem}
            charge={this.state.selectedItem.charge}
            onClose={_ => {
              // this.fetchInitialData();
              this.setState({ openEmailClientModal: false });
            }}
          />
        )}
        {this.state.openTotalModal && (
          <TotalRecurringModal
            onClose={_ => this.setState({ openTotalModal: false })}
            selectedItem={this.state.selectedItem}
          />
        )}
        {this.state.openArchiveModal && (
          <ArchiveModal
            selectedItem={this.state.selectedItem}
            refresh={this.fetchInitialData}
            onClose={_ => this.setState({ openArchiveModal: false })}
          />
        )}
        {this.state.openAutoPayViewModal && (
          <AutoPayViewModal
            history={this.props.history}
            onClose={() => this.setState({ openAutoPayViewModal: false })}
            editDate={this.getEditDate(this.state.selectedItem)}
            selectedItem={this.state.selectedItem}
          />
        )}
        {this.state.openAutoPayEditModal && (
          <AutoPayEditModal
            onClose={() => this.setState({ openAutoPayEditModal: false })}
            selectedItem={this.state.selectedItem}
          />
        )}
        <div className="admin">
          <div className="container-fluid adminprofiles">
            <div className="row cont">
              <div className="col-md-6">
                <h1>Recurring Payments</h1>
              </div>
            </div>
            <div className="cont">
              <div className="tablecont">
                <div className="table-responsive">
                  <div
                    style={{
                      display: "inline-flex",
                      flex: "1 1",
                      border: "none",
                      borderRadius: "10px",
                      overflow: "hidden",
                      width: "100%"
                    }}
                  >
                    <i
                      className="fas fa-search"
                      style={{ padding: "8px", background: "white" }}
                    ></i>
                    <input
                      type="text"
                      placeholder="Search by purchaser, child, or item"
                      style={{
                        border: "none",
                        fontSize: "13px",
                        padding: "5px",
                        outline: 0,
                        background: "white",
                        flex: 1
                      }}
                      value={this.state.filter}
                      onChange={e => {
                        this.setState({ 
                          filter: e.target.value,
                          page: 0 
                        });
                      }}
                    />
                  </div>
                  <NewTabbedTable
                    tabs={this.tabs}
                    headings={this.headings()}
                    page={"Financials>Recurring Payments"}
                    data={this.getFilteredOrders()}
                    actions={this.actions}
                    currentPage={this.state.page}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RecurringPayments;
