import React from "react";
import ModalFormLabel from "./ModalFormLabel";

import "./ModalFormGroup.css";

/**
 * Select Form Group component.
 *
 * @param label Display Label of the form group
 * @param name Input attribute and label htmlFor attribute.
 *
 * @param tooltip Text of tooltip (optional).
 *
 * @param value Value of select element.
 * @param onChange OnChange handler of element.
 * @param required Whether the field is required.
 * @param children JSX representing the options.
 */
const ModalFormGroup = ({
  label,
  name,
  tooltip,
  value,
  onChange,
  required,
  children,
  ...options
}) => (
  <div className="form-group modal-form-group">
    <ModalFormLabel
      label={label}
      name={name}
      tooltip={tooltip}
      required={required}
    />
    <select
      name={name}
      id={name}
      className={options.className || "form-control"}
      style={options.style || { width: "70%" }}
      value={value}
      onChange={onChange}
      required={required}
      {...options}
    >
      <option selected disabled hidden>
        Please Select
      </option>
      {children}
    </select>
  </div>
);

export default ModalFormGroup;
