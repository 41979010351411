import React, { useState, useEffect, useGlobal } from "reactn";
import _ from "lodash";
import { format, parse, fromUnixTime, addDays } from "date-fns";
import axios from "axios";

import {
  useListings,
  useGroupedOrders,
  useDateFilter,
  MILLISECONDS_IN_SECONDS,
  CENTS_IN_DOLLAR
} from "../../../providers/reports";

import {
  DateFilter,
  ReportContainer,
  ReportTable
} from "../../../components/UI/reportsAnalytics";
import {
  showPlan,
  getPlusMinus,
  getRevenueChange,
  getSubtotal,
  formatMoney
} from "../../../utilities/reportsAnalytics";

const ListingSales = () => {
  const [dateFrom, dateTo, setDateFrom, setDateTo] = useDateFilter();
  const { programs, events, memberships, onlineVirtuals } = useListings();
  const orgData = useGlobal("organizationInfo")[0];
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");

  const [listingType, setListingType] = useState("Programs");

  const [selectedId, setSelectedId] = useState(-1);

  const { programOrders, eventOrders, onlineOrders, membershipOrders } =
    useGroupedOrders();

  const [allOrders, setAllOrders] = useState([]);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [formattedOrders, setFormattedOrders] = useState([]);

  const [allListings, setAllListings] = useState([]);
  const [currentListings, setCurrentListings] = useState([]);

  useEffect(() => {
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" },
      { label: "Sales by Listings", to: "/reports/listing-sales" }
    ]);

    fetchInitialData();
  }, []);

  // change the name from "Program: XXXX" to "XXXX"
  const cleanName = orders => {
    return orders.map(o => ({
      ...o,
      item: o.item.slice(o.item.indexOf(":") + 1).trim()
    }));
  };

  const fetchInitialData = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/order-tracking`;
    const result = await axios.get(ep);
    console.log("result", result);
    const allOrders = cleanName([
      ...result.data.data.Once.payments,
      ...result.data.data.Recurring.payments
    ]).sort((a, b) => new Date(b.date) - new Date(a.date));
    setAllOrders(allOrders);

    const allListings = allOrders
      .reduce((prev, curr) => {
        if (
          prev.find(
            l =>
              l.listingId === curr.listingId &&
              l.listingType === curr.listingType
          )
        ) {
          return prev;
        }
        return [
          ...prev,
          {
            listingId: curr.listingId,
            item: curr.item,
            listingType: curr.listingType
          }
        ];
      }, [])
      .filter(e => e.listingId);
    setAllListings(allListings);
    console.log("allListings", allListings);
  };

  useEffect(() => {
    const filteredOrders = getFilteredOrders();
    setCurrentOrders(filteredOrders);
  }, [allOrders, dateFrom, dateTo, listingType, selectedId]);

  useEffect(() => {
    const filteredListings = getFilteredListings();
    setCurrentListings(filteredListings);
  }, [allOrders, allListings, listingType]);

  useEffect(() => {
    const formattedOrders = getFormattedOrders();
    setFormattedOrders(formattedOrders);
  }, [currentOrders]);

  const getFilteredOrders = () => {
    // filter out orders of different listing types
    const correctListingType = allOrders.filter(e => {
      if (listingType === "Programs") {
        return e.listingType === "program";
      } else if (listingType === "Events") {
        return e.listingType === "event";
      } else if (listingType === "Online Virtual") {
        return e.listingType === "online";
      } else if (listingType === "Memberships") {
        return e.listingType === "membership";
      }
      return false;
    });

    // filter out orders that dont have the correct listing id
    const correctListingId = correctListingType.filter(e => {
      if (selectedId === -1) {
        return false;
      }
      return parseInt(e.listingId) === parseInt(selectedId);
    });

    // filter out orders that dont have the correct date range
    const correctDateRange = correctListingId.filter(e => {
      const date = new Date(e.date);
      return new Date(dateFrom) <= date && date <= addDays(new Date(dateTo), 1);
    });

    return correctDateRange;
  };

  const getFormattedOrders = () => {
    const formattedOrders = currentOrders.map(order => [
      order.purchaser,
      order.item,
      getModifierString(order),
      formatMoney(parseInt(order.total) / 100)
    ]);
    return formattedOrders;
  };

  const getFilteredListings = () => {
    const filteredListings = allListings.filter(e => {
      if (listingType === "Programs") {
        return e.listingType === "program";
      } else if (listingType === "Events") {
        return e.listingType === "event";
      } else if (listingType === "Online Virtual") {
        return e.listingType === "online";
      } else if (listingType === "Memberships") {
        return e.listingType === "membership";
      }
      return false;
    });
    console.log("filtered listings", filteredListings);
    return filteredListings;
  };

  const getModifierString = order => {
    // modifiers are: tax, credit card fee, promotion, coupon, addOnSemesterItemAmount, gift discount, discount amount, refund
    return Object.keys(order.modifiers)
      .map(modifier =>
        parseInt(order.modifiers[modifier])
          ? `${getModifierName(modifier)} (${formatMoney(
              order.modifiers[modifier] / 100
            )})`
          : ""
      )
      .filter(e => e !== "")
      .join(" / ");
  };

  const getModifierName = modifier => {
    switch (modifier) {
      case "tax":
        return "Tax";
      case "creditCardFee":
        return "Credit Card Fee";
      case "promotion":
        return "Promotion";
      case "coupon":
        return "Coupon";
      case "addOnSemesterItem":
        return "Add On Semester Item";
      case "giftDiscount":
        return "Gift Discount";
      case "discount":
        return "Discount";
      case "refunded":
        return "Refunded";
      default:
        return "";
    }
  };

  /**
   * Function used to filter orders.
   *
   * @returns {Boolean} Whether or not the order should be displayed/considered.
   *
   * @param {Object} order The order to filter.
   * @param {String} id The selected id of the listing.
   */
  const filterOrder = (order, id) => {
    const dateFromUnix = new Date(dateFrom).getTime() / MILLISECONDS_IN_SECONDS;
    const tempDateTo = new Date(dateTo);
    // Offset by a day to provide the correct filter.
    const dateToUnix =
      tempDateTo.setDate(tempDateTo.getDate() + 1) / MILLISECONDS_IN_SECONDS;
    return (
      order.created > dateFromUnix &&
      order.created < dateToUnix &&
      order.productId.toString() === id
    );
  };

  /**
   * Returns the table rows for the revenue summary table, for a specific listing type.
   *
   * @returns {Array} An array of arrays.
   */
  const getListingData = () => {
    const a = filteredOrders.map(row => {
      const manualStatus = row.paid ? "(Manual - Paid)" : "(Manual - Pending)";
      const modifiers = getPlusMinus(row)
        .map(obj => {
          return `${obj.label} (${obj.text})`;
        })
        .join(" / ");

      return [
        row.customer_name,
        `${showPlan(row)} ${
          selectedListing.manual_invoices ? manualStatus : ""
        }`,
        getSubtotal(row),
        modifiers,
        formatMoney(row.revenue / CENTS_IN_DOLLAR)
      ];
    });
    return a;
  };

  /**
   * Gets total revenue by aggregating all order data.
   *
   * @returns {Number} Total revenue.
   */
  const getTotalRevenue = () => {
    const totalRevenue = currentOrders.reduce(
      (a, c) => a + parseInt(c.total),
      0
    );
    // const totalRevenueChange = getRevenueChange(filteredOrders);

    return formatMoney(totalRevenue / CENTS_IN_DOLLAR);
  };

  const csvHeaders = [
    { label: "Customer Name", key: "name" },
    { label: "Transaction Datetime", key: "dateTime" },
    { label: "Listing", key: "listingName" },
    { label: "Tax", key: "tax" },
    { label: "Credit Card Fee", key: "creditCardFee" },
    { label: "Promo", key: "promotion" },
    { label: "Coupon", key: "coupon" },
    { label: "Add on Semester Item", key: "addOnSemesterItem" },
    { label: "Gift Discount", key: "giftDiscount" },
    { label: "Refunded", key: "refunded" },
    { label: "Amount", key: "amount" }
  ];

  /**
   * Gets the CSV for the CSVLink component to use.
   *
   * @returns {Array<Object>} Each property of each object corresponds to a column.
   */
  const getCsvData = () => {
    // Returning null here will disable the export button.
    if (!selectedListing) {
      return null;
    }
    let csvRows = [];
    currentOrders.forEach(row => {
      csvRows.push({
        name: row.purchaser,
        dateTime: format(new Date(row.date), "yyyy-MM-dd"),
        listingName: row.item,
        tax: formatMoney(row.modifiers.tax / 100),
        creditCardFee: formatMoney(row.modifiers.creditCardFee / 100),
        promotion: formatMoney(row.modifiers.promotion / 100),
        coupon: formatMoney(row.modifiers.coupon / 100),
        addOnSemesterItem: formatMoney(row.modifiers.addOnSemesterItem / 100),
        giftDiscount: formatMoney(row.modifiers.giftDiscount / 100),
        discount: formatMoney(row.modifiers.discount / 100),
        refunded: formatMoney(row.modifiers.refunded / 100),
        amount: formatMoney(row.total / 100)
      });
    });
    csvRows.push({ name: "TOTAL", amount: `${getTotalRevenue()}` });
    return csvRows;
  };

  const tablesMap = {
    Programs: {
      listingArray: programs,
      nameProperty: "program_name",
      orders: programOrders
    },
    Events: {
      listingArray: events,
      nameProperty: "event_title",
      orders: eventOrders
    },
    Memberships: {
      listingArray: memberships,
      nameProperty: "membership_name",
      orders: membershipOrders
    },
    "Online Virtual": {
      listingArray: onlineVirtuals,
      nameProperty: "program_name",
      orders: onlineOrders
    }
  };

  let listingArray, nameProperty, orders;
  let selectedListing;
  if (listingType) {
    ({ listingArray, nameProperty, orders } = tablesMap[listingType]);
    selectedListing = listingArray.find(listing => {
      return listing.id.toString() === selectedId;
    });
  }

  const filteredOrders = orders.filter(order => {
    return filterOrder(order, selectedId);
  });

  let reportSubtitles = [
    orgData.organization_title,
    `For ${format(parse(dateFrom, "yyyy-MM-dd", new Date()), "MMM d, yyyy")}
    - 
    ${format(parse(dateTo, "yyyy-MM-dd", new Date()), "MMM d, yyyy")} 
    `
  ];
  if (selectedListing) {
    reportSubtitles.push(
      `${selectedListing[nameProperty]} ${
        selectedListing.manual_invoices ? "(Offline Payments)" : ""
      }`
    );
  }

  return (
    <div className="admin">
      <div className="container-fluid adminprofiles">
        <div className="row cont">
          <div className="col-md-6">
            <h1>Sales by Listings</h1>
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-section">
            <DateFilter
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
            />
            <div className="filter-item grid-new-col">
              <label htmlFor="client_name" className="filter-label">
                Select Listing:
              </label>
              <select
                name="client_name"
                id="client_name"
                type="text"
                className="form-control filter-input"
                onChange={e => {
                  setListingType(e.target.value);
                  setSelectedId("-1");
                }}
              >
                <option value="Programs">Program</option>
                <option value="Events">Events</option>
                <option value="Memberships">Memberships</option>
                <option value="Online Virtual">Online Virtual</option>
              </select>
              <select
                name="program"
                id="program"
                type="text"
                className="form-control filter-input"
                onChange={e => {
                  setSelectedId(e.target.value);
                }}
                value={selectedId}
              >
                <option value="-1" selected disabled hidden>
                  Please Select...
                </option>
                {listingType &&
                  // listingArray.map((listing, index) => {
                  //   return (
                  //     <option key={index} value={listing.id}>
                  //       {listing[nameProperty]}
                  //     </option>
                  //   );
                  currentListings.map((listing, index) => (
                    <option key={index} value={listing.listingId}>
                      {listing.item}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <ReportContainer
          title="Sales by Listings"
          subtitles={reportSubtitles}
          csvHeaders={csvHeaders}
          csvData={getCsvData()}
          fileName={`${selectedListing?.[nameProperty]}_Sales_${dateFrom}-${dateTo}.csv`}
          isLarge
        >
          <div className={"report-table-large"}>
            <ReportTable
              headings={[
                { label: "Summary", className: "table-item-bold" },
                { label: "", className: "table-item-money" }
              ]}
              body={[["Total Sales Revenue for Listing", getTotalRevenue()]]}
            />

            <ReportTable
              headings={[
                { label: "Customer" },
                { label: "Item" },
                { label: "+ / -" },
                {
                  label: "Amount",
                  className: "table-item-money",
                  style: { textAlign: "right" }
                }
              ]}
              body={formattedOrders}
              noDataText="No sales found for this listing."
            />
          </div>
        </ReportContainer>
      </div>
    </div>
  );
};

export default ListingSales;
