import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { toast } from "react-toastify";
import { Close } from "@material-ui/icons";
class CloseModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async submitForm(e) {
    e.preventDefault();
    const endp = `${process.env.REACT_APP_API}/adm/partner/update`;
    const result = await axios.put(endp, {
      categorie: 5,
      id: this.props.data.id
    });
    if (result.data.success) {
      toast.success("Updated Lead!");
      const objToUpdate = {
        categorie: 5,
        id: this.props.data.id
      };
      this.props.update(objToUpdate);
      this.props.onClose();
    }
  }

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div>
            <h3>Lead</h3>
            <form onSubmit={e => this.submitForm(e)}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Name *
                </label>
                <input
                  name="name"
                  id="displayName"
                  type="text"
                  className="form-control"
                  placeholder="Lead's full name..."
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={
                    this.state.name === undefined
                      ? this.props.data.name
                      : this.state.name
                  }
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="company"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Organization *
                </label>
                <input
                  name="company"
                  id="organization"
                  type="text"
                  className="form-control"
                  placeholder="Organization's name..."
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={
                    this.state.company === undefined
                      ? this.props.data.company
                      : this.state.company
                  }
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Postal / Zip
                </label>
                <input
                  name="postalcode"
                  id="postal"
                  type="text"
                  className="form-control"
                  placeholder="Postal Code..."
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={
                    this.state.postalcode === undefined
                      ? this.props.data.postalcode
                      : this.state.postalcode
                  }
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="email"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Email
                </label>
                <input
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Lead's Email..."
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={
                    this.state.email === undefined
                      ? this.props.data.email
                      : this.state.email
                  }
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Mobile
                </label>
                <input
                  name="phone"
                  id="mobile"
                  type="text"
                  className="form-control"
                  placeholder="Phone Number..."
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={
                    this.state.phone === undefined
                      ? this.props.data.phone
                      : this.state.phone
                  }
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Close Reason
                </label>
                <select
                  name="reason"
                  id="notes"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                >
                  <option>Unfit Lead</option>
                  <option>Low Interest</option>
                  <option>Not Interested</option>
                  <option>Paused Account</option>
                  <option>Canceled Account</option>
                </select>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Notes
                </label>
                <input
                  name="displayName"
                  id="displayName"
                  type="text"
                  className="form-control"
                  placeholder="Notes..."
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={
                    this.state.notes === undefined
                      ? this.props.data.notes
                      : this.state.notes
                  }
                />
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn profile-btn">
                  Close
                </button>
              </div>
            </form>

            <IconButton
              style={{ position: "absolute", right: "0", top: "0" }}
              onClick={_ => this.props.onClose()}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CloseModal);
