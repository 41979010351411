import React from "reactn";
import MyInformationForm from "./forms/myInformation";
import MyAddressForm from "./forms/myAddress";

class MyProfilePage extends React.Component {
  render() {
    return (
      <div className="cont boxes-row mt-50px">
        <MyInformationForm vals={this.global.myApplicationProfileInfo} />
        <MyAddressForm vals={this.global.myApplicationProfileAddress} />
      </div>
    );
  }
}

export default MyProfilePage;
