import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton, Switch } from "@material-ui/core";
import { formTypes } from "../UI/form";
import { Edit, Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, SWITCH, DROPDOWN, BUTTON, TITLE, GET_HTML } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class EditClassificationsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...props.data,
      editClassification1: false,
      editClassification2: false
    };
  }

  async componentDidMount() {
    try {
      const ep = `${process.env.REACT_APP_API}/partner/observations/customization`;
      const results = await axios.get(ep);

      if (results.data.success) {
        this.setState({
          ...this.state,
          classification1: results.data.data.classification1,
          classification2: results.data.data.classification2
        })
      }
    } catch (err) {
      console.error(err);
    }
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async saveEdit() {
    const ep = `${process.env.REACT_APP_API}/partner/observations/customization`;
    const result = await axios.post(ep, { classification1: this.state.classification1, classification2: this.state.classification2 })

    if (result.data.success) {
      toast.success("Observation settings saved")
      // this.props.nextPage();
    } else {
      toast.error("Observation settings could not be saved")
    }
  }

  async savePlan() {
    const ep = `${process.env.REACT_APP_API}/partner/observations/customization`;
    const result = await axios.post(ep, { classification1: this.state.classification1, classification2: this.state.classification2 })

    if (result.data.success) {
      this.props.nextPage();
    } else {
      toast.error("Observation settings could not be saved")
    }
  }

  validateFields = () => {
    if (this.state.classification1 === "") {
      toast.error("Please fill in all Fields")
      return false
    }
    return true
  }

  /// ================== NEW MODAL ========================== ///

  getClassification1Func = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            // marginBottom: 9
          }}
        >
          <div style={{ display: "block", width: "100%" }}>
            <div
              // key={i}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start"
              }}
              name=""
            >
              <div
                style={{
                  width: "100%",
                  display: "block",
                  alignSelf: "flex-start"
                }}
              >
                <input
                  style={{
                    height: "40px",
                    width: "100%",
                    fontSize: "0.93rem",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "300",
                    color: "#494949",
                    border: "1px solid #c5c5c5",
                    borderRadius: "4px",
                    paddingLeft: "8px"
                  }}
                  // disabled={!this.state.editMode}
                  disabled={!this.state.editClassification1}
                  // name={domain.id}
                  // id={domain.id}
                  type="text"
                  onChange={e => {
                    this.setState({
                      classification1: e.target.value
                    });
                  }}
                  value={this.state.classification1 !== undefined ? this.state.classification1 : "Domain"}
                />
              </div>
              <div
                style={{
                  cursor: "pointer",
                  color: "#9BA7B2",
                  marginLeft: "20px"
                }}
                onClick={() => {
                  this.setState({
                    editClassification1: true
                  })
                }}
              >
                <Edit color="action" fontSize="large" />
              </div>
            </div>

          </div>
        </div>
      </>
    )
  }

  getClassification2Func = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            // marginBottom: 9
          }}
        >
          <div style={{ display: "block", width: "100%" }}>
            <div
              // key={i}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start"
              }}
              name=""
            >
              <div
                style={{
                  width: "100%",
                  display: "block",
                  alignSelf: "flex-start"
                }}
              >
                <input
                  style={{
                    height: "40px",
                    width: "100%",
                    fontSize: "0.93rem",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "300",
                    color: "#494949",
                    border: "1px solid #c5c5c5",
                    borderRadius: "4px",
                    paddingLeft: "8px"
                  }}
                  // disabled={!this.state.editMode}
                  disabled={!this.state.editClassification2}
                  // name={domain.id}
                  // id={domain.id}
                  type="text"
                  onChange={e => {
                    this.setState({
                      classification2: e.target.value
                    });
                  }}
                  value={this.state.classification2 !== undefined ? this.state.classification2 : "Skills"}
                />
              </div>
              <div
                style={{
                  cursor: "pointer",
                  color: "#9BA7B2",
                  marginLeft: "20px"
                }}
                onClick={() => {
                  this.setState({
                    editClassification2: true
                  })
                }}
              >
                <Edit color="action" fontSize="large" />
              </div>
            </div>

          </div>
        </div>
      </>
    )
  }

  getSaveButtonFunc = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          marginBottom: 0
        }}
      >
        <button
          onClick={async e => {
            if (this.validateFields) {
              await this.saveEdit();
              this.setState({
                editClassification1: false,
                editClassification2: false
              })
            }
            // handleClickAndValidate(handleClick, validatorKey, e)
          }}
          className="base-modal-button"
          style={{
            backgroundColor: BLUE_BUTTON,
            color: WHITE,
            maxWidth: "175px"
          }}
          type={BUTTON}
        >
          {"Save Edit"}
        </button>
      </div>
    )
  }

  getBaseModalFields() {
    const classification1 = {
      type: GET_HTML,
      data: {
        name: "Classification Name",
        required: true,
        gethtml: this.getClassification1Func
      }
    }

    const classification2 = {
      type: GET_HTML,
      data: {
        name: "Sub-Classification Name",
        gethtml: this.getClassification2Func
      }
    }

    const saveEditButton = {
      type: GET_HTML,
      data: {
        gethtml: this.getSaveButtonFunc
      }
    }

    // const classification1 = {
    //   type: STRING_INPUT,
    //   data: {
    //     name: "Classification Name",
    //     required: true,
    //     handleChange: e => {
    //       this.setState({
    //         classification1: e.target.value
    //       });
    //     },
    //     value: this.state.classification1 !== undefined ? this.state.classification1 : "Domain"
    //   },
    //   validators: { validateSubmit: () => this.state.classification1 !== "" }
    // };

    // const classification2 = {
    //   type: STRING_INPUT,
    //   data: {
    //     name: "Sub-Classification Name",
    //     required: false,
    //     handleChange: e => {
    //       this.setState({
    //         classification2: e.target.value
    //       });
    //     },
    //     value: this.state.classification2 !== undefined ? this.state.classification2 : "Skills"
    //   },
    //   // validators: { validateSubmit: () => this.state.classification2 !== "" }
    // };

    const fields = [];
    fields.push(classification1);
    fields.push(classification2);
    fields.push(saveEditButton);

    return fields;
  }

  getBaseModalButtons = () => {
    // const addButton = {
    //   name: "Submit",
    //   buttonColour: BLUE_BUTTON,
    //   textColour: WHITE,
    //   validatorKey: "validateSubmit",
    //   handleClick: _ => this.savePlan()
    // };

    const nextButton = {
      name: "Next",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: async _ => {
        if (this.validateFields) {
          await this.savePlan()
        }
      }
    }

    return [nextButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Change Classification names",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ========================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(EditClassificationsModal);
