import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import { formTypes } from "../../components/UI/form";
import ReactTooltip from "react-tooltip";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT } from "./baseModal/FieldTypes";
import { notEmptyString } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class AddConnectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      phone: "",
      contactForm: null
    };
  }
  async create_connection(e) {
    e.preventDefault();

    if (this.state.name.length == 0 || this.state.email.length == 0) {
      toast.error(`Name and email are mandatory fields`);
      return;
    }

    const formData = {};

    formData[`${this.state.contactForm.id}Full Name`] = this.state.name;
    formData[`${this.state.contactForm.id}Cell Phone`] = this.state.phone;
    formData[`${this.state.contactForm.id}Email`] = this.state.email;
    formData[`${this.state.contactForm.id}Message`] = this.state.message;

    const ep = `${process.env.REACT_APP_API}/partners/leads`;
    const cb = await axios.post(ep, {
      Form: this.state.contactForm,
      data: formData,
      added_contact: true
    });

    if (cb.data.success) {
      toast.success(`Connection Created!`);
      this.props.addOne(cb.data.data);
      this.props.onClose();
    } else {
      toast.error(`Connection email already added!`);
    }
  }

  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/partners/forms/contact-us`;
    const results = await axios.get(ep);

    if (results.data.success) {
      this.setState({ contactForm: results.data.data[0] });
    }
  }

  /// ================== NEW MODAL ========================== ///

  getBaseModalFields() {
    const nameInput = {
      type: STRING_INPUT,
      data: {
        name: "Name",
        id: "name",
        required: true,
        placeholder: "",
        value: this.state.name,
        handleChange: e => {
          this.setState({
            name: e.target.value
          });
        }
      },
      validators: { validateSubmit: () => this.state.name != "" }
    };

    const emailInput = {
      type: STRING_INPUT,
      data: {
        name: "Email",
        id: "email",
        required: true,
        placeholder: "",
        value: this.state.email,
        handleChange: e => {
          this.setState({
            email: e.target.value
          });
        }
      },
      validators: { validateSubmit: () => this.state.email != "" }
    };

    const phoneInput = {
      type: STRING_INPUT,
      data: {
        name: "Cell Phone",
        id: "phone",
        required: false,
        type: "tel",
        placeholder: "",
        value: this.state.phone,
        maxLength: "10",
        info: "Make sure to only enter numbers",
        handleChange: e => {
          this.setState({
            phone: e.target.value
          });
        }
      }
    };

    return [nameInput, emailInput, phoneInput];
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.create_connection(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Create New Connection",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      height: "65vh",
      midSectionHeight: "49vh"
    };
  };

  /// ================== END NEW MODAL ===================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px"
              }}
            >
              <h3>Create New Connection</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  color: "#999999",
                  fontSize: ".8rem",
                  marginBottom: "20px"
                }}
              >
                Items with <span style={{ color: "orange" }}>*</span> are
                required
              </div>
            </div>
            <form onSubmit={e => this.create_connection(e)}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Name <span style={{ color: "orange" }}>*</span>
                </label>
                <input
                  name="name"
                  id="name"
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={this.state.name}
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="email"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Email <span style={{ color: "orange" }}>*</span>
                </label>
                <input
                  name="email"
                  id="email"
                  type="email"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={this.state.email}
                />
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="phone"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Cell Phone
                </label>
                <input
                  name="phone"
                  id="phone"
                  type="number"
                  className="form-control"
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  value={this.state.phone}
                />
              </div>

              <div className="modal-footer" style={{ marginTop: "25px" }}>
                <button
                  type="submit"
                  // onClick={_ => this.savePlan()}
                  className="btn profile-btn"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default AddConnectionModal;
