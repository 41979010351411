import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { CircleCheck } from "../../assets";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
class EditInvoiceQuantityModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      quantity: props.invoiceDetails[props.selectedItemIndex].quantity,
      invoiceDetails: props.invoiceDetails
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async editQuantity(e) {
    e.preventDefault();
    console.log("editQuantity");

    const ep = `${process.env.REACT_APP_API}/partners/orders/update`;
    const res = await axios.post(ep, {
      id: this.props.match.params.id,
      invoice_details: this.state.invoiceDetails
    });
    console.log("res", res);
    if (res.data.success) {
      toast.success("Quantity Updated");
      this.props.onClose();
    }
  }

  render() {
    console.log("this.state", this.state);
    console.log("this.props", this.props);
    console.log("this.global", this.global);

    return (
      <ModalTemplate
        heading="Edit Quantity"
        onClose={this.props.onClose}
        style={{ overflow: "visible" }}
      >
        <form onSubmit={e => this.editQuantity(e)}>
          <div id="heightset" className="modal-main">
            <ModalFields.input
              label="Quantity"
              name="quantity"
              type="number"
              min={1}
              value={this.state.quantity}
              required={false}
              onChange={e => {
                const updatedInvoiceDetails = this.props.invoiceDetails;
                updatedInvoiceDetails[this.props.selectedItemIndex].quantity =
                  parseInt(e.target.value);

                this.setState({
                  quantity: e.target.value,
                  invoiceDetails: updatedInvoiceDetails
                });
              }}
            />
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <img
                  src={CircleCheck}
                  alt=""
                  style={{
                    color: "white",
                    height: "15px",
                    width: "15px",
                    marginRight: "8px"
                  }}
                />
                <span>Update Quantity</span>
              </div>
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(EditInvoiceQuantityModal);
