import React, { Fragment } from "reactn";
// import { Link } from "react-router-dom";
import { formTypes } from "../../../components/UI/form";
import { numToHours } from "../../../lib/util";
import { addBusinessDays } from "date-fns";

class FillApplication extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      daysOfWeek: [],
      startDate: null,
      endDate: null,
      selectedPlan: {},
      selectedCat: {},
      hasEnd: "",
      ...this.global.enrApp
    };

    this.handleChange = this.handleChange.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.getAvailableDays = this.getAvailableDays.bind(this);

    if (!this.global.enrApp || !this.global.enrApp.selectedChild.id) {
      this.props.history.push(
        `/parents/interested-programs/${props.match.params.slug}/choose-child`
      );
    }
  }

  nextStep() {
    const slug = this.props.match.params.slug;
    this.setGlobal({
      enrApp: {
        ...this.state
      }
    });
    this.props.history.push(`/parents/interested-programs/${slug}/finalize`);
  }

  prevStep() {
    const slug = this.props.match.params.slug;
    this.props.history.push(
      `/parents/interested-programs/${slug}/choose-child`
    );
  }

  getAvailableDays() {
    return formTypes.SelectOptions.daysOfWeekRanges.filter(d => {
      return (
        d.value !==
        this.global.school.excludedDays.find(ed => ed.value === d.value).value
      );
    });
  }

  handleChange = (e, v) => {
    this.setState({
      [e]: v
    });
  };
  render() {
    const vals = this.state;
    const glbl = this.global.school;
    return (
      <div className="container-fluid new_application new_application_3">
        <div className="cont">{/* <h1>New Application</h1> */}</div>
        {!glbl ? null : (
          <>
            <div className="cont mt-5 pt-5">
              <div className="contbox">
                <p className="nom">2</p>
                <div className="header">
                  <h2>Fill in Application</h2>
                </div>
                <div className="body">
                  <div className="row">
                    <div className="col-md-7 col-lg-6">
                      <h2>Program Plan</h2>
                      <div className="boxwithcroll">
                        {glbl.pricingPlans.map((cat, i) => (
                          <Fragment key={`cat-${i}`}>
                            {!cat.plans.length ? null : (
                              <>
                                <h3>{cat.name}</h3>
                                {cat.plans.map((pln, j) => (
                                  <div key={`pln-${j}`} className="controw">
                                    <div className="row">
                                      <div className="col-7">
                                        <h4>{pln.numDays} days / week</h4>
                                        <p>
                                          <span>
                                            ${pln.amount} - {pln.interval}
                                          </span>
                                        </p>
                                        <p>
                                          {numToHours(pln.hoursFrom)} -{" "}
                                          {numToHours(pln.hoursTo)}
                                        </p>
                                      </div>
                                      <div className="col-5 parent">
                                        <div className="selectDiv">
                                          <button
                                            onClick={() => {
                                              this.setState({
                                                selectedPlan: pln,
                                                selectedCat: cat
                                              });
                                            }}
                                            className={
                                              vals.selectedPlan.id === pln.id
                                                ? "selectedButton"
                                                : "selectButton"
                                            }
                                          ></button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-5 col-lg-6">
                      <div className="rightside">
                        <div className="wraper">
                          <h2>Days of the Week</h2>

                          <div className="select">
                            <formTypes.DaysDropdownSelect
                              name="daysOfWeek"
                              value={vals.daysOfWeek}
                              onChange={this.handleChange}
                              className="w-100"
                              isDisabled={!vals.selectedPlan.id}
                              toremove={this.global.school.excludedDays}
                              range={this.global.school.daysOfWeek}
                            />
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <p>
                                {vals.daysOfWeek.length >
                                vals.selectedPlan.numDays ? (
                                  <span style={{ color: "red" }}>
                                    Selected days cannot exceed number of plan
                                    days
                                  </span>
                                ) : (
                                  <>&nbsp; </>
                                )}
                                {vals.daysOfWeek.length <
                                vals.selectedPlan.numDays ? (
                                  <span style={{ fontSize: "13px" }}>
                                    {vals.selectedPlan.numDays -
                                      vals.daysOfWeek.length}{" "}
                                    remaining
                                  </span>
                                ) : (
                                  <>&nbsp;</>
                                )}
                              </p>
                            </div>
                          </div>

                          <h2 className="mt-3 mb-0">Start Date</h2>

                          <formTypes.Date
                            name="startDate"
                            value={vals.startDate}
                            onChange={this.handleChange}
                            isDisabled={!vals.selectedPlan.id}
                            minDate={addBusinessDays(
                              new Date(),
                              new Date().getDay() === 0 ||
                                new Date().getDay() === 6
                                ? 0
                                : process.env.REACT_APP_DAYS_OUT
                            )}
                          />

                          {/* <h2 className="mt-3 mb-0">End Date</h2>
                          <div className="row my-2">
                            <div className="col-1">
                              <input
                                type="checkbox"
                                name="hasEnd"
                                onChange={e =>
                                  this.setState({
                                    [e.target.name]: !this.state.hasEnd
                                      ? "checked"
                                      : ""
                                  })
                                }
                                disabled={!vals.selectedPlan.id}
                                checked={this.state.hasEnd}
                                id="hasEnd"
                              />
                            </div>
                            <div className="col-11">
                              <label htmlFor="hasEnd">I know my end date</label>
                            </div>
                          </div>
                          <formTypes.Date
                            name="endDate"
                            selected={vals.endDate}
                            value={vals.endDate}
                            onChange={this.handleChange}
                            isDisabled={
                              !vals.selectedPlan.id || this.state.hasEnd === ""
                            }
                            minDate={
                              vals.startDate
                                ? new Date(vals.startDate)
                                : addDays(new Date(), 1)
                            }
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="buttons mb-0">
                      <button onClick={this.prevStep} className="savebtn">
                        BACK
                      </button>
                      <button
                        onClick={this.nextStep}
                        className="savebtn"
                        disabled={
                          !vals.selectedPlan.id ||
                          !vals.daysOfWeek.length ||
                          !vals.startDate ||
                          vals.daysOfWeek.length > vals.selectedPlan.numDays ||
                          vals.daysOfWeek.length < vals.selectedPlan.numDays
                        }
                      >
                        NEXT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default FillApplication;
