import { CSVLink } from "react-csv";
import React from "react";

const sanitizeString = (desc) => {
  let itemDesc = desc;
  if (desc && typeof(itemDesc) === 'string') {
      itemDesc = itemDesc.replace(/"/g, '""');
      itemDesc = itemDesc.replace(/ +(?= )/g,'');
  }
  return itemDesc;
}

/**
 * Card element for report & analytics pages.
 *
 * @returns {JSX}
 *
 * @param {String} title Title of Card.
 * @param {Array<String>} subtitles Subtitles to appear below the title.
 * @param {Array<Object>} csvHeaders Array of headers for CSV export. See react-csv documentation.
 * @param {Array<Object>} csvData Data for CSV export. See react-csv documentation.
 * @param {String} filename Filename for CSV export.
 * @param {bool} isLarge Option for larger table.
 */
const ReportContainer = ({
  title,
  subtitles,
  csvHeaders,
  csvData,
  children,
  fileName,
  buttonText,
  isLarge
}) => {
  return (
    <div className="report-container">
      <div className={isLarge ? "report-section larger" : "report-section"}>
        <div className="export-button-section">
          {csvData ? (
            <CSVLink
              headers={csvHeaders.map(header => {
                return {...header, label: sanitizeString(header.label)}})}
              data={csvData.map(data => {
                const newData = {};
                Object.entries(data).forEach(pair => {
                  newData[pair[0]] = sanitizeString(pair[1]);
                })
                return newData
              })}
              filename={fileName}
            >
              <button className="filter-button">{buttonText || "Export"}</button>
            </CSVLink>
          ) : (
            <button className="filter-button disabled" disabled>
              Export
            </button>
          )}
        </div>
        <div className="report-top-section">
          <h3 id="table-title" className="report-heading">{title}</h3>
        </div>
        <div className="report-top-section">
          {subtitles.map((subtitle, i) => {
            return (
              <p className="report-subtitles" key={i}>
                {subtitle}
              </p>
            );
          })}
        </div>
        {children}
      </div>
    </div>
  );
};

export default ReportContainer;
